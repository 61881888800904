import { get } from "lodash-es";
import { createSelector } from "reselect";
import {
  AvailableMetrics,
  SocketResponse,
} from "views/modules/builder/entities/builder.entities";
import {
  BarFilters,
  BarType,
  BuilderMetrics,
  MetricsAPIResp,
  MetricsReducer,
  ResourceAPIResp,
} from "./entities";
import { DashboardReducer, ReportReducer } from "./reducers";

export const getWidgetReportItems = createSelector(
  (state: any) => get(state, ["widgets", "reports", "reports"], []),
  (r) => r
);

export const getWidgetReportItemByKey = createSelector(
  (state: any, key: string) => {
    const reports = get(state, ["widgets", "reports", "reports"], []);
    const index = reports.findIndex((c: any) => c.key == key);
    if (index >= 0) return reports[index];
    return null;
  },
  (r) => r
);

export const getWidgetAttributeItems = createSelector(
  (state: any) => get(state, ["widgets", "attributes", "items"], {}),
  (r) => r
);
export const getWidgetDatasetsList = createSelector(
  (state: any) => get(state, ["widgets", "attributes", "datasets"], []),
  (r) => r
);

export const getWidgetReportsInflight = createSelector(
  (state: any) => get(state, ["widgets", "reports", "inflight"], true),
  (r) => r
);

export const getReportWidgetsInflightStatus = createSelector(
  (state: unknown) =>
    get(state, ["widgets", "reports", "builders", "inflight"], true) as boolean,
  (r) => r
);

export const getWidgetReportsBuilderItems = createSelector(
  (state: any) => {
    return get(state, ["widgets", "reports", "builders", "items"], []);
  },
  (r) => r
);

export const getWidgetReportsBuilderLayouts = createSelector(
  (state: any) => get(state, ["widgets", "reports", "builders", "layouts"], []),
  (r: any) => r
);

export const getBuilderFormattedRandomStatics = createSelector(
  (state: any) =>
    get(
      state,
      ["widgets", "reports", "builders", "chart_data", "_statics"],
      null
    ),
  (r: any) => r
);

export const getBuilderFormattedData = createSelector(
  (state: any) =>
    get(state, ["widgets", "reports", "builders", "chart_data"], {}),
  (r: any) => r
);
export const getBuilderFormattedData2 = createSelector(
  (state: any) => get(state, ["widgets"], {}),
  (r: any) => r
);

export const getAvailableMetrics = createSelector(
  (state: any) => get(state, ["widgets", "datasets"], {}),
  (r: AvailableMetrics) => r
);
export const getDatasetsColumns: any = createSelector(
  (state: any, type: string, _for: "table" | "timeseries") =>
    get(state, ["widgets", "datasets", "metrics_list", type, _for], []),
  (r: any) => r.map((c: any) => c.name)
);

// getState().widgets.reports.builders.defaultViews
export const getDefaultGridviewDataByResource: any = createSelector(
  (state: any, resourceType: string, identifiers: string, value: string) => {
    const _items: any = get(
      state,
      [
        "widgets",
        "reports",
        "builders",
        "defaultViews",
        resourceType,
        "widgetData",
        "gridviewData",
        "data",
      ],
      []
    );
    const idx = _items.findIndex((c: any) => c[identifiers] == value);
    return idx >= 0 ? _items[idx] : null;
  },
  (r) => ({ ...r })
);

export const getWidgetBuilderDialogInflight = createSelector(
  (state: any) => get(state, ["widgets", "dialogConfig", "inflight"], {}),
  (r) => r
);
export const getWidgetBuilderDialogScopeConfig = createSelector(
  (state: any, scope: string) =>
    get(state, ["widgets", "dialogConfig", "items", scope], {}),
  (r: AvailableMetrics) => r
);

export const getReportData = createSelector(
  (state: ReportReducer) => get(state, ["widgets", "reports"]),
  (r: ReportReducer) => r
);
export const getDashboardData = createSelector(
  (state: DashboardReducer) => get(state, ["widgets", "dashboards"]),
  (r: DashboardReducer) => r
);

export const getResourceAPIResp = createSelector(
  (state: MetricsReducer) =>
    get(
      state,
      ["widgets", "metrics", "resourceAPIResp"],
      {}
    ) as ResourceAPIResp,
  (r: ResourceAPIResp) => r
);

export const getBuilderMetrics = createSelector(
  (state: MetricsReducer) =>
    get(state, ["widgets", "metrics", "builder"], {}) as BuilderMetrics,
  (bm: BuilderMetrics) => bm
);

export const getBarFilters = (barType: BarType) =>
  createSelector(
    (state: MetricsReducer) =>
      get(state, ["widgets", "metrics", barType], {}) as BarFilters,
    (bf: BarFilters) => bf
  );

export const getSocketData = createSelector(
  (state: {
    widgets: {
      socketData: {
        data: SocketResponse[];
      };
    };
  }) => get(state, ["widgets", "socketData"]),
  (sr) => sr
);

export const getInfraMetrics = createSelector(
  (state: MetricsReducer) => get(state, ["widgets", "metrics", "infra"]),
  (r: Record<string, MetricsAPIResp>) => r
);
