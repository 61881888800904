import * as React from "react";
import "./_style.scss";
import {RoutesPatterns} from "core/utils/route_schema";

const IntegrationRoutes: RoutesPatterns = {
    moduleName: "integration",
    slug: "/integration",
    items: [
        {
            pattern: [
                "/",
            ],
            component: React.lazy(() => import("views/modules/integrations/index")),
            breadcrumbTitle: "Integration",
            breadcrumbs: React.lazy(() => import("views/modules/integrations/breadcrumbs")),
            sidebar: React.lazy(() => import("views/modules/integrations/sidebar")),
        },
        {
            pattern: [
                "/vercel",
            ],
            component: React.lazy(() => import("views/modules/integrations/components/dialog/integration-dialog/vercel-integration-popup")),
            breadcrumbTitle: "Integration",
        }
    ]
}
export default IntegrationRoutes