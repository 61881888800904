import * as React from 'react'

export default function (tip:any, children:any, getContent:any, multiline:any) {
  if (children) return children
  if (getContent !== undefined && getContent !== null) return getContent // getContent can be 0, '', etc.
  if (getContent === null) return null // Tip not exist and children is null or undefined

  const regexp = /<br\s*\/?>/
  if (!multiline || multiline === 'false' || !regexp.test(tip)) {
    // No trim(), so that user can keep their input
    return tip
  }

  // Multiline tooltip content
  return tip.split(regexp).map((d:any, i:any) => {
    return (
      <span key={i} className='multi-line'>{d}</span>
    )
  })
}
