import { createSelector } from "reselect";
import { get } from "lodash-es";
import {
  FILTER_ACTION,
  FILTER_BAR,
  FILTERS,
  TIMINGS,
  PICKERDATE,
  LOG_FILTERS,
  TIMELINE,
  CUSTOM_METRIC_TIME,
} from "store/logs/constant";
import { LogsList } from "src/entities/logs/lists";
import { Log } from "views/modules/logs-v2/types";

export const getRefreshBtnClick = createSelector(
  (state: any) => get(state, ["logs", "refreshBtnClick"], false),
  (data) => data
);
export const getFilterBar = createSelector(
  (state: any) => get(state, ["logs", "filterBar"], FILTER_BAR),
  (data) => {
    return data;
  }
);
export const getFilters = createSelector(
  (state: any) => get(state, ["logs", "filters"], FILTERS),
  (data) => {
    return data;
  }
);

export const getNameSpaces = createSelector(
  (state: any) => get(state, ["logs", "nameSpaces"], []),
  (data) => {
    return data;
  }
);

export const getLogs = createSelector(
  (state: any) => get(state, ["logs", "list", "items"], []),
  (data: LogsList[]) => data
);

export const getLogsList = createSelector(
  (state: any) => get(state, ["logs", "list", "items"], []),
  (data: Log[]) => data
);

export const getUpdateList = createSelector(
  (state: any) => get(state, ["logs", "list", "update"], Date.now()),
  (data) => data
);

export const getLogInfo = createSelector(
  (state: any) => get(state, ["logs", "sidePanel", "info"], {}),
  (data) => data
);

export const getUpdate = createSelector(
  (state: any) => get(state, ["logs", "sidePanel", "update"], Date.now()),
  (data) => data
);

export const getFilterAction = createSelector(
  (state: any) => get(state, ["logs", "filterAction"], FILTER_ACTION),
  (data) => {
    if (!data.text) {
      return {
        text: "All Of",
      };
    }
    return data;
  }
);

export const getLogsTimeline = createSelector(
  (state: any) => get(state, ["logs", "list", "timeline"], []),
  (data) => {
    return data;
  }
);
export const getLiveIndicator = createSelector(
  (state: any) => get(state, ["logs", "liveIndicator"], false),
  (data) => data
);

export const getLogTimings = createSelector(
  (state: any) => get(state, ["logs", "timings"], TIMINGS),
  (data) => data
);
export const getPickerDate = createSelector(
  (state: any) => get(state, ["logs", "pickerDate"], PICKERDATE),
  (data) => data
);

export const getLogFilters = createSelector(
  (state: any) => get(state, ["logs", "logFilters"], {}),
  (data) => data
);
export const getSelectedFilter = createSelector(
  (state: any) => get(state, ["logs", "selectedFilter"], {}),
  (data) => (data.key ? data.key : null)
);
export const getFilterOptions = createSelector(
  (state: any) => get(state, ["logs", "filterOptions"], {}),
  (data) => data
);
export const getLogTimeline = createSelector(
  (state: any) => get(state, ["logs", "timelineTimings"], TIMELINE),
  (data) => data
);
export const getLogsSettings = createSelector(
  (state: any) => get(state, ["logs", "logsSetting"], []),
  (data) => data
);
export const getLogsSettingId = createSelector(
  (state: any) => get(state, ["logs", "logsSettingId"], 0),
  (data) => data
);

export const getFingerPrints = createSelector(
  (state: any) => get(state, ["logs", "fingerPrints", "items"], []),
  (data) => data
);
export const getApmProjects = createSelector(
  (state: any) => get(state, ["logs", "apmProjects", "items"], []),
  (data) => data
);
export const getInitLogs = createSelector(
  (state: any) => get(state, ["logs", "initLogs"], { init: true }),
  (data) => {
    return data.init;
  }
);

export const getCustomMetrics = createSelector(
  (state: any) => get(state, ["logs", "customMetrics"], []),
  (data: any) => {
    return data;
  }
);

export const getCustomMetricId = createSelector(
  (state: any) => get(state, ["logs", "metricId"], ""),
  (data: string) => {
    return data;
  }
);

export const getCustomMetricsTimings = createSelector(
  (state: any) =>
    get(state, ["logs", "customMetricsTimings"], CUSTOM_METRIC_TIME),
  (data) => data
);

export const getLogColumns = createSelector(
  (state: any) => get(state, ["logs", "logColumns"], {}),
  (data) => data
);

export const getTimelineLogs = createSelector(
  (state: any) => get(state, ["logs", "timelineLogs", "items"], []),
  (data) => data
);
