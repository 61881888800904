import {createReducer} from "core/utils/create_reducer";
import {AGENT_TOKEN_DETAIL_RECEIVED,CLIENT_TOKEN_LIST_RECEIVED} from "./constant";
import {combineReducers} from "redux";

const list = createReducer([], {
    [CLIENT_TOKEN_LIST_RECEIVED]: (state, opts) => {
        if (Array.isArray(opts.items)) {
            return opts.items;
        }
        return {...state};
    }
})
const detail = createReducer([], {
    [AGENT_TOKEN_DETAIL_RECEIVED]: (state, opts) => {
        if (Object.keys(opts.data).length) {
            return opts.data;
        }
        return {...state};
    }
})

export default combineReducers({
    list,
    detail
})