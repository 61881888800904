import {
    RECEIVED_BACKOFF_AUTH_TOKEN,
    RECEIVED_BACKOFF_AGENTS,
    RECEIVED_BACKOFF_USER,
    REQUEST_BACKOFF_INFLIGHT,
    RECEIVED_BACKOFF_LOGIN_DETAILS,
    RECEIVED_BACKOFF_EMAIL_DETAILS,
    RECEIVED_BACKOFF_SENDER,
    RECEIVED_BACKOFF_EMAIL_CAMPAIGN,
    RECEIVED_BACKOFF_EDIT_EMAIL_CAMPAIGN,
    RECEIVED_BACKOFF_DELETE_EMAIL_CAMPAIGN,
    RECEIVED_BACKOFF_LOGS,
    RECEIVED_BACKOFF_DELETE_ACCOUNT,
    RECEIVED_BACKOFF_UPDATE_ACCOUNT,
    RECEIVED_BACKOFF_VERCEL_DATA,
    RECEIVED_BACKOFF_UPDATE_TRIALDAY,
    RECEIVED_BACKOFF_CONTACT_DETAILS,
    THEME_MODE,
    RECEIVED_BACKOFF_USAGES,
    RECEIVED_INTERNAL_ACCOUNT_FLAG
} from "./constant";
import {requestUpdateTrialDay} from "store/backoff/api";


export const receivedBackoffAuthToken = (arg: any = {}) => {
    return {
        type: RECEIVED_BACKOFF_AUTH_TOKEN,
        ...arg
    }
}
export const receivedBackoffUsers = (arg: any = {}, isScroll: boolean) => {
    return {
        type: RECEIVED_BACKOFF_USER,
        ...arg,
        isScroll
    }
}
export const requestBackoffInflight = () => {
    return {
        type: REQUEST_BACKOFF_INFLIGHT,
    }
}
export const receivedBackoffAgents = (arg: any = {}) => {
    return {
        type: RECEIVED_BACKOFF_AGENTS,
        ...arg
    }
}

export const receivedBackoffLogs = (arg: any = {}) => {
    return {
        type: RECEIVED_BACKOFF_LOGS,
        ...arg
    }
}
export const receivedBackoffUsages = (arg: any = {}) => {
    return {
        type: RECEIVED_BACKOFF_USAGES,
        ...arg
    }
}

export const receivedBackoffLoginDetails = (arg: any = {}) => {
    return {
        type: RECEIVED_BACKOFF_LOGIN_DETAILS,
        ...arg
    }
}

export const receivedBackoffEmailDetails = (arg: any = {}) => {
    return {
        type: RECEIVED_BACKOFF_EMAIL_DETAILS,
        ...arg
    }
}
export const receivedBackoffContactDetails = (arg: any = {}) => {
    return {
        type: RECEIVED_BACKOFF_CONTACT_DETAILS,
        ...arg
    }
}

export const receivedBackoffSender = (arg: any = {}) => {
    return {
        type: RECEIVED_BACKOFF_SENDER,
        ...arg
    }
}

export const receivedEmailCampaignTemplate = (arg: any = {}) => {
    return {
        type: RECEIVED_BACKOFF_EMAIL_CAMPAIGN,
        ...arg
    }
}

export const receivedEditEmailCampaignTemplate = (arg: any = {}) => {
    return {
        type: RECEIVED_BACKOFF_EDIT_EMAIL_CAMPAIGN,
        ...arg
    }
}

export const receivedDeleteTemplate = (arg: any = {}) => {
    return {
        type: RECEIVED_BACKOFF_DELETE_EMAIL_CAMPAIGN,
        ...arg
    }
}

export const receivedDeleteAccount = (arg: any = {}) => {
    return {
        type: RECEIVED_BACKOFF_DELETE_ACCOUNT,
        ...arg
    }
}
export const receivedReactivateAccount = (arg: any = {}) => {
    return {
        type: RECEIVED_BACKOFF_UPDATE_ACCOUNT,
        ...arg
    }
}
export const receivedUpdateTrialDay = (arg: any = {}) => {
    return {
        type: RECEIVED_BACKOFF_UPDATE_TRIALDAY,
        ...arg
    }
}

export const updateThemeModeBkoff = (mode: string) => {
    return {type: THEME_MODE, mode}
}

export const receivedBackoffVercelData = (arg: any = {}) => {
    return {
        type: RECEIVED_BACKOFF_VERCEL_DATA,
        ...arg
    }
}
export const receivedInternalAccountFlag = (arg: any = {}) => {
    return {
        type: RECEIVED_INTERNAL_ACCOUNT_FLAG,
        ...arg
    }
}