import {
  WS_TRACE_LIST_RECEIVED,
  ERROR_TRACE_LIST_RECEIVED,
  PANEL_ERROR_GROUP_LIST_RECEIVED,
  PROFILING_APPS_RECEIVED,
  PROFILING_LABELS_RECEIVED,
  PROFILING_DATA_RECEIVED,
  TRACE_LOGS_RECEIVED,
  TRACE_ERRORS_RECEIVED,
  RUM_SESSION_RECEIVED,
  SPAN_LABEL_DATA_UPDATE,
  TOGGLE_FILTER_VALUES,
  SELECT_FILTER_VALUES,
  SEARCH_VALUE_SELECT,
  REMOVE_SEARCH_TAG,
  SET_SPAN_FILTER_LABELS,
  UPDATE_SEARCH_LABEL,
  SET_DB_SPAN_FILTER_LABELS,
  UPDATE_DB_SEARCH_LABEL,
  REMOVE_DB_SEARCH_TAG,
  SPAN_DB_LABEL_DATA_UPDATE,
  SEARCH_DB_VALUE_SELECT,
} from "./constant";

export const errorTraceListReceived = (arg: any = {}) => {
  return {
    type: ERROR_TRACE_LIST_RECEIVED,
    ...arg,
  };
};

export const wsTraceListReceived = (arg: any = {}) => {
  return {
    type: WS_TRACE_LIST_RECEIVED,
    ...arg,
  };
};

export const panelErrorGroupListReceived = (arg: any = {}) => {
  return {
    type: PANEL_ERROR_GROUP_LIST_RECEIVED,
    ...arg,
  };
};
export const receivedRumSession = (arg: any = {}) => {
  return {
    type: RUM_SESSION_RECEIVED,
    ...arg,
  };
};

export const receivedTraceLogs = (arg: any = {}) => {
  return {
    type: TRACE_LOGS_RECEIVED,
    ...arg,
  };
};

export const receivedTraceErrors = (arg: any = {}) => {
  return {
    type: TRACE_ERRORS_RECEIVED,
    ...arg,
  };
};

export const receivedProfilingApps = (arg: any = {}) => {
  return {
    type: PROFILING_APPS_RECEIVED,
    ...arg,
  };
};

export const receivedProfilingLabels = (arg: any = {}) => {
  return {
    type: PROFILING_LABELS_RECEIVED,
    ...arg,
  };
};

export const receivedProfilingData = (arg: any = {}) => {
  return {
    type: PROFILING_DATA_RECEIVED,
    ...arg,
  };
};

export const setSpanFilterLabels = (arg: any = {}) => {
  return {
    type: SET_SPAN_FILTER_LABELS,
    ...arg,
  };
};

export const setDbSpanFilterLabels = (arg: any = {}) => {
  return {
    type: SET_DB_SPAN_FILTER_LABELS,
    ...arg,
  };
};

export const updateSpanLabelData = (arg: any = {}) => {
  return {
    type: SPAN_LABEL_DATA_UPDATE,
    ...arg,
  };
};

export const updateDbSpanLabelData = (arg: any = {}) => {
  return {
    type: SPAN_DB_LABEL_DATA_UPDATE,
    ...arg,
  };
};

export const updateSearchLabel = (arg: any = {}) => {
  return {
    type: UPDATE_SEARCH_LABEL,
    ...arg,
  };
};

export const updateDbSearchLabel = (arg: any = {}) => {
  return {
    type: UPDATE_DB_SEARCH_LABEL,
    ...arg,
  };
};

export const toggleFilterValues = (arg: any = {}) => {
  return {
    type: TOGGLE_FILTER_VALUES,
    ...arg,
  };
};

export const selectFilterValue = (arg: any = {}) => {
  return {
    type: SELECT_FILTER_VALUES,
    ...arg,
  };
};

export const searchValueSelect = (arg: any = {}) => {
  return {
    type: SEARCH_VALUE_SELECT,
    ...arg,
  };
};

export const searchDbValueSelect = (arg: any = {}) => {
  return {
    type: SEARCH_DB_VALUE_SELECT,
    ...arg,
  };
};

export const removeSearchTag = (arg: any = {}) => {
  return {
    type: REMOVE_SEARCH_TAG,
    ...arg,
  };
};

export const removeDbSearchTag = (arg: any = {}) => {
  return {
    type: REMOVE_DB_SEARCH_TAG,
    ...arg,
  };
};

