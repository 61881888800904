export const APPLICATION_LIST_RECEIVED = "APPLICATION_LIST_RECEIVED";
export const USER_SESSIONS_GRAPH_RECEIVED = "USER_SESSIONS_GRAPH_RECEIVED";
export const LOADING_TIME_GRAPH_REQUESTED = "LOADING_TIME_GRAPH_REQUESTED";
export const PERCENTAGE_ERROR_GRAPH_REQUESTED =
  "PERCENTAGE_ERROR_GRAPH_REQUESTED";
export const CORE_WEB_VITALS_REQUESTED = "CORE_WEB_VITALS_REQUESTED";
export const CORE_WEB_VITAL_GRAPH_REQUESTED = "CORE_WEB_VITAL_GRAPH_REQUESTED";
export const RUM_DASHBOARD_FILTERS_RECEIVED = "RUM_DASHBOARD_FILTERS_RECEIVED";
export const RUM_DASHBOARD_ERROR_LIST_RECEIVED =
  "RUM_DASHBOARD_ERROR_LIST_RECEIVED";
export const RUM_DASHBOARD_ERROR_STATS_RECEIVED =
  "RUM_DASHBOARD_ERROR_STATS_RECEIVED";
export const RUM_DASHBOARD_TOP_URLS_RECEIVED =
  "RUM_DASHBOARD_TOP_URLS_RECEIVED";
export const RUM_DASHBOARD_USER_STATS_RECEIVED =
  "RUM_DASHBOARD_USER_STATS_RECEIVED";

// Session & Reply

export const SESSION_GRAPH_RECEIVED = "SESSION_GRAPH_RECEIVED";
export const SESSION_LIST_RECEIVED = "SESSION_LIST_RECEIVED";
export const SESSION_RECORDING_STATUS_RECEIVED =
  "SESSION_RECORDING_STATUS_RECEIVED";
export const SESSION_USER_STATUS_RECEIVED = "SESSION_USER_STATUS_RECEIVED";
export const SESSION_RECORDING_TIME_RECEIVED =
  "SESSION_RECORDING_TIME_RECEIVED";
export const SESSION_FILTERS_RECEIVED = "SESSION_FILTERS_RECEIVED";
export const SESSION_TRACES_RECEIVED = "SESSION_TRACES_RECEIVED";
export const SESSION_RECORDING_RECEIVED = "SESSION_RECORDING_RECEIVED";
export const SESSION_ERRORS_RECEIVED = "SESSION_ERRORS_RECEIVED";
export const SESSION_STATUS_RECEIVED = "SESSION_STATUS_RECEIVED";
export const SESSION_ERROR_GRAPH_RECEIVED = "SESSION_ERROR_GRAPH_RECEIVED";
export const SESSION_ERROR_LIST_RECEIVED = "SESSION_ERROR_LIST_RECEIVED";
export const ERROR_SPAN_GRAPH_RECEIVED = "ERROR_SPAN_GRAPH_RECEIVED";
export const ERROR_SPAN_LIST_RECEIVED = "ERROR_SPAN_LIST_RECEIVED";
export const RUM_MOBILE_LANGUAGE = ["android", "ios", "react-native"];

export const RUM_SUB_NAV_TAB_CHANGE = "RUM_SUB_NAV_TAB_CHANGE";
