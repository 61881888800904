export const TRACE_DASHBOARD_RECEIVED = 'TRACE_DASHBOARD_RECEIVED';
export const WS_TRACE_DASHBOARD_RECEIVED = 'WS_TRACE_DASHBOARD_RECEIVED';
export const TRACE_LIST_RECEIVED = 'TRACE_LIST_RECEIVED';
export const WS_TRACE_LIST_RECEIVED = 'WS_TRACE_LIST_RECEIVED';
export const RECEIVED_TRACE_INFO = 'RECEIVED_TRACE_INFO';
export const PANEL_TRACE_LIST_RECEIVED = 'PANEL_TRACE_LIST_RECEIVED';
export const RECEIVED_SPAN_INFO = 'RECEIVED_SPAN_INFO';
export const PANEL_LATENCY_GRAPH_RECEIVED = 'PANEL_LATENCY_GRAPH_RECEIVED';
export const PANEL_SUCCESS_ERR_GRAPH_RECEIVED = 'PANEL_SUCCESS_ERR_GRAPH_RECEIVED';
export const PANEL_HITS_GRAPH_RECEIVED = 'PANEL_HITS_GRAPH_RECEIVED';
export const PANEL_TIMELINE_RECEIVED = 'PANEL_TIMELINE_RECEIVED';
