import * as React from "react";
import "./_style.scss";
import { RoutesPatterns } from "core/utils/route_schema";

const AlertRoutes: RoutesPatterns = {
  moduleName: "alert",
  slug: "/alert",
  breadcrumbs: React.lazy(() => import("views/modules/alerts-v2/breadcrumbs")),
  items: [
    {
      pattern: ["/rules"],
      component: React.lazy(() => import("views/modules/alerts-v2/rules")),
      breadcrumbTitle: "RulesList",
      sidebar: React.lazy(
        () => import("views/modules/alerts-v2/components/sidebar")
      ),
    },
    {
      pattern: ["/newrule", "/edit/:editId/:name"],
      component: React.lazy(() => import("views/modules/alert/rules/form")),
      breadcrumbTitle: "Create Rule",
    },
    {
      pattern: ["/ruleslist"],
      component: React.lazy(
        () => import("views/modules/alert/rules/ruleslist")
      ),
      breadcrumbTitle: "RulesList",
      sidebar: React.lazy(() => import("views/modules/alert/sidebar/sidebar")),
    },
    {
      pattern: ["/maintenance"],
      component: React.lazy(
        () => import("views/modules/alerts-v2/maintenance")
      ),
      breadcrumbTitle: "Maintenance",
      sidebar: React.lazy(
        () => import("views/modules/alerts-v2/components/sidebar")
      ),
    },
    {
      pattern: ["/maintenanceold"],
      component: React.lazy(
        () => import("views/modules/alert/maintenance/index")
      ),
      breadcrumbTitle: "Maintenance",
      sidebar: React.lazy(
        () => import("views/modules/alerts-v2/components/sidebar")
      ),
    },
  ],
};
export default AlertRoutes;
