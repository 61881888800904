/** @format */

/**
 * External dependencies
 */

import * as React from 'react';
import classNames from 'classnames';
import {ReactNode} from "react";

interface Props {
	isError?: boolean,
	isWarning?: boolean,
	text: ReactNode,
	icon?: string,
	id?: string
	className?: any
	isHidden?: boolean
}
const FormInputValidation = React.memo(function (props: Props) {
	const classes = classNames( props.className, {
		'form-input-validation': true,
		'is-warning': props.isWarning,
		'is-error': props.isError,
		'is-hidden': props.isHidden,
	} );
	return (
		<div className={ classes } role="alert">
				<span id={ props.id }>
					{ props.text }
				</span>
		</div>
	);
})

export default FormInputValidation