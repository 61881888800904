import CreateRequest from "core/xhr";
import { SubscribeRequest } from "views/modules/builder/entities/builder.entities";

export const subscribeData = (opts?: { body?: SubscribeRequest }) => {
    return () => {
        const sessionId: string = (window as any).mw_user_session;
        if (!sessionId) {
            return;
        }
        if (opts?.body) {
            opts.body.session_id = sessionId;
        }
        return CreateRequest({
            baseURL: "https://" + window.location.host + "/custom-ws/",
            url: "/subscription/subscribe",
            method: 'post',
            data: JSON.stringify(opts?.body)
        }).then(res => {
            if (res && res.data && res.data.success) {
                // console.log("Subscription Successful!!")
            } else {
                (window as any).mwDebug && console.log("Error while subscribing data", res)
            }
        }).catch(er => {
            (window as any).mwDebug && console.log("Error while subscribing data", er)
        })
    }
}
export const getMetrics = () => {
    return () => {
        return CreateRequest({
            baseURL: "https://" + window.location.host + "/custom-ws",
            url: "/subscription/get-metrics",
            method: 'get',
        }).then(res => {
            if (res && res.status === 200) {
                console.log("Subscription Successful!!", res.data)
            }
        }).catch(er => {
            (window as any).mwDebug && console.log("Error while subscribing data", er)
        })
    }
}

export const unsubscribeData = (event: string) => {
    return () => {
        const sessionId: string = (window as any).mw_user_session;
        if (!sessionId || !event) {
            console.log("event or session not found")
            return;
        }
        CreateRequest({
            baseURL: "https://" + window.location.host + "/custom-ws/",
            url: `/subscription/unsubscribe`,
            method: 'post',
            data: JSON.stringify({
                event,
                session_id: sessionId,
            })
        }).then(res => {
            if (res && res.data && res.data.success) {
                // console.log("Un-Subscription Successful!!")
            } else {
                (window as any).mwDebug &&console.log("Error while unsubscribing data")
            }
        }).catch(er => {
            (window as any).mwDebug && console.log("Error while unsubscribing data", er)
        })
    }
}
export const unregister = (isLogout = false) => {
    return () => {
        let sessionId: string = "";
        if (!isLogout) {
            sessionId = (window as any).mw_user_session;
            if (!sessionId) {
                return;
            }
        }
        CreateRequest({
            baseURL: "https://" + window.location.host + "/custom-ws/",
            url: `/subscription/unregister?session_id=` + sessionId,
            method: 'delete',
            data: {}
        }).then(res => {
            if (res && res.data && res.data.success) {
                // console.log("Un-Registered Successful!!")
            } else {
                (window as any).mwDebug &&console.log("Error while unregistering data")
            }
        }).catch(er => {
            (window as any).mwDebug && console.log("Error while unregistering data", er)
        })
    }
}
// export const register = () => {
//     return () => {
//         const sessionId: string = (window as any).mw_user_session;
//         if (!sessionId) {
//             console.log("event not found")
//             return;
//         }
//         return CreateRequest({
//             baseURL: "https://" + window.location.host + "/custom-ws/",
//             url: "/subscription/register?session_id=" + sessionId,
//             method: 'get',
//         }).then(res => {
//             if (res && res.data && res.data.success) {
//                 // console.log("Subscription Successful!!")
//             } else {
//                 console.log("Error while subscribing data")
//             }
//         }).catch(er => {
//             console.log("Error while subscribing data", er)
//         })
//     }
// }