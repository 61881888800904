import Button from "core/components/button";
import CodeViewer from "core/components/code-viewer";
import FormInputValidation from "core/components/form/form-input-validation";
import FormTextInput from "core/components/form/form-text-input";
import Loader from "core/components/loader";
import { CopyIconNew, InfoIconSvg } from "core/components/svg/icons";
import Tooltip from "core/components/tooltip";
import AppContext from "core/components/wrapper/context";
import * as React from "react";
import { connect } from "react-redux";
import { getThemesMode } from "store/selectors";
import {
    AgentConfigType,
    generateClientAgentConfig,
    requestAgentTokenDetail,
    updateClientAgentConfig
} from "store/settings/installation/api";
import { getAgentTokenDetail } from "store/settings/installation/selectors";
import { showToast } from "store/toast-alerts/actions";
import "../../../integrations/_style.scss";
import "../../../profile/_style.scss";

interface States {
    origin: string[]
    tracePropagationTargets: RegExp[],
    originErr: boolean
    name: string
    nameErr: boolean
    tokenData?: TokenData
    isLoading: boolean
}

interface Props {
    onSaveClick: () => void;
    onDiscardClick: () => void;
    closeDialog: () => void;
    generateClientAgentConfig: (a0?: AgentConfigType, a1?: any) => void
    updateClientAgentConfig: (a0?: AgentConfigType, a1?: any) => void
    token?: string
    language?: string,
    showToast: (a0?: any, a1?: any) => void
    requestAgentTokenDetail: (a0?: any, a1?: any) => void
    tokenDetail?: any
}

interface TokenData {
    id: number,
    metaData: metaData;
    token: string;
}

interface metaData {
    name: string,
    origin: string[],
    language: string,
    tracePropagationTargets: string[]
}

class RumSettingDialog extends React.Component<Props, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            origin: [],
            tracePropagationTargets: [],
            originErr: false,
            name: "",
            nameErr: false,
            tokenData: undefined,
            isLoading: false,
        }
    }

    componentDidMount() {

        if (this.props.token) {
            this.requestTokenDetail(this.props.token)
        }

    }


    requestTokenDetail = (token: string) => {
        this.setState({
            isLoading: true
        })
        this.props.requestAgentTokenDetail({ token: token }, (res?: any) => {
            this.setState({
                isLoading: false
            })
            const _detail = this.props.tokenDetail
            const tokenObj: TokenData = {
                id: _detail.id,
                metaData: {
                    name: _detail?.meta_data?.RawMessage?.name,
                    origin: _detail?.meta_data?.RawMessage?.origin,
                    language: _detail?.meta_data?.RawMessage?.language,
                    tracePropagationTargets: _detail?.meta_data?.RawMessage?.tracePropagationTargets ? _detail?.meta_data?.RawMessage?.tracePropagationTargets.map((r: string) => new String(r)) : []
                },
                token: _detail.token
            }
            this.setState({
                tokenData: tokenObj,
                origin: _detail?.meta_data?.RawMessage?.origin,
                name: _detail?.meta_data?.RawMessage?.name,
                tracePropagationTargets: _detail?.meta_data?.RawMessage?.tracePropagationTargets ? _detail?.meta_data?.RawMessage?.tracePropagationTargets.map((r: string) => new String(r)) : []
            })
        })
    }

    closeDialog = () => {
        this.props.closeDialog();
    }

    isValidOrigins = () => {
        const origins = this.state.origin
        let isValidated = true
        if (origins.length > 0) {
            origins.forEach((url) => {
                const ipPattern = /^((https?:\/\/)|(www.))(?:([a-zA-Z]+)|(\d+\.\d+.\d+.\d+)):\d{4}$/;
                let urlRegex = /^(https?:\/\/)?(www.)?([\da-zA-z\_\-\*]+)\.(com|(|[\da-zA-Z]{2,6}))([\/\w \.\-\#\&\?\%\_]*)?([^\/| |\s\.])$/gmi;
                if (url.indexOf("localhost") > -1 || ipPattern.test(url)) {
                    urlRegex = /^(https?:\/\/)?(www\.)?(([\da-zA-Z\-\_\*]+(\.[\da-zA-Z\-\_\*]+)*)|(\d{1,3}\.){3}\d{1,3})(\.([a-zA-Z]{2,6}|localhost))?(:\d{1,5})?([\/\w \.\-\#\&\?\%\_]*)?([^\/| |\s\.])$/gmi;
                }
                const isValidURL = urlRegex.test(url)
                if (!isValidURL) {
                    this.setState({
                        originErr: true
                    })
                    isValidated = false
                }
            })
        }
        return isValidated
    }
    generateKey = () => {

        const valid = this.props.language === "javascript" ? this.isValidOrigins() : true;
        if (valid) {
            this.props.generateClientAgentConfig && this.props.generateClientAgentConfig({
                type: "client",
                origin: this.props.language === "javascript" ? this.state.origin : ["sdk.middleware.io"],
                app_name: this.state.name,
                language: this.props.language,
                tracePropagationTargets: this.state.tracePropagationTargets ? this.state.tracePropagationTargets.map((r) => r.toString()): [],
            }, (status?: any, res?: any) => {
                if (status && res?.id > 0) {
                    this.props.showToast("success", "Application saved successfully")
                    const tokenObj: TokenData = {
                        id: res.id,
                        metaData: {
                            name: res?.meta_data?.RawMessage?.name,
                            origin: res?.meta_data?.RawMessage?.origin,
                            language: res?.meta_data?.RawMessage?.language,
                            tracePropagationTargets: res?.meta_data?.RawMessage?.tracePropagationTargets,
                        },
                        token: res.token
                    }
                    this.setState({
                        tokenData: tokenObj,
                    })
                }
            });
        }

    }
    editApplication = () => {
        const valid = this.props.language === "javascript" ? this.isValidOrigins() : true;
        if (valid) {
            this.props.updateClientAgentConfig && this.props.updateClientAgentConfig({
                token: this.state.tokenData?.token,
                origin: this.props.language === "javascript" ? this.state.origin : ["sdk.middleware.io"],
                app_name: this.state.name,
                tracePropagationTargets: this.state.tracePropagationTargets ? this.state.tracePropagationTargets.map((r) => r.toString()): [],
            }, (status?: any, res?: any) => {
                if (status) {
                    this.props.showToast("success", "Application updated successfully")
                    const tokenObj: TokenData = {
                        id: res.id,
                        metaData: {
                            name: res?.meta_data?.RawMessage?.name,
                            origin: res?.meta_data?.RawMessage?.origin,
                            language: res?.meta_data?.RawMessage?.language,
                            tracePropagationTargets: res?.meta_data?.RawMessage?.tracePropagationTargets
                        },
                        token: res.token
                    }
                    this.setState({
                        tokenData: tokenObj,
                    })
                }
            });
        }

    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any) {
        if (prevProps.token != this.props.token && this.props.token != undefined) {
            this.requestTokenDetail(this.props.token)
        }
    }

    handleCopyClick = (codeSnippet: any) => {
        navigator.clipboard.writeText(codeSnippet)
            .then(() => {
                this.props.showToast("success", "Copied to Clipboard !")

            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    render() {
        const origins = this.state.origin || []
        const val = origins.toString()
        const originalTraceUrls = this.state.tracePropagationTargets || []
        const traceUrls = originalTraceUrls.toString()
        const jsCommand = `<script src="https://cdnjs.middleware.io/browser/libs/0.0.1/middleware-rum.min.js" type="text/javascript"></script>`;
        const jsCommand2 = `<script>
        if (window.Middleware){
            Middleware.track({
                serviceName:"${this.state.tokenData?.metaData?.name}",
                projectName:"${this.state.tokenData?.metaData?.name}",
                accountKey:"${this.state.tokenData?.token}",
                target:"${window.location.origin}",
                tracePropagationTargets: [${this.state.tracePropagationTargets.length > 0 ? this.state.tracePropagationTargets.map((x) => new RegExp(x, "i")) : [/localhost:3000/i]}]
            })
        }
 </script>
`;
        const androidInstallationCommand = `implementation 'io.github.middleware-labs:android-sdk:+`
        const androidCommand =
            `import static io.middleware.android.sdk.utils.Constants.APP_VERSION;
            
import android.app.Application;

import java.time.Duration;

import io.middleware.android.sdk.Middleware;
import io.opentelemetry.api.common.Attributes;

public class MiddlewareApplication extends Application {
    private final String targetUrl = "${window.location.origin}";
    private final String rumAccessToken = "${this.state.tokenData?.token}";

    @Override
    public void onCreate() {
        super.onCreate();
    
        Middleware.builder()
            .setGlobalAttributes(Attributes.of(APP_VERSION, BuildConfig.VERSION_NAME))
            .setTarget(targetUrl)
            .setServiceName("${this.state.tokenData?.metaData?.name}")
            .setProjectName("${this.state.tokenData?.metaData?.name}")
            .setRumAccessToken(rumAccessToken)
            .setSlowRenderingDetectionPollInterval(Duration.ofMillis(1000))
            .setDeploymentEnvironment("PROD")
            .build(this);
}
    `;

        const iosInstallationCommand = `.package(url: "https://github.com/middleware-labs/middleware-ios"),`;
        const iosUseCommand = `.target(name: "YourApp", dependencies: [
    .product(name: "MiddlewareRum", package: "middleware-ios")
],`;
        const iosScript = `import MiddlewareRum

MiddlewareRumBuilder()
    .globalAttributes(["customerId" : "123456"])
    .target("${window.location.origin}")
    .serviceName("${this.state.tokenData?.metaData?.name}")
    .projectName("${this.state.tokenData?.metaData?.name}")
    .rumAccessToken("${this.state.tokenData?.token}")
    .deploymentEnvironment("PROD")
    .build()
        `;

        const reactNativeInstallation = `# yarn
yarn add @middleware.io/middleware-react-native`;

        const reactNativeScript = `import { MiddlewareWrapper, type ReactNativeConfiguration } from '@middleware.io/middleware-react-native';
        
const MiddlewareConfig: ReactNativeConfiguration = {
    serviceName: '${this.state.tokenData?.metaData?.name}',
    projectName: '${this.state.tokenData?.metaData?.name}',
    accountKey: '${this.state.tokenData?.token}',
    target: '${window.location.origin}',
    deploymentEnvironment: 'PROD',
    globalAttributes: {
        name: '${this.context.user.full_name}',
    },
};

export default function App() { 
    return (
      <MiddlewareWrapper configuration={MiddlewareConfig}>
        // Application Components
      </MiddlewareWrapper>
    );
  }
`;

        return <div className={'rum-installation-new'}>
            <div className={'update-user-side-panel setting_side-panel'}>
                {/*<div className={'panel-header'}>*/}
                {/*    <h5 className={'panel-title'}>{this.state.tokenData ? "Update Application" : "Create Application"}</h5>*/}
                {/*</div>*/}
                <div className={'panel-body'}>
                    {this.state.isLoading ? <Loader /> : <div className={'panel-wrapper'}>
                        <div className={'change-personal-info'}>
                            {/*<h5 style={{marginBottom: 10}}>Create Client Key</h5>*/}
                            <FormTextInput
                                id={"name"}
                                name={"name"}
                                label={"Application Name"}
                                value={this.state.name}
                                isError={this.state.nameErr}
                                onKeyUp={() => {
                                    if (this.state.name.length > 0) {
                                        this.setState({
                                            nameErr: false
                                        })
                                    } else {
                                        this.setState({
                                            nameErr: true
                                        })
                                    }
                                }}
                                onChange={(e) => {
                                    this.setState({
                                        name: e.target.value
                                    })
                                }}
                            />
                            {this.state.nameErr && <FormInputValidation isError text={"Name is required"} />}
                            {this.state?.tokenData?.metaData.language == "javascript" && (<>
                                <h6 className={"origins-heading"}>Add origin <span><i data-tip data-for={`tooltip_info`}><InfoIconSvg /></i></span>
                                </h6>
                                <Tooltip id={`tooltip_info`} place="top" type="dark" effect="solid">
                                    <div>You can add multiple origins by comma separating them.<br />Origin should match to validate data. </div>
                                </Tooltip>

                                <FormTextInput
                                    id={"origin"}
                                    name={"origin"}
                                    label={"Add Origin"}
                                    placeholder={"https://example.com,https://subdomain.example.com"}
                                    value={val}
                                    isError={this.state.originErr}
                                    onKeyUp={() => {
                                        if (val.length > 0) {
                                            this.setState({
                                                originErr: false
                                            })
                                        } else {
                                            this.setState({
                                                originErr: true
                                            })
                                        }
                                    }}
                                    onChange={(e) => {
                                        const value = e.target.value
                                        const origin_array = value.trim().split(',');
                                        this.setState({
                                            origin: origin_array
                                        })
                                    }}
                                />
                            </>)}
                            {this.state.originErr && <FormInputValidation isError text={"Valid origin is required without trailing slash or dot"} />}
                            {this.state?.tokenData?.metaData.language == "javascript" && (<><h6 className={"origins-heading"}>Trace Propagation Targets<span><i data-tip data-for={`tooltip_info`}><InfoIconSvg /></i></span>
                            </h6>
                                <Tooltip id={`tooltip_info`} place="top" type="dark" effect="solid">
                                    <div>You can add multiple trace domain names by comma separating them.<br />Trace will be generated only for specified domains. For example: https://api.example.com, http://localhost:3000. </div>
                                </Tooltip>
                                <FormTextInput
                                    id={"tracePropagationTargets"}
                                    name={"tracePropagationTargets"}
                                    label={"URLs"}
                                    placeholder={"https://api.example.com, http://localhost:3000"}
                                    value={traceUrls}
                                    onChange={(e) => {
                                        const value = e.target.value
                                        const traceUrls = value.trim().split(',');
                                        this.setState({
                                            tracePropagationTargets: traceUrls
                                        })
                                    }}
                                />
                            </>)}
                            {this.state?.tokenData?.metaData.language == "javascript" ? (
                                <Button
                                    disabled={origins.length < 1 || this.state.originErr || this.state.name.length < 1}
                                    onClick={this.state.tokenData ? this.editApplication : this.generateKey}
                                    primary>{this.state.tokenData ? "Update Application" : "Create Application"}</Button>
                            ) : (
                                <Button
                                    disabled={this.state.originErr || this.state.name.length < 1}
                                    onClick={this.state.tokenData ? this.editApplication : this.generateKey}
                                    primary>{this.state.tokenData ? "Update Application" : "Create Application"}</Button>
                            )}
                            {this.state.tokenData && <>
                                {this.state.tokenData.metaData.language == "javascript" && (
                                    <div>
                                        <p>1. Add the following script tag to the head section of your index.html file.</p>
                                        <div className={"installation-page rum-script"}>
                                            <CodeViewer showLineNumbers={false}>
                                                {jsCommand}
                                            </CodeViewer>
                                            <span className={"rum-copy-btn"} onClick={() => this.handleCopyClick(jsCommand)}>
                                                <CopyIconNew size={8} />
                                            </span>
                                        </div>
                                        <p>2. Place below script tag in the head section of your index.html file.</p>

                                        <div className={"installation-page rum-script"}>
                                            <CodeViewer showLineNumbers={false}>
                                                {jsCommand2}
                                            </CodeViewer>
                                            <span className={"rum-copy-btn"} onClick={() => this.handleCopyClick(jsCommand2)}>
                                                <CopyIconNew size={8} />
                                            </span>
                                        </div>
                                    </div>

                                )}
                                {this.state.tokenData.metaData.language === "android" && (
                                    <div>
                                        <p>1. Add the following dependency in build.gradle file.</p>
                                        <div className={"installation-page rum-script"}>
                                            <CodeViewer language="groovy" showLineNumbers={false}>
                                                {androidInstallationCommand}
                                            </CodeViewer>
                                            <span className={"rum-copy-btn"} onClick={() => this.handleCopyClick(androidInstallationCommand)}>
                                                <CopyIconNew size={8} />
                                            </span>
                                        </div>
                                        <p>2. Place below class file in your Android application. This will instantiate Middleware SDK.</p>

                                        <div className={"installation-page rum-script"}>
                                            <CodeViewer language="java" showLineNumbers={false}>
                                                {androidCommand}
                                            </CodeViewer>
                                            <span className={"rum-copy-btn"} onClick={() => this.handleCopyClick(androidCommand)}>
                                                <CopyIconNew size={8} />
                                            </span>
                                        </div>
                                    </div>

                                )}
                                {this.state.tokenData.metaData.language === "ios" && (
                                    <div>
                                        <p>1. Add the following dependencies section in <code>Package.swift</code> file.</p>
                                        <div className={"installation-page rum-script"}>
                                            <CodeViewer language="swift" showLineNumbers={false}>
                                                {iosInstallationCommand}
                                            </CodeViewer>
                                            <span className={"rum-copy-btn"} onClick={() => this.handleCopyClick(iosInstallationCommand)}>
                                                <CopyIconNew size={8} />
                                            </span>
                                        </div>
                                        <p>2. To use Middleware SDK add the following in <code>targets</code> in <code>Package.swift</code> file.</p>
                                        <div className={"installation-page rum-script"}>
                                            <CodeViewer language="swift" showLineNumbers={false}>
                                                {iosUseCommand}
                                            </CodeViewer>
                                            <span className={"rum-copy-btn"} onClick={() => this.handleCopyClick(iosUseCommand)}>
                                                <CopyIconNew size={8} />
                                            </span>
                                        </div>
                                        <p>3. Add below code file in your iOS application. This will instantiate Middleware SDK.</p>
                                        <div className={"installation-page rum-script"}>
                                            <CodeViewer language="swift" showLineNumbers={false}>
                                                {iosScript}
                                            </CodeViewer>
                                            <span className={"rum-copy-btn"} onClick={() => this.handleCopyClick(iosScript)}>
                                                <CopyIconNew size={8} />
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {this.state.tokenData.metaData.language === "react-native" && (
                                    <div>
                                        <p>1. Install the library using either npm or yarn.</p>
                                        <div className={"installation-page rum-script"}>
                                            <CodeViewer language="shell" showLineNumbers={false}>
                                                {reactNativeInstallation}
                                            </CodeViewer>
                                            <span className={"rum-copy-btn"} onClick={() => this.handleCopyClick(reactNativeInstallation)}>
                                                <CopyIconNew size={8} />
                                            </span>
                                        </div>
                                        <p>2. Initialize the library in your app lifecycle.</p>
                                        <div className={"installation-page rum-script"}>
                                            <CodeViewer language="typescript" showLineNumbers={false}>
                                                {reactNativeScript}
                                            </CodeViewer>
                                            <span className={"rum-copy-btn"} onClick={() => this.handleCopyClick(reactNativeScript)}>
                                                <CopyIconNew size={8} />
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </>}
                        </div>
                    </div>}
                </div>
                {/*<div className={'panel-footer bottom-buttons'}>*/}
                {/*    <Button secondary onClick={this.closeDialog}>Discard</Button>*/}
                {/*</div>*/}
            </div>
        </div>
    }
}

const mapStateTopProps = (state: any, props: any) => {
    return {
        themeMode: getThemesMode(state),
        tokenDetail: getAgentTokenDetail(state)
    }
}
RumSettingDialog.contextType = AppContext;

export default connect(mapStateTopProps, {
    generateClientAgentConfig,
    updateClientAgentConfig,
    showToast,
    requestAgentTokenDetail,
})(RumSettingDialog)