import React, { useState } from 'react';
import "./_style.scss";
import { formatLatency } from "views/modules/apm/utils";
import { generateHashAndMetricColor } from "views/modules/helper";
import moment from "moment";

const CollapsibleTable = ({ data, updateAttributes }: any) => {
  // @ts-ignore
    const [openRows, setOpenRows]: any = useState([...Array(data.length).keys()]);

    const toggleRow = (index: number) => {
        setOpenRows((prevOpenRows: any) => {
            const isOpen = prevOpenRows.includes(index);
            return isOpen
                ? prevOpenRows.filter((rowIndex: number) => rowIndex !== index)
                : [...prevOpenRows, index];
        });
    };

    return (
        <div className="collapsible-table">
            <div className={"header-row"}>
                <div className={"span-list-cell cell-name"}>Service Name</div>
                <div className={"span-list-cell cell-span-count"}>Spans</div>
                <div className={"span-list-cell cell-span-count"}>Date</div>
                <div className={"span-list-cell cell-avg-duration"}>Avg Duration</div>
                <div className={"span-list-cell cell-exec-time"}>Exec Time</div>
                <div className={"span-list-cell cell-exec-percentage"}>Exec %</div>
            </div>
            <div className={"main-span-body"}>
                {data.map((service: any, index: number) => (
                    <React.Fragment key={index}>
                        <div
                            onClick={() => toggleRow(index)}
                            className={`main-row ${openRows.includes(index) ? 'open' : ''}`}
                        >
                            <div className={"span-list-cell cell-name"}>
                                {openRows.includes(index) ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                                                                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="chevron">
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                                              stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="chevron">
                                    <polyline points="9 18 15 12 9 6"></polyline>
                                </svg>}

                                <span
                                    style={{
                                        backgroundColor: `${generateHashAndMetricColor(service.service_name || '').color}`,
                                        borderRadius: "10px",
                                        padding: "2px",
                                        marginRight: "5px",
                                        height: "20px"
                                    }}
                                ></span>

                                {service.service_name}
                            </div>
                            <div className={"span-list-cell cell-span-count"}>{service.span_count}</div>
                            <div className={"span-list-cell cell-span-count"}>{moment(service.timestamp / 1000000).format("YYYY-MM-DD HH:mm:ss")}</div>
                            <div className={"span-list-cell cell-avg-duration"}>{formatLatency(service.avg_duration)}</div>
                            <div className={"span-list-cell cell-exec-time"}>{formatLatency(service.execution_time)}</div>
                            <div className={"span-list-cell cell-exec-percentage"}>{service.execution_time_percentage}</div>
                        </div>
                        {openRows.includes(index) && (
                            <div>
                                {service.children.map((child: any, childIndex: number) => (
                                    <div key={childIndex} className="child-row" onClick={() => updateAttributes(child)}>
                                        <div className={"span-list-cell cell-name"}><span
                                            style={{marginLeft: "18px"}}>{child.name}</span></div>
                                        <div className={"span-list-cell cell-span-count"}>
                                            <span>{child.span_count}</span></div>
                                        <div
                                            className={"span-list-cell cell-span-count"}>{moment(service.timestamp / 1000000).format("YYYY-MM-DD HH:mm:ss")}</div>
                                        <div className={"span-list-cell cell-avg-duration"}>
                                            <span>{formatLatency(child.avg_duration)}</span></div>
                                        <div className={"span-list-cell cell-exec-time"}>
                                            <span>{formatLatency(service.execution_time)}</span></div>
                                        <div className={"span-list-cell cell-exec-percentage"}>
                                            <span>{child.execution_time_percentage}</span></div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default CollapsibleTable;
