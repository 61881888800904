import * as React from "react";
import "./_style.scss";
import { RoutesPatterns } from "core/utils/route_schema";

const DashboardRoutes: RoutesPatterns = {
  moduleName: "dashboard",
  slug: "/dashboards/unified-dashboard",
  breadcrumbs: React.lazy(() => import("views/modules/dashboard/breadcrumbs")),
  items: [
    {
      pattern: ["/"],
      component: React.lazy(() => import("views/modules/dashboard/index")),
      breadcrumbTitle: "Unified View",
    },
  ],
};
export default DashboardRoutes;
