import { SeriesOptionsType } from "highcharts";
import moment from "moment";
import React from "react";
import { DateRange } from "views/layouts/app/routes/model";
import ChartV2 from "..";
import { getMetricDetails } from "../../../../../views/modules/builder/common/utils";
import {
  BuilderNestedProps,
  GridviewDataType,
  ResourceViewOptions,
} from "../../../../../views/modules/builder/entities/builder.entities";
import {
  RenderTpl,
  getValueFromConfig,
} from "../../../../../views/modules/builder/entities/tpl.entities";
import NoDataSpace from "../../no-data";
import { ChartType, DEFAULT_CHART_HEIGHT } from "../models";
export interface TopListChartProps {
  toplistData: GridviewDataType | undefined;
  nestedProps?: BuilderNestedProps;
  resource?: ResourceViewOptions;
  onDateChange?: (range: DateRange) => void;
}
const TopListChart = (props: TopListChartProps) => {
  const { toplistData, nestedProps, onDateChange } = props;
  const columnConfig = nestedProps?.columnConfig;
  if (!toplistData) return null;
  let maxValue = 0;
  if (!toplistData.data.length) {
    //TODO: Implement inflight
    return <NoDataSpace />;
  }
  const metrics = toplistData.columns.filter((col) => col.isMetric);
  const groups = toplistData.columns.filter((col) => !col.isMetric);
  const data = toplistData.data
    .map((item) => {
      const keys = Object.keys(item);
      let title = "";
      let value = 0;
      let configType: RenderTpl | undefined;
      keys.forEach((key) => {
        const isMetric = metrics.find((col) => col.accessor == key);
        if (isMetric) {
          const config = columnConfig?.[key];
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          value = item[key];
          if (typeof value === "string") value = parseFloat(value);
          configType = config?.tpl ?? isMetric.tpl;
          if (groups.length === 0 && config?.title) {
            title = config.title || key;
          } else if (keys.length == 1) {
            title = getMetricDetails(key).metricName;
          }
        } else {
          if (!title) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            title = item[key];
          } else {
            if (item[key]) {
              title += " - " + item[key];
            }
          }
        }
      });
      if (maxValue < value) maxValue = value;
      return {
        title,
        value,
        barWid: 0,
        configType,
      };
    })
    .sort((a, b) => b.value - a.value);
  data.forEach((item) => {
    item.barWid = (item.value * 100) / maxValue;
  });
  const values = [];
  const xAxsisCtagory: string[] = [];
  let configType: RenderTpl | undefined = undefined;
  if (data?.length) {
    for (const element of data) {
      values.push(element.value);
      xAxsisCtagory.push(element.title);
      if (element.configType) {
        configType = element.configType;
      }
    }
  }
  let color: string | undefined = "";
  if (
    props.nestedProps?.columnConfig &&
    Object.keys(props.nestedProps?.columnConfig).length
  ) {
    color =
      props.nestedProps?.columnConfig[
        Object.keys(props.nestedProps?.columnConfig)[0]
      ]?.color;
  }
  const seriesData: SeriesOptionsType[] = [
    {
      type: "bar",
      pointWidth: 12,
      groupPadding: 0,
      pointPadding: 1,
      showInLegend: false,
      name: "top-list",
      dataLabels: [
        {
          useHTML: true,
          formatter: function () {
            return `<div>${configType ? getValueFromConfig(this.y!, configType) : this.y}</div>`;
          },
        },
      ],
      color: color,
      data: [...values],
    },
  ];
  return (
    <div className={"builder-top_list_chart_wrapper"}>
      <ChartV2
        isZooming={false}
        chartType={ChartType.BAR}
        series={seriesData}
        yAxsisVisible={false}
        showCrossHair={false}
        xAxsisVisible={false}
        yAxisConfig={configType}
        stacked={true}
        showLegend={false}
        resource={props?.resource}
        xAxsisCategory={xAxsisCtagory}
        xType="category"
        chartHeight={
          props.nestedProps?.chartHeight
            ? props.nestedProps?.chartHeight
            : DEFAULT_CHART_HEIGHT.HEIGHT_150
        }
        xAxisFormatter={function (ctx: { value: moment.MomentInput }) {
          return moment(ctx.value).format("HH:mm:ss");
        }}
        onDateChange={onDateChange}
      />
    </div>
  );
};
export default TopListChart;
