import { get } from "lodash-es";
import { createSelector } from "reselect";

interface GlobalRefresh {
  refresh: boolean;
}

export const getThemesMode = createSelector(
  (state: any) => {
    const token = get(state, ["auth_token"], null);
    if (!token) return "light";
    return get(state, ["theme", "mode"], "light");
  },
  (mode: string) => mode
);

export const getRouteState = createSelector(
  (state: any) => {
    return get(state, ["routes"], null);
  },
  (resp: any) => resp
);

export const getAuthToken = createSelector(
  (state: any) => get(state, ["auth_token"], null),
  (token) => token
);

export const getGlobalRefresh = createSelector(
  (state: GlobalRefresh) =>
    get(state, ["globalRefresh"], {
      refresh: false,
    }) as GlobalRefresh,
  (gr) => gr
);
