import {
  AddDataSourceIcon,
  GoToActionIcon,
} from "core/components/v2/svg/icons";
import React from "react";
import "./_style.scss";
import { AddWidgetCardProps } from "./entities";

export default function AddFromDSCard({ onCardClick }: AddWidgetCardProps) {
  return (
    <div
      className="add-widget-action-card"
      onClick={(e) => {
        e.stopPropagation();
        onCardClick();
      }}
    >
      <AddDataSourceIcon />
      <span className="button-text">
        Add from Data Source <br />
        or existing Dashboard
      </span>
      <GoToActionIcon />
    </div>
  );
}
