import React from 'react';
import "./_style.scss";
import {formatLatency} from "views/modules/apm/utils";
interface FlameGraphTicksProps {
    totalDuration: number;
    numTicks: number;
    parentWidth: number;
}

const FlameGraphTicks: React.FC<FlameGraphTicksProps> = ({ totalDuration, numTicks, parentWidth }) => {
    const tickValues = Array.from({ length: numTicks + 1 }, (_, index) => (index * totalDuration) / numTicks);

    return (
        <div className="flame-graph-ticks" style={{ width: `${parentWidth}px`, marginBottom: "2px" }}>
            <div className="tick-line" />
            {tickValues.map((tick, index) => (
                <div key={index} className="flame-graph-tick" style={{ left: `${(tick * parentWidth) / totalDuration}px` }}>
                    <div className={"tick-dash"}></div>
                    <div className="tick-label">{`${formatLatency(tick)}`}</div>
                </div>
            ))}
        </div>
    );
};

export default FlameGraphTicks;
