import { getTenantUrl } from "core/application/utils";
import FormTextInput from "core/components/v2/form/form-text-input";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import Command from "../../../components/code-block/command";
import StepItem from "../../../components/steps/step-item";
import APMAgentConnect from "../../common/apm-agent-connect";
import ViewYourData from "../../common/view-data-buttons";

interface ContentProps {
  serverless: string;
}

const Content: React.FC<ContentProps> = ({ serverless }) => {
  const [appEnv, setAppEnv] = useState<number>(1);
  const [appBehaviour, setAppBehaviour] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];

  interface StepFormat {
    title: string;
    content: React.ReactNode;
  }

  const [activeServiceName, setActiveServiceName] = useState("");
  const sendEvent = useMixPanel();
  const steps: StepFormat[] = [
    {
      title: "Download JAR File:",
      content: (
        <>
          <br />
          Download the most recent Middleware Java Agent jar file from the{" "}
          <a
            href="https://github.com/middleware-labs/opentelemetry-java-instrumentation/releases"
            target="_blank"
            rel="noreferrer"
          >
            Github Release Page
          </a>
          .
          <br />
          <br />
        </>
      ),
    },
    {
      title: "Update your `build.sbt` file",
      content: (
        <>
          <div className="apm-service-name-block">
            <FormTextInput
              id="scala-service-text-input"
              // label={"Service Name"}
              className="apm-service-name-input"
              placeholder={"Service Name"}
              value={activeServiceName}
              onChange={(e: any) => setActiveServiceName(e)}
            />
          </div>
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Scala",
                command_type: "Update your `build.sbt` file",
              });
            }}
            command={`lazy val root = (project in file("."))
.settings(
  ...
  (run / javaOptions) ++= Seq(
    "-javaagent:middleware-javaagent-1.4.0.jar",
    "-Dotel.service.name=${activeServiceName == "" ? "{APM-SERVICE-NAME}" : activeServiceName}"
  )
  ...
)`}
            className="margin-1"
          />
        </>
      ),
    },
    {
      title: "Run Your App",
      content: (
        <>
          Run the following command in your terminal to start your application
          <br />
          <br />
          <Command
            command={`MW_API_KEY=${context.user?.account?.agent_config?.token} sbt run`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Scala",
                command_type: "Run Your App",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Enable Custom Logs (Optional)",
      content: (
        <>
          Custom logs are ones that the application does not produce by default.
          They may be generated within certain methods, or managed by a central
          logging method in your application. To get started with custom logs
          add the following code: <br />
          <br />
          <Command
            command={`<dependency>
  <groupId>io.github.middleware-labs</groupId>
  <artifactId>agent-apm-java</artifactId>
  <version></version>
</dependency>`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Scala",
                command_type: "Enable Custom Logs (Optional)",
              });
            }}
          />
          <Command
            command={`mvn install`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Scala",
                command_type: "Enable Custom Logs (Optional)",
              });
            }}
          />
          Import logger package:
          <br />
          <br />
          <Command
            command={`import io.github.middlewarelabs.agentapmjava.Logger;`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Scala",
                command_type: "Enable Custom Logs (Optional)",
              });
            }}
          />
          Use the following functions for logging with different severity
          levels:
          <br />
          <br />
          <Command
            command={`Logger.info("info message");
Logger.debug("debug message");
Logger.warn("warn message");
Logger.error("error message");`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Scala",
                command_type: "Enable Custom Logs (Optional)",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Enable Stack Errors",
      content: (
        <>
          Use the errorRecord method to record stack traces that throw an error
          object:
          <br />
          <br />
          <Command
            command={`try {
    ...
} catch (Exception e) {
    ...
    Logger.recordError(e);
    ...
}`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Scala",
                command_type: "Enable Stack Errors",
              });
            }}
          />
        </>
      ),
    },
  ];

  let indexOffset = 0;
  if (serverless != "true") {
    indexOffset = 2;
  }

  return (
    <>
      {serverless != "true" && <APMAgentConnect agentName="Scala" />}
      {steps.map((step, index) => (
        <StepItem stepNumber={index + indexOffset + 1} title={step.title}>
          {step.content}
        </StepItem>
      ))}
      <ViewYourData
        language="Scala"
        stepNumber={steps.length + indexOffset + 1}
      />
    </>
  );
};
export default Content;
