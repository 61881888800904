import * as React from "react";
import "./_style.scss";
import {RoutesPatterns} from "core/utils/route_schema";

const OnboardingDashboardRoutes: RoutesPatterns = {
    moduleName: "onboarding-dashboard",
    slug: "/dashboard",
    breadcrumbs: React.lazy(() => import("views/modules/onboarding-dashboard/breadcrumbs")),
    items: [
        {
            pattern: [
                "/",
            ],
            component: React.lazy(() => import("views/modules/onboarding-dashboard/index")),
            breadcrumbTitle: "Welcome to Middleware",
        }
    ]
}
export default OnboardingDashboardRoutes