import React, {useContext, useRef, useState} from "react";
import {showToast} from "store/toast-alerts/actions";
import {useDispatch} from "react-redux";
import NavTab from "core/components/nav-tab";
import TabItems from "core/components/nav-tab/items";
import TableGridview, {GridviewColumnsOptions} from "core/components/table-gridview";
import AppContext, {ContextProps} from "core/components/wrapper/context";

export default function OpenTelemetryLogs() {
    const context = useContext<ContextProps>(AppContext);

    const [codeCollaps, setcodeCollaps] = useState();
    const toggleCodeVisibility = () => {
        // @ts-ignore
        setcodeCollaps(!codeCollaps);
    };
    const dispatch = useDispatch();

    function copyToClipboard(text: string) {

        navigator.clipboard.writeText(text)
            .then(() => {
                dispatch(showToast("success", "Copied to Clipboard !"))
            });
    }


    const dataProvider = [
        {
            key: "mw.account_key",
            compliance: "required",
            description: 'Will be required to authenticate your data with your Middleware account',
        },
        {
            key: "mw.resource_type",
            compliance: "required",
            description: 'Will be required to label the log source as "custom"',
        },
        {
            key: "service.name",
            compliance: "optional",
            description: 'Can be helpful to filter the data  in "Logs" module',
        },

    ]

    const columns: Array<GridviewColumnsOptions> = [
        {
            title: "Key",
            accessor: "key",
            value: (data: any) => {
                return data.key
            }
        },
        {
            title: "Compliance Level",
            accessor: "compliance",
            value: (data: any) => {
                return data.compliance
            }
        },
        {
            title: "Description",
            accessor: "description",
            value: (data: any) => {
                return data.description
            }
        },
    ]

    const currentDate = new Date();
    const unixTime = Math.floor(currentDate.getTime() / 1000) + "000";

    const customAPICodeCommon = `curl -X POST "${window.location.origin}:443/v1/logs\" \\\n` +
        "-H \"Accept: application/json\" \\\n" +
        "-H \"Content-Type: application/json\" \\\n" +
        "-d @- << EOF\n" +
        "{\n" +
        "  \"resource_logs\": [\n" +
        "    {\n" +
        "      \"resource\": {\n" +
        "        \"attributes\": [\n" +
        "......"


    const customAPICode = `curl -X POST "${window.location.origin}:443/v1/logs\" \\\n` +
        "-H \"Accept: application/json\" \\\n" +
        "-H \"Content-Type: application/json\" \\\n" +
        "-d @- << EOF\n" +
        "{\n" +
        "  \"resource_logs\": [\n" +
        "    {\n" +
        "      \"resource\": {\n" +
        "        \"attributes\": [\n" +
        "          {\n" +
        "            \"key\": \"mw.account_key\",\n" +
        "            \"value\": {\n" +
        `              \"string_value\": \"${context.user?.account?.agent_config?.token}\"\n` +
        "            }\n" +
        "          },\n" +
        "          {\n" +
        "            \"key\": \"mw.resource_type\",\n" +
        "            \"value\": {\n" +
        "              \"string_value\": \"custom\"\n" +
        "            }\n" +
        "          },\n" +
        "          {\n" +
        "            \"key\": \"service.name\",\n" +
        "            \"value\": {\n" +
        "              \"string_value\": \"nginx-123\"\n" +
        "            }\n" +
        "          }\n" +
        "        ]\n" +
        "      },\n" +
        "      \"scope_logs\": [\n" +
        "        {\n" +
        "          \"log_records\": [\n" +
        "            {\n" +
        "              \"severity_text\": \"WARN\",\n" +
        "              \"body\": {\n" +
        "                  \"string_value\": \"upstream server is not accepting request\"\n" +
        "              },\n" +
        "              \"severity_number\": 11,\n" +
        "              \"attributes\": [\n" +
        "                 {\n" +
        "                   \"key\": \"server\",\n" +
        "                   \"value\": {\n" +
        "                     \"string_value\": \"nginx\"\n" +
        "                   }\n" +
        "                 }\n" +
        "              ],\n" +
        `              \"time_unix_nano\": ${unixTime}\n` +
        "            }\n" +
        "          ]\n" +
        "        }\n" +
        "      ]\n" +
        "    }\n" +
        "  ]\n" +
        "}\n" +
        "EOF"
    return (
        <>
            <div className={"markdown-body aws-kiness-doc"}>
                <div className={"node-tab"}>
                    <div className={"steps"}>
                        <div className={"details"}>
                            <div className={"count"}>
                                <span>1</span>
                            </div>
                            <div className={"detail"}>
                                Post your custom data
                            </div>
                        </div>
                        <p className={'fn-size'}>You can send custom logs to Middleware Backend Using the API given
                            below.</p>
                        <p className={'notice'}>POST {window.location.origin}:443/v1/logs</p>
                        {codeCollaps && (
                            <pre onClick={() => {
                                copyToClipboard(customAPICode)
                            }} className={'command'}>
                                <code>{customAPICode}</code>
                            </pre>
                        )}
                        {!codeCollaps && (
                            <pre onClick={() => {
                                copyToClipboard(customAPICode)
                            }} className={'command'}>
                                <code>{customAPICodeCommon}</code>
                            </pre>

                        )}
                        <div className={'collaps-expand'}>{codeCollaps ?
                            <button className={'collaps-btn'} onClick={toggleCodeVisibility}>Collapse curl
                                request</button> :
                            <button className={'expand-btn'} onClick={toggleCodeVisibility}>Expand curl
                                request</button>}
                        </div>
                    </div>
                    <p className={'fn-size'}>You can refer this sample curl request to understand how to ingest custom
                        logs to Middleware, Request Body fields are explained below.</p>
                    <div className={'bkoff-opentelemetry-table'}>
                        <NavTab defaultActiveTabIndex={0}>
                            <TabItems title={'Understanding Resource Attributes'}>
                                <p className={'fn-size'}>(resource_logs {">"} resource {">"} attributes)</p>
                                <TableGridview
                                    columns={columns}
                                    dataProvider={dataProvider}
                                />
                            </TabItems>
                            <TabItems title={'Understanding Log Records'}>
                                <p className={'fn-size'}>(resource_logs {">"} scope_logs {">"} log_records)</p>
                                <div className={'tabs-active-content tab_content__undefined'}>
                                    <div className={'table-gridview__components'}>
                                        <table className={'head__sticky'}>
                                            <thead>
                                            <tr>
                                                <th>Key</th>
                                                <th>Compliance Level</th>
                                                <th>Description</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>severity_text</td>
                                                <td>optional</td>
                                                <td>To label log severity with one of these : FATAL, ERROR, WARN, INFO,
                                                    DEBUG, TRACE
                                                </td>
                                            </tr>
                                            <tr>
                                                <td rowSpan={7}>severity_number</td>
                                                <td rowSpan={7}>optional</td>
                                            </tr>
                                            <tr>
                                                <td>1-4 : TRACE</td>
                                            </tr>
                                            <tr>
                                                <td>5-8 : DEBUG</td>
                                            </tr>
                                            <tr>
                                                <td>9-12 : INFO</td>
                                            </tr>
                                            <tr>
                                                <td>13-16 : WARN</td>
                                            </tr>
                                            <tr>
                                                <td>17-20 : ERROR</td>
                                            </tr>
                                            <tr>
                                                <td>21-24 : FATAL</td>
                                            </tr>
                                            <tr>
                                                <td>body</td>
                                                <td>required</td>
                                                <td>The actual log body that you want to ingest</td>
                                            </tr>
                                            <tr>
                                                <td>time_unix_nano</td>
                                                <td>required</td>
                                                <td>For setting a timestamp for your log</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </TabItems>
                        </NavTab>
                    </div>
                </div>
            </div>
        </>
    )
}