import React, {useContext, useState} from "react";
import {showToast} from "store/toast-alerts/actions";
import {useDispatch} from "react-redux";
import {
    AlertsIntegrationIcon,
    ContainerizedIcon, CopyIconNew,
    DebAndUbuntuColorSvg, DockerColorSvg, KubernetesColorSvg, NativelyIcon
} from "core/components/svg/icons";
import CodeViewer from "core/components/code-viewer";
import AppContext, {ContextProps} from "core/components/wrapper/context";
import Button from "core/components/button";
import CommonAgentInstallation from "views/modules/installation/components/apm/common-agent-installation";
import FormTextInput from "core/components/form/form-text-input";
import Warning from "views/modules/installation/common/warning";
import {StepsEntity, StepsEnum} from "views/modules/integrations/entity";


export default function PhpAPM() {
    const context = useContext<ContextProps>(AppContext);
    const isHelloBarRendered = document.querySelector('.hello-bar') !== null;

    const dispatch = useDispatch();

    function copyToClipboard(text: string) {

        navigator.clipboard.writeText(text)
            .then(() => {
                dispatch(showToast("success", "Copied to Clipboard !"))
            });
    }


    const [activeServiceName, setActiveServiceName] = useState('{APM-SERVICE-NAME}');

    const ApmLogginSnap = "$tracker->warn(\"this is warning log.\"); \n" +
        "$tracker->error(\"this is error log.\"); \n" +
        "$tracker->info(\"this is info log.\"); \n" +
        "$tracker->debug(\"this is debug log.\");\n"

    const PhpApmSnap = `$tracker = new MwTracker('${activeServiceName == '' ? "{APM-SERVICE-NAME}" : activeServiceName}'); \n` +
        `$tracker->preTrack(); \n \n` +
        `$tracker->registerHook('<CLASS-NAME-1>', '<FUNCTION-NAME-1>', [ \n` +
        "  'custom.attr1' => 'value1', \n" +
        "  'custom.attr2' => 'value2', \n" +
        `]); \n` +
        `$tracker->registerHook('<CLASS-NAME-2>', '<FUNCTION-NAME-2>'); \n`

    const ApmLogginSnap2 = "require 'vendor/autoload.php';\n" +
        "use Middleware\\AgentApmPhp\\MwTracker;"


    const steps: StepsEntity = {
        deployment_type: {
            title: "Choose Deployment Type",
        },
        code: {
            title: "Getting Started with Code",
        },
        custom_logs: {
            title: "Setup Custom Logs",
        },
        see_data: {
            title: "View Your Data",
        }
    }
    const [activeStep, setActiveStep] = useState(StepsEnum.DeploymentType);
    const [activeDeployment, setActiveDeployment] = useState('kubernetes');
    const [activeAppType, setActiveAppType] = useState('containerized');
    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const MwAgentServiceK8s = "MW_AGENT_SERVICE=mw-service.mw-agent-ns.svc.cluster.local\n"
    const MwAgentServiceLinuxDocker = "MW_AGENT_SERVICE=172.17.0.1"


    return (
        <>
            <div className={"markdown-body installation-docs apm-installation-doc php-doc"}>
                <div className={"apm-body"}>
                    <div className={"apm-steps"}>
                        {
                            steps && Object.keys(steps).map((step, index) => {
                                return <div key={index + 1}
                                            className={`step ${activeStep === step ? 'selected' : ''} ${(Object.keys(steps).indexOf(activeStep) || 0) > index ? 'passed' : ''}`}>
                                    <div className={"count"}>
                                        <span>{index + 1}</span>
                                    </div>
                                    <div className={"detail"} onClick={() => setActiveStep(step)}>
                                        {steps[step].title}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className={`apm-content ${!isHelloBarRendered && "hello-bar-exists"}`}>
                        {activeStep === StepsEnum.DeploymentType && <>
                            <div className={"workspace"}>
                                <div className={"blocks"}>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>1. Our APMs require the host agent in order to operate. To install a host agent please select from the below options. </span>
                                            <p><span>(If the host already has an agent running please <span
                                                onClick={() => setActiveStep('code')}> skip to Getting Started with Code.</span>)</span>
                                            </p>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Run only one MW Agent. Multiple agents will cause unexpected behavior.</span>
                                            <br/>
                                            <span>Which platform are you hosting your PHP application?</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'button-flex'}>
                                                <button className={activeDeployment === 'kubernetes' ? 'active' : ''}
                                                        onClick={() => {
                                                            setActiveDeployment('kubernetes')
                                                            setActiveAppType('containerized')
                                                        }}>
                                                    <KubernetesColorSvg/> Kubernetes
                                                </button>
                                                <button className={activeDeployment === 'linux' ? 'active' : ''}
                                                        onClick={() => setActiveDeployment('linux')}>
                                                    <DebAndUbuntuColorSvg/> Linux
                                                </button>
                                                <button className={activeDeployment === 'docker' ? 'active' : ''}
                                                        onClick={() => setActiveDeployment('docker')}>
                                                    <DockerColorSvg/> Docker
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>2. Application Behaviour Will Be</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Please choose from below, as your application will run accordingly.</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'button-flex'}>
                                                <button className={activeAppType === 'containerized' ? 'active' : ''}
                                                        onClick={() => setActiveAppType('containerized')}>
                                                    <ContainerizedIcon/> Containerized
                                                </button>
                                                <button
                                                    className={`${activeAppType === 'natively' ? 'active' : ''} ${activeDeployment === 'kubernetes' ? 'disabled' : ''}`}
                                                    onClick={() => {
                                                        if (activeDeployment !== 'kubernetes') {
                                                            setActiveAppType('natively')
                                                        }
                                                    }}>
                                                    <NativelyIcon/> Natively
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {activeAppType === 'containerized' && <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>3. Setup Container Variable</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Application running in a container require an additional environment variable</span>
                                        </div>
                                        <div className={"content"}>
                                            {activeDeployment === 'kubernetes' && <>
                                                <div className={'docs-script'}>
                                                    <CodeViewer showLineNumbers={false}
                                                                language={'java'}>{MwAgentServiceK8s}</CodeViewer>
                                                    <span className={"doc-copy-btn"}
                                                          onClick={() => copyToClipboard(MwAgentServiceK8s)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                                </div>
                                                <span className={"sentence mini"}>The default namespace for running the Middleware agent is <strong>mw-service.mw-agent-ns.svc.cluster.local</strong>.</span><br/>
                                            </>}
                                            {activeDeployment !== 'kubernetes' && <>
                                                <div className={'docs-script'}>
                                                    <CodeViewer showLineNumbers={false}
                                                                language={'java'}>{MwAgentServiceLinuxDocker}</CodeViewer>
                                                    <span className={"doc-copy-btn"}
                                                          onClick={() => copyToClipboard(MwAgentServiceLinuxDocker)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                                </div>
                                                <span
                                                    className={"sentence mini"}>The default value for <strong>DOCKER_BRIDGE_GATEWAY_ADDRESS</strong> is typically <strong>172.17.0.1</strong>.</span><br/>
                                                <span className={"sentence mini"}>For more detailed information, refer to the official Docker&nbsp;
                                                    <a href={"https://docs.docker.com/network/network-tutorial-standalone/"}
                                                       target={"_blank"}>documentation</a>.</span>
                                            </>}
                                        </div>
                                    </div>}
                                    <div className={"block"}>
                                        <CommonAgentInstallation activeDeployment={activeDeployment}
                                                                 activeAppType={activeAppType}/>
                                    </div>
                                </div>

                            </div>
                            <div className={"buttons-group"}>
                                <Button className={"disabled"}>Back</Button>
                                <Button primary onClick={() => setActiveStep('code')}>Next</Button>
                            </div>
                        </>}

                        {activeStep === 'code' && <>
                            <div className={"workspace"}>
                                <div className={"blocks"}>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>1. Install Library & Dependency Manager</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Install <a href={'https://pecl.php.net/'}
                                                             target={'_blank'}
                                                             rel={'noreferrer'}>Pecl</a> and <a
                                                href={'https://getcomposer.org/'} target={'_blank'}
                                                rel={'noreferrer'}>Composer</a> before installing the
                                                Middleware APM.</span>
                                        </div>

                                    </div>

                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>2. Create composer.json file (optional)</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>If you do not have a composer.json project at the root of your directory, run the following command: </span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'php'}>{'composer init'}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard('composer init')}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>

                                    </div>


                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>3. Initial Setup</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Run this in your terminal.</span><br/>
                                            <Warning
                                                warning={"To install the Middleware Agent, your PHP version must have version 8.1+"}/>

                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'java'}>{'sudo pecl install channel://pecl.php.net/opentelemetry-1.0.0beta3'}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard('sudo pecl install channel://pecl.php.net/opentelemetry-1.0.0beta3')}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>4. Add the extension to your php.ini file</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Append the below line to your php.ini file.</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'java'}>{'extension=opentelemetry.so'}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard('extension=opentelemetry.so')}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>5. Verify the extension</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Verify the extension is installed and enabled using:</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'java'}>{'php -m | grep opentelemetry'}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard('php -m | grep opentelemetry')}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>6. Install APM-PHP package</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Run composer commands to fetch our PHP Package.</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'java'}>{'composer update\n' +
                                                    'composer require middleware/agent-apm-php'}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard('composer update\n' +
                                                          'composer require middleware/agent-apm-php')}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>7. Prepend APM script</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Write the following lines at the top of your project:</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'php'}>{ApmLogginSnap2}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard(ApmLogginSnap2)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>8. Use APM Collector & Start the Tracing-scope</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>The APM Collector will start the tracing-scope before your code runs. You must register your hooks and initial declaration.</span><br/>
                                            <span>You must define your class and function names in each hook for the Middleware Agent to automatically track them.</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={"inputbox-flex"} style={{marginTop: 15}}>
                                                <FormTextInput label={"Service Name"} placeholder={"Service Name"}
                                                               maxLength={50}
                                                               onChange={(e: any) => setActiveServiceName(e.target.value)}/>
                                            </div>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'php'}>{PhpApmSnap}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard(PhpApmSnap)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>9. End the Tracing-scope</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Add the following command to the end of your code to end the tracing scope and send data to the Middleware APM Dashboard:</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'php'}>{'$tracker->postTrack();'}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard('$tracker->postTrack();')}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"help"}>
                                    <div className={"block"}>
                                        <AlertsIntegrationIcon/> You can clone the sample project from the <a
                                        href={'https://github.com/middleware-labs/demo-apm/tree/master/php'}
                                        target={"_blank"} rel={'noreferrer'}>GitHub</a> repository.
                                    </div>

                                </div>
                            </div>
                            <div className={"buttons-group"}>
                                <Button onClick={() => setActiveStep(StepsEnum.DeploymentType)}>Back</Button>
                                <Button primary onClick={() => setActiveStep('custom_logs')}>Next</Button>
                            </div>
                        </>}
                        {activeStep === 'custom_logs' && <>
                            <div className={"workspace"}>
                                <div className={"blocks"}>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>1. Enable Custom Logs (Optional)</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Custom logs are ones that the application does not produce by default. They may be generated within certain methods, or managed by a central logging method in your application.</span><br/>
                                            <span>To get started with custom logs add the following code:</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'php'}>{ApmLogginSnap}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard(ApmLogginSnap)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={"buttons-group"}>
                                <Button onClick={() => setActiveStep('code')}>Back</Button>
                                <Button primary onClick={() => setActiveStep('see_data')}>Next</Button>
                            </div>
                        </>}
                        {activeStep === 'see_data' && <>
                            <div className={"workspace"}>
                                <div className={"blocks"}>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>View Your Data</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Once you have completed the PHP installation, wait 3-5 minutes before accessing your data.</span>
                                        </div>
                                        <div className={"content"}>
                                            <p className={"paragraph"}
                                               style={{marginTop: "10px", marginBottom: "0"}}>Access your Trace, Log,
                                                and Profiling data by clicking on one of the buttons bellow.</p>
                                            <p className={"paragraph"} style={{marginTop: "0px"}}>Proceed to deploy your
                                                project on {capitalizeFirstLetter(activeDeployment)}.</p>
                                            <div className={"buttons-group"}>
                                                <Button primary
                                                        onClick={() => window.open('/apm/overview', '_blank')}>Traces</Button>
                                                <Button primary
                                                        onClick={() => window.open('/logs', '_blank')}>Logs</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={"buttons-group"}>
                                <Button onClick={() => setActiveStep('custom_logs')}>Back</Button>
                                <Button className={"disabled"}>Next</Button>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </>
    )
}
