import MwRouteContext from "./MWRouteContext"
import React, { useState } from "react";
import useMwRouteData from "./useMwRouteData";
type MwRouteProps = {
    children: any;
}
const MwRouter = (props: MwRouteProps) => {

    const routeData = useMwRouteData("MWRouter");

    return (
        <MwRouteContext.Provider value={routeData}>
            {props.children}
        </MwRouteContext.Provider>
    )
}
export default MwRouter;