import { createReducer } from "core/utils/create_reducer";
import { combineReducers } from "redux";
import {
  Incident,
  IncidentHistory,
  IncidentListState,
  IncidentServiceHistory,
  PublicUptimeMetricsResponse,
} from "views/modules/status-page/incident/model";
import {
  ChartAndServiceResponse,
  IncidentWithHistory,
  Service,
  StatusPageSettings,
  Subscriber,
} from "views/modules/status-page/page_list/model";
import {
  CHART_AND_SERVICE_LIST_RECEIVED,
  CURRENT_STATUS_OF_SYNTHETIC_RECEIVED,
  INCIDENT_HISTORY_RECEIVED,
  INCIDENT_LIST_RECEIVED,
  INCIDENT_SERVICE_HISTORY_RECEIVED,
  INCIDENT_SINGLE_RECEIVED,
  PAGE_LIST_RECEIVED,
  PUBLIC_INCIDENT_HISTORY_RECEIVED,
  PUBLIC_VIEW_STATUS_PAGE_RECEIVED,
  SERVICE_LIST_RECEIVED,
  SERVICE_SIDE_LIST_RECEIVED,
  SINGLE_PAGE_LIST_RECEIVED,
  STATUS_PAGE_CREATE_REQUEST,
  STATUS_PAGE_PUBLISH,
  SUBSCRIBER_LIST_RECEIVED,
} from "./constant";

const initialPageListState: StatusPageSettings[] = [];

const pageList = createReducer(initialPageListState, {
  [PAGE_LIST_RECEIVED]: (state, action) => {
    if (Array.isArray(action.arg)) {
      return action.arg;
    }
    return { ...state };
  },
});

const singlePage = createReducer(initialPageListState, {
  [SINGLE_PAGE_LIST_RECEIVED]: (state, action) => {
    if (Array.isArray(action.arg)) {
      return action.arg;
    }
    return { ...state };
  },
});

const createPage = createReducer(
  {
    inflight: true,
    pageStatus: [],
  },
  {
    [STATUS_PAGE_CREATE_REQUEST]: (state, opts) => {
      // if (Array.isArray(opts.usercontact)) {
      //   state.usercontact = Array.isArray(opts.usercontact) ? opts.usercontact : [];
      // }
      return { ...state };
    },
  }
);

const intialServiceListState: Service[] = [];
const serviceList = createReducer(intialServiceListState, {
  [SERVICE_LIST_RECEIVED]: (state, action) => {
    if (Array.isArray(action.arg)) {
      return action.arg;
    }
    return { ...state };
  },
});

const intialServiceSideListState: Service[] = [];
const serviceSideList = createReducer(intialServiceSideListState, {
  [SERVICE_SIDE_LIST_RECEIVED]: (state, action) => {
    if (Array.isArray(action.arg)) {
      return action.arg;
    }
    return { ...state };
  },
});

const initialSubscriberListState: Subscriber[] = [];
const subscriberList = createReducer(initialSubscriberListState, {
  [SUBSCRIBER_LIST_RECEIVED]: (state, action) => {
    if (Array.isArray(action.arg)) {
      return action.arg;
    }
    return { ...state };
  },
});

const initialIncidentListState: IncidentListState = {
  incidents: [],
  count: 0,
};
const incidentList = createReducer(initialIncidentListState, {
  [INCIDENT_LIST_RECEIVED]: (state, action) => {
    if (action.arg && typeof action.arg === "object") {
      return {
        incidents: Array.isArray(action.arg.incidents)
          ? action.arg.incidents
          : [],
        count: typeof action.arg.count === "number" ? action.arg.count : 0,
      };
    }
    return { ...state };
  },
});

const initialIncidentHistoryListState: IncidentHistory[] = [];
const incidentHistoryList = createReducer(initialIncidentHistoryListState, {
  [INCIDENT_HISTORY_RECEIVED]: (state, action) => {
    if (Array.isArray(action.arg)) {
      return action.arg;
    }
    return { ...state };
  },
});

const initialIncidentSingleState: Incident[] = [];
const incidentSingle = createReducer(initialIncidentSingleState, {
  [INCIDENT_SINGLE_RECEIVED]: (state, action) => {
    if (Array.isArray(action.arg)) {
      return action.arg;
    }
    return { ...state };
  },
});

const initialIncidentServiceHistoryState: IncidentServiceHistory[] = [];
const incidentServiceHistory = createReducer(
  initialIncidentServiceHistoryState,
  {
    [INCIDENT_SERVICE_HISTORY_RECEIVED]: (state, action) => {
      if (Array.isArray(action.arg)) {
        return action.arg;
      }
      return { ...state };
    },
  }
);

const initialCurrentStatusOfSyntheticState: PublicUptimeMetricsResponse[] = [];
const currentStatusOfSynthetic = createReducer(
  initialCurrentStatusOfSyntheticState,
  {
    [CURRENT_STATUS_OF_SYNTHETIC_RECEIVED]: (state, action) => {
      if (Array.isArray(action.arg)) {
        return action.arg;
      } else if (typeof action.arg === "object" && action.arg !== null) {
        return Object.keys(action.arg.checks_overview).map((key) => ({
          id: key,
          status: action.arg.checks_overview[key].status,
        }));
      }
      return state;
    },
  }
);

const publicViewStatusPage: StatusPageSettings[] = [];
const publicStatusPageView = createReducer(publicViewStatusPage, {
  [PUBLIC_VIEW_STATUS_PAGE_RECEIVED]: (state, action) => {
    if (Array.isArray(action.arg)) {
      return action.arg;
    }
    return { ...state };
  },
});

const initialIncidentHistoryState: IncidentWithHistory[] = [];
const publicIncidentHistoryView = createReducer(initialIncidentHistoryState, {
  [PUBLIC_INCIDENT_HISTORY_RECEIVED]: (state, action) => {
    if (Array.isArray(action.arg)) {
      return action.arg;
    }
    return [...state];
  },
});

const initialChartAndServiceState: ChartAndServiceResponse[] = [];
const chartAndServiceList = createReducer(initialChartAndServiceState, {
  [CHART_AND_SERVICE_LIST_RECEIVED]: (state, action) => {
    if (
      action.arg &&
      typeof action.arg === "object" &&
      "data" in action.arg &&
      "service" in action.arg
    ) {
      return action.arg;
    }
    return [...state];
  },
});

const publishState = createReducer(false, {
  [STATUS_PAGE_PUBLISH]: (state, opts) => {
    return opts.value;
  },
});

export default combineReducers({
  createPage,
  pageList,
  serviceList,
  serviceSideList,
  subscriberList,
  incidentList,
  incidentHistoryList,
  incidentSingle,
  incidentServiceHistory,
  publicStatusPageView,
  publicIncidentHistoryView,
  chartAndServiceList,
  publishState,
  singlePage,
  currentStatusOfSynthetic,
});
