import { TimeRangeOption } from "core/components/datepicker/model";
import {
  FilterOpEnum,
  MetricTypeEnum,
} from "views/modules/builder/entities/builder.entities";

//TODO: Remove optional for interval
export interface DateRange {
  fromTs: number;
  toTs: number;
  interval?: number;
  range?: TimeRangeOption;
}
export interface MwRouteActions {
  //Function to change date range in params. Just pass valid date range and it will be applied to params
  handleTimeRange: (
    fromTs: number,
    toTs: number,
    range?: TimeRangeOption
  ) => void;
  // Function call make live mode true
  handleGoLive: () => void;
  // Function call make live mode false
  handleGoOffline: () => void;
  // Function call to apply filters
  handleApplyFilters: (filters: QueryParams) => void;
  // Function call to update timerange
  // This will be executed only if predefined range is selected
  // If custom range is selected then it will not update range in url
  handleRefreshClick: () => void;
}
export interface MwQuery {
  set: (key: string, value: string) => void;
  get: (key: string) => string | undefined;
  delete: (key: string) => void;
  toString: () => string;
  has: (key: string) => boolean;
  redirect: () => void;
}

export type QueryParams = Record<string, FilterParams>;

export interface FilterParams {
  operator: FilterOpEnum;
  //TODO: Not being set. Need to check
  type?: MetricTypeEnum;
  values: string[];
}

export interface MwRouteParams {
  dateRange: DateRange;
  debug: boolean;
  isLive: boolean;
  query: MwQuery;
  allParams: QueryParams;
  appliedFilters: QueryParams;
  previousLocationPathName: string | undefined;
}
export interface MwRouteData {
  params: MwRouteParams;
  actions: MwRouteActions;
}
const FromTs = "from_ts";
const ToTs = "to_ts";
const Live = "live";
const Debug = "debug";
const Filters = "filter";
const Range = "range";

// Route constants to exclude from query params
export const AllRouteParams = [FromTs, ToTs, Live, Debug, Filters, Range];

export const GlobalRouteParams = {
  FromTs,
  ToTs,
  Live,
  Debug,
  Filters,
  Range,
};
