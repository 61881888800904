import * as React from "react";
export const ColorPalette = (props: {
    color?: string;
}) => {
    let { color } = props;
    if (!color) {
        color = "#E95E5F";
    }
    return <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.1963 6H14.8115C9.39277 6 5 10.3928 5 15.8115V16.1962C5 20.7152 8.23178 24.5876 12.6779 25.396C14.2846 25.6881 15.6881 24.2846 15.396 22.6779L15 20.5C14.5164 17.8399 16.8399 15.5164 19.5 16L21.6779 16.396C23.2846 16.6881 24.6881 15.2846 24.396 13.6779C23.5876 9.23178 19.7152 6 15.1963 6Z" stroke={color} strokeOpacity="1.5" />
        <path d="M17 12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12Z" fill={color} />
        <path d="M13 11C13 11.5523 12.5523 12 12 12C11.4477 12 11 11.5523 11 11C11 10.4477 11.4477 10 12 10C12.5523 10 13 10.4477 13 11Z" fill={color} />
        <path d="M11 14C11 14.5523 10.5523 15 10 15C9.44772 15 9 14.5523 9 14C9 13.4477 9.44772 13 10 13C10.5523 13 11 13.4477 11 14Z" fill={color} />
        <path d="M12 18C12 18.5523 11.5523 19 11 19C10.4477 19 10 18.5523 10 18C10 17.4477 10.4477 17 11 17C11.5523 17 12 17.4477 12 18Z" fill={color} />
    </svg>

}