import FormTextInput from "core/components/v2/form/form-text-input";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import Command from "../../../components/code-block/command";

import { getTenantUrl } from "core/application/utils";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import StepItem from "../../../components/steps/step-item";
import APMAgentConnect from "../../common/apm-agent-connect";
import ViewYourData from "../../common/view-data-buttons";

interface ContentProps {
  serverless: string;
}

const Content: React.FC<ContentProps> = ({ serverless }) => {
  const [appEnv, setAppEnv] = useState<number>(1);
  const [appBehaviour, setAppBehaviour] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];

  interface StepFormat {
    title: string;
    content: React.ReactNode;
  }

  const [activeServiceName, setActiveServiceName] = useState("");
  const sendEvent = useMixPanel();
  const steps: StepFormat[] = [
    {
      title: "Import dependencies",
      content: (
        <>
          Import necessary packages into your Deno project from the Deno
          standard library and middlewareio library:
          <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Deno",
                command_type: "Import dependencies",
              });
            }}
            command={`import { serve } from "https://deno.land/std@0.120.0/http/server.ts";
import { track, httpTracer, info, warn, error, debug } from "https://deno.land/x/middlewareio@v1.0.7/mod.ts";`}
            className="margin-1"
          />
        </>
      ),
    },
    {
      title: "Configure Tracing",
      content: (
        <>
          <div className="apm-service-name-block">
            <div className="apm-service-name-label">
              Trace network requests with the following `track()` function:{" "}
            </div>
            <FormTextInput
              id="go-service-text-input"
              // label={"Service Name"}
              className="apm-service-name-input"
              placeholder={"Service Name"}
              value={activeServiceName}
              onChange={(e: any) => setActiveServiceName(e)}
            />
          </div>

          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Deno",
                command_type: "Configure Tracing",
              });
            }}
            command={`track({
  serviceName: "${activeServiceName == "" ? "{APM-SERVICE-NAME}" : activeServiceName}",
  target: "${capturePath}",
  accessToken: "${context.user?.account?.agent_config?.token}",
})`}
            className="margin-1"
          />
        </>
      ),
    },
    {
      title: "Define Request Handler",
      content: (
        <>
          Define how your server responds to incoming HTTP requests with the
          following function:
          <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Deno",
                command_type: "Define Request Handler",
              });
            }}
            command={`function handler(_req: Request): Response {
  const data = {
      message: \`Hello world!\`,
  };
  return new Response(JSON.stringify(data), { headers: { "Content-Type": "application/json" } });
}`}
            className="margin-1"
          />
        </>
      ),
    },
    {
      title: "Start Your Server",
      content: (
        <>
          Start an HTTP server with the `serve` function:
          <br />
          <br />
          <Command
            command={`await serve(httpTracer(handler));`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Deno",
                command_type: "Start Your Server",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Enable Custom Logs (Optional)",
      content: (
        <>
          Custom logs are ones that the application does not produce by default.
          They may be generated within certain methods, or managed by a central
          logging method in your application. To get started with custom logs
          add the following code: <br />
          <br />
          <Command
            command={`info("Sample Info Log");
warn("Sample Warning Log");
error("Sample Error Log");
debug("Sample Debug Log");`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Deno",
                command_type: "Enable Custom Logs (Optional)",
              });
            }}
          />
        </>
      ),
    },
  ];

  let indexOffset = 0;
  if (serverless != "true") {
    indexOffset = 2;
  }

  return (
    <>
      {serverless != "true" && <APMAgentConnect agentName="Deno" />}
      {steps.map((step, index) => (
        <StepItem stepNumber={index + indexOffset + 1} title={step.title}>
          {step.content}
        </StepItem>
      ))}
      <ViewYourData
        language="Deno"
        stepNumber={steps.length + indexOffset + 1}
      />
    </>
  );
};
export default Content;
