import { BillingStatus } from "core/application/model";

const calculateLogMonitoring = (gb: number, price: number) => {
  const free = price * 10;
  return parseFloat((gb * price - free).toFixed(2));
};

const calculateInfrastructureMonitoring = (hosts: number, price: number) => {
  const free = price * 2;
  return parseFloat((hosts * price - free).toFixed(2));
};

const calculateAPM = (hosts: number, price: number) => {
  return parseFloat((hosts * price).toFixed(2)) - price * 2;
};

const calculateSyntheticMonitoring = (checks: number, price: number) => {
  return parseFloat(((checks / 10000) * price).toFixed(2)) - price * 2;
};

const calcluateDatabaseMonitoring = (hosts: number, price: number) => {
  return parseFloat((hosts * price).toFixed(2)) - price * 1;
};

const calculateRUM = (sessions: number, price: number) => {
  return parseFloat(((sessions / 1000) * price).toFixed(2)) - price * 0.5;
};

const calculateServerlessMonitoring = (traces: number, price: number) => {
  return parseFloat(((traces / 1000000) * price).toFixed(2)) - price * 1;
};

const formatPrice = (price: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(price);
};

const sum = (...args: number[]) => {
  return args.reduce((total: number, num: number) => total + num, 0);
};

const getLimitExceedProducts = (productLimits: {
  Apm: boolean;
  Infrastructure: boolean;
  Logs: boolean;
  Synthetics: boolean;
  Rum: boolean;
  "Serverless Traces": boolean;
  Database: boolean;
}) => {
  const exceedProducts = Object.entries(productLimits)
    .filter(([product, limitOver]) => limitOver)
    .map(([product]) => product);

  const numberOfExceedProducts = exceedProducts.length;

  if (numberOfExceedProducts === 1) {
    return exceedProducts[0];
  } else if (numberOfExceedProducts === 2) {
    return `${exceedProducts[0]} and ${exceedProducts[1]}`;
  } else if (numberOfExceedProducts > 2) {
    const lastProduct = exceedProducts.pop(); // Remove the last product
    return `${exceedProducts.join(", ")} and ${lastProduct}`;
  } else {
    return "";
  }
};

const getPlanName = (status: string) => {
  let plan = "Trial";
  if (status === BillingStatus.active) {
    plan = "Pay as you go";
  } else if (status === BillingStatus.pending) {
    plan = "Free Forever";
  }
  return plan;
};

const prodChargebeePlan = "MW_pay-as-you-go-USD-Monthly";
const testChargebeePlan = "MW_standard-USD-Monthly";
export {
  calcluateDatabaseMonitoring,
  calculateAPM,
  calculateInfrastructureMonitoring,
  calculateLogMonitoring,
  calculateRUM,
  calculateServerlessMonitoring,
  calculateSyntheticMonitoring,
  formatPrice,
  getLimitExceedProducts,
  getPlanName,
  prodChargebeePlan,
  sum,
  testChargebeePlan,
};
