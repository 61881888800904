import { APIStatus } from "core/application/utils";
import {
  DashboardBuilderReducer,
  DashboardFavResp,
  DashboardListResp,
  DashboardResp,
  WidgetDataSourcesResp,
} from "./entities";
import { createReducer } from "core/utils/create_reducer";
import {
  MADE_DASHBOARD_FAVORITE,
  RECEIVED_DASHBOARD,
  RECEIVED_DASHBOARD_LIST,
  RECEIVED_DATA_SOURCE_WIDGETS,
  RECEIVED_WIDGET_DATA_SOURCES,
  RESET_DASHBOARD,
} from "./constants";
import { APIAction } from "store/widgets/reducers";

const defaultDashboardBuilderReducer: DashboardBuilderReducer = {
  dashboardListResp: {
    apiStatus: APIStatus.IDLE,
  },
  dashboardResp: {
    apiStatus: APIStatus.IDLE,
  },
  dashboardFavoriteResp: {
    apiStatus: APIStatus.IDLE,
    id: 0,
  },
  widgetDataSourcesResp: {
    apiStatus: APIStatus.IDLE,
  },
  dataSourceWidgetsResp: {
    apiStatus: APIStatus.IDLE,
  },
};

export default createReducer(defaultDashboardBuilderReducer, {
  [RECEIVED_DASHBOARD_LIST]: (
    state: DashboardBuilderReducer,
    opts: {
      data: DashboardListResp;
    }
  ) => {
    state.dashboardListResp = opts.data;
    return { ...state };
  },
  [RECEIVED_DASHBOARD]: (
    state: DashboardBuilderReducer,
    opts: {
      data: DashboardResp;
    }
  ) => {
    state.dashboardResp = opts.data;
    return { ...state };
  },
  [RESET_DASHBOARD]: (state: DashboardBuilderReducer) => {
    state.dashboardResp = {
      apiStatus: APIStatus.IDLE,
    };
    return { ...state };
  },
  [MADE_DASHBOARD_FAVORITE]: (
    state: DashboardBuilderReducer,
    opts: {
      data: DashboardFavResp;
    }
  ) => {
    state.dashboardFavoriteResp = opts.data;

    if (opts.data.favorite !== undefined) {
      const favorite = opts.data.favorite;

      state.dashboardListResp.dashboards?.map((dashboard) => {
        if (dashboard.id === opts.data.id) {
          dashboard.favorite = favorite;
        }
        return { ...dashboard };
      });

      state.dashboardListResp = {
        ...state.dashboardListResp,
      };
    }

    return { ...state };
  },
  [RECEIVED_WIDGET_DATA_SOURCES]: (
    state: DashboardBuilderReducer,
    opts: {
      data: WidgetDataSourcesResp;
    }
  ) => {
    state.widgetDataSourcesResp = opts.data;
    return { ...state };
  },
  [RECEIVED_DATA_SOURCE_WIDGETS]: (
    state: DashboardBuilderReducer,
    opts: {
      data: WidgetDataSourcesResp;
    }
  ) => {
    state.dataSourceWidgetsResp = opts.data as APIAction;

    // Add list of widgets in the existing data source details
    if (opts.data.apiStatus === APIStatus.SUCCESS) {
      state.widgetDataSourcesResp.sources?.forEach((source) => {
        if (source.label === opts.data.dashboardKey) {
          const sourceWidgets = source.widgets || [];
          // API returns list of widgets as the list of sources, where each source has only one widget i.e. itself.
          opts.data.sources?.forEach((s) => {
            sourceWidgets.push(...s.widgets);
          });
          source.widgets = sourceWidgets;
        }
      });
    }
    return { ...state };
  },
});
