import moment from 'moment';
import * as d3 from "d3";

function generateChartId(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

function closestInteger(a: number, b: number) {
    const c1 = a - (a % b);
    const c2 = (a + b) - (a % b);
    if (c2 > c1) {
        return c2;
    } else {
        return c1;
    }
}

function getXTicksRange(payload: {
    startDate: any,
    endDate: any,
    ticksCount: number,
}) {
    let {startDate, endDate, ticksCount} = payload;
    ticksCount--;
    startDate = moment(startDate, 'YYYY-MM-DD HH:mm:ss');
    endDate = moment(endDate, 'YYYY-MM-DD HH:mm:ss');
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    let labelFormat: string; // Label Format for months it will be 'Jan 2020' and for hours it will be '1 PM'
    const getDifference = (unit: any) => {
        return endDate.diff(startDate, unit);
    }
    const days = getDifference('days');

    if (days === 0) {
        const hours = getDifference('hours');
        if (hours > 0) {
            labelFormat = `%H:%M %p`;
        } else {
            labelFormat = `%H:%M:%S`;
        }
    } else {
        const years = moment(endDate).diff(moment(startDate), 'years');
        if (years > 0) {
            labelFormat = '%b %Y';
        } else {
            labelFormat = '%d %b';
        }
    }
    let milliseconds = getDifference('milliseconds');
    let range = milliseconds / ticksCount;
    const ranges = [];
    const currentDate = startDate;
    for (let i = 0; i <= ticksCount; i++) {
        const from = currentDate.format(dateFormat);
        currentDate.add(range, "milliseconds").subtract(1, 'seconds');
        let to = currentDate;
        if (to > endDate) {
            to = endDate;
        }
        ranges.push({from, to: to.format(dateFormat)});
        currentDate.add(1, 'seconds');
    }
    return {
        ranges,
        labelFormat,
    };
}
export function distanceBetweenPointAndSegment(mouseLocation: any, segmentStartLocation: any, segmentEndLocation: any) {
    try {
        // The next four lines are the complicated algebra to determine the length of the imaginary line between the mouse and the segment if the segment were infinitely long
        const a = segmentStartLocation[1] - segmentEndLocation[1];
        const b = segmentEndLocation[0] - segmentStartLocation[0];
        const c = (segmentEndLocation[1] * segmentStartLocation[0]) - (segmentStartLocation[1] * segmentEndLocation[0]);
        const distancePerpendicularToExtendedLine = Math.abs(((a * mouseLocation[0]) + (b * mouseLocation[1]) + c) / (Math.sqrt((Math.pow(a, 2) + (Math.pow(b, 2))))));

        let distance;
        const lengthOfLineSegment = Math.sqrt(Math.pow((segmentEndLocation[0] - segmentStartLocation[0]), 2) + Math.pow((segmentEndLocation[1] - segmentStartLocation[1]), 2));// The length of the segment
        const distanceBetweenPointAndSegmentStart = Math.sqrt(Math.pow((mouseLocation[0] - segmentStartLocation[0]), 2) + Math.pow((mouseLocation[1] - segmentStartLocation[1]), 2));// Distance between mouse and one end of the segment
        const distanceBetweenPointAndSegmentEnd = Math.sqrt(Math.pow((mouseLocation[0] - segmentEndLocation[0]), 2) + Math.pow((mouseLocation[1] - segmentEndLocation[1]), 2));// Distance between mouse and the other end of the segment
        const max: any = d3.max([distanceBetweenPointAndSegmentStart, distanceBetweenPointAndSegmentEnd]);
        const distanceBetweenClosestPointOfExtendedLineAndOtherEndOfLine = Math.sqrt(Math.pow(max, 2) - Math.pow(distancePerpendicularToExtendedLine, 2));
        // distanceBetweenClosestPointOfExtendedLineAndOtherEndOfLine is the length of the imaginary line between whichever end of the segment is farther away and the point on the imaginary infinitely long line closest to the mouse.
        // If this line is --longer-- than the segment itself, it means the mouse is located beyond the end of the line, therefore the closest point is whichever end of the segment is closer, as opposed to the point on the imaginary line closest to the mouse.
        if (distanceBetweenClosestPointOfExtendedLineAndOtherEndOfLine > lengthOfLineSegment) {
            distance = d3.min([distanceBetweenPointAndSegmentStart, distanceBetweenPointAndSegmentEnd]);
        } else {
            distance = distancePerpendicularToExtendedLine;
        }
        return distance;
    } catch (e) {
        console.log({e});
        return 0;
    }
}

export {generateChartId, closestInteger, getXTicksRange};