import FormTextInput from "core/components/v2/form/form-text-input";
import { WidgetCopyIcon } from "core/components/v2/svg/chart-icons";
import { DeleteIcon } from "core/components/v2/svg/icons";
import { debounceHandler } from "core/utils";
import React, { useCallback, useState } from "react";
import useDidMountEffect from "views/layouts/app/routes/useDidMountEffect";
import "./style.scss";

export interface FormulaProps {
  formula: string;
  formulaIdx: number;
  isValidFormula: (formula: string) => boolean;
  onFormulaChange: (newFormula: string) => void;
  onFormulaClone: (formula: string) => void;
  onFormulaDelete: () => void;
}

export default function Formula({
  formula,
  formulaIdx,
  isValidFormula,
  onFormulaChange,
  onFormulaClone,
  onFormulaDelete,
}: FormulaProps) {
  const [localFormula, setLocalFormula] = useState<string>(formula);

  const handleLocalFormulaChange = useCallback(
    debounceHandler(-1, onFormulaChange, 500),
    [onFormulaChange]
  );
  useDidMountEffect(() => {
    handleLocalFormulaChange(localFormula);
  }, [localFormula]);

  return (
    <div className="formula-view-container">
      <div className="formula-view-index">{formulaIdx + 1}</div>

      <div className="formula-view-input-controls-container">
        <div className="formula-input-text">
          <div className="text-input-with-label-container">
            <label>Formula</label>
            <FormTextInput
              id="formula"
              isError={!isValidFormula(localFormula)}
              onChange={(value) => {
                setLocalFormula(value);
              }}
              value={localFormula}
            />
          </div>
        </div>

        <div className="formula-actions-container">
          <div
            className="action-icon copy"
            onClick={(e) => {
              e.stopPropagation();
              onFormulaClone(localFormula);
            }}
          >
            <WidgetCopyIcon />
          </div>

          <div
            className="action-icon delete"
            onClick={(e) => {
              e.stopPropagation();
              onFormulaDelete();
            }}
          >
            <DeleteIcon color="var(--color-error)" />
          </div>
        </div>
      </div>
    </div>
  );
}
