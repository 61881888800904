import FormTextInput from "core/components/v2/form/form-text-input";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { requestPublicSubmitSubscriber } from "store/status_page/api";
import { PublicSbscriber, Subscriber } from "../page_list/model";
import FormInputValidation from "core/components/v2/form/form-input-validation";

interface EmailConfigurationProps {
  pageId: number;
  projectId: number;
  subemailSate: boolean;
  setOpen: (isOpen: boolean) => void;
  setSubemailState: (subemailSate: boolean) => void;
}

const EmailPage = (props: EmailConfigurationProps) => {
  const { pageId, projectId, setOpen, setSubemailState, subemailSate } = props;
  const [subemail, setSubemail] = useState("");
  const dispatch = useDispatch();

  const payload: PublicSbscriber = {
    page_id: Number(pageId),
    subscriber_value: subemail,
    subscriber_type: "email",
    project_id: projectId,
    host: window.location.host,
  };

  const onSubmitSubscriberPage = () => {
    if (!subemail.includes("@")) {
      setSubemailState(true);
    } else {
      dispatch(requestPublicSubmitSubscriber(payload, (success, res) => {}));
      setOpen(false);
      setSubemailState(false);
    }
  };
  return (
    <div className={"email-page"}>
      <div style={{ padding: "12px 0", fontWeight: "bold" }}>
        <label>{"Get updates via email"}</label>
      </div>
      <div>
        <FormTextInput
          id={"email"}
          label={"Your email address"}
          isError={subemailSate}
          value={subemail}
          placeholder="email@company.com"
          onChange={(value: string) => {
            setSubemail(value)
            !value.includes("@") ? setSubemailState(true) : setSubemailState(false);
          }}
        />
        {subemailSate && (
          <FormInputValidation
            isError
            text={"Please enter a valid email address"}
          />
        )}
      </div>
      <div style={{ padding: "8px 0" }}>
        <button
          className={"button-v2 is-primary"}
          onClick={() => {
            onSubmitSubscriberPage();
          }}
        >
          Subscribe
        </button>
      </div>
    </div>
  );
};

export default EmailPage;
