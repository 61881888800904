import React from "react"
import classNames from "classnames";
import { DownArrowSvg, RightArrowSvg } from "core/components/svg/icons";

export const SidebarHeading = function (props: any) {
    return (<SidebarCardBox borderBottom><div className={"sidebar__heading"}>{props.children}</div></SidebarCardBox>)
}

export const SidebarCardBox = function (props: {
    children: any;
    borderBottom?: boolean,
    parentClassName?: string,
    background ?: boolean
}) {
    return (<div className={classNames("sidebar__card__box", props.parentClassName, {
        "with__border-bottom": !!props.borderBottom,
        "with__background": !!props.background,
    })}>{props.children}</div>)
}

export const SidebarSeparator = function () {
    return (<div className={"sidebar__separator"} />)
}

export const SidebarCardListItem = function (props: {
    children: any;
    selected?: boolean
    onClick?: (e0?: any) => void,
    subItem?: any,
}) {
    const { selected, children, subItem, ...restOfProps } = props;
    return (<div {...restOfProps} className={classNames("sidebar__car__list__item", {
        "selected__item": selected,
        "is_sub__item": subItem
    })}>{children}</div>)
}

export const SidebarCardListItemButton = function (props: {
    children: any;
    active?: boolean
    onClick?: (e0?: any) => void
}) {
    const { active, children, ...restOfProps } = props;
    return (<div {...restOfProps} className={classNames("sidebar__car__list__item sidebar__car__list__item__button", {
        "button__active": active
    })}>{children}</div>)
}

export const SidebarCardAccordionWrapper = function (props: any) {
    return (<div className={classNames(`sidebar__card__accordion__wrapper`, {
    })}>
        {props.children}
    </div>)
}

export const SidebarCardAccordionHeader = function (props: {
    children: any;
    onClick?: (e0?: any) => void
    isOpen?: boolean
    toggle?: boolean
    badge?: string
    arrowPosition?: 'left' | 'right'
    defaultOnActive ?: boolean

} | any) {
    const { isOpen, toggle, children, badge, ...restOfProps } = props;
    return (<div className={classNames(`sidebar__card__accordion__header`, {
        active: isOpen,
        "default_on_active": !!props.defaultOnActive
    })} {...restOfProps}>
        {badge !== "" && <span className={"status__badge"} title={badge} />}
        <span className={"__title"} style={
            props.arrowPosition == 'left' ? { marginLeft: '20px' } :
                {}
        }>{children}</span>

        {toggle && <button className={`toggle__action ${isOpen ? "open" : "close"}` } style={
            props.arrowPosition == 'left' ? { position: 'absolute' } :
                {}
        }>
            {/*{isOpen ? <DownArrowSvg /> : <RightArrowSvg />}*/}
        </button>}
    </div>)
}

export const SidebarCardAccordionContent = function (props: any) {
    return (<div className={`sidebar__card__accordion__content`}>
        {props.children}
    </div>)
}

export const SidebarCardAccordionContentPlaceholder = function (props: any) {
    return (<div className={`placeholder__content`}>
        {props.children}
    </div>)
}