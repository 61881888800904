import FormTextInput from "core/components/v2/form/form-text-input";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { requestSubmitSubscriber } from "store/status_page/api";
import { Subscriber } from "../page_list/model";
import FormCountrySelect from "core/components/v2/form/form-country-select";

interface SmsConfigurationProps {
  pageId: number;
}

const SmsPage = (props: SmsConfigurationProps) => {
  const { pageId } = props;
  const [subsms, setSubSms] = useState("");
  const [selectedCountry, setSelectedCountry] = useState<string>("IND");
  const [callingCode, setCallingCode] = useState<string>("+91");
  const [phone, setPhone] = useState<string>("");
  const [phoneErr, setPhoneErr] = useState<boolean>(false);

  const dispatch = useDispatch();

  const payload: Subscriber = {
    page_id: Number(pageId),
    subscriber_value: callingCode + phone,
    subscriber_type: "sms",
  };

  const onSubmitSubscriberPage = () => {
    dispatch(requestSubmitSubscriber(payload, (success, res) => {}));
  };
  return (
    <div className={"email-page"}>
      <div style={{ padding: "12px 0", fontWeight: "bold" }}>
        <label>{"Get updates via SMS"}</label>
      </div>
      <div style={{ padding: "0 0 12px 0" }}>
        <label>{"Phone"}</label>
      </div>
      <div className="combine-input" style={{ display: "flex" }}>
        <FormCountrySelect
          defaultSelected={selectedCountry}
          onSelect={(_name, _code, label, id) => {
            if (label) setCallingCode(label);
            if (id) setSelectedCountry(id);
          }}
        />

        <FormTextInput
          id={"phone"}
          type="number"
          value={phone}
          isError={phoneErr}
          onKeyUp={() => {
            setPhoneErr(false);
          }}
          onChange={(value) => {
            setPhone(value);
          }}
        />
      </div>
      <div style={{ padding: "8px 0" }}>
        <button
          className={"button-v2 is-primary"}
          onClick={() => {
            onSubmitSubscriberPage();
          }}
        >
          Subscribe
        </button>
      </div>
    </div>
  );
};

export default SmsPage;
