import {AGENT_TOKEN_DETAIL_RECEIVED, CLIENT_TOKEN_LIST_RECEIVED} from "./constant";


export const clientTokenListReceived = (arg: any = {}) => {
    return {
        type: CLIENT_TOKEN_LIST_RECEIVED,
        ...arg
    }
}
export const agentTokenDetailReceived = (arg: any = {}) => {
    return {
        type: AGENT_TOKEN_DETAIL_RECEIVED,
        ...arg
    }
}