import {
  REMOVE_TOAST_ALERTS,
  REQUEST_TOAST_ALERTS,
  TOAST_ALERTS_ITEMS,
} from "./constant";

export interface ShowToastProps {
  uniqueId?: number;
  duration?: number;
  callback?: (a0: any) => void;
  onPrimaryBtnClick?: () => void;
  onSecondaryBtnClick?: () => void;
  onBtnClick?: () => void;
  primaryBtnText?: string;
  secondaryBtnText?: string;
  btnText?: string;
  title?: string;
}

export type ToastType = "success" | "default" | "error" | "warning" | "info";

export const showToast = (
  toastType: ToastType,
  message: string,
  arg?: ShowToastProps
) => {
  return {
    type: REQUEST_TOAST_ALERTS,
    toastType,
    message,
    ...arg,
  };
};
export const removeToast = (toastIndex: any) => {
  return {
    type: REMOVE_TOAST_ALERTS,
    toastIndex,
  };
};

// showToast("success", "hello test",{duration: 200})
