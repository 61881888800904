import {createReducer} from "core/utils/create_reducer";
import {
    PRICE_RECEIVED,
    PRICE_LIST_RECEIVED,
    SUBSCRIPTION_FOR_CUSTOMER_RECEIVED,
    INVOICES_RECEIVED,
    HOSTED_PAGE_RECEIVED,
    CUSTOMER_RECEIVED,
    ITEMS_PRICES_RECEIVED,
    USAGE_LIST_RECEIVED,
    MW_DAILY_USAGE_RECEIVED,
    BILLING_COUNTS_RECEIVED,
    MW_MONTHLY_USAGE_RECEIVED
} from "./constant";
import {combineReducers} from "redux";


const price = createReducer({}, {
    [PRICE_RECEIVED]: (state, opts) => {
        if (Object.keys(opts.data).length) {
            return opts.data;
        }
        return {...state};
    }
})

const priceList = createReducer([], {
    [PRICE_LIST_RECEIVED]: (state, opts) => {
        if (Array.isArray(opts.data)) {
            return opts.data;
        }
        return {...state};
    }
})

const subscriptionForCustomer = createReducer([], {
    [SUBSCRIPTION_FOR_CUSTOMER_RECEIVED]: (state, opts) => {
        if (Array.isArray(opts.data)) {
            return opts.data;
        }
        return {...state};
    }
})

const invoices = createReducer([], {
    [INVOICES_RECEIVED]: (state, opts) => {
        if (Array.isArray(opts.data)) {
            return opts.data;
        }
        return {...state};
    }
})

const hostedPage = createReducer([], {
    [HOSTED_PAGE_RECEIVED]: (state, opts) => {
        if (Object.keys(opts.data).length) {
            return opts.data;
        }
        return {...state};
    }
})

const customer = createReducer([], {
    [CUSTOMER_RECEIVED]: (state, opts) => {
        if (Object.keys(opts.data).length) {
            return opts.data;
        }
        return {...state};
    }
})

const items = createReducer([], {
    [ITEMS_PRICES_RECEIVED]: (state, opts) => {
        if (Object.keys(opts.data).length) {
            return opts.data;
        }
        return {...state};
    }
})

const usageList = createReducer([], {
    [USAGE_LIST_RECEIVED]: (state, opts) => {
        if (Object.keys(opts.data).length) {
            return opts.data;
        }
        return {...state};
    }
})
const dailyUsage = createReducer([], {
    [MW_DAILY_USAGE_RECEIVED]: (state, opts) => {
        if (Object.keys(opts.data).length) {
            return opts.data;
        }
        return {...state};
    }
})
const dailyCost = createReducer([], {
    [MW_DAILY_USAGE_RECEIVED]: (state, opts) => {
        if (opts.aggregated_cost && typeof opts.aggregated_cost === 'object' && Object.keys(opts.aggregated_cost).length) {
            return opts.aggregated_cost;
        }
        return {...state};
    }
})
const counts = createReducer({}, {
    [BILLING_COUNTS_RECEIVED]: (state, opts) => {
        if (opts) {
            return opts;
        }
        return {...state};
    }
})
const monthlyCost = createReducer([], {
    [MW_MONTHLY_USAGE_RECEIVED]: (state, opts) => {
        if (opts && opts.data && Object.keys(opts.data).length) {
            return opts.data;
        }
        return {...state};
    }
})
export default combineReducers({
    price,
    priceList,
    subscriptionForCustomer,
    invoices,
    hostedPage,
    customer,
    items,
    usageList,
    dailyUsage,
    counts,
    dailyCost,
    monthlyCost
})