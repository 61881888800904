import * as React from "react";
import { useSelector } from "react-redux";

import { getAuthToken } from "store/selectors";
import { getInitInFlight } from "store/account/selectors";
import AppLayouts from "./layouts/app";
import AuthLayouts from "./layouts/auth";
import InitLoader from "./placeholder/init-loader";
import { getThemesMode } from "store/selectors";
import BackoffLayouts from "views/backoff/layout";
import { getThemeStateBkoff, triggerRegisterEvent } from "core/utils";
import MwRouter from "views/layouts/app/routes/MwRouter";
import LogoLoader from "core/components/v2/loader/logo-loader";
import { getPublicStatusPageViewSelector } from "store/status_page/selector";
import PublicStatusPage from "./modules/status-page/public-status-page";

const LayoutViews = (props: any) => {
  const isAuthorised = useSelector(getAuthToken);
  const inflight = useSelector(getInitInFlight);
  const themeMode = useSelector(getThemesMode);
  const themeModeBkoff = useSelector(getThemeStateBkoff);
  const statusPageData = useSelector(getPublicStatusPageViewSelector);

  const isStatusPage =
    statusPageData !== null &&
    statusPageData &&
    Object.keys(statusPageData).length > 0;

  const isDarkTheme = isAuthorised && themeMode === "dark";
  const isDarkThemeBkoff = themeModeBkoff === "dark";

  const _window: any = window as any;
  _window.mwSite({ isDarkTheme, isAuthorised });

  const isBackkoffHost = (window.location.host || "").indexOf("bkoff.") >= 0;

  return (
    <React.Suspense fallback={<LogoLoader />}>
      {inflight && <LogoLoader />}

      <div
        className={`context__root__wrapper ${isDarkTheme ? "dark-theme" : "" || isDarkThemeBkoff ? "dark-theme" : "light"}`}
        data-theme={themeMode && themeModeBkoff}
      >
        {isStatusPage && <PublicStatusPage statusPageData={statusPageData} />}
        {isBackkoffHost && <BackoffLayouts />}
        {!isBackkoffHost && !isStatusPage && (
          <React.Fragment>
            {isAuthorised ? (
              <MwRouter>
                <AppLayouts />
              </MwRouter>
            ) : (
              <AuthLayouts />
            )}
          </React.Fragment>
        )}
      </div>
    </React.Suspense>
  );
};

export default LayoutViews;
