import React, { ReactNode } from "react";

import classNames from "classnames";
import { CheckmarkIcon, MinusIcon } from "core/components/v2/svg/icons";
import { Size } from "core/components/v2/enums";
import Tooltip from "../../tooltip";

interface FormCheckboxProps {
  id: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string | ReactNode;
  prefixLabelIcon?: React.ReactNode;
  suffixLabelIcon?: React.ReactNode;
  disabled?: boolean;
  size?: Size.Small;
  showMinusIcon?: boolean;
  className?: string;
  tooltipContent?: string;
  tooltipPosition?:
    | "top"
    | "right"
    | "left"
    | "bottom"
    | "topLeft"
    | "topRight";
}

const FormCheckbox = (props: FormCheckboxProps) => {
  const {
    id,
    checked,
    onChange,
    label,
    prefixLabelIcon,
    suffixLabelIcon,
    disabled,
    showMinusIcon,
    size,
    className,
    tooltipContent,
    tooltipPosition,
  } = props;
  const checkmarkSize = size === Size.Small ? 9 : 12;
  const checkboxInputClass = classNames("checkboxInput", size, className, {
    "is-disabled": disabled,
  });
  const checkmarkClass = classNames("checkmark", size, {
    "is-disabled": disabled,
  });
  const checkboxLabelClass = classNames("checkbox-label-content", {
    "is-disabled": disabled,
  });

  return (
    <div className="custom-checkbox">
      <label htmlFor={id} className={checkmarkClass}>
        <span
          className={`checkbox-icon ${showMinusIcon ? "minus-icon" : "check-icon"} ${disabled ? "disabled" : ""}`}
        >
          {checked &&
            (showMinusIcon ? (
              <MinusIcon size={checkmarkSize} />
            ) : (
              <CheckmarkIcon size={checkmarkSize} />
            ))}
        </span>
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
          className={checkboxInputClass}
        />
        <span className={checkboxLabelClass}>
          {prefixLabelIcon}
          <Tooltip position={tooltipPosition} content={tooltipContent}>
            {label}
          </Tooltip>
          {suffixLabelIcon}
        </span>
      </label>
    </div>
  );
};

export default FormCheckbox;
