import {
  MADE_DASHBOARD_FAVORITE,
  RECEIVED_DASHBOARD,
  RECEIVED_DASHBOARD_LIST,
  RECEIVED_DATA_SOURCE_WIDGETS,
  RECEIVED_WIDGET_DATA_SOURCES,
} from "./constants";
import {
  DashboardFavResp,
  DashboardListResp,
  DashboardResp,
  WidgetDataSourcesResp,
} from "./entities";

export const dashboardListAction = (data: DashboardListResp) => ({
  type: RECEIVED_DASHBOARD_LIST,
  data,
});

export const dashboardAction = (data: DashboardResp) => ({
  type: RECEIVED_DASHBOARD,
  data,
});

export const resetDashboardAction = () => ({
  type: RECEIVED_DASHBOARD,
});

export const dashboardFavoriteAction = (data: DashboardFavResp) => ({
  type: MADE_DASHBOARD_FAVORITE,
  data,
});

export const widgetDataSourcesAction = (data: WidgetDataSourcesResp) => ({
  type: RECEIVED_WIDGET_DATA_SOURCES,
  data,
});

export const dataSourceWidgetsAction = (data: WidgetDataSourcesResp) => ({
  type: RECEIVED_DATA_SOURCE_WIDGETS,
  data,
});
