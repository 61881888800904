import { get } from "lodash-es";
import { createSelector } from "reselect";

export const getSlackURL = createSelector((state: any) =>
    get(state, ['alert', 'rules', 'items'], []), data => data);

export const getVercelDetails = createSelector((state: any) =>
    get(state, ['integration', 'vercelDetails'], {
        data: {},
        otel: {},
        projects: [],
        inflight: false,
        update: null,
    }), data => data);

export const getVercelInflight = createSelector((state: any) =>
    get(state, ['integration', 'vercelDetails', 'inflight'], false), data => data);

export const getVercelProjects = createSelector((state: any) =>
    get(state, ['integration', 'vercelDetails', 'projects'], []), data => data);

export const getVercelStatus = createSelector((state: any) =>
    get(state, ['integration', 'vercelDetails', 'status_code'], "vercel_integration_not_found"), data => data);

export const getAWSAccessKeyStatus = createSelector((state: unknown) =>
    get(state, ['integration', 'validateAWSAccessKeyStatus'], {
        status: false,
        message: "",
    }), data => data)

export const AWSInflightStatus = createSelector((state: unknown) =>
    get(state, ['integration', 'awsInflight'], false), data => data)
