import { Message } from "core/components/v2/enums";
import FormTextInput from "core/components/v2/form/form-text-input";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import Command from "../../../components/code-block/command";
import MwMessage from "../../../components/message";

import { getTenantUrl } from "core/application/utils";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import StepItem from "../../../components/steps/step-item";
import APMAgentConnect from "../../common/apm-agent-connect";
import ViewYourData from "../../common/view-data-buttons";

interface ContentProps {
  serverless: string;
}

const Content: React.FC<ContentProps> = ({ serverless }) => {
  const [appEnv, setAppEnv] = useState<number>(1);
  const [appBehaviour, setAppBehaviour] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];

  interface StepFormat {
    title: string;
    content: React.ReactNode;
  }

  const [activeServiceName, setActiveServiceName] = useState("");
  const sendEvent = useMixPanel();
  const steps: StepFormat[] = [
    {
      title: "Install Library & Dependency Manager",
      content: (
        <>
          Install Pecl and Composer before installing the Middleware APM.
          <br />
          <br />
        </>
      ),
    },
    {
      title: "Create composer.json file (optional)",
      content: (
        <>
          If you do not have a composer.json project at the root of your
          directory, run the following command:
          <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "PHP",
                command_type: "Create composer.json file (optional)",
              });
            }}
            command={`composer init`}
            className="margin-1"
          />
        </>
      ),
    },
    {
      title: "Initial Setup",
      content: (
        <>
          Run this in your terminal. <br />
          <br />
          <MwMessage
            type={Message.Warning}
            message={
              <div>
                To install the Middleware Agent, your PHP version <a>must</a>{" "}
                have version 8.1+
              </div>
            }
          ></MwMessage>
          <Command
            command={`sudo pecl install channel://pecl.php.net/opentelemetry-1.0.0beta3`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "PHP",
                command_type: "Initial Setup",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Add the extension to your php.ini file",
      content: (
        <>
          Append the below line to your php.ini file. <br />
          <br />
          <Command
            command={`extension=opentelemetry.so`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "PHP",
                command_type: "Add the extension to your php.ini file",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Verify the extension",
      content: (
        <>
          Verify the extension is installed and enabled using:
          <br />
          <br />
          <Command
            command={`php -m | grep opentelemetry`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "PHP",
                command_type: "Verify the extension",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Install APM-PHP package",
      content: (
        <>
          Run composer commands to fetch our PHP Package.
          <br />
          <br />
          <Command
            command={`composer update
composer require middleware/agent-apm-php`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "PHP",
                command_type: "Install APM-PHP package",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Prepend APM script",
      content: (
        <>
          Write the following lines at the top of your project:
          <br />
          <br />
          <Command
            command={`require 'vendor/autoload.php';
use Middleware\AgentApmPhp\MwTracker;`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "PHP",
                command_type: "Prepend APM script",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Use APM Collector & Start the Tracing-scope",
      content: (
        <>
          The APM Collector will start the tracing-scope before your code runs.
          You must register your hooks and initial declaration. You must define
          your class and function names in each hook for the Middleware Agent to
          automatically track them.
          <br />
          <br />
          <div className="apm-service-name-block">
            <FormTextInput
              id="go-service-text-input"
              // label={"Service Name"}
              className="apm-service-name-input"
              placeholder={"Service Name"}
              value={activeServiceName}
              onChange={(e: any) => setActiveServiceName(e)}
            />
          </div>
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "PHP",
                command_type: "Use APM Collector & Start the Tracing-scope",
              });
            }}
            command={`$tracker = new MwTracker('${activeServiceName == "" ? "{APM-SERVICE-NAME}" : activeServiceName}'); 
$tracker->preTrack(); 
  
$tracker->registerHook('<CLASS-NAME-1>', '<FUNCTION-NAME-1>', [ 
  'custom.attr1' => 'value1', 
  'custom.attr2' => 'value2', 
]); 
$tracker->registerHook('<CLASS-NAME-2>', '<FUNCTION-NAME-2>'); `}
            className="margin-1"
          />
        </>
      ),
    },
    {
      title: "End the Tracing-scope",
      content: (
        <>
          Add the following command to the end of your code to end the tracing
          scope and send data to the Middleware APM Dashboard:
          <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "PHP",
                command_type: "End the Tracing-scope",
              });
            }}
            command={`$tracker->postTrack();`}
            className="margin-1"
          />
        </>
      ),
    },
  ];

  let indexOffset = 0;
  if (serverless != "true") {
    indexOffset = 2;
  }

  return (
    <>
      {serverless != "true" && <APMAgentConnect agentName="PHP" />}
      {steps.map((step, index) => (
        <StepItem stepNumber={index + indexOffset + 1} title={step.title}>
          {step.content}
        </StepItem>
      ))}
      <ViewYourData
        language="PHP"
        stepNumber={steps.length + indexOffset + 1}
      />
    </>
  );
};
export default Content;
