import * as React from "react"
import "./_style.scss";
import { PropsWithChildren, useState } from "react";
import Card from "core/components/card";
import Skeleton from 'react-loading-skeleton'
import Loader from "core/components/loader";
import TableGridview, { GridviewColumnsOptions } from "core/components/table-gridview";
import SyntaxHighlighter from "react-syntax-highlighter";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import MultiBaseChart from "core/components/charts/common/multi-base-chart";
import Dialog from "core/components/dialog";
import LogInfoPanel from "views/modules/logs/dialog-panel/log.panel";
import SuggestionInput from "core/components/suggestion-input";
import { useNavigate } from "react-router-dom";
import { convertToTimeline, getFilterInstance } from "../helpers/utils";
import { LogsInfoBase } from "../../../../entities/logs/lists";
import { subscribeData, unsubscribeData } from "store/subscription/api";
import { AddUpdateWidgetBuilder, CustomWidget, GLOBAL_ATTRIBUTE_FILTER, Query, SubscribeRequest, builderFiltersInstance, builderLimitInstance, builderSortingInstance, defaultQuery } from "views/modules/builder/entities/builder.entities";
import { getTimelineLogs } from "store/logs/selectors";
import BuilderView from "views/modules/builder/views";
import { granularityInterval } from "core/utils/chart_helper";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import { requestBuilderFormattedData } from "store/widgets/api";
import { getAvailableMetrics } from "store/widgets/selectors";


let dummyLoaders: any[] = []
let listLoaders: any[] = []



const LogsIntegration = (props: { attr: string, value?: string, sts?: any, ets?: any, themeMode?: string, onlyGrid?: boolean, hideService?: boolean, multipleFilters?: any, disableChange?: boolean }) => {

    const [isLoading, setIsLoading] = useState(true)
    const [isTlLoading, setTlLoading] = useState(true)
    const [listLoading, setListLoading] = useState(true)
    // const [logs, setLogs] = useState([] as any[])
    const logs = useSelector(getTimelineLogs)
    const [brushVisible, setBrushVisible] = useState(false)
    const [logTimeLine, setLogTimeline] = useState([] as any[])

    const [infoOpen, setInfoOpen] = useState(false);

    const [logId, setLogId] = useState(0);
    const [logTs, setLogTs] = useState(new Date().getTime());

    const [lineBar, setLineBar] = useState([]) as any;

    const [filterOptions, setFilterOptions] = useState<any>({})

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const availableMetricsData = useSelector(getAvailableMetrics);

    const [isLive, setIsLive] = useState(!props.onlyGrid)

    const [fltrs, setFilters] = useState<any>(props.multipleFilters ? props.multipleFilters : { [props.attr]: [props.value || ''] })
    const [dateRange, setDateRange] = useState<any>({
        sts: new Date().getTime() - 3600000,
        ets: new Date().getTime()
    })
    const routeData = React.useContext(MwRouteContext);
    const columns: Array<GridviewColumnsOptions> = [

        {
            title: "Occurance",
            accessor: "timestamp",
            onClick: (item: any) => {
                setLogId(item.id);
                setLogTs(item.timestamp + 3600000)
                setInfoOpen(true);
            },
            style: { cursor: 'pointer' },
            value: (data: any) => {
                if (listLoading) {
                    return <Skeleton height={30} width={95} count={1} inline wrapper={InlineWrapperWithMargin} />
                }
                return <div style={{
                    paddingRight: '20px'
                }} className={`split-detail ${data.severity ? data.severity.toLowerCase() : 'default'}`}>
                    <span>{formatDate(data.timestamp, 'day_format')}</span>
                    <span className={"description-text"}>{formatDate(data.timestamp, 'time_format')}</span>
                </div>
            }
        },

        {
            title: "Service Name",
            accessor: 'service',
            onClick: (item: any) => {
                setLogId(item.id);
                setLogTs(item.timestamp + 3600000)
                setInfoOpen(true);
            },
            style: { cursor: 'pointer' },
            value: (data: any) => {

                if (listLoading) {
                    return <Skeleton height={30} width={95} count={1} inline wrapper={InlineWrapperWithMargin} />
                }

                return <span style={{ color: 'var(--color-blue-400)' }}>{data.service}</span>
            }
        },

        {
            title: "Log",
            accessor: 'body',
            value: (data: any) => {

                if (listLoading) {
                    return <Skeleton height={60} width={1120} count={1} inline wrapper={InlineWrapperWithMargin} />
                }

                return <SyntaxHighlighter language={"apache"} wrapLines={true} wrapLongLines={true} style={github}>
                    {data.body}
                    {/* {'{"instant":{"epochSecond":1672664374,"nanoOfSecond":999210676},"thread":"grpc-default-executor-11701","level":"INFO","loggerName":"hipstershop.AdService","message":"received ad request (context_words=[footwear])","endOfBatch":false,"loggerFqcn":"org.apache.logging.log4j.spi.AbstractLogger","threadId":11730,"threadPriority":5,"logging.googleapis.com/trace":"${ctx:traceId}","logging.googleapis.com/spanId":"${ctx:spanId}","logging.googleapis.com/traceSampled":"${ctx:traceSampled}","time":"2023-01-02T12:59:34.999Z"}'} */}
                </SyntaxHighlighter>
            }
        },
        // {
        //     title: "SEVERITY",
        //     accessor: 'severity',
        //     value: (data: any) => {
        //         if (listLoading) {
        //             return <Skeleton height={25} width={30} count={1} inline wrapper={InlineWrapperWithMargin} />
        //         }
        //         const _type: LogType = data.severity ? data.severity.toLowerCase() : 'unknown';
        //         const _label = LogBadgeTheme[_type].label;
        //         const _theme = LogBadgeTheme[_type].type;
        //         return <StatusBadge
        //             labelText={_label}
        //             simple={props?.themeMode === "light"}
        //             info={(_theme === "info")}
        //             primary={(_theme === "primary")}
        //             warning={(_theme === "warning")}
        //             error={(_theme === "error")}
        //             success={(_theme === "success")}
        //         />;
        //     }
        // }
    ]

    const InlineWrapperWithMargin = ({ children }: PropsWithChildren<unknown>) => {
        return <span style={{ marginRight: '1.5rem' }}>{children}</span>
    }

    if (props.hideService) {
        columns.splice(1, 1)
    }

    // React.useEffect(() => {
    //     if(props.multipleFilters) {
    //         setFilters(props.multipleFilters)
    //     }else {
    //         setFilters({[props.attr]: [props.value || '']})
    //     }
    // }, [])

    const getAvailableLogsFilters = () => {
        const filterOptions: { [key: string]: string[] } = {};
        const logResource = availableMetricsData?.availableMetrics?.metrics?.find((resource: any) => resource.resource === 'log');
        if (logResource && logResource.table && logResource.table.filters) {
            for (const key in logResource.table.filters) {
                if (logResource.table.filters.hasOwnProperty(key)) {
                    const values = logResource.table.filters[key].values;
                    if (values && Array.isArray(values) && values.length > 0) {
                        if (filterOptions.hasOwnProperty(key)) {
                            filterOptions[key].push(...values);
                        } else {
                            filterOptions[key] = [...values];
                        }
                    }
                }
            }
        }
        return filterOptions;
    };

    React.useEffect(() => {
        // setTlLoading(true)
        setListLoading(true)
        setDateRange({
            ets: props.ets || new Date().getTime(),
            sts: props.sts || new Date().getTime() - 3600000
        })

        const filterOptions = getAvailableLogsFilters();
        if (filterOptions) {
            if(filterOptions['level']) {
                delete filterOptions['level']
            }
            setFilterOptions(filterOptions);
        } else {
            setFilterOptions({});
        }
        
        dummyLoaders = []
        for (let i = 0; i < 8; i++) {
            dummyLoaders.push(i + 1)
        }

        listLoaders = []
        for (let i = 0; i <= 10; i++) {
            listLoaders.push({
                body: i + 1,
                severity: "INFO"
            })
        }

        let opts = {
            key: props.attr,
            value: props.value,
            ets: props.ets || new Date().getTime(),
            sts: props.sts || new Date().getTime() - 3600000

        }
        // getTimeLine(opts);
        opts = {
            ...opts, ...{
                build: 'combine',
                filters: fltrs
            }
        }
        getRelatedLogs(opts)

    }, [props.sts, props.ets])


    React.useEffect(() => {
        generateLineBar()
    }, [logTimeLine])

    React.useEffect(() => {
        return () => {
            dispatch(unsubscribeData('logs_list_tl'))
        };
    }, [])

    const requestTlLogsTQB = (sts: number, ets: number, key: string, value: string) => {
        const userQuery: Query = JSON.parse(JSON.stringify(defaultQuery));
        const resource = {
            columns: [
                "bodyFingerprint",
                "timestampMs",
                "severityText",
                "serviceName",
                "body",
                "logAttributes",
                "resource_attributes",
                "is_apm",
                "is_infra",
            ],
            resourceType: "log",
            name: `details-log-service`,
            widgetAppId: 5,
        }

        userQuery.columns = resource.columns;
        userQuery.source.name = resource.resourceType;
        const fromTs = sts;
        const toTs = ets;
        const _params = [
            { key: "from_ts", value: fromTs },
            { key: "to_ts", value: toTs }
        ]
        userQuery.with = [];

        //TODO: build == "combine" condition and check with other filters
        // for (const key in logFilters) {
        //     if (Object.prototype.hasOwnProperty.call(logFilters, key)) {
        //         const values = logFilters[key];
        //         const filters = builderFiltersInstance.In(key, values);
        //         if (filters) {
        //             const fValues = builderFiltersInstance.ConvertToValueType(filters);
        //             if (fValues) {
        //                 userQuery.with.push({
        //                     key: GLOBAL_ATTRIBUTE_FILTER,
        //                     value: fValues,
        //                     is_arg: true,
        //                 });
        //             }
        //         }
        //     }
        // }

        const identityFilter = builderFiltersInstance.Equal(key, value);
        if (identityFilter) {
            const fValues = builderFiltersInstance.ConvertToValueType(identityFilter);
            if (fValues) {
                userQuery.with.push({
                    key: GLOBAL_ATTRIBUTE_FILTER,
                    value: fValues,
                    is_arg: true,
                });
            }
        }

        const sorting = builderSortingInstance.Desc("timestampMs");
        if (sorting) {
            userQuery.with.push(builderSortingInstance.getValues([sorting]));
        }

        const offset = 0;
        const limit = 100;
        const Limit = builderLimitInstance.Limit(offset, limit);
        if (Limit) {
            userQuery.with.push(builderLimitInstance.getValues(Limit));
        }

        const widgetRequest: CustomWidget = {
            label: "GetTimeLineLogs Service",
            builderId: 0,
            scopeId: 0,
            widgetAppId: 5,
            builderConfig: userQuery,
            builderViewOptions: {
                resource,
            },
            params: _params,
        }
        const data: AddUpdateWidgetBuilder = {
            action: "add",
            body: widgetRequest,
            inflight: true,
        }

        dispatch(requestBuilderFormattedData(data, (status?: boolean, res?: any) => {

            if (res && res?.chart_data) {

                const data = res.chart_data.data;
                for (let i = 0; i < data.length; i++) {
                    data[i].severity = data[i].severityText
                    delete data[i].severityText
                    data[i].timestamp = data[i].timestampMs
                    delete data[i].timestampMs
                    data[i].id = data[i].bodyFingerprint
                    delete data[i].bodyFingerprint
                    data[i].log_attributes = data[i].logAttributes
                    delete data[i].logAttributes
                    data[i].service = data[i].serviceName
                    delete data[i].serviceName
                }
                const result = {
                    list: data,
                    message: '',
                    status: true
                }
                if (result && result.list) {
                    setLogTimeline(result.list)
                    setListLoading(false)
                }
            } else {
                return
            }
        }))
    }

    const getRelatedLogs = (opts: any) => {
        requestTlLogsTQB(opts.sts, opts.ets, opts.key, opts.value);
        setListLoading(true)
        // dispatch(requestTlLogs(opts, ((status?: boolean, res?: any) => {
        //     if (status) {
        //         setListLoading(false)
        //     }

        // })))

    }

    // const getTimeLine = (opts: any) => {

    //     dispatch(requestSingleLogTimeline(opts, ((status?: boolean, res?: any) => {
    //         if (res && Array.isArray(res)) {
    //             setLogTimeline(res)
    //             setTlLoading(false)
    //         } else setTlLoading(false)

    //     })))

    // }

    const formatDate = (ts: any, type: string): any => {
        const years = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]
        const weeks = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"];

        const date = new Date(ts);
        switch (type) {
            case 'day_format':
                return (weeks[date.getDay()] + " ," + date.getDate() + " " + years[date.getMonth()] + " " + date.getFullYear());
            case 'time_format': return formatAMPM(date);

            default: return '';

        }

    }

    const formatAMPM = (date: Date): string => {
        let hours: number = date.getHours();
        let minutes: any = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        const strTime: string = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    const convertToMomentFormat = (ts: any): string => {
        const day = new Date(ts);
        const year = day.getFullYear()
        const month = day.getMonth() + 1;
        const date = day.getDate();
        const hours = day.getHours();
        const minutes = day.getMinutes();
        const seconds = day.getSeconds();

        return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;

    }


    const generateLineValues = (level: string) => {
        const linesCount = 1440;
        // const { timeline } = currentInfo as any;
        const timeline = logTimeLine
        if (timeline && Array.isArray(timeline) && timeline.length) {
            // linesCount = timeline.length;
            const values = [];
            let count = 0;
            if (timeline.length <= 5) {
                let curr = timeline[0].timestamp
                for (let i = 0; i < 5; i++) {
                    timeline.unshift({
                        timestamp: curr - 60000,
                        value: 0
                    })
                    curr -= 60000;
                }
                curr = timeline[timeline.length - 1].timestamp
                for (let i = 0; i < 5; i++) {
                    timeline.push({
                        timestamp: curr + 60000,
                        value: 0
                    })
                    curr += 60000;
                }
            }

            for (const tl of timeline) {
                if (linesCount <= count) return values;
                const { groups } = tl;

                if (groups && Array.isArray(groups)) {
                    const f = groups[0] || [];
                    const s = groups[1] || [];
                    const x = moment(convertToMomentFormat(parseInt(tl.timestamp)));
                    const index = f.indexOf(level);
                    let val = 0;
                    if (index >= 0) {
                        val = s[index];
                    }
                    values.push({
                        xAxis: x.format('YYYY-MM-DD HH:mm:ss'),
                        yAxis: val,
                        yAxisTitle: val,
                    });
                    count++;

                }


            }
            return values;
        }

        return [];

    }
    const getColor = (type: string) => {
        switch (type) {
            case 'WARN': return 'var(--color-yellow-500)';
            case 'ERROR': return 'var(--color-red-500)';
            case 'INFO': return 'var(--color-blue-500)';
            case 'DEBUG': return 'var(--color-green-500)'
            default: return 'white'
        }

    }


    const generateLineBar: any = () => {
        const levels = ['INFO', 'WARN', 'ERROR', 'DEBUG']
        const arr = []
        let r = 1;
        for (const level of levels) {
            arr.push({
                enableXAxisBar: r == 1,
                enableYAxisBar: r == 1,
                // gradientFill: true,

                groupId: r,
                groupTitle: level,
                groupType: 'line',
                lineChartProps: {
                    chartData: [
                        {
                            lineId: r,
                            lineTitle: level,
                            lineColor: getColor(level),
                            values: generateLineValues(level)
                        }
                    ]
                }
            })

            r++;

        }

        setLineBar([...arr])
    }


    const convertGraphData = (): any => {
        const outputData: any = {
            timestamps: {
            },
            chartGroups: {
                'INFO': { 'INFO': [] },
                'WARN': { 'WARN': [] },
                'ERROR': { 'ERROR': [] },
                'DEBUG': { 'DEBUG': [] },
            }
        };

        const timeline = logTimeLine

        if (timeline && Array.isArray(timeline) && timeline.length) {


            for (const tl of timeline) {

                const { groups } = tl;

                outputData.timestamps[tl.timestamp] = true;

                if (groups && Array.isArray(groups)) {
                    const f = groups[0] || [];
                    const s = groups[1] || [];
                    const x = moment(convertToMomentFormat(parseInt(tl.timestamp)));

                    if (f && Array.isArray(f)) {
                        for (const i in f) {
                            const level = f[i];
                            outputData.chartGroups[level][level].push({
                                timestamp: tl.timestamp,
                                value: s[i],
                                config: {}

                            })
                        }
                    }

                }


            }

        }

        return outputData;
    }

    React.useEffect(() => {
        if (!isLive) {
            dispatch(unsubscribeData('logs_list_tl'))
        } else {
            logsSubscribe()
        }
    }, [isLive])

    const buildFilters = () => {
        const resAttrs = ['host.name', 'host.id', 'os.type'];
        const obj: {
            [key: string]: {
                attribute_name: string;
                value: string;
            }
        } = {}
        Object.keys(fltrs).map((key: string) => {
            if (key != 'text') {
                if (resAttrs.indexOf(key) > -1) {
                    obj['resource_attributes'] = {
                        attribute_name: key,
                        value: fltrs[key][fltrs[key].length - 1]
                    }
                } else {
                    obj['logAttributes'] = {
                        attribute_name: key,
                        value: fltrs[key][fltrs[key].length - 1]
                    }
                }
            }
        })

        return obj;
    }

    const logsSubscribe = () => {
        const data: SubscribeRequest = {
            type: "log",
            event: "logs_list_tl",
            log_requests: {
                columns: ["body", "severityText", "logAttributes", "timestampMs", "bodyFingerprint", "resource_attributes", "serviceName"],
                filters: buildFilters()
            }
        }
        dispatch(subscribeData({
            body: data
        }))
    }



    return <React.Fragment>

        {!props.onlyGrid && <div className={'log-timeline-view'}>


            <div style={{ width: "100%", height: "130px" }}>
                {/* <TimeseriesChart
                        timeseriesData={convertToTimeline(logTimeLine)}
                        nestedProps={{
                            doModification: true,
                            onSelectionChange: (data) => {

                                const { startPoint, endPoint } = data;
                                const sts = startPoint.timestamp;
                                const ets = endPoint.timestamp;
                                const obj = { ets, sts }

                                const opts: any = {
                                    key: props.attr,
                                    value: props.value,
                                    ets,
                                    sts,
                                    build: 'combine',
                                    filters: fltrs
                                }

                                setDateRange({ ...obj })
                                setListLoading(true)
                                setBrushVisible(true)
                                getRelatedLogs(opts)



                            },
                            colorGroups: {
                                'INFO': 'var(--color-blue-500)',
                                'WARN': 'var(--color-yellow-500)',
                                'ERROR': 'var(--color-red-500)',
                                'DEBUG': 'var(--color-green-500)'
                            },
                        }}
                        chartType={"area_chart"}
                    /> */}

                <BuilderView
                    filters={[getFilterInstance(fltrs)]}
                    builderViewOptions={{
                        displayScope: "",
                        resource: {
                            columns: ["any(total_info)", "any(total_errors)", "any(total_warnings)", "any(total_debug)"],
                            resourceType: "log",
                            name: `log-timeline-service`,
                            widgetAppId: 1,
                            granularity: {
                                value: granularityInterval(
                                    routeData.params.dateRange.fromTs || 0,
                                    routeData.params.dateRange.toTs || 0
                                ),
                            },
                        },
                    }}
                    dateRange={{
                        fromTs: routeData.params.dateRange.fromTs || 0,
                        toTs: routeData.params.dateRange.toTs || 0,
                    }}
                    nestedProps={{
                        hideFilterAction: true,
                        allowAllLegend: true,
                        columnConfig: {
                            ["any(total_info)"]: {
                                title: "Info",
                                color: "var(--color-blue-500)",
                            },
                            ["any(total_errors)"]: {
                                title: "Error",
                                color: "var(--color-red-500)",
                            },
                            ["any(total_warnings)"]: {
                                title: "Warnings",
                                color: "var(--color-yellow-500)",
                            },
                            ["any(total_debug)"]: {
                                title: "Debug",
                                color: "var(--color-green-500)",
                            },
                        },
                        stackId: "1",
                        onSelectionChange: (data: any) => {
                            routeData.actions.handleTimeRange(data.startPoint.timestamp, data.endPoint.timestamp);
                        }
                    }}
                />
            </div>


        </div>}


        {!props.onlyGrid && <Card bordered={false} style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between' }}>

            <div>SELECT <span style={{ color: 'rgb(0, 128, 128)' }}>&quot;Logs&quot;</span> WHERE <span style={{ fontWeight: 600 }}>{`${props.attr}`}</span>  =  <span style={{ fontWeight: 600, color: 'var(--color-blue-400)' }}>{`${props.value}`}</span></div>
            <div
                className={`indicator__badge ${isLive && "is_success"}`}
                onClick={() => {

                    setIsLive(!isLive)
                }}
            >
                <span className={"indicator__icon"} />
                <span className={"indicator__text"}>
                    {isLive ? "Live" : "History"}
                </span>
            </div>
        </Card>}
        {/* {!props.onlyGrid && <Card bordered={false} style={{ textAlign: 'center' }}>
            <div>SELECT <span style={{ color: 'rgb(0, 128, 128)' }}>"Logs"</span> WHERE <span style={{ fontWeight: 600 }}>{`${props.attr}`}</span>  =  <span style={{ fontWeight: 600, color: 'var(--color-blue-400)' }}>{`${props.value}`}</span></div>
        </Card>}
        {!props.onlyGrid && <Card bordered={false} style={{ textAlign: 'center' }}>
            <div
                className={`indicator__badge ${isLive && "is_success"}`}
                onClick={() => {

                    setIsLive(!isLive)
                }}
            >
                <span className={"indicator__icon"} />
                <span className={"indicator__text"}>
                    {isLive ? "Live" : "History"}
                </span>
            </div>
        </Card>} */}
        {!props.onlyGrid && <Card bordered={false} borderRadius={false} noPadding style={{ width: '100%' }}>
            <SuggestionInput options={filterOptions || {}}
                placeHolder={'Query Related Logs'}
                disableChange={props.disableChange ? true : false}
                onChange={(f: any) => {

                    const opts = {
                        key: props.attr,
                        value: props.value,
                        ets: dateRange.ets,
                        sts: dateRange.sts,
                        build: 'combine',
                        filters: f

                    }
                    setFilters({ ...f })
                    getRelatedLogs(opts)
                }}
                onChipSelect={(val: string) => {
                    // if (val && typeof val == 'string') {
                    //     this.props.setSelectedFilter({
                    //         key: val
                    //     })
                    // }
                }}
                selectedChips={fltrs}

            />
        </Card>}

        <Card style={{ width: '100%', overflowY: 'scroll' }} className={'related-logs'}>
            <div className={'logs-grid-view'}>
                <TableGridview
                    columns={columns}
                    dataProvider={listLoading ? listLoaders : logs}
                />
            </div>
        </Card>

        <Dialog
            isOpen={infoOpen}
            position={"right"}
            size={'md'}
            onClose={() => {
                setInfoOpen(false)
                setLogId(0);
            }}>

            <LogInfoPanel
                id={logId}
                themeMode={props.themeMode || 'light'}
                isLogScreen={false}
                ets={logTs}
                navigate={navigate}

            />

        </Dialog>
    </React.Fragment>
}
export default LogsIntegration