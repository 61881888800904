/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as React from "react";
import Tooltip from "core/components/v2/tooltip";
import classNames from "classnames";
import CustomLegend from "core/components/v2/charts/legends";
import {
  HexagonsProps,
  defSize,
  defMargin,
  HexagonsStatusType,
  HexagonsStatus,
  defMarginBottomMultiply,
  defHeightMultiply,
} from ".";
import {
  Group,
  getTitleName,
} from "core/components/v2/charts/components/hexagon-chart";
import { LegendType } from "core/components/v2/charts/models";

export const HexagonsGrid = (props: HexagonsProps) => {
  const { items, onClick, onHover, getBgColor, showLegend = true } = props;
  const [hexagonItems, setHexagonItems] = React.useState<
    (Group & {
      visible?: boolean;
      status?: HexagonsStatusType;
    })[]
  >([]);
  const [mouseEnter, setMouseEnter] = React.useState<boolean>(false);
  const [mouseEnterOn, setMouseEnterOn] = React.useState<number | null>(null);
  React.useEffect(
    () =>
      setHexagonItems(
        [...(items || [])].map(
          (
            it
          ): Group & {
            visible?: boolean;
            status?: HexagonsStatusType;
          } => ({
            ...it,
            visible: true,
          })
        )
      ),
    [items]
  );
  const handleVisibleChartsSeries = (name: string) => {
    const copyGrid = [...hexagonItems];
    const foundItem = copyGrid.findIndex((it) => it.name === name);
    if (foundItem > -1) {
      copyGrid[foundItem].visible = !copyGrid[foundItem].visible;
      setHexagonItems(copyGrid);
    }
  };
  const size = props?.size && props.size > defSize ? props.size : defSize;
  const margin =
    props?.margin && props.margin > defMargin ? props.margin : defMargin;
  const styles: Record<string, string> = {
    "--size": size + "px",
    "--margin": margin + "px",
    "--shape": 1.732 * size + 4 * margin - 1 + "px",
  };
  return (
    <div className={"hexagon-chart-component"}>
      <div
        className={classNames("hexagon__container", {
          "is-selection":
            props?.selectedValue && props.selectedValue !== "" ? true : false,
        })}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
        style={styles}
      >
        <span
          className={"hexa__separator"}
          style={{
            width: `calc(var(--size) / 2 + var(--margin))`,
            shapeOutside: `repeating-linear-gradient(#0000, #0000 calc(var(--shape) - 3px), #000 var(--shape))`,
          }}
        />

        {hexagonItems.length > 0 &&
          hexagonItems.map((d, index: number) => {
            if (!d.visible) {
              return null;
            }
            let _bgColor = "";
            if (d?.status) {
              const _status: HexagonsStatusType = d.status;
              if (typeof HexagonsStatus[_status] !== "undefined")
                _bgColor = HexagonsStatus[_status];
            }
            if (getBgColor && typeof getBgColor === "function") {
              _bgColor = getBgColor(index, d);
            }
            if (_bgColor == "") _bgColor = HexagonsStatus.default;

            const _style: {
              background: string;
              width: string;
              margin: string;
              height: string;
              cursor: string;
              opacity: number;
            } = {
              background: _bgColor,
              width: `var(--size)`,
              margin: `var(--margin) var(--margin) calc(var(--margin) - var(--size) * ${defMarginBottomMultiply}) var(--margin) `,
              height: `calc(var(--size) * ${defHeightMultiply})`,
              //marginBottom: `calc(var(--margin) - var(--size) * ${defMarginBottomMultiply})`,
              cursor: onClick ? "pointer" : onHover ? "pointer" : "default",
              opacity: mouseEnter ? (mouseEnterOn === index ? 1 : 0.5) : 1,
            };
            const _attr: {
              style: {
                background: string;
                width: string;
                margin: string;
                height: string;
                cursor: string;
              };
              "data-tip"?: boolean;
              "data-for"?: string;
            } = {
              style: _style,
            };
            let _content = <></>;
            if (typeof props?.tooltipTemplate == "function") {
              _content = props.tooltipTemplate(index, d) as React.JSX.Element;
            }
            const tTitle = getTitleName(d.name);
            return (
              <React.Fragment key={index}>
                <Tooltip
                  content={
                    <div className="highcharts_tooltip_custom hexagon-tooltip">
                      <div className="date_block">
                        <b>{tTitle}</b>
                      </div>
                      {_content}
                    </div>
                  }
                  position="right"
                >
                  <div
                    className={classNames("hexagon__item", {
                      "is-selected":
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        props?.selectedValue === (d as any)[props?.selectedKey]
                          ? true
                          : false,
                    })}
                    {..._attr}
                    onMouseEnter={() => {
                      setMouseEnterOn(index);
                      onHover && onHover(index, d);
                    }}
                    onMouseLeave={() => {
                      setMouseEnterOn(null);
                    }}
                    onClick={() => onClick && onClick(index, d)}
                  />
                </Tooltip>
              </React.Fragment>
            );
          })}
        {showLegend && (
          <CustomLegend
            items={[...hexagonItems].map(
              (it): LegendType => ({
                name: it.name,
                color: it.color ? it.color : "",
                visible: it.visible ? it.visible : false,
              })
            )}
            handleLegendItemClick={handleVisibleChartsSeries}
          />
        )}
      </div>
    </div>
  );
};
