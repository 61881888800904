import * as React from "react";
import NavTab from "core/components/nav-tab";
import TabItems from "core/components/nav-tab/items";
import {
    BashSVG, BatSVG, InstallationDocsIcon, KubernetesColorSvgV2,
    LinuxIcon, PowerShellSVG, WindowsSvg
} from "core/components/svg/icons";
import KubernetesInstallation from "views/modules/installation/components/kubernetes-installation";
import {Link} from "react-router-dom";
import TableGridview, {GridviewColumnsOptions} from "core/components/table-gridview";
import Tooltip from "core/components/tooltip";
import {useRef} from "react";

export default function K8sInfraInstrument() {
    const tooltipParentRef = useRef<HTMLDivElement>(null)

    const dataProviderMetric = [
        {
            continue: 'k8s.container',
            item: [
                <span>container.cpu.time</span>,
                'CPU Time',
                'Container CPU time\n'
            ]
        },
        {
            continue: 'k8s.container',
            item: [
                <span>container.cpu.utilization</span>,
                'CPU (%)\n',
                'Container CPU utilization\n'
            ]
        },
        {
            continue: 'k8s.container',
            item: [
                <span>container.filesystem.available</span>,
                'Container Filesystem Available\n',
                'Container filesystem available\n'
            ]
        },
        {
            continue: 'k8s.container',
            item: [
                <span>container.filesystem.capacity</span>,
                'Container Filesystem Capacity\n',
                'Container filesystem capacity\n'
            ]
        },
        {
            continue: 'k8s.container\n',
            item: [
                <span>container.filesystem.usage</span>,
                'Container Filesystem Usage\n',
                'Container filesystem usage\n'
            ]
        },
        {
            continue: 'k8s.container\n',
            item: [
                <span>container.filesystem.utilization</span>,
                '-',
                'Container filesystem utilization\n'
            ]
        },
        {
            continue: 'k8s.container\n',
            item: [
                <span>container.id</span>,
                '-',
                'The container id.\n'
            ]
        },
        {
            continue: 'k8s.container\n',
            item: [
                <span>container.image.name</span>,
                '-',
                'The container image name\n'
            ]
        },
        {
            continue: 'k8s.container\n',
            item: [
                <span>container.image.tag</span>,
                '-',
                'The container image tag\n'
            ]
        },
        {
            continue: 'k8s.container\n',
            item: [
                <span>container.memory.available</span>,
                'Container Memory Available\n',
                'Container memory available\n'
            ]
        },
        {
            continue: 'k8s.container\n',
            item: [
                <span>container.memory.major_page_faults</span>,
                'Memory Major Page Faults\n',
                'Container memory major_page_faults\n'
            ]
        },
        {
            continue: 'k8s.container\n',
            item: [
                <span>container.memory.page_faults</span>,
                'Memory Page Faults\n',
                'Container memory page_faults\n'
            ]
        },
        {
            continue: 'k8s.container\n',
            item: [
                <span>container.memory.rss</span>,
                'Container Memory RSS\n',
                'Container memory rss\n'
            ]
        },
        {
            continue: 'k8s.container\n',
            item: [
                <span>container.memory.usage</span>,
                'Memory Usage\n',
                'Container memory usage\n'
            ]
        },
        {
            continue: 'k8s.container\n',
            item: [
                <span>container.memory.working_set</span>,
                'Memory Working Set\n',
                'Container memory working_set\n'
            ]
        },
        {
            continue: 'k8s.container\n',
            item: [
                <span>k8s.cluster.name</span>,
                '-',
                'The k8s cluster name.\n'
            ]
        },
        {
            continue: 'k8s.container\n',
            item: [
                <span>k8s.container.cpu_limit</span>,
                'CPU Limit\n',
                'Maximum resource limit set for the container. See https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.23/#resourcerequirements-v1-core for details\n'
            ]
        },
        {
            continue: 'k8s.container\n',
            item: [
                <span>k8s.container.cpu_limit_utilization</span>,
                '-',
                'Container cpu utilization as a ratio of the container\'s limits\n'
            ]
        },
        {
            continue: 'k8s.container\n',
            item: [
                <span>k8s.container.cpu_request</span>,
                '-',
                'Resource requested for the container. See https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.23/#resourcerequirements-v1-core for details\n'
            ]
        },
        {
            continue: 'k8s.container\n',
            item: [
                <span>k8s.container.cpu_request_utilization</span>,
                '-',
                'Container cpu utilization as a ratio of the container\'s requests\n'
            ]
        }
    ];
    const columnsMetric: Array<GridviewColumnsOptions> = [
        {
            title: "Resource",
            value: (data: any) => {
                return data.continue;
            }
        },
        {
            title: "Key",
            value: (data: any) => {
                return data.item[0];
            }
        },
        {
            title: "Label",
            value: (data: any) => {
                return data.item[1];
            }
        },
        {
            title: "Description",
            value: (data: any,columnIndex ?:number, rowIndex ?: number) => {

                const parentWidth = tooltipParentRef.current ? tooltipParentRef.current.clientWidth : 500

                const approxLetterWid = 9
                const charactersPerLine = Math.floor(parentWidth / approxLetterWid);
                const truncatedValue = data.item[2]?.length > charactersPerLine ? data.item[2]?.slice(0, charactersPerLine) + "..." : data.item[2];

                return <div ref={tooltipParentRef}>
                    <span data-tip data-for={"tooltip" + rowIndex}>{truncatedValue}</span>
                    {data.item[2]?.length > charactersPerLine && <Tooltip id={"tooltip" + rowIndex} place="top" type="dark" effect="solid">
                        <span>{data.item[2]}</span>
                    </Tooltip>}
                </div>
            }
        },
    ];

    return <>
        <div className={'jira-integration-new dialog-table-componants'}>
            <div className={' setting_side-panel'}>
                <div className={'integration-popup'}>
                    <div className={'header-section'}>
                        <div className={'d-flex'}>
                            <KubernetesColorSvgV2/>
                            <div className={'header-content'}>
                                <h3>Kubernetes</h3>
                                <p>Kubernetes is an open-source container orchestration platform that helps you deploy,
                                    manage and scale your software. Monitor all of your clusters, nodes, services,
                                    namespaces, workloads and more.</p>
                            </div>
                            <button className={"docs-link"}><Link
                                to={'https://docs.middleware.io/docs/agent-installation/kubernetes'}
                                target={'_blank'}><span> <InstallationDocsIcon/>Documentation</span></Link></button>
                        </div>
                        <NavTab targetIndex={1} defaultActiveTabIndex={1}>
                            <TabItems title={'Overview'}>
                                <div className={'d-flex in-content'}>
                                    <div className={'overview-heading'}>
                                        <div className={'config-overview'}>
                                            <h4>Category</h4>
                                            <span>Infrastructure</span><br/>
                                            <span>Orchestration</span>

                                            <h4 className={'tp-mr'}>Supported OS</h4>
                                            <div className={'d-flex'}>
                                                <LinuxIcon/>
                                                <WindowsSvg/>
                                                {/*<MacIcon/>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'overview-content'}>
                                        <p>The Kubernetes Infra Agent will set up a DaemonSet inside your Kubernetes
                                            cluster and add necessary components to appropriately permission the
                                            DaemonSet pods. A Kubernetes service is also created to allow components to
                                            connect to the Infra Agent via Cluster IP address and ports (i.e.
                                            language-based APMs).</p>
                                        <div className={'overview-image'}>
                                            <img src={'assets/images/k8s-overview.png'}
                                                 alt={'bare-metal-overview'}/>
                                        </div>
                                        <div className={'features-dialog-instrumentation'}>
                                            <h3>Features</h3>
                                            <div className={'d-flex'}>
                                                <button className={'example-linux'}>.bat Install</button>
                                                <button className={'example-linux'}>Bash Install</button>
                                                <button className={'example-linux'}>Disk Persistence</button>
                                                <button className={'example-linux'}>Memory Usage</button>
                                                <button className={'example-linux'}>Performance Metrics</button>
                                            </div>
                                        </div>


                                        <div className={'features-dialog-instrumentation'}>
                                            <h3>Supported Distributions</h3>
                                            <div className={'d-flex'}>
                                                <button className={'example-linux'}><BatSVG/></button>
                                                <button className={'example-linux'}><BashSVG/></button>
                                                <button className={'example-linux'}><PowerShellSVG/></button>
                                            </div>
                                        </div>
                                        <div className={'overview-table'}>
                                            <h3 className={'mr-top'}>Metrics Collected</h3>
                                            <div className={'table-metric-collect'}>
                                            <TableGridview
                                                columns={columnsMetric}
                                                dataProvider={dataProviderMetric}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabItems>
                            <TabItems title={'Installation'}>
                                <div className={'panel-body'}>
                                    <KubernetesInstallation/>
                                </div>

                            </TabItems>


                        </NavTab>
                    </div>
                </div>

            </div>
        </div>
    </>
}