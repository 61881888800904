import { TimeRangeOption } from "core/components/datepicker/model";
import Button from "core/components/v2/button";
import FormToggle from "core/components/v2/form/form-toggle";
import NavTab from "core/components/v2/nav-tab";
import TabItems from "core/components/v2/nav-tab/items";
import { RefreshIcon } from "core/components/v2/svg/icons";
import Tooltip from "core/components/v2/tooltip";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { doRefresh } from "store/actions";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import { BreadcrumbsListItem, BreadcrumbsWrapper } from "../../../breadcrumbs";
import GlobalDatePicker from "../global-date-picker";
import "./_style.scss";
import { GlobalHeaderProps } from "./model";

const GlobalHeader = (headerProps: GlobalHeaderProps) => {
  const {
    tabs = [],
    breadcrumbs,
    extraChildren,
    handleRefreshClick,
    activeTabIndex = 0,
    showLiveOrHistoryToggle = true,
    showDatePicker = true,
    showRefreshBtn = true,
    onTabChange,
    preFixChildren,
    dataTestIdDatePicker,
  } = headerProps;
  const routeData = React.useContext(MwRouteContext);
  const { params, actions } = routeData;
  const { isLive } = params;
  const { handleGoLive, handleGoOffline } = actions;
  const dispatch = useDispatch();
  const handleRefresh = () => {
    if (
      routeData.params.dateRange.range &&
      routeData.params.dateRange.range !== TimeRangeOption.Custom
    ) {
      routeData.actions.handleRefreshClick();
    } else {
      dispatch(doRefresh());
    }
    if (typeof handleRefreshClick === "function") handleRefreshClick();
  };

  return (
    <div
      className={`${tabs && tabs.length > 0 ? "main-tabs__context" : "main-breadcrumbs__context"} global-header--wrapper`}
    >
      {preFixChildren}
      <BreadcrumbsWrapper>
        {tabs && tabs.length > 0 ? (
          <NavTab targetIndex={activeTabIndex} onTabChange={onTabChange}>
            {tabs.map((tab) => (
              <TabItems
                title={tab.title}
                prefixIcon={tab.prefixicon}
                key={tab.title}
                suffixNode={tab.suffixNode}
              />
            ))}
          </NavTab>
        ) : (
          breadcrumbs &&
          breadcrumbs.length > 0 &&
          breadcrumbs.map((item, index) => {
            if (item.label) {
              return (
                <BreadcrumbsListItem
                  key={index}
                  classname={"active"}
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {item.link ? (
                    <Link
                      to={item.link}
                      onClick={() => {
                        if (
                          typeof headerProps.handleBreadcrumbClick ===
                          "function"
                        ) {
                          headerProps.handleBreadcrumbClick(item.label);
                        }
                      }}
                    >
                      {item.label}
                    </Link>
                  ) : (
                    item.label
                  )}
                </BreadcrumbsListItem>
              );
            }
          })
        )}
      </BreadcrumbsWrapper>

      <div className={"breadcrumbs__filter__wrapper"}>
        <div className={"block_group__item"}>{headerProps.children}</div>
        <div className={"block_group__item"}>
          {showLiveOrHistoryToggle && (
            <div
              className={`live_history_section ${isLive && "is_success"}`}
              onClick={() => {
                if (isLive) {
                  handleGoOffline();
                } else {
                  handleGoLive();
                }
              }}
            >
              <span
                className={`indicator__text ${!isLive ? "not_active" : ""}`}
              >
                {"Live"}
              </span>
              <FormToggle
                checked={!isLive}
                label="History"
                onChange={handleRefresh}
                id={""}
              />
              <span className={`indicator__text ${isLive ? "not_active" : ""}`}>
                {"History"}
              </span>
            </div>
          )}
          {showRefreshBtn && (
            <Tooltip position="bottom" content={"Refresh"}>
              <div>
                <Button
                  onClick={handleRefresh}
                  prefixicon={<RefreshIcon size={16} />}
                  disabled={isLive}
                >
                  Update
                </Button>
              </div>
            </Tooltip>
          )}
        </div>
        {showDatePicker && (
          <div className={"block_group__item"}>
            {<GlobalDatePicker dataTestId={dataTestIdDatePicker} />}
          </div>
        )}
        {extraChildren && (
          <div className={"block_group__item"}>{extraChildren}</div>
        )}
      </div>
    </div>
  );
};
export default GlobalHeader;
