import React from "react";
import { NoDataIcon } from "../svg/icons";

interface NoDataSpaceProps {
  iconSize?: number;
  displayInline?: boolean;
  exmpySpaceText?: string;
}

const NoDataSpace = (props: NoDataSpaceProps) => {
  const { iconSize = 60 } = props;
  let exmpySpaceText = "No data found during this current time period.";
  if (props.exmpySpaceText) {
    exmpySpaceText = props.exmpySpaceText;
  }
  return (
    <div className={`no-data-space ${props.displayInline ? "" : "as-column"}`}>
      <NoDataIcon size={iconSize} />
      <span title={exmpySpaceText}>{exmpySpaceText}</span>
    </div>
  );
};

export default NoDataSpace;
