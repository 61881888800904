import * as React from "react";
import NavTab from "core/components/nav-tab";
import TabItems from "core/components/nav-tab/items";
import {
    DockerNewIcon, InstallationDocsIcon,
    LinuxIcon,
    WindowsSvg
} from "core/components/svg/icons";
import DockerInstallation from "views/modules/installation/components/docker-installation";
import {Link} from "react-router-dom";
import TableGridview, {GridviewColumnsOptions} from "core/components/table-gridview";
import Tooltip from "core/components/tooltip";
import {useRef} from "react";

export default function DockerInfraInstrument() {

    const tooltipParentRef = useRef<HTMLDivElement>(null)

    const dataProviderProfiling = [
        {
            continue: 'container',
            item: [
                <span>container.cpu.usage.system</span>,
                'Usage System',
                'System CPU usage, as reported by docker.'
            ]
        },
        {
            continue: 'container',
            item: [
                <span>container.image.name</span>,
                'Image',
                'The name of the docker image in use by the container.'
            ]
        },
        {
            continue: 'container',
            item: [
                <span>container.runtime</span>,
                'Runtime',
                'The runtime of the container. For this receiver, it will always be \'docker\'.'
            ]
        },
    ];
    const columnsProfiling: Array<GridviewColumnsOptions> = [
        {
            title: "Resource",
            value: (data: any) => {
                return data.continue;
            }
        },
        {
            title: "Key",
            value: (data: any) => {
                return data.item[0];
            }
        },
        {
            title: "Label",
            value: (data: any) => {
                return data.item[1];
            }
        },
        {
            title: "Description",
            value: (data: any,columnIndex ?:number, rowIndex ?: number) => {

                const parentWidth = tooltipParentRef.current ? tooltipParentRef.current.clientWidth : 500

                const approxLetterWid = 9
                const charactersPerLine = Math.floor(parentWidth / approxLetterWid);
                const truncatedValue = data.item[2]?.length > charactersPerLine ? data.item[2]?.slice(0, charactersPerLine) + "..." : data.item[2];

                return <div ref={tooltipParentRef}>
                    <span data-tip data-for={"tooltip" + rowIndex}>{truncatedValue}</span>
                    {data.item[2]?.length > charactersPerLine && <Tooltip id={"tooltip" + rowIndex} place="top" type="dark" effect="solid">
                        <span>{data.item[2]}</span>
                    </Tooltip>}
                </div>
            }
        },
    ];


    return <>
        <div className={'jira-integration-new dialog-table-componants'}>
            <div className={' setting_side-panel'}>
                <div className={'integration-popup'}>
                    <div className={'header-section'}>
                        <div className={'d-flex'}>
                            <DockerNewIcon/>
                            <div className={'header-content'}>
                                <h3>Docker</h3>
                                <p>Docker is a platform for developing, shipping, and running applications with
                                    standardized units called containers. Monitor all of your containers, volumes,
                                    images, and more.</p>
                            </div>
                            <button className={"docs-link"}><Link
                                to={'https://docs.middleware.io/docs/agent-installation/docker'}
                                target={'_blank'}><span> <InstallationDocsIcon/>Documentation</span></Link></button>
                        </div>
                        <NavTab targetIndex={1} defaultActiveTabIndex={1}>
                            <TabItems title={'Overview'}>
                                <div className={'d-flex in-content'}>
                                    <div className={'overview-heading'}>
                                        <div className={'config-overview'}>
                                            <h4>Category</h4>
                                            <span>Infrastructure</span><br/>
                                            <span>Container</span>

                                            <h4 className={'tp-mr'}>Supported OS</h4>
                                            <div className={'d-flex'}>
                                                <LinuxIcon/>
                                                <WindowsSvg/>
                                                {/*<MacIcon/>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'overview-content'}>
                                        <p>The Docker Infra Agent is a containerized version of the Infra Agent. Upon
                                            installation, the Infra Agent collects host level details from host network
                                            mode and uses volume binding for reading logs.</p>
                                        <div className={'overview-image'}>
                                            <img src={'assets/images/bare-metal-overview.png'}
                                                 alt={'bare-metal-overview'}/>
                                        </div>
                                        <div className={'features-dialog-instrumentation'}>
                                            <h3>Features</h3>
                                            <div className={'d-flex'}>
                                                <button className={'example-linux'}>Disk Persistence</button>
                                                <button className={'example-linux'}>Memory Usage</button>
                                                <button className={'example-linux'}>Performance Metrics</button>
                                                <button className={'example-linux'}>Container Monitoring</button>
                                            </div>
                                        </div>
                                        <div className={'overview-table'}>
                                            <h3 className={'mr-top'}>Metrics Collected</h3>
                                            <div className={'table-metric-collect docker-table-td'}>
                                            <TableGridview
                                                columns={columnsProfiling}
                                                dataProvider={dataProviderProfiling}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabItems>
                            <TabItems title={'Installation'}>
                                <div className={'panel-body'}>
                                    <DockerInstallation/>
                                </div>

                            </TabItems>

                        </NavTab>
                    </div>
                </div>

            </div>
        </div>
    </>
}