import * as React from "react";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { CollapseIconSvg, ExpandIconSvg } from "core/components/svg/icons";

interface Props {
  heading?: string;
  primary?: boolean;
  secondary?: boolean;
  className?: string;
  children?: any;
  defaultActive?: boolean;
  extraLabel?: string;
  arrowPosition?: "left" | "right";
  extraHeaderElement?: any;
  isDisabled?: boolean;
  headingElem?: any;
  icon?: any;
  onToggle?: (toggle: boolean) => void;
  onClick?: (event: any) => void;
}
const Accordion = (props: Props) => {
  let arrowPosition = props.arrowPosition ? props.arrowPosition : "right";
  const [isActive, setIsActive] = useState(props.defaultActive ? true : false);
  const className = classNames("accordion-item", props.className, {
    "is-primary": props.primary,
    active: isActive,
    "is-default": !props.primary && !props.secondary,
    "is-secondary": props.secondary,
    "has-extra_item": props.extraLabel || props.extraHeaderElement,
    "arrow-left": arrowPosition === "left",
    "is-disabled": props.isDisabled,
  });

  const toggleDiv = () => {
    setIsActive(!isActive);
    props.onToggle && props.onToggle(!isActive);
  };
  useEffect(() => {
    if (props.defaultActive != undefined) {
      setIsActive(props.defaultActive);
    }
  }, [props.defaultActive]);

  return (
    <div className={className}>
      {props.headingElem && (
        <div style={{ marginLeft: "10px" }}>{props.headingElem}</div>
      )}
      <div
        className={"accordion-toggle accordion-title"}
        onClick={() => !props.isDisabled && toggleDiv()}
      >
        {props.icon && <div className={"accordion-icon-one"}>{props.icon}</div>}
        {props.primary && (
          <span className={"accordion-icon"}>
            {isActive ? <CollapseIconSvg /> : <ExpandIconSvg />}
          </span>
        )}
        <h3>{props.heading}</h3>
        {/* {props.headingElem && <div>{props.headingElem}</div>} */}
        <div
          className={"extra_items"}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {props.extraLabel && (
            <span className={"extra-label"}>{props.extraLabel}</span>
          )}
          {props.extraHeaderElement && (
            <span className={"extra-header-element"}>
              {props.extraHeaderElement}
            </span>
          )}
        </div>
      </div>
      <div className="accordion-content">{props.children}</div>
    </div>
  );
};

export default Accordion;
