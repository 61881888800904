export const SET_APM_DATE_RANGE = "SET_APM_DATE_RANGE";
export const SET_SPAN_FILTER_LABELS = "SET_SPAN_FILTER_LABELS";
export const SET_DB_SPAN_FILTER_LABELS = "SET_DB_SPAN_FILTER_LABELS";
export const SPAN_LABELS_DATA_RECEIVED = "SPAN_LABELS_DATA_RECEIVED";
export const SPAN_LABEL_DATA_UPDATE = "SPAN_LABEL_DATA_UPDATE";
export const SPAN_DB_LABEL_DATA_UPDATE = "SPAN_DB_LABEL_DATA_UPDATE";
export const UPDATE_DB_SEARCH_LABEL = "UPDATE_DB_SEARCH_LABEL";
export const UPDATE_SEARCH_LABEL = "UPDATE_SEARCH_LABEL";
export const WS_TRACE_LIST_RECEIVED = "WS_TRACE_LIST_RECEIVED";
export const DB_TRACE_LIST_RECEIVED = "DB_TRACE_LIST_RECEIVED";
export const ERROR_TRACE_LIST_RECEIVED = "ERROR_TRACE_LIST_RECEIVED";
export const PANEL_ERROR_GROUP_LIST_RECEIVED =
  "PANEL_ERROR_GROUP_LIST_RECEIVED";
export const RUM_SESSION_RECEIVED = "RUM_SESSION_RECEIVED";
export const ERROR_FILTER_RECEIVED = "ERROR_FILTER_RECEIVED";
export const TRACE_LOGS_RECEIVED = "TRACE_LOGS_RECEIVED";
export const TRACE_ERRORS_RECEIVED = "TRACE_ERRORS_RECEIVED";
export const TOGGLE_FILTER_VALUES = "TOGGLE_FILTER_VALUES";
export const SELECT_FILTER_VALUES = "SELECT_FILTER_VALUES";
export const SEARCH_VALUE_SELECT = "SEARCH_VALUE_SELECT";
export const SEARCH_DB_VALUE_SELECT = "SEARCH_DB_VALUE_SELECT";
export const REMOVE_SEARCH_TAG = "REMOVE_SEARCH_TAG";
export const REMOVE_DB_SEARCH_TAG = "REMOVE_DB_SEARCH_TAG";

// Profiling tabs
export const PROFILING_APPS_RECEIVED = "PROFILING_APPS_RECEIVED";
export const PROFILING_LABELS_RECEIVED = "PROFILING_LABELS_RECEIVED";
export const PROFILING_DATA_RECEIVED = "PROFILING_DATA_RECEIVED";

