import {createContext} from "react";
import {Application, StatusPageData, User} from "core/application/model";

export interface ChangeContextProps {
    key: 'isAuthenticated' | 'user',
    value: User | boolean | undefined,
}

export interface ContextProps {
    changeContext: (obj: ChangeContextProps) => any;
    application: Application;
    isAuthenticated: boolean;
    user: User | undefined;
    statusPageData?: StatusPageData | undefined;
}

const AppContext = createContext({} as ContextProps);
export default AppContext;