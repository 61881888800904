export const DASHBOARD_CARD_TOGGLES_SETTINGS_KEY = 'dashboard_cards_toggle_updates_newer';
export const SET_INDICATOR_REQUEST = 'SET_INDICATOR_REQUEST';
export const SET_INDICATOR_HITS_REQUEST = 'SET_INDICATOR_HITS_REQUEST';
export const SET_FILTERS_STATE_REQUEST = 'SET_FILTERS_STATE_REQUEST';
export const TIMELINE_RECEIVED = 'TIMELINE_RECEIVED';
export const UNIFIED_DASHBOARD_RECEIVED = 'UNIFIED_DASHBOARD_RECEIVED';
export const RESET_DASHBOARD_INFO = 'RESET_DASHBOARD_INFO';
export const DASHBOARD_PORTION_RECEIVED = 'DASHBOARD_PORTION_RECEIVED';
export const WS_UNIFIED_DASHBOARD_RECEIVED = 'WS_UNIFIED_DASHBOARD_RECEIVED';

export const DEFAULT_CARD_TOGGLES = {
    metrics: ["machines", "overview", "cpu_usage", "ram_usage", "network_usage", "disk_usage", "filesystem_usage", "latency_wise", "request_count", "throughput"],
    logs: ["ratio", "list"],
    traces: ["ratio", "list"],
} as object;

export const FIXED_INFO_VAL = {
    metrics: {
        overview: null,
        cards: null,
    },
    logs: {
        overview: null,
        cards: null,
    },
    traces: {
        overview: null,
        cards: null,
    }
} as object;

export const FILTER_ITEMS = {
    metrics: {
        header: {
            filters: {
                op1: 'Option 1',
                op2: 'Option 2',
                op3: 'Option 3',
            },
            sections: {
                machines: 'Machines',
                overview: 'Overview',
                cpu_usage: 'CPU Usage',
                ram_usage: 'RAM Usage',
                network_usage: 'Network Usage',
                disk_usage: 'Disk Usage',
                filesystem_usage: 'Filesystem Usage',
                // latency_wise: 'Latency Wise',
                // request_count: 'Request Count',
                // throughput: 'Throughput',
            }
        },
        machines: {
            high_usage: 'High Usage',
            low_usage: 'Low Usage',
        },
        overview: {
            high_usage: 'High Usage',
            low_usage: 'Low Usage',
        },
        cpu_usage: {
            high_usage: 'High Usage',
            low_usage: 'Low Usage',
        },
        ram_usage: {
            high_usage: 'High Usage',
            low_usage: 'Low Usage',
        },
        network_usage: {
            high_usage: 'High Usage',
            low_usage: 'Low Usage',
        },
        disk_usage: {
            high_usage: 'High Usage',
            low_usage: 'Low Usage',
        },
        filesystem_usage: {
            high_usage: 'High Usage',
            low_usage: 'Low Usage',
        },
        latency_wise: {
            top5: 'Top 5 Process',
            least5: 'Least 5 Process',
        },
        request_count: {
            top5: 'Top 5 Machines',
            least5: 'Least 5 Machines',
        },
        throughput: {
            top5: 'Top 5 Machines',
            least5: 'Least 5 Machines',
        }
    },
    logs: {
        header: {
            filters: {
                op1: 'Option 1',
                op2: 'Option 2',
                op3: 'Option 3',
            },
            sections: {
                ratio: 'Log Ratio',
                list: 'Log List',
            }
        },
        ratio: {
            all: 'All Ratio',
            error: 'Error Ratio',
            warn: 'Warning Ratio',
            debug: 'Debug Ratio',
            info: 'Info Ratio',
        },
        list: {
            all: 'All Logs',
            infra: 'Infra Logs',
            app: 'App Logs',
            high: 'High Counts',
            low: 'Low Counts',
            error: 'Errors Logs',
            warn: 'Warnings Logs',
            debug: 'Debug Logs',
            info: 'Info Logs',
        },
    },
    traces: {
        header: {
            filters: {
                op1: 'Option 1',
                op2: 'Option 2',
                op3: 'Option 3',
            },
            sections: {
                ratio: 'Trace Ratio',
                list: 'Trace List',
            }
        },
        ratio: {
            all: 'All Ratio',
            error: 'Error Ratio',
            ok: 'Ok Ratio',
        },
        list: {
            all: 'All Traces',
            app: 'App Traces',
            web: 'Web Traces',
            error: 'Errors Traces',
            ok: 'Ok Traces',
            /*high: 'High Latency',
            low: 'Low Latency',*/
        },
    }
} as object;

export const DASH_DESIGN = {
    metrics: {
        label: 'Metrics',
        filters: [
            {
                type: 'action_button',
                icon: 'filter',
                value: '',
                items: [
                    'Option 1',
                    'Option 2',
                    'Option 3'
                ]
            },
            {
                type: 'action_button',
                icon: 'plus',
                value: '',
                items: [
                    'Option 1',
                    'Option 2',
                    'Option 3'
                ]
            }
        ],
        overview: {
            view0: {
                label: 'incl. #DOWN_MACHINES# Down Machine',
                value: 0,
                sub_value: 0
            },
            view1: {
                label: 'Total Containers',
                value: 0,
                sub_value: 0
            },
            view2: {
                label: 'Total Services',
                value: 0,
                sub_value: 0
            },
            view3: {
                label: 'Total Processes',
                value: 0,
                sub_value: 0
            }
        },
        cards: {
            machines: {
                label: 'Machines',
                filter: {
                    type: 'action_button',
                    icon: 'filter',
                    value: '',
                    items: [],
                    disabled: true,
                    visible: true,
                },
                body: {
                    type: 'hexagons',
                    gradient: false,
                    values: []
                },
                visible: true,
                help: "It shows the number of machines in the cluster. The machines are grouped by their status as running or down.",
                beta: false,
            },
            overview: {
                label: 'Overview',
                filter: {
                    type: 'action_button',
                    icon: 'filter',
                    value: '',
                    items: [],
                    disabled: true,
                    visible: true,
                },
                body: {
                    type: 'progress-list',
                    gradient: false,
                    values: []
                },
                visible: true,
                help: "It shows the CPU and RAM overview of up to 25 recently installed machines in the cluster.",
                beta: false,
            },
            cpu_usage: {
                label: 'CPU Usage',
                filter: {
                    type: 'action_button',
                    icon: 'filter',
                    value: '',
                    items: [],
                    disabled: true,
                    visible: true,
                },
                body: {
                    type: 'cpu-single-graph',
                    gradient: false,
                    values: []
                },
                visible: true,
                help: "It shows the CPU usage of up to 25 recently installed machines in the graphical-view.",
                beta: false,
            },
            ram_usage: {
                label: 'RAM Usage',
                filter: {
                    type: 'action_button',
                    icon: 'filter',
                    value: '',
                    items: [],
                    disabled: true,
                    visible: true,
                },
                body: {
                    type: 'ram-single-graph',
                    gradient: false,
                    values: []
                },
                visible: true,
                help: "It shows the RAM usage of up to 25 recently installed machines in the graphical-view.",
                beta: false,
            },
            network_usage: {
                label: 'Network Usage',
                filter: {
                    type: 'action_button',
                    icon: 'filter',
                    value: '',
                    items: [],
                    disabled: true,
                    visible: true,
                },
                body: {
                    type: 'network-dual-graph',
                    gradient: false,
                    values: []
                },
                visible: true,
                help: "It shows the network usage of up to 25 recently installed machines in the graphical-view (most data received/ transmitted in second). It includes your physical network interfaces.",
                beta: false,
            },
            disk_usage: {
                label: 'Disk Usage',
                filter: {
                    type: 'action_button',
                    icon: 'filter',
                    value: '',
                    items: [],
                    disabled: true,
                    visible: true,
                },
                body: {
                    type: 'disk-dual-graph',
                    gradient: false,
                    values: []
                },
                visible: true,
                help: "it shows disk usages of up to 25 recently installed machines in the graphical-view (most consumed capacity).",
                beta: false,
            },
            filesystem_usage: {
                label: 'Filesystem Usage',
                filter: {
                    type: 'action_button',
                    icon: 'filter',
                    value: '',
                    items: [],
                    disabled: true,
                    visible: true,
                },
                body: {
                    type: 'filesystem-list',
                    gradient: false,
                    values: []
                },
                visible: true,
                help: "It shows the filesystem utilization & usage (used, reserved & free) of recent installed 25 machines in the cluster.",
                beta: false,
            },
            latency_wise: {
                label: 'Latency Wise',
                filter: {
                    type: 'action_button',
                    icon: 'filter',
                    value: '',
                    items: [],
                    disabled: true,
                    visible: true,
                },
                body: {
                    type: 'line',
                    values: []
                },
                visible: false,
                beta: false,
            },
            request_count: {
                label: 'Request Count',
                filter: {
                    type: 'action_button',
                    icon: 'filter',
                    value: '',
                    items: [],
                    disabled: true,
                    visible: true,
                },
                body: {
                    type: 'line',
                    values: []
                },
                visible: false,
                beta: false,
            },
            throughput: {
                label: 'Throughput',
                filter: {
                    type: 'action_button',
                    icon: 'filter',
                    value: '',
                    items: [],
                    disabled: true,
                    visible: true,
                },
                body: {
                    type: 'line',
                    values: []
                },
                visible: false,
                beta: false,
            }
        }
    },
    logs: {
        label: 'Logs',
        filters: [
            {
                type: 'action_button',
                icon: 'filter',
                value: '',
                items: [
                    'Option 1',
                    'Option 2',
                    'Option 3'
                ]
            },
            {
                type: 'action_button',
                icon: 'plus',
                value: '',
                items: [
                    'Option 1',
                    'Option 2',
                    'Option 3'
                ]
            }
        ],
        overview: {
            view0: {
                label: 'incl. #UNIQUE_LOG# Unique Logs',
                value: 0,
                sub_value: 0
            },
            view1: {
                label: 'Total Errors',
                value: 0,
                sub_value: 0
            },
            view2: {
                label: 'Total Warnings',
                value: 0,
                sub_value: 0
            },
            view3: {
                label: 'Total Info',
                value: 0,
                sub_value: 0
            }
        },
        cards: {
            ratio: {
                label: 'Log Ratio (Error, Warning, Debug & Info Trends)',
                filter: {
                    type: 'action_button',
                    icon: 'filter',
                    value: '',
                    items: [],
                    disabled: false,
                    visible: true,
                },
                body: {
                    type: 'line',
                    gradient: false,
                    values: []
                },
                visible: true,
                help: "It shows the number of logs that are triggered per minute in the form of Error, Warning, Debug & Info.",
                beta: false,
            },
            list: {
                label: 'Logs List',
                filter: {
                    type: 'action_button',
                    icon: 'filter',
                    value: '',
                    items: [],
                    disabled: false,
                    visible: true,
                },
                body: {
                    type: 'log-list',
                    values: []
                },
                visible: true,
                help: "It shows the recent logs as per selected time frame and log-list can be modified with available filters..",
                beta: false,
            }
        }
    },
    traces: {
        label: 'Traces',
        filters: [
            {
                type: 'action_button',
                icon: 'filter',
                value: '',
                items: [
                    'Option 1',
                    'Option 2',
                    'Option 3'
                ]
            },
            {
                type: 'action_button',
                icon: 'plus',
                value: '',
                items: [
                    'Option 1',
                    'Option 2',
                    'Option 3'
                ]
            }
        ],
        overview: {
            view0: {
                label: 'incl. #UNIQUE_TRACE# Unique Traces',
                value: 0,
                sub_value: 0
            },
            view1: {
                label: 'Total Errors',
                value: 0,
                sub_value: 0
            },
            view2: {
                label: 'Total Ok',
                value: 0,
                sub_value: 0
            },
            view3: {
                label: 'Max Duration',
                value: 0,
                sub_value: 0
            }
        },
        cards: {
            ratio: {
                label: 'Trace Ratio (Error & Ok Trends)',
                filter: {
                    type: 'action_button',
                    icon: 'filter',
                    value: '',
                    items: [],
                    disabled: false,
                    visible: true,
                },
                body: {
                    type: 'line',
                    gradient: false,
                    values: []
                },
                visible: true,
                help: "It shows the number of traces that are triggered per minute in the form of Error & Ok.",
                beta: false,
            },
            list: {
                label: 'Traces List',
                filter: {
                    type: 'action_button',
                    icon: 'filter',
                    value: '',
                    items: [],
                    disabled: false,
                    visible: true,
                },
                body: {
                    type: 'trace-list',
                    values: []
                },
                visible: true,
                help: "It shows the recent traces as per selected time frame and traces-list can be modified with available filters.",
                beta: false,
            }
        }
    },
} as object;
