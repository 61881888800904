import {createReducer} from "core/utils/create_reducer";
import {
    TEST_ZZ,
} from "./constant";
import {combineReducers} from "redux";

export default combineReducers({
    list: createReducer({
        items: [],
        inflight: true,
    }, {
        [TEST_ZZ]: (state, opts) => {
            state.lastUpdated = Date.now()
            return {...state};
        },
    }),
})