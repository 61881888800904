import {
  APIStatus,
  RequestError,
  isDebugEnabled,
} from "core/application/utils";
import { createReducer } from "core/utils/create_reducer";
import { uniq } from "lodash";
import moment, { unitOfTime } from "moment";
import { combineReducers } from "redux";
import {
  ADD_UPDATE_REPORT,
  IMPORT_DASHBOARD,
  LAYOUTS_UPDATE,
  RECEIVED_ALL_WIDGETS,
  RECEIVED_AVAILABLE_METRICS,
  RECEIVED_AVAILABLE_METRICS_ATTRIBUTE_VALUES,
  RECEIVED_AWS_NAMESPACES,
  RECEIVED_BAR_FILTERS,
  RECEIVED_BUILDER_COUNT_DATA,
  RECEIVED_BUILDER_DIALOG_DATA,
  RECEIVED_BUILDER_FORMATTED_DATA,
  RECEIVED_BUILDER_METRIC_FILTER_VALUES,
  RECEIVED_BUILDER_METRIC_LIST,
  RECEIVED_CUSTOM_ATTRIBUTES,
  RECEIVED_INFRA_METRICS,
  RECEIVED_REPORTS,
  RECEIVED_RESOURCE_LIST,
  RECEIVED_SIDEBAR_FILTER_VALUES,
  RECEIVED_SOCKET_DATA,
  RECEIVED_SOURCES,
  RECEIVED_WIDGETS_APPS,
  RECEIVED_WIDGET_REPORTS_BUILDER_LIST,
  REFRESH_BUILDER,
  REMOVE_ITEM_FROM_DASHBOARD,
  REMOVE_ITEM_FROM_REPORT,
  REMOVE_SOCKET_DATA_BY_INDEX,
  REQUEST_ALL_WIDGETS,
  REQUEST_AVAILABLE_METRICS,
  REQUEST_AWS_NAMESPACES,
  REQUEST_SOURCES,
  RESET_BUILDER_FORMATTED_DATA,
  RESET_RESOURCE_VIEW,
  RESET_WIDGET,
  RESET_WIDGETS_REPORTS,
  UPDATE_WIDGET_REPORTS_BUILDER_LIST,
  WS_RECEIVED_BUILDER_FORMATTED_DATA,
} from "store/widgets/constant";
import {
  WidgetAppType,
  WidgetsApp,
} from "views/modules/builder/core/widgets.app";
import {
  AddUpdateWidgetBuilder,
  AttrValues,
  AvailableFilters,
  AvailableMetrics,
  AvailableMetricsResponse,
  BuilderViewOptions,
  ChartDataType,
  ChartType,
  CustomWidget,
  DashboardSource,
  GridViewCountData,
  GridviewDataType,
  LayoutItem,
  ListType,
  MetricTypeEnum,
  QueryCountDataType,
  QueryValueType,
  SelectGroupBy,
  SocketResponse,
  TimeSeriesData,
  TimeseriesType,
  Widget,
  getIntValue,
  getWidgetType,
} from "views/modules/builder/entities/builder.entities";
import {
  BarType,
  DataType,
  FilterAttSearchResp,
  FiltersAPIResp,
  MetricsAPIResp,
  MetricsReducer,
  ResourceAPIResp,
  defaultMetricAPIResp,
} from "./entities";

// divide chart data in specific granularity
// granularity is in seconds
const formatType: unitOfTime.Base = "seconds";
const granularity = {
  time: 30,
  format: formatType,
};
interface Builders {
  inflight: boolean;
  widgets: Record<number, CustomWidget>;
  defaultViews: Record<string, CustomWidget>;
  refreshData: Record<string, boolean>;
}
export interface ResetWidget {
  builderId: number;
  widget: CustomWidget;
}
export interface ReportItem {
  created_at: string;
  description: string;
  display_scope: string;
  favorite: boolean;
  id: number;
  key: string;
  label: string;
  meta_data: {
    data_source?: string;
  };
  updated_at: string;
  user: {
    email: string;
    first_name: string;
    id: number;
  };
  user_id: number;
  visibility: string;
  clone: boolean;
}
export interface APIAction {
  apiStatus: APIStatus;
  errorMsg?: string;
  requestErrors?: RequestError[];
}
export interface ReportReducer {
  builders: Builders;
  reportsData: {
    reports: ReportItem[];
    page: number | false;
    limit: number | false;
    apiAction: APIAction;
  };
  createUpdateAction: APIAction;
  deleteAction: APIAction;
}
const defaultValue: ReportReducer = {
  builders: {
    inflight: true,
    widgets: {},
    defaultViews: {},
    refreshData: {},
  },
  reportsData: {
    reports: [],
    page: false,
    limit: false,
    apiAction: {
      apiStatus: APIStatus.IDLE,
    },
  },
  createUpdateAction: {
    apiStatus: APIStatus.IDLE,
  },
  deleteAction: {
    apiStatus: APIStatus.IDLE,
  },
};
export interface ReportListReceived {
  reports?: ReportItem[];
  apiAction: APIAction;
}
export interface ReportReceive {
  report?: ReportItem;
  apiAction: APIAction;
}

const reports = createReducer(defaultValue, {
  [RECEIVED_REPORTS]: (
    state: ReportReducer,
    opts: {
      type: string;
      data: ReportListReceived;
    }
  ) => {
    state.reportsData.apiAction = opts.data.apiAction;
    if (opts?.data?.reports && Array.isArray(opts?.data?.reports)) {
      state.reportsData.reports = opts.data.reports;
    }
    return { ...state };
  },
  [ADD_UPDATE_REPORT]: (
    state: ReportReducer,
    opts: {
      type: string;
      data: ReportReceive;
    }
  ) => {
    state.createUpdateAction = opts.data.apiAction;
    if (opts?.data?.report?.id) {
      const index = state.reportsData.reports.findIndex(
        (c: ReportItem) => c.id == opts?.data?.report?.id
      );
      if (index >= 0) {
        state.reportsData.reports[index] = JSON.parse(
          JSON.stringify(opts.data.report)
        );
      } else {
        state.reportsData.reports.splice(1, 0, opts.data.report);
      }
      state.reportsData.reports = [
        ...JSON.parse(JSON.stringify(state.reportsData.reports)),
      ];
    }
    return { ...state };
  },
  [RECEIVED_WIDGET_REPORTS_BUILDER_LIST]: (
    mainState: ReportReducer,
    opts: {
      type: string;
      error: "";
      data?: {
        items: Widget[];
        builderViewOptions: BuilderViewOptions;
      };
      layouts: LayoutItem[];
      status: true;
      inflight: false;
    }
  ) => {
    const state = Object.assign({}, mainState.builders);
    if (typeof opts.inflight == "boolean") {
      state.inflight = opts.inflight;
    }
    if (typeof opts?.data?.items !== "undefined") {
      if (Array.isArray(opts.data.items) && opts.data.items.length > 0) {
        opts.data.items.forEach((item: Widget, index) => {
          let builderViewOptions: BuilderViewOptions;
          if (typeof opts?.data?.builderViewOptions !== "undefined") {
            builderViewOptions = opts.data.builderViewOptions;
          } else {
            builderViewOptions = {
              displayScope: "infrastructure",
            };
          }
          if (!item?.scope?.meta_data?.RawMessage?.layouts) {
            item.scope.meta_data.RawMessage =
              item.scope.meta_data.RawMessage || {};
            item.scope.meta_data.RawMessage.layouts = {
              _scope_id: item.scope.id,
              h: 4,
              resizeHandles: ["se"],
              w: 4,
              x: 0,
              y: 0,
            };
          }
          const cw: CustomWidget = {
            key: item.key,
            builderConfig: item.config.RawMessage,
            builderId: item.id,
            label: item.label,
            scopeId: item.scope.id,
            widgetAppId: item.widget_app_id,
            layout: item.scope.meta_data.RawMessage.layouts || {},
            builderViewOptions,
            builderMetaData: item.meta_data.RawMessage || {},
          };
          state.widgets[item.id] = cw;
        });
      } else {
        state.widgets = {};
      }
    }
    mainState.builders = state;
    return { ...mainState };
  },
  [UPDATE_WIDGET_REPORTS_BUILDER_LIST]: (mainState: ReportReducer, opts) => {
    const state = Object.assign({}, mainState.builders);
    if (typeof opts.layout !== "undefined") {
      // let _items: any = state.items.map((item: any, i:number) => {
      //     item.builder_meta_data.layouts = item
      // })
      // state.items = Array.isArray(opts.items) ? opts.items : []
    }
    mainState.builders = state;
    return { ...mainState };
  },
  [RECEIVED_BUILDER_FORMATTED_DATA]: (
    mainState: ReportReducer,
    opts: {
      type: string;
      data: AddUpdateWidgetBuilder;
    }
  ) => {
    const state: ReportReducer = Object.assign({}, mainState);
    if (opts) {
      const inflight =
        typeof opts.data.inflight != "undefined" ? opts.data.inflight : true;
      const builderId = opts.data.body.builderId;
      const resourceType =
        opts.data?.body?.builderViewOptions?.resource?.name || "";
      let builderItem: CustomWidget = opts.data.body;
      const widgetId = opts.data.body.widgetAppId;
      const _App = Object.values(WidgetsApp).find(
        (app: WidgetAppType) => app.id === widgetId
      );
      if (!_App) return state;
      let widgetData: ChartDataType | undefined = {
        inflight,
      };
      let layout: LayoutItem | undefined;
      if (builderId && state.builders.widgets[builderId]) {
        if (state.builders.widgets[builderId].widgetData) {
          widgetData = state.builders.widgets[builderId].widgetData;
        }
        if (state.builders.widgets[builderId].layout) {
          layout = state.builders.widgets[builderId].layout;
        }
      } else if (resourceType && state.builders.defaultViews[resourceType]) {
        if (state.builders.defaultViews[resourceType].widgetData) {
          widgetData = state.builders.defaultViews[resourceType].widgetData;
        }
        if (state.builders.defaultViews[resourceType].layout) {
          layout = state.builders.defaultViews[resourceType].layout;
        }
      }
      if (opts.data.body.widgetData && widgetData) {
        if (
          _App.key === ChartType.BarChart ||
          _App.key === ChartType.TimeseriesChart ||
          _App.key === ChartType.HeatMapChart
        ) {
          const d = opts.data.body.widgetData as TimeSeriesData;
          Object.keys(d.chartGroups).forEach((groupName) => {
            const metricGroup = d.chartGroups[groupName];
            Object.keys(metricGroup).forEach((item) => {
              const chartPoints = metricGroup[item];
              if (!chartPoints.length || chartPoints.length <= 4) return;
              const secondlastRecord = chartPoints[chartPoints.length - 2];
              if (!secondlastRecord.value) {
                const thirdLastElement = chartPoints[chartPoints.length - 3];
                const fourthLastElement = chartPoints[chartPoints.length - 4];
                const numThirdValue = getIntValue(thirdLastElement.value);
                const numFourthValue = getIntValue(fourthLastElement.value);
                const avg = (numThirdValue + numFourthValue) / 2;
                secondlastRecord.value = avg;
                // secondlastRecord.valueTitle = avg.toFixed(2);
                chartPoints[chartPoints.length - 2] = secondlastRecord;
              }

              const lastRecord = chartPoints[chartPoints.length - 1];
              if (!lastRecord.value) {
                const thirdLastElement = chartPoints[chartPoints.length - 3];
                const secondLastElement = chartPoints[chartPoints.length - 2];
                const numThirdValue = getIntValue(thirdLastElement.value);
                const numTwothValue = getIntValue(secondLastElement.value);
                const avgForLastRecord = (numThirdValue + numTwothValue) / 2;
                lastRecord.value = avgForLastRecord;
                // lastRecord.valueTitle = avgForLastRecord.toFixed(2);
                chartPoints[chartPoints.length - 1] = lastRecord;
              }

              d.chartGroups[groupName][item] = chartPoints;
            });
          });
          widgetData.timeseriesChartData = d;
        } else if (
          (ChartType.TopListChart === _App.key ||
            ChartType.PieChart === _App.key ||
            ChartType.ScatterPlotChart === _App.key ||
            ChartType.TreeChart === _App.key ||
            ChartType.HexagonChart === _App.key) &&
          opts.data.body.widgetData
        ) {
          const d = opts.data.body.widgetData as GridviewDataType;
          d.total_count =
            widgetData.gridviewData?.total_count || d.total_count || 0;
          d.up_count_data =
            widgetData.gridviewData?.up_count_data || d.up_count_data || "";
          widgetData.gridviewData = d;
        } else if (_App.key === ChartType.TableChart) {
          const d = opts.data.body.widgetData as GridviewDataType;
          d.up_count_data =
            widgetData.gridviewData?.up_count_data || d.up_count_data || "";
          widgetData.gridviewData = d;
        } else if (
          ChartType.QueryValueChart === _App.key &&
          opts.data.body.widgetData
        ) {
          const d = opts.data.body.widgetData as QueryCountDataType;
          widgetData.queryCountChartData = d;
        }
      }
      if (
        opts.data.action === "add" &&
        !opts.data.body.widgetData &&
        state.builders.widgets[-1]?.widgetData
      ) {
        widgetData = state.builders.widgets[-1].widgetData;
      }
      if (opts.data.body.layout) {
        layout = opts.data.body.layout;
      }
      if (widgetData) {
        widgetData.inflight = inflight;
      }
      if (widgetData && opts.data.body.isError) {
        widgetData = {
          inflight: widgetData.inflight,
        };
      }
      builderItem = {
        ...builderItem,
        widgetData,
      };
      if (layout) {
        builderItem.layout = layout;
      }
      if (opts.data.body.isError) {
        builderItem.isError = true;
        builderItem.errorMsg = builderItem.errorMsg || "Something went wrong!";
      }
      if (builderId) {
        state.builders.widgets[opts.data.body.builderId] = builderItem;
      } else if (resourceType) {
        state.builders.defaultViews[resourceType] = builderItem;
      }
    }
    mainState = { ...state };
    return { ...mainState };
  },
  [RECEIVED_BUILDER_COUNT_DATA]: (
    mainState: ReportReducer,
    opts: {
      type: string;
      data: GridViewCountData;
    }
  ) => {
    const state: ReportReducer = Object.assign({}, mainState);
    if (opts) {
      const { data } = opts;
      const { resourceName } = data;
      let builderItem: CustomWidget | undefined;
      if (resourceName) {
        const defaultItem: CustomWidget = {
          widgetData: {
            gridviewData: {
              up_count_data: opts.data.up_count_data,
              total_count: opts.data.total_count,
              columns: [],
              data: [],
              timestamp: Date.now(),
            },
            inflight: true,
          },
          builderId: -1,
          builderViewOptions: {
            resource: {
              name: resourceName,
              columns: [],
              resourceType: "",
              widgetAppId: 5,
            },
          },
          label: "",
          scopeId: -1,
          widgetAppId: 5,
        };
        if (state.builders.defaultViews[resourceName]) {
          builderItem = state.builders.defaultViews[resourceName];
          if (builderItem) {
            const data = builderItem.widgetData;
            if (data?.gridviewData) {
              data.gridviewData.up_count_data = opts.data.up_count_data;
              data.gridviewData.total_count = opts.data.total_count;
              builderItem.widgetData = data;
            } else {
              const gData: GridviewDataType = {
                up_count_data: opts.data.up_count_data,
                total_count: opts.data.total_count,
                columns: [],
                data: [],
                timestamp: Date.now(),
              };
              builderItem.widgetData = {
                gridviewData: gData,
                inflight: true,
              };
            }
            state.builders.defaultViews[resourceName] = builderItem;
          } else {
            state.builders.defaultViews[resourceName] = defaultItem;
          }
        } else {
          state.builders.defaultViews[resourceName] = defaultItem;
        }
      }
    }
    mainState = { ...state };
    return { ...mainState };
  },
  [WS_RECEIVED_BUILDER_FORMATTED_DATA]: (
    mainState: ReportReducer,
    opts: {
      type: string;
      data: SocketResponse;
    }
  ) => {
    // if( count > 0 ) {
    //     return { ...mainState }
    // }
    // count++;
    const response = opts.data.metricsData;
    if (!response) return mainState;
    if (isDebugEnabled()) {
      console.log("WS_RECEIVED_BUILDER_FORMATTED_DATA:", opts);
    }
    const c = { ...mainState };
    Object.keys(response).forEach((builderIdFromResponse) => {
      const builderId = parseInt(builderIdFromResponse);
      let existingWidget: CustomWidget;
      if (c.builders.widgets[builderId]) {
        existingWidget = c.builders.widgets[builderId];
      } else if (response[builderIdFromResponse]) {
        const rtype = builderIdFromResponse;
        if (rtype) {
          const com = c.builders.defaultViews[rtype];
          if (com) {
            existingWidget = com;
          } else {
            return;
          }
        } else {
          return;
        }
      } else {
        return;
      }
      if (!existingWidget) {
        return;
      }
      let responseTimeSeriesData = response[builderId];
      if (!responseTimeSeriesData) {
        responseTimeSeriesData = response[builderIdFromResponse];
      }
      Object.keys(responseTimeSeriesData.chartGroups).forEach(
        (groupNameReceivedFromReponse) => {
          if (!existingWidget) return;
          const existingWidgetData = existingWidget.widgetData;
          const widgetAppId = existingWidget.widgetAppId;
          const _App = Object.values(WidgetsApp).find(
            (app: WidgetAppType) => app.id === widgetAppId
          );
          if (!_App) return mainState;
          if (existingWidgetData) {
            if (
              ChartType.TimeseriesChart === _App.key ||
              ChartType.BarChart === _App.key ||
              ChartType.HeatMapChart === _App.key
            ) {
              const existingData = existingWidgetData.timeseriesChartData!;
              if (!existingData) return;
              const existingGroup =
                existingData.chartGroups[groupNameReceivedFromReponse];
              if (existingGroup) {
                const receivedMetricsForGroup =
                  responseTimeSeriesData.chartGroups[
                    groupNameReceivedFromReponse
                  ];
                if (!receivedMetricsForGroup) return;
                Object.keys(receivedMetricsForGroup.metrics).forEach(
                  (metricName) => {
                    if (!existingGroup[metricName]) return;
                    let existingChartPoints = [...existingGroup[metricName]];
                    const newChartPoints =
                      receivedMetricsForGroup.metrics[metricName];
                    newChartPoints.forEach((newChartPoint) => {
                      let finalTs = newChartPoint.timestamp;
                      const timestamp = newChartPoint.timestamp; // the timestamp value you want to round
                      const seconds = moment(timestamp).seconds();
                      if (seconds <= granularity.time) {
                        finalTs = moment(timestamp)
                          .seconds(granularity.time)
                          .valueOf();
                      } else {
                        finalTs = moment(timestamp)
                          .seconds(0)
                          .add(1, "minute")
                          .valueOf();
                      }
                      const receiveDateValue = moment(finalTs);
                      const receivedDateInString = receiveDateValue.format(
                        "YYYY-MM-DD HH:mm:ss"
                      );
                      const findInExisting = existingChartPoints.findIndex(
                        (e) => {
                          const existingDate = moment(e.timestamp);
                          const existingDateInString = existingDate.format(
                            "YYYY-MM-DD HH:mm:ss"
                          );
                          return existingDateInString === receivedDateInString;
                        }
                      );
                      const numNewValue = getIntValue(newChartPoint.value);
                      if (findInExisting > -1) {
                        const existingLastRecord =
                          existingChartPoints[findInExisting];
                        const oldYAxis = existingLastRecord.lastValue;
                        const newValue = oldYAxis + numNewValue;
                        let count = existingLastRecord.count || 1;
                        count++;
                        let valueToUpdate = newValue / count;
                        if (widgetAppId === 9) {
                          valueToUpdate = numNewValue;
                        }
                        existingLastRecord.value = valueToUpdate;
                        existingLastRecord.count = count;
                        existingLastRecord.lastValue = newValue;
                        // existingLastRecord.valueTitle = "";
                        existingChartPoints[findInExisting] =
                          existingLastRecord;
                      } else {
                        newChartPoint.count = 1;
                        newChartPoint.lastValue = numNewValue;
                        newChartPoint.timestamp = receiveDateValue.valueOf();
                        if (widgetAppId === 9) {
                          newChartPoint.config = existingChartPoints[0].config;
                          existingChartPoints = [newChartPoint];
                        } else {
                          existingChartPoints.push(newChartPoint);
                        }
                        Object.keys(existingData.chartGroups).forEach(
                          (groupName) => {
                            if (groupName === groupNameReceivedFromReponse)
                              return;
                            if (widgetAppId === 9) return;
                            const group = existingData.chartGroups[groupName];
                            Object.keys(group).forEach((metricName) => {
                              const chartPoints = group[metricName];
                              const v = getIntValue(
                                chartPoints[chartPoints.length - 1].value
                              );
                              chartPoints.push({
                                // ...newChartPoint,
                                lastValue: v,
                                value: 0,
                                count: 0,
                                // valueTitle: "",
                                timestamp: receiveDateValue.valueOf(),
                                config: chartPoints?.[0].config || {},
                              });
                              group[metricName] = chartPoints;
                            });
                            existingData.chartGroups[groupName] = group;
                          }
                        );
                        const lastTsinTimestamps = Object.keys(
                          existingData.timestamps
                        ).map((t) => parseInt(t));
                        const lastTs =
                          lastTsinTimestamps[lastTsinTimestamps.length - 1];
                        if (lastTs && lastTs < receiveDateValue.valueOf()) {
                          existingData.timestamps[receiveDateValue.valueOf()] =
                            true;
                        }
                      }
                      existingGroup[metricName] = existingChartPoints;
                    });
                  }
                );
                existingData.chartGroups[groupNameReceivedFromReponse] =
                  existingGroup;
                // console.log("existingData updated");
                existingData.timestamp = Date.now();
                existingWidgetData.timeseriesChartData = existingData;
                existingWidget.widgetData = existingWidgetData;
                // widgets[builderId] = existingWidget;
                const rtype = builderIdFromResponse;
                if (rtype) {
                  c.builders.defaultViews[rtype] = existingWidget;
                } else {
                  c.builders.widgets[builderId] = existingWidget;
                }
              }
            } else if (
              _App.key === ChartType.TableChart ||
              ChartType.TopListChart === _App.key ||
              ChartType.PieChart === _App.key ||
              ChartType.ScatterPlotChart === _App.key ||
              ChartType.TreeChart === _App.key ||
              ChartType.HexagonChart === _App.key
            ) {
              const existingData = existingWidgetData.gridviewData!;
              if (!existingData) return;
              const rows = existingData.data;
              const receivedMetricsForGroup =
                responseTimeSeriesData.chartGroups[
                  groupNameReceivedFromReponse
                ];
              if (!receivedMetricsForGroup) return;
              let groupTypeBy: string[] = [];
              //TODO: Confirm if logic works for all cases
              const builderConfig = existingWidget?.builderConfig;
              if (Array.isArray(builderConfig)) {
                builderConfig.forEach((w) => {
                  if (w.with) {
                    w.with.forEach((w) => {
                      if (
                        w.key === SelectGroupBy &&
                        Array.isArray(w.value) &&
                        w.value.length > 0
                      ) {
                        groupTypeBy.push(...w.value);
                      }
                    });
                  }
                });
              } else {
                (builderConfig?.with || []).forEach((w) => {
                  if (
                    w.key === "SELECT_DATA_BY" &&
                    Array.isArray(w.value) &&
                    w.value.length > 0
                  ) {
                    groupTypeBy = w.value;
                  }
                });
              }
              if (!groupTypeBy.length) {
                groupTypeBy = ["resource"];
              }
              const rowIndex = rows.findIndex((row) => {
                let value = "";
                groupTypeBy.forEach((type) => {
                  if (row[type]) {
                    if (value === "") {
                      value = row[type];
                    } else {
                      value += "-" + row[type];
                    }
                  }
                });
                return value === groupNameReceivedFromReponse;
              });
              if (rowIndex === -1) return;
              Object.keys(receivedMetricsForGroup.metrics).forEach(
                (metricName) => {
                  const existingRow = rows[rowIndex];
                  const newChartPoints =
                    receivedMetricsForGroup.metrics[metricName];
                  newChartPoints.forEach((newChartPoint) => {
                    if (!newChartPoint.value) return;
                    existingRow[metricName] = newChartPoint.value;
                  });
                }
              );
              existingData.data = rows;
              existingData.timestamp = Date.now();
              existingWidgetData.gridviewData = existingData;
              existingWidget.widgetData = existingWidgetData;
              const rtype = builderIdFromResponse;
              if (rtype) {
                c.builders.defaultViews[rtype] = existingWidget;
              } else {
                c.builders.widgets[builderId] = existingWidget;
              }
            } else if (widgetAppId == 6) {
              // const d = chartData as QueryCountDataType;
            }
          }
        }
      );
    });
    // c.builders.widgets = widgets;
    return { ...c };
  },
  [RESET_BUILDER_FORMATTED_DATA]: (mainState: ReportReducer, opts) => {
    const state = Object.assign({}, mainState);
    // state.builders.chart_data = {
    //     ...state.builders.chart_data,
    //     _statics: Date.now(),
    //     _builder_statics: null,
    // }
    return { ...state };
  },
  [RESET_WIDGET]: (
    mainState: ReportReducer,
    opts: {
      type: string;
      data: ResetWidget;
    }
  ) => {
    const { data } = opts;
    if (!data) return { ...mainState };
    const { builderId, widget } = data;
    if (!builderId) {
      console.warn("RESET_WIDGET: builderId and scopeId not found");
      return { ...mainState };
    }
    const state = Object.assign({}, mainState.builders);
    if (state.widgets && state.widgets[builderId] && widget) {
      state.widgets[builderId] = widget;
    }
    mainState.builders = state;
    return { ...mainState };
  },
  [RESET_RESOURCE_VIEW]: (
    mainState: ReportReducer,
    opts: {
      type: string;
      data: string;
    }
  ) => {
    const { data } = opts;
    if (!data) return { ...mainState };
    const state = Object.assign({}, mainState.builders);
    if (state?.defaultViews?.[data]) {
      delete state.defaultViews[data];
    }
    mainState.builders = state;
    return { ...mainState };
  },
  [REMOVE_ITEM_FROM_DASHBOARD]: (mainState: ReportReducer, opts) => {
    const scopeId = opts?.scopeId;
    if (!scopeId) return { ...mainState };
    const state = Object.assign({}, mainState.builders);
    // to-do: remove item from state.items
    // state.items = state.items.filter(item => item.scopeId != scopeId);
    mainState.builders = state;
    return { ...mainState };
  },
  [REMOVE_ITEM_FROM_REPORT]: (mainState: ReportReducer, opts) => {
    const builderId = opts?.builderId;
    const scopeId = opts?.scopeId;
    if (!builderId && !scopeId) {
      console.warn("REMOVE_ITEM_FROM_REPORT: builderId and scopeId not found");
      return { ...mainState };
    }
    const state = Object.assign({}, mainState.builders);
    if (state.widgets && state.widgets[builderId]) {
      delete state.widgets[builderId];
    }
    mainState.builders = state;
    return { ...mainState };
  },
  [RESET_WIDGETS_REPORTS]: (mainState: ReportReducer, opts) => {
    const state: ReportReducer = Object.assign({}, mainState);
    state.reportsData.apiAction = {
      apiStatus: APIStatus.LOADING,
      errorMsg: "",
      requestErrors: [],
    };
    return { ...state };
  },
  [LAYOUTS_UPDATE]: (
    mainState: ReportReducer,
    opts: {
      type: string;
      layouts: LayoutItem[];
    }
  ) => {
    const layouts = opts?.layouts;
    if (!layouts) return { ...mainState };
    layouts.forEach((layout) => {
      const { _scope_id } = layout;
      const state = Object.assign({}, mainState.builders);
      const widgetIds = Object.values(state.widgets);
      const widget = widgetIds.find(
        (widget) => widget?.layout?._scope_id == _scope_id
      );
      if (!widget) {
        return;
      }
      widget.layout = layout;
      state.widgets[widget.builderId] = widget;
    });

    return { ...mainState };
  },
  [REFRESH_BUILDER]: (
    mainState: ReportReducer,
    opts: {
      type: string;
      name: string;
    }
  ) => {
    const viewToRefresh = opts?.name;
    if (!viewToRefresh) return { ...mainState };
    if (typeof mainState.builders.refreshData[viewToRefresh] !== undefined) {
      mainState.builders.refreshData[viewToRefresh] =
        !mainState.builders.refreshData[viewToRefresh];
    } else {
      mainState.builders.refreshData[viewToRefresh] = true;
    }
    return { ...mainState };
  },
});

const apps = createReducer(
  {
    count: 0,
    items: [],
    inflight: false,
    page: false,
    limit: false,
    datasets: [],
  },
  {
    [RECEIVED_WIDGETS_APPS]: (state, opts) => {
      return { ...state };
    },
  }
);

const attributes = createReducer(
  {
    inflight: true,
    items: null,
  },
  {
    [RECEIVED_CUSTOM_ATTRIBUTES]: (state, opts) => {
      if (typeof opts.inflight == "boolean") {
        state.inflight = opts.inflight;
      }
      if (opts?.resource_types && typeof opts.resource_types == "object") {
        state.items = opts.resource_types;
      }
      if (opts?.datasets && Array.isArray(opts.datasets)) {
        state.datasets = opts.datasets;
      }
      return { ...state };
    },
  }
);

const defaultAvailableMetrics: AvailableMetrics = {
  inflight: false,
  integratedMetrics: {
    aws: {
      namespaces: [],
      inflight: true,
    },
  },
  availableMetrics: {
    metrics: [],
    settings: {},
  },
};
interface ReceivedType {
  type: string;
  response: AvailableMetricsResponse;
}

interface ReceivedFilters {
  resource: string;
  response: AvailableFilters;
}
export interface UpdateDatasetsListType {
  datasetId?: number;
  action: "delete" | "update" | "create";
}

const datasets = createReducer(defaultAvailableMetrics, {
  [REQUEST_AVAILABLE_METRICS]: (state: AvailableMetrics) => {
    state.inflight = true;
    return { ...state };
  },
  [RECEIVED_AVAILABLE_METRICS]: (
    state: AvailableMetrics,
    opts: ReceivedType
  ) => {
    state.inflight = false;
    if (opts?.response) {
      state.availableMetrics = opts.response;
    }
    return { ...state };
  },
  [REQUEST_AWS_NAMESPACES]: (state) => {
    if (!state.integratedMetrics) {
      state.integratedMetrics = {
        aws: {
          namespaces: [],
          inflight: false,
        },
      };
    }
    state.integratedMetrics.aws.inflight = true;
    return { ...state };
  },
  [RECEIVED_AWS_NAMESPACES]: (
    state,
    data: {
      type: string;
      namespaces: string[];
    }
  ) => {
    if (!state.integratedMetrics) {
      state.integratedMetrics = {
        aws: {
          namespaces: [],
        },
      };
    }
    state.integratedMetrics.aws.inflight = false;
    state.integratedMetrics.aws.namespaces = data.namespaces || [];
    return { ...state };
  },
  [RECEIVED_AVAILABLE_METRICS_ATTRIBUTE_VALUES]: (
    state: AvailableMetrics,
    data: {
      type: string;
      resource: string;
      attribute: string;
      chartType: ChartType;
      values: GridviewDataType;
    }
  ) => {
    // Clone the existing state
    const newState: AvailableMetrics = JSON.parse(JSON.stringify(state));

    // Get the metrics of the specified resource
    const resMetIdx = newState.availableMetrics.metrics.findIndex(
      (m) => m.resource === data.resource
    );
    if (resMetIdx < 0) return newState;

    // Get the filters of the specified resource by chart type
    let filters: AvailableFilters | undefined;
    const widgetType = getWidgetType(data.chartType);
    switch (widgetType) {
      case TimeseriesType: {
        filters =
          newState.availableMetrics.metrics[resMetIdx].timeseries.filters;
        break;
      }
      case QueryValueType: {
        filters =
          newState.availableMetrics.metrics[resMetIdx].queryValue.filters;
        break;
      }
      case ListType: {
        filters = newState.availableMetrics.metrics[resMetIdx].table.filters;
        break;
      }
    }

    if (!filters) return newState;

    // Get the attribute of the specified resource
    const attributeFilters = filters[data.attribute];
    if (!attributeFilters) return newState;

    // Get the list of string from Grid view data
    if (!data.values.data) return newState;
    const stringValues: string[] = [];
    data.values.data.forEach((d) => {
      const attVal = d[data.attribute];
      if (attVal) stringValues.push(attVal);
    });

    // Update the attribute values
    const values = uniq([...(attributeFilters.values || []), ...stringValues]);
    attributeFilters.values = values;

    // Update the table filters
    filters[data.attribute] = attributeFilters;
    switch (widgetType) {
      case TimeseriesType: {
        newState.availableMetrics.metrics[resMetIdx].timeseries.filters =
          filters;
        break;
      }
      case QueryValueType: {
        newState.availableMetrics.metrics[resMetIdx].queryValue.filters =
          filters;
        break;
      }
      case ListType: {
        newState.availableMetrics.metrics[resMetIdx].table.filters = filters;
        break;
      }
    }

    return newState;
  },
});

const dialogConfig = createReducer(
  {
    items: {},
    inflight: false,
  },
  {
    [RECEIVED_BUILDER_DIALOG_DATA]: (state, a) => {
      if (a?.data?.display_scope) {
        state.items[a?.data?.display_scope] = a.data;
      }
      if (typeof a.inflight !== "undefined") state.inflight = a.inflight;

      return { ...state };
    },
  }
);

export interface DashboardReducer {
  importAction: APIAction;
  sourcesAction: APIAction;
  widgetsActions: APIAction;
  sources: DashboardSource[];
}
const defaultDashboardValue: DashboardReducer = {
  importAction: {
    apiStatus: APIStatus.IDLE,
  },
  sourcesAction: {
    apiStatus: APIStatus.IDLE,
  },
  widgetsActions: {
    apiStatus: APIStatus.IDLE,
  },
  sources: [],
};
// Reducer for builder dashboards
const dashboards = createReducer(
  // Initial state
  defaultDashboardValue,
  {
    // Reducer function for "Import Dashboard" functionality
    [IMPORT_DASHBOARD]: (
      state: DashboardReducer,
      opts: {
        type: string;
        data: APIAction;
      }
    ) => {
      state.importAction = opts.data;
      return { ...state };
    },
    [REQUEST_SOURCES]: (
      state: DashboardReducer,
      opts: {
        type: string;
        data: APIAction;
      }
    ) => {
      state.sourcesAction = {
        apiStatus: APIStatus.LOADING,
        errorMsg: "",
        requestErrors: [],
      };
      return { ...state };
    },
    [RECEIVED_SOURCES]: (
      state: DashboardReducer,
      opts: {
        type: string;
        data: {
          apiAction: APIAction;
          sources: DashboardSource[];
        };
      }
    ) => {
      state.sourcesAction = opts.data.apiAction;
      state.sources = opts.data.sources;
      if (state.sources && state.sources.length > 0) {
        state.sourcesAction.apiStatus = APIStatus.LOADED;
      }
      return { ...state };
    },
    [REQUEST_ALL_WIDGETS]: (state: DashboardReducer) => {
      state.widgetsActions = {
        apiStatus: APIStatus.LOADING,
        errorMsg: "",
        requestErrors: [],
      };
      return { ...state };
    },
    [RECEIVED_ALL_WIDGETS]: (
      state: DashboardReducer,
      opts: {
        type: string;
        data: {
          apiAction: APIAction;
          widgets: DashboardSource[];
          reportKey: string;
        };
      }
    ) => {
      state.widgetsActions = opts.data.apiAction;
      if (opts.data.reportKey) {
        state.sources.forEach((source) => {
          if (source.value === opts.data.reportKey) {
            const widgets = source.widgets || [];
            opts.data.widgets.forEach((w) => {
              widgets.push(...w.widgets);
            });
          }
        });
      }
      return { ...state };
    },
  }
);

const defaultMetricsReducer: MetricsReducer = {
  builder: {
    metrics: JSON.parse(JSON.stringify(defaultMetricAPIResp)) as MetricsAPIResp,
    groupby: JSON.parse(JSON.stringify(defaultMetricAPIResp)) as MetricsAPIResp,
    filters: JSON.parse(JSON.stringify(defaultMetricAPIResp)) as MetricsAPIResp,
    fltAttSearchAPIResp: {
      apiStatus: APIStatus.IDLE,
    },
  },
  querybar: {
    filters: {
      apiStatus: APIStatus.IDLE,
    },
  },
  sidebar: {
    filters: {
      apiStatus: APIStatus.IDLE,
    },
  },
  resourceAPIResp: {
    apiStatus: APIStatus.IDLE,
  },
  infra: {},
};

// Reducer for new metrics v2 API which has search and pagination features
const metrics = createReducer(defaultMetricsReducer, {
  [RECEIVED_RESOURCE_LIST]: (
    state: MetricsReducer,
    opts: {
      data: ResourceAPIResp;
    }
  ) => {
    state.resourceAPIResp = opts.data;
    return { ...state };
  },
  [RECEIVED_BUILDER_METRIC_LIST]: (
    state: MetricsReducer,
    opts: {
      dataType: DataType;
      data: MetricsAPIResp;
    }
  ) => {
    const existMetricsApiResp = JSON.parse(
      JSON.stringify(state.builder[opts.dataType])
    ) as MetricsAPIResp;

    existMetricsApiResp.apiStatus = opts.data.apiStatus;
    existMetricsApiResp.errorMsg = opts.data.errorMsg;
    existMetricsApiResp.requestErrors = opts.data.requestErrors;

    if (opts.data.apiStatus === APIStatus.SUCCESS) {
      existMetricsApiResp.limit = opts.data.limit;
      existMetricsApiResp.page = opts.data.page;
      existMetricsApiResp.items = opts.data.items;
    }

    state.builder = {
      ...state.builder,
      [opts.dataType]: existMetricsApiResp,
    };
    return { ...state };
  },
  [RECEIVED_INFRA_METRICS]: (
    state: MetricsReducer,
    opts: {
      infraType: string;
      data: MetricsAPIResp;
    }
  ) => {
    const existMetricsApiResp = JSON.parse(
      JSON.stringify(state.infra[opts.infraType] || {})
    ) as MetricsAPIResp;

    existMetricsApiResp.apiStatus = opts.data.apiStatus;
    existMetricsApiResp.errorMsg = opts.data.errorMsg;
    existMetricsApiResp.requestErrors = opts.data.requestErrors;

    if (opts.data.apiStatus === APIStatus.SUCCESS) {
      existMetricsApiResp.limit = opts.data.limit;
      existMetricsApiResp.page = opts.data.page;
      existMetricsApiResp.items = opts.data.items;
    }
    state.infra = {
      ...state.infra,
      [opts.infraType]: existMetricsApiResp,
    };
    return { ...state };
  },
  [RECEIVED_BUILDER_METRIC_FILTER_VALUES]: (
    state: MetricsReducer,
    opts: {
      data: FilterAttSearchResp;
    }
  ) => {
    state.builder.fltAttSearchAPIResp.apiStatus = opts.data.apiStatus;
    state.builder.fltAttSearchAPIResp.errorMsg = opts.data.errorMsg;
    state.builder.fltAttSearchAPIResp.requestErrors = opts.data.requestErrors;

    const existFiltersApiResp = JSON.parse(
      JSON.stringify(state.builder.filters)
    ) as MetricsAPIResp;
    if (!existFiltersApiResp.items) return state;

    const filterIdx = existFiltersApiResp.items.findIndex((item) => {
      const fd = item as AttrValues;
      return fd.name === opts.data.attName;
    });
    if (filterIdx < 0) {
      existFiltersApiResp.items.unshift({
        name: opts.data.attName || "",
        label: opts.data.attLabel || "",
        resource: opts.data.resource || "",
        type: opts.data.attType || MetricTypeEnum.MetricTypeNone,
        values: opts.data.searchResult,
        count: opts.data.searchResult?.length || 0,
      });
    } else {
      const filterDetails = existFiltersApiResp.items[filterIdx] as AttrValues;
      filterDetails.values = opts.data.searchResult || [];
      existFiltersApiResp.items[filterIdx] = filterDetails;
    }

    state.builder.filters = existFiltersApiResp;
    return { ...state };
  },
  [RECEIVED_BAR_FILTERS]: (
    state: MetricsReducer,
    opts: {
      barType: BarType;
      data: FiltersAPIResp;
    }
  ) => {
    const existFiltersApiResp = JSON.parse(
      JSON.stringify(opts.data)
    ) as FiltersAPIResp;

    existFiltersApiResp.apiStatus = opts.data.apiStatus;
    existFiltersApiResp.errorMsg = opts.data.errorMsg;
    existFiltersApiResp.requestErrors = opts.data.requestErrors;

    if (opts.data.apiStatus === APIStatus.SUCCESS) {
      existFiltersApiResp.limit = opts.data.limit;
      existFiltersApiResp.page = opts.data.page;
      existFiltersApiResp.items = opts.data.items;
    }

    state[opts.barType] = {
      filters: existFiltersApiResp,
    };
    return { ...state };
  },
  // RECEIVED_SIDEBAR_FILTER_VALUES is specifically designed for updating the
  // sidebar state with "multi-data" API response.
  [RECEIVED_SIDEBAR_FILTER_VALUES]: (
    state: MetricsReducer,
    opts: {
      data: AvailableFilters;
    }
  ) => {
    // add missing fields to state
    const stateKeys = new Set(
      state.sidebar.filters.items?.map((item) => item.name)
    );
    const itemsToAdd = Object.keys(opts.data)
      .filter((key) => !stateKeys.has(key))
      .map((key) => opts.data[key]);
    state.sidebar.filters.items?.push(...itemsToAdd);

    state.sidebar.filters?.items?.forEach((item) => {
      if (!item.name || !opts.data[item.name]) return;

      const newFilterDetails = opts.data[item.name];
      item.values = newFilterDetails.values;
      item.sidebarFilterValues = newFilterDetails.sidebarFilterValues;
    });

    return { ...state };
  },
});
export interface BuilderSocketReducer {
  data: SocketResponse[];
}

const socketData = createReducer(
  {
    data: [],
  },
  {
    [RECEIVED_SOCKET_DATA]: (
      state: BuilderSocketReducer,
      actionData: {
        type: string;
        data: SocketResponse;
      }
    ) => {
      const copy = { ...state };
      if (actionData.data) {
        if (copy.data.length > 10) {
          copy.data = [];
        }
        copy.data.push(actionData.data);
      }
      return {
        ...copy,
      };
    },
    [REMOVE_SOCKET_DATA_BY_INDEX]: (
      state: BuilderSocketReducer,
      actionData: {
        type: string;
        index: number;
      }
    ) => {
      const copy = { ...state };
      if (actionData.index) {
        copy.data.splice(actionData.index, 1);
      }
      return copy;
    },
  }
);
export interface WidgetReducer {
  reports: ReportReducer;
}
export default combineReducers({
  apps,
  reports,
  attributes,
  datasets,
  dialogConfig,
  dashboards,
  metrics,
  socketData,
});
