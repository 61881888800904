import {PROJECTS_RECEIVED,PROJECT_DETAIL_RECEIVED} from "./constant";


export const projectsReceived = (arg: any = {}) => {
    return {
        type: PROJECTS_RECEIVED,
        ...arg
    }
}
export const projectDetailReceived = (arg: any = {}) => {
    return {
        type: PROJECT_DETAIL_RECEIVED,
        ...arg
    }
}