import * as React from "react";
import NavTab from "core/components/nav-tab";
import TabItems from "core/components/nav-tab/items";
import {
    AndroidV2, InstallationDocsIcon
} from "core/components/svg/icons";
import {Link} from "react-router-dom";
import RumSetting from "views/modules/installation/components/rum/rum-setting";

export default function AndroidRUMInstrument() {

    return <>
        <div className={'cloudflare-apm-new dialog-table-componants'}>
            <div className={' setting_side-panel'}>
                <div className={'integration-popup'}>
                    <div className={'header-section'}>
                        <div className={'d-flex'}>
                            <AndroidV2/>
                            <div className={'header-content'}>
                                <h3>Android</h3>
                                <p>Android RUM is specifically used for monitoring Android mobile applications.</p>
                            </div>
                            <button className={"docs-link"}><Link
                                to={'https://docs.middleware.io/rum/rum-android-mobile'}
                                target={'_blank'}><span> <InstallationDocsIcon/>Documentation</span></Link></button>

                        </div>
                        <NavTab targetIndex={1} defaultActiveTabIndex={1}>
                            <TabItems title={'Overview'}>
                                <div className={'d-flex in-content'}>
                                    <div className={'overview-heading'}>
                                        <div className={'config-overview'}>
                                            <h4>Category</h4>
                                            <span>Real User Monitoring</span>

                                            <h4 className={'tp-mr'}>Supported Version</h4>
                                            <img alt="Android RUM Supported"
                                                 src="https://img.shields.io/maven-central/v/io.github.middleware-labs/android-sdk?include_prereleases&style=flat"/>

                                        </div>
                                    </div>
                                    <div className={'overview-content'}>
                                        <p>The Mobile RUM SDK provides a customizable suite of tools to analyze and optimize the performance of Android applications. Isolate ANR and network changes, quickly detect application crashes, identify slow or frozen frames and more.</p>
                                        <div className={'overview-image'}>
                                            <img src={'assets/images/mobile-sdk-overview.png'}
                                                 alt={'android-overview'}/>
                                        </div>
                                    </div>
                                </div>
                            </TabItems>
                            <TabItems title={'Installation'}>
                                <div className={'panel-body'}>
                                    <RumSetting data={'android'}/>
                                </div>

                            </TabItems>
                        </NavTab>
                    </div>
                </div>

            </div>
        </div>
    </>
}