import Button from "core/components/v2/button";
import { DeleteIcon } from "core/components/v2/svg/icons";
import React, { useEffect, useState } from "react";

export interface AppSelectionTab {
  label: string;
  value: number | string;
  icon: React.ReactNode;
  enabled: boolean;
  addMoreButton?: React.ReactNode;
}

export type AppSelectionTabs = AppSelectionTab[];

interface AppSelectionProps {
  tabs: AppSelectionTabs;
  selectedTab?: number | string;
  allowDelete: boolean;
  addMoreButton?: React.ReactNode;
  onTabChange: (selectedTab: AppSelectionTab) => void;
}
const AppSelection = (props: AppSelectionProps) => {
  const { tabs } = props;
  const [hasManyApplications, setHasManyApplications] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    props.selectedTab ? props.selectedTab : tabs[0].value
  );
  useEffect(() => {
    if (props.selectedTab != undefined) {
      setSelectedTab(props.selectedTab);
    }
  }, [props.selectedTab]);
  const setActiveTab = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tabObj: AppSelectionTab
  ) => {
    e.preventDefault();
    setSelectedTab(tabObj.value);
    if (props.onTabChange && typeof props.onTabChange === "function") {
      props.onTabChange(tabObj);
    }
  };
  return (
    <>
      <div className="mw-app-tab-selection">
        {tabs.map((it, index) => (
          <div
            key={it.value}
            className={"mw-tab " + (it.value === selectedTab ? "active" : "")}
          >
            <div
              className="mw-app-tab"
              key={index + "_div"}
              onClick={(e) => setActiveTab(e, it)}
            >
              <div className="step-number">
                <span>{index + 1}</span>
              </div>
              <span className="mw-app-tab-label" key={index + "_input"}>
                {it.label}
              </span>
              {props.allowDelete && (
                <Button
                  secondary
                  error
                  shouldStopPropagation
                  onClick={() => {}}
                  key={index + "_button"}
                  prefixicon={<DeleteIcon color="var(--color-error)" />}
                />
              )}
            </div>
          </div>
        ))}
        {props.addMoreButton}
      </div>
    </>
  );
};

export default AppSelection;
