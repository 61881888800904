import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestAvailableMetrics } from "store/widgets/api";
import { getAvailableMetrics } from "store/widgets/selectors";
import { getMetricDetails } from "../../common/utils";
import {
  AvailableFilters,
  ChartType,
  FilterOption,
  GroupByItem,
  KpiType,
  ListType,
  MetricItem,
  MetricTypeEnum,
  QueryValueType,
  TimeseriesType,
  getWidgetType,
  toKpiType,
} from "../../entities/builder.entities";
import { ColumnTypes } from "../../entities/column.entities";

interface BuilderData {
  selectors: {
    getResourceMetrics: (
      resource: string,
      chartType: ChartType
    ) => MetricItem[];
    getAvailableByOptions: (
      resource: string,
      chartType: ChartType,
      selectedMetrics: ColumnTypes[]
    ) => GroupByItem[];
    getAvailableFilters: (
      resource: string,
      chartType: ChartType
    ) => FilterOption[];
    getAllResources: () => string[];
    getAllMetrics: (chartType: ChartType) => MetricItem[];
    getOriginalMetric: (
      metricName: string,
      resource: string
    ) => MetricItem | undefined;
  };
}
const useBuilder = () => {
  const availableMetricsData = useSelector(getAvailableMetrics);
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      availableMetricsData.availableMetrics.metrics.length === 0 &&
      !availableMetricsData.inflight
    ) {
      // dispatch action to get available metrics
      dispatch(requestAvailableMetrics());
    }
  }, []);

  const availableMetrics = availableMetricsData.availableMetrics;
  const resourceMetrics = availableMetrics.metrics;

  const getResourceMetrics = (
    resource: string,
    chartType: ChartType
  ): MetricItem[] => {
    if (resourceMetrics && resource) {
      const metrics = resourceMetrics.find((a) => a.resource === resource);
      if (metrics) {
        const widgetType = getWidgetType(chartType);
        if (widgetType == TimeseriesType) {
          return metrics.timeseries.metrics;
        } else if (widgetType == QueryValueType) {
          return metrics.queryValue.metrics;
        } else {
          return metrics.table.metrics;
        }
      }
    }
    return [];
  };
  const getAllMetrics = (chartType: ChartType): MetricItem[] => {
    const widgetType = getWidgetType(chartType);
    if (resourceMetrics) {
      const allMetrics: MetricItem[] = [];
      resourceMetrics.forEach((resource) => {
        const kpiType = toKpiType(resource.resource);
        if (kpiType === KpiType.KpiTypeLog || kpiType === KpiType.KpiTypeTrace)
          return;
        if (widgetType == TimeseriesType) {
          allMetrics.push(...resource.timeseries.metrics);
        } else if (widgetType == QueryValueType) {
          allMetrics.push(...resource.queryValue.metrics);
        } else {
          allMetrics.push(...resource.table.metrics);
        }
      });
      return allMetrics;
    }
    return [];
  };
  const getAvailableFilters = (
    resource: string,
    chartType: ChartType
  ): FilterOption[] => {
    let options: FilterOption[] = [];
    const resourceMetrics = availableMetrics.metrics.find(
      (a) => a.resource === resource
    );
    if (!resourceMetrics) return options;
    let filters: AvailableFilters = {};
    const widgetType = getWidgetType(chartType);
    if (widgetType === TimeseriesType && resourceMetrics.timeseries.filters) {
      filters = resourceMetrics.timeseries.filters;
    } else if (
      widgetType === QueryValueType &&
      resourceMetrics.queryValue.filters
    ) {
      filters = resourceMetrics.queryValue.filters;
    } else if (widgetType === ListType && resourceMetrics.table.filters) {
      filters = resourceMetrics.table.filters;
    }
    if (filters && Object.keys(filters).length > 0) {
      options = Object.keys(filters).map((filterKey) => {
        return {
          value: filterKey,
          options: filters[filterKey].values || [],
          label: filters[filterKey].label || filterKey,
          type: filters[filterKey].type,
        };
      });
    }
    return options;
  };
  const getAvailableByOptions = (
    resource: string,
    chartType: ChartType,
    selectedMetrics: ColumnTypes[]
  ): GroupByItem[] => {
    const metrics = getResourceMetrics(resource, chartType);
    let availableBy: GroupByItem[] = [];

    const availableResource = availableMetrics.metrics.find(
      (a) => a.resource === resource
    );
    if (availableResource) {
      const widgetType = getWidgetType(chartType);
      if (widgetType === TimeseriesType) {
        availableBy = availableResource.timeseries.group_by;
      } else if (widgetType === QueryValueType) {
        availableBy = availableResource.queryValue.group_by;
      } else {
        availableBy = availableResource.table.group_by;
      }
    }

    const options: GroupByItem[] = [];

    const kpiType = toKpiType(resource);
    selectedMetrics.forEach((column) => {
      const item = column as string;
      const mDetails = getMetricDetails(item);
      const selectedMetric: MetricItem | undefined = metrics.find(
        (a: MetricItem) => a.name === mDetails.metricName
      );
      if (!selectedMetric) return;

      availableBy.forEach((g) => {
        if (
          g.type === MetricTypeEnum.ResourceAttributeKey ||
          kpiType === KpiType.KpiTypeLog ||
          kpiType === KpiType.KpiTypeTrace ||
          selectedMetric.attributes?.[g.name]
        ) {
          options.push({
            name: g.name,
            label: g.label || g.name, // label might be empty in some cases, so use name
            type: g.type,
          });
        }
      });
    });

    return options;
  };
  const getAllResources = (): string[] => {
    return resourceMetrics.map((rMetric) => {
      return rMetric.resource;
    });
  };
  const getOriginalMetric = (
    metricName: string,
    resource: string
  ): MetricItem | undefined => {
    let selectedMetric: MetricItem | undefined = undefined;
    if (resourceMetrics) {
      const metricDetails = getMetricDetails(metricName);
      resourceMetrics.forEach((resourceType) => {
        resourceType.table.metrics.forEach((metric) => {
          if (
            metric.name === metricDetails.metricName &&
            metric.resource === resource
          ) {
            selectedMetric = metric;
            return;
          }
        });
      });
    }
    return selectedMetric;
  };
  const builderData: BuilderData = {
    selectors: {
      getResourceMetrics,
      getAvailableByOptions,
      getAvailableFilters,
      getAllResources,
      getAllMetrics,
      getOriginalMetric,
    },
  };
  return builderData;
};
export default useBuilder;
