import React from "react";
import { SectionItems } from "./entity";
import RUMInstallation, { Languages } from "./pages/rum";
import {
  AndroidIcon,
  JavascriptIcon,
  ReactNativeIcon,
} from "core/components/v2/svg/language-icons";
import { IosIcon } from "core/components/v2/svg/integrations-icons";
import { getThemeState } from "core/utils";

export const RumItems: () => SectionItems[] = () => {
  const themeMode = getThemeState();
  const borderOpacity = themeMode === "dark" ? 0.4 : 0.1;
  return [
    {
      label: "Javascript",
      link: "javascript",
      prefixicon: <JavascriptIcon />,
      pageProps: {
        title: "Installing on JavaScript",
        bgColor: `rgba(239, 232, 79, ${borderOpacity})`,
        component: <RUMInstallation language={Languages.JavaScript} />,
        docLink:
          "https://docs.middleware.io/rum/rum-nextjs?_gl=1*1exfvu0*_ga*OTYxMDg2NjQ4LjE3MTM4NTE2MTY.*_ga_2KBHR7YB2G*MTcxNzY3MjIwOC44MS4wLjE3MTc2NzIyMDguMC4wLjA.",
      },
    },
    {
      label: "iOS",
      link: "ios",
      prefixicon: <IosIcon />,
      pageProps: {
        title: "Installing on iOS",
        bgColor: `${themeMode === "dark" ? "rgba(173, 173, 173, 0.5)" : "rgba(0, 0, 0, 0.1)"}`,
        component: <RUMInstallation language={Languages.IOS} />,
        docLink:
          "https://docs.middleware.io/rum/rum-ios?_gl=1*1vd71hi*_ga*OTYxMDg2NjQ4LjE3MTM4NTE2MTY.*_ga_2KBHR7YB2G*MTcxNzY3MjIwOC44MS4xLjE3MTc2NzIyMTcuMC4wLjA.",
      },
    },
    {
      label: "Android",
      link: "android",
      prefixicon: <AndroidIcon />,
      pageProps: {
        title: "Installing on Android",
        bgColor: `rgba(61, 219, 133, ${borderOpacity})`,
        component: <RUMInstallation language={Languages.Android} />,
        docLink:
          "https://docs.middleware.io/rum/rum-android-mobile?_gl=1*19bvin9*_ga*OTYxMDg2NjQ4LjE3MTM4NTE2MTY.*_ga_2KBHR7YB2G*MTcxNzY3MjIwOC44MS4xLjE3MTc2NzIyMTMuMC4wLjA.",
      },
    },
    {
      label: "React Native",
      link: "react-native",
      prefixicon: <ReactNativeIcon />,
      pageProps: {
        title: "Installing React Native",
        bgColor: `rgba(97, 218, 251, ${borderOpacity})`,
        component: <RUMInstallation language={Languages.ReactNative} />,
        docLink:
          "https://docs.middleware.io/rum/rum-react?_gl=1*1k7hyh7*_ga*OTYxMDg2NjQ4LjE3MTM4NTE2MTY.*_ga_2KBHR7YB2G*MTcxNzY3MjIwOC44MS4xLjE3MTc2NzIyMjEuMC4wLjA.",
      },
    },
  ];
};
