import Highcharts from "highcharts";
import React from "react";
import "./_style.scss";
import HighchartsReact from "./highcharts-config";

interface DataEntry {
  date: string;
  status: string;
  currentStage?: string;
}

interface AvailabilityTimelineProps {
  data: DataEntry[];
  latestStatus?: number;
  currentStage?: string;
}

const AvailabilityTimeline: React.FC<AvailabilityTimelineProps> = ({
  data,
  latestStatus,
}) => {
  const now = new Date();
  const startDate = new Date(now);
  startDate.setDate(now.getDate() - 89);
  const past90Days = Array.from({ length: 90 }, (_, i) => {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + i);
    return date;
  });

  const normalizedData = data.map((d) => ({
    ...d,
    date: new Date(d.date).toISOString().split("T")[0],
  }));

  const filledData = past90Days.map((date) => {
    const dateString = date.toISOString().split("T")[0];
    const entry = normalizedData.find((d) => d.date === dateString);
    return {
      date: date.getTime(),
      status: entry ? entry.status : "resolved",
      currentStage: entry ? entry.currentStage : undefined,
    };
  });

  const statusColors: Record<string, string> = {
    resolved: "#2DA44E",
    identified: "#D92D20",
    update: "#D92D20",
    investigating: "#F79009",
    monitoring: "#F79009",
    Ok: "#D7DFE9",
    outage: "#D92D20",
    incident: "#F79009",
  };

  const workingStatuses = ["resolved", "Ok"];
  let workingDays = filledData.filter((entry) =>
    workingStatuses.includes(entry.status)
  ).length;
  const totalDays = filledData.length;
  workingDays =
    latestStatus !== undefined && latestStatus < 1
      ? workingDays - 1
      : workingDays;
  const uptimePercentage = ((workingDays / totalDays) * 100).toFixed(1);
  const today = new Date().setHours(0, 0, 0, 0);

  const seriesData = filledData.map((entry) => {
    const entryDate = new Date(entry.date).setHours(0, 0, 0, 0);
    return {
      x: entry.date,
      y: 1,
      color:
        entryDate === today
          ? entry.currentStage == "resolved"
            ? statusColors["resolved"]
            : statusColors[entry.status]
          : statusColors["resolved"],
      status:
        entryDate === today
          ? entry.currentStage == "resolved"
            ? "resolved"
            : entry.status
          : "resolved",
    };
  });
  if (seriesData.length > 0 && latestStatus !== undefined) {
    const lastIndex = seriesData.length - 1;
    const lastEntry = seriesData[lastIndex];
    if (lastEntry.status === "resolved" && latestStatus < 1) {
      seriesData[lastIndex] = {
        ...lastEntry,
        status: "down",
        color: statusColors["identified"],
      };
    }
  }

  const options: Highcharts.Options = {
    chart: {
      type: "column",
      height: 60,
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      tickInterval: 24 * 3600 * 1000 * 7,
      labels: {
        formatter: function () {
          const date = new Date(this.value as number);
          const dayDiff = Math.round(
            (date.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
          );

          return "";
        },
      },
      lineWidth: 0,
      gridLineWidth: 0,
      tickWidth: 0,
    },
    yAxis: {
      visible: false,
    },
    plotOptions: {
      column: {
        stacking: "normal",
        pointWidth: 9,
        pointPadding: 0.1,
        groupPadding: 0.1,
        // borderRadius: 0,
      },
      series: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        type: "column",
        data: seriesData,
        showInLegend: false,
      } as Highcharts.SeriesColumnOptions,
    ],
    tooltip: {
      className: "highcharts-tooltip-custom",
      backgroundColor: "#191a23",
      style: {
        fontWeight: "lighter",
      },

      formatter: function () {
        const incstatus =
          (this.point as any).status === "resolved"
            ? "No incidents reported"
            : (this.point as any).status;

        return (
          "<b>" +
          '<span style="color: var(--color-white)">' +
          Highcharts.dateFormat("%A, %b %e, %Y", this.x as number) +
          "</span>" +
          "</b><br/>" +
          '<span style="color: var(--color-white); font-weight:normal">' +
          incstatus +
          "</span>"
        );
      },
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <div className="time_line_chart_status_page">
        <div>
          <label>90 days</label>
          <div></div>
        </div>
        <div className="right-side">
          <label>{uptimePercentage}% uptime</label>
        </div>
        <div className="line-container">
          <label>Today</label>
        </div>
      </div>
    </div>
  );
};

export default AvailabilityTimeline;
