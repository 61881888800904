import Card from "core/components/v2/card";
import TimeseriesChartV2 from "core/components/v2/charts/components/timeseries";
import {
  ChartTypes,
  DEFAULT_CHART_HEIGHT,
  TypeMap,
} from "core/components/v2/charts/models";
import AvailabilityTimeline from "core/components/v2/charts/time-line-chart";
import NavTab from "core/components/v2/nav-tab";
import TabItems from "core/components/v2/nav-tab/items";
import SectionExpand from "core/components/v2/section-expand";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  requestCurrentSyntheticStatus,
  requestPublicPageIncidentHistory,
  requestServiceIncidentHistoryListPublic,
  requestServiceListPublic,
} from "store/status_page/api";
import {
  getChartAndServiceList,
  getCurrentStatusOfSynthetic,
  getIncidentServiceHistory,
  getPublicIncidentHistorySelector,
  getServiceList,
} from "store/status_page/selector";
import GlobalCenterDialog from "views/layouts/app/components/global-center-dialog";
import { HeaderTabBar } from "views/layouts/app/components/v2/global-header/model";
import { AllSystemOptional, LogoSvg } from "views/layouts/app/nav/_icons";
import { getContrastColor } from "views/modules/builder/common/utils";
import { TimeSeriesData } from "views/modules/builder/entities/builder.entities";
import {
  RenderTpl,
  TPL_TYPE_PERCENT,
} from "views/modules/builder/entities/tpl.entities";
import { Incident } from "../incident/model";
import { IncidentWithHistory } from "../page_list/model";
import "./_style.scss";
import EmailPage from "./email";
import IncidentItem from "./incident-history-list";
import SlackPage from "./slack";
import SmsPage from "./sms";

interface DefaultServiceList {
  [serviceName: string]: DataEntry[];
}

interface DataEntry {
  date: string;
  status: string;
  currentStage: string;
}
const yAxisConfig: RenderTpl = {
  type: TPL_TYPE_PERCENT,
  config: {
    items: [],
  },
  label: "",
};

interface CheckDurationItem {
  value: number;
  config: {
    type: string;
    config: null;
  };
  timestamp: number;
}

interface MetadataItem {
  value: {
    "check.location": string;
  };
  config: {
    type: string;
    config: null;
  };
  timestamp: number;
}

interface RegionData {
  "check.duration": CheckDurationItem[];
  metadata: MetadataItem[];
}

interface SyntheticData {
  [region: string]: RegionData;
}

const PublicStatusPage = (props: any) => {
  const dispatch = useDispatch();
  const PageData = props.statusPageData[0];
  const [publicPageData, setPublicPageData] = useState<any>({});
  const [subemailSate, setSubemailState] = useState(false);
  const [pageServiceList, setPageServiceList] = useState<DefaultServiceList>();
  const [syntheticChartData, setSyntheticChartData] = useState<any>();
  const [incidentHistoryList, setIncidentHistoryList] = useState<
    IncidentWithHistory[]
  >([]);
  useEffect(() => {
    if (PageData && PageData.id) {
      setPublicPageData(PageData);
      dispatch(
        requestServiceListPublic({
          page_id: PageData.id.toString(),
          project_id: PageData.project_id,
        })
      );
      dispatch(
        requestServiceIncidentHistoryListPublic(
          Number(PageData.id),
          PageData.project_id
        )
      );
      dispatch(
        requestPublicPageIncidentHistory(
          Number(PageData.id),
          PageData.project_id
        )
      );
    }
    document.title = PageData?.page_name;
    const favicon = document.querySelector(
      'link[rel="icon"]'
    ) as HTMLLinkElement;
    if (favicon) {
      favicon.href = PageData?.favicon_url;
    }
  }, [dispatch, PageData]);

  useEffect(() => {
    {
      publicPageData.favicon_url
        ? updateFavicon(publicPageData.favicon_url)
        : "";
    }
  }, []);

  const serviceList = useSelector(getServiceList);
  const incidentServiceHistory = useSelector(getIncidentServiceHistory);
  const currentStatusOfSynthetic = useSelector(getCurrentStatusOfSynthetic);
  const [isOpen, setIsOpen] = useState(false);
  const incidentHistory = useSelector(getPublicIncidentHistorySelector);
  const chartAndService = useSelector(getChartAndServiceList);
  const [tab, setTab] = useState(0);

  const getDataBasedOnPage = (
    pageID: number,
    serviceList: any[]
  ): DefaultServiceList => {
    let result: DefaultServiceList = {};
    incidentServiceHistory.forEach((entry: Incident) => {
      let service = serviceList.find((service) => {
        return service.id === entry.service_id && service.is_added;
      });
      if (service) {
        if (!result[service.name]) {
          result[service.name] = [];
        }
        result[service.name].push({
          date: entry.created_at || new Date().toISOString().split("T")[0],
          status: entry.type,
          currentStage: entry.status,
        });
      }
    });

    return result;
  };

  const tabs: HeaderTabBar[] = [
    {
      title: "Email",
    },
    {
      title: "SMS",
    },
    {
      title: "Slack",
    },
  ];

  const onTabChange = (index: number) => {
    setTab(index);
  };

  useEffect(() => {
    if (
      (publicPageData && publicPageData.id, incidentServiceHistory.length > 0)
    ) {
      if (chartAndService && chartAndService.service) {
        const checkIds = chartAndService.service
          .map((service) => service.check_id)
          .filter((id) => id !== 0);

        const validCheckIds: number[] = checkIds.filter(
          (id): id is number => id !== undefined
        );

        setPageServiceList(
          getDataBasedOnPage(publicPageData.id, chartAndService.service)
        );
        setSyntheticChartData(chartAndService.data);

        dispatch(
          requestCurrentSyntheticStatus(
            publicPageData.id,
            publicPageData.project_id,
            validCheckIds
          )
        );
      }
    }
    if (incidentHistory && incidentHistory.length > 0) {
      setIncidentHistoryList(incidentHistory);
    }
  }, [
    publicPageData,
    serviceList,
    incidentServiceHistory,
    incidentHistory,
    chartAndService,
  ]);

  // useEffect(() => {
  //   console.log("here we get the data", currentStatusOfSynthetic);
  //   console.log("here we get the service list", chartAndService);
  // }, [currentStatusOfSynthetic]);

  const updateFavicon = (href: string) => {
    let link = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement | null;
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = href;
  };

  const StatusPageSyntheticChart = ({
    chartProps,
    title,
    options = [],
    selectedOption = "",
    selectOption,
    newChartData,
  }: {
    chartProps?: ChartTypes<TypeMap>;
    title?: string;
    options?: string[];
    selectedOption?: string;
    newChartData: TimeSeriesData;
    selectOption?: (x: string) => void;
  }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const newProps = { ...chartProps };
    // if (dialogOpen && newProps.chartHeight) {
    //   newProps.chartHeight = newProps.chartHeight + 40;
    // }
    const RenderChart = () => (
      <div style={{ width: "100%", height: "200px" }}>
        <TimeseriesChartV2
          timeseriesData={newChartData}
          chartType={"bar_chart"}
          nestedProps={{
            chartHeight: DEFAULT_CHART_HEIGHT.HEIGHT_80,
          }}
        />
      </div>
      // <ChartV2
      //   {...newProps}
      //   resource={{
      //     resourceType: "Status page",
      //     columns: [],
      //     name: "Status page chart",
      //   }}
      // />
    );
    return (
      <>
        <Card
          title={title}
          options={options}
          selectedOption={selectedOption}
          selectOption={selectOption}
          displayActionsOnHoverOnly
        >
          {RenderChart()}
        </Card>
      </>
    );
  };
  const transformedData = (checkLocationData: any) => {
    return checkLocationData.map((item: any) => [item.timestamp, item.value]);
  };

  const ensureHttps = (url: string) => {
    if (url && !url.match(/^https?:\/\//)) {
      return `https://${url}`;
    }
    return url;
  };

  const getLatestValue = (data: SyntheticData, serviceName: string) => {
    const service = chartAndService.service.find((s) => s.name === serviceName);
    let returnStatus = 0;
    if (service?.check_id !== 0) {
      currentStatusOfSynthetic.forEach((item: any) => {
        if (Number(item.id) === service?.check_id) {
          if (Number(item.id) === service?.check_id && item.status == "OK") {
            returnStatus = 200;
          } else {
            returnStatus = 0;
          }
        }
      });
      return returnStatus;
    } else {
      const regions = Object.keys(data);
      if (regions.length === 0) return null;

      let latestValue = null;
      let latestTimestamp = 0;

      regions.forEach((region) => {
        const checkDuration = data[region]["check.duration"];
        if (Array.isArray(checkDuration) && checkDuration.length > 0) {
          checkDuration.forEach((item) => {
            if (item.timestamp > latestTimestamp) {
              latestTimestamp = item.timestamp;
              latestValue = item.value;
            }
          });
        }
      });
      const currentTimestamp = Date.now();
      const fiveMinutesInMs = 5 * 60 * 1000;
      if (currentTimestamp - latestTimestamp > fiveMinutesInMs) {
        return 0;
      }
      return latestValue;
    }
  };

  return (
    <div className="status-page">
      <div className="status-page-header"></div>
      <div className={"status-page-view"}>
        <div key={publicPageData?.id} className={"view-status"}>
          <div className={"header-view"}>
            <div
              className={"d-flex"}
              style={{ backgroundColor: publicPageData.theme_color }}
            >
              {publicPageData.logo_url ? (
                publicPageData.logo_url && (
                  <img
                    src={publicPageData.logo_url}
                    alt="Uploaded"
                    style={{ width: "36px", height: "36px" }}
                  />
                )
              ) : (
                <LogoSvg />
              )}
              <p
                style={{
                  color: getContrastColor(
                    publicPageData.theme_color
                      ? publicPageData.theme_color ===
                        "var(--color-primary-faded)"
                        ? "efeff9"
                        : publicPageData.theme_color
                      : "efeff9"
                  ),
                }}
              >
                {publicPageData.page_name}
              </p>
              {publicPageData.subscribe ? (
                <button
                  className={"button-v2 is-primary"}
                  onClick={() => {
                    setIsOpen(true);
                    setSubemailState(false);
                  }}
                >
                  Subscribe
                </button>
              ) : (
                ""
              )}
              <GlobalCenterDialog
                size={"xxxs"}
                title={"Subscribe to updates"}
                isOpen={isOpen}
                onClose={() => {
                  setIsOpen(false);
                  setSubemailState(false);
                }}
              >
                <NavTab
                  showBorderBottom={true}
                  targetIndex={tab}
                  onTabChange={(index: number) => onTabChange(index)}
                >
                  {tabs.map((tab) => (
                    <TabItems
                      title={tab.title}
                      key={tab.title}
                      isDisabled={tab.title == "SMS" ? true : false}
                    />
                  ))}
                </NavTab>

                <div>
                  {tab == 0 && (
                    <EmailPage
                      pageId={publicPageData.id}
                      projectId={publicPageData.project_id}
                      setOpen={setIsOpen}
                      setSubemailState={setSubemailState}
                      subemailSate={subemailSate}
                    />
                  )}{" "}
                  {tab == 1 && <SmsPage pageId={publicPageData.id} />}{" "}
                  {tab == 2 && (
                    <SlackPage
                      pageId={publicPageData.id}
                      projectId={publicPageData.project_id}
                    />
                  )}{" "}
                </div>
              </GlobalCenterDialog>
            </div>
          </div>
          <div className={"main"}>
            <div className={"fully-optional"}>
              <div className={"d-flex"}>
                <AllSystemOptional />
                All system are fully operational
              </div>
            </div>
            <p>About This Site: {publicPageData?.description}</p>
            <p>
              The status of{" "}
              <Link
                to={ensureHttps(publicPageData?.website_url)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {publicPageData?.website_url}
              </Link>
            </p>
          </div>
          <div className="incident-graph">
            {pageServiceList &&
              Object.keys(pageServiceList).map((serviceName, index) => (
                <div key={index} className="incident-graph-inner">
                  <SectionExpand
                    defaultExpanded
                    showBorder={false}
                    shouldtitleStyleBold={false}
                    title={serviceName}
                    key={index}
                  >
                    {pageServiceList[serviceName] &&
                      pageServiceList[serviceName].length > 0 &&
                      (syntheticChartData[serviceName] ? (
                        <>
                          <AvailabilityTimeline
                            data={pageServiceList[serviceName]}
                            latestStatus={
                              getLatestValue(
                                syntheticChartData[serviceName].d.chart_data[
                                  "chartGroups"
                                ],
                                serviceName
                              ) || 0
                            }
                          />
                          <StatusPageSyntheticChart
                            newChartData={
                              syntheticChartData[serviceName].d.chart_data
                            }
                          />
                        </>
                      ) : (
                        <AvailabilityTimeline
                          data={pageServiceList[serviceName]}
                        />
                      ))}
                  </SectionExpand>
                </div>
              ))}
          </div>
          <div className="incident-history">
            <div className="incident-history-header">
              <div className="incident-history-title">Past Incidents</div>
            </div>
            {incidentHistoryList &&
              incidentHistoryList.length > 0 &&
              incidentHistoryList
                .sort((a, b) => {
                  const dateA = new Date(a.Incident.CreatedAt).getTime();
                  const dateB = new Date(b.Incident.CreatedAt).getTime();
                  return dateB - dateA;
                })
                .map((incidentWithHistory, index) => (
                  <IncidentItem
                    key={index}
                    incidentWithHistory={incidentWithHistory}
                  />
                ))}
          </div>
          {PageData.hide_powered_by === true && (
            <div
              className="status-page-footer"
              style={{ backgroundColor: publicPageData.theme_color }}
            >
              <span className="bold-text">Powerd by</span>{" "}
              <span className="middleware-text">Middleware</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PublicStatusPage;
