import { StatusPageData } from "core/application/model";
import { get } from "lodash-es";
import { createSelector } from "reselect";
import {
  Incident,
  IncidentHistory,
  IncidentListState,
  PublicUptimeMetricsResponse,
} from "views/modules/status-page/incident/model";
import {
  ChartAndServiceResponse,
  IncidentWithHistory,
  Service,
  StatusPageSettings,
  Subscriber,
} from "views/modules/status-page/page_list/model";

export const getPageList = createSelector(
  (state: any) => {
    return get(state, ["statusPage", "pageList"], []);
  },
  (data: StatusPageSettings[]) => data
);

export const getPage = createSelector(
  (state: any) => {
    return get(state, ["statusPage", "singlePage"], []);
  },
  (data: StatusPageData) => data
);

export const getServiceList = createSelector(
  (state: any) => {
    return get(state, ["statusPage", "serviceList"], false);
  },
  (data: Service[]) => data
);

export const getServiceSideList = createSelector(
  (state: any) => {
    return get(state, ["statusPage", "serviceSideList"], false);
  },
  (data: Service[]) => data
);

export const getSubscriberList = createSelector(
  (state: any) => {
    return get(state, ["statusPage", "subscriberList"], false);
  },
  (data: Subscriber[]) => data
);

export const getIncidentList = createSelector(
  (state: any) => {
    return get(state, ["statusPage", "incidentList"], {
      incidents: [],
      count: 0,
    });
  },
  (data: IncidentListState) => data
);

export const getIncidentHistoryList = createSelector(
  (state: any) => {
    return get(state, ["statusPage", "incidentHistoryList"], false);
  },
  (data: IncidentHistory[]) => data
);

export const getIncidentSingle = createSelector(
  (state: any) => {
    return get(state, ["statusPage", "incidentSingle"], false);
  },
  (data: Incident[]) => data
);

export const getIncidentServiceHistory = createSelector(
  (state: any) => {
    return get(state, ["statusPage", "incidentServiceHistory"], false);
  },
  (data: Incident[]) => data
);

export const getCurrentStatusOfSynthetic = createSelector(
  (state: any) => {
    return get(state, ["statusPage", "currentStatusOfSynthetic"], false);
  },
  (data: PublicUptimeMetricsResponse[]) => data
);

export const getPublicStatusPageViewSelector = createSelector(
  (state: any) => {
    return get(state, ["statusPage", "publicStatusPageView"], false);
  },
  (data: StatusPageData) => data
);

export const getPublicIncidentHistorySelector = createSelector(
  (state: any) => {
    return get(state, ["statusPage", "publicIncidentHistoryView"], false);
  },
  (data: IncidentWithHistory[]) => data
);

export const getChartAndServiceList = createSelector(
  (state: any) => {
    return get(state, ["statusPage", "chartAndServiceList"], false);
  },
  (data: ChartAndServiceResponse) => data
);

export const getPublishState = createSelector(
  (state: boolean) => get(state, ["statusPage", "publishState"], false),
  (data: boolean) => data
);
