import classNames from "classnames";
import Button from "core/components/button";
import Dialog from "core/components/dialog";
import useQuery from "core/components/query";
import LogoLoader from "core/components/v2/loader/logo-loader";
import AppContext from "core/components/wrapper/context";
import EventEmitter from "core/utils/events";
import {
  RoutesItemPattern,
  RoutesPatterns,
  RoutesPatternsProps,
} from "core/utils/route_schema";
import _ from "lodash";
import * as React from "react";
import { useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { requestInstallationData } from "store/onboard-dashboard/api";
import { getInstallationOverview } from "store/onboard-dashboard/selectors";
import MainBreadcrumbs from "views/layouts/app/breadcrumbs";
import MainSidebar from "views/layouts/app/sidebar";
import { defaultInstallationPath } from "views/modules/installation/common/sections";
import NotFound from "views/placeholder/NotFound";
import useMixPanel from "../mix-panel-events/use-mix-panel";
import { GetDbDeletedflag } from "../utils";
import DBDeletedDialog from "./db-deleted-dialog";

const AppRoutesHelper = React.memo(function (props: RoutesPatternsProps) {
  const context = useContext(AppContext);
  const siteNav = window.location.pathname === "/";
  const defaultNav = window.location.pathname !== "/dashboard";
  const navigate = useNavigate();
  const query = useQuery();
  const sidebarRefElm = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const dispatch = useDispatch();
  React.useEffect(() => {
    const installationRedirect = localStorage.getItem("installation_redirect");
    const isAgentInstalled = context.user?.account?.is_agent_installed;
    const simulateOn = context.user?.account?.meta_data?.simulate;
    const isSandboxAccount = context.user?.account?.uid === "sandbox";
    const installationPath = defaultInstallationPath;
    const sholdNavigateIfNotInstalled =
      !isAgentInstalled &&
      !isSandboxAccount &&
      !simulateOn &&
      location.pathname !== installationPath;
    if (sholdNavigateIfNotInstalled && !installationRedirect) {
      localStorage.setItem("installation_redirect", "true");
      if (window.location.pathname == "/dashboard") {
        navigate(installationPath);
      }
    }
  }, []);

  useEffect(() => {
    EventEmitter.on("sidebar_toggle__action", (event: boolean) => {
      // classList.toggle -- ?
      sidebarRefElm?.current?.classList[event ? "remove" : "add"](
        "sidebar__is_opened"
      );
    });
  }, []);

  const isDataInstalled =
    context?.user?.misc_details?.has_data ||
    context?.user?.misc_details?.skip_to_dashboard;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const installationOverview = useSelector(getInstallationOverview);
  const sendEvent = useMixPanel();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (installationOverview?.installed_any) {
      const agent_type: string =
        installationOverview?.agent?.meta_data?.RawMessage?.script || "";

      sendEvent("Agent installed successfull", {
        agent_type: agent_type,
      });
      sendEvent(
        "",
        {
          installed_agent_type: agent_type,
        },
        true
      );
      sendEvent(
        "",
        {
          already_installed: true,
        },
        true
      );
      sendEvent("open welcome dashboard after successful agent installed");

      context.changeContext({
        key: "user",
        value: {
          ...context.user,
          misc_details: {
            ...context.user?.misc_details,
            has_data: true,
            skip_to_dashboard: true,
          },
        },
      });

      if (!location.pathname.includes("/dashboard")) {
        navigate("/dashboard");
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }, [installationOverview?.installed_any]);

  useEffect(() => {
    const dbDeleted = GetDbDeletedflag(
      context.user?.projects || [],
      context.user?.account?.project_id || 0
    );
    if (!isDataInstalled && !dbDeleted) {
      // Set up the interval to call the API every 5 seconds

      const id = setInterval(() => {
        dispatch(
          requestInstallationData(_, (res) => {
            if (res.hasErr && res.error === "Auth Error") {
              window.location.reload();
            }
          })
        );
      }, 5000);

      // Clean up the interval on component unmount
      return () => {
        clearInterval(id);
      };
    }
  }, [isDataInstalled]);

  return (
    <React.Suspense fallback={<LogoLoader />}>
      <Routes>
        {props.routes.map((module: RoutesPatterns) => {
          const ModuleBreadcrumbs = module.breadcrumbs;
          const GlobalElement = module.globalElement;
          const ModuleSidebar = module.sidebar;
          const ModuleSlug = module.slug || "";
          return module.items.map((v: RoutesItemPattern) => {
            return v.pattern.map((path, key: number) => {
              const ElementComponent = v.component;
              const BreadcrumbsComponent = v.breadcrumbs;
              const SidebarComponent = v.sidebar;
              const HelloBarComponent = v.helloBar;
              const url = (v.slug ? v.slug : ModuleSlug) + path;
              const isSidebar = !!(SidebarComponent || ModuleSidebar);
              const isBreadcrumbs = !!(
                BreadcrumbsComponent || ModuleBreadcrumbs
              );
              return (
                <Route
                  key={key}
                  path={url}
                  element={
                    <React.Suspense fallback={<LogoLoader />}>
                      {HelloBarComponent && (
                        <div className={"hello-bar"}>
                          <HelloBarComponent />
                        </div>
                      )}
                      <div
                        className={`primary-layout-wrapper-div layout_wrapper__${module.moduleName}`}
                      >
                        <div className={"primary-context-middle-wrapper"}>
                          <div
                            className={`primary-layout__context modules__${module.moduleName}`}
                          >
                            <DBDeletedDialog url={url} />
                            {context?.user?.billing?.status === "cancelled" &&
                              location.pathname != "/settings/billing" && (
                                <Dialog
                                  isOpen={true}
                                  position={"center"}
                                  overlayClassName={
                                    "subscription-activate-confirmation__dialog common-confirmation-dialog"
                                  }
                                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                                  onClose={() => {}}
                                >
                                  <h4>
                                    Your account is cancelled . Please
                                    reactivate your account to login and access
                                    the data.
                                  </h4>
                                  <div className={"dialog-buttons-wrapper"}>
                                    <Button
                                      primary
                                      onClick={() => {
                                        window.location.href =
                                          "/settings/billing?tab=invoices";
                                      }}
                                    >
                                      Reactivate account
                                    </Button>
                                  </div>
                                </Dialog>
                              )}
                            {isSidebar && (
                              <MainSidebar className={module.moduleName}>
                                <div className={"sidebar__primary-context"}>
                                  {SidebarComponent && <SidebarComponent />}
                                  {!SidebarComponent && ModuleSidebar && (
                                    <ModuleSidebar />
                                  )}
                                </div>
                              </MainSidebar>
                            )}

                            <div
                              id={"main__routes__wrapper"}
                              ref={sidebarRefElm}
                              className={classNames("routes-content__wrapper", {
                                with__sidebar: isSidebar,
                                //"sidebar__is_opened": sidebarIsOpened,
                              })}
                            >
                              {isBreadcrumbs && (
                                <MainBreadcrumbs module={module.moduleName}>
                                  {BreadcrumbsComponent && (
                                    <BreadcrumbsComponent
                                      title={v.breadcrumbTitle || ""}
                                      {...v.breadcrumbProps}
                                    />
                                  )}
                                  {!BreadcrumbsComponent &&
                                    ModuleBreadcrumbs && (
                                      <ModuleBreadcrumbs
                                        title={v.breadcrumbTitle || ""}
                                        {...v.breadcrumbProps}
                                      />
                                    )}
                                </MainBreadcrumbs>
                              )}
                              <React.Suspense fallback={<LogoLoader />}>
                                <div className={"main__playground__context"}>
                                  {GlobalElement && (
                                    <div
                                      className={
                                        "main__playground__context_global_item"
                                      }
                                    >
                                      <GlobalElement />
                                    </div>
                                  )}
                                  <ElementComponent
                                    context={context}
                                    path={url}
                                    query={query}
                                    navigate={navigate}
                                    location={location}
                                  />
                                </div>
                              </React.Suspense>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Suspense>
                  }
                />
              );
            });
          });
        })}

        {!defaultNav && (
          <>
            <Route path={"/404"} element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </>
        )}
        {siteNav && (
          <Route path="/dashboard" element={<Navigate to="/dashboard" />} />
        )}
        {defaultNav && (
          <Route path="*" element={<Navigate to="/dashboard" />} />
        )}
      </Routes>
    </React.Suspense>
  );
});
AppRoutesHelper.displayName = "AppRoutesHelper";
export default AppRoutesHelper;
