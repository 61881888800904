export const PAGE_LIST_RECEIVED = "PAGE_LIST_RECEIVED";
export const STATUS_PAGE_CREATE_REQUEST = "STATUS_PAGE_CREATE_REQUEST";
export const SERVICE_LIST_RECEIVED = "SERVICE_LIST_RECEIVED";
export const SERVICE_SIDE_LIST_RECEIVED = "SERVICE_SIDE_LIST_RECEIVED";
export const SUBSCRIBER_LIST_RECEIVED = "SUBSCRIBER_LIST_RECEIVED";
export const INCIDENT_LIST_RECEIVED = "INCIDENT_LIST_RECEIVED";
export const INCIDENT_HISTORY_RECEIVED = "INCIDENT_HISTORY_RECEIVED";
export const INCIDENT_SINGLE_RECEIVED = "INCIDENT_SINGLE_RECEIVED";
export const INCIDENT_SERVICE_HISTORY_RECEIVED =
  "INCIDENT_SERVICE_HISTORY_RECEIVED";
export const PUBLIC_VIEW_STATUS_PAGE_RECEIVED =
  "PUBLIC_VIEW_STATUS_PAGE_RECEIVED";
export const PUBLIC_INCIDENT_HISTORY_RECEIVED =
  "PUBLIC_INCIDENT_HISTORY_RECEIVED";
export const CHART_AND_SERVICE_LIST_RECEIVED =
  "CHART_AND_SERVICE_LIST_RECEIVED";
export const STATUS_PAGE_PUBLISH = "STATUS_PAGE_PUBLISH";
export const SINGLE_PAGE_LIST_RECEIVED = "SINGLE_PAGE_LIST_RECEIVED";
export const CURRENT_STATUS_OF_SYNTHETIC_RECEIVED =
  "CURRENT_STATUS_OF_SYNTHETIC_RECEIVED";
