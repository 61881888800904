import {
  getSyntheticsList,
  getSyntheticsMetrics,
} from "store/synthetics/selectors";
import {
  receivedSyntheticsList,
  receivedSyntheticsMetrics,
} from "store/synthetics/actions";
import moment from "moment";

const getAttrVal = (attrs: any, key: string) => {
  if (attrs && attrs[key] && attrs[key][0]) {
    return attrs[key][0];
  }
  return "";
};

export const wsSynthetics = (store: any, event: string, data: any) => {
  const dispatch: any = store.dispatch;
  const state: any = store.getState();
  const list = getSyntheticsList(state);
  const metricsGroup = getSyntheticsMetrics(state);

  if (event == "synthetics__metrics__update") {
    if (data?.checks && typeof data.checks === "object") {
      if (list.length == 0) {
        let count = 0;
        const _debounce = setInterval(() => {
          if (list.length > 0 || count > 6) {
            clearInterval(_debounce);
          } else {
            wsSynthetics(store, event, data);
          }
          count++;
        }, 1000);
      }

      for (const check_id in data.checks) {
        const index = list.findIndex((item: any) => item.id == check_id);

        if (index > -1) {
          if (!list[index]?.details) list[index].details = {};

          if (!list[index]?.details?.last_up_times)
            list[index].details.last_up_times = [];

          list[index].details.last_up_times =
            data.checks[check_id].last_up_times;
          list[index].details.success_rate = data.checks[check_id].success_rate;

          dispatch(receivedSyntheticsList({ synthetics: list[index] }));
        }
      }
    }
  } else if (["synthetics_test_view", "synthetics_test"].indexOf(event) >= 0) {
    const chartGroups: any =
      data?.metricsData?.synthetic_checks?.chartGroups || {};
    for (const checkId in chartGroups) {
      const index = list.findIndex((item: any) => item.id == checkId);
      if (index !== -1) {
        const metrics: any = chartGroups[checkId]?.metrics || {};
        const attrsMetrics = chartGroups[checkId]?.metricsAttributes || {};

        if (typeof metricsGroup[checkId] === "undefined") {
          metricsGroup[checkId] = {
            test_runes: [],
          };
        }
        if (typeof metricsGroup[checkId] !== "undefined") {
          const _location = getAttrVal(attrsMetrics, "check.location");
          const checkStatus = getAttrVal(attrsMetrics, "check.status");
          const testId = getAttrVal(attrsMetrics, "check.test_id");
          const resType = getAttrVal(attrsMetrics, "check.response.type");

          const _mAttrs: any = {};
          for (const k in attrsMetrics) {
            _mAttrs[k] = getAttrVal(attrsMetrics, k);
          }

          if (attrsMetrics) {
            let _testRunes: any = {};
            const _checks: any = {};
            const _checksGroup: any = [];
            for (const k in metrics) {
              if (Array.isArray(metrics[k]) && metrics[k].length > 0) {
                const _metric: any = metrics[k][0];
                const _val = parseFloat((_metric.value || 0).toFixed(2));
                const _ts = _metric.timestamp;

                if (typeof _checks[k] === "undefined") {
                  _checks[k] = {
                    attrs: _mAttrs,
                    name: k,
                    timestamp: _ts,
                    value: _val,
                  };
                }

                _checksGroup.push({
                  check_id: testId,
                  fingerprint: "",
                  checks: _checks,
                  ws_stats: true,
                });

                if (k === "check.duration") {
                  _testRunes = {
                    fingerprint: "",
                    location: _location,
                    response_type: resType,
                    status: checkStatus,
                    test_id: testId,
                    check_id: checkId,
                    timestamp: _ts,
                    is_socket_data: true,
                    value: _val,
                  };
                }
              }
            }

            dispatch(
              receivedSyntheticsMetrics({
                checkId: checkId,
                testRunes: _testRunes,
                checksGroup: _checksGroup,
              })
            );
          }
        }

        if (!list[index]?.details) list[index].details = {};

        if (!list[index]?.details?.last_up_times)
          list[index].details.last_up_times = [];

        if (
          typeof metrics["check.duration"] !== "undefined" &&
          metrics["check.duration"].length > 0
        ) {
          const checkStatus = getAttrVal(attrsMetrics, "check.status");

          if (list[index].details.last_up_times.length > 49) {
            list[index].details.last_up_times.shift();
          }

          list[index].details.last_up_times.push({
            status: checkStatus,
            time: metrics["check.duration"][0].timestamp,
            test_id: getAttrVal(attrsMetrics, "check.test_id"),
          });

          let totalOk = 0;
          let totalFail = 0;
          for (const item of list[index].details.last_up_times) {
            if (item.status === "OK") {
              totalOk++;
            } else {
              totalFail++;
            }
          }
          list[index].details.success_rate =
            ((totalOk / (totalOk + totalFail)) * 100 || 0).toFixed(2) + "%";
        }

        dispatch(receivedSyntheticsList({ synthetics: list[index] }));
      }
    }
  }
};
