import { getTenantUrl } from "core/application/utils";
import NavTab from "core/components/v2/nav-tab";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import InstallationSteps from "../../../components/steps";
// import TabItems from "core/components/v2/nav-tab/items";
import Content from "./linux";
// import ServerlessAPMSetup from "./serverless-apm-setup";
import TabItems from "core/components/v2/nav-tab/items";
import DatadogECS from "./ecs";
import DatadogKubernetes from "./kubernetes";
import DatadogLinux from "./linux";

interface DatadogIntegrationProps {
  serverlessSupport: boolean;
}
const DatadogIntegration: React.FC<DatadogIntegrationProps> = ({
  serverlessSupport,
}) => {
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];

  const tabs = [
    {
      title: "Linux Agent",
      // component: ServerlessAPMSetup,
      component: Content,
    },
    {
      title: "Kubernetes Agent",
      component: Content,
    },
  ];

  const [tabIndex, setTabIndex] = useState<number>(0);

  const onTabChange = (index: number) => {
    console.log("on tab change index", index);
    setTabIndex(index);
  };

  interface TabComponent {
    title: string;
    component: React.FC<{}>;
  }

  return (
    <>
      {serverlessSupport && (
        <InstallationSteps>
          <NavTab targetIndex={0}>
            <TabItems title="Kubernetes Agent" key="host-based">
              <DatadogKubernetes serverless="true" />
            </TabItems>

            <TabItems title="Linux Agent" key="serverless">
              <DatadogLinux serverless="true" />
            </TabItems>

            <TabItems title="ECS Agent" key="serverless">
              <DatadogECS serverless="true" />
            </TabItems>
          </NavTab>
        </InstallationSteps>
      )}
    </>
  );
};
export default DatadogIntegration;
