import Accordion from "core/components/accordion";
import ActionMenu from "core/components/action-menu";
import Dropdown from "core/components/dropdown";
import FormInputCheckbox from "core/components/form/form-checkbox";
import FormTextInput from "core/components/form/form-text-input";
import { ColorPalette } from "core/components/svg/common";
import { CopyIcon } from "core/components/svg/icons";
import Tooltip from "core/components/tooltip";
import FormCheckbox from "core/components/v2/form/form-checkbox";
import { StatusBadgeType } from "core/components/v2/status-badge";
import { debounceHandler } from "core/utils";
import React, { useEffect } from "react";
import { HexColorPicker } from "react-colorful";
import { useDispatch } from "react-redux";
import { showToast } from "store/toast-alerts/actions";
import useDidMountEffect from "views/layouts/app/routes/useDidMountEffect";
import {
  getAvailableAggregations,
  getColumnName,
  getContrastColor,
  getMetricDetails,
  isMetricAggregation,
} from "../common/utils";
import {
  AddNewButton,
  BuilderButton,
  DeleteButton,
  EditButton,
  RemoveButton,
} from "../core/components/buttons/builder-buttons";
import CofirmationDialog from "../core/components/dialog/confirmation-dialog";
import {
  AggMethod,
  ChartType,
  FilterOpEnum,
  KpiType,
  KpiTypeOptions,
  ListType,
  MetricTypeEnum,
  OnDropDownChange,
  Query,
  SelectOneMetric,
  VisualFormattingRule,
  defaultQuery,
  getKpiName,
  getWidgetType,
  sqlOperators,
  toKpiType,
} from "../entities/builder.entities";
import { WithExpr } from "../entities/extra.entities";
import {
  TPL_TYPE_DEFAULT,
  defaultTplOptions,
  getTplByType,
} from "../entities/tpl.entities";
import useBuilder from "../views/core/useBuilder";
// import "./_style.scss";
import { QuerySelectionProps } from "./entities";
import Filter from "./filter/filter";
import GroupBy from "./group-by/group-by";
import Metric from "./metric/metric";

const QuerySelection = (props: QuerySelectionProps) => {
  const {
    query,
    chartType,
    onQueryChange: onChange,
    queryIndex,
    onQueryDelete: onDelete,
    builderConfig,
    onQueryCopy: onCopy,
  } = props;
  const KpiOptions = KpiTypeOptions.map((option) => {
    return { label: option.title, value: option.value + "" };
  });
  const dispatch = useDispatch();
  const builder = useBuilder();

  // Builder Data to use to display options
  const { getOriginalMetric } = builder.selectors;

  // Selected Metric
  const existingMetric =
    query.columns.length > 0 ? (query.columns[0] as string) : "";
  const selectedMetric = existingMetric;
  const metricDetails = getMetricDetails(selectedMetric);

  // Selected Sourcess
  const resourceType = query.source.name;
  const queryType = toKpiType(resourceType);

  // const disblaeAggregation = queryType === KpiType.KpiTypeLog || queryType === KpiType.KpiTypeTrace;
  const widgetType = getWidgetType(chartType);
  const disableIngestionSettings = widgetType === ListType;
  const originalMetric = getOriginalMetric(
    metricDetails.metricName,
    resourceType
  );
  const metricAggregationSelected = isMetricAggregation(
    metricDetails.aggregation
  );

  const [showConfirmDialog, setShowConfirmDialog] = React.useState<KpiType>(
    KpiType.KpiTypeNone
  );
  const renderDialog = () => {
    return (
      <CofirmationDialog
        isOpen={showConfirmDialog !== KpiType.KpiTypeNone}
        onCancel={() => {
          setShowConfirmDialog(KpiType.KpiTypeNone);
        }}
        onConfirm={() => {
          changeKpiType(showConfirmDialog);
          setShowConfirmDialog(KpiType.KpiTypeNone);
        }}
        titleMessage="Click confirm to change the data source, thus removing the existing query"
      />
    );
  };
  const changeKpiType = (selectedKpiType: KpiType) => {
    const copy: Query = JSON.parse(JSON.stringify(defaultQuery));
    copy.source.name = getKpiName(selectedKpiType);
    onChange(copy);
  };
  const isAllSoucesSame = (selecctedKpiType: string) => {
    if (!builderConfig) return true;
    if (builderConfig.length === 1) return true;
    const sources = builderConfig.map((query: Query) => {
      return query.source.name;
    });
    return sources.every((val) => val === selecctedKpiType);
  };
  const kpiTypeSelection = () => {
    return (
      <div className="wrapper kpi-options">
        <span className="letter">
          {String.fromCharCode(queryIndex + "A".charCodeAt(0))}
        </span>
        <Dropdown
          options={KpiOptions}
          value={queryType}
          onChange={(value: OnDropDownChange) => {
            const intValue = parseInt(value.value);
            const selectedKpiType = intValue as KpiType;
            if (queryType === selectedKpiType) return;
            if (!isAllSoucesSame(getKpiName(selectedKpiType))) {
              dispatch(
                showToast(
                  "error",
                  "Sources must be the same for all queires in a single chart"
                )
              );
              return;
            }
            const isColumnInValid = query.columns.every((column) => {
              const c = column as string;
              const metricDetails = getMetricDetails(c);
              return metricDetails.metricName !== SelectOneMetric;
            });
            if (query.columns.length > 0 && isColumnInValid) {
              setShowConfirmDialog(selectedKpiType);
              return;
            }
            changeKpiType(selectedKpiType);
          }}
        />
        {renderDialog()}
      </div>
    );
  };
  const metricOptions = () => {
    return (
      <Metric
        chartType={chartType}
        builderConfig={query}
        onMetricUpdate={(updatedConfig: Query) => {
          onChange(updatedConfig);
        }}
      />
    );
  };

  const bySelection = () => {
    // if (!originalMetric) return "";
    const availableAggregations = getAvailableAggregations(
      originalMetric?.type || MetricTypeEnum.MetricNameKey
    );
    return (
      <div className="wrapper">
        <GroupBy
          query={query}
          chartType={chartType}
          onConfigUpdate={(builderConfig: Query) => {
            onChange(builderConfig);
          }}
        />
        {metricAggregationSelected ? (
          <div className="content">
            <Dropdown
              options={availableAggregations.map((o) => {
                return {
                  label: o.title + " of all values",
                  value: o.value,
                };
              })}
              onChange={(data: OnDropDownChange) => {
                metricDetails.subAggregation = data.value as AggMethod;
                const updateMetric = getColumnName(metricDetails);
                const copy = { ...query };
                copy.columns[0] = updateMetric;
                onChange(copy);
              }}
              value={metricDetails.subAggregation}
              label="Aggregated By"
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };
  const filtersSelection = () => {
    return (
      <Filter
        chartType={chartType}
        query={query}
        onConfigUpdate={(builderConfig: Query) => {
          onChange(builderConfig);
        }}
      />
    );
  };
  const [displayTimeAggregation, setDisplayTimeAggregation] =
    React.useState(false);
  const aggregateSelection = () => {
    // if (!originalMetric) return "";
    const options = getAvailableAggregations(
      originalMetric?.type || MetricTypeEnum.MetricNameKey
    );
    return (
      <Dropdown
        options={options.map((o) => {
          return {
            label: o.title + " By",
            value: o.value,
          };
        })}
        onChange={(value: OnDropDownChange) => {
          if (!originalMetric) return;
          metricDetails.aggregation = value.value as AggMethod;
          const updateMetricName = getColumnName(metricDetails);
          // onMetricUpdate(updateMetricName);
          const copy = { ...query };
          copy.columns[0] = updateMetricName;
          const metricResource = originalMetric.resource;
          if (copy.source.name !== metricResource) {
            copy.source.name = metricResource;
          }
          onChange(copy);
        }}
        value={metricDetails.aggregation}
      />
    );
  };
  const [aliasEdit, setAliasEdit] = React.useState<boolean>(false);
  const [alias, setAlias] = React.useState<string>(
    query.meta_data?.alias || ""
  );
  const onAliasChange = debounceHandler(
    -3,
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAlias(event.target.value);
    },
    500
  );
  useEffect(() => {
    if (alias && alias !== query.meta_data?.alias) {
      const copy = { ...query };
      if (!copy.meta_data) {
        copy.meta_data = {
          alias: "",
        };
      }
      copy.meta_data.alias = alias;
      onChange(copy);
    }
  }, [alias]);
  const rendeAlias = () => {
    return (
      <div className="wrapper">
        {!aliasEdit ? (
          <EditButton
            btnText={query.meta_data?.alias ? query.meta_data?.alias : "Alias"}
            onClick={() => {
              setAliasEdit(true);
            }}
            className="alias-btn"
          />
        ) : (
          <React.Fragment>
            <FormTextInput
              onChange={(e) => {
                onAliasChange(e);
              }}
              defaultValue={alias}
            />
            <RemoveButton
              onClick={() => {
                if (!query.meta_data) {
                  setAliasEdit(false);
                  setAlias("");
                  return;
                }
                const copy = { ...query };
                if (copy.meta_data) {
                  copy.meta_data.alias = "";
                }
                onChange(copy);
                setAliasEdit(false);
                setAlias("");
              }}
              btnText=""
            />
          </React.Fragment>
        )}
      </div>
    );
  };
  const [ingestInterval, setIngestInterval] = React.useState<number>(
    query.granularity?.ingest_interval || 0
  );
  const onIngestIntervalChange = debounceHandler(
    -1,
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (typeof value === "string" && !isNaN(parseInt(value))) {
        setIngestInterval(parseInt(value));
      }
    },
    500
  );
  useEffect(() => {
    if (
      ingestInterval &&
      ingestInterval !== query.granularity?.ingest_interval
    ) {
      const copy = { ...query };
      if (!copy.granularity) {
        copy.granularity = {
          ingest_interval: 0,
          value: 0,
        };
      }
      copy.granularity.ingest_interval = ingestInterval;
      onChange(copy);
    }
  }, [ingestInterval]);
  const renderIngestInterval = () => {
    return disableIngestionSettings ? (
      ""
    ) : displayTimeAggregation ? (
      <div className="ingest-interval">
        <span className="title">over an input window of</span>
        <FormTextInput
          label={"In Seconds"}
          defaultValue={ingestInterval}
          onChange={(event) => {
            onIngestIntervalChange(event);
          }}
          type="number"
          maxLength={86400}
        />
        <DeleteButton
          onClick={() => {
            setDisplayTimeAggregation(false);
            const copy = { ...query };
            copy.granularity = {
              ingest_interval: 15,
              value: 0,
            };
            onChange(copy);
            setIngestInterval(15);
          }}
          btnText=""
        />
      </div>
    ) : (
      <AddNewButton
        btnText="Specify time aggregation"
        onClick={() => {
          setDisplayTimeAggregation(true);
        }}
      />
    );
  };
  const [formattingRules, setFormattingRules] = React.useState(
    query.meta_data?.visualFormatting || []
  );
  const [selectedColor, setSelectedColor] = React.useState<string>("black");
  const onRulesChange = debounceHandler(
    -2,
    (updatedRules: VisualFormattingRule[]) => {
      setFormattingRules(updatedRules);
    },
    500
  );
  useDidMountEffect(() => {
    if (formattingRules) {
      const copy = { ...query };
      if (!copy.meta_data) {
        copy.meta_data = {};
      }
      copy.meta_data.visualFormatting = formattingRules;
      onChange(copy);
    }
  }, [JSON.stringify(formattingRules)]);
  const renderVisualFormattingRules = () => {
    return (
      <Accordion
        heading="Visual Formatting Rules"
        key={"visual"}
        className="visual"
      >
        <div className="wrapper">
          <div>
            <div className="rules">
              {formattingRules.map((rule, index) => {
                return (
                  <div className="rule" key={index}>
                    <div className="wrapper">
                      If value is
                      <Dropdown
                        options={sqlOperators.filter((o) => o.isArithmetic)}
                        label={"Operator"}
                        labelField={"title"}
                        valueField={"value"}
                        value={rule.operator}
                        onChange={(e: OnDropDownChange) => {
                          const copyFormattingRules = [...formattingRules];
                          copyFormattingRules[index].operator =
                            e.value as FilterOpEnum;
                          const copy = { ...query };
                          if (!copy.meta_data) {
                            copy.meta_data = {};
                          }
                          copy.meta_data.visualFormatting = copyFormattingRules;
                          onChange(copy);
                          setFormattingRules(copyFormattingRules);
                        }}
                      />
                      <FormTextInput
                        label={"Value"}
                        defaultValue={rule.value}
                        onChange={(e) => {
                          const copyFormattingRules = [...formattingRules];
                          copyFormattingRules[index].value = parseInt(
                            e.target.value
                          );
                          onRulesChange(copyFormattingRules);
                        }}
                        type="number"
                      />
                      show with
                      <ActionMenu
                        popoverMenuClassName="color-picker-popover"
                        dialog={true}
                        stopPropagation={true}
                        position="bottom right"
                        iconColor={rule.color}
                        buttonIcon={
                          <div
                            style={{ background: rule.color }}
                            onClick={() => {
                              setSelectedColor(rule.color);
                            }}
                          >
                            <ColorPalette
                              color={getContrastColor(rule.color)}
                            />
                          </div>
                        }
                      >
                        <HexColorPicker
                          color={selectedColor}
                          onChange={(newColor) => {
                            const copyFormattingRules = [...formattingRules];
                            copyFormattingRules[index].color = newColor;
                            onRulesChange(copyFormattingRules);
                          }}
                        />
                      </ActionMenu>
                      <RemoveButton
                        onClick={() => {
                          const copyFormattingRules = [...formattingRules];
                          copyFormattingRules.splice(index, 1);
                          onRulesChange(copyFormattingRules);
                        }}
                        btnText=""
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <AddNewButton
              onClick={() => {
                const copyQuery = { ...query };
                if (!copyQuery.meta_data) {
                  copyQuery.meta_data = {};
                }
                if (!copyQuery.meta_data.visualFormatting) {
                  copyQuery.meta_data.visualFormatting = [];
                }
                copyQuery.meta_data.visualFormatting.push({
                  operator: FilterOpEnum.GreaterThanEqual,
                  value: 0,
                  color: "black",
                });
                onChange(copyQuery);
                setFormattingRules(copyQuery.meta_data.visualFormatting);
              }}
              btnText="Add Rule"
            />
          </div>
        </div>
      </Accordion>
    );
  };
  const renderDiffSelection = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <FormCheckbox
          label="Show Comparison with Previous Time Period"
          id={"show-diff-selection"}
          checked={typeof query?.meta_data?.comparisonRanges !== "undefined"}
          onChange={(checked: boolean) => {
            const copy = { ...query };
            if (checked) {
              if (!copy.meta_data) {
                copy.meta_data = {};
              }
              copy.meta_data.comparisonRanges = {
                badgeType: StatusBadgeType.Success,
              };
            } else if (copy.meta_data) {
              delete copy.meta_data.comparisonRanges;
            }
            onChange(copy);
          }}
        />
        {query?.meta_data?.comparisonRanges && (
          <React.Fragment>
            <span style={{ marginLeft: "10px", marginRight: "10px" }}>
              Display comparison as
            </span>
            <Dropdown
              value={query?.meta_data?.comparisonRanges?.badgeType || ""}
              options={["success", "error"]}
              onChange={(e: { value: string }) => {
                const copy = { ...query };
                const customRange = {
                  badgeType: e.value as StatusBadgeType,
                };
                if (!copy.meta_data) {
                  copy.meta_data = {};
                }
                if (!copy.meta_data.comparisonRanges) {
                  copy.meta_data.comparisonRanges = customRange;
                }
                copy.meta_data.comparisonRanges = customRange;
                onChange(copy);
              }}
            />
          </React.Fragment>
        )}
      </div>
    );
  };
  const advancedOptions = () => {
    return (
      <Accordion heading="Advanced Options">
        <div className="advanced-options">
          {renderSelectDataByTopRes()}
          {unitOverride()}
          {renderIngestInterval()}
          {chartType === ChartType.QueryValueChart ? renderDiffSelection() : ""}
        </div>
        {chartType === ChartType.QueryValueChart
          ? renderVisualFormattingRules()
          : ""}
      </Accordion>
    );
  };
  const unitOverride = () => {
    return (
      <Dropdown
        options={defaultTplOptions.filter((o) => o.externalVisible)}
        label={"Unit Format"}
        // value={configValue?.modifier?.tpl?.type || ""}
        valueField={"type"}
        labelField={"label"}
        onChange={(e: OnDropDownChange) => {
          const tpl = getTplByType(e.value);
          if (!tpl) return;
          const copy = { ...query };
          if (!copy.meta_data) {
            copy.meta_data = {};
          }
          if (tpl.type === TPL_TYPE_DEFAULT) {
            delete copy.meta_data.tpl;
          } else {
            copy.meta_data.tpl = tpl;
          }
          onChange(copy);
        }}
        value={query.meta_data?.tpl?.type || ""}
      />
    );
  };
  const renderSelectDataByTopRes = () => (
    <FormInputCheckbox
      label="Select Data By Top Resource"
      onChange={(e: any) => {
        const checked = e.target.checked as boolean;
        const newQry = { ...query };

        const selectDataByTopResConfig: WithExpr = {
          key: "IS_SELECT_DATA_BY_TOP_RESOURCE",
          value: [],
          is_arg: true,
        };

        if (checked) {
          newQry.with?.push(selectDataByTopResConfig);
        } else {
          newQry.with = newQry.with?.filter(
            (w) => w.key !== "IS_SELECT_DATA_BY_TOP_RESOURCE"
          );
        }

        onChange(newQry);
      }}
      checked={
        query.with &&
        query.with?.findIndex(
          (w) => w.key === "IS_SELECT_DATA_BY_TOP_RESOURCE"
        ) > -1
      }
    />
  );
  const renderDeleteButton = () => {
    return (
      <DeleteButton
        btnText=""
        onClick={() => {
          onDelete && onDelete(queryIndex);
        }}
      />
    );
  };
  const copyButton = () => {
    return (
      <div data-tip data-for={`clone-query`}>
        <BuilderButton
          btnText=""
          onClick={() => {
            onCopy && onCopy(query);
          }}
          icon={<CopyIcon />}
        />
        <Tooltip id={`clone-query`} type="dark" effect="solid">
          <span>Copy Query</span>
        </Tooltip>
      </div>
    );
  };
  const renderSelection = () => {
    return (
      <div className="wrapper all-selection">
        <div className="wrapper">
          {kpiTypeSelection()}
          {metricOptions()}
          {aggregateSelection()}
          {bySelection()}
        </div>
        <div className="wrapper">
          {rendeAlias()}
          {renderDeleteButton()}
          {copyButton()}
        </div>
      </div>
    );
  };
  const renderSingleContent = () => {
    return (
      <div className="query-selection column">
        {renderSelection()}
        <div className="wrapper">{filtersSelection()}</div>
        {advancedOptions()}
      </div>
    );
  };
  // return renderSections();
  return renderSingleContent();
};
export default QuerySelection;
