import { capitalizeFirstLetter } from "core/application/utils";
import ColorPicker from "core/components/v2/color-picker";
import DropdownSelection from "core/components/v2/dropdown-selection";
import FormTextInput from "core/components/v2/form/form-text-input";
import { ColorPaletteIcon, DeleteIcon } from "core/components/v2/svg/icons";
import { DragHandlerIcon } from "core/components/v2/svg/interaction-icons";
import React from "react";
import {
  ColorApplyAt,
  FilterOpEnum,
  VisualFormattingRule,
  operatorMapping,
  sqlOperators,
} from "../../entities/builder.entities";
import "./_style.scss";

export interface VisualFormattingRuleItemProps {
  rule: VisualFormattingRule;
  idx: number;
  onOperatorChange: (idx: number, value: string) => void;
  onValueChange: (idx: number, value: string) => void;
  onValue2Change: (idx: number, value: string) => void;
  onColorChange: (idx: number, value: string) => void;
  onColorApplyAtChange: (idx: number, value: ColorApplyAt) => void;
  onRuleDelete: (idx: number) => void;
}

const operators = sqlOperators
  .filter((so) => so.isArithmetic)
  .map((so) => ({
    label: so.title,
    value: so.value,
  }));

export default function VisualFormattingRuleItem({
  rule,
  idx,
  onOperatorChange,
  onValueChange,
  onValue2Change,
  onColorChange,
  onColorApplyAtChange,
  onRuleDelete,
}: VisualFormattingRuleItemProps) {
  return (
    <div className="formatting-rule-container">
      <span>
        <DragHandlerIcon color={"var(--color-text)"} />
      </span>

      <label>If value is</label>

      <div className="condition">
        <DropdownSelection
          placeholder="Select Condition"
          notFoundContent="No options found"
          selectedValues={rule.operator || []}
          options={operators}
          onChange={(value) => {
            onOperatorChange(idx, value as string);
          }}
        />
      </div>

      <div className="values-container">
        <FormTextInput
          id="value1"
          onChange={(value) => {
            onValueChange(idx, value);
          }}
          value={rule.value.toString()}
        />

        {operatorMapping[rule.operator] === FilterOpEnum.Between && (
          <>
            <label>to</label>

            <FormTextInput
              id="value2"
              onChange={(value) => {
                onValue2Change(idx, value);
              }}
              value={rule.value2?.toString() || "0"}
            />
          </>
        )}
      </div>

      <label>show</label>

      <div className="applyAt">
        <DropdownSelection
          showSearch={false}
          selectedValues={rule.applyAt || [ColorApplyAt.Value]}
          options={[
            {
              label: capitalizeFirstLetter(String(ColorApplyAt.Background)),
              value: ColorApplyAt.Background,
            },
            {
              label: capitalizeFirstLetter(String(ColorApplyAt.Value)),
              value: ColorApplyAt.Value,
            },
          ]}
          onChange={(value) => {
            onColorApplyAtChange(idx, value as ColorApplyAt);
          }}
        />
      </div>

      <label>with</label>

      <div className="color-picker">
        <ColorPicker
          prefixIcon={<ColorPaletteIcon />}
          handleColorChange={(value) => {
            onColorChange(idx, value.hex);
          }}
          defaultHashColor={rule.color}
          disabledAlpha={true}
        />
      </div>

      <div
        className="action-icon delete"
        onClick={(e) => {
          e.stopPropagation();
          onRuleDelete(idx);
        }}
      >
        <DeleteIcon color="var(--color-error)" />
      </div>
    </div>
  );
}
