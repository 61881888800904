import Button from "core/components/v2/button";
import { PlusIcon } from "core/components/v2/svg/icons";
import { debounceHandler } from "core/utils";
import React, { useCallback, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { recievedBuilderMetricList } from "store/widgets/actions";
import { fetchMetricList } from "store/widgets/api";
import { DataType, MetricsAPIResp } from "store/widgets/entities";
import { getBuilderMetrics } from "store/widgets/selectors";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import {
  ATTRIBUTE_FILTER,
  AttrValues,
  FilterOp,
  FilterOption,
  KpiType,
  SelectFilter,
  getDefaultOperator,
  getOperatorZeroValue,
  getWidgetType,
  sqlOperators,
  toKpiType,
} from "views/modules/builder/entities/builder.entities";
import { CustomObject } from "views/modules/builder/entities/extra.entities";
import { BuilderSelectionProps } from "../../views/components/entities";
import "./_style.scss";
import { FilterItem } from "./filter-item";
import {
  convertBuilderFiltersToUIFilters,
  convertUIFiltersToBuilderFilters,
  getNewFilterIdx,
  getUIFilterKey,
} from "./helper";

const Filter = ({
  chartType,
  query,
  onConfigUpdate,
  disableAddButton,
}: BuilderSelectionProps) => {
  const dispatch = useDispatch();
  const builderMetrics = useSelector(getBuilderMetrics);

  const routeData = useContext(MwRouteContext);
  const { fromTs, toTs } = routeData.params.dateRange;

  const kpiType = useMemo(
    () => toKpiType(query.source.name),
    [query.source.name]
  );

  // Get the index of the attribute group filter from the query
  // All the attribute filters will be in the same group i.e. object
  // with the key = ATTRIBUTE_FILTER
  const attrFilterGroupIdx = useMemo(
    () => (query?.with || []).findIndex((w) => w.key === ATTRIBUTE_FILTER),
    [query?.with?.length]
  );

  const attrFilterGroup = useMemo(
    () => (query?.with || []).find((w) => w.key === ATTRIBUTE_FILTER),
    [JSON.stringify(query?.with)]
  );

  // Each individual attribute filter will be stored as key-value pair
  // in the object with the key = ATTRIBUTE_FILTER
  const attrFilterGroupItems: Record<
    string,
    Record<string, CustomObject>
  > = useMemo(
    () =>
      attrFilterGroup?.value
        ? convertBuilderFiltersToUIFilters(attrFilterGroup.value)
        : {},
    [JSON.stringify(attrFilterGroup?.value)]
  );

  const attrKeyOptions = useMemo(() => {
    if (kpiType === KpiType.KpiTypeNone) return [];

    return ((builderMetrics.filters?.items as AttrValues[]) || []).map(
      (item) =>
        ({
          label: item.label || item.name || "", // item.name will never be undefined or empty
          value: item.name || "", // item.name will never be undefined or empty
          type: item.type,
          options: item.values || [],
        }) as FilterOption
    );
  }, [query.source.name, chartType, builderMetrics.filters?.items]);

  const onFilterTextValueChange = useCallback(
    debounceHandler(
      -1,
      (filterKey: string, value: string) => {
        const curAttrKey = Object.keys(attrFilterGroupItems[filterKey])[0];
        const curFilter = attrFilterGroupItems[filterKey][curAttrKey];
        const curOperator = Object.keys(curFilter)[0];

        let operatorVal: number | string = value;
        if (value && !isNaN(Number(value))) operatorVal = Number(value);

        if (curOperator) {
          curFilter[curOperator] = operatorVal;
        }

        attrFilterGroupItems[filterKey][curAttrKey] = curFilter;
        updateAttrGroupItems(attrFilterGroupItems);
      },
      500
    ),
    [attrFilterGroupItems]
  );

  const isValidFilterValue = (
    filterKey: string,
    operator: FilterOp
  ): boolean => {
    if (filterKey === SelectFilter || !attrFilterGroupItems) return false;

    const filter = attrFilterGroupItems[filterKey];
    const attribute = Object.keys(filter)[0];
    const value = attrFilterGroupItems[filterKey][attribute];
    if (Array.isArray(value) && value.length === 0) return false;

    if (typeof value === "object") {
      const valueObj = value as Record<string, string[]>;
      const val = valueObj[operator];

      return val && Array.isArray(val) ? val.length > 0 : val !== "";
    }

    return false;
  };

  const handleAddFilter = () => {
    const newQuery = {
      ...query,
    };

    const newSelectFilter = {
      [SelectFilter]: {
        "": "",
      },
    };

    const newFilters = convertUIFiltersToBuilderFilters({
      ...attrFilterGroupItems,
      [getUIFilterKey(newSelectFilter, 0)]: newSelectFilter,
    });

    // If there is attribute filter group, add a new filter item
    if (newQuery.with && attrFilterGroupIdx > -1) {
      newQuery.with[attrFilterGroupIdx].value = newFilters;
    } else {
      // If there is no attribute filter group, create a new one
      newQuery.with = [
        ...(newQuery.with || []),
        {
          key: ATTRIBUTE_FILTER,
          value: newFilters,
          is_arg: true,
        },
      ];
    }

    onConfigUpdate(newQuery);
  };

  const updateAttrGroupItems = (
    newFilterGroupItems: Record<string, Record<string, CustomObject>>
  ) => {
    const filters = convertUIFiltersToBuilderFilters(newFilterGroupItems);

    const newQuery = { ...query };
    if (newQuery.with) newQuery.with[attrFilterGroupIdx].value = filters;
    else {
      newQuery.with = [
        {
          key: ATTRIBUTE_FILTER,
          value: filters,
          is_arg: true,
        },
      ];
    }

    onConfigUpdate(newQuery);
  };

  const handleAttrKeyOptionChange = (
    filterKey: string,
    newAttrKey: string | string[]
  ) => {
    const curAttrKey = Object.keys(attrFilterGroupItems[filterKey])[0];
    if (curAttrKey === newAttrKey) return;

    delete attrFilterGroupItems[filterKey];
    const newAttrDetails = attrKeyOptions.find((o) => o.value === newAttrKey);

    if (newAttrKey) {
      const strNewAttrKey = newAttrKey as string;

      // Create the new key-value pair as per the new attribute
      const newFilterOper = getDefaultOperator(newAttrDetails);
      // Create the filter
      const newFilterDetails = {
        [strNewAttrKey]: {
          [newFilterOper]: getOperatorZeroValue(newFilterOper),
        },
      };

      const newFilterIdx = getNewFilterIdx(
        attrFilterGroupItems,
        newFilterDetails
      );

      attrFilterGroupItems[getUIFilterKey(newFilterDetails, newFilterIdx)] =
        newFilterDetails;
    }

    updateAttrGroupItems(attrFilterGroupItems);
  };

  const handleOperatorChange = (
    filterKey: string,
    newOperator: string | string[]
  ) => {
    const curAttrKey = Object.keys(attrFilterGroupItems[filterKey])[0];
    const curFilter = attrFilterGroupItems[filterKey][curAttrKey];
    const curOperator = Object.keys(curFilter)[0];

    if (!newOperator || curOperator === newOperator) return;
    delete attrFilterGroupItems[filterKey];

    const newOper = newOperator as string;
    const newOperDetails = sqlOperators.find((so) => so.value === newOper);

    if (curOperator) {
      let operatorValues = curFilter[curOperator];
      delete curFilter[curOperator];

      if (Array.isArray(operatorValues)) {
        if (!newOperDetails) return;
        if (!newOperDetails.allowMultiple) operatorValues = operatorValues[0];
      }

      curFilter[newOper] = operatorValues;
    }

    const newFilterDetails = {
      [curAttrKey]: curFilter,
    };

    const newFilterIdx = getNewFilterIdx(
      attrFilterGroupItems,
      newFilterDetails
    );

    attrFilterGroupItems[getUIFilterKey(newFilterDetails, newFilterIdx)] =
      newFilterDetails;
    updateAttrGroupItems(attrFilterGroupItems);
  };

  const handleOperatorDropdownValueChange = (
    filterKey: string,
    value: string | string[]
  ) => {
    const curAttrKey = Object.keys(attrFilterGroupItems[filterKey])[0];
    const curFilter = attrFilterGroupItems[filterKey][curAttrKey];
    const curOperator = Object.keys(curFilter)[0];

    if (curOperator) {
      curFilter[curOperator] = value;
    }

    attrFilterGroupItems[filterKey][curAttrKey] = curFilter;
    updateAttrGroupItems(attrFilterGroupItems);
  };

  const handleDeleteFilter = (filterKey: string) => {
    const newQuery = { ...query };
    if (newQuery.with) {
      const cpAttrFilterGroupItems = { ...attrFilterGroupItems };
      delete cpAttrFilterGroupItems[filterKey];

      if (Object.keys(cpAttrFilterGroupItems).length === 0)
        newQuery.with.splice(attrFilterGroupIdx, 1);
      else
        newQuery.with[attrFilterGroupIdx].value =
          convertUIFiltersToBuilderFilters(cpAttrFilterGroupItems);
    }

    onConfigUpdate(newQuery);
  };

  const requestFilterOptions = (
    searchVal: string,
    event?: string,
    attrKey?: string
  ) => {
    // Don't make api call if metric is not selected
    if (!query.source.name) return;
    // If event is onAttrKeyOptionFocus
    if (event === "onAttrKeyOptionFocus" && attrKey) {
      if (attrKeyOptions.find((ako) => ako.value === attrKey)) return;
      // If details of attrKey is not present, fetch it from the api
      else searchVal = attrKey;
    }

    dispatch(
      fetchMetricList(
        {
          kpiType: kpiType,
          widgetType: getWidgetType(chartType),
          dataType: DataType.Filters,
          search: searchVal,
          resource: query.source.name,
        },
        (resp: MetricsAPIResp) =>
          recievedBuilderMetricList(DataType.Filters, resp)
      )
    );
  };

  return (
    <div className="filter-selection-container">
      {Object.entries(attrFilterGroupItems).map(([filterKey, filter]) => (
        <FilterItem
          key={filterKey}
          filterKey={filterKey}
          filter={filter}
          attrKeyOptions={attrKeyOptions}
          builderMetrics={builderMetrics}
          fromTs={fromTs}
          toTs={toTs}
          isValidFilterValue={isValidFilterValue}
          handleAttrKeyOptionChange={handleAttrKeyOptionChange}
          handleOperatorChange={handleOperatorChange}
          handleOperatorDropdownValueChange={handleOperatorDropdownValueChange}
          handleDeleteFilter={handleDeleteFilter}
          requestFilterOptions={requestFilterOptions}
          resource={query.source.name}
          onFilterTextValueChange={onFilterTextValueChange}
        />
      ))}

      <Button
        prefixicon={<PlusIcon color="var(--color-text)" />}
        onClick={handleAddFilter}
        disabled={disableAddButton}
      >
        Add Filter
      </Button>
    </div>
  );
};

export default Filter;
