import Button from "core/components/v2/button";
import * as React from "react";
interface Props {
  onPrimaryBtnClick?: () => void;
  primaryBtnText?: string;
  onSecondaryBtnClick?: () => void;
  secondaryBtnText?: string;
}
const FooterButtons = (props: Props) => {
  const {
    onPrimaryBtnClick,
    primaryBtnText,
    onSecondaryBtnClick,
    secondaryBtnText,
  } = props;
  return (
    <>
      <div className="installation-footer-buttons-wrapper">
        {onSecondaryBtnClick && (
          <Button secondary onClick={onSecondaryBtnClick}>
            {secondaryBtnText ? secondaryBtnText : "Cancel"}
          </Button>
        )}
        {onPrimaryBtnClick && (
          <Button primary onClick={onPrimaryBtnClick}>
            {primaryBtnText ? primaryBtnText : "Next"}
          </Button>
        )}
      </div>
    </>
  );
};
export default FooterButtons;
