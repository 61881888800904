import Button from "core/components/v2/button";
import Dialog from "core/components/v2/dialog";
import AppContext from "core/components/wrapper/context";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { requestUpdateProjectDbDeleted } from "store/settings/project/api";
import { showToast } from "store/toast-alerts/actions";
import { ApiResponse } from "views/modules/settings-v2/entity";

interface Props {
  url: string;
}
const DBDeletedDialog = (props: Props) => {
  const { url } = props;
  const context = useContext(AppContext);
  const projects = context.user?.projects;
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    Array.isArray(projects) &&
      projects.forEach((item) => {
        const isSameProject = context?.user?.account?.project_id == item?.id;

        const isDbDeleted = isSameProject && item.db_deleted;
        if (isDbDeleted) {
          setIsDialogOpen(true);
        }
      });
  }, [url]);
  return (
    <>
      {isDialogOpen && (
        <Dialog
          isOpen={isDialogOpen}
          position={"center"}
          hideCloseButton={true}
          overlayClassName={
            "subscription-activate-confirmation__dialog common-confirmation-dialog"
          }
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClose={() => {}}
        >
          <h4>
            Your project has been temporarily paused due to lack of activity.
            Please click Activate below to re-enable your project.
          </h4>
          <div className={"dialog-buttons-wrapper"}>
            <Button
              primary
              loading={isLoading}
              onClick={() => {
                setIsLoading(true);
                dispatch(
                  requestUpdateProjectDbDeleted(
                    {
                      account_id: context.user?.account_id || 0,
                      project_id: context.user?.account?.project_id || 0,
                      db_deleted: false,
                    },
                    (res?: ApiResponse) => {
                      setIsLoading(false);
                      if (res?.status === 200) {
                        const updatedProjects =
                          Array.isArray(projects) &&
                          projects.map((project) =>
                            project.id === context.user?.account?.project_id
                              ? { ...project, db_deleted: false }
                              : project
                          );

                        dispatch(
                          showToast(
                            "success",
                            `Project activated successfully.`
                          )
                        );
                        context.changeContext({
                          key: "user",
                          value: {
                            ...context.user,
                            projects: Array.isArray(updatedProjects)
                              ? updatedProjects
                              : projects,
                          },
                        });
                        setIsDialogOpen(false);
                      } else {
                        dispatch(
                          showToast(
                            "error",
                            "Something went wrong while activating project. please contact middleware team to activate."
                          )
                        );
                      }
                    }
                  )
                );
              }}
            >
              Reactivate project
            </Button>
          </div>
        </Dialog>
      )}
    </>
  );
};
export default DBDeletedDialog;
