import React, {useContext, useEffect, useState} from 'react';
import {showToast} from "store/toast-alerts/actions";
import {useDispatch} from "react-redux";

import {
    ContainerizedIcon, CopyIconNew,
    DebAndUbuntuColorSvg, DockerColorSvg, KubernetesColorSvg, NativelyIcon, WindowsSvg
} from "core/components/svg/icons";
import AppContext, {ContextProps} from "core/components/wrapper/context";
import CodeViewer from "core/components/code-viewer";
import Button from "core/components/button";
import CommonAgentInstallation from "views/modules/installation/components/apm/common-agent-installation";
import FormTextInput from "core/components/form/form-text-input";
import {StepsEntity, StepsEnum} from "views/modules/integrations/entity";


export default function Ruby() {
    const context = useContext<ContextProps>(AppContext);
    const isHelloBarRendered = document.querySelector('.hello-bar') !== null;
    const [rubyVersion, setRubyVersion] = useState('tab1');
    const dispatch = useDispatch();

    function copyToClipboard(text: string) {

        navigator.clipboard.writeText(text)
            .then(() => {
                dispatch(showToast("success", "Copied to Clipboard !"))
            });
    }

    useEffect(() => {
        // this api for get ruby latest version
        fetch("https://api.github.com/repos/middleware-labs/ruby-gem/releases/latest")
            .then(response => response.json())
            .then(data => {
                const latestTag = data.tag_name;
                const tagWithoutV = latestTag.substring(1);
                setRubyVersion(tagWithoutV)

            })
            .catch(error => {
                console.error("Error:", error);
            });
    }, [])

    const [activeServiceName, setActiveServiceName] = useState('{APM-SERVICE-NAME}');

    const steps: StepsEntity = {
        deployment_type: {
            title: "Choose Deployment Type",
        },
        code: {
            title: "Getting Started with Code",
        },
        see_data: {
            title: "View Your Data",
        }
    }
    const [activeStep, setActiveStep] = useState(StepsEnum.DeploymentType);
    const [activeDeployment, setActiveDeployment] = useState('kubernetes');
    const [activeAppType, setActiveAppType] = useState('containerized');
    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const MwAgentServiceK8s = "MW_AGENT_SERVICE=mw-service.mw-agent-ns.svc.cluster.local\n"
    const MwAgentServiceLinuxDocker = "MW_AGENT_SERVICE=172.17.0.1"


    const StepThirdWindow = "gem 'opentelemetry-sdk'\n" +
        "gem 'opentelemetry-exporter-otlp'\n" +
        "gem 'opentelemetry-instrumentation-all'\n" +
        `gem 'middleware_apm_windows', '~> ${rubyVersion}'`

    const StepThird = "gem 'opentelemetry-sdk'\n" +
        "gem 'opentelemetry-exporter-otlp'\n" +
        "gem 'opentelemetry-instrumentation-all'\n" +
        "gem 'pyroscope'\n" +
        `gem 'middleware_apm_linux', '~> ${rubyVersion}'`

    const linuxRuby = "require 'middleware/ruby_gem_linux'\n" +
        "Middleware::RubyGem.init"

    const WindowRuby = "require 'middleware/ruby_gem_windows'\n" +
        "Middleware::RubyGem.init"

    const rubyTraceLinux = "OTEL_EXPORTER_OTLP_ENDPOINT=http://localhost:9320 \\ \n" +
        `OTEL_SERVICE_NAME = "${activeServiceName == '' ? "{APM-SERVICE-NAME}" : activeServiceName}" \\ \n` +
        `MW_API_KEY= ${context.user?.account?.agent_config?.token} \\ \n` +
        "{YOUR-COMMAND} "

    const rubyTraceWindow = "OTEL_EXPORTER_OTLP_ENDPOINT=http://localhost:9320 \\ \n" +
        `OTEL_SERVICE_NAME = "${activeServiceName == '' ? "{APM-SERVICE-NAME}" : activeServiceName}" \\ \n` +
        "{YOUR-COMMAND} "

    return (
        <>
            <div className={"markdown-body installation-docs apm-installation-doc ruby-doc"}>
                <div className={"apm-body"}>
                    <div className={"apm-steps"}>
                        {
                            steps && Object.keys(steps).map((step, index) => {
                                return <div key={index + 1}
                                            className={`step ${activeStep === step ? 'selected' : ''} ${(Object.keys(steps).indexOf(activeStep) || 0) > index ? 'passed' : ''}`}>
                                    <div className={"count"}>
                                        <span>{index + 1}</span>
                                    </div>
                                    <div className={"detail"} onClick={() => setActiveStep(step)}>
                                        {steps[step].title}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className={`apm-content ${!isHelloBarRendered && "hello-bar-exists"}`}>
                        {activeStep === StepsEnum.DeploymentType && <>
                            <div className={"workspace"}>
                                <div className={"blocks"}>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>1. Our APMs require the host agent in order to operate. To install a host agent please select from the below options. </span>
                                            <p><span>(If the host already has an agent running please <span
                                                onClick={() => setActiveStep('code')}> skip to Getting Started with Code.</span>)</span>
                                            </p>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Which platform are you hosting your Ruby application?</span>
                                            <span>Run only one MW Agent. Multiple agents will cause unexpected behavior.</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'button-flex'}>
                                                <button className={activeDeployment === 'kubernetes' ? 'active' : ''}
                                                        onClick={() => {
                                                            setActiveDeployment('kubernetes')
                                                            setActiveAppType('containerized')
                                                        }}>
                                                    <KubernetesColorSvg/> Kubernetes
                                                </button>
                                                <button className={activeDeployment === 'linux' ? 'active' : ''}
                                                        onClick={() => setActiveDeployment('linux')}>
                                                    <DebAndUbuntuColorSvg/> Linux
                                                </button>
                                                <button className={activeDeployment === 'docker' ? 'active' : ''}
                                                        onClick={() => setActiveDeployment('docker')}>
                                                    <DockerColorSvg/> Docker
                                                </button>
                                                <button className={activeDeployment === 'windows' ? 'active' : ''}
                                                        onClick={() => setActiveDeployment('windows')}>
                                                    <WindowsSvg/> Windows
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>2. Application Behaviour Will Be</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Please choose from below, as your application will run accordingly.</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'button-flex'}>
                                                <button className={activeAppType === 'containerized' ? 'active' : ''}
                                                        onClick={() => setActiveAppType('containerized')}>
                                                    <ContainerizedIcon/> Containerized
                                                </button>
                                                <button
                                                    className={`${activeAppType === 'natively' ? 'active' : ''} ${activeDeployment === 'kubernetes' ? 'disabled' : ''}`}
                                                    onClick={() => {
                                                        if (activeDeployment !== 'kubernetes') {
                                                            setActiveAppType('natively')
                                                        }
                                                    }}>
                                                    <NativelyIcon/> Natively
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {activeAppType === 'containerized' && <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>3. Setup Container Variable</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Application running in a container require an additional environment variable</span>
                                        </div>
                                        <div className={"content"}>
                                            {activeDeployment === 'kubernetes' && <>
                                                <div className={'docs-script'}>
                                                    <CodeViewer showLineNumbers={false}
                                                                language={'html'}>{MwAgentServiceK8s}</CodeViewer>
                                                    <span className={"doc-copy-btn"}
                                                          onClick={() => copyToClipboard(MwAgentServiceK8s)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                                </div>
                                                <span className={"sentence mini"}>The default namespace for running the Middleware agent is <strong>mw-service.mw-agent-ns.svc.cluster.local</strong>.</span><br/>
                                            </>}
                                            {activeDeployment !== 'kubernetes' && <>
                                                <div className={'docs-script'}>
                                                    <CodeViewer showLineNumbers={false}
                                                                language={'html'}>{MwAgentServiceLinuxDocker}</CodeViewer>
                                                    <span className={"doc-copy-btn"}
                                                          onClick={() => copyToClipboard(MwAgentServiceLinuxDocker)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                                </div>
                                                <span
                                                    className={"sentence mini"}>The default value for <strong>DOCKER_BRIDGE_GATEWAY_ADDRESS</strong> is typically <strong>172.17.0.1</strong>.</span><br/>
                                                <span className={"sentence mini"}>For more detailed information, refer to the official Docker&nbsp;
                                                    <a href={"https://docs.docker.com/network/network-tutorial-standalone/"}
                                                       target={"_blank"}>documentation</a>.</span>
                                            </>}
                                        </div>
                                    </div>}
                                    <div className={"block"}>
                                        <CommonAgentInstallation activeDeployment={activeDeployment}
                                                                 activeAppType={activeAppType}/>
                                    </div>
                                </div>

                            </div>
                            <div className={"buttons-group"}>
                                <Button className={"disabled"}>Back</Button>
                                <Button primary onClick={() => setActiveStep('code')}>Next</Button>
                            </div>
                        </>}

                        {activeStep === 'code' && <>
                            <div className={"workspace"}>
                                <div className={"blocks"}>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>1. Add these dependencies in your Gemfile</span>
                                        </div>

                                        <div className={"desc"}>
                                            <span>To install the Middleware Agent, your Ruby version must
                                        have <a
                                                    href={"https://www.ruby-lang.org/en/news/2020/12/25/ruby-3-0-0-released/"}
                                                    target={"_blank"} rel={'noreferrer'}>version 3.0+.</a></span>
                                        </div>
                                        <div className={"content"}>
                                            {activeDeployment !== "windows" ?
                                                <div className={'docs-script'}>
                                                    <CodeViewer showLineNumbers={false}
                                                                language={'ruby'}>{StepThird}</CodeViewer>
                                                    <span className={"doc-copy-btn"}
                                                          onClick={() => copyToClipboard(StepThird)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                                </div> : <div className={'docs-script'}>
                                                    <CodeViewer showLineNumbers={false}
                                                                language={'ruby'}>{StepThirdWindow}</CodeViewer>
                                                    <span className={"doc-copy-btn"}
                                                          onClick={() => copyToClipboard(StepThirdWindow)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                                </div>}
                                        </div>
                                    </div>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>2. Add this code at the very start of your application</span>
                                        </div>
                                        <div className={"content"}>
                                            {activeDeployment !== "windows" ?
                                                <div className={'docs-script'}>
                                                    <CodeViewer showLineNumbers={false}
                                                                language={'ruby'}>{linuxRuby}</CodeViewer>
                                                    <span className={"doc-copy-btn"}
                                                          onClick={() => copyToClipboard(linuxRuby)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                                </div> : <div className={'docs-script'}>
                                                    <CodeViewer showLineNumbers={false}
                                                                language={'ruby'}>{WindowRuby}</CodeViewer>
                                                    <span className={"doc-copy-btn"}
                                                          onClick={() => copyToClipboard(WindowRuby)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                                </div>}

                                            <div className={"content"}>
                                                <div className={"inputbox-flex"} style={{marginTop: 15}}>
                                                    <FormTextInput label={"Service Name"} placeholder={"Service Name"}
                                                                   maxLength={50}
                                                                   onChange={(e: any) => setActiveServiceName(e.target.value)}/>
                                                </div>
                                                {activeDeployment !== "windows" ?
                                                    <div className={'docs-script'}>
                                                        <CodeViewer showLineNumbers={false}
                                                                    language={'ruby'}>{rubyTraceLinux}</CodeViewer>
                                                        <span className={"doc-copy-btn"}
                                                              onClick={() => copyToClipboard(rubyTraceLinux)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                                    </div> : <div className={'docs-script'}>
                                                        <CodeViewer showLineNumbers={false}
                                                                    language={'ruby'}>{rubyTraceWindow}</CodeViewer>
                                                        <span className={"doc-copy-btn"}
                                                              onClick={() => copyToClipboard(rubyTraceWindow)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                                    </div>}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={"buttons-group"}>
                                <Button onClick={() => setActiveStep(StepsEnum.DeploymentType)}>Back</Button>
                                <Button primary onClick={() => setActiveStep('see_data')}>Next</Button>
                            </div>
                        </>}
                        {activeStep === 'see_data' && <>
                            <div className={"workspace"}>
                                <div className={"blocks"}>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>View Your Data</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Once you have completed the Ruby installation, wait 3-5 minutes before accessing your data.</span>
                                        </div>
                                        <div className={"content"}>
                                            <p className={"paragraph"}
                                               style={{marginTop: "10px", marginBottom: "0"}}>Access your Trace, Log,
                                                and Profiling data by clicking on one of the buttons bellow.</p>
                                            <p className={"paragraph"} style={{marginTop: "0px"}}>Proceed to deploy your
                                                project on {capitalizeFirstLetter(activeDeployment)}.</p>
                                            <div className={"buttons-group"}>
                                                <Button primary
                                                        onClick={() => window.open('/apm/overview', '_blank')}>Traces</Button>
                                                {activeDeployment != "windows" ? <Button primary
                                                                                         onClick={() => window.open('/apm/continuous-profiling', '_blank')}>Profiling</Button> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={"buttons-group"}>
                                <Button onClick={() => setActiveStep('code')}>Back</Button>
                                <Button className={"disabled"}>Next</Button>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </>
    )
}