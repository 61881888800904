import Button from "core/components/button";
import Dialog from "core/components/dialog";
import useQuery from "core/components/query";
import { PencilIcon } from "core/components/svg/icons";
import moment from "moment";
import * as React from "react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { receivedBuilderFormattedData } from "store/widgets/actions";
import {
    requestBuilderFormattedData,
    requestGetWidgetBuilderDialog,
    requestWidgetBuilderDialog
} from "store/widgets/api";
import {
    getAvailableMetrics,
    getWidgetBuilderDialogInflight,
    getWidgetBuilderDialogScopeConfig
} from "store/widgets/selectors";
import { ColumnTemplates } from "views/modules/builder/core/column.templates";
import WidgetActions from "views/modules/builder/core/dialog/widget.actions";
import WidgetAppView from "views/modules/builder/core/widget-app-view";
import { WidgetsApp } from "views/modules/builder/core/widgets.app";
import { CustomWidget, MetricItem, MetricTypeEnum, defaultWidgetBuilder } from "views/modules/builder/entities/builder.entities";
import WidgetBuilder from "views/modules/builder/views/widget-builder";
import LogsIntegration from "views/modules/logs/logs-components/logs.corelation";
import "./_style.scss";
const WidgetAppDialogView = (props: any) => {
    if (!props?.resource?.name)
        return <React.Fragment />
    const _scope: string = props.resource.name
    const query = useQuery();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [logWindow, setLogWindowOpen] = React.useState<boolean>(false);

    const [dateRange, setDateRange] = React.useState<any>({
        from_ts: query.get("from_ts"),
        to_ts: query.get("to_ts"),
        isFilter: false,
    });
    const [formattedInflight, setFormattedInflight] = React.useState<boolean>(false);
    const [attrInfo, setAttrInfo] = React.useState<any>({});
    const [widgetDiagram, setWidgetDiagram] = React.useState<any>([]);
    const availableMetricsData = useSelector(getAvailableMetrics);
    const availableMetrics = availableMetricsData.availableMetrics;
    const [attributesItems, setVisibleCards] = React.useState<MetricItem[]>([]);
    const [widgetBuilderDialog, setWidgetBuilderDialog] = useState<{
        isOpen: boolean;
        action: any;
        data: CustomWidget | undefined;
    }>();
    const [widgetAppLoading, setWidgetAppLoading] = useState<any>({});
    const getDialogScopeConfigSel: any = getWidgetBuilderDialogScopeConfig
    const dialogScopeConfig = useSelector((state: any) => getDialogScopeConfigSel(state, _scope))
    const loading = useSelector(getWidgetBuilderDialogInflight)
    const editAllow = query.get("edit") === "true";

    const sts = query.get("from_ts")
    const ets = query.get("to_ts")

    const [keyMappings , setKeyMappings] = React.useState<any>({});

    const mappings : any = {
        'k8s.service' : {attr:'service.name' , key:'namespace'},
        "k8s.pod" : {attr:'k8s.pod.name' , key:'pod_name'},
        "k8s.container" : {attr:'k8s.container.name' , key:'container_name'},
        "k8s.node": {attr:'host.name' , key:'host.name'},
    }

    React.useEffect(() => {
        if (availableMetrics.metrics && availableMetrics.metrics.find(m => {
            return m.resource === props.resource.resourceType;
        })) {
            let _attrs: any = [...(availableMetrics.metrics.find(m => m.resource === props.resource.resourceType)?.table.metrics || [])];
            Object.values(WidgetsApp).filter((a: any) => !a.disabled).map((item: any, key: number) => {
                _attrs.push({
                    name: item.label,
                    key: item.key,
                    label: item.label,
                    icon: item.icon,
                    isWidget: true,
                    widgetAppId: item.id,
                })
            })
            setVisibleCards(_attrs);
        } else {
            setVisibleCards([]);
        }

        if (!dialogScopeConfig?.display_scope)
            dispatch(requestGetWidgetBuilderDialog(_scope))
    }, [])

    React.useEffect(() => {
        if (attributesItems && !attributesItems.length || formattedInflight) return;

        _reqData()

    }, [attributesItems])

    const _reqData = (opts?: any ) => {
        const widgetAppId = 5
        const _params = getParams()

        if (opts?.from_ts && opts?.to_ts) {
            const fIndex = _params.findIndex((p: any) => p.key === "from_ts")
            const tIndex = _params.findIndex((p: any) => p.key === "to_ts")
            if (fIndex > -1) {
                _params[fIndex].value = opts.from_ts
            } else {
                _params.push({ key: "from_ts", value: opts.from_ts })
            }
            if (tIndex > -1) {
                _params[tIndex].value = opts.to_ts
            } else {
                _params.push({ key: "to_ts", value: opts.to_ts })
            }
        }

        let _columns: any = attributesItems.filter(item => item.type === MetricTypeEnum.MetricNameKey || item.type === MetricTypeEnum.ResourceAttributeKey).map(item => item.name)
        let _body: any = {
            label: "",
            builderId: 0,
            scopeId: 0,
            widgetAppId,
            builderConfig: {
                with: [],
                columns: _columns,
                source: {
                    name: props.resource.resourceType,
                    alias: "",
                    dataset_id: 0
                },
                join_with: [],
                group_by: [],
                order_by: [],
                top: 0
            },
            params: _params,
            builderViewOptions: {
                resource: {
                    columns: _columns,
                    resourceType: props.resource.resourceType,
                    name: _scope,
                    widgetAppId
                }
            }
        }

        dispatch(requestBuilderFormattedData({ body: _body } as any, (st: boolean, res: any) => {
            setFormattedInflight(false)
            if (st && res?.chart_data?.columns && res?.chart_data?.data && Array.isArray(res?.chart_data?.data) && res.chart_data.data.length > 0) {
                let _cols: any = {}
                for (let c of res.chart_data.columns) {
                    _cols[c.accessor] = c
                }
                setAttrInfo({
                    columns: _cols,
                    data: res.chart_data.data[0],
                })
            }
        }, true))
    }

    const saveDialogConfig = () => {
        if (!widgetDiagram || !Array.isArray(widgetDiagram) || widgetDiagram.length == 0 || !hasDiff()) return;
        dispatch(requestWidgetBuilderDialog({
            display_scope: _scope,
            body: {
                slug: `k8s_default_dialog__${_scope}`,
                config: widgetDiagram,
            }
        }, (st: boolean, res: any) => {

        }))
    }

    const _reqScopeConfig = (opts?: any) => {
        if (dialogScopeConfig && dialogScopeConfig?.config && Array.isArray(dialogScopeConfig.config)) {

            const _params = getParams()
            let _WidgetApp: any = {}
            if (opts?.from_ts && opts?.to_ts) {
                const fIndex = _params.findIndex((p: any) => p.key === "from_ts")
                const tIndex = _params.findIndex((p: any) => p.key === "to_ts")
                if (fIndex > -1) {
                    _params[fIndex].value = opts.from_ts
                } else {
                    _params.push({ key: "from_ts", value: opts.from_ts })
                }
                if (tIndex > -1) {
                    _params[tIndex].value = opts.to_ts
                } else {
                    _params.push({ key: "to_ts", value: opts.to_ts })
                }
            }

            for (let item of dialogScopeConfig.config) {
                item.child.map((c: any) => {
                    if (c?.type && c.type == "widget_app") {
                        if (c?.request_opts?.body?.builderViewOptions) {
                            c.request_opts.body.builderViewOptions = {
                                displayScope: "",
                            }
                            // delete c.request_opts.body.builderViewOptions
                        }
                        c.request_opts.body.params = _params as any
                        _WidgetApp[c.builder_id] = c.request_opts
                    }
                })
            }

            if (Object.keys(_WidgetApp).length > 0) {
                for (let k in _WidgetApp) {
                    dispatch(requestBuilderFormattedData(_WidgetApp[k] as any))
                }

            }
            setWidgetDiagram(dialogScopeConfig.config)
        }
    }

    React.useEffect(() => {
        _reqScopeConfig()
    }, [dialogScopeConfig])
    const getParams = (): any => {
        const _params: { key: string; value: string; }[] = [];
        query.forEach((value, key) => {
            _params.push({
                key: key,
                value: value
            });
        });
        _params.push({
            key: "resource",
            value: props.row.resource
        })
        return _params
    }

    const addAttrs = (item: any, index?: number) => {
        if (item.isWidget) {
            const d: CustomWidget = JSON.parse(JSON.stringify(defaultWidgetBuilder));
            d.builderViewOptions = {
                displayScope: "",
            };
            if(!Array.isArray(d.builderConfig) && d.builderConfig) {
                d.builderConfig.source.name = props.resource.resourceType;
                d.widgetAppId = item.widgetAppId;
                setWidgetBuilderDialog({
                    isOpen: true,
                    data: d,
                    action: {
                        item,
                        index
                    }
                });
            }
            return
        }

        let _obj: any = {
            type: "attr",
            attr_key: item.name,
            title: (item.name || "").replaceAll("_", " ").replaceAll(".", " ").toUpperCase(),
        }
        if (typeof index !== "undefined")
            widgetDiagram[index].child.push(_obj)
        else
            widgetDiagram.push({ child: [_obj] })

        setWidgetDiagram([...widgetDiagram])
    }

    const hasDiff = (): boolean => widgetDiagram !== dialogScopeConfig?.config

    return <React.Fragment>
        <div className={"widget_app_dialog_view__content"}>

            {loading && <React.Fragment>
                {new Array(6).fill(0).map((x: any, index: number) => {
                    return (<div key={index} className={"widget__row__card"}>
                        {new Array(index > 3 ? 2 : 4).fill(0).map((j: any, ck: number) => {
                            return (<div key={`inner_${index}__${ck}`} className={"row__cols type__attr"}
                                style={{
                                    minWidth: `${index > 3 ? "49%" : "24%"}`,
                                    height: `${index > 3 ? "220px" : "80px"}`,
                                    position: "relative"
                                }}>
                                <span style={{
                                    position: "absolute", top: "15px", left: "15px",
                                    zIndex: 2, color: "var(--color-grey-400)",
                                    margin: "auto"
                                }}>loading...</span>
                                <Skeleton
                                    borderRadius={2}
                                    width={"100%"} height={`${index > 3 ? "220px" : "80px"}`} />
                            </div>)
                        })}
                    </div>)
                })}
            </React.Fragment>}

            {!loading && <React.Fragment>
                {widgetDiagram.map((row: any, index: number) => {
                    return (<div key={index} className={"widget__row__card"}>
                        {row.child.map((item: any, ck: number) => {
                            const isWidget = item?.type && item.type == "widget_app"
                            let _attr = item.attr_key
                            let _val = ""
                            let _tpl: any = null
                            if (!isWidget) {
                                if (attrInfo?.data && typeof attrInfo.data[_attr] !== "undefined" && attrInfo.data[_attr])
                                    _val = attrInfo.data[_attr]

                                if (attrInfo?.columns && typeof attrInfo.columns[_attr] !== "undefined" && attrInfo.columns[_attr].tpl)
                                    _tpl = attrInfo.columns[_attr].tpl
                            }

                            return (<div
                                key={ck}
                                className={`row__cols type__${item.type || ""} ${row.child.length > 1 ? "more_then_one" : ""}`}>
                                {editMode && <div className={"overlay_action__wrapper"}>
                                    <button className={"delete__btn"} onClick={() => {
                                        widgetDiagram[index].child.splice(ck, 1)
                                        if (widgetDiagram[index].child.length == 0)
                                            widgetDiagram.splice(index, 1)
                                        setWidgetDiagram([...widgetDiagram])
                                    }}>&times;</button>
                                </div>}
                                <div className={"row__cols__title"}>
                                    <span>{item.title}</span>
                                    {isWidget && dateRange?.isFilter && <div className={"widget_app_view__filter"}>
                                        <span className={"filter__value"}>{moment(dateRange.from_ts).format("DD, MMM HH:mm")} - {moment(dateRange.to_ts).format("DD, MMM HH:mm")}</span>

                                        <span className={"filter__clear"} onClick={() => {
                                            let _d: any = {
                                                from_ts: moment().subtract(15, "minutes").valueOf(),
                                                to_ts: moment().valueOf(),
                                            }
                                            _d.from_ts = query.get("from_ts")
                                            _d.to_ts = query.get("to_ts")
                                            setDateRange({
                                                ..._d,
                                                isFilter: false
                                            })
                                            _reqScopeConfig(_d)
                                        }}>&times;</span>
                                    </div>}
                                </div>
                                <div className={"row__cols__content"}>

                                    {isWidget && <div className={"widget_app_view__wrapper"} title={item.builder_id}>
                                        <React.Suspense fallback={<div>App loading...</div>}>
                                            <WidgetAppView
                                                key={`${index}__${ck}`}
                                                builderViewOptions={Object.assign({}, {
                                                    builderView: {
                                                        builderId: item.builder_id,
                                                        scopeId: -1,
                                                    }
                                                })}
                                                onSortingChange={() => {}}
                                                refreshData={() => {}}
                                                nestedProps={{
                                                    onSelectionChange: (data: any) => {
                                                        setDateRange({
                                                            from_ts: data.startPoint.timestamp,
                                                            to_ts: data.endPoint.timestamp,
                                                            isFilter: true
                                                        })
                                                        _reqScopeConfig({
                                                            from_ts: data.startPoint.timestamp,
                                                            to_ts: data.endPoint.timestamp,
                                                        });
                                                    }
                                                }}
                                            />
                                        </React.Suspense>
                                    </div>}

                                    {!isWidget && <React.Fragment>
                                        {!_val && <span style={{ color: "var(--color-grey-500)" }}>N/A</span>}
                                        {_val && <React.Fragment>
                                            {_tpl && <ColumnTemplates appKey={"data_table"} colTpl={_tpl} colValue={_val} />}
                                            {!_tpl && <span>_val</span>}
                                        </React.Fragment>}
                                    </React.Fragment>}
                                </div>
                            </div>)
                        })}

                        {editMode && <WidgetActions options={attributesItems} btnSmall
                            callback={(item: any) => addAttrs(item, index)} />}
                    </div>)
                })}


                <div style={{ display: "flex", gap: "15px" }}>

                    {["k8s.pod", "k8s.service", "k8s.container" , "k8s.node"].includes(props.resource.resourceType) &&
                        <>
                            <div>
                                <Button primary onClick={() => {
                                    setKeyMappings(mappings[props.resource.resourceType])

                                    setLogWindowOpen(true)
                                    console.log({props , mappings , keyMappings })
                                }}>See related logs</Button>
                            </div>
                        </>
                    }

                    {["k8s.pod"].includes(props.resource.resourceType) &&
                        <>
                            <div>
                                <Button primary onClick={() => {
                                    navigate(`/apm/list?limit=20&start_ts=${sts}&end_ts=${ets}&pod=${attrInfo.data['k8s.pod.name'] && typeof attrInfo.data['k8s.pod.name'] === "string" ? attrInfo.data['k8s.pod.name'] : ''}`)
                                }}>See related Traces</Button>
                            </div>
                        </>
                    }

                    {editMode && <WidgetActions options={attributesItems} callback={(item: any) => addAttrs(item)} />}

                    {editAllow && <Button
                        primary={editMode && hasDiff()}
                        scary={editMode && !hasDiff()}
                        info={!editMode} className={`edit__btn`}
                        onClick={() => {
                            setEditMode(!editMode)
                            if (editMode) {
                                saveDialogConfig()
                            }
                        }}>
                        <PencilIcon />
                        <span>{editMode ? (!hasDiff() ? "Exit" : "Save") : "Edit"}</span>
                    </Button>}
                </div>
            </React.Fragment>}
        </div>
        {widgetBuilderDialog?.isOpen && <WidgetBuilder widgetToEdit={widgetBuilderDialog.data} onWidgetCreate={() => {
            setWidgetBuilderDialog(undefined)
        }} /*callback={(status: boolean, res?: any) => {
            if (res?.body?.body) {
                let _body: any = res?.body?.body
                _body.builderId = Date.now()
                dispatch(receivedBuilderFormattedData({
                    body: { ..._body, widgetData: res.chart_data },
                    inflight: false,
                } as any))

                let _obj: any = {
                    type: "widget_app",
                    builder_id: _body.builderId,
                    attr_key: widgetBuilderDialog?.action?.item?.key,
                    title: _body.label,
                    request_opts: res.body,
                }

                if (typeof widgetBuilderDialog?.action?.index !== "undefined")
                    widgetDiagram[widgetBuilderDialog?.action?.index].child.push(_obj)
                else
                    widgetDiagram.push({ child: [_obj] })

                setWidgetDiagram([...widgetDiagram])
                setWidgetBuilderDialog(undefined)
            }

        }}*/ />}

        <Dialog
            isOpen={logWindow}
            position={"right"}
            size = {'lg'}
            onClose={() => {
                setLogWindowOpen(false)
                setKeyMappings({})
            }}>

            <LogsIntegration
                attr={keyMappings['key']}
                value={props.row[keyMappings['attr']]}
                themeMode={props.themeMode}
                // sts={new Date().getTime() - 12*3600000}
                // ets={new Date().getTime()}
                sts = {parseInt(sts || '0')}
                ets={parseInt(ets || '0')}
            />

        </Dialog>
    </React.Fragment>
}

export default WidgetAppDialogView
