import * as React from "react";
import classNames from "classnames";
import "./_style.scss";
import {
  ErrorToastIcon,
  InfoToastIcon,
  SuccessToastIcon,
  WarningToastIcon,
} from "core/components/v2/svg/icons";
import { Message } from "core/components/v2/enums";

interface Props {
  message: string | React.ReactNode;
  type?: Message;
  className?: string;
  children?: React.ReactNode;
}
const MwMessage = (props: Props) => {
  const { message, type = Message.Info, children } = props;

  const className = classNames("mw-message-wrapper", props.className, {
    "is-info": type === Message.Info,
    "is-warning": type === Message.Warning,
    "is-error": type === Message.Error,
    "is-success": type === Message.Success,
  });
  return (
    <>
      <div className={className}>
        <div className="info-area">
          <div className="message-icon">
            {type === Message.Warning ? (
              <WarningToastIcon />
            ) : type === Message.Success ? (
              <SuccessToastIcon />
            ) : type === Message.Error ? (
              <ErrorToastIcon />
            ) : (
              <InfoToastIcon />
            )}
          </div>
          <p className="message">{message}</p>
        </div>

        {children && <div className="message-content-area">{children}</div>}
      </div>
    </>
  );
};
export default MwMessage;
