import { createReducer } from "core/utils/create_reducer";
import { combineReducers } from "redux";
import {
  ALERTS_RECEIVED,
  ALERTS_STATES_RECEIVED,
  ALERT_COUNT_SIDEBAR,
  ALERT_DETAIL_RECEIVED,
  ALERT_FILTERS,
  ALERT_STATES_RECEIVED,
  CLEAR_TRIGGERED_ALERTS,
  DATE_RANGE_CHART,
  EMAIL_RECEIVED,
  FILTER_BAR,
  FILTER_SECTIONS_RECEIVED,
  GET_LOGS_ATTRIBUTE_DATA,
  GET_LOGS_TYPE_DATA,
  HOST_RECEIVED,
  INTEGRATIONS_LIST_RECEIVED,
  K8S_METRICS_LIST_RECEIVED,
  RECEIVED_RULES_LIST,
  REMOVE_FILTER_FOR_ALERTS,
  RESET_RULE_DETAILS,
  RULES_RECEIVED,
  RULE_DETAILS_RECEIVED,
  SEARCH_ALERT_FILTER_BAR,
  SET_ALERT_FILTER_BAR,
  SET_FILTER_FOR_ALERTS,
  TAGS_RECEIVED,
  TRIGGERED_ALERTS_RECEIVED,
  TRIGGERED_INNER_ALERTS_RECEIVED,
  WS_ALERTS_RECEIVED,
  WS_ALERT_DETAIL_RECEIVED,
} from "./constant";

function pushMetric(sourceArray: object[], newArray: object) {
  if (sourceArray.length >= 10) sourceArray.shift();
  sourceArray.push(newArray);
  // sourceArray = sourceArray.concat(newArray);
  return sourceArray;
}
const hostList = createReducer(
  {},
  {
    [HOST_RECEIVED]: (state, opts) => {
      if (opts.status && Object.keys(opts.items).length) {
        return opts.items;
      }
      return { ...state };
    },
  }
);
const rule_Detail = createReducer(
  {},
  {
    [RULE_DETAILS_RECEIVED]: (state, opts) => {
      if (opts.status && Object.keys(opts.data).length) {
        return opts.data;
      }
      return { ...state };
    },
    [RESET_RULE_DETAILS]: () => {
      return {};
    },
  }
);
const filterSections = createReducer(
  {},
  {
    [FILTER_SECTIONS_RECEIVED]: (state, opts) => {
      if (opts.status) {
        return opts.data;
      }
      return { ...state };
    },
  }
);
const alertStates = createReducer(
  {},
  {
    [ALERT_STATES_RECEIVED]: (state, opts) => {
      if (opts.status) {
        return opts.data;
      }
      return { ...state };
    },
  }
);
const integrationsList = createReducer(
  {},
  {
    [INTEGRATIONS_LIST_RECEIVED]: (state, opts) => {
      if (opts.status) {
        return opts.integration_list;
      }
      return { ...state };
    },
  }
);
const triggeredInnerAlerts = createReducer(
  {
    items: [],
    inflight: false,
    singleInflight: false,
    page: 1,
    total: 0,
  },
  {
    [TRIGGERED_INNER_ALERTS_RECEIVED]: (state, opts) => {
      if (typeof opts.inflight !== "undefined") {
        state.inflight = opts.inflight;
      }
      if (opts.status && Array.isArray(opts.items)) {
        state.items = opts.items.sort((a: any, b: any) => b.id - a.id);
      }
      if (opts?.alerts?.id) {
        const index = state.items.findIndex(
          (item: any) => item.id === opts.alerts.id
        );
        if (index === -1) {
          state.items.unshift(opts.alerts);
        } else {
          state.items[index] = opts.alerts;
        }
        state.items = Object.assign([], state.items);
      }
      return { ...state };
    },
  }
);

const triggeredAlerts = createReducer(
  {
    items: [],
    inflight: false,
    singleInflight: false,
    page: 1,
    total: 0,
    latestStatus: 0,
  },
  {
    [TRIGGERED_ALERTS_RECEIVED]: (state, opts) => {
      if (typeof opts.inflight !== "undefined") {
        state.inflight = opts.inflight;
      }
      if (opts && Array.isArray(opts.alerts)) {
        state.items = opts.alerts;
      }
      if (opts?.latest_status) {
        state.latestStatus = opts?.latest_status;
      }

      if (opts?.alerts?.id) {
        const index = state.items.findIndex(
          (item: any) => item.id === opts.alerts.id
        );
        if (index === -1) {
          state.items.unshift(opts.alerts);
        } else {
          state.items[index] = opts.alerts;
        }
        state.items = Object.assign([], state.items);
      }
      return { ...state };
    },
    [CLEAR_TRIGGERED_ALERTS]: (state) => {
      state.items = [];
      return { ...state };
    },
  }
);

const rulesList = createReducer(
  {
    items: [],
    inflight: false,
    singleInflight: false,
    page: 1,
    total: 0,
    countsByStatus: [],
    totalAlerts: 0,
  },
  {
    [RECEIVED_RULES_LIST]: (state, opts) => {
      if (typeof opts.inflight !== "undefined") {
        state.inflight = opts.inflight;
      }
      if (opts && Array.isArray(opts.rules)) {
        state.items = opts.rules.sort((a: any, b: any) => {
          const dateA = new Date(a.created_at).getTime();
          const dateB = new Date(b.created_at).getTime();
          return dateB - dateA;
        });
      }
      if (opts && Array.isArray(opts?.countables?.counts_by_status)) {
        state.countsByStatus = opts?.countables?.counts_by_status;
      }
      if (opts?.countables?.total_count) {
        state.totalAlerts = opts?.countables?.total_count;
      }
      if (opts?.id > 0 && opts?.actionType == "delete") {
        const index = state.items.findIndex((item: any) => item.id === opts.id);
        if (index > -1) {
          state.items.splice(index, 1);
        }
        state.items = Object.assign([], state.items);
      }
      if (opts?.rules?.id) {
        const index = state.items.findIndex(
          (item: any) => item.id === opts.rules.id
        );
        if (index === -1) {
          state.items.unshift(opts.rules);
        } else {
          state.items[index] = opts.rules;
        }
        state.items = Object.assign([], state.items);
      }
      state.counts = state?.items?.length;
      return { ...state };
    },
  }
);

export default combineReducers({
  rule_Detail,
  hostList,
  rulesList,
  triggeredAlerts,
  triggeredInnerAlerts,
  filterSections,
  alertStates,
  integrationsList,
  filterBar: createReducer(FILTER_BAR, {
    [SET_ALERT_FILTER_BAR]: (state, opts) => {
      return opts.filterBar;
    },
    [SEARCH_ALERT_FILTER_BAR]: (state, opts) => {
      const filterBar = JSON.parse(JSON.stringify(FILTER_BAR));
      if (opts?.key && opts.key !== "") {
        let result = [];
        result = filterBar[opts.key].checkboxes.filter((item: any) => {
          return item.includes(opts.search);
        });
        filterBar[opts.key].checkboxes = result;
      } else {
        for (const filterKey in filterBar) {
          let result = [];
          result = filterBar[filterKey].checkboxes.filter((item: any) => {
            return item.includes(opts.search);
          });
          filterBar[filterKey].checkboxes = result;
        }
      }
      return filterBar;
    },
  }),

  list: createReducer(
    {
      isLoading: false,
      isError: false,
      items: [],
      update: null,
    },
    {
      [ALERTS_RECEIVED]: (state, opts) => {
        if (opts.status && Array.isArray(opts.items)) {
          state.items = opts.items;
        }
        return { ...state };
      },
      [WS_ALERTS_RECEIVED]: (state, opts) => {
        if (opts?.wsResponse?.list) {
          const wsResponse = opts.wsResponse.list;
          const existItems = state.items;
          for (const [i, item] of existItems.entries()) {
            if (wsResponse.hasOwnProperty(item.host_name)) {
              state.items[i].status = wsResponse[item.host_name].status;
              state.items[i].cpu_usage = wsResponse[item.host_name].cpu_usage;
              state.items[i].ram_usage = wsResponse[item.host_name].ram_usage;
              state.items[i].avg_load = wsResponse[item.host_name].avg_load;
            }
          }
          state.update = Date.now();
        }
        return { ...state };
      },
    }
  ),

  sidePanel: createReducer(
    {
      info: [],
      alertData: {},
      update: null,
      inflight: false,
    },
    {
      [ALERT_DETAIL_RECEIVED]: (state, opts) => {
        if (opts.hasOwnProperty("info")) {
          state.info = Object.keys(opts.info).length > 0 ? opts.info : [];
        }
        if (opts?.alertData) {
          state.alertData = opts.alertData;
        }
        if (typeof opts.inflight !== undefined) {
          state.inflight = opts.inflight;
        }
        return { ...state };
      },
      [WS_ALERT_DETAIL_RECEIVED]: (state, opts) => {
        if (opts.wsResponse) {
          const wsResponse = opts.wsResponse;
          const wsHostInfo = state.info.host_info;
          const wsStack = state.info.ws_stats;
          if (
            wsResponse.hasOwnProperty("cpu") &&
            wsStack.hasOwnProperty("cpu")
          ) {
            const linesLength = wsResponse.cpu.length;
            if (linesLength > 0) {
              wsStack.cpu[0].values = pushMetric(
                wsStack.cpu[0].values,
                wsResponse.cpu[0]
              );
            }
            if (wsHostInfo?.cpu?.hasOwnProperty("usage")) {
              wsHostInfo.cpu.usage = wsResponse.cpu[0].yAxis;
            }
          }

          if (
            wsResponse.hasOwnProperty("ram") &&
            wsStack.hasOwnProperty("ram")
          ) {
            const linesLength = wsResponse.ram.length;
            if (linesLength > 0) {
              wsStack.ram[0].values = pushMetric(
                wsStack.ram[0].values,
                wsResponse.ram[0]
              );
            }
            if (wsHostInfo?.ram?.hasOwnProperty("usage")) {
              wsHostInfo.ram.usage = wsResponse.ram[0].yAxis;
            }
          }

          if (
            wsResponse.hasOwnProperty("avg_load") &&
            wsStack.hasOwnProperty("avg_load")
          ) {
            const linesLength = wsResponse.avg_load.length;
            if (linesLength > 0) {
              wsStack.avg_load[0].values = pushMetric(
                wsStack.avg_load[0].values,
                wsResponse.avg_load[0]
              );
            }
            if (wsHostInfo?.avg_load?.hasOwnProperty("value")) {
              wsHostInfo.avg_load.value = wsResponse.avg_load[0].yAxis;
            }
          }

          if (
            wsResponse.hasOwnProperty("core") &&
            wsStack.hasOwnProperty("core")
          ) {
            for (const coreStats in wsResponse.core) {
              if (wsStack.core.hasOwnProperty(coreStats)) {
                const linesLength = wsResponse.core[coreStats].length;
                for (const [lineIndex, _] of wsResponse.core[
                  coreStats
                ].entries()) {
                  if (linesLength === wsStack.core[coreStats].length) {
                    wsStack.core[coreStats][lineIndex].values = pushMetric(
                      wsStack.core[coreStats][lineIndex].values,
                      wsResponse.core[coreStats][lineIndex]
                    );
                  }
                }
              }
            }
          }

          if (
            wsResponse.hasOwnProperty("app_wise") &&
            wsStack.hasOwnProperty("app_wise")
          ) {
            for (const appStats in wsResponse.app_wise) {
              if (wsStack.app_wise.hasOwnProperty(appStats)) {
                for (const chart in wsResponse.app_wise[appStats]) {
                  const linesLength =
                    wsResponse.app_wise[appStats][chart].length;
                  for (const [lineIndex, _] of wsResponse.app_wise[appStats][
                    chart
                  ].entries()) {
                    if (
                      linesLength === wsStack.app_wise[appStats][chart].length
                    ) {
                      wsStack.app_wise[appStats][chart][lineIndex].values =
                        pushMetric(
                          wsStack.app_wise[appStats][chart][lineIndex].values,
                          wsResponse.app_wise[appStats][chart][lineIndex]
                        );
                    }
                  }
                }
              }
            }
          }

          state.info.host_info = wsHostInfo;
          state.info.ws_stats = wsStack;
          state.update = Date.now();
          // console.log("state==>", hostInfo.metrics.core.boxes[0].chart.stats[0].values);
        }
        return { ...state };
      },
    }
  ),

  rules: createReducer(
    {
      isLoading: false,
      isError: false,
      items: [],
      update: null,
    },
    {
      [RULES_RECEIVED]: (state, opts) => {
        if (opts.status && Array.isArray(opts.items)) {
          state.items = opts.items;
        }
        return { ...state };
      },
    }
  ),

  alertStateCountItem: createReducer(
    {
      isLoading: false,
      isError: false,
      items: [],
      update: null,
    },
    {
      [ALERTS_STATES_RECEIVED]: (state, opts) => {
        if (opts.status && Array.isArray(opts.items)) {
          state.items = opts.items;
        }
        return { ...state };
      },
    }
  ),

  dateRangeChart: createReducer(
    {
      isLoading: false,
      isError: false,
      items: [],
      update: null,
    },
    {
      [DATE_RANGE_CHART]: (state, opts) => {
        if (opts.status && Array.isArray(opts.items)) {
          state.items = opts.items;
        }
        return { ...state };
      },
    }
  ),

  k8sMetricsList: createReducer(
    {
      isLoading: false,
      isError: false,
      items: [],
      update: null,
    },
    {
      [K8S_METRICS_LIST_RECEIVED]: (state, opts) => {
        if (opts.status && Array.isArray(opts.items)) {
          state.items = opts.items;
        }
        return { ...state };
      },
    }
  ),

  getLogsAttributeData: createReducer(
    {
      isLoading: false,
      isError: false,
      items: [],
      update: null,
    },
    {
      [GET_LOGS_ATTRIBUTE_DATA]: (state, opts) => {
        if (opts) {
          const keys = Object.keys(opts);
          state.items = keys.filter((key) => key !== "type");
        }
        return { ...state };
      },
    }
  ),

  getLogsTypeData: createReducer(
    {
      isLoading: false,
      isError: false,
      items: [],
      update: null,
    },
    {
      [GET_LOGS_TYPE_DATA]: (state, opts) => {
        if (opts) {
          const keys = Object.keys(opts);
          state.items = keys.filter((key) => key !== "type");
        }
        return { ...state };
      },
    }
  ),

  tags: createReducer(
    {
      isLoading: false,
      isError: false,
      items: [],
      update: null,
    },
    {
      [TAGS_RECEIVED]: (state, opts) => {
        if (opts.status && Array.isArray(opts.tags)) {
          state.tags = opts.tags;
        }
        return { ...state };
      },
    }
  ),

  emails: createReducer(
    {
      isLoading: false,
      isError: false,
      items: [],
      update: null,
    },
    {
      [EMAIL_RECEIVED]: (state, opts) => {
        if (opts.status && Array.isArray(opts.emails)) {
          state.emails = opts.emails;
        }
        return { ...state };
      },
    }
  ),

  alertCounts: createReducer(
    {
      isLoading: false,
      isError: false,
      items: [],
      update: null,
    },
    {
      [ALERT_COUNT_SIDEBAR]: (state, opts) => {
        if (opts.status && Array.isArray(opts.items)) {
          state.items = opts.items;
        }
        return { ...state };
      },
    }
  ),

  alertsFilters: createReducer(ALERT_FILTERS, {
    [SET_FILTER_FOR_ALERTS]: (state, opts) => {
      const { key, value, proc } = opts;
      if (proc == "delete_all") {
        state = {};
        return { ...state };
      }
      if (proc == "add_batch") {
        const { data } = opts || {};
        return { ...data };
      }
      if (key && value) {
        if (state[key]) {
          if (proc == "bulk-addition") {
            state[key] = value;
          } else if (state[key].indexOf(value) == -1) {
            state[key].push(value);
          }
        } else {
          state[key] = [];
          state[key].push(value);
        }
      }
      return { ...state };
    },
    [REMOVE_FILTER_FOR_ALERTS]: (state, opts) => {
      const { key, value, proc } = opts;

      if (key && state[key]) {
        if (!proc) {
          delete state[key];
        } else {
          if (value) {
            state[key] = state[key].filter((item: any) => item !== value);
          }
        }
        if (state[key] && Array.isArray(state[key]) && state[key].length == 0) {
          delete state[key];
        }
      }

      return { ...state };
    },
  }),
});
