import { getTenantUrl } from "core/application/utils";
import NavTab from "core/components/v2/nav-tab";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import InstallationSteps from "../../../components/steps";
// import TabItems from "core/components/v2/nav-tab/items";
import TabItems from "core/components/v2/nav-tab/items";
import Content from "./content";

interface DenoIntegrationProps {
  serverlessSupport: boolean;
}
const DenoIntegration: React.FC<DenoIntegrationProps> = ({
  serverlessSupport,
}) => {
  //   const [tab, setTab] = useState<number>(1);
  //   const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];

  const tabs = [
    {
      title: "Serverless",
      // component: ServerlessAPMSetup,
      component: Content,
    },
    {
      title: "With Host",
      component: Content,
    },
  ];

  const [tabIndex, setTabIndex] = useState<number>(0);

  const onTabChange = (index: number) => {
    setTabIndex(index);
  };

  interface TabComponent {
    title: string;
    component: React.FC<{}>;
  }

  // const components = tabs.map((tab: TabComponent) => tab.component);
  // console.log("Components >>", components);
  // const RenderComponent = components[tabIndex];
  // console.log("RenderComponent >>", RenderComponent);

  const debCommand = `MW_API_KEY=${context.user?.account?.agent_config?.token} MW_TARGET=${capturePath}:443 bash -c "$(curl -L https://install.middleware.io/scripts/deb-install.sh)"`;
  const rpmCpmmand = `MW_API_KEY=${context.user?.account?.agent_config?.token} MW_TARGET=${capturePath}:443 bash -c "$(curl -L https://install.middleware.io/scripts/rpm-install.sh)"`;
  const oldStatusCheckCommand = `sudo systemctl status mwservice`;
  const newStatusCheckCommand = `sudo systemctl status mw-agent`;

  return (
    <>
      {serverlessSupport && (
        <InstallationSteps>
          <NavTab>
            <TabItems title="Serverless" key="serverless">
              <Content serverless="true" />
            </TabItems>

            <TabItems title="With Host" key="host-based">
              <Content serverless="false" />
            </TabItems>
          </NavTab>
        </InstallationSteps>
      )}
    </>
  );
};
export default DenoIntegration;
