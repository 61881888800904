import { TypographyHeadingPrimary } from "core/components/typography";
import React from "react";
type ReportStepProps = {
  stepNumber: number;
  stepTitle: string;
  children?: any;
};
const WidgetStep = (props: ReportStepProps): any => {
  const { stepNumber, stepTitle, children } = props;
  return (
    <div className={`step-wrapper ${!stepTitle ? "flex" : ""}`}>
      <div className="step">
        <span className="step-number">
          <span>{stepNumber}</span>
        </span>
        <TypographyHeadingPrimary>{stepTitle}</TypographyHeadingPrimary>
        {/* <h4>{stepNumber}. {stepTitle}</h4> */}
      </div>
      <div className="step-body">{children}</div>
    </div>
  );
};
export default WidgetStep;
