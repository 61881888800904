import { createSelector } from "reselect";
import { get } from "lodash-es";
import { FILTER_BAR } from "store/alert/constant";
interface IntegrationListState {
  alert: {
    integrationsList: { integration: string }[];
  };
}

export const getFilterBar = createSelector(
  (state: any) => get(state, ["alert", "filterBar"], FILTER_BAR),
  (data) => data
);

export const getAlerts = createSelector(
  (state: any) => get(state, ["alert", "list", "items"], []),
  (data) => data
);

export const getUpdateList = createSelector(
  (state: any) => get(state, ["alert", "list", "update"], Date.now()),
  (data) => data
);

export const getAlertInfo = createSelector(
  (state: any) => get(state, ["alert", "sidePanel", "info"], {}),
  (data) => data
);

export const getAlertInfoInflight = createSelector(
  (state: any) => get(state, ["alert", "sidePanel", "inflight"], false),
  (data) => data
);

export const getAlertInfoData = createSelector(
  (state: any) => get(state, ["alert", "sidePanel", "alertData"], {}),
  (data) => data
);

export const getUpdate = createSelector(
  (state: any) => get(state, ["alert", "sidePanel", "update"], Date.now()),
  (data) => data
);

export const getRules = createSelector(
  (state: any) => get(state, ["alert", "rules", "items"], []),
  (data) => data
);

export const getAlertStateCountItem = createSelector(
  (state: any) => get(state, ["alert", "alertStateCountItem", "items"], []),
  (data) => data
);

export const getAlertChartDataSelector = createSelector(
  (state: any) => get(state, ["alert", "dateRangeChart", "items"], []),
  (data) => data
);

export const getUpdateRuleList = createSelector(
  (state: any) => get(state, ["alert", "rules", "update"], Date.now()),
  (data) => data
);

export const getMetricsHostList = createSelector(
  (state: any) => get(state, ["alert", "hostList"], []),
  (data) => data
);

export const alertFilters = createSelector(
  (state: any) => get(state, ["alerts", "alertsFilters"], {}),
  (data) => data
);

export const k8sMetricList = createSelector(
  (state: any) => get(state, ["alerts", "k8sMetricsList"], {}),
  (data) => data
);

export const getLogsAttributeListData = createSelector(
  (state: any) => get(state, ["alert", "getLogsAttributeData"], {}),
  (data) => data
);

export const getLogsTypeListData = createSelector(
  (state: any) => get(state, ["alert", "getLogsTypeData"], {}),
  (data) => data
);

export const ruleListSelector = createSelector(
  (state: any) => get(state, ["alert", "rulesList", "items"], {}),
  (data) => data
);

export const triggeredAlertsSelector = createSelector(
  (state: any) => get(state, ["alert", "triggeredAlerts", "items"], {}),
  (data) => data
);

export const triggeredAlertsSelectorNew = createSelector(
  (state: any) => get(state, ["alert", "triggeredAlerts","latestStatus"], {}),
  (data) => data
);

export const triggeredAlertsInflight = createSelector(
  (state: any) => get(state, ["alert", "triggeredAlerts", "inflight"], {}),
  (data) => data
);

export const triggeredInnerAlertsInflight = createSelector(
  (state: any) => get(state, ["alert", "triggeredInnerAlerts", "inflight"], {}),
  (data) => data
);

export const triggeredInnerAlertsSelector = createSelector(
  (state: any) => get(state, ["alert", "triggeredInnerAlerts", "items"], {}),
  (data) => data
);

export const getRuleListInflight = createSelector(
  (state: any) => get(state, ["alert", "rulesList", "inflight"], {}),
  (data) => data
);

export const getRuleSingle: any = createSelector(
  (state: any, id: any) => {
    return {
      id,
      items: get(state, ["alert", "rulesList", "items"], []),
    };
  },
  (data: any) => {
    const i = data.items.findIndex(
      (item: any) => item.id === parseInt(data.id)
    );
    return i >= 0 ? data.items[i] : null;
  }
);
export const getRuleDetail = createSelector(
  (state: any) => get(state, ["alert", "rule_Detail"], {}),
  (data) => data
);
export const getFilterSections = createSelector(
  (state: any) => get(state, ["alert", "filterSections"], []),
  (data) => data
);
export const getAlertStates = createSelector(
  (state: any) => get(state, ["alert", "alertStates"], []),
  (data) => data
);
export const getCountsByStatus = createSelector(
  (state: any) => get(state, ["alert", "rulesList", "countsByStatus"], []),
  (data) => data
);
export const getTotalAlertsCounts = createSelector(
  (state: any) => get(state, ["alert", "rulesList", "totalAlerts"], []),
  (data) => data
);
export const getIntegrationsList = createSelector(
  (state: IntegrationListState) =>
    get(state, ["alert", "integrationsList"], []),
  (data) => data
);
