import DropdownSelection from "core/components/v2/dropdown-selection";
import { OptionProps } from "core/components/v2/dropdown-selection/entity";
import React, { useMemo } from "react";
import { Query } from "../../entities/builder.entities";
import { ColumnTypes } from "../../entities/column.entities";
import "./style.scss";

type MonitoringType =
  | "log_monitoring"
  | "infrastructure_monitoring"
  | "apm"
  | "synthetic_monitoring"
  | "database_monitoring"
  | "real_user_monitoring"
  | "serverless_monitoring";

const billingProductOptions = [
  {
    label: "Log monitoring",
    value: "log_monitoring",
  },
  {
    label: "Infrastructure monitoring",
    value: "infrastructure_monitoring",
  },
  {
    label: "APM",
    value: "apm",
  },
  {
    label: "Synthetic monitoring",
    value: "synthetic_monitoring",
  },
  {
    label: "Database monitoring",
    value: "database_monitoring",
  },
  {
    label: "Real user monitoring",
    value: "real_user_monitoring",
  },
  {
    label: "Serverless monitoring",
    value: "serverless_monitoring",
  },
];

const commonUnitOptions: OptionProps[] = [{ label: "Cost", value: "cost" }];

const unitOptionsMap: Record<MonitoringType, OptionProps[]> = {
  log_monitoring: [{ label: "Size", value: "size" }, ...commonUnitOptions],
  infrastructure_monitoring: [
    { label: "Host", value: "host" },
    ...commonUnitOptions,
  ],
  apm: [{ label: "Host", value: "host" }, ...commonUnitOptions],
  synthetic_monitoring: [
    { label: "Checks", value: "checks" },
    ...commonUnitOptions,
  ],
  database_monitoring: [
    { label: "Count", value: "count" },
    ...commonUnitOptions,
  ],
  real_user_monitoring: [
    { label: "Session", value: "session" },
    ...commonUnitOptions,
  ],
  serverless_monitoring: [
    { label: "Traces", value: "traces" },
    ...commonUnitOptions,
  ],
};

export interface BillingProps {
  query: Query;
  onQueryUpdate: (newQuery: Query) => void;
}

export default function Billing({ query, onQueryUpdate }: BillingProps) {
  const unitOptions: OptionProps[] = useMemo(() => {
    if (query.columns.length !== 1) return commonUnitOptions;
    return (
      unitOptionsMap[query.columns[0] as MonitoringType] || commonUnitOptions
    );
  }, [query.columns]);

  return (
    <div className="billing-input-container">
      <div className="dropdown-with-label-container">
        <label>Select Product</label>
        <DropdownSelection
          placeholder={"Select Service(s)"}
          showSearch={false}
          options={billingProductOptions}
          isError={query.columns.length <= 0}
          onChange={(value: string | string[]) => {
            const val = Array.isArray(value) ? value : [value];
            const newQuery = JSON.parse(JSON.stringify(query)) as Query;
            newQuery.columns = val as ColumnTypes[];
            newQuery.meta_data = {};
            onQueryUpdate(newQuery);
          }}
          selectedValues={query.columns as string[]}
          notFoundContent="No options found"
        />
      </div>

      <div className="dropdown-with-label-container">
        <label>Select Unit</label>
        <DropdownSelection
          placeholder={"Select Unit"}
          showSearch={false}
          options={unitOptions}
          onChange={(value: string | string[]) => {
            const val = Array.isArray(value) ? value[0] : value;
            const newQuery = JSON.parse(JSON.stringify(query)) as Query;
            newQuery.meta_data = {
              billingUnit: val,
            };
            onQueryUpdate(newQuery);
          }}
          selectedValues={query.meta_data?.billingUnit}
          isError={!query.meta_data?.billingUnit}
          notFoundContent="No options found"
        />
      </div>
    </div>
  );
}
