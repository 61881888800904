export const REQUEST_SYNTHETICS_LIST = "REQUEST_SYNTHETICS_LIST";
export const REQUEST_SYNTHETICS_INFO = "REQUEST_SYNTHETICS_INFO";
export const REQUEST_SYNTHETICS_CREATE = "REQUEST_SYNTHETICS_CREATE";
export const REQUEST_SYNTHETICS_EDIT = "REQUEST_SYNTHETICS_EDIT";
export const REQUEST_SYNTHETICS_DELETE = "REQUEST_SYNTHETICS_DELETE";

export const RECEIVED_SYNTHETICS_LIST = "RECEIVED_SYNTHETICS_LIST";
export const RECEIVED_SYNTHETICS_INFO = "RECEIVED_SYNTHETICS_INFO";
export const RECEIVED_SYNTHETICS_METRICS = "RECEIVED_SYNTHETICS_METRICS";
export const RECEIVED_SYNTHETICS_LIST_ITEMS = "RECEIVED_SYNTHETICS_LIST_ITEMS";
export const STEP_COMPLETED_INDEX = "STEP_COMPLETED_INDEX";
