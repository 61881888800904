import * as React from 'react';
import classname from 'classnames'


/* Decorators */
import staticMethods from './decorators/staticMethods'
import windowListener from './decorators/windowListener'
import customEvent from './decorators/customEvent'
import isCapture from './decorators/isCapture'
import getEffect from './decorators/getEffect'
import trackRemoval from './decorators/trackRemoval'

/* Utils */
import getPosition from './utils/getPosition'
import getTipContent from './utils/getTipContent'
import { parseAria } from './utils/aria'
import nodeListToArray from './utils/nodeListToArray'



interface Props {
    children ?: any,
    place ?: string,
    type ?: string,
    effect ?: string,
    offset ?: object,
    multiline ?: boolean,
    border ?: boolean,
    insecure ?: boolean,
    class ?: string,
    className ?: string,
    id ?: string,
    html ?: boolean,
    delayHide ?: number,
    delayUpdate ?: number,
    delayShow ?: any,
    event?: string,
    eventOff?: string,
    watchWindow?: boolean,
    isCapture?: any,
    globalEventOff?: string,
    getContent?:any,
    afterShow?: (a0?: any,a1?:any) => void,
    afterHide?: (a0?: any ,a1?:any) => void,
    overridePosition?: (a0?: any ,a1?:any) => void,
    disable?: boolean,
    scrollHide?: boolean,
    resizeHide?: boolean,
    wrapper?: string,
    clickable?: boolean,

}
interface States {
    show ?: boolean,
    place?: string,
    desiredPlace?: string,
    type?: string,
    effect?: string
    border?: boolean,
    offset?: any,
    extraClass?: string,
    html?: boolean,
    delayHide?: number,
    delayShow?: any,
    event?: any,
    eventOff?: any,
    currentEvent?: any,
    currentTarget?: any,
    ariaProps?: any,
    isEmptyTip?: any,
    disable?: boolean,
    originTooltip?: any,
    isMultiline?: boolean
}
@staticMethods
@windowListener
//@customEvent
@isCapture
@getEffect
@trackRemoval
class Tooltip extends React.Component<Props,States> {



    static defaultProps = {
        insecure: true,
        resizeHide: true,
        wrapper: 'div',
        clickable: false
    };

    static supportedWrappers = ['div', 'span'];

    static displayName = 'Tooltip';
    mount?:any;
    delayShowLoop ?:any;
    delayHideLoop ?:any;
    delayReshow ?:any;
    intervalUpdateContent ?:any;
    tooltipRef ?: any;
    bindWindowEvents ?: (a0?: any,a1?: any,a2?: any) => void;
    unbindWindowEvents ?: any;
    isCapture ?: (a0?: any,a1?: any,a2?: any) => void;
    getEffect?: (a0?: any,a1?: any,a2?: any) => void;
    //isCustomEvent?: (a0?: any,a1?: any,a2?: any) => void;
    customBindListener?: (a0?: any,a1?: any,a2?: any) => void;
    customUnbindListener?: (a0?: any,a1?: any,a2?: any) => void;
    unbindRemovalTracker ?: (a0?: any,a1?: any,a2?: any) => void;

    constructor (props:Props) {
        super(props)
        this.state = {
            place: props.place || 'top', // Direction of tooltip
            desiredPlace: props.place || 'top',
            type: 'dark', // Color theme of tooltip
            effect: 'float', // float or fixed
            show: false,
            border: false,
            offset: {},
            extraClass: '',
            html: false,
            delayHide: 0,
            delayShow: 0,
            event: props.event || null,
            eventOff: props.eventOff || null,
            currentEvent: null, // Current mouse event
            currentTarget: null, // Current target of mouse event
            ariaProps: parseAria(props), // aria- and role attributes
            isEmptyTip: false,
            disable: false,
            originTooltip: null,
            isMultiline: false
        }

        this.bind([
            'showTooltip',
            'updateTooltip',
            'hideTooltip',
            'hideTooltipOnScroll',
            'getTooltipContent',
            'globalRebuild',
            'globalShow',
            'globalHide',
            'onWindowResize',
            'mouseOnToolTip'
        ])

        this.mount = true
        this.delayShowLoop = null
        this.delayHideLoop = null
        this.delayReshow = null
        this.intervalUpdateContent = null
    }

    bind (methodArray:any) {
        methodArray.forEach((method:any) => {
            (this as any)[method] = (this as any)[method].bind(this)
        })
    }

    componentDidMount () {
        const { resizeHide } = this.props
        this.bindListener() // Bind listener for tooltip
        this.bindWindowEvents != undefined && this.bindWindowEvents(resizeHide) // Bind global event for static method
    }

    static getDerivedStateFromProps (nextProps:any, prevState:any) {
        const { ariaProps } = prevState
        const newAriaProps = parseAria(nextProps)
        const isChanged = Object.keys(newAriaProps).some(props => {
            return (newAriaProps as any)[props] !== ariaProps[props]
        })
        if (!isChanged) {
            return null
        }
        return {
            ...prevState,
            ariaProps: newAriaProps
        }
    }

    componentWillUnmount () {
        const unbindWindowEvents:any = () => this.unbindWindowEvents()
        this.mount = false
        this.clearTimer()
        this.unbindListener()
        this.removeScrollListener()
        unbindWindowEvents
    }



    mouseOnToolTip () {
        const {show} = this.state
        if (show && this.tooltipRef) {
            if (!this.tooltipRef.matches) {
                if (this.tooltipRef.msMatchesSelector) {
                    this.tooltipRef.matches = this.tooltipRef.msMatchesSelector
                } else {
                    this.tooltipRef.matches = this.tooltipRef.mozMatchesSelector
                }
            }
            return this.tooltipRef.matches(':hover')
        }
        return false
    }

    getTargetArray (id:any) {
        let targetArray
        if (!id) {
            targetArray = document.querySelectorAll('[data-tip]:not([data-for])')
        } else {
            const escaped = id.replace(/\\/g, '\\\\').replace(/"/g, '\\"')
            targetArray = document.querySelectorAll(`[data-tip][data-for="${escaped}"]`)
        }

        return nodeListToArray(targetArray)
    }
    isCustomEvent = (a0?: any,a1?: any,a2?: any): any => {
     return a0
    }

    bindListener () {
        const {id, globalEventOff, isCapture} = this.props
        let targetArray = this.getTargetArray(id)
        const bindRemovalTracker = () => bindRemovalTracker
        targetArray.forEach(target => {
            const isCaptureMode =this.isCapture != undefined && this.isCapture(target)
            const effect:any = this.getEffect != undefined && this.getEffect(target)

            if (target.getAttribute('currentItem') === null) {
                target.setAttribute('currentItem', 'false')
            }
            this.unbindBasicListener(target)
            if (this.isCustomEvent != undefined && this.isCustomEvent(target) && this.customBindListener != undefined) {
                this.customBindListener(target)
                return
            }
            target.addEventListener('mouseenter', this.showTooltip, isCaptureMode)
            if (effect === 'float') {
                target.addEventListener('mousemove', this.updateTooltip, isCaptureMode)
            }
            target.addEventListener('mouseleave', this.hideTooltip, isCaptureMode)
        })


        if (globalEventOff) {
            (window as any).removeEventListener(globalEventOff, this.hideTooltip)
            (window as any).addEventListener(globalEventOff, this.hideTooltip, isCapture)
        }


        bindRemovalTracker
    }

    unbindListener = () => {
        const {id, globalEventOff} = this.props
        const targetArray = this.getTargetArray(id)
        const unbindRemoveTracker:any = () => this.unbindRemovalTracker != undefined && this.unbindRemovalTracker()
        targetArray.forEach(target => {
            this.unbindBasicListener(target)
            if (this.isCustomEvent != undefined && this.isCustomEvent(target) && this.customUnbindListener != undefined) this.customUnbindListener(target)


        })

        if (globalEventOff) (window as any).removeEventListener(globalEventOff, this.hideTooltip)
        unbindRemoveTracker
    }

    unbindBasicListener (target:any) {
        const isCaptureMode = this.isCapture != undefined && this.isCapture(target)
        target.removeEventListener('mouseenter', this.showTooltip, isCaptureMode)
        target.removeEventListener('mousemove', this.updateTooltip, isCaptureMode)
        target.removeEventListener('mouseleave', this.hideTooltip, isCaptureMode)
    }

    getTooltipContent () {
        const {getContent, children} = this.props
        let content
        if (getContent) {
            if (Array.isArray(getContent)) {
                content = getContent[0] && getContent[0](this.state.originTooltip)
            } else {
                content = getContent(this.state.originTooltip)
            }
        }
        return getTipContent(this.state.originTooltip, children, content, this.state.isMultiline)
    }

    isEmptyTip (placeholder:any) {
        return  (placeholder.props.children === 'string' && placeholder.props.children === '') || placeholder.props.children === null
    }


    showTooltip (e:any, isGlobalCall:any) {
        if (isGlobalCall) {
            const targetArray = this.getTargetArray(this.props.id)
            const isMyElement = targetArray.some(ele => ele === e.currentTarget)
            if (!isMyElement) return
        }
        const {multiline, getContent} = this.props
        const originTooltip = e.currentTarget.getAttribute('data-tip')
        const isMultiline = e.currentTarget.getAttribute('data-multiline') || multiline || false

        const switchToSolid = e instanceof window.FocusEvent || isGlobalCall

        let scrollHide = true
        if (e.currentTarget.getAttribute('data-scroll-hide')) {
            scrollHide = e.currentTarget.getAttribute('data-scroll-hide') === 'true'
        } else if (this.props.scrollHide != null) {
            scrollHide = this.props.scrollHide
        }

        // Make sure the correct place is set
        let desiredPlace = e.currentTarget.getAttribute('data-place') || this.props.place || 'top'
        let effect = (switchToSolid && 'solid') || (this.getEffect != undefined && this.getEffect(e.currentTarget))
        let offset = e.currentTarget.getAttribute('data-offset') || this.props.offset || {}
        let result = getPosition(e, e.currentTarget, this.tooltipRef, desiredPlace, desiredPlace, effect, offset)
        let tooltipResult = {
            position: result.position,
            currentEvent:this.state.currentEvent,
            currentTarget: e.currentTarget,
            node:this.tooltipRef,
            desiredPlace:desiredPlace,
            effect:effect,
            offset:offset,
            place:desiredPlace
        };
        if (result.position && this.props.overridePosition) {
            (result as any).position = this.props.overridePosition(tooltipResult)
        }

        let place = result.isNewState ? (result as any).newState.place : desiredPlace

        // To prevent previously created timers from triggering
        this.clearTimer()

        var target = e.currentTarget

        var reshowDelay = this.state.show ? target.getAttribute('data-delay-update') || this.props.delayUpdate : 0

        var self = this

        var updateState = function updateState () {
            self.setState({
                originTooltip: originTooltip,
                isMultiline: isMultiline,
                desiredPlace: desiredPlace,
                place: place,
                type: target.getAttribute('data-type') || self.props.type || 'dark',
                effect: effect,
                offset: offset,
                html: target.getAttribute('data-html') ? target.getAttribute('data-html') === 'true' : self.props.html || false,
                delayShow: target.getAttribute('data-delay-show') || self.props.delayShow || 0,
                delayHide: target.getAttribute('data-delay-hide') || self.props.delayHide || 0,
                // delayUpdate: target.getAttribute('data-delay-update') || self.props.delayUpdate || 0,
                border: target.getAttribute('data-border') ? target.getAttribute('data-border') === 'true' : self.props.border || false,
                extraClass: target.getAttribute('data-class') || self.props.class || self.props.className || '',
                disable: target.getAttribute('data-tip-disable') ? target.getAttribute('data-tip-disable') === 'true' : self.props.disable || false,
                currentTarget: target
            }, () => {
                if (scrollHide) self.addScrollListener(self.state.currentTarget)
                self.updateTooltip(e)

                if (getContent && Array.isArray(getContent)) {
                    self.intervalUpdateContent = setInterval(() => {
                        if (self.mount) {
                            const {getContent} = self.props
                            const placeholder = getTipContent(originTooltip, '', getContent[0](), isMultiline)
                            const isEmptyTip = self.isEmptyTip(placeholder)
                            self.setState({
                                isEmptyTip
                            })
                            self.updatePosition()
                        }
                    }, getContent[1])
                }
            })
        }

        // If there is no delay call immediately, don't allow events to get in first.
        if (reshowDelay) {
            this.delayReshow = setTimeout(updateState, reshowDelay)
        } else {
            updateState()
        }
    }

    /**
     * When mouse hover, update tool tip
     */
    updateTooltip (e:any) {
        const {delayShow, disable} = this.state
        const {afterShow} = this.props
        const placeholder = this.getTooltipContent()
        const delayTime = parseInt(delayShow, 10)
        const eventTarget = e.currentTarget || e.target
        // Check if the mouse is actually over the tooltip, if so don't hide the tooltip
        if (this.mouseOnToolTip()) {
            return
        }

        if (this.isEmptyTip(placeholder) || disable) return // if the tooltip is empty, disable the tooltip

        const updateState = () => {

            if ((placeholder.length > 0 || (placeholder as any).props.children != null)) {

                const isInvisible = !this.state.show
                this.setState({
                    currentEvent: e,
                    currentTarget: eventTarget,
                    show: true
                }, () => {
                    this.updatePosition()
                    if (isInvisible && afterShow) afterShow(e)
                })
            }
        }

        clearTimeout(this.delayShowLoop)
        if (delayShow) {

            this.delayShowLoop = setTimeout(updateState, delayTime)
        } else {

            updateState()
        }
    }

    /*
    * If we're mousing over the tooltip remove it when we leave.
     */
    listenForTooltipExit () {
        const {show} = this.state

        if (show && this.tooltipRef) {
            this.tooltipRef.addEventListener('mouseleave', this.hideTooltip)
        }
    }

    removeListenerForTooltipExit () {
        const {show} = this.state

        if (show && this.tooltipRef) {
            this.tooltipRef.removeEventListener('mouseleave', this.hideTooltip)
        }
    }

    /**
     * When mouse leave, hide tooltip
     */
    hideTooltip (e:any, hasTarget:any, options = { isScroll: false }) {
        const {disable} = this.state
        const {isScroll} = options
        const delayHide:any = isScroll ? 0 : this.state.delayHide
        const {afterHide} = this.props
        const placeholder = this.getTooltipContent()

        if (!this.mount) return
        if (this.isEmptyTip(placeholder) || disable) return // if the tooltip is empty, disable the tooltip
        if (hasTarget) {
            const targetArray = this.getTargetArray(this.props.id)
            const isMyElement = targetArray.some(ele => ele === e.currentTarget)
            if (!isMyElement || !this.state.show) return
        }

        const resetState = () => {
            const isVisible = this.state.show
            // Check if the mouse is actually over the tooltip, if so don't hide the tooltip
            if (this.mouseOnToolTip()) {
                this.listenForTooltipExit()
                return
            }
            this.removeListenerForTooltipExit()

            this.setState({
                show: false
            }, () => {
                this.removeScrollListener()
                if (isVisible && afterHide) afterHide(e)
            })
        }

        this.clearTimer()
        if (delayHide) {
            this.delayHideLoop = setTimeout(resetState, parseInt(delayHide, 10))
        } else {
            resetState()
        }
    }

    /**
     * When scroll, hide tooltip
     */
    hideTooltipOnScroll (event:any, hasTarget:any) {
        this.hideTooltip(event, hasTarget, { isScroll: true })
    }

    /**
     * Add scroll event listener when tooltip show
     * automatically hide the tooltip when scrolling
     */
    addScrollListener (currentTarget:any) {
        const isCaptureMode:any = this.isCapture != undefined && this.isCapture(currentTarget)
        window.addEventListener('scroll', (this as any).hideTooltipOnScroll, isCaptureMode)
    }

    removeScrollListener () {
        window.removeEventListener('scroll', (this as any).hideTooltipOnScroll)
    }

    // Calculation the position
    updatePosition () {

        const {currentEvent, currentTarget, place, desiredPlace, effect, offset} = this.state
        const node = this.tooltipRef
        let result:any = getPosition(currentEvent, currentTarget, node, place, desiredPlace, effect, offset)
        let tooltipResult = {
            position: result.position,
            currentEvent: currentEvent,
            currentTarget: currentTarget,
            node:node,
            place:place,
            desiredPlace:desiredPlace,
            effect:effect,
            offset:offset
        };
        if (result.position && this.props.overridePosition) {
            (result as any).position = this.props.overridePosition(tooltipResult)
        }

        if (result.isNewState) {
            // Switch to reverse placement
            return this.setState(result.newState, () => {
                this.updatePosition()
            })
        }
        if(!this.props.overridePosition){
            // Set tooltip position
            node.style.left = result.position.left + 'px'
            node.style.top = result.position.top + 'px'
        }
    }

    /**
     * CLear all kinds of timeout of interval
     */
    clearTimer () {
        clearTimeout(this.delayShowLoop)
        clearTimeout(this.delayHideLoop)
        clearTimeout(this.delayReshow)
        clearInterval(this.intervalUpdateContent)
    }

    render () {
        const {extraClass, html, ariaProps, disable} = this.state
        const placeholder = this.getTooltipContent()
        const isEmptyTip = this.isEmptyTip(placeholder)
        let tooltipClass = classname(
            '__mw_component_tooltip',
            {'show': this.state.show && !disable && !isEmptyTip},
            {'border': this.state.border},
            {'place-top': this.state.place === 'top'},
            {'place-bottom': this.state.place === 'bottom'},
            {'place-left': this.state.place === 'left'},
            {'place-right': this.state.place === 'right'},
            {'type-dark': this.state.type === 'dark'},
            {'type-success': this.state.type === 'success'},
            {'type-warning': this.state.type === 'warning'},
            {'type-error': this.state.type === 'error'},
            {'type-info': this.state.type === 'info'},
            {'type-light': this.state.type === 'light'},
            {'type-block': this.state.type === 'block'},
            {'allow_hover': this.props.delayUpdate},
            {'allow_click': this.props.clickable}
        )

        let Wrapper:any = this.props.wrapper
        if (Tooltip.supportedWrappers.indexOf(Wrapper) < 0) {
            Wrapper = Tooltip.defaultProps.wrapper
        }
        const wrapperClassName = [tooltipClass, extraClass].filter(Boolean).join(' ')

        if (html) {
            return (
                <Wrapper className={wrapperClassName}
                         id={this.props.id}
                         ref={(ref:any) => this.tooltipRef = ref}
                         {...ariaProps}
                         data-id='tooltip'
                         dangerouslySetInnerHTML={{__html: placeholder}}/>
            )
        } else {
            return (
                <Wrapper className={wrapperClassName}
                         id={this.props.id}
                         {...ariaProps}
                         ref={(ref:any) => this.tooltipRef = ref}
                         data-id='tooltip'>{placeholder}</Wrapper>
            )
        }
    }
}

export default Tooltip;
