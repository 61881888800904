import React, {useContext, useState} from 'react';
import {showToast} from "store/toast-alerts/actions";
import {useDispatch} from "react-redux";
import {
    AlertsIntegrationIcon,
} from "core/components/svg/icons";
import AppContext, {ContextProps} from "core/components/wrapper/context";
import CodeViewer from "core/components/code-viewer";
import Button from "core/components/button";
import FormTextInput from "core/components/form/form-text-input";
import {CopyIconNew} from "core/components/svg/icons";
import {getTenantUrl} from "core/application/utils";
import {StepsEntity, StepsEnum} from "views/modules/integrations/entity";

export default function CloudfareWorker() {
    const context = useContext<ContextProps>(AppContext);
    const [inputValue, setInputValue] = useState('');
    const [serviceValue, setServiceValue] = useState('');
    const isHelloBarRendered = document.querySelector('.hello-bar') !== null;
    const dispatch = useDispatch();

    function copyToClipboard(text: string) {

        navigator.clipboard.writeText(text)
            .then(() => {
                dispatch(showToast("success", "Copied to Clipboard !"))
            });
    }

    const handleInputChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setInputValue(event.target.value);
    };
    const handleServiceChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setServiceValue(event.target.value);
    };

    const url = getTenantUrl(context.user);
    const capturePath = `https://${url}`;

    const CloudFareAPM = "init({ \n" +
        `   projectName:"${inputValue == '' ? "{APM-PROJECT-NAME}" : inputValue}",
` +
        `   serviceName:"${serviceValue == '' ? "{APM-SERVICE-NAME}" : serviceValue}", \n  ` +
        ` accountKey:\"${context.user?.account?.agent_config?.token}",\n ` +
        `  target:\"${capturePath}:443",\n` +
        "   consoleLogEnabled:false,\n" +
        "})"

    const CloudFareLogs = "// sdk.logger.SEVERITY( MESSAGE, KEY-VALUE PAIRS ) \n \n" +
        `sdk.logger.error("error test") \n` +
        `sdk.logger.error("error with attributes",{"log.file.name":"error.log"}) \n` +
        `sdk.logger.info("info test") \n` +
        `sdk.logger.debug("debug test") \n` +
        `sdk.logger.warn("warn test") \n`

    const steps: StepsEntity = {
        code: {
            title: "Getting Started with Code",
        },
        custom_logs: {
            title: "Setup Custom Logs",
        },
        see_data: {
            title: "View Your Data",
        }
    }

    const [activeStep, setActiveStep] = useState(StepsEnum.Code);

    return (
        <>
            <div className={"markdown-body installation-docs apm-installation-doc node-js-doc"}>
                <div className={"apm-body"}>
                    <div className={"apm-steps"}>
                        {
                            steps && Object.keys(steps).map((step, index) => {
                                return <div key={index + 1}
                                            className={`step ${activeStep === step ? 'selected' : ''} ${(Object.keys(steps).indexOf(activeStep) || 0) > index ? 'passed' : ''}`}>
                                    <div className={"count"}>
                                        <span>{index + 1}</span>
                                    </div>
                                    <div className={"detail"} onClick={() => setActiveStep(step)}>
                                        {steps[step].title}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className={`apm-content ${!isHelloBarRendered && "hello-bar-exists"}`}>
                        {activeStep === StepsEnum.Code && <>
                            <div className={"workspace"}>
                                <div className={"blocks"}>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>1. Install Middleware Worker Package</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'javascript'}>{"npm i @middleware.io/agent-apm-worker"}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard("npm i @middleware.io/agent-apm-worker")}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>2. Import Tracker</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'javascript'}>{"import { init,track } from '@middleware.io/agent-apm-worker';"}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard("import { init,track } from '@middleware.io/agent-apm-worker';")}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>3. Initialize Tracker with your Middleware API key</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={"inputbox-flex cloudflare-worker-flex"}
                                                 style={{marginTop: 15}}>
                                                <FormTextInput label={"Project Name"} placeholder={"Project Name"}
                                                               maxLength={50} onChange={handleInputChange}/>
                                                <FormTextInput label={"Service Name"} placeholder={"Service Name"}
                                                               maxLength={50} onChange={handleServiceChange}/>
                                            </div>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'javascript'}>{CloudFareAPM}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard(CloudFareAPM)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"help"}>
                                    <div className={"block"}>
                                        <AlertsIntegrationIcon/> If you want to watch instrumented logs in terminal, set
                                        consoleLogEnabled:true
                                    </div>
                                </div>
                            </div>
                            <div className={"buttons-group"}>
                                <Button className={"disabled"}>Back</Button>
                                <Button primary onClick={() => setActiveStep('custom_logs')}>Next</Button>
                            </div>
                        </>}
                        {activeStep === 'custom_logs' && <>
                            <div className={"workspace"}>
                                <div className={"blocks"}>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>1. Enable Custom Logs (Optional)</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Custom logs are ones that the application does not produce by default. They may be generated within certain methods, or managed by a central logging method in your application.</span><br/>
                                            <span>To get started with custom logs add the following code:</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'javascript'}>{CloudFareLogs}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard(CloudFareLogs)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={"buttons-group"}>
                                <Button onClick={() => setActiveStep(StepsEnum.Code)}>Back</Button>
                                <Button primary onClick={() => setActiveStep('see_data')}>Next</Button>
                            </div>
                        </>}
                        {activeStep === 'see_data' && <>
                            <div className={"workspace"}>
                                <div className={"blocks"}>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>View Your Data</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Once you have completed the Cloudflare Workers installation, wait 3-5 minutes before accessing your data.</span>
                                        </div>
                                        <div className={"content"}>
                                            <p className={"paragraph"}
                                               style={{marginTop: "10px", marginBottom: "0"}}>Access your Trace and Log
                                                data by clicking on one of the buttons bellow.</p>
                                            <div className={"buttons-group"}>
                                                <Button primary
                                                        onClick={() => window.open('/apm/overview', '_blank')}>Traces</Button>
                                                <Button primary
                                                        onClick={() => window.open('/logs', '_blank')}>Logs</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={"buttons-group"}>
                                <Button onClick={() => setActiveStep('custom_logs')}>Back</Button>
                                <Button className={"disabled"}>Next</Button>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </>
    )
}
