import * as React from "react";
import "./_style.scss";

import { useState } from "react";
import Dialog from "core/components/v2/dialog";
import LogoLoader from "core/components/v2/loader/logo-loader";

interface Props {
  url: string;
  onClose: () => void;
  openChargebeePopup: boolean;
}

const ChargebeePopup = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const handleIframeLoad = () => {
    setIsLoading(false);
  };
  return (
    <>
      <Dialog
        isOpen={props.openChargebeePopup}
        position={"center"}
        overlayClassName={
          "free-trial-over-__dialog common-confirmation-dialog chargebee-iframe-popup"
        }
        onClose={() => {
          props.onClose();
        }}
      >
        <div className="chargebee-iframe-container">
          {isLoading && <LogoLoader />}
          <iframe
            src={props.url}
            width="100%"
            height="100%"
            allowFullScreen
            onLoad={handleIframeLoad}
          ></iframe>
        </div>
      </Dialog>
    </>
  );
};

export default ChargebeePopup;
