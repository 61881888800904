import * as React from "react";
import { RoutesPatterns } from "core/utils/route_schema";
import withSectionExpand from "./components/Common/withSectionExpand";

const LogsRoutes: RoutesPatterns = {
  moduleName: "logs",
  slug: "/logs",
  breadcrumbs: React.lazy(() => import("views/modules/logs-v2/breadcrumbs")),
  sidebar: React.lazy(() => import("views/modules/logs-v2/sidebar/index")),
  globalElement: React.lazy(
    () => import("views/modules/logs-v2/components/Common/index")
  ),
  items: [
    {
      pattern: ["/"],
      component: React.lazy(() =>
        import("views/modules/logs-v2/index").then((module) => ({
          default: withSectionExpand(module.default),
        }))
      ),
      breadcrumbTitle: "Logs",
    },
    {
      pattern: ["/transactions"],
      component: React.lazy(() =>
        import("views/modules/logs-v2/modules/transaction/index").then(
          (module) => ({
            default: withSectionExpand(module.default),
          })
        )
      ),
      breadcrumbTitle: "Transaction",
    },
    {
      pattern: ["/patterns"],
      component: React.lazy(() =>
        import("views/modules/logs-v2/modules/patterns/index").then(
          (module) => ({
            default: withSectionExpand(module.default),
          })
        )
      ),
      breadcrumbTitle: "Patterns",
    },
    {
      pattern: ["/dashboard"],
      component: React.lazy(() =>
        import("views/modules/logs-v2/modules/dashboard/index").then(
          (module) => ({
            default: withSectionExpand(module.default),
          })
        )
      ),
      breadcrumbTitle: "Log Dashboard",
    },
  ],
};
export default LogsRoutes;
