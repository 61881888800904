import { getThemeState } from "core/utils";
import { formatYAxisTick } from "core/utils/chart_helper";
import {
  Axis,
  Chart as HighchartsChart,
  Options,
  PlotOptions,
  Point,
  SeriesOptionsType,
  TooltipOptions,
  XAxisOptions,
  YAxisOptions,
  YAxisPlotBandsOptions,
} from "highcharts";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import {
  RenderTpl,
  getValueFromConfig,
} from "views/modules/builder/entities/tpl.entities";
import { TimeRangeOption } from "../../datepicker/model";
import { Tooltip } from "./Tooltip";
import TooltipFormatter from "./TooltipFormatter";
import "./_style.scss";
import useIsclicked from "./components/isClicked";
import HighchartsReact, { Highchart } from "./highcharts-config";
import { ChartType, ChartTypes, TypeMap } from "./models";

const DEFAULT_HEIGHT = 160;
const DEFULT_LABEL_HEIGHT = 16;
const DEFAULT_GAP = 12;

const getAllChartsIds = () => {
  const availableCharts: string[] = [];
  const modal = document.getElementsByClassName("ReactModalPortal"); //ReactModalPortal
  if (modal?.length) {
    const highcharts = Array.from(
      modal[0].getElementsByClassName("highcharts-container")
    );
    if (highcharts?.length) {
      highcharts.forEach((element) => {
        availableCharts.push(element.id);
      });
    }
  } else {
    const highcharts = Array.from(
      document.getElementsByClassName("highcharts-container")
    );
    if (highcharts?.length) {
      highcharts.forEach((element) => {
        availableCharts.push(element.id);
      });
    }
  }
  return availableCharts;
};
function setHighlightState(point: Point, isAltClicked: boolean): void {
  const availableCharts = getAllChartsIds();
  Highchart.charts.forEach((chart) => {
    if (
      chart?.container?.id &&
      availableCharts.includes(chart?.container?.id)
    ) {
      if (point.series.chart !== chart) {
        if (chart) {
          const matchedPoints: Point[] = [];
          chart.series.forEach((s) => {
            if (s.points) {
              const matchedPoint = s.points.find((po) => po.x === point.x);
              if (matchedPoint) {
                matchedPoints.push(matchedPoint);
                matchedPoint.setState("hover");
              }
            }
          });
          if (matchedPoints?.length) {
            if (isAltClicked) {
              chart.tooltip.refresh(matchedPoints);
            }
          }
          chart.xAxis[0].drawCrosshair(undefined, matchedPoints[0]);
        }
      }
    }
  });
}

function hideHighlightState(point: Point): void {
  const availableCharts = getAllChartsIds();
  Highchart.charts.forEach((chart) => {
    if (
      chart?.container?.id &&
      availableCharts.includes(chart?.container?.id)
    ) {
      if (chart) {
        if (point.series.chart !== chart) {
          chart.series.forEach((s) => {
            const found = s.points?.find((po) => po.x === point.x);
            if (found) found.setState("");
          });
        }
        chart.tooltip.hide();
        chart.xAxis[0].hideCrosshair();
      }
    }
  });
}
const ChartV2 = (props: ChartTypes<TypeMap>) => {
  const theme = useSelector(getThemeState);
  const isDark = theme === "dark";
  const {
    isZooming = true,
    showMarkers = true,
    yAxsisVisible = true,
    stacked = false,
    xAxsisVisible = true,
    showLegend = false,
    showCrossHair = true,
    yAxsisTitle = "",
    secondYAxisTitle = "",
    onDateChange,
  } = props;
  const routeData = React.useContext(MwRouteContext);
  const parentRef = useRef<HTMLDivElement>(null);
  const resizeObs = useRef<ResizeObserver | null>(null);
  const [chartHeight, setChartHeight] = useState("");
  const { isAltClicked } = useIsclicked();
  const getPloutOptions = (): PlotOptions => {
    switch (props.chartType) {
      case ChartType.COLUMN:
        return {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            states: {
              hover: {
                enabled: true,
                halo: {
                  size: 0,
                },
              },
            },
            ...props.extraChartConfig,
            dataLabels: {
              enabled: false,
            },
            borderRadius: 3,
          },
        };
      case ChartType.BAR:
        return {
          bar: {
            pointPadding: 1,
            borderWidth: 0,
            states: {
              hover: {
                enabled: true,
                halo: {
                  size: 0,
                },
              },
            },
            minPointLength: 15,
            ...props.extraChartConfig,
            dataLabels: {
              enabled: true,
              style: {
                color: "var(--color-text)",
                fontSize: "10px",
                fontWeight: "700",
                border: "0px",
                textOutline: "none",
              },
              borderWidth: 0,
              inside: false,
              shadow: false,
            },
            borderRadius: 3,
          },
        };
      case ChartType.COLUMNRANGE:
        return {
          columnrange: {
            animation: false,
            pointWidth: 10,
            states: {
              hover: {
                enabled: true,
                halo: {
                  size: 0,
                },
              },
            },
            borderRadius: 0,
            ...props.extraChartConfig,
            dataLabels: {
              enabled: false,
            },
          },
        };
      case ChartType.TILEMAP:
        return {
          tilemap: {
            ...props.extraChartConfig,
            animation: true,
            states: {
              hover: {
                enabled: true,
                halo: {
                  size: 0,
                },
              },
            },
            borderRadius: 0,
            dataLabels: {
              enabled: false,
            },
          },
        };
      case ChartType.PIE:
        return {
          pie: {
            animation: {
              duration: 1000,
            },
            states: {
              hover: {
                enabled: true,
                halo: {
                  size: 0,
                },
              },
            },
          },
        };
      default:
        return {
          treemap: {
            allowTraversingTree: true,
            states: {
              hover: {
                enabled: true,
                halo: {
                  size: 0,
                },
              },
            },
          },
          area: {
            connectNulls: false,
            fillOpacity: 0.1,
            marker: {
              enabled: false,
              radius: 2.5,
              symbol: "circle",
              states: {
                hover: {
                  enabled: true,
                  lineColor: "rgb(100,100,100)",
                },
              },
            },
          },
          areaspline: {
            stacking: "normal",
            marker: {
              enabled: false,
              radius: 2.5,
              symbol: "circle",
              states: {
                hover: {
                  enabled: true,
                  lineColor: "rgb(100,100,100)",
                },
              },
            },
          },
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
        };
    }
  };

  const getTooltipOptions = (): TooltipOptions => {
    const tooltipOptions: TooltipOptions = {
      useHTML: true,
      snap: 0,
      shadow: false,
      animation: false,
      borderWidth: 2,
      hideDelay: 0.1,
      padding: 0,
      backgroundColor: "var(--color-theme-bg) !important",
      borderRadius: 0,
      borderColor: "var(--color-theme-bg) !important",
      shared: true,
      followPointer: true, // [ChartType.TREEMAP].includes(props.chartType),
      style: {
        width: 400,
        "white-space": "normal !important",
        zIndex: 99,
        color: isDark ? "#001844" : "#fff",
        background: isDark ? "var(--color-theme-bg) !important" : "#d0d0d2",
        backgroundColor: "var(--color-theme-bg) !important",
        fill: isDark ? "var(--color-theme-bg) !important" : "#fff",
      },
      distance: 0,
      nullFormat: "Value is not available.",
      stickOnContact: false,
      outside: true,
      positioner: function (boxWidth, boxHeight, point) {
        // Adjust tooltip position if it goes outside the viewport
        const tooltipWidth = boxWidth; // Add extra padding
        const element = document.getElementById(this.chart.container.id)!;

        const customElement = document.querySelector(
          ".highcharts_tooltip_custom"
        ) as HTMLElement;

        const showTooltipOnTop =
          (boxHeight || 55) >
          window.innerHeight - element.getBoundingClientRect().bottom;

        const chartWidth = this.chart.plotWidth;
        let x = point.plotX + 90;

        let y = showTooltipOnTop
          ? -40 - (customElement?.offsetHeight ?? 9999)
          : this.chart.plotHeight + this.chart.plotTop + 30;

        if (props.xAxsisVisible) {
          x = point.plotX - 80;
          if (showTooltipOnTop) {
            this.chart.tooltip.container?.classList.remove(
              "bottom-side-tooltip"
            );
            this.chart.tooltip.container?.classList.add("top-side-tooltip");
          } else {
            this.chart.tooltip.container?.classList.add("bottom-side-tooltip");
          }

          y = showTooltipOnTop
            ? -40 - (customElement?.offsetHeight ?? 9999)
            : this.chart.plotHeight + this.chart.plotTop + 30;

          if ([ChartType.COLUMN].includes(props.chartType)) {
            x = point.plotX + 60 - (boxWidth || 9999) / 2;
          }
          if ([ChartType.AREA, ChartType.SCATTER].includes(props.chartType)) {
            x = point.plotX + 60 - (boxWidth || 9999) / 2;
          }
          if (
            x + (tooltipWidth - 80) > chartWidth &&
            ![ChartType.PIE].includes(props.chartType)
          ) {
            x = point.plotX - tooltipWidth + 80;
            if ([ChartType.TREEMAP].includes(props.chartType)) {
              x = point.plotX - tooltipWidth;
            }
            if ([ChartType.BAR].includes(props.chartType)) {
              x = point.plotX - tooltipWidth - 20;
            }
          }
        } else {
          if ([ChartType.PIE, ChartType.TREEMAP].includes(props.chartType)) {
            x = point.plotX + 40;
            y = point.plotY - 30;
          }
          if ([ChartType.BAR].includes(props.chartType)) {
            x = point.plotX + 30;
            y = point.plotY + 10 - boxHeight / 2;
          }
          if ([ChartType.COLUMN].includes(props.chartType)) {
            x = point.plotX + 70;
            y = point.plotY - 30;
          }
          this.chart.tooltip.container?.classList.remove("right-side-tooltip");
          if (
            x + tooltipWidth > chartWidth &&
            ![ChartType.PIE].includes(props.chartType)
          ) {
            x = point.plotX - tooltipWidth + 20;
            if ([ChartType.TREEMAP].includes(props.chartType)) {
              x = point.plotX - tooltipWidth;
            }
            if ([ChartType.BAR].includes(props.chartType)) {
              x = this.chart.plotLeft - tooltipWidth - 20;
              y = point.plotY + 10 - boxHeight / 2;
            }

            this.chart.tooltip.container?.classList.add("right-side-tooltip");
          }
        }
        return {
          x: x,
          y: y,
        };
      },
      ...(props.tooltipOptions ? props.tooltipOptions : {}),
    };
    return tooltipOptions;
  };
  const getXAxisConfig = () => {
    const config: XAxisOptions | XAxisOptions[] = {
      visible: xAxsisVisible,
      startOnTick: false,
      endOnTick: false,
      title: {
        text: "",
      },
      crosshair:
        showCrossHair &&
        ![ChartType.PIE, ChartType.TREEMAP].includes(props.chartType)
          ? {
              color: "#D6D9DC",
              dashStyle: "ShortDash",
            }
          : false,

      labels: {
        rotation: 0,
        skew3d: false,
        style: {
          fontSize: "12px",
          margin: "10px",
          padding: "10px",
          color: isDark ? "#fff" : "#001844",
          textOverflow: "ellipsis",
        },
        y: DEFULT_LABEL_HEIGHT + DEFAULT_GAP - 2,
      },
      tickLength: 0,
      tickColor: isDark ? "#fff" : "var(--color-grey-300)",
      scrollbar: {
        enabled: false,
      },
      lineColor: isDark ? "var(--color-neutrals-200)" : "var(--color-grey-300)",
      gridLineColor: "var(--color-grey-300)",
      gridLineWidth: 0,
      gridLineDashStyle: "Dash",
      showEmpty: true,
    };

    if (props.xAxisFormatter && config.labels) {
      config.labels.formatter = props.xAxisFormatter;
    }
    if (props.xType) {
      config.type = props.xType;
    } else {
      config.type = "datetime";
    }
    if (props.xAxsisCategory) {
      config.categories = props.xAxsisCategory;
    }
    if (props.chartType === ChartType.BAR) {
      config.visible = true;
      config.lineWidth = 0;
      config.labels = {
        rotation: 0,
        skew3d: false,
        padding: 0,
        x: 0,
        align: "left",
        reserveSpace: false,
        y: 25,
        style: {
          fontSize: "var(--font-body-small)",
          color: "var(--color-card-text-color)",
          textOverflow: "ellipsis",
        },
        useHTML: true,
      };
    }
    if (props.chartType === "tilemap") {
      config.visible = false;
    }
    return config;
  };
  const getYAxisConfig = (opposite = false, yAxsisTitle = "") => {
    const config: YAxisOptions | YAxisOptions[] = {
      visible: yAxsisVisible,
      startOnTick: true,
      title: {
        text: yAxsisTitle || "",
        style: {
          fontWeight: "500",
          lineWidth: 20,
          color: "var(--color-card-text-color)",
          fontSize: "var(--font-body)",
        },
      },
      minRange: 0.001,
      opposite,
      showEmpty: true,
      tickLength: 4,
      labels: {
        skew3d: false,
        style: {
          fontSize: "12px",
          margin: "10px",
          padding: "10px",
          color: isDark ? "#fff" : "#001844",
        },
        formatter: function () {
          return this.value.toString();
        },
      },
      tickAmount: 4, // Ensure 4 labels are displayed
      softMax: props?.maxYAxisValue,
      min: 0,
      tickPixelInterval: 23,
      allowDecimals: true,
      scrollbar: {
        enabled: false,
      },
      lineWidth: 0,
      lineColor: isDark ? "#fff" : "black",
      gridLineColor: "var(--color-neutrals-200)",
      gridLineWidth: 1,
      gridLineDashStyle: "Solid",
    };
    if (props?.timeseriesChartOptions?.bands?.length) {
      const bands: YAxisPlotBandsOptions[] =
        props.timeseriesChartOptions.bands.map((b) => {
          const obj: YAxisPlotBandsOptions = {
            from: b.range.min,
            to: b.range.max,
            color: b.color,
          };
          return obj;
        });
      config.plotBands = bands;
    }
    const yAxisTicker = (
      value: number | string,
      yAxisConfig: RenderTpl | undefined
    ): string => {
      if (!yAxisConfig) return value + "";
      if (typeof value === "string") return value;
      if (!yAxisConfig.type && typeof value === "number") {
        /**
         * Disabled due to default it is any
         */
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return formatYAxisTick(value);
      }
      const v = getValueFromConfig(value || 0, yAxisConfig);
      return v;
    };
    if (!opposite && props.yAxisConfig && config.labels) {
      config.labels.formatter = function (ctx) {
        const axsis: Axis & {
          dataMax?: number;
        } = ctx.axis;

        if (Number(ctx.value) === 0) {
          const maxExt = yAxisTicker(
            parseFloat((axsis?.dataMax ?? 0).toString()),
            !props.yAxisConfig
              ? undefined
              : {
                  ...(props.yAxisConfig || {}),
                  config: {
                    ...(props.yAxisConfig?.config || {}),
                    precision: 0,
                  },
                }
          ).toString();

          const lastChar = maxExt.replace(/[0-9.]/g, "");
          return ctx.value.toString() + " " + lastChar;
        }
        return yAxisTicker(
          parseFloat(ctx.value.toString()),
          !props.yAxisConfig
            ? undefined
            : {
                ...(props.yAxisConfig || {}),
                config: {
                  ...(props.yAxisConfig?.config || {}),
                  precision: 0,
                },
              }
        );
      };
    }
    if (opposite && props.secondyAxisConfig && config.labels) {
      config.labels.formatter = function (ctx) {
        const axsis: Axis & {
          dataMax?: number;
        } = ctx.axis;

        if (Number(ctx.value) === 0) {
          const maxExt = yAxisTicker(
            parseFloat((axsis?.dataMax ?? 0).toString()),
            !props.secondyAxisConfig
              ? undefined
              : {
                  ...(props.secondyAxisConfig || {}),
                  config: {
                    ...(props.secondyAxisConfig?.config || {}),
                    precision: 0,
                  },
                }
          ).toString();

          const lastChar = maxExt.replace(/[0-9.]/g, "");
          return ctx.value.toString() + " " + lastChar;
        }
        return yAxisTicker(
          parseFloat(ctx.value.toString()),
          !props.secondyAxisConfig
            ? undefined
            : {
                ...(props.secondyAxisConfig || {}),
                config: {
                  ...(props.secondyAxisConfig?.config || {}),
                  precision: 0,
                },
              }
        );
      };
    }
    if (props.chartType === "tilemap") {
      config.min = 0;
      config.max = 8;
    }
    return config;
  };

  const chartsOptions = (
    chartData: (SeriesOptionsType & {
      stickyTracking?: boolean | undefined;
      data?: unknown;
    })[]
  ): Options => {
    const opts: Options = {
      accessibility: {
        enabled: false,
      },
      boost: {
        enabled: true,
        useGPUTranslations: true,
        seriesThreshold: 5,
      },
      scrollbar: {
        enabled: true,
      },
      chart: {
        // renderTo: "container",
        style: {
          cursor: "crosshair",
          overflow: "visible",
        },
        panning: {
          enabled: false,
          type: "x",
        },
        inverted: false,
        animation: false,
        marginBottom: [ChartType.BAR].includes(props.chartType)
          ? 15
          : showLegend
            ? DEFULT_LABEL_HEIGHT + DEFAULT_GAP
            : 28,
        type: props.chartType,
        zooming: {
          type: "x",
          pinchType: "x",
          singleTouch: true,
          mouseWheel: {
            enabled: true,
            type: "x",
          },
        },
        resetZoomButton: {
          theme: {
            style: {
              display: "none",
            },
          },
        },
        backgroundColor: "transparent",
        events: {
          load: function () {
            if (
              this.series?.length &&
              stacked &&
              ChartType.BAR === props.chartType
            ) {
              this.series.map((series, index) => {
                if (index) {
                  series.points.map((point) => {
                    point.update({
                      dataLabels: {
                        enabled: false,
                        inside: false,
                      },
                    });
                  });
                }
              });

              this.redraw(true); // Manually trigger redraw
            }
          },
          selection: function (event) {
            if (event.xAxis?.[0].min && event.xAxis[0].max) {
              if (onDateChange)
                onDateChange({
                  fromTs: new Date(event.xAxis[0].min).getTime(),
                  toTs: new Date(event.xAxis[0].max).getTime(),
                  range: TimeRangeOption.Custom,
                });
              else
                routeData.actions.handleTimeRange(
                  new Date(event.xAxis[0].min).getTime(),
                  new Date(event.xAxis[0].max).getTime(),
                  TimeRangeOption.Custom
                );
            }
            return undefined;
          },
        },
      },
      title: {
        text: "",
      },
      legend: {
        align: "center",
        verticalAlign: "bottom",
        layout: "horizontal",
        navigation: {
          enabled: false,
        },
      },

      plotOptions: {
        ...getPloutOptions(),
        series: {
          getExtremesFromAll: false,
          animation: false,
          marker: {
            enabled: showMarkers,
          },
          connectNulls: false,
          states: {
            inactive: {
              opacity: 1,
            },
            hover: {
              enabled: true,
              opacity: 1,
            },
          },

          lineWidth: 2,
          threshold: 0,
          stickyTracking: true,
          point: {
            events: {
              mouseOver: function () {
                OnMouseOver(this);
              },
              mouseOut: function () {
                OnMouseOut(this);
              },
            },
          },
        },
        ...(props.extraPlotOptions ? props.extraPlotOptions : {}),
      },
      series: chartData.map((it) => ({
        ...it,
        visible: true,
      })),
      xAxis: getXAxisConfig(),
      yAxis: props.multipleYAxis
        ? [
            getYAxisConfig(false, yAxsisTitle),
            getYAxisConfig(true, secondYAxisTitle),
          ]
        : getYAxisConfig(false, yAxsisTitle),
      credits: {
        enabled: false,
      },
      tooltip: getTooltipOptions(),
      ...(props.extraConfig ? props.extraConfig : {}),
    };
    if (props.multipleYAxis) {
      delete opts.chart?.type;
    }
    if ([ChartType.BAR].includes(props.chartType) && opts.chart) {
      opts.chart.marginTop = -10;
      opts.chart.height =
        props.series && props.series.length > 0
          ? (
              props.series as (SeriesOptionsType & {
                data: Record<string, string>[];
              })[]
            )[0]?.data.length * 44
          : 125;
    }
    if (!isZooming) {
      delete opts?.chart?.zooming;
    }
    return opts;
  };
  const chartData = useMemo(() => {
    return props.series as (SeriesOptionsType & {
      stickyTracking?: boolean;
      data?: unknown;
      tooltip?: {
        enabled?: boolean;
      };
    })[];
  }, [props.series]);
  const [chart, setChart] = useState<HighchartsChart>();
  // const [options, setoptions] = useState<Options | undefined>();
  // const [legendItems, setLegendItems] = useState<LegendType[]>([]);
  const callback = useCallback((chart: HighchartsChart) => {
    setChart(chart);
  }, []);
  function OnMouseOver(point: Point) {
    setHighlightState(point, isAltClicked);
    if (props.multipleYAxis) {
      point.series.chart.series.forEach((otherSeries) => {
        if (
          otherSeries.type !== point.series.type &&
          otherSeries?.points?.length > 0
        ) {
          otherSeries.points.forEach((p) => {
            p?.graphic?.css({
              opacity: 0.3,
            });
          });
        }
      });
    } else {
      point.series.points.forEach((p) => {
        p?.graphic?.css({
          opacity: 1,
        });
      });

      point?.graphic?.css({
        opacity: 1,
      });
    }
  }
  function OnMouseOut(point: Point) {
    hideHighlightState(point);
    point.series.points.forEach((p) => {
      p?.graphic?.css({
        opacity: 1,
      });
    });
    point.series.chart.series.forEach((otherSeries) => {
      if (otherSeries !== point.series && otherSeries?.points?.length > 0) {
        otherSeries.points.forEach((p) => {
          p?.graphic?.css({
            opacity: 1,
          });
        });
      }
    });
  }
  useEffect(() => {
    if (chart) {
      chart.update(
        {
          plotOptions: {
            series: {
              point: {
                events: {
                  mouseOver: function () {
                    OnMouseOver(this);
                  },
                  mouseOut: function () {
                    OnMouseOut(this);
                  },
                },
              },
            },
          },
        },
        true
      );
    }
  }, [isAltClicked]);
  // const setYaxsisExtremes = () => {
  //   if (chart) {
  //     let max = 4;
  //     let min = 0;
  //     chart.series.forEach(
  //       (
  //         series: Series & {
  //           options: { data?: unknown };
  //         }
  //       ) => {
  //         const yAxis: Axis & {
  //           dataMin: number;
  //           dataMax: number;
  //         } = series.yAxis as Axis & {
  //           dataMin: number;
  //           dataMax: number;
  //         };
  //         if (yAxis) {
  //           if (yAxis.dataMax > max) max = yAxis.dataMax;
  //           if (yAxis.dataMin < min) min = yAxis.dataMin;
  //         }
  //       }
  //     );
  //     if (chart.yAxis?.length) {
  //       chart.yAxis.map(
  //         (
  //           yAxis: Axis & {
  //             dataMax?: number;
  //           }
  //         ) => {
  //           yAxis.setExtremes(0, yAxis.dataMax, true);
  //           chart.yAxis[0].update(
  //             {
  //               max: yAxis.dataMax,
  //             },
  //             true
  //           );
  //         }
  //       );
  //     }
  //   }
  // };
  // useEffect(() => {
  //   if (options) return;
  //   setoptions(chartsOptions(chartData));
  //   if (chart) {
  //     const items: LegendType[] = [];
  //     setYaxsisExtremes();
  //     chart.series.forEach(
  //       (
  //         series: Series & {
  //           options: { data?: unknown };
  //         }
  //       ) => {
  //         if (series.name !== NULL_SERIES && series.visible) {
  //           items.push({
  //             name: series.name,
  //             color: series.color as string,
  //             visible: series.visible,
  //           });
  //         }
  //       }
  //     );

  //     setLegendItems(items);
  //   }
  // }, [chartData, chartHeight]);
  // useEffect(() => {
  //   if (chart) {
  //     console.log("called chartData, chartHeight, chart");
  //     const items: LegendType[] = [];
  //     chart.series.forEach(
  //       (
  //         series: Series & {
  //           options: { data?: unknown };
  //         }
  //       ) => {
  //         if (series.name !== NULL_SERIES && series.visible) {
  //           items.push({
  //             name: series.name,
  //             color: series.color as string,
  //             visible: series.visible,
  //           });
  //         }
  //       }
  //     );
  //     setYaxsisExtremes();
  //     setLegendItems(items);
  //     // Update the chart options with the calculated tickPixelInterval
  //     if (chart && !props.xType) {
  //       chart.update(
  //         {
  //           xAxis: {
  //             type: "datetime", // Assuming you are working with date and time values
  //             // tickPixelInterval: tickPixelInterval,
  //           },
  //         },
  //         true
  //       );
  //     }
  //   }
  // }, [chartData, chartHeight, chart]);

  useEffect(() => {
    if (!parentRef.current) return;
    if (resizeObs.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (parentRef.current) {
        setChartHeight(`${parentRef.current.offsetHeight}px`);
      }
    });
    resizeObserver.observe(parentRef.current);
    resizeObs.current = resizeObserver;
    return () => resizeObserver.disconnect(); // clean up
  }, [chartData]);
  /*const handleLegendItemClick = (itemName: string) => {
    // Modify Highcharts options based on the legend item clicked
    const newOptions = { ...options };
    if (newOptions.series?.length) {
      // Example modification: Hide/show series based on legend item clicked
      newOptions.series.forEach((series) => {
        if (series.name === itemName) {
          series.visible = !series.visible || false;
        }
      });
      const foundName = [...legendItems].findIndex(
        (it) => it.name === itemName
      );
      if (foundName > -1) {
        const updated = [...legendItems];
        updated[foundName].visible = !updated[foundName].visible || false;
        setLegendItems(updated);
      }
      // Update Highcharts chart with new options
      if (chart) {
        chart.update(newOptions, true, true);
        setYaxsisExtremes();
      }
    }
  };*/
  const options = useMemo(() => {
    return chartsOptions(chartData);
  }, [chartData, isAltClicked]);
  return options && Object.keys(options).length > 0 ? (
    <div
      ref={parentRef}
      style={{
        width: "100%",
        height: props.resource?.resourceType
          ? props.chartHeight
            ? `${props.chartHeight}px`
            : showLegend
              ? `${DEFAULT_HEIGHT + DEFULT_LABEL_HEIGHT + DEFAULT_GAP}px`
              : DEFAULT_HEIGHT + "px"
          : "100%",
        position: "relative",
      }}
      id={"container"}
    >
      <HighchartsReact
        containerProps={{
          style: [ChartType.BAR].includes(props.chartType)
            ? {
                width: "100%",
                height: "100%",
                overflowY: chartHeight ? "auto" : "hidden",
              }
            : {
                width: "100%",
                height: `calc(100% - ${showLegend ? `${DEFULT_LABEL_HEIGHT + DEFAULT_GAP - 2}px` : `12px`})`,
              },
          className: "chart-v2",
        }}
        highcharts={Highchart}
        options={options}
        id={"chartNew"}
        callback={callback}
      />
      {chart && (
        <>
          {/* {showLegend && (
            <CustomLegend
              items={legendItems}
              handleLegendItemClick={handleLegendItemClick}
            />
          )} */}
          <Tooltip chart={chart}>
            {(formatterContext, isAltClicked) =>
              TooltipFormatter(
                formatterContext,
                props.yAxisConfig,
                isAltClicked,
                props.interval
              )
            }
          </Tooltip>
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

export default ChartV2;

ChartV2.defaultProps = {
  xAxsisVisible: true,
};
