import { TimeRangeOption } from "core/components/datepicker/model";
import { PresetOptions } from ".";
import dayjs from "dayjs";

const {
  OneMinute,
  FiveMinutes,
  FifteenMinutes,
  OneHour,
  ThreeHours,
  TwelveHours,
  OneDay,
  TwoDays,
  ThreeDays,
  FiveDays,
  TenDays,
  OneWeek,
  TwoWeeks,
  TwentyDays,
  TwentyFiveDays,
  OneMonth,
  Custom,
} = TimeRangeOption;

export const defaultPresetOptions: PresetOptions[] = [
  {
    timeRangeOption: OneMinute,
    label: "Past minute",
    value: [dayjs().subtract(1, "minute"), dayjs()],
  },
  {
    timeRangeOption: FiveMinutes,
    label: "Past 5 minutes",
    value: [dayjs().subtract(5, "minute"), dayjs()],
  },
  {
    timeRangeOption: FifteenMinutes,
    label: "Past 15 minutes",
    value: [dayjs().subtract(15, "minute"), dayjs()],
  },
  {
    timeRangeOption: OneHour,
    label: "Past hour",
    value: [dayjs().subtract(1, "hour"), dayjs()],
  },
  {
    timeRangeOption: ThreeHours,
    label: "Past 3 hours",
    value: [dayjs().subtract(3, "hour"), dayjs()],
  },
  {
    timeRangeOption: TwelveHours,
    label: "Past 12 hours",
    value: [dayjs().subtract(12, "hour"), dayjs()],
  },
  {
    timeRangeOption: OneDay,
    label: "Past day",
    value: [dayjs().subtract(1, "day"), dayjs()],
  },
  {
    timeRangeOption: TwoDays,
    label: "Past 2 days",
    value: [dayjs().subtract(2, "day"), dayjs()],
  },
  {
    timeRangeOption: OneWeek,
    label: "Past week",
    value: [dayjs().subtract(1, "week"), dayjs()],
  },
  { timeRangeOption: Custom, label: "Custom" },
];

export const usagesPresetOptions: PresetOptions[] = [
  {
    timeRangeOption: OneDay,
    label: "Past day",
    value: [dayjs().subtract(1, "day"), dayjs()],
  },
  {
    timeRangeOption: ThreeDays,
    label: "Past 3 days",
    value: [dayjs().subtract(3, "day"), dayjs()],
  },
  {
    timeRangeOption: FiveDays,
    label: "Past 5 days",
    value: [dayjs().subtract(5, "day"), dayjs()],
  },
  {
    timeRangeOption: OneWeek,
    label: "Past week",
    value: [dayjs().subtract(1, "week"), dayjs()],
  },
  {
    timeRangeOption: TenDays,
    label: "Past 10 days",
    value: [dayjs().subtract(10, "day"), dayjs()],
  },
  {
    timeRangeOption: TwoWeeks,
    label: "Past 2 weeks",
    value: [dayjs().subtract(2, "week"), dayjs()],
  },
  {
    timeRangeOption: TwentyDays,
    label: "Past 20 days",
    value: [dayjs().subtract(20, "day"), dayjs()],
  },
  {
    timeRangeOption: TwentyFiveDays,
    label: "Past 25 days",
    value: [dayjs().subtract(25, "day"), dayjs()],
  },
  {
    timeRangeOption: OneMonth,
    label: "Past month",
    value: [dayjs().subtract(1, "month"), dayjs()],
  },
  { timeRangeOption: Custom, label: "Custom" },
];

export const futurePresetOptions: PresetOptions[] = [
  {
    timeRangeOption: OneMinute,
    label: "Next minute",
    value: [dayjs(), dayjs().add(1, "minute")],
  },
  {
    timeRangeOption: FiveMinutes,
    label: "Next 5 minutes",
    value: [dayjs(), dayjs().add(5, "minute")],
  },
  {
    timeRangeOption: FifteenMinutes,
    label: "Next 15 minutes",
    value: [dayjs(), dayjs().add(15, "minute")],
  },
  {
    timeRangeOption: OneHour,
    label: "Next hour",
    value: [dayjs(), dayjs().add(1, "hour")],
  },
  {
    timeRangeOption: ThreeHours,
    label: "Next 3 hours",
    value: [dayjs(), dayjs().add(3, "hour")],
  },
  {
    timeRangeOption: TwelveHours,
    label: "Next 12 hours",
    value: [dayjs(), dayjs().add(12, "hour")],
  },
  {
    timeRangeOption: OneDay,
    label: "Next day",
    value: [dayjs(), dayjs().add(1, "day")],
  },
  {
    timeRangeOption: TwoDays,
    label: "Next 2 days",
    value: [dayjs(), dayjs().add(2, "day")],
  },
  {
    timeRangeOption: OneWeek,
    label: "Next week",
    value: [dayjs(), dayjs().add(1, "week")],
  },
  { timeRangeOption: Custom, label: "Custom" },
];
