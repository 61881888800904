import {
    PRICE_RECEIVED,
    PRICE_LIST_RECEIVED,
    SUBSCRIPTION_FOR_CUSTOMER_RECEIVED,
    INVOICES_RECEIVED,
    HOSTED_PAGE_RECEIVED,
    CUSTOMER_RECEIVED,
    ITEMS_PRICES_RECEIVED,
    USAGE_LIST_RECEIVED, MW_DAILY_USAGE_RECEIVED, BILLING_COUNTS_RECEIVED,  MW_MONTHLY_USAGE_RECEIVED
} from "./constant";

export const priceReceived = (arg: any = {}) => {
    return {
        type: PRICE_RECEIVED,
        ...arg
    }
}

export const priceListReceived = (arg: any = {}) => {
    return {
        type: PRICE_LIST_RECEIVED,
        ...arg
    }
}
export const subscriptionForCustomerReceived = (arg: any = {}) => {
    return {
        type: SUBSCRIPTION_FOR_CUSTOMER_RECEIVED,
        ...arg
    }
}

export const invoicesReceived = (arg: any = {}) => {
    return {
        type: INVOICES_RECEIVED,
        ...arg
    }
}
export const hostedPageReceived = (arg: any = {}) => {
    return {
        type: HOSTED_PAGE_RECEIVED,
        ...arg
    }
}
export const customerReceived = (arg: any = {}) => {
    return {
        type: CUSTOMER_RECEIVED,
        ...arg
    }
}

export const itemsPricesReceived = (arg: any = {}) => {
    return {
        type: ITEMS_PRICES_RECEIVED,
        ...arg
    }
}

export const usageListReceived = (arg: any = {}) => {
    return {
        type: USAGE_LIST_RECEIVED,
        ...arg
    }
}
export const dailyUsageReceived = (arg: any = {}) => {
    return {
        type: MW_DAILY_USAGE_RECEIVED,
        ...arg
    }
}

export const billingCountsReceived = (arg: any = {}) => {
    return {
        type: BILLING_COUNTS_RECEIVED,
        ...arg
    }
}
export const monthlyUsageReceived = (arg: any = {}) => {
    return {
        type: MW_MONTHLY_USAGE_RECEIVED,
        ...arg
    }
}