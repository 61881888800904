import React, {Fragment, useState} from "react";
import { Span } from "../../modal/apm.modals";
import {convertTime, getRandomFlameColor} from "views/modules/apm/utils";
import "../_style.scss"
import Tooltip from "core/components/tooltip";
import * as color from "color";

type Props = {
    spanId: string;
    span: Span;
    minStartTimeNs: number;
    minPresentationalSpanDuration: number;
    maxEndTimeNs: number;
    width: number;
    onTraceClick: (arg0: Span) => void
};

const FlamegraphSpan = ({
    spanId,
    span,
    minStartTimeNs,
    minPresentationalSpanDuration,
    maxEndTimeNs,
    width,
    onTraceClick
}: Props) => {
    const {
        timestampNs: startTimeNs,
        durationNano,
        resource_attributes,
        hasError,
        tagMap
    } = span;
    const { isGhostSpan } = resource_attributes;

    const endTimeNs = startTimeNs + durationNano;
    const spanName = isGhostSpan ? "Missing Span" : span.name;
    const spanDuration = Math.max(endTimeNs - startTimeNs, minPresentationalSpanDuration);
    const totalDuration = maxEndTimeNs - minStartTimeNs;
    const spanWidth = (spanDuration / totalDuration) * width;
    const offset =
        ((startTimeNs - minStartTimeNs) / (maxEndTimeNs - minStartTimeNs)) * width;

    const [ bgColor, setBgColor ] = useState(getRandomFlameColor(tagMap["service.name"]))
    const percentage = ((durationNano / totalDuration) * 100).toFixed(2);

    return (
        <Fragment>
            <div
                className="flame"
                style={{
                    backgroundColor: bgColor,
                    width: `${Math.max(spanWidth, 1)}px`,
                    transform: `translate3d(${offset}px, 0, 0)`,
                }}
                data-tip data-for={spanId}
                onClick={() => onTraceClick(span)}
                onMouseOver={() => {
                    setBgColor(color(bgColor).darken(0.2))
                }}
                onMouseOut={() => {
                    setBgColor(getRandomFlameColor(tagMap["service.name"]))
                }}
            >
                <div className="flamegraph-span-text flex">
                    <div className="flex-left">
                        <div className="flamegraph-span-text-name">
                            {hasError &&
                                <svg stroke="#E65965" fill="#E65965" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                                </svg>
                            }
                            {spanName}
                        </div>
                    </div>
                    <div className="flamegraph-span-duration">
                        {convertTime(durationNano)}
                    </div>
                </div>
            </div>
            <Tooltip id={spanId} place="bottom" type="dark" effect="solid">
                <span className={"flame-tooltip"}>
                    <span>{spanName}</span>
                    <span className={"duration"}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="14" width="14" xmlns="http://www.w3.org/2000/svg">
                            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                            <path d="M686.7 638.6L544.1 535.5V288c0-4.4-3.6-8-8-8H488c-4.4 0-8 3.6-8 8v275.4c0 2.6 1.2 5 3.3 6.5l165.4 120.6c3.6 2.6 8.6 1.8 11.2-1.7l28.6-39c2.6-3.7 1.8-8.7-1.8-11.2z"></path>
                        </svg>
                        <span>
                            {convertTime(durationNano)}({percentage}% of total Time)
                        </span>
                    </span>
                </span>
            </Tooltip>
        </Fragment>
    );
};

export default FlamegraphSpan;
