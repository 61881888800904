import { APIAction } from "store/widgets/reducers";
import { DashboardSource } from "views/modules/builder/entities/builder.entities";

export interface DashboardAuthor {
  name: string;
}

export interface Dashboard {
  id: number;
  account_id: number;
  user_id: number;
  key: string;
  visibility: string;
  display_scope: string;
  description: string;
  label: string;
  meta_data: Record<string, unknown>;
  created_at: string;
  updated_at: string;
  favorite: boolean;
  project_id: number;
  user: DashboardAuthor;
}

export interface DashboardListQueryParams {
  search?: string;
  limit?: number;
  offset?: number;
  sort?: string;
  filterBy: string | null;
}

export interface DashboardListResp extends APIAction {
  total?: number;
  offset?: number;
  limit?: number;
  dashboards?: Dashboard[];
}

export interface DashboardResp extends APIAction {
  dashboard?: Dashboard;
}

export interface DashboardFavResp extends APIAction {
  id: number;
  favorite?: boolean;
}

export interface ImportDashboardResp extends APIAction {
  importedDashboard?: Dashboard;
}

export enum DashboardOperationType {
  Create = "Create",
  Update = "Update",
  Clone = "Clone",
  Delete = "Delete",
}

export interface DashboardOperationPayload {
  label: string;
  key: string;
  description: string;
  visibility: string;
  id?: number;
}

export interface DashboardOperationResp extends APIAction {
  dashboard?: Dashboard;
}

export interface WidgetDataSourcesResp extends APIAction {
  dashboardKey?: string; // Will only be available in case of fetching widgets for the particular dashboard via /all-widgets?reportKey=<> API
  sources?: DashboardSource[];
}

export interface DashboardBuilderReducer {
  dashboardListResp: DashboardListResp;
  dashboardResp: DashboardResp;
  dashboardFavoriteResp: DashboardFavResp;
  widgetDataSourcesResp: WidgetDataSourcesResp;
  dataSourceWidgetsResp: APIAction;
}
