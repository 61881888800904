import {createReducer} from "core/utils/create_reducer";
import {ROLE_DETAIL_RECEIVED, ROLE_RECEIVED} from "./constant";
import {combineReducers} from "redux";


const list = createReducer({}, {
    [ROLE_RECEIVED]: (state, opts) => {
        if (Object.keys(opts.items).length) {
            return opts.items;
        }
        return {...state};
    }
})

const detail = createReducer([], {
    [ROLE_DETAIL_RECEIVED]: (state, opts) => {
        if (Object.keys(opts.data || {}).length) {
            return opts.data;
        }
        return {...state};
    }
})


export default combineReducers({
    list,
    detail,
})