import * as React from "react";
import "./_style.scss";
interface Props {
  children: React.ReactNode;
}
const InstallationSteps = (props: Props) => {
  const { children } = props;
  return (
    <>
      <div className="installation-steps-wrapper">{children}</div>
    </>
  );
};
export default InstallationSteps;
