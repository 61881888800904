import * as React from 'react';

import * as ReactDom from "react-dom";
interface Props {
    className ?: string,
}
export default class RootDiv extends React.Component<Props> {
    container?: any;
    componentDidMount(){
        this.container = document.createElement('div');
        document.body.appendChild(this.container);
        this.renderRootContent(this.props);
    }
    componentWillReceiveProps(newProps: any){
        this.renderRootContent(newProps);
    }
    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        this.renderRootContent(this.props);
    }

    componentWillUnmount(){
        if ( ! this.container ) {
            return;
        }
        ReactDom.unmountComponentAtNode(this.container);
        document.body.removeChild(this.container);
    }

    renderRootContent(props: any){

        let _content: any;

        if ( this.props && ( Object.keys( this.props ).length > 1 || ! this.props.children ) ) {
            _content = <div { ...this.props }>{ this.props.children }</div>;
        } else {
            _content = this.props.children;

        }
        ReactDom.render( _content, this.container );


    }
    render() {

        return null;

    }
}
