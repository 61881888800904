import {
  Incident,
  IncidentHistory,
  PublicUptimeMetricsResponse,
} from "views/modules/status-page/incident/model";
import {
  ChartAndServiceResponse,
  IncidentWithHistory,
  Service,
  StatusPageSettings,
  Subscriber,
} from "views/modules/status-page/page_list/model";
import {
  CHART_AND_SERVICE_LIST_RECEIVED,
  CURRENT_STATUS_OF_SYNTHETIC_RECEIVED,
  INCIDENT_HISTORY_RECEIVED,
  INCIDENT_LIST_RECEIVED,
  INCIDENT_SERVICE_HISTORY_RECEIVED,
  INCIDENT_SINGLE_RECEIVED,
  PAGE_LIST_RECEIVED,
  PUBLIC_INCIDENT_HISTORY_RECEIVED,
  PUBLIC_VIEW_STATUS_PAGE_RECEIVED,
  SERVICE_LIST_RECEIVED,
  SERVICE_SIDE_LIST_RECEIVED,
  SINGLE_PAGE_LIST_RECEIVED,
  STATUS_PAGE_CREATE_REQUEST,
  STATUS_PAGE_PUBLISH,
  SUBSCRIBER_LIST_RECEIVED,
} from "./constant";

export const pageListReceived = (arg: StatusPageSettings[]) => {
  return {
    type: PAGE_LIST_RECEIVED,
    arg,
  };
};

export const singlePageListReceived = (arg: StatusPageSettings[]) => {
  return {
    type: SINGLE_PAGE_LIST_RECEIVED,
    arg,
  };
};

export const requestCreateStatusPage = (arg: StatusPageSettings) => {
  return {
    type: STATUS_PAGE_CREATE_REQUEST,
    ...arg,
  };
};
export const serviceListReceived = (arg: Service[]) => {
  return {
    type: SERVICE_LIST_RECEIVED,
    arg,
  };
};
export const serviceSideListReceived = (arg: Service[]) => {
  return {
    type: SERVICE_SIDE_LIST_RECEIVED,
    arg,
  };
};
export const subscriberListReceived = (arg: Subscriber[]) => {
  return {
    type: SUBSCRIBER_LIST_RECEIVED,
    arg,
  };
};
export const incidentListReceived = (arg: {
  incidents: Incident[];
  count: number;
}) => {
  return {
    type: INCIDENT_LIST_RECEIVED,
    arg,
  };
};
export const incidentHistoryReceived = (arg: IncidentHistory[]) => {
  return {
    type: INCIDENT_HISTORY_RECEIVED,
    arg,
  };
};

export const incidentSingleReceived = (arg: Incident[]) => {
  return {
    type: INCIDENT_SINGLE_RECEIVED,
    arg,
  };
};

export const incidentServiceHistoryReceived = (arg: Incident[]) => {
  return {
    type: INCIDENT_SERVICE_HISTORY_RECEIVED,
    arg,
  };
};

export const currentStatusOfSyntheticReceived = (
  arg: PublicUptimeMetricsResponse[]
) => {
  return {
    type: CURRENT_STATUS_OF_SYNTHETIC_RECEIVED,
    arg,
  };
};

export const publicViewStatusPageReceived = (arg: StatusPageSettings) => {
  return {
    type: PUBLIC_VIEW_STATUS_PAGE_RECEIVED,
    arg,
  };
};

export const publicIncidentHistoryReceived = (arg: IncidentWithHistory) => {
  return {
    type: PUBLIC_INCIDENT_HISTORY_RECEIVED,
    arg,
  };
};

export const chartAndServiceListReceived = (arg: ChartAndServiceResponse) => {
  return {
    type: CHART_AND_SERVICE_LIST_RECEIVED,
    arg,
  };
};

export const setPublishState = (index: boolean) => {
  return {
    type: STATUS_PAGE_PUBLISH,
    value: index,
  };
};
