import React from "react";
import { SectionItems } from "./entity";
import {
  DotNetIcon,
  GoLangIcon,
  JavaIcon,
  NextJSIcon,
  NodeJsIcon,
  PhpIcon,
  PythonIcon,
  RubyIcon,
  ScalaIcon,
  VercelIcon,
} from "core/components/v2/svg/language-icons";
import CloudflareIntegration from "./pages/apm/cloudflare";
import DenoIntegration from "./pages/apm/deno";
import DotnetIntegration from "./pages/apm/dotnet";
import GoIntegration from "./pages/apm/go";
import JavaIntegration from "./pages/apm/java";
import NextJsIntegration from "./pages/apm/nextjs";
import NodeJsIntegration from "./pages/apm/nodejs";
import PhpIntegration from "./pages/apm/php";
import PythonIntegration from "./pages/apm/python";
import RubyIntegration from "./pages/apm/ruby";
import ScalaIntegration from "./pages/apm/scala";
import VercelIntegration from "./pages/apm/vercel";
import {
  CloudflareIcon,
  DenoIcon,
} from "core/components/v2/svg/integrations-icons";
import { getThemeState } from "core/utils";

export const apmItems: () => SectionItems[] = () => {
  const themeMode = getThemeState();
  const borderOpacity = themeMode === "dark" ? 0.4 : 0.1;
  return [
    {
      label: "Go",
      link: "go",
      prefixicon: <GoLangIcon />,
      pageProps: {
        title: "Go",
        bgColor: `rgba(0, 172, 215, ${borderOpacity})`,
        component: <GoIntegration serverlessSupport={true} />,
        dashboardLink: "/dashboards",
        docLink: "https://docs.middleware.io/apm-configuration/go",
      },
    },
    {
      label: "Java",
      link: "java",
      prefixicon: <JavaIcon />,
      pageProps: {
        title: "Java",
        bgColor: `rgba(245, 130, 25, ${borderOpacity})`,
        component: <JavaIntegration serverlessSupport={false} />,
        dashboardLink: "/dashboards",
        docLink: "https://docs.middleware.io/apm-configuration/java",
      },
    },
    {
      label: "Node.JS",
      link: "node-js",
      prefixicon: <NodeJsIcon />,
      pageProps: {
        title: "Node.JS",
        bgColor: `rgba(61, 169, 46, ${borderOpacity})`,
        component: <NodeJsIntegration serverlessSupport={true} />,
        dashboardLink: "/dashboards",
        docLink: "https://docs.middleware.io/apm-configuration/node-js",
      },
    },
    {
      label: "Next.JS",
      link: "next-js",
      prefixicon: <NextJSIcon />,
      pageProps: {
        title: "Next.JS",
        bgColor: `${themeMode === "dark" ? "rgba(173, 173, 173, 0.5)" : "rgba(0, 0, 0, 0.1)"}`,
        component: <NextJsIntegration serverlessSupport={true} />,
        dashboardLink: "/dashboards",
        docLink: "https://docs.middleware.io/apm-configuration/next-js",
      },
    },
    {
      label: "PHP",
      link: "php",
      prefixicon: <PhpIcon />,
      pageProps: {
        title: "PHP",
        bgColor: `rgba(137, 147, 190, ${borderOpacity})`,
        component: <PhpIntegration serverlessSupport={false} />,
        dashboardLink: "/dashboards",
        docLink: "https://docs.middleware.io/apm-configuration/php",
      },
    },
    {
      label: "Python",
      link: "python",
      prefixicon: <PythonIcon />,
      pageProps: {
        title: "Python",
        bgColor: `rgba(90, 159, 212, ${borderOpacity})`,
        component: <PythonIntegration serverlessSupport={true} />,
        dashboardLink: "/dashboards",
        docLink: "https://docs.middleware.io/apm-configuration/python",
      },
    },
    {
      label: "Ruby",
      link: "ruby",
      prefixicon: <RubyIcon />,
      pageProps: {
        title: "Ruby",
        bgColor: `rgba(177, 18, 5, ${borderOpacity})`,
        component: <RubyIntegration serverlessSupport={false} />,
        dashboardLink: "/dashboards",
        docLink: "https://docs.middleware.io/apm-configuration/ruby",
      },
    },
    {
      label: ".NET",
      link: "dot-net",
      prefixicon: <DotNetIcon />,
      pageProps: {
        title: ".NET",
        bgColor: `rgba(92, 45, 145, ${borderOpacity})`,
        component: <DotnetIntegration serverlessSupport={false} />,
        dashboardLink: "/dashboards",
        docLink: "https://docs.middleware.io/apm-configuration/dotnet",
      },
    },
    {
      label: "Deno",
      link: "deno",
      prefixicon: <DenoIcon />,
      pageProps: {
        title: "Deno",
        bgColor: `${themeMode === "dark" ? "rgba(173, 173, 173, 0.5)" : "rgba(0, 0, 0, 0.1)"}`,
        component: <DenoIntegration serverlessSupport={true} />,
        dashboardLink: "/dashboards",
        docLink: "https://docs.middleware.io/apm-configuration/deno",
      },
    },
    {
      label: "Cloudflare",
      link: "cloudflare",
      prefixicon: <CloudflareIcon />,
      pageProps: {
        title: "Cloudflare",
        bgColor: `rgba(244, 129, 32, ${borderOpacity})`,
        component: <CloudflareIntegration />,
        dashboardLink: "/dashboards",
        docLink: "https://docs.middleware.io/apm-configuration/cloudflare",
      },
    },
    {
      label: "Vercel",
      link: "vercel",
      prefixicon: <VercelIcon />,
      pageProps: {
        title: "Vercel",
        bgColor: `${themeMode === "dark" ? "rgba(173, 173, 173, 0.5)" : "rgba(0, 0, 0, 0.1)"}`,
        component: <VercelIntegration serverlessSupport={true} />,
        // dashboardLink: "/dashboards",
        docLink:
          "https://docs.middleware.io/apm-configuration/vercel-integration",
        // footerComponent: <VercelFooter />,
      },
    },
    {
      label: "Scala",
      link: "scala",
      prefixicon: <ScalaIcon />,
      pageProps: {
        title: "Scala",
        bgColor: `${themeMode === "dark" ? "rgba(222, 52, 35, 0.5" : "rgba(56, 13, 9, 0.1)"}`,
        component: <ScalaIntegration serverlessSupport={false} />,
        dashboardLink: "/dashboards",
        docLink: "https://docs.middleware.io/apm-configuration/scala",
      },
    },
  ];
};
