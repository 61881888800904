import moment from "moment";
import {
  generateHashAndMetricColor
} from "views/modules/helper";

export function getMinutesDifference(startTimestamp: any, endTimestamp: any) {
  const differenceInMs = endTimestamp - startTimestamp;
  const differenceInMinutes = differenceInMs / 60 / 1000;

  return differenceInMinutes.toFixed(0);
}

export const getRoundedInterval = (val: number) => {
  if (val >= 0 && val <= 5) {
    return 5;
  } else if (val > 5 && val <= 15) {
    return 15;
  } else if (val > 15 && val <= 30) {
    return 30;
  } else if (val > 30 && val <= 360) {
    return 60;
  } else if (val > 360 && val <= 720) {
    return 240;
  } else if (val > 720 && val <= 1440) {
    return 1200;
  }
  return 2400;
};

export function getInterval(val: string): number {
  switch (val) {
    case "5":
      return 5;
    case "15":
      return 10;
    case "30":
      return 30;
    case "60":
    case "180":
      return 60;
    case "360":
    case "720":
      return 240;
    case "1440":
      return 1200;
    case "10080":
      return 60 * 40;
    default:
      return 2400;
  }
}

export function getRandomColor() {
  const hue = Math.floor(Math.random() * 360); // Random hue value between 0 and 359
  const saturation = Math.floor(Math.random() * 50) + 50; // Random saturation value between 50 and 99
  const lightness = Math.floor(Math.random() * 30) + 35; // Random lightness value between 35 and 64
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`; // Construct the HSL color string
}

export function truncateText(text: string, length: number) {
  if (text.length <= length) {
    return text;
  }

  return text.substr(0, length) + "\u2026";
}

export function getDiffFromTime(t1: any, t2: any) {
  let diff: any = (t2 - t1) / 1000; // time difference in seconds
  const units = [
    { label: "year", seconds: 60 * 60 * 24 * 365 },
    { label: "month", seconds: 60 * 60 * 24 * 30 },
    { label: "day", seconds: 60 * 60 * 24 },
    { label: "hour", seconds: 60 * 60 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  let str = "";
  units.forEach((unit) => {
    const count = Math.floor(diff / unit.seconds);
    diff -= count * unit.seconds;
    if (count > 0) {
      str += ` ${count} ${unit.label}${count > 1 ? "s" : ""}`;
    }
  });

  return str.trim();
}

type SampleObject = Record<any, any>;

export function generateEmptyChartData(
  start_ts: number,
  end_ts: number,
  interval: number,
  sample: SampleObject
): SampleObject[] {
  const data: SampleObject[] = [];
  let current_ts = start_ts;

  while (current_ts <= end_ts) {
    let xAxisName: any = null;

    if (interval === 1800)
      xAxisName = new Date(current_ts).toLocaleTimeString();
    else xAxisName = new Date(current_ts).toLocaleTimeString();

    const obj = {
      name: xAxisName,
      tooltip: {
        timestamp: current_ts,
        interval,
      },
      ...sample,
    };
    data.push(obj);
    current_ts += interval * 1000; // Convert interval to milliseconds
  }

  return data;
}

export function convertTimestamp(timestamp: any) {
  const currentTimestamp = Date.now();
  const timeDiff = currentTimestamp - timestamp;
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
  }
}

export const convertTime = (duration: number) => {
  const nanoseconds = Math.floor(duration);
  const microseconds = Math.floor(nanoseconds / 1000);
  const milliseconds = Math.floor(nanoseconds / 1000000);
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const dayString = days > 0 ? `${days}d` : "";
  const hourString = hours > 0 ? `${hours % 24}h` : "";
  const minuteString = minutes > 0 ? `${minutes % 60}m` : "";
  const secondString = seconds > 0 ? `${seconds % 60}s` : "";
  const millisecondString = milliseconds > 0 ? `${milliseconds % 1000}ms` : "";
  const microsecondString = microseconds > 0 ? `${microseconds % 1000}µs` : "";

  if (dayString) {
    return `${dayString} ${hourString} ${minuteString} ${secondString}`;
  } else if (hourString) {
    return `${hourString} ${minuteString} ${secondString}`;
  } else if (minuteString) {
    return `${minuteString} ${secondString}`;
  } else if (secondString) {
    return `${secondString} ${millisecondString}`;
  } else if (millisecondString) {
    return millisecondString;
  } else {
    return microsecondString;
  }
};

export const convertMilliseconds = (milliseconds: number) => {
  const seconds = milliseconds / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;

  if (milliseconds < 1000) {
    return milliseconds.toFixed(2) + "ms";
  } else if (seconds < 60) {
    return seconds.toFixed(2) + "s";
  } else if (minutes < 60) {
    return minutes.toFixed(2) + "min";
  } else if (hours < 24) {
    return hours.toFixed(2) + "hr";
  } else {
    return days.toFixed(2) + "days";
  }
};

export function getCountStats(
  data: any,
  type: any
): { avg: any; max: any; min: any } {
  const entries = data[type];

  let minCount = Number.MAX_SAFE_INTEGER;
  let maxCount = Number.MIN_SAFE_INTEGER;
  let total = 0;
  let nonZeroCount = false;

  if (!entries || entries.length <= 0) return { avg: 0, max: 0, min: 0 };

  for (const entry of entries) {
    const countData = parseFloat(entry[1]);

    if (countData > 0) {
      minCount = Math.min(minCount, countData);
      nonZeroCount = true; // Set the flag to true
    }
    maxCount = Math.max(maxCount, countData);
    total += countData;
  }

  if (!nonZeroCount) {
    minCount = 0;
  }

  const avgCount = parseFloat((total / entries.length).toFixed(2)) || 0;

  return {
    min: minCount.toFixed(2),
    max: maxCount.toFixed(2),
    avg: avgCount.toFixed(2),
  };
}

export function formatLatency(latencyInNs: number): string {
  if (latencyInNs >= 1000000000) {
    const s = latencyInNs / 1000000000;
    return `${s.toFixed(2)}s`;
  } else if (latencyInNs >= 1000000) {
    const ms = latencyInNs / 1000000;
    return `${ms.toFixed(2)}ms`;
  } else if (latencyInNs >= 1000) {
    const us = latencyInNs / 1000;
    return `${us.toFixed(2)}μs`;
  } else {
    return `${latencyInNs.toFixed(0)}ns`;
  }
}

export function convertGraphData(inputData: any) {
  const outputData: any = {
    timestamps: {},
    chartGroups: {},
  };

  for (const key in inputData) {
    if (inputData.hasOwnProperty(key)) {
      outputData.chartGroups[key] = {
        [key]: [],
      };

      for (const entry of inputData[key]) {
        const timestamp = entry[0];
        const value = entry[1];
        const config = entry[2];

        outputData.timestamps[timestamp / 1000000] = true;
        outputData.chartGroups[key][key].push({
          value: value,
          timestamp: timestamp / 1000000,
          config: {
            // type: config.title === `${key} Count` ? undefined : "percent",
            // config: {}
          },
        });
      }
    }
  }

  return outputData;
}
export function organizeTags(tagList: any) {
  const result: any = {};

  const itemsToRemove: string[] = [
    "busy_ns",
    "dbName",
    "dbOperation",
    "dbSystem",
    "decode_time_microseconds",
    "downstream_cluster",
    "element.xpath",
    "email",
    "env",
    "environment",
    "event.type",
    "externalHttpMethod",
    "externalHttpUrl",
    "error",
    "gRPCCode",
    "gRPCMethod",
    "guid:x-request-id",
    "heap.free",
    "httpCode",
    "httpHost",
    "httpMethod",
    "httpRoute",
    "httpUrl",
    "idle_ns",
    "idle_time_microseconds",
    "jinja2.template_name",
    "jinja2.template_path",
    "parent",
    "msgOperation",
    "msgSystem",
    "mw.account_key",
    "mw.service.name.derived",
    "mw_agent",
    "navigator.userAgent",
    "peerService",
    "process.pid",
    "query_time_microseconds",
    "queue_time_microseconds",
    "recording.available",
    "request_size",
    "resource",
    "responseStatusCode",
    "response_flags",
    "response_size",
    "rpcMethod",
    "rpcService",
    "rpcSystem",
    "service.instance.id",
    "session.id",
    "session.previous_id",
    "sinatra.template_name",
    "some",
    "source",
    "start.type",
    "statusCode",
    "target.type",
    "target_element",
    "target_xpath",
    "thread.id",
    "thread.name",
    "total_time_microseconds",
    "type",
    "upstream_cluster",
    "upstream_cluster.name",
    "user_type",
    "workerId",
    "workflow.name",
  ];

  for (const item in tagList) {
    if (itemsToRemove.includes(item)) {
      continue;
    }

    const splitResult = item.split(".");
    if (!result[splitResult[0]]) {
      result[splitResult[0]] = {};
    }

    result[splitResult[0]][item] = {
      is_loading: false,
      toggle: false,
      data: [],
    };
  }

  return result;
}

export function formatTimeDifference(timestampNs: number) {
  const timestampInMilliseconds = timestampNs / 1e6;

  const currentTimeInMilliseconds = moment().valueOf();

  const timeDifference = currentTimeInMilliseconds - timestampInMilliseconds;

  const minuteThreshold = 60 * 1000;
  const hourThreshold = 60 * minuteThreshold;
  const dayThreshold = 24 * hourThreshold;

  if (timeDifference < minuteThreshold) {
    return moment.duration(timeDifference, "milliseconds").humanize() + " ago";
  } else if (timeDifference < hourThreshold) {
    return moment.duration(timeDifference, "milliseconds").humanize() + " ago";
  } else if (timeDifference < dayThreshold) {
    return moment.duration(timeDifference, "milliseconds").humanize() + " ago";
  } else {
    return moment(timestampInMilliseconds).format("MMMM D, YYYY");
  }
}

export const getRandomFlameColor = (service_name: string) => {
  return generateHashAndMetricColor(service_name || "").color;
};

export interface SpanData {
  durationNano: number;
  gRPCCode: string;
  gRPCMethod: string;
  "http.status_code": string;
  httpMethod: string;
  httpUrl: string;
  name: string;
  parentSpanID: string;
  "service.name": string;
  spanID: string;
  span_count: number;
  timestampNs: number;
  traceID: string;
  services_latency: Record<string, number>;
  services_percent: Record<string, number>;
  services_total_count: number;
}