import { DEFAULT_CHART_HEIGHT } from "core/components/v2/charts/models";
import { granularityInterval } from "core/utils/chart_helper";
import React, { useContext } from "react";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import { ResourceKeyEnum, _scopes } from "views/modules/builder/common/utils";
import { FilterDataType } from "views/modules/builder/entities/builder.entities";
import BuilderView from "views/modules/builder/views";
import {
  InfraDialogTabType,
  K8sDialogConfig,
  MetricChartTabType,
} from "./dialog.config";
import "../_style.scss";

interface InfraMetricsProps {
  resourceType: ResourceKeyEnum;
  filters?: FilterDataType[];
}
const InfraMetrics = (props: InfraMetricsProps) => {
  const { resourceType, filters } = props;
  const routeData = useContext(MwRouteContext);
  const dialogConfig = K8sDialogConfig[resourceType];
  const scopeSchema = _scopes[resourceType];
  if (!dialogConfig?.tabs) return <div>No Dialog Data Found</div>;
  const tabContent = dialogConfig?.tabs.find(
    (t) => t.tabType === InfraDialogTabType.METRICS
  );
  if (!tabContent) return <div>No Tab Data Found</div>;
  const content = tabContent.content as MetricChartTabType;
  return (
    <React.Fragment>
      <div className="card_dialog__content">
        {content.map((c, i: number) => {
          return (
            <div key={i} className={"charts-container"}>
              {c?.items?.map((item, i: number) => {
                const g = granularityInterval(
                  new Date(routeData.params.dateRange.fromTs).valueOf(),
                  new Date(routeData.params.dateRange.toTs).valueOf()
                );
                if (!item?.resource?.columns?.length)
                  return <React.Fragment key={i} />;
                return (
                  <div key={i} className={`charts__cols grid-item-${i}`}>
                    <BuilderView
                      boxTitle={item.label}
                      allowAllLegend
                      builderViewOptions={{
                        displayScope: "",
                        resource: {
                          resourceType: resourceType,
                          name: `metric__${i}--${item?.resource?.columns.join("__")}`,
                          widgetAppId: 1,
                          granularity: {
                            // value: granularity || 45,
                            value: g,
                          },
                          with: [
                            {
                              key: "SELECT_DATA_BY",
                              value: [scopeSchema?.primaryColumnKey],
                              is_arg: true,
                            },
                          ],
                          ...item?.resource,
                        },
                      }}
                      filters={filters ? filters : []}
                      nestedProps={{
                        // colorGroups: {
                        //     [selectedData.primaryColumnValue || ""]: item?.color || undefined
                        // },
                        titleInfo: item.label,
                        yAxisTitle: item.yAxisLabel,
                        showLegend: false,
                        onSelectionChange: (data: any) => {
                          routeData.actions.handleTimeRange(
                            data.startPoint.timestamp,
                            data.endPoint.timestamp
                          );
                        },
                        chartHeight: DEFAULT_CHART_HEIGHT.HEIGHT_200,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};
export default InfraMetrics;
