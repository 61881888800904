import * as React from "react";
import {RoutesItemPattern, RoutesPatterns} from "core/utils/route_schema";

const BackoffRoutes: RoutesPatterns = {
    moduleName: "backoff",
    slug: '/',
    items: [
        {
            pattern: [
                "/",
            ],
            component: React.lazy(() => import("views/backoff/modules/users.index"))
            // component: React.lazy(() => import("views/backoff/index"))
        },
        {
            pattern: [
                "/login",
            ],
            component: React.lazy(() => import("views/backoff/login"))
        },
        {
            pattern: [
                "/users",
            ],
            breadcrumbTitle: "User list",
            component: React.lazy(() => import("views/backoff/modules/users.index"))
        },
        {
            pattern: [
                "/users/:userId",
            ],
            breadcrumbTitle: "User Info",
            component: React.lazy(() => import("views/backoff/modules/users.info"))
        },
        {
            pattern: [
                "/email",
            ],
            breadcrumbTitle: "Email Details",
            component: React.lazy(() => import("views/backoff/modules/email.index"))
        },
        {
            pattern: [
                "/email/:id",
            ],
            breadcrumbTitle: "User Info",
            component: React.lazy(() => import("views/backoff/modules/email.new"))
        },
        {
            pattern: [
                "/contacts",
            ],
            breadcrumbTitle: "Add Contact list",
            component: React.lazy(() => import("views/backoff/modules/add-contact-list"))
        },
        {
            pattern: [
                "/builder",
            ],
            breadcrumbTitle: "Builder Settings",
            component: React.lazy(() => import("views/backoff/modules/builder-setting/index"))
        },

    ] as Array<RoutesItemPattern>
}
export default BackoffRoutes

