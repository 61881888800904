import { getTenantUrl } from "core/application/utils";
import FormCheckbox from "core/components/v2/form/form-checkbox";
import TabSelection from "core/components/v2/tab-selection";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import InstallationCard from "../../components/card";
import Command from "../../components/code-block/command";
import InstallationSteps from "../../components/steps";
import StepItem from "../../components/steps/step-item";
import KubernetesInstallationSnippet from "../common/kubernetes-snippet";
const KubernetesIntegration = () => {
  const [tab, setTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Kubernetes Version",
          value: "Kubernetes v1.21 or above",
        },
        {
          label: "Kubernetes Access",
          value: (
            <div>
              Kubernetes cluster and client (i.e.{" "}
              <a
                href={"https://kubernetes.io/docs/reference/kubectl/kubectl/"}
                target={"_blank"}
                rel="noreferrer"
              >
                kubectl
              </a>
              )
            </div>
          ),
        },
        {
          label: "CLI Tools",
          value: (
            <div>
              Install{" "}
              <a
                href={"https://www.gnu.org/software/bash/"}
                target={"_blank"}
                rel="noreferrer"
              >
                bash
              </a>
              ,{" "}
              <a href={"https://curl.se/"} target={"_blank"} rel="noreferrer">
                curl
              </a>{" "}
              and{" "}
              <a
                href={"https://www.gnu.org/software/wget/"}
                target={"_blank"}
                rel="noreferrer"
              >
                wget
              </a>
            </div>
          ),
        },
      ],
    },
  ];
  const command = `MW_API_KEY=${context.user?.account?.agent_config?.token} MW_TARGET=${capturePath}:443 bash -c "$(curl -L https://install.middleware.io/scripts/mw-kube-agent-install.sh)"`;
  const [checkboxState1, setCheckboxState1] = useState(true);
  const [checkboxState2, setCheckboxState2] = useState(false);

  const certificate = checkboxState1 ? "MW_INSTALL_CERT_MANAGER=true " : "";
  const sendEvent = useMixPanel();
  return (
    <>
      <InstallationSteps>
        <StepItem stepNumber={1} title={"Prerequisites"}>
          <InstallationCard items={cardItems} />
        </StepItem>
        <StepItem
          stepNumber={2}
          title={"Install Kubernetes Infrastructure Monitor"}
        >
          <KubernetesInstallationSnippet />
        </StepItem>
        <StepItem stepNumber={3} title={"Auto-Instrumentation (Optional)"}>
          <TabSelection
            tabs={[
              {
                label: "Bash",
                value: 1,
              },
              {
                label: "Helm Chart",
                value: 2,
              },
            ]}
            selectedTab={1}
            onTabChange={(tabObject) => {
              setTab(tabObject.value);
            }}
          />
          <div className="tab-content-area">
            {tab === 1 && (
              <>
                Auto-Instrumentation requires{" "}
                <a
                  href="https://cert-manager.io/docs/installation/"
                  target="_blank"
                  rel="noreferrer"
                >
                  cert-manager
                </a>{" "}
                to generate required certificates.
                <br />
                <br />
                <FormCheckbox
                  id="checkbox1"
                  checked={checkboxState1}
                  onChange={(checked) => {
                    console.log(checked, "Checked..");
                    setCheckboxState1(true);
                    setCheckboxState2(false);
                  }}
                  label="Okay, Add the cert-manager for me"
                />
                <br />
                <FormCheckbox
                  id="checkbox2"
                  checked={checkboxState2}
                  onChange={(checked) => {
                    console.log(checked, "Checked..");
                    setCheckboxState1(false);
                    setCheckboxState2(true);
                  }}
                  label="I already have cert-manager installed in my Kubernetes Cluster"
                />
                <br />
                <Command
                  onCopyClick={() => {
                    sendEvent("click on copy command", {
                      agent_type: "Kubernetes",
                      command_type: "Auto-Instrumentation (Optional)",
                    });
                  }}
                  command={
                    `${certificate}` +
                    `MW_API_KEY=${context.user?.account?.agent_config?.token} bash -c "$(curl -L https://install.middleware.io/scripts/mw-kube-auto-instrumentation-install.sh)"
`
                  }
                  className="margin-1"
                />
              </>
            )}
            {tab === 2 && (
              <>
                Auto-Instrumentation requires{" "}
                <a
                  href="https://cert-manager.io/docs/installation/"
                  target="_blank"
                  rel="noreferrer"
                >
                  cert-manager
                </a>{" "}
                to generate required certificates.
                <br />
                <br />
                <FormCheckbox
                  id="checkbox1"
                  checked={checkboxState1}
                  onChange={(checked) => {
                    console.log(checked, "Checked..");
                    setCheckboxState1(true);
                    setCheckboxState2(false);
                  }}
                  label="Okay, Add the cert-manager for me"
                />
                <br />
                <FormCheckbox
                  id="checkbox2"
                  checked={checkboxState2}
                  onChange={(checked) => {
                    console.log(checked, "Checked..");
                    setCheckboxState1(false);
                    setCheckboxState2(true);
                  }}
                  label="I already have cert-manager installed in my Kubernetes Cluster"
                />
                <br />
                {checkboxState1 && (
                  <>
                    <Command
                      command={`helm repo add jetstack https://charts.jetstack.io --force-update`}
                      className="margin-1"
                      onCopyClick={() => {
                        sendEvent("click on copy command", {
                          agent_type: "Kubernetes",
                          command_type: "Auto-Instrumentation (Optional)",
                        });
                      }}
                    />
                    <Command
                      command={`helm install cert-manager jetstack/cert-manager --namespace cert-manager --create-namespace --version v1.14.5 --set installCRDs=true`}
                      className="margin-1"
                      onCopyClick={() => {
                        sendEvent("click on copy command", {
                          agent_type: "Kubernetes",
                          command_type: "Auto-Instrumentation (Optional)",
                        });
                      }}
                    />
                  </>
                )}
                <Command
                  command={`helm repo add open-telemetry https://open-telemetry.github.io/opentelemetry-helm-charts`}
                  className="margin-1"
                  onCopyClick={() => {
                    sendEvent("click on copy command", {
                      agent_type: "Kubernetes",
                      command_type: "Auto-Instrumentation (Optional)",
                    });
                  }}
                />
                <Command
                  command={`helm install opentelemetry-operator-controller-manager  open-telemetry/opentelemetry-operator --namespace opentelemetry-operator-system --create-namespace --values https://install.middleware.io/manifests/autoinstrumentation/helm-values.yaml`}
                  className="margin-1"
                  onCopyClick={() => {
                    sendEvent("click on copy command", {
                      agent_type: "Kubernetes",
                      command_type: "Auto-Instrumentation (Optional)",
                    });
                  }}
                />
              </>
            )}
          </div>
        </StepItem>
      </InstallationSteps>
    </>
  );
};
export default KubernetesIntegration;
