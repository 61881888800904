import Button from "core/components/v2/button";
import DropdownSelection from "core/components/v2/dropdown-selection";
import FormCheckbox from "core/components/v2/form/form-checkbox";
import FormTextInput from "core/components/v2/form/form-text-input";
import SectionExpand from "core/components/v2/section-expand";
import { StatusBadgeType } from "core/components/v2/status-badge";
import { WidgetCopyIcon } from "core/components/v2/svg/chart-icons";
import { DeleteIcon, PlusIcon } from "core/components/v2/svg/icons";
import TabSelection from "core/components/v2/tab-selection";
import { debounceHandler } from "core/utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "store/toast-alerts/actions";
import { getBuilderMetrics } from "store/widgets/selectors";
import useDidMountEffect from "views/layouts/app/routes/useDidMountEffect";
import {
  getAvailableAggregations,
  getColumnName,
  getMetricDetails,
  isMetricAggregation,
} from "../common/utils";
import CofirmationDialog from "../core/components/dialog/confirmation-dialog";
import {
  AggMethod,
  ChartType,
  KpiType,
  KpiTypeOptions,
  ListType,
  MetricItem,
  MetricTypeEnum,
  Query,
  QueryValueType,
  SelectOneMetric,
  defaultQuery,
  getKpiName,
  getWidgetType,
  toKpiType,
} from "../entities/builder.entities";
import { WithExpr } from "../entities/extra.entities";
import {
  TPL_TYPE_DEFAULT,
  defaultTplOptions,
  getTplByType,
} from "../entities/tpl.entities";
import "./_stylev2.scss";
import Billing from "./billing/billing";
import { QuerySelectionProps } from "./entities";
import Filter from "./filter/filterv2";
import GroupBy from "./group-by/group-by";
import Metric from "./metric/metricv2";
import VisualFormattingRules from "./visual-formatting-rule";

const QuerySelectionV2 = ({
  query,
  chartType,
  onQueryChange,
  queryIndex,
  onQueryDelete,
  builderConfig,
  onQueryCopy,
  isPartOfRuleForm,
}: QuerySelectionProps) => {
  const dispatch = useDispatch();
  const builderMetrics = useSelector(getBuilderMetrics);

  const kpiOptions = useMemo(() => {
    const options = KpiTypeOptions.map((option) => {
      return { label: option.title, value: option.value.toString() };
    });
    if (isPartOfRuleForm) options.push({ label: "Billing", value: "billing" });

    return options;
  }, [KpiTypeOptions, isPartOfRuleForm]);

  const currentMetricDetails = useMemo(
    () =>
      getMetricDetails(
        query.columns.length > 0 ? (query.columns[0] as string) : ""
      ),
    [query.columns]
  );

  const queryType = useMemo(
    () =>
      query.source.name === "billing"
        ? query.source.name
        : toKpiType(query.source.name),
    [query.source.name]
  );
  const widgetType = useMemo(() => getWidgetType(chartType), [chartType]);
  const [currentMetricMetadata, setCurrentMetricMetadata] =
    useState<MetricItem>();

  const functionOptions = useMemo(
    () =>
      getAvailableAggregations(
        currentMetricMetadata?.type || MetricTypeEnum.MetricNameKey
      ).map((o) => ({
        label: o.title,
        value: o.value,
      })),
    [currentMetricMetadata?.type]
  );
  const aggregatedByOptions = useMemo(
    () =>
      getAvailableAggregations(
        currentMetricMetadata?.type || MetricTypeEnum.MetricNameKey
      ).map((o) => {
        return {
          label: `${o.title} of All Values`,
          value: o.value,
        };
      }),
    [currentMetricMetadata?.type]
  );

  const metricAggregationSelected = useMemo(
    () => isMetricAggregation(currentMetricDetails.aggregation),
    [currentMetricDetails.aggregation]
  );

  const [selectedKpiType, setSelectedKpiType] = useState<KpiType | "billing">(
    queryType
  );
  const [showKpiChangeConfirmDialog, setShowKpiChangeConfirmDialog] =
    useState(false);
  const [selectedFunction, setSelectedFunction] = useState<AggMethod>(
    currentMetricDetails.aggregation
  );
  const [alias, setAlias] = useState<string>(query.meta_data?.alias || "");
  const [ingestInterval, setIngestInterval] = useState<number>(
    query.granularity?.ingest_interval || 0
  );
  const [displayTimeAggregation, setDisplayTimeAggregation] = useState(false);

  useEffect(() => {
    // Don't update the details if the metric details is already up to date
    if (
      currentMetricDetails.metricName === currentMetricMetadata?.name &&
      query.source.name === currentMetricMetadata.resource
    )
      return;

    const metric = ((builderMetrics.metrics?.items as MetricItem[]) || []).find(
      (m) => {
        return (
          m.name === currentMetricDetails.metricName &&
          m.resource === query.source.name
        );
      }
    );

    setCurrentMetricMetadata(metric);
  }, [
    currentMetricDetails.metricName,
    query.source.name,
    builderMetrics.metrics?.items,
  ]);

  // useCallback is used to use the same function reference between re-renders.
  // It will help to prevent calling the debounceHandler multiple times due to
  // new function definition creation.
  const onAliasChange = useCallback(
    debounceHandler(
      -3,
      (changedAlias: string) => {
        if (changedAlias !== query.meta_data?.alias) {
          const copy = { ...query };
          copy.meta_data = copy.meta_data || {};
          copy.meta_data.alias = changedAlias;
          onQueryChange(copy);
        }
      },
      500
    ),
    [query]
  );
  useDidMountEffect(() => {
    onAliasChange(alias);
  }, [alias]);

  const onIngestIntervalChange = useCallback(
    debounceHandler(
      -1,
      (changeIngestInterval: number) => {
        if (changeIngestInterval !== query.granularity?.ingest_interval) {
          const copy = { ...query };
          copy.granularity = copy.granularity || {
            ingest_interval: 0,
            value: 0,
          };
          copy.granularity.ingest_interval = changeIngestInterval;
          onQueryChange(copy);
        }
      },
      500
    ),
    [query]
  );
  useDidMountEffect(() => {
    onIngestIntervalChange(ingestInterval);
  }, [ingestInterval]);

  const changeKpiType = (selectedKpiType: KpiType | "billing") => {
    const newQuery: Query = JSON.parse(JSON.stringify(defaultQuery)) as Query;

    if (selectedKpiType === "billing") {
      newQuery.source.name = "billing";
      newQuery.columns = [];
    } else {
      newQuery.source.name = getKpiName(selectedKpiType);
    }
    onQueryChange(newQuery);
  };

  const doAllQueriesHaveSameKpi = (kpiType: KpiType | "billing") => {
    if (!builderConfig || builderConfig.length === 1) return true;

    return builderConfig
      .map((query) => toKpiType(query.source.name))
      .every((val) => val === kpiType);
  };

  const handleKpiChange = (value: string | string[]) => {
    const newKpiVal: KpiType | "billing" = !value
      ? KpiType.KpiTypeNone
      : value === "billing"
        ? "billing"
        : (parseInt(value as string) as KpiType);
    if (selectedKpiType === newKpiVal) return;

    if (!doAllQueriesHaveSameKpi(newKpiVal)) {
      dispatch(
        showToast(
          "error",
          "Sources must be the same for all queries in a single chart"
        )
      );
      return;
    }

    setSelectedKpiType(newKpiVal);

    const queryHasValidColumns = query.columns.every(
      (column) =>
        getMetricDetails(column as string).metricName !== SelectOneMetric
    );
    if (query.columns.length > 0 && queryHasValidColumns) {
      setShowKpiChangeConfirmDialog(true);
      return;
    }

    changeKpiType(newKpiVal);
  };

  const renderKpiChangeConfirmDialog = () =>
    showKpiChangeConfirmDialog && (
      <CofirmationDialog
        isOpen
        onCancel={() => {
          setShowKpiChangeConfirmDialog(false);
          setSelectedKpiType(toKpiType(query.source.name));
        }}
        onConfirm={() => {
          setShowKpiChangeConfirmDialog(false);
          changeKpiType(selectedKpiType);
        }}
        titleMessage="Changing the data source will remove the existing query, click confirm to continue"
      />
    );

  const renderMetricSelection = () => (
    <div className="query-view-input-controls-container">
      <div className="metric-type-selection">
        <div className="dropdown-with-label-container">
          <label>Type*</label>
          <DropdownSelection
            placeholder="Select Type"
            notFoundContent="No options found"
            options={kpiOptions}
            isError={selectedKpiType === KpiType.KpiTypeNone}
            selectedValues={
              selectedKpiType !== KpiType.KpiTypeNone
                ? String(selectedKpiType)
                : undefined
            }
            onChange={handleKpiChange}
          />
        </div>
      </div>

      <div className="metric-name-selection">
        {selectedKpiType === "billing" && (
          <Billing query={query} onQueryUpdate={onQueryChange} />
        )}
        {selectedKpiType !== "billing" && (
          <Metric
            chartType={chartType}
            query={query}
            selectedKpiType={selectedKpiType}
            onMetricUpdate={(updatedQry: Query) => {
              onQueryChange(updatedQry);
            }}
          />
        )}
      </div>

      {queryType !== "billing" && (
        <div className="metric-actions-container">
          <div
            className="action-icon copy"
            onClick={(e) => {
              e.stopPropagation();
              onQueryCopy && onQueryCopy(query);
            }}
          >
            <WidgetCopyIcon />
          </div>

          <div
            className="action-icon delete"
            onClick={(e) => {
              e.stopPropagation();
              onQueryDelete && onQueryDelete(queryIndex);
            }}
          >
            <DeleteIcon color="var(--color-error)" />
          </div>
        </div>
      )}

      {renderKpiChangeConfirmDialog()}
    </div>
  );

  const renderFunctionSelection = () => (
    <div className="function-selection">
      <div className="dropdown-with-label-container">
        <label>Function</label>
        <DropdownSelection
          placeholder="Select Function"
          notFoundContent="No options found"
          options={functionOptions}
          defaultValue={currentMetricDetails.aggregation}
          selectedValues={selectedFunction || undefined}
          onChange={(value: string | string[]) => {
            const aggMethod = value as AggMethod;
            setSelectedFunction(aggMethod);

            currentMetricDetails.aggregation = aggMethod;
            const updatedMetricName = getColumnName(currentMetricDetails);

            const newQuery = { ...query };
            newQuery.columns[0] = updatedMetricName;

            onQueryChange(newQuery);
          }}
        />
      </div>
    </div>
  );

  const renderGroupBySelection = () => (
    <div className="group-by-selection">
      <div className="dropdown-with-label-container">
        <label>Group By</label>
        <GroupBy
          query={query}
          chartType={chartType}
          onConfigUpdate={(builderConfig: Query) => {
            onQueryChange(builderConfig);
          }}
        />
      </div>
    </div>
  );

  const renderAggregatedBySelection = () =>
    metricAggregationSelected && (
      <div className="aggregated-by-selection">
        <div className="dropdown-with-label-container">
          <label>Aggregated By</label>
          <DropdownSelection
            placeholder="Select Aggregated By"
            notFoundContent="No options found"
            options={aggregatedByOptions}
            defaultValue={currentMetricDetails.subAggregation}
            onChange={(value: string | string[]) => {
              currentMetricDetails.subAggregation = value as AggMethod;
              const updatedMetricName = getColumnName(currentMetricDetails);

              const newQuery = { ...query };
              newQuery.columns[0] = updatedMetricName;

              onQueryChange(newQuery);
            }}
          />
        </div>
      </div>
    );

  const filtersSelection = () => {
    return (
      <Filter
        chartType={chartType}
        query={query}
        onConfigUpdate={(builderConfig: Query) => {
          onQueryChange(builderConfig);
        }}
      />
    );
  };

  const renderAlias = () => (
    <>
      <label className="first-row">Alias Name</label>
      <div className="second-row">
        <FormTextInput id="alias" onChange={setAlias} value={alias} />
      </div>
    </>
  );

  const renderIngestInterval = () => {
    return displayTimeAggregation ? (
      <>
        <span className="second-row">over an input window of</span>
        <label className="first-row seconds-label">Seconds</label>
        <div className="second-row">
          <FormTextInput
            id="second"
            value={String(ingestInterval)}
            onChange={(value: string) => {
              const intVal = parseInt(value);
              if (!isNaN(intVal)) {
                setIngestInterval(intVal);
              }
            }}
            type="number"
          />
        </div>
        <div
          className="second-row action-icon delete"
          onClick={() => {
            setDisplayTimeAggregation(false);
            const copy = { ...query };
            copy.granularity = {
              ingest_interval: 15,
              value: 0,
            };
            onQueryChange(copy);
            setIngestInterval(15);
          }}
        >
          <DeleteIcon color="var(--color-error)" />
        </div>
      </>
    ) : (
      <div className="second-row">
        <Button
          onClick={() => {
            setDisplayTimeAggregation(true);
          }}
          outlined
          prefixicon={<PlusIcon color="var(--color-primary)" />}
        >
          Specify time aggregation
        </Button>
      </div>
    );
  };

  const renderDiffSelection = () => (
    <div className="display-comparison-container">
      <FormCheckbox
        label="Show Comparison with Previous Time Period"
        id={"show-diff-selection"}
        checked={typeof query?.meta_data?.comparisonRanges !== "undefined"}
        onChange={(checked: boolean) => {
          const copy = { ...query };
          if (checked) {
            if (!copy.meta_data) {
              copy.meta_data = {};
            }
            copy.meta_data.comparisonRanges = {
              badgeType: StatusBadgeType.Success,
            };
          } else if (copy.meta_data) {
            delete copy.meta_data.comparisonRanges;
          }
          onQueryChange(copy);
        }}
      />
      {query?.meta_data?.comparisonRanges && (
        <div className="tab-container">
          <span>Display comparison as</span>
          <TabSelection
            selectedTab={
              query?.meta_data?.comparisonRanges?.badgeType
                ? query?.meta_data?.comparisonRanges?.badgeType ===
                  StatusBadgeType.Success
                  ? 1
                  : 2
                : 1
            }
            tabs={[
              {
                label: "Success",
                value: 1,
              },
              {
                label: "Error",
                value: 2,
              },
            ]}
            onTabChange={(selectedTab) => {
              const copy = { ...query };
              const badge = selectedTab.value === 1 ? "success" : "error";
              const customRange = {
                badgeType: badge as StatusBadgeType,
              };
              if (!copy.meta_data) {
                copy.meta_data = {};
              }
              if (!copy.meta_data.comparisonRanges) {
                copy.meta_data.comparisonRanges = customRange;
              }
              copy.meta_data.comparisonRanges = customRange;
              onQueryChange(copy);
            }}
          />
        </div>
      )}
    </div>
  );

  const renderAdvancedOptions = () => (
    <div className="query-view-input-controls-container">
      <div className="advance-options-container">
        <div className="advance-options">
          <SectionExpand
            showBorder={false}
            shouldtitleStyleBold={false}
            borderlessBtn={true}
            title="Advanced Options"
            shouldtitleColorPrimary
          >
            <div className="advanced-options">
              <div className="input-container">
                {renderAlias()}
                {renderUnitFormat()}
                {widgetType !== ListType &&
                  widgetType !== QueryValueType &&
                  renderIngestInterval()}
              </div>
              {chartType === ChartType.QueryValueChart && (
                <VisualFormattingRules
                  query={query}
                  onQueryChange={onQueryChange}
                />
              )}
              {chartType === ChartType.QueryValueChart && renderDiffSelection()}
              {renderSelectDataByTopRes()}
            </div>
          </SectionExpand>
        </div>
      </div>
    </div>
  );

  const renderUnitFormat = () => {
    return (
      <>
        <label className="first-row">Unit Format</label>
        <div className="second-row">
          <DropdownSelection
            options={defaultTplOptions
              .filter((o) => o.externalVisible)
              .map((o) => ({
                label: o.label,
                value: o.type,
              }))}
            placeholder="Unit Format"
            defaultValue={query.meta_data?.tpl?.type || ""}
            onChange={(value: string | string[]) => {
              const tpl = getTplByType(value as string);
              if (!tpl) return;
              const copy = { ...query };
              if (!copy.meta_data) {
                copy.meta_data = {};
              }
              if (tpl.type === TPL_TYPE_DEFAULT) {
                delete copy.meta_data.tpl;
              } else {
                copy.meta_data.tpl = tpl;
              }
              onQueryChange(copy);
            }}
            notFoundContent="No options found"
          />
        </div>
      </>
    );
  };
  const renderSelectDataByTopRes = () => (
    <FormCheckbox
      id="select-data-by-top-resource"
      label="Select Data By Top Resource"
      onChange={(checked) => {
        const newQry = { ...query };

        const selectDataByTopResConfig: WithExpr = {
          key: "IS_SELECT_DATA_BY_TOP_RESOURCE",
          value: [],
          is_arg: true,
        };

        if (checked) {
          newQry.with = [...(newQry.with || []), selectDataByTopResConfig];
        } else {
          newQry.with = newQry.with?.filter(
            (w) => w.key !== "IS_SELECT_DATA_BY_TOP_RESOURCE"
          );
        }

        onQueryChange(newQry);
      }}
      checked={
        query.with !== undefined &&
        query.with?.findIndex(
          (w) => w.key === "IS_SELECT_DATA_BY_TOP_RESOURCE"
        ) > -1
      }
    />
  );

  return (
    <div className="query-view-container">
      <div className="query-view-index">
        {String.fromCharCode(queryIndex + "a".charCodeAt(0))}
      </div>
      {renderMetricSelection()}
      {queryType !== "billing" && (
        <>
          <div className="query-view-input-controls-container">
            {renderFunctionSelection()}
            {renderGroupBySelection()}
            {renderAggregatedBySelection()}
          </div>
          <div className="query-view-input-controls-container">
            {filtersSelection()}
          </div>
        </>
      )}
      {!isPartOfRuleForm && renderAdvancedOptions()}
    </div>
  );
};

export default QuerySelectionV2;
