import * as React from 'react';
import { requestBackToReal, requestSwitchSandbox } from "store/api";
import { useDispatch } from "react-redux";
import { showToast } from 'store/toast-alerts/actions';
import AppContext from 'core/components/wrapper/context';
import { defaultInstallationPath, defaultInstallationSlug } from 'views/modules/installation/common/sections';
import { Link } from 'react-router-dom';

export default function Sandbox() {

    const dispatch = useDispatch()
    const context = React.useContext(AppContext);

    const isAgentInstalled = context.user?.account?.is_agent_installed;
    const removeSandbox = () => {
        dispatch(requestBackToReal((_, data: { inflight: boolean, status: any, message: any, redirect: string }) => {
            if (!data.inflight) {
                if (!data.status) {
                    dispatch(showToast("warning", "Please try again"))
                } else if (data.redirect) {
                    window.location.href = data.redirect;
                }
            }
        }))
    }
    const onStartSandbox = () => {
        dispatch(requestSwitchSandbox((_, data: { inflight: boolean, status: any, message: any, redirect: string }) => {
            if (!data.inflight) {
                if (!data.status) {
                    dispatch(showToast("warning", "Please try again"))
                } else if (data.redirect) {
                    window.open(data.redirect);
                }
            }
        }))
    }
    return <div className={`component__sandbox ${location.pathname ===  "/dashboard" ? "remove_margin" : ""}`}>
            {/*{*/}

            {/*    !isAgentInstalled*/}
            {/*        ? location.pathname === defaultInstallationSlug ?*/}
            {/*            <h6 className={`component__sandbox_conatiner`}>*/}
            {/*                <span onClick={onStartSandbox} className={"view_sandbox"}>View sandbox account</span></h6> :*/}
            {/*            <span>*/}
            {/*                /!*Install MW agent from&nbsp;<Link to={defaultInstallationPath}>here</Link>.*!/*/}
            {/*            </span>*/}
            {/*        : context.user?.account?.uid === "sandbox" ?*/}
            {/*            <span onClick={removeSandbox} className={"view_sandbox"}>*/}
            {/*                Return to your Middleware account and see your infrastructure and application data*/}
            {/*            </span> : ""*/}
            {/*}*/}
    </div>
}