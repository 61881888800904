import * as React from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Modal from "react-modal";
import {omit} from "lodash-es";
import classNames from "classnames";
import {nice} from "d3";
import Button from "core/components/button";

interface DialogProps {
    isOpen: boolean
    onClose: (arg0: string) => void
    closeTimeoutMS?: number
    contentLabel?: string
    position: "center" | "right"
    shouldCloseOnEsc?: boolean
    shouldCloseOnOverlayClick?: boolean
    overlayClassName?: string
    contentClassName?: string
    htmlOpenClassName?: string
    disabledCloseAction?: boolean
    style?: object
    closeButtonPosition ?: "bottom" | "top"
    size?: "sx" | "sm" | "md" | "lg" | "default"
}

class Dialog extends React.Component<DialogProps, any> {
    constructor(props: any) {
        super(props);
    }

    _close = (action: any) => {
        const cls = document.getElementsByClassName("ReactModal__Content")
        if (cls !=null) {
            cls[0].classList.add("ReactModal__Content__ReadyToClose")
        }
        setTimeout(() => {
            if (this.props.onClose) {
                this.props.onClose(action);
            }
        }, 120)
    };

    render() {
        const restOfProps = omit(this.props, "onClose", "overlayClassName", "contentClassName", "htmlOpenClassName", "disabledCloseAction")
        const htmlOpenClassName = classNames("ReactModal__Html--open", `dialog-html-position__${this.props.position}`, this.props.htmlOpenClassName)
        const overlayClassName = classNames("dialog__backdrop", `backdrop-position__${this.props.position}`, this.props.overlayClassName, {
            [`dialog__size__${this.props.size}`]: this.props?.size
        })
        const contentClassName = classNames("dialog__content", `dialog-content-position__${this.props.position}`, this.props.contentClassName)
        const styleContent = {}
        return (this.props.isOpen && <Modal
            appElement={document.getElementById('main__app-root')}
            onRequestClose={this._close}
            style={{content:styleContent , ...this.props?.style}}
            overlayClassName={overlayClassName}
            className={contentClassName}
            htmlOpenClassName={htmlOpenClassName}
            role="dialog" {...restOfProps}>
            {this.props.children}
            {!this.props.disabledCloseAction && this.props.closeButtonPosition != "bottom" && <button className={"dialog__close-action"} onClick={this._close}>&times;</button>}
            {this.props.closeButtonPosition === "bottom" && <div className={'close-bottom'}><Button secondary className={"dialog__close-action_position-bottom"} onClick={this._close}>Close</Button></div>}
        </Modal>);
    }
}

export default Dialog