import { TableView } from "core/components/v2/table-view";
import {
  ColumnProps,
  UnionRecordType,
} from "core/components/v2/table-view/entity";
import TableOuterWrapper from "core/components/v2/table-view/outer-wrapper";
import React, { useCallback, useState } from "react";
import MwCard from "views/layouts/app/components/cards";
import StatusBadge, { StatusBadgeType } from "core/components/v2/status-badge";
import MwMessage from "views/modules/installation-v2/components/message";
import { Message } from "core/components/v2/enums";
import { ConvertedDashboardStats } from "../../entities/builder.entities";

interface StatsViewProps {
  stats: ConvertedDashboardStats[]; // Replace 'any' with a more specific type if possible
}

const StatsView = (props: StatsViewProps) => {
  const { stats } = props;
  const [page, setPage] = useState(1);
  const pageLimit = 20;
  // Transform (Flatten Details Array)
  const statsData = stats.map((stat: ConvertedDashboardStats) => ({
    ...stat,
    details: Array.isArray(stat.details) ? stat.details.join(" ") : "",
  }));
  const columns: ColumnProps[] = [
    {
      title: "Widget",
      columnKey: "widget",
      width: 300,
    },
    {
      title: "Type",
      columnKey: "type",
      width: 150,
    },
    {
      title: "Status",
      columnKey: "status",
      width: 150,
      //align: Align.Center,
      render: (text) => {
        return getStatusBadge(text as string);
      },
    },
    {
      title: "Details",
      columnKey: "details",
    },
  ];

  const getStatusBadge = useCallback((status: string): React.ReactNode => {
    const statusBadges: Record<string, React.ReactNode> = {
      success: (
        <StatusBadge labelText="Success" type={StatusBadgeType.Success} />
      ),
      partial: (
        <StatusBadge labelText="Partial" type={StatusBadgeType.Warning} />
      ),
      failed: <StatusBadge labelText="Failed" type={StatusBadgeType.Error} />,
    };

    return (
      statusBadges[status.toLowerCase()] ?? (
        <StatusBadge labelText="Unknown" type={StatusBadgeType.Default} />
      )
    );
  }, []);

  const totalWidgets = statsData.length;
  const successfulConversions = statsData.filter(
    (r) => r.status === "success"
  ).length;
  const partialConversions = statsData.filter(
    (r) => r.status === "partial"
  ).length;
  const failedConversions = statsData.filter(
    (r) => r.status === "failed"
  ).length;

  return (
    <div className="overview-card">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "16px",
          marginBottom: "20px", // Add some space between the cards and the table
        }}
      >
        <MwCard
          title="TOTAL WIDGETS"
          info="Total Widgets"
          value={totalWidgets + ""}
        />
        <MwCard
          title="SUCCESSFULLY CONVERTED"
          info="Successfully converted"
          value={successfulConversions + ""}
          success
        />
        <MwCard
          title="PARTIALLY CONVERTED"
          info="partially converted"
          value={partialConversions + ""}
          warning
        />
        <MwCard
          title="FAILED CONVERSION"
          info="Failed conversion"
          value={failedConversions + ""}
          error
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          //maxHeight: "200px",
          overflowY: "auto",
          marginBottom: "20px",
        }}
      >
        <TableOuterWrapper>
          <TableView
            columns={columns} // Add your column definitions here
            dataProvider={statsData as Record<string, UnionRecordType>[]}
            loading={false}
            uniqId="stats-tableview"
            pagination={{
              totalRecords: totalWidgets,
              recordsPerPage: pageLimit,
              page: page,
              onChange: (page: number) => {
                setPage(page);
              },
              recordsLabel: "widgets",
              internalPaginatedData: true,
            }}
          />
        </TableOuterWrapper>
      </div>
      <MwMessage
        type={Message.Info}
        message="Converts DataDog Dashboard JSON to Middleware format. Currently supports selected widgets from DataDog agent only. Some features may be limited as we continue to improve the service"
      ></MwMessage>
    </div>
  );
};

export default StatsView;
