import {USER_RECEIVED, USER_DETAIL_RECEIVED} from "./constant";

export const userReceived = (arg: any = {}) => {
    return {
        type: USER_RECEIVED,
        ...arg
    }
}

export const userDetailReceived = (arg: any = {}) => {
    return {
        type: USER_DETAIL_RECEIVED,
        ...arg
    }
}
