import Button from "core/components/button";
import { DeleteIcon, PlusSvg } from "core/components/svg/icons";
import React from "react";
import "./_style.scss";
import { EditIcon } from "views/modules/logs/logs-icons/icons";
import { BkoffWrongIcon } from "views/layouts/app/nav/_icons";
type BuilderButtonProps = {
    onClick: () => void;
    btnText: string;
    className?: string;
    icon?: React.JSX.Element;
}
const EditButton = (props: BuilderButtonProps) => {
    return <BuilderButton {...props} icon={<EditIcon size={11} />} />
}
const AddNewButton = (props: BuilderButtonProps) => {
    return <BuilderButton {...props} icon={<PlusSvg size={11} />} />
}
const DeleteButton = (props: BuilderButtonProps) => {
    const className = `${props.className || ""} delete`;
    return <BuilderButton {...props} className={className} icon={<DeleteIcon size={11} />} />
}
const RemoveButton = (props: BuilderButtonProps) => {
    const className = `${props.className || ""} remove`;
    return <BuilderButton {...props} className={className}  icon={<BkoffWrongIcon width={30} height={30} />} />
}
const BuilderButton = (props: BuilderButtonProps) => {
    const { onClick, btnText, className, icon } = props;
    return <Button className={`builder-button ${className || ""}`} onClick={onClick}>
        {icon}{btnText}
    </Button>
}
export { AddNewButton, DeleteButton, EditButton, RemoveButton, BuilderButton };