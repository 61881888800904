import React, {useEffect} from "react";
import {useState} from "react";
import Dialog from "core/components/dialog";
import LinuxInfraInstrument from "views/modules/integrations/components/dialog/infra-dialog/linux-integration-dialog";
import useQuery from "core/components/query";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import {
    DataIngestionAPiItems,
    IntegrationItem
} from "views/modules/integrations/entity";
import Search from "core/components/search";

let filteredDataIngestionAPiItems = DataIngestionAPiItems


export default function DataIngestionAPiIngegrationItems() {
    const query = useQuery()
    const routeData = React.useContext(MwRouteContext);
    const integration = query.get("ingestion_api")


    const hash = window?.location?.hash?.split("/")[1]

    const [searchTerm, setSearchTerm] = useState('');
    const handleSearch = (searchTerm: string) => {
        setSearchTerm(searchTerm);
        const filteredDataIngestionList = DataIngestionAPiItems.filter(item =>
            item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
        if (!searchTerm) {
            filteredDataIngestionAPiItems = DataIngestionAPiItems;
        } else {
            filteredDataIngestionAPiItems = filteredDataIngestionList;
        }
    };
    useEffect(() => {
        return () => {
            filteredDataIngestionAPiItems = DataIngestionAPiItems;
        }
    }, []);
    const filteredItems: IntegrationItem[] = filteredDataIngestionAPiItems.filter(item => item.category && item.category.indexOf(hash) !== -1)

    const [openDialog, setOpenDialog] = useState(false)
    const [component, setComponent] = useState(<LinuxInfraInstrument/>)
    const getPopUp = (key: string) => {
        routeData.params.query.set('ingestion_api', key)
        routeData.params.query.redirect()
    }
    useEffect(() => {
        if (integration != null) {
            const foundItem = filteredItems.find(item => integration === item.key);
            if (foundItem) {
                setOpenDialog(true);
                setComponent(foundItem.component);
            } else {
                setOpenDialog(false);
            }
        } else {
            setOpenDialog(false)
        }

    }, [integration]);

    return <>
        <div className="all-integration-screen-container">
            <div className="integration-container">
                <Search
                    onKeyDown={(a: any, b: any) => {
                    }}
                    onSearchClose={(a: any, b: any) => {
                    }}
                    value={''}
                    onSearch={handleSearch}
                    placeholder={'Search...'}/>
                <div className="integration-row">
                    {
                        filteredItems.map((item, index) => {
                            return <div key={index} className={'integration-screen'}>
                                <div className="features-main" onClick={() => {
                                    getPopUp(item.key)
                                }}>
                                    <div style={{borderColor: item.borderColor}} className={`feature linux`}>
                                        <div className="feature__head">
                                            <div className={"button_icon_placement"}>
                                                <div className="svg-icon db-monitoring-icon-svg">
                                                    {item.icon}
                                                </div>
                                                <div className={"feature__title"}>
                                                    <span>{item.title}</span>
                                                    {(item.key == 'go-serverless' || item.key == 'nodejs-serverless') ?
                                                        <p className={'serverless-integration'}>Serverless</p> : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <p className="feature__desc">{item.desc}</p>

                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>

        <Dialog
            overlayClassName={'jira-integration'}
            isOpen={openDialog}
            position={"right"}
            onClose={() => {
                setOpenDialog(false)
                routeData.params.query.delete('ingestion_api')
                routeData.params.query.redirect()
            }}>
            {component}
        </Dialog>
    </>
}