import LogoLoader from "core/components/v2/loader/logo-loader";
import AppContext from "core/components/wrapper/context";
import * as React from "react";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import AppRoutesHelper from "views/layouts/app/routes/helper";
import MainNavMenu from "./nav";
import AppRoutes from "./routes/routes";

const AppLayouts = function () {
  const context = useContext(AppContext);
  // const isAgentInstalled = context.user?.account?.is_agent_installed;
  // const isSimulateOn = context.user?.account?.meta_data?.simulate;
  // const hideMenu = !isAgentInstalled && !isSimulateOn && context.user?.account?.uid != "sandbox";
  let hideMenu = false;
  if (location.pathname && location.pathname == "/integration/vercel") {
    hideMenu = true;
  }

  const errorContext = useSelector((state: any) => state.errorContext);
  let scrollTimer: NodeJS.Timeout;
  const handleScroll = (e: any) => {
    const target = e.target as HTMLElement;
    if (target?.classList?.contains("on-mw-scrollbar") === false) {
      target.classList.add("on-mw-scrollbar");
      clearTimeout(scrollTimer);
      scrollTimer = setTimeout(() => {
        target.classList.remove("on-mw-scrollbar");
        if (target.classList.contains("ant-table-header")) {
          const tableBody = target.nextElementSibling;
          if (tableBody) {
            tableBody.classList.remove("on-mw-scrollbar");
          }
        }
      }, 500);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <React.Suspense fallback={<LogoLoader />}>
      {context.user?.inFlight ? (
        <LogoLoader />
      ) : (
        <div
          className={`app-layouts__context ${hideMenu ? "hide-main-sidebar" : "show-main-sidebar"}`}
        >
          {!context.user?.inFlight && (hideMenu ? "" : <MainNavMenu />)}
          {errorContext && !context.user?.account && (
            <div className="error-context__wrapper">
              <div className="error-context__content">
                <div className="error-context__content__title">
                  <h1>{"Oops!"}</h1>
                  <h4>Something went wrong..!</h4>
                </div>
                <div className="error-context__content__message">
                  <p>Brace yourself till we get the error fixed.</p>
                  <p>
                    You may also{" "}
                    <a href={window.location.href}>refresh the page</a> or try
                    again later.
                  </p>
                </div>
              </div>
            </div>
          )}
          {!errorContext &&
            context.user?.account &&
            !context.user?.inFlight && <AppRoutesHelper routes={AppRoutes} />}
        </div>
      )}
    </React.Suspense>
  );
};

export default AppLayouts;
