import { RangeInfo } from "core/components/datepicker";
import { TimeRangeOption } from "core/components/datepicker/model";
import {
  getMinutesDifference,
  getRoundedInterval,
} from "views/modules/apm/utils";
import { DateRange } from "../../routes/model";
import { GetRounderdOneHourTs } from "../../routes/utils";

// Same range is in backend also so if you are changing this then please change in backend also: timerange.utils.go
export const AvailableDateRange: RangeInfo[] = [
  {
    key: "global",
    count: 0,
    label: "Global Time",
    range: TimeRangeOption.Empty,
    hideFromGlobalPicker: true,
  },
  {
    key: "minute",
    count: 1,
    label: "1 Minute",
    range: TimeRangeOption.OneMinute,
    hideFromGlobalPicker: true,
  },
  {
    key: "minute",
    count: 5,
    label: "5 Minutes",
    range: TimeRangeOption.FiveMinutes,
  },
  {
    key: "minute",
    count: 15,
    label: "15 Minutes",
    range: TimeRangeOption.FifteenMinutes,
  },
  {
    key: "minute",
    count: 60,
    label: "Hour",
    range: TimeRangeOption.OneHour,
  },
  {
    key: "minute",
    count: 180,
    label: "3 Hours",
    range: TimeRangeOption.ThreeHours,
  },
  {
    key: "minute",
    count: 720,
    label: "12 Hours",
    range: TimeRangeOption.TwelveHours,
  },
  {
    key: "day",
    count: 1,
    label: "1 Day",
    range: TimeRangeOption.OneDay,
  },
  {
    key: "day",
    count: 2,
    label: "2 Days",
    range: TimeRangeOption.TwoDays,
  },
  {
    key: "week",
    count: 1,
    label: "1 Week",
    range: TimeRangeOption.OneWeek,
  },
];

const OneMinute = 60 * 1000;
const FiveMinutes = 5 * 60 * 1000;
const FifteenMinutes = 15 * 60 * 1000;
const OneHour = 60 * 60 * 1000;
const ThreeHours = 3 * 60 * 60 * 1000;
const TwelveHours = 12 * 60 * 60 * 1000;
const OneDay = 24 * 60 * 60 * 1000;
const TwoDays = 2 * OneDay;
const OneWeek = 7 * OneDay;

const getMillisecondsFromRange = (range: TimeRangeOption): number => {
  switch (range) {
    case TimeRangeOption.OneMinute:
      return OneMinute;
    case TimeRangeOption.FiveMinutes:
      return FiveMinutes;
    case TimeRangeOption.FifteenMinutes:
      return FifteenMinutes;
    case TimeRangeOption.OneHour:
      return OneHour;
    case TimeRangeOption.ThreeHours:
      return ThreeHours;
    case TimeRangeOption.TwelveHours:
      return TwelveHours;
    case TimeRangeOption.OneDay:
      return OneDay;
    case TimeRangeOption.TwoDays:
      return TwoDays;
    case TimeRangeOption.OneWeek:
      return OneWeek;
    default:
      return OneHour;
  }
};
export const getTimeFromRange = (range: TimeRangeOption): DateRange => {
  const now = new Date();
  const fromTs = now.getTime() - getMillisecondsFromRange(range);
  const toTs = now.getTime();
  const interval = getRoundedInterval(
    parseInt(getMinutesDifference(fromTs, toTs))
  );
  return {
    fromTs: GetRounderdOneHourTs(fromTs),
    toTs: GetRounderdOneHourTs(toTs),
    interval,
    range,
  };
};
//TODO: @Mittal define in /core/components/datepicker/model.ts
export interface OnDateSelection {
  fromDate: Date;
  toDate: Date;
  time: string;
  timeFormat: "12 hours" | "24 hours"; // Adjusted to make it more specific
  complete: boolean;
  text: string;
  range: TimeRangeOption;
}
