
export interface Parsed {
    parts: string[];
    values: string[];
    operators: string[]; // You can replace 'string' with a more specific type if needed
}
export const formatString = (str: string) : Parsed => {

    const parsed: Parsed = {
        parts: [],
        values: [],
        operators: []
    };

    // const operators = ["AND", "OR",  "(" , ")", "="];
    const delimiterPattern = /(\sAND\s|\sOR\s|\(|\))/;

    parsed.parts = (str.split(delimiterPattern) || [])
   
    parsed.operators= parsed.parts.filter((item:string) =>  item.trim() != '').map((item:string) => item.trim()).filter((item:string) => item == 'AND' || item == 'OR');
    
    if(parsed.operators.length) {
        parsed.values = parsed.parts.filter((item:string) =>  item.trim() != '').map((item:string) => item.trim()).filter((item:string) => item != 'AND' && item != 'OR');
    }

    return parsed;

}

export const evaluateExpression = (strs: string[]) : boolean => {
    const str: string[] = strs?.filter((item:string) =>  item.trim() != '');
    for(let i = 0 ; i<str.length ; i++) {
        if(str[i] == 'AND' || str[i] == 'OR') {
            if(i == 0 || str[i-1] == '(' || str[i-1] == 'AND' || str[i-1] == 'OR') return false;
            if(i == str.length-1 || str[i+1] == ')' || str[i+1] == 'AND' || str[i+1] == 'OR') return false;
        }else if(str[i] == '(') {
            if(i == str.length-1 || str[i+1] == 'AND' || str[i+1] == 'OR') return false; 
        }else if(str[i] == ')') {
            if(i == 0 || str[i-1] == 'AND' || str[i-1] == 'OR') return false;
        }

    }
    return checkParenthesis(str);
}

export const checkParenthesis = (str : string[]) : boolean => {
    const stack: any = [];
    for(let i = 0 ; i<str.length ; i++) {
        if(str[i] == '(') {
            stack.push('(');
        }
        if(str[i] == ')') {
            if(stack.length == 0) return false;
            stack.pop();
        }

    }
    return stack.length == 0;

}