import * as React from "react";
import classNames from "classnames";
import { ReactNode } from "react";
import "./_style.scss";

interface Props {
  isError?: boolean;
  isWarning?: boolean;
  text: ReactNode;
  id?: string;
  className?: string;
  isHidden?: boolean;
}
const FormInputValidation = (props: Props) => {
  const { isWarning, isError, isHidden } = props;
  const classes = classNames(props.className, {
    "form-input-validation-v2": true,
    "is-warning": isWarning,
    "is-error": isError,
    "is-hidden": isHidden,
  });
  return (
    <div className={classes} role="alert">
      <span id={props.id}>{props.text}</span>
    </div>
  );
};

export default FormInputValidation;
