import {Span} from "../modal/apm.modals";

const sortByStartTimeNs = (spans: Span[]) => {
    return (a: string, b: string) => {
        const spanA = spans.find((span) => span.spanID === a);
        const spanB = spans.find((span) => span.spanID === b);
        if (!spanA || !spanB) return 0;
        return spanA.timestampNs - spanB.timestampNs;
    };
};

const canAddSpanToRow = (
    row: string[],
    spanBitMap: { [key: string]: Span },
    spanId: string,
    minPresentationalSpanDuration: number
) => {
    if (!row.length) return true;
    const startTimeNs = spanBitMap[spanId].timestampNs;
    const lastSpan = spanBitMap[row[row.length - 1]];
    const endTimeNs = lastSpan.timestampNs + lastSpan.durationNano;
    return (
        startTimeNs >
        Math.max(endTimeNs, lastSpan.timestampNs + minPresentationalSpanDuration)
    );
};

const addChildToSpanRow = (
    result: string[][],
    spanBitMap: { [key: string]: Span },
    spanId: string,
    minPresentationalSpanDuration: number
) => {
    const lastRow = result[result.length - 1];
    if (
        canAddSpanToRow(
            lastRow,
            spanBitMap,
            spanId,
            minPresentationalSpanDuration
        )
    ) {
        lastRow.push(spanId);
    } else {
        result.push([spanId]);
    }
};

const processOrphanedSpan = (
    earliestStartTime: number,
    latestEndTime: number,
    orphanedSpanIds: string[],
    spanMap: { [key: string]: Span }
) => {
    if (!orphanedSpanIds.length) return;

    const isSingleOrphan = orphanedSpanIds.length === 1;
    const firstOrphanSpanId = isSingleOrphan ? orphanedSpanIds[0] : "0";
    spanMap[firstOrphanSpanId] = {
        "db.system": "",
        durationNano: latestEndTime - earliestStartTime,
        events: [],
        hasError: false,
        name: "",
        parentSpanID: "",
        resource_attributes: {
            isGhostSpan: "true",
        },
        spanID: firstOrphanSpanId,
        tagMap: {},
        timestampNs: earliestStartTime,
        traceID: "",
    };

    if (isSingleOrphan) {
        orphanedSpanIds.pop();
    }
};

const getSpanRows = (spans: Span[], width: number) => {
    const spanMap: { [key: string]: Span } = {};
    const childrenMap: { [key: string]: string[] } = {};
    const parentsMap: { [key: string]: string } = {};
    let earliestStartTime: number | null = null;
    let latestEndTime: number | null = null;

    spans.forEach((span) => {
        const {
            timestampNs: startTimeNs,
            durationNano,
            parentSpanID,
            spanID,
        } = span;
        spanMap[spanID] = span;
        if (earliestStartTime === null || startTimeNs < earliestStartTime) {
            earliestStartTime = startTimeNs;
        }
        const endTimeNs = startTimeNs + durationNano;
        if (latestEndTime === null || endTimeNs > latestEndTime) {
            latestEndTime = endTimeNs;
        }
        if (parentSpanID === "") {
            parentsMap[spanID] = spanID;
        } else {
            parentsMap[parentSpanID] = parentSpanID;
            if (!childrenMap[parentSpanID]) {
                childrenMap[parentSpanID] = [];
            }
            childrenMap[parentSpanID].push(spanID);
        }
    });

    const minPresentationalSpanDuration = Math.floor(width / 100);
    const orphanedSpanIds = spans
        .filter(
            (span) => span.parentSpanID !== "" && !spanMap[span.parentSpanID]
        )
        .map((span) => span.spanID);
    const result: string[][] = [];
    let rootSpanId: string | null = null;
    let rootSpanChildren: string[] = [];
    let orphanedSpanCount = 0;

    const processSpan = (
        childrenBySpanId: { [key: string]: string[] },
        childrenSpanIds: string[],
        minPresentationalSpanDuration: number,
        spanBitMap: { [key: string]: Span },
        result: string[][]
    ): void => {
        const orphanedSpanIds: string[] = [];
        childrenSpanIds
            .sort(sortByStartTimeNs(spans))
            .forEach((spanId) => {
                addChildToSpanRow(result, spanBitMap, spanId, minPresentationalSpanDuration);
                (childrenBySpanId[spanId] || []).forEach((childSpanId) => {
                    orphanedSpanCount++;
                    orphanedSpanIds.push(childSpanId);
                });
            });
        if (orphanedSpanIds.length) {
            processSpan(
                childrenBySpanId,
                orphanedSpanIds,
                minPresentationalSpanDuration,
                spanBitMap,
                result
            );
        }
    };

    spans.forEach((span) => {
        const { parentSpanID, spanID } = span;
        if (parentSpanID === "") {
            rootSpanId = spanID;
            rootSpanChildren = childrenMap[spanID] || [];
            if (!orphanedSpanCount && orphanedSpanIds.length) {
                processOrphanedSpan(
                    earliestStartTime!,
                    latestEndTime!,
                    orphanedSpanIds,
                    spanMap
                );
                rootSpanId = orphanedSpanIds.length === 1 ? orphanedSpanIds[0] : "0";
                orphanedSpanIds.length = 0;
            }
        } else {
            parentsMap[parentSpanID] = parentSpanID;
        }
    });

    if (!rootSpanId && spans.length) {
        const isSingleSpan = spans.length === 1;
        const firstSpanId = isSingleSpan ? spans[0].spanID : "0";
        processOrphanedSpan(earliestStartTime!, latestEndTime!, [firstSpanId], spanMap);
        rootSpanId = firstSpanId;
        if (isSingleSpan) {
            rootSpanChildren = childrenMap[firstSpanId] || [];
        }
    }

    if (rootSpanId) {
        result.push([rootSpanId]);
        processSpan(
            childrenMap,
            rootSpanChildren,
            minPresentationalSpanDuration,
            spanMap,
            result
        );
    }

    return {minPresentationalSpanDuration,
        minStartTimeNs: earliestStartTime!,
        maxEndTimeNs: latestEndTime!,
        spanBitMap: spanMap,
        spanRows: result,
    };
};

export default getSpanRows;
