import {createReducer} from "core/utils/create_reducer";
import {RECEIVED_METRICS_TIMELINE, RECEIVED_RESOURCE_ATTRIBUTES} from "store/infrastructure/constant";

export const resource_attributes = createReducer({
    inflight: true,
    items: {}
}, {
    [RECEIVED_RESOURCE_ATTRIBUTES]: (state, opts) => {
        if (opts) {
            if (typeof opts.inflight != "undefined")
                state.inflight = opts.inflight;

            if (opts?.attributes)
                state.items = opts.attributes;
        }
        return {...state}
    }
})

export const metrics_timeline = createReducer({
    inflight: true,
    inner_inflight: true,
    items: {}
}, {
    [RECEIVED_METRICS_TIMELINE]: (state, opts) => {
        if (opts) {
            let group_key = opts?.isInnerMetrics && opts?.fingerprint ? opts.fingerprint : "all"

            if (typeof opts.inflight != "undefined")
                state.inflight = opts.inflight;

            if (typeof opts.inner_inflight != "undefined")
                state.inner_inflight = opts.inner_inflight;

            if (opts?.metric_key)
                state.metric_key = opts.metric_key;

            if (opts?.metrics)
                state.items[group_key] = opts.metrics;

            if (opts?.legend_items && opts?.legend_schema && Array.isArray(opts.legend_items) && Array.isArray(opts.legend_schema))
                state.items[group_key] = {
                    legend_items: opts.legend_items,
                    legend_schema: opts.legend_schema,
                };
        }
        return {...state}
    }
})