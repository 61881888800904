import {createReducer} from "core/utils/create_reducer";
import {INSTALLATION_OVERVIEW_RECEIVED, ONBOARD_DASHBOARD_OVERVIEW_RECEIVED,REFRESH_BTN_CLICKED} from "store/onboard-dashboard/constant";
import {combineReducers} from "redux";

const detail = createReducer([], {
    [ONBOARD_DASHBOARD_OVERVIEW_RECEIVED]: (state, opts) => {
        if (Object.keys(opts.data)?.length) {
            return opts.data;
        }
        return {...state};
    }
})
const installationDetail = createReducer([], {
    [INSTALLATION_OVERVIEW_RECEIVED]: (state, opts) => {
        if (Object.keys(opts.data)?.length) {
            return opts.data;
        }
        return {...state};
    }
})
const refreshBtnClicked = createReducer(false, {
    [REFRESH_BTN_CLICKED]: (state, opts) => {
        return opts.value;
    }
})
export default combineReducers({
    detail,
    installationDetail,
    refreshBtnClicked
})