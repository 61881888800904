import * as React from "react";
import {
    TypographyHeadingPrimary, TypographyParagraphPrimary800, TypographyParagraphSteps,
} from "core/components/typography";
import Command from "views/modules/installation/common/command";
import Note from "views/modules/installation/common/note";
import Button from "core/components/button";
import {useContext, useState} from "react";
import AppContext, {ContextProps} from "core/components/wrapper/context";
import {generateAgentConfig} from "store/settings/installation/api";
import {showToast} from "store/toast-alerts/actions";
import {useDispatch} from "react-redux";
import {WindowsImageAgent} from "core/components/svg/icons";
import {getTenantUrl} from "core/application/utils";
import GoToDashboard from "../common/goto-dashboard";

export default function WindowsInstallation() {
    const context = useContext<ContextProps>(AppContext);
    const [generatingToken, setGeneratingToken] = useState<boolean>(false);
    const dispatch = useDispatch();

    const url = getTenantUrl(context.user);
    const capturePath = `https://${url}`;

    const generateToken = () => {
        setGeneratingToken(true);
        dispatch(generateAgentConfig((success, config) => {
            setGeneratingToken(false);
            if (success) {
                context.changeContext({
                    key: "user",
                    value: {
                        ...context.user,
                        account: {
                            ...context.user?.account,
                            agent_config: config,
                        }
                    }
                })
            } else {
                dispatch(showToast("error", "Error while generating token.Please try again."))
            }
        }))
    }

    return <div className={"installation-page windows-docs"}>
        <div className={"page-header"}>
            <div className={"title"}>
                <TypographyHeadingPrimary>Installing the Middleware Agent on Microsoft
                    Windows.</TypographyHeadingPrimary>
            </div>
            <div className={"description"}>
                <TypographyParagraphPrimary800>Install the Middleware Agent (MW Agent) to observe your Microsoft Windows
                    machine and the applications running inside them.</TypographyParagraphPrimary800>
            </div>
        </div>
        <div className={"page-body"}>
            <div className={"step"}>
                <div className={"details"}>
                    <div className={"count"}>
                        <span>1</span>
                    </div>
                    <div className={"detail"}>
                        <TypographyParagraphSteps>Download the MW Agent for Windows from <a
                            href={'https://github.com/middleware-labs/mw-agent/releases/latest/download/mw-windows-agent-setup.exe'}
                            target={'_blank'} rel={'noreferrer'}>here</a> and follow the installation
                            prompts.</TypographyParagraphSteps>
                    </div>
                </div>
            </div>

            <div className={"step"}>
                <div className={"details"}>
                    <div className={"count"}>
                        <span>2</span>
                    </div>
                    <div className={"detail"}>
                        <TypographyParagraphSteps>Enter MW API Key & MW Target URL:</TypographyParagraphSteps>
                    </div>
                </div>
                <div className={'d-flex'}>
                    <div className={'left-block'}>
                        {
                            context.user?.account?.agent_config?.token ?
                                <React.Fragment>
                                    <p className={'fn-size'}>Middleware API Key:</p>
                                    <Command command={context.user?.account?.agent_config?.token}/>

                                    <p className={'fn-size'}>Middleware Target URL:</p>
                                    <Command command={capturePath}/>

                                </React.Fragment>
                                : <div className={"notice"}><Button loading={generatingToken} disabled={generatingToken}
                                                                    onClick={generateToken}>Generate Token</Button>
                                </div>
                        }
                        <div className={"details"}>
                            <div className={"count"}>
                                <span>3</span>
                            </div>
                            <div className={"detail"}>
                                <TypographyParagraphSteps>Verify the MW Agent installation using the following command
                                    in Powershell:</TypographyParagraphSteps>
                            </div>
                        </div>
                        <Command command={'Get-Service -Name mw-windows-agent'}/>
                        <p className={'fn-size'}>You should see the following output:</p>
                        <img src={"assets/images/windows-powershall.png"}/>
                        <Note
                            note={"Do not run more than one MW Agent per machine. Multiple Agents will cause unexpected behavior."}/>
                    </div>

                    <div className={'right-block'}>
                        <WindowsImageAgent/>
                    </div>
                </div>

            </div>

            <GoToDashboard dashboardURL="/infrastructure/host/dashboard"/>
        </div>
    </div>
}