import CreateRequest from "core/xhr";
import { showToast } from "store/toast-alerts/actions";
import { ApiResponse } from "views/modules/settings-v2/entity";
import {
  receivedBackoffAgents,
  receivedBackoffAuthToken,
  receivedBackoffContactDetails,
  receivedBackoffEmailDetails,
  receivedBackoffLoginDetails,
  receivedBackoffLogs,
  receivedBackoffUsages,
  receivedBackoffUsers,
  receivedBackoffVercelData,
  receivedDeleteAccount,
  receivedDeleteTemplate,
  receivedEditEmailCampaignTemplate,
  receivedEmailCampaignTemplate,
  receivedInternalAccountFlag,
  receivedReactivateAccount,
  receivedUpdateTrialDay,
  requestBackoffInflight,
} from "./actions";

export const requestBackoffLogin = (body: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/login",
      method: "POST",
      data: typeof body == "object" ? body : {},
    })
      .then((res) => {
        console.log("res.data---", res.data);
        if (res?.data?.token) {
          dispatch(receivedBackoffAuthToken({ token: res.data.token }));
        }
        if (res?.data?.error && res.data.error !== "") {
          dispatch(showToast("error", res.data.error));
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestBackoffInit = () => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/init",
      method: "GET",
    })
      .then((res) => {
        if (res?.data?.status) {
          dispatch(receivedBackoffAuthToken({ token: null }));
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestBackoffLogout = () => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/logout",
      method: "GET",
    })
      .then((res) => {
        if (res?.data?.status) {
          dispatch(receivedBackoffAuthToken({ token: "" }));
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestBackoffUsers = (params?: any) => {
  return (dispatch: (arg0: any) => void) => {
    dispatch(requestBackoffInflight());
    return CreateRequest({
      url: "/backoff/users",
      method: "GET",
      params: {
        search: params.search,
        page: params.page,
        limit: params.limit,
        agentFilter: params.agentFilter,
      },
    })
      .then((res) => {
        dispatch(receivedBackoffUsers(res.data, params.isScroll));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestBackoffUserLogin = (params?: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/userlogins/",
      method: "GET",
      params: {
        user_id: params?.user_id,
        account_id: params?.account_id,
        uid: params?.uid,
      },
    })
      .then((res) => {
        dispatch(receivedBackoffLoginDetails(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestBackoffAgent = (params?: any) => {
  const account_id = params?.account_id;
  const u_id = params?.uid;

  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/agents/" + account_id + "/" + u_id,
      method: "GET",
      data: {},
    })
      .then((res) => {
        dispatch(receivedBackoffAgents(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestBackoffLogs = (params?: any) => {
  const u_id = params?.uid;

  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/logs/" + u_id,
      method: "GET",
      data: {},
      params: {
        account_id: params?.account_id,
      },
    })
      .then((res) => {
        dispatch(receivedBackoffLogs(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};
export const requestBackofUsages = (
  data?: any,
  callback?: (res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/usages",
      method: "get",
      params: data,
    })
      .then((res) => {
        dispatch(receivedBackoffUsages(res.data));
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

// email content request details
export const requestEmailDetails = (
  body: any,
  callback?: (a0: boolean, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/email",
      method: "POST",
      data: typeof body == "object" ? body : {},
    })
      .then((res) => {
        console.log("Email api Content details ---", res.data);
        callback && callback(res?.data?.status, res?.data?.email);
        if (res?.data?.email) {
          dispatch(receivedBackoffEmailDetails(res.data.email));
          dispatch(showToast("success", `Email template add Successfully!`));
        }
        if (res?.data?.error && res.data.error !== "") {
          dispatch(showToast("error", res.data.error));
        }
      })
      .catch((err) => {
        callback && callback(false);
        console.log("list: error", err);
      });
  };
};

export const requestEmailManually = (
  body: any,
  callback?: (a0: boolean, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/email-manually",
      method: "POST",
      data: typeof body == "object" ? body : {},
    })
      .then((res) => {
        console.log("Email api Content details error ---", res.data);
        callback && callback(res?.data?.status, res?.data?.email);
        if (res?.data?.email) {
          dispatch(receivedBackoffEmailDetails(res.data.email));
          dispatch(showToast("success", `Email Send Successfully!`));
        }
        if (res?.data?.error && res.data.error !== "") {
          dispatch(showToast("error", res.data.error));
        }
      })
      .catch((err) => {
        callback && callback(false);
        console.log("list: error", err);
      });
  };
};

// // sender content request details
// export const requestToSender = (body: any) => {
//     return (dispatch: (arg0: any) => void) => {
//         return CreateRequest({
//             url: "/backoff/sender",
//             method: "POST",
//             data: typeof body == "object" ? body : {},
//         }).then( res => {
//             console.log("Sender api Content details ---", res.data)
//             if (res?.data?.token) {
//                 dispatch(receivedBackoffSender({token: res.data.token}))
//             }
//             if (res?.data?.error && res.data.error !== "") {
//                 dispatch(showToast("error", res.data.error))
//             }
//
//         }).catch( err => {
//             console.log("list: error", err)
//         });
//     }
// }

export const requestEmailCampaignTemplate = (body: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/compaigns",
      method: "GET",
      data: typeof body == "object" ? body : {},
    })
      .then((res) => {
        dispatch(receivedEmailCampaignTemplate(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestUpdateEmailCampaign = (
  params?: any,
  callback?: (a0: boolean, res?: any) => void
) => {
  const id = params?.editId;
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/compaigns/" + id,
      method: "PUT",
      params: {
        edit_id: params?.editId,
        subject: params?.subject,
        campaign_type: params?.campaign_type,
        content_body: params?.content_body,
        status: params?.status,
      },
    })
      .then((res) => {
        dispatch(receivedEditEmailCampaignTemplate(res.data));
        callback && callback(res?.data?.status, res?.data?.email);
        dispatch(showToast("success", `Email template Updated Successfully!`));

        console.log(res.data, "===>>  update res.data");
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestDeleteEmailCampaign = (params?: any) => {
  const id = params?.editId;

  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/compaigns/" + id,
      method: "DELETE",
      params: {
        edit_id: params?.editId,
      },
    })
      .then((res) => {
        dispatch(receivedDeleteTemplate(res.data));
        dispatch(showToast("success", `Email template Delete Successfully!`));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestDeleteAccount = (params?: any) => {
  const id = params?.accountId;
  const delete_type = params?.deleteType;

  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/account_delete/" + id + "/" + delete_type,
      method: "DELETE",
    })
      .then((res) => {
        dispatch(receivedDeleteAccount(res.data));
        dispatch(showToast("success", `Account Delete Successfully!`));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestReactivateAccount = (params?: any) => {
  const id = params?.accountId;

  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/account_reactive/" + id,
      method: "PUT",
    })
      .then((res) => {
        dispatch(receivedReactivateAccount(res.data));
        dispatch(showToast("success", `Account Activate Successfully!`));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};
export const requestUpdateTrialDay = (params?: any) => {
  const accountId = params?.accountId;
  const trialId = params?.trialDay;
  const status = params?.status;
  const subscribeProduct = params?.subscribeProduct;

  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url:
        "/backoff/billing_update/" + trialId + "/" + status + "/" + accountId,
      method: "PUT",
      data: {
        subscribeProduct,
      },
    })
      .then((res) => {
        dispatch(receivedUpdateTrialDay(res.data));
        dispatch(showToast("success", `Updated Trial Successfully!`));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestContactDetails = (
  body: any,
  callback?: (a0: boolean, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/contact/",
      method: "POST",
      data: typeof body == "object" ? body : {},
    })
      .then((res) => {
        callback && callback(res?.data?.status, res?.data);
        if (res?.data) {
          dispatch(receivedBackoffContactDetails(res.data));
          dispatch(requestGetContactList());
          dispatch(showToast("success", `New Contact Add Successfully!!`));
        }
        if (res?.data?.error && res.data.error !== "") {
          dispatch(showToast("error", res.data.error));
        }
      })
      .catch((err) => {
        callback && callback(false);
        console.log("list: error", err);
      });
  };
};
export const requestGetContactList = (params?: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/contacts/",
      method: "GET",
    })
      .then((res) => {
        dispatch(receivedBackoffContactDetails(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};
export const requestDeleteContact = (params?: any) => {
  const deleteId = params?.delete_id;
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/contacts/" + deleteId,
      method: "DELETE",
    })
      .then((res) => {
        dispatch(receivedBackoffContactDetails(res.data));
        dispatch(requestGetContactList());
        dispatch(showToast("error", `Contact delete Successfully!`));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};
// export const  requestUploadFile = (params?: any) => {
//     const uploadData = params?.uploadData
//     console.log(uploadData, "--uuu")
//     const formData: any = new FormData();
//     formData.append("file", uploadData, uploadData.name);
//
//     return CreateRequest({
//         url: '/backoff/contacts/upload/',
//         method: 'POST',
//         data: formData,
//         headers: {
//             "form-data": formData,
//         },
//     })
// }

export const requestVercelData = (params?: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/vercel",
      method: "GET",
      params: { ...params },
    })
      .then((res) => {
        dispatch(receivedBackoffVercelData(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};
export const requestUpdateInternalAccountFlag = (
  params: { account_id: number; flag: boolean },
  callback?: (res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/backoff/internal-account-flag",
      method: "PUT",
      data: params,
    })
      .then((res) => {
        dispatch(receivedInternalAccountFlag(res.data));
        if (callback) {
          callback(res);
        }
      })
      .catch((err) => {
        console.log("update: error", err);
      });
  };
};

export const requestBkoffProjects = (
  accountId: string,
  cb: (success: boolean, projects?: any[]) => void
) => {
  CreateRequest({
    url: "/backoff/projects/" + accountId,
    method: "GET",
  })
    .then((res) => {
      if (res.status === 200) {
        cb(true, res.data);
      } else {
        cb(false);
      }
    })
    .catch((err) => {
      console.log("project list error: ", err);
      cb(false);
    });
};

export const requestBkoffProjectHosts = (
  projectUid: string,
  platform: string,
  cb: (success: boolean, hosts?: string[]) => void
) => {
  CreateRequest({
    url: "/backoff/project_hosts/" + projectUid + "?platform=" + platform,
    method: "GET",
  })
    .then((res) => {
      if (res.status === 200) {
        cb(true, res.data);
      } else {
        cb(false);
      }
    })
    .catch((err) => {
      console.log("project host list error: ", err);
      cb(false);
    });
};

export const requestBkoffHostSettings = (
  hostId: string,
  accountId: number,
  projectId: number,
  cb: (success: boolean, settings?: any) => void
) => {
  CreateRequest({
    url:
      "/backoff/host_settings/" +
      encodeURIComponent(hostId) +
      "?accountId=" +
      accountId +
      "&projectId=" +
      projectId,
    method: "GET",
  })
    .then((res) => {
      if (res.status === 200) {
        cb(true, res.data);
      } else {
        cb(false);
      }
    })
    .catch((err) => {
      console.log("project host settings error: ", err);
      cb(false);
    });
};

export const updateBkoffHostSettings = (
  hostId: string,
  accountId: number,
  projectId: number,
  config: object,
  cb: (success: boolean) => void
) => {
  const encodedConfig = window.btoa(JSON.stringify(config));
  const reqBody = {
    encoded_config: encodedConfig,
  };

  CreateRequest({
    url:
      "/backoff/host_settings/" +
      encodeURIComponent(hostId) +
      "?accountId=" +
      accountId +
      "&projectId=" +
      projectId,
    method: "POST",
    data: reqBody,
  })
    .then((res) => {
      if (res.status === 200) {
        cb(true);
      } else {
        cb(false);
      }
    })
    .catch((err) => {
      console.log("project host settings update error: ", err);
      cb(false);
    });
};
export const requestUpdateAccountMetadata = (
  params: {
    account_id: number;
    key: string;
    value: string | boolean | number | object;
  },
  callback?: (res?: ApiResponse) => void
) => {
  return () => {
    return CreateRequest({
      url: "/backoff/update-account-meta",
      method: "PUT",
      data: params,
    })
      .then((res) => {
        const apiResponse = res as unknown as ApiResponse;

        if (callback) {
          callback(apiResponse);
        }
      })
      .catch((err) => {
        console.log("update: error", err);
      });
  };
};
export const requestUpdateProjectTTL = (
  params: {
    project_id: number;
    ttl: number;
    account_id: number;
  },
  callback?: (res?: ApiResponse) => void
) => {
  return () => {
    return CreateRequest({
      url: "/backoff/update-project-ttl",
      method: "PUT",
      data: params,
    })
      .then((res) => {
        const apiResponse = res as unknown as ApiResponse;

        if (callback) {
          callback(apiResponse);
        }
      })
      .catch((err) => {
        console.log("update: error", err);
      });
  };
};

interface BodyType {
  full_name: string;
  password: string;
  email: string;
  company_name: string;
  calling_code: string;
  phone: string;
  cca: string;
}

export const registerRequestBkoff = (
  body: BodyType,
  callback?: (
    status: boolean,
    b: boolean,
    res?: { send: boolean; inflight: boolean; message: string }
  ) => void
) => {
  return () => {
    if (callback) {
      callback(true, true);
    }
    return CreateRequest({
      url: "/backoff/rwe",
      method: "post",
      data: body,
    })
      .then((res) => {
        if (callback) {
          callback(true, false, res.data);
        }
      })
      .catch((err) => {
        if (callback) {
          callback(false, false, err);
        }
      });
  };
};
export const requestUpdateProjectDbDeleted = (
  params: {
    project_id: number;
    db_deleted: boolean;
    account_id: number;
  },
  callback?: (res?: ApiResponse) => void
) => {
  return () => {
    return CreateRequest({
      url: "/backoff/delete-project-db",
      method: "PUT",
      data: params,
    })
      .then((res) => {
        const apiResponse = res as unknown as ApiResponse;

        if (callback) {
          callback(apiResponse);
        }
      })
      .catch((err) => {
        console.log("update: error", err);
      });
  };
};
