import CreateRequest from "core/xhr";
import { showToast } from "store/toast-alerts/actions";
import {
  Incident,
  UpdateStatus,
} from "views/modules/status-page/incident/model";
import { PageListParams } from "views/modules/status-page/page_list";
import { incidentListParams } from "views/modules/status-page/page_list/helper";
import {
  PublicSbscriber,
  Service,
  StatusPageSettings,
  Subscriber,
} from "views/modules/status-page/page_list/model";
import {
  chartAndServiceListReceived,
  currentStatusOfSyntheticReceived,
  incidentHistoryReceived,
  incidentListReceived,
  incidentServiceHistoryReceived,
  incidentSingleReceived,
  pageListReceived,
  publicIncidentHistoryReceived,
  publicViewStatusPageReceived,
  serviceListReceived,
  serviceSideListReceived,
  singlePageListReceived,
  subscriberListReceived,
} from "./actions";

export const requestPageList = (params: PageListParams) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/statuspage/page",
      method: "GET",
      params: { ...params },
    })
      .then((res) => {
        dispatch(pageListReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};
export const requestPageListByID = (pageID: Number) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/page/${pageID}`,
      method: "GET",
    })
      .then((res) => {
        dispatch(pageListReceived(res.data));
        dispatch(singlePageListReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestCreateStatuspage = (
  body: StatusPageSettings,
  callback?: (success: boolean, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/statuspage/page",
      method: "POST",
      data: typeof body === "object" ? body : {},
    })
      .then((res) => {
        if (callback) callback(res?.data?.status, res?.data);
        if (res?.data) {
          dispatch({ type: "CREATE_STATUS_PAGE_SUCCESS", payload: res.data });
          if (res?.status == 200) {
            dispatch(
              showToast("success", `New Status page created Successfully!!`)
            );
            if (callback) {
              callback(true);
            }
          }
        }
        if (res?.data?.error && res.data.error !== "") {
          dispatch(showToast("error", res.data.error));
        }
      })
      .catch((err) => {
        if (callback) callback(false);
        console.error("list: error", err);
      });
  };
};

export const requestUpdateStatuspage = (
  body: StatusPageSettings,
  pageID: string,
  callback?: (success: boolean, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/page/${pageID}`,
      method: "PUT",
      data: typeof body === "object" ? body : {},
    })
      .then((res) => {
        if (callback) callback(res?.data?.status, res?.data);
        if (res?.data) {
          dispatch({ type: "CREATE_STATUS_PAGE_SUCCESS", payload: res.data });
          if (res?.status == 200) {
            dispatch(showToast("success", `Status page Update Successfully!!`));
            if (callback) {
              callback(true);
            }
          }
        }
        if (res?.data?.error && res.data.error !== "") {
          dispatch(showToast("error", res.data.error));
        }
      })
      .catch((err) => {
        if (callback) callback(false);
        console.error("list: error", err);
      });
  };
};

export const requestCreateService = (
  body: Service,
  callback?: (success: boolean, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/statuspage/service",
      method: "POST",
      data: typeof body === "object" ? body : {},
    })
      .then((res) => {
        if (callback) callback(res?.data?.status, res?.data);
        if (res?.data) {
          dispatch({ type: "CREATE_STATUS_PAGE_SUCCESS", payload: res.data });
          if (res?.status == 200) {
            if (body.is_added) {
              dispatch(showToast("success", `Service added Successfully!!`));
            } else {
              dispatch(
                showToast("success", `New Service created Successfully!!`)
              );
            }
            if (callback) {
              callback(true);
            }
          }
        }
        if (res?.data?.error && res.data.error !== "") {
          dispatch(showToast("error", res.data.error));
        }
      })
      .catch((err) => {
        if (callback) callback(false);
        console.error("list: error", err);
      });
  };
};

export const requestServiceList = (body: {
  page_id: string;
  sidelist?: boolean;
}) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/statuspage/service",
      method: "GET",
      params: { ...body },
      data: typeof body == "object" ? body : {},
    })
      .then((res) => {
        if (body.sidelist) {
          dispatch(serviceSideListReceived(res.data));
        } else {
          dispatch(serviceListReceived(res.data));
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestAllServiceList = () => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/statuspage/servicelist",
      method: "GET",
    })
      .then((res) => {
        dispatch(serviceListReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestServiceIncidentHistoryList = (pageID: Number) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/servicehistory/${pageID}`,
      method: "GET",
    })
      .then((res) => {
        dispatch(incidentServiceHistoryReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestServiceIncidentHistoryListPublic = (
  pageID: Number,
  project_id: Number
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/public/statuspage/servicehistory/${pageID}/${project_id}`,
      method: "GET",
    })
      .then((res) => {
        dispatch(incidentServiceHistoryReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};
export const requestPublicPageIncidentHistory = (
  pageID: Number,
  project_id: Number
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/public/statuspage/incidenthistory/${pageID}/${project_id}`,
      method: "GET",
    })
      .then((res) => {
        dispatch(publicIncidentHistoryReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestCurrentSyntheticStatus = (
  pageId: Number,
  projectId: Number,
  syntheticIds: Number[]
) => {
  const currentDate = new Date();
  const tenMinutesAgoDate = new Date(currentDate.getTime() - 10 * 60 * 1000);

  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/synthetics/uptime_metrics/${pageId}/${projectId}`,
      method: "GET",
      params: {
        ids: syntheticIds.join(","),
        start_ts: tenMinutesAgoDate.getTime(),
        end_ts: currentDate.getTime(),
      },
    })
      .then((res) => {
        dispatch(currentStatusOfSyntheticReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestSubscriberList = (pageID: Number) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/subscriber/page/${pageID}`,
      method: "GET",
    })
      .then((res) => {
        dispatch(subscriberListReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestSubmitSubscriber = (
  body: Subscriber,
  callback?: (success: boolean, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/subscriber/`,
      method: "POST",
      data: typeof body === "object" ? body : {},
    })
      .then((res) => {
        if (callback) callback(res?.data?.status, res?.data);
        if (res?.data) {
          dispatch({ type: "CREATE_STATUS_PAGE_SUCCESS", payload: res.data });
          if (res?.status == 200) {
            if (res?.data?.status === true) {
              dispatch(
                showToast("success", `New Subscriber Create Successfully!!`)
              );
              dispatch(requestSubscriberList(Number(body.page_id)));
              if (callback) {
                callback(true);
              }
            } else {
              dispatch(showToast("error", `Subscriber Already Exists`));
            }
          }
        }
        if (res?.data?.error && res.data.error !== "") {
          dispatch(showToast("error", res.data.error));
        }
      })
      .catch((err) => {
        if (callback) callback(false);
        console.error("list: error", err);
      });
  };
};

export const requestUpdateSubscriber = (
  body: Subscriber,
  callback?: (success: boolean, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/subscriber/${body.id}`,
      method: "PUT",
      data: typeof body === "object" ? body : {},
    })
      .then((res) => {
        if (callback) callback(res?.data?.status, res?.data);
        if (res?.data) {
          dispatch({ type: "UPDATE_SUBSCRIBER_SUCCESS", payload: res.data });
          if (res?.status == 200) {
            dispatch(showToast("success", `Subscriber updated successfully!!`));
            dispatch(requestSubscriberList(Number(body.page_id)));
            if (callback) {
              callback(true);
            }
          }
        }
        if (res?.data?.error && res.data.error !== "") {
          dispatch(showToast("error", res.data.error));
        }
      })
      .catch((err) => {
        if (callback) callback(false);
        console.error("list: error", err);
      });
  };
};

export const requestDeleteSubscriber = (
  body: Subscriber,
  callback?: (success: boolean, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/subscriber/${body.id}`,
      method: "DELETE",
    })
      .then((res) => {
        if (callback) callback(res?.data?.status, res?.data);
        if (res?.data) {
          dispatch({ type: "DELETE_SUBSCRIBER_SUCCESS", payload: res.data });
          if (res?.status == 200) {
            dispatch(showToast("success", `Subscriber deleted successfully!!`));
            dispatch(requestSubscriberList(Number(body.page_id)));
            if (callback) {
              callback(true);
            }
          }
        }
        if (res?.data?.error && res.data.error !== "") {
          dispatch(showToast("error", res.data.error));
        }
      })
      .catch((err) => {
        if (callback) callback(false);
        console.error("list: error", err);
      });
  };
};

export const requestPublicSubmitSubscriber = (
  body: PublicSbscriber,
  callback?: (success: boolean, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/public/subscriber`,
      method: "POST",
      data: typeof body === "object" ? body : {},
    })
      .then((res) => {
        if (callback) callback(res?.data?.status, res?.data);
        if (res?.data) {
          dispatch({ type: "CREATE_STATUS_PAGE_SUCCESS", payload: res.data });
          if (res?.status == 200) {
            if (res?.data?.status === true) {
              dispatch(
                showToast("success", `New Subscriber Create Successfully!!`)
              );
            } else {
              dispatch(showToast("error", `Subscriber Already Exists`));
            }
          }
        }
        if (res?.data?.error && res.data.error !== "") {
          dispatch(showToast("error", res.data.error));
        }
      })
      .catch((err) => {
        if (callback) callback(false);
        console.error("list: error", err);
      });
  };
};

export const requestIncidentList = (params: incidentListParams) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/statuspage/incident",
      method: "GET",
      params: { ...params },
    })
      .then((res) => {
        dispatch(incidentListReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestCreateIncident = (
  body: Incident,
  callback?: (success: boolean, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/statuspage/incident",
      method: "POST",
      data: typeof body === "object" ? body : {},
    })
      .then((res) => {
        if (callback) callback(res?.data?.status, res?.data);
        if (res?.data) {
          dispatch({ type: "CREATE_INCIDENT_SUCCESS", payload: res.data });
          if (res?.status == 200) {
            dispatch(
              showToast("success", `New Incident created Successfully!!`)
            );
            if (callback) {
              callback(true);
            }
          }
        }
        if (res?.data?.error && res.data.error !== "") {
          dispatch(showToast("error", "Type or Criticality not selected"));
        }
      })
      .catch((err) => {
        if (callback) callback(false);
        console.error("list: error", err);
      });
  };
};

export const requestUpdateIncident = (
  body: Incident,
  incidentId: string,
  callback?: (success: boolean, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/incident/${incidentId}`,
      method: "PUT",
      data: typeof body === "object" ? body : {},
    })
      .then((res) => {
        if (callback) callback(res?.data?.status, res?.data);
        if (res?.data) {
          dispatch({ type: "CREATE_INCIDENT_SUCCESS", payload: res.data });
          if (res?.status == 200) {
            dispatch(showToast("success", `Incident Update Successfully!!`));
            if (callback) {
              callback(true);
            }
          }
        }
        if (res?.data?.error && res.data.error !== "") {
          dispatch(showToast("error", res.data.error));
        }
      })
      .catch((err) => {
        if (callback) callback(false);
        console.error("list: error", err);
      });
  };
};

export const requestUpdateService = (
  body: UpdateStatus,
  callback?: (success: boolean, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/service/${body.id}`,
      method: "PUT",
      data: typeof body === "object" ? body : {},
    })
      .then((res) => {
        if (callback) callback(res?.data?.status, res?.data);
        if (res?.data) {
          dispatch({ type: "CREATE_INCIDENT_SUCCESS", payload: res.data });
          if (res?.status == 200) {
            if (body.is_added) {
              dispatch(showToast("success", `Service added successfully!!`));
            } else {
              dispatch(showToast("success", `Service removed successfully!!`));
            }
            if (callback) {
              callback(true);
            }
          }
        }
        if (res?.data?.error && res.data.error !== "") {
          dispatch(showToast("error", res.data.error));
        }
      })
      .catch((err) => {
        if (callback) callback(false);
        console.error("list: error", err);
      });
  };
};

export const requestIncidentHistory = (incidentId: string) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/incident/history/${incidentId}`,
      method: "GET",
    })
      .then((res) => {
        dispatch(incidentHistoryReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestSingleIncident = (incidentId: number) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/incident/${incidentId}`,
      method: "GET",
    })
      .then((res) => {
        dispatch(incidentSingleReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestDeleteServiceHistory = (
  pageID: Number,
  serviceId: Number
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/servicehistory/${pageID}`,
      method: "GET",
    })
      .then((res) => {
        dispatch(subscriberListReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getPublicStatusPageView = (domain_name: string) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/public/${domain_name}`,
      method: "GET",
    })
      .then((res) => {
        dispatch(publicViewStatusPageReceived(res.data));
        return res.data;
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestServiceListPublic = (body: {
  page_id: string;
  project_id: number;
  sidelist?: boolean;
}) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/public/statuspage/service",
      method: "GET",
      params: { ...body },
      data: typeof body == "object" ? body : {},
    })
      .then((res) => {
        if (body.sidelist) {
          dispatch(serviceSideListReceived(res.data));
        } else {
          dispatch(chartAndServiceListReceived(res.data));
          dispatch(serviceListReceived(res.data));
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const checkIsURLAvailable = (
  body: { url: string; page_id: string; domain_url: string },
  cb?: (a2?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/publicurl/${body.url}/${body.page_id}/${body.domain_url}`,
      method: "get",
      data: {},
    })
      .then((res) => {
        if (cb) {
          cb(res.data);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestPublicSlackSubscriber = (
  body: { page_id: Number; host: string; project_id: Number },
  cb?: (a2?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/public/subscribe/slack/${body.page_id}/${body.host}/${body.project_id}`,
      method: "GET",
      data: {},
    })
      .then((res) => {
        if (cb) {
          cb(res.data);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const deletePage = (pageID: Number, cb?: (a2?: any) => void) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/page/${pageID}/`,
      method: "DELETE",
      data: {},
    })
      .then((res) => {
        if (cb) {
          cb(res.data);
        }
      })
      .then((res) => {
        const _params: PageListParams = {};
        dispatch(requestPageList(_params));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const addDomaintoCloudflare = (
  body: { domain_name: string },
  cb?: (a2?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/publicurl/${body.domain_name}`,
      method: "POST",
      data: {},
    })
      .then((res) => {
        if (cb) {
          cb(res.data);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getDomainFromCloudflare = (
  body: { domain_name: string },
  cb?: (a2?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/customdomain/${body.domain_name}`,
      method: "GET",
      data: {},
    })
      .then((res) => {
        if (cb) {
          cb(res.data);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const deleteCloudflareDomain = (
  body: { domain_name: string },
  cb?: (a2?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/statuspage/customdomain/${body.domain_name}`,
      method: "DELETE",
      data: {},
    })
      .then((res) => {
        if (cb) {
          cb(res.data);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};
