import * as React from "react";
import {AddFilledCircleSvg} from "core/components/svg/icons";
import PopoverMenuItem from "core/components/popover/menu-item";
import ActionMenu from "core/components/action-menu";
import PopoverMenuSearchCard from "core/components/popover/menu-search";

const WidgetActions = (props: any) => {
const [searchStr, setSearchStr] = React.useState<string>("");

    const attributes = props.options.filter((v: any) => {
        return searchStr ? v.name.toLowerCase().includes(searchStr.toLowerCase()) : v.name;
    }).sort((a: any, b: any) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });

    return <div className={"widget_actions__wrapper"}>
        <ActionMenu
            className={`widget_actions__button_wrapper ${props?.btnSmall ? "btn__small" : ""}`}
            iconBeforeText
            position="bottom left"
            buttonIcon={<AddFilledCircleSvg/>}
            additionalText={'Add Widget'}
            popoverMenuClassName={"max_sm"}
            onClose={() => {
                setSearchStr("")
            }}
            buttonAttr={{'primary':true,'borderless':false,'action':true}}>
            <PopoverMenuSearchCard sticky onSearch={(e: any) => setSearchStr(e)}/>
            {attributes.map((item: any, index: number) => {
                return <PopoverMenuItem placeholderIcon={item?.icon} key={index} onClick={() => {
                    props.callback(item)
                }}>{item.name}</PopoverMenuItem>
            })}
        </ActionMenu>
    </div>
}

export default WidgetActions