import CreateRequest from "core/xhr";
import {
    agentIntegrationDetailsReceived,
    tasksReceived
} from './actions'
import {slackChannelReceived} from "store/alert/actions";


export const addTask = (body:{status:string,assigned_to:string},cb?: (a2?: any) => void) => {
   console.log("Hello from add Tasks")
}

export const assignTask = (body:any,cb?: (a2?: any) => void) => {
    body.status = typeof(body.status) == "string" ? body.status : body.status[0];
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/task_management/assigned",
            method: 'post',
            data: body
        }).then(res => {
            if (cb) {
                cb(res.data)
                // dispatch(K8PodListReceived(res.data))
            }
        }).catch(err => {
            if (cb) {
                cb(err)
            }
        });
    }
}


export const getProjectList = (body:{email:string,auth_token:string,url:string},cb?: (a2?: any) => void) => {
    // return;
    // return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/task_management/getprojectlist",
            method: 'post',
            data: body
        }).then(res => {
            if (cb) {
                cb(res.data)
            }
        }).catch(err => {
            if (cb) {
                cb(err)
            }
        });
    // }
}

export const InsertPagerDutyAuth = (body:{email:string,access_token:string,serial_key:string},cb?:(a2?:any) => void) => {
    return CreateRequest({
        url: "/task_management/insertpagerdutyauth",
        method: 'post',
        data: body
    }).then(res => {
        if(cb) {
            cb(res)
        }
    }).catch(err => {
        if(cb){
            cb(err)
        }
    });
}

export const InsertOpsgenieAuth = (body:{email:string,access_token:string,serial_key:string},cb?:(a2?:any) => void) => {
    return CreateRequest({
        url: "/task_management/insertopsgenieauth",
        method: 'post',
        data: body
    }).then(res => {
        if(cb) {
            cb(res.data)
        }
    }).catch(err => {
        if(cb){
            cb(err)
        }
    });
}

export const getPagerDutyList = (cb?:any) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/task_management/getpagerdutylist",
            method: 'get',
            data: {}
        }).then( res => {
            cb(res.data)
            // dispatch(msTeamsChannelReceived(res.data))
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}


export const getOpsgenieList = (cb?: any) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/task_management/getopsgenielist",
            method: 'get',
            data: {}
        }).then(res => {
            if (cb) cb(res.data);
            // dispatch(msTeamsChannelReceived(res.data))
            return res.data; // Return the response data
        }).catch(err => {
            console.log("list: error", err);
            throw err; // Propagate the error
        });
    }
}

//(body:{summary:string,name:string, description:string,project:string,reference_id:number},

export const createTask = (body:any,cb?: (a2?: any) => void) => {
    return CreateRequest({
        url: "/task_management/createjiratask",
        method: 'post',
        data: body
    }).then(res => {
        if (cb) {
            cb(res.data)
        }
    }).catch(err => {
        if (cb) {
            cb(err)
        }
    });
    // }
}

export const createPagerDutyTask = (body:any,cb?: (a2?: any) => void) => {
    return CreateRequest({
        url: "/task_management/createpagerdutytask",
        method: 'post',
        data: body
    }).then(res => {
        if (cb) {
            cb(res)
        }
    }).catch(err => {
        if (cb) {
            cb(err)
        }
    });
}

export const createOpsgenieTask = (body:any,cb?: (a2?: any) => void) => {
    return CreateRequest({
        url: "/task_management/createopsgenietask",
        method: 'post',
        data: body
    }).then(res => {
        if (cb) {
            cb(res)
        }
    }).catch(err => {
        if (cb) {
            cb(err)
        }
    });
}


export const getJiraDetails = (cb?:any) => {
    return CreateRequest({
        url: "/task_management/getjiradetails",
        method: 'get',
        data: {}
    }).then( res => {
        cb(res.data)
        // dispatch(requestSlackUrl(res.data))
    }).catch( err => {
        console.log("list: error", err)
    });
}

export const getCheckIsIntegratedDetails = (cb?:any) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/task_management/agent-integration-details",
            method: 'get',
            data: {}
        }).then( res => {
            dispatch(agentIntegrationDetailsReceived(res.data))
            cb(res.data)
            // dispatch(requestSlackUrl(res.data))
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const taskDetailsJira = (body:{reference_id: any},cb?: (a2?: any) => void) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: `/task_management/task_detail_jira/${body.reference_id}`,
            method: 'get',
            data: {}
        }).then( res => {
            if(cb){
                cb(res.data)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const getPagerDutyIncidentList = (body:{reference_id: any},cb?: (a2?: any) => void) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: `/task_management/task_detail_pagerduty/${body.reference_id}`,
            method: 'get',
            data: {}
        }).then( res => {
            if(cb){
                cb(res.data)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const getOpsgenieIncidentList = (body:{reference_id: any},cb?: (a2?: any) => void) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: `/task_management/task_detail_opsgenie/${body.reference_id}`,
            method: 'get',
            data: {}
        }).then( res => {
            if(cb){
                cb(res.data)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const removePagerDutyAuth = (cb?:any) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/removepagerdutyauth",
            method: 'get',
            data: {}
        }).then( res => {
            cb(res.data)
            dispatch(slackChannelReceived(res.data))
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const removeOpsgenieAuth = (cb?:any) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/removeopsgenieauth",
            method: 'get',
            data: {}
        }).then( res => {
            cb(res.data)
            dispatch(slackChannelReceived(res.data))
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const removeJiraAuth = (cb?:any) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/removejiraauth",
            method: 'get',
            data: {}
        }).then( res => {
            cb(res.data)
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}
