import React, {useEffect} from "react";
import {useState} from "react";
import Dialog from "core/components/dialog";
import LinuxInfraInstrument from "views/modules/integrations/components/dialog/infra-dialog/linux-integration-dialog";
import useQuery from "core/components/query";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import {IntegrationItem} from "views/modules/integrations/entity";

type InstrumentProps = {
    items: IntegrationItem[]
}

export default function InstrumentationItems(props: InstrumentProps) {
    const query = useQuery()
    const routeData = React.useContext(MwRouteContext);
    const integration = query.get("installation_integration")
    const instrumentData = props.items


    const [openDialog, setOpenDialog] = useState(false)
    const [component, setComponent] = useState(<LinuxInfraInstrument/>)
    const getPopUp = (key: string) => {
        routeData.params.query.set('installation_integration', key)
        routeData.params.query.redirect()
    }
    useEffect(() => {
        if (integration != null) {
            const foundItem = instrumentData.find(item => integration === item.key);
            if (foundItem) {
                setOpenDialog(true);
                setComponent(foundItem.component);
            } else {
                setOpenDialog(false);
            }
        } else {
            setOpenDialog(false)
        }

    }, [integration]);
    return <>
        {
            instrumentData.map((item, index) => {
                return <div key={index} className={'integration-screen'}>
                    <div className="features-main" onClick={() => {
                        getPopUp(item.key)
                    }}>
                        <div style={{borderColor: item.borderColor}} className={`feature linux`}>
                            <div className="feature__head">
                                <div className={"button_icon_placement"}>
                                    <div className="svg-icon db-monitoring-icon-svg">
                                        {item.icon}
                                    </div>
                                    <div className={"feature__title"}>
                                        <span>{item.title}</span>
                                        {(item.key == 'go-serverless' || item.key == 'nodejs-serverless') ?
                                            <p className={'serverless-integration'}>Serverless</p> : ''}
                                    </div>
                                </div>
                            </div>
                            <p className="feature__desc">{item.desc}</p>

                        </div>
                    </div>
                </div>
            })
        }

        <Dialog
            overlayClassName={'jira-integration'}
            isOpen={openDialog}
            position={"right"}
            onClose={() => {
                setOpenDialog(false)
                routeData.params.query.delete('installation_integration')
                routeData.params.query.redirect()
            }}>
            {component}
        </Dialog>
    </>
}