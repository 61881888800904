import React, { useContext } from "react";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import BuilderView from "views/modules/builder/views";
import { BuilderViewProps, DateRange } from "views/modules/logs-v2/types";
// import { generateChartId } from "core/components/charts/common/utils";

export const RenderGraph = ({
  title,
  column,
  chartType,
  groupBy,
  filters,
  limit,
  sortBy,
  resourceType,
  widgetAppId,
  columnConfig,
  titleInfo = "",
  expandableTitleOptions,
}: BuilderViewProps) => {
  const routerContext = useContext(MwRouteContext);
  const dateRange: DateRange = {
    fromTs: routerContext?.params.dateRange.fromTs || 0,
    toTs: routerContext?.params.dateRange.toTs || 0,
  };
  return (
    <BuilderView
      expandableTitleOptions={{
        showExpandable: expandableTitleOptions?.showExpandable || false,
        titleOnClick: expandableTitleOptions?.titleOnClick,
      }}
      boxTitle={title}
      builderViewOptions={{
        displayScope: "",
        resource: {
          columns: column,
          resourceType: resourceType ? resourceType : "log",
          name: title,
          widgetAppId: widgetAppId,
          granularity: {
            ingest_interval: 60,
            value: routerContext.params.dateRange.interval ?? 60,
          },
        },
      }}
      filters={filters || []}
      groupBy={groupBy}
      dateRange={dateRange}
      nestedProps={{
        columnConfig: columnConfig,
        titleInfo: titleInfo,
        chartType: chartType,
        showLegend: false,
        stacked: chartType === "bar_chart",
        stackId: chartType === "bar_chart" ? "1" : null,
      }}
      sortBy={sortBy}
      limit={limit}
    />
  );
};
