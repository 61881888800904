import React, { useRef, useState, useEffect } from 'react';
import "./_style.scss"
import PlayerControls from './PlayerControls';
import 'devices.css/dist/devices.css'

interface VideoProps {
  device: string,
  videoUrl: string,
  controls?: boolean,
  playerCallback?: any
}

const MobileVideoPlayer: React.FC<VideoProps> = (props: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(document.fullscreenElement !== null);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  return (
    <div className='video-container'>

      <div className={`device device-${props.device}`} style={{ height: "675px", width: "360px" }}>
        <div className="device-frame" style={{ height: "675px", width: "360px" }}>
          {isLoading && <div className="spinner"></div>}

          <video 
          className="device-screen"
          onContextMenu={(event: any) => event.preventDefault()}
          style={{ height: "100%", width: "100%", objectFit: isFullScreen ? "contain" : "cover" }}
            ref={videoRef}
            width="100%"
            height="100%"
            controls={props?.controls}
            onLoadedData={() => setIsLoading(false)}>
            <source src={props?.videoUrl} type="video/mp4" />
          </video>
        </div>
        <div className="device-stripe"></div>
        <div className="device-header"></div>
        <div className="device-sensors"></div>
        <div className="device-btns"></div>
        <div className="device-power"></div>
      </div>
      <PlayerControls
        videoRef={videoRef}
        gotoCallback={props?.playerCallback}
      />
    </div>
  );
};

export default MobileVideoPlayer;
