import * as React from "react";
import {
  TypographyHeadingPrimary,
  TypographyParagraphPrimary800,
  TypographyParagraphSteps,
} from "core/components/typography";
import { useContext, useState } from "react";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import { showToast } from "store/toast-alerts/actions";
import { useDispatch } from "react-redux";
import {
  AWSECSEC2Setup,
  AWSECSFargateSetup,
  CopyIconNew,
} from "core/components/svg/icons";
import TabItems from "core/components/nav-tab/items";
import NavTab from "core/components/nav-tab";
import CodeViewer from "core/components/code-viewer";
import GoToDashboard from "../common/goto-dashboard";
import { getTenantUrl } from "core/application/utils";

export default function AwsEcsInstallation() {
  const [activeDeployment, setActiveDeployment] = useState("fargate");
  const dispatch = useDispatch();
  const context = useContext<ContextProps>(AppContext);
  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      dispatch(showToast("success", "Copied to Clipboard !"));
    });
  }

  const commonScript =
    "{\n" +
    '    "name": "mw-agent",\n' +
    '    "image": "ghcr.io/middleware-labs/mw-host-agent:master",\n' +
    '    "cpu": 256,\n' +
    '    "portMappings": [\n' +
    "        {\n" +
    '            "name": "8006-tcp",\n' +
    '            "containerPort": 8006,\n' +
    '            "hostPort": 8006,\n' +
    '            "protocol": "tcp",\n' +
    '            "appProtocol": "http"\n' +
    "        }\n" +
    "    ],\n" +
    '    "essential": true,\n' +
    '    "environment": [\n' +
    "        {\n" +
    '            "name": "MW_API_KEY",\n' +
    `            \"value\": \"${context.user?.account?.agent_config?.token}\"\n` +
    "        },\n" +
    "        {\n" +
    '            "name": "MW_TARGET",\n' +
    `            \"value\": \"${capturePath}\"\n` +
    "        },\n" +
    "    ],\n" +
    '    "mountPoints": [],\n' +
    '    "volumesFrom": [],\n' +
    '    "logConfiguration": {\n' +
    "        <Setup Log Configurations as per your requirement>\n" +
    "    }\n" +
    "}\n";

  const logsScript =
    "{\n" +
    '    "name": "log_router",\n' +
    '    "image": "amazon/aws-for-fluent-bit:stable",\n' +
    '    "cpu": 0,\n' +
    '    "portMappings": [],\n' +
    '    "essential": true,\n' +
    '    "environment": [],\n' +
    '    "mountPoints": [],\n' +
    '    "volumesFrom": [],\n' +
    '    "user": "0",\n' +
    '    "firelensConfiguration": {\n' +
    '        "type": "fluentbit",\n' +
    '        "options": {\n' +
    '            "enable-ecs-log-metadata": "true"\n' +
    "        }\n" +
    "    }\n" +
    "}\n";

  const logsScript1 =
    '"logConfiguration": {\n' +
    '    "logDriver": "awsfirelens",\n' +
    '    "options": {\n' +
    '        "Host": "127.0.0.1",\n' +
    '        "Name": "forward",\n' +
    '        "Port": "8006"\n' +
    "    }\n" +
    "}\n";

  const cmdEC2JSON =
    "{\n" +
    '"containerDefinitions": [\n' +
    "    {\n" +
    '    "name": "mw-agent",\n' +
    '    "image": "ghcr.io/middleware-labs/mw-host-agent:master",\n' +
    '    "cpu": 100,\n' +
    '    "memory": 512,\n' +
    '    "essential": true,\n' +
    '    "portMappings": [\n' +
    "        {\n" +
    '        "name": "mw-agent-9319-tcp",\n' +
    '        "containerPort": 9319,\n' +
    '        "hostPort": 9319,\n' +
    '        "protocol": "tcp",\n' +
    '        "appProtocol": "grpc"\n' +
    "        },\n" +
    "        {\n" +
    '        "name": "mw-agent-8006-tcp",\n' +
    '        "containerPort": 8006,\n' +
    '        "hostPort": 8006,\n' +
    '        "protocol": "tcp"\n' +
    "        },\n" +
    "        {\n" +
    '        "name": "mw-agent-9320-tcp",\n' +
    '        "containerPort": 9320,\n' +
    '        "hostPort": 9320,\n' +
    '        "protocol": "tcp"\n' +
    "        }\n" +
    "    ],\n" +
    '    "environment": [\n' +
    "        {\n" +
    '        "name": "MW_API_KEY",\n' +
    `        \"value\": \"${context.user?.account?.agent_config?.token}\"\n` +
    "        },\n" +
    "        {\n" +
    '        "name": "MW_TARGET",\n' +
    `        \"value\": \"${capturePath}\"\n` +
    "        }\n" +
    "    ],\n" +
    '    "mountPoints": [\n' +
    "        {\n" +
    '        "sourceVolume": "docker-sock",\n' +
    '        "containerPath": "/var/run/docker.sock",\n' +
    '        "readOnly": false\n' +
    "        }\n" +
    "    ]\n" +
    "    }\n" +
    "],\n" +
    '"volumes": [\n' +
    "    {\n" +
    '    "name": "docker-sock",\n' +
    '    "host": {\n' +
    '        "sourcePath": "/var/run/docker.sock"\n' +
    "    }\n" +
    "    }\n" +
    "],\n" +
    '"family": "mw-agent-task"\n' +
    "}\n";

  const cmdEC2Logs =
    '"containerDefinitions": [\n' +
    "        {\n" +
    "        (...)\n" +
    '        "logConfiguration": {\n' +
    '                "logDriver": "fluentd",\n' +
    '                "options": {\n' +
    '                    "fluentd-address": "172.17.0.1:8006"\n' +
    "                }\n" +
    "            }\n" +
    "        (...)\n" +
    "        }\n" +
    "    ]\n";

  const cmdEC2Traces =
    '"containerDefinitions": [\n' +
    "        {\n" +
    "        (...)\n" +
    '        "environment": [\n' +
    "                {\n" +
    '        "name": "MW_AGENT_SERVICE",\n' +
    '        "value": "172.17.0.1"\n' +
    "            }\n" +
    " ],\n" +
    "        (...)\n" +
    "       }\n" +
    "    ]\n";

  const shortJSONE2 =
    "{\n" +
    '"containerDefinitions": [\n' +
    "    {\n" +
    '    "name": "mw-agent",\n' +
    '    "image": "ghcr.io/middleware-labs/mw-host-agent:master",\n' +
    '    "cpu": 100,\n' +
    '    "memory": 512,\n' +
    '    "essential": true,\n' +
    '    "portMappings": [\n' +
    "        {\n" +
    '        "name": "mw-agent-9319-tcp",' +
    "..........";

  const commonScriptShort =
    "{\n" +
    '    "name": "mw-agent",\n' +
    '    "image": "ghcr.io/middleware-labs/mw-host-agent:master",\n' +
    '    "cpu": 256,\n' +
    '    "portMappings": [\n' +
    "        {\n" +
    '            "name": "8006-tcp",\n' +
    '            "containerPort": 8006,\n' +
    '            "hostPort": 8006,\n' +
    '            "protocol": "tcp",\n' +
    '            "appProtocol": "http"\n' +
    "        }" +
    "..........";

  const [codeCollaps, setcodeCollaps] = useState();
  const [codeCollapsFargate, setcodeCollapsFargate] = useState();
  const toggleCodeVisibility = () => {
    // @ts-ignore
    setcodeCollaps(!codeCollaps);
  };
  const toggleCodeVisibilityFargate = () => {
    // @ts-ignore
    setcodeCollapsFargate(!codeCollapsFargate);
  };

  return (
    <div className={"markdown-body installation-page aws-ecs-docs"}>
      <div className={"page-header"}>
        <div className={"title"}>
          <TypographyHeadingPrimary>
            Installing the Middleware Agent on AWS ECS.
          </TypographyHeadingPrimary>
        </div>
        <div className={"description"}>
          <TypographyParagraphPrimary800>
            Install the Middleware Agent (MW Agent) to observe your AWS ECS
            machine and the applications running inside them.
          </TypographyParagraphPrimary800>
        </div>
      </div>
      <div className={"page-body"}>
        <div className={"step"}>
          <div className={"details"}>
            <div className={"count"}>
              <span>1</span>
            </div>
            <div className={"detail"}>
              <TypographyParagraphSteps>
                Download the MW Agent for AWS ECS and follow the installation
                prompts.
              </TypographyParagraphSteps>
            </div>
          </div>
        </div>
        <div className={"infra-button-aws"}>
          <button
            className={activeDeployment === "fargate" ? "active" : ""}
            onClick={() => {
              setActiveDeployment("fargate");
            }}
          >
            <AWSECSFargateSetup /> Fargate Setup
          </button>
          <button
            className={activeDeployment === "ec2" ? "active" : ""}
            onClick={() => setActiveDeployment("ec2")}
          >
            <AWSECSEC2Setup /> EC2 Setup
          </button>
        </div>
        <div className={"infra-aws-steps"}>
          {activeDeployment === "fargate" ? (
            <NavTab defaultActiveTabIndex={0}>
              <TabItems title={"Logs"}>
                <div className={"content-infra-aws"}>
                  <p className={"heading"}>
                    1. Add Middleware Agent sidecar in your ECS Task container
                    definitions.
                  </p>
                  {codeCollapsFargate && (
                    <div className={"docs-script"}>
                      <CodeViewer showLineNumbers={false} language={"json"}>
                        {commonScript}
                      </CodeViewer>
                      <span
                        className={"doc-copy-btn"}
                        onClick={() => copyToClipboard(commonScript)}
                      >
                        <CopyIconNew size={8} />
                      </span>
                    </div>
                  )}

                  {!codeCollapsFargate && (
                    <div className={"docs-script"}>
                      <CodeViewer showLineNumbers={false} language={"json"}>
                        {commonScriptShort}
                      </CodeViewer>
                      <span
                        className={"doc-copy-btn"}
                        onClick={() => copyToClipboard(commonScript)}
                      >
                        <CopyIconNew size={8} />
                      </span>
                    </div>
                  )}

                  <div className={"collaps-expand"}>
                    {codeCollapsFargate ? (
                      <button
                        className={"collaps-btn"}
                        onClick={toggleCodeVisibilityFargate}
                      >
                        Collapse JSON
                      </button>
                    ) : (
                      <button
                        className={"expand-btn"}
                        onClick={toggleCodeVisibilityFargate}
                      >
                        Expand JSON
                      </button>
                    )}
                  </div>

                  <p className={"heading"}>
                    2. Add a fluent sidebar in your ECS task.
                  </p>
                  <div className={"docs-script"}>
                    <CodeViewer showLineNumbers={false} language={"json"}>
                      {logsScript}
                    </CodeViewer>
                    <span
                      className={"doc-copy-btn"}
                      onClick={() => copyToClipboard(logsScript)}
                    >
                      <CopyIconNew size={8} />
                    </span>
                  </div>

                  <p className={"heading"}>
                    3. Add a log Configuration in your main container.
                  </p>
                  <div className={"docs-script"}>
                    <CodeViewer showLineNumbers={false} language={"json"}>
                      {logsScript1}
                    </CodeViewer>
                    <span
                      className={"doc-copy-btn"}
                      onClick={() => copyToClipboard(logsScript1)}
                    >
                      <CopyIconNew size={8} />
                    </span>
                  </div>
                </div>
              </TabItems>
              <TabItems title={"Metrics"}>
                <div className={"content-infra-aws"}>
                  <p className={"heading"}>
                    1. Add Middleware Agent sidecar in your ECS Task
                    containerdefinitions.
                  </p>
                  <div className={"docs-script"}>
                    <CodeViewer showLineNumbers={false} language={"json"}>
                      {commonScript}
                    </CodeViewer>
                    <span
                      className={"doc-copy-btn"}
                      onClick={() => copyToClipboard(commonScript)}
                    >
                      <CopyIconNew size={8} />
                    </span>
                  </div>
                </div>
              </TabItems>
            </NavTab>
          ) : (
            <>
              <div className={"content-infra-aws"}>
                <p className={"heading"}>
                  1. Create Middleware Middleware Agent ECS Task Definition
                  using following JSON.
                </p>
                {!codeCollaps && (
                  <div className={"docs-script"}>
                    <CodeViewer showLineNumbers={false} language={"json"}>
                      {shortJSONE2}
                    </CodeViewer>
                    <span
                      className={"doc-copy-btn"}
                      onClick={() => copyToClipboard(cmdEC2JSON)}
                    >
                      <CopyIconNew size={8} />
                    </span>
                  </div>
                )}

                {codeCollaps && (
                  <div className={"docs-script"}>
                    <CodeViewer showLineNumbers={false} language={"json"}>
                      {cmdEC2JSON}
                    </CodeViewer>
                    <span
                      className={"doc-copy-btn"}
                      onClick={() => copyToClipboard(cmdEC2JSON)}
                    >
                      <CopyIconNew size={8} />
                    </span>
                  </div>
                )}

                <div className={"collaps-expand"}>
                  {codeCollaps ? (
                    <button
                      className={"collaps-btn"}
                      onClick={toggleCodeVisibility}
                    >
                      Collapse JSON
                    </button>
                  ) : (
                    <button
                      className={"expand-btn"}
                      onClick={toggleCodeVisibility}
                    >
                      Expand JSON
                    </button>
                  )}
                </div>

                <p className={"heading"}>
                  2. Set up the Middleware agent Task Definition created in Step
                  1 as an ECS Daemon Service to ensure only one Middleware Agent
                  container is running on each EC2 container instance of your
                  ECS EC2 cluster.
                </p>
                <p>
                  Ensure that you select Launch Type as EC2 while creating the
                  ECS Daemon Service.
                </p>
              </div>
              <NavTab defaultActiveTabIndex={0}>
                <TabItems title={"Logs"}>
                  <div className={"content-infra-aws"}>
                    <p>
                      To collect logs, configure the logConfiguration in your
                      application’s Task Definition JSON configuration with the
                      following
                    </p>
                    <div className={"docs-script"}>
                      <CodeViewer showLineNumbers={false} language={"json"}>
                        {cmdEC2Logs}
                      </CodeViewer>
                      <span
                        className={"doc-copy-btn"}
                        onClick={() => copyToClipboard(cmdEC2Logs)}
                      >
                        <CopyIconNew size={8} />
                      </span>
                    </div>
                  </div>
                </TabItems>

                <TabItems title={"Traces"}>
                  <div className={"content-infra-aws"}>
                    <p>
                      To collect traces, configure the environment variable in
                      your application’s Task Definition JSON configuration with
                      the following
                    </p>
                    <div className={"docs-script"}>
                      <CodeViewer showLineNumbers={false} language={"json"}>
                        {cmdEC2Traces}
                      </CodeViewer>
                      <span
                        className={"doc-copy-btn"}
                        onClick={() => copyToClipboard(cmdEC2Traces)}
                      >
                        <CopyIconNew size={8} />
                      </span>
                    </div>
                  </div>
                </TabItems>

                <TabItems title={"Metrics"}>
                  <div className={"content-infra-aws"}>
                    <p>
                      Metrics for ECS tasks and containers will start showing
                      automatically without any further changes.
                    </p>
                  </div>
                </TabItems>
              </NavTab>
            </>
          )}
        </div>

        <GoToDashboard
          dashboardURL={`/dashboards/ecs_metrics_${context?.user?.account?.project_id}`}
        />
      </div>
    </div>
  );
}
