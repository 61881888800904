import React from "react";

interface LogoLoaderProps {
  size?: number;
}

const LogoLoader = (props: LogoLoaderProps) => {
  const { size = 100 } = props;

  return (
    <div className={"logo-loader-container"}>
      <div
        className="logo-loader"
        style={{
          left: `calc(50% - ${size / 2}px)`,
          top: `calc(50% - ${size / 2}px)`,
          width: "max-content",
        }}
      >
        <img src="assets/gif/LogoLoader.gif" width={size} />
      </div>
    </div>
  );
};

export default LogoLoader;
