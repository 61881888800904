/** @format */
/**
 * External dependencies
 */
import * as React from 'react';
import classNames from 'classnames';
import ActionMenu from "../action-menu";
import PopoverMenuItem from "../popover/menu-item";
import Tooltip from "core/components/tooltip";


interface Props {
    style?: React.CSSProperties,
    bgStyle?: React.CSSProperties,
    labelText?: string | number,
    tooltipId?: string,
    tooltipText?: any,
    status?: boolean,
    radius?: boolean,
    bordered?: boolean,
    simple?: boolean,
    default?: boolean,
    primary?: boolean,
    error?: boolean,
    success?: boolean,
    warning?: boolean,
    normal?: boolean,
    info?: boolean,
    neutral?: boolean,
    onClick?: (e: any) => void,
    popoverMenuItem?: any,
    position?: string,
    popoverClassName?: string,
    className?: string,
    children?: any,
    allowPopover?: boolean
}

const StatusBadge = React.memo(function (props: Props) {
    const popoverMenuItem = (typeof props.popoverMenuItem !== "undefined" && props.popoverMenuItem.length > 0);
    const className = classNames('status-label', props.className, {
        'status': props.status,
        'simple': props.simple,
        'default': props.default,
        'radius': props.radius,
        'bordered': props.bordered,
        'primary': props.primary,
        'error': props.error,
        'success': props.success,
        'warning': props.warning,
        'normal': props.normal,
        'info': props.info,
        'neutral': props.neutral,
        'is-popover__menu': (popoverMenuItem),
    });
    const position = props.position || 'bottom left';
    const popoverClassName = props.popoverClassName || '';
    const { tooltipId } = props;

    return (<div className={className} style={props.bgStyle} onClick={(e) => {
        if (!popoverMenuItem || (popoverMenuItem && props.popoverMenuItem.length < 0)) {
            if (typeof props.onClick !== "undefined" && typeof props.onClick === "function") {
                props.onClick(e);
            }
        }
    }}>
        <span className="status-text" style={props.style} data-tip data-for={tooltipId}>
            {props.labelText && props.labelText}
            {props.children && props.children}
        </span>
        {tooltipId && props?.tooltipText && <Tooltip id={tooltipId} place={"top"} type="dark" effect="solid">
            <span>{props.tooltipText}</span>
        </Tooltip>}


        {popoverMenuItem && props.popoverMenuItem.length > 0 &&
            <ActionMenu className={"badge__popover_menu"} buttonIcon={"icon-arrow-bottom"} position={position} onClick={(e) => {
                if (typeof props.onClick !== "undefined" && typeof props.onClick === "function") {
                    props.onClick(e);
                }
            }}
                popoverClassName={popoverClassName}>
                {props.popoverMenuItem.map((menu: any, key: number) => {
                    return <PopoverMenuItem fontIcon={menu.fontIcon} isSelected={menu.selected} key={key} onClick={() => {
                        if (typeof menu.onClick === "function") menu.onClick(key, menu);
                    }} icon={menu.icon}>{menu.title}</PopoverMenuItem>
                })}
            </ActionMenu>}

    </div>);

})
StatusBadge.displayName = "StatusBadge"
export default StatusBadge
