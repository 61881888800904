import { TimeRangeOption } from "core/components/datepicker/model";
import { Location } from "react-router-dom";
import { getMinutesDifference, getRoundedInterval } from "views/modules/apm/utils";
import { DateRange, GlobalRouteParams } from "./model";
const GetRounderdOneHourTs = (ts: number) => {
    return (Math.round(ts / (1000 * 60)) * 1000) * 60;
}
export const getTimeRangeFromLocation = (locationToSearch: Location | undefined): DateRange => {
    let fromTs = 0;
    let toTs = 0;
    let interval = 60;
    let range = undefined;
    if (locationToSearch) {
        const query = new URLSearchParams(locationToSearch.search);
        const f = query.get(GlobalRouteParams.FromTs);
        const t = query.get(GlobalRouteParams.ToTs);
        if (f && t) {
            fromTs = parseInt(f);
            toTs = parseInt(t);
            interval = getRoundedInterval(parseInt(getMinutesDifference(fromTs, toTs)));
        }
        range = query.get(GlobalRouteParams.Range) as TimeRangeOption;
    }
    return {
        fromTs,
        toTs,
        interval,
        range,
    }
}
const ColoredLog = (color: string, ...args: any[]) => {
    console.log(`%c${args}`, `color: ${color}`);
}
export {
    ColoredLog, GetRounderdOneHourTs
};
