import {
  converMicroSecondsToSeconds,
  copyToClipboard,
  truncate,
} from "core/application/utils";
import Accordion from "core/components/accordion";
import Button from "core/components/button";
import Dialog from "core/components/dialog";
import NavTab from "core/components/nav-tab";
import TabItems from "core/components/nav-tab/items";
import StatusBadge from "core/components/status-badge";
import Tooltip from "core/components/tooltip";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { FaLink } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "rrweb-player/dist/style.css";
import { requestSessionRecordings } from "store/rum/api";
import { showToast } from "store/toast-alerts/actions";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import TraceSideInfoPanel from "views/modules/apm/panel/span-panel/trace_side_panel";
import { extractUserAgentInfo } from "views/modules/helper";
import RumErrorPanel from "views/modules/rum/panel/error-panel";
import Player from "views/modules/rum/panel/rr-player";
import "../_style.scss";
import MobileVideoPlayer from "../video-player";
import RenderIcon from "../utils/browser-icon/browser-icon";
import BuilderView from "views/modules/builder/views";
import {
  TPL_TYPE_COUNT,
  TPL_TYPE_TIME_UTILIZATION,
} from "views/modules/builder/entities/tpl.entities";

interface ApplicationAttr {
  "Application Name": string;
  Environment: string;
  "Application Version": string;
}

interface SessionAttr {
  "Session ID": string;
  OS: string;
  Browser: string;
  Version: string;
}

interface UserDetails {
  name: string;
  email: string;
  extra: string;
}

const SessionPanel = (props: any) => {
  const dispatch = useDispatch();
  const { dateRange } = useContext(MwRouteContext)?.params;

  const { session } = props;

  const [sessionRecording, setSessionRecording] = useState<any>({
    is_loading: false,
    data: [],
  });
  const [player, setPlayer] = useState<any>();
  const [traceInfoDialog, setTraceInfoDialog] = useState(false);
  const [traceInfoDialogData, setTraceInfoDialogData] = useState({});
  const [errorDialogSession, setErrorDialogSession] = useState(false);
  const [errorDialogSessionData, setErrorDialogSessionData] = useState({});
  const [applicationAttr, setApplicationAttr] = useState<ApplicationAttr>({
    "Application Name": "",
    Environment: "-",
    "Application Version": "-",
  });
  const [sessionAttr, setSessionAttr] = useState<SessionAttr>({
    "Session ID": "",
    OS: "-",
    Browser: "-",
    Version: "-",
  });
  const [otherAttr, setOtherAttr] = useState<any>({});
  const [userDetails, setUserDetails] = useState<UserDetails>({
    name: "-",
    email: "-",
    extra: "-",
  });
  const [initialTimestamp, setInitialTimestmap] = useState<any>();

  useEffect(() => {
    getSessionRecordings();

    setApplicationAttr({
      "Application Name": session.application_name,
      Environment: session.other_attributes?.env
        ? session.other_attributes.env
        : "-",
      "Application Version":
        props?.session?.resource_attributes &&
        props?.session?.other_attributes?.["app.version"]
          ? props?.session?.other_attributes?.["app.version"]
          : "-",
    });

    setSessionAttr({
      "Session ID": session.session_id,
      OS: session.other_attributes?.os
        ? session.other_attributes.os
        : props?.session?.resource_attributes?.["os.name"]
          ? props?.session?.resource_attributes?.["os.name"]
          : "-",
      Browser: session.other_attributes?.["navigator.userAgent"]
        ? extractUserAgentInfo(session.other_attributes["navigator.userAgent"])
            .browser
        : "-",
      Version: session.other_attributes?.["navigator.userAgent"]
        ? extractUserAgentInfo(session.other_attributes["navigator.userAgent"])
            .version
        : "-",
    });

    setOtherAttr({
      "http.host": session.other_attributes?.["http.host"]
        ? session.other_attributes["http.host"]
        : "-",
      "http.scheme": session.other_attributes?.["http.scheme"]
        ? session.other_attributes["http.scheme"]
        : "-",
      "recording.available": session.other_attributes?.["recording.available"]
        ? session.other_attributes["recording.available"]
        : "-",
      "root.url": session.other_attributes?.["root.url"]
        ? session.other_attributes["root.url"]
        : "-",
      "telemetry.sdk.language": session.resource_attributes?.[
        "telemetry.sdk.language"
      ]
        ? session.resource_attributes["telemetry.sdk.language"]
        : "-",
      "telemetry.sdk.name": session.resource_attributes?.["telemetry.sdk.name"]
        ? session.resource_attributes["telemetry.sdk.name"]
        : "-",
      "telemetry.sdk.version": session.resource_attributes?.[
        "telemetry.sdk.version"
      ]
        ? session.resource_attributes["telemetry.sdk.version"]
        : "-",
    });

    setUserDetails({
      name: session.other_attributes?.name
        ? session.other_attributes.name
        : "-",
      email: session.other_attributes?.email
        ? session.other_attributes.email
        : "-",
      extra: session.other_attributes?.extra
        ? session.other_attributes.extra
        : "-",
    });
  }, []);

  const getSessionRecordings = () => {
    const queryParams = {
      session_id: session.session_id,
      start_ts: session.start_date,
      end_ts: session.end_date,
    };

    setSessionRecording(() => ({
      data: [],
      is_loading: true,
    }));

    dispatch(
      requestSessionRecordings(queryParams, (status, res) => {
        if (status) {
          if (res.items.length > 0) {
            const playerData: any = [];
            const data = res.items;

            data.filter((item: any) => {
              playerData.push({
                data: JSON.parse(item.attributes.data),
                timestamp: parseInt(item.attributes.timestamp),
                type: parseInt(item.attributes.type),
              });
            });

            setSessionRecording({
              is_loading: false,
              data: playerData,
            });
          } else {
            setSessionRecording({
              is_loading: false,
              data: [],
            });
          }
        }
      })
    );
  };

  const NanosecondsToHoursMinutesSeconds = (nanos: number): string => {
    const seconds = nanos / 1e9;
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const secondsPart = Math.floor(remainingSeconds % 60);

    if (hours === 0) {
      return `${String(minutes).padStart(2, "0")}:${String(secondsPart).padStart(2, "0")}`;
    } else {
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(secondsPart).padStart(2, "0")}`;
    }
  };

  const formatEventType = (eventType: string, name: string) => {
    if (eventType === "load") {
      return (
        <div className={"application-row"}>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z"
              stroke="#2da44e"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.58398 6.2037C4.58398 6.2037 5.55523 4.58203 7.50065 4.58203C9.12232 4.58203 10.4173 5.8887 10.4173 7.4987C10.4173 8.85203 9.5 9.98839 8.2444 10.3191C7.50065 10.515 6.23281 10.3 5.5 9.5M4.58398 6.2037V4.58203M4.58398 6.2037L6 6.5"
              stroke="#2da44e"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span style={{ color: "#2da44e" }}>Load</span>
        </div>
      );
    } else if (eventType === "click") {
      return (
        <div className={"application-row"}>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z"
              stroke="#2575ee"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.9037 6.03986C6.71679 6.02566 6.58462 6.11591 6.52429 6.16774C6.3701 6.3002 6.30464 6.50206 6.35227 6.69999L7.26992 10.706C7.3498 11.0568 7.65271 11.1111 7.74617 11.1182C7.8214 11.1177 8.14056 11.1231 8.27874 10.7889L8.72821 9.73865C8.90416 9.31952 9.27956 8.99704 9.72033 8.88635L10.8163 8.60705C10.8183 8.60654 10.8181 8.60617 10.8202 8.60633V8.60633C10.8222 8.60649 10.8267 8.60649 10.8287 8.60599C11.1771 8.51883 11.2249 8.21686 11.2315 8.12987C11.2386 8.03639 11.2307 7.72865 10.8961 7.59668L7.06955 6.08379C7.00914 6.05413 6.95354 6.04364 6.9037 6.03986Z"
              stroke="#2575ee"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.00072 7.50069C3.50144 5.50135 5 3 8.50064 4.00133"
              stroke="#2575ee"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span style={{ color: "#2575ee" }}>Click</span>
        </div>
      );
    } else if (eventType === "error") {
      return (
        <div className={"application-row"}>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z"
              stroke="#e95e5f"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 5L5 10"
              stroke="#e95e5f"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 5L10 10"
              stroke="#e95e5f"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span style={{ color: "#e95e5f" }}>Error</span>
        </div>
      );
    } else if (eventType === "fetch") {
      return (
        <div className={"application-row"}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 16L15.5 19L18 16"
              stroke="#6C6C6C"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              d="M15.5 18.5L15.5 6"
              stroke="#6C6C6C"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              d="M6 9L8.5 6L11 9"
              stroke="#6C6C6C"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              d="M8.5 6.5L8.5 19"
              stroke="#6C6C6C"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
          <span style={{ color: "#6C6C6C" }}>Fetch</span>
        </div>
      );
    } else if (eventType === "app_activity") {
      return (
        <div className={"application-row"}>
          <svg
            id="Layer_1"
            enableBackground="new 0 0 100 100"
            fill="none"
            height="24"
            viewBox="0 0 100 100"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m21.668 56.666h8.333v8.334h-8.333z"
              fill="#0073E6"
              stroke="#0073E6"
            />
            <path
              fill="#0073E6"
              d="m60.001 56.943 6.728-30.276h6.667l2.78 12.5h13.824v5h-17.834l-2.103-9.454-6.729 30.287h-6.667l-7.825-35.265-6.354 28.599h-6.667l-3.148-14.167h-11.005v-5h15.016l2.471 11.12 6.354-28.62h6.66z"
            />
            <path
              d="m21.668 21.667h16.667v4.999h-16.667z"
              fill="#0073E6"
              stroke="#0073E6"
            />
            <path
              d="m21.668 30h8.333v5h-8.333z"
              fill="#0073E6"
              stroke="#0073E6"
            />
            <path
              fill="#0073E6"
              stroke="#0073E6"
              d="m63.334 21.667v-5c0-3.665-3.001-6.667-6.667-6.667h-39.999c-3.665 0-6.668 3.002-6.668 6.667v66.667c0 3.664 3.003 6.666 6.668 6.666h39.999c3.666 0 6.667-3.002 6.667-6.666v-13.334h-46.666v-53.333h39.999v5zm-29.167 55.001h5.001c1.373 0 2.5 1.125 2.5 2.498 0 1.375-1.127 2.5-2.5 2.5h-5.001c-1.373 0-2.5-1.125-2.5-2.5 0-1.373 1.127-2.498 2.5-2.498z"
            />
          </svg>
          <span style={{ color: "#0073E6" }}>Activity Event</span>
        </div>
      );
    } else if (name === "slowRenders") {
      return (
        <div className={"application-row"}>
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.5664 10.9005C10.5664 10.51 10.883 10.1934 11.2735 10.1934H17.6377C18.0282 10.1934 18.3448 10.51 18.3448 10.9005C18.3448 11.9056 18.0488 12.9777 17.5936 13.8939C17.3001 14.4848 16.9216 15.0479 16.4773 15.4968C16.9216 15.9457 17.3001 16.5089 17.5936 17.0997C18.0488 18.0159 18.3448 19.088 18.3448 20.0931C18.3448 20.4837 18.0282 20.8003 17.6377 20.8003H11.2735C10.883 20.8003 10.5664 20.4837 10.5664 20.0931C10.5664 19.088 10.8624 18.0159 11.3176 17.0997C11.6111 16.5089 11.9896 15.9457 12.434 15.4968C11.9896 15.0479 11.6111 14.4848 11.3176 13.8939C10.8624 12.9777 10.5664 11.9056 10.5664 10.9005ZM12.0414 11.6076C12.1323 12.1606 12.3216 12.7363 12.5841 13.2646C12.9664 14.0341 13.4594 14.6149 13.9208 14.8887C14.1355 15.0161 14.2671 15.2472 14.2671 15.4968C14.2671 15.7464 14.1355 15.9776 13.9208 16.105C13.4594 16.3787 12.9664 16.9595 12.5841 17.729C12.3216 18.2574 12.1323 18.8331 12.0414 19.386H16.8698C16.7789 18.8331 16.5896 18.2574 16.3271 17.729C15.9448 16.9595 15.4518 16.3787 14.9904 16.105C14.7758 15.9776 14.6441 15.7464 14.6441 15.4968C14.6441 15.2472 14.7758 15.0161 14.9904 14.8887C15.4518 14.6149 15.9448 14.0341 16.3271 13.2646C16.5896 12.7363 16.7789 12.1606 16.8698 11.6076H12.0414Z"
              fill="#F16E00"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.54498 20.7099C6.87566 20.4305 7.37026 20.472 7.64971 20.8027C7.9978 21.2146 8.37998 21.5917 8.79071 21.9311C9.12448 22.2068 9.17152 22.7009 8.89577 23.0347C8.62003 23.3685 8.12592 23.4155 7.79215 23.1398C7.30977 22.7412 6.86096 22.2983 6.45222 21.8146C6.17277 21.4839 6.21431 20.9893 6.54498 20.7099ZM10.5806 23.9812C10.7222 23.572 11.1686 23.3551 11.5778 23.4967C12.0813 23.6709 12.6022 23.8011 13.1351 23.8841C13.6679 23.967 14.2038 24.0014 14.7364 23.9885C15.1692 23.978 15.5286 24.3204 15.5391 24.7532C15.5496 25.186 15.2072 25.5454 14.7744 25.5559C14.1489 25.571 13.5196 25.5306 12.8939 25.4332C12.2681 25.3358 11.6564 25.1829 11.0651 24.9783C10.6559 24.8367 10.439 24.3903 10.5806 23.9812ZM23.1316 10.3051C23.5271 10.129 23.9905 10.3069 24.1666 10.7024C24.4241 11.2809 24.6276 11.8778 24.7764 12.4855C24.8794 12.906 24.622 13.3304 24.2015 13.4334C23.781 13.5364 23.3566 13.279 23.2536 12.8585C23.1269 12.341 22.9537 11.8328 22.7343 11.3401C22.5582 10.9446 22.7361 10.4812 23.1316 10.3051ZM17.4319 24.3638C17.2707 23.962 17.4657 23.5056 17.8675 23.3444C18.362 23.146 18.8407 22.9028 19.2976 22.6163C19.7545 22.3297 20.1817 22.0045 20.5755 21.6456C20.8955 21.3541 21.3914 21.3771 21.6829 21.6971C21.9745 22.0171 21.9515 22.513 21.6314 22.8045C21.1689 23.226 20.6672 23.6079 20.1307 23.9444C19.5943 24.2809 19.0321 24.5664 18.4513 24.7994C18.0495 24.9606 17.5931 24.7656 17.4319 24.3638ZM24.3203 15.3622C24.7503 15.4128 25.0579 15.8024 25.0073 16.2324C24.9341 16.8538 24.8055 17.4711 24.6208 18.0769C24.4362 18.6826 24.1987 19.2668 23.9128 19.8233C23.715 20.2084 23.2425 20.3603 22.8573 20.1624C22.4722 19.9646 22.3204 19.4921 22.5182 19.107C22.7617 18.6331 22.9639 18.1357 23.1211 17.6198C23.2784 17.1039 23.3879 16.5783 23.4502 16.0492C23.5008 15.6192 23.8904 15.3116 24.3203 15.3622Z"
              fill="#F16E00"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.80463 9.01557C6.36401 10.6325 5.55523 12.715 5.52688 14.8804C5.5109 16.1009 5.74348 17.3016 6.19984 18.4133C6.36424 18.8139 6.17285 19.2718 5.77234 19.4362C5.37183 19.6006 4.91388 19.4092 4.74947 19.0087C4.21358 17.7033 3.94044 16.2932 3.9592 14.8599C3.99249 12.3169 4.94227 9.87142 6.63404 7.97262C8.32582 6.07381 10.646 4.84924 13.1682 4.5239C15.0604 4.27982 16.9711 4.55414 18.7019 5.30065L18.5016 4.55326C18.3896 4.13507 18.6377 3.70522 19.0559 3.59317C19.4741 3.48112 19.904 3.72929 20.016 4.14748L20.687 6.65168C20.7991 7.06987 20.5509 7.49971 20.1327 7.61176L17.6285 8.28276C17.2103 8.39481 16.7805 8.14664 16.6684 7.72845C16.5564 7.31027 16.8045 6.88042 17.2227 6.76837L17.7833 6.61816C16.3896 6.07532 14.8724 5.88488 13.3688 6.07883C11.221 6.35588 9.24526 7.39865 7.80463 9.01557Z"
              fill="#F16E00"
            />
          </svg>
          <span style={{ color: "#F16E00" }}>Slow Render</span>
        </div>
      );
    } else if (name === "frozenRenders") {
      return (
        <div className={"application-row"}>
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.9999 5.01953C15.4491 5.01953 15.8132 5.38367 15.8132 5.83287V24.1629C15.8132 24.6121 15.4491 24.9763 14.9999 24.9763C14.5507 24.9763 14.1865 24.6121 14.1865 24.1629V5.83287C14.1865 5.38367 14.5507 5.01953 14.9999 5.01953Z"
              fill="#5D87F0"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.37961 10.0084C6.60421 9.61935 7.10163 9.48607 7.49065 9.71066L23.3649 18.8757C23.754 19.1003 23.8872 19.5977 23.6626 19.9867C23.4381 20.3758 22.9406 20.509 22.5516 20.2844L6.67731 11.1194C6.2883 10.8948 6.15501 10.3974 6.37961 10.0084Z"
              fill="#5D87F0"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.6246 10.0201C23.8492 10.4091 23.7159 10.9065 23.3269 11.1311L7.48869 20.2753C7.09968 20.4999 6.60225 20.3666 6.37766 19.9776C6.15306 19.5886 6.28635 19.0912 6.67536 18.8666L22.5135 9.72238C22.9026 9.49779 23.4 9.63107 23.6246 10.0201Z"
              fill="#5D87F0"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.2443 7.81149C9.67044 7.66944 10.131 7.89975 10.2731 8.32589L11.6479 12.4502C11.7207 12.6686 11.6975 12.9077 11.584 13.1081C11.4706 13.3085 11.2775 13.4514 11.0527 13.5013L6.92842 14.4178C6.48993 14.5153 6.05546 14.2388 5.95802 13.8003C5.86058 13.3618 6.13705 12.9273 6.57555 12.8299L9.81948 12.109L8.7299 8.84029C8.58785 8.41414 8.81816 7.95354 9.2443 7.81149Z"
              fill="#5D87F0"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.95802 16.1976C6.05546 15.7591 6.48993 15.4826 6.92842 15.5801L11.0527 16.4966C11.2775 16.5465 11.4706 16.6894 11.584 16.8898C11.6975 17.0902 11.7207 17.3293 11.6479 17.5478L10.2731 21.672C10.131 22.0982 9.67044 22.3285 9.2443 22.1864C8.81816 22.0444 8.58785 21.5838 8.7299 21.1576L9.81948 17.8889L6.57555 17.168C6.13705 17.0706 5.86058 16.6361 5.95802 16.1976Z"
              fill="#5D87F0"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.7568 7.81149C21.183 7.95354 21.4133 8.41414 21.2712 8.84029L20.1816 12.109L23.4256 12.8299C23.8641 12.9273 24.1405 13.3618 24.0431 13.8003C23.9457 14.2388 23.5112 14.5153 23.0727 14.4178L18.9484 13.5013C18.7236 13.4514 18.5306 13.3085 18.4171 13.1081C18.3036 12.9077 18.2804 12.6686 18.3533 12.4502L19.728 8.32589C19.8701 7.89975 20.3307 7.66944 20.7568 7.81149Z"
              fill="#5D87F0"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.0431 16.1976C24.1405 16.6361 23.8641 17.0706 23.4256 17.168L20.1816 17.8889L21.2712 21.1576C21.4133 21.5838 21.183 22.0444 20.7568 22.1864C20.3307 22.3285 19.8701 22.0982 19.728 21.672L18.3533 17.5478C18.2804 17.3293 18.3036 17.0902 18.4171 16.8898C18.5306 16.6894 18.7236 16.5465 18.9484 16.4966L23.0727 15.5801C23.5112 15.4826 23.9457 15.7591 24.0431 16.1976Z"
              fill="#5D87F0"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.6757 6.63275C11.9933 6.31512 12.5083 6.31512 12.826 6.63275L15.0003 8.80715L17.1747 6.63275C17.4924 6.31512 18.0073 6.31512 18.325 6.63275C18.6426 6.95038 18.6426 7.46535 18.325 7.78298L15.5755 10.5325C15.2578 10.8501 14.7429 10.8501 14.4252 10.5325L11.6757 7.78298C11.3581 7.46535 11.3581 6.95038 11.6757 6.63275Z"
              fill="#5D87F0"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.4252 19.4628C14.7429 19.1452 15.2578 19.1452 15.5755 19.4628L18.325 22.2123C18.6426 22.53 18.6426 23.0449 18.325 23.3626C18.0073 23.6802 17.4924 23.6802 17.1747 23.3626L15.0003 21.1882L12.826 23.3626C12.5083 23.6802 11.9933 23.6802 11.6757 23.3626C11.3581 23.0449 11.3581 22.53 11.6757 22.2123L14.4252 19.4628Z"
              fill="#5D87F0"
            />
          </svg>
          <span style={{ color: "#5D87F0" }}>Frozen Render</span>
        </div>
      );
    } else if (name && name.toString().includes("textChanged")) {
      return (
        <div className={"application-row"}>
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3 12.4793C3 10.9094 4.27266 9.63672 5.84256 9.63672H24.1574C25.7273 9.63672 27 10.9094 27 12.4793V20.9323C27 22.5022 25.7273 23.7749 24.1574 23.7749H5.84256C4.27266 23.7749 3 22.5022 3 20.9323V12.4793ZM5.84256 11.0953C5.07823 11.0953 4.45862 11.715 4.45862 12.4793V20.9323C4.45862 21.6966 5.07823 22.3162 5.84256 22.3162H24.1574C24.9218 22.3162 25.5414 21.6966 25.5414 20.9323V12.4793C25.5414 11.715 24.9218 11.0953 24.1574 11.0953H5.84256Z"
              fill="#DD9F83"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.81738 13.8875C5.81738 13.4847 6.14391 13.1582 6.54669 13.1582H9.01216C9.41494 13.1582 9.74147 13.4847 9.74147 13.8875C9.74147 14.2903 9.41494 14.6168 9.01216 14.6168H6.54669C6.14391 14.6168 5.81738 14.2903 5.81738 13.8875Z"
              fill="#DD9F83"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.81738 16.7059C5.81738 16.3031 6.14391 15.9766 6.54669 15.9766H9.01216C9.41494 15.9766 9.74147 16.3031 9.74147 16.7059C9.74147 17.1087 9.41494 17.4352 9.01216 17.4352H6.54669C6.14391 17.4352 5.81738 17.1087 5.81738 16.7059Z"
              fill="#DD9F83"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.749 13.8875C10.749 13.4847 11.0755 13.1582 11.4783 13.1582H13.9438C14.3466 13.1582 14.6731 13.4847 14.6731 13.8875C14.6731 14.2903 14.3466 14.6168 13.9438 14.6168H11.4783C11.0755 14.6168 10.749 14.2903 10.749 13.8875Z"
              fill="#DD9F83"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.749 16.7059C10.749 16.3031 11.0755 15.9766 11.4783 15.9766H13.9438C14.3466 15.9766 14.6731 16.3031 14.6731 16.7059C14.6731 17.1087 14.3466 17.4352 13.9438 17.4352H11.4783C11.0755 17.4352 10.749 17.1087 10.749 16.7059Z"
              fill="#DD9F83"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.33984 19.5223C9.33984 19.1195 9.66637 18.793 10.0692 18.793L19.931 18.793C20.3338 18.793 20.6603 19.1195 20.6603 19.5223C20.6603 19.9251 20.3338 20.2516 19.931 20.2516L10.0692 20.2516C9.66637 20.2516 9.33984 19.9251 9.33984 19.5223Z"
              fill="#DD9F83"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.98047 19.5226C7.98047 19.9254 7.65395 20.252 7.25116 20.252H6.54674C6.14395 20.252 5.81743 19.9254 5.81743 19.5226C5.81743 19.1199 6.14395 18.7933 6.54674 18.7933H7.25116C7.65395 18.7933 7.98047 19.1199 7.98047 19.5226Z"
              fill="#DD9F83"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.1826 19.5226C24.1826 19.9254 23.8561 20.252 23.4533 20.252H22.7489C22.3461 20.252 22.0196 19.9254 22.0196 19.5226C22.0196 19.1199 22.3461 18.7933 22.7489 18.7933H23.4533C23.8561 18.7933 24.1826 19.1199 24.1826 19.5226Z"
              fill="#DD9F83"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.6797 13.8875C15.6797 13.4847 16.0062 13.1582 16.409 13.1582H18.8745C19.2772 13.1582 19.6038 13.4847 19.6038 13.8875C19.6038 14.2903 19.2772 14.6168 18.8745 14.6168H16.409C16.0062 14.6168 15.6797 14.2903 15.6797 13.8875Z"
              fill="#DD9F83"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.6797 16.7059C15.6797 16.3031 16.0062 15.9766 16.409 15.9766H18.8745C19.2772 15.9766 19.6038 16.3031 19.6038 16.7059C19.6038 17.1087 19.2772 17.4352 18.8745 17.4352H16.409C16.0062 17.4352 15.6797 17.1087 15.6797 16.7059Z"
              fill="#DD9F83"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.6104 13.8875C20.6104 13.4847 20.9369 13.1582 21.3397 13.1582H23.8051C24.2079 13.1582 24.5344 13.4847 24.5344 13.8875C24.5344 14.2903 24.2079 14.6168 23.8051 14.6168H21.3397C20.9369 14.6168 20.6104 14.2903 20.6104 13.8875Z"
              fill="#DD9F83"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.6104 16.7059C20.6104 16.3031 20.9369 15.9766 21.3397 15.9766H23.8051C24.2079 15.9766 24.5344 16.3031 24.5344 16.7059C24.5344 17.1087 24.2079 17.4352 23.8051 17.4352H21.3397C20.9369 17.4352 20.6104 17.1087 20.6104 16.7059Z"
              fill="#DD9F83"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.8175 4C18.2203 4 18.5468 4.32652 18.5468 4.72931V5.1514C18.5468 6.3309 17.8184 7.38785 16.7162 7.80775L16.6204 7.84423C16.0838 8.04867 15.7291 8.56326 15.7291 9.13751V10.3647C15.7291 10.7674 15.4026 11.094 14.9998 11.094C14.597 11.094 14.2705 10.7674 14.2705 10.3647V9.13751C14.2705 7.95802 14.9989 6.90106 16.1011 6.48117L16.1969 6.44468C16.7335 6.24025 17.0882 5.72566 17.0882 5.1514V4.72931C17.0882 4.32652 17.4147 4 17.8175 4Z"
              fill="#DD9F83"
            />
          </svg>
          <span style={{ color: "#DD9F83" }}>Keyboard Event</span>
        </div>
      );
    } else if (name && name.toString().includes("handleMultiTapGesture")) {
      return (
        <div className={"application-row"}>
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.3902 11.7895C14.2843 11.7895 14.1827 11.8316 14.1077 11.9066C14.0328 11.9815 13.9907 12.0831 13.9907 12.1891V18.9811C13.9907 19.4225 13.633 19.7802 13.1916 19.7802C12.7503 19.7802 12.3926 19.4225 12.3926 18.9811V12.1891C12.3926 11.6593 12.603 11.1511 12.9777 10.7765C13.3523 10.4019 13.8604 10.1914 14.3902 10.1914C14.9201 10.1914 15.4282 10.4019 15.8028 10.7765C16.1774 11.1511 16.3879 11.6593 16.3879 12.1891V16.985C16.3879 17.4263 16.0302 17.7841 15.5888 17.7841C15.1475 17.7841 14.7898 17.4263 14.7898 16.985V12.1891C14.7898 12.0831 14.7477 11.9815 14.6728 11.9066C14.5978 11.8316 14.4962 11.7895 14.3902 11.7895Z"
              fill="#2575ee"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.7877 15.7856C16.7352 15.7856 16.6833 15.796 16.6348 15.816C16.5863 15.8361 16.5423 15.8656 16.5052 15.9027C16.4681 15.9398 16.4387 15.9838 16.4186 16.0323C16.3985 16.0807 16.3882 16.1327 16.3882 16.1852V17.7833C16.3882 18.2246 16.0304 18.5824 15.5891 18.5824C15.1478 18.5824 14.79 18.2246 14.79 17.7833V16.1852C14.79 15.9228 14.8417 15.6631 14.9421 15.4207C15.0425 15.1783 15.1896 14.9581 15.3751 14.7726C15.5606 14.5871 15.7809 14.44 16.0232 14.3396C16.2656 14.2392 16.5254 14.1875 16.7877 14.1875C17.05 14.1875 17.3098 14.2392 17.5522 14.3396C17.7945 14.44 18.0148 14.5871 18.2003 14.7726C18.3858 14.9581 18.5329 15.1783 18.6333 15.4207C18.7337 15.6631 18.7854 15.9228 18.7854 16.1852V18.1828C18.7854 18.6241 18.4276 18.9819 17.9863 18.9819C17.545 18.9819 17.1872 18.6241 17.1872 18.1828V16.1852C17.1872 16.1327 17.1769 16.0807 17.1568 16.0323C17.1367 15.9838 17.1073 15.9398 17.0702 15.9027C17.0331 15.8656 16.9891 15.8361 16.9406 15.816C16.8921 15.796 16.8402 15.7856 16.7877 15.7856Z"
              fill="#2575ee"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.1842 16.5845C19.0782 16.5845 18.9766 16.6266 18.9017 16.7015C18.8268 16.7764 18.7847 16.878 18.7847 16.984C18.7847 17.4253 18.4269 17.7831 17.9856 17.7831C17.5443 17.7831 17.1865 17.4253 17.1865 16.984C17.1865 16.4542 17.397 15.9461 17.7716 15.5714C18.1463 15.1968 18.6544 14.9863 19.1842 14.9863C19.714 14.9863 20.2221 15.1968 20.5968 15.5714C20.9714 15.9461 21.1819 16.4542 21.1819 16.984V18.9832C21.1819 19.4245 20.8241 19.7823 20.3828 19.7823C19.9415 19.7823 19.5837 19.4245 19.5837 18.9832V16.984C19.5837 16.878 19.5416 16.7764 19.4667 16.7015C19.3918 16.6266 19.2902 16.5845 19.1842 16.5845Z"
              fill="#2575ee"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.3906 8.1372C12.3743 8.1372 10.7397 9.77176 10.7397 11.7881C10.7397 12.2294 10.382 12.5872 9.94067 12.5872C9.49936 12.5872 9.1416 12.2294 9.1416 11.7881C9.1416 8.88914 11.4917 6.53906 14.3906 6.53906C17.2896 6.53906 19.6397 8.88914 19.6397 11.7881C19.6397 12.2294 19.2819 12.5872 18.8406 12.5872C18.3993 12.5872 18.0416 12.2294 18.0416 11.7881C18.0416 9.77176 16.407 8.1372 14.3906 8.1372Z"
              fill="#2575ee"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.3902 4.59813C10.5978 4.59813 7.59813 7.50033 7.59813 10.9907C7.59813 11.432 7.24038 11.7897 6.79907 11.7897C6.35775 11.7897 6 11.432 6 10.9907C6 6.53739 9.79768 3 14.3902 3C18.9827 3 22.7804 6.53739 22.7804 10.9907C22.7804 11.432 22.4227 11.7897 21.9813 11.7897C21.54 11.7897 21.1823 11.432 21.1823 10.9907C21.1823 7.50033 18.1826 4.59813 14.3902 4.59813Z"
              fill="#2575ee"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.21 17.4124H21.5818C21.7868 17.4124 21.9531 17.5787 21.9531 17.7837V21.3795C21.9531 23.5705 20.177 25.3466 17.986 25.3466H17.1353C15.4668 25.3466 13.9171 24.4833 13.0389 23.0647L10.2578 18.5722C10.0701 18.2689 10.1566 17.8714 10.4534 17.6736C10.7504 17.4755 11.1511 17.549 11.3586 17.8395L12.5184 19.4632C12.7839 19.835 13.3006 19.9211 13.6724 19.6555C14.0442 19.39 14.1304 18.8733 13.8648 18.5015L12.705 16.8778C11.9784 15.8605 10.5757 15.6034 9.53554 16.2969C8.49648 16.9896 8.19366 18.3813 8.85097 19.4431L11.632 23.9356C12.8119 25.8415 14.8938 27.0012 17.1353 27.0012H17.986C21.0908 27.0012 23.6077 24.4843 23.6077 21.3795V17.7837C23.6077 16.6648 22.7007 15.7578 21.5818 15.7578H21.1823C20.284 15.7578 19.5559 16.486 19.5559 17.3842C19.5559 17.8411 19.9263 18.2115 20.3832 18.2115C20.8306 18.2115 21.1951 17.8563 21.21 17.4124Z"
              fill="#2575ee"
            />
          </svg>
          <span style={{ color: "#2575ee" }}>Tap</span>
        </div>
      );
    } else {
      return (
        <div className={"application-row"}>
          <img src={"assets/images/other-event.svg"} />
          <span>Other Event</span>
        </div>
      );
    }
  };

  const playerCallback = (_player: any) => {
    setPlayer(_player);
  };

  const toggleTraceInfoDialog = (dialogState: boolean, data: any) => {
    setTraceInfoDialog(dialogState);
    if (data) {
      const traceData = {
        parentSpanID: data["any(parentSpanID)"],
        trace_id: data["any(traceID)"],
        span_id: data["any(spanID)"],
        start_date: initialTimestamp / 1e6,
        end_date: dateRange.toTs,
        "service.name": data["any(trace.service.name)"],
        tagMap: data["any(tagMap)"],
        duration: data["any(durationNano)"],
      };
      setTraceInfoDialogData(traceData);
    }
  };

  const toggleErrorDialog = (dialogState: boolean, data: any) => {
    setErrorDialogSession(dialogState);
    if (data) {
      const errorData = {
        name: data["any(error.name)"] || data["error.name"],
        trace: data["any(name)"],
        err_count: data["count(error_count)"],
        events: data["any(events)"],
        latest_timestamp: data["max(timestampNs,value(any))"],
        first_timestamp: data["min(timestampNs,value(any))"],
        resource_attr: data["any(resource_attributes)"],
        other_attr: data["any(tagMap)"],
        duration: data["any(durationNano)"],
      };
      setErrorDialogSessionData(errorData);
    }
  };

  const shareSession = (url: string) => {
    copyToClipboard(url).then(() => {
      dispatch(showToast("success", "Copied link to clipboard"));
    });
  };

  return (
    <div className="session-panel">
      <div className={"header"}>
        <div
          className="title"
          onClick={() => {
            const url = new URL(window.location.href);
            url.searchParams.set("tab", "session-replay");
            url.searchParams.set("openRumSession", "true");
            url.searchParams.set("rumSessionId", session.session_id);
            shareSession(url.href);
          }}
        >
          Session: {`${session?.application_name} (${session?.session_id})`}{" "}
          <span className="divider"></span>
          <FaLink size={12} />
        </div>
        <div className={"other-utils"}>
          <StatusBadge
            labelText={`${session && session.view_count > 0 ? session.view_count : 0} View Loaded`}
            default
          />
          <StatusBadge
            labelText={`${session && session.action_count > 0 ? session.action_count : 0} User Action`}
            default
          />
          <StatusBadge
            labelText={`${session && session.error_count > 0 ? session.error_count : 0} Errors`}
            default
          />
          <StatusBadge
            labelText={`${session?.other_attributes?.env ? session.other_attributes.env : "-"} Environment`}
            default
          />

          <RenderIcon
            key="browser-icon"
            browserName={session.other_attributes["browser.name"]}
          />
          <StatusBadge
            labelText={`${session?.other_attributes?.["browser.name"] ? session.other_attributes["browser.name"] : "-"} Browser`}
            default
          />
          <RenderIcon key="os-icon" browserName={session.other_attributes.os} />
          <StatusBadge
            labelText={`${session?.other_attributes?.os ? session.other_attributes.os : "-"} Operating System`}
            default
          />
          <StatusBadge
            labelText={`${session?.visited_on ? moment(session.visited_on / 1000000).format("YYYY-MM-DD HH:mm:ss") : "-"}`}
            default
          />
        </div>
      </div>

      <div className={"session-tabs"}>
        <NavTab defaultActiveTabIndex={0} borderTop={false}>
          <TabItems title="Session">
            <div className={"session-player-container"}>
              <div className={"session-replay-player"}>
                <div className={"session-player"}>
                  {sessionRecording.is_loading && (
                    <div className={"loading"}>Loading...</div>
                  )}

                  {!sessionRecording.is_loading &&
                    sessionRecording.data.length > 0 &&
                    (sessionRecording.data?.[0]?.data?.videoUrl ? (
                      <MobileVideoPlayer
                        playerCallback={playerCallback}
                        device={
                          sessionAttr && sessionAttr?.OS === "iOS"
                            ? "iphone-14-pro"
                            : "google-pixel-6-pro"
                        }
                        controls={false}
                        videoUrl={sessionRecording.data[0]?.data.videoUrl
                          .toString()
                          .replaceAll("\\u0026", "&")}
                      />
                    ) : (
                      <Player
                        session={session}
                        events={sessionRecording.data}
                        playerCallback={playerCallback}
                        timestampToJumpVideo={props.timestampToJumpVideo}
                      />
                    ))}
                  {!sessionRecording.is_loading &&
                    sessionRecording.data.length <= 0 &&
                    sessionAttr &&
                    sessionAttr?.OS !== "iOS" &&
                    sessionAttr?.OS !== "Android" && (
                      <div className={"no-session-recording"}>
                        {" "}
                        No Session recording found{" "}
                      </div>
                    )}
                  {!sessionRecording.is_loading &&
                    sessionRecording.data.length <= 0 &&
                    sessionAttr &&
                    sessionAttr?.OS === "iOS" && (
                      <div className={"no-session-recording"}>
                        No Session recording found. To enable session
                        recording&nbsp;
                        <Link
                          to="https://github.com/middleware-labs/middleware-ios?tab=readme-ov-file#enable-session-recording"
                          target="_blank"
                        >
                          click here
                        </Link>
                      </div>
                    )}
                  {!sessionRecording.is_loading &&
                    sessionRecording.data.length <= 0 &&
                    sessionAttr &&
                    sessionAttr?.OS === "Android" && (
                      <div className={"no-session-recording"}>
                        No Session recording found. To enable session
                        recording&nbsp;
                        <Link
                          to="https://github.com/middleware-labs/middleware-android?tab=readme-ov-file#enable-session-recording"
                          target="_blank"
                        >
                          click here
                        </Link>
                      </div>
                    )}
                </div>
                <div className={"error-list"}>
                  <>
                    <BuilderView
                      nestedProps={{
                        columnConfig: {
                          "count(error_count)": {
                            title: "ERROR OCCURRED",
                            tpl: {
                              type: TPL_TYPE_COUNT,
                              config: {},
                              label: "",
                              externalVisible: true,
                            },
                          },
                          "any(name)": {
                            title: "ERROR",
                            customRender: (data: any) => {
                              const encode = window.btoa(
                                encodeURIComponent(data["any(name)"])
                              );
                              return (
                                <>
                                  <span
                                    data-tip
                                    data-for={`tooltip-top-${encode}`}
                                  >
                                    {truncate(
                                      data["any(name)"]
                                        ? data["any(name)"]
                                        : "-",
                                      40
                                    )}
                                  </span>
                                  <Tooltip
                                    id={`tooltip-top-${encode}`}
                                    place="left"
                                    type="dark"
                                    effect="solid"
                                  >
                                    <span>
                                      {data["any(name)"]
                                        ? data["any(name)"]
                                        : "-"}
                                    </span>
                                  </Tooltip>
                                </>
                              );
                            },
                          },
                          "any(root.url)": {
                            title: "PAGE",
                          },
                          "error.name": {
                            title: "ERROR TYPE",
                          },
                          "any(events)": {
                            isHidden: true,
                          },
                          "max(timestampNs)": {
                            isHidden: true,
                          },
                          "min(timestampNs)": {
                            isHidden: true,
                          },
                          "any(resource_attributes)": {
                            isHidden: true,
                          },
                          "any(tagMap)": {
                            isHidden: true,
                          },
                          "any(durationNano)": {
                            isHidden: true,
                          },
                          "any(event.type)": {
                            isHidden: true,
                          },
                        },
                      }}
                      builderViewOptions={{
                        displayScope: "",
                        resource: {
                          columns: [
                            "any(name)",
                            "any(root.url)",
                            "count(error_count)",
                            "any(events)",
                            "max(timestampNs)",
                            "min(timestampNs)",
                            "any(resource_attributes)",
                            "any(tagMap)",
                            "any(durationNano)",
                            "any(event.type)",
                          ],
                          resourceType: "trace",
                          name: "trace-errors",
                          widgetAppId: 5,
                          with: [
                            {
                              key: "ATTRIBUTE_FILTER",
                              value: {
                                "browser.trace": {
                                  "=": "true",
                                },
                                "event.type": {
                                  IN: ["error", "crash"],
                                },
                                "session.id": {
                                  "=": `${session.session_id}`,
                                },
                              },
                              is_arg: true,
                            },
                            {
                              key: "SELECT_DATA_BY",
                              value: ["error.name"],
                              is_arg: true,
                            },
                          ],
                        },
                      }}
                      listOnClick={(data: any) => {
                        toggleErrorDialog(true, data?.row);
                      }}
                    />

                    <Dialog
                      isOpen={errorDialogSession}
                      position={"right"}
                      size={"sm"}
                      onClose={() => {
                        toggleErrorDialog(false, null);
                      }}
                    >
                      <RumErrorPanel
                        themeMode={""}
                        error={errorDialogSessionData}
                      />
                    </Dialog>
                  </>
                </div>
              </div>
              <div className={"session-trace-list"}>
                <>
                  <BuilderView
                    nestedProps={{
                      columnConfig: {
                        "any(durationNano)": {
                          title: "DURATION",
                          tpl: {
                            type: TPL_TYPE_TIME_UTILIZATION,
                            config: {
                              valueType: "nanosecond",
                              items: ["nanosecond"],
                            },
                            label: "",
                          },
                        },
                        timestampNs: {
                          title: "TIMESTAMP",
                          customRender: (data: any) => {
                            const timeSinceStart = initialTimestamp
                              ? Math.max(
                                  data["timestampNs"] - initialTimestamp,
                                  0
                                )
                              : 0;
                            return (
                              <Button
                                secondary
                                compact
                                onClick={(e: any) => {
                                  e.stopPropagation();

                                  if (player) {
                                    player?.goto(
                                      converMicroSecondsToSeconds(
                                        timeSinceStart
                                      ),
                                      false
                                    );
                                  }
                                }}
                              >
                                {NanosecondsToHoursMinutesSeconds(
                                  timeSinceStart
                                )}
                              </Button>
                            );
                          },
                        },
                        "any(event.type)": {
                          title: "TYPE",
                          customRender: (data: any) => {
                            return formatEventType(
                              data["any(event.type)"],
                              data["any(error.name)"]
                            );
                          },
                        },
                        "any(name)": {
                          title: "EVENT",
                        },
                        "any(events)": {
                          isHidden: true,
                        },
                        "any(resource_attributes)": {
                          isHidden: true,
                        },
                        "max(timestampNs)": {
                          isHidden: true,
                        },
                        "min(timestampNs)": {
                          isHidden: true,
                        },
                        "any(tagMap)": {
                          isHidden: true,
                        },
                        "any(parentSpanID)": {
                          isHidden: true,
                        },
                        "any(traceID)": {
                          isHidden: true,
                        },
                        "any(spanID)": {
                          isHidden: true,
                        },
                        "any(trace.service.name)": {
                          isHidden: true,
                        },
                        "count(error_count)": {
                          isHidden: true,
                        },
                        "any(error.name)": {
                          isHidden: true,
                        },
                      },
                      onDataReceived: (res: any) => {
                        setInitialTimestmap(
                          res?.gridviewData?.data[0].timestampNs
                        );
                      },
                    }}
                    builderViewOptions={{
                      displayScope: "",
                      resource: {
                        columns: [
                          "timestampNs",
                          "any(durationNano)",
                          "any(event.type)",
                          "any(name)",
                          "any(error.name)",
                          "count(error_count)",
                          "any(events)",
                          "max(timestampNs)",
                          "min(timestampNs)",
                          "any(resource_attributes)",
                          "any(tagMap)",
                          "any(parentSpanID)",
                          "any(traceID)",
                          "any(spanID)",
                          "any(trace.service.name)",
                        ],
                        resourceType: "trace",
                        name: "traces",
                        widgetAppId: 5,
                        with: [
                          {
                            key: "ATTRIBUTE_FILTER",
                            value: {
                              "browser.trace": {
                                "=": "true",
                              },
                              "session.id": {
                                "=": `${session.session_id}`,
                              },
                              parentSpanID: {
                                "=": "",
                              },
                            },
                            is_arg: true,
                          },
                          {
                            key: "SELECT_DATA_BY",
                            value: ["timestampNs"],
                            is_arg: true,
                          },
                          {
                            key: "ORDER_BY_METRICS",
                            value: {
                              timestampNs: "ASC",
                            },
                            is_arg: true,
                          },
                        ],
                      },
                    }}
                    listOnClick={(data: any) => {
                      if (data?.row["any(event.type)"] === "error") {
                        toggleErrorDialog(true, data?.row);
                      } else {
                        toggleTraceInfoDialog(true, data?.row);
                      }
                    }}
                  />

                  <Dialog
                    isOpen={errorDialogSession}
                    position={"right"}
                    size={"sm"}
                    onClose={() => {
                      toggleErrorDialog(false, null);
                    }}
                  >
                    <RumErrorPanel
                      themeMode={""}
                      error={errorDialogSessionData}
                    />
                  </Dialog>
                  <Dialog
                    isOpen={traceInfoDialog}
                    size={"md"}
                    position={"right"}
                    onClose={() => {
                      toggleTraceInfoDialog(false, null);
                    }}
                  >
                    <TraceSideInfoPanel
                      disableSessionReplayTab={true}
                      themeMode={""}
                      trace={traceInfoDialogData}
                    />
                  </Dialog>
                </>
                {/* )} */}
              </div>
            </div>
          </TabItems>
          <TabItems title="Attributes">
            <div className={"attributes-listing"}>
              <Accordion
                secondary
                defaultActive
                heading={"Application"}
                arrowPosition={"right"}
              >
                <div className={"content-container"}>
                  {applicationAttr &&
                    Object.keys(applicationAttr).map((key: string) => {
                      const k = key as keyof ApplicationAttr;
                      return (
                        <div className={"content-row"} key={key}>
                          <div className={"content-label"}>{key}</div>
                          <div className={"content-value"}>
                            {applicationAttr[k]}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Accordion>

              <Accordion
                secondary
                defaultActive
                heading={"Session"}
                arrowPosition={"right"}
              >
                <div className={"content-container"}>
                  {sessionAttr &&
                    Object.keys(sessionAttr).map((key: string) => {
                      const k = key as keyof SessionAttr;
                      return (
                        <div className={"content-row"} key={key}>
                          <div className={"content-label"}>{key}</div>
                          <div className={"content-value"}>
                            {sessionAttr[k]}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Accordion>

              <Accordion
                secondary
                defaultActive
                heading={"Other Attributes"}
                arrowPosition={"right"}
              >
                <div className={"content-container"}>
                  {otherAttr &&
                    Object.keys(otherAttr).map((key: string) => {
                      return (
                        <div className={"content-row"} key={key}>
                          <div className={"content-label"}>{key}</div>
                          <div className={"content-value"}>
                            {otherAttr[key]}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Accordion>

              <Accordion
                secondary
                defaultActive
                heading={"User Details"}
                arrowPosition={"right"}
              >
                <div className={"content-container"}>
                  {userDetails &&
                    Object.keys(userDetails).map((key: string) => {
                      const k = key as keyof UserDetails;
                      return (
                        <div className={"content-row"} key={key}>
                          <div className={"content-label"}>{key}</div>
                          <div className={"content-value"}>
                            {userDetails[k]}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Accordion>
            </div>
          </TabItems>
        </NavTab>
      </div>
    </div>
  );
};

export default SessionPanel;
