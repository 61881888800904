import React from 'react';
import Command from 'views/modules/installation-v2/components/code-block/command';
import MwMessage from '../../components/message';
import { Message } from "core/components/v2/enums";

const MariaDBConfigGuide: React.FC = () => {
  return (
    <div style={{ fontFamily: 'monospace', margin: '20px' }}>
      <h5>Turn on `performance_schema`</h5>
      <p>By default the performance schema is not enabled in mariadb. The performance schema cannot be activated at runtime - it must be set when the server starts by adding the following line in your `my.cnf`
 configuration file.</p>

      <Command command={`[mysqld]
performance_schema=ON`}/>

      <h5>Create a MariaDB User</h5>
      <p>This creates a user named "mw" that can log in from any host. Modify the host as needed for specific access control.</p>
      <Command command={`MariaDB [mysql]CREATE USER 'mw'@'%' IDENTIFIED BY '<PASSWORD>';`} />

      <p>To verify if the user was created successfully:</p>
      <Command command={`mysql -u mw --password=<PASSWORD> -e "show status" | \\
grep Uptime && echo -e "\\033[0;32mMySQL user - OK\\033[0m" || \\
echo -e "\\033[0;31mCannot connect to MySQL\\033[0m"`} />

      <MwMessage
        type={Message.Info}
        message="Replace <PASSWORD> with the actual password you want to set for the 'mw' user."
      />

      <h5>Grant Necessary Permissions</h5>
      <p>Permissions needed for collecting metrics:</p>
      <Command command="GRANT REPLICATION CLIENT ON *.* TO 'mw'@'%';" />
      <br/>
      <Command command="ALTER USER 'mw'@'%' WITH MAX_USER_CONNECTIONS 5;" />
      <br/>
      <Command command="GRANT PROCESS ON *.* TO 'mw'@'%';" />
      <br/>
      <Command command="GRANT SELECT ON performance_schema.* TO 'mw'@'%';" />

      <p>To verify that the necessary permissions are granted:</p>
      <Command command={`mysql -u mw --password=<PASSWORD> -e "show slave status" && \\
echo -e "\\033[0;32mMySQL grant - OK\\033[0m" || \\
echo -e "\\033[0;31mMissing REPLICATION CLIENT grant\\033[0m"`} />

      <MwMessage
        type={Message.Info}
        message="Ensure to replace <PASSWORD> with the actual password when running verification commands."
      />

    </div>
  );
}

export default MariaDBConfigGuide