import { getTenantUrl } from "core/application/utils";
import CardSelection from "core/components/v2/card-selection";
import { AwsEc2Svg, AwsFargateSvg } from "core/components/v2/svg/icons";
import TabSelection from "core/components/v2/tab-selection";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import Command from "../../components/code-block/command";
import InstallationSteps from "../../components/steps";
import StepItem from "../../components/steps/step-item";
const AwsEcsIntegration = () => {
  const [tab, setTab] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Windows OS Version",
          value: "10 or above",
        },
        {
          label: "Windows Processor",
          value: "64-bit",
        },
      ],
    },
  ];
  const mwApiKey = `${context.user?.account?.agent_config?.token}`;
  const mwTarget = `${capturePath}`;
  const oldStatusCheckCommand = `sudo systemctl status mwservice`;
  const newStatusCheckCommand = `sudo systemctl status mw-agent`;
  const windowsStatusCheckCommand = `Get-Service -Name mw-windows-agent`;

  const [appEnv, setAppEnv] = useState<number>(1);
  const sendEvent = useMixPanel();
  return (
    <>
      <InstallationSteps>
        <StepItem stepNumber={1} title={"Select your AWS ECS environment"}>
          <CardSelection
            tabs={[
              {
                label: "Fargate Setup",
                icon: <AwsFargateSvg />,
                value: 1,
                enabled: true,
              },
              {
                label: "EC2 Setup",
                icon: <AwsEc2Svg />,
                value: 2,
                enabled: true,
              },
            ]}
            selectedTab={1}
            onTabChange={(tabObject) => {
              setAppEnv(tabObject.value);
            }}
          />
          <br />
          <br />
          {appEnv == 1 && (
            <>
              <TabSelection
                tabs={[
                  {
                    label: "Logs",
                    value: 1,
                  },
                  {
                    label: "Metrics",
                    value: 2,
                  },
                ]}
                selectedTab={1}
                onTabChange={(tabObject) => {
                  setTab(tabObject.value);
                }}
              />
              <div className="tab-content-area">
                {tab === 1 && (
                  <>
                    <InstallationSteps>
                      <StepItem
                        stepNumber={1}
                        title="Add Middleware Agent sidecar in your ECS Task container
                    definitions."
                      >
                        <Command
                          onCopyClick={() => {
                            sendEvent("click on copy command", {
                              agent_type: "AWS ECS",
                              command_type:
                                "Add Middleware Agent sidecar in your ECS Task container definitions.",
                            });
                          }}
                          command={`{
    "name": "mw-agent",
    "image": "ghcr.io/middleware-labs/mw-host-agent:master",
    "cpu": 256,
    "portMappings": [
        {
            "name": "8006-tcp",
            "containerPort": 8006,
            "hostPort": 8006,
            "protocol": "tcp",
            "appProtocol": "http"
        }
    ],
    "essential": true,
    "environment": [
        {
            "name": "MW_API_KEY",
            "value": "${context.user?.account?.agent_config?.token}"
        },
        {
            "name": "MW_TARGET",
            "value": "${capturePath}"
        },
    ],
    "mountPoints": [],
    "volumesFrom": [],
    "logConfiguration": {
        <Setup Log Configurations as per your requirement>
    }
}
`}
                          className="margin-0"
                        />
                      </StepItem>
                      <StepItem
                        stepNumber={2}
                        title="Add a fluent sidebar in your ECS task."
                      >
                        <Command
                          onCopyClick={() => {
                            sendEvent("click on copy command", {
                              agent_type: "AWS ECS",
                              command_type:
                                "Add a fluent sidebar in your ECS task.",
                            });
                          }}
                          command={`{
    "name": "log_router",
    "image": "amazon/aws-for-fluent-bit:stable",
    "cpu": 0,
    "portMappings": [],
    "essential": true,
    "environment": [],
    "mountPoints": [],
    "volumesFrom": [],
    "user": "0",
    "firelensConfiguration": {
        "type": "fluentbit",
        "options": {
            "enable-ecs-log-metadata": "true"
        }
    }
}`}
                        />
                      </StepItem>
                      <StepItem
                        stepNumber={3}
                        title="Add a log Configuration in your main container."
                      >
                        <Command
                          onCopyClick={() => {
                            sendEvent("click on copy command", {
                              agent_type: "AWS ECS",
                              command_type:
                                "Add a log Configuration in your main container.",
                            });
                          }}
                          command={`"logConfiguration": {
    "logDriver": "awsfirelens",
    "options": {
        "Host": "127.0.0.1",
        "Name": "forward",
        "Port": "8006"
    }
}`}
                        />
                      </StepItem>
                    </InstallationSteps>
                  </>
                )}
                {tab === 2 && (
                  <>
                    <InstallationSteps>
                      <StepItem
                        stepNumber={1}
                        title="Add Middleware Agent sidecar in your ECS Task container
                    definitions."
                      >
                        <Command
                          onCopyClick={() => {
                            sendEvent("click on copy command", {
                              agent_type: "AWS ECS",
                              command_type:
                                "Add Middleware Agent sidecar in your ECS Task container definitions.",
                            });
                          }}
                          command={`{
    "name": "mw-agent",
    "image": "ghcr.io/middleware-labs/mw-host-agent:master",
    "cpu": 256,
    "portMappings": [
        {
            "name": "8006-tcp",
            "containerPort": 8006,
            "hostPort": 8006,
            "protocol": "tcp",
            "appProtocol": "http"
        }
    ],
    "essential": true,
    "environment": [
        {
            "name": "MW_API_KEY",
            "value": "${context.user?.account?.agent_config?.token}"
        },
        {
            "name": "MW_TARGET",
            "value": "${capturePath}"
        },
    ],
    "mountPoints": [],
    "volumesFrom": [],
    "logConfiguration": {
        <Setup Log Configurations as per your requirement>
    }
}`}
                          className="margin-0"
                        />
                      </StepItem>
                    </InstallationSteps>
                  </>
                )}
              </div>
            </>
          )}
          {appEnv == 2 && (
            <>
              <InstallationSteps>
                <StepItem
                  stepNumber={1}
                  title="Create Middleware Agent ECS Task Definition using following JSON."
                >
                  <Command
                    onCopyClick={() => {
                      sendEvent("click on copy command", {
                        agent_type: "AWS ECS",
                        command_type:
                          "Create Middleware Agent ECS Task Definition using following JSON.",
                      });
                    }}
                    command={`{
  "family": "mw-agent-task",
  "containerDefinitions": [
      {
          "name": "mw-agent",
          "image": "ghcr.io/middleware-labs/mw-host-agent:master",
          "cpu": 100,
          "memory": 512,
          "portMappings": [
              {
                  "name": "mw-agent-9319-tcp",
                  "containerPort": 9319,
                  "hostPort": 9319,
                  "protocol": "tcp",
                  "appProtocol": "grpc"
              },
              {
                  "name": "mw-agent-8006-tcp",
                  "containerPort": 8006,
                  "hostPort": 8006,
                  "protocol": "tcp"
              },
              {
                  "name": "mw-agent-9320-tcp",
                  "containerPort": 9320,
                  "hostPort": 9320,
                  "protocol": "tcp"
              }
          ],
          "essential": true,
          "environment": [
              {
                  "name": "MW_API_KEY",
                  "value": "<Your MW API Key here>"
              },
              {
                  "name": "MW_TARGET",
                  "value": "<Your MW Target here>"
              }
          ],
          "mountPoints": [
              {
                  "sourceVolume": "docker-sock",
                  "containerPath": "/var/run/docker.sock",
                  "readOnly": false
              },
              {
                  "sourceVolume": "proc",
                  "containerPath": "/rootfs/proc",
                  "readOnly": true
              },
              {
                  "sourceVolume": "dev",
                  "containerPath": "/rootfs/dev",
                  "readOnly": true
              },
              {
                  "sourceVolume": "al2_cgroup",
                  "containerPath": "/sys/fs/cgroup",
                  "readOnly": true
              },
              {
                  "sourceVolume": "al1_cgroup",
                  "containerPath": "/cgroup",
                  "readOnly": true
              },
              {
                  "sourceVolume": "al2_cgroup",
                  "containerPath": "/rootfs/sys/fs/cgroup",
                  "readOnly": true
              },
              {
                  "sourceVolume": "al1_cgroup",
                  "containerPath": "/rootfs/cgroup",
                  "readOnly": true
              },
              {
                  "sourceVolume": "docker-containers-root",
                  "containerPath": "/var/lib/docker/containers",
                  "readOnly": true
              },
              {
                  "sourceVolume": "var-log-host",
                  "containerPath": "/var/log",
                  "readOnly": true
              }
          ],
          "volumesFrom": [],
          "privileged": true,
          "systemControls": []
      }
  ],
  "networkMode": "host",
  "volumes": [
      {
          "name": "docker-sock",
          "host": {
              "sourcePath": "/var/run/docker.sock"
          }
      },
      {
          "name": "proc",
          "host": {
              "sourcePath": "/proc"
          }
      },
      {
          "name": "dev",
          "host": {
              "sourcePath": "/dev"
          }
      },
      {
          "name": "al1_cgroup",
          "host": {
              "sourcePath": "/cgroup"
          }
      },
      {
          "name": "al2_cgroup",
          "host": {
              "sourcePath": "/sys/fs/cgroup"
          }
      },
      {
          "name": "docker-containers-root",
          "host": {
              "sourcePath": "/var/lib/docker/containers"
          }
      },
      {
          "name": "var-log-host",
          "host": {
              "sourcePath": "/var/log"
          }
      }
  ],
  "requiresCompatibilities": [
      "EC2"
  ],
  "pidMode": "host"
}`}
                    className="margin-0"
                  />
                </StepItem>
                <StepItem
                  stepNumber={2}
                  title="Set up the Middleware agent Task Definition created in Step 1 as an ECS Daemon Service to ensure only one Middleware Agent container is running on each EC2 container instance of your ECS EC2 cluster."
                >
                  <TabSelection
                    tabs={[
                      {
                        label: "Logs",
                        value: 1,
                      },
                      {
                        label: "Traces",
                        value: 2,
                      },
                      {
                        label: "Metrics",
                        value: 3,
                      },
                    ]}
                    selectedTab={1}
                    onTabChange={(tabObject) => {
                      setTab(tabObject.value);
                    }}
                  />
                  <div className="tab-content-area">
                    {tab === 1 && (
                      <>
                        <InstallationSteps>
                          <StepItem
                            stepNumber={1}
                            title="To collect logs, configure the logConfiguration in your application's Task Definition JSON configuration with the following"
                          >
                            <Command
                              onCopyClick={() => {
                                sendEvent("click on copy command", {
                                  agent_type: "AWS ECS",
                                  command_type:
                                    "To collect logs, configure the logConfiguration in your application's Task Definition JSON configuration with the following",
                                });
                              }}
                              command={`"containerDefinitions": [
    {
    (...)
    "logConfiguration": {
            "logDriver": "fluentd",
            "options": {
                "fluentd-address": "172.17.0.1:8006"
            }
        }
    (...)
    }
]`}
                              className="margin-0"
                            />
                          </StepItem>
                        </InstallationSteps>
                      </>
                    )}
                    {tab === 2 && (
                      <>
                        <InstallationSteps>
                          <StepItem
                            stepNumber={1}
                            title="To collect traces, configure the environment variable in your application’s Task Definition JSON configuration with the following"
                          >
                            <Command
                              onCopyClick={() => {
                                sendEvent("click on copy command", {
                                  agent_type: "AWS ECS",
                                  command_type:
                                    "To collect traces, configure the environment variable in your application’s Task Definition JSON configuration with the following",
                                });
                              }}
                              command={`"containerDefinitions": [
    {
    (...)
    "environment": [
            {
              "name": "MW_AGENT_SERVICE",
              "value": "172.17.0.1"
                  }
      ],
    (...)
    }
]`}
                              className="margin-0"
                            />
                          </StepItem>
                        </InstallationSteps>
                      </>
                    )}
                    {tab === 3 && (
                      <>
                        Metrics for ECS tasks and containers will start showing
                        automatically without any further changes.
                        <br />
                        <br />
                      </>
                    )}
                  </div>
                </StepItem>
              </InstallationSteps>
            </>
          )}
        </StepItem>
      </InstallationSteps>
    </>
  );
};
export default AwsEcsIntegration;
