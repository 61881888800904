import { APIStatus } from "core/application/utils";
import DropdownSelection from "core/components/v2/dropdown-selection";
import { DropdownMode } from "core/components/v2/dropdown-selection/entity";
import FormTextInput from "core/components/v2/form/form-text-input";
import { DeleteIcon } from "core/components/v2/svg/icons";
import { DragHandlerIcon } from "core/components/v2/svg/interaction-icons";
import { debounceHandler } from "core/utils";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchBuilderFilterValues } from "store/widgets/api";
import { BuilderMetrics } from "store/widgets/entities";
import useDidMountEffect from "views/layouts/app/routes/useDidMountEffect";
import {
  DefaultNoneValue,
  FilterOp,
  FilterOpEnum,
  FilterOption,
  MetricTypeEnum,
  SelectFilter,
  getAvailableOperators,
  operatorMapping,
  sqlOperators,
} from "../../entities/builder.entities";
import { CustomObject } from "../../entities/extra.entities";

export interface FilterItemProps {
  filterKey: string;
  filter: Record<string, CustomObject>;
  resource: string;
  builderMetrics: BuilderMetrics;
  attrKeyOptions: FilterOption[];
  fromTs: number;
  toTs: number;
  isValidFilterValue: (filterKey: string, operator: FilterOp) => boolean;
  requestFilterOptions: (
    searchVal: string,
    event?: string,
    attrKey?: string
  ) => void;
  handleAttrKeyOptionChange: (
    filterKey: string,
    newAttrKey: string | string[]
  ) => void;
  handleOperatorChange: (
    filterKey: string,
    newOperator: string | string[]
  ) => void;
  onFilterTextValueChange: (filterKey: string, value: string) => void;
  handleOperatorDropdownValueChange: (
    filterKey: string,
    value: string | string[]
  ) => void;
  handleDeleteFilter: (filterKey: string) => void;
}

export const FilterItem = ({
  filterKey,
  filter,
  resource,
  builderMetrics,
  attrKeyOptions,
  fromTs,
  toTs,
  isValidFilterValue,
  requestFilterOptions,
  handleAttrKeyOptionChange,
  handleOperatorChange,
  onFilterTextValueChange,
  handleOperatorDropdownValueChange,
  handleDeleteFilter,
}: FilterItemProps) => {
  const dispatch = useDispatch();

  const attrKey = Object.keys(filter)[0];
  const filterDetails = filter[attrKey];

  const operator = operatorMapping[Object.keys(filterDetails)[0]];
  const operand = filterDetails[operator] as string | string[];

  const selectedAttKeyOption = attrKeyOptions.find((o) => o.value === attrKey);
  const availableSqlOperators = selectedAttKeyOption
    ? getAvailableOperators(selectedAttKeyOption.type)
    : sqlOperators;

  const operatorDetails =
    availableSqlOperators.find((o) => o.value === operator) ||
    availableSqlOperators[0];

  const filterOptions = (
    operatorDetails.defaultOptions ||
    attrKeyOptions.find((ako) => ako.value === attrKey)?.options ||
    (operand ? (Array.isArray(operand) ? operand : [operand]) : [])
  ).map((v) => String(v)); // Convert to string to prevent dropdown from crashing on the integer search

  const [filterTextValue, setFilterTextValue] = useState<string>(
    Array.isArray(operand)
      ? operand.map((iv) => String(iv)).join(", ")
      : operand ?? ""
  );

  useDidMountEffect(() => {
    onFilterTextValueChange(filterKey, filterTextValue);
  }, [filterTextValue]);

  const handleAttrKeyOptionSearch = debounceHandler(
    -1,
    (search: string) => {
      dispatch(
        fetchBuilderFilterValues(
          resource,
          attrKey,
          selectedAttKeyOption?.label ?? "",
          selectedAttKeyOption?.type ?? MetricTypeEnum.MetricTypeNone,
          fromTs,
          toTs,
          search
        )
      );
    },
    500
  );

  return (
    <div className="filter-option-container">
      <span>
        <DragHandlerIcon color={"var(--color-text)"} />
      </span>

      <DropdownSelection
        placeholder={SelectFilter}
        notFoundContent="No options found"
        extraNoteTitle={
          attrKeyOptions.length >= 100 ? "Search for more..." : undefined
        }
        options={attrKeyOptions.map((ako) => ({
          label: ako.label,
          value: ako.value,
        }))}
        defaultValue={attrKey && attrKey !== SelectFilter ? attrKey : undefined}
        isLoading={builderMetrics.filters?.apiStatus === APIStatus.LOADING}
        isError={!attrKey || [SelectFilter, DefaultNoneValue].includes(attrKey)}
        onChange={(value) => {
          handleAttrKeyOptionChange(filterKey, value);
        }}
        onFocus={() => requestFilterOptions("")}
        onSearch={debounceHandler(-3, requestFilterOptions, 500)}
      />

      <DropdownSelection
        placeholder="Select Operator"
        notFoundContent="No options found"
        options={availableSqlOperators.map((o) => ({
          label: o.title,
          value: o.value,
        }))}
        selectedValues={operator || undefined}
        isError={!operator}
        onChange={(value) => {
          handleOperatorChange(filterKey, value);
        }}
      />

      {operator &&
      (operator === FilterOpEnum.Like ||
        operator === FilterOpEnum.NotLike ||
        operator === FilterOpEnum.ILIKE ||
        operator === FilterOpEnum.NotILike ||
        operator === FilterOpEnum.Regex ||
        operator === FilterOpEnum.NotRegex) ? (
        <FormTextInput
          id="filter-operator-value"
          placeholder="Add Value"
          value={filterTextValue}
          onChange={setFilterTextValue}
          isError={!isValidFilterValue(filterKey, operator)}
        />
      ) : (
        <DropdownSelection
          placeholder="Select Value"
          notFoundContent="No options found"
          extraNoteTitle={
            filterOptions.length > 0 ? "Search for more..." : undefined
          }
          mode={DropdownMode.AddOptions}
          maxSelection={!operatorDetails.allowMultiple ? 1 : undefined}
          selectedValues={operand || undefined}
          options={filterOptions}
          onChange={(value: string | string[]) => {
            const val =
              !operatorDetails.allowMultiple &&
              Array.isArray(value) &&
              value.length > 0
                ? value[0]
                : value;
            handleOperatorDropdownValueChange(filterKey, val);
          }}
          isLoading={
            builderMetrics.filters?.apiStatus === APIStatus.LOADING ||
            builderMetrics.fltAttSearchAPIResp.apiStatus === APIStatus.LOADING
          }
          isError={!isValidFilterValue(filterKey, operator)}
          onFocus={() =>
            requestFilterOptions("", "onAttrKeyOptionFocus", attrKey)
          }
          onSearch={handleAttrKeyOptionSearch}
        />
      )}

      <div
        className="action-icon delete"
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteFilter(filterKey);
        }}
      >
        <DeleteIcon color="var(--color-error)" />
      </div>
    </div>
  );
};
