/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import Tooltip from "core/components/tooltip";
import classNames from "classnames";
import { Group } from "core/components/v2/charts/components/hexagon-chart";

export type HexagonsStatusType =
  | "working"
  | "pending"
  | "stop"
  | "lowRunning"
  | "default";
export const HexagonsStatus: { [key in HexagonsStatusType]: string } = {
  working: "#2da44e",
  pending: "#ececed",
  stop: "#d62747",
  lowRunning: "#ffb800",
  default: "#36383e",
};

export interface HexagonsProps {
  onClick?: (index: number, data: Group) => void;
  onHover?: (index: number, data: unknown) => void;
  tooltipTemplate?: (index: number, data: any) => React.ReactNode;
  size?: number;
  margin?: number;
  showLegend?: boolean;
  getBgColor?: (index: number, data: Group) => string;
  items: (
    | {
        status: HexagonsStatusType;
      }
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    | any
  )[];
  selectedValue?: any;
  selectedKey?: any;
}

export const defHeightMultiply = 1.1547;
export const defMarginBottomMultiply = 0.2885;
export const defSize = 14;
export const defMargin = 1;

const HexagonsGrid = (props: HexagonsProps) => {
  const { items, onClick, onHover, getBgColor } = props;
  const size = props?.size && props.size > defSize ? props.size : defSize;
  const margin =
    props?.margin && props.margin > defMargin ? props.margin : defMargin;
  const styleProps: Record<string, string> = {
    "--size": size + "px",
    "--margin": margin + "px",
    "--shape": 1.732 * size + 4 * margin - 1 + "px",
  };
  return (
    <div className={"hexagon-chart-component"}>
      <div
        className={classNames("hexagon__container", {
          "is-selection":
            props?.selectedValue && props.selectedValue !== "" ? true : false,
        })}
        style={styleProps}
      >
        <span
          className={"hexa__separator"}
          style={{
            width: `calc(var(--size) / 2 + var(--margin))`,
            shapeOutside: `repeating-linear-gradient(#0000, #0000 calc(var(--shape) - 3px), #000 var(--shape))`,
          }}
        />

        {items.map((d, index: any) => {
          let _bgColor = "";
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (d?.status) {
            const _status: HexagonsStatusType = d.status;
            if (typeof HexagonsStatus[_status] !== "undefined")
              _bgColor = HexagonsStatus[_status];
          }
          if (typeof getBgColor === "function") {
            _bgColor = getBgColor(index, d);
          }
          if (_bgColor == "") _bgColor = HexagonsStatus.default;

          const _style: any = {
            background: _bgColor,
            width: `var(--size)`,
            margin: `var(--margin) var(--margin) calc(var(--margin) - var(--size) * ${defMarginBottomMultiply}) var(--margin) `,
            height: `calc(var(--size) * ${defHeightMultiply})`,
            //marginBottom: `calc(var(--margin) - var(--size) * ${defMarginBottomMultiply})`,
            cursor: onClick ? "pointer" : onHover ? "pointer" : "default",
          };
          const _attr: any = {
            style: _style,
          };
          let _tooltip: any = "";
          if (typeof props?.tooltipTemplate == "function") {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            const _content = props.tooltipTemplate(index, d);
            if (_content && _content != "") {
              const _id = Math.random().toString(36);
              _attr["data-tip"] = true;
              _attr["data-for"] = _id;
              _tooltip = (
                <Tooltip id={_id} place={"top"} type="dark" effect="solid">
                  {_content}
                </Tooltip>
              );
            }
          }

          return (
            <React.Fragment key={index}>
              <div
                className={classNames("hexagon__item", {
                  "is-selected": props?.selectedValue === d[props?.selectedKey],
                })}
                {..._attr}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                onMouseEnter={() => onHover && onHover(index, d)}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                onClick={() => onClick && onClick(index, d)}
              />
              {_tooltip}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
export default HexagonsGrid;
