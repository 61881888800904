import React, { useContext, useState } from "react";
import { showToast } from "store/toast-alerts/actions";
import { useDispatch } from "react-redux";
import {
  AlertsIntegrationIcon,
  ContainerizedIcon,
  CopyIconNew,
  DebAndUbuntuColorSvg,
  DockerColorSvg,
  KubernetesColorSvg,
  NativelyIcon,
  WindowsSvg,
} from "core/components/svg/icons";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import CodeViewer from "core/components/code-viewer";
import Button from "core/components/button";
import FormToggle from "core/components/form/form-toggle";
import FormTextInput from "core/components/form/form-text-input";
import CommonAgentInstallation from "views/modules/installation/components/apm/common-agent-installation";
import GoToDashboard from "../../common/goto-dashboard";
import Warning from "views/modules/installation/common/warning";
import { StepsEntity, StepsEnum } from "views/modules/integrations/entity";

export default function PythonAPM() {
  const context = useContext<ContextProps>(AppContext);

  const dispatch = useDispatch();
  const isHelloBarRendered = document.querySelector(".hello-bar") !== null;

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      dispatch(showToast("success", "Copied to Clipboard !"));
    });
  }

  const importTracker =
    "import logging \n" +
    " \n" +
    "from middleware import MwTracker\n" +
    "tracker=MwTracker()";

  const customLogging =
    'logging.info("info sample")\n' +
    'logging.warning("Sample Warning Log") \n' +
    "logging.error(\"Sample Error Log.\", extra={'tester': 'Alex'})\n";

  const stackErrors =
    "try:\n" +
    "    not_possible = 12/0\n" +
    "except ZeroDivisionError as e:\n" +
    "    tracker.record_error(e)\n";

  const steps: StepsEntity = {
    deployment_type: {
      title: "Choose Deployment Type",
    },
    code: {
      title: "Getting Started with Code",
    },
    setup_ini: {
      title: "Setup .ini File",
    },
    logs_error: {
      title: "Logs & Stack Errors",
    },
    start_project: {
      title: "Start Your Project",
    },
    see_data: {
      title: "View Your Data",
    },
  };
  const [activeStep, setActiveStep] = useState(StepsEnum.DeploymentType);
  const [activeDeployment, setActiveDeployment] = useState("kubernetes");
  const [activeAppType, setActiveAppType] = useState("containerized");
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const runProjectCmd = "middleware-apm run python app.py\n";
  const runDjangoProjectCmd =
    "DJANGO_SETTINGS_MODULE='mysite.settings' middleware-apm run python manage.py runserver";
  const MwAgentServiceK8s = "mw-service.mw-agent-ns.svc.cluster.local\n";
  const MwAgentServiceLinuxDocker = "MW_AGENT_SERVICE=172.17.0.1";
  const MwAgentServiceLinuxDockerCustom = "172.17.0.1";

  const MwAgentServiceInIni =
    "# The service name, where Middleware Agent is running, in case of K8s.\n" +
    `mw_agent_service = ${activeDeployment === "kubernetes" ? MwAgentServiceK8s : MwAgentServiceLinuxDockerCustom + "\n"}\n`;

  const [activeServiceName, setActiveServiceName] =
    useState("{APM-SERVICE-NAME}");
  const [activeTraces, setActiveTraces] = useState(true);
  const [activeMetrics, setActiveMetrics] = useState(true);
  const [activeLogs, setActiveLogs] = useState(true);
  const [activeProfiling, setActiveProfiling] = useState(true);

  const iniContent =
    "# ---------------------------------------------------------------------------\n" +
    "# This file contains settings for the Middleware Python-APM Agent.\n" +
    "# ---------------------------------------------------------------------------\n" +
    "\n" +
    "[middleware.common]\n" +
    "\n" +
    "# The name of your application as service-name, as it will appear in the UI to filter out your data.\n" +
    `service_name = ${activeServiceName || "Python-APM-Service"}\n` +
    "\n" +
    "# This Token binds the Python Agent's data and profiling data to your account.\n" +
    `access_token = ${context.user?.account?.agent_config?.token}\n` +
    "\n" +
    `${activeAppType === "containerized" ? MwAgentServiceInIni : ""}` +
    "# Toggle to enable/disable distributed traces for your application.\n" +
    `collect_traces = ${activeTraces}\n` +
    "\n" +
    "# Toggle to enable/disable the collection of metrics for your application.\n" +
    `collect_metrics = ${activeMetrics}\n` +
    "\n" +
    "# Toggle to enable/disable the collection of logs for your application.\n" +
    `collect_logs = ${activeLogs}\n` +
    "\n" +
    "# Toggle to enable/disable the collection of profiling data for your application.\n" +
    `collect_profiling = ${activeProfiling}\n` +
    "\n" +
    "# ---------------------------------------------------------------------------";

  return (
    <>
      <div
        className={
          "markdown-body installation-docs apm-installation-doc next-js-doc"
        }
      >
        <div className={"apm-body"}>
          <div className={"apm-steps"}>
            {steps &&
              Object.keys(steps).map((step, index) => {
                return (
                  <div
                    key={index + 1}
                    className={`step ${activeStep === step ? "selected" : ""} ${(Object.keys(steps).indexOf(activeStep) || 0) > index ? "passed" : ""}`}
                  >
                    <div className={"count"}>
                      <span>{index + 1}</span>
                    </div>
                    <div
                      className={"detail"}
                      onClick={() => setActiveStep(step)}
                    >
                      {steps[step].title}
                    </div>
                  </div>
                );
              })}
          </div>
          <div
            className={`apm-content ${!isHelloBarRendered && "hello-bar-exists"}`}
          >
            {activeStep === StepsEnum.DeploymentType && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>
                          1. Our APMs require the host agent in order to
                          operate. To install a host agent please select from
                          the below options.{" "}
                        </span>
                        <p>
                          <span>
                            (If the host already has an agent running please{" "}
                            <span onClick={() => setActiveStep("code")}>
                              {" "}
                              skip to Getting Started with Code.
                            </span>
                            )
                          </span>
                        </p>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Which platform are you hosting your Python
                          application?
                        </span>
                        <br />
                        <span>
                          Run only one MW Agent. Multiple agents will cause
                          unexpected behavior.
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"button-flex"}>
                          <button
                            className={
                              activeDeployment === "kubernetes" ? "active" : ""
                            }
                            onClick={() => {
                              setActiveDeployment("kubernetes");
                              setActiveAppType("containerized");
                            }}
                          >
                            <KubernetesColorSvg /> Kubernetes
                          </button>
                          <button
                            className={
                              activeDeployment === "linux" ? "active" : ""
                            }
                            onClick={() => setActiveDeployment("linux")}
                          >
                            <DebAndUbuntuColorSvg /> Linux
                          </button>
                          <button
                            className={
                              activeDeployment === "docker" ? "active" : ""
                            }
                            onClick={() => setActiveDeployment("docker")}
                          >
                            <DockerColorSvg /> Docker
                          </button>
                          <button
                            className={
                              activeDeployment === "windows" ? "active" : ""
                            }
                            onClick={() => setActiveDeployment("windows")}
                          >
                            <WindowsSvg /> Windows
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>2. Application Behaviour Will Be</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Please choose from below, as your application will run
                          accordingly.
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"button-flex"}>
                          <button
                            className={
                              activeAppType === "containerized" ? "active" : ""
                            }
                            onClick={() => setActiveAppType("containerized")}
                          >
                            <ContainerizedIcon /> Containerized
                          </button>
                          <button
                            className={`${activeAppType === "natively" ? "active" : ""} ${activeDeployment === "kubernetes" ? "disabled" : ""}`}
                            onClick={() => {
                              if (activeDeployment !== "kubernetes") {
                                setActiveAppType("natively");
                              }
                            }}
                          >
                            <NativelyIcon /> Natively
                          </button>
                        </div>
                      </div>
                    </div>
                    {activeAppType === "containerized" && (
                      <div className={"block"}>
                        <div className={"heading"}>
                          <span>3. Setup Container Variable</span>
                        </div>
                        <div className={"desc"}>
                          <span>
                            Application running in a container require an
                            additional environment variable
                          </span>
                        </div>
                        <div className={"content"}>
                          {activeDeployment === "kubernetes" && (
                            <>
                              <div className={"docs-script"}>
                                <CodeViewer
                                  showLineNumbers={false}
                                  language={"javascript"}
                                >
                                  {"MW_AGENT_SERVICE=" + MwAgentServiceK8s}
                                </CodeViewer>
                                <span
                                  className={"doc-copy-btn"}
                                  onClick={() =>
                                    copyToClipboard(
                                      "MW_AGENT_SERVICE=" + MwAgentServiceK8s
                                    )
                                  }
                                >
                                  <CopyIconNew size={8} />
                                </span>
                              </div>
                              <span className={"sentence mini"}>
                                The default namespace for running the Middleware
                                agent is{" "}
                                <strong>
                                  mw-service.mw-agent-ns.svc.cluster.local
                                </strong>
                                .
                              </span>
                              <br />
                            </>
                          )}
                          {activeDeployment !== "kubernetes" && (
                            <>
                              <div className={"docs-script"}>
                                <CodeViewer
                                  showLineNumbers={false}
                                  language={"javascript"}
                                >
                                  {MwAgentServiceLinuxDocker}
                                </CodeViewer>
                                <span
                                  className={"doc-copy-btn"}
                                  onClick={() =>
                                    copyToClipboard(MwAgentServiceLinuxDocker)
                                  }
                                >
                                  <CopyIconNew size={8} />
                                </span>
                              </div>
                              <span className={"sentence mini"}>
                                The default value for{" "}
                                <strong>DOCKER_BRIDGE_GATEWAY_ADDRESS</strong>{" "}
                                is typically <strong>172.17.0.1</strong>.
                              </span>
                              <br />
                              <span className={"sentence mini"}>
                                For more detailed information, refer to the
                                official Docker&nbsp;
                                <a
                                  href={
                                    "https://docs.docker.com/network/network-tutorial-standalone/"
                                  }
                                  target={"_blank"}
                                >
                                  documentation
                                </a>
                                .
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    <div className={"block"}>
                      <CommonAgentInstallation
                        activeDeployment={activeDeployment}
                        activeAppType={activeAppType}
                      />
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button className={"disabled"}>Back</Button>
                  <Button primary onClick={() => setActiveStep("code")}>
                    Next
                  </Button>
                </div>
              </>
            )}

            {activeStep === "code" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>1. Install Python APM package</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Run the following command at the root of your
                          workspace:
                        </span>{" "}
                        <br />
                        <span>
                          Make sure you have installed the Python version with
                          greater than{" "}
                          <a
                            href={
                              "https://www.python.org/downloads/release/python-380/"
                            }
                            target={"_blank"}
                            rel={"noreferrer"}
                          >
                            v3.8.
                          </a>
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer
                            showLineNumbers={false}
                            language={"python"}
                          >
                            {"pip install middleware-apm"}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() =>
                              copyToClipboard("pip install middleware-apm\n")
                            }
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Check the middleware-apm was installed with the
                          following command:
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer
                            showLineNumbers={false}
                            language={"python"}
                          >
                            {"pip list"}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard("pip list\n")}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>2. Import Middleware Tracker</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Import the following code at the beginning of your
                          project:
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer
                            showLineNumbers={false}
                            language={"python"}
                          >
                            {importTracker}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard(importTracker)}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>
                          3. Install Instrumentation Packages (Optional)
                        </span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Add the following command to your Dockerfile to reduce
                          your application start up time. This command installs
                          instrumentation packages by crawling through your
                          active site packages and finding any libraries that
                          support auto-instrumentation.
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer
                            showLineNumbers={false}
                            language={"python"}
                          >
                            {"RUN middleware-bootstrap -a install"}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() =>
                              copyToClipboard(
                                "RUN middleware-bootstrap -a install"
                              )
                            }
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"help"}>
                    <div className={"block"}>
                      <AlertsIntegrationIcon /> You can clone the sample project
                      from the{" "}
                      <a
                        href={
                          "https://github.com/middleware-labs/demo-apm/tree/master/nextjs/setup"
                        }
                        target={"_blank"}
                      >
                        GitHub
                      </a>{" "}
                      repository.
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button
                    onClick={() => setActiveStep(StepsEnum.DeploymentType)}
                  >
                    Back
                  </Button>
                  <Button primary onClick={() => setActiveStep("setup_ini")}>
                    Next
                  </Button>
                </div>
              </>
            )}
            {activeStep === "setup_ini" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>
                          Create a <i>middleware.ini</i> file based on the
                          features you want to observe. service_name and
                          access_token are required for the tracker to send data
                          to Middleware.
                        </span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Make sure the <i>middleware.ini</i> file is saved in
                          the same folder as your app.
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"config-setting"}>
                          <div className={"inputbox-flex"}>
                            <FormTextInput
                              label={"Service Name"}
                              maxLength={50}
                              onChange={(e: any) =>
                                setActiveServiceName(e.target.value)
                              }
                            />
                          </div>
                          <div className={"checkbox-flex"}>
                            <div className={"check-box"}>
                              <FormToggle
                                checked={activeTraces}
                                onChange={() => setActiveTraces(!activeTraces)}
                              />
                              <span className={"title"}>Traces</span>
                            </div>
                            <div className={"check-box"}>
                              <FormToggle
                                checked={activeMetrics}
                                onChange={() =>
                                  setActiveMetrics(!activeMetrics)
                                }
                              />
                              <span className={"title"}>Metrics</span>
                            </div>
                            <div className={"check-box"}>
                              <FormToggle
                                checked={activeLogs}
                                onChange={() => setActiveLogs(!activeLogs)}
                              />
                              <span className={"title"}>Logs</span>
                            </div>
                            <div className={"check-box"}>
                              <FormToggle
                                checked={activeProfiling}
                                onChange={() =>
                                  setActiveProfiling(!activeProfiling)
                                }
                              />
                              <span className={"title"}>Profiling</span>
                            </div>
                          </div>
                        </div>
                        <div className={"docs-script"}>
                          <CodeViewer
                            showLineNumbers={false}
                            language={"python"}
                          >
                            {iniContent}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard(iniContent)}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button onClick={() => setActiveStep("code")}>Back</Button>
                  <Button primary onClick={() => setActiveStep("logs_error")}>
                    Next
                  </Button>
                </div>
              </>
            )}
            {activeStep === "logs_error" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>1. Enable Custom Logs (Optional)</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Custom logs are ones that the application does not
                          produce by default. They may be generated within
                          certain methods, or managed by a central logging
                          method in your application.
                        </span>
                        <br />
                        <span>
                          To get started with custom logs add the following
                          code:
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer
                            showLineNumbers={false}
                            language={"python"}
                          >
                            {customLogging}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard(customLogging)}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>2. Record Stack-Errors (Optional)</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Use tracker.record_error() method to record a stack
                          trace when an error occurs:
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer
                            showLineNumbers={false}
                            language={"python"}
                          >
                            {stackErrors}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard(stackErrors)}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button onClick={() => setActiveStep("setup_ini")}>
                    Back
                  </Button>
                  <Button
                    primary
                    onClick={() => setActiveStep("start_project")}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}

            {activeStep === "start_project" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>Start Your Project</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          After deploying your application, run the following
                          command to start your project:
                        </span>
                      </div>
                      <div className={"desc"} style={{ marginTop: "5px" }}>
                        <span>
                          If <strong>middleware.ini</strong> isn't in your
                          project's root, set{" "}
                          <strong>
                            MIDDLEWARE_CONFIG_FILE=./path/to/middleware.ini
                          </strong>{" "}
                          in the below run command.
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer
                            showLineNumbers={false}
                            language={"python"}
                          >
                            {runProjectCmd}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard(runProjectCmd)}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                      <div className={"desc"}>
                        <Warning
                          warning={
                            "If you are using the Django framework in your Python application, skip the above command and proceed to the next section."
                          }
                        />
                      </div>
                    </div>
                    <div className={"block-group"}>
                      <div className={"block"}>
                        <div className={"heading"}>
                          <span>Django Instrumentation (Optional)</span>
                        </div>
                        <div className={"desc"}>
                          <span>
                            Add the following code to your main() function in
                            manage.py, if you are using the Django framework:
                          </span>
                        </div>
                        <div className={"content"}>
                          <div className={"docs-script"}>
                            <CodeViewer
                              showLineNumbers={false}
                              language={"python"}
                            >
                              {"tracker.django_instrument()"}
                            </CodeViewer>
                            <span
                              className={"doc-copy-btn"}
                              onClick={() =>
                                copyToClipboard("tracker.django_instrument()")
                              }
                            >
                              <CopyIconNew size={8} />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={"block"}>
                        <div className={"heading"}>
                          <span>Start Your Django Project (Optional)</span>
                        </div>
                        <div className={"desc"}>
                          <span>
                            Initialize your Django project with the following
                            command:
                          </span>
                        </div>
                        <div className={"content"}>
                          <div className={"docs-script"}>
                            <CodeViewer
                              showLineNumbers={false}
                              language={"python"}
                            >
                              {runDjangoProjectCmd}
                            </CodeViewer>
                            <span
                              className={"doc-copy-btn"}
                              onClick={() =>
                                copyToClipboard(runDjangoProjectCmd)
                              }
                            >
                              <CopyIconNew size={8} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button onClick={() => setActiveStep("logs_error")}>
                    Back
                  </Button>
                  <Button primary onClick={() => setActiveStep("see_data")}>
                    Next
                  </Button>
                </div>
              </>
            )}
            {activeStep === "see_data" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>View Your Data</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Once you have completed the Python installation, wait
                          3-5 minutes before accessing your data.
                        </span>
                      </div>
                      <div className={"content"}>
                        <p
                          className={"paragraph"}
                          style={{ marginTop: "10px", marginBottom: "0" }}
                        >
                          Access your Trace, Log, and Profiling data by clicking
                          on one of the buttons bellow.
                        </p>
                        <p className={"paragraph"} style={{ marginTop: "0px" }}>
                          Proceed to deploy your project on{" "}
                          {capitalizeFirstLetter(activeDeployment)}.
                        </p>
                        <div className={"buttons-group"}>
                          <Button
                            primary
                            onClick={() =>
                              window.open("/apm/overview", "_blank")
                            }
                          >
                            Traces
                          </Button>
                          <Button
                            primary
                            onClick={() => window.open("/logs", "_blank")}
                          >
                            Logs
                          </Button>
                          {activeDeployment != "windows" ? (
                            <Button
                              primary
                              onClick={() =>
                                window.open(
                                  "/apm/continuous-profiling",
                                  "_blank"
                                )
                              }
                            >
                              Profiling
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button onClick={() => setActiveStep("start_project")}>
                    Back
                  </Button>
                  <GoToDashboard
                    dashboardURL={`/dashboards/python_metrics_${context?.user?.account?.project_id}`}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
