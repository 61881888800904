import { ProjectData } from "views/modules/settings-v2/projects/entities";

const billingPopupKeyName = "billing_popup";
const BillingPopupEnum = {
  closed: "closed",
};

const setBillingPopupInSession = (value: string) => {
  sessionStorage.setItem(billingPopupKeyName, value);
};

const PermissionsEnum = {
  InviteUser: "access_to_invite_user",
  ViewUsers: "access_to_view_users",
  Installation: "access_to_view_installation_script",
  ViewApiKey: "access_to_view_api_keys",
  CreateApiKey: "create_api_keys",
  EditOrRemoveApiKey: "edit_remove_api_key",
  ViewTeams: "access_to_view_teams",
  CreateTeams: "create_teams",
  EditOrDeleteTeams: "edit_remove_teams",
  Billing: "billing_and_usages",
  ViewAuditLogs: "view_audit_logs",
  ExportAuditLogs: "export_audit_logs",
  SetupNotificationPreference: "setup_notification_preference",
  SetupTimeZone: "setup_timezone",
  UnifiedView: "unified_view",
  HostList: "host_list",
  Containers: "containers",
  Process: "process",
  APMDashboard: "apm_dashboard",
  Traces: "traces",
  DatabaseMonitoring: "database_monitoring",
  ServiceList: "service_list",
  ContinuousProfiling: "continuous_profiling",
  Logs: "logs",
  CreateAlerts: "create_new_alerts",
  ViewAlertHistory: "view_alert_history",
};
const DEFAULT_PERMISSION_ERROR_TEXT =
  "You don't have permission. Please contact administrator.";

const GetDbDeletedflag = (projects: ProjectData[], aciveProjectId: number) => {
  return (
    Array.isArray(projects) &&
    projects.some((item) => item.id === aciveProjectId && item.db_deleted)
  );
};
export {
  BillingPopupEnum,
  DEFAULT_PERMISSION_ERROR_TEXT,
  GetDbDeletedflag,
  PermissionsEnum,
  billingPopupKeyName,
  setBillingPopupInSession,
};
