import * as React from "react"
import {SidebarCardBox, SidebarCardListItem, SidebarHeading} from "views/layouts/app/sidebar/micro-components";
import Skeleton from "react-loading-skeleton";

export default function SidebarPlaceholder() {
    return <div className={"main__sidebar__loader"}>
        <SidebarHeading>
            <Skeleton borderRadius={2} width={"100%"}/>
        </SidebarHeading>

        <SidebarCardBox borderBottom>
            <div style={{display: "block", width: "100%", marginBottom: "3px"}}>
                <Skeleton baseColor={"var(--color-grey-300)"} borderRadius={2} count={5} height={18} width={"100%"}/></div>
        </SidebarCardBox>

        {new Array(3).fill(Math.random()).map((k0: any, index0: number) => {
            return <SidebarCardBox borderBottom key={index0}>
                <div style={{display: "block", width: "100%", marginBottom: "3px"}}>
                    <Skeleton baseColor={"var(--color-grey-300)"} borderRadius={2} count={5} height={18} width={"100%"}/></div>
            </SidebarCardBox>
        })}

    </div>
}