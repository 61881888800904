import React, { useContext, useState } from "react";
import { showToast } from "store/toast-alerts/actions";
import { useDispatch } from "react-redux";

import {
  KubernetesColorSvg,
  DebAndUbuntuColorSvg,
  DockerColorSvg,
  WindowsSvg,
  ContainerizedIcon,
  NativelyIcon,
  AlertsIntegrationIcon,
  DotNetBigIcon,
  CopyIconNew,
} from "core/components/svg/icons";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import CodeViewer from "core/components/code-viewer";
import Button from "core/components/button";
import CommonAgentInstallation from "views/modules/installation/components/apm/common-agent-installation";
import FormTextInput from "core/components/form/form-text-input";
import GoToDashboard from "../../common/goto-dashboard";
import { StepsEntity, StepsEnum } from "views/modules/integrations/entity";
import Note from "views/modules/installation/common/note";

export default function Dotnet() {
  const context = useContext<ContextProps>(AppContext);
  const isHelloBarRendered = document.querySelector(".hello-bar") !== null;

  const dispatch = useDispatch();

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      dispatch(showToast("success", "Copied to Clipboard !"));
    });
  }

  const [activeServiceName, setActiveServiceName] =
    useState(".Net-APM-Service");
  const projectName = `MW_API_KEY=${context.user?.account?.agent_config?.token} . $HOME/.mw-dotnet-auto/instrument.sh && OTEL_SERVICE_NAME="${activeServiceName == "" ? "{APM-SERVICE-NAME}" : activeServiceName}" dotnet path/to/YourApplication.dll`;
  const projectNameDum = `Register-OpenTelemetryForCurrentSession -OTelServiceName="${activeServiceName == "" ? "{APM-SERVICE-NAME}" : activeServiceName}"`;

  const cmnK8sSnipt =
    "RUN apt-get update && apt-get install -y curl unzip\n" +
    "RUN curl -sSfL https://install.middleware.io/apm/dotnet/v1.0.0-rc.1/scripts/mw-dotnet-auto-install.sh -O \n" +
    "RUN bash mw-dotnet-auto-install.sh \n" +
    "RUN rm -rf mw-dotnet-auto-install.sh\n" +
    "RUN chmod +x $HOME/.mw-dotnet-auto/instrument.sh\n" +
    "...........\n" +
    "CMD . $HOME/.mw-dotnet-auto/instrument.sh && dotnet Mw-WebApplication.dll";

  const steps: StepsEntity = {
    deployment_type: {
      title: "Choose Deployment Type",
    },
    code: {
      title: "Getting Started with Code",
    },
    custom_logs: {
      title: "Setup Logs",
    },
    see_data: {
      title: "View Your Data",
    },
  };
  const [activeStep, setActiveStep] = useState(StepsEnum.DeploymentType);
  const [activeDeployment, setActiveDeployment] = useState("kubernetes");
  const [activeAppType, setActiveAppType] = useState("containerized");
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const MwAgentServiceK8s =
    "MW_AGENT_SERVICE=mw-service.mw-agent-ns.svc.cluster.local\n";
  const MwAgentServiceLinuxDocker = "MW_AGENT_SERVICE=172.17.0.1";

  const dotNet =
    "<ItemGroup>\n" +
    '   <Reference Include="Middleware">\n' +
    "       <HintPath>path\\to\\Middleware.dll</HintPath>\n" +
    "   </Reference>\n" +
    "</ItemGroup>";

  const customLogs =
    "using Middleware.Logger;\n" +
    "...\n" +
    'Logger.Info("This is info log");\n' +
    'Logger.Warning("This is info log");\n' +
    'Logger.Debug("This is info log");\n' +
    "Logger.Error(<Object of Exception>);";

  const applicationLogs =
    '<Project Sdk="Microsoft.NET.Sdk.Web">    \n' +
    "    ...\n" +
    "    <ItemGroup>\n" +
    '      <PackageReference Include="OpenTelemetry" Version="1.5.1" />\n' +
    '      <PackageReference Include="OpenTelemetry.Exporter.Console" Version="1.5.1" />\n' +
    "    </ItemGroup>\n" +
    "    ...\n" +
    "</Project>";

  const applicationLogs1 =
    "using OpenTelemetry.Logs;\n" +
    "using OpenTelemetry.Resources;\n" +
    "...\n" +
    "\n" +
    "var builder = WebApplication.CreateBuilder(args);\n" +
    " \n" +
    "builder.Logging.AddOpenTelemetry(options => \n" +
    "{\n" +
    "    options.AddConsoleExporter();\n" +
    "});\n" +
    "...\n" +
    "\n" +
    "var app = builder.Build();\n" +
    "...\n" +
    "\n" +
    "app.Run();";

  return (
    <>
      <div
        className={
          "markdown-body installation-docs apm-installation-doc java-doc"
        }
      >
        <div className={"apm-body"}>
          <div className={"apm-steps"}>
            {steps &&
              Object.keys(steps).map((step, index) => {
                return (
                  <div
                    key={index + 1}
                    className={`step ${activeStep === step ? "selected" : ""} ${(Object.keys(steps).indexOf(activeStep) || 0) > index ? "passed" : ""}`}
                  >
                    <div className={"count"}>
                      <span>{index + 1}</span>
                    </div>
                    <div
                      className={"detail"}
                      onClick={() => setActiveStep(step)}
                    >
                      {steps[step].title}
                    </div>
                  </div>
                );
              })}
          </div>
          <div
            className={`apm-content ${!isHelloBarRendered && "hello-bar-exists"}`}
          >
            {activeStep === StepsEnum.DeploymentType && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>
                          1. Our APMs require the host agent in order to
                          operate. To install a host agent please select from
                          the below options.{" "}
                        </span>
                        <p>
                          <span>
                            (If the host already has an agent running please{" "}
                            <span onClick={() => setActiveStep("code")}>
                              {" "}
                              skip to Getting Started with Code.
                            </span>
                            )
                          </span>
                        </p>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Run only one MW Agent. Multiple agents will cause
                          unexpected behavior.
                        </span>
                        <br />
                        <span>
                          Which platform are you hosting your .NET application?
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"button-flex"}>
                          <button
                            className={
                              activeDeployment === "kubernetes" ? "active" : ""
                            }
                            onClick={() => {
                              setActiveDeployment("kubernetes");
                              setActiveAppType("containerized");
                            }}
                          >
                            <KubernetesColorSvg /> Kubernetes
                          </button>
                          <button
                            className={
                              activeDeployment === "linux" ? "active" : ""
                            }
                            onClick={() => setActiveDeployment("linux")}
                          >
                            <DebAndUbuntuColorSvg /> Linux
                          </button>
                          <button
                            className={
                              activeDeployment === "docker" ? "active" : ""
                            }
                            onClick={() => setActiveDeployment("docker")}
                          >
                            <DockerColorSvg /> Docker
                          </button>
                          <button
                            className={
                              activeDeployment === "windows" ? "active" : ""
                            }
                            onClick={() => setActiveDeployment("windows")}
                          >
                            <WindowsSvg /> Windows
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>2. Application Behaviour Will Be</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Please choose from below, as your application will run
                          accordingly.
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"button-flex"}>
                          <button
                            className={
                              activeAppType === "containerized" ? "active" : ""
                            }
                            onClick={() => setActiveAppType("containerized")}
                          >
                            <ContainerizedIcon /> Containerized
                          </button>
                          <button
                            className={`${activeAppType === "natively" ? "active" : ""} ${activeDeployment === "kubernetes" ? "disabled" : ""}`}
                            onClick={() => {
                              if (activeDeployment !== "kubernetes") {
                                setActiveAppType("natively");
                              }
                            }}
                          >
                            <NativelyIcon /> Natively
                          </button>
                        </div>
                      </div>
                    </div>
                    {activeAppType === "containerized" && (
                      <div className={"block"}>
                        <div className={"heading"}>
                          <span>3. Setup Container Variable</span>
                        </div>
                        <div className={"desc"}>
                          <span>
                            Application running in a container require an
                            additional environment variable
                          </span>
                        </div>
                        <div className={"content"}>
                          {activeDeployment === "kubernetes" && (
                            <>
                              <div className={"docs-script"}>
                                <CodeViewer
                                  showLineNumbers={false}
                                  language={"php"}
                                >
                                  {MwAgentServiceK8s}
                                </CodeViewer>
                                <span
                                  className={"doc-copy-btn"}
                                  onClick={() =>
                                    copyToClipboard(MwAgentServiceK8s)
                                  }
                                >
                                  <CopyIconNew size={8} />
                                </span>
                              </div>
                              <span className={"sentence mini"}>
                                The default namespace for running the Middleware
                                agent is{" "}
                                <strong>
                                  mw-service.mw-agent-ns.svc.cluster.local
                                </strong>
                                .
                              </span>
                              <br />
                            </>
                          )}
                          {activeDeployment !== "kubernetes" && (
                            <>
                              <div className={"docs-script"}>
                                <CodeViewer
                                  showLineNumbers={false}
                                  language={"html"}
                                >
                                  {MwAgentServiceLinuxDocker}
                                </CodeViewer>
                                <span
                                  className={"doc-copy-btn"}
                                  onClick={() =>
                                    copyToClipboard(MwAgentServiceLinuxDocker)
                                  }
                                >
                                  <CopyIconNew size={8} />
                                </span>
                              </div>
                              <span className={"sentence mini"}>
                                The default value for{" "}
                                <strong>DOCKER_BRIDGE_GATEWAY_ADDRESS</strong>{" "}
                                is typically <strong>172.17.0.1</strong>.
                              </span>
                              <br />
                              <span className={"sentence mini"}>
                                For more detailed information, refer to the
                                official Docker&nbsp;
                                <a
                                  href={
                                    "https://docs.docker.com/network/network-tutorial-standalone/"
                                  }
                                  target={"_blank"}
                                >
                                  documentation
                                </a>
                                .
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    <div className={"block"}>
                      <CommonAgentInstallation
                        activeDeployment={activeDeployment}
                        activeAppType={activeAppType}
                      />
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button className={"disabled"}>Back</Button>
                  <Button primary onClick={() => setActiveStep("code")}>
                    Next
                  </Button>
                </div>
              </>
            )}

            {activeStep === "code" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>1. Setup Middleware .NET project</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          To install the Middleware Agent, your .NET version
                          must have{" "}
                          <a
                            href={
                              "https://dotnet.microsoft.com/en-us/download/dotnet/6.0"
                            }
                            target={"_blank"}
                            rel={"noreferrer"}
                          >
                            version 6.0+
                          </a>
                          .
                        </span>{" "}
                        <br />
                        <span>
                          Make sure you have{" "}
                          <a
                            href={"https://curl.se/"}
                            target={"_blank"}
                            rel={"noreferrer"}
                          >
                            Curl
                          </a>
                          ,{" "}
                          <a href={"#"} target={"_blank"} rel={"noreferrer"}>
                            Unzip
                          </a>{" "}
                          tools installed, in order to go forward with .NET APM.
                        </span>
                        <br />
                        <span>Add Following code in .csproj file.</span>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer showLineNumbers={false} language={"html"}>
                            {dotNet}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard(dotNet)}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                    {activeDeployment === "linux" && (
                      <div>
                        <div className={"block"}>
                          <div className={"heading"}>
                            <span>2. .NET binary installation</span>
                          </div>
                          <div className={"desc"}>
                            <span>Download the bash script.</span>
                          </div>
                          <div className={"content"}>
                            <div className={"docs-script"}>
                              <CodeViewer
                                showLineNumbers={false}
                                language={"html"}
                              >
                                {
                                  "curl -sSfL https://install.middleware.io/apm/dotnet/v1.0.0-rc.1/scripts/mw-dotnet-auto-install.sh -O"
                                }
                              </CodeViewer>
                              <span
                                className={"doc-copy-btn"}
                                onClick={() =>
                                  copyToClipboard(
                                    "curl -sSfL https://install.middleware.io/apm/dotnet/v1.0.0-rc.1/scripts/mw-dotnet-auto-install.sh -O"
                                  )
                                }
                              >
                                <CopyIconNew size={8} />
                              </span>
                            </div>
                            <p className={"fn-size"}>Install core files</p>
                            <div className={"docs-script"}>
                              <CodeViewer
                                showLineNumbers={false}
                                language={"html"}
                              >
                                {"sh ./mw-dotnet-auto-install.sh\n" +
                                  "rm -rf mw-dotnet-auto-install.sh"}
                              </CodeViewer>
                              <span
                                className={"doc-copy-btn"}
                                onClick={() =>
                                  copyToClipboard(
                                    "sh ./mw-dotnet-auto-install.sh\n" +
                                      "rm -rf mw-dotnet-auto-install.sh"
                                  )
                                }
                              >
                                <CopyIconNew size={8} />
                              </span>
                            </div>
                            <p className={"fn-size"}>
                              Enable execution for the instrumentation script
                            </p>
                            <div className={"docs-script"}>
                              <CodeViewer
                                showLineNumbers={false}
                                language={"html"}
                              >
                                {"chmod +x $HOME/.mw-dotnet-auto/instrument.sh"}
                              </CodeViewer>
                              <span
                                className={"doc-copy-btn"}
                                onClick={() =>
                                  copyToClipboard(
                                    "chmod +x $HOME/.mw-dotnet-auto/instrument.sh"
                                  )
                                }
                              >
                                <CopyIconNew size={8} />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={"block"}>
                          <div className={"heading"}>
                            <span>3. Build and run .NET application</span>
                          </div>
                          <div className={"content"}>
                            <div
                              className={"inputbox-flex"}
                              style={{ marginTop: 15 }}
                            >
                              <FormTextInput
                                label={"Service Name"}
                                placeholder={"Service Name"}
                                maxLength={50}
                                onChange={(e: any) =>
                                  setActiveServiceName(e.target.value)
                                }
                              />
                            </div>
                            <div className={"docs-script"}>
                              <CodeViewer
                                showLineNumbers={false}
                                language={"html"}
                              >
                                {projectName}
                              </CodeViewer>
                              <span
                                className={"doc-copy-btn"}
                                onClick={() => copyToClipboard(projectName)}
                              >
                                <CopyIconNew size={8} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {activeDeployment === "kubernetes" && (
                      <div className={"blocks"}>
                        <div className={"block"}>
                          <div className={"heading"}>
                            <span>2. .NET binary installation</span>
                          </div>
                          <div className={"desc"}>
                            <span>Add this script in your Dockerfile.</span>
                            <Note
                              note={
                                "Make sure to modify your Dockerfile with the instructions given below, before re-deploying your app on Kubernetes"
                              }
                            />
                          </div>
                          <div className={"content"}>
                            <div className={"docs-script"}>
                              <CodeViewer
                                showLineNumbers={false}
                                language={"html"}
                              >
                                {cmnK8sSnipt}
                              </CodeViewer>
                              <span
                                className={"doc-copy-btn"}
                                onClick={() => copyToClipboard(cmnK8sSnipt)}
                              >
                                <CopyIconNew size={8} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeDeployment === "docker" && (
                      <div className={"blocks"}>
                        <div className={"block"}>
                          <div className={"heading"}>
                            <span>2. NET binary installation</span>
                          </div>
                          <div className={"desc"}>
                            <span>Add this script in your Dockerfile.</span>
                          </div>
                          <div className={"content"}>
                            <div className={"docs-script"}>
                              <CodeViewer
                                showLineNumbers={false}
                                language={"html"}
                              >
                                {cmnK8sSnipt}
                              </CodeViewer>
                              <span
                                className={"doc-copy-btn"}
                                onClick={() => copyToClipboard(cmnK8sSnipt)}
                              >
                                <CopyIconNew size={8} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeDeployment === "windows" && (
                      <div>
                        <div className={"block"}>
                          <div className={"heading"}>
                            <span>2. .NET binary installation</span>
                          </div>
                          <div className={"desc"}>
                            <span># Download the script</span>
                          </div>
                          <div className={"content"}>
                            <div className={"docs-script"}>
                              <CodeViewer
                                showLineNumbers={false}
                                language={"html"}
                              >
                                {'$install = Join-Path $env:temp "mw-dotnet-auto-install-setup.ps1"\n' +
                                  'Invoke-WebRequest -Uri "https://install.middleware.io/apm/dotnet/v1.0.0-rc.1/scripts/mw-dotnet-auto-install-setup.ps1" -OutFile $install -UseBasicParsing'}
                              </CodeViewer>
                              <span
                                className={"doc-copy-btn"}
                                onClick={() =>
                                  copyToClipboard(
                                    '$install = Join-Path $env:temp "mw-dotnet-auto-install-setup.ps1"\n' +
                                      'Invoke-WebRequest -Uri "https://install.middleware.io/apm/dotnet/v1.0.0-rc.1/scripts/mw-dotnet-auto-install-setup.ps1" -OutFile $install -UseBasicParsing'
                                  )
                                }
                              >
                                <CopyIconNew size={8} />
                              </span>
                            </div>
                            <p className={"fn-size"}># Execute the script</p>
                            <div className={"docs-script"}>
                              <CodeViewer
                                showLineNumbers={false}
                                language={"html"}
                              >
                                {". $install"}
                              </CodeViewer>
                              <span
                                className={"doc-copy-btn"}
                                onClick={() => copyToClipboard(". $install")}
                              >
                                <CopyIconNew size={8} />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={"block"}>
                          <div className={"heading"}>
                            <span>3. Build and run .NET application</span>
                          </div>
                          <div className={"content"}>
                            <div
                              className={"inputbox-flex"}
                              style={{ marginTop: 15 }}
                            >
                              <FormTextInput
                                label={"Service Name"}
                                placeholder={"Service Name"}
                                maxLength={50}
                                onChange={(e: any) =>
                                  setActiveServiceName(e.target.value)
                                }
                              />
                            </div>
                            <div className={"docs-script"}>
                              <CodeViewer
                                showLineNumbers={false}
                                language={"html"}
                              >
                                {projectNameDum}
                              </CodeViewer>
                              <span
                                className={"doc-copy-btn"}
                                onClick={() => copyToClipboard(projectNameDum)}
                              >
                                <CopyIconNew size={8} />
                              </span>
                            </div>
                            <p className={"fn-size"}>
                              # Run your application with instrumentation.
                            </p>
                            <div className={"docs-script"}>
                              <CodeViewer
                                showLineNumbers={false}
                                language={"html"}
                              >
                                {".\\YourNetApp.exe"}
                              </CodeViewer>
                              <span
                                className={"doc-copy-btn"}
                                onClick={() =>
                                  copyToClipboard(".\\YourNetApp.exe")
                                }
                              >
                                <CopyIconNew size={8} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={"help"}>
                    <div className={"block"}>
                      <AlertsIntegrationIcon /> Download Latest Middleware.dll
                      from{" "}
                      <a
                        href={
                          "https://github.com/middleware-labs/dotnet-plugin/releases"
                        }
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        here
                      </a>
                      .
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button
                    onClick={() => setActiveStep(StepsEnum.DeploymentType)}
                  >
                    Back
                  </Button>
                  <Button primary onClick={() => setActiveStep("custom_logs")}>
                    Next
                  </Button>
                </div>
              </>
            )}
            {activeStep === "custom_logs" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>Enable Custom Logs (Optional)</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Custom logs are ones that the application does not
                          produce by default. They may be generated within
                          certain methods, or managed by a central logging
                          method in your application.
                        </span>
                        <br />
                        <span>
                          To get started with custom logs add the following
                          code:
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer showLineNumbers={false} language={"html"}>
                            {customLogs}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard(customLogs)}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>Application Logs feature Configuration.</span>
                      </div>
                      <div className={"desc"}>
                        <p className={"fn-size"}>
                          Add required package reference in{" "}
                          <span>YourApp.csproj</span> file.
                        </p>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer showLineNumbers={false} language={"html"}>
                            {applicationLogs}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard(applicationLogs)}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>

                      <div className={"desc"}>
                        <p className={"fn-size"}>
                          Add the following code to your <span>Project.cs</span>{" "}
                          file to enable Application logs:
                        </p>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer showLineNumbers={false} language={"html"}>
                            {applicationLogs1}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard(applicationLogs1)}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button onClick={() => setActiveStep("code")}>Back</Button>
                  <Button primary onClick={() => setActiveStep("see_data")}>
                    Next
                  </Button>
                </div>
              </>
            )}
            {activeStep === "see_data" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>View Your Data</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Once you have completed the .NET installation, wait
                          3-5 minutes before accessing your data.
                        </span>
                      </div>
                      <div className={"content"}>
                        <p
                          className={"paragraph"}
                          style={{ marginTop: "10px", marginBottom: "0" }}
                        >
                          Access your Trace, Log, and Profiling data by clicking
                          on one of the buttons bellow.
                        </p>
                        <p className={"paragraph"} style={{ marginTop: "0px" }}>
                          Proceed to deploy your project on{" "}
                          {capitalizeFirstLetter(activeDeployment)}.
                        </p>
                        <div className={"buttons-group"}>
                          <Button
                            primary
                            onClick={() =>
                              window.open("/apm/overview", "_blank")
                            }
                          >
                            Traces
                          </Button>
                          <Button
                            primary
                            onClick={() => window.open("/logs", "_blank")}
                          >
                            Logs
                          </Button>
                          {activeDeployment != "windows" ? (
                            <Button
                              primary
                              onClick={() =>
                                window.open(
                                  "/apm/continuous-profiling",
                                  "_blank"
                                )
                              }
                            >
                              Profiling
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button onClick={() => setActiveStep("custom_logs")}>
                    Back
                  </Button>
                  <GoToDashboard
                    dashboardURL={`/dashboards/dot_net_metrics_${context?.user?.account?.project_id}`}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
