import classNames from "classnames";
import { omit } from "lodash-es";
import React from "react";
import * as Modal from "react-modal";

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  position: "center" | "right";
  overlayClassName?: string;
  contentClassName?: string;
  htmlOpenClassName?: string;
  size?:
    | "sx"
    | "sm"
    | "md"
    | "lg"
    | "default"
    | "xxs"
    | "xxxs"
    | "xl"
    | "xxl"
    | "full";
  children: React.ReactNode;
  verticalCenter?: boolean;
  dataTestIdCloseAction?: string;
  hideCloseButton?: boolean;
}
const Dialog = (props: DialogProps) => {
  const {
    onClose,
    htmlOpenClassName,
    position,
    overlayClassName,
    size,
    contentClassName,
    isOpen,
    children,
    verticalCenter,
    dataTestIdCloseAction,
    hideCloseButton = false,
  } = props;
  const closeAction = () => {
    const cls = document.getElementsByClassName("ReactModal__Content");
    if (cls != null) {
      cls[0].classList.add("ReactModal__Content__ReadyToClose");
    }
    if (onClose) {
      onClose();
    }
  };
  const restOfProps = omit(
    props,
    "onClose",
    "overlayClassName",
    "contentClassName",
    "htmlOpenClassName"
  );
  const htmlOpenClass = classNames(
    "ReactModal__Html--open",
    `dialog-html-position__${position}`,
    htmlOpenClassName
  );
  const overlayClass = classNames(
    "dialog__backdrop",
    "mw_dialog__backdrop",
    `backdrop-position__${position}`,
    overlayClassName,
    {
      [`dialog__size__${size}`]: size,
    }
  );
  const contentClass = classNames(
    "dialog__content",
    `dialog-content-position__${position}`,
    contentClassName,
    {
      "is-vertical-center": verticalCenter,
    }
  );
  const styleContent = {};
  return (
    <>
      {isOpen && (
        <Modal
          appElement={document.getElementById("main__app-root")}
          onRequestClose={closeAction}
          style={{ content: styleContent }}
          overlayClassName={overlayClass}
          className={contentClass}
          htmlOpenClassName={htmlOpenClass}
          role="dialog"
          {...restOfProps}
        >
          {children}
          {!hideCloseButton && (
            <button
              data-testid={dataTestIdCloseAction}
              className={"dialog__close-action"}
              onClick={closeAction}
            >
              &times;
            </button>
          )}
        </Modal>
      )}
    </>
  );
};
export default Dialog;
