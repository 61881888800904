import CreateRequest from "core/xhr";
import {
  dashboardOverviewReceived,
  installationOverviewReceived,
} from "store/onboard-dashboard/actions";

export const requestDashboardOverview = (
  data?: any,
  callback?: (res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/onboard-dashboard/overview" + `?sts=${data.sts}&ets=${data.ets}`,
      method: "get",
      data: {},
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(dashboardOverviewReceived(res.data));
        } else {
          dispatch(dashboardOverviewReceived());
        }
        if (callback) {
          callback(res);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};
export const requestInstallationData = (
  data?: any,
  callback?: (res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/onboard-dashboard/installation-data",
      method: "get",
      data: {},
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(installationOverviewReceived(res.data));
        } else {
          dispatch(installationOverviewReceived());
        }
        if (callback) {
          callback(res);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
        if (callback) {
          callback({
            hasErr: true,
            error: err,
          });
        }
      });
  };
};
