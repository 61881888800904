import CreateRequest from "core/xhr";
import {
  projectDetailReceived,
  projectsReceived,
} from "store/settings/project/actions";
import { showToast } from "store/toast-alerts/actions";
import { ApiResponse } from "views/modules/settings-v2/entity";

export const requestProjectList = (callback?: (res?: any) => void) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/settings/projects",
      method: "get",
      data: {},
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(projectsReceived(res.data));
        } else {
          dispatch(projectsReceived());
        }
        if (callback) {
          callback(res);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};
export const requestCreateProject = (
  data: {
    name: string;
    description?: string;
    alias: string;
  },
  callback?: (res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/settings/project",
      method: "post",
      data,
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(requestProjectList());
        }
        if (callback) {
          callback(res);
        }
      })
      .catch((err) => {
        console.log("save: error", err);
      });
  };
};

export const requestProjectDetail = (
  data: { id: number },
  callback?: (res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/settings/project/" + data.id,
      method: "get",
      data: {},
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(projectDetailReceived(res.data));
        } else {
          dispatch(projectDetailReceived());
        }

        if (callback) {
          callback(res);
        }
      })
      .catch((err) => {
        console.log("save: error", err);
      });
  };
};
export const requestProjectDelete = (
  data: { id: number },
  callback?: (res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/settings/project/" + data.id,
      method: "delete",
      data: {},
    })
      .then((res) => {
        if (callback) {
          callback(res);
        }
        if (res.status === 200) {
          dispatch(requestProjectList());
        }
      })
      .catch((err) => {
        console.log("delete: error", err);
      });
  };
};

export const requestProjectUpdate = (
  data: {
    id: number;
    name: string;
    description: string;
    alias: string;
  },
  callback?: (res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/settings/project/" + data.id,
      method: "put",
      data,
    })
      .then((res) => {
        if (callback) {
          callback(res);
        }
        if (res.status === 200) {
          dispatch(requestProjectList());
        }
      })
      .catch((err) => {
        console.log("update: error", err);
      });
  };
};

export const requestSwitchProject = (
  data: any,
  callback?: (body: any, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/auth/project-switch/${data.project}`,
      method: "get",
      data,
    })
      .then((res) => {
        if (res.status === 200 && res?.data?.status) {
          (window as any).open(res?.data?.redirect, "_blank").focus();
        } else {
          dispatch(showToast("error", "Error while switching project"));
        }
      })
      .catch((err) => {
        console.log("save: error", err);
      });
  };
};

export const requestUpdateProjectDbDeleted = (
  params: {
    project_id: number;
    db_deleted: boolean;
    account_id: number;
  },
  callback?: (res?: ApiResponse) => void
) => {
  return () => {
    return CreateRequest({
      url: "/settings/project/db-delete",
      method: "PUT",
      data: params,
    })
      .then((res) => {
        const apiResponse = res as unknown as ApiResponse;

        if (callback) {
          callback(apiResponse);
        }
      })
      .catch((err) => {
        console.log("update: error", err);
      });
  };
};
