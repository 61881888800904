import Dropdown from 'core/components/dropdown';
import React, { RefObject, useEffect, useState } from 'react';
import {
    FaExpand,
    FaPause,
    FaPlay,
    FaRedo,
    FaUndo
} from 'react-icons/fa';

import {
    BsPipFill
} from 'react-icons/bs';

interface PlayerControlsProps {
    videoRef: RefObject<HTMLVideoElement>;
    gotoCallback: any;
}

const PlayerControls: React.FC<PlayerControlsProps> = ({
    videoRef,
    gotoCallback,
}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [playbackSpeed, setPlaybackSpeed] = useState(1);
    const [jumpInterval, setJumpInterval] = useState(5);
    useEffect(() => {
        const video = videoRef.current;

        const handleTimeUpdate = () => {
            setCurrentTime(video?.currentTime || 0);
        };

        const handleDurationChange = () => {
            setDuration(video?.duration || 0);
        };

        video?.addEventListener('timeupdate', handleTimeUpdate);
        video?.addEventListener('durationchange', handleDurationChange);
        gotoCallback({ goto: handleSeek })
        return () => {
            video?.removeEventListener('timeupdate', handleTimeUpdate);
            video?.removeEventListener('durationchange', handleDurationChange);
        };
    }, []);

    useEffect(() => {
        const video = videoRef?.current
        if (currentTime === duration) {
            if (isPlaying) {
                setIsPlaying(false)
                setCurrentTime(0)
            }
        }
    }, [currentTime])

    const togglePlay = () => {
        const video = videoRef.current;

        if (video) {
            if (isPlaying) {
                video.pause();
            } else {
                video.play();
            }

            setIsPlaying(!isPlaying);
        }
    };

    const handlePlaybackSpeedChange = (e: any) => {
        const video = videoRef.current;

        if (video) {
            video.playbackRate = parseInt(e.value);
            setPlaybackSpeed(parseInt(e.value));
        }
    };

    const handleFullScreen = () => {
        const video = videoRef.current;

        if (video) {
            if (video.requestFullscreen) {
                video.requestFullscreen();
            }
        }
    };

    const handlePictureInPicture = async () => {
        const video = videoRef.current;

        if (video && document.pictureInPictureEnabled) {
            try {
                if (!document.pictureInPictureElement) {
                    await video.requestPictureInPicture();
                } else {
                    await document.exitPictureInPicture();
                }
            } catch (error) {
                console.error('Picture-in-Picture failed:', error);
            }
        }
    };

    const handleSeekBackward = () => {
        if (videoRef.current) {
            videoRef.current.currentTime -= jumpInterval;
        }
    };

    const handleSeekForward = () => {
        if (videoRef.current) {
            videoRef.current.currentTime += jumpInterval;
        }
    };

    const handleSeek = (seconds: number, args: boolean) => {
        const video = videoRef.current;
        if (video) {
            video.currentTime = seconds / 1000;
        }
    };

    const handleJumpIntervalChange = (e: any) => {
        const selectedInterval = parseInt(e?.value, 10);
        console.log(selectedInterval, typeof selectedInterval)
        setJumpInterval(selectedInterval);
    };

    return (
        <div className="custom-controls">
            <div className="playback-controls seekbar">
                <span>{formatTime(videoRef.current?.currentTime || 0)}</span>
                <input
                    type="range"
                    min={0}
                    max={videoRef.current?.duration || 0}
                    value={videoRef.current?.currentTime || 0}
                    onChange={(e) => {
                        if (videoRef.current) {
                            videoRef.current.currentTime = parseFloat(e.target.value);
                        }
                    }}
                    className={"video-seekbar"}
                />
                <span>{formatTime(videoRef.current?.duration || 0)}</span>
            </div>
            <div className="playback-controls">
                <button onClick={togglePlay}>{isPlaying ? <FaPause title='Pause' /> : <FaPlay title='Play'/>}</button>
                <button onClick={handleSeekBackward}><FaUndo title={`Rewind ${jumpInterval}s`} /></button>

                <Dropdown
                    hideInputValidation
                    onChange={(e: any) => handleJumpIntervalChange(e)}
                    options={[
                        {
                            value: 2,
                            label: "2s"
                        },
                        {
                            value: 5,
                            label: "5s"
                        },
                        {
                            value: 10,
                            label: "10s"
                        },
                        {
                            value: 15,
                            label: "15s"
                        },
                        {
                            value: 20,
                            label: "20s"
                        }, {
                            value: 30,
                            label: "30s"
                        }]
                    }
                    value={jumpInterval.toString()}
                />

                <button onClick={handleSeekForward}><FaRedo title={`Forward ${jumpInterval}s`} /></button>

                <div className="playback-speed">
                    <Dropdown
                        hideInputValidation
                        onChange={(e: any) => handlePlaybackSpeedChange(e)}
                        options={[
                            {
                                value: 1,
                                label: "1x"
                            },
                            {
                                value: 2,
                                label: "2x"
                            },
                            {
                                value: 4,
                                label: "4x"
                            },
                            {
                                value: 8,
                                label: "8x"
                            },
                            {
                                value: 16,
                                label: "16x"
                            }]
                        }
                        value={playbackSpeed.toString()}
                    />
                </div>
                <button  onClick={handlePictureInPicture}><BsPipFill title='Picture-in-picture'/></button>
                <button onClick={handleFullScreen}><FaExpand title='Fullscreen' /></button>
            </div>

        </div >
    );
};

const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export default PlayerControls;