import * as React from "react";
import NavTab from "core/components/nav-tab";
import TabItems from "core/components/nav-tab/items";
import {
    InstallationDocsIcon, OpenTelemetryV2
} from "core/components/svg/icons";

import {Link} from "react-router-dom";
import CustomAPIs from "views/modules/installation/components/custom-apis";

export default function OpenTelemetryMetricInstrument() {

    return <>
        <div className={'cloudflare-apm-new dialog-table-componants'}>
            <div className={' setting_side-panel'}>
                <div className={'integration-popup'}>
                    <div className={'header-section'}>
                        <div className={'d-flex'}>
                            <OpenTelemetryV2/>
                            <div className={'header-content'}>
                                <h3>OpenTelemetry Metrics</h3>
                                <p>OpenTelemetry Metrics allow you to send and monitor custom Metric data in
                                    Middleware.</p>
                            </div>
                            <button className={"docs-link"}><Link
                                to={'https://docs.middleware.io/data-ingestion-apis/opentelemetry-metrics#metrics-endpoint'}
                                target={'_blank'}><span> <InstallationDocsIcon/>Documentation</span></Link></button>

                        </div>
                        <NavTab targetIndex={1} defaultActiveTabIndex={1}>
                            <TabItems title={'Overview'}>
                                <div className={'d-flex in-content'}>
                                    <div className={'overview-heading'}>
                                        <div className={'config-overview'}>
                                            <h4>Category</h4>
                                            <span>Data Ingestion APIs</span>

                                            <h4 className={'tp-mr'}>Supported Version</h4>
                                            <img alt="Android RUM Supported"
                                                 src="https://img.shields.io/maven-central/v/io.github.middleware-labs/android-sdk?include_prereleases&style=flat"/>

                                        </div>
                                    </div>
                                    <div className={'overview-content'}>
                                        <p>More advanced users have the option to use OpenTelemetry Protocol Exporters instead of Middleware’s Agent and associated SDKs to ingest data. Each language will have a different approach which can be an adaptation from our relevant Host Agent.</p>
                                    </div>
                                </div>
                            </TabItems>
                            <TabItems title={'Installation'}>
                                <div className={'panel-body'}>
                                    <CustomAPIs/>
                                </div>

                            </TabItems>

                        </NavTab>
                    </div>
                </div>

            </div>
        </div>
    </>
}