import * as React from "react";
import { RoutesPatterns } from "core/utils/route_schema";

const InstallationRoutes: RoutesPatterns = {
  moduleName: "installation",
  slug: "/installation-v1",
  items: [
    {
      pattern: ["/"],
      component: React.lazy(() => import("views/modules/installation/index")),
      breadcrumbTitle: "Agent",
      sidebar: React.lazy(() => import("views/modules/installation/sidebar")),
      breadcrumbs: React.lazy(
        () => import(`views/modules/installation/breadcrumbs`)
      ),
    },
    {
      pattern: ["/simulate-selection"],
      component: React.lazy(
        () => import("views/modules/installation/simulate-selection")
      ),
      breadcrumbTitle: "Agent",
      helloBar: React.lazy(
        () => import("views/modules/installation/hello-bar")
      ),
    },
  ],
};
export default InstallationRoutes;
