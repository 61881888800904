import React from "react";
import { SubInfoRange } from "views/modules/builder/entities/builder.entities";

export enum ValueType {
  String = "string",
  Badge = "badge",
}

export enum CardType {
  warning = "is-warning",
  error = "is-error",
  success = "is-success",
  default = "is-default",
}

export interface CardTypeRange {
  min: number;
  max: number;
  cardType: CardType;
}

export interface MwCardProps {
  title?: string;
  info?: string;
  secondaryValue?: string;
  subInfo?: string | React.ReactNode;
  hideValue?: boolean;
  unit?: string;
  value?: string;
  warning?: boolean;
  error?: boolean;
  success?: boolean;
  valueType?: ValueType;
  prefixicon?: JSX.Element;
  default?: boolean;
  placeInfoIconOnLeft?: boolean;
  children?: JSX.Element;
  subInfoBadgeRange?: SubInfoRange[];
  cardTypeRange?: CardTypeRange[];
  isError?: boolean;
  refreshData?: () => void;
}
