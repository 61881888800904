import classNames from "classnames";
import { TabItemProps } from "core/components/v2/nav-tab/entity";
import * as React from "react";

const TabItems = React.memo(function (props: TabItemProps) {
  const { tabIndex, isDisabled, isActive, prefixIcon, title, suffixNode } =
    props;
  const index = tabIndex ?? 0;
  const handleTabClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    if (!isDisabled && typeof props.onClick === "function") {
      props.onClick(index);
    }
  };
  return (
    <li
      className={classNames("tab", {
        "active-tab": isActive,
        "tab__is-disabled": isDisabled,
      })}
    >
      <a
        className={`tab-link  ${isActive ? "active" : ""}`}
        onClick={handleTabClick}
      >
        {prefixIcon && <span className={"tab-icon"}>{prefixIcon}</span>}
        <span className="tab-title">{title}</span>
        {suffixNode}
      </a>
    </li>
  );
});
TabItems.displayName = "TabItems";
export default TabItems;
