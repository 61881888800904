import * as React from "react";
import { RoutesPatterns } from "core/utils/route_schema";
import "./_style.scss";

export const SyntheticsRoutes: RoutesPatterns = {
  moduleName: "synthetics",
  slug: "/synthetics",

  items: [
    {
      pattern: ["/", "/*"],
      component: React.lazy(() => import("views/modules/synthetics/index")),
      breadcrumbs: React.lazy(
        () => import("views/modules/synthetics/breadcrumbs")
      ),
      sidebar: React.lazy(() => import("views/modules/synthetics/sidebar-v2")),
    },
    {
      pattern: ["/create", "/edit/:editId", "/duplicate/:cloneId"],
      breadcrumbTitle: "New Monitor",
      breadcrumbs: React.lazy(
        () => import("views/modules/synthetics/breadcrumbs")
      ),
      component: React.lazy(() => import("views/modules/synthetics/form")),
      sidebar: React.lazy(
        () => import("views/modules/synthetics/form-sidebar")
      ),
    },
    {
      pattern: ["/status-page"],
      component: React.lazy(
        () => import("views/modules/status-page/page_list")
      ),
      breadcrumbTitle: "Status Page",
      breadcrumbs: React.lazy(
        () => import("views/modules/synthetics/breadcrumbs")
      ),
      sidebar: React.lazy(
        () => import("views/modules/status-page/page_list/sidebar")
      ),
    },
    {
      pattern: ["/status-page/create"],
      component: React.lazy(
        () => import("views/modules/status-page/page_list/create_page")
      ),
      breadcrumbTitle: "Create a New Status Page",
    },
    {
      pattern: ["/status-page/view"],
      component: React.lazy(
        () => import("views/modules/status-page/page_list/edit_page")
      ),
      breadcrumbTitle: "View Status Page",
    },
    {
      pattern: ["/incident"],
      component: React.lazy(() => import("views/modules/status-page/incident")),
      breadcrumbTitle: "Incident",
      breadcrumbs: React.lazy(
        () => import("views/modules/synthetics/breadcrumbs")
      ),
      sidebar: React.lazy(
        () => import("views/modules/status-page/incident/sidebar")
      ),
    },
    {
      pattern: ["/status-page"],
      component: React.lazy(
        () => import("views/modules/status-page/page_list")
      ),
      breadcrumbTitle: "Status Page",
      breadcrumbs: React.lazy(
        () => import("views/modules/synthetics/breadcrumbs")
      ),
      sidebar: React.lazy(
        () => import("views/modules/status-page/page_list/sidebar")
      ),
    },
    {
      pattern: ["/status-page/create"],
      component: React.lazy(
        () => import("views/modules/status-page/page_list/create_page")
      ),
      breadcrumbTitle: "Create a New Status Page",
    },
    {
      pattern: ["/status-page/view"],
      component: React.lazy(
        () => import("views/modules/status-page/page_list/edit_page")
      ),
      breadcrumbTitle: "View Status Page",
    },
    {
      pattern: ["/incident"],
      component: React.lazy(() => import("views/modules/status-page/incident")),
      breadcrumbTitle: "Incident",
      breadcrumbs: React.lazy(
        () => import("views/modules/synthetics/breadcrumbs")
      ),
      sidebar: React.lazy(
        () => import("views/modules/status-page/incident/sidebar")
      ),
    },
    {
      pattern: ["/newincident"],
      component: React.lazy(
        () => import("views/modules/status-page/incident/create-Incident")
      ),
      breadcrumbTitle: "Add New Incident",
    },
    {
      pattern: ["/incident/create"],
      component: React.lazy(
        () => import("views/modules/status-page/incident/create-Incident")
      ),
      breadcrumbTitle: "Create a New Incident",
    },
    {
      pattern: ["/incident/view"],
      component: React.lazy(
        () => import("views/modules/status-page/page_list/edit_page")
      ),
      breadcrumbTitle: "View Incident Page",
    },
  ],
};

export default SyntheticsRoutes;
