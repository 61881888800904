import React from "react";

import classNames from "classnames";
import { Size } from "core/components/v2/enums";

export enum StatusBadgeType {
  Success = "success",
  Error = "error",
  Warning = "warning",
  Other = "other",
  Info = "info",
  Get = "get",
  Post = "post",
  Delete = "delete",
  Trace = "trace",
  Options = "options",
  Patch = "patch",
  Head = "head",
  Put = "put",
  Connect = "connect",
  Secondary = "secondary",
  Default = "default",
  Primary = "primary",
}

interface StatusBadgeProps {
  labelText: string | React.ReactNode;
  size?: Size.Small | Size.Medium;
  type?: StatusBadgeType;
  suffixicon?: React.ReactNode;
  prefixicon?: React.ReactNode;
  onClick?: () => void;
}

const StatusBadge = (props: StatusBadgeProps) => {
  const classname = classNames("status-badge-v2", props.size, {
    [`is-${props.type}`]: props.type,
  });
  return (
    <div className={classname} onClick={props.onClick}>
      <>
        {props.prefixicon}
        {props.labelText}
        {props.suffixicon}
      </>
    </div>
  );
};

export default StatusBadge;
