import React, {useContext, useState} from "react";
import {showToast} from "store/toast-alerts/actions";
import {useDispatch} from "react-redux";
import {
    AlertsIntegrationIcon, AmazonLinux, ContOS, CopyIconNew,
    Debian,
    MiddlewareOnlyLogo,
    RedHet, UbuntuDebIcon, UbuntuLinux, UbuntuRpmIcon, WindowsImageAgent,
} from "core/components/svg/icons";
import AppContext, {ContextProps} from "core/components/wrapper/context";
import CodeViewer from "core/components/code-viewer";
import {getTenantUrl} from "core/application/utils";

export default function CommonAgentInstallation(props: any) {
    const context = useContext<ContextProps>(AppContext);
    const dispatch = useDispatch();

    function copyToClipboard(text: string) {

        navigator.clipboard.writeText(text)
            .then(() => {
                dispatch(showToast("success", "Copied to Clipboard !"))
            });
    }

    const activeDeployment: any = props.activeDeployment;
    const activeAppType: any = props.activeAppType;
    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const [activeLinuxTab, setActiveLinuxTab] = useState('deb');

    const url = getTenantUrl(context.user);
    const capturePath = `https://${url}`;

    const target = `${capturePath}`;
    const apiKey = `${context.user?.account?.agent_config?.token}`;
    const envVariables = `MW_API_KEY=${apiKey} MW_TARGET=${target}:443`
    const kubernetesCommand = `${envVariables} bash -c "$(curl -L https://install.middleware.io/scripts/mw-kube-agent-install.sh)"`
    const ubuntuDebCommand = `${envVariables} bash -c "$(curl -L https://install.middleware.io/scripts/deb-install.sh)"`;
    const ubuntuRpmCommand = `${envVariables} bash -c "$(curl -L https://install.middleware.io/scripts/rpm-install.sh)"`;
    const dockerCommand = `${envVariables} bash -c "$(curl -L https://install.middleware.io/scripts/docker-install.sh)"`
    const ubuntuStatus = `sudo systemctl status mwservice`
    const windowsStatus = `Get-Service -Name mw-windows-agent`

    return (
        <div className={"workspace"}>
            <div className={"blocks"}>
                <div className={"block"}>
                    <div className={"heading"}>
                        <span>{activeDeployment === "windows" ? "4. " && (activeAppType === "natively" ? "3. " : "4. ") : "" || activeAppType === "natively" ? "3. " : "4. "} Install Middleware Agent</span>

                    </div>
                    <div className={"desc"}>
                        <span> {activeDeployment === "windows" ? "Download" : "Install"} our Middleware agent {activeDeployment !== "windows" && "by running below scripts"} to observe your {capitalizeFirstLetter(activeDeployment)} system.</span>
                    </div>
                    <div className={"content"}>
                        {activeDeployment === "kubernetes" && <>
                            <div className={'docs-script'}>
                                <CodeViewer showLineNumbers={false}
                                            language={'javascript'}>{kubernetesCommand}</CodeViewer>
                                <span className={"doc-copy-btn"}
                                      onClick={() => copyToClipboard(kubernetesCommand)}>
                                    <CopyIconNew size={8}/>
                                </span>
                            </div>
                            <span className={"sentence mini"}>To learn more about Kubernetes, read our <a
                                href={"https://docs.middleware.io/docs/agent-installation/kubernetes"} target={"_blank"}
                                rel={'noreferrer'}>Infrastructure Monitoring</a> documentation.</span>
                        </>}
                        {activeDeployment === "linux" && <>
                            <div className={'button-flex'}>
                                <button className={activeLinuxTab === 'deb' ? 'active' : ''}
                                        onClick={() => setActiveLinuxTab('deb')}>
                                    <UbuntuDebIcon/> DEB
                                </button>
                                <button className={activeLinuxTab === 'rpm' ? 'active' : ''}
                                        onClick={() => setActiveLinuxTab('rpm')}>
                                    <UbuntuRpmIcon/> RPM
                                </button>
                            </div>
                            <div className={'docs-script'}>
                                <CodeViewer showLineNumbers={false}
                                            language={'javascript'}>{activeLinuxTab === 'deb' ? ubuntuDebCommand : ubuntuRpmCommand}</CodeViewer>
                                <span className={"doc-copy-btn"}
                                      onClick={() => copyToClipboard(activeLinuxTab === 'deb' ? ubuntuDebCommand : ubuntuRpmCommand)}>
                                    <CopyIconNew size={8}/>
                                </span>
                            </div>
                            <span
                                className={"sentence mini"}>This script can be used for {activeLinuxTab === 'deb' ? "Debian" : "RPM"} based Linux distributions. Popular distributions are given below as examples:</span>
                            <div className={'button-flex icon-only'}>
                                {activeLinuxTab === 'deb' && <>
                                    <button><Debian/></button>
                                    <button><UbuntuLinux/></button>
                                </>}
                                {activeLinuxTab === 'rpm' && <>
                                    <button><RedHet/></button>
                                    <button><ContOS/></button>
                                    <button><AmazonLinux/></button>
                                </>}
                            </div>
                            <span className={"sentence mini"}>If you want to know the status of this Agent, just run the below command:</span>
                            <div className={'docs-script'}>
                                <CodeViewer showLineNumbers={false}
                                            language={'javascript'}>{ubuntuStatus}</CodeViewer>
                                <span className={"doc-copy-btn"}
                                      onClick={() => copyToClipboard(ubuntuStatus)}>
                                    <CopyIconNew size={8}/>
                                </span>
                            </div>
                            <span className={"sentence mini"}>Please refer to&nbsp;<a
                                href="https://docs.middleware.io/docs/agent-installation/linux" target={"_blank"}>these instructions</a>&nbsp;for more information about the installation process.</span>
                        </>}
                        {activeDeployment === "docker" && <>
                            <div className={'docs-script'}>
                                <CodeViewer showLineNumbers={false}
                                            language={'javascript'}>{dockerCommand}</CodeViewer>
                                <span className={"doc-copy-btn"}
                                      onClick={() => copyToClipboard(dockerCommand)}>
                                    <CopyIconNew size={8}/>
                                </span>
                            </div>
                            <span className={"sentence mini"}>Please refer to&nbsp;<a
                                href="https://docs.middleware.io/docs/agent-installation/docker" target={"_blank"}>these instructions</a>&nbsp;for more information about the installation process.</span>
                        </>}
                        {activeDeployment === "windows" && <>
                            <div className={"app-box"} onClick={() => {
                                window.open('https://github.com/middleware-labs/mw-agent/releases/latest/download/mw-windows-agent-setup.exe');
                            }}>
                                <div className={"top"}>
                                    <div className={"icon"}>
                                        <MiddlewareOnlyLogo/>
                                    </div>
                                    <div className={"details"}>
                                        <div className={"app-name"}>Middleware.exe</div>
                                        <div className={"app-tag"}>Observability</div>
                                    </div>
                                </div>
                                <div className={"bottom"}>
                                    <span>AI-powered cloud observability platform.</span>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
                {activeDeployment === "windows" && <div className={"block"}>
                    <div className={"heading"}>
                        <span>5. Complete Installation Prompts</span>
                    </div>
                    <div className={"desc"}>
                        <span>Please follow the installation prompts with MW_API_KEY and MW_TARGET details.</span>
                    </div>
                    <div className={"content"}>
                        <div className={"script-with-image"}>
                            <div className={"side-image"}>
                                <WindowsImageAgent/>
                            </div>
                            <div className={"scripts"}>
                                <div className={"script"}>
                                    <div className={"script-head"}>
                                        <span>API Key (MW_API_KEY):</span>
                                    </div>
                                    <pre onClick={() => {
                                        copyToClipboard(apiKey)
                                    }} className={'command'}>
                                        <code>{apiKey}</code>
                                    </pre>
                                </div>
                                <div className={"script"}>
                                    <div className={"script-head"}>
                                        <span>Target URL (MW_TARGET):</span>
                                    </div>
                                    <pre onClick={() => {
                                        copyToClipboard(target)
                                    }} className={'command'}>
                                        <code>{target}</code>
                                    </pre>
                                </div>
                                <div className={"script"}>
                                    <span className={"sentence mini"}>To verify the Agent's status, just run the below command:</span>
                                    <pre onClick={() => {
                                        copyToClipboard(windowsStatus)
                                    }} className={'command'}>
                                        <code>{windowsStatus}</code>
                                    </pre>
                                </div>
                            </div>
                        </div>
                        <span className={"sentence mini"}>Please refer to&nbsp;<a
                            href="https://docs.middleware.io/docs/agent-installation/windows" target={"_blank"}>these instructions</a>&nbsp;for more information about the installation process.</span>
                    </div>
                </div>}
            </div>

        </div>
    )
}