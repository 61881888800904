import {combineReducers} from "redux";
import {createReducer} from "core/utils/create_reducer";
import {
    DASH_DESIGN,
    DASHBOARD_PORTION_RECEIVED,
    FILTER_ITEMS,
    FIXED_INFO_VAL,
    RESET_DASHBOARD_INFO,
    TIMELINE_RECEIVED,
    SET_FILTERS_STATE_REQUEST,
    SET_INDICATOR_REQUEST,
    SET_INDICATOR_HITS_REQUEST,
    UNIFIED_DASHBOARD_RECEIVED,
    WS_UNIFIED_DASHBOARD_RECEIVED
} from "store/dashboard/constant";
import moment from "moment/moment";

const memoryKeys = [
    "system_memory_utilization__used",
    "system_memory_utilization__buffered",
    "system_memory_utilization__free",
    "system_cpu_load_average_15m"
];

const cpuKeys = [
    "system_cpu_utilization__nice",
    "system_cpu_utilization__softirq",
    "system_cpu_utilization__steal",
    "system_cpu_utilization__system",
    "system_cpu_utilization__user",
    "system_cpu_utilization__wait",
    "system_cpu_utilization__interrupt",
];

const networkDiskKeys = [
    "system_network_io_bandwidth__transmit",
    "system_network_io_bandwidth__receive",
    "system_disk_io_speed__read",
    "system_disk_io_speed__write",
    "system_filesystem_usage__free",
    "system_filesystem_usage__reserved",
    "system_filesystem_usage__used"
];

function pushMetric(sourceArray: object[], newObject: object, maxCount: number) {
    const sourceLength = sourceArray.length;
    if (sourceLength >= maxCount) {
        sourceArray = sourceArray.slice(sourceLength - maxCount, sourceLength)
        sourceArray.shift();
    }
    sourceArray.push(newObject);
    return sourceArray;
}

function unshiftRecords(type: string, sourceArray: any, newObject: any, maxCount: number) {

    const objIndex = type == "logs"
        ? sourceArray.findIndex((e: any) => e.body_fingerprint === newObject.body_fingerprint)
        : (type == "traces"
            ? sourceArray.findIndex((e: any) => e.route === newObject.route)
            : -1);

    if (objIndex > -1) {
        if (type == "logs") {
            sourceArray[objIndex]["count"] += newObject["count"];
            sourceArray[objIndex]["started"] = newObject["started"];
            sourceArray.sort((a: { started: string; }, b: { started: string; }): any => {
                return  new Date(b.started).valueOf() - new Date(a.started).valueOf();
            });
        } else if (type == "traces") {
            sourceArray[objIndex]["success_count"] += newObject["success_count"];
            sourceArray[objIndex]["error_count"] += newObject["error_count"];
            sourceArray[objIndex]["total_hits"] += newObject["total_hits"];
            sourceArray[objIndex]["last_hit_at"] = newObject["last_hit_at"];
            sourceArray.sort((a: { last_hit_at: string; }, b: { last_hit_at: string; }) => {
                return  new Date(b.last_hit_at).valueOf() - new Date(a.last_hit_at).valueOf();
            });
        }
    } else {
        const sourceLength = sourceArray.length;
        if (sourceLength >= maxCount) {
            sourceArray.splice(sourceLength - 1, 1)
        }
        sourceArray.unshift(newObject);
    }
    return sourceArray;
}

function MetricKeyAggregation(str: string, keyName: string, attrKey: string) :string {
    const res1 = str.split(keyName + '.')
    let _key = ""
    if (res1.length > 1) {

        const attrs: any = res1[1].split('.'), kI:any = [], vI:any = []
        attrs.map((v: any, i: number) => ((i + 1) % 2 == 0) ? vI.push(v) : kI.push(v))
        const attr: any = {
            key: keyName
        }
        kI.map((k: any, i:number) => attr[k] = vI[i])

        if (attr?.key && typeof attr[attrKey] !== "undefined")
            _key = (attr.key + "__" + attr[attrKey]).replaceAll(".", "_")
    }
    return _key
}

function calculateAvg(arr: any) {
    let sum = 0;
    arr.forEach((item: any) => {
        sum += item;
    });
    return sum / arr.length;
}

function sanitizeMetrics(metrics: any) {
    const sanitizedMetrics = {} as any;
    for (const _fig in metrics) {
        const _opts = metrics[_fig]
        if (_opts?.metrics && Array.isArray(_opts.metrics)) {
            sanitizedMetrics[_fig] = {}
            for (const _item of _opts.metrics) {
                if (_item?.name && _item?.metric_key) {
                    // "system.cpu.load_average.15m"
                    let attrKey = "state";
                    if (_item?.metric_key && ["system.network.io.bandwidth", "system.disk.io.speed"].includes(_item.metric_key)) {
                        attrKey = "direction";
                    }
                    const _key = MetricKeyAggregation(_item?.name, _item?.metric_key, attrKey)
                    if ([...cpuKeys, ...memoryKeys, ...networkDiskKeys].indexOf(_key) !== -1) {
                        if (typeof sanitizedMetrics[_fig][_key] == "undefined")
                            sanitizedMetrics[_fig][_key] = []
                        sanitizedMetrics[_fig][_key].push(_item.value)
                    } else {
                        const n_key = (_item.metric_key || "").replaceAll(".", "_")

                        if (typeof sanitizedMetrics[_fig][n_key] == "undefined")
                            sanitizedMetrics[_fig][n_key] = []

                        sanitizedMetrics[_fig][n_key].push(_item.value)
                    }
                }
            }
        }
    }
    return sanitizedMetrics;
}

function getLastCheckpoint(groupData: any) {
    const tsValues = Object.values(groupData.values);
    return tsValues[tsValues.length - 1];
}

function simplifyLineData(lineData: object | any) {
    if (typeof lineData === 'object' && Object.keys(lineData).length > 0) {
        for (const ts in lineData) {
            if (lineData[ts].hasOwnProperty('ts')) {
                lineData[ts]['ts'] = new Date(lineData[ts]['ts']).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
            }
        }
        return Object.values(lineData);
    }
    return [];
}

function formatTwoDigits(num: number) {
    return num.toString().padStart(2, '0');
}

function getHostNames(obj: any) {
    const hostNames: string[] = [];
    if (obj["host-names"]["chartGroups"] === undefined) return hostNames;

    for (const hostName in obj["host-names"]["chartGroups"]) {
        if (hostNames.indexOf(hostName) === -1) {
            hostNames.push(hostName);
        }
    }
    return hostNames;
}

const DashboardReducers = combineReducers({
    filterItems: createReducer(FILTER_ITEMS, {}),

    liveIndicator: createReducer(true, {
        [SET_INDICATOR_REQUEST]: (state, opts) => {
            return opts.value;
        },
        [RESET_DASHBOARD_INFO]: (state) => {
            return true;
        },
    }),

    liveIndicatorHits: createReducer(false, {
        [SET_INDICATOR_HITS_REQUEST]: (state, opts) => {
            return opts.value;
        },
        [RESET_DASHBOARD_INFO]: (state) => {
            return false;
        },
    }),

    filters: createReducer({
        live_badge: true,
        timeline_start_ts: 0,
        start_ts: 0,
        end_ts: 0,
        host: undefined,
        overview: undefined,
        cpu_usage: undefined,
        ram_usage: undefined,
        network_usage: undefined,
        disk_usage: undefined,
        log_ratio: undefined,
        log_list: undefined,
        trace_ratio: undefined,
        trace_list: undefined,
        picker_date_ts: new Date().valueOf(),
        selection: [],
    }, {

        [RESET_DASHBOARD_INFO]: (state) => {
            state = {...state, ...{
                    live_badge: true,
                    timeline_start_ts: 0,
                    start_ts: 0,
                    end_ts: 0,
                    host: undefined,
                    overview: undefined,
                    cpu_usage: undefined,
                    ram_usage: undefined,
                    network_usage: undefined,
                    disk_usage: undefined,
                    log_ratio: undefined,
                    log_list: undefined,
                    trace_ratio: undefined,
                    trace_list: undefined,
                    picker_date_ts: new Date().valueOf(),
                    selection: [],
                }};
            return {...state};
        },

        [SET_FILTERS_STATE_REQUEST]: (state, opts) => {
            return opts.filters_state;
        },
    }),

    timeline: createReducer({
        chartGroup: [],
        chartGroupToggle: true,
        log5min: {
            error: 0,
            warn: 0,
            info: 0,
            debug: 0,
        },
        trace5min: {
            error: 0,
            ok: 0,
        },
        upMachines5minCollection: [],
        totalMachines: [],
        /*currentHostCount: {
            total: null,
            up: null,
            down: null,
        },*/
        update: null,
    }, {

        [RESET_DASHBOARD_INFO]: (state) => {
            state.chartGroup = [];
            state.chartGroupToggle = true;
            state.log5min = {
                error: 0,
                warn: 0,
                info: 0,
                debug: 0,
            };
            state.trace5min = {
                error: 0,
                ok: 0,
            };
            state.upMachines5minCollection = [];
            state.totalMachines = [];
            /*state.currentHostCount = {
                total: null,
                up: null,
                down: null,
            };*/
            state.update = Date.now();
            return {...state};
        },

        [TIMELINE_RECEIVED]: (state, opts) => {
            const respData = opts.data || {};
            const chartGroupData = respData?.chart_groups || [];

            for (const [index, group] of chartGroupData.entries()) {
                if (group.type == "line") {
                    chartGroupData[index]["chartData"][0]["values"] = Object.values(group["chartData"][0]["values"]);
                }/* else if (group.type == "bar") {
                    chartGroupData[index]["chartData"] = Object.values(group["chartData"][0]);
                }*/
            }
            state.chartGroup = chartGroupData;
            state.chartGroupToggle = !state.chartGroupToggle;

            const upcomingChartData = respData?.upcoming_counts || {
                log: {
                    error: 0,
                    warn: 0,
                    info: 0,
                    debug: 0,
                },
                trace: {
                    error: 0,
                    ok: 0,
                },
                up_machines: [],
            };
            state.log5min = {
                error: 0,
                warn: 0,
                info: 0,
                debug: 0,
            };
            state.trace5min = {
                error: 0,
                ok: 0,
            };
            state.log5min.error += upcomingChartData.log.error;
            state.log5min.warn += upcomingChartData.log.warn;
            state.log5min.info += upcomingChartData.log.info;
            state.log5min.debug += upcomingChartData.log.debug;
            state.trace5min.error += upcomingChartData.trace.error;
            state.trace5min.ok += upcomingChartData.trace.ok;
            state.upMachines5minCollection = [...(upcomingChartData?.up_machines || [])];
            state.totalMachines = [...(respData?.total_machines || [])];
            state.update = Date.now();
            return {...state};
        },

        /*[WS_UNIFIED_DASHBOARD_RECEIVED]: (state, opts) => {
            if (opts?.data
                && opts.data?.Section
                && ["metrics", "logs", "traces"].includes(opts.data.Section)
                && opts.data?.RespData) {

                const logCount = {
                    error: 0,
                    warn: 0,
                    info: 0,
                    debug: 0,
                };

                const traceCount = {
                    error: 0,
                    ok: 0,
                };
                let upMachines: string[] = [];

                const d = new Date();
                const FIVE_MIN = 5*60*1000;
                const respData = opts.data.RespData;

                if (opts.data.Section === "metrics") {
                    upMachines = respData?.host_names || [];
                    if (upMachines.length) {
                        state.totalMachines = upMachines.concat(state.totalMachines.filter((item: string) => !upMachines.includes(item)))
                    }
                } else if (opts.data.Section === "logs") {

                    const logBunches: any = respData?.service
                        && typeof respData.service === "object"
                        && Object.keys(respData.service).length ? Object.values(respData.service) : [];

                    let logBunch: any;
                    for (logBunch of logBunches) {
                        const logsRecords = logBunch?.log_records  || [];
                        for (const item of logsRecords) {
                            if (item.severity_number >= 17 && item.severity_number <= 20) {
                                logCount.error += item.count;
                            } else if (item.severity_number >= 13 && item.severity_number <= 16) {
                                logCount.warn += item.count;
                            } else if (item.severity_number >= 9 && item.severity_number <= 12) {
                                logCount.info += item.count;
                            } else if (item.severity_number >= 5 && item.severity_number <= 8) {
                                logCount.debug += item.count;
                            }
                        }
                    }

                } else if (opts.data.Section === "traces") {
                    const traceBunches: any = respData?.service
                    && typeof respData.service === "object"
                    && Object.keys(respData.service).length ? Object.values(respData.service) : [];

                    let traceBunch: any;
                    for (traceBunch of traceBunches) {
                        const traceRecords = traceBunch?.trace_records  || [];
                        for (const item of traceRecords) {
                            traceCount.error += item.error_count;
                            traceCount.ok += item.success_count;
                        }
                    }
                }

                if (Array.isArray(state.chartGroup) && state.chartGroup.length > 0) {
                    const chartGroup = state.chartGroup;
                    const lastUpCheckpoint: any = getLastCheckpoint(chartGroup[0]["chartData"][0]);
                    const lastTime = lastUpCheckpoint?.xAxis || "";

                    if ((Date.parse(d.toString()) - Date.parse(lastTime)) >= FIVE_MIN) {
                        const _next = new Date(Date.parse(lastTime) + FIVE_MIN);
                        const _xAxis = _next.getFullYear() + '-' + (_next.getMonth() + 1) + '-' + _next.getDate() + ' ' + _next.getHours() + ':' + _next.getMinutes() + ':00';

                        const upMachinesNames = state.upMachines5minCollection;
                        const downMachinesNames = (state.totalMachines || []).filter((item: string) => !upMachinesNames.includes(item));
                        const currentUpCount = upMachinesNames.length || 0;
                        const currentDownCount = downMachinesNames.length || 0;

                        /!*state.currentHostCount = {
                            total: (state.totalMachines || []).length || 0,
                            up: currentUpCount,
                            down: currentDownCount,
                        };*!/

                        // console.log("==>", prevUpCount, prevDownCount, prevUpCount + prevDownCount, currentUpCount, currentDownCount)
                        chartGroup[0].chartData[0].values = pushMetric(chartGroup[0].chartData[0].values, {
                            xAxis: _xAxis,
                            yAxis: currentUpCount,
                            yAxisTitle: currentUpCount,
                        }, 288);
                        chartGroup[1].chartData[0].values = pushMetric(chartGroup[1].chartData[0].values, {
                            xAxis: _xAxis,
                            yAxis: currentDownCount,
                            yAxisTitle: currentDownCount,
                        }, 288);

                        chartGroup[2].chartData[0].values = pushMetric(chartGroup[2].chartData[0].values, {
                            xAxis: _xAxis,
                            yAxis: state.log5min.error,
                            yAxisTitle: state.log5min.error
                        }, 288);
                        chartGroup[3].chartData[0].values = pushMetric(chartGroup[3].chartData[0].values, {
                            xAxis: _xAxis,
                            yAxis: state.log5min.warn,
                            yAxisTitle: state.log5min.warn
                        }, 288);
                        chartGroup[4].chartData[0].values = pushMetric(chartGroup[4].chartData[0].values, {
                            xAxis: _xAxis,
                            yAxis: state.log5min.info,
                            yAxisTitle: state.log5min.info
                        }, 288);
                        chartGroup[5].chartData[0].values = pushMetric(chartGroup[5].chartData[0].values, {
                            xAxis: _xAxis,
                            yAxis: state.log5min.debug,
                            yAxisTitle: state.log5min.debug
                        }, 288);

                        chartGroup[6].chartData[0].values = pushMetric(chartGroup[6].chartData[0].values, {
                            xAxis: _xAxis,
                            yAxis: state.trace5min.error,
                            yAxisTitle: state.trace5min.error
                        }, 288);
                        chartGroup[7].chartData[0].values = pushMetric(chartGroup[7].chartData[0].values, {
                            xAxis: _xAxis,
                            yAxis: state.trace5min.ok,
                            yAxisTitle: state.trace5min.ok
                        }, 288);
                        /!* chartGroup[6].chartData = pushMetric(chartGroup[6].chartData, {
                            barColor: "#2da44e",
                            xAxis: _xAxis,
                            yAxis: (state.upMachines5minCollection).length,
                            yAxisTitle: (state.upMachines5minCollection).length,
                        }, 288);*!/

                        state.chartGroup = chartGroup;
                        state.chartGroupToggle = !state.chartGroupToggle;
                        state.upMachines5minCollection = [...upMachines];
                        state.log5min = logCount;
                        state.trace5min = traceCount;
                    } else {
                        state.upMachines5minCollection = upMachines.concat(state.upMachines5minCollection.filter((item: string) => upMachines.indexOf(item) < 0))
                        state.log5min.error += logCount.error;
                        state.log5min.warn += logCount.warn;
                        state.log5min.info += logCount.info;
                        state.log5min.debug += logCount.debug;
                        state.trace5min.error += traceCount.error;
                        state.trace5min.ok += traceCount.ok;
                    }
                } else {
                    // later..
                }
            }

            return {...state};
        },*/

        [WS_UNIFIED_DASHBOARD_RECEIVED]: (state, opts) => {

            let upMachines: string[] = [];
            const logCount = {
                error: 0,
                warn: 0,
                info: 0,
                debug: 0,
            };

            const traceCount = {
                error: 0,
                ok: 0,
            };

            const d = new Date();
            const FIVE_MIN = 5*60*1000;
            // const respData = opts.data.RespData;

            if (opts.hasOwnProperty('metricsData') && opts.metricsData != null) {
                upMachines = getHostNames(opts.metricsData);
                if (upMachines.length) {
                    state.totalMachines = upMachines.concat(state.totalMachines.filter((item: string) => !upMachines.includes(item)))
                }
            }

            if (opts.hasOwnProperty('logsData') && opts.logsData != null) {

                const logsRecords: any = opts.logsData?.service
                && typeof opts.logsData.service === "object"
                && Object.keys(opts.logsData.service).length ? Object.values(opts.logsData.service) : [];

                for (const items of logsRecords) {
                    for (const item of items) {
                        if (item.severityNumber >= 17 && item.severityNumber <= 20) {
                            logCount.error += item.count;
                        } else if (item.severityNumber >= 13 && item.severityNumber <= 16) {
                            logCount.warn += item.count;
                        } else if (item.severityNumber >= 9 && item.severityNumber <= 12) {
                            logCount.info += item.count;
                        } else if (item.severityNumber >= 5 && item.severityNumber <= 8) {
                            logCount.debug += item.count;
                        }
                    }
                }


            }

            if (opts.hasOwnProperty('tracesData') && opts.tracesData != null) {

                const traceRecords: any = opts.tracesData?.service
                && typeof opts.tracesData.service === "object"
                && Object.keys(opts.tracesData.service).length ? Object.values(opts.tracesData.service) : [];

                let traceBunch: any;
                for (const items of traceRecords) {
                    for (const item of items) {
                        traceCount.error += item.traceErrorCount;
                        traceCount.ok += item.traceCount;
                    }
                }
            }

            if (Array.isArray(state.chartGroup) && state.chartGroup.length > 0) {
                const chartGroup = state.chartGroup;
                const lastUpCheckpoint: any = getLastCheckpoint(chartGroup[0]["chartData"][0]);
                const lastTime = lastUpCheckpoint?.xAxis || "";

                if ((Date.parse(d.toString()) - Date.parse(lastTime)) >= FIVE_MIN) {
                    const _next = new Date(Date.parse(lastTime) + FIVE_MIN);
                    const _xAxis = _next.getFullYear() + '-' + (_next.getMonth() + 1) + '-' + _next.getDate() + ' ' + _next.getHours() + ':' + _next.getMinutes() + ':00';

                    const upMachinesNames = state.upMachines5minCollection;
                    const downMachinesNames = (state.totalMachines || []).filter((item: string) => !upMachinesNames.includes(item));
                    const currentUpCount = upMachinesNames.length || 0;
                    const currentDownCount = downMachinesNames.length || 0;

                    /*state.currentHostCount = {
                        total: (state.totalMachines || []).length || 0,
                        up: currentUpCount,
                        down: currentDownCount,
                    };*/

                    // console.log("==>", prevUpCount, prevDownCount, prevUpCount + prevDownCount, currentUpCount, currentDownCount)
                    chartGroup[0].chartData[0].values = pushMetric(chartGroup[0].chartData[0].values, {
                        xAxis: _xAxis,
                        yAxis: currentUpCount,
                        yAxisTitle: currentUpCount,
                    }, 288);
                    chartGroup[1].chartData[0].values = pushMetric(chartGroup[1].chartData[0].values, {
                        xAxis: _xAxis,
                        yAxis: currentDownCount,
                        yAxisTitle: currentDownCount,
                    }, 288);

                    chartGroup[2].chartData[0].values = pushMetric(chartGroup[2].chartData[0].values, {
                        xAxis: _xAxis,
                        yAxis: state.log5min.error,
                        yAxisTitle: state.log5min.error
                    }, 288);
                    chartGroup[3].chartData[0].values = pushMetric(chartGroup[3].chartData[0].values, {
                        xAxis: _xAxis,
                        yAxis: state.log5min.warn,
                        yAxisTitle: state.log5min.warn
                    }, 288);
                    chartGroup[4].chartData[0].values = pushMetric(chartGroup[4].chartData[0].values, {
                        xAxis: _xAxis,
                        yAxis: state.log5min.info,
                        yAxisTitle: state.log5min.info
                    }, 288);
                    chartGroup[5].chartData[0].values = pushMetric(chartGroup[5].chartData[0].values, {
                        xAxis: _xAxis,
                        yAxis: state.log5min.debug,
                        yAxisTitle: state.log5min.debug
                    }, 288);

                    chartGroup[6].chartData[0].values = pushMetric(chartGroup[6].chartData[0].values, {
                        xAxis: _xAxis,
                        yAxis: state.trace5min.error,
                        yAxisTitle: state.trace5min.error
                    }, 288);
                    chartGroup[7].chartData[0].values = pushMetric(chartGroup[7].chartData[0].values, {
                        xAxis: _xAxis,
                        yAxis: state.trace5min.ok,
                        yAxisTitle: state.trace5min.ok
                    }, 288);
                    /* chartGroup[6].chartData = pushMetric(chartGroup[6].chartData, {
                        barColor: "#2da44e",
                        xAxis: _xAxis,
                        yAxis: (state.upMachines5minCollection).length,
                        yAxisTitle: (state.upMachines5minCollection).length,
                    }, 288);*/

                    state.chartGroup = chartGroup;
                    state.chartGroupToggle = !state.chartGroupToggle;
                    state.upMachines5minCollection = [...upMachines];
                    state.log5min = logCount;
                    state.trace5min = traceCount;
                } else {
                    state.upMachines5minCollection = upMachines.concat(state.upMachines5minCollection.filter((item: string) => upMachines.indexOf(item) < 0))
                    state.log5min.error += logCount.error;
                    state.log5min.warn += logCount.warn;
                    state.log5min.info += logCount.info;
                    state.log5min.debug += logCount.debug;
                    state.trace5min.error += traceCount.error;
                    state.trace5min.ok += traceCount.ok;
                }
            } else {
                // later..
            }

            return {...state};
        },
    }),

    dashboard: createReducer({
        design: JSON.parse(JSON.stringify(DASH_DESIGN)),
        info: JSON.parse(JSON.stringify(FIXED_INFO_VAL)),
        total_count: {
            logs: 0,
            traces: 0
        },
        dashboardPortionInflight: false,
        blankLegend: {},
        metric1min: {
            cpu_usage: {
                graph: {},
            },
            ram_usage: {
                graph: {},
            },
            network_usage: {
                receive: {},
                transmit: {},
            },
            disk_usage: {
                read: {},
                write: {},
            }
        },
        log1min: {
            error: 0,
            warn: 0,
            info: 0,
            debug: 0,
        },
        trace1min: {
            ok: 0,
            error: 0,
        },
        update: null,
        quickFilters: {
            host: undefined,
            log_ratio: undefined,
            log_list: undefined,
            trace_ratio: undefined,
            trace_list: undefined,
        },
    }, {

        [RESET_DASHBOARD_INFO]: (state) => {
            state.info = JSON.parse(JSON.stringify(FIXED_INFO_VAL));
            state.total_count = {
                logs: 0,
                traces: 0
            };
            state.blankLegend = {};
            state.metric1min = {
                cpu_usage: {
                    graph: {},
                },
                ram_usage: {
                    graph: {},
                },
                network_usage: {
                    receive: {},
                    transmit: {},
                },
                disk_usage: {
                    read: {},
                    write: {},
                }
            };
            state.log1min = {
                error: 0,
                warn: 0,
                info: 0,
                debug: 0,
            };
            state.trace1min = {
                ok: 0,
                error: 0,
            };
            state.quickFilters = {
                host: undefined,
                log_ratio: undefined,
                log_list: undefined,
                trace_ratio: undefined,
                trace_list: undefined,
            };
            state.update = Date.now();
            return {...state};
        },

        [SET_FILTERS_STATE_REQUEST]: (state, opts) => {
            state.quickFilters = {
                host: opts.filters_state.host,
                log_ratio: opts.filters_state.log_ratio,
                log_list: opts.filters_state.log_list,
                trace_ratio: opts.filters_state.trace_ratio,
                trace_list: opts.filters_state.trace_list,
            }
            return {...state};
        },

        [UNIFIED_DASHBOARD_RECEIVED]: (state, opts) => {
            if (opts.status && opts?.data && opts.data && Object.keys(opts.data).length > 0) {

                const section = opts.section || '';

                if (section == '' || section == 'metrics') {
                    const metricsDesign = state.design.metrics;
                    const wsMetricsStack = opts.data['metrics'];

                    if (metricsDesign?.cards && wsMetricsStack?.cards && wsMetricsStack.cards) {
                        for (const cardKey in metricsDesign.cards) {
                            if (wsMetricsStack.cards.hasOwnProperty(cardKey)) {
                                if (wsMetricsStack.cards[cardKey]) {
                                    if (metricsDesign.cards[cardKey].body.type === 'line'
                                        && Array.isArray(wsMetricsStack.cards[cardKey])
                                        && wsMetricsStack.cards[cardKey].length > 0) {
                                        for (const [lIndex] of wsMetricsStack.cards[cardKey].entries()) {
                                            wsMetricsStack.cards[cardKey][lIndex].values = Object.values(wsMetricsStack.cards[cardKey][lIndex].values)
                                        }
                                    }
                                    if (['cpu-single-graph', 'ram-single-graph', 'network-dual-graph', 'disk-dual-graph'].includes(metricsDesign.cards[cardKey].body.type)
                                        && typeof (wsMetricsStack.cards[cardKey]) == 'object') {

                                        // common for CPU & RAM
                                        if (wsMetricsStack.cards[cardKey]?.graph
                                            && typeof wsMetricsStack.cards[cardKey].graph === 'object'
                                            && Object.keys(wsMetricsStack.cards[cardKey].graph).length > 0) {
                                            wsMetricsStack.cards[cardKey].graph = simplifyLineData(wsMetricsStack.cards[cardKey].graph);
                                            if (wsMetricsStack.cards[cardKey]?.misc) {
                                                // managed upcoming dropped counts here.
                                                if (wsMetricsStack.cards[cardKey].misc?.upcoming_counts) {
                                                    const upcomingCounts = wsMetricsStack.cards[cardKey].misc.upcoming_counts;
                                                    for (const subKey in upcomingCounts) {
                                                        for (const fing in upcomingCounts[subKey]) {
                                                            state.metric1min[cardKey][subKey][fing] = [upcomingCounts[subKey][fing]];
                                                        }
                                                    }
                                                }
                                                if (state?.blankLegend
                                                    && Object.keys(state.blankLegend).length == 0
                                                    && wsMetricsStack.cards[cardKey].misc?.blank_legend
                                                    && wsMetricsStack.cards[cardKey].misc.blank_legend?.values) {
                                                    const blankLegend = wsMetricsStack.cards[cardKey].misc.blank_legend;
                                                    blankLegend.values = Object.values(blankLegend.values)
                                                    state.blankLegend = blankLegend;
                                                }
                                            }
                                        }

                                        // for Network usage
                                        if (wsMetricsStack.cards[cardKey]?.receive
                                            && wsMetricsStack.cards[cardKey]?.transmit
                                            && typeof wsMetricsStack.cards[cardKey].receive === 'object'
                                            && typeof wsMetricsStack.cards[cardKey].transmit === 'object'
                                            && Object.keys(wsMetricsStack.cards[cardKey].receive).length > 0
                                            && Object.keys(wsMetricsStack.cards[cardKey].transmit).length > 0) {
                                            wsMetricsStack.cards[cardKey].receive = simplifyLineData(wsMetricsStack.cards[cardKey].receive);
                                            wsMetricsStack.cards[cardKey].transmit = simplifyLineData(wsMetricsStack.cards[cardKey].transmit);
                                            if (wsMetricsStack.cards[cardKey]?.misc) {
                                                // managed upcoming dropped counts here.
                                                if (wsMetricsStack.cards[cardKey].misc?.upcoming_counts) {
                                                    const upcomingCounts = wsMetricsStack.cards[cardKey].misc.upcoming_counts;
                                                    for (const subKey in upcomingCounts) {
                                                        for (const fing in upcomingCounts[subKey]) {
                                                            state.metric1min[cardKey][subKey][fing] = [upcomingCounts[subKey][fing]];
                                                        }
                                                    }
                                                }
                                                if (state?.blankLegend
                                                    && Object.keys(state.blankLegend).length == 0
                                                    && wsMetricsStack.cards[cardKey].misc?.blank_legend
                                                    && wsMetricsStack.cards[cardKey].misc.blank_legend?.values) {
                                                    const blankLegend = wsMetricsStack.cards[cardKey].misc.blank_legend;
                                                    blankLegend.values = Object.values(blankLegend.values)
                                                    state.blankLegend = blankLegend;
                                                }
                                            }
                                        }

                                        // for Disk usage
                                        if (wsMetricsStack.cards[cardKey]?.read
                                            && wsMetricsStack.cards[cardKey]?.write
                                            && typeof wsMetricsStack.cards[cardKey].read === 'object'
                                            && typeof wsMetricsStack.cards[cardKey].write === 'object'
                                            && Object.keys(wsMetricsStack.cards[cardKey].read).length > 0
                                            && Object.keys(wsMetricsStack.cards[cardKey].write).length > 0) {
                                            wsMetricsStack.cards[cardKey].read = simplifyLineData(wsMetricsStack.cards[cardKey].read);
                                            wsMetricsStack.cards[cardKey].write = simplifyLineData(wsMetricsStack.cards[cardKey].write);
                                            if (wsMetricsStack.cards[cardKey]?.misc) {
                                                // managed upcoming dropped counts here.
                                                if (wsMetricsStack.cards[cardKey].misc?.upcoming_counts) {
                                                    const upcomingCounts = wsMetricsStack.cards[cardKey].misc.upcoming_counts;
                                                    for (const subKey in upcomingCounts) {
                                                        for (const fing in upcomingCounts[subKey]) {
                                                            state.metric1min[cardKey][subKey][fing] = [upcomingCounts[subKey][fing]];
                                                        }
                                                    }
                                                }
                                                if (state?.blankLegend
                                                    && Object.keys(state.blankLegend).length == 0
                                                    && wsMetricsStack.cards[cardKey].misc?.blank_legend
                                                    && wsMetricsStack.cards[cardKey].misc.blank_legend?.values) {
                                                    const blankLegend = wsMetricsStack.cards[cardKey].misc.blank_legend;
                                                    blankLegend.values = Object.values(blankLegend.values)
                                                    state.blankLegend = blankLegend;
                                                }
                                            }
                                        }


                                    }
                                }
                            }
                        }
                    }
                    state.info['metrics'] = wsMetricsStack;
                }

                if (section == '' || section == 'logs') {
                    const logsDesign = state.design.logs;
                    const wsLogsStack = opts.data['logs'];

                    if (logsDesign?.cards && wsLogsStack?.cards && wsLogsStack.cards) {
                        if (wsLogsStack.cards.hasOwnProperty('total_records')) {
                            state.total_count.logs = wsLogsStack.cards["total_records"] || 0;
                        }
                        for (const cardKey in logsDesign.cards) {
                            if (wsLogsStack.cards.hasOwnProperty(cardKey)) {
                                if (wsLogsStack.cards[cardKey]) {
                                    if (logsDesign.cards[cardKey].body.type === 'line'
                                        && typeof wsLogsStack.cards[cardKey] === 'object'
                                        && Object.keys(wsLogsStack.cards[cardKey]).length > 0) {
                                        wsLogsStack.cards[cardKey] = simplifyLineData(wsLogsStack.cards[cardKey]);
                                    }
                                }
                            }
                        }
                        if (wsLogsStack.cards.hasOwnProperty('upcoming_counts')) {
                            const upComingCounts = wsLogsStack.cards.upcoming_counts;
                            state.log1min.error += upComingCounts.error;
                            state.log1min.warn += upComingCounts.warn;
                            state.log1min.info += upComingCounts.info;
                            state.log1min.debug += upComingCounts.debug;
                        }
                    }
                    state.info['logs'] = wsLogsStack;
                }

                if (section == '' || section == 'traces') {
                    const tracesDesign = state.design.traces;
                    const wsTracesStack = opts.data['traces'];
                    if (tracesDesign?.cards && wsTracesStack?.cards && wsTracesStack.cards) {
                        if (wsTracesStack.cards.hasOwnProperty('total_records')) {
                            state.total_count.traces = wsTracesStack.cards["total_records"] || 0;
                        }
                        for (const cardKey in tracesDesign.cards) {
                            if (wsTracesStack.cards.hasOwnProperty(cardKey)) {
                                if (wsTracesStack.cards[cardKey]) {
                                    if (tracesDesign.cards[cardKey].body.type === 'line'
                                        && typeof wsTracesStack.cards[cardKey] === 'object'
                                        && Object.keys(wsTracesStack.cards[cardKey]).length > 0) {
                                        wsTracesStack.cards[cardKey] = simplifyLineData(wsTracesStack.cards[cardKey]);
                                    }
                                }
                            }
                        }
                        if (wsTracesStack.cards.hasOwnProperty('upcoming_counts')) {
                            const upComingCounts = wsTracesStack.cards.upcoming_counts;
                            state.trace1min.error += upComingCounts.error;
                            state.trace1min.ok += upComingCounts.ok;
                        }
                    }

                    state.info['traces'] = wsTracesStack
                }
            }
            state.update = Date.now();
            return {...state};
        },

        [DASHBOARD_PORTION_RECEIVED]: (state, opts) => {
            if (typeof opts.dashboardPortionInflight != "undefined")
                state.dashboardPortionInflight = opts.dashboardPortionInflight;

            if (opts.data
                && opts?.section
                && opts.section
                && ["logs", "traces"].includes(opts.section)) {

                const historyRsp = opts.data;
                const logsDesign = state.design.logs;
                const tracesDesign = state.design.traces;
                const wsStack = state.info;

                if (opts.section === "logs" && wsStack?.logs && historyRsp?.logs && historyRsp.logs) {
                    if (historyRsp.logs?.cards && wsStack.logs?.cards) {
                        if (historyRsp.logs.cards.hasOwnProperty('total_records')) {
                            state.total_count.logs = historyRsp.logs.cards["total_records"] || 0;
                        }
                        if (logsDesign.cards["ratio"].body.type === 'line'
                            && wsStack.logs.cards.hasOwnProperty("ratio")
                            && historyRsp.logs.cards.hasOwnProperty("ratio")
                            && typeof historyRsp.logs.cards.ratio === 'object'
                            && Object.keys(historyRsp.logs.cards.ratio).length > 0) {
                            historyRsp.logs.cards.ratio = simplifyLineData(historyRsp.logs.cards.ratio);
                            wsStack.logs.cards.ratio = historyRsp.logs.cards.ratio;
                        }
                        if (wsStack.logs.cards.hasOwnProperty("list")
                            && historyRsp.logs.cards.hasOwnProperty("list")
                            && logsDesign.cards["list"].body.type === 'log-list') {
                            if (opts?.request_for) {
                                if (opts.request_for === "list") {
                                    wsStack.logs.cards.list = historyRsp.logs.cards.list;
                                } else if (opts.request_for === "scroll-list") {
                                    wsStack.logs.cards.list = wsStack.logs.cards.list.concat(historyRsp.logs.cards.list);
                                }
                            }
                        }
                    }
                }
                if (opts.section === "traces" && wsStack?.traces && historyRsp?.traces && historyRsp.traces) {
                    if (historyRsp.traces?.cards && wsStack.traces?.cards) {
                        if (historyRsp.traces.cards.hasOwnProperty('total_records')) {
                            state.total_count.traces = historyRsp.traces.cards["total_records"] || 0;
                        }
                        if (tracesDesign.cards["ratio"].body.type === 'line'
                            && wsStack.traces.cards.hasOwnProperty("ratio")
                            && historyRsp.traces.cards.hasOwnProperty("ratio")
                            && typeof historyRsp.traces.cards.ratio === 'object'
                            && Object.keys(historyRsp.traces.cards.ratio).length > 0) {
                            historyRsp.traces.cards.ratio = simplifyLineData(historyRsp.traces.cards.ratio);
                            wsStack.traces.cards.ratio = historyRsp.traces.cards.ratio;
                        }
                        if (wsStack.traces.cards.hasOwnProperty("list")
                            && historyRsp.traces.cards.hasOwnProperty("list")
                            && tracesDesign.cards["list"].body.type === 'trace-list') {
                            if (opts?.request_for) {
                                if (opts.request_for === "list") {
                                    wsStack.traces.cards.list = historyRsp.traces.cards.list;
                                } else if (opts.request_for === "scroll-list") {
                                    wsStack.traces.cards.list = wsStack.traces.cards.list.concat(historyRsp.traces.cards.list);
                                }
                            }
                        }
                    }
                }

                state.info = wsStack;
                state.update = Date.now();
            }
            return {...state};
        },

        /*[WS_UNIFIED_DASHBOARD_RECEIVED]: (state, opts) => {
            if (opts?.data
                && opts.data?.Section
                && ["metrics", "logs", "traces"].includes(opts.data.Section)
                && opts.data?.RespData) {
                const metricCount: any = {
                    cpu_usage: {
                        graph: {},
                    },
                    ram_usage: {
                        graph: {},
                    },
                    network_usage: {
                        receive: {},
                        transmit: {},
                    },
                    disk_usage: {
                        read: {},
                        write: {},
                    }
                };

                const logCount = {
                    error: 0,
                    warn: 0,
                    info: 0,
                    debug: 0,
                };

                const traceCount = {
                    ok: 0,
                    error: 0
                };

                const d = new Date();
                const ONE_MIN = 60*1000;
                const respData = opts.data.RespData;
                // const appliedFilter = opts.data.Filter;
                const quickFilters = state.quickFilters;
                const isHostFilterApplied = typeof quickFilters.host == "string"; //appliedFilter.hasOwnProperty('host')
                // const logRatioFilter = quickFilters?.log_ratio || 'all'; //appliedFilter?.log_ratio
                const logListFilter = quickFilters?.log_list || 'all'; //appliedFilter?.log_list
                // const traceRatioFilter = quickFilters?.trace_ratio || 'all'; //appliedFilter?.trace_ratio
                const traceListFilter = quickFilters?.trace_list || 'all'; //appliedFilter?.trace_list

                if (opts.data.Section === "metrics") {

                    // ----------- Overview -----------------
                    // manage later.
                    /!*const metricOverview = state.info?.metrics?.overview
                    && typeof state.info.metrics.overview == 'object'
                    && Object.keys(state.info.metrics.overview).length > 0
                        ? state.info.metrics.overview : {};
                    if (Object.keys(metricOverview).length > 0) {
                        if (typeof respData.host_count != undefined) {
                            metricOverview['view0']['value'] = respData.host_count;
                        }
                        if (typeof respData.container_count != undefined) {
                            metricOverview['view1']['value'] = respData.container_count;
                        }
                        // pending for services/process...
                        // metricOverview['view2']['value'] = respData.service_count;
                        // metricOverview['view3']['value'] =respData.process_count;
                        state.info.metrics.overview = metricOverview;
                    }*!/

                    // ----------- Cards -----------------
                    const hostSanitizedRecords = sanitizeMetrics(respData.host_info);
                    for (const _fing in hostSanitizedRecords) {
                        const _metrics: any = hostSanitizedRecords[_fing];
                        if (_metrics?.system_cpu_utilization__steal
                            && _metrics?.system_cpu_utilization__system
                            && _metrics?.system_cpu_utilization__user
                            && _metrics?.system_cpu_utilization__wait) {
                            const stealVal = calculateAvg(_metrics.system_cpu_utilization__steal || []) * 100;
                            const systemVal = calculateAvg(_metrics.system_cpu_utilization__system || []) * 100;
                            const userVal = calculateAvg(_metrics.system_cpu_utilization__user || []) * 100;
                            const waitVal = calculateAvg(_metrics.system_cpu_utilization__wait || []) * 100;
                            const val = stealVal + systemVal + userVal + waitVal;

                            if (metricCount.cpu_usage.graph.hasOwnProperty(_fing)) {
                                metricCount.cpu_usage.graph[_fing].push(val);
                            } else {
                                metricCount.cpu_usage.graph[_fing] = [val];
                            }
                        }

                        if (_metrics?.system_memory_utilization__used) {
                            let val = calculateAvg(_metrics.system_memory_utilization__used);
                            val *= 100;
                            if (metricCount.ram_usage.graph.hasOwnProperty(_fing)) {
                                metricCount.ram_usage.graph[_fing].push(val);
                            } else {
                                metricCount.ram_usage.graph[_fing] = [val];
                            }
                        }

                        if (_metrics?.system_network_io_bandwidth__receive) {
                            const val = calculateAvg(_metrics.system_network_io_bandwidth__receive);
                            if (metricCount.network_usage.receive.hasOwnProperty(_fing)) {
                                metricCount.network_usage.receive[_fing].push(val);
                            } else {
                                metricCount.network_usage.receive[_fing] = [val];
                            }
                        }

                        if (_metrics?.system_network_io_bandwidth__transmit) {
                            const val = calculateAvg(_metrics.system_network_io_bandwidth__transmit);
                            if (metricCount.network_usage.transmit.hasOwnProperty(_fing)) {
                                metricCount.network_usage.transmit[_fing].push(val);
                            } else {
                                metricCount.network_usage.transmit[_fing] = [val];
                            }
                        }

                        if (_metrics?.system_disk_io_speed__read) {
                            const val = calculateAvg(_metrics.system_disk_io_speed__read);
                            if (metricCount.disk_usage.read.hasOwnProperty(_fing)) {
                                metricCount.disk_usage.read[_fing].push(val);
                            } else {
                                metricCount.disk_usage.read[_fing] = [val];
                            }
                        }

                        if (_metrics?.system_disk_io_speed__write) {
                            const val = calculateAvg(_metrics.system_disk_io_speed__write);
                            if (metricCount.disk_usage.write.hasOwnProperty(_fing)) {
                                metricCount.disk_usage.write[_fing].push(val);
                            } else {
                                metricCount.disk_usage.write[_fing] = [val];
                            }
                        }
                    }
                } else if (opts.data.Section === "logs") {
                    const logBunches: any = respData?.service
                    && typeof respData.service === "object"
                    && Object.keys(respData.service).length ? Object.values(respData.service) : [];

                    let logBunch: any;
                    for (logBunch of logBunches) {
                        const logsRecords = (logBunch?.log_records  || []).reverse();
                        for (const item of logsRecords) {

                            if (item.severity_number >= 17 && item.severity_number <= 20) {
                                logCount.error += item.count;
                            } else if (item.severity_number >= 13 && item.severity_number <= 16) {
                                logCount.warn += item.count;
                            } else if (item.severity_number >= 9 && item.severity_number <= 12) {
                                logCount.info += item.count;
                            } else if (item.severity_number >= 5 && item.severity_number <= 8) {
                                logCount.debug += item.count;
                            }

                            if (state.info.logs?.cards && state.info.logs.cards?.list) {
                                // -----------Logs-List Push-----------------
                                if (logListFilter == 'infra' && item.is_apm == 1) {
                                    continue;
                                } else if (logListFilter == 'app' && item.is_apm == 0) {
                                    continue;
                                } else if (logListFilter == 'error' && (item.severity_number < 17 || item.severity_number > 20)) {
                                    continue;
                                } else if (logListFilter == 'warn' && (item.severity_number < 13 || item.severity_number > 16)) {
                                    continue;
                                } else if (logListFilter == 'info' && (item.severity_number < 9 || item.severity_number > 12)) {
                                    continue;
                                } else if (logListFilter == 'debug' && (item.severity_number < 5 || item.severity_number > 8)) {
                                    continue;
                                }

                                let stateList = state.info.logs.cards.list;
                                stateList = unshiftRecords(
                                    "logs",
                                    stateList,
                                    {
                                        body_fingerprint: item.body_fingerprint,
                                        count: item.count,
                                        resource: item.resource,
                                        is_apm: item.is_apm,
                                        label: item.body,
                                        mini_chart: [],
                                        started: moment.unix(item.time_unix_ms/1000).format("YYYY-MM-DDTHH:mm:ssZ"),
                                        severity_text: item.severity_text,
                                        trace_id: item.trace_id,
                                        attributes: item.attributes,
                                    }, 100);

                                if (logListFilter == 'high') {
                                    stateList = stateList.sort((a: { count: number; }, b: { count: number;}) => {
                                        return b.count - a.count;
                                    });
                                } else if (logListFilter == 'low') {
                                    stateList = stateList.sort((a: { count: number; }, b: { count: number;}) => {
                                        return a.count - b.count;
                                    });
                                }
                                state.info.logs.cards.list = stateList;
                            }
                        }
                    }

                    // -----------Overview-----------------
                    const logOverview = state.info?.logs?.overview
                        && typeof state.info.logs.overview == 'object'
                        && Object.keys(state.info.logs.overview).length > 0
                        ? state.info.logs.overview : {};
                    if (Object.keys(logOverview).length > 0) {
                        logOverview['view0']['value'] += (logCount.error + logCount.warn + logCount.info + logCount.debug);
                        logOverview['view0']['sub_value'] = (state.info.logs.cards?.list || []).length || 0;
                        logOverview['view1']['value'] += logCount.error;
                        logOverview['view2']['value'] += logCount.warn;
                        logOverview['view3']['value'] += logCount.info;
                        state.info.logs.overview = logOverview;
                    }

                } else if (opts.data.Section === "traces") {
                    const traceBunches: any = respData?.service
                    && typeof respData.service === "object"
                    && Object.keys(respData.service).length ? Object.values(respData.service) : [];

                    let traceBunch: any;
                    let maxLatency = 0;
                    for (traceBunch of traceBunches) {
                        const traceRecords = traceBunch?.trace_records  || [];
                        const attributes = traceBunch?.attributes  || {};
                        const serviceName = attributes.hasOwnProperty("service.name") ? attributes["service.name"] : "";
                        for (const item of traceRecords) {

                            traceCount.ok += item.success_count;
                            traceCount.error += item.error_count;
                            const _durationNano = item.end_time_unix_nano - item.start_time_unix_nano;
                            if (_durationNano > maxLatency) {
                                maxLatency = _durationNano
                            }

                            if (state.info.traces?.cards && state.info.traces.cards?.list) {
                                // -----------Traces-List Push-----------------
                                if (traceListFilter == 'error' && item.has_error == false) {
                                    continue;
                                } else if (traceListFilter == 'ok' && item.has_error == true) {
                                    continue;
                                }
                                // console.log("item==>", item, traceListFilter)
                                let stateList = state.info.traces.cards.list;
                                stateList = unshiftRecords(
                                    "traces",
                                    stateList,
                                    {
                                        trace_id: item.trace_id,
                                        route: item.name,
                                        service_name: serviceName,
                                        p75: 0,
                                        p99: 0,
                                        last_hit_at: moment.unix(item.timestamp_ms/1000).format("YYYY-MM-DDTHH:mm:ssZ"),
                                        error_count: item.error_count,
                                        success_count: item.success_count,
                                        total_hits: (item.success_count + item.error_count),
                                        start_date: 0,
                                        end_date: 0,
                                        interval: 60
                                    }, 100);

                                state.info.traces.cards.list = stateList;
                            }

                        }
                    }

                    // -----------Overview-----------------
                    const traceOverview = state.info?.traces?.overview
                    && typeof state.info.traces.overview == 'object'
                    && Object.keys(state.info.traces.overview).length > 0
                        ? state.info.traces.overview : {};
                    if (Object.keys(traceOverview).length > 0) {
                        traceOverview['view0']['value'] += (traceCount.error + traceCount.ok);
                        traceOverview['view0']['sub_value'] = (state.info.traces.cards?.list || []).length || 0;
                        traceOverview['view1']['value'] += traceCount.error;
                        traceOverview['view2']['value'] += traceCount.ok;

                        const previousMaxLatency = traceOverview['view3']['value'];
                        if (maxLatency > previousMaxLatency) {
                            traceOverview['view3']['value'] = maxLatency;
                        }

                        state.info.traces.overview = traceOverview;
                    }

                }

                // -----------Ratio of Metrics: CPU Usage-----------------
                const metricsCpuArray = state.info?.metrics?.cards?.cpu_usage?.graph
                && Array.isArray(state.info.metrics.cards.cpu_usage.graph)
                    ? state.info.metrics.cards.cpu_usage.graph : [];

                // -----------Ratio of Metrics: RAM Usage-----------------
                const metricsRamArray = state.info?.metrics?.cards?.ram_usage?.graph
                && Array.isArray(state.info.metrics.cards.ram_usage.graph)
                    ? state.info.metrics.cards.ram_usage.graph : [];

                const availMetricsArray = metricsCpuArray.length > 0
                    ? metricsCpuArray
                    : (metricsRamArray.length > 0 ? metricsRamArray : []);

                if (availMetricsArray.length > 0) {

                    const latestCp: any = availMetricsArray[availMetricsArray.length - 1];
                    const lastTime = latestCp?.tooltip?.timestamp || 0;
                    const diffInMin = (Date.parse(d.toString()) - Date.parse(new Date(lastTime).toString()));

                    if (diffInMin >= ONE_MIN) {
                        const _next = new Date(Date.parse(new Date(lastTime).toString()) + ONE_MIN);
                        const ts = formatTwoDigits(_next.getHours()) + ':' + formatTwoDigits(_next.getMinutes());

                        // -----------Push into Metrics: CPU Usage-----------------

                        const commonFunc = (metricsArray: any[], state1min: any) => {
                            const existLines: any[] = [];
                            const latestCp = metricsArray[metricsArray.length - 1];
                            const nextCheckpoint: any = {
                                ts: ts,
                                tooltip: {
                                    timestamp: _next.getTime(),
                                    interval: 60,
                                }
                            };
                            for (const _fig in latestCp) {
                                if (!['ts', 'tooltip'].includes(_fig)) {
                                    let avgVal = 0;
                                    if (!existLines.includes(_fig)) existLines.push(_fig);
                                    if (state1min.hasOwnProperty(_fig)) {
                                        avgVal = calculateAvg(state1min[_fig]);
                                        avgVal = parseFloat(avgVal.toFixed(2));
                                    }

                                    // temp. to manage dropping counts. (to be removed later)
                                    if (diffInMin > 62000 && avgVal == 0) {
                                        const preValue = latestCp[_fig];
                                        avgVal = preValue > 0 ? preValue : 0;
                                    }

                                    nextCheckpoint[_fig] = avgVal;
                                }
                            }
                            metricsArray = pushMetric(metricsArray, nextCheckpoint, 60);

                            if (existLines.length > 0 && existLines.length < 25 && !isHostFilterApplied) {
                                // check for new machine installed or not.
                                for (const rKey in state1min) {
                                    if (!existLines.includes(rKey)) {
                                        // new machine installed.
                                        let avgVal = calculateAvg(state1min[rKey]);
                                        avgVal = parseFloat(avgVal.toFixed(2));
                                        for (let i = 0; i < metricsArray.length; i++) {
                                            if (i === metricsArray.length - 1) {
                                                metricsArray[i][rKey] = avgVal;
                                            } else {
                                                metricsArray[i][rKey] = 0;
                                            }
                                        }
                                    }
                                }
                            }

                            return metricsArray;
                        }

                        if (metricsCpuArray.length) {
                            state.info.metrics.cards.cpu_usage.graph = commonFunc(metricsCpuArray, state.metric1min.cpu_usage.graph);
                        }

                        // -----------Push into Metrics: RAM Usage-----------------

                        if (metricsRamArray.length) {
                            state.info.metrics.cards.ram_usage.graph = commonFunc(metricsRamArray, state.metric1min.ram_usage.graph);
                        }

                        // -----------Push into Metrics: Network Usage-----------------

                        const metricsNetworkReceiveArray = state.info?.metrics?.cards?.network_usage?.receive
                        && Array.isArray(state.info.metrics.cards.network_usage.receive)
                            ? state.info.metrics.cards.network_usage.receive : [];

                        const metricsNetworkTransmitArray = state.info?.metrics?.cards?.network_usage?.transmit
                        && Array.isArray(state.info.metrics.cards.network_usage.transmit)
                            ? state.info.metrics.cards.network_usage.transmit : [];

                        if (metricsNetworkReceiveArray.length) {
                            state.info.metrics.cards.network_usage.receive = commonFunc(metricsNetworkReceiveArray, state.metric1min.network_usage.receive);
                        }

                        if (metricsNetworkTransmitArray.length) {
                            state.info.metrics.cards.network_usage.transmit = commonFunc(metricsNetworkTransmitArray, state.metric1min.network_usage.transmit);
                        }

                        // -----------Push into Metrics: Disk Usage-----------------

                        const metricsDiskReadArray = state.info?.metrics?.cards?.disk_usage?.read
                        && Array.isArray(state.info.metrics.cards.disk_usage.read)
                            ? state.info.metrics.cards.disk_usage.read : [];

                        const metricsDiskWriteArray = state.info?.metrics?.cards?.disk_usage?.write
                        && Array.isArray(state.info.metrics.cards.disk_usage.write)
                            ? state.info.metrics.cards.disk_usage.write : [];

                        if (metricsDiskReadArray.length) {
                            state.info.metrics.cards.disk_usage.read = commonFunc(metricsDiskReadArray, state.metric1min.disk_usage.read);
                        }

                        if (metricsDiskWriteArray.length) {
                            state.info.metrics.cards.disk_usage.write = commonFunc(metricsDiskWriteArray, state.metric1min.disk_usage.write);
                        }

                        // -----------State Manage for Metrics: Common-----------------

                        for (const key in metricCount) {
                            for (const subKey in metricCount[key]) {
                                state.metric1min[key][subKey] = {};
                                for (const fing in metricCount[key][subKey]) {
                                    state.metric1min[key][subKey][fing] = metricCount[key][subKey][fing];
                                }
                            }
                        }

                    } else {

                        for (const key in metricCount) {
                            for (const subKey in metricCount[key]) {
                                for (const fing in metricCount[key][subKey]) {
                                    if (typeof state.metric1min[key][subKey][fing] != 'undefined') {
                                        state.metric1min[key][subKey][fing] = [...state.metric1min[key][subKey][fing], ...metricCount[key][subKey][fing]];
                                    } else {
                                        state.metric1min[key][subKey][fing] = metricCount[key][subKey][fing];
                                    }
                                }
                            }
                        }

                    }
                } else {
                    // later..
                }

                // -----------Ratio of Logs-----------------

                let logRatioArray = state.info?.logs?.cards?.ratio
                && Array.isArray(state.info.logs.cards.ratio)
                    ? state.info.logs.cards.ratio : [];

                if (logRatioArray.length > 0) {
                    const latestCp: any = logRatioArray[logRatioArray.length - 1];
                    const lastTime = latestCp?.tooltip?.timestamp || 0;
                    const diffInMin = (Date.parse(d.toString()) - Date.parse(new Date(lastTime).toString()));
                    if (diffInMin >= ONE_MIN) {
                        const _next = new Date(Date.parse(new Date(lastTime).toString()) + ONE_MIN);
                        const ts = formatTwoDigits(_next.getHours()) + ':' + formatTwoDigits(_next.getMinutes());

                        if (logRatioArray.length > 0) {
                            // diffInMin > 62000 condition :- temp. to manage dropping counts. (to be removed later)
                            if (diffInMin > 62000) {
                                if (state.log1min.error == 0) {
                                    const preValue = latestCp['error'];
                                    state.log1min.error = preValue > 0 ? preValue : 0;
                                }
                                if (state.log1min.warn == 0) {
                                    const preValue = latestCp['warn'];
                                    state.log1min.warn = preValue > 0 ? preValue : 0;
                                }
                                if (state.log1min.info == 0) {
                                    const preValue = latestCp['info'];
                                    state.log1min.info = preValue > 0 ? preValue : 0;
                                }
                                if (state.log1min.debug == 0) {
                                    const preValue = latestCp['debug'];
                                    state.log1min.debug = preValue > 0 ? preValue : 0;
                                }
                            }

                            const nextCheckpoint: any = {
                                ts: ts,
                                tooltip: {
                                    timestamp: _next.getTime(),
                                    interval: 60,
                                }
                            }
                            if (latestCp.hasOwnProperty('error')) {
                                nextCheckpoint['error'] = state.log1min.error;
                            }
                            if (latestCp.hasOwnProperty('warn')) {
                                nextCheckpoint['warn'] = state.log1min.warn;
                            }
                            if (latestCp.hasOwnProperty('info')) {
                                nextCheckpoint['info'] = state.log1min.info;
                            }
                            if (latestCp.hasOwnProperty('debug')) {
                                nextCheckpoint['debug'] = state.log1min.debug;
                            }
                            logRatioArray = pushMetric(logRatioArray, nextCheckpoint, 60);
                            state.info.logs.cards.ratio = logRatioArray;
                        }
                        state.log1min = logCount;

                    } else {
                        state.log1min.error += logCount.error;
                        state.log1min.warn += logCount.warn;
                        state.log1min.info += logCount.info;
                        state.log1min.debug += logCount.debug;
                    }
                } else {
                    // later..
                }

                // -----------Ratio of Traces-----------------

                let traceRatioArray = state.info?.traces?.cards?.ratio
                && Array.isArray(state.info.traces.cards.ratio)
                    ? state.info.traces.cards.ratio : [];

                if (traceRatioArray.length > 0) {
                    const latestCp: any = traceRatioArray[traceRatioArray.length - 1];
                    const lastTime = latestCp?.tooltip?.timestamp || 0;
                    const diffInMin = (Date.parse(d.toString()) - Date.parse(new Date(lastTime).toString()));
                    if (diffInMin >= ONE_MIN) {

                        const _next = new Date(Date.parse(new Date(lastTime).toString()) + ONE_MIN);
                        const ts = formatTwoDigits(_next.getHours()) + ':' + formatTwoDigits(_next.getMinutes());

                        if (traceRatioArray.length > 0) {

                            // diffInMin > 62000 condition :- temp. to manage dropping counts. (to be removed later)
                            if (diffInMin > 62000) {
                                if (state.trace1min.ok == 0) {
                                    const preValue = latestCp['ok'];
                                    state.trace1min.ok = preValue > 0 ? preValue : 0;
                                }
                                if (state.trace1min.error == 0) {
                                    const preValue = latestCp['error'];
                                    state.trace1min.error = preValue > 0 ? preValue : 0;
                                }
                            }

                            const nextCheckpoint: any = {
                                ts: ts,
                                tooltip: {
                                    timestamp: _next.getTime(),
                                    interval: 60,
                                }
                            }
                            if (latestCp.hasOwnProperty('ok')) {
                                nextCheckpoint['ok'] = state.trace1min.ok;
                            }
                            if (latestCp.hasOwnProperty('error')) {
                                nextCheckpoint['error'] = state.trace1min.error;
                            }
                            traceRatioArray = pushMetric(traceRatioArray, nextCheckpoint, 60);
                            state.info.traces.cards.ratio = traceRatioArray;
                        }
                        state.trace1min = traceCount;

                    } else {
                        state.trace1min.ok += traceCount.ok;
                        state.trace1min.error += traceCount.error;
                    }
                } else {
                    // later..
                }
            }

            return {...state};
        },*/

        [WS_UNIFIED_DASHBOARD_RECEIVED]: (state, opts) => {

            const d = new Date();
            const ONE_MIN = 60*1000;
            const quickFilters = state.quickFilters;
            // const isHostFilterApplied = typeof quickFilters.host == "string";
            const logListFilter = quickFilters?.log_list || 'all';
            const traceListFilter = quickFilters?.trace_list || 'all';

            if (opts.hasOwnProperty('logsData') && opts.logsData != null) {
                const logCount = {
                    error: 0,
                    warn: 0,
                    info: 0,
                    debug: 0,
                };

                const logsRecords: any = opts.logsData?.service
                && typeof opts.logsData.service === "object"
                && Object.keys(opts.logsData.service).length ? Object.values(opts.logsData.service) : [];

                for (const items of logsRecords) {
                    for (const item of items) {

                        if (item.severityNumber >= 17 && item.severityNumber <= 20) {
                            logCount.error += item.count;
                        } else if (item.severityNumber >= 13 && item.severityNumber <= 16) {
                            logCount.warn += item.count;
                        } else if (item.severityNumber >= 9 && item.severityNumber <= 12) {
                            logCount.info += item.count;
                        } else if (item.severityNumber >= 5 && item.severityNumber <= 8) {
                            logCount.debug += item.count;
                        }

                        if (state.info.logs?.cards && state.info.logs.cards?.list) {

                            // -----------Logs-List Push-----------------
                            if (logListFilter == 'infra' && (item.attrs.hasOwnProperty("fluent.tag") || item.attrs.hasOwnProperty("mw.app.lang"))) {
                                continue;
                            } else if (logListFilter == 'app' && (!item.attrs.hasOwnProperty("fluent.tag") || !item.attrs.hasOwnProperty("mw.app.lang"))) {
                                continue;
                            } else if (logListFilter == 'error' && (item.severityNumber < 17 || item.severityNumber > 20)) {
                                continue;
                            } else if (logListFilter == 'warn' && (item.severityNumber < 13 || item.severityNumber > 16)) {
                                continue;
                            } else if (logListFilter == 'info' && (item.severityNumber < 9 || item.severityNumber > 12)) {
                                continue;
                            } else if (logListFilter == 'debug' && (item.severityNumber < 5 || item.severityNumber > 8)) {
                                continue;
                            }

                            let stateList = state.info.logs.cards.list;
                            stateList = unshiftRecords(
                                "logs",
                                stateList,
                                {
                                    body_fingerprint: item.crc32,
                                    count: item.count,
                                    resource: item.resource,
                                    is_apm: (item.attrs.hasOwnProperty("fluent.tag") || item.attrs.hasOwnProperty("mw.app.lang")),
                                    label: item.body,
                                    mini_chart: [],
                                    started: moment.unix(item.timestampMs / 1000).format("YYYY-MM-DDTHH:mm:ssZ"),
                                    severity_text: item.severityText,
                                    trace_id: "",
                                    attributes: item.attrs,
                                }, 100);

                            if (logListFilter == 'high') {
                                stateList = stateList.sort((a: { count: number; }, b: { count: number; }) => {
                                    return b.count - a.count;
                                });
                            } else if (logListFilter == 'low') {
                                stateList = stateList.sort((a: { count: number; }, b: { count: number; }) => {
                                    return a.count - b.count;
                                });
                            }

                            state.info.logs.cards.list = stateList;
                        }
                    }
                }

                // -----------Overview-----------------
                const logOverview = state.info?.logs?.overview
                && typeof state.info.logs.overview == 'object'
                && Object.keys(state.info.logs.overview).length > 0
                    ? state.info.logs.overview : {};
                if (Object.keys(logOverview).length > 0) {
                    logOverview['view0']['value'] += (logCount.error + logCount.warn + logCount.info + logCount.debug);
                    logOverview['view0']['sub_value'] = (state.info.logs.cards?.list || []).length || 0;
                    logOverview['view1']['value'] += logCount.error;
                    logOverview['view2']['value'] += logCount.warn;
                    logOverview['view3']['value'] += logCount.info;
                    state.info.logs.overview = logOverview;
                }

                // -----------Ratio of Logs-----------------

                let logRatioArray = state.info?.logs?.cards?.ratio
                && Array.isArray(state.info.logs.cards.ratio)
                    ? state.info.logs.cards.ratio : [];

                if (logRatioArray.length > 0) {
                    const latestCp: any = logRatioArray[logRatioArray.length - 1];
                    const lastTime = latestCp?.tooltip?.timestamp || 0;
                    const diffInMin = (Date.parse(d.toString()) - Date.parse(new Date(lastTime).toString()));
                    if (diffInMin >= ONE_MIN) {
                        const _next = new Date(Date.parse(new Date(lastTime).toString()) + ONE_MIN);
                        const ts = formatTwoDigits(_next.getHours()) + ':' + formatTwoDigits(_next.getMinutes());

                        if (logRatioArray.length > 0) {
                            // diffInMin > 62000 condition :- temp. to manage dropping counts. (to be removed later)
                            if (diffInMin > 62000) {
                                if (state.log1min.error == 0) {
                                    const preValue = latestCp['error'];
                                    state.log1min.error = preValue > 0 ? preValue : 0;
                                }
                                if (state.log1min.warn == 0) {
                                    const preValue = latestCp['warn'];
                                    state.log1min.warn = preValue > 0 ? preValue : 0;
                                }
                                if (state.log1min.info == 0) {
                                    const preValue = latestCp['info'];
                                    state.log1min.info = preValue > 0 ? preValue : 0;
                                }
                                if (state.log1min.debug == 0) {
                                    const preValue = latestCp['debug'];
                                    state.log1min.debug = preValue > 0 ? preValue : 0;
                                }
                            }

                            const nextCheckpoint: any = {
                                ts: ts,
                                tooltip: {
                                    timestamp: _next.getTime(),
                                    interval: 60,
                                }
                            }
                            if (latestCp.hasOwnProperty('error')) {
                                nextCheckpoint['error'] = state.log1min.error;
                            }
                            if (latestCp.hasOwnProperty('warn')) {
                                nextCheckpoint['warn'] = state.log1min.warn;
                            }
                            if (latestCp.hasOwnProperty('info')) {
                                nextCheckpoint['info'] = state.log1min.info;
                            }
                            if (latestCp.hasOwnProperty('debug')) {
                                nextCheckpoint['debug'] = state.log1min.debug;
                            }
                            logRatioArray = pushMetric(logRatioArray, nextCheckpoint, 60);
                            state.info.logs.cards.ratio = logRatioArray;
                        }
                        state.log1min = logCount;

                    } else {
                        state.log1min.error += logCount.error;
                        state.log1min.warn += logCount.warn;
                        state.log1min.info += logCount.info;
                        state.log1min.debug += logCount.debug;
                    }
                } else {
                    // later..
                }

            }

            if (opts.hasOwnProperty('tracesData') && opts.tracesData != null) {
                const traceCount = {
                    ok: 0,
                    error: 0
                };

                const traceRecords: any = opts.tracesData?.service
                && typeof opts.tracesData.service === "object"
                && Object.keys(opts.tracesData.service).length ? Object.values(opts.tracesData.service) : [];
                let maxLatency = 0;
                for (const items of traceRecords) {
                    for (const item of items) {

                        traceCount.ok += item.traceCount;
                        traceCount.error += item.traceErrorCount;
                        const _durationNano = item.durationNano;
                        if (_durationNano > maxLatency) {
                            maxLatency = _durationNano
                        }

                        if (state.info.traces?.cards && state.info.traces.cards?.list) {
                            // -----------Traces-List Push-----------------
                            if (traceListFilter == 'error' && item.hasError == false) {
                                continue;
                            } else if (traceListFilter == 'ok' && item.hasError == true) {
                                continue;
                            }
                            // console.log("item==>", item, traceListFilter)
                            let stateList = state.info.traces.cards.list;
                            stateList = unshiftRecords(
                                "traces",
                                stateList,
                                {
                                    trace_id: item.traceId,
                                    route: item.name,
                                    service_name: item.resourceAttributes["service.name"] || "",
                                    p75: 0,
                                    p99: 0,
                                    last_hit_at: moment.unix(item.timestampNs / 1000000000).format("YYYY-MM-DDTHH:mm:ssZ"),
                                    error_count: item.traceErrorCount,
                                    success_count: item.traceCount,
                                    total_hits: (item.traceCount + item.traceErrorCount),
                                    start_date: 0,
                                    end_date: 0,
                                    interval: 60
                                }, 100);

                            state.info.traces.cards.list = stateList;
                        }

                    }
                }

                // -----------Overview-----------------
                const traceOverview = state.info?.traces?.overview
                && typeof state.info.traces.overview == 'object'
                && Object.keys(state.info.traces.overview).length > 0
                    ? state.info.traces.overview : {};
                if (Object.keys(traceOverview).length > 0) {
                    traceOverview['view0']['value'] += (traceCount.error + traceCount.ok);
                    traceOverview['view0']['sub_value'] = (state.info.traces.cards?.list || []).length || 0;
                    traceOverview['view1']['value'] += traceCount.error;
                    traceOverview['view2']['value'] += traceCount.ok;

                    const previousMaxLatency = traceOverview['view3']['value'];;
                    if (maxLatency > previousMaxLatency) {
                        traceOverview['view3']['value'] = maxLatency;
                    }

                    state.info.traces.overview = traceOverview;
                }

                // -----------Ratio of Traces-----------------

                let traceRatioArray = state.info?.traces?.cards?.ratio
                && Array.isArray(state.info.traces.cards.ratio)
                    ? state.info.traces.cards.ratio : [];

                if (traceRatioArray.length > 0) {
                    const latestCp: any = traceRatioArray[traceRatioArray.length - 1];
                    const lastTime = latestCp?.tooltip?.timestamp || 0;
                    const diffInMin = (Date.parse(d.toString()) - Date.parse(new Date(lastTime).toString()));
                    if (diffInMin >= ONE_MIN) {

                        const _next = new Date(Date.parse(new Date(lastTime).toString()) + ONE_MIN);
                        const ts = formatTwoDigits(_next.getHours()) + ':' + formatTwoDigits(_next.getMinutes());

                        if (traceRatioArray.length > 0) {

                            // diffInMin > 62000 condition :- temp. to manage dropping counts. (to be removed later)
                            if (diffInMin > 62000) {
                                if (state.trace1min.ok == 0) {
                                    const preValue = latestCp['ok'];
                                    state.trace1min.ok = preValue > 0 ? preValue : 0;
                                }
                                if (state.trace1min.error == 0) {
                                    const preValue = latestCp['error'];
                                    state.trace1min.error = preValue > 0 ? preValue : 0;
                                }
                            }

                            const nextCheckpoint: any = {
                                ts: ts,
                                tooltip: {
                                    timestamp: _next.getTime(),
                                    interval: 60,
                                }
                            }
                            if (latestCp.hasOwnProperty('ok')) {
                                nextCheckpoint['ok'] = state.trace1min.ok;
                            }
                            if (latestCp.hasOwnProperty('error')) {
                                nextCheckpoint['error'] = state.trace1min.error;
                            }
                            traceRatioArray = pushMetric(traceRatioArray, nextCheckpoint, 60);
                            state.info.traces.cards.ratio = traceRatioArray;
                        }
                        state.trace1min = traceCount;

                    } else {
                        state.trace1min.ok += traceCount.ok;
                        state.trace1min.error += traceCount.error;
                    }
                } else {
                    // later..
                }

            }

            return {...state};
        },
    }),
})
export default DashboardReducers