import * as React from "react";
import "./_style.scss"
import BackoffRoutes from "views/backoff/routes";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getBackoffAuthToken} from "store/backoff/selectors";
import {useEffect} from "react";
import BackoffRoutesHelper from "views/backoff/routes-helper";
import {requestBackoffInit} from "store/backoff/api";
import {useLocation} from "react-router-dom";

const BackoffLayouts = function (props: any) {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const token = useSelector(getBackoffAuthToken)

    useEffect(() => {
        if (token !== "") {
            dispatch(requestBackoffInit())
            if (location.pathname == "/login")
                navigate("/")

        } else {
            navigate("/login")
        }
    }, [token, token === null])



    return <React.Fragment>
        <BackoffRoutesHelper routes={[BackoffRoutes]}/>
    </React.Fragment>
}
export default BackoffLayouts