import InstallationSteps from "../../../components/steps";
// import TabItems from "core/components/v2/nav-tab/items";
// import { ReactIcon } from "core/components/svg/icons";
import AppSelection, {
  AppSelectionTabs,
} from "core/components/v2/app-selection";
import Button from "core/components/v2/button";
import CardSelection from "core/components/v2/card-selection";
import { Message } from "core/components/v2/enums";
import {
  LinkToDoc,
  MiddlewareIcon,
  RefreshIcon,
} from "core/components/v2/svg/icons";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useEffect, useState } from "react";
import Command from "../../../components/code-block/command";
import MwMessage from "../../../components/message";
import MwLinkCard from "../../../components/mw-link-card";
import ViewYourData from "../../common/view-data-buttons";

import { useDispatch } from "react-redux";
import StepItem from "../../../components/steps/step-item";

import {
  AngularIcon,
  GatsbyIcon,
  NextJSIcon,
  ReactIcon,
  VueIcon,
} from "core/components/v2/svg/language-icons";

import StatusBadge from "core/components/status-badge";
import { useSelector } from "react-redux";
import { vercelStatusReset } from "store/integration/actions";
import {
  requestVercelListProjects,
  requestVercelStatus,
  requestVercelSyncProjects,
} from "store/integration/api";
import {
  getVercelInflight,
  getVercelProjects,
  getVercelStatus,
} from "store/integration/selectors";
import { getThemesMode } from "store/selectors";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import { StepsEnum } from "views/modules/integrations/entity";
import FooterButtons from "../../../components/footer-buttons";

interface VercelIntegrationProps {
  serverlessSupport: boolean;
}

interface VercelCardProps {
  appStatus: string;
}

const VercelCard: React.FC<VercelCardProps> = ({ appStatus }) => {
  return (
    <div className="vercel-card-box">
      {/* <Link to="https://vercel.com/integrations/middleware" target="_blank"> */}
      <div
        className="vercel-card"
        onClick={() => {
          window.open("https://vercel.com/integrations/middleware", "_blank");
        }}
      >
        <div className="content">
          <div className="middleware-icon">
            <MiddlewareIcon size={40} />
          </div>
          <div className="middleware-title">
            <h2>Middleware</h2>
            <StatusBadge>Observability</StatusBadge>
          </div>
          <div className="middleware-vercel-link">
            <LinkToDoc color="var(--color-primary)" size={12} />
          </div>
        </div>
        <div className="description">
          AI-powered cloud observability platform.
        </div>
      </div>
      {/* </Link> */}
      {appStatus == "vercel_integration_found" && (
        <MwMessage
          type={Message.Success}
          message="Middleware app is already installed"
        ></MwMessage>
      )}
      {appStatus == "vercel_integration_bad_access_token" && (
        <MwMessage
          type={Message.Warning}
          message=" Middleware app hasn't been installed properly,
                    Please re-install it."
        ></MwMessage>
      )}
    </div>
  );
};

const VercelIntegration: React.FC<VercelIntegrationProps> = ({
  serverlessSupport,
}) => {
  // const inflight = useSelector(getVercelInflight);
  // const dispatch = useDispatch();
  // const syncProjects = () => {
  //   if (!inflight) dispatch(requestVercelSyncProjects());
  // };
  const context = useContext<ContextProps>(AppContext);

  const dispatch = useDispatch();
  const isHelloBarRendered = document.querySelector(".hello-bar") !== null;

  const isVercelRedirectionExist = false;
  // const isVercelRedirectionExist = context?.user?.misc_details?.redirect_to_vercel || false;
  const [warningStrip, setWarningStrip] = useState(isVercelRedirectionExist);
  const themeMode = useSelector(getThemesMode);

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      dispatch(showToast("success", "Copied to Clipboard !"));
    });
  }

  const [activeStep, setActiveStep] = useState(StepsEnum.InstalledApp);
  const [activeProject, setActiveProject] = useState("");

  const modifyNextjs =
    "const nextConfig = { \n" +
    "   experimental: { \n " +
    "      instrumentationHook: true, \n" +
    "      serverComponentsExternalPackages: ['@middleware.io/agent-apm-nextjs'] \n " +
    "   } \n" +
    "} \n" +
    "module.exports = nextConfig";

  const projectNameVercel =
    "// @ts-ignore \n" +
    "import tracker from '@middleware.io/agent-apm-nextjs'; \n \n" +
    "export function register() { \n" +
    "   tracker.track({\n" +
    `        serviceName: "${activeProject == "" ? "{APM-SERVICE-NAME}" : activeProject}", \n  ` +
    `      accessToken: "${context.user?.account?.agent_config?.token}", \n` +
    `        target: "vercel", \n` +
    "   }); \n" +
    "}";

  const ApmLogginSnap =
    " // @ts-ignore \n" +
    "import tracker from '@middleware.io/agent-apm-nextjs'; \n \n" +
    "export default async function handler(req, res) { \n \n" +
    '   tracker.info("Info Sample"); \n' +
    '   tracker.warn("Warn Sample", { \n' +
    '       "tester": "Alex", \n' +
    "   }); \n" +
    '   tracker.debug("Debug Sample"); \n' +
    '   tracker.error("Error Sample");\n \n' +
    "   // Your existing code \n " +
    "}";

  useEffect(() => {
    if (activeStep == StepsEnum.InstalledApp) {
      dispatch(requestVercelStatus());
    }

    if (activeStep == "projects_list") {
      dispatch(vercelStatusReset());
      dispatch(requestVercelListProjects());
    }
  }, [activeStep]);

  const appStatus = useSelector(getVercelStatus);
  const projects = useSelector(getVercelProjects);
  const inflight = useSelector(getVercelInflight);

  useEffect(() => {
    return () => {
      dispatch(vercelStatusReset());
    };
  }, []);

  useEffect(() => {
    if (isVercelRedirectionExist) {
      setActiveStep("projects_list");
    }
  }, [isVercelRedirectionExist]);

  const syncProjects = () => {
    if (!inflight) dispatch(requestVercelSyncProjects());
  };

  // State to manage project tabs
  const [projectTabs, setProjectTabs] = useState<AppSelectionTabs>([]);
  // Update projectTabs whenever projects change
  useEffect(() => {
    const updatedTabs: AppSelectionTabs = projects.map(
      (item: any, i: number) => ({
        label: item.name,
        icon: item.icon,
        value: i + 1,
        enabled: item.enabled,
      })
    );

    setProjectTabs(updatedTabs);
  }, [projects]);

  interface StepFormat {
    title: string;
    content: React.ReactNode;
  }
  const sendEvent = useMixPanel();
  const steps: StepFormat[] = [
    {
      title: "Install Next.js APM Package",
      content: (
        <>
          Run the command below in your terminal to install Middleware's Next.js
          APM package.
          <br />
          <br />
          <Command
            command={`npm install @middleware.io/agent-apm-nextjs`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Vercel",
                command_type: "Install Next.js APM Package",
              });
            }}
          />
          <MwMessage
            type={Message.Info}
            message="You can clone the sample project from the GitHub repository."
          ></MwMessage>
          As this feature is experimental, you need to explicitly opt-in by
          adding the following code to your `next.config.js` file.
          <br />
          <br />
          <Command
            command={`const nextConfig = {
  experimental: {
    instrumentationHook: true,
    serverComponentsExternalPackages: ['@middleware.io/agent-apm-nextjs']
  }
}
module.exports = nextConfig`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Vercel",
                command_type: "Install Next.js APM Package",
              });
            }}
          />
          Create a custom `instrumentation.ts` file in your project root
          directory, and add the following code.
          <br />
          <br />
          <Command
            command={`// @ts-ignore
import tracker from '@middleware.io/agent-apm-nextjs';

export function register() {
    tracker.track({
        serviceName: ${activeProject == "" ? "{APM-SERVICE-NAME}" : activeProject},
        accessToken: "murwpaubimiwrfrtoxbwpoiuoztzdjgmsyph",
        target: "vercel",
    });
}`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Vercel",
                command_type: "Install Next.js APM Package",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Enable Custom Logs (Optional)",
      content: (
        <>
          Custom logs are ones that the application does not produce by default.
          They may be generated within certain methods, or managed by a central
          logging method in your application.To get started with custom logs add
          the following code: <br />
          <br />
          <Command
            command={` // @ts-ignore
import tracker from '@middleware.io/agent-apm-nextjs';

export default async function handler(req, res) {

  tracker.info("Info Sample");
  tracker.warn("Warn Sample", {
      "tester": "Alex",
  });
  tracker.debug("Debug Sample");
  tracker.error("Error Sample");

  // Your existing code
}`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Vercel",
                command_type: "Enable Custom Logs (Optional)",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Deploy your Git Repository to Vercel",
      content: (
        <>
          Launch your latest project on Vercel by deploying your Git repository.
        </>
      ),
    },
  ];

  const moveToCode = () => {
    if (projects.length == 0)
      dispatch(
        showToast("error", "At least one project is mandatory to proceed.")
      );
    else if (activeProject == "")
      dispatch(
        showToast(
          "error",
          "Please select a project, on which you want to apply instrumentation."
        )
      );
    else setActiveStep("code");
  };

  // let vercelPage = 1;
  const [appEnv, setAppEnv] = useState<number | string>(1);
  return (
    <>
      <div className="installation-has-body-footer">
        {activeStep === StepsEnum.InstalledApp && (
          <InstallationSteps>
            <StepItem
              stepNumber={1}
              title="Choose Framework & Install Middleware Application"
            >
              Choose your framework. Then, complete the installation of our
              application. Vercel tracing is only available on
              Serverless-Functions, as mentioned in the prerequisites here .
              <br />
              <br />
              <CardSelection
                tabs={[
                  {
                    label: "Next.js",
                    icon: <NextJSIcon />,
                    value: 1,
                    enabled: true,
                  },
                  {
                    label: "React",
                    icon: <ReactIcon />,
                    value: 2,
                    enabled: false,
                  },
                  {
                    label: "Angular",
                    icon: <AngularIcon />,
                    value: 3,
                    enabled: false,
                  },
                  {
                    label: "Vue",
                    icon: <VueIcon />,
                    value: 4,
                    enabled: false,
                  },
                  {
                    label: "Gatsby",
                    icon: <GatsbyIcon />,
                    value: 5,
                    enabled: false,
                  },
                ]}
                selectedTab={1}
                onTabChange={(tabObject) => {
                  setAppEnv(tabObject.value);
                }}
              />
              <br />
              <br />
              Click below to install the application from the Vercel
              marketplace. Checkout our doc for complete Vercel integration.
              <br />
              <br />
              <MwLinkCard
                appStatus={appStatus}
                link="https://vercel.com/integrations/middleware"
                title="Middleware"
              />
            </StepItem>
          </InstallationSteps>
        )}
        {activeStep == StepsEnum.ProjectList && (
          <InstallationSteps>
            <StepItem stepNumber={2} title="Select project to Install agent">
              Please select any one project that you want to instrument right
              now. If you want to instrument multiple projects then follow the
              same step for rest of projects.
              <br />
              <br />
              <AppSelection
                allowDelete={false}
                tabs={projectTabs}
                selectedTab={1}
                onTabChange={(tabObject) => {
                  setAppEnv(tabObject.value);
                  setActiveProject(tabObject.label);
                }}
              />
              <Button
                prefixicon={<RefreshIcon />}
                onClick={() => {
                  syncProjects();
                }}
              >
                Sync Projects
              </Button>
              <br />
              <br />
              <MwMessage
                type={Message.Info}
                message={
                  <div>
                    To deploy your first project, You can use the following
                    command:{" "}
                    <b>npx create-next-app@latest --example api-routes</b>
                  </div>
                }
              ></MwMessage>
            </StepItem>
          </InstallationSteps>
        )}
        {activeStep == StepsEnum.Code && (
          <InstallationSteps>
            {steps.map((step, index) => (
              <StepItem stepNumber={index + 1} key={index} title={step.title}>
                {step.content}
              </StepItem>
            ))}
            <ViewYourData language="Vercel" stepNumber={steps.length + 1} />
          </InstallationSteps>
        )}
      </div>

      <div className="installation-page-footer">
        <FooterButtons
          secondaryBtnText="Back"
          {...(activeStep !== StepsEnum.Code && {
            onPrimaryBtnClick: () => {
              if (activeStep == StepsEnum.InstalledApp) {
                setActiveStep(StepsEnum.ProjectList);
              }
              if (activeStep == StepsEnum.ProjectList) {
                setActiveStep(StepsEnum.Code);
              }
            },
          })}
          {...(activeStep !== StepsEnum.InstalledApp && {
            onSecondaryBtnClick: () => {
              if (activeStep == StepsEnum.ProjectList) {
                setActiveStep(StepsEnum.InstalledApp);
              }
              if (activeStep == StepsEnum.Code) {
                setActiveStep(StepsEnum.ProjectList);
              }
            },
          })}
        />
      </div>
    </>
  );
};

export default VercelIntegration;
