import { Pagination as AntdPagination, Table } from "antd";
import classNames from "classnames";
import { abbreviateNumber } from "core/application/utils";
import LogoLoader from "core/components/v2/loader/logo-loader";
import {
  ColumnProps,
  IS_ACTION_COLUMN,
  LocalStorageType,
  RECORDS_PER_PAGE,
  Size,
  Sorter,
  TableProps,
  UnionRecordType,
  paginatedTableData,
} from "core/components/v2/table-view/entity";
import { ResizableTitle } from "core/components/v2/table-view/resizable";
import Tooltip from "core/components/v2/tooltip";
import { getThemeState } from "core/utils";
import React, { useCallback, useEffect, useState } from "react";
import ReactDragListView from "react-drag-listview";
import { SortDirection } from "views/modules/builder/entities/builder.entities";
import Button from "../button";
import { Size as ButtonSize } from "../enums";
import { SectionMinusIcon, SectionPlusIcon } from "../svg/icons";
import NoDataSpace from "../no-data";

export const TableView = (props: TableProps) => {
  const {
    columns,
    dataProvider,
    rowOnClick,
    pagination,
    uniqId,
    maxHeight,
    onSortingChange,
    loading,
    noDataLabel,
    rowClassName,
  } = props;
  const [tableColumns, setTableColumns] = useState<ColumnProps[]>([]);
  useEffect(() => {
    setTableColumns(columns);
  }, [columns]);
  const dragProps = {
    onDragEnd(fromIndex: number, toIndex: number) {
      const newColumns = [...tableColumns];
      const item = newColumns.splice(fromIndex, 1)[0];
      newColumns.splice(toIndex, 0, item);
      setTableColumns(newColumns);
      localStorage.setItem(
        uniqId,
        JSON.stringify(
          newColumns.map((col) => ({
            width: col.width,
            key: col.columnKey,
          }))
        )
      );
    },
    nodeSelector: "th",
    handleSelector: ".dragHandler",
    ignoreSelector: "ant-table-column-sorter",
    lineClassName: "drag-line-classname",
  };
  const handleResize = useCallback(
    (index: number) =>
      (e: React.MouseEvent, { size }: { size: Size }) => {
        setTableColumns((tableColumns) => {
          const nextColumns = [...tableColumns];
          nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
          };
          localStorage.setItem(
            uniqId,
            JSON.stringify(
              nextColumns.map((col) => ({
                width: col.width,
                key: col.columnKey,
              }))
            )
          );
          return nextColumns;
        });
      },
    []
  );

  const resizedColumns = tableColumns.map((col, index) => ({
    ...col,
    dataIndex: col.columnKey,
    sorter: col.defaultSortOrder ? true : false,
    className: col.columnKey === IS_ACTION_COLUMN ? "is-action-column" : "",
    defaultSortOrder:
      col.defaultSortOrder === SortDirection.Asc
        ? "ascend"
        : col.defaultSortOrder === SortDirection.Desc
          ? "descend"
          : col.defaultSortOrder === SortDirection.None
            ? "none"
            : undefined,
    showSorterTooltip: col.sorterTooltip
      ? { target: "sorter-icon", title: col.sorterTooltip }
      : { target: "sorter-icon" },
    onHeaderCell: () => ({
      width: col.width ? col.width : 150,
      onResize: handleResize(index),
      tooltipInfo: col.tooltipInfo,
    }),
    render: (text: string, record?: UnionRecordType) => {
      const approxLetterWid = 11;
      const wid = col.width ? col.width : 150;
      const charactersPerLine = Math.floor(wid / approxLetterWid);
      const type = typeof text;
      const tooltipContent = col.renderTooltipContent
        ? col.renderTooltipContent
        : text;
      return (
        <Tooltip
          content={
            (type === "number" || type === "string") &&
            tooltipContent.toString()?.length > charactersPerLine
              ? tooltipContent
              : ""
          }
        >
          {col.render && <div>{col.render(text, record)}</div>}
          {!col.render && <div>{text}</div>}
        </Tooltip>
      );
    },
  }));
  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  useEffect(() => {
    const localStorageData = localStorage.getItem(uniqId);
    const dataArray: LocalStorageType[] = localStorageData
      ? JSON.parse(localStorageData)
      : [];
    const orderedColumns: ColumnProps[] = [];

    const columnsNotInLocalStorage = columns.filter(
      (column) => !dataArray.find((item) => item.key === column.columnKey)
    );

    const defaultColumns = columnsNotInLocalStorage.map((column) => ({
      ...column,
      width: 150,
    }));

    if (localStorageData && dataArray.length > 0) {
      dataArray.forEach((item) => {
        const column = columns.find((col) => col.columnKey === item.key);
        if (column) {
          orderedColumns.push({
            ...column,
            width: item.width,
          });
        }
      });
      const mergedColumns = [...orderedColumns, ...defaultColumns];
      setTableColumns(mergedColumns);
    } else {
      setTableColumns(columns);
    }
  }, [columns, uniqId]);

  const renderPaginationTitle = () => {
    if (pagination) {
      const recordsPerPage = pagination.recordsPerPage
        ? pagination.recordsPerPage
        : RECORDS_PER_PAGE;
      return (
        <span className={"showNumber"}>
          {"Showing"}:{" "}
          <span>
            {(pagination.totalRecords > 0
              ? Math.ceil(pagination.totalRecords / recordsPerPage) > 1
                ? recordsPerPage * (pagination.page - 1) + 1
                : 1
              : 0) +
              "-" +
              (recordsPerPage * pagination.page < pagination.totalRecords
                ? recordsPerPage * pagination.page
                : pagination.totalRecords)}{" "}
          </span>
          {"of "}{" "}
          <span>{abbreviateNumber(pagination.totalRecords, false, true)} </span>
          {"  " + pagination.recordsLabel ? pagination.recordsLabel : ""}
        </span>
      );
    }
  };
  const locale = {
    emptyText: loading
      ? " "
      : noDataLabel
        ? noDataLabel
        : <NoDataSpace/>, // Text to display when the table has no data
  };
  const customExpandIcon = (props: {
    expanded: boolean;
    expandable?: boolean;
    onExpand: (
      arg0: Record<string, string | number | object | string[] | object[]>,
      arg1: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => void;
    record: Record<string, string | number | object | string[] | object[]>;
  }) => {
    return (
      props.expandable && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            props.onExpand(props.record, e);
          }}
        >
          <Button
            size={ButtonSize.Small}
            className="section-expand-button"
            prefixicon={
              props.expanded ? (
                <div className="circle">
                  <SectionMinusIcon />
                </div>
              ) : (
                <div className="circle">
                  <SectionPlusIcon />
                </div>
              )
            }
          />
        </div>
      )
    );
  };

  const dataProviderWithKeys = dataProvider.map((item, index) => ({
    ...item,
    mw_table_row_key: `row_${index}`,
  }));

  const rowClassNames = classNames(rowClassName, {
    "mw-clickable-row": rowOnClick,
    "mw-hoverable-row": !rowOnClick,
  });
  const renderInternalPaginatedData = paginatedTableData(
    dataProviderWithKeys,
    pagination?.page || 1,
    pagination?.recordsPerPage || RECORDS_PER_PAGE
  );

  const themeMode = getThemeState();
  return (
    <div
      className={`mw-table-view ${themeMode === "dark" ? "dark-theme" : ""}`}
    >
      <ReactDragListView.DragColumn {...dragProps}>
        <Table
          locale={locale}
          loading={{ indicator: <LogoLoader size={50} />, spinning: loading }}
          onRow={(
            record: Record<
              string,
              string | number | object | string[] | object[]
            >,
            rowIndex: number
          ) => {
            return {
              onClick: () => {
                if (rowOnClick) rowOnClick(record, rowIndex);
              },
            };
          }}
          rowClassName={rowClassNames}
          rowKey={"mw_table_row_key"}
          components={components}
          columns={resizedColumns}
          expandable={props.expandable ? props.expandable : {}}
          dataSource={
            pagination?.internalPaginatedData
              ? renderInternalPaginatedData
              : dataProviderWithKeys
          }
          expandIcon={customExpandIcon}
          scroll={{ x: "100%", y: "max-content" }}
          pagination={false}
          onChange={(a0: null, a1: null, sorter: Sorter) => {
            if (onSortingChange) {
              onSortingChange(
                sorter.field,
                sorter.order == "ascend"
                  ? SortDirection.Asc
                  : sorter.order == "descend"
                    ? SortDirection.Desc
                    : SortDirection.None
              );
            }
          }}
        />
      </ReactDragListView.DragColumn>
      {pagination && (
        <div className={"mw-pagination"}>
          <div className={"left-area"}>
            <div className={"showing-result"}>
              <div className={"middle"}>{renderPaginationTitle()}</div>
            </div>
          </div>
          {pagination && (
            <div className={"right-area"}>
              <AntdPagination
                total={pagination.totalRecords}
                defaultPageSize={
                  pagination.recordsPerPage
                    ? pagination.recordsPerPage
                    : RECORDS_PER_PAGE
                }
                defaultCurrent={1}
                current={pagination.page}
                onChange={(page: number, pageSize: number) =>
                  pagination.onChange?.(page, pageSize)
                }
                showSizeChanger={false}
                hideOnSinglePage={true}
                showSorterTooltip={{ target: "sorter-icon" }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
