import { getTenantUrl } from "core/application/utils";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import InstallationCard from "../../components/card";
import Command from "../../components/code-block/command";
import InstallationSteps from "../../components/steps";
import StepItem from "../../components/steps/step-item";
const ElasticLogStashIntegration = () => {
  const [tab, setTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Logstash agent should already be installed on your machine.",
          value: "",
        },
      ],
    },
  ];
  const command = `MW_API_KEY=${context.user?.account?.agent_config?.token} MW_TARGET=${capturePath}:443 bash -c "$(curl -L https://install.middleware.io/scripts/mw-kube-agent-install.sh)"`;
  const sendEvent = useMixPanel();
  return (
    <>
      <InstallationSteps>
        <StepItem stepNumber={1} title={"Prerequisites"}>
          <InstallationCard items={cardItems} />
        </StepItem>
        <StepItem stepNumber={2} title={"Logstash Configuration"}>
          Open your{" "}
          <a href="https://www.elastic.co/guide/en/logstash/current/config-setting-files.html#pipeline-config-files">
            Logstash pipeline configuration
          </a>{" "}
          file (usually located in <b>/etc/logstash/conf.d</b> directory on
          Linux platform) and add the following filter and output configuration
          as described below
          <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Logstash",
                command_type: "Logstash Configuration",
              });
            }}
            command={`filter {
    ruby {
        init => "
          require 'socket'
          @@hostname = Socket.gethostname
        "
        code => "event.set('host.id', @@hostname)
                 event.set('mw.account_key','${context.user?.account?.agent_config?.token}')
                "
    }
}
`}
            className="margin-1"
          />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Logstash",
                command_type: "Logstash Configuration",
              });
            }}
            command={`output{
   http {
     Format => "json"
     Http_method => "post"
     headers => {
       "Content-Type" => "application/json"
     }

     Url => "${capturePath}/logstash"
   }
}
`}
            className="margin-1"
          />
        </StepItem>
      </InstallationSteps>
    </>
  );
};
export default ElasticLogStashIntegration;
