import { TimeRangeOption } from "core/components/datepicker/model";
import { ChartTypeOptions } from "core/components/v2/charts/models";
import { Align } from "core/components/v2/enums";
import { StatusBadgeType } from "core/components/v2/status-badge";
import { UnionRecordType } from "core/components/v2/table-view/entity";
import React from "react";
import { CustomizedFilterOption } from "views/layouts/app/components/customise-filter-button";
import { DateRange, FilterParams } from "views/layouts/app/routes/model";
import { ColumnDttm, ColumnTypes } from "./column.entities";
import { ValueType, WithExpr } from "./extra.entities";
import { Source } from "./source.entities";
import { RenderTpl } from "./tpl.entities";

export const SelectOneMetric = "Select Metric";
export const SelectFilter = "Select Filter";
export const SelectGroupBy = "Select a Field";
export const DefaultNoneValue = "Default (None)";
export interface GridviewColumn {
  accessor: string;
  order: number;
  sort: string;
  tpl: RenderTpl;
  isMetric?: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GridviewData = Record<string, any>;
export interface GridviewDataType {
  columns: GridviewColumn[];
  data: GridviewData[];
  total_count: number;
  timestamp: number;
  up_count_data: string;
}
export interface TimeSeriesData {
  timestamps: Record<number, boolean>;
  chartGroups: Record<string, Record<string, ChartPoint[]>>;
  timestamp: number;
  resourceType1?: string;
  name?: string;
  noDataFound?: boolean;
  /**
   * Timerange will be calclated from builder side based on fromTs, toTs and interval
   * This will be used in timeseries graph to make points
   */
  timeRange?: {
    fromTs: number;
    toTs: number;
    interval: number;
  };
}
export interface ChartPoint {
  value: number | string;
  config: RenderTpl;
  timestamp: number | string;
  lastValue: number;
  count: number;
}
export interface QueryCountItem {
  name: string;
  value: number;
  config: RenderTpl;
  comparisonData: number;
}
export type QueryCountDataType = QueryCountItem[];
export type WidgetDataType =
  | GridviewDataType
  | TimeSeriesData
  | QueryCountDataType;
export interface ChartDataType {
  inflight?: boolean;
  gridviewData?: GridviewDataType;
  timeseriesChartData?: TimeSeriesData;
  queryCountChartData?: QueryCountDataType;
}

export interface CustomWidget {
  key?: string;
  label: string;
  builderId: number;
  scopeId: number;
  widgetAppId: number;
  builderConfig?: Query | Query[];
  formulas?: string[];
  params?: RequestParam[];
  widgetData?: ChartDataType;
  layout?: LayoutItem;
  builderViewOptions: BuilderViewOptions;
  isError?: boolean;
  errorMsg?: string;
  builderMetaData?: builderMetaData;
  isClone?: boolean;
  returnOnlyFormulaResult?: boolean;
}
export interface builderMetaData {
  desc?: string;
  group_name?: string;
  is_default?: boolean;
  default_key?: string;
  display_preference?: TimeRangeOption;
  chartType?: string;
}
export interface VisualFormattingRule {
  color: string;
  operator: FilterOp;
  value: number;
  // value2 will be used for operators like "Between".
  value2?: number;
  applyAt?: string;
}

export enum ColorApplyAt {
  Background = "background",
  Value = "value",
}

export interface Query {
  // key uniquely identifies this query in the list.
  key?: string;
  with?: WithExpr[];
  columns: ColumnTypes[];
  source: Source;
  granularity?: ColumnDttm;
  meta_data?: QueryMetaData;
}

export interface QueryMetaData {
  alias?: string;
  tpl?: RenderTpl;
  visualFormatting?: VisualFormattingRule[];
  comparisonRanges?: {
    badgeType: StatusBadgeType;
  };
  // billingUnit will be used only with "source.name" = "billing" (specific to rule creation).
  billingUnit?: string;
}
export const defaultQuery: Query = {
  with: [],
  columns: [SelectOneMetric],
  source: {
    name: "",
    alias: "",
    data_set: undefined,
    dataset_id: 0,
  },
};
export interface DeleteDatasetResponse {
  success: boolean;
}
export interface RemoveGraphFromDashboardResponse {
  success: boolean;
}
export interface AddUpdateWidgetBuilder {
  action: string;
  body: CustomWidget;
  inflight: boolean;
}
export interface LayoutConfig {
  x: number;
  y: number;
  w: number;
  h: number;
  i: number;
}
export interface DeleteDatasetResponse {
  success: boolean;
}
export interface RemoveGraphFromDashboardResponse {
  success: boolean;
}
export interface GridViewCountData {
  total_count: number;
  up_count_data: string;
  resourceName: string;
}
export interface AddUpdateWidgetBuilder {
  action: string;
  body: CustomWidget;
  inflight: boolean;
}
export interface LayoutConfig {
  x: number;
  y: number;
  w: number;
  h: number;
  i: number;
}

export enum Aggregations {
  "sum" = "sum",
  "avg" = "avg",
  "min" = "min",
  "max" = "max",
  "count" = "count",
  "any" = "any",
  "anyLast" = "anyLast",
}

export type AggMethod =
  | "sum"
  | "avg"
  | "min"
  | "max"
  | "count"
  | "any"
  | "anyLast"
  | "uniq";

export enum MetricTypeEnum {
  MetricTypeNone,
  ColumnIntNumber,
  ColumnFloatNumber,
  ColumnBool,
  ColumnString,
  ColumnArray,
  ColumnMap,
  ColumnTuple,
  ColumnTimestamp,
  ColumnCalculated,
  ColumnAggregateFunc,
  ResourceFingerprint,
  ResourceAttributeKey,
  MetricAttributeKey,
  MetricNameKey,
  MetricValue,
  LogAttributeKey,
  TagMapKey,
  CalculatedMetric,
  MetricOther,
  ColumnNameOther,
}

export const AggregationOptions: AggregationOptionType[] = [
  {
    title: "Average",
    value: "avg",
    excludedMetricType: [
      MetricTypeEnum.ResourceAttributeKey,
      MetricTypeEnum.MetricAttributeKey,
      MetricTypeEnum.ColumnString,
      MetricTypeEnum.LogAttributeKey,
      MetricTypeEnum.TagMapKey,
    ],
  },
  {
    title: "Sum",
    value: "sum",
    excludedMetricType: [
      MetricTypeEnum.ResourceAttributeKey,
      MetricTypeEnum.MetricAttributeKey,
      MetricTypeEnum.ColumnString,
      MetricTypeEnum.LogAttributeKey,
      MetricTypeEnum.TagMapKey,
    ],
  },
  {
    title: "Minimum",
    value: "min",
    excludedMetricType: [
      MetricTypeEnum.ResourceAttributeKey,
      MetricTypeEnum.MetricAttributeKey,
      MetricTypeEnum.ColumnString,
      MetricTypeEnum.LogAttributeKey,
      MetricTypeEnum.TagMapKey,
    ],
  },
  {
    title: "Maximum",
    value: "max",
    excludedMetricType: [
      MetricTypeEnum.ResourceAttributeKey,
      MetricTypeEnum.MetricAttributeKey,
      MetricTypeEnum.ColumnString,
      MetricTypeEnum.LogAttributeKey,
      MetricTypeEnum.TagMapKey,
    ],
  },

  // Below are for string types
  { title: "Any", value: "any", excludedMetricType: [] },
  { title: "Unique", value: "uniq", excludedMetricType: [] },
  { title: "Count", value: "count", excludedMetricType: [] },
  { title: "Group", value: "group", excludedMetricType: [] },
  {
    title: "Diff",
    value: "diff",
    excludedMetricType: [],
    supportedMetrics: ["k8s.container.restarts"],
  },
];
export interface AggregationOptionType {
  title: string;
  value: string;
  excludedMetricType: MetricTypeEnum[];
  supportedMetrics?: string[];
}

export interface SqlOperatorType {
  title: string;
  value: FilterOp;
  allowMultiple: boolean;
  defaultOptions?: string[];
  excludedMetricType: MetricTypeEnum[];
  isArithmetic: boolean;
  validateValue?: (values: validateType) => boolean;
}
export interface SqlOperatorType {
  title: string;
  value: FilterOp;
  allowMultiple: boolean;
  defaultOptions?: string[];
  excludedMetricType: MetricTypeEnum[];
  isArithmetic: boolean;
  validateValue?: (values: validateType) => boolean;
}
export type FilterOp =
  | "="
  | "!="
  | "<"
  | "<="
  | ">"
  | ">="
  | "IN"
  | "NOT IN"
  | "LIKE"
  | "NOT LIKE"
  | "ILIKE"
  | "NOT ILIKE"
  | "BETWEEN"
  | "IS NOT NULL"
  | "IS NULL"
  | "REGEX"
  | "NOT REGEX";

export enum FilterOpEnum {
  Equal = "=",
  NotEqual = "!=",
  LessThan = "<",
  LessThanEqual = "<=",
  GreaterThan = ">",
  GreaterThanEqual = ">=",
  In = "IN",
  NotIn = "NOT IN",
  Like = "LIKE",
  NotLike = "NOT LIKE",
  ILIKE = "ILIKE",
  NotILike = "NOT ILIKE",
  Between = "BETWEEN",
  Null = "IS NULL",
  NotNull = "IS NOT NULL",
  Regex = "REGEX",
  NotRegex = "NOT REGEX",
}

export const operatorMapping: Record<string, FilterOpEnum> = {
  "=": FilterOpEnum.Equal,
  "!=": FilterOpEnum.NotEqual,
  "<": FilterOpEnum.LessThan,
  "<=": FilterOpEnum.LessThanEqual,
  ">": FilterOpEnum.GreaterThan,
  ">=": FilterOpEnum.GreaterThanEqual,
  IN: FilterOpEnum.In,
  "NOT IN": FilterOpEnum.NotIn,
  LIKE: FilterOpEnum.Like,
  "NOT LIKE": FilterOpEnum.NotLike,
  ILIKE: FilterOpEnum.ILIKE,
  "NOT ILIKE": FilterOpEnum.NotILike,
  BETWEEN: FilterOpEnum.Between,
  "IS NOT NULL": FilterOpEnum.NotNull,
  "IS NULL": FilterOpEnum.Null,
  REGEX: FilterOpEnum.Regex,
  "NOT REGEX": FilterOpEnum.NotRegex,
};

export const getFilter = (key: string, filterData: FilterParams) => {
  const { operator, values } = filterData;

  if (operator === FilterOpEnum.Equal && values.length) {
    return builderFiltersInstance.Equal(key, values[0]);
  } else if (operator === FilterOpEnum.In && values.length) {
    return builderFiltersInstance.In(key, values);
  } else if (operator === FilterOpEnum.NotEqual) {
    return builderFiltersInstance.NotEqual(key, values[0]);
  } else if (operator === FilterOpEnum.NotIn) {
    return builderFiltersInstance.NotIn(key, values);
  } else if (operator === FilterOpEnum.ILIKE && values.length) {
    return builderFiltersInstance.ILike(key, values[0]);
  } else if (operator === FilterOpEnum.Like && values.length) {
    return builderFiltersInstance.Like(key, values[0]);
  } else if (operator === FilterOpEnum.NotLike && values.length) {
    return builderFiltersInstance.NotLike(key, values[0]);
  } else if (operator === FilterOpEnum.NotILike && values.length) {
    return builderFiltersInstance.NotILike(key, values[0]);
  } else if (operator === FilterOpEnum.LessThan && values.length) {
    if (!isNaN(Number(values[0]))) {
      return builderFiltersInstance.LessThan(key, Number(values[0]));
    }
    return;
  } else if (operator === FilterOpEnum.GreaterThan && values.length) {
    if (!isNaN(Number(values[0]))) {
      return builderFiltersInstance.GreaterThan(key, Number(values[0]));
    }
    return;
  } else if (operator === FilterOpEnum.LessThanEqual && values.length) {
    if (!isNaN(Number(values[0]))) {
      return builderFiltersInstance.LessThanEqual(key, Number(values[0]));
    }
    return;
  } else if (operator === FilterOpEnum.GreaterThanEqual && values.length) {
    if (!isNaN(Number(values[0]))) {
      return builderFiltersInstance.GreaterThanEqual(key, Number(values[0]));
    }
    return;
  } else if (operator === FilterOpEnum.Between && values.length === 2) {
    if (!isNaN(Number(values[0])) && !isNaN(Number(values[1]))) {
      const numbers = [];
      numbers.push(Number(values[0]));
      numbers.push(Number(values[1]));
      return builderFiltersInstance.Between(key, numbers);
    }
    return;
  } else if (operator === FilterOpEnum.Null && values.length) {
    return builderFiltersInstance.Null(key, values[0]);
  } else if (operator === FilterOpEnum.NotNull && values.length) {
    return builderFiltersInstance.NotNull(key, values[0]);
  } else {
    return;
  }
};

// getOperatorZeroValue returns the zero/default value for the given operator
export const getOperatorZeroValue = (operator: FilterOpEnum) => {
  switch (operator) {
    case FilterOpEnum.Equal:
      return "";
    case FilterOpEnum.NotEqual:
      return "";
    case FilterOpEnum.LessThan:
      return "";
    case FilterOpEnum.LessThanEqual:
      return "";
    case FilterOpEnum.GreaterThan:
      return "";
    case FilterOpEnum.GreaterThanEqual:
      return "";
    case FilterOpEnum.In:
      return [] as string[];
    case FilterOpEnum.NotIn:
      return [] as string[];
    case FilterOpEnum.Like:
      return "";
    case FilterOpEnum.NotLike:
      return "";
    case FilterOpEnum.ILIKE:
      return "";
    case FilterOpEnum.NotILike:
      return "";
    case FilterOpEnum.Between:
      return [] as number[];
    case FilterOpEnum.Null:
      return "";
    case FilterOpEnum.NotNull:
      return "";
    case FilterOpEnum.Regex:
      return "";
    case FilterOpEnum.NotRegex:
      return "";
  }
};

export const getDefaultOperator = (attribute: FilterOption | undefined) => {
  if (
    attribute?.type === MetricTypeEnum.ResourceAttributeKey ||
    attribute?.type === MetricTypeEnum.MetricAttributeKey
  )
    return FilterOpEnum.In;

  return FilterOpEnum.Equal;
};

interface validateType {
  valuesToCompare: string | number;
  originalValue: string | number;
}
const validateEqual = (values: validateType): boolean => {
  return values.valuesToCompare === values.originalValue;
};
const validateNotEqual = (values: validateType): boolean => {
  return values.valuesToCompare !== values.originalValue;
};
const validateLessThan = (values: validateType): boolean => {
  return values.valuesToCompare < values.originalValue;
};
const validateLessThanEqual = (values: validateType): boolean => {
  return values.valuesToCompare <= values.originalValue;
};
const validateGreaterThan = (values: validateType): boolean => {
  return values.valuesToCompare > values.originalValue;
};
const validateGreaterThanEqual = (values: validateType): boolean => {
  return values.valuesToCompare >= values.originalValue;
};
export const sqlOperators: SqlOperatorType[] = [
  {
    title: "= Equal to",
    value: "=",
    allowMultiple: false,
    excludedMetricType: [],
    isArithmetic: true,
    validateValue: validateEqual,
  },
  {
    title: "!= Not equal to",
    value: "!=",
    allowMultiple: false,
    excludedMetricType: [],
    isArithmetic: true,
    validateValue: validateNotEqual,
  },
  {
    title: "< Less than",
    value: "<",
    allowMultiple: false,
    excludedMetricType: [
      MetricTypeEnum.ResourceAttributeKey,
      MetricTypeEnum.MetricAttributeKey,
    ],
    isArithmetic: true,
    validateValue: validateLessThan,
  },
  {
    title: "<= Less than or equal to",
    value: "<=",
    allowMultiple: false,
    excludedMetricType: [
      MetricTypeEnum.ResourceAttributeKey,
      MetricTypeEnum.MetricAttributeKey,
    ],
    isArithmetic: true,
    validateValue: validateLessThanEqual,
  },
  {
    title: "> Greater than",
    value: ">",
    allowMultiple: false,
    excludedMetricType: [
      MetricTypeEnum.ResourceAttributeKey,
      MetricTypeEnum.MetricAttributeKey,
    ],
    isArithmetic: true,
    validateValue: validateGreaterThan,
  },
  {
    title: ">= Greater than or equal to",
    value: ">=",
    allowMultiple: false,
    excludedMetricType: [
      MetricTypeEnum.ResourceAttributeKey,
      MetricTypeEnum.MetricAttributeKey,
    ],
    isArithmetic: true,
    validateValue: validateGreaterThanEqual,
  },
  {
    title: "IN",
    value: "IN",
    allowMultiple: true,
    excludedMetricType: [],
    isArithmetic: false,
  },
  {
    title: "NOT IN",
    value: "NOT IN",
    allowMultiple: true,
    excludedMetricType: [],
    isArithmetic: false,
  },
  {
    title: "LIKE",
    value: "LIKE",
    allowMultiple: false,
    excludedMetricType: [MetricTypeEnum.MetricNameKey],
    isArithmetic: false,
  },
  {
    title: "NOT LIKE",
    value: "NOT LIKE",
    allowMultiple: false,
    excludedMetricType: [MetricTypeEnum.MetricNameKey],
    isArithmetic: false,
  },
  {
    title: "ILIKE (case insensitive)",
    value: "ILIKE",
    allowMultiple: false,
    excludedMetricType: [MetricTypeEnum.MetricNameKey],
    isArithmetic: false,
  },
  {
    title: "NOT ILIKE (case insensitive)",
    value: "NOT ILIKE",
    allowMultiple: false,
    excludedMetricType: [MetricTypeEnum.MetricNameKey],
    isArithmetic: false,
  },
  {
    title: "BETWEEN",
    value: "BETWEEN",
    allowMultiple: true,
    excludedMetricType: [
      MetricTypeEnum.ResourceAttributeKey,
      MetricTypeEnum.MetricAttributeKey,
    ],
    isArithmetic: false,
  },
  {
    title: "IS NULL",
    value: "IS NULL",
    allowMultiple: false,
    defaultOptions: ["IS NULL"],
    excludedMetricType: [],
    isArithmetic: false,
  },
  {
    title: "IS NOT NULL",
    value: "IS NOT NULL",
    allowMultiple: false,
    defaultOptions: ["IS NOT NULL"],
    excludedMetricType: [],
    isArithmetic: false,
  },
  {
    title: "REGEX",
    value: "REGEX",
    allowMultiple: false,
    excludedMetricType: [],
    isArithmetic: false,
  },
  {
    title: "NOT REGEX",
    value: "NOT REGEX",
    allowMultiple: false,
    excludedMetricType: [],
    isArithmetic: false,
  },
];
export const getAvailableOperators = (
  metricType: MetricTypeEnum
): SqlOperatorType[] => {
  return sqlOperators.filter((op) => {
    return op.excludedMetricType.indexOf(metricType) === -1;
  });
};
export const defaultWidgetBuilder: CustomWidget = {
  builderConfig: [
    {
      columns: [SelectOneMetric],
      source: {
        name: "",
        alias: "",
        dataset_id: 0,
      },
    },
  ],
  label: "Untitled",
  builderId: -1,
  scopeId: -1,
  widgetAppId: 1,
  isClone: false,
  builderViewOptions: {
    displayScope: "",
  },
};
export interface LayoutItem {
  _scope_id: number;
  h: number;
  resizeHandles: ["se"];
  w: number;
  x: number;
  y: number;
}
export type CustomRender = <T>(
  t: T,
  record?: UnionRecordType
) => React.ReactNode;
export interface ColumnConfigDetails {
  isHidden?: boolean;
  title?: string;
  width?: number;
  color?: string;
  bgColor?: string;
  tpl?: RenderTpl;
  visualFormatting?: VisualFormattingRule[];
  customRender?: CustomRender;
  align?: Align;
  tooltipContent?: string;
  tooltipInfo?: string;
}
//TODO: Make use of columnConfig instead of columnNames
export type ColumnConfig = Record<string, ColumnConfigDetails>;
export interface BuilderViewResource {
  resourceType: string;
  columns: string[];
  name: string;
  widgetAppId: number;
  /**
   * @deprecated Instead send it in BuilderView directly as sorting
   */
  with?: WithExpr[];
  /**
   * @deprecated Instead use columnConfig
   */
  columnNames?: Record<string, string>;
  /**
   * @deprecated Instead pass it in NestedProps
   */
  columnConfig?: ColumnConfig;
  resourceAttributes?: string[];
  metricAttributes?: Record<string, string[]>;
  granularity?: ColumnDttm;
}
export interface BuilderViewOptions {
  report?: {
    reportId: number;
    reportName: string;
    reportSource?: string;
    reportKey?: string;
    reportDescription?: string;
    metadata?: Record<string, unknown>;
  };
  displayScope?: string;
  resource?: BuilderViewResource;
}
export interface Pagination {
  noOfRecordsPerPage: number;
  limit: number;
}
export interface BuilderProps {
  /**
   * Based on this builder will fetch data. data can be either of report, displayScope or resource
   */
  builderViewOptions?: BuilderViewOptions;
  additionalBuilderViewOptions?: BuilderViewOptions[];
  /**
   * @deprecated The method should not be used
   */
  openInnerDialog?: boolean;
  /**
   * Display header if you are using builder as resource
   * @deprecated. Instead pass boxTitle
   **/
  boxHeader?: boolean;
  /** Display title if you are using builder as resource */
  boxTitle?: string;
  /**
   * @deprecated
   * Not implemeted in v2 design
   */
  tooltipId?: number | string;
  /**
   * @deprecated
   * Use titleInfo instead in nested props
   */
  tooltipInfo?: string;
  bordered?: boolean;
  /**
   * To disable to watch global query params.
   * If you are disabling this, you need to pass dateRange and filters as props
   * */
  disableQueryParamChange?: boolean;
  /**
   * If you are using builder as resource and type as table, you can listen on click event on list
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listOnClick?: (a0: any) => void;
  /**
   * If you are using builder as resource and type as timeseries, you can disable legend selection
   */
  allowAllLegend?: boolean;
  /**
   * todo: @Bhomaram define description and divide into multiple props or give proper names
   */
  nestedProps?: BuilderNestedProps;
  /**
   * Builder will watch this for change
   * IF you are passing date range then it builder will use this instead of global date range
   *  */
  dateRange?: DateRange;
  /**
   * Builder will watch this for change
   */
  filters?: FilterDataType[];
  /**
   * Builder will watch this for change
   */
  sortBy?: SortBy[];
  /**
   * Builder will watch this for change
   */
  groupBy?: GroupBy[];
  /**
   * @deprecated: Use pagination object instead.
   */
  limit?: Limit;
  subInfo?: string;
  columnsSelection?: CustomizedFilterOption[];
  onColumnsChange?: (selectedColumns: string[]) => void;
  onDefaultLabelClick?: () => void;
  /**
   * limit must be divisible by noOfRecordsPerPage.Value of noOfRecordsPerPage must be grater than zero.
   */
  pagination?: Pagination;
  expandableTitleOptions?: {
    showExpandable: boolean;
    titleOnClick?: (expanded: boolean) => void;
  };
  extraParams?: RequestParam[];
  // allowMultipleRequest allows to execute multiple Data API requests.
  allowMultipleRequest?: boolean;
  isPoller?: boolean;
}

export enum FilterConditionOptions {
  "AND" = "and",
  "OR" = "or",
}

// and operartor filter example
// const filter = {
//     ["and"]: [
//         {
//             "host.name": {
//                 [FilterOpEnum.In]: ["mw-front-worker2"]
//             },
//             "host.id": "abc",
//         }
//     ]
// }
export type InFilterType = Record<
  string,
  {
    [FilterOpEnum.In]: string[];
  }
>;

export type NotInFilterType = Record<
  string,
  {
    [FilterOpEnum.NotIn]: string[];
  }
>;

export type EqualFilterType = Record<
  string,
  {
    [FilterOpEnum.Equal]: string;
  }
>;
export type NotEqualFilterType = Record<
  string,
  {
    [FilterOpEnum.NotEqual]: string;
  }
>;

export type LikeFilterType = Record<
  string,
  {
    [FilterOpEnum.Like]: string;
  }
>;
export type ILikeFilterType = Record<
  string,
  {
    [FilterOpEnum.ILIKE]: string;
  }
>;

export type NotLikeFilterType = Record<
  string,
  {
    [FilterOpEnum.NotLike]: string;
  }
>;
export type NotILikeFilterType = Record<
  string,
  {
    [FilterOpEnum.NotILike]: string;
  }
>;

export type RegexFilterType = Record<
  string,
  {
    [FilterOpEnum.Regex]: string;
  }
>;

export type NotRegexFilterType = Record<
  string,
  {
    [FilterOpEnum.NotRegex]: string;
  }
>;

export type LessThanEqualFilterType = Record<
  string,
  {
    [FilterOpEnum.LessThanEqual]: number;
  }
>;
export type GreaterThanEqualFilterType = Record<
  string,
  {
    [FilterOpEnum.GreaterThanEqual]: number;
  }
>;

export type LessThanFilterType = Record<
  string,
  {
    [FilterOpEnum.LessThan]: number;
  }
>;
export type GreaterThanFilterType = Record<
  string,
  {
    [FilterOpEnum.GreaterThan]: number;
  }
>;

export type BetweenFilterType = Record<
  string,
  {
    [FilterOpEnum.Between]: number[];
  }
>;

export type NullFilterType = Record<
  string,
  {
    [FilterOpEnum.Null]: string;
  }
>;
export type NotNullFilterType = Record<
  string,
  {
    [FilterOpEnum.NotNull]: string;
  }
>;

export interface AndFilterType {
  [FilterConditionOptions.AND]: FilterDataType[];
}
export interface OrFilterType {
  [FilterConditionOptions.OR]: FilterDataType[];
}
export type FilterDataType =
  | InFilterType
  | EqualFilterType
  | LikeFilterType
  | AndFilterType
  | OrFilterType
  | NotEqualFilterType
  | NotInFilterType
  | ILikeFilterType
  | NotLikeFilterType
  | NotILikeFilterType
  | RegexFilterType
  | NotRegexFilterType
  | LessThanFilterType
  | GreaterThanFilterType
  | LessThanEqualFilterType
  | GreaterThanEqualFilterType
  | BetweenFilterType
  | NullFilterType
  | NotNullFilterType;

export interface GenerateBuilderFiltersType {
  /** Get Filter for In operator. It should have valid values else will return undefined */
  In: (fiterKey: string, values: string[]) => InFilterType | undefined;
  /** Get Filter for NotIn operator. It should have valid values else will return undefined */
  NotIn: (fiterKey: string, values: string[]) => NotInFilterType | undefined;
  /** Get Filter for Equal operator. It should have valid values else will return undefined */
  Equal: (filterKey: string, value: string) => EqualFilterType | undefined;
  /** Get Filter for NotEqual operator. It should have valid values else will return undefined*/
  NotEqual: (
    filterKey: string,
    value: string
  ) => NotEqualFilterType | undefined;
  /** Get Filter for And operator. It should have valid values else will return undefined */
  And: (values: FilterDataType[]) => AndFilterType | undefined;
  /** Get Filter for Or operator. It should have valid values else will return undefined */
  Or: (values: FilterDataType[]) => OrFilterType | undefined;
  /** Get Filter for Like operator. It should have valid values else will return undefined */
  ConvertToValueType: (obj: FilterDataType) => ValueType | undefined;
  /** Get Filter for Like operator. It should have valid values else will return undefined */
  Like: (filterKey: string, value: string) => LikeFilterType | undefined;
  /**Get Filter for ILIKE operator */
  ILike: (filterKey: string, value: string) => ILikeFilterType | undefined;
  /** Get Filter for Not Like operator. It should have valid values else will return undefined */
  NotLike: (filterKey: string, value: string) => NotLikeFilterType | undefined;
  /** Get Filter for Not ILike operator. It should have valid values else will return undefined */
  NotILike: (
    filterKey: string,
    value: string
  ) => NotILikeFilterType | undefined;
  /* Get Filter for Regex operator */
  Regex: (filterKey: string, value: string) => RegexFilterType | undefined;
  /* Get Filter for NotRegex operator */
  NotRegex: (
    filterKey: string,
    value: string
  ) => NotRegexFilterType | undefined;
  /** Get Filter for Less than operator. It should have valid values else will return undefined */
  LessThan: (
    filterKey: string,
    value: number
  ) => LessThanFilterType | undefined;
  /** Get Filter for Greater Than operator. It should have valid values else will return undefined */
  GreaterThan: (
    filterKey: string,
    value: number
  ) => GreaterThanFilterType | undefined;
  /** Get Filter for Less Than and Equal to operator. It should have valid values else will return undefined */
  LessThanEqual: (
    filterKey: string,
    value: number
  ) => LessThanEqualFilterType | undefined;
  /** Get Filter for Greater Than and Equal to operator. It should have valid values else will return undefined */
  GreaterThanEqual: (
    filterKey: string,
    value: number
  ) => GreaterThanEqualFilterType | undefined;
  /** Get Filter for Between operator. It should have valid values else will return undefined */
  Between: (
    filterKey: string,
    value: number[]
  ) => BetweenFilterType | undefined;
  /** Get Filter for Null operator. It should have valid values else will return undefined */
  Null: (filterKey: string, value: string) => NullFilterType | undefined;
  /** Get Filter for Not Null operator. It should have valid values else will return undefined */
  NotNull: (filterKey: string, value: string) => NotNullFilterType | undefined;
}

export const GenerateBuilderFilters = () => {
  const In = (fiterKey: string, values: string[]): InFilterType | undefined => {
    // if(values.length === 0) return
    return {
      [fiterKey]: {
        [FilterOpEnum.In]: values,
      },
    };
  };
  const NotIn = (
    fiterKey: string,
    values: string[]
  ): NotInFilterType | undefined => {
    // if(values.length === 0) return
    return {
      [fiterKey]: {
        [FilterOpEnum.NotIn]: values,
      },
    };
  };
  const Equal = (
    filterKey: string,
    value: string
  ): EqualFilterType | undefined => {
    // if (value === "" || value === undefined || value === null) return;
    return {
      [filterKey]: {
        [FilterOpEnum.Equal]: value,
      },
    };
  };
  const NotEqual = (
    filterKey: string,
    value: string
  ): NotEqualFilterType | undefined => {
    // if (value === "" || value === undefined || value === null) return;
    return {
      [filterKey]: {
        [FilterOpEnum.NotEqual]: value,
      },
    };
  };
  const And = (values: FilterDataType[]): AndFilterType | undefined => {
    if (values.length === 0) return;
    return {
      [FilterConditionOptions.AND]: values,
    };
  };
  const Or = (values: FilterDataType[]): OrFilterType | undefined => {
    if (values.length === 0) return;
    return {
      [FilterConditionOptions.OR]: values,
    };
  };
  const Like = (
    filterKey: string,
    value: string
  ): LikeFilterType | undefined => {
    if (value === "" || value === undefined || value === null) return;
    return {
      [filterKey]: {
        [FilterOpEnum.Like]: `%${value}%`,
      },
    };
  };
  const ILike = (
    filterKey: string,
    value: string
  ): ILikeFilterType | undefined => {
    if (value === "" || value === undefined || value === null) return;
    return {
      [filterKey]: {
        [FilterOpEnum.ILIKE]: `%${value}%`,
      },
    };
  };
  const NotLike = (
    filterKey: string,
    value: string
  ): NotLikeFilterType | undefined => {
    if (value === "" || value === undefined || value === null) return;
    return {
      [filterKey]: {
        [FilterOpEnum.NotLike]: `%${value}%`,
      },
    };
  };
  const NotILike = (
    filterKey: string,
    value: string
  ): NotILikeFilterType | undefined => {
    if (value === "" || value === undefined || value === null) return;
    return {
      [filterKey]: {
        [FilterOpEnum.NotILike]: `%${value}%`,
      },
    };
  };
  const Regex = (
    filterKey: string,
    value: string
  ): RegexFilterType | undefined => {
    if (value === "" || value === undefined || value === null) return;
    return {
      [filterKey]: {
        [FilterOpEnum.Regex]: value,
      },
    };
  };
  const NotRegex = (
    filterKey: string,
    value: string
  ): NotRegexFilterType | undefined => {
    if (value === "" || value === undefined || value === null) return;
    return {
      [filterKey]: {
        [FilterOpEnum.NotRegex]: value,
      },
    };
  };
  const LessThan = (
    filterKey: string,
    value: number
  ): LessThanFilterType | undefined => {
    return {
      [filterKey]: {
        [FilterOpEnum.LessThan]: value,
      },
    };
  };
  const GreaterThan = (
    filterKey: string,
    value: number
  ): GreaterThanFilterType | undefined => {
    return {
      [filterKey]: {
        [FilterOpEnum.GreaterThan]: value,
      },
    };
  };
  const LessThanEqual = (
    filterKey: string,
    value: number
  ): LessThanEqualFilterType | undefined => {
    return {
      [filterKey]: {
        [FilterOpEnum.LessThanEqual]: value,
      },
    };
  };
  const GreaterThanEqual = (
    filterKey: string,
    value: number
  ): GreaterThanEqualFilterType | undefined => {
    return {
      [filterKey]: {
        [FilterOpEnum.GreaterThanEqual]: value,
      },
    };
  };
  const Between = (
    filterKey: string,
    value: number[]
  ): BetweenFilterType | undefined => {
    if (value.length < 2) return;
    if (Number.isInteger(value[0]) && Number.isInteger(value[1])) {
      return {
        [filterKey]: {
          [FilterOpEnum.Between]: value,
        },
      };
    } else {
      return;
    }
  };
  const Null = (
    filterKey: string,
    value: string
  ): NullFilterType | undefined => {
    if (value === "" || value === undefined || value === null) return;
    return {
      [filterKey]: {
        [FilterOpEnum.Null]: value,
      },
    };
  };
  const NotNull = (
    filterKey: string,
    value: string
  ): NotNullFilterType | undefined => {
    if (value === "" || value === undefined || value === null) return;
    return {
      [filterKey]: {
        [FilterOpEnum.NotNull]: value,
      },
    };
  };
  const ConvertToValueType = (obj: FilterDataType): ValueType | undefined => {
    return obj as ValueType;
  };
  const obj: GenerateBuilderFiltersType = {
    In,
    NotIn,
    Equal,
    NotEqual,
    And,
    Or,
    ConvertToValueType,
    Like,
    ILike,
    NotLike,
    NotILike,
    Regex,
    NotRegex,
    LessThan,
    GreaterThan,
    LessThanEqual,
    GreaterThanEqual,
    Between,
    Null,
    NotNull,
  };
  return obj;
};
export const builderFiltersInstance = GenerateBuilderFilters();

export interface GenerateBuilderSortingType {
  /** Get Filter for In operator. It should have valid values else will return undefined */
  Asc: (fiterKey: string) => SortBy | undefined;
  /** Get Filter for Equal operator. It should have valid values else will return undefined */
  Desc: (filterKey: string) => SortBy | undefined;
  /**Get Values to send in request */
  getValues: (sortBys: SortBy[]) => WithExpr;
}

export const Asc = "asc";
export const Desc = "desc";

export enum SortDirection {
  Asc = "asc",
  Desc = "desc",
  None = "none",
}

export interface SortBy {
  key: string;
  direction: SortDirection;
}
export const GenerateBuilderSorting = () => {
  const Asc = (sortingKey: string): SortBy | undefined => {
    return {
      key: sortingKey,
      direction: SortDirection.Asc,
    };
  };
  const Desc = (filterKey: string): SortBy | undefined => {
    return {
      key: filterKey,
      direction: SortDirection.Desc,
    };
  };
  const getValues = (sortBys: SortBy[]): WithExpr => {
    const obj: Record<string, SortDirection> = {};
    sortBys.forEach((sortBy) => {
      obj[sortBy.key] = sortBy.direction;
    });
    const sortWith: WithExpr = {
      key: ORDER_BY_METRICS,
      value: obj,
      is_arg: false,
    };
    return sortWith;
  };
  const obj: GenerateBuilderSortingType = {
    Asc,
    Desc,
    getValues,
  };
  return obj;
};
export const builderSortingInstance = GenerateBuilderSorting();

export interface GenerateBuilderGroupByType {
  /** Get Filter for In operator. It should have valid values else will return undefined */
  GroupBy: (fiterKey: string) => GroupBy | undefined;
  /**Get Values to send in request */
  getValues: (groupBys: GroupBy[]) => WithExpr;
}

export type GroupBy = string;

export const GenerateBuilderGroupBy = () => {
  const GroupBy = (groupBy: string): GroupBy | undefined => {
    return groupBy;
  };
  const getValues = (groupBys: GroupBy[]): WithExpr => {
    const groupBy: WithExpr = {
      key: SELECT_DATA_BY,
      value: groupBys,
      is_arg: false,
    };
    return groupBy;
  };
  const obj: GenerateBuilderGroupByType = {
    GroupBy,
    getValues,
  };
  return obj;
};
export const builderGroupByInstance = GenerateBuilderGroupBy();

export interface GenerateBuilderLimitType {
  /** Get Filter for In operator. It should have valid values else will return undefined */
  Limit: (offset: number, limit: number) => Limit | undefined;
  /**Get Values to send in request */
  getValues: (limit: Limit) => WithExpr;
}

export interface Limit {
  offset: number;
  limit: number;
}

export const GenerateBuilderLimit = () => {
  const Limit = (offset: number, limit: number): Limit | undefined => {
    return {
      offset,
      limit,
    };
  };
  const getValues = (limit: Limit): WithExpr => {
    const groupBy: WithExpr = {
      key: SELECT_LIMIT,
      value: {
        offset: limit.offset,
        n: limit.limit,
      },
      is_arg: false,
    };
    return groupBy;
  };
  const obj: GenerateBuilderLimitType = {
    Limit,
    getValues,
  };
  return obj;
};
export const builderLimitInstance = GenerateBuilderLimit();
export interface BuilderNestedProps {
  /**
   * @deprecated. OnSelection, component will update url params itself. No need to pass this
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectionChange?: (a0: any) => void;
  hexagonProps?: {
    selectedHostId?: string;
    selectedValue?: string;
    selectedKey?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick?: (i: number, data: any) => void;
    splitBy?: string;
  };
  tableOptions?: {
    showTableStats?: boolean;
    statsLabel?: string;
  };
  allowAllLegend?: boolean;
  selection?: {
    tsStart?: number;
    tsEnd?: number;
  };
  /**
   * @deprecated Instead pass it with columnConfig
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  colorGroups?: Record<string, any>;
  /**
   * area_chart or other options for timeseries chart
   */
  chartType?: string;
  stackId?: string | null;
  /**
   * @deprecated Not in use
   * @Bhomaram confirm this and remove
   */
  doModification?: boolean;
  hideFilterAction?: boolean;
  columnConfig?: ColumnConfig;
  onDataReceived?: (data: ChartDataType) => void;
  timeStampData?: boolean;
  interval?: string;
  /**
   * Chart Height set for different content
   * @default 220
   */
  chartHeight?: number;
  /**
   * Chart title (Card Header)
   * @default null
   */
  actionMenu?: React.ReactNode;
  /**
   * Options if you do required to have a dropdown in component
   */
  options?: string[];
  /**
   * Default Selected Option
   */
  selectedOption?: string;
  /**
   * Call back when option is selected
   */
  selectOption?: (x: string) => void;
  /**
   * Option where legend will be visible or now
   * @default false
   */
  showLegend?: boolean;
  countChartOptions?: {
    hideValue?: boolean;
    /**
     * If you are passing this then subInfo will be ignored which is being passed from builder
     */
    subInfoBadgeRange?: SubInfoRange[];
    /**
     * If subInfoBadgeRange is not passed then this will be used
     */
    badgeType?: StatusBadgeType;
    showComparison?: boolean;
  };
  timeseriesChartOptions?: TimeSeriesChartOptions;
  gridViewDataOptions?: GridViewDataOptions;
  /**
   * Tooltip option beside the title
   */
  titleInfo?: string;
  /**
   * Tooltip option beside the title
   */
  yAxisTitle?: string;
  secondYAxisTitle?: string;
  multipleYAxis?: boolean;
  types?: {
    title: string;
    type: ChartTypeOptions;
    /**
     * @default false
     */
    onSecondaryAxis?: boolean;
  }[];
  stacked?: boolean;
  titleChild?: React.ReactNode;
}
export interface SubInfoRange {
  min: number;
  max: number;
  badgeType: StatusBadgeType;
  subInfo?: string;
}
export interface TimeSeriesChartOptions {
  bands?: TimeseriesBand[];
}
export interface GridViewDataOptions {
  useTotalCount?: boolean;
}
export interface RangeParams {
  min: number;
  max: number;
}
export interface TimeseriesBand {
  range: RangeParams;
  color: string;
}
export interface RequestParam {
  key: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}
type SubscribeType = "metric" | "log" | "trace";

export interface SubscribeRequest {
  type: SubscribeType;
  event: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter_request?: Record<string, any>;
  requests?: SubscrieDataRequest[];
  log_requests?: {
    columns: string[];
    filters?: Record<string, SubscribeFilter>;
  };
  trace_requests?: {
    columns: string[];
    filters?: Record<string, SubscribeFilter>;
  };
  session_id?: string;
}

export type FilterType = "column" | "attribute";

export interface SubscribeFilter {
  // type: FilterType;
  attribute_name: string;
  value: string | string[];
  operator?: FilterOp;
}
export interface SubscrieDataRequest {
  uniqueId: string;
  resource: string;
  columns: ColumnTypes[];
  resourceAttributes?: string[];
  metricAttributes?: Record<string, string[]>;
  groupBy: string[];
  widgetAppId: number;
  filters?: SubscribeFilter[];
}
export interface SocketResponse {
  event: string;
  metricsData: BuilderSocketResponse;
}
export interface ChartGroup {
  metrics: Record<string, ChartPoint[]>;
  resourceAttributes: Record<string, string>;
}
export type BuilderSocketResponse = Record<
  string,
  {
    chartGroups: Record<string, ChartGroup>;
  }
>;
export interface SocketChartPoint {
  value: number;
  timestamp: number;
}
export interface BuilderRenderParams {
  legends: string[];
}
export type BuilderRenderedViews = Record<number, BuilderRenderParams>;
export interface RequestPayload {
  params: {
    key: string;
    value: string;
  }[];
  report_id?: number;
  display_scope?: string;
  resource_type?: string;
  query?: Query;
}
/**
 * Deprecated: Use direct response entity from API
 */
export interface FilterOption {
  value: string;
  options: string[];
  label: string;
  type: MetricTypeEnum;
}
export const ORDER_BY_METRICS = "ORDER_BY_METRICS";
export const SELECT_DATA_BY = "SELECT_DATA_BY";
export const SELECT_LIMIT = "SELECT_LIMIT";
export const ATTRIBUTE_FILTER = "ATTRIBUTE_FILTER";
export const GLOBAL_ATTRIBUTE_FILTER = "GLOBAL_ATTRIBUTE_FILTER";
export const TIME_RANGE = "TIME_RANGE";
export const SELECT_METRICS_FORMULA = "SELECT_METRICS_FORMULA";

export const TimeseriesChartType = "time_series_chart"; // 1
export const BarChartType = "bar_chart"; // 2
export const PieChartType = "pie_chart"; // 3
export const ScatterPlotChartType = "scatter_plot"; // 4
export const TableChartType = "data_table"; // 5
// export const QueryCountChartType = "query_count"; // 6
export const CountChartType = "count_chart"; // 7
export const TreeChartType = "tree_chart"; // 8
export const TopListChartType = "top_list_chart"; // 9
export const HeatMapChartType = "heatmap_chart"; // 10
export const HexagonChartType = "hexagon_chart"; // 11
export const QueryValueChartType = "query_value"; // 12
export const AreaChartType = "area_chart"; // 13
export const ComboChartType = "combo_chart"; // 13
export const InvalidChartType = "invalid"; // 13
export const StackedBarChartType = "stacked_bar_chart"; // 15

export enum ChartType {
  TimeseriesChart = 1,
  BarChart,
  PieChart,
  ScatterPlotChart,
  TableChart,
  /**
   * @deprecated Use CountChart
   */
  QueryCountChartType,
  CountChart,
  TreeChart,
  TopListChart,
  HeatMapChart,
  HexagonChart,
  QueryValueChart,
  AreaChart,
  ComboChart,
  StackedBarChart,
  Invalid,
}
export const mapAppKey = (chartType: ChartType): string => {
  if (chartType === ChartType.TimeseriesChart) {
    return TimeseriesChartType;
  } else if (chartType === ChartType.BarChart) {
    return BarChartType;
  } else if (chartType === ChartType.PieChart) {
    return PieChartType;
  } else if (chartType === ChartType.ScatterPlotChart) {
    return ScatterPlotChartType;
  } else if (chartType === ChartType.TableChart) {
    return TableChartType;
  } else if (chartType === ChartType.CountChart) {
    return CountChartType;
  } else if (chartType === ChartType.TreeChart) {
    return TreeChartType;
  } else if (chartType === ChartType.TopListChart) {
    return TopListChartType;
  } else if (chartType === ChartType.HeatMapChart) {
    return HeatMapChartType;
  } else if (chartType === ChartType.HexagonChart) {
    return HexagonChartType;
  } else if (chartType === ChartType.QueryValueChart) {
    return QueryValueChartType;
  } else if (chartType === ChartType.AreaChart) {
    return AreaChartType;
  } else if (chartType === ChartType.ComboChart) {
    return AreaChartType;
  } else if (chartType === ChartType.StackedBarChart) {
    return StackedBarChartType;
  }
  return InvalidChartType;
};
export const getChartType = (appKey: string): ChartType => {
  if (appKey === TimeseriesChartType) {
    return ChartType.TimeseriesChart;
  } else if (appKey === BarChartType) {
    return ChartType.BarChart;
  } else if (appKey === PieChartType) {
    return ChartType.PieChart;
  } else if (appKey === ScatterPlotChartType) {
    return ChartType.ScatterPlotChart;
  } else if (appKey === TableChartType) {
    return ChartType.TableChart;
  } else if (appKey === CountChartType) {
    return ChartType.CountChart;
  } else if (appKey === TreeChartType) {
    return ChartType.TreeChart;
  } else if (appKey === TopListChartType) {
    return ChartType.TopListChart;
  } else if (appKey === HeatMapChartType) {
    return ChartType.HeatMapChart;
  } else if (appKey === HexagonChartType) {
    return ChartType.HexagonChart;
  } else if (appKey === QueryValueChartType) {
    return ChartType.QueryValueChart;
  } else if (appKey === AreaChartType) {
    return ChartType.AreaChart;
  } else if (appKey === StackedBarChartType) {
    return ChartType.StackedBarChart;
  }
  return ChartType.Invalid;
};
export const IsTimeSeriesChart = (chartType: string) => {
  return chartType === TimeseriesChartType;
};
export const IsAreaChart = (chartType: string) => {
  return chartType === AreaChartType;
};
export const IsBarChart = (chartType: string) => {
  return chartType === BarChartType;
};
export const IsPieChart = (chartType: string) => {
  return chartType === PieChartType;
};
export const IsScatterPlotChart = (chartType: string) => {
  return chartType === ScatterPlotChartType;
};
export const IsTableChart = (chartType: string) => {
  return chartType === TableChartType;
};
/**
 * @deprecated Use IsQueryValueChart
 */
export const IsCountChart = (chartType: string) => {
  return chartType === CountChartType;
};
export const IsTreeChart = (chartType: string) => {
  return chartType === TreeChartType;
};
export const IsTopListChart = (chartType: string) => {
  return chartType === TopListChartType;
};
export const IsHeatMapChart = (chartType: string) => {
  return chartType === HeatMapChartType;
};
export const IsHexagonChart = (chartType: string) => {
  return chartType === HexagonChartType;
};
export const IsQueryValueChart = (chartType: string) => {
  return chartType === QueryValueChartType;
};
export const IsStackedBarChart = (chartType: string) => {
  return chartType === StackedBarChartType;
};
/**
 * Buider supports 3 types
 * 1. Timeseries, 2. Table, 3. QueryValue
 * @param chartType
 * @returns
 */
export const getWidgetType = (chartType: ChartType): AppKeyType => {
  const c = mapAppKey(chartType);
  if (
    IsTimeSeriesChart(c) ||
    IsBarChart(c) ||
    IsHeatMapChart(c) ||
    IsStackedBarChart(c)
  ) {
    return TimeseriesType;
  } else if (IsQueryValueChart(c)) {
    return QueryValueType;
  } else {
    return ListType;
  }
};
export interface AvailableMetricsResponse {
  metrics: AvailableResource[];
  settings: Record<string, string>;
}
export interface AvailableResource {
  resource: string;
  table: DataTypeOptions;
  timeseries: DataTypeOptions;
  queryValue: DataTypeOptions;
}
export type AvailableFilters = Record<string, AttrValues>;

export interface DataTypeOptions {
  metrics: MetricItem[];
  group_by: GroupByItem[];
  filters?: AvailableFilters;
}
export interface GroupByItem {
  name: string;
  type: MetricTypeEnum;
  label: string;
  resource?: string;
}
export interface MetricItem {
  name: string;
  attributes?: AvailableFilters;
  type: MetricTypeEnum;
  label: string;
  config?: RenderTpl;
  resource: string;
}

export interface KpiAttrValue {
  value: string;
  count: number;
}
export type SidebarFilterValue = KpiAttrValue | string;
export type SidebarFilterValues = SidebarFilterValue[];
export interface AttrValues {
  count: number;
  type: MetricTypeEnum;
  values?: string[];
  sidebarFilterValues?: KpiAttrValue[];
  label?: string;
  name?: string;
  resource?: string;
}

export interface AvailableMetrics {
  inflight: boolean;
  availableMetrics: AvailableMetricsResponse;
  integratedMetrics: {
    aws: {
      namespaces: string[];
      inflight: boolean;
    };
  };
}
export const ListType = "list";
export const TimeseriesType = "timeseries";
export const QueryValueType = "queryValue";
export type AppKeyType = "list" | "timeseries" | "queryValue";
export const metricToLogMapping: Record<string, string> = {
  "container.name": "container_name",
  "k8s.pod.name": "pod_name",
  "k8s.namespace.name": "namespace",
};
export const logToMetricMapping: Record<string, string> = {
  container_name: "container.name",
  pod_name: "k8s.pod.name",
  namespace: "k8s.namespace.name",
};

export interface WidgetScope {
  id: number;
  builder_id: number;
  report_id: number;
  order_id: number;
  display_scope: string;
  meta_data: {
    RawMessage: {
      layouts: LayoutItem;
    };
    Valid: boolean;
  };
  created_at: string;
  updated_at: string;
  project_id: number;
}

export interface Widget {
  id: number;
  account_id: number;
  user_id: number;
  widget_app_id: number;
  key: string;
  status: string;
  visibility: "public" | "private";
  config: {
    RawMessage: Query;
    Valid: boolean;
  };
  meta_data: {
    RawMessage: builderMetaData;
    Valid: boolean;
  };
  created_at: string;
  updated_at: string;
  dataset_id: number;
  label: string;
  project_id: number;
  scope: WidgetScope;
}
export const getIntValue = (value: string | number): number => {
  if (typeof value === "string" && !isNaN(parseInt(value))) {
    return parseInt(value);
  } else if (typeof value === "number") {
    return value;
  }
  return 0;
};
export interface DashboardWidget {
  config: Query[];
  label: string;
  layout: LayoutItem;
  widgetAppId: number;
  key: string;
  builderMetaData?: builderMetaData;
}

export interface ConvertedDashboardStats {
  widget?: string;
  type?: string;
  status?: string;
  details?: string[] | string;
}

export interface DashboardCfg {
  widgets: DashboardWidget[];
  builderViewOptions: BuilderViewOptions;
}

export interface DashboardSource {
  label: string;
  value: string;
  widgets: DashboardWidget[];
}

export enum KpiType {
  KpiTypeMetric = 1,
  KpiTypeLog,
  KpiTypeTrace,
  KpiTypeNone,
}
export const KpiTypeOptions = [
  { title: "Metrics", value: KpiType.KpiTypeMetric },
  { title: "Log", value: KpiType.KpiTypeLog },
  { title: "Trace", value: KpiType.KpiTypeTrace },
];
export const getKpiName = (kpiType: KpiType): string => {
  switch (kpiType) {
    case KpiType.KpiTypeLog:
      return "log";
    case KpiType.KpiTypeTrace:
      return "trace";
    case KpiType.KpiTypeMetric:
      return "metric";
    default:
      return "";
  }
};
export const toKpiType = (resourceType: string): KpiType => {
  if (resourceType.startsWith("log.") || resourceType.startsWith("log")) {
    return KpiType.KpiTypeLog;
  } else if (
    resourceType.startsWith("trace.") ||
    resourceType.startsWith("trace")
  ) {
    return KpiType.KpiTypeTrace;
  }
  return KpiType.KpiTypeMetric;
};
export interface OnDropDownChange {
  value: string;
  options: { label: string; value: string }[];
  type: string;
  index: number;
}
export enum HostStatus {
  UP = "UP",
  DOWN = "DOWN",
  PARTIALLY_UP = "PARTIALLY_UP",
}
export interface ResourceViewOptions {
  resourceType: string;
  columns: string[];
  name: string;
  columnNames?: Record<string, string>;
  with?: WithExpr[];
}
export interface ResourceViewProps {
  nestedProps?: BuilderNestedProps;
  // As data can be dynamic and used in multilpe places. Will define generic type in future.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listOnClick?: (a0: any) => void;
  refreshData?: () => void;
  onSortingChange?: (
    sortingColumnName: string,
    sortingType: SortDirection
  ) => void;
  resource: ResourceViewOptions;
  requestData: (caller: string) => void;
  boxTitle?: string;
  subInfo?: string;
  columnsSelection?: CustomizedFilterOption[];
  onColumnsChange?: (selectedColumns: string[]) => void;
  onDefaultLabelClick?: () => void;
  titleChild?: React.ReactNode;
  onPagination?: (offset: number) => void;
  pagination?: Pagination;
  showExpandableTitle?: boolean;
  expandableTitleOnClick?: (expanded?: boolean) => void;
}
