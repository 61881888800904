import * as React from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";

import NotFound from "views/placeholder/NotFound";
import {useNavigate} from "react-router";
import InitLoader from "views/placeholder/init-loader";
import {RoutesPatternsProps} from "core/utils/route_schema";
import {getRedirectURI} from "core/utils";

const AuthRoutesHelper = React.memo(function (props: RoutesPatternsProps) {
    const location = useLocation()

    const loginNav = location.pathname !==  "/auth/login";
    const navigate = useNavigate()

    let redirect_uri = ""
    if (loginNav) {
        let _path = getRedirectURI()
        if (_path !== "/")
            redirect_uri = `?redirect_uri=${encodeURIComponent(_path)}`
    }

    return <React.Suspense fallback={<InitLoader/>}>
        <Routes>
            {props.routes.map( module => {
                return module.items.map(v => {
                    return v.pattern.map((path, key) => {
                        const ElementComponent = v.component
                        const url = (v.slug ? v.slug : module.slug) + path
                        return <Route key={key} path={url} element={<React.Suspense fallback={
                            <InitLoader/>
                        }>
                            <ElementComponent path={url} navigate={navigate}/>
                        </React.Suspense>}/>
                    })
                })
            })}

            {!loginNav && <>
                <Route path={"/404"} element={<NotFound/>}/>
                <Route path="*" element={<Navigate to="/404"/>}/>
            </> }
            {loginNav && <Route path="*" element={<Navigate to={`/auth/login${redirect_uri}`}/>}/>}

        </Routes>

    </React.Suspense>
})


export default AuthRoutesHelper