import { Message } from "core/components/v2/enums";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import InstallationCard from "../../../components/card";
import Command from "../../../components/code-block/command";
import MwMessage from "../../../components/message";

import { getTenantUrl } from "core/application/utils";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import StepItem from "../../../components/steps/step-item";
// import agentRelatedSteps from "../../common/apm-agent-connect";

interface DatadogECSProps {
  serverless: string;
}

const DatadogECS: React.FC<DatadogECSProps> = ({ serverless }) => {
  const [appEnv, setAppEnv] = useState<number>(1);
  const [appBehaviour, setAppBehaviour] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };
  const sendEvent = useMixPanel();
  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const capturePathWithoutHttp = url;

  const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];

  interface StepFormat {
    title: string;
    content: React.ReactNode;
  }

  const datadogCardItems = [
    {
      items: [
        {
          label: "Datadog Agent Version",
          value: "6.7.0 or above",
        },
      ],
    },
  ];

  const steps: StepFormat[] = [
    {
      title: "Prerequisites",
      content: (
        <>
          <InstallationCard items={datadogCardItems} />
          <br />
          <br />
        </>
      ),
    },
    {
      title: "Send APM Traces",
      content: (
        <>
          Add the following JSON script to your ECS task definition. The below
          environment variables are required for{" "}
          <a
            href="https://docs.datadoghq.com/agent/configuration/dual-shipping/#apm"
            target="_blank"
            rel="noreferrer"
          >
            dual shipping APM traces{" "}
          </a>
          in the Datadog Agent.
          <br />
          <br />
          <MwMessage
            type={Message.Warning}
            message={
              <div>
                The <b>DD_APM_ENABLED</b> and <b>DD_APM_ADDITION_ENDPOINTS</b>{" "}
                environment variables are in addition to existing environment
                variables (e.g. <b>DD_API_KEY</b>, <b>DD_SITE</b>, etc.) that
                may already be defined in your Datadog Agent's task definition..
              </div>
            }
          />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Datadog",
                command_type: "Send APM Traces",
              });
            }}
            command={`"environment": [
   ...
   {
     "name": "DD_APM_ENABLED",
     "value": "true"
   },
   {
     "name": "DD_APM_ADDITIONAL_ENDPOINTS",
     "value": "{\"https://${capturePathWithoutHttp}\": [\"${context.user?.account?.agent_config?.token}\"]}"
   },
 ]

`}
            className="margin-1"
          />
        </>
      ),
    },
    {
      title: "Send Logs",
      content: (
        <>
          Add the following JSON script to your ECS task definition. The below
          environment variables are required for{" "}
          <a
            href="https://docs.datadoghq.com/agent/configuration/dual-shipping/#logs"
            target="_blank"
            rel="noreferrer"
          >
            dual shipping Logs
          </a>{" "}
          in the Datadog Agent.
          <br />
          <br />
          <MwMessage
            type={Message.Warning}
            message={
              <div>
                The four environment variables mentioned below are in addition
                to existing environment variables (e.g. <b>DD_API_KEY</b>,{" "}
                <b>DD_SITE</b>, etc.) that may already be defined in your
                Datadog Agent's task definition.
              </div>
            }
          />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Datadog",
                command_type: "Send Logs",
              });
            }}
            command={`"environment": [
  {
    "name": "DD_LOGS_CONFIG_USE_HTTP",
    "value": "true"
  },
  {
    "name": "DD_LOGS_ENABLED",
    "value": "true"
  },
  {
    "name": "DD_LOGS_CONFIG_CONTAINER_COLLECT_ALL",
    "value": "true"
  },
  {
    "name": "DD_LOGS_CONFIG_ADDITIONAL_ENDPOINTS",
    "value": "[{\"api_key\": \"${context.user?.account?.agent_config?.token}\", \"Host\": \"${capturePathWithoutHttp}\", \"Port\": 443, \"is_reliable\": true}]"
  }
]`}
            className="margin-1"
          />
        </>
      ),
    },
    {
      title: "Update Agent Service",
      content: (
        <>
          Once you have updated the ECS task definition for the Datadog Agent,
          update the relevant Datadog Agent Service to redeploy the agent with
          your new configuration. APM traces and logs will start flowing into
          your Middleware account.
        </>
      ),
    },
  ];

  let indexOffset = 0;
  if (serverless != "true") {
    indexOffset = 2;
  }

  return (
    <>
      Middleware can ingest Logs and APM Traces from the Datadog Agent running
      on ECS clusters that use EC2 instances.
      <br />
      <br />
      {steps.map((step, index) => (
        <StepItem stepNumber={index + indexOffset + 1} title={step.title}>
          {step.content}
        </StepItem>
      ))}
    </>
  );
};
export default DatadogECS;
