import Tooltip from "core/components/tooltip";
import * as React from "react";
import CircularProgressbar from "../v2/progress-bar/circular-progressbar";
import { Align } from "../v2/enums";

export interface ProgressBarPercentProps {
  error?: boolean;
  tooltipId?: string;
  tooltipContent?: React.JSX.Element;
  progressValue: number;
  align?: Align;
}

export const ProgressBarWithPercent = function (
  props: ProgressBarPercentProps
) {
  const tipId = `tooltip_${Math.random().toString(36).substring(2)}_${props.tooltipId}`;
  let _val = props.progressValue;
  if (_val > 100) _val = 99.99;
  return (
    <React.Fragment>
      <div
        className={`progress-bar__with_percentage__wrapper ${props.align?.toString()}`}
        data-tip
        data-for={tipId}
      >
        <CircularProgressbar progress={_val} />
      </div>
      {props.tooltipId && (
        <Tooltip id={tipId} place="top" type="dark" effect="solid">
          {props.tooltipContent}
        </Tooltip>
      )}
    </React.Fragment>
  );
};
