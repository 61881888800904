import * as React from "react";
import Button from "core/components/button";
import { useNavigate } from "react-router-dom";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";

type GoToDashboardProps = {
    dashboardURL: string
}

export default function GoToDashboard(props: GoToDashboardProps) {
    const navigate = useNavigate()
    const context = React.useContext<ContextProps>(AppContext);

    const isDataInstalled = context?.user?.misc_details?.has_data || context?.user?.misc_details?.skip_to_dashboard
    const routeData = React.useContext(MwRouteContext);

    const navigateGoTo = () => {
        navigate(props.dashboardURL)
        routeData.params.query.delete('installation_integration')
        routeData.params.query.redirect()
    }

    return isDataInstalled
        ? (
            <div className="goto-dashboard-btn-container">
                <Button primary onClick={() => {
                    navigateGoTo()
                }}>Go to Dashboard</Button>
            </div>
        )
        : null
}