import * as React from 'react';
import classNames from "classnames";
interface Props {
    onClick?: (a0: any) => void,
    onTabChange?: (a0: any) => void,
    tabIndex?: number,
    isActive?: boolean,
    isDisabled?: boolean,
    title?: string,
    children?: any
    className?: any
}
const TabItems = React.memo(function (props: Props) {
    const handleTabClick = (event: any) => {
        event.preventDefault();
        const isDisabled = typeof props.isDisabled != "undefined" ? props.isDisabled : false;
        if (!isDisabled && typeof props.onClick === "function") {
            props.onClick(props.tabIndex);
        }
        if (!isDisabled && typeof props.onTabChange === "function") {
            props.onTabChange(props.tabIndex);
        }
    }
    return (
        <li className={classNames("tab", {
            "active-tab": props.isActive,
            "tab__is-disabled": props.isDisabled,
        })}>
            <a className={`tab-link  ${props.isActive ? 'active' : ''}`}
               onClick={handleTabClick}>
                {props.title ? props.title : props?.children}
            </a>
        </li>
    );
})
TabItems.displayName = "TabItems"
export default TabItems