import {
  ALERTS_RECEIVED,
  ALERTS_STATES_RECEIVED,
  ALERT_COUNT_SIDEBAR,
  ALERT_DETAIL_RECEIVED,
  ALERT_STATES_RECEIVED,
  APM_PROJECCT_NAME_RECEIVED,
  APM_ROUTE_LIST_RECEIVED,
  APM_SERVICE_NAME_RECEIVED,
  CLEAR_TRIGGERED_ALERTS,
  DATE_RANGE_CHART,
  EMAIL_RECEIVED,
  FILTER_BAR,
  FILTER_SECTIONS_RECEIVED,
  GET_ALERT_FILTER_DATA,
  GET_LOGS_ATTRIBUTE_DATA,
  GET_LOGS_TYPE_DATA,
  HOST_RECEIVED,
  INTEGRATIONS_LIST_RECEIVED,
  K8S_METRICS_LIST_RECEIVED,
  K8_NAMESPACE_LIST_RECEIVED,
  K8_NODE_LIST_RECEIVED,
  K8_POD_LIST_RECEIVED,
  MS_TEAMS_CHANNEL_RECEIVED,
  RESET_RULE_DETAILS,
  RULES_RECEIVED,
  RULE_DETAILS_RECEIVED,
  SEARCH_ALERT_FILTER_BAR,
  SET_ALERT_FILTER_BAR,
  SLACK_CHANNEL_RECEIVED,
  TAGS_RECEIVED,
} from "./constant";

interface IntegrationListData {
  status: boolean;
  message: string;
  count: number;
  page: number;
  integration_list: { integration: string }[];
  access_token: string;
}

export const setFilterBar = (filterBar: typeof FILTER_BAR) => {
  return {
    type: SET_ALERT_FILTER_BAR,
    filterBar,
  };
};

export const searchFilterBar = (arg: any = {}) => {
  return {
    type: SEARCH_ALERT_FILTER_BAR,
    ...arg,
  };
};

export const alertsReceived = (arg: any = {}) => {
  return {
    type: ALERTS_RECEIVED,
    ...arg,
  };
};

export const alertsStateReceived = (arg: any = {}) => {
  return {
    type: ALERTS_STATES_RECEIVED,
    ...arg,
  };
};

export const alertDetailReceived = (arg: any = {}) => {
  return {
    type: ALERT_DETAIL_RECEIVED,
    ...arg,
  };
};

export const rulesReceived = (arg: any = {}) => {
  return {
    type: RULES_RECEIVED,
    ...arg,
  };
};

export const dateRangeChartReceived = (arg: any = {}) => {
  return {
    type: DATE_RANGE_CHART,
    ...arg,
  };
};

export const tagsReceived = (arg: any = {}) => {
  return {
    type: TAGS_RECEIVED,
    ...arg,
  };
};

export const emailReceived = (arg: any = {}) => {
  return {
    type: EMAIL_RECEIVED,
    ...arg,
  };
};

export const alertCountSidebar = (arg: any = {}) => {
  return {
    type: ALERT_COUNT_SIDEBAR,
    ...arg,
  };
};

export const hostsReceived = (arg: any = {}) => {
  return {
    type: HOST_RECEIVED,
    ...arg,
  };
};

export const slackChannelReceived = (arg: any = {}) => {
  return {
    type: SLACK_CHANNEL_RECEIVED,
    ...arg,
  };
};

export const msTeamsChannelReceived = (arg: any = {}) => {
  return {
    type: MS_TEAMS_CHANNEL_RECEIVED,
    ...arg,
  };
};

export const apmProjectNameReceived = (arg: any = {}) => {
  return {
    type: APM_PROJECCT_NAME_RECEIVED,
    ...arg,
  };
};

export const apmServiceNameReceived = (arg: any = {}) => {
  return {
    type: APM_SERVICE_NAME_RECEIVED,
    ...arg,
  };
};

export const apmRouteListReceived = (arg: any = {}) => {
  return {
    type: APM_ROUTE_LIST_RECEIVED,
    ...arg,
  };
};

export const K8SMetricsListReceived = (arg: any = {}) => {
  return {
    type: K8S_METRICS_LIST_RECEIVED,
    ...arg,
  };
};

export const K8NodeListReceived = (arg: any = {}) => {
  return {
    type: K8_NODE_LIST_RECEIVED,
    ...arg,
  };
};

export const K8NamespaceListReceived = (arg: any = {}) => {
  return {
    type: K8_NAMESPACE_LIST_RECEIVED,
    ...arg,
  };
};

export const K8PodListReceived = (arg: any = {}) => {
  return {
    type: K8_POD_LIST_RECEIVED,
    ...arg,
  };
};

export const GetAlertFilterData = (arg: any = {}) => {
  return {
    type: GET_ALERT_FILTER_DATA,
    ...arg,
  };
};

export const GetLogsAttributeData = (arg: any = {}) => {
  return {
    type: GET_LOGS_ATTRIBUTE_DATA,
    ...arg,
  };
};

export const GetLogsTypeData = (arg: any = {}) => {
  return {
    type: GET_LOGS_TYPE_DATA,
    ...arg,
  };
};

export const ReceivedRulesList = (arg: any = {}) => {
  return {
    type: "RECEIVED_RULES_LIST",
    ...arg,
  };
};

export const TriggeredAlertsReceived = (arg: any = {}) => {
  return {
    type: "TRIGGERED_ALERTS_RECEIVED",
    ...arg,
  };
};

export const TriggeredInnerAlertsReceived = (arg: any = {}) => {
  return {
    type: "TRIGGERED_INNER_ALERTS_RECEIVED",
    ...arg,
  };
};
export const RuleDetailReceived = (arg: any = {}) => {
  return {
    type: RULE_DETAILS_RECEIVED,
    ...arg,
  };
};
export const ResetRuleDetails = () => {
  return {
    type: RESET_RULE_DETAILS,
  };
};
export const filterSectionsReceived = (arg: any = {}) => {
  return {
    type: FILTER_SECTIONS_RECEIVED,
    ...arg,
  };
};
export const alertStatesReceived = (arg: any = {}) => {
  return {
    type: ALERT_STATES_RECEIVED,
    ...arg,
  };
};
export const integrationsListReceived = (
  arg?: Partial<IntegrationListData>
) => {
  return {
    type: INTEGRATIONS_LIST_RECEIVED,
    ...arg,
  };
};

export const clearTriggeredAlerts = () => {
  return {
    type: CLEAR_TRIGGERED_ALERTS,
  };
};
