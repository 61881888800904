import { Message } from "core/components/v2/enums";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import InstallationCard from "../../../components/card";
import Command from "../../../components/code-block/command";
import MwMessage from "../../../components/message";

import { getTenantUrl } from "core/application/utils";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import StepItem from "../../../components/steps/step-item";
// import agentRelatedSteps from "../../common/apm-agent-connect";

interface DatadogLinuxProps {
  serverless: string;
}

const DatadogLinux: React.FC<DatadogLinuxProps> = ({ serverless }) => {
  const [appEnv, setAppEnv] = useState<number>(1);
  const [appBehaviour, setAppBehaviour] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const capturePathWithoutHttp = url;

    const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];

  interface StepFormat {
    title: string;
    content: React.ReactNode;
  }
  const sendEvent = useMixPanel();
  const datadogCardItems = [
    {
      items: [
        {
          label: "Datadog Agent Version",
          value: "6.7.0 or above",
        },
      ],
    },
  ];

  const steps: StepFormat[] = [
    {
      title: "Prerequisites",
      content: (
        <>
          <InstallationCard items={datadogCardItems} />
          <br />
          <br />
        </>
      ),
    },
    {
      title: "Send APM Traces",
      content: (
        <>
          Datadog's dual shipping configuration for APM traces requires
          <b> additional_endpoints</b> under the <b>apm_config</b> section. Add
          the following configuration in your <b>datadog.yaml</b> file <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Datadog",
                command_type: "Send APM Traces",
              });
            }}
            command={`apm_config:
  additional_endpoints:
    "https://${capturePathWithoutHttp}":
      - ${context.user?.account?.agent_config?.token}`}
            className="margin-1"
          />
          <MwMessage
            type={Message.Warning}
            message={
              <div>
                If you already have the <b>apm_config</b> section in your
                <b> datadog.yaml</b> file, please add an additional endpoint in
                the same section. Datadog agent does not support multiple
                <b> apm_config</b> sections. For <b>additional_endpoint</b>, do
                not forget to get <b>https://</b> in front of
                <b> ${capturePathWithoutHttp}</b>.
              </div>
            }
          />
          <br />
          <br />
        </>
      ),
    },
    {
      title: "Send Logs",
      content: (
        <>
          {/* <MwMessage
            type={Message.Warning}
            message="HTTPS requires Datadog Agent version >= 6.13"
          ></MwMessage> */}
          Datadog's{" "}
          <a
            href="https://docs.datadoghq.com/agent/configuration/dual-shipping/#logs"
            target="_blank"
            rel="noreferrer"
          >
            dual shipping configuration for Logs
          </a>{" "}
          requires additional_endpoints under the logs_config section.
          <br />
          <br />
          Add following configuration in datadog.yaml file
          <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Datadog",
                command_type: "Send Logs",
              });
            }}
            command={`logs_config:
  use_http: true
  additional_endpoints:
  - api_key: ${context.user?.account?.agent_config?.token}
    Host: "${capturePathWithoutHttp}"
    Port: 443
    is_reliable: true`}
            className="margin-0"
          />
          <MwMessage
            type={Message.Info}
            message="Unlike APM traces, you only need to provide hostname “<uid>.middleware.io” without the protocol scheme “https://” for the Host field in the logs_config section."
          ></MwMessage>
          You will need to restart the Datadog agent using the command below.
          <br />
          <br />
          <Command
            command={`sudo systemctl restart datadog-agent`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Datadog",
                command_type: "Send Logs",
              });
            }}
          />
        </>
      ),
    },
  ];

  let indexOffset = 0;
  if (serverless != "true") {
    indexOffset = 2;
  }

  return (
    <>
      Datadog's Linux agent stores configuration in YAML format. This file is
      located in /etc/datadog-agent/datadog.yaml by default. You will need to
      update this file to enable sending APM traces and logs to the Middleware
      platform.
      <br />
      <br />
      {steps.map((step, index) => (
        <StepItem stepNumber={index + indexOffset + 1} title={step.title}>
          {step.content}
        </StepItem>
      ))}
    </>
  );
};
export default DatadogLinux;
