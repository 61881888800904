import { createReducer } from "core/utils/create_reducer"
import { combineReducers } from "redux"
import { AdvisorRecDetailResponse, AdvisorRecommendationListReducer, AdvisorRecommendationResponse, AdvisorRecommendationSummaryReducer, AdvisorRuleListResponse, AdvisorRulesReducer, AdvisorSummaryResponse, ReferenceDetailsReducer } from "./advisor.entities"
import { RECEIVED_RULES, RECEIVED_SUMMARY, RECOMMENDATIONS_RECEIVED, RECOMMENDATION_DETAIL_RECEIVED, REQUESTS_RULES, REQUESTS_SUMMARY, REQUEST_RECOMMENDATIONS } from "./constant"
const defaultValue: AdvisorRulesReducer = {
    rules: [],
    inflight: true,
    error: "",
}
const advisorRules = createReducer(defaultValue, {
    [REQUESTS_RULES]: (state: AdvisorRulesReducer) => {
        state.inflight = true
        return { ...state }
    },
    [RECEIVED_RULES]: (mainState: AdvisorRulesReducer, opts: {
        type: string;
        response: AdvisorRuleListResponse;
    }) => {
        const state = Object.assign({}, mainState)
        state.rules = opts.response.rules;
        state.inflight = false
        return { ...state }
    },
})
const defaultRecommedationList: AdvisorRecommendationListReducer = {
    recommendations: {
        recommendations: [],
    },
    inflight: true,
}
const recommendationsList = createReducer(defaultRecommedationList, {
    [REQUEST_RECOMMENDATIONS]: (state: AdvisorRecommendationListReducer, opts: {
        type: string
    }) => {
        const c = Object.assign({}, state)
        c.inflight = true
        return { ...c }
    },
    [RECOMMENDATIONS_RECEIVED]: (state: AdvisorRecommendationListReducer, opts: {
        type: string,
        recommendations: AdvisorRecommendationResponse,
    }) => {
        const c = Object.assign({}, state);
        c.inflight = false;
        if (opts.recommendations) {
            c.recommendations = opts.recommendations
        }
        return { ...c }
    }
})
const recommendationDetails: ReferenceDetailsReducer = {};

const recommendationDetail = createReducer(recommendationDetails, {
    [RECOMMENDATION_DETAIL_RECEIVED]: (state: ReferenceDetailsReducer, opts: {
        type: string,
        data: AdvisorRecDetailResponse,
    }) => {
        if (opts.data.details) {
            state[opts.data.reference.referenceId] = opts.data.details;
            state[opts.data.reference.referenceId].inflight = false;
        }
        return { ...state };
    }
})
const defaultAdvisorSummary: AdvisorRecommendationSummaryReducer = {
    summary: {
        error: "",
        success: false,
        total_count: 0,
        total_count_by_impact: {
            high: 0,
            medium: 0,
            low: 0,
        },
        impacted_source_count: 0,
    },
    inflight: true,
}
const advisorSummary = createReducer(defaultAdvisorSummary, {
    [REQUESTS_SUMMARY]: (state: AdvisorRecommendationSummaryReducer) => {
        state.inflight = true
        return { ...state }
    },
    [RECEIVED_SUMMARY]: (mainState: AdvisorRecommendationSummaryReducer, opts: {
        type: string;
        response: AdvisorSummaryResponse;
    }) => {
        const state = Object.assign({}, mainState)
        state.inflight = false;
        if (opts.response) {
            state.summary = opts.response;
        }
        return { ...state }
    },
})
export default combineReducers({
    advisorRules,
    recommendationsList,
    recommendationDetail,
    advisorSummary,
})