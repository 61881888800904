import { getTenantUrl } from "core/application/utils";
import FormTextInput from "core/components/v2/form/form-text-input";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import Command from "../../components/code-block/command";
import InstallationSteps from "../../components/steps";
import StepItem from "../../components/steps/step-item";
interface LinuxIntegrationProps {
  packageType: string;
  os: string;
}

const FlatcarContainerIntegration: React.FC<LinuxIntegrationProps> = () => {
  const [activeServiceName, setActiveServiceName] = useState("");

  const context = useContext<ContextProps>(AppContext);

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;

  const flatcarCode = `variant: flatcar
version: 1.0.0
systemd:
 Units:
   - name: mw-agent.service
     enabled: true
     contents: |
       [Unit]
       Description=Middleware Agent
       After=docker.service
       Requires=docker.service
       [Service]
       TimeoutStartSec=0
       ExecStartPre=-/usr/bin/docker rm --force mw-agent
       ExecStart=/usr/bin/docker run --pid host --name mw-agent \\
                  -v /var/run/docker.sock:/var/run/docker.sock \\
                  -v /var/log:/var/log \\
                  -v /var/lib/docker/containers:/var/lib/docker/containers \\
                  -e MW_API_KEY="${context.user?.account?.agent_config?.token}" \\
                  -e MW_TARGET="${capturePath}" \\
                  --pull always --log-driver=journald --net host \\       
                  --privileged ghcr.io/middleware-labs/mw-host-agent:1.7.0
       ExecStop=/usr/bin/docker stop mw-agent
       Restart=always
       RestartSec=5s
       [Install]
       WantedBy=multi-user.target
`;
  const flatcarFileName = `butane ${activeServiceName == "" ? "your-configuration.bn" : activeServiceName + ".bn"} > ignition.ign`;
  const sendEvent = useMixPanel();
  return (
    <>
      <InstallationSteps>
        <StepItem stepNumber={1} title={"Prerequisites"}>
          <p>
            <a
              href={"https://coreos.github.io/butane/"}
              target={"_blank"}
              rel="noreferrer"
            >
              Butane
            </a>{" "}
            tool to translate human readable Butane configuration into machine
            readable{" "}
            <a
              href={"https://coreos.github.io/ignition/"}
              target={"_blank"}
              rel="noreferrer"
            >
              Ignition
            </a>{" "}
            configuration used by Flatcar Container Linux.
          </p>
        </StepItem>

        <StepItem stepNumber={2} title={"Butane Configuration"}>
          <p>
            You will have to create a systemd service in your Flatcar container
            Linux’s Butane configuration.
          </p>
          <p>
            You will have to create a <span>mw-agent.service</span> systemd unit
            in your existing Butane configuration.
          </p>
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Flatcar Linux",
                command_type: "Butane Configuration",
              });
            }}
            command={flatcarCode}
          />
        </StepItem>

        <StepItem stepNumber={3} title={"Ignition Configuration"}>
          <p>
            Convert your Butane configuration to Ignition configuration using
            the following command
          </p>
          <div className="apm-service-name-block">
            <FormTextInput
              id="go-service-text-input"
              // label={"Service Name"}
              className="linux-flatcar-name-input"
              placeholder={"Filename"}
              value={activeServiceName}
              onChange={(e: any) => setActiveServiceName(e)}
            />
          </div>
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Flatcar Linux",
                command_type: "Ignition Configuration",
              });
            }}
            command={flatcarFileName}
          />
          <p>
            You can now use the contents of ignition.ign to provision and launch
            your Flatcar container Linux.
          </p>
        </StepItem>
      </InstallationSteps>
    </>
  );
};
export default FlatcarContainerIntegration;
