import {INSTALLATION_OVERVIEW_RECEIVED, ONBOARD_DASHBOARD_OVERVIEW_RECEIVED,REFRESH_BTN_CLICKED} from "store/onboard-dashboard/constant";

export const dashboardOverviewReceived = (arg: any = {}) => {
    return {
        type: ONBOARD_DASHBOARD_OVERVIEW_RECEIVED,
        ...arg
    }
}
export const installationOverviewReceived = (arg: any = {}) => {
    return {
        type: INSTALLATION_OVERVIEW_RECEIVED,
        ...arg
    }
}
export const setRefreshBtnClicked = (isClicked: boolean) => {
    return {
        type: REFRESH_BTN_CLICKED,
        value: isClicked,
    }
}