import * as React from 'react';
import classNames from 'classnames';

export interface CardProps {
    className?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
    noPadding?: boolean;
    flexBox?: boolean;
    bordered?: boolean;
    borderRadius?: boolean;
    borderTop?: boolean;
    borderBottom?: boolean;
    borderLeft?: boolean;
    borderRight?: boolean;
    children?: any;
}

export default function Card(props: CardProps) {
    const {className, children, style} = props;

    const classes = classNames("component__card__wrapper card", className, {
        "is-border-radius": typeof props.borderRadius === "undefined" ? true : props?.borderRadius,
        "is-bordered": typeof props.bordered === "undefined" ? true : props?.bordered,
        "is-border-top": props.borderTop,
        "is-border-bottom": props.borderBottom,
        "is-border-left": props.borderLeft,
        "is-border-right": props.borderRight,
        "is-flex-box": props.flexBox,
        "no-padding": props.noPadding,
        "is-disabled": props.disabled,
    });
    return <div className={classes} style={style}>
        {children}
    </div>
}