import React from "react";

interface CircularProgressbarProps {
  progress: number;
  successMetric?: boolean;
  defaultColor?: string;
}

// Considering the size = 20px, strokeSize = 4px
// r = (size - strokeSize)/2,
// cx & cy = size/2
// circumference = 2*Pi*r

const CircularProgressbar = (props: CircularProgressbarProps) => {
  const { progress, successMetric, defaultColor } = props;
  const circumference = 2 * Math.PI * 8; // (20-4)/2 = 8
  const offset = circumference - (progress / 100) * circumference;

  let progressColor =
    defaultColor ||
    (progress < 25
      ? "var(--color-success)"
      : progress < 50
        ? "var(--color-secondary)"
        : progress < 75
          ? "var(--color-warning)"
          : "var(--color-error)");
  if (successMetric) {
    progressColor =
      defaultColor ||
      (progress < 25
        ? "var(--color-error)"
        : progress < 50
          ? "var(--color-warning)"
          : progress < 75
            ? "var(--color-secondary)"
            : "var(--color-success)");
  }

  return (
    <div className={"circular-progress-bar"}>
      <svg width={20} height={20} viewBox={`0 0 ${20} ${20}`}>
        <circle
          cx={10}
          cy={10}
          r={8}
          fill="var(--color-theme-bg)"
          stroke="var(--color-neutrals-300)"
          strokeWidth={4}
        />
        <circle
          cx={10}
          cy={10}
          r={8}
          fill="none"
          stroke={progressColor}
          strokeWidth={4}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          style={
            {
              "--animation-end": offset,
              "--animation-start": circumference,
            } as React.CSSProperties
          }
          strokeLinecap="round"
          transform={`rotate(-90 ${10} ${10})`}
          className="progress-ring"
        />
      </svg>
      <div
        className="progress-value"
        style={{ "--text-color": progressColor } as React.CSSProperties}
      >
        {progress}%
      </div>
    </div>
  );
};

export default CircularProgressbar;
