import { createSelector } from "reselect";
import { get } from "lodash-es";

export const getSyntheticsList = createSelector(
  (state: any) => get(state, ["synthetics", "list", "items"], []),
  (data: any) => {
    return data;
  }
);

export const getSyntheticsSingleInflight = createSelector(
  (state: any) => get(state, ["synthetics", "list", "singleInflight"], false),
  (d) => d
);
export const getSyntheticsUpTimeOverviewInflight = createSelector(
  (state: any) =>
    get(state, ["synthetics", "list", "upTimeOverviewInflight"], false),
  (d) => d
);
export const getSyntheticTestFireInflight = createSelector(
  (state: any) => get(state, ["synthetics", "list", "testFireInflight"], {}),
  (d) => d
);

export const getSyntheticsSingle = createSelector(
  (state: any, id: any) => {
    return {
      id,
      items: get(state, ["synthetics", "list", "items"], []),
    };
  },
  (data: any) => {
    const i = data.items.findIndex(
      (item: any) => item.id === parseInt(data.id)
    );
    return i >= 0 ? data.items[i] : null;
  }
);

export const getSyntheticsListInflight = createSelector(
  (state: any) => get(state, ["synthetics", "list", "inflight"], false),
  (d: any) => d
);

export const getSyntheticsMetrics = createSelector(
  (state: any) => get(state, ["synthetics", "metrics", "groups"], {}),
  (d: any) => d
);
export const getSyntheticsMetricsTestRuneById = createSelector(
  (state: any, id: any) =>
    get(state, ["synthetics", "metrics", "groups", id, "test_runes"], []),
  (d: any) => d
);

export const getSyntheticsMetricsInflight = createSelector(
  (state: any) => get(state, ["synthetics", "metrics", "inflight"], true),
  (d: any) => d
);

export const getSyntheticsChecks = createSelector(
  (state: any) => get(state, ["synthetics", "metrics", "checks"], {}),
  (d: any) => d
);

export const getSyntheticsChecksInflight = createSelector(
  (state: any) =>
    get(state, ["synthetics", "metrics", "check_inflight"], false),
  (d: any) => d
);

export const getSyntheticsUpdate = createSelector(
  (state: any) => get(state, ["synthetics", "metrics", "_update"], null),
  (d: any) => d
);
export const getCompletedStepIndex = createSelector(
  (state: number) => get(state, ["synthetics", "completedStepIndex"], 1),
  (data: number) => data
);
