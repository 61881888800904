import {
    DASHBOARD_PORTION_RECEIVED,
    RESET_DASHBOARD_INFO, TIMELINE_RECEIVED,
    SET_FILTERS_STATE_REQUEST,
    SET_INDICATOR_REQUEST,
    SET_INDICATOR_HITS_REQUEST,
    UNIFIED_DASHBOARD_RECEIVED,
    WS_UNIFIED_DASHBOARD_RECEIVED
} from "./constant";

export const setIndicatorRequest = (isLive: boolean) => {
    return {
        type: SET_INDICATOR_REQUEST,
        value: isLive,
    }
}

export const setIndicatorHitsRequest = (isLiveHits: boolean) => {
    return {
        type: SET_INDICATOR_HITS_REQUEST,
        value: isLiveHits,
    }
}

export const setFiltersStateRequest = (filtersState = {
    start_ts: 0,
    end_ts: 0,
    host: undefined,
    overview: undefined,
    cpu_usage: undefined,
    ram_usage: undefined,
    network_usage: undefined,
    disk_usage: undefined,
    log_ratio: undefined,
    log_list: undefined,
    trace_ratio: undefined,
    trace_list: undefined,
    selection: [],
}) => {
    return {
        type: SET_FILTERS_STATE_REQUEST,
        filters_state: filtersState,
    }
}

export const timelineReceived = (arg: any = {}) => {
    return {
        type: TIMELINE_RECEIVED,
        ...arg
    }
}


export const resetDashboardInfo = () => {
    return {
        type: RESET_DASHBOARD_INFO,
    }
}

export const unifiedDashboardReceived = (arg: any = {}) => {
    return {
        type: UNIFIED_DASHBOARD_RECEIVED,
        ...arg
    }
}

export const dashboardPortionReceived = (arg: any = {}) => {
    return {
        type: DASHBOARD_PORTION_RECEIVED,
        ...arg,
    }
}

export const wsUnifiedDashboardReceived = (arg: any = {}) => {
    return {
        type: WS_UNIFIED_DASHBOARD_RECEIVED,
        ...arg
    }
}