import moment, { unitOfTime } from "moment";
import { WidgetAppType, WidgetsApp } from "../../core/widgets.app";
import {
  ChartType,
  CustomWidget,
  SelectGroupBy,
  SocketResponse,
  WidgetDataType,
  getIntValue,
} from "../../entities/builder.entities";

export const POLL_INTERVAL = 30 * 1000; // 30s
export interface ReceivedDataType {
  viewName: string;
  widget: CustomWidget;
  widgetData?: WidgetDataType;
}

// divide chart data in specific granularity
// granularity is in seconds
const formatType: unitOfTime.Base = "seconds";
const granularity = {
  time: 30,
  format: formatType,
};
const onSocketResponseReceived = (
  originalData?: Record<string, CustomWidget>,
  data?: SocketResponse
): Record<string, CustomWidget> => {
  if (!originalData || !data) return {};
  const mainState = { ...originalData };
  const response = data.metricsData;
  Object.keys(response).forEach((builderIdFromResponse) => {
    let existingWidget: CustomWidget;
    const com = mainState[builderIdFromResponse];
    if (com) {
      existingWidget = com;
    } else {
      return mainState;
    }
    if (!existingWidget) {
      return mainState;
    }
    const responseTimeSeriesData = response[builderIdFromResponse];
    if (!responseTimeSeriesData) {
      return mainState;
    }
    Object.keys(responseTimeSeriesData.chartGroups).forEach(
      (groupNameReceivedFromReponse) => {
        if (!existingWidget) return;
        const existingWidgetData = existingWidget.widgetData;
        const widgetAppId = existingWidget.widgetAppId;
        const _App = Object.values(WidgetsApp).find(
          (app: WidgetAppType) => app.id === widgetAppId
        );
        if (!_App) return mainState;
        if (existingWidgetData) {
          if (
            ChartType.TimeseriesChart === _App.key ||
            ChartType.BarChart === _App.key ||
            ChartType.HeatMapChart === _App.key
          ) {
            const existingData = existingWidgetData.timeseriesChartData!;
            if (!existingData) return;
            const existingGroup =
              existingData.chartGroups[groupNameReceivedFromReponse];
            if (existingGroup) {
              const receivedMetricsForGroup =
                responseTimeSeriesData.chartGroups[
                  groupNameReceivedFromReponse
                ];
              if (!receivedMetricsForGroup) return;
              Object.keys(receivedMetricsForGroup.metrics).forEach(
                (metricName) => {
                  if (!existingGroup[metricName]) return;
                  let existingChartPoints = [...existingGroup[metricName]];
                  const newChartPoints =
                    receivedMetricsForGroup.metrics[metricName];
                  newChartPoints.forEach((newChartPoint) => {
                    let finalTs = newChartPoint.timestamp;
                    const timestamp = newChartPoint.timestamp; // the timestamp value you want to round
                    const seconds = moment(timestamp).seconds();
                    if (seconds <= granularity.time) {
                      finalTs = moment(timestamp)
                        .seconds(granularity.time)
                        .valueOf();
                    } else {
                      finalTs = moment(timestamp)
                        .seconds(0)
                        .add(1, "minute")
                        .valueOf();
                    }
                    const receiveDateValue = moment(finalTs);
                    const receivedDateInString = receiveDateValue.format(
                      "YYYY-MM-DD HH:mm:ss"
                    );
                    const findInExisting = existingChartPoints.findIndex(
                      (e) => {
                        const existingDate = moment(e.timestamp);
                        const existingDateInString = existingDate.format(
                          "YYYY-MM-DD HH:mm:ss"
                        );
                        return existingDateInString === receivedDateInString;
                      }
                    );
                    const numNewValue = getIntValue(newChartPoint.value);
                    if (findInExisting > -1) {
                      const existingLastRecord =
                        existingChartPoints[findInExisting];
                      const oldYAxis = existingLastRecord.lastValue;
                      const newValue = oldYAxis + numNewValue;
                      let count = existingLastRecord.count || 1;
                      count++;
                      let valueToUpdate = newValue / count;
                      if (widgetAppId === 9) {
                        valueToUpdate = numNewValue;
                      }
                      existingLastRecord.value = valueToUpdate;
                      existingLastRecord.count = count;
                      existingLastRecord.lastValue = newValue;
                      // existingLastRecord.valueTitle = "";
                      existingChartPoints[findInExisting] = existingLastRecord;
                    } else {
                      newChartPoint.count = 1;
                      newChartPoint.lastValue = numNewValue;
                      newChartPoint.timestamp = receiveDateValue.valueOf();
                      if (widgetAppId === 9) {
                        newChartPoint.config = existingChartPoints[0].config;
                        existingChartPoints = [newChartPoint];
                      } else {
                        existingChartPoints.push(newChartPoint);
                      }
                      Object.keys(existingData.chartGroups).forEach(
                        (groupName) => {
                          if (groupName === groupNameReceivedFromReponse)
                            return;
                          if (widgetAppId === 9) return;
                          const group = existingData.chartGroups[groupName];
                          Object.keys(group).forEach((metricName) => {
                            const chartPoints = group[metricName];
                            const v = getIntValue(
                              chartPoints[chartPoints.length - 1].value
                            );
                            chartPoints.push({
                              // ...newChartPoint,
                              lastValue: v,
                              value: 0,
                              count: 0,
                              // valueTitle: "",
                              timestamp: receiveDateValue.valueOf(),
                              config: chartPoints?.[0].config || {},
                            });
                            group[metricName] = chartPoints;
                          });
                          existingData.chartGroups[groupName] = group;
                        }
                      );
                      const lastTsinTimestamps = Object.keys(
                        existingData.timestamps
                      ).map((t) => parseInt(t));
                      const lastTs =
                        lastTsinTimestamps[lastTsinTimestamps.length - 1];
                      if (lastTs && lastTs < receiveDateValue.valueOf()) {
                        existingData.timestamps[receiveDateValue.valueOf()] =
                          true;
                      }
                    }
                    existingGroup[metricName] = existingChartPoints;
                  });
                }
              );
              existingData.chartGroups[groupNameReceivedFromReponse] =
                existingGroup;
              // console.log("existingData updated");
              existingData.timestamp = Date.now();
              existingWidgetData.timeseriesChartData = existingData;
              existingWidget.widgetData = existingWidgetData;
              // widgets[builderId] = existingWidget;
              mainState[builderIdFromResponse] = existingWidget;
            }
          } else if (
            _App.key === ChartType.TableChart ||
            ChartType.TopListChart === _App.key ||
            ChartType.PieChart === _App.key ||
            ChartType.ScatterPlotChart === _App.key ||
            ChartType.TreeChart === _App.key ||
            ChartType.HexagonChart === _App.key
          ) {
            const existingData = existingWidgetData.gridviewData!;
            if (!existingData) return;
            const rows = existingData.data;
            const receivedMetricsForGroup =
              responseTimeSeriesData.chartGroups[groupNameReceivedFromReponse];
            if (!receivedMetricsForGroup) return;
            let groupTypeBy: string[] = [];
            //TODO: Confirm if logic works for all cases
            const builderConfig = existingWidget?.builderConfig;
            if (Array.isArray(builderConfig)) {
              builderConfig.forEach((w) => {
                if (w.with) {
                  w.with.forEach((w) => {
                    if (
                      w.key === SelectGroupBy &&
                      Array.isArray(w.value) &&
                      w.value.length > 0
                    ) {
                      groupTypeBy.push(...w.value);
                    }
                  });
                }
              });
            } else {
              (builderConfig?.with || []).forEach((w) => {
                if (
                  w.key === "SELECT_DATA_BY" &&
                  Array.isArray(w.value) &&
                  w.value.length > 0
                ) {
                  groupTypeBy = w.value;
                }
              });
            }
            if (!groupTypeBy.length) {
              groupTypeBy = ["resource"];
            }
            const rowIndex = rows.findIndex((row) => {
              let value = "";
              groupTypeBy.forEach((type) => {
                if (row[type]) {
                  if (value === "") {
                    value = row[type] as string;
                  } else {
                    value += "-" + row[type];
                  }
                }
              });
              return value === groupNameReceivedFromReponse;
            });
            if (rowIndex === -1) return;
            Object.keys(receivedMetricsForGroup.metrics).forEach(
              (metricName) => {
                const existingRow = rows[rowIndex];
                const newChartPoints =
                  receivedMetricsForGroup.metrics[metricName];
                newChartPoints.forEach((newChartPoint) => {
                  if (!newChartPoint.value) return;
                  existingRow[metricName] = newChartPoint.value;
                });
              }
            );
            existingData.data = rows;
            existingData.timestamp = Date.now();
            existingWidgetData.gridviewData = existingData;
            existingWidget.widgetData = existingWidgetData;
            mainState[builderIdFromResponse] = existingWidget;
          }
        }
      }
    );
  });
  return mainState;
};
export { onSocketResponseReceived };
