import React, { useEffect, useRef, useState } from "react";
import { LegendType } from "./models";
import Tooltip from "../tooltip";

const CustomLegend = ({
  items,
  handleLegendItemClick,
}: {
  items: LegendType[];
  handleLegendItemClick: (x: string) => void;
}) => {
  return (
    <div
      className="legend-items"
      style={{
        // display: "flex",
        // alignItems: "center",
        overflow: "hidden",
        gap: "12px",
        marginTop: "12px",
        // flexWrap: "nowrap",
      }}
    >
      {items && items.length > 0 && (
        <ResponsiveFlexPopover
          items={items}
          handleLegendItemClick={handleLegendItemClick}
        />
      )}
    </div>
  );
};

export default CustomLegend;

const ResponsiveFlexPopover = ({
  items,
  handleLegendItemClick,
}: {
  items: LegendType[];
  handleLegendItemClick: (x: string) => void;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [numTagsPerRow, setNumTagsPerRow] = useState<number>(items.length);
  const [extraCount, setExtraCount] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef(null);
  const calculateNumTagsPerRow = () => {
    const container = containerRef.current;
    if (container) {
      const containerWidth = container.offsetWidth - 200;
      const children = Array.from(container.children);
      let totalWidth = 0;
      let numTags = 0;
      // Calculate the total width of the displayed tags
      for (const iterator of children) {
        const childWidth = iterator.getBoundingClientRect().width;
        totalWidth += childWidth;
        if (totalWidth <= containerWidth) {
          numTags++;
        } else {
          break;
        }
      }

      setNumTagsPerRow(numTags);
      setExtraCount(items.length - numTags);
    }
  };
  useEffect(() => {
    if (items.length > 1) {
      calculateNumTagsPerRow();
    }
  }, []);
  useEffect(() => {
    let resizeTimer: string | number | NodeJS.Timeout | undefined;
    const handleResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        calculateNumTagsPerRow();
      }, 100); // Adjust debounce delay as needed
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [items]);
  const displayedTags = /* expanded ? items :  */ items.slice(0, numTagsPerRow);
  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const renderTag = (tag: LegendType) => {
    return (
      <div
        className={"tag-box" + (!tag.visible ? " not-visible" : "")}
        onClick={() => handleLegendItemClick(tag.name)}
        key={tag.name}
      >
        <span
          style={{
            display: "inline-flex",
            background: tag.color ? tag.color : undefined,
            height: "8px",
            width: "8px",
            borderRadius: "2px",
          }}
        ></span>
        <span className="text-legend-label">{tag.name}</span>
      </div>
    );
  };

  return (
    <div className="responsive-max-tag-count" ref={containerRef}>
      {displayedTags.map((tag, index) => (
        <div className="tag" key={tag.name + index}>
          {renderTag(tag)}
        </div>
      ))}
      <div ref={buttonRef}>
        <Tooltip
          position="right"
          // isVisible={expanded}
          // onClose={handleToggle}
          // triggerEvent={triggerEnum.hover}
          content={
            <div
              className="popover_flex-col_tags"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "flex-start",
                gap: "4px",
              }}
            >
              {items
                .filter((it, index) => index >= numTagsPerRow)
                .map((tag) => renderTag(tag))}
            </div>
          }
        >
          <div
            className="toggle-btn"
            onClick={handleToggle}
            ref={buttonRef}
            style={{
              display: extraCount <= 0 ? "none" : "block",
            }}
          >
            {"+" + extraCount + " More"}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
