import CreateRequest from "core/xhr";
import moment from "moment/moment";
import {
  GetAlertFilterData,
  GetLogsAttributeData,
  GetLogsTypeData,
  K8NamespaceListReceived,
  K8NodeListReceived,
  K8PodListReceived,
  K8SMetricsListReceived,
  ReceivedRulesList,
  RuleDetailReceived,
  TriggeredAlertsReceived,
  TriggeredInnerAlertsReceived,
  alertCountSidebar,
  alertDetailReceived,
  alertStatesReceived,
  alertsReceived,
  alertsStateReceived,
  apmProjectNameReceived,
  apmRouteListReceived,
  apmServiceNameReceived,
  dateRangeChartReceived,
  emailReceived,
  filterSectionsReceived,
  hostsReceived,
  integrationsListReceived,
  msTeamsChannelReceived,
  rulesReceived,
  slackChannelReceived,
  tagsReceived,
} from "store/alert/actions";
import { receivedSyntheticsList } from "store/synthetics/actions";
import { showToast } from "store/toast-alerts/actions";

export const requestAlerts = (callback?: (res?: any) => void) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/alert",
      method: "get",
      data: {},
    })
      .then((res) => {
        res.data.items.sort(function (a: any, b: any) {
          return (
            new Date(b.last_triggred).getTime() -
            new Date(a.last_triggred).getTime()
          );
        });
        dispatch(alertsReceived(res.data));
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestDateTimeAlerts = (
  startTime: any,
  endTime: any,
  callback?: (res?: any) => void
) => {
  const body = {
    start_time: startTime,
    end_time: endTime,
  };
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/datetimefilter",
      method: "post",
      data: body,
    })
      .then((res) => {
        res?.data?.items?.sort(function (a: any, b: any) {
          return (
            new Date(b.last_triggred).getTime() -
            new Date(a.last_triggred).getTime()
          );
        });
        dispatch(alertsReceived(res.data));
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const StatusFilterAlert = (
  body: { key: string; value: string },
  cb?: (a2?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/alert",
      method: "get",
      data: {},
    })
      .then((res) => {
        res.data.items.sort(function (a: any, b: any) {
          return (
            new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
          );
        });
        dispatch(alertsReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestAlertsStates = (params: any = {}) => {
  const { start_ts, end_ts, severity, alert_type, ...restParam } = params;
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/alertstates",
      method: "GET",
      params: params,
    })
      .then((res) => {
        dispatch(alertsStateReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestRuleStates = (callback?: (res?: any) => void) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/rulestates",
      method: "get",
      data: {},
    })
      .then((res) => {
        dispatch(alertsStateReceived(res.data));
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const updateAlertInfo = (id: any, callback?: (res?: any) => void) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/alert/info/${id.id}`,
      method: "get",
      data: {},
    })
      .then((res) => {
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const fetchSingleAlert = async (id: any) => {
  try {
    const response = await CreateRequest({
      url: `/getsinglealert/${id}`,
      method: "get",
      data: {},
    });
    return response.data.items[0];
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const requestAlertInfo = (data: any) => {
  return async (dispatch: (arg0: any) => void) => {
    if (!data) {
      if (!data) {
        data = await fetchSingleAlert(data.id);
        if (!data) return;
      }
    }

    dispatch(alertDetailReceived({ info: [] }));
    return CreateRequest({
      url: `/alert/info/${data.id}/1/1000`,
      method: "get",
      data: {},
    })
      .then((res) => {
        res.data.info = res.data.info.info
          .sort((a: any, b: any) => {
            return (
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
            );
          })
          .filter(
            (obj: any) =>
              obj.thresholds_value !== "0" || obj.projectName == "Message"
          );
        res.data.alertData = data;
        dispatch(alertDetailReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const requestAlertInfoNew = (data: any, event: any, page: any) => {
  if (!data) {
    return null;
  }
  return (dispatch: (arg0: any) => void) => {
    dispatch(alertDetailReceived({ info: [], inflight: true }));
    return CreateRequest({
      url: `/alert/info/${data.id}/${page}/1000`,
      method: "get",
      data: {},
    })
      .then((res) => {
        res.data.info = res.data.info.sort((a: any, b: any) => {
          return (
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          );
        });
        dispatch(alertDetailReceived({ ...res.data, inflight: false }));
      })
      .catch((err) => {
        dispatch(alertDetailReceived({ inflight: true }));
        console.log("list: error", err);
      });
  };
};

export const getJiraStatus = (
  body: { task_number: string },
  cb?: (a2?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/task_management/get_jira_status/${body.task_number}`,
      method: "get",
      data: {},
    })
      .then((res) => {
        if (cb) {
          cb(res);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getChartData = (
  body: {
    create_builder: boolean;
    is_new_builder: boolean;
    display_scope: string;
    builder_id: number;
    widget_app_id: number;
    key: string;
    query: object;
    label: string;
    intervals: string;
    params: object;
    dataset_id: number;
    report: object;
    meta_data: object;
  },
  cb?: (a0: boolean, a1?: boolean, a2?: any) => void
) => {
  body = {
    create_builder: true,
    is_new_builder: true,
    display_scope: "infrastructure",
    builder_id: -1,
    widget_app_id: 5,
    key: "untitled_ogk79fk9l7",
    query: {
      columns: ["system.cpu.load_average.15m"],
      source: {
        name: "host",
        alias: "",
        dataset_id: 0,
        availableColumns: [],
      },
      group_by: [],
      order_by: [],
      limit: {
        n: 10,
      },
      top: 0,
      granularity: {
        column: "",
        filter_required: true,
        is_dttm: true,
      },
    },
    label: "Untitled",
    intervals: "15m",
    params: [
      {
        key: "cr_date",
        value: "1675918220792",
      },
      {
        key: "from_ts",
        value: "1675918220792",
      },
      {
        key: "to_ts",
        value: "1675919120792",
      },
    ],
    dataset_id: -1,
    report: {
      id: 12,
    },
    meta_data: {
      layouts: {
        h: 5,
        i: -1,
        w: 4,
        x: 4,
        y: 24,
      },
    },
  };

  return () => {
    if (cb) {
      cb(false, true);
    }
    return CreateRequest({
      url: "/builder/widget/data",
      method: "post",
      data: body,
    })
      .then((res) => {
        if (cb) {
          cb(true, false, res.data);
        }
      })
      .catch((err) => {
        if (cb) {
          cb(false, false, err);
        }
      });
  };
};

export const hostFilter = (
  body: {
    host_list: string | null;
    alert_type_list: string | null;
    filter_type: string;
    monitor_type_list: string | null;
    assignee_filter: string | null;
    start_time: number | null;
    end_time: number | null;
    created_by_type: string | null;
  },
  cb?: (a0: boolean, a1?: boolean, a2?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    if (cb) {
      cb(false, true);
    }
    return CreateRequest({
      url: "/alerthostfilter",
      method: "post",
      data: body,
    })
      .then((res) => {
        if (cb) {
          cb(true, false, res.data);
          res.data.items = res.data.items
            .sort((a: any, b: any) => {
              return (
                new Date(b.updated_at).getTime() -
                new Date(a.updated_at).getTime()
              );
            })
            .filter((obj: any) => obj.thresholds_value !== "0");

          if (body.filter_type == "list") {
            dispatch(alertsReceived(res.data));
          } else if (body.filter_type == "rules") {
            dispatch(rulesReceived(res.data));
          }
        }
      })
      .catch((err) => {
        if (cb) {
          cb(false, false, err);
        }
      });
  };
};

export const dateTimeFilter = (
  body: {
    start_time: number | null;
    end_time: number | null;
    current_page: string | "";
  },
  cb?: (a0: boolean, a1?: boolean, a2?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    if (cb) {
      cb(false, true);
    }
    return CreateRequest({
      url: "/datetimefilter",
      method: "post",
      data: body,
    })
      .then((res) => {
        if (cb) {
          cb(true, false, res.data);
          res.data.items = res.data.items
            .sort((a: any, b: any) => {
              return (
                new Date(b.updated_at).getTime() -
                new Date(a.updated_at).getTime()
              );
            })
            .filter((obj: any) => obj.thresholds_value !== "0");
          dispatch(alertsReceived(res.data));
        }
      })
      .catch((err) => {
        if (cb) {
          cb(false, false, err);
        }
      });
  };
};

export const requestRules = () => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/rule",
      method: "get",
      data: {},
    })
      .then((res) => {
        dispatch(rulesReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getAlertChartData = (callback?: (res: any) => void) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/alert/infochart",
      method: "get",
      data: {},
    })
      .then((res) => {
        dispatch(rulesReceived(res.data));
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getDateRangeChartData = (
  body: {
    start_time: number | null;
    end_time: number | null;
    current_page: string | "";
  },
  cb?: (a0: boolean, a1?: boolean, a2?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    if (cb) {
      cb(false, true);
    }
    return CreateRequest({
      url: "/datetimefilter",
      method: "post",
      data: body,
    })
      .then((res) => {
        if (cb) {
          cb(true, false, res.data);
          res.data.items = res.data.items
            .sort((a: any, b: any) => {
              return (
                new Date(b.updated_at).getTime() -
                new Date(a.updated_at).getTime()
              );
            })
            .filter((obj: any) => obj.thresholds_value !== "0");
          dispatch(dateRangeChartReceived(res.data));
        }
      })
      .catch((err) => {
        if (cb) {
          cb(false, false, err);
        }
      });
  };
};

export const ruleFilter = (data: any, tab: any) => {
  if (!data) {
    return null;
  }
  const isDefault = data.created_filter == "middleware" ? true : false;
  return (dispatch: (arg0: any) => void) => {
    dispatch(alertDetailReceived({ info: [] }));
    data.is_default = isDefault;
    return CreateRequest({
      // url: `/rulefilter/${data.filter_type ? data.filter_type : 'clear'}/${data.alert_type ? data.alert_type : 'clear'}/${isDefault}`,
      url: `/rulefilter`,
      method: "post",
      data: data,
    })
      .then((res) => {
        res.data.items = res?.data?.items.sort((a: any, b: any) => {
          return a.id - b.id;
        });
        dispatch(rulesReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const ruleDateTimeFilter = (
  body: {
    start_time: number | null;
    end_time: number | null;
    current_page: string | "";
  },
  cb?: (a0: boolean, a1?: boolean, a2?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    dispatch(alertDetailReceived({ info: [] }));
    return CreateRequest({
      url: `/ruledatetimefilter`,
      method: "post",
      data: body,
    })
      .then((res) => {
        res.data.items = res.data.items.sort((a: any, b: any) => {
          return a.id - b.id;
        });
        dispatch(rulesReceived(res.data));
      })
      .catch((err) => {
        if (cb) {
          cb(false, false, err);
        }
      });
  };
};

export const configuredRules = () => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/configuredrules",
      method: "get",
      data: {},
    })
      .then((res) => {
        res.data.items = res.data.items.sort((a: any, b: any) => {
          return a.id - b.id;
        });
        dispatch(rulesReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getTagList = (cb?: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/gettagslist",
      method: "get",
      data: {},
    })
      .then((res) => {
        const sortedTags = res.data.tags.sort((a: any, b: any) =>
          a.name.localeCompare(b.name)
        );
        const sortedData = {
          ...res.data,
          tags: sortedTags,
        };
        cb(sortedData);
        dispatch(tagsReceived(sortedData));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getEmailList = (cb?: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/getemaillist",
      method: "get",
      data: {},
    })
      .then((res) => {
        cb(res.data);
        dispatch(emailReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const alertCountForSidebar = (cb?: any) => {
  const storedTime = localStorage.getItem("lastVisitedTime")
    ? localStorage.getItem("lastVisitedTime")
    : Date.now().toString();
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/alertcountforsidebar/${storedTime}`,
      method: "get",
      data: {},
    })
      .then((res) => {
        cb(res.data);
        dispatch(alertCountSidebar(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getHostListForSidebar = (cb?: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/hostlistforfilter`,
      method: "get",
      data: {},
    })
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getAssigneeListForSidebar = (cb?: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/getassigneelistforfilter`,
      method: "get",
      data: {},
    })
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getCreatedByListForSidebar = (cb?: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/getcreateduserlist`,
      method: "get",
      data: {},
    })
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getHostList = (
  cb?: any,
  allHost?: boolean,
  startTime?: any,
  endTime?: any
) => {
  let configData: any = {
    builderConfig: {
      columns: ["host.id"],
      source: { name: "host", alias: "", dataset_id: 0 },
      group_by: [],
      order_by: [],
      limit: { n: 10 },
      top: 0,
      granularity: { column: "", filter_required: true, is_dttm: true },
      with: [
        {
          key: "ATTRIBUTE_FILTER",
          value: {
            "is.k8s.node": [""],
          },
          is_arg: true,
        },
      ],
    },
    label: "Untitled",
    params: [
      {
        key: "from_ts",
        value: startTime,
      },
      {
        key: "to_ts",
        value: endTime,
      },
    ],
    builderId: -1,
    scopeId: -1,
    widgetAppId: 5,
    builderViewOptions: {
      displayScope: "infrastructure_k8s_cluster_dashboard",
      resource: {
        resourceType: "",
        columns: [],
        name: "infrastructure_k8s_cluster_dashboard",
        widgetAppId: 5,
      },
    },
  };
  if (allHost) {
    configData = {
      builderConfig: {
        columns: ["host.id"],
        source: { name: "host", alias: "", dataset_id: 0 },
        group_by: [],
        order_by: [],
        limit: { n: 10 },
        top: 0,
        granularity: { column: "", filter_required: true, is_dttm: true },
      },
      label: "Untitled",
      params: [
        {
          key: "from_ts",
          value: startTime,
        },
        {
          key: "to_ts",
          value: endTime,
        },
      ],
      builderId: -1,
      scopeId: -1,
      widgetAppId: 5,
      builderViewOptions: {
        displayScope: "infrastructure_k8s_cluster_dashboard",
        resource: {
          resourceType: "",
          columns: [],
          name: "infrastructure_k8s_cluster_dashboard",
          widgetAppId: 5,
        },
      },
    };
  }
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/builder/widget/data",
      method: "post",
      data: configData,
    })
      .then((res) => {
        const _items: any = [];
        if (
          res?.data?.chart_data?.data &&
          Array.isArray(res.data.chart_data.data)
        ) {
          for (const item of res.data.chart_data.data) {
            _items.push({
              host_id: item["host.id"],
            });
          }
        }
        dispatch(hostsReceived({ items: _items, status: res?.status === 200 }));
        // dispatch(hostsReceived(_items))
        if (cb) {
          cb({
            items: _items,
          });
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getk8sMetricsList = () => {
  return (dispatch: (arg0: any) => void) => {
    dispatch(K8SMetricsListReceived(["a", "b", "c"]));
  };
};

export const getPostgreSQLList = (cb?: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/builder/metrics?visibility=public&from_ts=1688383885893&to_ts=1688387485893",
      method: "get",
      data: {},
    })
      .then((res) => {
        cb(res.data);
        // dispatch(postgresqlReceived(res.data))
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getChannelList = (cb?: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/getslackchannel",
      method: "get",
      data: {},
    })
      .then((res) => {
        cb(res.data);
        dispatch(slackChannelReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getIntegrationList = (cb?: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/task_management/getintegrationlist",
      method: "get",
      data: {},
    })
      .then((res) => {
        dispatch(integrationsListReceived(res.data));
        cb?.(res.data);
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const removeSlackAuth = (cb?: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/removeslackauth",
      method: "get",
      data: {},
    })
      .then((res) => {
        cb(res.data);
        dispatch(slackChannelReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getMSTeamsChannelList = (cb?: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/getmsteamschannel",
      method: "get",
      data: {},
    })
      .then((res) => {
        cb(res.data);
        dispatch(msTeamsChannelReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getJiraDetailsCheck = (cb?: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/task_management/getjiradetails",
      method: "get",
      data: {},
    })
      .then((res) => {
        cb(res.data);
        // dispatch(msTeamsChannelReceived(res.data))
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const unsubscribeWs = (event: string) => {
  return () => {
    CreateRequest({
      url: `/alert/unsubscribe?event=${event}`,
      method: "get",
      data: {},
    });
  };
};

export const createRule = (
  body: {
    alert_name: string;
    monitor_type: string;
    message: string;
    status: string;
    host: string;
    alert_type: string;
    thresholds: string;
    thresholds_value: string;
    created_by: string;
    tags: string;
    notify_to: string;
    notify_interval: string;
    notification_volume: string;
    alert_threshold: string;
    alert_threshold_percentage: string;
    alert_threshold_condition: string;
    alert_threshold_minute: string;
    alert_network_condition: string;
    warning_threshold: string;
    warning_threshold_percentage: string;
    warning_threshold_condition: string;
    warning_threshold_minute: string;
    warning_network_condition: string;
    email_body: string;
    alert_id: number;
    notification_medium: string;
    slack_channel_name: string;
    apm_project_name: string;
    apm_service_name: string;
    apm_route: string;
    apm_time_window: string;
    apm_condition_value: number | string;
    k8_node_name: string;
    k8_namespace_name: string;
    k8_pod_name: string;
    log_source: string;
    log_source_key: string;
    critical_threshold: string;
    critical_threshold_minute: string;
    critical_network_condition: string;
    critical_threshold_percentage: string;
    critical_threshold_condition: string;
    is_active: boolean;
    is_default: boolean;
    multiple_alert_threshold: object;
    database_metrics: string;
    current_status: string;
    log_condition_type: string;
    log_message_value: string;
    rum_application_list: string;
    rum_condition: string;
  },
  cb?: (a0: boolean, a1?: boolean, a2?: any) => void
) => {
  return () => {
    if (cb) {
      cb(false, true);
    }
    return CreateRequest({
      url: "/createalert",
      method: "post",
      data: body,
    })
      .then((res) => {
        if (cb) {
          cb(true, false, res.data);
        }
      })
      .catch((err) => {
        if (cb) {
          cb(false, false, err);
        }
      });
  };
};

export const updateRule = (
  body: {
    alert_name: string;
    monitor_type: string;
    message: string;
    status: string;
    host: string;
    alert_type: string;
    thresholds: string;
    thresholds_value: string;
    created_by: string;
    tags: string;
    notify_to: string;
    notify_interval: string;
    notification_volume: string;
    alert_threshold: string;
    alert_threshold_percentage: string;
    alert_threshold_condition: string;
    alert_threshold_minute: string;
    alert_network_condition: string;
    warning_threshold: string;
    warning_threshold_percentage: string;
    warning_threshold_condition: string;
    warning_threshold_minute: string;
    warning_network_condition: string;
    email_body: string;
    alert_id: number;
    notification_medium: string;
    slack_channel_name: string;
    apm_project_name: string;
    apm_service_name: string;
    apm_route: string;
    apm_time_window: string;
    apm_condition_value: number | string;
    k8_node_name: string;
    k8_namespace_name: string;
    k8_pod_name: string;
    log_source: string;
    log_source_key: string;
    critical_threshold: string;
    critical_threshold_minute: string;
    critical_network_condition: string;
    critical_threshold_percentage: string;
    critical_threshold_condition: string;
    is_active: boolean;
    is_default: boolean;
    multiple_alert_threshold: object;
    database_metrics: string;
    current_status: string;
    log_condition_type: string;
    log_message_value: string;
    rum_application_list: string;
    rum_condition: string;
  },
  cb?: (a0: boolean, a1?: boolean, a2?: any) => void
) => {
  return () => {
    if (cb) {
      cb(false, true);
    }
    return CreateRequest({
      url: "/updatealert",
      method: "post",
      data: body,
    })
      .then((res) => {
        if (cb) {
          cb(true, false, res.data);
        }
      })
      .catch((err) => {
        if (cb) {
          cb(false, false, err);
        }
      });
  };
};

export const updateAlertStatus = (
  body: { status: string; alert_id: number },
  cb?: (a0: boolean, a1?: boolean, a2?: any) => void
) => {
  return () => {
    return CreateRequest({
      url: "/updatealertstatus",
      method: "post",
      data: body,
    })
      .then((res) => {
        if (cb) {
          cb(res.data);
        }
      })
      .catch((err) => {
        if (cb) {
          cb(false, false, err);
        }
      });
  };
};

export const deleteRule = (
  id: any,
  callback?: (a0: boolean, a1: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    callback && callback(true, { inflight: true });
    return CreateRequest({
      url: `/rules/${id}`,
      method: "delete",
    })
      .then((res) => {
        dispatch(
          showToast(
            res?.status == 200 ? "success" : "error",
            res?.status == 200
              ? "Rule deleted successfully"
              : "Error while deleting rule",
            { duration: 3000 }
          )
        );
        if (res?.status) {
          dispatch(
            ReceivedRulesList({ actionType: "delete", id, inflight: false })
          );
        }
        callback && callback(true, { inflight: false, ...res.data });
      })
      .catch((err) => {
        callback && callback(false, { inflight: false });
      });
  };
};

export const updateStatusOfAlert = (
  body: { id: number; status: boolean },
  cb?: (a2?: any) => void
) => {
  return;
  return CreateRequest({
    url: "/alert/updatestatusofalert",
    method: "delete",
    data: body,
  })
    .then((res) => {
      if (cb) {
        cb(res.data);
      }
    })
    .catch((err) => {
      if (cb) {
        cb(err);
      }
    });
};

export const getProjectList = (cb?: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/getservicelistforalert",
      method: "get",
      data: {},
    })
      .then((res) => {
        cb(res.data);
        dispatch(apmProjectNameReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getServiceList = (
  body: { host_name: string; project_name: string },
  cb?: (a2?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/getservicelistforalert",
      method: "get",
      data: {},
    })
      .then((res) => {
        if (cb) {
          cb(res.data);
        }
        dispatch(apmServiceNameReceived(res.data));
      })
      .catch((err) => {
        if (cb) {
          cb(err);
        }
      });
  };
};

export const getRouteLists = (
  body: { service_name: string },
  cb?: (a2?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/getrouteslistforalert/`,
      method: "post",
      data: body,
    })
      .then((res) => {
        if (cb) {
          cb(res.data);
        }
        dispatch(apmRouteListReceived(res.data));
      })
      .catch((err) => {
        if (cb) {
          cb(err);
        }
      });
  };
};

export const getLogsSourceList = (cb?: any) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/logssourcelist",
      method: "get",
      data: {},
    })
      .then((res) => {
        cb(res.data);
        dispatch(K8NodeListReceived(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getLogsAttributeList = () => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/logattributelist",
      method: "get",
      data: {},
    })
      .then((res) => {
        dispatch(GetLogsAttributeData(res.data));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getLogsTypeList = (
  body: { attribute_name: string },
  cb?: (a2?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    const podNames: string[] = [];
    return CreateRequest({
      url: "/logstypeslist",
      method: "post",
      data: body,
    })
      .then((res) => {
        if (cb) {
          cb(res);
          dispatch(GetLogsTypeData(res));
        }
      })
      .catch((err) => {
        if (cb) {
          cb(err);
        }
      });
  };
};

const k8NodeListBuilder = {
  builderConfig: {
    columns: ["any(k8s.node.name)"],
    source: {
      name: "k8s.node",
      alias: "",
      dataset_id: 0,
    },
    group_by: [],
    order_by: [],
    limit: {
      n: 10,
    },
    top: 0,
    granularity: {
      column: "",
      filter_required: true,
      is_dttm: true,
      value: 30,
    },
  },
  label: "Untitled",
  builderId: -1,
  scopeId: -1,
  widgetAppId: 5,
  builderViewOptions: {
    displayScope: "infrastructure_k8s_cluster_dashboard",
    resource: {
      resourceType: "",
      columns: [],
      name: "infrastructure_k8s_cluster_dashboard",
      widgetAppId: 5,
    },
  },
};

export const getK8NodeList = (cb?: any) => {
  const nodeNames: string[] = [];
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      // url: "/getk8nodelist",
      // method: 'get',
      // data: {}
      url: "/builder/widget/data",
      method: "post",
      data: k8NodeListBuilder,
    })
      .then((res) => {
        const nodeObject = res?.data?.chart_data?.data;
        nodeObject.forEach((item: any) => {
          if (item["any(k8s.node.name)"]) {
            nodeNames.push(item["any(k8s.node.name)"]);
          }
        });
        cb(nodeNames);
        dispatch(K8NodeListReceived(nodeNames));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

const k8NamespaceListBuilder = {
  builderConfig: {
    columns: ["any(k8s.namespace.name)"],
    source: {
      name: "k8s.namespace",
      alias: "",
      dataset_id: 0,
    },
    group_by: [],
    order_by: [],
    limit: {
      n: 10,
    },
    top: 0,
    granularity: {
      column: "",
      filter_required: true,
      is_dttm: true,
      value: 30,
    },
  },
  label: "Untitled",
  builderId: -1,
  scopeId: -1,
  widgetAppId: 5,
  builderViewOptions: {
    displayScope: "infrastructure_k8s_cluster_dashboard",
    resource: {
      resourceType: "",
      columns: [],
      name: "infrastructure_k8s_cluster_dashboard",
      widgetAppId: 5,
    },
  },
};

export const getK8NamespaceList = (cb?: any) => {
  const namespaceNames: string[] = [];
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      // url: "/getk8namespacelist",
      // method: 'get',
      // data: {}
      url: "/builder/widget/data",
      method: "post",
      data: k8NamespaceListBuilder,
    })
      .then((res) => {
        const namespaceObject = res?.data?.chart_data?.data;
        namespaceObject.forEach((item: any) => {
          if (item["any(k8s.namespace.name)"]) {
            namespaceNames.push(item["any(k8s.namespace.name)"]);
          }
        });
        cb(namespaceNames);
        dispatch(K8NamespaceListReceived(namespaceNames));
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

export const getK8PodList = (
  body: { node_name: string; namespace_name: string },
  cb?: (a2?: any) => void
) => {
  const k8PodListBuilder = {
    builderConfig: {
      columns: ["any(k8s.pod.name)"],
      source: {
        name: "k8s.pod",
        alias: "",
        dataset_id: 0,
      },
      group_by: [],
      order_by: [],
      limit: {
        n: 10,
      },
      top: 0,
      granularity: {
        column: "",
        filter_required: true,
        is_dttm: true,
        value: 30,
      },
      with: [
        {
          key: "ATTRIBUTE_FILTER",
          value: {
            "k8s.namespace.name": body.namespace_name,
            "k8s.node.name": body.node_name,
          },
          is_arg: true,
        },
      ],
    },
    label: "Untitled",
    builderId: -1,
    scopeId: -1,
    widgetAppId: 5,
    builderViewOptions: {
      displayScope: "infrastructure_k8s_cluster_dashboard",
      resource: {
        resourceType: "",
        columns: [],
        name: "infrastructure_k8s_cluster_dashboard",
        widgetAppId: 5,
      },
    },
  };

  return (dispatch: (arg0: any) => void) => {
    const podNames: string[] = [];
    return CreateRequest({
      // url: "/getk8podlist",
      // method: 'post',
      // data: body
      url: "/builder/widget/data",
      method: "post",
      data: k8PodListBuilder,
    })
      .then((res) => {
        if (cb) {
          const podObject = res?.data?.chart_data?.data;
          podObject.forEach((item: any) => {
            if (item["any(k8s.pod.name)"]) {
              podNames.push(item["any(k8s.pod.name)"]);
            }
          });

          cb(podNames);
          dispatch(K8PodListReceived(podNames));
        }
      })
      .catch((err) => {
        if (cb) {
          cb(err);
        }
      });
  };
};

export const getAPMServicesList = (
  body: { from_ts: any; to_ts: any },
  cb?: (a2?: any) => void
) => {
  const serviceNameListBuilder = {
    builderConfig: {
      columns: ["any(service.name)"],
      source: {
        name: "trace.service",
        alias: "",
        dataset_id: 0,
      },
      group_by: [],
      order_by: [],
      limit: {
        n: 10,
      },
      top: 0,
      granularity: {
        column: "",
        filter_required: true,
        is_dttm: true,
        value: 30,
      },
      with: [
        {
          key: "SELECT_DATA_BY",
          value: ["service.name"],
          is_arg: true,
        },
      ],
    },
    label: "Untitled",
    builderId: -1,
    scopeId: -1,
    widgetAppId: 5,
    isClone: false,
    builderViewOptions: {
      report: {
        reportName: "first",
        reportId: 27,
      },
      extraActions: [],
    },
    params: [
      {
        key: "from_ts",
        value: body.from_ts,
      },
      {
        key: "to_ts",
        value: body.to_ts,
      },
      {
        key: "range",
        value: "custom",
      },
      {
        key: "live",
        value: "false",
      },
    ],
    isError: false,
  };

  return (dispatch: (arg0: any) => void) => {
    const serviceNameList: string[] = [];
    return CreateRequest({
      url: "/builder/widget/data",
      method: "post",
      data: serviceNameListBuilder,
    })
      .then((res) => {
        if (cb) {
          const serviceNameObject = res?.data?.chart_data?.data;
          serviceNameObject.forEach((item: any) => {
            if (item["service.name"]) {
              serviceNameList.push(item["service.name"]);
            }
          });
          cb(serviceNameList);
          dispatch(apmServiceNameReceived(serviceNameList));
        }
      })
      .catch((err) => {
        if (cb) {
          cb(err);
        }
      });
  };
};

export const getAPMRouteList = (
  body: { from_ts: any; to_ts: any; service_name: any },
  cb?: (a2?: any) => void
) => {
  const serviceNameListBuilder = {
    builderConfig: {
      columns: ["any(name)"],
      source: {
        name: "trace.service",
        alias: "",
        dataset_id: 0,
      },
      group_by: [],
      order_by: [],
      limit: {
        n: 10,
      },
      top: 0,
      granularity: {
        column: "",
        filter_required: true,
        is_dttm: true,
        value: 30,
      },
      with: [
        {
          key: "ATTRIBUTE_FILTER",
          value: {
            "service.name": {
              "=": body.service_name,
            },
          },
          is_arg: true,
        },
        {
          key: "SELECT_DATA_BY",
          value: ["name"],
          is_arg: true,
        },
      ],
    },
    label: "Untitled",
    builderId: -1,
    scopeId: -1,
    widgetAppId: 5,
    isClone: false,
    builderViewOptions: {
      report: {
        reportName: "first",
        reportId: 27,
      },
      extraActions: [],
    },
    params: [
      {
        key: "from_ts",
        value: body.from_ts,
      },
      {
        key: "to_ts",
        value: body.to_ts,
      },
      {
        key: "range",
        value: "1h",
      },
    ],
    isError: false,
  };

  return (dispatch: (arg0: any) => void) => {
    const serviceNameList: string[] = [];
    return CreateRequest({
      url: "/builder/widget/data",
      method: "post",
      data: serviceNameListBuilder,
    })
      .then((res) => {
        if (cb) {
          const serviceNameObject = res?.data?.chart_data?.data;
          serviceNameObject.forEach((item: any) => {
            if (item.name) {
              serviceNameList.push(item.name);
            }
          });
          cb(serviceNameList);
          dispatch(apmServiceNameReceived(serviceNameList));
        }
      })
      .catch((err) => {
        if (cb) {
          cb(err);
        }
      });
  };
};

export const getRouteList = (
  body: { service_name: string },
  cb?: (a2?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/getrouteslistforalert",
      method: "post",
      data: body,
    })
      .then((res) => {
        if (cb) {
          cb(res.data);
          // dispatch(K8PodListReceived(res.data))
        }
      })
      .catch((err) => {
        if (cb) {
          cb(err);
        }
      });
  };
};

export const getAlertFilterData = (
  body: { node_name: string; namespace_name: string },
  cb?: (a2?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/getalertfilterdata",
      method: "post",
      data: body,
    })
      .then((res) => {
        if (cb) {
          cb(res.data);
          dispatch(GetAlertFilterData(res.data));
        }
      })
      .catch((err) => {
        if (cb) {
          cb(err);
        }
      });
  };
};

export const getChartDataForNewAlert = (
  body: { threshold_name: string | number; host_name: string },
  cb?: (a2?: any) => void
) => {
  const currentTimestamp: number = Date.now();
  const twentyFourHoursAgo: number = currentTimestamp - 1 * 60 * 60 * 1000;

  const a = {
    create_builder: true,
    is_new_builder: true,
    display_scope: "infrastructure",
    builderId: -1,
    scopeId: -1,
    widgetAppId: 1,
    key: "untitled_ogk79fk9l7",
    builderConfig: {
      columns: [body.threshold_name],
      distinct: false,
      extras: null,
      filter: null,
      granularity: {
        column: "",
        filter_required: true,
        is_dttm: true,
        value: 0,
      },
      group_by: [],
      join_with: null,
      limit: {
        n: 10,
      },
      order_by: [],
      schema: "",
      source: {
        alias: "",
        data_set: null,
        dataset_id: 0,
        name: "host",
      },
      top: 0,
      with: [
        {
          is_arg: true,
          key: "SELECT_DATA_BY",
          value: ["host.name"],
        },
      ],
    },
    label: "Untitled",
    intervals: "15m",
    params: [
      {
        key: "cr_date",
        value: currentTimestamp.toString(),
      },
      {
        key: "from_ts",
        value: twentyFourHoursAgo.toString(),
      },
      {
        key: "to_ts",
        value: currentTimestamp.toString(),
      },
    ],
    dataset_id: -1,
    report: {
      id: 12,
    },
    meta_data: {
      layouts: {
        h: 5,
        i: -1,
        w: 4,
        x: 4,
        y: 24,
      },
    },
  };

  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/builder/widget/data",
      method: "post",
      data: a,
    })
      .then((res) => {
        if (cb) {
          cb(res.data);
          dispatch(K8PodListReceived(res.data));
        }
      })
      .catch((err) => {
        if (cb) {
          cb(err);
        }
      });
  };
};

export const getChartDataForAlertListDetails = (
  body: {
    threshold_name: string | number;
    host_name: string;
    start_time: number;
    end_time: number;
  },
  cb?: (a2?: any) => void
) => {
  const currentTimestamp: number = Date.now();
  const twentyFourHoursAgo: number = currentTimestamp - 1 * 60 * 60 * 1000;

  const a = {
    create_builder: true,
    is_new_builder: true,
    display_scope: "infrastructure",
    builderId: -1,
    scopeId: -1,
    widgetAppId: 1,
    key: "untitled_ogk79fk9l7",
    builderConfig: {
      columns: [body.threshold_name],
      distinct: false,
      extras: null,
      filter: null,
      granularity: {
        column: "",
        filter_required: true,
        is_dttm: true,
        value: 0,
      },
      group_by: [],
      join_with: null,
      limit: {
        n: 10,
      },
      order_by: [],
      schema: "",
      source: {
        alias: "",
        data_set: null,
        dataset_id: 0,
        name: "host",
      },
      top: 0,
      with: [
        {
          is_arg: true,
          key: "SELECT_DATA_BY",
          value: ["host.name"],
        },
        {
          key: "ATTRIBUTE_FILTER",
          value: {
            "host.name": {
              IN: ["narendra"],
            },
          },
          swap_as: false,
          is_arg: true,
        },
      ],
    },
    label: "Untitled",
    intervals: "15m",
    params: [
      {
        key: "from_ts",
        value: body.start_time,
      },
      {
        key: "to_ts",
        value: body.end_time,
      },
      // {
      //     "key": "debug",
      //     "value": "true"
      // }
    ],
    dataset_id: -1,
    report: {
      id: 12,
    },
    meta_data: {
      layouts: {
        h: 5,
        i: -1,
        w: 4,
        x: 4,
        y: 24,
      },
    },
  };

  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/builder/widget/data",
      method: "post",
      data: a,
    })
      .then((res) => {
        if (cb) {
          cb(res.data);
        }
      })
      .catch((err) => {
        if (cb) {
          cb(err);
        }
      });
  };
};

const defDates = (): any => {
  const defDate: any = new Date();
  return [
    ((new Date(moment(defDate).subtract(15, "minutes").valueOf()) as any) /
      1000) *
      1000,
    (defDate / 1000) * 1000,
  ];
};

export const createNewRule = (
  opts: any,
  callback: (a0: boolean, a1: any) => void
) => {
  const method = opts.method || "POST";
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/rules",
      method: method,
      data: opts.body,
    })
      .then((res) => {
        callback(true, res);
      })
      .catch((err) => {
        callback(false, err);
      });
  };
};
export const editRule = (
  opts: any,
  callback: (a0: boolean, a1: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/rules/" + opts.editId,
      method: "PUT",
      data: opts.body,
    })
      .then((res) => {
        callback(true, res.data);
      })
      .catch((err) => {
        callback(false, err);
      });
  };
};
export const getRulesList = (params: any = {}) => {
  const { start_ts, end_ts, severity, alert_type, ...restParam } = params;
  return (dispatch: (arg0: any) => void) => {
    dispatch(ReceivedRulesList({ inflight: true }));
    return CreateRequest({
      url: `/rules`,
      method: "get",
      params: params,
    })
      .then((res) => {
        dispatch(ReceivedRulesList({ ...res.data, inflight: false }));
      })
      .catch((err) => {
        console.log("list: error", err);
        dispatch(ReceivedRulesList({ inflight: false }));
      });
  };
};

export const getTriggeredAlertsList = (params: any = {}) => {
  const {
    start_ts,
    end_ts,
    severity,
    alert_type,
    host_filter,
    ruleId,
    ...restParam
  } = params;

  return (dispatch: (arg0: any) => void) => {
    dispatch(TriggeredAlertsReceived({ inflight: true }));
    return CreateRequest({
      url: `/rules/` + ruleId + "/alerts",
      method: "GET",
      params: params,
    })
      .then((res) => {
        dispatch(TriggeredAlertsReceived({ ...res.data, inflight: false }));
      })
      .catch((err) => {
        console.log("list: error", err);
        dispatch(TriggeredAlertsReceived({ inflight: false }));
      });
  };
};

export const getTriggeredInnerAlerts = (params: any = {}) => {
  const { start_ts, end_ts, ...restParam } = params;
  return (dispatch: (arg0: any) => void) => {
    dispatch(TriggeredInnerAlertsReceived({ inflight: true }));
    return CreateRequest({
      url: `/gettriggeredinneralerts/${restParam.rule_id}`,
      method: "get",
      data: restParam,
    })
      .then((res) => {
        dispatch(
          TriggeredInnerAlertsReceived({ ...res.data, inflight: false })
        );
      })
      .catch((err) => {
        console.log("list: error", err);
        dispatch(TriggeredInnerAlertsReceived({ inflight: false }));
      });
  };
};

export const requestGetRule = (
  params: any,
  opts?: any,
  callback?: (a1: any) => void
) => {
  const { rule_id, ...restParam } = params;
  return (dispatch: (arg0: any) => void) => {
    dispatch(receivedSyntheticsList({ singleInflight: true }));
    return CreateRequest({
      url: "/rules/" + rule_id,
      method: "GET",
      // params: params
    })
      .then((res) => {
        dispatch(RuleDetailReceived(res));
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
        // dispatch(ReceivedRulesList({singleInflight: false}));
      });
  };
};
export const requestFilterSections = (
  params?: any,
  callback?: (a1: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    dispatch(receivedSyntheticsList({ singleInflight: true }));
    return CreateRequest({
      url: "rules/filter/sections",
      method: "GET",
      params: params,
    })
      .then((res) => {
        dispatch(filterSectionsReceived(res));
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        console.log("filter section: error", err);
      });
  };
};
export const getFilteredRulesList = (
  params: any = {},
  callback?: (a1?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/rules/filter?page=${params.page}`,
      method: "post",
      data: params,
    })
      .then((res) => {
        dispatch(ReceivedRulesList({ ...res.data, inflight: false }));
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
        dispatch(ReceivedRulesList({ inflight: false }));
      });
  };
};
export const requestAlertStates = (
  params: { id: number },
  callback?: (a1?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `rules/${params.id}/alerts/stats`,
      method: "GET",
      params: params,
    })
      .then((res) => {
        dispatch(alertStatesReceived(res));
        if (callback) {
          callback(res);
        }
      })
      .catch((err) => {
        console.log("filter section: error", err);
      });
  };
};

export const alertMuteUnmute = (
  id?: any,
  status?: boolean,
  callback?: (a1: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/rules/" + id + "/active/" + status,
      method: "PUT",
      data: {},
    })
      .then((res) => {
        const msg = status ? "Unmuted" : "Muted";
        dispatch(showToast("success", `Alert ${msg} successfully`));
        if (callback) {
          callback(true);
        }
      })
      .catch((err) => {
        dispatch(showToast("error", "Something went wrong!"));
        console.log("Alert mute unmute section: error", err);
      });
  };
};
