import { createReducer } from "core/utils/create_reducer";
import { combineReducers } from "redux";
import {
    AWS_ACCESS_KEY_STATUS_RECEIVED,
    AWS_ACCESS_KEY_STATUS_RESET,
    AWS_VALIDATION_DETAILS_INFLIGHT,
    SLACK_AUTH_URL,
    VERCEL_DETAILS_INFLIGHT,
    VERCEL_DETAILS_RECEIVED,
    VERCEL_PROJECTS_RECEIVED,
    VERCEL_STATUS_RECEIVED
} from './constant';

export default combineReducers({
    slackurl: createReducer({
        isLoading: false,
        isError: false,
        items: [],
        update: null,
    }, {
        [SLACK_AUTH_URL]: (state, opts) => {
            return {...state};
        },
    }),
    vercelDetails: createReducer({
        data: {},
        otel: {},
        projects: [],
        inflight: false,
        status_code: "vercel_integration_not_found",
        update: null,
    }, {
        [VERCEL_DETAILS_RECEIVED]: (state, opts) => {
            state.data = opts.data;
            state.otel = opts.otel;
            state.projects = opts.projects;
            state.inflight = false;
            return {...state};
        },
        [VERCEL_PROJECTS_RECEIVED]: (state, opts) => {
            state.projects = opts.projects;
            state.inflight = false;
            return {...state};
        },
        [VERCEL_STATUS_RECEIVED]: (state, opts) => {
            state.status_code = opts.code;
            state.inflight = false;
            return {...state};
        },
        [VERCEL_DETAILS_INFLIGHT]: (state, opts) => {
            if  (opts.hasOwnProperty("flag")) {
                state.inflight = opts.flag;
            } else {
                state.inflight = true;
            }
            return {...state};
        },
    }),
    validateAWSAccessKeyStatus: createReducer({
        status: false,
        message: "",
    }, {
        [AWS_ACCESS_KEY_STATUS_RECEIVED]: (state, opts) => {
            state.status = opts.valid;
            state.message = opts.message;
            return {...state};
        },
        [AWS_ACCESS_KEY_STATUS_RESET]: (state, opts) => {
            state.status = false;
            state.message = "";
            return {...state};
        },
    }),
    awsInflight: createReducer(false, {
        [AWS_VALIDATION_DETAILS_INFLIGHT]: () => {
            return true;
        },
        [AWS_ACCESS_KEY_STATUS_RECEIVED]: () => {
            return false;
        },
    })
})