import React from "react";

import { SearchIcon } from "core/components/v2/svg/icons";

interface SearchProps {
  width?: number;
  placeholder?: string;
  onChange?: (value: string) => void;
  defaultValue?: string;
  value?: string;
  disabled?: boolean;
  onEnter?: (value: string) => void;
}

const Search = (props: SearchProps) => {
  const {
    width,
    placeholder,
    onChange,
    defaultValue,
    value,
    disabled = false,
    onEnter,
  } = props;
  return (
    <div className="search-container">
      <div className="search-icon">
        <SearchIcon />
      </div>
      <input
        defaultValue={defaultValue}
        onChange={(e) => onChange?.(e.target.value)}
        className={`input-field ${disabled ? "disabled" : ""}`}
        placeholder={placeholder ?? "Search by name, tag, label or annotation"}
        style={{ "--input-width": `${width}px` } as React.CSSProperties}
        value={value}
        disabled={disabled}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter")
            onEnter?.((e.target as HTMLInputElement).value);
        }}
      />
    </div>
  );
};

export default Search;
