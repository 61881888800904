import { getTenantUrl } from "core/application/utils";
import CardSelection from "core/components/v2/card-selection";
import { Message } from "core/components/v2/enums";
import {
  DockerIcon,
  KubernetesIcon,
  LinuxIcon,
  WindowsIcon,
} from "core/components/v2/svg/integrations-icons";
import TabSelection from "core/components/v2/tab-selection";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import Command from "../../components/code-block/command";
import MwMessage from "../../components/message";
import MwLinkCard from "../../components/mw-link-card";
import StepItem from "../../components/steps/step-item";
import DockerInstallationSnippet from "../common/docker-snippet";
import KubernetesInstallationSnippet from "../common/kubernetes-snippet";
import LinuxInstallationSnippet from "../common/linux-snippet";
// import agentRelatedSteps from "../../common/apm-agent-connect";

interface APMAgentConnectProps {
    agentName: string;
    setAppEnvShared?: React.Dispatch<React.SetStateAction<number>>;
}

const APMAgentConnect = (props: APMAgentConnectProps) => {
  const [appEnv, setAppEnv] = useState<number>(1);
  const [appBehaviour, setAppBehaviour] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];

  interface StepFormat {
    title: string;
    content: React.ReactNode;
  }
  const sendEvent = useMixPanel();
  return (
    <>
      <StepItem
        title="Install Middleware Host Agent (If not already installed)"
        stepNumber={1}
      >
        <>
          Select one of these method to install the Middleware Agent into your
          system.
          <br />
          <br />
          <CardSelection
            tabs={[
              {
                label: "Kubernetes",
                icon: <KubernetesIcon />,
                value: 1,
                enabled: true,
              },
              {
                label: "Docker",
                icon: <DockerIcon />,
                value: 2,
                enabled: true,
              },
              {
                label: "Linux",
                icon: <LinuxIcon />,
                value: 3,
                enabled: true,
              },
              {
                label: "Windows",
                icon: <WindowsIcon />,
                value: 4,
                enabled: true,
              },
            ]}
            selectedTab={1}
            onTabChange={(tabObject) => {
              setAppEnv(tabObject.value);
              if (props.setAppEnvShared) {
                props.setAppEnvShared(tabObject.value);
              }
            }}
          />
          {appEnv == 1 && (
            <>
              <br />
              <br />
              <KubernetesInstallationSnippet />
            </>
          )}
          {appEnv == 2 && (
            <>
              <br />
              <br />
              <DockerInstallationSnippet />
            </>
          )}
          {appEnv == 3 && (
            <>
              <br />
              <br />
              <LinuxInstallationSnippet packageType="unknown" os="unknown" />
            </>
          )}
          {appEnv == 4 && (
            <>
              <br />
              <br />
              Download and Install Middleware EXE File
              <br />
              <br />
              <MwLinkCard
                appStatus="unknown"
                link="https://github.com/middleware-labs/mw-agent/releases/latest/download/mw-windows-agent-setup.exe"
                title="Middleware.exe"
              />
            </>
          )}
        </>
      </StepItem>
      <StepItem title="Communicate with Middleware Host Agent" stepNumber={2}>
        <>
          Choose how your application will run:
          <br />
          <br />
          <TabSelection
            tabs={[
              {
                label: "Containerized",
                value: 1,
              },
              {
                label: "Natively",
                value: 2,
              },
            ]}
            selectedTab={1}
            onTabChange={(tabObject) => {
              setAppBehaviour(tabObject.value);
            }}
          />
          <div className="tab-content-area">
            {appBehaviour === 1 && (
              <>
                {appEnv == 1 && (
                  <Command
                    command={`MW_AGENT_SERVICE=mw-service.mw-agent-ns.svc.cluster.local`}
                    className="margin-0"
                    onCopyClick={() => {
                      sendEvent("click on copy command", {
                        agent_type: props.agentName,
                        command_type: "Communicate with Middleware Host Agent",
                      });
                    }}
                  />
                )}
                {appEnv != 1 && (
                  <Command
                    command={`MW_AGENT_SERVICE=172.17.0.1`}
                    className="margin-0"
                    onCopyClick={() => {
                      sendEvent("click on copy command", {
                        agent_type: props.agentName,
                        command_type: "Communicate with Middleware Host Agent",
                      });
                    }}
                  />
                )}

                <MwMessage
                  type={Message.Info}
                  message="Pass this Environment variable with your application."
                ></MwMessage>
              </>
            )}
          </div>
        </>
      </StepItem>
    </>
  );
};
export default APMAgentConnect;
