import React, { useState } from "react";
import WidgetStep from "./step-child";

export type Step = {
  // stepNumber: number;
  title: string;
  description?: string;
  component: any;
  shouldDisplayComponent: boolean;
};

export type StepProps = {
  steps: Step[];
};

const Steps = (props: StepProps) => {
  const { steps = [] } = props;
  return (
    <div className={"step-contaier"}>
      {steps
        .filter((step) => step.shouldDisplayComponent)
        .map((step, index) => {
          // if(!step.shouldDisplayComponent) return <React.Fragment key={index}></React.Fragment>
          return (
            <WidgetStep
              stepNumber={index + 1}
              stepTitle={step.title}
              key={index}
            >
              {step.component}
            </WidgetStep>
          );
        })}
    </div>
  );
};
export default Steps;
