import React from "react";
import FlamegraphSpan from "./FlamegraphSpan";
import { Span } from "../../modal/apm.modals";
import "../_style.scss"

type Props = {
    spans: string[];
    spanBitMap: { [key: string]: Span };
    minStartTimeNs: number;
    minPresentationalSpanDuration: number;
    maxEndTimeNs: number;
    width: number;
    onTraceClick: (arg0: Span) => void
};

const FlamegraphRow = ({
   spans,
   spanBitMap,
   minStartTimeNs,
   minPresentationalSpanDuration,
   maxEndTimeNs,
   width,
   onTraceClick
}: Props) => {
    return (
        <div className="flamegraph-row">
            {spans.map((span, key) => {
                return (
                    <FlamegraphSpan
                        spanId={span}
                        span={spanBitMap[span]}
                        minStartTimeNs={minStartTimeNs}
                        minPresentationalSpanDuration={minPresentationalSpanDuration}
                        maxEndTimeNs={maxEndTimeNs}
                        key={key}
                        width={width}
                        onTraceClick={onTraceClick}
                    />
                );
            })}
        </div>
    );
};

export default FlamegraphRow;
