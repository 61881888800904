
import * as React from "react";
import { useEffect, useState } from "react";
import { Parsed, formatString } from "./parse-helper";
const PrepareContent = ({ searchVal }: any) => {

    const [allSpan , setAllSpan] = useState<any>([]); 

    useEffect(() => {
        const operators = ["AND", "OR", "(", ")", "="];
        let parsed: Parsed = formatString(searchVal) || {};
        const parts: string[] = parsed.parts;
        const spans: any = []

        parts.forEach((part: any, index: number) => {
            if (operators.includes(part.trim())) {
                if (part.trim() == 'AND') {
                    spans.push(<span style = {{color:'var(--color-red-500)'}} key = {'operator-spans-index-and-' + index + 1}>{part}</span>)
                } else if (part.trim() == "OR") {
                    spans.push(<span style = {{color:'var(--color-green-500)'}} key = {'operator-spans-index-or-' + index + 1}>{part}</span>)
                } else {
                    spans.push(<span style = {{color:'var(--color-blue-500)'}} key = {'operator-spans-index-def-' + index + 1}>{part}</span>)
                }
            } else {
                spans.push(<span key = {'operator-spans-index-no-operator-' + index + 1}>{part}</span>)
            }
        })

        setAllSpan(spans)


    }, [searchVal])


    return allSpan.map((item:any) => item)

}

export default PrepareContent