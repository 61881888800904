import { RoutesPatterns } from "core/utils/route_schema";
import * as React from "react";

const AdvisorRoutes: RoutesPatterns = {
    moduleName: "advisor",
    slug: "/advisor",
    breadcrumbs: React.lazy(() => import("views/modules/advisor/breadcrumbs")),
    items: [
        {
            pattern: [
                "/recommendations",
            ],
            component: React.lazy(() => import("views/modules/advisor/index")),
            breadcrumbTitle: "Recommendations",
            // sidebar: React.lazy(() => import("views/modules/advisor/sidebar")),
        },
        {
            pattern: [
                "/rules",
            ],
            component: React.lazy(() => import("views/modules/advisor/rules/rules.index")),
            breadcrumbTitle: "Rules",
            sidebar: React.lazy(() => import("views/modules/advisor/sidebar")),
        }
    ]
}
export default AdvisorRoutes