import React from "react";

import classNames from "classnames";
import { Size } from "core/components/v2/enums";
import CircularLoader from "core/components/v2/loader/circulat-loader";

interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  primary?: boolean;
  secondary?: boolean;
  error?: boolean;
  outlined?: boolean;
  loading?: boolean;
  prefixicon?: React.ReactNode;
  suffixicon?: React.ReactNode;
  shouldStopPropagation?: boolean;
  size?: Size;
  onClick?: () => void;
  borderless?: boolean;
  background?: boolean;
  integration?: boolean;
  success?: boolean;
  dataTestId?: string;
}

const Button = (props: ButtonProps) => {
  const {
    size,
    prefixicon,
    suffixicon,
    children,
    primary,
    loading,
    disabled,
    secondary,
    error,
    outlined,
    borderless,
    background = true,
    shouldStopPropagation,
    integration,
    success,
    dataTestId,
    ...otherProps
  } = props;

  const className = classNames("button-v2", props.className, size, {
    "is-disabled": disabled,
    "is-primary": primary,
    "is-secondary": secondary,
    "is-outlined": outlined,
    "is-loading": loading,
    "is-error": error,
    "is-borderless": borderless,
    "has-no-bg": !background,
    "is-integration-btn": integration,
    "is-success": success,
  });

  return (
    <button
      className={className}
      data-testid={dataTestId}
      {...otherProps}
      onClick={(e) => {
        if (shouldStopPropagation) {
          e.stopPropagation();
        }
        if (!disabled && props?.onClick) {
          props?.onClick();
        }
      }}
    >
      {loading && (
        <div className="loader">
          <CircularLoader
            color={error ? "var(--color-error)" : "var(--color-primary)"}
          />
        </div>
      )}
      {prefixicon}
      {children && <span className="btn-text">{children}</span>}
      {suffixicon}
    </button>
  );
};

export default Button;
