
import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { Parsed, evaluateExpression, formatString } from "./parse-helper";
import PrepareContent from "./prepareContents";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import { FilterOpEnum } from "views/modules/builder/entities/builder.entities";

interface RemovedItem {
    key: string;
    value: string;
}

interface Props {
    placeHolder?: string,
    className?: string,
    children?: any,
    options: any,
    onChipSelect?: (val: string) => void;
    onChange?: (chips: any) => void;
    selectedChips?: any;
    showHelp?: boolean;
    hidePopover?: boolean;
    disableChange?:boolean;
    returnRemovedItem?: (element: RemovedItem) => void;

}
const SuggestionInput = (props: Props) => {

    const [popOver, setPopOver] = useState(false);
    const [width, setWidth] = useState(0);
    const [X, setX] = useState(0);
    const [Y, setY] = useState(0);

    const divRef = useRef(null) as any;

    const [values, setValues] = useState([]) as any;
    const [key, setKey] = useState('');

    const [chips, setChips] = useState({}) as any;
    const [searchVal, setSearchVal] = useState('')
    const [reg, setReg] = useState<string>('');
    const [isPlaceholderVisible, setIsPlaceholderVisible] = useState(true);

    const [parsedValues, setParsedValues] = useState<string[]>([]);
    const [parsedOperators, setParsedOperators] = useState<string[]>([]);

    const [isValid, setIsValid] = useState(true);

    const [cursorPosition, setCursorPosition] = useState(-1);
    const [positionTop, setPositionTop] = useState(0);

    const routeData = React.useContext(MwRouteContext);
    const getAppliedFilters = () => {
        const logFiltersData:{[key:string]:string[]} = {};
        for (const key in routeData.params.appliedFilters) {
            if (Object.prototype.hasOwnProperty.call(routeData.params.appliedFilters, key)) {
                const element = routeData.params.appliedFilters[key];
                if (!logFiltersData[key]) {
                    logFiltersData[key] = element.values
                } else {
                    logFiltersData[key] = [...logFiltersData[key], ...element.values]
                }
            }
        }
        return logFiltersData;
    }
    const logFilters = getAppliedFilters();

    const onClickOutsideHandler = (event: any) => {
        let dropdownPopover = document.querySelector('.popover');
        if (dropdownPopover && popOver) {
            if (event.x && event.y) {
                if ((event.x < X || event.x > (X + width)) || (event.y < Y - 60 || event.y > Y + 410)) {

                    setPopOver(false)
                    setKey('');
                    // setSearchVal('')
                }
            }
        }

    }

    window.addEventListener('click', onClickOutsideHandler);

    const onOptionClickHandler = (event: any, item: string) => {
        if (key.trim() == '') {
            setKey(item)
            setValues(props.options[item])
            setSearchVal('')
        } else {
            let items = chips;
            if (!items[key]) {
                items[key] = [];
            }
            items[key].push(item);
            setChips(items)
            setKey('');
            setValues(Object.keys(props.options));
            setSearchVal('')
            setReg('')
            setPopOver(false)
            if (props.onChange) props.onChange(chips)
        }
    }

    const onChangeHandler = (e: any) => {

        let valueCurr = e.target.value
      
        let options: any = []
        if (key.trim() == '') {
            if (valueCurr[0] == '~') {
                const st = valueCurr.split('~');
                st.splice(0, 1);
                if (st.length) {
                    const reg = st.join().trim();
                    setReg(reg);
                    options = [];
                }
            } else {
                setReg('');
                options = Object.keys(props.options).filter((item: string) => {
                    return item.indexOf(valueCurr) > -1;
                })

            }
            setSearchVal(valueCurr);
        } else {
            valueCurr = valueCurr.split(key + ':').join('');
            setReg('');
            options = props.options[key].filter((item: string) => {
                return item.indexOf(valueCurr) > -1;
            })
            setSearchVal(valueCurr);
        }

        setValues(options)

        if (divRef.current) {

            let valueCurr = divRef.current.textContent;
            if (key.trim() != '') {
                if (valueCurr) {
                    let div = valueCurr.split(`${key}:`);
                    if (div && div.length) {
                        div.splice(0, 1);
                        valueCurr = div.join().trim();
                    }
                }
            }

            // if (valueCurr) {
            //     const ph = props.placeHolder || 'Query Logs'
            //     valueCurr = valueCurr.replace(ph, '');
            // }


            // const splits = valueCurr.split('=');
            // splits.splice(0, 1);
            let val = ''
            // if (splits.length) {

            //     val = splits.join().trim()
            // } else {
            // }
            val = valueCurr;
            setSearchVal(val);
            let options: any = []
            if (key.trim() == '') {
                if (val[0] == '~') {
                    const st = valueCurr.split('~');
                    st.splice(0, 1);
                    if (st.length) {
                        const reg = st.join().trim();
                        setReg(reg);
                        options = [];
                    }
                } else {
                    setReg('');
                    options = Object.keys(props.options).filter((item: string) => {
                        return item.indexOf(val) > -1;
                    })

                }
            } else {
                setReg('');
                options = props.options[key].filter((item: string) => {
                    return item.indexOf(val) > -1;
                })
            }

            setValues(options)




        }
    }


    useEffect(() => {
        if (props.selectedChips && typeof props.selectedChips == 'object') {
            setChips(props.selectedChips);
        }

    }, [props.selectedChips])

    useEffect(() => {
        const allQuery: any = document.querySelectorAll('.logs_search')

        if (allQuery && allQuery.length) {
            // const check: any = document.querySelector('.logs_search')?.getBoundingClientRect()
            const check: any = allQuery[allQuery.length - 1]?.getBoundingClientRect()

            const popover: any = document.querySelectorAll('.popover')
            const checkPop: any = popover[popover.length - 1]?.getBoundingClientRect()
            setWidth(check?.width)
            setX(check?.left - checkPop?.left);
            setY(check.height + check.top);
            if (props.options != undefined) {
                const data = props.options || {}

                if (key.trim() == '') {
                    setValues(Object.keys(data) || []);
                } else {
                    setValues(data[key] || [])
                }
            }

        }

    }, [popOver])


    const isChildOf = (node: any, parentElement: any) => {
        while (node !== null) {
            if (node === parentElement) {
                return true;
            }
            node = node.parentNode;
        }

        return false;
    }

    const getCurrentCursorPosition = (parentElement: any) => {
        var selection = window.getSelection(),
            charCount = -1,
            node: any;

        if (selection?.focusNode) {
            if (isChildOf(selection?.focusNode, parentElement)) {
                node = selection?.focusNode;
                charCount = selection?.focusOffset;

                while (node) {
                    if (node === parentElement) {
                        break;
                    }

                    if (node.previousSibling) {
                        node = node.previousSibling;
                        charCount += node.textContent.length;
                    } else {
                        node = node.parentNode;
                        if (node === null) {
                            break;
                        }
                    }
                }
            }
        }

        return charCount;
    };

    const createRange = (node: any, chars: any, range: any = null) => {
        if (!range) {
            range = document.createRange()
            range.selectNode(node);
            range.setStart(node, 0);
        }

        if (chars.count === 0) {
            range.setEnd(node, chars.count);
        } else if (node && chars.count > 0) {
            if (node.nodeType === Node.TEXT_NODE) {
                if (node.textContent.length < chars.count) {
                    chars.count -= node.textContent.length;
                } else {
                    range.setEnd(node, chars.count);
                    chars.count = 0;
                }
            } else {
                for (var lp = 0; lp < node.childNodes.length; lp++) {
                    range = createRange(node.childNodes[lp], chars, range);

                    if (chars.count === 0) {
                        break;
                    }
                }
            }
        }

        return range;
    };

    useEffect(() => {
        getInputPosition()
    }, [chips]);


    const handleKeyDown = (event: any) => {


        if (event.key === 'Enter') {
            event.preventDefault();
            const rndInt = Math.floor(Math.random() * 100) + 1
            setCursorPosition(rndInt)
            if (searchVal.trim() != '') {
                let parsed: Parsed = formatString(reg.trim() != '' ? reg : searchVal) || {};
                const shouldProceed = evaluateExpression(parsed.parts);
                setIsValid(shouldProceed);
                if (shouldProceed) {


                    if (key.trim() == '') {
                        let items = chips;

                        let appKey = handleKeySelect(parsed);


                        let searchText = searchVal.trim();
                        if (reg.trim() != '') {
                            // appKey = 'regex_pattern'
                            searchText = reg;
                        }
                        if (!items[appKey]) {
                            items[appKey] = [];
                        }

                        if (searchText.trim() != '') {
                            items[appKey].push(searchText);
                            setChips(items)
                            setPopOver(false)
                        }

                    } else {
                        let items = chips;
                        if (!items[key]) {
                            items[key] = [];
                        }
                        if (searchVal.trim() != '') {
                            items[key].push(searchVal);
                            setChips(items)
                            setPopOver(false)

                        }

                    }

                    setKey('');
                    setValues(Object.keys(props.options));
                    setSearchVal('')
                    setReg('')
                    if (divRef.current) {
                        divRef.current.innerHTML = '';
                    }



                    if (props.onChange) props.onChange(chips)
                }

            }

        }

    };

    const handleFocus = (e: any) => {
        // setIsPlaceholderVisible(false);
        // if (divRef.current) {
        //     divRef.current.innerHTML = '';
        // }
    };

    const handleBlur = () => {
        setIsPlaceholderVisible(searchVal === '');
    };

    const handleKeySelect = (parsed: Parsed): string => {

        if (parsed.operators.length) {
            setParsedOperators(parsed.operators);
            setParsedValues(parsed.values);
            return 'text_query'

        }
        let appendKey: string = key.trim() != '' ? key : 'text';
        if (reg.trim() != '') {
            appendKey = 'regex_pattern';
        }

        return appendKey;
    }



    let str = ``;


    if (key.trim() != '') {
        str = `<span>${key}:</span>`

    }




    const operators = ["AND", "OR", "(", ")", "="];
    let parsed: Parsed = formatString(searchVal) || {};
    const parts: string[] = parsed.parts;

    parts.forEach((part: any, index: number) => {
        if (operators.includes(part.trim())) {
            if (part.trim() == 'AND') {
                str += `<span style = "color:var(--color-red-500)">${part}</span>`
            } else if (part.trim() == "OR") {
                str += `<span style = "color:var(--color-green-500)">${part}</span>`
            } else {
                str += `<span style = "color:var(--color-blue-500)">${part}</span>`
            }
        } else {
            str += `<span>${part}</span>`
        }
    })



    if (divRef.current) {
        divRef.current.innerHTML = str;
        // divRef.current.focus();
    }

    const getInputPosition = () => {
        var inputElement = document.getElementById('input-positions');
        var divElement = document.getElementById('logs_search_bar_id'); // Replace 'your-div-id' with the actual ID of the div containing the input element

        if (inputElement && divElement) {
            var inputRect = inputElement.getBoundingClientRect();
            var divRect = divElement.getBoundingClientRect();

            var position = {
                top: inputRect.top - divRect.top,
                left: inputRect.left - divRect.left,
                right: inputRect.right - divRect.left,
                bottom: inputRect.bottom - divRect.top
            };
            setPositionTop(position.top)
        }
    }

    const searchTotal = key.trim() != '' ? key + ':' + searchVal : searchVal

    const handleChipLabelClick = (chip: string) => {
        setPopOver(true);
      
        setKey(chip);
        setValues(props.options[chip] || []);
        setSearchVal(chips[chip]?.[0] || ''); 
      
        setChips((prevChips:any) => {
          const updatedChips = {
            ...prevChips,
            [chip]: [searchVal],
          };
      
          const [oldKey, oldValue] = Object.entries(prevChips).find(([key]) => key === chip || key.startsWith(chip + ':')) || [];
          if (oldKey && oldValue) {
            delete updatedChips[oldKey];
          }
      
          return updatedChips;
        });
    };


    return (
        <div className={"logs_search"}>
            {popOver && !props.hidePopover && <div className={'popover'}>
                <div className={'popover_panel--autocomplete popover_panel'} style={
                    {
                        width: width,
                        transform: `translate3d(${X}px, ${Y}px, 0px)`
                    }
                }>
                    <div className={'bar_autocomplete'}>
                        <div className={'bar_scroll'}>
                            {searchVal.trim() != '' && <div>
                                <p className="bar_subheader">{reg.trim() == '' ? 'Search' : 'Regex Pattern'}</p>
                                <button className={'autocomplete_list_option'}>
                                    <div className="autocomplete_option_label" onClick={() => {
                                        let items = chips;
                                        // let appendKey = key.trim() != '' ? key : 'text';
                                        // if (reg.trim() != '') {
                                        //     appendKey = 'regex_pattern';
                                        // }
                                        let parsed: Parsed = formatString(reg.trim() != '' ? reg : searchVal) || {};
                                        const shouldProceed = evaluateExpression(parsed.parts);
                                        setIsValid(shouldProceed);
                                        if (shouldProceed) {
                                            const appendKey = handleKeySelect(parsed);

                                            if (!items[appendKey]) {
                                                items[appendKey] = [];
                                            }
                                            items[appendKey].push(searchVal);
                                            setChips(items)
                                            setKey('');
                                            setValues(Object.keys(props.options));
                                            setSearchVal('')
                                            setReg('')
                                            setPopOver(false)
                                            if (props.onChange) props.onChange(chips)
                                        }
                                    }}>{`${key.trim() == '' ? searchVal : key + '= ' + searchVal}`}</div>
                                </button>
                            </div>}
                            {reg.trim() == '' && <p className="bar_subheader">{key.trim() == '' ? 'Attributes' : key}</p>}

                            {values.map((item: string, index: number) => {
                                return (<button className={'autocomplete_list_option'} key={index + 1} onClick={(e) => onOptionClickHandler(e, item)}>
                                    <div className="autocomplete_option_label">{item}</div>
                                </button>)
                            })}

                        </div>
                        {props.showHelp && <div className='search_help'>
                            <div className={'search-info'}>
                                <span style={{ fontWeight: '500' }}>Filters : </span>
                                <div className={'attributes-info'}>
                                    <span style={{ color: 'var(--color-blue-400)' }}>Attributes : </span>
                                    <span style={{ color: 'var(--color-red-500)' }}>{"{value}"}</span>
                                </div>
                            </div>
                            <div style={{ marginLeft: '60px' }} className={'search-info'}>
                                <span style={{ fontWeight: '500' }}>Regular Expression : </span>
                                <div className={'attributes-info'}>
                                    <span style={{ color: 'var(--color-blue-400)' }}>Input '~' and type regex pattern : </span>
                                    <span style={{ color: 'var(--color-red-500)' }}>{"~{REGEX_PATTERN}"}</span>
                                </div>
                            </div>
                        </div>}
                    </div>

                </div>

            </div>
            }
            <div className={'logs_search_item'}>
                <div id = {'logs_search_bar_id'} className={`logs_search_bar ${!isValid ? "border-error" : "border-fine"}`}>
                    {Object.keys(chips).map((chip: any, index: number) => {
                        if (chips[chip] && Array.isArray(chips[chip]) && chips[chip].length) {

                            return chips[chip].map((item: any, i: number) => {
                                return (
                                    <div className="chip" key={'chip-list' + (index + 1) + '- ' + (i+1) + ' - ' + item}>
                                        <button className="chip_label" onClick={() => {
                                            // No need to allow the change of chip value on click causing the popover to open issue with text alignment
                                            // handleChipLabelClick(chip);
                                        }}>{`${chip} : ${item}`}</button>
                                        <button className="chip_button" type="button" onClick={() => {
                                            if (logFilters.hasOwnProperty(chip) && Array.isArray(logFilters[chip])) {
                                                const newAppliedFilters = { ...logFilters };
                                                if (newAppliedFilters[chip]) {
                                                    newAppliedFilters[chip] = newAppliedFilters[chip].filter((_item: any) => {
                                                        return _item != item;
                                                    });
                                                }
                                                const filters: { [key: string]: { operator: FilterOpEnum, values: string[] } } = {};
                                                for (const key in newAppliedFilters) {
                                                    if (Object.prototype.hasOwnProperty.call(newAppliedFilters, key)) {
                                                        const element = newAppliedFilters[key];
                                                        filters[key] = {
                                                            operator: FilterOpEnum.In,
                                                            values: element
                                                        }
                                                    }
                                                }
                                                routeData.actions.handleApplyFilters(filters);
                                            }
                                            const chipItems = { ...chips };
                                            chipItems[chip] = chipItems[chip].filter((_chpItem: any) => {
                                                return _chpItem != item;
                                            });
                                            if (chipItems[chip] && !chipItems[chip].length) {
                                                delete chipItems[chip];
                                            }
                                            setChips({ ...chipItems })
                                            if (props.onChange) props.onChange(chipItems)
                                            if(props.returnRemovedItem) props.returnRemovedItem({
                                                key:chip,
                                                value:item
                                            })

                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                        </button>
                                    </div>
                                )
                            })
                        }
                    })}


                    {/* <div contentEditable
                        ref={divRef}
                        spellCheck={false}
                        autoCapitalize={'false'}
                        autoCorrect={"false"}
                        role={'textbox'}
                        aria-disabled={false}
                        aria-multiline={false}
                        aria-busy={false}
                        suppressContentEditableWarning={true}
                        className={'logs_search_bar_input'}
                        // value={`${key.trim() == '' ? ((reg.trim() != '') ? '~' + reg : searchVal) : key + '= ' + searchVal}`}
                        onClick={() => {
                            if (!popOver) {
                                setPopOver(true)
                            }
                        }}
                        onFocus={handleFocus}
                        onBlur={handleBlur}

                        onKeyDown={handleKeyDown}

                        onInput={onChangeHandler}
                    ></div> */}



                    {!props.disableChange && <input
                        className={'logs_search_bar_input'}
                        // value={`${key.trim() == '' ? ((reg.trim() != '') ? '~' + reg : searchVal) : key + '= ' + searchVal}`}
                        onClick={() => {
                            if (!popOver) {
                                setPopOver(true)
                            }
                        }}
                        disabled = {props.disableChange}
                        id={'input-positions'}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        placeholder={props.placeHolder || 'Query Logs'}

                        onKeyDown={handleKeyDown}
                        value={searchTotal}
                        style={{
                            caretColor: 'var(--color-grey-900)',
                            color: 'transparent',
                            background: 'none',
                            position:'relative',
                            zIndex: "202"
                        }}

                        onChange={onChangeHandler}
                    />}

                    <div
                        style={{
                            position: 'absolute',
                            fontSize: '13px',
                            // paddingLeft: '8px',
                            marginLeft:'8px',
                            letterSpacing:'0.15px',
                            top: 9 + positionTop + 'px',
                            zIndex: 201,
                            whiteSpace: 'pre-wrap'
                        }}
                    >

                        {/* {`${searchVal}`}  */}
                        {key.trim() != '' && <span>{key + ':'}</span>}
                        <PrepareContent searchVal={searchVal} />
                    </div>


                    {/* (key.trim() =='' && searchVal.trim() == '' && (!chips || Object.keys(chips).length == 0))  */}
                    {/* {(key.trim() == '' && searchVal.trim() == '' && (!chips || Object.keys(chips).length == 0)) && (
                        <div
                            style={{
                                position: 'absolute',
                                color: '#aaa',
                                pointerEvents: 'none',
                                userSelect: 'none',
                                paddingLeft: '10px',
                                top: '9px'
                            }}
                        >
                        
                            {props.placeHolder || 'Search Logs'}
                        </div>
                    )} */}
                </div>
            </div>

        </div>
    );
};

export default SuggestionInput
