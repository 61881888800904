import * as React from 'react';
import classNames from 'classnames';
import { noop, omit } from 'lodash-es';
import { getThemeState } from 'core/utils';
interface Props {
	href ?: string,
	className ?: string,
	isSelected ?: boolean,
	isChecked ?: boolean,
	icon ?: string,
	fontIcon ?: string,
	focusOnHover ?: boolean,
	onMouseOver ?: (a0: any) => void,
	isExternalLink ?: boolean,
	itemComponent ?: (a0: any) => void | string,
	imgUrl ?: string,
	withActionButton ?: boolean,
	disabled ?: boolean,
	placeholderIcon?: string,
	iconStyle? : object,
	onClick?: (a0: any) => void,
}
export default class PopoverMenuItem extends React.Component<Props, any> {
	onMouseOver?: (a0: any) => void;

	public static defaultProps = {
		isSelected: false,
		focusOnHover: true,
		onMouseOver: noop,
		itemComponent: 'button',
		withActionButton: false,
		disabled: false
	};

	handleMouseOver = (event: any) => {
		const {focusOnHover} = this.props;

		if (focusOnHover) {
			event.target.focus();
		}
		if (this.props.onMouseOver != undefined) {
			this.props.onMouseOver(event);
		}
	};

	render() {
		const {
			children,
			className,
			href,
			icon,
			isSelected,
			isExternalLink,
			fontIcon,
			imgUrl,
			withActionButton,
			placeholderIcon,
			disabled
		} = this.props;
		const itemProps = omit(
			this.props,
			'icon',
			'fontIcon',
			'focusOnHover',
			'isSelected',
			'isChecked',
			'isExternalLink',
			'className',
			'itemComponent',
			'imgUrl',
			'withActionButton',
			'iconStyle',
			'placeholderIcon',
		);
		const is_checked = typeof this.props.isChecked !== "undefined"
		const theme = getThemeState();
		const classes = classNames('popover__menu-item', className, {
			'is-selected': isSelected,
			'is__checked': is_checked,
			'hover-normal': withActionButton,
			'disabled': disabled,
			'with-item-checked': this.props?.isChecked,
			'theme-dark': theme && theme === "dark",
		});

		let ItemComponent: any = this.props.itemComponent;
		if (isExternalLink && href) {
			// ItemComponent = ExternalLink;
			(itemProps as any).icon = true;
		} else if (href) {
			(ItemComponent) = 'a';
		}

		return (
			<ItemComponent
				role="menuitem"
				onMouseOver={this.handleMouseOver}
				tabIndex="-1"
				className={classes}
				{...itemProps}
			>

				{imgUrl && <img src={imgUrl} className="user_img" alt="User" onError={(e: any) => {
					if (e && e.target && e.target.src)
						e.target.src = "./assets/avatar/default-avatar-sea-green.svg";
				}} />}

				{placeholderIcon && <span className={"placeholder-icon"}>{placeholderIcon}</span>}

				<span className={classNames(`label-${classes}`, {
					'with-img-text': imgUrl,
					'with-action-btn': withActionButton,
				})}>{children}</span>
			</ItemComponent>
		);
	}
}
