/** @format */

/**
 * External dependencies
 */

import * as React from 'react';
import classNames from 'classnames';
import './_style.scss'
import {LeftArrowIcon, RightArrowIcon} from "core/components/svg/icons";

interface Props {
    totalRecord: number,
    perPage: number,
    currentPage: number,
    onClick:(a0 ?: any) => void
    totalRecordText ?: string
}
interface States {
    activePage : number
}
export default class PaginationWithNumber extends React.Component<Props,States> {
    state = {
        activePage:1
    };


    pageSelected(e:any,page:number) {
        return this.props.onClick(page);
    }

    createPage() {


        let currentPage = this.props.currentPage;
        const pageRange = 1;
        const totalPage = Math.ceil(this.props.totalRecord / this.props.perPage);
        if (totalPage <= currentPage) currentPage = totalPage;
        let rangeStart = currentPage - pageRange;
        let rangeEnd = currentPage + pageRange;


        if (rangeEnd > totalPage) {
            rangeEnd = totalPage;
            rangeStart = totalPage - pageRange * 2;
            rangeStart = rangeStart < 1 ? 1 : rangeStart;
        }

        if (rangeStart <= 1) {
            rangeStart = 1;
            rangeEnd = Math.min(pageRange * 2 + 1, totalPage);
        }


        const html = [];
        let i;
        const ellipsisText = "...";
        const showFirstOnEllipsisShow = true;
        const showLastOnEllipsisShow = true;
        let __key = 0;
        const previous_disabled = (1 >= currentPage);
        const next_disabled = (currentPage >= totalPage);


        html.push(<li key={__key} className={classNames("action-btn previous",{"disabled":previous_disabled})}>
            <a className={"icon-arrow-left"} onClick={(e)=>{
                if (!previous_disabled) this.pageSelected("btn",currentPage-1)
            }} data-num={(currentPage-1)}><LeftArrowIcon size={15}/></a>
        </li>);

        if (totalPage > 0) {
            if (rangeStart <= 3) {
                for (i = 1; i < rangeStart; i++) {
                    __key++;
                    const this_key = i;
                    html.push(<li key={__key} className={classNames({"active":(currentPage === i)})}>
                        <a onClick={(e)=>this.pageSelected(e,this_key)} data-num={i}>{i}</a></li>);
                }
            }
            else {
                if (showFirstOnEllipsisShow) {
                    __key++;
                    html.push(<li key={__key} className={classNames({"active":(currentPage === 1)})}>
                        <a onClick={(e)=>this.pageSelected(e,1)} data-num={1}>{1}</a></li>);
                }
                __key++;
                html.push(<li key={__key} className={"ellipsis"}><span>{ellipsisText}</span></li>);
            }

            for (i = rangeStart; i <= rangeEnd; i++) {
                __key++;
                if (i === currentPage) {
                    html.push(<li key={__key} className={"active"} ><a>{i}</a></li>);
                } else {
                    const this_key = i;
                    html.push(<li key={__key}><a onClick={(e)=>this.pageSelected(e,this_key)} data-num={i}>{i}</a></li>);
                }
            }

            if (rangeEnd >= totalPage - 2) {
                for (i = rangeEnd + 1; i <= totalPage; i++) {
                    __key++;
                    const this_key = i;
                    html.push(<li key={__key} className={classNames({"active":currentPage === i})}>
                        <a onClick={(e)=>this.pageSelected(e,this_key)} data-num={i}>{i}</a></li>);
                }
            }
            else {
                __key++;
                html.push(<li key={__key} className={"ellipsis"}><span>{ellipsisText}</span></li>);
                if (showLastOnEllipsisShow) {
                    __key++;
                    const this_key = totalPage;
                    html.push(<li key={__key} className={classNames({"active":currentPage === totalPage})}>
                        <a onClick={(e)=>this.pageSelected(e,this_key)} data-num={totalPage}>{totalPage}</a></li>);
                }
            }
        }else {
            __key++;
            html.push(<li key={__key} className={"action-btn disabled"}><a>{0}</a></li>);
        }

        __key++;
        html.push(<li key={__key} className={classNames("action-btn next",{"disabled":next_disabled})}>
            <a className={"icon-arrow-right"} onClick={(e)=>{
                if (!next_disabled) this.pageSelected("btn",currentPage+1)
            }}><RightArrowIcon size={15}/></a>
        </li>);

        return html;
    }

    render() {
        const totalPage = Math.ceil(this.props.totalRecord / this.props.perPage);

        return (<div className="pagination-with-number">
            <div className={"d-flex"}>
                <div className={"pagination-pages"}><ul>{totalPage > 1 && this.createPage()}</ul></div>
                <div className={"showing-result"}><div className={"middle"}><span className={"showNumber"}>{"Showing"}: {(this.props.totalRecord > 0 ? (totalPage > 1 ? (this.props.perPage*(this.props.currentPage - 1) + 1) : 1) : 0) + "-" + (this.props.perPage*this.props.currentPage < this.props.totalRecord ?  (this.props.perPage*this.props.currentPage) : this.props.totalRecord)} {"of "} { this.props.totalRecord  }</span></div></div>
            </div>
        </div>)
    }
}
