import {createReducer} from "core/utils/create_reducer";
import {USER_DETAIL_RECEIVED, USER_RECEIVED} from "./constant";
import {combineReducers} from "redux";


const list = createReducer([], {
    [USER_RECEIVED]: (state, opts) => {
        if (Object.keys(opts.items).length) {
            return opts.items;
        }
        return {...state};
    }
})

const detail = createReducer([], {
    [USER_DETAIL_RECEIVED]: (state, opts) => {
        if ( Object.keys(opts.data).length) {
            return opts.data;
        }
        return {...state};
    }
})


export default combineReducers({
    list,
    detail,
})