/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import useQuery from "core/components/query";
import ChartsV2 from "core/components/v2/charts";
import { ChartType, NULL_SERIES } from "core/components/v2/charts/models";
import { granularityInterval } from "core/utils/chart_helper";
import dayjs from "dayjs";
import { PointOptionsObject, SeriesOptionsType } from "highcharts";
import React, { useEffect, useState } from "react";
import { DateRange } from "views/layouts/app/routes/model";
import { generateHashAndMetricColor } from "views/modules/helper";
import {
  BuilderNestedProps,
  IsAreaChart,
  IsBarChart,
  ResourceViewOptions,
  TimeSeriesData,
  getIntValue,
} from "../../../../../views/modules/builder/entities/builder.entities";
import {
  RenderTpl,
  getValueFromConfig,
} from "../../../../../views/modules/builder/entities/tpl.entities";
import { BuilderChartPoint, DefaultGroupName } from "./entities";

interface BaseChartProps {
  timeseriesData: TimeSeriesData;
  allowAllLegend?: boolean;
  chartType:
    | "column"
    | "area"
    | "spline"
    | "pie"
    | "scatter"
    | "treemap"
    | "heatmap"
    | "tilemap"
    | string;
  resource?: ResourceViewOptions;
  nestedProps?: BuilderNestedProps;
  timeStampData?: boolean;
  xAxisCustomFormat?: (val: string | number) => string;
  onDateChange?: (range: DateRange) => void;
}
interface IdxElementType {
  /**
   * Used any because of un even type of every different keys, and we have random keys
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
  timestamp: number | string;
  tooltip: {
    timestamp: number | string;
    interval: number;
    tooltipText: Record<string, number | string>;
  };
}
const noData = {
  key: "-1",
  label: "No data found during this current time period.",
  color: "red",
};

const ComboChartV2 = (props: BaseChartProps) => {
  const query = useQuery();
  const { timeseriesData, timeStampData = true, onDateChange } = props;
  const [seriesData, setSeriesData] = useState<{
    max: number;
    points: BuilderChartPoint[];
    yAxisConfig: RenderTpl | undefined;
    secondyAxisConfig: RenderTpl | undefined;
    series: SeriesOptionsType[];
    format: string;
  }>();
  useEffect(() => {
    const chartData = generateData();
    setSeriesData(chartData);
  }, [timeseriesData]);
  let chartType = props.chartType;
  if (props?.nestedProps?.chartType) {
    chartType = props.nestedProps.chartType;
  }
  let from = 0;
  if (timeseriesData?.timeRange?.fromTs) {
    from = timeseriesData?.timeRange?.fromTs;
  } else if (query.get("from_ts")) {
    from = Number(query.get("from_ts"));
  }
  let to = 0;
  if (timeseriesData?.timeRange?.toTs) {
    to = timeseriesData?.timeRange?.toTs;
  } else if (query.get("to_ts")) {
    to = Number(query.get("to_ts"));
  }
  const getColorGroups = () => {
    const colorGroups: Record<string, string> = {};
    if (props.nestedProps?.columnConfig) {
      const config = props.nestedProps.columnConfig;
      Object.keys(config).forEach((metricName) => {
        const mConfig = config[metricName];
        if (mConfig.color) {
          colorGroups[metricName] = mConfig.color;
        }
      });
    }
    return colorGroups;
  };

  const generateData = (): {
    max: number;
    points: BuilderChartPoint[];
    yAxisConfig: RenderTpl | undefined;
    secondyAxisConfig: RenderTpl | undefined;
    series: SeriesOptionsType[];
    format: string;
  } => {
    const points: BuilderChartPoint[] = [];
    // let timestamps = timeseriesData?.timestamps || {};
    const processedData = timeseriesData || {};
    let max = 0;
    let config: RenderTpl | undefined = undefined;
    let secondconfig: RenderTpl | undefined = undefined;
    const chartIdxData: Record<string, IdxElementType> = {};

    const metricNamesGroups: Record<string, number> = {};
    const metricNamesConfigs: Record<string, RenderTpl> = {};
    if (processedData && Object.keys(processedData.chartGroups).length > 0) {
      const _colorGroups = getColorGroups();
      Object.keys(processedData.chartGroups).forEach((groupName) => {
        if (groupName === "-1") {
          return;
        }
        const metricGroup = processedData.chartGroups[groupName] || {};
        const metricsCount = Object.keys(metricGroup).length;
        Object.keys(metricGroup).forEach((metricName) => {
          let color: string;
          let colorGroupName = groupName;
          if (colorGroupName === DefaultGroupName) {
            colorGroupName = metricName;
          }
          if (_colorGroups[colorGroupName] || _colorGroups[metricName]) {
            color = _colorGroups[colorGroupName] || _colorGroups[metricName];
          } else {
            color = generateHashAndMetricColor(groupName).color;
          }
          let tooltipTitleKey = groupName + "-" + metricName;
          if (metricsCount == 1) {
            tooltipTitleKey = groupName;
          }
          if (groupName === DefaultGroupName) {
            const configName =
              props.nestedProps?.columnConfig?.[metricName]?.title;
            tooltipTitleKey = configName ? configName : metricName;
          }
          points.push({
            dataKey:
              tooltipTitleKey === noData.key ? noData.label : tooltipTitleKey,
            title: groupName === noData.key ? noData.label : groupName,
            color: tooltipTitleKey === noData.key ? noData.color : color,
          });

          metricNamesGroups[tooltipTitleKey] = 0;
          if (metricGroup[metricName]?.length) {
            metricGroup[metricName].forEach((record, recordIndex) => {
              if (recordIndex === 0 && !config) {
                if (record.config.config) {
                  config = record.config;
                } else {
                  config = props.nestedProps?.columnConfig?.[metricName]?.tpl;
                }
              }
              metricNamesConfigs[tooltipTitleKey] = config?.config
                ? config
                : record.config;
              const foundSecondAxis = props.nestedProps?.types?.length
                ? props.nestedProps?.types.find(
                    (it) => it.onSecondaryAxis && it.title === metricName
                  )
                : undefined;
              if (foundSecondAxis && recordIndex === 0 && !secondconfig) {
                if (record.config.config) {
                  secondconfig = record.config;
                } else {
                  secondconfig =
                    props.nestedProps?.columnConfig?.[metricName]?.tpl;
                }
                metricNamesConfigs[tooltipTitleKey] = secondconfig?.config
                  ? secondconfig
                  : record.config;
              }

              metricNamesGroups[tooltipTitleKey] = 0;
              const timestamp = record.timestamp;
              let idxElement: IdxElementType = chartIdxData[timestamp];
              if (!idxElement) {
                idxElement = {
                  timestamp: timestamp,
                  tooltip: {
                    timestamp,
                    interval: 0,
                    tooltipText: {},
                  },
                };
              }
              if (groupName === noData.key) {
                idxElement.tooltip.tooltipText[noData.label] = "";
                idxElement[noData.label] = "";
                chartIdxData[timestamp] = idxElement;
              } else {
                const chartValue =
                  Number(record.value) >= 0
                    ? getIntValue(record.value)
                    : undefined;
                if (chartValue) {
                  idxElement.tooltip.tooltipText[tooltipTitleKey] =
                    getValueFromConfig(
                      chartValue,
                      foundSecondAxis && secondconfig
                        ? secondconfig
                        : config
                          ? config
                          : record.config
                    );
                  idxElement[tooltipTitleKey] = record.value;
                  if (chartValue && max < chartValue) {
                    max = chartValue;
                  }
                }

                chartIdxData[timestamp] = idxElement;
              }
            });
          } else {
            const timestamp = from;
            chartIdxData[timestamp] = {
              timestamp: timestamp,
              tooltip: {
                timestamp,
                interval: 0,
                tooltipText: {},
              },
            };
          }
        });
      });
    }
    const int = timeseriesData?.timeRange?.interval
      ? timeseriesData?.timeRange?.interval
      : granularityInterval(from, to);
    const interval = int * 1000;
    if (timeStampData && chartIdxData && Object.keys(chartIdxData).length) {
      const fromTs = Math.round(from / interval) * interval;
      const toTs = Math.round(to / interval) * interval;
      const steps = Math.ceil((toTs - fromTs) / interval);
      for (let i = 0; i <= steps - 2; i++) {
        const timestamp = fromTs + (i + 1) * interval;
        if (typeof chartIdxData[timestamp] !== "undefined") {
          if (
            Object.keys(chartIdxData[timestamp]).length - 2 !=
            Object.keys(metricNamesGroups).length
          ) {
            Object.keys(metricNamesGroups).forEach((metricName) => {
              if (
                chartIdxData[timestamp] &&
                !chartIdxData[timestamp][metricName] &&
                typeof chartIdxData[timestamp][metricName] === "undefined"
              ) {
                chartIdxData[timestamp].tooltip.tooltipText[metricName] =
                  getValueFromConfig(0, metricNamesConfigs[metricName]);
                chartIdxData[timestamp][metricName] = null;
              }
            });
          }
        } else {
          const idxElement: IdxElementType = {
            timestamp: timestamp,
            tooltip: {
              timestamp,
              interval: 0,
              tooltipText: {},
            },
          };
          Object.keys(metricNamesGroups).forEach((metricName) => {
            if (idxElement.tooltip) {
              idxElement.tooltip.tooltipText[metricName] = getValueFromConfig(
                0,
                metricNamesConfigs[metricName]
              );
            }
            idxElement[metricName] = null;
          });
          chartIdxData[timestamp] = idxElement;
        }
      }
    }
    /**
     * Used any because of un even type of every different keys, and we have random keys
     */
    const objData: Record<
      string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [string | number, any, Record<string, string | number>][]
    > = {};
    Object.keys(chartIdxData)
      .sort()
      .reduce((obj: Record<string, IdxElementType>, key) => {
        obj[key] = chartIdxData[key];
        if (
          chartIdxData?.[key] &&
          Object.keys(chartIdxData[key] as object).length
        ) {
          Object.keys(chartIdxData[key] as object).map((it) => {
            if (objData[it]?.length) {
              objData[it].push([
                !Number.isNaN(Number(key)) ? Number(key) : key,
                chartIdxData[key][it],
                chartIdxData[key].tooltip?.tooltipText,
              ]);
            } else {
              objData[it] = [
                [
                  !Number.isNaN(Number(key)) ? Number(key) : key,
                  chartIdxData[key][it],
                  chartIdxData[key].tooltip.tooltipText,
                ],
              ];
            }
          });
        }

        return obj;
      }, {});
    const series: SeriesOptionsType[] = [];

    Object.keys(objData).map((key) => {
      const foundKeyNested = props.nestedProps?.types?.findIndex(
        (it) => it.title === key
      );
      const foundKey = points.findIndex((i) => i.dataKey === key);
      const foundSecondAxis = props.nestedProps?.types?.length
        ? props.nestedProps?.types.find(
            (it) => it.onSecondaryAxis && it.title === key
          )
        : undefined;
      const yaxisIdObj: {
        yAxis?: number;
      } = {};
      if (foundSecondAxis) {
        yaxisIdObj.yAxis = 1;
      }
      if (foundKey > -1) {
        series.push({
          showInLegend: false,
          // `type: column` is required for type-checking this options as a column series
          type:
            Number(foundKeyNested) >= 0 && props.nestedProps?.types?.length
              ? props.nestedProps.types[Number(foundKeyNested)].type
              : "spline",
          name: key,
          data: objData[key],
          color: points[foundKey].color,
          marker: {
            enabled: true,
            radius: 1,
            symbol: "circle",
            states: {
              hover: {
                enabled: true,
                radius: 5, // Increase the radius on hover for better visibility
              },
            },
          },
          showInNavigator: true,
          dataGrouping: {
            units: [
              ["day", [1]],
              ["hours", [1]],
              ["minutes", [1]],
            ],
            forced: true,
            enabled: true,
            groupAll: true,
          },
          ...yaxisIdObj,
        } as SeriesOptionsType);
      }
    });
    const newSeries: Record<string, number> = {};
    for (const element of series) {
      const newEle: SeriesOptionsType & {
        stickyTracking?: boolean | undefined;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data?: PointOptionsObject | (number | object | any[])[] | undefined;
        tooltip?: {
          enabled?: boolean;
        };
      } = { ...element };
      if (Array.isArray(newEle.data) && newEle.data?.length) {
        for (const ele of newEle.data) {
          if (Array.isArray(ele)) {
            const x = Number(ele[0]);
            const y = Number(ele[1]);
            if (!y && !newSeries[x]) newSeries[x] = x;
            if (y && newSeries[x]) delete newSeries[x];
          }
        }
      }
    }
    if (newSeries && Object.keys(newSeries).length) {
      const scatterData = Object.values(newSeries);
      const find = series.find((it) => it.name === NULL_SERIES);
      if (!find) {
        series.push({
          type: "scatter",
          name: NULL_SERIES,
          showInLegend: false,
          enableMouseTracking: true,
          data: scatterData.map((it) => [it, 0, {}]),
          lineWidth: 0,
          marker: {
            enabled: false,
            states: {
              hover: {
                lineWidthPlus: 0,
                enabled: false,
              },
            },
          },
        });
      }
    }
    let format = "HH:mm";
    if (timeStampData) {
      const timestamps = Object.keys(chartIdxData).map((i) => {
        const ii = parseInt(i);
        return chartIdxData[ii].timestamp;
      });
      const first = timestamps[0];
      const last = timestamps[timestamps.length - 1];
      const diffInMinutes = (Number(last) - Number(first)) / 60000;
      if (diffInMinutes < 60) {
        format = "HH:mm:ss";
      } else if (diffInMinutes < 1440) {
        format = "HH:mm";
      } else {
        format = "MMM DD HH:mm";
      }
    }

    return {
      max,
      points,
      yAxisConfig: config,
      secondyAxisConfig: secondconfig,
      series,
      format,
    };
  };

  const chartData = seriesData;
  let chartTypeProp = ChartType.LINE;
  if (IsBarChart(chartType)) chartTypeProp = ChartType.COLUMN;
  if (IsAreaChart(chartType)) chartTypeProp = ChartType.AREA;

  return chartData ? (
    <ChartsV2
      chartType={chartTypeProp}
      series={chartData.series}
      yAxsisVisible
      resource={props?.resource}
      showLegend={props?.nestedProps?.showLegend}
      yAxisConfig={chartData.yAxisConfig}
      multipleYAxis={true}
      secondyAxisConfig={chartData.secondyAxisConfig}
      yAxsisTitle={props?.nestedProps?.yAxisTitle}
      secondYAxisTitle={props?.nestedProps?.secondYAxisTitle}
      xAxisFormatter={
        timeStampData
          ? function (ctx) {
              return dayjs(ctx.value).format(chartData.format);
            }
          : function (ctx) {
              if (props?.xAxisCustomFormat) {
                return props?.xAxisCustomFormat(ctx.value);
              }
              return ctx.value.toString();
            }
      }
      chartHeight={props.nestedProps?.chartHeight}
      xType={timeStampData ? undefined : "category"}
      onDateChange={onDateChange}
    />
  ) : null;
};

export default ComboChartV2;
