import { isDebugEnabled } from "core/application/utils";
import { applyMiddleware, legacy_createStore as createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import CombinedReducers from "./reducers";

const InitialState = {};

const Store = createStore(
  CombinedReducers,
  InitialState,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);

if (isDebugEnabled()) {
  // (window as any).Store = Store as any;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/unbound-method, @typescript-eslint/no-explicit-any
  (window as any).getState = Store.getState;
}

export default Store;
