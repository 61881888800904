import { getTenantUrl } from "core/application/utils";
import AppContext from "core/components/wrapper/context";
import { useContext } from "react";
import { Languages } from ".";
import { ApplicationDetails } from "./mobile-installation";

export const getSteps = (
  language: Languages,
  applicationDetails: ApplicationDetails
) => {
  const context = useContext(AppContext);
  const targetUrl = `https://${getTenantUrl(context.user)}`;

  if (language === Languages.IOS) {
    return [
      {
        heading:
          "Add the following dependencies section in Package.swift file.",
        info: `.package(url: "https://github.com/middleware-labs/middleware-ios"),`,
      },
      {
        heading:
          "To use Middleware SDK add the following in targets in Package.swift file.",
        info: `.target(name: "YourApp", dependencies: [
  .product(name: "MiddlewareRum", package: "middleware-ios")
],`,
      },
      {
        heading:
          "Add below code file in your iOS application. This will instantiate Middleware SDK.",
        info: `import MiddlewareRum 
        
MiddlewareRumBuilder()  
  .globalAttributes(["customerId" : "123456"])   
  .target("${targetUrl}")
  .serviceName("${applicationDetails.app_name}")  
  .projectName("${applicationDetails.app_name}")   
  .rumAccessToken("${applicationDetails.token}")  
  .deploymentEnvironment("PROD")   
  .build()`,
      },
    ];
  } else if (language === Languages.Android) {
    return [
      {
        heading: "Add the following dependency in build.gradle file.",
        info: `implementation 'io.github.middleware-labs:android-sdk:+`,
      },
      {
        heading:
          "Place below class file in your Android application. This will instantiate Middleware SDK.",
        info: `import static io.middleware.android.sdk.utils.Constants.APP_VERSION;            
import android.app.Application;
import java.time.Duration;
import io.middleware.android.sdk.Middleware;
import io.opentelemetry.api.common.Attributes;

public class MiddlewareApplication extends Application {   
  private final String targetUrl = "${targetUrl}";    
  private final String rumAccessToken = "${applicationDetails.token}";    
  
  @Override    
  public void onCreate() {        
    super.onCreate();

    Middleware.builder()            
      .setGlobalAttributes(Attributes.of(APP_VERSION, BuildConfig.VERSION_NAME))            
      .setTarget(targetUrl)            
      .setServiceName("${applicationDetails.app_name}")            
      .setProjectName("${applicationDetails.app_name}")            
      .setRumAccessToken(rumAccessToken)            
      .setSlowRenderingDetectionPollInterval(Duration.ofMillis(1000))            
      .setDeploymentEnvironment("PROD")            
      .build(this);
  }
}`,
      },
    ];
  } else {
    return [
      {
        heading: "Install the library using either npm or yarn.",
        info: `# yarn   
yarn add @middleware.io/middleware-react-native`,
      },
      {
        heading: "Initialize the library in your app lifecycle.",
        info: `import { MiddlewareWrapper, type ReactNativeConfiguration } from '@middleware.io/middleware-react-native';   

const MiddlewareConfig: ReactNativeConfiguration = {    
  serviceName: '${applicationDetails.app_name}',    
  projectName: '${applicationDetails.app_name}',   
  accountKey: '${applicationDetails.token}',    
  target: '${targetUrl}',    
  deploymentEnvironment: 'PROD',    
  
  globalAttributes: {        
    name: "${context.user?.full_name}",   
  },
};

export default function App() {    
  return (      
    <MiddlewareWrapper configuration={MiddlewareConfig}>        
    // Application Components      
    </MiddlewareWrapper>    
  );
}`,
      },
    ];
  }
};
