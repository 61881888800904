import InstallationSteps from "../../components/steps";
import AppSelection, {
  AppSelectionTabs,
} from "core/components/v2/app-selection";
import Button from "core/components/v2/button";
import CardSelection from "core/components/v2/card-selection";
import { Message } from "core/components/v2/enums";
import {
  LinkToDoc,
  MiddlewareIcon,
  RefreshIcon,
} from "core/components/v2/svg/icons";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useEffect, useState } from "react";
import Command from "../../components/code-block/command";
import MwMessage from "../../components/message";
import ViewYourData from "../../pages/common/view-data-buttons";

import { useDispatch } from "react-redux";
import StepItem from "../../components/steps/step-item";

import {
  AngularIcon,
  GatsbyIcon,
  NextJSIcon,
  ReactIcon,
  VueIcon,
} from "core/components/v2/svg/language-icons";

import StatusBadge from "core/components/status-badge";
import { useSelector } from "react-redux";
import { vercelStatusReset } from "store/integration/actions";
import {
  requestVercelListProjects,
  requestVercelStatus,
  requestVercelSyncProjects,
} from "store/integration/api";
import {
  getVercelInflight,
  getVercelProjects,
  getVercelStatus,
} from "store/integration/selectors";
import { getThemesMode } from "store/selectors";
import { showToast } from "store/toast-alerts/actions";
import { StepsEnum } from "views/modules/integrations/entity";
import FooterButtons from "../../components/footer-buttons";

interface MwLinkCardProps {
  appStatus: string;
  title: string;
  link: string;
}

const MwLinkCard: React.FC<MwLinkCardProps> = ({ appStatus, title, link }) => {
  return (
    <div className="vercel-card-box">
      {/* <Link to="https://vercel.com/integrations/middleware" target="_blank"> */}
      <div
        className="vercel-card"
        onClick={() => {
          window.open(`${link}`, "_blank");
        }}
      >
        <div className="content">
          <div className="middleware-icon">
            <MiddlewareIcon size={40} />
          </div>
          <div className="middleware-title">
            <h2>{title}</h2>
            <StatusBadge>Observability</StatusBadge>
          </div>
          <div className="middleware-vercel-link">
            <LinkToDoc color="var(--color-primary)" size={12} />
          </div>
        </div>
        <div className="description">
          AI-powered cloud observability platform.
        </div>
      </div>
      {/* </Link> */}
      {appStatus == "vercel_integration_found" && (
        <MwMessage
          type={Message.Success}
          message="Middleware app is already installed"
        ></MwMessage>
      )}
      {appStatus == "vercel_integration_bad_access_token" && (
        <MwMessage
          type={Message.Warning}
          message=" Middleware app hasn't been installed properly,
                    Please re-install it."
        ></MwMessage>
      )}
    </div>
  );
};

export default MwLinkCard;