import {
  Chart,
  TooltipFormatterCallbackFunction,
  TooltipFormatterContextObject,
} from "highcharts";
import { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import useIsclicked from "core/components/v2/charts/components/isClicked";

const generateTooltipId = (chartId: string) =>
  `highcharts-custom-tooltip-${chartId}`;

interface Props {
  chart: Chart | null;
  children(
    formatterContext: TooltipFormatterContextObject,
    isAltClicked?: boolean
  ): JSX.Element;
}

export const Tooltip = ({ chart, children }: Props) => {
  const isInit = useRef(false);
  const { isAltClicked } = useIsclicked();
  const [context, setContext] = useState<TooltipFormatterContextObject | null>(
    null
  );
  useEffect(() => {
    if (chart) {
      const formatter: TooltipFormatterCallbackFunction = function () {
        if (!isInit.current) {
          isInit.current = true;

          chart.tooltip.refresh.apply(chart.tooltip, [this.point]);
          // chart.tooltip.hide(0);
        }else{
          isInit.current=false;
        }

        setContext(this);
        return `<div id="${generateTooltipId(chart?.container?.id)}"></div>`;
      };

      chart.update({
        tooltip: {
          formatter,
          useHTML: true,
          outside: true,
          shared: true,
          followPointer: true,
          enabled: true,
        },
      });
    }
  }, [chart?.tooltip]);

  const node =
    chart && document.getElementById(generateTooltipId(chart?.container?.id));
  return node && context
    ? ReactDOM.createPortal(children(context, isAltClicked), node)
    : null;
};
