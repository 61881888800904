import { copyToClipboard } from "core/application/utils";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showToast } from "store/toast-alerts/actions";
import { formatLatency, getRandomColor, truncateText } from "../../utils";
import moment from "moment";
import TableGridview, {
  GridviewColumnsOptions,
} from "core/components/table-gridview";
import { requestErrorTraces, requestTraceLogs } from "store/apm/api";
import { requestRumSession } from "store/rum/api";
import Card from "core/components/card";
import Skeleton from "react-loading-skeleton";
import NavTab from "core/components/nav-tab";
import TabItems from "core/components/nav-tab/items";
import { FilterParams, QueryParams } from "views/layouts/app/routes/model";
import ActionMenu from "core/components/action-menu";
import PopoverMenuItem from "core/components/popover/menu-item";
import CollapsibleTable from "../../helper/collapsible-table/collapsible-table";
import ReactJson from "react-json-view";
import Dialog from "core/components/dialog";
import LogInfoPanel from "views/modules/logs/dialog-panel/log.panel";
import RumErrorPanel from "views/modules/rum/panel/error-panel";
import SessionPanel from "views/modules/rum/panel/session-panel";
import { generateHashAndMetricColor } from "views/modules/helper";
import CollapsibleJSON from "../../../apm-v2/modules/spans/components/panel/components/active-span/json-viewer/json-viewer";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import {
  AddUpdateWidgetBuilder,
  CustomWidget,
  defaultQuery,
  FilterOpEnum,
  ORDER_BY_METRICS,
  Query,
} from "views/modules/builder/entities/builder.entities";
import { requestBuilderFormattedData } from "store/widgets/api";
import { Span } from "../../helper/modal/apm.modals";
import Flamegraph from "../../helper/span-flamegraph";
import SessionReplay from "views/modules/rum/components/session-replay";
import StatusBadge from "core/components/status-badge";
import "./_style.scss";

const SpanPanel = (props?: {
  trace: any;
  themeMode: string;
  disableSessionReplayTab?: boolean;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routerContext = useContext(MwRouteContext);

  const [selectedTrace, setSelectedTrace]: any = useState(null);
  const [traceDetails, setTraceDetails]: any = useState({
    is_loading: false,
    data: [],
  });
  const [rumSession, setRumSession]: any = useState({
    is_loading: false,
    data: {},
  });
  const [allAttr, setAllAttr]: any = useState(null);
  const [activeSpan, setActiveSpan]: any = useState(null);
  const [events, setEvents]: any = useState(null);
  const [totalDuration, setTotalDuration]: any = useState(null);
  const [services, setServices]: any = useState(null);
  const [spanList, setSpanList]: any = useState(null);
  const [spanLogs, setSpanLogs]: any = useState({
    is_loading: false,
    data: [],
  });
  const [selectedServiceExec, setSelectedServiceExec]: any =
    useState("Exec time %");
  const [rumSessionId, setRumSessionId] = useState(null);
  const [page, setPage] = useState(1);
  const [errPage, setErrPage] = useState(1);
  const [logSpan, setLogSpan] = useState(0);
  const [infoOpen, setInfoOpen] = useState(false);
  const [spanTs, setSpanTs] = useState(new Date().getTime());
  const [traceErrors, setTraceErrors]: any = useState({
    is_loading: false,
    data: [],
  });
  const [errorPanelData, setErrorPanelData] = useState(null);
  const [errPanelOpen, setErrPanelOpen] = useState(false);
  const [flameGraphWidth, setFlameGraphWidth] = useState(1000);
  const [sessionDialog, setSessionDialog] = useState(false);
  const [sessionDialogData, setSessionDialogData] = useState<any>(null);

  const getTraceDetails = useCallback(() => {
    setTraceDetails((prevState: any) => ({
      ...prevState,
      is_loading: true,
    }));

    const userQuery: Query = JSON.parse(JSON.stringify(defaultQuery));

    userQuery.columns = [
      "db.system",
      "durationNano",
      "timestampNs",
      "events",
      "hasError",
      "name",
      "resource_attributes",
      "tagMap",
      "parentSpanID",
      "spanID",
      "traceID",
    ];

    userQuery.source.name = "trace";

    userQuery.with = [
      {
        key: "ATTRIBUTE_FILTER",
        value: {
          traceID: {
            "=": props?.trace?.trace_id || "",
          },
        },
        is_arg: true,
      },
      {
        key: ORDER_BY_METRICS,
        value: {
          timestampNs: "ASC",
        },
        is_arg: true,
      },
    ];

    const resource = {
      columns: [
        "db.system",
        "durationNano",
        "timestampNs",
        "events",
        "hasError",
        "name",
        "resource_attributes",
        "tagMap",
        "parentSpanID",
        "spanID",
        "traceID",
      ],
      resourceType: "trace",
      name: `span-list-filter-by-traceId`,
      widgetAppId: 5,
    };

    const _params = [
      { key: "from_ts", value: props?.trace?.start_date },
      { key: "to_ts", value: props?.trace?.end_date },
    ];

    if (routerContext.params.debug) {
      _params.push({ key: "debug", value: "true" });
    }

    const widgetRequest: CustomWidget = {
      label: "",
      builderId: 0,
      scopeId: 0,
      widgetAppId: 5,
      builderConfig: userQuery,
      builderViewOptions: {
        resource,
      },
      params: _params,
    };

    const data: AddUpdateWidgetBuilder = {
      action: "add",
      body: widgetRequest,
      inflight: true,
    };

    dispatch(
      requestBuilderFormattedData(data, (status: boolean, res: any) => {
        if (!res.inflight) {
          if (res.chart_data.data.length > 0) {
            const traces = res.chart_data.data;

            const currentTrace = traces.find(
              (i: any) => i.spanID === props?.trace?.span_id
            );

            if (!props?.disableSessionReplayTab) {
              const tagMapWithRumSession = traces
                .map((i: any) => i.tagMap)
                .find((i: any) => i["session.id"]);
              if (tagMapWithRumSession) {
                setRumSessionId(tagMapWithRumSession["session.id"]);
              }
            }

            setSelectedTrace(currentTrace);
            setActiveSpan(currentTrace);

            setAllAttr({
              ...currentTrace?.tagMap,
              ...currentTrace?.resource_attributes,
            });

            if (currentTrace?.events && currentTrace.events.length > 0) {
              setEvents(currentTrace.events[0]);
            }

            setTraceDetails({
              is_loading: false,
              data: traces,
            });

            const parentSpan = traces.find(
              (trace: Span) => trace.parentSpanID === ""
            );
            if (parentSpan) setTotalDuration(parentSpan.durationNano);
            else setTotalDuration(0);

            const serviceExec = createServiceMap(traces);
            setServices(serviceExec);

            const spanList = getSpanList(traces);
            setSpanList(spanList);
          }
        }
      })
    );
  }, []);

  useEffect(() => {
    getTraceDetails();
    getTraceLogs(props?.trace?.trace_id, props?.trace?.span_id, page);
    getTraceErrors(props?.trace?.trace_id, props?.trace?.span_id, errPage);
  }, [props]);

  useEffect(() => {
    rumSessionId && getRumSession(rumSessionId);
  }, [rumSessionId]);

  const getSpanList = (data: any) => {
    const serviceMap: any = new Map();

    let totalDuration = 0;

    for (const item of data) {
      const serviceName = item?.tagMap["service.name"] || "";

      if (serviceName !== "") {
        if (serviceMap.has(serviceName)) {
          const service = serviceMap.get(serviceName)!;
          const duration = item.durationNano;

          const child = {
            name: item.name,
            avg_duration: duration,
            duration: duration,
            span_count: 1,
            execution_time_percentage: "0%",
            span_id: item.spanID,
            timestamp: item.timestampNs,
          };

          service.children.push(child);

          service.avg_duration =
            (service.avg_duration * service.span_count + duration) /
            (service.span_count + 1);
          service.span_count += 1;
          service.execution_time += duration;

          child.execution_time_percentage =
            ((duration / service.execution_time) * 100).toFixed(2) + "%";
        } else {
          const duration = item.durationNano;

          const newService: any = {
            service_name: serviceName,
            children: [
              {
                name: item.name,
                avg_duration: duration,
                duration: duration,
                span_count: 1,
                execution_time_percentage: "0%",
                span_id: item.spanID,
                timestamp: item.timestampNs,
              },
            ],
            avg_duration: duration,
            span_count: 1,
            execution_time: duration,
            execution_time_percentage: "0%",
            span_id: item.spanID,
            timestamp: item.timestampNs,
          };

          serviceMap.set(serviceName, newService);
        }

        totalDuration += item.durationNano;
      }
    }

    for (const service of serviceMap.values()) {
      service.execution_time_percentage =
        ((service.execution_time / totalDuration) * 100).toFixed(2) + "%";

      for (const child of service.children) {
        child.execution_time_percentage =
          ((child.duration / service.execution_time) * 100).toFixed(2) + "%";
      }
    }

    return Array.from(serviceMap.values());
  };

  const getRumSession = (sessionId: string) => {
    const data = {
      session_id: sessionId,
      start_date: props?.trace?.start_date,
      end_date: props?.trace?.end_date,
    };

    setRumSession((prevState: any) => ({
      ...prevState,
      is_loading: true,
    }));

    dispatch(
      requestRumSession(data, (status, res) => {
        if (status) {
          if (res.item) {
            setRumSession({
              is_loading: false,
              data: {
                ...res.item,
                start_date: Math.floor(res.item.last_value) - 4 * 60 * 60 * 1e9,
                end_date: Math.floor(res.item.last_value) + 4 * 60 * 60 * 1e9,
              },
            });
          } else {
            setRumSession({
              is_loading: false,
              data: {},
            });
          }
        }
      })
    );
  };

  const createServiceMap = (spans: any) => {
    const serviceMap = new Map();
    let totalDuration = 0;

    for (const trace of spans) {
      const serviceName = trace?.tagMap["service.name"] || "";
      const duration = trace.durationNano;

      totalDuration += duration;

      if (serviceMap.has(serviceName)) {
        const service = serviceMap.get(serviceName);
        service.spans += 1;
        service.duration += duration;
        serviceMap.set(serviceName, service);
      } else {
        serviceMap.set(serviceName, { spans: 1, duration });
      }
    }

    return Array.from(serviceMap, ([name, value]) => ({
      service_name: name,
      number_of_spans: value.spans,
      execution_time_percentage:
        ((value.duration / totalDuration) * 100).toFixed(2) + "%",
    }));
  };

  const selectServiceExec = (e: any) => {
    setSelectedServiceExec(e);
  };

  const getTraceLogs = (trace_id: any, span_id: any, page: number) => {
    const data = {
      trace_id: trace_id,
      span_id: span_id,
      start_date: props?.trace?.start_date,
      end_date: props?.trace?.end_date,
      page,
      limit: 20,
    };

    setSpanLogs((prevState: any) => ({
      ...prevState,
      is_loading: true,
    }));

    dispatch(
      requestTraceLogs(data, (status: boolean, res: any) => {
        if (status) {
          if (res.data && res.data.length > 0) {
            setSpanLogs({
              is_loading: false,
              data: res.data,
            });
          } else {
            setSpanLogs({
              is_loading: false,
              data: [],
            });
          }
        }
      })
    );
  };

  const getTraceErrors = (trace_id: any, span_id: any, page: number) => {
    const data = {
      trace_id: trace_id,
      span_id: span_id,
      start_date: props?.trace?.start_date,
      end_date: props?.trace?.end_date,
      page,
      limit: 20,
    };

    setTraceErrors((prevState: any) => ({
      ...prevState,
      is_loading: true,
    }));

    dispatch(
      requestErrorTraces(data, (status: boolean, res: any) => {
        if (status) {
          if (res.data && res.data.length > 0) {
            setTraceErrors({
              is_loading: false,
              data: res.data,
            });
          } else {
            setTraceErrors({
              is_loading: false,
              data: [],
            });
          }
        }
      })
    );
  };

  const pageCallback = (a0: any) => {
    let _page: any = page;

    if (page >= 1) {
      if (a0 == "next") _page = parseInt(_page) + 1;
      else _page = parseInt(_page) - 1;
    }

    setPage(_page);

    getTraceLogs(props?.trace?.trace_id, props?.trace?.span_id, _page);
  };

  const errTracepageCallback = (a0: any) => {
    let _page: any = page;

    if (page >= 1) {
      if (a0 == "next") _page = parseInt(_page) + 1;
      else _page = parseInt(_page) - 1;
    }

    setErrPage(_page);

    getTraceErrors(props?.trace?.trace_id, props?.trace?.span_id, _page);
  };

  const columns: GridviewColumnsOptions[] = [
    {
      title: "RECORDED AT",
      accessor: "timestamp_ms",
      value: (data: any) => {
        return (
          <div>
            <span
              style={{
                backgroundColor: `${getRandomColor()}`,
                borderRadius: "10px",
                padding: "2px",
                marginRight: "5px",
              }}
            ></span>
            {`${moment(data.timestamp_ms).format("YYYY-MM-DD HH:mm:ss")}`}
          </div>
        );
      },
    },
    {
      title: "SEVERITY TEXT",
      accessor: "severity_text",
    },
    {
      title: "SEVERITY NUMBER",
      accessor: "severity_number",
    },
    {
      title: "BODY",
      accessor: "body",
    },
  ];

  const traceErrColumns: GridviewColumnsOptions[] = [
    {
      title: "RECORDER AT",
      accessor: "timestamp_ns",
      value: (data: any) => {
        return (
          <div>
            <span
              style={{
                backgroundColor: `${getRandomColor()}`,
                borderRadius: "10px",
                padding: "2px",
                marginRight: "5px",
              }}
            ></span>
            {`${moment(data.timestamp_ns / 1000000).format(
              "YYYY-MM-DD HH:mm:ss"
            )}`}
          </div>
        );
      },
    },
    {
      title: "SPAN ID",
      accessor: "span_id",
    },
    {
      title: "EXCEPTION MESSAGE",
      accessor: "exception_message",
    },
    {
      title: "EXCEPTION TYPE",
      accessor: "exception_type",
    },
  ];

  const goToService = () => {
    const { service_name, start_date, end_date } = props?.trace;

    if (service_name && service_name !== "") {
      const path = `/apm/services?from_ts=${start_date}&to_ts=${end_date}&service=${service_name}&tab=resources`;
      navigate(path);
    }
  };

  const toggleErrorDialog = (dialogState: boolean, data: any) => {
    setErrPanelOpen(dialogState);
    if (data) {
      data = {
        name: data.exception_message,
        trace: data.exception_stack_trace,
        start_time: data.timestamp_ns,
        ...data,
      };
      setErrorPanelData(data);
    }
  };

  const toggleSessionDialog = (dialogState: boolean, data: any) => {
    setSessionDialog(dialogState);

    if (data) {
      data = {
        ...data,
        start_date: Math.floor(data.visited_on) - 4 * 60 * 60 * 1e9,
        end_date: Math.floor(data.last_value) + 4 * 60 * 60 * 1e9,
      };
      setSessionDialogData(data);
    }
  };

  const shareSession = (url: string) => {
    copyToClipboard(url).then(() => {
      dispatch(showToast("success", "Copied link to clipboard"));
    });
  };

  const [panelHeader, setPanelHeader] = useState({
    method: "",
    endpoint: "",
  });

  useEffect(() => {
    const { method, endpoint } = extractMethodAndEndpoint(selectedTrace);

    setPanelHeader({
      method: method,
      endpoint: endpoint,
    });
  }, [selectedTrace]);

  function extractMethodAndEndpoint(span: Span): {
    method: string;
    endpoint: string;
  } {
    const tagMap = span?.tagMap ?? {};
    if (tagMap && Object.keys(tagMap).length > 0) {
      if (tagMap["http.method"] && tagMap["http.url"]) {
        const method = tagMap["http.method"];
        const endpoint = tagMap["http.url"];
        return { method, endpoint };
      }

      if (tagMap["rpc.method"] && tagMap["rpc.service"]) {
        const method = tagMap["rpc.method"];
        const service = tagMap["rpc.service"];
        const endpoint = `/${service}/${method}`;
        return { method, endpoint };
      }
    }
    return { method: "", endpoint: "" };
  }

  const otherDetailDivRef = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [initialHeight, setInitialHeight] = useState(360);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (otherDetailDivRef.current) {
      setDragging(true);
      setStartY(event.clientY);
      setInitialHeight(otherDetailDivRef.current.offsetHeight);
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (dragging && otherDetailDivRef.current) {
      const deltaY = event.clientY - startY;
      const newHeight = initialHeight - deltaY;

      if (newHeight > 275 && newHeight <= 750) {
        otherDetailDivRef.current.style.height = `${newHeight}px`;
      }
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging]);

  return (
    <React.Suspense fallback={"dialog.loading..."}>
      <div className="main-span-panel">
        <Card
          className={"main-header"}
          bordered={false}
          borderRadius={false}
          borderBottom
          flexBox
          style={{
            padding: "4px 16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className={"heading"}>
            <div className="left">
              {selectedTrace && (
                <div className="resource-details">
                  {panelHeader.method !== "" ? (
                    <StatusBadge
                      labelText={`${panelHeader.method}`}
                      info
                      radius
                    />
                  ) : (
                    <StatusBadge
                      labelText={`${truncateText(selectedTrace.name, 70)}`}
                      info
                      radius
                    />
                  )}
                  {panelHeader.endpoint !== "" && (
                    <StatusBadge
                      labelText={`${panelHeader.endpoint}`}
                      success
                      radius
                    />
                  )}
                  <StatusBadge default className={"total-duration"} radius>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 1024 1024"
                      height="14"
                      width="14"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                      <path d="M686.7 638.6L544.1 535.5V288c0-4.4-3.6-8-8-8H488c-4.4 0-8 3.6-8 8v275.4c0 2.6 1.2 5 3.3 6.5l165.4 120.6c3.6 2.6 8.6 1.8 11.2-1.7l28.6-39c2.6-3.7 1.8-8.7-1.8-11.2z"></path>
                    </svg>

                    <span>
                      {totalDuration && formatLatency(totalDuration)} Total
                      Duration
                    </span>
                  </StatusBadge>
                  <StatusBadge normal className={"total-duration"} radius>
                    <span>
                      {props?.trace?.timestamp_ms &&
                        moment(props?.trace?.timestamp_ms / 1000000).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                    </span>
                  </StatusBadge>
                </div>
              )}
            </div>
          </div>
          <div className={"heading"}>
            {props?.trace?.service_name && props?.trace?.service_name != "" && (
              <div className={"service-button"} onClick={() => goToService()}>
                <span>View Service</span>
              </div>
            )}
            {!props?.disableSessionReplayTab &&
              rumSessionId &&
              rumSession &&
              !rumSession.is_loading &&
              Object.keys(rumSession.data).length > 0 && (
                <div
                  className={"service-button"}
                  onClick={() => toggleSessionDialog(true, rumSession.data)}
                >
                  <span>View Session</span>
                </div>
              )}
            {!props?.disableSessionReplayTab &&
              rumSessionId &&
              rumSession &&
              !rumSession.is_loading &&
              Object.keys(rumSession.data).length > 0 && (
                <div
                  className={"service-button"}
                  onClick={() =>
                    shareSession(
                      `${
                        window.location.origin
                      }${`/real-user-monitoring/${rumSession.data.application_name}?tab=session-replay&rumSessionId=${rumSession.data.session_id}&openRumSession=true&timestampToJumpVideo=${props?.trace?.timestamp_ms}`}`
                    )
                  }
                >
                  <span>Share Session</span>
                </div>
              )}
          </div>
        </Card>

        <div className="panel-body">
          <div className="span-data-views">
            {traceDetails.is_loading && traceDetails.data.length <= 0 && (
              <Skeleton count={5} height={50} />
            )}

            {!traceDetails.is_loading && traceDetails.data.length > 0 && (
              <div className={"span-container"}>
                <NavTab
                  classname={"span-tabs"}
                  defaultActiveTabIndex={0}
                  borderTop={false}
                >
                  <TabItems title="Flame graph" className={"flamegraph-tab"}>
                    <div className={"flame-graph-container"}>
                      <div className={"profiling-graph"}>
                        <div className="flamegraph">
                          {traceDetails?.data &&
                            traceDetails.data.length > 0 && (
                              <Flamegraph
                                width={flameGraphWidth}
                                spans={traceDetails.data}
                                onTraceClick={(data) => {
                                  setActiveSpan(data);
                                  setAllAttr({
                                    ...data?.tagMap,
                                    ...data?.resource_attributes,
                                  });

                                  if (data.events && data.events.length > 0) {
                                    setEvents(data.events[0]);
                                  }

                                  getTraceErrors(
                                    data.traceID,
                                    data.spanID,
                                    errPage
                                  );
                                  getTraceLogs(data.traceID, data.spanID, page);
                                }}
                              />
                            )}

                          <div className={"flamegraph-tools"}>
                            <div className={"flamegraph-tools-buttons"}>
                              <button
                                className={"buttons"}
                                onClick={() => {
                                  if (flameGraphWidth > 800) {
                                    setFlameGraphWidth(flameGraphWidth - 100);
                                  }
                                }}
                              >
                                <svg
                                  strokeWidth="0"
                                  viewBox="0 0 24 24"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g>
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15zM7 10h8v2H7v-2z"></path>
                                  </g>
                                </svg>
                              </button>
                              <button
                                className={"buttons"}
                                onClick={() => {
                                  if (flameGraphWidth < 2000) {
                                    setFlameGraphWidth(flameGraphWidth + 100);
                                  }
                                }}
                              >
                                <svg
                                  strokeWidth="0"
                                  viewBox="0 0 24 24"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g>
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15zM10 10V7h2v3h3v2h-3v3h-2v-3H7v-2h3z"></path>
                                  </g>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"resource"} style={{ width: `300px` }}>
                        <div className={"header"}>
                          <div className={"title"}>Services</div>
                          <div className={"title"}>
                            <ActionMenu
                              hideButtonIcon
                              additionalText={selectedServiceExec}
                              buttonAttr={{ borderless: false, action: true }}
                            >
                              <PopoverMenuItem
                                className={"popover-text"}
                                onClick={() => selectServiceExec("Exec time %")}
                              >
                                Exec time %
                              </PopoverMenuItem>
                              <PopoverMenuItem
                                className={"popover-text"}
                                onClick={() => selectServiceExec("Span Count")}
                              >
                                Span Count
                              </PopoverMenuItem>
                            </ActionMenu>
                          </div>
                        </div>

                        {services?.map((item: any, key: any) => {
                          const color = generateHashAndMetricColor(
                            item.service_name || ""
                          );
                          return (
                            <div key={key} className={"service-data"}>
                              <div className={"title"}>
                                <span
                                  style={{
                                    backgroundColor: `${color.color}`,
                                    borderRadius: "10px",
                                    padding: "2px",
                                    marginRight: "5px",
                                  }}
                                ></span>
                                {item.service_name}
                              </div>
                              <div className={"title"}>
                                {selectedServiceExec === "Exec time %"
                                  ? item.execution_time_percentage
                                  : item.number_of_spans}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </TabItems>
                  <TabItems title={"Spans"} className={"span-list-tab"}>
                    <div className={"span-list-view"}>
                      {spanList && spanList.length > 0 && (
                        <CollapsibleTable
                          data={spanList}
                          updateAttributes={(data: any) => {
                            const span = traceDetails.data.find(
                              (i: Span) => i.spanID === data.span_id
                            );

                            if (span) {
                              setActiveSpan(span);
                              setAllAttr({
                                ...span?.tagMap,
                                ...span?.resource_attributes,
                              });

                              if (span.events && span.events.length > 0) {
                                setEvents(span.events[0]);
                              }

                              getTraceErrors(
                                span.traceID,
                                span.spanID,
                                errPage
                              );
                              getTraceLogs(span.traceID, span.spanID, page);
                            }
                          }}
                        />
                      )}
                    </div>
                  </TabItems>
                </NavTab>
              </div>
            )}

            <div
              className={`resizer resizer-horizontal ${dragging ? "resizer-dragging" : ""}`}
              onMouseDown={handleMouseDown}
            >
              <button className="resizer-handle"></button>
            </div>
          </div>
          <div className="span-other-details">
            <div className="active-span-header">
              {activeSpan && (
                <div className="title">
                  <span
                    style={{
                      backgroundColor: `${
                        generateHashAndMetricColor(
                          activeSpan.tagMap["service.name"] || ""
                        ).color
                      }`,
                      borderRadius: "10px",
                      height: "18px",
                      width: "4px",
                      marginRight: "5px",
                    }}
                  ></span>
                  <span>{activeSpan.tagMap["service.name"]}</span>
                  <span>{">"}</span>
                  <span>{truncateText(activeSpan.name, 100)}</span>
                </div>
              )}
            </div>
            <div
              ref={otherDetailDivRef}
              className="detail-container"
              style={{
                height: `${initialHeight}px`,
              }}
            >
              <NavTab
                classname={"trace-attributes-container"}
                defaultActiveTabIndex={0}
                borderTop={true}
              >
                <TabItems
                  title="Trace Attributes"
                  className={"other-details-active-tab"}
                >
                  <div className={"other-section"}>
                    {activeSpan && (
                      <div className="title">
                        <span>Parent Span:</span>
                        <span>
                          {activeSpan.parentSpanID
                            ? activeSpan.parentSpanID
                            : "Root Span"}
                        </span>
                      </div>
                    )}

                    {allAttr && Object.keys(allAttr).length > 0 && (
                      <CollapsibleJSON
                        data={allAttr}
                        onFilterSelect={(filter: any) => {
                          const filterToApply: QueryParams = {
                            [filter.key]: {
                              operator:
                                filter.type === "filter"
                                  ? FilterOpEnum.Equal
                                  : FilterOpEnum.NotEqual,
                              values: [filter.value],
                            } as FilterParams,
                          };
                          routerContext.actions.handleApplyFilters(
                            filterToApply
                          );
                        }}
                      />
                    )}
                  </div>
                </TabItems>
                <TabItems title="Events" className={"other-details-active-tab"}>
                  <div className={"events-tab"}>
                    {events && Object.keys(events).length > 0 && (
                      <ReactJson src={JSON.parse(events)} />
                    )}

                    {(!events || Object.keys(events).length <= 0) && (
                      <div className={"no-data"}>No Event data found</div>
                    )}
                  </div>
                </TabItems>
                {!spanLogs.is_loading && spanLogs.data.length > 0 && (
                  <TabItems
                    title={"Logs"}
                    className={"other-details-active-tab"}
                  >
                    <div className={"log-tab"}>
                      <>
                        <TableGridview
                          columns={columns}
                          dataProvider={spanLogs.data}
                          headSticky={true}
                          rowOnClick={(row) => {
                            setLogSpan(row.fingerprint);
                            setSpanTs(row.timestamp_ms + 3600000);
                            setInfoOpen(true);
                          }}
                          pagination={true}
                          limit={20}
                          page={page}
                          pageCallback={(event: any, page: number) =>
                            pageCallback(event)
                          }
                        />
                        <Dialog
                          isOpen={infoOpen}
                          position={"right"}
                          size={"md"}
                          onClose={() => {
                            setInfoOpen(false);
                            setLogSpan(0);
                            setSpanTs(new Date().getTime());
                          }}
                        >
                          <LogInfoPanel
                            id={logSpan}
                            themeMode={props?.themeMode || "light"}
                            isLogScreen={false}
                            ets={spanTs}
                            navigate={navigate}
                          />
                        </Dialog>
                      </>
                    </div>
                  </TabItems>
                )}

                <TabItems
                  title={"Error Traces"}
                  className={"other-details-active-tab"}
                >
                  {!traceErrors.is_loading && traceErrors.data.length > 0 && (
                    <>
                      <TableGridview
                        columns={traceErrColumns}
                        dataProvider={traceErrors.data}
                        headSticky={true}
                        pagination={true}
                        limit={20}
                        page={page}
                        pageCallback={(event: any, page: number) =>
                          errTracepageCallback(event)
                        }
                        rowOnClick={(row) => {
                          toggleErrorDialog(true, row);
                        }}
                      />
                      <Dialog
                        isOpen={errPanelOpen}
                        position={"right"}
                        size={"sm"}
                        onClose={() => {
                          toggleErrorDialog(false, null);
                        }}
                      >
                        <RumErrorPanel themeMode={""} error={errorPanelData} />
                      </Dialog>
                    </>
                  )}
                  {traceErrors.is_loading && traceErrors.data.length <= 0 && (
                    <TableGridview
                      rowLoader={true}
                      columns={traceErrColumns}
                      dataProvider={[]}
                      headSticky={true}
                      numberOfRowLoader={20}
                    />
                  )}
                  {!traceErrors.is_loading && traceErrors.data.length <= 0 && (
                    <TableGridview
                      columns={traceErrColumns}
                      dataProvider={[]}
                      headSticky={true}
                      noDataLabel={"No Error traces found"}
                    />
                  )}
                </TabItems>
                {!props?.disableSessionReplayTab &&
                  rumSessionId &&
                  rumSession &&
                  !rumSession.is_loading && (
                    <TabItems
                      title={"Session Replay"}
                      className={"other-details-active-tab"}
                    >
                      {!rumSession.is_loading &&
                        Object.keys(rumSession.data).length > 0 && (
                          <div className="container">
                            <SessionReplay
                              themeMode={""}
                              session={rumSession.data}
                              timestampToJumpVideo={props?.trace?.timestamp_ms}
                            />
                          </div>
                        )}
                    </TabItems>
                  )}
              </NavTab>
            </div>
          </div>
        </div>
        <Dialog
          isOpen={sessionDialog}
          position={"right"}
          onClose={() => {
            toggleSessionDialog(false, null);
          }}
        >
          {sessionDialogData && (
            <SessionPanel
              themeMode={props?.themeMode}
              session={sessionDialogData}
              dates={{
                startDate: props?.trace?.start_date * 1000000,
                endDate: props?.trace?.end_date * 1000000,
              }}
              timestampToJumpVideo={props?.trace?.timestamp_ms}
            />
          )}
        </Dialog>
      </div>
    </React.Suspense>
  );
};

export default SpanPanel;
