import React, { CSSProperties, useEffect, useState } from "react";
import { ColorResult, SketchPicker } from "react-color";
import "./styles.scss";
import { ColorPaletteIcon } from "../svg/icons";
import { PresetColors } from "antd/es/theme/internal";
import FormTextInput from "../form/form-text-input";
import { Popover } from "../popover";

interface ColorPickerProps {
  handleColorChange: (value: Omit<ColorResult, "hsl">) => void;
  prefixIcon?: JSX.Element;
  presetColors?: string[];
  defaultHashColor?: string;
  disabledAlpha?: boolean;
}

const getRgbColor = (hash: string) => ({
  a: 1,
  r: parseInt(hash.substring(1, 3), 16) ?? 0,
  g: parseInt(hash.substring(3, 5), 16) ?? 0,
  b: parseInt(hash.substring(5, 7), 16) ?? 0,
});

const ColorPicker = (props: ColorPickerProps) => {
  const {
    presetColors = [],
    prefixIcon,
    defaultHashColor = "#5b5fc7",
    handleColorChange,
    disabledAlpha = true,
  } = props;
  const [isSketchPickerOpen, setIsSketchPickerOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState<Omit<ColorResult, "hsl">>({
    rgb: getRgbColor(defaultHashColor),
    hex: defaultHashColor,
  });

  useEffect(() => {
    handleColorChange(selectedColor);
  }, [selectedColor]);

  return (
    <Popover
      isVisible={isSketchPickerOpen}
      onClose={() => setIsSketchPickerOpen(false)}
      shouldCloseOnInnerClick={false}
      content={
        <SketchPicker
          color={selectedColor.rgb}
          onChange={(value: ColorResult) => {
            setSelectedColor({ rgb: value.rgb, hex: value.hex });
          }}
          presetColors={presetColors}
          disableAlpha={disabledAlpha}
        />
      }
      rootClassName={`color-picker-popover ${disabledAlpha ? "disabled-alpha" : ""}`}
    >
      <div
        className="color-picker-container"
        style={{ "--picker-color": selectedColor } as CSSProperties}
      >
        <div className="color-picker-input-container">
          <div
            className="picker-prefix"
            onClick={() => setIsSketchPickerOpen(!isSketchPickerOpen)}
          >
            {prefixIcon ?? <ColorPaletteIcon />}
            <div
              className="color-box"
              style={{ background: selectedColor.hex }}
            ></div>
          </div>
          <FormTextInput
            id="color-picker-input"
            className="color-picker-input"
            value={selectedColor.hex.substring(0, 7)}
            onChange={(value) =>
              setSelectedColor({ hex: value, rgb: getRgbColor(value) })
            }
          />
        </div>
      </div>
    </Popover>
  );
};

export default ColorPicker;
