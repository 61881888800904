import { getTenantUrl } from "core/application/utils";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import InstallationCard from "../../components/card";
import Command from "../../components/code-block/command";
import InstallationSteps from "../../components/steps";
import StepItem from "../../components/steps/step-item";
import DockerInstallationSnippet from "../common/docker-snippet";
const DockerIntegration = () => {
  const [tab, setTab] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Linux Based OS Version",
          value: "Running on LTS",
        },
      ],
    },
  ];

  const dockerInstallCommand = `MW_API_KEY=${context.user?.account?.agent_config?.token} MW_TARGET=${capturePath}:443 bash -c "$(curl -L https://install.middleware.io/scripts/docker-install.sh)"`;
  const dockerStatusCommand =
    "docker stop `docker ps -a -q --filter ancestor=ghcr.io/middleware-labs/mw-host-agent:master`";
  const sendEvent = useMixPanel();
  return (
    <>
      <InstallationSteps>
        <StepItem stepNumber={1} title={"Prerequisites"}>
          <InstallationCard items={cardItems} />
        </StepItem>

        <StepItem stepNumber={2} title={"Install Middleware Host Agent"}>
          <DockerInstallationSnippet />
        </StepItem>
        <StepItem stepNumber={3} title={"Check Status:  Middleware Host Agent"}>
          <>
            <Command
              onCopyClick={() => {
                sendEvent("click on copy command", {
                  agent_type: "Docker",
                  command_type: "Check Status:  Middleware Host Agent",
                });
              }}
              command={dockerStatusCommand}
              className="margin-0"
            />
          </>
        </StepItem>
      </InstallationSteps>
    </>
  );
};
export default DockerIntegration;
