import React, { useState } from "react";
import Button from "core/components/v2/button";
import { Popover } from "core/components/v2/popover";
import { DownArrowSvg } from "core/components/svg/icons";
import { Size, Type } from "core/components/v2/enums";

interface Props {
  children: React.ReactNode;
  prefixIcon?: React.ReactNode;
  suffixicon?: React.ReactNode;
  disabled?: boolean;
  text?: string;
  buttonType?: string;
  shouldCloseOnInnerClick?: boolean;
  downArrow?: boolean;
  btnSize?: Size;
  rootClassName?: string;
}
export const ActionMenu = (props: Props) => {
  const {
    prefixIcon,
    suffixicon,
    children,
    disabled,
    text,
    buttonType,
    shouldCloseOnInnerClick = true,
    downArrow = false,
    btnSize,
    rootClassName,
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <div className={"mw-action-menu"}>
      <Popover
        content={children}
        isVisible={disabled ? false : open}
        onClose={(isVisible) => {
          setOpen(isVisible);
        }}
        rootClassName={rootClassName}
        shouldCloseOnInnerClick={shouldCloseOnInnerClick}
      >
        <span className={"action-menu-trigger-element"}>
          <Button
            disabled={disabled}
            secondary={buttonType == Type.Secondary}
            primary={buttonType === Type.Primary}
            outlined={buttonType === Type.Outlined}
            prefixicon={prefixIcon ? prefixIcon : <></>}
            suffixicon={suffixicon ? suffixicon : <></>}
            size={btnSize}
            onClick={() => {
              if (!disabled) setOpen(!open);
            }}
          >
            {(text || downArrow) && (
              <>
                {text ?? ""}
                {downArrow && (
                  <span className={"down-arrow"}>
                    <DownArrowSvg />
                  </span>
                )}
              </>
            )}
          </Button>
        </span>
      </Popover>
    </div>
  );
};
