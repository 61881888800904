import React, { useEffect, useState } from "react";
import Button from "core/components/v2/button";
import { AIInputIcon } from "core/components/v2/svg/icons";
import "./_style.scss";

interface TextToQueryProps {
  uniqueId: string;
  handleRunQuery?: (config: string) => void;
}

const TextToQuery = (props: TextToQueryProps) => {
  const { uniqueId, handleRunQuery } = props;
  const [nlText, setNlText] = useState("");
  const [qlText, setQLText] = useState("");
  const [showMoreLines, setShowMoreLines] = useState(false);
  const [disableGenerate, setDisableGenerate] = useState(false);

  const handleNLTextChange = (e: string) => {
    setNlText(e);
    setDisableGenerate(false);
  };

  const handleQLTextChange = (e: string) => {
    setQLText(e);
  };

  const handleGenerateClick = () => {
    // Todo: need to replce below mentioned setState with API Call
    setQLText("SELECT * FROM Span WHERE http.method = 'GET' ");
    setDisableGenerate(true);
  };

  const showMultipleLines = showMoreLines || nlText.length > 85;

  return (
    <>
      <div
        style={{ borderBottom: "1px solid var(--color-neutrals-200)" }}
      ></div>
      <div className="text-to-query-container">
        <div className="search-container">
          <div className="search-icon">
            <AIInputIcon />
          </div>
          <textarea
            id={uniqueId + "-nl-text"}
            className={`input-field ${showMultipleLines ? "multi-line" : ""}`}
            placeholder={
              "Write something in natural language and click on generate to get the query"
            }
            value={nlText}
            onChange={(e) => handleNLTextChange(e.target.value)}
            onKeyDown={(key) =>
              key.key === "Enter"
                ? setShowMoreLines(true)
                : key.key === "Backspace" && nlText.length === 0
                  ? setShowMoreLines(false)
                  : {}
            }
            rows={showMultipleLines ? 4 : 1}
          />
          <div
            className={`query-submit-button ${showMultipleLines ? "multi-line" : ""}`}
          >
            <Button
              primary
              onClick={handleGenerateClick}
              disabled={disableGenerate || nlText.trim().length === 0}
            >
              Generate Query
            </Button>
          </div>
        </div>
        <div className="search-container">
          <div className="search-icon">
            <AIInputIcon />
          </div>
          <textarea
            id={uniqueId + "-query"}
            className={`input-field ${showMultipleLines ? "multi-line" : ""}`}
            value={qlText}
            placeholder={
              "Write something in natural language and click on generate to get the query"
            }
            onChange={(e) => handleQLTextChange(e.target.value)}
            rows={showMultipleLines ? 4 : 1}
          />
          {qlText !== "" && (
            <div
              className={`query-submit-button ${showMultipleLines ? "multi-line" : ""}`}
            >
              <Button
                primary
                disabled={!qlText}
                onClick={() => handleRunQuery?.(qlText)}
              >
                Run Query
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TextToQuery;
