import {GLOBAL_REFRESH_STATE, SERVER_ERROR_CONTEXT, THEME_MODE, UPDATE_AUTH_TOKEN} from "store/constant";


export const updateThemeMode = (mode: string) => {
    return {type: THEME_MODE, mode}
}


export const updateAuthToken = (token: any) => {
    return {type: UPDATE_AUTH_TOKEN, token}
}
export const serverErrorContext = (flag: boolean) => ({type: SERVER_ERROR_CONTEXT, flag})

export const resetAllState = () => {
    return {type: "RESET_ALL_STATE"}
}

export const doRefresh = () => {
    return {type: GLOBAL_REFRESH_STATE}
}
