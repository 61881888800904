import { combineReducers } from "redux";
import { createReducer } from "core/utils/create_reducer";
import {
  APPLICATION_LIST_RECEIVED,
  RUM_SUB_NAV_TAB_CHANGE,
} from "store/rum/constant";

const applicationList = createReducer(
  {
    inflight: true,
    application_list: [],
  },
  {
    [APPLICATION_LIST_RECEIVED]: (state, opts) => {
      if (typeof opts == "object") {
        if (opts?.items && Array.isArray(opts.items) && opts.items.length > 0)
          state.application_list = opts.items;
      }

      return { ...state };
    },
  }
);

const selectedTab = createReducer(
  {
    performance_tab: 1,
  },
  {
    [RUM_SUB_NAV_TAB_CHANGE]: (state, opts) => {
      state[opts.args.key] = opts.args.tab;
      return { ...state };
    },
  }
);

export default combineReducers({
  applicationList,
  selectedTab,
});
