import * as React from "react";
import NavTab from "core/components/nav-tab";
import TabItems from "core/components/nav-tab/items";
import {AWSECSNewDocsSVG, InstallationDocsIcon, LinuxIcon, WindowsSvg} from "core/components/svg/icons";
import AwsEcsInstallation from "views/modules/installation/components/aws-ecs-installation";
import {Link} from "react-router-dom";

export default function AWSECSInfraInstrument(actionFlag: any) {

    return <>
        <div className={'jira-integration-new'}>
            <div className={' setting_side-panel'}>
                <div className={'integration-popup'}>
                    <div className={'header-section'}>
                        <div className={'d-flex'}>
                            <AWSECSNewDocsSVG/>
                            <div className={'header-content'}>
                                <h3>Amazon ECS</h3>
                                <p>Amazon Elastic Container Service (ECS) helps you monitor your ECS containers and
                                    tasks running on EC2 instances and ECS Fargate</p>
                            </div>
                            <button className={"docs-link"}><Link
                                to={'https://docs.middleware.io/integrations/aws-ecs-monitoring'}
                                target={'_blank'}><span> <InstallationDocsIcon/>Documentation</span></Link></button>
                        </div>
                        <NavTab targetIndex={1} defaultActiveTabIndex={1}>
                            <TabItems title={'Overview'}>
                                <div className={'d-flex in-content'}>
                                    <div className={'overview-heading'}>
                                        <div className={'config-overview'}>
                                            <h4>Category</h4>
                                            <span>Infrastructure</span>

                                            <h4 className={'tp-mr'}>Supported OS</h4>
                                            <div className={'d-flex'}>
                                                <LinuxIcon/>
                                                <WindowsSvg/>
                                                {/*<MacIcon/>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'overview-content'}>
                                        <p>Amazon Elastic Container Service (Amazon ECS) is a fully managed container orchestration service that helps you easily deploy, manage, and scale containerized applications. With the Infrastructure Agent (Infra Agent), you can effectively monitor your ECS containers and tasks running on ECS EC2 instances and ECS Fargate in your cloud, on-prem, and hybrid architectures.</p>
                                        <div className={'overview-image'}>
                                            <img src={'assets/images/bare-metal-overview.png'}
                                                 alt={'bare-metal-overview'}/>
                                        </div>
                                    </div>
                                </div>
                            </TabItems>
                            <TabItems title={'Installation'}>
                                <div className={'panel-body'}>
                                    <AwsEcsInstallation/>
                                </div>

                            </TabItems>
                        </NavTab>
                    </div>
                </div>

            </div>
        </div>
    </>
}