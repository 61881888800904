
export const RECEIVED_RESOURCE_ATTRIBUTES = "RECEIVED_RESOURCE_ATTRIBUTES";
export const RECEIVED_METRICS_TIMELINE = "RECEIVED_METRICS_TIMELINE";

export const DATE_RANGE = {
    "15m": "Past 15 Minutes",
    "30m": "Past 30 Minutes",
    "45m": "Past 45 Minutes",
    "1h": "Past 1 Hours",
    "2h": "Past 2 Hours",
    "4h": "Past 4 Hours",
    "12h": "Past 12 Hours",
    "1d": "Past 1 Day",
    "2d": "Past 1 Days",
    "1w": "Past 1 Week",
    "10d": "Past 10 Days",
    "1mo": "Past 1 Month",
} as any
export const INFRA_REFRESH = "INFRA_REFRESH";