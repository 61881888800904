import { combineReducers } from "redux";
import ToastAlerts from "./toast-alerts/reducers";
import AccountReducers from "./account/reducers";
import UserReducers from "./settings/user/reducers";
import RoleReducers from "./settings/role/reducers";
import CountriesReducer from "./countries/reducers";
import LogsReducer from "./logs/reducers";
import AlertsReducer from "./alert/reducers";
import { createReducer } from "core/utils/create_reducer";
import { getAuthStore, getThemeState } from "core/utils";
import {
  GLOBAL_REFRESH_STATE,
  SERVER_ERROR_CONTEXT,
  THEME_MODE,
  UPDATE_AUTH_TOKEN,
} from "store/constant";
import DashboardReducers from "store/dashboard/reducers";
import IntegrationReducers from "store/integration/reducers";
import InfrastructureReducers from "store/infrastructure/reducers";
import ApiKeyReducers from "store/settings/api-key/reducers";
import WidgetsReducers from "store/widgets/reducers";
import APMReducer from "./apm/reducer";
import TeamReducer from "store/settings/team/reducers";
import BackoffReducers from "store/backoff/reducers";
import BillingReducers from "store/settings/billing/reducers";
import MaintenanceWindowReducers from "store/settings/advanced/maintenance_window/reducers";
import SyntheticsReducers from "store/synthetics/reducers";
import AdvisorReducers from "store/advisor/reducers";
import RumReducer from "store/rum/reducer";
import OnboardDashboard from "store/onboard-dashboard/reducers";
import Installation from "store/settings/installation/reducers";
import Project from "store/settings/project/reducers";
import MetricsReducer from "store/settings/metrics-control-panel/reducer";
import TaskManagementReducer from "store/task_management/reducers";
import DashboardBuilderReducer from "store/dashboard-builder/reducers";
import StatusPageReducer from "store/status_page/reducer"

export default combineReducers({
  toast_alerts: ToastAlerts,
  account: AccountReducers,
  user: UserReducers,
  role: RoleReducers,
  countries: CountriesReducer,
  logs: LogsReducer,
  alert: AlertsReducer,
  widgets: WidgetsReducers,
  routes: createReducer(
    {},
    {
      ["UPDATE_ROUTES"]: () => {
        return {
          time: new Date().toISOString(),
        };
      },
    }
  ),
  errorContext: createReducer(false, {
    [SERVER_ERROR_CONTEXT]: (state, action) => {
      if (typeof action.flag !== "undefined") return action.flag;

      return state;
    },
  }),
  theme: createReducer(
    {
      mode: getThemeState(),
    },
    {
      [THEME_MODE]: (state, action) => {
        if (action?.mode) return { mode: action?.mode };
        return { ...state };
      },
    }
  ),
  auth_token: createReducer(getAuthStore(), {
    [UPDATE_AUTH_TOKEN]: (state, action) => {
      if (typeof action.token !== "undefined") return action.token;
      return state;
    },
  }),
  globalRefresh: createReducer(
    { refresh: false },
    {
      [GLOBAL_REFRESH_STATE]: (state) => {
        state.refresh = !state.refresh;
        return { ...state };
      },
    }
  ),
  dashboard: DashboardReducers,
  integration: IntegrationReducers,
  infrastructure: InfrastructureReducers,
  api_key: ApiKeyReducers,
  apm: APMReducer,
  team: TeamReducer,
  backoff: BackoffReducers,
  billing: BillingReducers,
  maintenance_window: MaintenanceWindowReducers,
  synthetics: SyntheticsReducers,
  advisor: AdvisorReducers,
  rum: RumReducer,
  onboard_dashboard: OnboardDashboard,
  installation: Installation,
  project: Project,
  metrics: MetricsReducer,
  task_management: TaskManagementReducer,
  dashboardBuilder: DashboardBuilderReducer,
  statusPage: StatusPageReducer
});
