import * as React from "react";
import { RoutesPatterns } from "core/utils/route_schema";

const APMRoutes: RoutesPatterns = {
  moduleName: "apm",
  slug: "/apm",
  breadcrumbs: React.lazy(() => import("views/modules/apm-v2/breadcrumbs")),
  items: [
    {
      pattern: ["/services"],
      component: React.lazy(
        () => import("views/modules/apm-v2/modules/services")
      ),
      breadcrumbTitle: "APM Services",
      sidebar: React.lazy(
        () => import("views/modules/apm-v2/sidebar/service-sidebar")
      ),
    },
    {
      pattern: ["/spans"],
      component: React.lazy(() => import("views/modules/apm-v2/modules/spans")),
      breadcrumbTitle: "APM Services",
      sidebar: React.lazy(
        () => import("views/modules/apm-v2/sidebar/span-sidebar")
      ),
    },
    {
      pattern: ["/profiling"],
      component: React.lazy(
        () => import("views/modules/apm-v2/modules/profiling")
      ),
      breadcrumbTitle: "Profiling",
      sidebar: React.lazy(
        () => import("views/modules/apm-v2/sidebar/profiling-sidebar")
      ),
    },
    {
      pattern: ["/api-catalogue"],
      component: React.lazy(
        () => import("views/modules/apm-v2/modules/api-catalogue")
      ),
      breadcrumbTitle: "API Catalogue",
      sidebar: React.lazy(
        () => import("views/modules/apm-v2/sidebar/catalogue-sidebar")
      ),
    },
    {
      pattern: ["/database"],
      component: React.lazy(
        () => import("views/modules/apm-v2/modules/database")
      ),
      breadcrumbTitle: "Database",
      sidebar: React.lazy(
        () => import("views/modules/apm-v2/sidebar/db-sidebar")
      ),
    },
    {
      pattern: ["/database/:host/:db"],
      component: React.lazy(
        () => import("views/modules/apm-v2/modules/database/page")
      ),
      breadcrumbTitle: "Database Dashboard",
      sidebar: React.lazy(
        () => import("views/modules/apm-v2/sidebar/db-dashboard-sidebar")
      ),
    },
  ],
};
export default APMRoutes;
