import { getTenantUrl } from "core/application/utils";
import { Message } from "core/components/v2/enums";
import TabSelection from "core/components/v2/tab-selection";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import Command from "../../components/code-block/command";
import MwMessage from "../../components/message";
const KubernetesInstallationSnippet = () => {
  const [tab, setTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;

  let command = `MW_API_KEY=${context.user?.account?.agent_config?.token} MW_TARGET=${capturePath}:443 bash -c "$(curl -L https://install.middleware.io/scripts/mw-kube-agent-install-v2.sh)"`;
  const helmRepoAddCommand = `helm repo add middleware-labs https://helm.middleware.io`;
  let helmCommand = `helm install mw-agent middleware-labs/mw-kube-agent-v2 --set mw.apiKey=${context.user?.account?.agent_config?.token} --set mw.target=${capturePath}:443`;

  if (context.user?.misc_details?.env_type != "prod") {
    command = `MW_API_URL_FOR_CONFIG_CHECK="${capturePath}" ${command}`;
    helmCommand = `${helmCommand} --set mw.apiUrlForConfigCheck ${capturePath}`;
  }
  const sendEvent = useMixPanel();
  return (
    <>
      <TabSelection
        tabs={[
          {
            label: "Bash",
            value: 1,
          },
          {
            label: "Helm Chart",
            value: 2,
          },
        ]}
        selectedTab={1}
        onTabChange={(tabObject) => {
          setTab(tabObject.value);
        }}
      />
      <div className="tab-content-area">
        {tab === 1 && (
          <>
            <Command
              command={command}
              className="margin-0"
              onCopyClick={() => {
                sendEvent("click on copy command", {
                  agent_type: "Kubernetes",
                  command_type: "Install Kubernetes Infrastructure Monitor",
                });
              }}
            />
          </>
        )}
        {tab === 2 && (
          <>
            <Command
              command={helmRepoAddCommand}
              className="margin-1"
              onCopyClick={() => {
                sendEvent("click on copy command", {
                  agent_type: "Kubernetes",
                  command_type: "Install Kubernetes Infrastructure Monitor",
                });
              }}
            />
            <Command
              command={helmCommand}
              className="margin-0"
              onCopyClick={() => {
                sendEvent("click on copy command", {
                  agent_type: "Kubernetes",
                  command_type: "Install Kubernetes Infrastructure Monitor",
                });
              }}
            />
          </>
        )}
      </div>
      <MwMessage
        type={Message.Info}
        message="This script will install a Middleware Kubernetes Agent in your Kubernetes cluster. This agent is used to monitor the data in your Kubernetes infrastructure"
      ></MwMessage>
      <MwMessage
        type={Message.Warning}
        message="Run only one Middleware Kubernetes Agent per cluster. Multiple agents will cause unexpected behavior."
      ></MwMessage>
    </>
  );
};
export default KubernetesInstallationSnippet;
