import { getRedirectURI } from "core/utils";
import CreateRequest from "core/xhr";
import { requestUserList } from "store/settings/user/api";
import { showToast } from "store/toast-alerts/actions";
import { InviteUsersData } from "views/modules/settings-v2/users/entity";

interface LoginParams {
  email: string;
  password: string;
}

interface loginRes {
  code?: string;
  message?: string;
  token?: string;
}

export const loginRequest = (
  body: LoginParams,
  callback: (arg0: boolean, inflight?: boolean, res?: loginRes) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    callback(false, true);

    return CreateRequest({
      url: "/auth/login",
      method: "post",
      data: body,
    })
      .then((res) => {
        if (res.data.token && res.data.redirect) {
          let _path = getRedirectURI();
          if (_path !== "") {
            const q: any = new URLSearchParams(window.location.search);
            if (
              q.has("redirect_uri") &&
              q.get("redirect_uri") &&
              q.get("redirect_uri") !== ""
            ) {
              _path = decodeURIComponent(q.get("redirect_uri"));
            }
            if (_path !== "") {
              _path = "&redirect_uri=" + encodeURIComponent(_path);
            }
          }
          window.location.href = res.data.redirect + _path;
        } else {
          callback(false, false, res.data);
        }
      })
      .catch(() => {
        callback(false, false);
      });
  };
};

export const requestSwitchSandbox = (
  callback?: (a0: boolean, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    callback && callback(true, { inflight: true });
    return CreateRequest({
      url: "/auth/switch-sandbox",
      method: "get",
    })
      .then((res) => {
        callback && callback(true, { inflight: false, ...res.data });
      })
      .catch(() => {
        callback && callback(true, { inflight: false });
      });
  };
};

export const requestBackToReal = (
  callback?: (a0: boolean, res?: any) => void,
  agentInstance?: boolean
) => {
  return (dispatch: (arg0: any) => void) => {
    let url = "/auth/back-to-real";
    if (agentInstance) {
      url = url + "?agentInstance=true";
    }
    callback && callback(true, { inflight: true });
    return CreateRequest({
      url,
      method: "get",
    })
      .then((res) => {
        callback && callback(true, { inflight: false, ...res.data });
      })
      .catch(() => {
        callback && callback(true, { inflight: false });
      });
  };
};

export const logoutRequest = () => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/auth/logout",
      method: "get",
      data: {},
    })
      .then((res) => {
        if (res.data && res.data.status && res.data.redirect) {
          let _path = getRedirectURI();
          if (_path !== "") {
            const q: any = new URLSearchParams(window.location.search);
            if (
              q.has("redirect_uri") &&
              q.get("redirect_uri") &&
              q.get("redirect_uri") !== ""
            ) {
              _path = decodeURIComponent(q.get("redirect_uri"));
            }
            if (_path !== "") {
              _path = "?redirect_uri=" + encodeURIComponent(_path);
            }
          }
          window.location.href = res.data.redirect + _path;
        }
      })
      .catch((err) => {
        console.log("logout:Err", err);
      });
  };
};

export const registerRequest = (
  body: any,
  callback?: (
    status: any,
    b: boolean,
    res?: { send: boolean; inflight: any; message: any }
  ) => void
) => {
  return () => {
    if (callback) {
      callback(true, true);
    }
    return CreateRequest({
      url: "/auth/rwe",
      method: "post",
      data: body,
    })
      .then((res) => {
        if (callback) {
          callback(true, false, res.data);
        }
      })
      .catch((err) => {
        if (callback) {
          callback(false, false, err);
        }
      });
  };
};

export const setPasswordRequest = (
  body: {
    password: string;
    access_code: string | undefined;
    confirm_password: string;
  },
  cb?: (a0: boolean, a1?: boolean, a2?: any) => void
) => {
  return () => {
    if (cb) {
      cb(false, true);
    }
    return CreateRequest({
      url: "/auth/set-password",
      method: "put",
      data: body,
    })
      .then((res) => {
        if (cb) {
          cb(true, false, res.data);
        }
      })
      .catch((err) => {
        if (cb) {
          cb(false, false, err);
        }
      });
  };
};

export const forgotPasswordRequest = (
  body: { email: string },
  cb?: (a0?: boolean, a1?: boolean, a2?: any) => void
) => {
  return () => {
    if (cb) {
      cb(undefined, true);
    }
    let _path = getRedirectURI();
    if (_path !== "") {
      const q: any = new URLSearchParams(window.location.search);
      if (
        q.has("redirect_uri") &&
        q.get("redirect_uri") &&
        q.get("redirect_uri") !== ""
      ) {
        _path = decodeURIComponent(q.get("redirect_uri"));
      }
      if (_path !== "") {
        _path = "?redirect_uri=" + encodeURIComponent(_path);
      }
    }

    return CreateRequest({
      url: "/auth/forgot-password" + _path,
      method: "post",
      data: body,
    })
      .then((res) => {
        if (cb) {
          cb(true, false, res.data);
        }
      })
      .catch((err) => {
        if (cb) {
          cb(false, false, err);
        }
      });
  };
};

export const changePasswordRequest = (
  body: {
    email: string;
    current_password: string;
    password: string;
    access_code: string | undefined;
    confirm_password: string;
  },
  cb?: (a0: boolean, a1?: boolean, a2?: any) => void
) => {
  return () => {
    if (cb) {
      cb(false, true);
    }
    return CreateRequest({
      url: "/auth/change-password",
      method: "put",
      data: body,
    })
      .then((res) => {
        if (cb) {
          cb(true, false, res.data);
        }
      })
      .catch((err) => {
        if (cb) {
          cb(false, false, err);
        }
      });
  };
};

export const inviteUserRequest = (
  body: InviteUsersData[],
  callback?: (status: boolean, b: boolean, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/auth/invite-user",
      method: "post",
      data: body,
    })
      .then((res) => {
        dispatch(requestUserList());
        if (callback) {
          callback(true, false, res.data);
        }
      })
      .catch((err) => {
        if (callback) {
          callback(false, false, err);
        }
      });
  };
};

export const requestCustomerSupport = (
  params?: any,
  callback?: (inflight: boolean, res?: any) => void
) => {
  const customerEmail = params?.customerEmail;
  const supportContent = params?.supportContent;
  callback?.(true);
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/auth/customer-support/" + customerEmail + "/" + supportContent,
      method: "POST",
    })
      .then((res) => {
        callback?.(false, res);
        if (res?.status === 200) {
          dispatch(
            showToast(
              "success",
              `Thanks for contacting our support team. We will get back to you.`
            )
          );
        }
      })
      .catch((err) => {
        callback?.(false);
        console.log("list: error", err);
      });
  };
};
export const requestConfirmRegister = (
  data: { token: string; method: "join" | "new" },
  callback?: (res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/auth/register-confirm/" + data.token + "/" + data.method,
      method: "get",
    })
      .then((res) => {
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};
