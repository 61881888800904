import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Tooltip from "../tooltip";

export interface Tab {
  label: string;
  value: number;
  isDisabled?: boolean;
  tooltipText?: string;
}

interface TabSelectionProps {
  tabs: Tab[];
  selectedTab?: number;
  onTabChange: (selectedTab: Tab) => void;
}
const TabSelection = (props: TabSelectionProps) => {
  const { tabs } = props;
  const [selectedTab, setSelectedTab] = useState(
    props.selectedTab ? props.selectedTab : tabs[0].value
  );

  useEffect(() => {
    if (props.selectedTab != undefined) {
      setSelectedTab(props.selectedTab);
    }
  }, [props.selectedTab]);

  const setActiveTab = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tabObj: Tab
  ) => {
    e.preventDefault();
    setSelectedTab(tabObj.value);
    if (props.onTabChange && typeof props.onTabChange === "function") {
      props.onTabChange(tabObj);
    }
  };
  return (
    <div className="mw-tab-selection">
      {tabs.map((it) => {
        const tabClass = classNames("mw-tab", {
          active: it.value === selectedTab,
          "is-disabled": it.isDisabled,
        });
        return (
          <Tooltip
            key={it.value}
            content={it.tooltipText ? it.tooltipText : ""}
          >
            <div
              key={it.value}
              className={tabClass}
              onClick={(e) => {
                if (!it.isDisabled) {
                  setActiveTab(e, it);
                }
              }}
            >
              {it.label}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default TabSelection;
