import { APIStatus } from "core/application/utils";
import {
  dashboardListAction,
  dashboardFavoriteAction,
  dashboardAction,
  widgetDataSourcesAction,
  dataSourceWidgetsAction,
} from "./actions";
import CreateRequest from "core/xhr";
import { dashboardListURL, dashboardURL } from "./constants";
import {
  DashboardOperationPayload,
  DashboardOperationResp,
  Dashboard,
  DashboardListQueryParams,
  ImportDashboardResp,
  DashboardOperationType,
} from "./entities";
import { Method } from "axios";
import { APIAction } from "store/widgets/reducers";
import { DashboardSource } from "views/modules/builder/entities/builder.entities";

// fetchDashboardList fetches the list of dashboards from the API and passes the response to the reducer
export const fetchDashboardList =
  (params: DashboardListQueryParams) =>
  async (dispatch: (arg: unknown) => void) => {
    dispatch(
      dashboardListAction({
        apiStatus: APIStatus.LOADING,
      })
    );

    try {
      const res = await CreateRequest({
        url: dashboardListURL,
        params,
        method: "GET",
      });

      if (res.status === 200) {
        dispatch(
          dashboardListAction({
            apiStatus: APIStatus.SUCCESS,
            total: res.data.total as number,
            offset: res.data.offset as number,
            limit: res.data.limit as number,
            dashboards: res.data.reports as Dashboard[],
          })
        );
      } else {
        dispatch(
          dashboardListAction({
            apiStatus: APIStatus.ERROR,
            errorMsg: (res.data.error as string) || "Something went wrong!!",
          })
        );
      }
    } catch (e) {
      console.error("e---", e);

      dispatch(
        dashboardListAction({
          apiStatus: APIStatus.ERROR,
          errorMsg: e as string,
        })
      );
    }
  };

// fetchDashboard fetches the dashboard details of the given dashboard key  and passes the response to the reducer
export const fetchDashboard =
  (dashboardKey: string) => async (dispatch: (arg: unknown) => void) => {
    dispatch(
      dashboardAction({
        apiStatus: APIStatus.LOADING,
      })
    );

    try {
      const res = await CreateRequest({
        url: `${dashboardListURL}/${dashboardKey}`,
        method: "GET",
      });

      if (
        res.status === 200 &&
        Array.isArray(res.data?.reports) &&
        res.data?.reports.length > 0
      ) {
        dispatch(
          dashboardAction({
            apiStatus: APIStatus.SUCCESS,
            dashboard: res.data.reports[0] as Dashboard,
          })
        );
      } else {
        dispatch(
          dashboardAction({
            apiStatus: APIStatus.ERROR,
            errorMsg: (res.data.error as string) || "Something went wrong!!",
          })
        );
      }
    } catch (e) {
      console.error("e---", e);

      dispatch(
        dashboardAction({
          apiStatus: APIStatus.ERROR,
          errorMsg: e as string,
        })
      );
    }
  };

// makeDashboardFavorite makes a dashboard favorite or unfavorite using the API and passes the response to the reducer
export const makeDashboardFavorite =
  (id: number, favorite: boolean) =>
  async (dispatch: (arg: unknown) => void) => {
    dispatch(
      dashboardFavoriteAction({
        id,
        apiStatus: APIStatus.LOADING,
      })
    );

    try {
      const res = await CreateRequest({
        url: `${dashboardListURL}/favourite/${id}/${favorite}`,
        method: "GET",
      });

      if (res.status === 200) {
        dispatch(
          dashboardFavoriteAction({
            id,
            favorite,
            apiStatus: APIStatus.SUCCESS,
          })
        );
      } else {
        dispatch(
          dashboardFavoriteAction({
            id,
            apiStatus: APIStatus.ERROR,
            errorMsg: (res.data.error as string) || "Something went wrong!!",
          })
        );
      }
    } catch (e) {
      console.error("e---", e);

      dispatch(
        dashboardFavoriteAction({
          id,
          apiStatus: APIStatus.ERROR,
          errorMsg: e as string,
        })
      );
    }
  };

// importDashboard imports a dashboard using the API and passes the response to the callback function
export const importDashboard = (
  fileBlob: Blob,
  cb: (resp: ImportDashboardResp) => void,
  dashboardType?: string
) => {
  cb({
    apiStatus: APIStatus.LOADING,
  });

  const formData = new FormData();
  formData.append("file", fileBlob);

  const headers: Record<string, string> = {
    "Content-Type": "multipart/form-data",
  };

  if (dashboardType) {
    headers["dashboard-type"] = dashboardType;
  }

  CreateRequest({
    url: `${dashboardURL}/import`,
    method: "POST",
    data: formData,
    headers: headers,
  })
    .then((res) => {
      if (res.status === 200) {
        cb({
          apiStatus: APIStatus.SUCCESS,
          importedDashboard: res.data as Dashboard,
        });
      } else {
        cb({
          apiStatus: APIStatus.ERROR,
          errorMsg: (res.data.error as string) || "Something went wrong!!",
        });
      }
    })
    .catch((e) => {
      console.log("e---", e);

      cb({
        apiStatus: APIStatus.ERROR,
        errorMsg: e as string,
      });
    });
};

// executeDashboardOperation creates, updates, or clones the dashboard using the API and passes the response to the callback function
export const executeDashboardOperation = (
  operationType: DashboardOperationType,
  payload: DashboardOperationPayload,
  cb: (resp: DashboardOperationResp) => void
) => {
  cb({
    apiStatus: APIStatus.LOADING,
  });

  // set the url and method based on the operation type
  let url = dashboardListURL,
    method: Method = "POST";
  switch (operationType) {
    case DashboardOperationType.Update: {
      url += `/${payload.id}`;
      method = "PUT";
      break;
    }

    case DashboardOperationType.Clone: {
      url += "/clone";
      break;
    }

    case DashboardOperationType.Delete: {
      url += `/${payload.id}`;
      method = "DELETE";
      break;
    }
  }

  CreateRequest({
    url,
    method,
    data: payload,
  })
    .then((res) => {
      if (res.status === 200) {
        cb({
          apiStatus: APIStatus.SUCCESS,
          dashboard: res.data as Dashboard,
        });
      } else {
        cb({
          apiStatus: APIStatus.ERROR,
          errorMsg: (res.data.error as string) || "Something went wrong!!",
        });
      }
    })
    .catch((e) => {
      console.log("e---", e);

      cb({
        apiStatus: APIStatus.ERROR,
        errorMsg: e as string,
      });
    });
};

// incrementDashboardViewCount increments the view count of a dashboard using the API
export const incrementDashboardViewCount = (
  id: number,
  cb?: (resp: APIAction) => void
) => {
  cb &&
    cb({
      apiStatus: APIStatus.LOADING,
    });

  CreateRequest({
    url: `${dashboardListURL}/${id}/increment-view-count`,
    method: "POST",
  })
    .then((res) => {
      if (res.status === 200) {
        cb &&
          cb({
            apiStatus: APIStatus.SUCCESS,
          });
      } else {
        cb &&
          cb({
            apiStatus: APIStatus.ERROR,
            errorMsg: (res.data.error as string) || "Something went wrong!!",
          });
      }
    })
    .catch((e) => {
      console.error("e---", e);

      cb &&
        cb({
          apiStatus: APIStatus.ERROR,
          errorMsg: e as string,
        });
    });
};

// fetchWidgetDataSources fetches the data sources from which widgets can be imported using the API
export const fetchWidgetDataSources =
  () => async (dispatch: (arg: unknown) => void) => {
    dispatch(
      widgetDataSourcesAction({
        apiStatus: APIStatus.LOADING,
      })
    );

    try {
      const resp = await CreateRequest({
        url: `${dashboardURL}/sources`,
        method: "GET",
      });

      if (resp.status === 200) {
        dispatch(
          widgetDataSourcesAction({
            apiStatus: APIStatus.SUCCESS,
            sources: resp.data as DashboardSource[],
          })
        );
      } else {
        dispatch(
          widgetDataSourcesAction({
            apiStatus: APIStatus.ERROR,
            errorMsg: (resp.data.error as string) || "Something went wrong!!",
          })
        );
      }
    } catch (e) {
      console.error("e---", e);

      dispatch(
        widgetDataSourcesAction({
          apiStatus: APIStatus.ERROR,
          errorMsg: e as string,
        })
      );
    }
  };

// fetchDataSourceWidgets fetches the widgets of the data source associated with the given dashboard key.
export const fetchDataSourceWidgets =
  (dashboardKey: string) => async (dispatch: (arg: unknown) => void) => {
    dispatch(
      dataSourceWidgetsAction({
        apiStatus: APIStatus.LOADING,
      })
    );

    try {
      const resp = await CreateRequest({
        url: `${dashboardURL}/all-widgets?reportKey=${dashboardKey}`,
        method: "GET",
      });

      if (resp.status === 200) {
        dispatch(
          dataSourceWidgetsAction({
            dashboardKey,
            apiStatus: APIStatus.SUCCESS,
            sources: resp.data as DashboardSource[],
          })
        );
      } else {
        dispatch(
          dataSourceWidgetsAction({
            apiStatus: APIStatus.ERROR,
            errorMsg: (resp.data.error as string) || "Something went wrong!!",
          })
        );
      }
    } catch (e) {
      console.error("e---", e);

      dispatch(
        dataSourceWidgetsAction({
          apiStatus: APIStatus.ERROR,
          errorMsg: e as string,
        })
      );
    }
  };
