declare global {
  interface Window {
    Middleware: any;
  }
}

import MWApplication from "core/application/app";
import { StatusPageData, User } from "core/application/model";
import { getAuthStore, triggerRegisterEvent } from "core/utils";
import EventEmitter from "core/utils/events";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getAuthToken } from "store/selectors";
import { requestUserUpdate } from "store/settings/user/api";
import { unregister } from "store/subscription/api";
import AppContext, { ChangeContextProps, ContextProps } from "./context";

const MwProvider = (props: any) => {
  const dispatch = useDispatch();
  const initialState: ContextProps = {
    changeContext: (obj: ChangeContextProps) => {
      setValue((state: any) => {
        return { ...state, [obj.key]: obj.value };
      });
    },
    application: new MWApplication(),
    isAuthenticated: !!getAuthStore(),
    user: undefined,
    statusPageData: undefined,
  };
  const applicationCleanUp = (e: any) => {
    // e.returnValue = 'onbeforeunload';
    props.unregister();
    // return 'onbeforeunload';
  };
  useEffect(() => {
    if (value.isAuthenticated) {
      value.application.init((user: User) => {
        if (
          !window.location.href.includes(".mw.lc") &&
          typeof user?.user_meta_data?.GA_EVENT !== "undefined" &&
          user?.user_meta_data?.GA_EVENT != "SENT"
        ) {
          let x = 0;
          const timer = setInterval(() => {
            triggerRegisterEvent((fired: boolean) => {
              x++;
              if (fired) {
                clearInterval(timer);
                user.user_meta_data = {
                  ...user.user_meta_data,
                  GA_EVENT: "SENT",
                  GA_EVENT_TS: Date.now(),
                };
                setValue({
                  ...value,
                  user,
                });
                dispatch(
                  requestUserUpdate({
                    id: user.user_id,
                    meta_data: user.user_meta_data,
                    _init: true,
                  })
                );
              }
            });
          }, 1500);
          if (x > 40) {
            clearInterval(timer);
          }
        }
        user.isSandboxUser = false;
        setValue({
          ...value,
          user,
        });
      });
      window.addEventListener("beforeunload", applicationCleanUp);

      EventEmitter.on("CONTEXT_UPDATE", ({ key, value }: any) => {
        setValue((state: any) => {
          return { ...state, [key]: value };
        });
      });
    }

    value.application.statuspageInit((statusPageData: StatusPageData) => {
      if (statusPageData) {
        setValue({
          ...value,
          statusPageData,
        });
      }
    });
  }, []);
  const [value, setValue] = useState<ContextProps>(initialState);
  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};
export default connect(
  (state) => {
    return {
      isAuthorised: getAuthToken(state),
    };
  },
  {
    unregister,
  }
)(MwProvider);
