import * as React from "react";
import {RoutesPatterns} from "core/utils/route_schema";

const AuthRoutes: RoutesPatterns = {
    moduleName: "auth",
    slug: '/auth',
    items: [
        {
            pattern: [
                "/login",
            ],
            component: React.lazy(() => import("views/layouts/auth/components/login"))
        },
        {
            pattern: [
                "/register"
            ],
            // component: React.lazy(() => import("views/layouts/auth/components/register"))
            component: React.lazy(() => import("views/layouts/auth/components/register-with-gmail"))
        },
        // {
        //     pattern: [
        //         "/register-with-email"
        //     ],
        //     component: React.lazy(() => import("views/layouts/auth/components/register-with-gmail"))
        // },
        {
            pattern: [
                "/set-password/:token"
            ],
            component: React.lazy(() => import("views/layouts/auth/components/set-password"))
        },
        {
            pattern: [
                "/thankyou"
            ],
            component: React.lazy(() => import("views/layouts/auth/components/thankyou"))
        },
        {
            pattern: [
                "/forgot-password"
            ],
            component: React.lazy(() => import("views/layouts/auth/components/forgot-password"))
        },
        {
            pattern: [
                "/register-confirm/:token"
            ],
            component: React.lazy(() => import("views/layouts/auth/components/register-confirm"))
        },
    ]
}
export default AuthRoutes

