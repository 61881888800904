import * as React from "react";
import {RoutesPatterns} from "core/utils/route_schema";

const ProfileRoutes: RoutesPatterns = {
    moduleName: "profile",
    slug: "/profile",
    breadcrumbs: React.lazy(() => import("views/modules/profile/breadcrumbs")),
    items: [
        {
            pattern: [
                "/",
            ],
            component: React.lazy(() => import("views/modules/profile/index")),
            breadcrumbTitle: "Profile"
        },

    ]
}
export default ProfileRoutes