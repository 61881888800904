import React, { useState } from "react";
import "../../style.scss";
import { RenderGraph } from "../render-graph";

interface WrappedComponentProps {
  sectionExpand: boolean;
}

const withSectionExpand = <P extends WrappedComponentProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  const HOC = (props: P) => {
    const [sectionExpand, setSectionExpand] = useState(true);

    const renderGraph = (
      <div className={"bar-scale"}>
        <RenderGraph
          expandableTitleOptions={{
            showExpandable: true,
            titleOnClick: (expanded) => {
              setSectionExpand(expanded);
            },
          }}
          chartType="bar_chart"
          column={[
            "total_info",
            "total_errors",
            "total_warnings",
            "total_debug",
          ]}
          titleInfo="Graph built based on selected severity values overtime"
          resourceType="log"
          widgetAppId={2}
          title="LOGS OVER TIME"
          columnConfig={{
            total_info: { color: "var(--color-secondary)" },
            total_errors: { color: "var(--color-error)" },
            total_warnings: { color: "var(--color-warning)" },
            total_debug: { color: "var(--color-success)" },
          }}
        />
      </div>
    );

    return (
      <>
        <div className="line-chart-container">
          {/* <SectionExpand
            expandItem={sectionExpand}
            handleSectionExpand={() => setSectionExpand(!sectionExpand)}
            showBorder={false}
            shouldtitleStyleBold={false}
            title="Logs over time"
          >
           
          </SectionExpand> */}
          {renderGraph}
        </div>
        <WrappedComponent {...props} sectionExpand={sectionExpand} />
      </>
    );
  };

  return HOC;
};

export default withSectionExpand;
