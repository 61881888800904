import React from "react";
import {
  StatusPageIncidentHistory,
} from "../page_list/model";
interface Props {
  history: StatusPageIncidentHistory;
}

const HistoryItem: React.FC<Props> = ({ history }) => {
  const date = new Date(history.CreatedAt ? history.CreatedAt : "");
  const formattedDate = date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  return (
    <div className="history-item">
      <div>
        <span className="bold-text">{history.Status ? history.Status : ""}</span>
        <p className="history-message">{history.Message ? history.Message : ""}</p>
      </div>
      <div>
        <span className="bold-text">{`${formattedDate} - ${formattedTime}`}</span>
      </div>
    </div>
  );
};

export default HistoryItem;
