import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";

import { getTenantUrl } from "core/application/utils";
import FormTextInput from "core/components/v2/form/form-text-input";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import Command from "../../../components/code-block/command";
import StepItem from "../../../components/steps/step-item";
import APMAgentConnect from "../../common/apm-agent-connect";
import ViewYourData from "../../common/view-data-buttons";

interface ContentProps {
  serverless: string;
}

const Content: React.FC<ContentProps> = ({ serverless }) => {
  const [appEnv, setAppEnv] = useState<number>(1);
  const [appBehaviour, setAppBehaviour] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];

  interface StepFormat {
    title: string;
    content: React.ReactNode;
  }

  const [activeServiceName, setActiveServiceName] = useState("");
  const [rubyCommand, setRubyCommand] = useState("");
  const sendEvent = useMixPanel();
  const steps: StepFormat[] = [
    {
      title: "Add these dependencies in your Gemfile",
      content: (
        <>
          To install the Middleware Agent, your Ruby version must have version
          3.0+.
          <br />
          <br />
          <Command
            command={`gem 'opentelemetry-sdk'
gem 'opentelemetry-exporter-otlp'
gem 'opentelemetry-instrumentation-all'
gem 'pyroscope'
gem 'middleware_apm_linux', '~> 2.1.0'`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Ruby",
                command_type: "Add these dependencies in your Gemfile",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Add this code at the very start of your application",
      content: (
        <>
          <Command
            command={`require 'middleware/ruby_gem_linux'
Middleware::RubyGem.init`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Ruby",
                command_type:
                  "Add this code at the very start of your application",
              });
            }}
          />
          <div className="apm-service-name-block">
            <div className="apm-service-name-label">
              Run your application with command given below{" "}
            </div>
            <FormTextInput
              id="ruby-service-text-input"
              // label={"Service Name"}
              className="apm-service-name-input"
              placeholder={"Service Name"}
              value={activeServiceName}
              onChange={(e: any) => setActiveServiceName(e)}
            />
            <FormTextInput
              id="ruby-command-text-input"
              // label={"Service Name"}
              className="apm-service-name-input"
              placeholder={"Ruby Command"}
              value={rubyCommand}
              onChange={(e: any) => setRubyCommand(e)}
            />
          </div>
          <br />
          <Command
            command={`OTEL_EXPORTER_OTLP_ENDPOINT=http://localhost:9320 \\ 
OTEL_SERVICE_NAME = "${activeServiceName == "" ? "{APM-SERVICE-NAME}" : activeServiceName}" \\
MW_API_KEY= ${context.user?.account?.agent_config?.token} \\
${rubyCommand == "" ? "{RUBY-COMMAND}" : rubyCommand}`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Ruby",
                command_type:
                  "Add this code at the very start of your application",
              });
            }}
          />
        </>
      ),
    },
  ];

  let indexOffset = 0;
  if (serverless != "true") {
    indexOffset = 2;
  }

  return (
    <>
      {serverless != "true" && <APMAgentConnect agentName="Ruby" />}
      {steps.map((step, index) => (
        <StepItem stepNumber={index + indexOffset + 1} title={step.title}>
          {step.content}
        </StepItem>
      ))}
      <ViewYourData
        language="Ruby"
        stepNumber={steps.length + indexOffset + 1}
      />
    </>
  );
};
export default Content;
