import Dropdown from "core/components/dropdown";
import React from "react";
import { MetricDetails, getColumnName, getMetricDetails, isAggregationSupported, isMetricAggregation } from "../../common/utils";
import { ChartType, DefaultNoneValue, KpiType, MetricItem, MetricTypeEnum, OnDropDownChange, Query, SelectOneMetric, getKpiName, toKpiType } from "../../entities/builder.entities";
import useBuilder from "../../views/core/useBuilder";
import "./_style.scss";
export type MetricProps = {
    chartType: ChartType;
    builderConfig: Query;
    onMetricUpdate: (updatedConfig: Query) => void;
}
const Metric = (metricProps: MetricProps) => {
    const { chartType, onMetricUpdate, builderConfig } = metricProps;

    const builder = useBuilder();
    const { getAllMetrics, getResourceMetrics } = builder.selectors;
    const existingMetric = builderConfig.columns.length > 0 ? builderConfig.columns[0] as string : "";
    const selectedMetric = existingMetric as string;
    const metricDetails = getMetricDetails(selectedMetric);
    let metrics = getAllMetrics(chartType);
    const resourceType = builderConfig.source.name;
    const queryType = toKpiType(resourceType);
    if (queryType === KpiType.KpiTypeLog || queryType === KpiType.KpiTypeTrace) {
        metrics = getResourceMetrics(getKpiName(queryType), chartType);
    }

    const onUpdate = (details: MetricDetails, newResource: string) => {
        const updateMetricName = getColumnName(details);
        // onMetricUpdate(updateMetricName);
        const copy = { ...builderConfig };
        copy.columns[0] = updateMetricName;
        if (copy.source.name !== newResource) {
            copy.source.name = newResource;
        }
        onMetricUpdate(copy);
    }
    const options = metrics.map((a: MetricItem) => a);
    options.push({
        label: DefaultNoneValue,
        name: DefaultNoneValue,
        type: MetricTypeEnum.MetricTypeNone,
        attributes: {},
        resource: "",
    });

    const metricSelection = () => {
        return <React.Fragment>
            <Dropdown
                search={true}
                options={options.sort((key1, key2) => {
                    if (key1.name < key2.name) {
                        return -1;
                    }
                    if (key1.name > key2.name) {
                        return 1;
                    }
                    return 0;
                }).map((a: MetricItem) => {
                    const resourceName = a.resource;
                    const isDuplicate = options.filter((b: MetricItem) => b.name === a.name).length > 1;
                    const shouldAddResource = (a.type === MetricTypeEnum.ResourceAttributeKey || a.type === MetricTypeEnum.MetricAttributeKey) || isDuplicate;
                    const label = shouldAddResource ? `${resourceName}.${a.name}` : a.name;
                    return {
                        label: label,
                        value: label,
                    }
                })}
                label={"Metrics"}
                value={metricDetails.metricName}
                onChange={(e: OnDropDownChange) => {
                    const selectedOption = options[e.index];
                    if (!selectedOption) {
                        return;
                    }
                    if (selectedOption.name === DefaultNoneValue) {
                        metricDetails.metricName = SelectOneMetric;
                        metricDetails.aggregation = "avg";
                        metricDetails.subAggregation = "avg";
                        onUpdate(metricDetails, selectedOption.resource);
                        return;
                    }
                    let agg = metricDetails.aggregation || "avg";
                    let subAggregation = metricDetails.subAggregation || "avg";
                    if (!isAggregationSupported(selectedOption.type, agg) && isMetricAggregation(agg)) {
                        agg = "any";
                        subAggregation = "any";
                    }
                    metricDetails.metricName = selectedOption.name;
                    metricDetails.aggregation = agg;
                    metricDetails.subAggregation = subAggregation;
                    onUpdate(metricDetails, selectedOption.resource);

                }}
            />
        </React.Fragment>
    }
    return <div className={"single-metric"}>
        <div className="selection">
            {metricSelection()}
        </div>
    </div>
}
export default Metric;