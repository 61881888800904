import * as React from "react";
import NavTab from "core/components/nav-tab";
import TabItems from "core/components/nav-tab/items";
import {
    DebAndUbuntuColorSvg, DenoSvgV2, DockerColorSvg, InstallationDocsIcon,
    KubernetesColorSvg,
    LinuxIcon,
    WindowsSvg
} from "core/components/svg/icons";
import TableGridview, {GridviewColumnsOptions} from "core/components/table-gridview";
import {BkoffRightIcon, BkoffWrongIcon} from "views/layouts/app/nav/_icons";
import DenoAPM from "views/modules/installation/components/apm/deno";
import {Link} from "react-router-dom";

export default function DenoAPMInstrument() {

    const dataProvider = [
        {
            agent: <KubernetesColorSvg/>,
            flags: [
                <BkoffRightIcon/>,
                <BkoffWrongIcon/>,
                <BkoffWrongIcon/>,
                <BkoffRightIcon/>,
                <BkoffWrongIcon/>
            ]
        },
        {
            agent: <DebAndUbuntuColorSvg/>,
            flags: [
                <BkoffRightIcon/>,
                <BkoffWrongIcon/>,
                <BkoffWrongIcon/>,
                <BkoffRightIcon/>,
                <BkoffWrongIcon/>
            ]
        },
        {
            agent: <DockerColorSvg/>,
            flags: [
                <BkoffRightIcon/>,
                <BkoffWrongIcon/>,
                <BkoffWrongIcon/>,
                <BkoffRightIcon/>,
                <BkoffWrongIcon/>
            ]
        },
        {
            agent: <WindowsSvg/>,
            flags: [
                <BkoffRightIcon/>,
                <BkoffWrongIcon/>,
                <BkoffWrongIcon/>,
                <BkoffRightIcon/>,
                <BkoffWrongIcon/>
            ]
        },
    ];
    const columns: Array<GridviewColumnsOptions> = [
        {
            title: "",
            value: (data: any) => {
                return data.agent;
            }
        },
        {
            title: "Traces",
            value: (data: any) => {
                return data.flags[0];
            }
        },
        {
            title: "Metrics",
            value: (data: any) => {
                return data.flags[1];
            }
        },
        {
            title: "App logs",
            value: (data: any) => {
                return data.flags[2];
            }
        },
        {
            title: "Custom logs",
            value: (data: any) => {
                return data.flags[3];
            }
        },
        {
            title: "Profiling",
            value: (data: any) => {
                return data.flags[4];
            }
        },
    ];


    return <>
        <div className={'cloudflare-apm-new dialog-table-componants'}>
            <div className={' setting_side-panel'}>
                <div className={'integration-popup'}>
                    <div className={'header-section'}>
                        <div className={'d-flex'}>
                            <DenoSvgV2/>
                            <div className={'header-content'}>
                                <h3>Deno</h3>
                                <p>Deno is a JavaScript and TypeScript runtime for serverless applications. Monitor your
                                    Deno application running on Kubernetes, Linux, Docker, and more.</p>
                            </div>
                            <button className={"docs-link"}><Link
                                to={'https://docs.middleware.io/apm-configuration/deno'}
                                target={'_blank'}><span> <InstallationDocsIcon/>Documentation</span></Link></button>
                        </div>
                        <NavTab targetIndex={1} defaultActiveTabIndex={1}>
                            <TabItems title={'Overview'}>
                                <div className={'d-flex in-content'}>
                                    <div className={'overview-heading'}>
                                        <div className={'config-overview'}>
                                            <h4>Category</h4>
                                            <span>APM</span>

                                            <h4 className={'tp-mr'}>Supported OS</h4>
                                            <div className={'d-flex'}>
                                                <LinuxIcon/>
                                                <WindowsSvg/>
                                                {/*<MacIcon/>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'overview-content'}>
                                        <div className={'overview-image'}>
                                            <img src={'assets/images/apm-overview.png'}
                                                 alt={'bare-metal-overview'}/>
                                        </div>
                                        <h3>APM Support</h3>
                                        <TableGridview
                                            columns={columns}
                                            dataProvider={dataProvider}
                                        />

                                    </div>
                                </div>
                            </TabItems>
                            <TabItems title={'Installation'}>
                                <div className={'panel-body'}>
                                    <DenoAPM/>
                                </div>

                            </TabItems>

                        </NavTab>
                    </div>
                </div>

            </div>
        </div>
    </>
}