import { CreateWidgetIcon, GoToActionIcon } from "core/components/v2/svg/icons";
import React from "react";
import { AddWidgetCardProps } from "./entities";
import "./_style.scss";

export default function CreateNewWidgetCard({
  onCardClick,
}: AddWidgetCardProps) {
  return (
    <div
      className="add-widget-action-card"
      onClick={(e) => {
        e.stopPropagation();
        onCardClick();
      }}
    >
      <CreateWidgetIcon />
      <span className="button-text">
        Create New Widget for <br />
        Metrics, Logs or Traces
      </span>
      <GoToActionIcon />
    </div>
  );
}
