import {
  AllIntegrationIcon,
  BareMetalIcons,
  ContainerInstIcons,
  CollaborationIntegrationIcon,
  DataBaseIntegrationIconV2,
  StreamingIntegrationIcon,
  TelemetryIntegrationIcon,
  RUMInstIcons,
  AllInstrumentationIcons,
  AllDataIngestionApi,
  AWSCloudIngestion,
  OpenTelemetryV2All,
  MobileRUMIconV2,
  BrowserRUMIconV2,
  NetworkingIntegrationIconV2,
} from "core/components/svg/icons";
import { NamedExoticComponent } from "react";
import Integration from "views/modules/integrations/components/Integration";
import InstrumentationCommon from "views/modules/installation/components/instrumentation";
import {
  DataIngestionAPiItems,
  InstrumentationIntegrationItems,
  IntegrationItem,
  IntegrationListItems,
} from "views/modules/integrations/entity";
import DataIngestionAPiIngegrationItems from "views/modules/integrations/components/data-ingestion-api";

export type SectionItem = {
  key: string;
  title: string;
  link: string;
  Component: (props: any) => JSX.Element;
  componentProps?: any;
  icon: NamedExoticComponent;
  items?: IntegrationItem[];
  subItems?: IntegrationItem[];
};
export type SectionType = {
  key: string;
  title: string;
  items: SectionItem[];
};

const Sections: SectionType[] = [
  {
    key: "instrumentation",
    title: "All Instrumentation Services",
    items: [
      {
        title: "All Instrumentation",
        link: "/installation-v1#instrumentation/allinstrumentation",
        icon: AllInstrumentationIcons,
        key: "allinstrumentation",
        Component: InstrumentationCommon,
        items: InstrumentationIntegrationItems,
      },
      {
        title: "Infrastructure",
        link: "/installation-v1#instrumentation/infrastructure",
        icon: BareMetalIcons,
        key: "infrastructure",
        Component: InstrumentationCommon,
      },
      {
        key: "apm",
        title: "APMs",
        link: "/installation-v1#instrumentation/apm",
        icon: ContainerInstIcons,
        Component: InstrumentationCommon,
      },
      {
        key: "rum",
        title: "Real User Monitoring",
        link: "/installation-v1#instrumentation/rum",
        icon: RUMInstIcons,
        Component: InstrumentationCommon,
        subItems: [
          {
            key: "browser",
            title: "Browser",
            link: "/installation-v1#instrumentation/rum/browser",
            icon: BrowserRUMIconV2,
            component: InstrumentationCommon,
          },
          {
            key: "mobile",
            title: "Mobile",
            link: "/installation-v1#instrumentation/rum/mobile",
            icon: MobileRUMIconV2,
            component: InstrumentationCommon,
          },
        ],
      },
    ],
  },

  {
    key: "custom_apis",
    title: "",
    items: [
      {
        title: "All Data Ingestion APIs",
        link: "/installation-v1#custom_apis/alldataingestionapi",
        icon: AllDataIngestionApi,
        key: "alldataingestionapi",
        Component: DataIngestionAPiIngegrationItems,
        items: DataIngestionAPiItems,
      },
      {
        title: "OpenTelemetry",
        link: "/installation-v1#custom_apis/opentelemetry",
        icon: OpenTelemetryV2All,
        key: "opentelemetry",
        Component: DataIngestionAPiIngegrationItems,
      },
    ],
  },
  {
    key: "integration",
    title: "Integrations",
    items: [
      {
        title: "All Integrations",
        link: "/installation-v1#integration/allintegration",
        icon: AllIntegrationIcon,
        key: "allintegration",
        Component: Integration,
        // items:IntegrationListItems,
      },
      {
        title: "Collaboration",
        link: "/installation-v1#integration/collaboration",
        icon: CollaborationIntegrationIcon,
        key: "collaboration",
        Component: Integration,
      },
      {
        title: "Database",
        link: "/installation-v1#integration/databasemonitoring",
        icon: DataBaseIntegrationIconV2,
        key: "databasemonitoring",
        Component: Integration,
      },
      {
        title: "Networking",
        link: "/installation-v1#integration/networking",
        icon: NetworkingIntegrationIconV2,
        key: "networking",
        Component: Integration,
      },
      {
        title: "Streaming",
        link: "/installation-v1#integration/streaming",
        icon: StreamingIntegrationIcon,
        key: "streaming",
        Component: Integration,
      },
      {
        title: "Telemetry",
        link: "/installation-v1#integration/telemetry",
        icon: TelemetryIntegrationIcon,
        key: "telemetry",
        Component: Integration,
      },
      {
        title: "AWS",
        link: "/installation-v1#integration/aws",
        icon: AWSCloudIngestion,
        key: "aws",
        Component: Integration,
      },
    ],
  },
];
export default Sections;
export const defaultInstallationPath =
  "/installation#instrumentation/allinstrumentation";
export const defaultInstallationSlug =
  "/installation#instrumentation/allinstrumentation";
