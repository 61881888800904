import Button from "core/components/v2/button";
import Dialog from "core/components/dialog";
import React from "react";
import "./_style.scss";
type ConfirmationDialogProps = {
  isOpen: boolean;
  position?: "center" | "right";
  overlayClassName?: string;
  onCancel: () => void;
  onConfirm: () => void;
  confirmationMessage?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  titleMessage?: string;
};
const CofirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    isOpen,
    onConfirm,
    onCancel,
    position,
    confirmationMessage,
    confirmButtonText,
    cancelButtonText,
    titleMessage,
  } = props;
  return (
    <Dialog
      isOpen={isOpen}
      position={position || "center"}
      overlayClassName={"confirmation-dialog"}
      onClose={onCancel}
    >
      <h4>{titleMessage || "Are you sure?"}</h4>
      <h6>{confirmationMessage || ""}</h6>
      <div className={"dialog-buttons-wrapper"}>
        <Button outlined onClick={onCancel}>
          {cancelButtonText || "Cancel"}
        </Button>
        <Button error onClick={onConfirm}>
          {confirmButtonText || "Confirm"}
        </Button>
      </div>
    </Dialog>
  );
};
export default CofirmationDialog;
