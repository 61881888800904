import * as React from "react";
import {
    TypographyHeadingPrimary,
    TypographyParagraphPrimary800,
    TypographyParagraphSteps
} from "core/components/typography";
import {useContext, useState} from "react";
import AppContext, {ContextProps} from "core/components/wrapper/context";
import Command from "views/modules/installation/common/command";
import Note from "views/modules/installation/common/note";
import Button from "core/components/button";
import {useDispatch} from "react-redux";
import {generateAgentConfig} from "store/settings/installation/api";
import {showToast} from "store/toast-alerts/actions";
import NavTab from "core/components/nav-tab/index";
import TabItems from "core/components/nav-tab/items";
import {
    AlmaLinuxIcon,
    AmazonLinux,
    ContOS,
    Debian, OracelLinuxIcon,
    RedHet,
    RockyLinuxIcon, SuseLinuxIcon,
    UbuntuLinux
} from "core/components/svg/icons";
import {getTenantUrl} from "core/application/utils";
import GoToDashboard from "../common/goto-dashboard";
import Info from "views/modules/installation/common/info";

export default function UbuntuInstallation() {
    const dispatch = useDispatch();
    const context = useContext<ContextProps>(AppContext);

    const url = getTenantUrl(context.user);
    const capturePath = `https://${url}`;

    const [generatingToken, setGeneratingToken] = useState<boolean>(false);
    let firstCommand = `MW_API_KEY=${context.user?.account?.agent_config?.token} ${capturePath ? `MW_TARGET=${capturePath}:443` : ""} bash -c "$(curl -L https://install.middleware.io/scripts/deb-install.sh)"`;
    const secondCommand = `MW_API_KEY=${context.user?.account?.agent_config?.token} ${capturePath ? `MW_TARGET=${capturePath}:443` : ""} bash -c "$(curl -L https://install.middleware.io/scripts/rpm-install.sh)"`;

    if (context.user?.misc_details?.env_type != "prod") {
        firstCommand = `MW_API_URL_FOR_CONFIG_CHECK="${capturePath}" ${firstCommand}`
    }

    const generateToken = () => {
        setGeneratingToken(true);
        dispatch(generateAgentConfig((success, config) => {
            setGeneratingToken(false);
            if (success) {
                context.changeContext({
                    key: "user",
                    value: {
                        ...context.user,
                        account: {
                            ...context.user?.account,
                            agent_config: config,
                        }
                    }
                })
            } else {
                dispatch(showToast("error", "Error while generating token.Please try again."))
            }
        }))
    }
    return context.user ? <div className={"installation-page"}>
        <div className={"page-header"}>
            <div className={"title"}>
                <TypographyHeadingPrimary>Installing MW Agent on Linux-based system</TypographyHeadingPrimary>
            </div>
            <div className={"description"}>
                <TypographyParagraphPrimary800>Run the MW Agent to collect and analyze your Linux data. Refer to the <a
                    href="https://docs.middleware.io/agent-installation/linux/" target={"_blank"}
                    rel={'noreferrer'}> Linux Documentation </a> for more information.</TypographyParagraphPrimary800>
            </div>
        </div>
        <div className={"page-body"}>
            <div className={"step"}>
                <div className={"details"}>
                    <div className={"count"}>
                        <span>1</span>
                    </div>
                    <div className={"detail"}>
                        <TypographyParagraphSteps>Use our easy one-step install.</TypographyParagraphSteps>
                    </div>
                </div>
                {
                    context.user?.account?.agent_config?.token ?
                        <React.Fragment>
                            <br/>
                            <NavTab defaultActiveTabIndex={0}>
                                <TabItems title={'DEB'}>
                                    <Command
                                        command={firstCommand}/>
                                    <p className={'fn-size'}>This script can be used for Debian based Linux
                                        distributions. Popular distributions are given below as examples.</p>
                                    <div className={'d-flex'}>
                                        <button className={'example-linux'}><Debian/></button>
                                        <button className={'example-linux'}><UbuntuLinux/></button>
                                    </div>
                                </TabItems>
                                <TabItems title={'RPM'}>
                                    <Command
                                        command={secondCommand}/>
                                    <p className={'fn-size'}>This script can be used for RPM based Linux distributions.
                                        Popular distributions are given below as examples.</p>
                                    <div className={'d-flex'}>
                                        <button className={'example-linux'}><RedHet/></button>
                                        <button className={'example-linux'}><ContOS/></button>
                                        <button className={'example-linux'}><AmazonLinux/></button>
                                        <button className={'example-linux'}><AlmaLinuxIcon/></button>
                                        <button className={'example-linux'}><RockyLinuxIcon/></button>
                                        <button className={'example-linux'}><SuseLinuxIcon/></button>
                                        <button className={'example-linux'}><OracelLinuxIcon/></button>
                                    </div>
                                </TabItems>
                            </NavTab>

                        </React.Fragment>
                        : <div className={"command"}><Button loading={generatingToken} disabled={generatingToken}
                                                             onClick={generateToken}>Generate Token</Button></div>
                }
                <Note
                    note={"Run only one MW Agent per host or cluster. Multiple agents will cause unexpected behavior."}/>


            </div>
            <div className={"step"}>
                <div className={"details"}>
                    <div className={"count"}>
                        <span>2</span>
                    </div>
                    <div className={"detail"}>
                        <TypographyParagraphSteps>Status of Agent</TypographyParagraphSteps>
                    </div>
                </div>
<br/>
                <NavTab defaultActiveTabIndex={0}>
                    <TabItems title={'v1.6.4 or higher'}>
                        <Command command={"sudo systemctl status mw-agent"}/>
                    </TabItems>
                    <TabItems title={'Up to v1.6.3'}>
                        <Command command={"sudo systemctl status mwservice"}/>
                    </TabItems>
                </NavTab>

                {/*<Command command={"sudo systemctl status mw-agent"}/>*/}
                {/*<Info info={"If your Infra Agent is older than v1.6.4, use the below command to check the installation status."} />*/}
                {/*<Command command={"sudo systemctl status mwservice"}/>*/}
            </div>

            <GoToDashboard dashboardURL="/infrastructure/host/dashboard"/>
        </div>
    </div> : <div>Loading</div>
}