import {INIT_IN_FLIGHT, RECEIVED_INIT} from "./constant";



export const initInFlight = (flag: boolean = false) => {
    return {
        type: INIT_IN_FLIGHT,
        flag
    }
}

export const receivedInit = (arg: any = {}) => {
    return {
        type: RECEIVED_INIT,
        ...arg
    }
}