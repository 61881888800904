import { getTenantUrl } from "core/application/utils";
import { Message } from "core/components/v2/enums";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import Command from "../../components/code-block/command";
import MwMessage from "../../components/message";
const DockerInstallationSnippet = () => {
  const [tab, setTab] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Linux Based OS Version",
          value: "Running on LTS",
        },
      ],
    },
  ];

  let dockerInstallCommand = `MW_API_KEY=${context.user?.account?.agent_config?.token} MW_TARGET=${capturePath}:443 bash -c "$(curl -L https://install.middleware.io/scripts/docker-install.sh)"`;
  if (context.user?.misc_details?.env_type != "prod") {
    dockerInstallCommand = `MW_API_URL_FOR_CONFIG_CHECK="${capturePath}" ${dockerInstallCommand}`;
  }
  const sendEvent = useMixPanel();
  return (
    <>
      <Command
        onCopyClick={() => {
          sendEvent("click on copy command", {
            agent_type: "Docker",
            command_type: "Install Middleware Host Agent",
          });
        }}
        command={dockerInstallCommand}
        className="margin-0"
      />
      <MwMessage
        type={Message.Info}
        message="This script will run Middleware Host Agent as a docker container"
      ></MwMessage>
      <MwMessage
        type={Message.Warning}
        message="Run only one MW Agent per host. Multiple agents will cause unexpected behavior."
      ></MwMessage>
    </>
  );
};
export default DockerInstallationSnippet;
