import CreateRequest from "core/xhr";
import {receivedCountriesList, receivedCountriesTimezone} from "./actions";

export const countryList = () => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/countries",
            method: 'get',
            data: {}
        }).then( res => {
            dispatch(receivedCountriesList(res.data))
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const countriesTimezone = () => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/countries-timezone",
            method: 'get',
            data: {}
        }).then( res => {
            dispatch(receivedCountriesTimezone(res.data))
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}



