import * as React from "react";
import {RoutesPatterns} from "core/utils/route_schema";

export const GuidelinesRoutes: RoutesPatterns = {
    moduleName: "guidelines",
    slug: "/guidelines",
    sidebar: React.lazy(() => import("views/modules/guidelines/sidebar")),
    items: [
        {
            pattern: [
                "/",
                "/*",
            ],
            breadcrumbTitle: "Guidelines",
            component: React.lazy(() => import("views/modules/guidelines/index"))
        },
        {
            pattern: [
                "/buttons",
            ],
            breadcrumbTitle: "Buttons",
            component: React.lazy(() => import("core/components/button/button-example"))
        },
        {
            pattern: [
                "/test",
            ],
            breadcrumbTitle: "Test",
            component: React.lazy(() => import("./test.example"))
        },
        {
            pattern: [
                "/chart_examples",
            ],
            breadcrumbTitle: "Chart Examples",
            component: React.lazy(() => import("./chart-examples/index"))
        },
        {
            pattern: [
                "/range_brush_chart_examples",
            ],
            breadcrumbTitle: "Range Brush ReChart",
            component: React.lazy(() => import("core/components/rechart/example"))
        },
        {
            pattern: [
                "/forms",
            ],
            breadcrumbTitle: "Forms",
            component: React.lazy(() => import("core/components/form/form-example"))
        },
        {
            pattern: [
                "/popover",
            ],
            breadcrumbTitle: "Popover",
            component: React.lazy(() => import("core/components/popover/popover-example"))
        },
        {
            pattern: [
                "/status-badge",
            ],
            breadcrumbTitle: "Status Badge",
            component: React.lazy(() => import("core/components/status-badge/status-badge-example"))
        },
        {
            pattern: [
                "/action-menu",
            ],
            breadcrumbTitle: "Action Menu",
            component: React.lazy(() => import("core/components/action-menu/action-menu-example"))
        },
        {
            pattern: [
                "/progress-bar",
            ],
            breadcrumbTitle: "Progress Bar",
            component: React.lazy(() => import("core/components/progress-bar/progress-example"))
        },
        {
            pattern: [
                "/nav-tab",
            ],
            breadcrumbTitle: "Nav Tab",
            component: React.lazy(() => import("core/components/nav-tab/nav-tab-example"))
        },
        {
            pattern: [
                "/gridview",
            ],
            breadcrumbTitle: "Gridview",
            component: React.lazy(() => import("core/components/gridview/gridview-example"))
        },
        {
            pattern: [
                "/tooltip",
            ],
            breadcrumbTitle: "Tooltip",
            component: React.lazy(() => import("core/components/tooltip/tooltip-example"))
        },
        {
            pattern: [
                "/search",
            ],
            breadcrumbTitle: "Search",
            component: React.lazy(() => import("core/components/search/search-example"))
        },
        {
            pattern: [
                "/pagination",
            ],
            breadcrumbTitle: "pagination",
            component: React.lazy(() => import("core/components/pagination/pagination-example"))
        },
        {
            pattern: [
                "/dropdown",
            ],
            breadcrumbTitle: "Dropdown",
            component: React.lazy(() => import("core/components/dropdown/dropdown-example"))
        },
        {
            pattern: [
                "/dialog",
            ],
            breadcrumbTitle: "Dialog",
            component: React.lazy(() => import("core/components/dialog/dialog-example"))
        },
        {
            pattern: [
                "/card",
            ],
            breadcrumbTitle: "Card",
            component: React.lazy(() => import("core/components/card/card-example"))
        },
        {
            pattern: [
                "/loader",
            ],
            breadcrumbTitle: "Loader",
            component: React.lazy(() => import("core/components/loader/loader-example"))
        },
        {
            pattern: [
                "/multiline",
            ],
            breadcrumbTitle: "Multiline",
            component: React.lazy(() => import("core/components/charts/multiline/multiline-example"))
        },
        {
            pattern: [
                "/code-viewer",
            ],
            breadcrumbTitle: "Code Viewer",
            component: React.lazy(() => import("core/components/code-viewer/code-viewer-example"))
        },
        {
            pattern: [
                "/datepicker",
            ],
            breadcrumbTitle: "Datepicker",
            component: React.lazy(() => import("core/components/datepicker/datepicker-example"))
        },
        {
            pattern: [
                "/charts/scatter-plot",
            ],
            breadcrumbTitle: "Chart Scatter Plot",
            component: React.lazy(() => import("core/components/charts/scatter-plot/example"))
        },
        {
            pattern: [
                "/avatar",
            ],
            breadcrumbTitle: "Avatar",
            component: React.lazy(() => import("core/components/avatar/avatar-example"))
        },
        {
            pattern: [
                "/avatar-group",
            ],
            breadcrumbTitle: "Avatar Group",
            component: React.lazy(() => import("core/components/avatar-group/avatargroup-example"))
        },
        {
            pattern: [
                "/charts/bar",
            ],
            breadcrumbTitle: "Chart Bar",
            component: React.lazy(() => import("core/components/charts/bar-chart/example"))
        },
        {
            pattern: [
                "/charts/donut-chart",
            ],
            breadcrumbTitle: "Chart Donut",
            component: React.lazy(() => import("core/components/charts/donut-chart/donut-example"))
        },
        {
            pattern: [
                "/media-uploader",
            ],
            breadcrumbTitle: "Media Uploader",
            component: React.lazy(() => import("core/components/media-uploader/media-uploader-example"))
        },
        {
            pattern: [
                "/table-gridview",
            ],
            breadcrumbTitle: "Table GridView",
            component: React.lazy(() => import("core/components/table-gridview/example"))
        },
        {
            pattern: [
                "/svg-icon",
            ],
            breadcrumbTitle: "Svg Icons",
            component: React.lazy(() => import("core/components/svg/example"))
        },
        {
            pattern: [
                "/time-range",
            ],
            breadcrumbTitle: "Time Range",
            component: React.lazy(() => import("core/components/time-range/time-range-example"))
        },
        {
            pattern: [
                "/timeline-picker",
            ],
            breadcrumbTitle: "Time Line Picker",
            component: React.lazy(() => import("core/components/timeline-picker/timeline-picker-example"))
        },
        {
            pattern: [
                "/time-scale",
            ],
            breadcrumbTitle: "Time Scale",
            component: React.lazy(() => import("core/components/time-scale/time-scale-example"))
        },
        {
            pattern: [
                "/toast-alerts",
            ],
            breadcrumbTitle: "Toast Alerts",
            component: React.lazy(() => import("core/components/toast-alerts/toast.example"))
        },
        {
            pattern: [
                "/line-bar-chart",
            ],
            breadcrumbTitle: "Line Bar Chart",
            component: React.lazy(() => import("core/components/charts/line-bar-chart/line-bar-example"))
        },
        {
            pattern: [
                "/accordion",
            ],
            breadcrumbTitle: "AccordionExample",
            component: React.lazy(() => import("core/components/accordion/accordion-example"))
        },
        {
            pattern: [
                "/chart-treemap",
            ],
            breadcrumbTitle: "ChartTreemap",
            component: React.lazy(() => import("core/components/charts/treemap/example"))
        },
    ]
}

export default GuidelinesRoutes