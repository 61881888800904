import { getTenantUrl } from "core/application/utils";
import FormTextInput from "core/components/v2/form/form-text-input";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import Command from "../../../components/code-block/command";
import StepItem from "../../../components/steps/step-item";
import APMAgentConnect from "../../common/apm-agent-connect";
import ViewYourData from "../../common/view-data-buttons";

interface ContentProps {
  serverless: string;
}

const Content: React.FC<ContentProps> = ({ serverless }) => {
  const [appEnv, setAppEnv] = useState<number>(1);
  const [appBehaviour, setAppBehaviour] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];

  interface StepFormat {
    title: string;
    content: React.ReactNode;
  }

  const [activeServiceName, setActiveServiceName] = useState("demo-service");
  const sendEvent = useMixPanel();
  const steps: StepFormat[] = [
    {
      title: "Install Middleware Worker Package",
      content: (
        <>
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Cloudflare",
                command_type: "Install Middleware Worker Package",
              });
            }}
            command={`npm i @middleware.io/agent-apm-worker`}
            className="margin-0"
          />
        </>
      ),
    },
    {
      title: "Import Tracker",
      content: (
        <>
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Cloudflare",
                command_type: "Import Tracker",
              });
            }}
            command={`import { init,track } from '@middleware.io/agent-apm-worker';`}
            className="margin-0"
          />
        </>
      ),
    },
    {
      title: "Initialize Tracker with your Middleware API key",
      content: (
        <>
          <div className="apm-service-name-block">
            <div className="apm-service-name-label">
              Add this snippet in your code{" "}
            </div>
            <FormTextInput
              id="go-service-text-input"
              // label={"Service Name"}
              className="apm-service-name-input"
              placeholder={"Service Name"}
              value={activeServiceName}
              onChange={(e: any) => setActiveServiceName(e)}
            />
          </div>
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Cloudflare",
                command_type: "Initialize Tracker with your Middleware API key",
              });
            }}
            command={`init({
  serviceName:"${activeServiceName == "" ? "{APM-SERVICE-NAME}" : activeServiceName}",
  accountKey:"${context.user?.account?.agent_config?.token}",
  target:"${capturePath}",
  consoleLogEnabled:false,
})`}
            className="margin-0"
          />
        </>
      ),
    },
    {
      title: "Enable Custom Logs (Optional)",
      content: (
        <>
          Custom logs are ones that the application does not produce by default.
          They may be generated within certain methods, or managed by a central
          logging method in your application. To get started with custom logs
          add the following code:
          <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Cloudflare",
                command_type: "Enable Custom Logs (Optional)",
              });
            }}
            command={`// sdk.logger.SEVERITY( MESSAGE, KEY-VALUE PAIRS ) 
sdk.logger.error("error test")
sdk.logger.error("error with attributes",{"log.file.name":"error.log"})
sdk.logger.info("info test")
sdk.logger.debug("debug test")
sdk.logger.warn("warn test")`}
            className="margin-0"
          />
        </>
      ),
    },
  ];

  let indexOffset = 0;
  if (serverless != "true") {
    indexOffset = 2;
  }

  return (
    <>
      {serverless != "true" && <APMAgentConnect agentName="Cloudflare" />}
      {steps.map((step, index) => (
        <StepItem stepNumber={index + indexOffset + 1} title={step.title}>
          {step.content}
        </StepItem>
      ))}
      <ViewYourData
        language="Cloudflare"
        stepNumber={steps.length + indexOffset + 1}
      />
    </>
  );
};
export default Content;
