import React from "react";
import { IncidentWithHistory } from "../page_list/model";
import HistoryItem from "./history-item";

interface Props {
  incidentWithHistory: IncidentWithHistory;
}

const IncidentItem: React.FC<Props> = ({ incidentWithHistory }) => {
  const { Incident: incident, History: history } = incidentWithHistory;

  const statusColors: { [key: string]: string } = {
    resolved: "rgba(45, 164, 78, 1)",
    identified: "rgba(255, 255, 50, 1)",
    update: "rgba(0, 255, 255, 1)",
    investigating: "rgba(0, 0, 255, 1)",
    monitoring: "rgba(44, 175, 254, 1)",
  };
  const getTransparentColor = (color: string, alpha: number): string => {
    const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
    const r = parseInt(rgba[0], 10);
    const g = parseInt(rgba[1], 10);
    const b = parseInt(rgba[2], 10);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const latestStatus = history[history.length - 1]?.Status || "resolved";
  const borderColor = statusColors[latestStatus] || "gray";
  const fillColor = getTransparentColor(borderColor, 0.14);

  const formattedDate = new Date(incident.CreatedAt).toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "short",
      day: "numeric",
    }
  );

  return (
    <div className="incident-item">
      <div className="status-line">
        <span
          className="circle"
          style={{
            border: `2px solid ${borderColor}`,
            backgroundColor: fillColor,
          }}
        ></span>
        <span className="bold-text">{formattedDate}</span>
        <span className="bold-text">{incident.Name}</span>
      </div>
      <div>
        {history
          .sort((a, b) => {
            if (!a.CreatedAt || !b.CreatedAt) return 0;
            return (
              new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime()
            );
          })
          .map((history, index) => (
            <HistoryItem key={index} history={history} />
          ))}
      </div>
    </div>
  );
};

export default IncidentItem;
