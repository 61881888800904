import * as React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
interface Props{
    children ?: any,
    language ?:string,
    showLineNumbers ?: boolean
    startingLineNumber ?: number
}
const CodeViewer = React.memo(function (props:Props) {
    return (
        <div className={'mw-code-viewer'}>
        <SyntaxHighlighter
            className={"code-highlighter"}
            showLineNumbers={props.showLineNumbers}
            startingLineNumber={props.startingLineNumber}
            language={props.language}
            style={docco}>
            {props.children}
        </SyntaxHighlighter>
        </div>

    );
})

export default CodeViewer
