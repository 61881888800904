import {
  EventTimelineChartIcon,
  HeatMapChartIcon,
  HexagonChartIcon,
  PieChartIcon,
  QueryCountChartIcon,
  ScatterPlotChartIcon,
  TableIcon,
  TimeSeriesChartIcon,
  TopListChartIcon,
  TreeChartIcon,
} from "core/components/v2/svg/chart-icons";
import * as React from "react";
import { ChartType, KpiType } from "../entities/builder.entities";

export interface WidgetAppType {
  id: number;
  icon: any;
  key: ChartType;
  label: string;
  description: string;
  disabled?: boolean;
  disabledResourceType?: KpiType[];
}
export const getChartTypeFromAppId = (widgetAppId: number) => {
  return (
    Object.values(WidgetsApp).find((a) => a.id === widgetAppId)?.key ||
    ChartType.TimeseriesChart
  );
};
const WidgetsApp: Record<string, WidgetAppType> = {
  time_series_chart: {
    id: 1,
    icon: <TimeSeriesChartIcon />,
    key: ChartType.TimeseriesChart,
    label: "Timeseries",
    description: "Shows multiple time series from different Apps",
    // disabled: true,
  },
  bar_chart: {
    id: 2,
    icon: <EventTimelineChartIcon />,
    key: ChartType.BarChart,
    label: "Bar Chart",
    description: "Shows multiple series from different Apps",
    // disabled: true,
  },
  data_table: {
    id: 5,
    icon: <TableIcon />,
    key: ChartType.TableChart,
    label: "List Value",
    description: "View Data in table format",
  },
  query_value: {
    id: 12,
    icon: <QueryCountChartIcon />,
    key: ChartType.QueryValueChart,
    label: "Query Value",
    description: "Shows Value",
    disabledResourceType: [KpiType.KpiTypeLog],
    // disabled: true,
  },
  pie_chart: {
    id: 3,
    icon: <PieChartIcon />,
    key: ChartType.PieChart,
    label: "Pie Chart",
    description: "Shows multiple series from different Apps",
    disabledResourceType: [KpiType.KpiTypeLog, KpiType.KpiTypeTrace],
  },
  scatter_plot: {
    id: 4,
    icon: <ScatterPlotChartIcon />,
    key: ChartType.ScatterPlotChart,
    label: "Scatter Plot",
    description: "Shows multiple series from different Apps",
    disabledResourceType: [KpiType.KpiTypeLog, KpiType.KpiTypeTrace],
  },
  count_chart: {
    id: 7,
    icon: <QueryCountChartIcon />,
    key: ChartType.CountChart,
    label: "Count Chart",
    description: "Shows count with line chart",
    disabled: true,
  },
  tree_chart: {
    id: 8,
    icon: <TreeChartIcon />,
    key: ChartType.TreeChart,
    label: "Tree Chart",
    description: "Shows tree chart",
    disabledResourceType: [KpiType.KpiTypeLog, KpiType.KpiTypeTrace],
    // disabled: true,
  },
  top_list_chart: {
    id: 9,
    icon: <TopListChartIcon />,
    key: ChartType.TopListChart,
    label: "Top List Chart",
    description: "Shows top list data",
    disabledResourceType: [KpiType.KpiTypeLog],
    // disabled: true,
  },
  heatmap_chart: {
    id: 10,
    icon: <HeatMapChartIcon />,
    key: ChartType.HeatMapChart,
    label: "Heatmap Chart",
    description: "Shows heatmap chart",
    disabledResourceType: [KpiType.KpiTypeLog, KpiType.KpiTypeTrace],
    // disabled: true,
  },
  hexagon_chart: {
    id: 11,
    icon: <HexagonChartIcon />,
    key: ChartType.HexagonChart,
    label: "Hexagon Chart",
    description: "Shows hexagon chart",
    disabledResourceType: [KpiType.KpiTypeLog, KpiType.KpiTypeTrace],
    // disabled: true,
  },
};

export const getWidgetAppName = (id: number): string => {
  for (const key in WidgetsApp) {
    if (WidgetsApp[key].id == id) {
      return key;
    }
  }

  return "";
};

export { WidgetsApp };
