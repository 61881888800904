import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from 'react-router-dom';

import Store from "store";
import LayoutViews from "views";
import MwProvider from "core/components/wrapper/wrapper-provider";
import ToastAlerts from "core/components/toast-alerts/toast.index";
import { ErrorBoundary } from 'react-error-boundary';
const ErrorFallback = () => {
    const retry = localStorage.getItem('refreshRetry');
    const uiReload = () => {
        if (window.location.host.indexOf(".mw.lc") == -1) {
            window.location.reload();
        }
    }
    useEffect(() => {
        if (retry) {
            const retryCount = parseInt(retry);
            if (retryCount < 3) {
                localStorage.setItem("refreshRetry", (retryCount + 1).toString())
                uiReload()
            } else {
                localStorage.removeItem("refreshRetry");
            }
        } else {
            localStorage.setItem("refreshRetry", "1");
            uiReload()
        }

    }, []);
    if (retry && parseInt(retry) >= 3) {
        return <div className="error-context__wrapper">
            <div className="error-context__content">
                <div className="error-context__content__title">
                    <h1>{"Oops!"}</h1>
                    <h4>Something went wrong..!</h4>
                </div>
                <div className="error-context__content__message">
                    <p>Brace yourself till we get the error fixed.</p>
                    <p>You may also <a href={window.location.href}>refresh the page</a> or try again later.</p>
                </div>
            </div>
        </div>
    } else {
        return <React.Fragment/>
    }
}
const App = () => (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
        <React.StrictMode>
            <Provider store={Store}>
                <MwProvider>
                    <Router>
                        <LayoutViews />
                    </Router>
                </MwProvider>
                <ToastAlerts />
            </Provider>
        </React.StrictMode>
    </ErrorBoundary>
);

ReactDOM.render(<App />, document.getElementById("main__app-root"));