import Button from "core/components/v2/button";
import Dialog from "core/components/v2/dialog";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  BackIcon,
} from "core/components/v2/svg/icons";
import React from "react";
import { GlobalSidePanelProps } from "views/layouts/app/components/global-side-panel/entity";
import GlobalDatePicker from "views/layouts/app/components/v2/global-date-picker";
import "./_style.scss";

const GlobalSidePanel = (props: GlobalSidePanelProps) => {
  const {
    title,
    onClose,
    children,
    isOpen,
    headerElements,
    onBackBtnClick,
    datePicker = true,
    bodyPadding = true,
    size = "default",
    dialogContentClassName = "global-panel-dialog-content",
    rightAlignedHeaderElements,
    lastHeaderElement,
    footer,
    dataTestIdCloseAction,
    dataTestIdBackAction,
    dataTestIdDatePicker,
    navigation,
  } = props;

  return (
    <>
      <Dialog
        htmlOpenClassName={"mw-global-side-panel"}
        isOpen={isOpen}
        position={"right"}
        onClose={onClose}
        size={size}
        contentClassName={dialogContentClassName}
        dataTestIdCloseAction={dataTestIdCloseAction}
      >
        <div className={"global-panel--wrapper"}>
          <div className={"panel-header"}>
            <div className={"left-area"}>
              {onBackBtnClick && (
                <div className={"back-btn"}>
                  <Button
                    onClick={onBackBtnClick}
                    dataTestId={dataTestIdBackAction}
                    outlined
                    prefixicon={<BackIcon color={"var(--color-black)"} />}
                  ></Button>
                </div>
              )}
              <div className={"header-body"}>
                <div className={"title text-ellipsis"}>{title}</div>
                {headerElements && (
                  <div className={"body-child"}>{headerElements}</div>
                )}
              </div>
            </div>
            <div className={"right-area"}>
              {datePicker && (
                <GlobalDatePicker dataTestId={dataTestIdDatePicker} />
              )}
              {navigation && (
                <>
                  <Button
                    prefixicon={<ArrowLeftIcon />}
                    onClick={() => {
                      if (navigation?.dataLength && navigation?.pageNo) {
                        navigation?.pageNo - 1 !== 0
                          ? navigation?.onNavAction?.(navigation?.pageNo - 1)
                          : null;
                      }
                    }}
                    disabled={
                      navigation?.pageNo ? navigation?.pageNo - 1 === 0 : true
                    }
                  />
                  <Button
                    prefixicon={<ArrowRightIcon />}
                    onClick={() => {
                      if (navigation?.dataLength && navigation?.pageNo) {
                        navigation?.pageNo !== navigation?.dataLength
                          ? navigation?.onNavAction?.(navigation?.pageNo + 1)
                          : null;
                      }
                    }}
                    disabled={
                      navigation?.pageNo === 0
                        ? true
                        : navigation?.pageNo === navigation?.dataLength
                    }
                  />
                </>
              )}
              {rightAlignedHeaderElements}
              {lastHeaderElement}
            </div>
          </div>
          <div
            className={`panel-body ${bodyPadding ? "padding-body" : "no-padding-body"}`}
          >
            {children}
          </div>
          {footer && <div className={"panel-footer"}>{footer}</div>}
        </div>
      </Dialog>
    </>
  );
};

export default GlobalSidePanel;
