import * as React from "react";
import {
    TypographyHeadingPrimary,
    TypographyParagraphPrimary800,
    TypographyParagraphSteps
} from "core/components/typography";
import {useContext, useState} from "react";
import AppContext, {ContextProps} from "core/components/wrapper/context";
import Command from "views/modules/installation/common/command";
import Button from "core/components/button";
import {useDispatch} from "react-redux";
import {generateAgentConfig} from "store/settings/installation/api";
import {showToast} from "store/toast-alerts/actions";
import Description from "../common/description";
import TabItems from "core/components/nav-tab/items";
import NavTab from "core/components/nav-tab";
import Warning from "views/modules/installation/common/warning";
import Info from "views/modules/installation/common/info";
import GoToDashboard from "../common/goto-dashboard";
import {getTenantUrl} from "core/application/utils";

export default function KubernetesInstallation() {
    const dispatch = useDispatch();
    const context = useContext<ContextProps>(AppContext);
    const [generatingToken, setGeneratingToken] = useState<boolean>(false);
    const generateToken = () => {
        setGeneratingToken(true);
        dispatch(generateAgentConfig((success, config) => {
            setGeneratingToken(false);
            if (success) {
                context.changeContext({
                    key: "user",
                    value: {
                        ...context.user,
                        account: {
                            ...context.user?.account,
                            agent_config: config,
                        }
                    }
                })
            } else {
                dispatch(showToast("error", "Error while generating token.Please try again."))
            }
        }))
    }

    const url = getTenantUrl(context.user);
    const capturePath = `https://${url}`;

    const command = `MW_API_KEY=${context.user?.account?.agent_config?.token} MW_TARGET=${capturePath}:443 bash -c "$(curl -L https://install.middleware.io/scripts/mw-kube-agent-install.sh)"`
    return context.user ? <div className={"installation-page"}>
        <div className={"page-header"}>
            <div className={"title"}>
                <TypographyHeadingPrimary>Installing MW Agent on Kubernetes</TypographyHeadingPrimary>
            </div>
            <div className={"description"}>
                <TypographyParagraphPrimary800>Run the MW Agent to collect and analyze your Kubernetes Infrastructure
                    data. Refer to the <a href="https://docs.middleware.io/agent-installation/kubernetes/"
                                          target={"_blank"} rel={'noreferrer'}>Kubernetes Documentation</a> for more
                    information.</TypographyParagraphPrimary800>
            </div>
        </div>
        <div className={"page-body"}>
            <div className={"step"}>
                <div className={"details"}>
                    <div className={"count"}>
                        <span>1</span>
                    </div>
                    <div className={"detail"}>
                        <TypographyParagraphSteps>Prerequisites</TypographyParagraphSteps>
                    </div>
                </div>
                <div className={'table-gridview__components table-in-prerequisites'}>
                    <table>
                        <tbody>
                        <tr>
                            <th>Kubernetes Version</th>
                            <td>Kubernetes v1.21 or above <br/>
                            </td>
                        </tr>
                        <tr>
                            <th>Kubernetes Access</th>
                            <td>Access to Kubernetes cluster and client (i.e. <a
                                href={'https://kubernetes.io/docs/reference/kubectl/kubectl/'}
                                target={'_blank'}>kubectl</a>)
                            </td>
                        </tr>
                        <tr>
                            <th>CLI Tools</th>
                            <td>Install <a href={"https://www.gnu.org/software/bash/"} target={'_blank'}>bash</a>, <a
                                href={"https://curl.se/"} target={'_blank'}>curl</a> and <a
                                href={"https://www.gnu.org/software/wget/"} target={'_blank'}>wget</a></td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <div className={"step first-step"}>
                <div className={"details"}>
                    <div className={"count"}>
                        <span>2</span>
                    </div>
                    <div className={"detail"}>
                        <TypographyParagraphSteps>Install Kubernetes Infrastructure Monitor</TypographyParagraphSteps>
                    </div>
                </div>
                {
                    context.user?.account?.agent_config?.token ?
                        <React.Fragment>
                            <div className={"k8s-bat-script"}>
                                <NavTab defaultActiveTabIndex={0}>
                                    <TabItems title={'Bash'}>
                                        <Command command={command}/>
                                    </TabItems>
                                    <TabItems title={'.bat'}>
                                        <Description description="<ul><li>Download the Kubernetes Agent batch file <a href='https://install.middleware.io/scripts/mw-kube-agent-install-windows.bat' class='bat-link'>here</a></li>
                                <li>Open PowerShell, change the directory to the location of the download, and execute the following commands: </li></ul>"/>

                                        <Command
                                            command={"set MW_API_KEY=" + context.user?.account?.agent_config?.token}/>
                                        <Command command={"set MW_TARGET=" + capturePath + ":443"}/>
                                        <Command command={"mw-kube-agent-install-windows.bat"}/>
                                    </TabItems>
                                    <TabItems title={'PowerShell'}>
                                        <Description description="<ul><li>Open Powershell terminal window.</li>
                                <li>Run these commands : </li></ul>"/>

                                        <Command
                                            command={"$env:MW_API_KEY=" + context.user?.account?.agent_config?.token}/>
                                        <Command command={"$env:MW_TARGET=" + capturePath}/>

                                        <Description description="<ul><li>Optionally, you can set the following environment variable to use helm chart installation method. Make sure you have <a href='https://helm.sh/docs/intro/install/' target='_blank'>helm</a> installed on your machine.</li>
                                </ul>"/>
                                        <Command command={"$env:MW_KUBE_AGENT_INSTALL_METHOD='helm'"}/>

                                        <Description description="<ul><li>To install MW Kubernetes agent, download <a href='https://install.middleware.io/scripts/mw-kube-agent-install-powershell.ps1'>this</a> script and execute it using the following command.</li>
                                </ul>"/>
                                        <Command command={".\\mw-kube-agent-install-powershell.ps1"}/>

                                        <Description description="<ul><li>To uninstall MW Kubneretes agent, download <a href='https://install.middleware.io/scripts/mw-kube-agent-uninstall-powershell.ps1'>this</a> script and execute it using the following command.</li>
                                </ul>"/>
                                        <Command command={".\\mw-kube-agent-uninstall-powershell.ps1"}/>

                                    </TabItems>
                                </NavTab>
                            </div>
                            <Info
                                info={"This script will install a Kubernetes DaemonSet in your Kubernetes cluster, This agent is used to monitor the data in your Kubernetes infrastructure"}/>
                            <Warning
                                warning={"Run only one MW Agent DaemonSet per cluster per host. Multiple agents will cause unexpected behavior."}/>
                        </React.Fragment>
                        : <div className={"notice"}><Button loading={generatingToken} disabled={generatingToken}
                                                            onClick={generateToken}>Generate Token</Button></div>
                }
            </div>

            <GoToDashboard dashboardURL="/infrastructure/k8s.cluster/dashboard"/>
        </div>
    </div> : <div>Loading</div>
}