import { AgentConfig } from "core/application/model";
import CreateRequest from "core/xhr";
import {
  agentTokenDetailReceived,
  clientTokenListReceived,
} from "store/settings/installation/actions";

export const generateAgentConfig = (
  cb: (success: boolean, config: AgentConfig | undefined) => void
) => {
  return () => {
    return CreateRequest({
      url: "/agent/generate-token",
      method: "get",
      data: {},
    })
      .then((res: any) => {
        if (res.data.Success) {
          cb && cb(true, res.data.AgentConfig);
        } else {
          cb && cb(false, undefined);
        }
      })
      .catch((err: any) => {
        cb && cb(false, undefined);
      });
  };
};
export const startSimulate = (cb: (success: boolean) => void) => {
  return () => {
    return CreateRequest({
      url: "/account/update-simulate",
      method: "get",
      data: {},
    })
      .then((res: any) => {
        if (res.data.success) {
          cb && cb(true);
        } else {
          cb && cb(false);
        }
      })
      .catch((err: any) => {});
  };
};
export interface AgentConfigType {
  type?: string;
  token?: string;
  app_name?: string;
  language?: string;
  origin?: string[];
  tracePropagationTargets?: string[];
}
export const generateClientAgentConfig = (
  data: AgentConfigType,
  cb?: (success: boolean, config: AgentConfig | undefined) => void
) => {
  CreateRequest({
    url: "/agent/generate-client-token",
    method: "post",
    data,
  })
    .then((res: any) => {
      if (res.data.Success) {
        requestClientTokenList();
        cb && cb(true, res.data.AgentConfig);
      } else {
        cb && cb(false, undefined);
      }
    })
    .catch((err: any) => {
      cb && cb(false, err);
    });
};
export const updateClientAgentConfig = (
  data: AgentConfigType,
  cb?: (success: boolean, config: AgentConfig | undefined) => void
) => {
  CreateRequest({
    url: "/agent/update-client-token",
    method: "put",
    data,
  })
    .then((res: any) => {
      if (res.data.Success) {
        requestClientTokenList();
        cb && cb(true, res.data.data);
      } else {
        cb && cb(false, undefined);
      }
    })
    .catch((err: any) => {
      cb && cb(false, err);
    });
};
export const requestAgentTokenDetail = (
  data: { token: string },
  callback?: (res?: any) => void
) => {
  CreateRequest({
    url: "/agent/token/" + data.token,
    method: "get",
    data: {},
  })
    .then((res: any) => {
      if (res.status) {
        agentTokenDetailReceived(res.data);
      } else {
        agentTokenDetailReceived();
      }

      if (callback) {
        callback(res);
      }
    })
    .catch((err: any) => {});
};

export const requestAgentTokenByName = (
  data: { name: string },
  callback?: (res?: any) => void
) => {
  CreateRequest({
    url: "/agent/name/" + data.name,
    method: "get",
    data: {},
  })
    .then((res: any) => {
      if (res.status) {
        agentTokenDetailReceived(res.data);
      } else {
        agentTokenDetailReceived();
      }

      if (callback) {
        callback(res);
      }
    })
    .catch((err: any) => {});
};
export const requestClientTokenList = (callback?: (res?: any) => void) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/agent/client-tokens",
      method: "get",
      data: {},
    })
      .then((res: any) => {
        if (res.status) {
          dispatch(clientTokenListReceived(res.data));
        } else {
          dispatch(clientTokenListReceived());
        }
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err: any) => {});
  };
};
export const requestClientTokenDelete = (
  data: { token: string },
  callback?: (res?: any) => void
) => {
  CreateRequest({
    url: "/agent/client-token/" + data.token,
    method: "delete",
    data: {},
  })
    .then((res: any) => {
      if (callback) {
        callback(res.data);
      }
      requestClientTokenList();
    })
    .catch((err: any) => {});
};
