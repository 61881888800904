import {
  RECEIVED_SYNTHETICS_LIST,
  RECEIVED_SYNTHETICS_INFO,
  RECEIVED_SYNTHETICS_METRICS,
  RECEIVED_SYNTHETICS_LIST_ITEMS,
  STEP_COMPLETED_INDEX,
} from "./constant";

export const receivedSyntheticsList = (arg: any = {}) => {
  return {
    type: RECEIVED_SYNTHETICS_LIST,
    ...arg,
  };
};

export const receivedSyntheticsInfo = (arg: any = {}) => {
  return {
    type: RECEIVED_SYNTHETICS_INFO,
    ...arg,
  };
};

export const receivedSyntheticsMetrics = (arg: any = {}) => {
  return {
    type: RECEIVED_SYNTHETICS_METRICS,
    ...arg,
  };
};
export const receivedSyntheticsListItems = (arg: any = {}) => {
  return {
    type: RECEIVED_SYNTHETICS_LIST_ITEMS,
    ...arg,
  };
};
export const setCompletedStepIndex = (index: number) => {
  return {
    type: STEP_COMPLETED_INDEX,
    value: index,
  };
};
