/** @format */

/**
 * External dependencies
 */

import * as React from 'react';
import { omit } from 'lodash-es';
import classNames from 'classnames';
import { useState } from "react";
import { escapeHtml } from "core/application/utils";

interface Props {
    disabled?: boolean
    readOnly?: boolean
    parentStyle?: React.CSSProperties
    style?: React.CSSProperties
    isError?: boolean
    isValid?: boolean
    selectOnFocus?: boolean
    isSearch?: boolean
    label?: any
    className?: string
    groupClass?: string
    placeholder?: string
    onFocus?: (a0: any) => void
    onBlur?: (a0: any) => void
    onClick?: (a0: any) => void
    inputRef?: any
    ref?: any
    children?: any
    id?: string
    type?: string
    autoComplete?: string,
    name?: string,
    value?: any,
    onChange?: (a0: any) => void,
    onKeyUp?: (a0: any) => void,
    defaultValue?: any,
    maxLength?: number,
    sanitizeInput?: boolean,
}

let textField;
const FormTextInput = React.memo(function (props: Props) {
    const [state, setState] = useState({
        inputHasValue: false,
        isFocus: false,
    })
    const updateState = (key: string, value: boolean) => {
        setState(st => {
            return { ...st, [key]: value }
        })
    }
    const mangeOnKeyPress = (e: any) => {
        if ((e.target.value || "").trim() !== "")
            updateState("inputHasValue", true);

        else
            updateState("inputHasValue", false);
    }

    const mangeFocusEvent = (e: any) => {
        updateState("isFocus", true)
        let focusout = () => {
            updateState("isFocus", false)
            mangeOnKeyPress(e);
            e.target.removeEventListener('focusout', focusout);
        };
        e.target.addEventListener('focusout', focusout);
        if (typeof props.onFocus === "function")
            props.onFocus(e);
    }

    const inputRefByPass = (input: HTMLInputElement | null) => {
        const { inputRef } = props;
        textField = input;
        if (inputRef) {
            inputRef(input);
        }
    }


    const selectOnFocus = (event: any) => {
        if (props.selectOnFocus) {
            event.target.select();
        }
        if (typeof props.onClick === "function") {
            return props.onClick(event);
        }
    }
    const classes = classNames('form-text-input', props.className, {
        'is-error': props.isError,
        'is-valid': props.isValid,
        'no-label': !props.label,
        'isFocus': state.isFocus
    });

    let groupClassName = classNames('input-group', props.groupClass, {
        'invalid': props.isError,
        "search-input": props.isSearch,
        "input-has-value": state.inputHasValue,
        'input-is-focus': state.isFocus
    });

    return (
        <div className={groupClassName} style={props?.parentStyle ? props.parentStyle : {}}>
            <input
                type={props.isSearch ? "search" : "text"}
                {...omit(props, "isError", "parentStyle", "children", "sanitizeInput")}
                ref={(input) => inputRefByPass(input)}
                className={classes}
                // @ts-ignore
                placeholder={(props.placeholder || "").trim() + " "}
                // @ts-ignore
                onClick={(e: any) => selectOnFocus(e)}
                onFocus={(e) => mangeFocusEvent(e)}
                onChange={(e) => {
                    if (props.sanitizeInput === undefined || props.sanitizeInput) {
                        const sanitizedInput = escapeHtml(e.target.value)
                        e.target.value = sanitizedInput
                    }
                    props.onChange && props.onChange(e)
                }}
            />
            {props.label && <label htmlFor={props.id} className={classNames({
                // @ts-ignore
                'not-placeholder': (props.placeholder || "").trim() === ""
            })}>{props.label}</label>}
            {props.children}
        </div>


    );
})



export default FormTextInput
