import React, { useEffect, useState } from 'react';
import moment from "moment";
import { github, a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import SyntaxHighlighter from "react-syntax-highlighter";
import ReactJson from 'react-json-view'
import { formatLatency } from "views/modules/apm/utils";
import '../_style.scss';
import { useSelector } from "react-redux";
import { getThemesMode } from "store/selectors";
const RumErrorPanel = (props: any) => {
    const errorDetails = props?.error;
    const themeMode = useSelector(getThemesMode)

    return (
        <div className={"rum-error-panel"}>
            <div className={"header"}>
                <div className={"title"}>Errors</div>
            </div>

            <div className={"error-table"}>
                {errorDetails && errorDetails?.name && (
                    <div className="row">
                        <div className="row-header">Message</div>
                        <div className={"row-content"}>
                            {errorDetails.name}
                        </div>
                    </div>
                )}
                {errorDetails && errorDetails?.start_time && (
                    <div className="row">
                        <div className="row-header">Date & Time</div>
                        <div className={"row-content"}>
                            {`${moment(errorDetails.start_time / 1000000).format("YYYY-MM-DD hh:mm:ss")}`}
                        </div>
                    </div>
                )}
                {errorDetails && Number(errorDetails?.duration) >= 0 && (
                    <div className="row">
                        <div className="row-header">Duration</div>
                        <div className={"row-content"}>
                            {`${formatLatency(Number(errorDetails.duration))}`}
                        </div>
                    </div>
                )}
                {errorDetails && errorDetails?.first_timestamp && (
                    <div className="row">
                        <div className="row-header">First Occurrence</div>
                        <div className={"row-content"}>
                            {`${moment(errorDetails.first_timestamp / 1000000).format("YYYY-MM-DD hh:mm:ss")}`}
                        </div>
                    </div>
                )}
                {errorDetails && errorDetails?.latest_timestamp && (
                    <div className="row">
                        <div className="row-header">Latest Occurrence</div>
                        <div className={"row-content"}>
                            {`${moment(errorDetails.latest_timestamp / 1000000).format("YYYY-MM-DD hh:mm:ss")}`}
                        </div>
                    </div>
                )}
                {errorDetails && errorDetails?.trace && (
                    <div className="row">
                        <div className="row-header">Error Stack</div>
                        <div className={"row-content"}>
                            <SyntaxHighlighter
                                showLineNumbers={false}
                                language={"javascript"}
                                wrapLines={true}
                                style={themeMode === 'light' ? github : a11yDark}
                            >
                                {errorDetails.trace}
                            </SyntaxHighlighter>
                        </div>
                    </div>
                )}
                {errorDetails && errorDetails?.err_count > 0 && (
                    <div className="row">
                        <div className="row-header">Error Instances</div>
                        <div className={"row-content"}>
                            {errorDetails.err_count}
                        </div>
                    </div>
                )}
                {errorDetails && errorDetails?.events && (
                    <div className="row">
                        <div className="row-header">Error Stack</div>
                        <div className={"row-content json-viewer"}>
                            {errorDetails?.events?.[0] ? <ReactJson
                                src={JSON.parse(errorDetails?.events[0])}
                            /> : "No event details found"}
                        </div>
                    </div>
                )}
                {errorDetails && errorDetails?.other_attr && (
                    <div className="row">
                        <div className="row-header">Attributes</div>
                        <div className={"row-content json-viewer"}>
                            <ReactJson
                                src={errorDetails.other_attr}
                            />
                        </div>
                    </div>
                )}
                {errorDetails && errorDetails?.resource_attr && (
                    <div className="row">
                        <div className="row-header">Extra Attributes</div>
                        <div className={"row-content json-viewer"}>
                            <ReactJson
                                src={errorDetails.resource_attr}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default RumErrorPanel;