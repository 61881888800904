import FormTextInput from "core/components/v2/form/form-text-input";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  requestPublicSlackSubscriber,
  requestPublicSubmitSubscriber,
} from "store/status_page/api";
import { PublicSbscriber, Subscriber } from "../page_list/model";

interface SlackConfigurationProps {
  pageId: number;
  projectId: number;
}

const SlackPage = (props: SlackConfigurationProps) => {
  const { pageId, projectId } = props;
  const dispatch = useDispatch();

  const payload: PublicSbscriber = {
    page_id: Number(pageId),
    subscriber_value: "",
    subscriber_type: "slack",
    project_id: projectId,
    host: window.location.host,
  };

  const onSubmitSubscriberPage = () => {
    dispatch(
      requestPublicSlackSubscriber(
        {
          page_id: pageId,
          host: window.location.host,
          project_id: projectId
        },
        (res?: any) => {
          if (res) {
            window.location.href = res.url;
          }
        }
      )
    );
  };
  return (
    <div className={"email-page"}>
      <div style={{ padding: "12px 0", fontWeight: "bold" }}>
        <label>{"Connect with Slack"}</label>
      </div>
      <div style={{ padding: "8px 0" }}>
        <button
          className={"button-v2 is-primary"}
          onClick={() => {
            onSubmitSubscriberPage();
          }}
        >
          Connect to Slack
        </button>
      </div>
    </div>
  );
};

export default SlackPage;
