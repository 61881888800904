import { Select } from "antd";
import classNames from "classnames";
import React from "react";
import CircularLoader from "../loader/circulat-loader";
import { ArrowDownIcon, CheckmarkIcon, CloseIcon } from "../svg/icons";
import "./_style.scss";
import { DropdownMode, DropdownSelectionProps, OptionProps } from "./entity";
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const { Option } = Select;

const DropdownSelection = (props: DropdownSelectionProps) => {
  const {
    mode,
    onChange,
    disabled,
    onSearch,
    placeholder,
    options,
    notFoundContent,
    showSearch = true,
    defaultValue,
    className,
    isError,
    isMethodSelection,
    selectedValues,
    onClear,
    allowClear = false,
    onFocus,
    onBlur,
    onPopupScroll,
    extraNoteTitle,
    isLoading = false,
    maxSelection = false,
  } = props;
  const selectClassname = classNames("mw-dropdown-element", className, {
    "is-error": isError,
    "is-disabled": disabled,
    "is-method-selection": isMethodSelection,
    "is-last-option-note": extraNoteTitle && extraNoteTitle !== "",
  });

  const handleSelect = (value: string[]) => {
    if (value?.length && value.includes("all")) {
      if (value.length === options.length + 1) {
        return [];
      }
      const items: string[] = [];
      options.forEach((item) => {
        if (typeof item === "string") {
          items.push(item);
        } else {
          items.push(item.value);
        }
      });
      onChange(items);
      return items;
    }
    const newValue = [...value].splice(-maxSelection);
    onChange(maxSelection ? (newValue as string[]) : value);

    return value;
  };

  const handleFilterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const finalOptions: OptionProps[] = [];
  options.forEach((item) => {
    let obj: OptionProps = {
      label: "",
      value: "",
    };
    if (typeof item === "string") {
      obj.label = item;
      obj.value = item;
    } else {
      obj = item;
    }
    finalOptions.push(obj);
  });

  extraNoteTitle && extraNoteTitle !== ""
    ? finalOptions.push({
        label: extraNoteTitle,
        value: extraNoteTitle,
        disabled: mode !== DropdownMode.AddOptions && true,
        className: "is-last-option-note",
      })
    : finalOptions;

  return (
    <div className="mw-dropdown-selection">
      <Select
        showSearch={showSearch}
        placeholder={placeholder ?? "Select"}
        optionFilterProp="children"
        allowClear={allowClear}
        defaultValue={defaultValue}
        value={selectedValues}
        onChange={handleSelect}
        onSearch={onSearch}
        onClear={onClear}
        filterOption={handleFilterOption}
        options={finalOptions}
        notFoundContent={notFoundContent ?? "No record found"}
        mode={mode}
        maxTagCount="responsive"
        disabled={disabled}
        suffixIcon={isLoading ? <CircularLoader /> : <ArrowDownIcon />}
        menuItemSelectedIcon={<CheckmarkIcon color="var(--color-primary)" />}
        removeIcon={<CloseIcon color="var(--color-primary)" />}
        rootClassName={selectClassname}
        onPopupScroll={onPopupScroll}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {finalOptions.map((option) => {
          const isOptString = typeof option === "string";

          return (
            <Option
              key={option}
              value={isOptString ? option : option.value}
              label={isOptString ? option : option.label}
              disabled={isOptString ? false : option.disabled}
            >
              {option}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};
export default DropdownSelection;
