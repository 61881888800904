import Dashboard from "views/modules/dashboard/routes";
import Logs from "views/modules/logs-v2/routes";
import SettingsRoutes from "views/modules/settings-v2/routes";
import GuidelinesRoutes from "views/modules/guidelines/routes";
import GuidelinesRoutesV2 from "views/modules/guidelines-v2/routes";
import Installation from "views/modules/installation/routes";
import Alert from "views/modules/alerts-v2/routes";
import Appstore from "views/modules/appstore/routes";
import CustomBuilderRoutes from "views/modules/builder/routes";
import { RoutesPatterns } from "core/utils/route_schema";
import IntegrationRoutes from "views/modules/integrations/routes";
import ProfileRoutes from "views/modules/profile/routes";
import SyntheticsRoutes from "views/modules/synthetics/routes";
import AdvisorRoutes from "views/modules/advisor/routes";
import OnboardingDashboardRoutes from "views/modules/onboarding-dashboard/routes";
import MetricsRoutes from "views/modules/metrics/routes";
import RumRoutes from "views/modules/rum/routes";
import InfrastructureMainRoutes from "views/modules/infrastructure/routes";
import APMRoutes from "views/modules/apm-v2/routes";
import Installationv2Routes from "views/modules/installation-v2/routes";

const AppRoutes: RoutesPatterns[] = [
  Dashboard,
  Logs,
  Appstore,
  SettingsRoutes,
  GuidelinesRoutes,
  GuidelinesRoutesV2,
  InfrastructureMainRoutes,
  Installation,
  Alert,
  APMRoutes,
  CustomBuilderRoutes,
  IntegrationRoutes,
  ProfileRoutes,
  SyntheticsRoutes,
  AdvisorRoutes,
  RumRoutes,
  OnboardingDashboardRoutes,
  MetricsRoutes,
  Installationv2Routes,
];
export default AppRoutes;
