import * as React from 'react';
import {useEffect, useState} from "react";
import TabItemsWrapper from "core/components/nav-tab/items-wrapper";
import NavTabWrapper from "core/components/nav-tab/nav-tab-wrapper";
import TabItemsContentWrapper from "core/components/nav-tab/items-content-wrapper";


interface Props {
    defaultActiveTabIndex?: number
    targetIndex?: number
    borderTop?: boolean
    borderBottom?: boolean
    children?: any
    tabChange?: (index: number) => void
    classname?: string,
    activateIndex?:number
}
const NavTab = React.memo(function (props: Props) {
    const borderTop = typeof props.borderTop !== "undefined" ? props.borderTop : true
    const borderBottom = typeof props.borderBottom !== "undefined" ? props.borderBottom : true;
    const defCls = props?.children && typeof props.defaultActiveTabIndex !== "undefined" && props.defaultActiveTabIndex >= 0 && typeof props?.children[props?.defaultActiveTabIndex] !== "undefined" && props?.children[props.defaultActiveTabIndex]?.props?.className || ""
    const [state, setState] = useState({
        activeTabIndex: props.defaultActiveTabIndex,
        activeTabClassName: defCls || "",
    })

    useEffect(() => {
        // Checking type because defaultActiveTabIndex can be 0
        if (typeof props.defaultActiveTabIndex !== "undefined")
            handleTabClick(props.defaultActiveTabIndex)
    }, [props.defaultActiveTabIndex])


    useEffect(() => {
        if(!props.targetIndex) {
            updateState('activeTabIndex', 0)
        } else {
            updateState('activeTabIndex', props.targetIndex)
        }
    }, [props.targetIndex])

    const updateState = (key: string, value: any) => {
        setState(st => {
            return {...st, [key]: value}
        })
    }
    const handleTabClick = (tabIndex: number) => {
        props.tabChange && props.tabChange(tabIndex)
        updateState('activeTabIndex', tabIndex === state.activeTabIndex ? props.defaultActiveTabIndex : tabIndex)
    }

    const renderChildrenWithTabsApiAsProps = () => {
        return React?.Children?.map(props?.children, (child: any, index: number) => {
            if (child != null && child.type && child.type.displayName === "TabItems") {
                return React.cloneElement(child, {
                    onClick: (tabIndex: any) => {
                        handleTabClick(tabIndex)
                        setState(st => {
                            return {...st,
                                activeTabIndex: tabIndex,
                                activeTabClassName: child?.props?.className,
                            }
                        })
                    },
                    tabIndex: index,
                    isActive: index === state.activeTabIndex
                } as any);
            }
        });
    }

    const renderActiveTabContent = () => {
        const {children} = props;
        const {activeTabIndex} = state;
        if (activeTabIndex != undefined && children?.[activeTabIndex]) {
            return children[activeTabIndex]?.props?.children;
        } else {
            return children?.props?.children
        }
    }

    

    return (<NavTabWrapper classname={props?.classname}>
        <TabItemsWrapper borderTop={borderTop} borderBottom={borderBottom}>
            {renderChildrenWithTabsApiAsProps()}
        </TabItemsWrapper>

        <TabItemsContentWrapper className={`tab_content__${state.activeTabClassName}`}>
            {renderActiveTabContent()}
        </TabItemsContentWrapper>
    </NavTabWrapper>);
})
NavTab.displayName = "NavTab"

export default NavTab