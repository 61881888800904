import { RoutesPatterns } from "core/utils/route_schema";
import * as React from "react";

const RumRoutes: RoutesPatterns = {
  moduleName: "rum",
  slug: "/real-user-monitoring",
  breadcrumbs: React.lazy(() => import("views/modules/rum/breadcrumbs")),
  items: [
    {
      pattern: ["/"],
      component: React.lazy(() => import("views/modules/rum/v2/index")),
      breadcrumbTitle: "Applications",
      sidebar: React.lazy(() => import("views/modules/rum/sidebar/index")),
    },
    {
      pattern: ["/:application"],
      component: React.lazy(
        () => import("views/modules/rum/v2/application-dashboard/index")
      ),
      breadcrumbs: React.lazy(() => import("views/modules/rum/v2/header")),
      breadcrumbTitle: "Overview",
      sidebar: React.lazy(() => import("views/modules/rum/sidebar/index")),
    },
    {
      pattern: ["/:application/:selectedTab"],
      component: React.lazy(
        () => import("views/modules/rum/v2/application-dashboard/index")
      ),
      breadcrumbs: React.lazy(() => import("views/modules/rum/v2/header")),
      breadcrumbTitle: "Overview",
      sidebar: React.lazy(() => import("views/modules/rum/sidebar/index")),
    },
  ],
};
export default RumRoutes;
