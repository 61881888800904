import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { countryList } from "store/countries/api";
import { getCountriesList } from "store/countries/selectors";
import { PopoverItem } from "../../popover/item";
import Search from "../../search";
import { Popover } from "../../popover";
import { DownArrowSvg } from "core/components/svg/icons";
import Button from "../../button";
import { debounceHandler } from "core/utils";

interface CountryData {
  name: string;
  name_official?: string;
  cca2?: string;
  ccn3?: string;
  cca3?: string;
  region_id?: number;
  calling_code1?: number;
  id: string;
  code: number;
  label: string;
}
interface Props {
  defaultSelected?: string;
  onSelect?: (name: string, code?: number, label?: string, id?: string) => void;
}
const FormCountrySelect = (props: Props) => {
  const { onSelect, defaultSelected } = props;
  const [selected, setSelected] = useState<string>("");
  const [isCountryListVisible, setIsCountryListVisible] = useState(false);
  const [countrySearch, setCountrySearch] = useState("");
  const __countryList = useSelector(getCountriesList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(countryList());
  }, []);

  useEffect(() => {
    if (defaultSelected) setSelected(defaultSelected);
  }, [defaultSelected]);
  const list: CountryData[] = [];

  if (__countryList) {
    for (const key in __countryList) {
      if (__countryList.hasOwnProperty(key)) {
        const data: CountryData = __countryList[key];
        const countryData: CountryData = {
          name: data.name,
          code: data.calling_code1 || 0,
          label: "+" + data.calling_code1,

          id: data.cca3 || "",
        };
        if (countryData.id != undefined) {
          list.push(countryData);
        }
      }
    }
  }

  const handleSearch = debounceHandler(
    0,
    (value: string) => {
      setCountrySearch(value);
    },
    500
  );

  const popoverContent = () => {
    return (
      <>
        <Search
          onChange={handleSearch}
          defaultValue={countrySearch}
          placeholder="Search Country"
        />
        <div className="country-list">
          {list
            .filter((item) =>
              item?.name?.toLowerCase().includes(countrySearch.toLowerCase())
            )
            .map((item, index) => {
              return (
                <PopoverItem
                  key={index}
                  selected={item.id === selected}
                  onClick={() => {
                    setSelected(item.id);
                    setIsCountryListVisible(false);
                    setCountrySearch("");
                    if (onSelect) {
                      onSelect(item.name, item.code, item.label, item.id);
                    }
                  }}
                >
                  {item.name}
                </PopoverItem>
              );
            })}
        </div>
      </>
    );
  };

  return (
    <Popover
      rootClassName="mw-country-selection-dropdown"
      isVisible={isCountryListVisible}
      onClose={() => {
        setIsCountryListVisible(false);
        setCountrySearch("");
      }}
      content={popoverContent()}
      shouldCloseOnInnerClick={false}
    >
      <div>
        <Button
          onClick={() => setIsCountryListVisible(!isCountryListVisible)}
          suffixicon={<DownArrowSvg />}
        >
          {selected.length > 0 ? selected : "Select"}
        </Button>
      </div>
    </Popover>
  );
};
export default FormCountrySelect;
