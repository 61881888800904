import CreateRequest from "core/xhr";
import {
  receivedSpanInfo,
} from "store/infrastructure/trace/actions";
import {
  errorTraceListReceived,
  panelErrorGroupListReceived,
  receivedProfilingApps,
  receivedProfilingData,
  receivedProfilingLabels,
  receivedTraceErrors,
  receivedTraceLogs,
} from "./actions";

//TODO: Need to be removed after moving onbording dashboard section to query-runtime
export const requestErrorTraceList = (
  params?: any,
  callback?: (status?: boolean, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/apm/error-trace/list",
      method: "get",
      params: { ...params },
    })
      .then((res) => {
        dispatch(errorTraceListReceived(res.data));
        if (callback) {
          callback(true, res.data);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
      });
  };
};

//TODO: Need to remove once archish moves the usage of this API to query-runtime
export const requestErrorGroupTraceList = (
  body: any,
  callback?: (status: boolean, res?: any) => void
) => {
  return (dispatch: (a: any) => void) => {
    dispatch(panelErrorGroupListReceived({ infoInflight: true }));

    return CreateRequest({
      url: `/apm/trace/error-group-list/${body.group_id}/?page=${body.page}&limit=${body.limit}&start_ts=${body.start_ts}&end_ts=${body.end_ts}`,
      method: "post",
      data: body,
    })
      .then((res) => {
        dispatch(panelErrorGroupListReceived(res.data));
        if (callback) {
          callback(true, res.data);
        }
      })
      .catch((err) => {
        console.log("trace:inner list: error", err);
        dispatch(panelErrorGroupListReceived({ infoInflight: false }));
      });
  };
};

//TODO: Need to remove once archish moves the usage of this API to query-runtime
export const requestSpanInfo = (
  id: string,
  params: any,
  callback?: (status: boolean, res?: any) => void
) => {
  return (dispatch: (a: any) => void) => {
    dispatch(receivedSpanInfo({ infoInflight: true }));

    return CreateRequest({
      url: `/apm/trace/span/${id}`,
      method: "get",
      params: { ...params },
    })
      .then((res) => {
        dispatch(receivedSpanInfo(res.data));
        if (callback) {
          callback(true, res.data);
        }
      })
      .catch((err) => {
        dispatch(receivedSpanInfo({ infoInflight: false }));
        console.log("trace:info: error", err);
      });
  };
};

//TODO: Need to remove once archish moves the usage of this API to query-runtime
export const requestErrorGroupScale = (
  data: any,
  callback?: (status: boolean, res?: any) => void
) => {
  return (dispatch: (a: any) => void) => {
    dispatch(receivedSpanInfo({ infoInflight: true }));

    return CreateRequest({
      url: `/apm/trace/group-scale/${data.group_id}`,
      method: "get",
      params: {
        start_ts: data.from_ts,
        end_ts: data.to_ts,
        interval: data.interval,
      },
    })
      .then((res) => {
        dispatch(receivedSpanInfo(res.data));
        if (callback) {
          callback(true, res.data);
        }
      })
      .catch((err) => {
        dispatch(receivedSpanInfo({ infoInflight: false }));
        console.log("trace:info: error", err);
      });
  };
};

// TODO: Will remove this once logs moves to query-runtime
export const requestTraceLogs = (
  data: any,
  callback?: (status: boolean, res?: any) => void
) => {
  return (dispatch: (a: any) => void) => {
    dispatch(receivedTraceLogs({ infoInflight: true }));

    return CreateRequest({
      url: `/apm/trace/logs/${data.trace_id}/${data.span_id}`,
      method: "get",
      params: {
        start_ts: data.start_date,
        end_ts: data.end_date,
        page: data.page,
        limit: data.limit,
      },
    })
      .then((res) => {
        dispatch(receivedTraceLogs(res.data));
        if (callback) {
          callback(true, res.data);
        }
      })
      .catch((err) => {
        dispatch(receivedTraceLogs({ infoInflight: false }));
        console.log("trace:logs: error", err);
      });
  };
};

// TODO: Will remove this once logs moves to query-runtime
export const requestErrorTraces = (
  data: any,
  callback?: (status: boolean, res?: any) => void
) => {
  return (dispatch: (a: any) => void) => {
    dispatch(receivedTraceErrors({ infoInflight: true }));

    return CreateRequest({
      url: `/apm/trace/error/${data.trace_id}/${data.span_id}`,
      method: "get",
      params: {
        start_ts: data.start_date,
        end_ts: data.end_date,
        page: data.page,
        limit: data.limit,
      },
    })
      .then((res) => {
        dispatch(receivedTraceErrors(res.data));
        if (callback) {
          callback(true, res.data);
        }
      })
      .catch((err) => {
        dispatch(receivedTraceErrors({ infoInflight: false }));
        console.log("trace:logs: error", err);
      });
  };
};

export const requestProfilingApps = (
  callback?: (status: boolean, res?: any) => void
) => {
  return (dispatch: (a: any) => void) => {
    dispatch(receivedProfilingApps({ infoInflight: true }));

    return CreateRequest({
      url: `/apm/profiling/apps`,
      method: "get",
    })
      .then((res) => {
        dispatch(receivedProfilingApps(res.data));
        if (callback) {
          callback(true, res.data);
        }
      })
      .catch((err) => {
        dispatch(receivedProfilingApps({ infoInflight: false }));
        console.log("profiling app error :>", err);
      });
  };
};

export const requestProfilingLabels = (
  params: any,
  callback?: (status: boolean, res?: any) => void
) => {
  return (dispatch: (a: any) => void) => {
    dispatch(receivedProfilingLabels({ infoInflight: true }));

    return CreateRequest({
      url: `/apm/profiling/labels`,
      method: "post",
      data: params,
    })
      .then((res) => {
        dispatch(receivedProfilingLabels(res.data));
        if (callback) {
          callback(true, res.data);
        }
      })
      .catch((err) => {
        dispatch(receivedProfilingLabels({ infoInflight: false }));
        console.log("profiling labels error :>", err);
      });
  };
};

export const requestProfilingData = (
  params: any,
  callback?: (status: boolean, res?: any) => void
) => {
  return (dispatch: (a: any) => void) => {
    dispatch(receivedProfilingData({ infoInflight: true }));

    return CreateRequest({
      url: `/apm/profiling/render`,
      method: "get",
      params: { ...params },
    })
      .then((res) => {
        dispatch(receivedProfilingData(res.data));
        if (callback) {
          callback(true, res.data);
        }
      })
      .catch((err) => {
        dispatch(receivedProfilingData({ infoInflight: false }));
        console.log("profiling labels error :>", err);
      });
  };
};

export const requestSyntheticDetails = (
  body: any,
  callback?: (status: boolean, res?: any) => void
) => {
  return (dispatch: (a: any) => void) => {
    return CreateRequest({
      url: `/synthetics/check`,
      method: "post",
      data: { endpoints: body },
    })
      .then((res) => {
        if (callback) {
          callback(true, res.data);
        }
      })
      .catch((err) => {
        console.log("trace:inner list: error", err);
      });
  };
}
