import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Button from "../button";
import { Size } from "../enums";
import {
  SectionMinusIcon,
  SectionPlusIcon,
  SectionViewMoreArow,
} from "../svg/icons";

interface SectionExpandProps {
  title: string | React.ReactNode;
  showBorder?: boolean;
  children: React.ReactNode;
  viewMoreText?: string;
  defaultExpanded?: boolean;
  handleSectionExpand?: () => void;
  onClickViewMore?: () => void;
  shouldtitleStyleBold?: boolean;
  shouldtitleColorPrimary?: boolean;
  expandItem?: boolean;
  iconPlacementRight?: boolean;
  headerItems?: React.ReactNode;
  fadedTitle?: boolean;
  forceStopPropagation?: boolean;
  borderlessBtn?: boolean;
}

const SectionExpand = (props: SectionExpandProps) => {
  const {
    defaultExpanded,
    viewMoreText = null,
    onClickViewMore,
    showBorder = true,
    shouldtitleStyleBold = true,
    shouldtitleColorPrimary = false,
    expandItem,
    handleSectionExpand,
    iconPlacementRight = false,
    headerItems,
    fadedTitle = false,
    forceStopPropagation = false,
    borderlessBtn = false,
  } = props;
  const [isExpanded, setIsExpanded] = useState(defaultExpanded ?? false);

  const handleToggleExpand = () => {
    if(handleSectionExpand) handleSectionExpand();
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (expandItem !== undefined) {
      setIsExpanded(expandItem);
    }
  }, [expandItem]);

  const expandViewClass = classNames("mw-expandable-view", {
    "is-icon-placement-right": iconPlacementRight,
    "faded-title": fadedTitle,
    "borderless-btn": borderlessBtn,
  });
  return (
    <div className={expandViewClass}>
      <div className="expandable-header">
        <Button
          size={Size.Small}
          className="section-expand-button"
          prefixicon={
            isExpanded ? (
              <div className="circle">
                <SectionMinusIcon />
              </div>
            ) : (
              <div className="circle">
                <SectionPlusIcon />
              </div>
            )
          }
          shouldStopPropagation={forceStopPropagation}
          onClick={handleToggleExpand}
        />
        <span
          className={`expandable-title ${!showBorder ? "hide-border" : ""} ${shouldtitleStyleBold ? "" : "show-normal-title"} ${shouldtitleColorPrimary ? "show-primary-title" : ""}`}
        >
          {props.title}
        </span>
        {headerItems && <div className="header-items">{headerItems}</div>}
      </div>

      {isExpanded && (
        <div className={"expandable-body" + (isExpanded ? " visible" : "")}>
          <div className={"app-expand-section"}>{props.children}</div>
          {viewMoreText && isExpanded && (
            <div
              className={"expandable-view-more"}
              onClick={() => {
                if (onClickViewMore && typeof onClickViewMore === "function") {
                  onClickViewMore();
                }
              }}
            >
              <span title={viewMoreText}>{viewMoreText}</span>
              <SectionViewMoreArow />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SectionExpand;
