/* eslint-disable react/no-typos */
import * as React from 'react';
import * as ReactDom from 'react-dom';
import classNames from 'classnames';
var clickOutside = require('click-outside');
import { uniqueId } from 'lodash-es';

import {
	bindWindowListeners,
	unbindWindowListeners,
	suggested as suggestPosition,
	constrainLeft,
	offset,
} from './util';
import RootDiv from "./root-div";

const noop = () => {};
const __popovers = new Set();

interface Props  {
	autoPosition?: boolean,
	autoRtl?: boolean,
	className?:string,
	closeOnEsc?: boolean,
	id?:string,
	ignoreContext?: {
		getDOMNode: any
	} | any,
	isRtl?: boolean,
	isVisible?:boolean,
	positionAdjustment?: {
		left: number,
		top:  number,
	},
	position?: string,
	rootClassName?: string,
	showDelay?: number,
	showArrow?: string,
	onClose?: (e?: any,e1?: any) => void,
	onShow?:(e: any,e1?: any) => void,
	relativePosition?:{
		left: number ;
	},
	customPosition?:any,
	secondaryTopOffset?:number
	secondaryContext?:any
	children?:any
	context?: any
	popoverMenuClassName ?: string,
}
interface States {
	show?: boolean,
	left?: number,
	top?: number,
	positionClass?: string,

}
class Popover extends React.Component<Props,States> {
	static defaultProps = {
		autoPosition: true,
		autoRtl: true,
		className: '',
		closeOnEsc: true,
		isRtl: false,
		isVisible: false,
		position: 'top',
        positionAdjustment: {
		    left:0,
		    top:0,
        },
		showDelay: 0,
        showArrow: false,
		onClose: noop,
		onShow: noop,
	};


	isUpdatingPosition = false;
	domContext?: any;
	domContainer?: any;
	id?:string | number;
	_clickoutHandlerReference?: any;
	willReposition?: any;
	_openDelayTimer?: any;

	constructor( props: Props ) {
		super( props );
		if(props.id != undefined){
			this.setPopoverId( props.id );
		}


		// bound methods
		this.setDOMBehavior = this.setDOMBehavior.bind( this );
		this.setPosition = this.setPosition.bind( this );
		this.onClickout = this.onClickout.bind( this );
		this.onKeydown = this.onKeydown.bind( this );
		this.onWindowChange = this.onWindowChange.bind( this );

		this.state = {
			show: props.isVisible,
			left: -99999,
			top: -99999,
			positionClass: this.getPositionClass( props.position ),
		};
	}

	componentDidMount() {
		if ( this.state.show ) {
			this.bindEscKeyListener();
			this.bindDebouncedReposition();
			bindWindowListeners();
		}
	}
	componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any){
		try {
			this.domContext = ReactDom.findDOMNode( nextProps.context );
		} catch (e) {
			console.error(e, "popover/index UNSAFE_componentWillReceiveProps");
		}


		if ( ! nextProps.isVisible ) {
			return null;
		}

		this.setPosition();
	}

	componentDidUpdate( prevProps: any, prevState:any ) {
		const { isVisible } = this.props;

		if ( ! prevState.show && this.state.show ) {
			this.bindEscKeyListener();
			this.bindDebouncedReposition();
			bindWindowListeners();
		}

		if ( isVisible !== prevProps.isVisible ) {
			if ( isVisible ) {
				this.show();
			} else {
				this.hide();
			}
		}

		if ( ! this.domContainer || ! this.domContext ) {
			return null;
		}

		if ( ! isVisible ) {
			return null;
		}

		if ( ! this.isUpdatingPosition ) {
			requestAnimationFrame( () => {
				// Prevent updating Popover position if it's already unmounted.
				if (
					! __popovers.has( this.id ) ||
					! this.domContainer ||
					! this.domContext ||
					! isVisible
				) {
					this.isUpdatingPosition = false;
					return;
				}

				this.setPosition();
				this.isUpdatingPosition = false;
			} );
			this.isUpdatingPosition = true;
		}
	}

	componentWillUnmount() {
		//console.log( 'unmounting .... ' );

		this.unbindClickoutHandler();
		this.unbindDebouncedReposition();
		this.unbindEscKeyListener();
		unbindWindowListeners();

		__popovers.delete( this.id );
		//console.log 'current popover instances: ', __popovers.size );
	}

	// --- ESC key ---
	bindEscKeyListener() {
		if ( ! this.props.closeOnEsc ) {
			return null;
		}

		//console.log( 'adding escKey listener ...' );
		document.addEventListener( 'keydown', this.onKeydown, true );
	}

	unbindEscKeyListener() {
		if ( ! this.props.closeOnEsc ) {
			return null;
		}

		//console.log( 'unbinding `escKey` listener ...' );
		document.removeEventListener( 'keydown', this.onKeydown, true );
	}

	onKeydown( event: any ) {
		if ( event.keyCode !== 27 ) {
			return null;
		}

		this.close( true );
	}

	// --- click outside ---
	bindClickoutHandler( el = this.domContainer ) {
		if ( ! el ) {
			//console.log( 'no element to bind clickout ' );
			return null;
		}

		if ( this._clickoutHandlerReference ) {
			//console.log( 'clickout event already bound' );
			return null;
		}

		//console.log( 'binding `clickout` event' );
		// Changes to only bing the click if the dropdown is Visible
		const { isVisible }  = this.props;
		if(isVisible){
			this._clickoutHandlerReference = clickOutside( el, this.onClickout );
		}
	}

	unbindClickoutHandler() {
		if ( this._clickoutHandlerReference ) {
			//console.log( 'unbinding `clickout` listener ...' );
			this._clickoutHandlerReference();
			this._clickoutHandlerReference = null;
		}
	}

	onClickout( event: any ) {
		let shouldClose =
			this.domContext && this.domContext.contains && ! this.domContext.contains( event.target );

		if ( this.props.ignoreContext != undefined && this.props.ignoreContext != null  && shouldClose ) {
			const ignoreContext = ReactDom.findDOMNode( this.props.ignoreContext );
			shouldClose =
				shouldClose &&
				( ignoreContext && ignoreContext.contains && ! ignoreContext.contains( event.target ) );
		}

		if ( shouldClose ) {
			this.close();
		}
	}

	// --- window `scroll` and `resize` ---
	bindDebouncedReposition() {
		window.addEventListener( 'scroll', this.onWindowChange, true );
		window.addEventListener( 'resize', this.onWindowChange, true );
	}

	unbindDebouncedReposition() {
		if ( this.willReposition ) {
			window.cancelAnimationFrame( this.willReposition );
			this.willReposition = null;
		}

		window.removeEventListener( 'scroll', this.onWindowChange, true );
		window.removeEventListener( 'resize', this.onWindowChange, true );
		//console.log( 'unbinding `debounce reposition` ...' );
	}

	onWindowChange() {
		this.willReposition = window.requestAnimationFrame( this.setPosition );
	}

	setDOMBehavior( domContainer: any ) {
		if ( ! domContainer ) {
			this.unbindClickoutHandler();
			return null;
		}
		this.bindClickoutHandler( domContainer );
		this.domContainer = domContainer;
		this.domContext = ReactDom.findDOMNode( this.props.context );
		this.setPosition();
	}

	getPositionClass( position = this.props.position ) {
		if(position != undefined) return `is-${ position.replace( /\s+/g, '-' ) }`;

	}

	adjustRtlPosition( position: any ) {
		if ( this.props.isRtl ) {
			switch ( position ) {
				case 'top right':
				case 'right top':
					return 'top left';

				case 'right':
					return 'left';

				case 'bottom right':
				case 'right bottom':
					return 'bottom left';

				case 'bottom left':
				case 'left bottom':
					return 'bottom right';

				case 'left':
					return 'right';

				case 'top left':
				case 'left top':
					return 'top right';
				default:
			}
		}
		return position;
	}

	computePosition() {
		if ( ! this.props.isVisible ) {
			return null;
		}
		const { domContainer, domContext } = this;
		const { position, relativePosition } = this.props;
		if ( ! domContainer || ! domContext ) {
			return null;
		}

		let suggestedPosition = position;

		if ( this.props.autoRtl ) {
			suggestedPosition = this.adjustRtlPosition( suggestedPosition );
		}

		if ( this.props.autoPosition ) {
			suggestedPosition = suggestPosition( suggestedPosition, domContainer, domContext );
		}

		const reposition = Object.assign(
			{},
			constrainLeft(
				offset( suggestedPosition, domContainer, domContext, relativePosition ),
				domContainer
			),
			{ positionClass: this.getPositionClass( suggestedPosition ) }
		);
		return reposition;
	}

	setPopoverId( id: string ) {
		this.id = id || `pop__${ uniqueId() }`;
		__popovers.add( this.id );

	}

	setPosition() {
		let position;
		if ( this.props.customPosition ) {
			position = Object.assign(
				{
					positionClass: this.getPositionClass( 'top' ),
				},
				this.props.customPosition
			);
		} else {
			position = this.computePosition();
		}

		if ( ! position ) {
			return null;
		}

		this.willReposition = null;
		this.setState( position );
	}

	getStylePosition() {
		const { left, top } = this.state;
		const { position, positionAdjustment } = this.props;
        let isRight = ((position || "").indexOf("right") !== -1);
        let isTop = ((position || "").indexOf("top") !== -1);
		if(positionAdjustment != undefined && left != undefined && top != undefined ){
			let __left= positionAdjustment.left;
			let __top = positionAdjustment.top;
			__left = (isRight?left + __left: left - __left);
			__top = (isTop?top - __top: top + __top);
			return { left:__left, top:__top };

		}



	}

	show() {
		if ( ! this.props.showDelay ) {
			this.setState( { show: true } );
			return null;
		}
		this.clearShowTimer();
		this._openDelayTimer = setTimeout( () => {
			this.setState( { show: true } );
		}, this.props.showDelay );
	}

	hide() {
		this.unbindClickoutHandler();
		this.unbindDebouncedReposition();
		this.unbindEscKeyListener();
		unbindWindowListeners();
		this.setState( { show: false } );
		this.clearShowTimer();
	}

	clearShowTimer() {
		if ( ! this._openDelayTimer ) {
			return null;
		}

		clearTimeout( this._openDelayTimer );
		this._openDelayTimer = null;
	}

	close( wasCanceled = false ) {
		if ( ! this.props.isVisible ) {
			return null;
		}
		if(this.props.onClose != undefined){
			this.props.onClose( wasCanceled );
		}

	}

	render() {
		if ( ! this.state.show ) {
			return null;
		}
		if ( ! this.props.context ) {
			return null;
		}
		const classes = classNames( 'popover', this.props.className, this.state.positionClass );

		return (
			<RootDiv className={ this.props.rootClassName }>
				<div style={ this.getStylePosition() } className={ classes }>
					{this.props.showArrow && <div className="popover__arrow" />}
					<div ref={ this.setDOMBehavior } className={'popover__inner__wrapper'}>
						<div className={`popover__inner ${this.props.popoverMenuClassName ? this.props.popoverMenuClassName : ""}`} >{this.props.children}</div>

						{this.props.secondaryContext && <div className="popover__inner__secondary" style={{
							top: `${this.props.secondaryTopOffset ? this.props.secondaryTopOffset : 0}px`
						}}>{this.props.secondaryContext}</div>}</div>
				</div>
			</RootDiv>
		);
	}
}

export default Popover;
