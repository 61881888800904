import * as React from 'react';
import classNames from 'classnames';
import "./_style.scss";
import Search from "core/components/search";

export default class PopoverMenuSearchCard extends React.Component<any, any> {

    render(){
        const { onSearch, searchCardClassName, placeholder, isOpen, onKeyDown, initialValue, sticky, children, rootClassName, onSearchClose, autoFocus, maxLength, hideClearIcon} = this.props;
        return(
            <div className={classNames(`popover__menu-search`, rootClassName, {
                "is-sticky": sticky,
            })}>
                <Search additionalClasses={searchCardClassName} disabledSearchIcon={true}
                    {...{autoFocus,onKeyDown, initialValue, onSearchClose, isOpen, placeholder, onSearch, maxLength, hideClearIcon}} />
                {children}
            </div>
        )
    }
}
