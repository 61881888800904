import React, { useRef, useState } from "react";
import "./_style.scss";
import Tooltip from "core/components/tooltip";
import { useOnClickOutside } from "core/hooks/use-on-click-outside";

export interface IFilter {
  key: string;
  type: string;
  value: string;
}

interface Props {
  data: Record<string, string | number>;
  onFilterSelect: (filter: IFilter) => void;
}

const CollapsibleJSON = ({ data, onFilterSelect }: Props) => {
  const jsonComponent = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLSpanElement>(null);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [copyText, setCopyText] = useState("Copy");
  const [showCopyIcon, setShowCopyIcon] = useState(false);
  const [hoveredKey, setHoveredKey] = useState<string | null>(null);
  const [showPopover, setShowPopover] = useState(false);
  const [popOverKey, setPopOverKey] = useState<string | null>(null);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleCopyToClipboard = (value: string | number) => {
    navigator.clipboard
      .writeText(value.toString())
      .then(() => {
        setCopyText("Copied!");
        setTimeout(() => {
          setCopyText("Copy");
        }, 2000);
      })
      .catch((err) => {
        console.error("Error copying to clipboard:", err);
      });
  };

  const handlePopup = (key: string) => {
    setShowPopover(true);
    setPopOverKey(key);
  };

  const handleClickOutside = () => {
    setShowPopover(false);
    setPopOverKey(null);
  };

  useOnClickOutside(jsonComponent, handleClickOutside);

  const applyFilters = (type: string, key: string, value: string) => {
    setShowPopover(false);
    setPopOverKey(null);

    onFilterSelect({
      key: key,
      type: type,
      value: value,
    });
  };

  const renderValue = (value: Record<string, string | number>, key: string) => {
    if (typeof value === "object") {
      return <CollapsibleJSON data={value} onFilterSelect={onFilterSelect} />;
    } else {
      return (
        <>
          <span
            ref={popupRef}
            className="copy-to-clipboard"
            onClick={() => handlePopup(key)}
          >
            {value}
          </span>
          {showPopover && key === popOverKey && (
            <div
              className={"popup-container"}
              style={{ width: "400px", left: 125, top: 20 }}
            >
              <div className={"popup-filter"}>
                <div
                  className={"popup-item"}
                  onClick={() => applyFilters("filter", key, value)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <span className={"label"}>Filter By </span>
                  <span className={"value"}>
                    {key}:{value}
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
  };

  const handleOnMouseEnter = (key: string) => {
    setShowCopyIcon(true);
    setHoveredKey(key);
  };

  const handleOnMouseLeave = () => {
    setShowCopyIcon(false);
  };

  const renderJSON = (data: Record<string, string | number>) => {
    if (typeof data !== "object" || data === null) {
      return null;
    }

    return (
      <div
        className={`json-object json-node ${isCollapsed ? "collapsed" : ""}`}
      >
        <span className="json-key" onClick={handleToggleCollapse}>
          {isCollapsed ? "{...}" : "{"}
        </span>
        {isCollapsed ? null : (
          <div className="json-object-items" ref={jsonComponent}>
            {Object.keys(data).map((key) => (
              <div
                key={key}
                className={"json-row"}
                onMouseEnter={() => handleOnMouseEnter(key)}
                onMouseLeave={handleOnMouseLeave}
              >
                <div className="json-item">
                  <span className="json-key">{key}&nbsp;</span>
                  {renderValue(
                    data[key] as unknown as Record<string, string | number>,
                    key
                  )}
                </div>
                <span
                  style={{
                    opacity: showCopyIcon && hoveredKey === key ? 1 : 0,
                  }}
                  data-tip
                  data-for={"top"}
                  className="copy-icon"
                  onClick={() => handleCopyToClipboard(data[key])}
                >
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    height="19"
                    width="19"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                    ></path>
                  </svg>
                </span>
                <Tooltip id={"top"} place="top" type="dark" effect="solid">
                  <span>{copyText}</span>
                </Tooltip>
              </div>
            ))}
          </div>
        )}
        {!isCollapsed && <span className="json-collapsed">{"}"}</span>}
      </div>
    );
  };

  return renderJSON(data);
};

export default CollapsibleJSON;
