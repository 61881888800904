import { getTenantUrl } from "core/application/utils";
import { Message } from "core/components/v2/enums";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import InstallationCard from "../../components/card";
import Command from "../../components/code-block/command";
import MwMessage from "../../components/message";
import InstallationSteps from "../../components/steps";
import StepItem from "../../components/steps/step-item";
const WindowsIntegration = () => {
  const [tab, setTab] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Windows OS Version",
          value: "10 or above",
        },
        {
          label: "Windows Processor",
          value: "64-bit",
        },
      ],
    },
  ];
  const mwApiKey = `${context.user?.account?.agent_config?.token}`;
  const mwTarget = `${capturePath}`;
  const oldStatusCheckCommand = `sudo systemctl status mwservice`;
  const newStatusCheckCommand = `sudo systemctl status mw-agent`;
  const windowsStatusCheckCommand = `Get-Service -Name mw-windows-agent`;
  const sendEvent = useMixPanel();
  return (
    <>
      <InstallationSteps>
        <StepItem
          stepNumber={1}
          title={"Download Middleware Host Agent Executable File"}
        >
          <InstallationCard items={cardItems} />
        </StepItem>

        <StepItem stepNumber={2} title={"Follow Installation Prompts"}>
          You may need the following API Key and Target when completing the
          installation prompts with{" "}
          <a
            href="https://github.com/middleware-labs/mw-agent/releases/latest/download/mw-windows-agent-setup.exe"
            target="_blank"
            rel="noreferrer"
          >
            EXE Installer
          </a>{" "}
          <br />
          <br />
          <>
            <Command
              onCopyClick={() => {
                sendEvent("click on copy command", {
                  agent_type: "Windows",
                  command_type: "Follow Installation Prompts",
                });
              }}
              command={mwApiKey}
              className="margin-0"
            />
            <MwMessage type={Message.Info} message="MW_API_KEY"></MwMessage>

            <Command
              onCopyClick={() => {
                sendEvent("click on copy command", {
                  agent_type: "Windows",
                  command_type: "Follow Installation Prompts",
                });
              }}
              command={mwTarget}
              className="margin-0"
            />
            <MwMessage type={Message.Info} message="TARGET"></MwMessage>
          </>
          <MwMessage
            type={Message.Warning}
            message="Run only one MW Agent per host. Multiple agents will cause unexpected behavior."
          ></MwMessage>
        </StepItem>
        <StepItem stepNumber={3} title={"Check Status:  Middleware Host Agent"}>
          <>
            <Command
              onCopyClick={() => {
                sendEvent("click on copy command", {
                  agent_type: "Windows",
                  command_type: "Check Status:  Middleware Host Agent",
                });
              }}
              command={windowsStatusCheckCommand}
              className="margin-0"
            />
          </>
        </StepItem>
      </InstallationSteps>
    </>
  );
};
export default WindowsIntegration;
