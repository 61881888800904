import ChartsV2 from "core/components/v2/charts";
import {
  PointOptionsObject,
  SeriesColumnrangeOptions,
  SeriesOptionsType,
} from "highcharts";
import React, { useEffect, useState } from "react";
import {
  colorSchemas,
  getToolTipTitle,
} from "../../../../../views/modules/builder/common/utils";
import {
  BuilderNestedProps,
  TimeSeriesData,
  getIntValue,
} from "../../../../../views/modules/builder/entities/builder.entities";

import moment from "moment";
import { DateRange } from "views/layouts/app/routes/model";
import {
  RenderTpl,
  getValueFromConfig,
} from "../../../../../views/modules/builder/entities/tpl.entities";
import NoDataSpace from "../../no-data";
interface BaseHeatMapChartProps {
  timeseriesData: TimeSeriesData | undefined;
  nestedProps?: BuilderNestedProps;
  onDateChange?: (range: DateRange) => void;
}
interface chartDataObjectType {
  type: SeriesColumnrangeOptions["type"];
  [x: string]:
    | string
    | number
    | {
        x: number;
        low: number;
        high: number;
        color: string;
        custom: Record<string, (string | number)[]>;
      }[]
    | {
        timestamp?: number;
        interval: number;
        tooltipText: Record<string, string | number>;
      }
    | (
        | PointOptionsObject
        | [string | number, number]
        | [string | number, number, number]
      )[]
    | undefined;
  timestamp?: number;
  data?:
    | (
        | PointOptionsObject
        | [string | number, number]
        | [string | number, number, number]
      )[]
    | undefined;
  tooltip: {
    timestamp?: number;
    interval: number;
    tooltipText: Record<string, string | number>;
  };
}

const BaseHeatMapChartV2 = (props: BaseHeatMapChartProps) => {
  const { timeseriesData, nestedProps, onDateChange } = props;
  if (!timeseriesData) return null;

  if (timeseriesData.noDataFound) {
    return <NoDataSpace />;
  }
  const columnConfig = nestedProps?.columnConfig;
  const [seriesData, setSeriesData] = useState<{
    heatMapChartData: (chartDataObjectType & SeriesOptionsType)[];
    yAxisConfig?: RenderTpl;
    format?: string;
    ts: string[];
  }>({
    heatMapChartData: [],
    ts: [],
  });
  useEffect(() => {
    const heatMapChartData = generateData();
    setSeriesData(heatMapChartData);
  }, [timeseriesData]);
  const generateData = (): {
    heatMapChartData: (chartDataObjectType & SeriesOptionsType)[];
    yAxisConfig?: RenderTpl;
    format?: string;
    ts: string[];
  } => {
    const heatMapChartData: (chartDataObjectType & SeriesOptionsType)[] = [];
    const chartData: chartDataObjectType[] = [];
    const points: (SeriesColumnrangeOptions & {
      name: string;
      data?:
        | (
            | PointOptionsObject
            | [string | number, number]
            | [string | number, number, number]
          )[]
        | undefined;
      config?: RenderTpl;
    })[] = [];
    const ts = Object.keys(timeseriesData.timestamps).map((t) => parseFloat(t));
    const processedData = timeseriesData;
    if (processedData && Object.keys(processedData.chartGroups).length > 0) {
      Object.keys(processedData.chartGroups).forEach((groupName) => {
        const metricGroup = processedData.chartGroups[groupName];
        if (metricGroup && Object.keys(metricGroup)) {
          const metricsCount = Object.keys(metricGroup).length;
          Object.keys(metricGroup).forEach((metricName) => {
            // let name = groupName + "-" + metricName;
            const title = getToolTipTitle(
              metricName,
              groupName,
              metricsCount,
              Object.keys(metricGroup),
              columnConfig
            );
            const tpl = columnConfig?.[metricName]?.tpl
              ? columnConfig?.[metricName]?.tpl
              : metricGroup?.[metricName]?.[0]?.config;
            // if (metricsCount == 1) {
            //   name = groupName;
            // }
            points.push({
              id: title,
              name: title,
              config: tpl,
              data: [],
              type: "columnrange",
            });
          });
        }
      });
    }
    let max = 0;
    for (let i = 0; i < ts.length; i++) {
      const obj: chartDataObjectType = {
        timestamp: ts[i],
        data: [],
        tooltip: {
          timestamp: 0,
          interval: 0,
          tooltipText: {},
        },
        type: "columnrange",
      };
      obj.tooltip = {
        timestamp: ts[i],
        interval: 0,
        tooltipText: {},
      };
      if (processedData && Object.keys(processedData.chartGroups).length > 0) {
        Object.keys(processedData.chartGroups).forEach((groupName) => {
          const chartGroup = processedData.chartGroups[groupName];
          if (chartGroup && Object.keys(chartGroup)) {
            const metricsCount = Object.keys(chartGroup).length;
            Object.keys(chartGroup).forEach((metricName) => {
              if (!chartGroup[metricName][i]) return;
              const title = getToolTipTitle(
                metricName,
                groupName,
                metricsCount,
                Object.keys(chartGroup),
                columnConfig
              );
              obj[title] = chartGroup[metricName][i].value;
              const chartValue = getIntValue(chartGroup[metricName][i].value);
              if (chartValue > max) {
                max = chartValue;
              }
              if (!chartGroup[metricName][i].config) {
                chartGroup[metricName][i].config =
                  chartGroup[metricName][0].config;
              }
              obj.tooltip.tooltipText[title] = getValueFromConfig(
                chartValue,
                chartGroup[metricName][i].config
              );
            });
          }
        });
      }
      chartData.push(obj);
    }
    const seriesData: Record<
      string,
      {
        data: PointOptionsObject[];
        tooltip: {
          timestamp?: number;
          interval: number;
          tooltipText: Record<string, string | number>;
        };
      }
    > = {};
    chartData.forEach((item: chartDataObjectType, index: number) => {
      const maxValArr: number[] = [];
      points.forEach((point) => {
        if (index > 2) {
          const min = Math.min(
            Number(
              chartData[index][point.name] ? chartData[index][point.name] : "0"
            ),
            Number(
              chartData[index - 1][point.name]
                ? chartData[index - 1][point.name]
                : "0"
            ),
            Number(
              chartData[index - 2][point.name]
                ? chartData[index - 2][point.name]
                : "0"
            )
          );
          const max = Math.max(
            Number(
              chartData[index][point.name] ? chartData[index][point.name] : "0"
            ),
            Number(
              chartData[index - 1][point.name]
                ? chartData[index - 1][point.name]
                : "0"
            ),
            Number(
              chartData[index - 2][point.name]
                ? chartData[index - 2][point.name]
                : "0"
            )
          );
          if (min >= 0 && max >= 0) {
            maxValArr.push(max);
            const __max = Math.max(...maxValArr);
            let colorFill = "";
            if (point.config && point.config.type === "percent") {
              if (__max > 70) {
                colorFill = "#f03c3e";
              } else if (__max > 50) {
                colorFill = "rgba(233,94,95,0.5)";
              } else if (__max > 30) {
                colorFill = "#ffa404";
              } else if (__max > 20) {
                colorFill = "rgba(255,182,4,0.7)";
              } else if (__max > 10) {
                colorFill = "rgba(255,182,4,0.3)";
              } else if (__max > 5) {
                colorFill = "rgba(81,146,49,0.7)";
              } else {
                colorFill = "#519231";
              }
            } else {
              colorFill = colorSchemas(index);
            }
            const rangeArr: PointOptionsObject = {
              x: item.timestamp,
              low: min,
              high: max,
              name: point.name,
              color: colorFill,
              custom: {},
            };
            if (rangeArr.custom) {
              rangeArr.custom[point.name] = [
                point.config ? getValueFromConfig(min, point.config) : 0,
                point.config ? getValueFromConfig(max, point.config) : 0,
              ];
            }
            if (seriesData[point.name]?.data?.length) {
              seriesData[point.name].data.push(rangeArr);
            } else {
              seriesData[point.name] = {
                data: [rangeArr],
                tooltip: item.tooltip,
              };
            }
            if (item.data) {
              item.data.push(rangeArr);
            } else {
              item.data = [rangeArr];
            }
          }
        }
      });
      // heatMapChartData.push(item);
    });
    if (Object.keys(seriesData).length) {
      Object.keys(seriesData).map((key) => {
        heatMapChartData.push({
          type: "columnrange",
          name: key,
          data: seriesData[key].data.filter((it) => it.x && it.low && it.high),
          tooltip: seriesData[key]?.tooltip,
        });
      });
    }
    Object.keys(timeseriesData.timestamps).map((t) => parseFloat(t));
    const timestamps = Object.keys(timeseriesData.timestamps).map((i) => {
      const ii = parseInt(i);
      return ii;
    });
    let format = "HH:mm";
    const first = timestamps[0];
    const last = timestamps[timestamps.length - 1];
    const diffInMinutes = (Number(last) - Number(first)) / 60000;
    if (diffInMinutes < 60) {
      format = "HH:mm:ss";
    } else if (diffInMinutes < 1440) {
      format = "HH:mm";
    } else {
      format = "MMM DD HH:mm";
    }
    return {
      heatMapChartData,
      yAxisConfig: points[0]?.config,
      format,
      ts: ts.map((it) => it.toString()),
    };
  };
  return (
    <ChartsV2
      chartType={"columnrange"}
      series={seriesData.heatMapChartData}
      xAxisFormatter={function (ctx) {
        return moment(ctx.value).format(seriesData.format);
      }}
      yAxsisTitle={props?.nestedProps?.yAxisTitle}
      showLegend={props?.nestedProps?.showLegend}
      yAxisConfig={seriesData.yAxisConfig}
      extraChartConfig={{
        colorByPoint: true,
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: [
          {
            enabled: false,
          },
        ],
      }}
      extraConfig={{
        colorAxis: {
          visible: false,
        },
        legend: {
          enabled: false,
          align: "center",
        },
      }}
      chartHeight={props.nestedProps?.chartHeight}
      onDateChange={onDateChange}
    />
  );
};

export default BaseHeatMapChartV2;
