import * as React from "react";
import { RoutesPatterns } from "core/utils/route_schema";

const CustomBuilderRoutes: RoutesPatterns = {
  moduleName: "builder",
  slug: "/dashboards",
  items: [
    {
      pattern: ["/"],
      sidebar: React.lazy(
        () => import("views/modules/builder/dashboard-list/sidebar.index")
      ),
      breadcrumbs: React.lazy(
        () => import("views/modules/builder/dashboard-list/breadcrumbs")
      ),
      component: React.lazy(
        () => import("views/modules/builder/dashboard-list/index")
      ),
    },
    {
      pattern: ["/:dashboardKey"],
      component: React.lazy(
        () => import("views/modules/builder/builder.index")
      ),
    },
  ],
};
export default CustomBuilderRoutes;
