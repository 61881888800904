import {createSelector} from "reselect";
import {get} from "lodash-es";
import { TimezoneInfo } from "views/modules/synthetics/model";


export const getCountriesList = createSelector((state: any) => {
    return get(state,  ["countries", 'list'], [])
}, (data: any) => data);

export const getCountriesTimezone = createSelector((state: any) => {
    return get(state,  ["countries", 'timezone'], [])
}, (data: TimezoneInfo[]) => Object.values(data));
