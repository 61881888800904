import React, { useState } from "react";

import Button from "core/components/v2/button";
import { Popover } from "core/components/v2/popover";
import { CustomiseIcon } from "core/components/v2/svg/icons";
import FilterDropdown from "./FilterDropdown";
import "./_style.scss";
export interface CustomizedFilterOption {
  label: string;
  value: string;
  defaultSelected?: boolean;
  disabled?: boolean;
}
interface CustomiseFilterProps {
  handleApplyFilter: (appliedFilterValues: string[]) => void;
  filterOptions: CustomizedFilterOption[];
  hideLabel?: boolean;
  button?: React.ReactNode;
  labelText?: string;
  defaultLabelText?: string;
  onDefaultLabelClick?: () => void;
  hideSelectAll?: boolean;
  hideFooter?: boolean;
  itemOnChange?: (position: number) => void;
}

const CustomiseFilterButton = (props: CustomiseFilterProps) => {
  const {
    filterOptions,
    handleApplyFilter,
    hideLabel,
    button,
    defaultLabelText,
    hideFooter,
    hideSelectAll,
    itemOnChange,
    labelText,
    onDefaultLabelClick,
  } = props;
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Popover
        content={
          <FilterDropdown
            filterOptions={filterOptions}
            handleApplyFilter={handleApplyFilter}
            onClose={onClose}
            labelText={labelText}
            defaultLabelText={defaultLabelText}
            onDefaultLabelClick={onDefaultLabelClick}
            hideSelectAll={hideSelectAll}
            hideFooter={hideFooter}
            itemOnChange={itemOnChange}
          />
        }
        isVisible={open}
        onClose={() => {
          setOpen(false);
        }}
        shouldCloseOnInnerClick={false}
      >
        <div onClick={() => setOpen(!open)}>
          {button ? (
            button
          ) : (
            <Button outlined prefixicon={<CustomiseIcon />}>
              {hideLabel ? "" : "Customize"}
            </Button>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default CustomiseFilterButton;
