import Dropdown from "core/components/dropdown";
import FormTextInput from "core/components/form/form-text-input";
import React from "react";
import {
  ATTRIBUTE_FILTER,
  DefaultNoneValue,
  FilterOp,
  FilterOpEnum,
  FilterOption,
  MetricTypeEnum,
  SelectFilter,
  getAvailableOperators,
  sqlOperators,
} from "views/modules/builder/entities/builder.entities";
import { CustomObject } from "views/modules/builder/entities/extra.entities";
import {
  AddNewButton,
  DeleteButton,
} from "../../core/components/buttons/builder-buttons";
import { BuilderSelectionProps } from "../../views/components/entities";
import useBuilder from "../../views/core/useBuilder";
import "./_style.scss";
import { getAttributeValues } from "store/widgets/api";
import { useDispatch } from "react-redux";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import { debounceHandler } from "core/utils";

const Filter = (props: BuilderSelectionProps) => {
  const { chartType, query: builderConfig, onConfigUpdate } = props;

  const dispatch = useDispatch();

  const routeData = React.useContext(MwRouteContext);
  const { fromTs, toTs } = routeData.params.dateRange;

  const builder = useBuilder();
  const { selectors } = builder;
  const { getAvailableFilters } = selectors;

  const filtersIndex = (builderConfig?.with || []).findIndex(
    (item) => item.key === ATTRIBUTE_FILTER
  );
  const selectedFilters = (builderConfig?.with || [])[filtersIndex]?.value as {
    [key: string]: CustomObject;
  };
  const options = getAvailableFilters(builderConfig.source.name, chartType);
  const filters = builderConfig.with?.find((w) => w.key === ATTRIBUTE_FILTER);
  // const isAllFiltersValid = () => {
  //     if (filters && Object.keys(filters.value).length) {
  //         const result = Object.keys(filters.value).some(val => {
  //             return !isSingleFilterValid(val);
  //         })
  //         return !result;
  //     }
  //     return true;
  // }
  const isSingleFilterValid = (filterKey: string): boolean => {
    if (!filters) return false;
    if (filterKey === SelectFilter) {
      return false;
    } else {
      const vs = filters.value as {
        [key: string]:
          | string[]
          | {
              [key: string]: string[];
            };
      };
      const value = vs[filterKey];
      if (Array.isArray(value) && value.length === 0) {
        return false;
      } else if (typeof value === "object") {
        const ivalue = value as {
          [key: string]: string[];
        };
        const op = Object.keys(ivalue)[0];
        const v = ivalue[op];
        if (Array.isArray(v) && v.length === 0) {
          return false;
        } else if (typeof v === "string" && v === "") {
          return false;
        } else if (!v) {
          return false;
        } else {
          return true;
        }
      }
    }
    return false;
  };
  options.unshift({
    label: DefaultNoneValue,
    value: DefaultNoneValue,
    options: [],
    type: MetricTypeEnum.MetricTypeNone,
  });
  return (
    <React.Fragment>
      {selectedFilters &&
        selectedFilters &&
        Object.keys(selectedFilters).map((filterKey) => {
          let selectedOperator: FilterOp | undefined;
          let filterValues = selectedFilters[filterKey];
          let selectedValues;
          if (Array.isArray(filterValues)) {
            // for old system when there was no operator selection
            selectedValues = filterValues;
          }
          if (!Array.isArray(filterValues)) {
            Object.keys(filterValues).forEach((key: string) => {
              selectedOperator = key as FilterOp;
              selectedValues = filterValues[key];
            });
          }
          const isFilterFound = options.find((o) => o.value === filterKey);
          let originalFilterOption: FilterOption | undefined;
          if (isFilterFound) {
            originalFilterOption = isFilterFound;
          }
          const availableSqlOperators = originalFilterOption
            ? getAvailableOperators(originalFilterOption.type)
            : sqlOperators;
          if (!selectedOperator && originalFilterOption) {
            if (
              originalFilterOption.type ==
                MetricTypeEnum.ResourceAttributeKey ||
              originalFilterOption.type == MetricTypeEnum.MetricAttributeKey
            ) {
              selectedOperator = FilterOpEnum.In;
            } else {
              selectedOperator = FilterOpEnum.Equal;
            }
          }
          let operatorFromList = availableSqlOperators.find(
            (o) => o.value === selectedOperator
          );
          if (!operatorFromList) {
            operatorFromList = availableSqlOperators[0];
          }
          const optionsAvailable = operatorFromList?.defaultOptions
            ? true
            : options.find((o) => o.value === filterKey)?.options
              ? true
              : false;

          const handleOptionSearch = debounceHandler(
            -1,
            (search: string) => {
              dispatch(
                getAttributeValues(
                  builderConfig.source.name,
                  filterKey,
                  originalFilterOption?.type ?? MetricTypeEnum.MetricTypeNone,
                  chartType,
                  fromTs,
                  toTs,
                  search
                )
              );
            },
            1000
          );

          return (
            <div
              className={"single-filter metric__item metric__item-filters"}
              key={filterKey}
            >
              <div className="selection">
                <Dropdown
                  isError={!isSingleFilterValid(filterKey)}
                  style={{ display: "table" }}
                  search
                  options={options}
                  labelField={"label"}
                  valueField={"value"}
                  // label={"Filter By"}
                  value={filterKey || ""}
                  onChange={(e: any) => {
                    if (filterKey === e.value) {
                      return;
                    }
                    delete selectedFilters[filterKey];
                    selectedFilters[e.value] = {};
                    const copy = { ...builderConfig };
                    if (copy.with) {
                      copy.with[filtersIndex].value = selectedFilters;
                    } else {
                      copy.with = [
                        {
                          key: ATTRIBUTE_FILTER,
                          value: selectedFilters,
                          is_arg: true,
                        },
                      ];
                    }
                    onConfigUpdate(copy);
                  }}
                />
                <Dropdown
                  isError={!isSingleFilterValid(filterKey)}
                  style={{ display: "table" }}
                  options={availableSqlOperators}
                  labelField={"title"}
                  valueField={"value"}
                  // label={"Operator"}
                  value={selectedOperator || "IN"}
                  onChange={(e: any) => {
                    if (selectedOperator === e.value) {
                      return;
                    }
                    const newOperator = e.value as string;
                    let existingValues;
                    if (Array.isArray(filterValues)) {
                      // for old system when there was no operator selection
                      existingValues = filterValues as string[];
                      const operatorFromList = sqlOperators.find(
                        (o) => o.value === newOperator
                      );
                      if (!operatorFromList) return;
                      if (!operatorFromList?.allowMultiple) {
                        existingValues = existingValues[0];
                      }
                      filterValues = {
                        [newOperator]: existingValues,
                      };
                    } else if (selectedOperator) {
                      existingValues = filterValues[selectedOperator];
                      delete filterValues[selectedOperator];
                      if (Array.isArray(existingValues)) {
                        existingValues = existingValues as string[];
                        const operatorFromList = sqlOperators.find(
                          (o) => o.value === newOperator
                        );
                        if (!operatorFromList) return;
                        if (!operatorFromList?.allowMultiple) {
                          existingValues = existingValues[0];
                        }
                      }
                      filterValues[newOperator] = existingValues;
                    }
                    selectedFilters[filterKey] = filterValues;
                    const copy = { ...builderConfig };
                    if (copy.with) {
                      copy.with[filtersIndex].value = selectedFilters;
                    } else {
                      copy.with = [
                        {
                          key: ATTRIBUTE_FILTER,
                          value: selectedFilters,
                          is_arg: true,
                        },
                      ];
                    }
                    onConfigUpdate(copy);
                  }}
                />
                {selectedOperator === FilterOpEnum.Like ||
                selectedOperator === FilterOpEnum.NotLike ||
                selectedOperator === "ILIKE" ||
                selectedOperator === "NOT ILIKE" ? (
                  <FormTextInput
                    value={selectedValues || ""}
                    onChange={(e) => {
                      let selectedValue: string | number = e.target
                        .value as string;
                      if (!isNaN(Number(selectedValue))) {
                        selectedValue = Number(selectedValue);
                      }
                      if (Array.isArray(filterValues) && selectedOperator) {
                        // for old system when there was no operator selection
                        filterValues = {
                          [selectedOperator]: selectedValue,
                        };
                      } else if (selectedOperator) {
                        filterValues[selectedOperator] = selectedValue;
                      }
                      const c = { ...builderConfig };
                      if (c.with) {
                        c.with[filtersIndex].value = selectedFilters;
                      }
                      onConfigUpdate(c);
                    }}
                  />
                ) : (
                  <Dropdown
                    addOption={optionsAvailable}
                    multiple={operatorFromList?.allowMultiple}
                    isError={!isSingleFilterValid(filterKey)}
                    style={{ display: "table" }}
                    search
                    onSearch={handleOptionSearch}
                    options={
                      operatorFromList?.defaultOptions ||
                      options.find((o) => o.value === filterKey)?.options ||
                      selectedValues ||
                      []
                    }
                    // label={"Filter By"}
                    value={selectedValues || []}
                    onChange={(e: any) => {
                      if (Array.isArray(filterValues) && selectedOperator) {
                        // for old system when there was no operator selection
                        filterValues = {
                          [selectedOperator]: e.value,
                        };
                      } else if (selectedOperator) {
                        filterValues[selectedOperator] = e.value;
                      }
                      const c = { ...builderConfig };
                      if (c.with) {
                        c.with[filtersIndex].value = selectedFilters;
                      }
                      onConfigUpdate(c);
                    }}
                  />
                )}
                <div className="actions">
                  <DeleteButton
                    onClick={() => {
                      const c = { ...builderConfig };
                      if (c.with) {
                        const copyFilters = { ...selectedFilters };
                        delete copyFilters[filterKey];
                        if (Object.keys(copyFilters).length === 0) {
                          c.with.splice(filtersIndex, 1);
                        } else {
                          c.with[filtersIndex].value = copyFilters;
                        }
                      }
                      onConfigUpdate(c);
                    }}
                    btnText=""
                  />
                </div>
              </div>
            </div>
          );
        })}
      {
        // selectedFilters ? <Button className="add-filter" onClick={() => {
        <AddNewButton
          btnText="Add Filter"
          onClick={() => {
            // Add new filter
            const c = { ...builderConfig };
            if (c.with && filtersIndex > -1) {
              const expIndex = c.with[filtersIndex].value as {
                [key: string]: string[];
              };
              expIndex[SelectFilter] = [];
              c.with[filtersIndex].value = expIndex;
            } else {
              const copy = [...(c.with || [])];
              copy.push({
                key: "ATTRIBUTE_FILTER",
                value: {
                  [SelectFilter]: [],
                },
                is_arg: true,
              });
              c.with = copy;
            }
            onConfigUpdate(c);
          }}
        />
      }
    </React.Fragment>
  );
};
export default Filter;
