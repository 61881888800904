import Button from "core/components/button";
import { TimeRangeOption } from "core/components/datepicker/model";
import Dialog from "core/components/dialog";
import Dropdown from "core/components/dropdown";
import FormTextInput from "core/components/form/form-text-input";
import Tooltip from "core/components/tooltip";
import { TypographyHeadingPrimary } from "core/components/typography";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastType, showToast } from "store/toast-alerts/actions";
import {
  receivedBuilderFormattedData,
  removeItemFromDashboard,
  resetWidget,
} from "store/widgets/actions";
import {
  requestBuilderFormattedData,
  requestReportBuilderCreate,
} from "store/widgets/api";
import { ReportReducer } from "store/widgets/reducers";
import { getBuilderFormattedData2 } from "store/widgets/selectors";
import { AvailableDateRange } from "views/layouts/app/components/global-date-picker/model";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import {
  AllRouteParams,
  GlobalRouteParams,
} from "views/layouts/app/routes/model";
import { getFreshLayout, getMetricDetails } from "../common/utils";
import WidgetAppView from "../core/widget-app-view";
import { WidgetsApp, getChartTypeFromAppId } from "../core/widgets.app";
import {
  ATTRIBUTE_FILTER,
  AddUpdateWidgetBuilder,
  BuilderNestedProps,
  ChartType,
  CustomWidget,
  MetricItem,
  OnDropDownChange,
  Query,
  RequestParam,
  SELECT_DATA_BY,
  SelectFilter,
  SelectGroupBy,
  SelectOneMetric,
  defaultQuery,
  defaultWidgetBuilder,
  mapAppKey,
  toKpiType,
} from "../entities/builder.entities";
import QuerySelection from "../widget/query-selection";
import useBuilder from "./core/useBuilder";
import Steps, { Step } from "./steps";

type CreateWidgetProps = {
  widgetToEdit: CustomWidget | undefined;
  nestedProps?: BuilderNestedProps;
  onWidgetCreate: () => void;
};
type ValidateResponse = {
  isValid: boolean;
  msg: string;
};
const CreateEditWidget = (props: CreateWidgetProps) => {
  const dispatch = useDispatch();
  const query = useContext(MwRouteContext);

  const { widgetToEdit } = props;

  // Will be used in new widget creation
  const [widgetBuilder, setWidgetBuilder] = React.useState<CustomWidget>(
    JSON.parse(JSON.stringify(defaultWidgetBuilder))
  );

  // will be used to restore the widget builder if user cancels the operation
  const [widgetBuilderBackup, setWidgetBuilderBackup] =
    React.useState<CustomWidget>();

  const widgetData = useSelector(getBuilderFormattedData2);
  const reports: ReportReducer = widgetData.reports;
  const widgets = reports?.builders.widgets || {};

  const builderData = useBuilder();

  const selectedWidgetAppId = widgetBuilder.widgetAppId;
  const chartType = getChartTypeFromAppId(selectedWidgetAppId);

  useEffect(() => {
    if (widgetToEdit) {
      setWidgetBuilder(widgetToEdit);
      setWidgetBuilderBackup(JSON.parse(JSON.stringify(widgetToEdit)));
      //TODO: Confirm chart in clone mode
      // if (!widgetToEdit.isClone && appKey === "default") {
      //     setAppKey("time_series_chart");
      // }
    } else {
      setWidgetBuilder(JSON.parse(JSON.stringify(defaultWidgetBuilder)));
    }
  }, []);

  // validations functions
  const isNameValid = () => {
    if (!widgetBuilder.label) {
      return false;
    }
    return true;
  };
  const isQueryColumnValid = (
    query: Query,
    addMoreButton: boolean
  ): ValidateResponse => {
    if (query.columns.length === 0) {
      return {
        isValid: false,
        msg: "Please select at least one column",
      };
    }
    const anyColummnInvalid = query.columns.some((col) => {
      const c = col as string;
      const mDetails = getMetricDetails(c);
      if (mDetails.metricName === SelectOneMetric) {
        return true;
      }
      return false;
    });
    return {
      isValid: !anyColummnInvalid,
      msg: !anyColummnInvalid ? "" : "Please select valid column",
    };
  };
  const isColumnValid = (addMoreButton: boolean): ValidateResponse => {
    const builderConfig = widgetBuilder.builderConfig;
    if (!Array.isArray(builderConfig)) {
      return {
        isValid: false,
        msg: "Please Add Metrics",
      };
    }
    let validation: ValidateResponse = {
      isValid: true,
      msg: "",
    };
    builderConfig.forEach((q: Query) => {
      const result = isQueryColumnValid(q, addMoreButton);
      if (!result.isValid) {
        validation = result;
        return;
      }
    });
    return validation;
  };
  const isQueryGroupByValid = (query: Query): ValidateResponse => {
    const avg = query.with?.find((w) => w.key === SELECT_DATA_BY);
    if (avg && Array.isArray(avg.value)) {
      const result = avg.value.some((val) => {
        if (val === SelectGroupBy) {
          return true;
        }
      });
      return {
        isValid: !result,
        msg: "Please select group by or remove empty group by",
      };
    }
    return {
      isValid: true,
      msg: "",
    };
  };
  const isGroupByValid = () => {
    const builderconfig = widgetBuilder?.builderConfig;
    if (!Array.isArray(builderconfig)) {
      return {
        isValid: false,
        msg: "Please Add Group By",
      };
    }
    let validation: ValidateResponse = {
      isValid: true,
      msg: "",
    };
    builderconfig.forEach((q: Query) => {
      const result = isQueryGroupByValid(q);
      if (!result.isValid) {
        validation = result;
        return;
      }
    });
    return validation;
  };
  const isQueryFilterValid = (query: Query): ValidateResponse => {
    const filters = query.with?.find((w) => w.key === ATTRIBUTE_FILTER);
    if (filters && Object.keys(filters.value).length) {
      const result = Object.keys(filters.value).some((val) => {
        if (val === SelectFilter) {
          return true;
        } else {
          const vs = filters.value as {
            [key: string]:
              | string[]
              | {
                  [key: string]: string[];
                };
          };
          const value = vs[val];
          if (Array.isArray(value) && value.length === 0) {
            return true;
          } else if (typeof value === "object") {
            const ivalue = value as {
              [key: string]: string[];
            };
            const op = Object.keys(ivalue)[0];
            const v = ivalue[op];
            if (Array.isArray(v) && v.length === 0) {
              return true;
            } else if (typeof v === "string" && v === "") {
              return true;
            } else if (!v) {
              return true;
            }
          }
        }
      });
      return {
        isValid: !result,
        msg: "Please select filter or remove empty filter",
      };
    }
    return {
      isValid: true,
      msg: "",
    };
  };
  const isFilterValid = () => {
    const builderconfig = widgetBuilder?.builderConfig;
    if (!Array.isArray(builderconfig)) {
      return {
        isValid: false,
        msg: "Please Add Filters",
      };
    }
    let validation: ValidateResponse = {
      isValid: true,
      msg: "",
    };
    builderconfig.forEach((q: Query) => {
      const result = isQueryFilterValid(q);
      if (!result.isValid) {
        validation = result;
        return;
      }
    });
    return validation;
  };
  const isQueryValid = (addMoreButton: boolean): ValidateResponse => {
    const buildeConfig = widgetBuilder.builderConfig;
    if (!Array.isArray(buildeConfig)) {
      return {
        isValid: false,
        msg: "Please Add Metrics",
      };
    }
    let isScatterPlotLengthValid = buildeConfig.length > 2;
    if (addMoreButton) {
      isScatterPlotLengthValid = buildeConfig.length >= 2;
    }
    if (chartType === ChartType.ScatterPlotChart && isScatterPlotLengthValid) {
      return {
        isValid: false,
        msg: "Scatter Plot supports exact two metrics only",
      };
    }
    if (
      chartType === ChartType.QueryValueChart ||
      chartType === ChartType.TopListChart
    ) {
      let isLengthInvalid = buildeConfig.length > 1;
      if (addMoreButton) {
        isLengthInvalid = buildeConfig.length >= 1;
      }
      if (isLengthInvalid) {
        let msg = " supports only one metric column";
        if (chartType === ChartType.QueryValueChart) {
          msg = "Query Value" + msg;
        } else if (chartType === ChartType.TopListChart) {
          msg = "Top List" + msg;
        }
        return {
          isValid: false,
          msg,
        };
      }
    }
    let isLengthValid = buildeConfig.length > 5;
    if (addMoreButton) {
      isLengthValid = buildeConfig.length >= 5;
    }
    if (chartType === ChartType.TimeseriesChart && isLengthValid) {
      return {
        isValid: false,
        msg: "Maximum 5 metrics are allowed",
      };
    }
    return {
      isValid: true,
      msg: "",
    };
    // const duplicates = query.columns.filter((item, index) => query.columns.indexOf(item) != index);
    // if (duplicates.length > 0) {
    //     return {
    //         isValid: false,
    //         msg: "Please select unique columns"
    //     };
    // }
  };
  const isValidate = (
    showError: boolean
  ): {
    isValid: boolean;
    msg: string;
  } => {
    if (!isNameValid()) {
      showError && displyNotificaion("error", "Please enter name");
      return {
        isValid: false,
        msg: "Please enter name",
      };
    }
    if (!isColumnValid(false).isValid) {
      const errorMsg = isColumnValid(false).msg;
      showError && displyNotificaion("error", errorMsg);
      return {
        isValid: false,
        msg: errorMsg,
      };
    }
    const filterValidation = isFilterValid();
    if (!filterValidation.isValid) {
      const errorMsg = filterValidation.msg;
      showError && displyNotificaion("error", errorMsg);
      return {
        isValid: false,
        msg: errorMsg,
      };
    }
    const groupByValidation = isGroupByValid();
    if (!groupByValidation.isValid) {
      const errorMsg = groupByValidation.msg;
      showError && displyNotificaion("error", errorMsg);
      return {
        isValid: false,
        msg: errorMsg,
      };
    }
    const queryValidation = isQueryValid(false);
    if (!queryValidation.isValid) {
      const errorMsg = queryValidation.msg;
      showError && displyNotificaion("error", errorMsg);
      return {
        isValid: false,
        msg: errorMsg,
      };
    }
    return {
      isValid: true,
      msg: "",
    };
  };
  const displyNotificaion = (type: ToastType, msg: string) => {
    dispatch(showToast(type, msg));
  };

  const requestRuntimeFormatted = (
    showError = true,
    fromSave?: boolean,
    widget?: CustomWidget
  ) => {
    const data: AddUpdateWidgetBuilder = {
      action: "add",
      inflight: true,
      body: widget || widgetBuilder,
    };
    if (!isValidate(showError).isValid) {
      return;
    }
    data.body.params = getParams();
    if (fromSave) {
      dispatch(
        requestBuilderFormattedData(data, (a0: any, res?: any) => {}, true)
      );
      return;
    }
    dispatch(requestBuilderFormattedData(data));
  };
  const getParams = (): RequestParam[] => {
    const params: RequestParam[] = [
      {
        key: GlobalRouteParams.FromTs,
        value: query.params.dateRange.fromTs,
      },
      {
        key: GlobalRouteParams.ToTs,
        value: query.params.dateRange.toTs,
      },
    ];
    if (query.params.debug) {
      params.push({
        key: "debug",
        value: "true",
      });
    }
    Object.keys(query.params.allParams).forEach((key) => {
      if (AllRouteParams.includes(key)) {
        return;
      }
      params.push({
        key: key,
        value: query.params.allParams[key].values.join(""),
      });
    });
    return params;
  };
  const getBuilderDataToCreate = () => {
    const params = getParams();
    const requestWidgetBuilder: CustomWidget = {
      key:
        widgetBuilder.label.replace(/[^A-Z\d]/gi, "_").toLocaleLowerCase() +
        "_" +
        Math.random().toString(36).substring(2),
      ...widgetBuilder,
      params,
    };
    if (!widgetBuilder?.layout || widgetBuilder.isClone) {
      requestWidgetBuilder.layout = getFreshLayout(Object.values(widgets));
    }
    return requestWidgetBuilder;
  };
  const isUpdate = () => {
    return widgetBuilder && widgetBuilder.builderId !== -1;
  };
  const isClone = () => {
    return widgetBuilder && widgetBuilder.isClone;
  };
  const saveOrUpdateWidget = (showError = true) => {
    if (isValidate(showError).isValid) {
      const widgetToCreateOrUpdate = getBuilderDataToCreate();

      dispatch(
        requestReportBuilderCreate(
          widgetToCreateOrUpdate,
          (status: boolean, res) => {
            if (status && !res.inflight) {
              const msg = isClone()
                ? "Builder Cloned!"
                : isUpdate()
                  ? "Builder Updated!"
                  : "Builder Created!";
              dispatch(showToast("success", msg));
              if (!res?.error) {
                const builderId = res?.widget?.id || -1;
                const builderItem = widgets[builderId];
                let isError: boolean | undefined = false;
                if (builderItem) {
                  isError = builderItem.isError;
                }
                widgetToCreateOrUpdate.builderId = builderId;
                const scopeId = res?.widget?.scope?.id || -1;
                widgetToCreateOrUpdate.scopeId = scopeId;
                widgetToCreateOrUpdate.isError = isError;
                widgetToCreateOrUpdate.isClone = false;

                if (
                  (isUpdate() || isClone()) &&
                  widgetToCreateOrUpdate.widgetData
                ) {
                  delete widgetToCreateOrUpdate.widgetData;
                }
                if (res.widget?.scope?.meta_data?.RawMessage?.layouts) {
                  widgetToCreateOrUpdate.layout =
                    res.widget?.scope.meta_data.RawMessage.layouts;
                }
                dispatch(
                  receivedBuilderFormattedData({
                    action: isUpdate() ? "update" : "add",
                    body: widgetToCreateOrUpdate,
                    inflight: false,
                  })
                );

                resetBuilder(false);
              }
            } else if (!res.inflight) {
              dispatch(
                showToast("error", res?.error || "Something went wrong!")
              );
            }
          }
        )
      );
    }
  };

  const resetBuilder = (isCancel: boolean) => {
    if (widgetBuilder?.builderId === -1) {
      dispatch(removeItemFromDashboard(-1));
    }
    if (
      isCancel &&
      widgetBuilder?.builderId !== -1 &&
      widgetBuilderBackup &&
      widgetBuilderBackup?.builderId !== -1
    ) {
      dispatch(
        resetWidget({
          builderId: widgetBuilderBackup?.builderId,
          widget: widgetBuilderBackup,
        })
      );
      setWidgetBuilderBackup(undefined);
    }
    setWidgetBuilder(JSON.parse(JSON.stringify(defaultWidgetBuilder)));
    props.onWidgetCreate && props.onWidgetCreate();
  };
  useEffect(() => {
    if (widgetBuilder.widgetAppId && isColumnValid(false).isValid) {
      const copy = { ...widgetBuilder };
      const builderConfig = copy.builderConfig;
      if (Array.isArray(builderConfig)) {
        builderConfig.forEach((query: Query) => {
          const metrics = builderData.selectors.getResourceMetrics(
            query.source.name,
            chartType
          );
          if (query.columns && query.columns.length > 0) {
            query.columns = query.columns.filter((column) => {
              const c = column as string;
              const mDetails = getMetricDetails(c);
              const met = metrics.find(
                (a: MetricItem) => a.name === mDetails.metricName
              );
              return met;
            });
            if (query.columns.length === 0) {
              query = JSON.parse(JSON.stringify(defaultQuery));
            }
            if (
              chartType === ChartType.ScatterPlotChart &&
              builderConfig.length === 1
            ) {
              builderConfig.push(JSON.parse(JSON.stringify(defaultQuery)));
            }
          }
        });
        copy.builderConfig = builderConfig;
        setWidgetBuilder(copy);
      }
    }
    isValidate(false).isValid && requestRuntimeFormatted();
  }, [widgetBuilder.widgetAppId]);
  const componentsList = () => {
    if (
      !widgetBuilder?.builderConfig ||
      !Array.isArray(widgetBuilder.builderConfig)
    )
      return null;
    const kpiType = toKpiType(widgetBuilder?.builderConfig?.[0]?.source?.name);
    return (
      <div className={"components__list"}>
        {Object.values(WidgetsApp)
          .filter((a) => !a.disabled)
          .map((item, key: number) => {
            return (
              <div
                key={key}
                className={`list__item ${chartType === item.key ? "selected" : ""} ${item.disabledResourceType?.includes(kpiType) ? "disabled" : ""}`}
                onClick={() => {
                  if (chartType !== item.key) {
                    setWidgetBuilder({
                      ...widgetBuilder,
                      widgetAppId: item.id,
                    });
                  }
                }}
              >
                <div className="item__icon">{item.icon}</div>
                <span className="label">{item.label}</span>
              </div>
            );
          })}
      </div>
    );
  };
  const widgetTitle = () => {
    return (
      <div className={`header__title`}>
        <React.Fragment>
          <FormTextInput
            isError={!isNameValid()}
            onChange={(e: any) =>
              setWidgetBuilder({ ...widgetBuilder, label: e.target.value })
            }
            type={"text"}
            label={"Widget Title"}
            value={widgetBuilder.label}
          />
        </React.Fragment>
      </div>
    );
  };
  const metricsSelection = () => {
    const views = [];
    const builderConfig = widgetBuilder.builderConfig;
    if (Array.isArray(builderConfig)) {
      builderConfig.forEach((query: Query, index: number) => {
        const view = (
          <div
            key={index}
            className={`query-selection-wrapper pb-14 divider ${index > 0 ? "mt-14" : ""}`}
          >
            <QuerySelection
              queryIndex={index}
              query={query}
              onQueryChange={(updatedQuery) => {
                const copy = { ...widgetBuilder };
                if (Array.isArray(copy.builderConfig)) {
                  copy.builderConfig[index] = updatedQuery;
                } else {
                  copy.builderConfig = updatedQuery;
                }
                const kpiType = toKpiType(updatedQuery.source.name);
                const app = getChartTypeFromAppId(copy.widgetAppId);
                const appKey = mapAppKey(app);
                if (
                  WidgetsApp[appKey]?.disabledResourceType?.includes(kpiType)
                ) {
                  copy.widgetAppId = ChartType.TimeseriesChart;
                }
                setWidgetBuilder(copy);
                requestRuntimeFormatted(false, false, copy);
              }}
              chartType={chartType}
              onQueryDelete={() => {
                if (
                  chartType === ChartType.ScatterPlotChart &&
                  builderConfig.length === 2
                ) {
                  dispatch(
                    showToast(
                      "error",
                      "Scatter Plot supports exact two metrics only"
                    )
                  );
                  return;
                }
                if (builderConfig.length === 1) {
                  dispatch(
                    showToast("error", "At least one metric is required")
                  );
                  return;
                }
                const copy = { ...widgetBuilder };
                if (Array.isArray(copy.builderConfig)) {
                  copy.builderConfig.splice(index, 1);
                }
                setWidgetBuilder(copy);
                requestRuntimeFormatted(false, false, copy);
              }}
              builderConfig={builderConfig}
              onQueryCopy={(queryToCopy) => {
                const copy = { ...widgetBuilder };
                if (Array.isArray(copy.builderConfig)) {
                  const newQuery: Query = JSON.parse(
                    JSON.stringify(queryToCopy)
                  );
                  copy.builderConfig.push(newQuery);
                }
                setWidgetBuilder(copy);
                requestRuntimeFormatted(false, false, copy);
              }}
            />
          </div>
        );
        views.push(view);
      });
    } else {
      const view = (
        <div key={0} className="query-selection-wrapper">
          <QuerySelection
            query={defaultQuery}
            onQueryChange={(updatedQuery) => {
              const copy = { ...widgetBuilder };
              copy.builderConfig = [updatedQuery];
              setWidgetBuilder(copy);
              requestRuntimeFormatted(false, false, copy);
            }}
            chartType={chartType}
            queryIndex={0}
          />
        </div>
      );
      views.push(view);
    }
    views.push(addNewQuery());
    return <div className="query-selection-container">{views}</div>;
  };

  const addNewQuery = () => {
    let disabledAddButton = false;
    let disabledAddButtonTooltip = "";
    const columnsValidations = isQueryValid(true);
    if (!columnsValidations.isValid) {
      disabledAddButton = true;
      disabledAddButtonTooltip = columnsValidations.msg;
    }
    return (
      <React.Fragment key={"add-new-query"}>
        <Button
          primary
          style={{ marginTop: "14px" }}
          onClick={() => {
            const copy = { ...widgetBuilder };
            if (Array.isArray(copy.builderConfig)) {
              //TODO: Add source
              const newQuery: Query = JSON.parse(JSON.stringify(defaultQuery));
              const sourceName = copy.builderConfig[0].source.name;
              newQuery.source.name = sourceName;
              copy.builderConfig.push(newQuery);
            }
            setWidgetBuilder(copy);
          }}
          disabled={disabledAddButton}
          data-tip
          data-for={"add-more-metric"}
        >
          New Query
        </Button>
        <Tooltip id={"add-more-metric"} disable={!disabledAddButton}>
          <span>{disabledAddButtonTooltip}</span>
        </Tooltip>
      </React.Fragment>
    );
  };
  const setDisplayPreferences = () => {
    return (
      <Dropdown
        options={AvailableDateRange.map((o) => {
          return {
            label: `${o.range !== TimeRangeOption.Empty ? "Past " : ""}${o.label}`,
            value: o.range,
          };
        })}
        onChange={(e: OnDropDownChange) => {
          const copy = { ...widgetBuilder };
          if (!copy.builderMetaData) {
            copy.builderMetaData = {};
          }
          copy.builderMetaData.display_preference = e.value as TimeRangeOption;
          setWidgetBuilder(copy);
          requestRuntimeFormatted(false, false, copy);
        }}
        label="Global Time"
        value={widgetBuilder.builderMetaData?.display_preference || ""}
      />
    );
  };
  const advancedOptions = () => {
    const typeSelectionOptions = [
      {
        label: "Time Series",
        value: "timeseries_chart",
      },
      {
        label: "Area Chart",
        value: "area_chart",
      },
    ];
    const dropddown = (
      <Dropdown
        value={widgetBuilder.builderMetaData?.chartType || "timeseries_chart"}
        options={typeSelectionOptions}
        onChange={(e: { value: string }) => {
          const ty = e.value;
          const copy = { ...widgetBuilder };
          if (!copy.builderMetaData) {
            copy.builderMetaData = {};
          }
          copy.builderMetaData.chartType = ty;
          setWidgetBuilder(copy);
          requestRuntimeFormatted(false, false, copy);
        }}
      />
    );
    return dropddown;
  };
  const renderGroupSelection = () => {
    return (
      <FormTextInput
        onChange={(e: { target: { value: string } }) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const ty = e.target.value;
          const copy = { ...widgetBuilder };
          if (!copy.builderMetaData) {
            copy.builderMetaData = {};
          }
          copy.builderMetaData.group_name = ty;
          setWidgetBuilder(copy);
        }}
      />
    );
  };
  const getContent = () => {
    const steps: Step[] = [
      {
        title: "Select Chart Type",
        component: componentsList(),
        shouldDisplayComponent: true,
        // stepNumber: 1,
      },
      {
        title: `Select Metrics`,
        component: metricsSelection(),
        shouldDisplayComponent: true,
        // stepNumber: 3,
      },
      {
        title: `Set display preferences`,
        component: setDisplayPreferences(),
        shouldDisplayComponent: true,
        // stepNumber: 3,
      },
      {
        title: `Advanced Options`,
        component: advancedOptions(),
        shouldDisplayComponent: chartType === ChartType.TimeseriesChart,
        // stepNumber: 3,
      },
      {
        title: `Group`,
        component: renderGroupSelection(),
        shouldDisplayComponent: query.params.debug,
        // stepNumber: 3,
      },
      {
        title: "Enter Widget Title",
        component: widgetTitle(),
        shouldDisplayComponent: true,
        // stepNumber: getAppkey() === "list" ? 4 : 5,
      },
    ];
    const isValid = isValidate(false);
    const validationMsg = isValid.msg;
    return (
      <React.Fragment>
        <div className="widget-builder-header">
          <TypographyHeadingPrimary>
            {isClone()
              ? "Clone Widget"
              : isUpdate()
                ? "Update Widget"
                : "Create New Widget"}
          </TypographyHeadingPrimary>
        </div>
        <div className="widget-builder-widget">{renderWidget()}</div>
        <div className="widget-builder-content">
          <Steps steps={steps} />
        </div>
        <div className="widget-builder-footer">
          <div data-tip data-for={"preview-btn"}>
            <Button
              disabled={!isValid.isValid}
              primary
              onClick={requestRuntimeFormatted}
            >
              Preview Data
            </Button>
            <Tooltip id={"preview-btn"} disable={isValid.isValid}>
              <span>{validationMsg}</span>
            </Tooltip>
          </div>
          <div data-tip data-for={"save-btn"}>
            <Button
              disabled={!isValid.isValid}
              primary
              onClick={saveOrUpdateWidget}
            >
              {isClone() ? "Clone" : isUpdate() ? "Update" : "Save"}
            </Button>
            <Tooltip id={"save-btn"} disable={isValid.isValid}>
              <span>{validationMsg}</span>
            </Tooltip>
          </div>
          <Button
            scary
            onClick={() => {
              resetBuilder(true);
            }}
          >
            Cancel
          </Button>
        </div>
      </React.Fragment>
    );
  };
  const renderWidget = () => {
    const validation = isValidate(false);
    if (validation.isValid) {
      return (
        <WidgetAppView
          nestedProps={props?.nestedProps}
          builderViewOptions={{
            builderView: {
              builderId: widgetBuilder.builderId,
              scopeId: widgetBuilder.scopeId,
            },
          }}
          onSortingChange={() => {
            requestRuntimeFormatted();
          }}
          preview={true}
          refreshData={() => {
            requestRuntimeFormatted();
          }}
        />
      );
    } else {
      return (
        <div className="widget-builder-placeholder">
          <TypographyHeadingPrimary>{validation.msg}</TypographyHeadingPrimary>
        </div>
      );
    }
  };
  const renderContent = () => {
    return <div className="widget-builder-wrapper">{getContent()}</div>;
  };
  const renderDialog = () => {
    // return <Dialog isOpen={typeof widgetToEdit !== "undefined"}
    return (
      <Dialog
        isOpen={true}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        position={"center"}
        onClose={() => {
          resetBuilder(true);
        }}
        overlayClassName="overlay-class"
        contentClassName="content-class"
        htmlOpenClassName="open-class"
      >
        {renderContent()}
      </Dialog>
    );
  };
  // const renderFullPage = () => {
  //     return <div className="content-class">
  //         {renderContent()}
  //     </div>
  // }
  return <React.Fragment>{renderDialog()}</React.Fragment>;
};
export default CreateEditWidget;
