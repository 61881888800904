import moment from "moment/moment";

export const INFRA_MODULE_SLUG = "/infrastructure";
// export const INFRA_MODULE_K8S_SLUG = `/kubernetes`;
// export const INFRA_MODULE_K8S_WORKLOAD_SLUG = `/workloads`;

export const defaultDateRange = (
  min: number = 59,
  d?: Date
): { start: number; end: number } => {
  const _end: any = d ? new Date(d) : new Date();
  const _start = new Date(moment(_end).subtract(min, "minutes").valueOf());
  return {
    start: _start.valueOf(),
    end: _end.valueOf(),
  };
};
export type MapMetrics = {
  [key: string]: {
    [key: string]: string;
  };
};
export const mapAlertMetricToType: MapMetrics = {
  "system.cpu.utilization": {
    container: "container.cpu.percent",
    "k8s.container": "container.cpu.utilization",
    process: "process.cpu.percent",
    "k8s.pod": "k8s.pod.cpu.utilization",
  },
  "k8s.pod.cpu.utilization": {
    container: "container.cpu.percent",
    "k8s.container": "container.cpu.utilization",
    process: "process.cpu.percent",
    "k8s.pod": "k8s.pod.cpu.utilization",
  },
  "system.memory.utilization": {
    container: "container.memory.percent",
    "k8s.container": "container.memory.usage",
    process: "process.memory.percent",
    "k8s.pod": "k8s.pod.memory.usage",
  },
};
