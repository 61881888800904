import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";

import { getTenantUrl } from "core/application/utils";
import FormTextInput from "core/components/v2/form/form-text-input";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import Command from "../../../components/code-block/command";
import StepItem from "../../../components/steps/step-item";
import APMAgentConnect from "../../common/apm-agent-connect";
import ViewYourData from "../../common/view-data-buttons";

interface ContentProps {
  serverless: string;
}

const Content: React.FC<ContentProps> = ({ serverless }) => {
  const [appEnv, setAppEnv] = useState<number>(1);
  const [appBehaviour, setAppBehaviour] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];

  const [activeServiceName, setActiveServiceName] = useState("");

  interface StepFormat {
    title: string;
    content: React.ReactNode;
  }
  const sendEvent = useMixPanel();
  const steps: StepFormat[] = [
    {
      title: "Install Next.js APM package",
      content: (
        <>
          To install the Middleware Agent, your Next.js version must have
          13.2.0. Run the following command in your terminal:
          <br />
          <br />
          <Command
            command={`npm install @middleware.io/agent-apm-nextjs`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Next.JS",
                command_type: "Install Next.js APM package",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Modify the next.config.js file",
      content: (
        <>
          Add the following code to your next.config.js file: <br />
          <br />
          <Command
            command={`const nextConfig = { 
  experimental: {
      instrumentationHook: true
  }
}
module.exports = nextConfig`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Next.JS",
                command_type: "Modify the next.config.js file",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Create an Instrumentation file",
      content: (
        <>
          <div className="apm-service-name-block">
            <div className="apm-service-name-label">
              Create a custom instrumentation.ts file your project root
              directory and add the following code:{" "}
            </div>
            <FormTextInput
              id="go-service-text-input"
              // label={"Service Name"}
              className="apm-service-name-input"
              placeholder={"Service Name"}
              value={activeServiceName}
              onChange={(e: any) => setActiveServiceName(e)}
            />
          </div>
          <br />
          {serverless == "true" && (
            <Command
              onCopyClick={() => {
                sendEvent("click on copy command", {
                  agent_type: "Next.JS",
                  command_type: "Create an Instrumentation file",
                });
              }}
              command={`// @ts-ignore
import tracker from '@middleware.io/agent-apm-nextjs';

export function register() {
  tracker.track({
      serviceName: "${activeServiceName == "" ? "{APM-SERVICE-NAME}" : activeServiceName}",
      accessToken: "${context.user?.account?.agent_config?.token}",
      target: "${capturePath}"
  });
}`}
              className="margin-1"
            />
          )}
          {serverless != "true" && (
            <Command
              onCopyClick={() => {
                sendEvent("click on copy command", {
                  agent_type: "Next.JS",
                  command_type: "Create an Instrumentation file",
                });
              }}
              command={`// @ts-ignore
import tracker from '@middleware.io/agent-apm-nextjs';

export function register() {
  tracker.track({
      serviceName: "{APM-SERVICE-NAME}",
      accessToken: "${context.user?.account?.agent_config?.token}",
  });
}`}
              className="margin-1"
            />
          )}
        </>
      ),
    },
    {
      title: "Enable Custom Logs (Optional)",
      content: (
        <>
          Custom logs are ones that the application does not produce by default.
          They may be generated within certain methods, or managed by a central
          logging method in your application. To get started with custom logs
          add the following code:
          <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Next.JS",
                command_type: "Enable Custom Logs (Optional)",
              });
            }}
            command={`// @ts-ignore 
import tracker from '@middleware.io/agent-apm-nextjs';

export default async function handler(req, res) {

  tracker.info("Info Sample");
  tracker.warn("Warn Sample", {
      "tester": "Alex",
  });
  tracker.debug("Debug Sample");
  tracker.error("Error Sample");

  // Your existing code
}`}
            className="margin-1"
          />
        </>
      ),
    },
  ];

  let indexOffset = 0;
  if (serverless != "true") {
    indexOffset = 2;
  }

  return (
    <>
      {serverless != "true" && <APMAgentConnect agentName="Next.JS" />}
      {steps.map((step, index) => (
        <StepItem stepNumber={index + indexOffset + 1} title={step.title}>
          {step.content}
        </StepItem>
      ))}
      <ViewYourData
        language="Next.js"
        stepNumber={steps.length + indexOffset + 1}
      />
    </>
  );
};
export default Content;
