import React from "react";
import "./_style.scss";
import { InstallationCardRowItems } from "./entity";

const InstallationCardRow = (props: InstallationCardRowItems) => {
  const { items } = props;

  return (
    <>
      <div className={"d-flex outer-row"}>
        {items.map((item, index) => {
          return (
            <div className={"inner-block"} key={index}>
              <div className={"d-flex inner-row"}>
                <div className={"left-col"}>{item.label}</div>
                <div className={"right-col"}>{item.value}</div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default InstallationCardRow;
