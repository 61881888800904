export enum Size {
  Small = "small",
  Medium = "medium",
  Large = "large",
}
export enum Type {
  Primary = "primary",
  Secondary = "secondary",
  Default = "default",
  Disabled = "disabled",
  Outlined = "outlined",
  Warning = "warning",
  Error = "error",
}
export enum Align {
  Left = "left",
  Center = "center",
  Right = "right",
}
export enum Message {
  Warning = "warning",
  Info = "info",
  Success = "success",
  Error = "error",
}
