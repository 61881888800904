import SocketConnector from "core/application/socket";
import { Store } from "redux";
import { ProjectData } from "views/modules/settings-v2/projects/entities";

export interface ChangeContextProps {
  key: "socket";
  value: any;
}
export interface SettingValue {
  created_at: string;
  updated_at: string;
  id: number;
  key: string;
  value: any;
}
export interface User {
  user_id?: number;
  account_id?: number;
  full_name?: string;
  email?: string;
  calling_code?: string;
  phone?: string;
  country?: string;
  photo_id?: number;
  media_path?: string;
  account?: AccountData;
  sessionId?: string;
  account_user_role?: string;
  isSandboxUser?: boolean;
  billing?: Billing;
  misc_details?: MiscDetails;
  //TODO: Define the type of settings
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settings?: any;
  triggredAlertCount?: number;
  inFlight?: boolean;
  apiStatus?: number;
  projects?: ProjectData[];
  user_meta_data?: Record<string, any>;
  env?: string;
  role_name?: string;
  updated_at?: string;
}

export interface AgentConfig {
  account_id?: number;
  id?: number;
  key?: string;
  token?: string;
  metaData?: never;
}
export interface MiscDetails {
  agent_installed?: boolean;
  traces_installed?: boolean;
  normal_host?: number;
  apm_hosts?: number;
  container?: number;
  microservices?: number;
  process?: number;
  apm_traces?: number;
  env_type?: string;
  has_data?: boolean;
  skip_to_dashboard?: boolean;
  redirect_to_vercel?: boolean;
  database_integration?: boolean;
  datadog_installed?: boolean;
}
export interface AccountData {
  id?: string;
  agent_config?: AgentConfig;
  meta_data?: {
    simulate?: boolean;
    is_agent_installed?: boolean;
  };
  is_agent_installed?: boolean;
  uid?: string;
  app_host?: string;
  redirect_tenant_url?: string;
  host_count?: any;
  apm_host_count?: number;
  project_id?: number;
  project_uid?: string;
  created_at?: string;
  updated_at?: string;
  is_internal?: boolean;
}
export interface Application {
  store: Store | undefined;
  dispatch: any;
  accountData: AccountData | undefined;
  user: User | undefined;
  init: (cb?: (user: User) => void) => void;
  logout: () => void;
  socket: SocketConnector | undefined;
  statuspageInit: (cb?: (statuspageData: StatusPageData) => void) => void;
  // isRegister: boolean;
}
export interface Billing {
  id?: number;
  account_id?: number;
  customer_id?: ValidString;
  email?: string;
  company_name?: ValidString;
  free_trial?: boolean;
  trial_days?: number;
  subscribed_products?: {
    RawMessage: SubscribedProducts;
    Valid: boolean;
  };
  status?: string;
  meta_data?: {
    RawMessage: {
      due_payment: boolean;
      invoice_id: string;
      not_paid: boolean;
      stop_data_ingestion?: boolean;
    };
    Valid: boolean;
  };
  created_at?: string;
  updated_at?: string;
}
export interface ValidString {
  String: string;
  Valid: boolean;
}
export interface SubscribedProducts {
  infra: boolean;
  apm: boolean;
  logs: boolean;
  synthetics: boolean;
  serverless_traces: boolean;
  database: boolean;
  rum: boolean;
}
export const BillingStatus = {
  active: "active",
  pending: "pending",
  in_trial: "in_trial",
  cancelled: "cancelled",
};

export interface StatusPageData {
  id: number;
  page_name: string;
  project_id: number;
  description: string;
  website_url: string;
  logo_url: string;
  logo: string | null;
  favicon_url: string;
  favicon: string | null;
  theme_type: string;
  subscribe: boolean;
  email_subscribe: boolean;
  slack_subscribe: boolean;
  sms_subscribe: boolean;
  password_protected: boolean;
  password: string;
  auto_refresh: boolean;
  hide_page: boolean;
  hide_powered_by: boolean;
  custom_domain_name: string;
  font_name: string;
  banner_header_enabled: boolean;
  banner_header_name: string;
  theme_color: string;
  services: any | null;
  created_at: string;
  updated_at: string;
  page_status: string;
}
