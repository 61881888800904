import CreateRequest from "core/xhr";
import { requestAddUpdateSetting } from "store/account/api";
import {
    AWSInflightActive,
    awsAccessKeyStatusReceived,
    requestSlackUrl,
    vercelDetailsInflight,
    vercelDetailsReceived,
    vercelProjectsReceived, vercelStatusReceived
} from "store/integration/actions";
import { showToast } from "store/toast-alerts/actions";

export const requestSlackUrlAPI = (cb?:any) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/getslackauthurl",
            method: 'get',
            data: {}
        }).then( res => {
            cb(res.data)
            dispatch(requestSlackUrl(res.data))
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const requestMSTeamUrlAPI = (cb?:any) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/getmsteamaccessurl",
            method: 'get',
            data: {}
        }).then( res => {
            cb(res.data)
            // dispatch(requestSlackUrl(res.data))
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const requestCallbackMSTeam = (cb?:any) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: `/mscallback/${cb}`,
            method: 'get',
            data: {}
        }).then( res => {
            cb(res.data)
            dispatch(requestSlackUrl(res.data))
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const requestVercelAccessToken = (params?: any) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(vercelDetailsInflight());
        return CreateRequest({
            url: `/integration/vercel`,
            method: 'get',
            params: params.hasOwnProperty("code") ? {"code": params["code"]} : {}
        }).then( res => {
            const vercelResp = res.data;
            dispatch(vercelDetailsReceived(vercelResp));
            if (vercelResp.data.accessToken) {
                dispatch(requestAddUpdateSetting({ key: "vercel_integration_app", value: {
                        "access_token":     vercelResp.data.accessToken,
                        "user_id":          vercelResp.data.userId || "",
                        "team_id":          vercelResp.data.teamId || "",
                        "configuration_id": params.hasOwnProperty("configurationId") ? params["configurationId"] : "",
                        "otel":             vercelResp.otel || {},
                        "projects":         vercelResp.projects || [],
                        "log_drain":         vercelResp?.logDrain || {},
                    } }));

                setTimeout(() => {
                    if (params.hasOwnProperty("next")) {
                        window.location.href = params["next"];
                    }
                }, 3000);
            }
        }).catch( err => {
            console.log("vercel: error", err);
        });
    }
}

export const requestVercelListProjects = (params?: any) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(vercelDetailsInflight());
        return CreateRequest({
            url: `/integration/vercel/list`,
            method: 'get',
            params: {}
        }).then( res => {
            setTimeout(() => {
                const vercelResp = res.data;
                if (vercelResp.hasOwnProperty("status") && vercelResp.status) {
                    dispatch(vercelProjectsReceived(vercelResp));
                } else {
                    dispatch(vercelDetailsInflight({ flag: false }));
                    dispatch(showToast("error", "No project found, Either you missed to install the app or you have not any project right now."))
                }
            }, 1000);
        }).catch( err => {
            console.log("vercel: error", err);
        });
    }
}

export const requestVercelSyncProjects = (params?: any) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(vercelDetailsInflight());
        return CreateRequest({
            url: `/integration/vercel/sync`,
            method: 'get',
            params: {}
        }).then( res => {
            const vercelResp = res.data;
            if (vercelResp.hasOwnProperty("status") && vercelResp.status) {
                dispatch(vercelProjectsReceived(vercelResp));
            } else {
                setTimeout(() => {
                    dispatch(vercelDetailsInflight({flag: false}));
                    dispatch(showToast("error", "Syncing Error: Please allow at least one project, while installing the app."))
                }, 1000)
            }
        }).catch( err => {
            console.log("vercel: error", err);
        });
    }
}

export const requestRemoveVercelWarningStrip = (params?: any) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: `/integration/vercel/remove-warning-strip`,
            method: 'get',
            params: {}
        }).then( res => {
            const vercelResp = res.data;
            if (vercelResp && vercelResp.status && vercelResp.message){
                setTimeout(() => {
                    dispatch(showToast("success", vercelResp.message))
                }, 800)
            }
        });
    }
}

export const requestVercelStatus = (params?: any) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(vercelDetailsInflight());
        return CreateRequest({
            url: `/integration/vercel/status`,
            method: 'get',
            params: {}
        }).then( res => {
            const vercelResp = res.data;
            dispatch(vercelStatusReceived(vercelResp));
        }).catch( err => {
            console.log("vercel: error", err);
        });
    }
}

export const validateAWSAccessKey = (params?: {
    "aws_account_id": string;
    "access_key": string;
    "secret_key": string;
}) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(AWSInflightActive());
        return CreateRequest({
            url: `/integration/aws/validate-creds`,
            method: 'post',
            data: params
        }).then(res => {
            const validateAWSAccessKeyResp = res.data;
            dispatch(awsAccessKeyStatusReceived(validateAWSAccessKeyResp));
        }).catch(err => {
            console.log("aws: error", err);
        });
    }
}