import Button from "core/components/button";
import CodeViewer from "core/components/code-viewer";
import {
  AlertsIntegrationIcon,
  AngularSvg,
  CloseSvg,
  GatsbySvg,
  MiddlewareOnlyLogo,
  NextJsSvg,
  ReactSvg,
  VercelDeploy,
  VercelProjectIcon,
  VueSvg,
  WarningHelpDocsIcon,
} from "core/components/svg/icons";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { vercelStatusReset } from "store/integration/actions";
import {
  requestRemoveVercelWarningStrip,
  requestVercelListProjects,
  requestVercelStatus,
  requestVercelSyncProjects,
} from "store/integration/api";
import {
  getVercelInflight,
  getVercelProjects,
  getVercelStatus,
} from "store/integration/selectors";
import { getThemesMode } from "store/selectors";
import { showToast } from "store/toast-alerts/actions";
import {
  BkoffRightIconDark,
  BkoffWrongIcon,
} from "views/layouts/app/nav/_icons";
import { StepsEntity, StepsEnum } from "views/modules/integrations/entity";

export default function Verceljs() {
  const context = useContext<ContextProps>(AppContext);

  const dispatch = useDispatch();
  const isHelloBarRendered = document.querySelector(".hello-bar") !== null;

  const isVercelRedirectionExist = false;
  // const isVercelRedirectionExist = context?.user?.misc_details?.redirect_to_vercel || false;
  const [warningStrip, setWarningStrip] = useState(isVercelRedirectionExist);
  const themeMode = useSelector(getThemesMode);

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      dispatch(showToast("success", "Copied to Clipboard !"));
    });
  }

  const [activeStep, setActiveStep] = useState(StepsEnum.InstalledApp);
  const [activeProject, setActiveProject] = useState("");
  const steps: StepsEntity = {
    install_app: {
      title: "Install Middleware App",
    },
    projects_list: {
      title: "List of Projects",
    },
    code: {
      title: "Getting Started with Code",
    },
    custom_logs: {
      title: "Setup Custom Logs",
    },
    deploy_code: {
      title: "Deploy Code to Vercel",
    },
    see_data: {
      title: "View Your Data",
    },
  };

  const modifyNextjs =
    "const nextConfig = { \n" +
    "   experimental: { \n " +
    "      instrumentationHook: true, \n" +
    "      serverComponentsExternalPackages: ['@middleware.io/agent-apm-nextjs'] \n " +
    "   } \n" +
    "} \n" +
    "module.exports = nextConfig";

  const projectNameVercel =
    "// @ts-ignore \n" +
    "import tracker from '@middleware.io/agent-apm-nextjs'; \n \n" +
    "export function register() { \n" +
    "   tracker.track({\n" +
    `        serviceName: "${activeProject == "" ? "{APM-SERVICE-NAME}" : activeProject}", \n  ` +
    `      accessToken: "${context.user?.account?.agent_config?.token}", \n` +
    `        target: "vercel", \n` +
    "   }); \n" +
    "}";

  const ApmLogginSnap =
    " // @ts-ignore \n" +
    "import tracker from '@middleware.io/agent-apm-nextjs'; \n \n" +
    "export default async function handler(req, res) { \n \n" +
    '   tracker.info("Info Sample"); \n' +
    '   tracker.warn("Warn Sample", { \n' +
    '       "tester": "Alex", \n' +
    "   }); \n" +
    '   tracker.debug("Debug Sample"); \n' +
    '   tracker.error("Error Sample");\n \n' +
    "   // Your existing code \n " +
    "}";

  useEffect(() => {
    if (activeStep == StepsEnum.InstalledApp) {
      dispatch(requestVercelStatus());
    }

    if (activeStep == "projects_list") {
      dispatch(vercelStatusReset());
      dispatch(requestVercelListProjects());
    }
  }, [activeStep]);

  const appStatus = useSelector(getVercelStatus);
  const projects = useSelector(getVercelProjects);
  const inflight = useSelector(getVercelInflight);

  useEffect(() => {
    return () => {
      dispatch(vercelStatusReset());
    };
  }, []);

  useEffect(() => {
    if (isVercelRedirectionExist) {
      setActiveStep("projects_list");
    }
  }, [isVercelRedirectionExist]);

  const syncProjects = () => {
    if (!inflight) dispatch(requestVercelSyncProjects());
  };

  const moveToCode = () => {
    if (projects.length == 0)
      dispatch(
        showToast("error", "At least one project is mandatory to proceed.")
      );
    else if (activeProject == "")
      dispatch(
        showToast(
          "error",
          "Please select a project, on which you want to apply instrumentation."
        )
      );
    else setActiveStep("code");
  };

  return (
    <>
      {warningStrip && (
        <div className={"warning-strip"}>
          <span>
            <WarningHelpDocsIcon /> Vercel integration is incomplete, please
            deploy the changes required in your Vercel project.
          </span>
          <span
            onClick={() => {
              setWarningStrip(false);
              context.changeContext({
                key: "user",
                value: {
                  ...context.user,
                  misc_details: {
                    ...context.user?.misc_details,
                    redirect_to_vercel: false,
                  },
                },
              });
              dispatch(requestRemoveVercelWarningStrip());
            }}
          >
            <CloseSvg themeMode={themeMode} />
          </span>
        </div>
      )}
      <div
        className={
          "markdown-body installation-docs apm-installation-doc vercel-doc"
        }
      >
        <div className={"apm-body"}>
          <div className={"apm-steps"}>
            {steps &&
              Object.keys(steps).map((step, index) => {
                return (
                  <div
                    key={index + 1}
                    className={`step ${activeStep === step ? "selected" : ""} ${(Object.keys(steps).indexOf(activeStep) || 0) > index ? "passed" : ""}`}
                  >
                    <div className={"count"}>
                      <span>{index + 1}</span>
                    </div>
                    <div className={"detail vercel"}>{steps[step].title}</div>
                  </div>
                );
              })}
          </div>
          <div
            className={`apm-content ${!isHelloBarRendered && "hello-bar-exists"} ${isVercelRedirectionExist && "vercel-redirection-exists"}`}
          >
            {activeStep === StepsEnum.InstalledApp && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>1. Choose Framework</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Choose your framework. Then, complete the installation
                          of our application.
                        </span>{" "}
                        <br />
                        <span>
                          Vercel tracing is only available on{" "}
                          <strong>Serverless-Functions</strong>, as mentioned in
                          the Prerequisites{" "}
                          <a
                            href={
                              "https://vercel.com/docs/observability/otel-overview/quickstart#prerequisites"
                            }
                            target={"_blank"}
                          >
                            here
                          </a>
                          .
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"button-flex"}>
                          <button className={"active"}>
                            <NextJsSvg /> Next.js
                          </button>
                          <button className={"disabled"}>
                            <ReactSvg /> React
                          </button>
                          <button className={"disabled"}>
                            <AngularSvg /> Angular
                          </button>
                          <button className={"disabled"}>
                            <VueSvg /> Vue
                          </button>
                          <button className={"disabled"}>
                            <GatsbySvg /> Gatsby
                          </button>
                        </div>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Make sure you have installed the latest version of
                          Next.js or a version greater than 13.2.0
                        </span>
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>2. Install Middleware Application</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Click below to install the application from the Vercel
                          marketplace
                        </span>
                      </div>
                      <div className={"content"}>
                        <div
                          className={"app-box"}
                          onClick={() => {
                            window.open(
                              "https://vercel.com/integrations/middleware",
                              "_blank"
                            );
                          }}
                        >
                          <div className={"top"}>
                            <div className={"icon"}>
                              <MiddlewareOnlyLogo />
                            </div>
                            <div className={"details"}>
                              <div className={"app-name"}>Middleware</div>
                              <div className={"app-tag"}>Observability</div>
                            </div>
                          </div>
                          <div className={"bottom"}>
                            <span>
                              AI-powered cloud observability platform.
                            </span>
                          </div>
                        </div>
                        <span className={"sentence mini"}>
                          Checkout our doc for complete&nbsp;
                          <a
                            href={
                              "https://docs.middleware.io/docs/apm-configuration/next-js/vercel-integration"
                            }
                            target={"_blank"}
                          >
                            Vercel integration
                          </a>
                          .
                        </span>
                        {(appStatus == "vercel_integration_found" ||
                          appStatus ==
                            "vercel_integration_bad_access_token") && (
                          <div
                            className={`app-box-status ${appStatus == "vercel_integration_bad_access_token" ? "issue" : ""}`}
                          >
                            <div className={"icon"}>
                              {appStatus == "vercel_integration_found" && (
                                <BkoffRightIconDark />
                              )}
                              {appStatus ==
                                "vercel_integration_bad_access_token" && (
                                <BkoffWrongIcon />
                              )}
                            </div>
                            <div className={"info"}>
                              {appStatus == "vercel_integration_found" && (
                                <span>
                                  Middleware app is already installed, Please
                                  proceed to the next step.
                                </span>
                              )}
                              {appStatus ==
                                "vercel_integration_bad_access_token" && (
                                <span>
                                  Middleware app hasn't been installed properly,
                                  Please re-install it.
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button className={"disabled"}>Back</Button>
                  <Button
                    primary
                    onClick={() => setActiveStep("projects_list")}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}
            {activeStep === "projects_list" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span className={"align-items-center"}>
                          <NextJsSvg /> List of Projects
                        </span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Below are the list of Next.js projects that allowed
                          while installing the app on Vercel.
                        </span>
                      </div>
                      <div className={"content"}>
                        {projects.length > 0 && (
                          <p
                            className={"paragraph"}
                            style={{
                              marginTop: "10px",
                              marginBottom: 0,
                            }}
                          >
                            Please select any one project that you want to
                            instrument right now. If you want to instrument
                            multiple projects then follow the same step for rest
                            of projects.
                          </p>
                        )}
                        <div className={"projects"}>
                          {!inflight ? (
                            projects.length > 0 ? (
                              <>
                                {" "}
                                {projects.map((project: any, index: any) => {
                                  return (
                                    <div
                                      className={`project ${activeProject == project.name ? "active" : ""}`}
                                      key={index}
                                      onClick={() =>
                                        setActiveProject(project.name)
                                      }
                                    >
                                      <div className={"pr-icon"}>
                                        <VercelProjectIcon />
                                      </div>
                                      <div className={"pr-name"}>
                                        <span title={project.name}>
                                          {project.name}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}{" "}
                              </>
                            ) : (
                              <div className={"no-projects"}>
                                <span className={"sentence"}>
                                  No projects found, Please allow at least one
                                  project, while installing the app.
                                </span>
                              </div>
                            )
                          ) : (
                            ""
                          )}
                          <div className={"buttons-group"}>
                            <Button
                              primary
                              loading={inflight}
                              onClick={() => syncProjects()}
                            >
                              Sync Your Projects
                            </Button>
                          </div>
                          {inflight && (
                            <>
                              <div className={"project"} key={0}>
                                <div className={"pr-icon"}>
                                  <VercelProjectIcon />
                                </div>
                                <div className={"pr-name"}>
                                  <span>
                                    <Skeleton
                                      baseColor={"var(--color-grey-300)"}
                                      borderRadius={2}
                                      count={1}
                                      height={18}
                                      width={"100%"}
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className={"project"} key={1}>
                                <div className={"pr-icon"}>
                                  <VercelProjectIcon />
                                </div>
                                <div className={"pr-name"}>
                                  <span>
                                    <Skeleton
                                      baseColor={"var(--color-grey-300)"}
                                      borderRadius={2}
                                      count={1}
                                      height={18}
                                      width={"100%"}
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className={"project"} key={2}>
                                <div className={"pr-icon"}>
                                  <VercelProjectIcon />
                                </div>
                                <div className={"pr-name"}>
                                  <span>
                                    <Skeleton
                                      baseColor={"var(--color-grey-300)"}
                                      borderRadius={2}
                                      count={1}
                                      height={18}
                                      width={"100%"}
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className={"project"} key={3}>
                                <div className={"pr-icon"}>
                                  <VercelProjectIcon />
                                </div>
                                <div className={"pr-name"}>
                                  <span>
                                    <Skeleton
                                      baseColor={"var(--color-grey-300)"}
                                      borderRadius={2}
                                      count={1}
                                      height={18}
                                      width={"100%"}
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className={"project"} key={4}>
                                <div className={"pr-icon"}>
                                  <VercelProjectIcon />
                                </div>
                                <div className={"pr-name"}>
                                  <span>
                                    <Skeleton
                                      baseColor={"var(--color-grey-300)"}
                                      borderRadius={2}
                                      count={1}
                                      height={18}
                                      width={"100%"}
                                    />
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <br />
                        <span className={"sentence"}>
                          To deploy your first project, You can use the
                          following command:{" "}
                          <strong>
                            npx create-next-app@latest --example api-routes
                          </strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button onClick={() => setActiveStep(StepsEnum.InstalledApp)}>
                    Back
                  </Button>
                  <Button primary onClick={() => moveToCode()}>
                    Next
                  </Button>
                </div>
              </>
            )}
            {activeStep === "code" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>1. Install Next.js APM package</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Run the command below in your terminal to install
                          Middleware's Next.js APM package.
                        </span>
                      </div>
                      <div className={"content"}>
                        <pre
                          onClick={() => {
                            copyToClipboard(
                              "npm install @middleware.io/agent-apm-nextjs\n"
                            );
                          }}
                          className={"command"}
                        >
                          <code>
                            npm install @middleware.io/agent-apm-nextjs
                          </code>
                        </pre>
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>2. Modify the next.config.js file</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          As this feature is experimental, you need to
                          explicitly opt-in by adding the following code to your
                          `next.config.js` file.
                        </span>
                      </div>
                      <div className={"content"}>
                        <button
                          className={"code-viewer"}
                          onClick={() => {
                            copyToClipboard(modifyNextjs);
                          }}
                        >
                          <CodeViewer language={"javascript"}>
                            {modifyNextjs}
                          </CodeViewer>
                        </button>
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>3. Create an Instrumentation file</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Create a custom `instrumentation.ts` file in your
                          project root directory, and add the following code.
                        </span>
                      </div>
                      <div className={"content"}>
                        <button
                          className={"code-viewer"}
                          onClick={() => {
                            copyToClipboard(projectNameVercel);
                          }}
                        >
                          <CodeViewer language={"javascript"}>
                            {projectNameVercel}
                          </CodeViewer>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={"help"}>
                    <div className={"block"}>
                      <AlertsIntegrationIcon /> You can clone the sample project
                      from the{" "}
                      <a
                        href={
                          "https://github.com/middleware-labs/demo-apm/tree/master/nextjs/setup"
                        }
                        target={"_blank"}
                      >
                        GitHub
                      </a>{" "}
                      repository.
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button onClick={() => setActiveStep("projects_list")}>
                    Back
                  </Button>
                  <Button primary onClick={() => setActiveStep("custom_logs")}>
                    Next
                  </Button>
                </div>
              </>
            )}
            {activeStep === "custom_logs" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>Enable Custom Logs (Optional)</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Custom logs are ones that the application does not
                          produce by default. They may be generated within
                          certain methods, or managed by a central logging
                          method in your application.
                        </span>
                        <br />
                        <span>
                          To get started with custom logs add the following
                          code:
                        </span>
                      </div>
                      <div className={"content"}>
                        <button
                          className={"code-viewer"}
                          onClick={() => {
                            copyToClipboard(ApmLogginSnap);
                          }}
                        >
                          <CodeViewer language={"javascript"}>
                            {ApmLogginSnap}
                          </CodeViewer>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button onClick={() => setActiveStep("code")}>Back</Button>
                  <Button primary onClick={() => setActiveStep("deploy_code")}>
                    Next
                  </Button>
                </div>
              </>
            )}
            {activeStep === "deploy_code" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>Deploy Your Git Repository to Vercel</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Launch your latest project on Vercel by deploying your
                          Git repository.
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"landing-svg"}>
                          <VercelDeploy />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button onClick={() => setActiveStep("custom_logs")}>
                    Back
                  </Button>
                  <Button primary onClick={() => setActiveStep("see_data")}>
                    Next
                  </Button>
                </div>
              </>
            )}
            {activeStep === "see_data" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>View Your Data</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Once you have completed the Vercel installation, wait
                          3-5 minutes before accessing your data.
                        </span>
                      </div>
                      <div className={"content"}>
                        <p
                          className={"paragraph"}
                          style={{ marginTop: "10px", marginBottom: "0" }}
                        >
                          Access your Trace, Log, and Profiling data by clicking
                          on one of the buttons bellow.
                        </p>
                        <div className={"buttons-group"}>
                          <Button
                            primary
                            onClick={() =>
                              window.open("/apm/overview", "_blank")
                            }
                          >
                            Traces
                          </Button>
                          <Button
                            primary
                            onClick={() => window.open("/logs", "_blank")}
                          >
                            Logs
                          </Button>
                          <Button
                            primary
                            onClick={() =>
                              window.open("/apm/continuous-profiling", "_blank")
                            }
                          >
                            Profiling
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button onClick={() => setActiveStep("deploy_code")}>
                    Back
                  </Button>
                  <Button className={"disabled"}>Next</Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
