import * as React from 'react';
import classnames from 'classnames';
import { noop } from 'lodash-es';
import Button from '../button';
import PopoverMenu from '../popover/menu';
import {useState,useRef, useEffect} from "react";
import {MoreOptionsSvg} from "core/components/svg/icons";
import Popover from "core/components/popover";

interface Props{
	translate?: (e: any) => void,
	toggleTitle?: string,
	tooltipId?: string,
	tooltipPosition?: string,
	position?: string,
	buttonIcon?: any,
	additionalText?: string,
	iconColor?: string,
	disabled?: boolean,
	iconBeforeText?: boolean,
	onClick?: (e: any) => void,
	onToggle?: (e: any) => void,
	popoverClassName?: string,
	popoverMenuClassName?: string,
	dialog?: boolean,
	stopPropagation?: boolean,
	isVisible?: boolean,
	positionAdjustment?: {
		top: number
		left: number
	},
	showArrow?:boolean,
	footerButtonOnClick?:(e: any) => void,
	footerButtonLabel?:any,
	tooltipOverridePosition?:(e: any) => void,
	onClose?:(e: any) => void,
	buttonAttr?:object,
	className?: string,
	refPopoverContext?: any,
	style?: React.CSSProperties,
	children?:any,
	hideButtonIcon ?: boolean

}

const defaultProps= {
	onClick: noop,
	onToggle: noop,
	dialog: false,
	iconBeforeText: false,
	stopPropagation: false,
	buttonIcon: <MoreOptionsSvg/>,
	iconColor: "var( --color-grey-800 )",
	tooltipPosition: "top",
	isVisible: false,
	buttonAttr: {},
	hideButtonIcon:false
};

const ActionMenu = React.forwardRef(function (props: Props,ref ) {
	props = { ...defaultProps, ...props }
	const popoverContext:any = useRef(null);

	const [state, setState] = useState({
		isMenuVisible: false,
	})
	const updateState = (key: number | string, value: boolean) => {
		setState(st => {
			return {...st, [key]: value}
		})
	}


	const handleClick = (event: any) => {
		const { onClick } = props;
		const { isMenuVisible } =state;
		if(onClick != undefined){
			onClick( event );
		}
		if ( isMenuVisible ) {

			hideMenu(event);
		} else {

			showMenu();
		}
		// event.stopPropagation();
	};


	useEffect(() => {
		if(props.isVisible != undefined){
			state.isMenuVisible = props.isVisible;
			updateState("isMenuVisible",props.isVisible)
		}

	},[props.isVisible])


	const hideMenu = (e : any) => {
		toggleMenu( false );
		if( props.onClose ){
			props.onClose(e);
		}
	}

	const showMenu = () => toggleMenu( true );

	const toggleMenu = ( isMenuVisible: boolean ) => {
		if ( ! props.disabled ) {
			updateState("isMenuVisible",!state.isMenuVisible)
			if(props.onToggle != undefined){
				props.onToggle( isMenuVisible );
			}

		}
	}
	const {
		toggleTitle,
		position,
		stopPropagation,
		positionAdjustment,
		iconBeforeText,
		disabled,
		className,
		popoverClassName,
		popoverMenuClassName,
		refPopoverContext,
		dialog,
		footerButtonOnClick,
		footerButtonLabel,
		buttonAttr,

	} = props;
	const { isMenuVisible } = state;
	const classes = classnames( 'action-menu', className, {
		'is-menu-visible': isMenuVisible,
		'is-disabled': disabled,
	} );
	const popoverClasses = classnames( 'action-menu__menu', 'popover', popoverClassName, {'in-dialog': dialog} );

	const positionLeft = popoverContext.current &&  25 - (popoverContext.current.getBoundingClientRect().width /2)
	const isActionButton = props.buttonAttr != undefined && props.buttonAttr != null &&(props.buttonAttr as any).action!= undefined && (props.buttonAttr as any).action === true
	const actionPositionAdjust = {
		left :positionLeft ,
		top: 10
	}
	return (

		<span ref={popoverContext} className={ classes } style={props.style}>
				<Button
					onClick={ handleClick }
					title={ (toggleTitle || '') }
					borderless
					disabled={ disabled }
					className="action-menu__toggle"
					{...buttonAttr}
				>
                    {!props.hideButtonIcon && iconBeforeText && <span className={classnames("action-menu__toggle-icon after-text")} >{props.buttonIcon != '' && props.buttonIcon}</span>}
					{props.additionalText && <span className={classnames("additional-text", {
						"before-text": iconBeforeText
					})}>{props.additionalText}</span>}
					{!props.hideButtonIcon && !iconBeforeText && <span className={classnames("action-menu__toggle-icon after-text")} >{props.buttonIcon != '' && props.buttonIcon}</span>}
				</Button>
			{stopPropagation ? <Popover
					 id="action-menu_popover"
					 isVisible={ state.isMenuVisible }
					 onClose={ hideMenu }
					 position={ isActionButton ?  "bottom left" : position }
					 context={refPopoverContext?refPopoverContext:popoverContext.current }
					 className={popoverClasses}
					 positionAdjustment={isActionButton ? actionPositionAdjust :positionAdjustment}
					 popoverMenuClassName={popoverMenuClassName}
				>
					 	{ props.children }
				 </Popover> :
				<PopoverMenu
					menuClass={popoverMenuClassName}
                    positionAdjustment={isActionButton ? actionPositionAdjust :positionAdjustment}
					stopPropagation={stopPropagation}
					isVisible={state.isMenuVisible }
					onClose={ hideMenu }
					position={isActionButton ?  "bottom left" : position }
					context={  refPopoverContext?refPopoverContext:popoverContext.current }
					footerButtonLabel={footerButtonLabel}
					footerButtonOnClick={footerButtonOnClick}
					className={ popoverClasses }
				>
					{ props.children }
				</PopoverMenu>}

			</span>

	);
})
ActionMenu.displayName = "ActionMenu"
export default ActionMenu ;
