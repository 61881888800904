export const FETCH_METRICS_LIST_LOADING = "FETCH_METRICS_LIST_LOADING";
export const FETCH_METRICS_LIST_SUCCESS = "FETCH_METRICS_LIST_SUCCESS";
export const FETCH_METRICS_LIST_ERROR = "FETCH_METRICS_LIST_ERROR";

export const FETCH_METRIC_INFO_LOADING = "FETCH_METRICS_LIST_LOADING";
export const FETCH_METRIC_INFO_SUCCESS = "FETCH_METRIC_INFO_SUCCESS";
export const FETCH_METRIC_INFO_ERROR = "FETCH_METRIC_INFO_ERROR";

export const FETCH_METRIC_FILTER_LIST_LOADING =
  "FETCH_METRIC_FILTER_LIST_LOADING";
export const FETCH_METRIC_FILTER_LIST_SUCCESS =
  "FETCH_METRIC_FILTER_LIST_SUCCESS";
export const FETCH_METRIC_FILTER_LIST_ERROR = "FETCH_METRIC_FILTER_LIST_ERROR";
