import { ExpandIcon } from "core/components/svg/icons";
import { ActionMenu } from "core/components/v2/action-menu";
import Button from "core/components/v2/button";
import Card from "core/components/v2/card";
import { InfoPlacement } from "core/components/v2/card/entity";
import ComboChartV2 from "core/components/v2/charts/components/combo-chart";
import CountChart from "core/components/v2/charts/components/count-chart";
import TopListChart from "core/components/v2/charts/components/top-list";
import Dialog from "core/components/v2/dialog";
import { Size } from "core/components/v2/enums";
import LogoLoader from "core/components/v2/loader/logo-loader";
import NoDataSpace from "core/components/v2/no-data";
import { PopoverItem } from "core/components/v2/popover/item";
import {
  ActionMenuThreeDots,
  CloneIcon,
  DeleteIcon,
  EditIcon,
  ExportIcon,
  FilterIcon,
  InfoIcon,
} from "core/components/v2/svg/icons";
import CreateRequest from "core/xhr";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "store/toast-alerts/actions";
import CustomizedFilterButton, {
  CustomizedFilterOption,
} from "views/layouts/app/components/customise-filter-button";
import { DateRange } from "views/layouts/app/routes/model";
import DataTable from "../../../../../core/components/v2/charts/components/data-table";
import BaseHeatMapChartV2 from "../../../../../core/components/v2/charts/components/heatmap-chart";
import BuilderHexagonV2 from "../../../../../core/components/v2/charts/components/hexagon-chart";
import BuilderPieChartV2 from "../../../../../core/components/v2/charts/components/pie-chart";
import BuilderScatterPlotV2 from "../../../../../core/components/v2/charts/components/scatter-plot";
import TimeseriesChartV2 from "../../../../../core/components/v2/charts/components/timeseries";
import BuilderTreeChartV2 from "../../../../../core/components/v2/charts/components/tree-chart";
import { legendsLimit } from "../../common/utils";
import {
  AddUpdateWidgetBuilder,
  BuilderNestedProps,
  ChartDataType,
  CustomWidget,
  GridviewDataType,
  IsAreaChart,
  IsBarChart,
  IsHeatMapChart,
  IsHexagonChart,
  IsPieChart,
  IsQueryValueChart,
  IsScatterPlotChart,
  IsTableChart,
  IsTimeSeriesChart,
  IsTopListChart,
  IsTreeChart,
  Pagination,
  Query,
  SortDirection,
} from "../../entities/builder.entities";
import { getValueFromConfig } from "../../entities/tpl.entities";
import { WidgetAppViewOptions } from "../widget-app-view";
import BuilderLoader from "./builder-loader";
import { DefaultGroupName, LegendItem } from "./entities";
import "./style.scss";

export interface BuilderChartProps {
  //TODO: Replace type string with ChartType
  appKey: string;
  widgetData?: ChartDataType;
  builderViewOptions: WidgetAppViewOptions;
  customWidget: CustomWidget | undefined;
  onSortingChange?: (
    sortingColumnName: string,
    sortingType: SortDirection
  ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listOnClick?: (a0: any) => void;
  preview?: boolean;
  builderId: number;
  openInnerDialog?: boolean;
  nestedProps?: BuilderNestedProps;
  onEdit?: (() => void) | null;
  onClone?: (() => void) | null;
  onDelete?: (() => void) | null;
  /** Display title if you are using builder as resource */
  boxTitle?: string;
  subInfo?: string;
  titleChild?: React.ReactNode;
  columnsSelection?: CustomizedFilterOption[];
  onColumnsChange?: (selectedColumns: string[]) => void;
  onDefaultLabelClick?: () => void;
  isLoading?: boolean;
  isError?: boolean;
  errorMsg?: string;
  refreshData?: () => void;
  onPagination?: (offset: number) => void;
  pagination?: Pagination;
  expandableTitleOptions?: {
    showExpandable?: boolean;
    titleOnClick?: (expanded: boolean) => void;
  };
  onDateChange?: (range: DateRange) => void;
}
const BuilderChartV2 = (props: BuilderChartProps) => {
  const {
    appKey,
    widgetData,
    builderViewOptions,
    customWidget,
    onSortingChange,
    listOnClick,
    preview,
    openInnerDialog,
    boxTitle,
    subInfo,
    titleChild,
    columnsSelection,
    onColumnsChange,
    onDefaultLabelClick,
    isLoading = false,
    isError,
    errorMsg,
    refreshData,
    onPagination,
    pagination,
    expandableTitleOptions,
    onDateChange,
  } = props;
  const [isOpenModal, setIsOpen] = React.useState(false);
  const [legends, setLegends] = React.useState<LegendItem[]>([]);
  const dispatch = useDispatch();

  const getTimeSeriesData = () => {
    const timeseriesChartData = widgetData?.timeseriesChartData;
    if (!timeseriesChartData) {
      return undefined;
    }
    let filteredData = timeseriesChartData;
    if (legends && legends.length > 0) {
      filteredData = {
        ...timeseriesChartData,
        chartGroups: {},
      };
      legends
        .filter((legend) => legend.selected)
        .forEach((legend) => {
          filteredData.chartGroups[legend.title] =
            timeseriesChartData.chartGroups[legend.title];
        });
    }
    return filteredData;
  };
  const getGridViewData = () => {
    let filteredData = widgetData?.gridviewData;
    if (legends && legends.length > 0 && filteredData) {
      const copy: GridviewDataType = JSON.parse(
        JSON.stringify(filteredData)
      ) as GridviewDataType;
      copy.data = [];
      const groups = filteredData.columns.filter((col) => !col.isMetric);
      filteredData.data.forEach((item) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        const groupName = groups.map((group) => item[group.accessor]).join("-");
        const isLegendSelected = legends.find((legend) => {
          return legend.title === groupName && legend.selected;
        })?.selected;
        if (isLegendSelected) {
          copy.data.push(item);
        }
      });
      filteredData = copy;
    }
    return filteredData;
  };
  const generateLegendData = () => {
    const _allow = typeof props?.nestedProps?.allowAllLegend !== "undefined";
    const __legendData: LegendItem[] = [];
    if (!widgetData) return __legendData;
    if (
      (IsBarChart(appKey) ||
        IsTimeSeriesChart(appKey) ||
        IsHeatMapChart(appKey)) &&
      widgetData.timeseriesChartData
    ) {
      if (
        Object.keys(widgetData.timeseriesChartData.chartGroups || {}) &&
        Object.keys(widgetData.timeseriesChartData.chartGroups || {}).length > 0
      ) {
        Object.keys(widgetData.timeseriesChartData.chartGroups || {}).forEach(
          (groupName, index) => {
            if (groupName === DefaultGroupName) {
              return;
            }
            const i = __legendData.find(
              (e: LegendItem) => e.title === groupName
            );
            if (!i) {
              __legendData.push({
                title: groupName,
                selected: !!(_allow
                  ? props?.nestedProps?.allowAllLegend
                  : index < legendsLimit),
                alias: groupName,
              });
            }
          }
        );
      }
    } else if (widgetData.gridviewData) {
      const groups = widgetData.gridviewData.columns.filter(
        (col) => !col.isMetric
      );
      widgetData.gridviewData.data.forEach((item, index) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        const groupName = groups.map((group) => item[group.accessor]).join("-");
        let alias = "";
        if (/^\d+$/.test(groupName) && groups.length) {
          const number = parseInt(groupName);
          const group = groups[0];
          if (group) {
            alias = getValueFromConfig(number, group.tpl);
          }
        }
        __legendData.push({
          title: groupName,
          selected: !!(_allow
            ? props?.nestedProps?.allowAllLegend
            : index < legendsLimit),
          alias,
        });
      });
    }
    return __legendData;
  };
  useEffect(() => {
    if (!widgetData?.inflight) {
      const isBarChart = IsBarChart(appKey);
      const isTimeSeriesChart = IsTimeSeriesChart(appKey);
      const isHeatMapChart = IsHeatMapChart(appKey);
      const isTableChart = IsTableChart(appKey);
      const isQueryValueChart = IsQueryValueChart(appKey);
      const hasTimeseriesChartData =
        typeof widgetData?.timeseriesChartData !== "undefined";
      const hasGridViewData = typeof widgetData?.gridviewData !== "undefined";
      if (
        ((isBarChart || isTimeSeriesChart || isHeatMapChart) &&
          hasTimeseriesChartData) ??
        (!(isTableChart || isQueryValueChart) && hasGridViewData)
      ) {
        const legends = generateLegendData();
        setLegends(legends);
      } else {
        setLegends([]);
      }
    }
  }, [widgetData?.inflight]);
  const resource = builderViewOptions?.resource;

  const getComponent = (shouldReturnErrMsgIfOccured?: boolean) => {
    if (shouldReturnErrMsgIfOccured && isError && !isLoading) {
      return (
        <div className="get-component-error-msg-container">
          <InfoIcon size={32} color="var(--color-error)" />
          <div className="error-text">
            <span>Could not load data. Please retry.</span>
          </div>
        </div>
      );
    }

    if (IsTableChart(appKey)) {
      return (
        <DataTable
          widgetData={widgetData}
          builderViewOptions={builderViewOptions}
          onSortingChange={(...data) => {
            if (typeof onSortingChange === "function") {
              onSortingChange(...data);
            }
          }}
          rowClassName={
            typeof listOnClick === "function"
              ? "builder-row-clickable"
              : "builder-row-hoverable"
          }
          listOnClick={(...data) => {
            if (typeof listOnClick === "function") {
              listOnClick(...data);
            }
          }}
          onPagination={onPagination}
          pagination={pagination}
          openInnerDialog={openInnerDialog}
          nestedProps={props.nestedProps}
          titleChild={titleChild}
          columnsSelection={columnsSelection}
          onColumnsChange={onColumnsChange}
          onDefaultLabelClick={onDefaultLabelClick}
          // isDashboardItem={props.builderId!=-1 ? true : false}
        />
      );
    } else if (
      IsBarChart(appKey) ||
      IsTimeSeriesChart(appKey) ||
      IsAreaChart(appKey)
    ) {
      try {
        const timeseriesChartData = widgetData?.timeseriesChartData;
        const data = getTimeSeriesData();
        if (!timeseriesChartData || !data) {
          return (
            <div
              style={{ textAlign: "center" }}
              className={"widget-no-data-label"}
            >
              {!isLoading && <NoDataSpace />}
            </div>
          );
        }
        if (props.nestedProps?.multipleYAxis) {
          return (
            <div
              className={`${preview ? "preview-mode" : "normal-mode"} ${"chartType"}`}
              style={{ height: preview ? "100%" : "100%" }}
            >
              <ComboChartV2
                nestedProps={props.nestedProps}
                chartType={appKey}
                resource={resource}
                timeseriesData={data}
                onDateChange={onDateChange}
              />
            </div>
          );
        }
        return (
          <div
            className={`${preview ? "preview-mode" : "normal-mode"} ${"chartType"}`}
            style={{ height: preview ? "100%" : "100%" }}
          >
            <TimeseriesChartV2
              nestedProps={props.nestedProps}
              chartType={appKey}
              resource={resource}
              timeseriesData={data}
              onDateChange={onDateChange}
            />
          </div>
        );
      } catch (e) {
        console.warn(e);
        return <div className="app-error">{!isLoading && <NoDataSpace />}</div>;
      }
    } /*else if (IsQueryValueChart(appKey)) {
      return (
        <CountChart
          nestedProps={props.nestedProps}
          widgetData={widgetData}
          boxTitle={boxTitle}
          subInfo={subInfo}
        />
      );
    }*/ else if (IsTopListChart(appKey)) {
      return (
        <TopListChart
          nestedProps={props.nestedProps}
          toplistData={getGridViewData()}
          resource={resource}
          onDateChange={onDateChange}
        />
      );
    } else if (IsPieChart(appKey)) {
      return (
        <BuilderPieChartV2
          nestedProps={props.nestedProps}
          piechartData={getGridViewData()}
        />
      );
    } else if (IsScatterPlotChart(appKey)) {
      return (
        <BuilderScatterPlotV2
          nestedProps={props.nestedProps}
          scatterPlotDaata={getGridViewData()}
          onDateChange={onDateChange}
        />
      );
    } else if (IsTreeChart(appKey)) {
      return (
        <BuilderTreeChartV2
          nestedProps={props.nestedProps}
          treeChartDaata={getGridViewData()}
          onDateChange={onDateChange}
        />
      );
    } else if (IsHexagonChart(appKey)) {
      return (
        <BuilderHexagonV2
          builderViewOptions={builderViewOptions}
          nestedProps={props.nestedProps}
          hexagonData={getGridViewData()}
        />
      );
    } else if (IsHeatMapChart(appKey)) {
      const data = getTimeSeriesData();
      if (!data) {
        return (
          <div
            style={{ textAlign: "center" }}
            className={"widget-no-data-label"}
          >
            {!isLoading && <NoDataSpace />}
          </div>
        );
      }
      return (
        <BaseHeatMapChartV2
          nestedProps={props.nestedProps}
          timeseriesData={data}
          onDateChange={onDateChange}
        />
      );
    } else {
      return <LogoLoader />;
    }
  };

  let visibleAction = false;
  if (props.onEdit ?? props.onClone ?? props.onDelete) {
    visibleAction = true;
  }
  const content = (
    <div>
      {props.onEdit && (
        <PopoverItem
          selected={false}
          prefixIcon={<EditIcon />}
          onClick={props.onEdit}
        >
          Edit
        </PopoverItem>
      )}
      {props.onClone && (
        <PopoverItem
          selected={false}
          prefixIcon={<CloneIcon />}
          onClick={props.onClone}
        >
          Clone
        </PopoverItem>
      )}
      {props.onDelete && (
        <PopoverItem
          selected={false}
          prefixIcon={<DeleteIcon color={"var(--color-red)"} />}
          onClick={props.onDelete}
        >
          Delete
        </PopoverItem>
      )}
      {IsTableChart(appKey) && (
        <PopoverItem
          selected={false}
          prefixIcon={<ExportIcon />}
          onClick={() => {
            //TODO : make a chunk api call and recieve all data from api
            exportCsv();
          }}
        >
          Download
        </PopoverItem>
      )}
    </div>
  );

  const exportCsv = () => {
    // Convert the data array into a CSV string

    let query: Query | Query[] = customWidget?.builderConfig || [];
    if (customWidget?.builderConfig) {
      if (Array.isArray(customWidget.builderConfig)) {
        customWidget?.builderConfig.map((item) => {
          item.key = customWidget.key;
        });
      } else {
        customWidget.builderConfig.key = customWidget.key;
      }
      customWidget.builderConfig = query;
    }

    const widgets: AddUpdateWidgetBuilder = {
      action: "add",
      body: customWidget || {
        label: "",
        builderId: 0,
        scopeId: 0,
        widgetAppId: 5,
        builderConfig: query,
        builderViewOptions: {
          resource: {
            columns: builderViewOptions.resource?.columns || [],
            resourceType: builderViewOptions.resource?.resourceType || "",
            widgetAppId: 5,
            name: "csv-data",
          },
        },
      },
      inflight: true,
    };
    CreateRequest({
      url: "/builder/widget/export-csv",
      method: "post",
      data: widgets.body,
    })
      .then((res) => {
        dispatch(
          showToast("info", "Your Attachment will be sent to email when ready!")
        );
        // console.log(res);
      })
      .catch((e) => {
        console.error("error e--");
      });
  };
  return (
    <>
      <div
        className={`${preview ? "preview-mode" : "normal-mode"} ${"base_chart__wrapper"} ${resource ? "resource" : ""} ${appKey}`}
        style={{
          height: "100%",
          width: "100%",
          position: "relative",
          minHeight: isLoading
            ? IsQueryValueChart(appKey)
              ? "80px"
              : "220px"
            : "auto",
        }}
      >
        {isLoading && !IsTableChart(appKey) && <BuilderLoader />}
        {IsQueryValueChart(appKey) ? (
          <CountChart
            nestedProps={props.nestedProps}
            widgetData={widgetData}
            boxTitle={boxTitle}
            subInfo={subInfo}
            isLoading={isLoading}
            cardActions={
              <div className="charts-card-actions">
                {visibleAction && boxTitle && (
                  <div>
                    <ActionMenu prefixIcon={<ActionMenuThreeDots />}>
                      {content}
                    </ActionMenu>
                  </div>
                )}
              </div>
            }
          />
        ) : props.boxTitle ? (
          <Card
            infoPlacement={InfoPlacement.Start}
            bordered={true}
            expandableTitleOptions={expandableTitleOptions}
            title={titleChild ?? (boxTitle ? boxTitle : "")}
            titleInfo={props.nestedProps?.titleInfo}
            className={`main-header full-height-card ${isLoading ? "loading" : ""} ${titleChild ? "with-title-child" : "without-title-child"}`}
            isError={isError}
            errorMsg={errorMsg}
            refreshData={refreshData}
            cardActions={
              <div className="charts-card-actions">
                {legends.length > 0 && (
                  <div className="action-button-chart">
                    <CustomizedFilterButton
                      defaultLabelText={"Select All"}
                      onDefaultLabelClick={() => {
                        const copy = [...legends];
                        copy.forEach((legend) => {
                          legend.selected = true;
                        });
                        setLegends(copy);
                      }}
                      hideFooter
                      hideSelectAll
                      itemOnChange={(position: number) => {
                        const copy = [...legends];
                        copy[position].selected = !copy[position].selected;
                        setLegends(copy);
                      }}
                      labelText="Filter by"
                      handleApplyFilter={(appliedFilters) => {
                        const copy = [...legends];
                        if (appliedFilters.length === 0) {
                          copy.forEach((legend) => {
                            legend.selected = false;
                          });
                          setLegends(copy);
                          return;
                        }
                        copy.forEach((legend) => {
                          legend.selected = appliedFilters.includes(
                            legend.title
                          );
                        });
                        setLegends(copy);
                      }}
                      filterOptions={legends.map((legend) => ({
                        label: legend.title,
                        value: legend.title,
                        defaultSelected: legend.selected,
                      }))}
                      hideLabel
                      button={<FilterIcon />}
                    />
                  </div>
                )}
                <Button
                  size={Size.Small}
                  onClick={() => setIsOpen(true)}
                  className="action-button-chart"
                  prefixicon={<ExpandIcon />}
                />
                {visibleAction && boxTitle && (
                  <div>
                    <ActionMenu prefixIcon={<ActionMenuThreeDots />}>
                      {content}
                    </ActionMenu>
                  </div>
                )}
              </div>
            }
            displayActionsOnHoverOnly
          >
            {getComponent()}
          </Card>
        ) : (
          getComponent(true)
        )}
      </div>
      <Dialog
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpen(false);
        }}
        position={"center"}
        size="sm"
        verticalCenter={true}
      >
        <div
          style={{
            padding: "20px",
          }}
        >
          {getComponent(true)}
        </div>
      </Dialog>
    </>
  );
};
export default BuilderChartV2;
