import { createReducer } from "core/utils/create_reducer";
import { combineReducers } from "redux";
import {
  RECEIVED_SYNTHETICS_INFO,
  RECEIVED_SYNTHETICS_LIST,
  RECEIVED_SYNTHETICS_LIST_ITEMS,
  RECEIVED_SYNTHETICS_METRICS,
  STEP_COMPLETED_INDEX,
} from "store/synthetics/constant";

const list = createReducer(
  {
    items: [],
    inflight: true,
    singleInflight: true,
    testFireInflight: {},
    upTimeOverviewInflight: true,
    page: 1,
    total: 0,
    listItems: [],
  },
  {
    [RECEIVED_SYNTHETICS_LIST]: (state, opts) => {
      if (typeof opts.inflight !== "undefined") {
        state.inflight = opts.inflight;
      }
      if (typeof opts.testFireInflight !== "undefined" && opts?.testFireId) {
        state.testFireInflight[opts.testFireId] = opts.testFireInflight;
        state.testFireInflight = { ...state.testFireInflight };
      }
      if (typeof opts.singleInflight !== "undefined") {
        state.singleInflight = opts.singleInflight;
      }
      if (typeof opts.upTimeOverviewInflight !== "undefined") {
        state.upTimeOverviewInflight = opts.upTimeOverviewInflight;
      }
      if (opts?.items && Array.isArray(opts.items)) {
        state.items = opts.items;
      }

      if (state?.items && Array.isArray(state.items)) {
        if (typeof opts?.checks_overview !== "undefined") {
          for (const _id in opts.checks_overview) {
            const _checks = opts.checks_overview[_id];
            const index = state.items.findIndex(
              (item: any) => item.id === parseInt(_id)
            );
            if (index > -1) {
              if (!state.items[index]?.details) state.items[index].details = {};

              if (!state.items[index]?.details?.last_up_times)
                state.items[index].details.last_up_times = [];

              if (!state.items[index]?.details?.success_rate)
                state.items[index].details.success_rate = "0%";

              if (Array.isArray(_checks?.last_up_times)) {
                let existUpTimes = [];
                if (state.items[index].details.last_up_times) {
                  existUpTimes = Object.assign(
                    [],
                    state.items[index].details.last_up_times
                  );
                }
                state.items[index].details.last_up_times = [
                  ...existUpTimes,
                  ..._checks.last_up_times,
                ];

                state.items[index].details.last_up_times = state.items[
                  index
                ].details.last_up_times
                  .filter((val: any, i: number, arr: any) => {
                    return (
                      arr.findIndex((c: any) => c?.test_id == val?.test_id) == i
                    );
                  })
                  .sort((a: any, b: any) => {
                    return a.ts - b.ts;
                  });
              }

              if (_checks?.success_rate)
                state.items[index].details.success_rate = _checks.success_rate;
            }
          }
          state.items = Object.assign([], state.items);
        }

        if (opts?.id > 0 && opts?.actionType == "delete") {
          const index = state.items.findIndex(
            (item: any) => item.id === opts.id
          );
          if (index > -1) {
            state.items.splice(index, 1);
          }
          state.items = Object.assign([], state.items);
        }
      }

      if (opts?.synthetics?.id) {
        const index = state.items.findIndex(
          (item: any) => item.id === opts.synthetics.id
        );
        if (index === -1) {
          state.items.unshift(opts.synthetics);
        } else {
          if (!opts?.synthetics?.details && state.items[index].details) {
            opts.synthetics.details = state.items[index].details;
          }
          state.items[index] = opts.synthetics;
        }
        state.items = Object.assign([], state.items);
      }

      state._update = Math.random() * 100000 + 1 + "___" + Date.now();
      return { ...state };
    },
    [RECEIVED_SYNTHETICS_INFO]: (state, opts) => {
      if (opts) {
        return opts;
      }
      return { ...state };
    },
    [RECEIVED_SYNTHETICS_LIST_ITEMS]: (state, opts) => {
      if (typeof opts.inflight !== "undefined") {
        state.inflight = opts.inflight;
      }
      if (typeof opts.testFireInflight !== "undefined" && opts?.testFireId) {
        state.testFireInflight[opts.testFireId] = opts.testFireInflight;
        state.testFireInflight = { ...state.testFireInflight };
      }
      if (typeof opts.singleInflight !== "undefined") {
        state.singleInflight = opts.singleInflight;
      }
      if (typeof opts.upTimeOverviewInflight !== "undefined") {
        state.upTimeOverviewInflight = opts.upTimeOverviewInflight;
      }
      if (opts?.items && Array.isArray(opts.items)) {
        state.listItems = opts.items;
      }
      if (state?.listItems && Array.isArray(state.listItems)) {
        if (typeof opts?.checks_overview !== "undefined") {
          for (const _id in opts.checks_overview) {
            const _checks = opts.checks_overview[_id];
            const index = state.listItems.findIndex(
              (item: any) => item.id === parseInt(_id)
            );
            if (index > -1) {
              if (!state.listItems[index]?.details)
                state.listItems[index].details = {};

              if (!state.listItems[index]?.details?.last_up_times)
                state.listItems[index].details.last_up_times = [];

              if (!state.listItems[index]?.details?.success_rate)
                state.listItems[index].details.success_rate = "0%";

              if (Array.isArray(_checks?.last_up_times)) {
                let existUpTimes = [];
                if (state.listItems[index].details.last_up_times) {
                  existUpTimes = Object.assign(
                    [],
                    state.listItems[index].details.last_up_times
                  );
                }
                state.listItems[index].details.last_up_times = [
                  ...existUpTimes,
                  ..._checks.last_up_times,
                ];

                state.listItems[index].details.last_up_times = state.listItems[
                  index
                ].details.last_up_times
                  .filter((val: any, i: number, arr: any) => {
                    return (
                      arr.findIndex((c: any) => c?.test_id == val?.test_id) == i
                    );
                  })
                  .sort((a: any, b: any) => {
                    return a.ts - b.ts;
                  });
              }

              if (_checks?.success_rate)
                state.listItems[index].details.success_rate =
                  _checks.success_rate;
            }
          }
          state.items = Object.assign([], state.items);
        }
      }
      return { ...state };
    },
  }
);

const metrics = createReducer(
  {
    _update: null,
    groups: {},
    checks: {},
    inflight: true,
    check_inflight: false,
    check_id: null,
  },
  {
    [RECEIVED_SYNTHETICS_METRICS]: (state, opts) => {
      let checkId = null;
      if (opts?.check_id && parseInt(opts.check_id) > 0)
        checkId = parseInt(opts.check_id);
      if (opts?.checkId && parseInt(opts.checkId) > 0)
        checkId = parseInt(opts.checkId);

      if (typeof opts.inflight !== "undefined") state.inflight = opts.inflight;
      if (typeof opts.check_inflight !== "undefined")
        state.check_inflight = opts.check_inflight;

      if (opts?.checksGroup && Array.isArray(opts.checksGroup)) {
        for (const group of opts.checksGroup) {
          state.checks[group.check_id] = group;
        }
        state.checks = { ...state.checks };
      }
      if (
        opts.updateChecks?.test_id &&
        opts.updateChecks?.checks &&
        Object.values(opts.updateChecks.checks).length > 0
      ) {
        state.checks[opts.updateChecks.test_id] = opts.updateChecks;
      }

      if (checkId) {
        let checkItems: any[] = [];
        if (typeof state.groups[checkId] == "undefined") {
          state.groups[checkId] = {
            test_runes: [],
          };
        }
        if (Array.isArray(state.groups[checkId].test_runes)) {
          checkItems = [...state.groups[checkId].test_runes];
        }
        if (opts?.testRunes && opts.testRunes?.test_id) {
          checkItems.unshift(opts.testRunes);
          state = { ...state };
        }
        if (
          opts?.metrics?.test_runes &&
          Array.isArray(opts.metrics.test_runes)
        ) {
          if (opts?.flushChecks) {
            checkItems = [...opts.metrics.test_runes];
          } else {
            checkItems = [...checkItems, ...opts.metrics.test_runes];
          }
        }

        checkItems = checkItems
          .sort((a: any, b: any) => {
            return b.timestamp - a.timestamp;
          })
          .filter(
            (item, i, items) =>
              items.findIndex((c) => c?.test_id == item?.test_id) === i
          );

        state.groups[checkId].test_runes = [...checkItems];
      }

      state._update = Math.random() * 100000 + 1 + "___" + Date.now();
      return Object.assign({}, { ...state });
    },
  }
);
const completedStepIndex = createReducer(false, {
  [STEP_COMPLETED_INDEX]: (state, opts) => {
    return opts.value;
  },
});
export default combineReducers({
  list,
  metrics,
  completedStepIndex,
});
