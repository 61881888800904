import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import highchartsAnnotations from "highcharts/modules/annotations";
import highchartsTreemap from "highcharts/modules/treemap";
import highchartsHeatmap from "highcharts/modules/heatmap";
import highchartsTilemap from "highcharts/modules/tilemap";
highchartsMore(Highcharts);
highchartsAnnotations(Highcharts);
highchartsTreemap(Highcharts);
highchartsHeatmap(Highcharts);
highchartsTilemap(Highcharts);
export default HighchartsReact;

export const Highchart = Highcharts;
