import Button from "core/components/v2/button";
import { PlusIcon } from "core/components/v2/svg/icons";
import { debounceHandler } from "core/utils";
import React, { useCallback, useState } from "react";
import useDidMountEffect from "views/layouts/app/routes/useDidMountEffect";
import {
  ColorApplyAt,
  FilterOpEnum,
  Query,
  VisualFormattingRule,
  operatorMapping,
} from "../../entities/builder.entities";
import VisualFormattingRuleItem from "./rule-item";

export interface VisualFormattingRulesProps {
  query: Query;
  onQueryChange: (query: Query) => void;
}

export default function VisualFormattingRules({
  query,
  onQueryChange,
}: VisualFormattingRulesProps) {
  const [formattingRules, setFormattingRules] = useState<
    VisualFormattingRule[]
  >(query.meta_data?.visualFormatting || []);

  const onFormattingChange = useCallback(
    debounceHandler(
      -1,
      (newFormattingRules: VisualFormattingRule[]) => {
        const copy = { ...query };
        copy.meta_data = copy.meta_data || {};
        copy.meta_data.visualFormatting = newFormattingRules;
        onQueryChange(copy);
      },
      500
    ),
    [query]
  );

  useDidMountEffect(() => {
    onFormattingChange(formattingRules);
  }, [JSON.stringify(formattingRules)]);

  const handleOperatorChange = (idx: number, value: string) => {
    const newFormattingRules = [...formattingRules];
    newFormattingRules[idx].operator = operatorMapping[value];
    setFormattingRules(newFormattingRules);
  };

  const handleValueChange = (idx: number, value: string) => {
    const newFormattingRules = [...formattingRules];
    newFormattingRules[idx].value = !isNaN(parseInt(value))
      ? parseInt(value)
      : 0;
    setFormattingRules(newFormattingRules);
  };

  const handleValue2Change = (idx: number, value: string) => {
    const newFormattingRules = [...formattingRules];
    newFormattingRules[idx].value2 = !isNaN(parseInt(value))
      ? parseInt(value)
      : 0;
    setFormattingRules(newFormattingRules);
  };

  const handleColorChange = (idx: number, value: string) => {
    const newFormattingRules = [...formattingRules];
    newFormattingRules[idx].color = value;
    setFormattingRules(newFormattingRules);
  };

  const handleColorApplyAtChange = (idx: number, value: ColorApplyAt) => {
    const newFormattingRules = [...formattingRules];
    newFormattingRules[idx].applyAt = value;
    setFormattingRules(newFormattingRules);
  };

  const handleRuleAdd = () => {
    const newFormattingRules = [...formattingRules];
    newFormattingRules.push({
      operator: FilterOpEnum.GreaterThan,
      value: 0,
      color: "#5b5fc7",
    });
    setFormattingRules(newFormattingRules);
  };

  const handleRuleDelete = (idx: number) => {
    const newFormattingRules = [...formattingRules];
    newFormattingRules.splice(idx, 1);
    setFormattingRules(newFormattingRules);
  };

  return (
    <div className="formatting-rules-container">
      <div className="add-button">
        <Button
          prefixicon={<PlusIcon color="var(--color-text)" />}
          onClick={handleRuleAdd}
        >
          Add Visual Formatting Rule
        </Button>
      </div>

      {formattingRules.map((rule, idx) => (
        <VisualFormattingRuleItem
          key={idx}
          idx={idx}
          rule={rule}
          onOperatorChange={handleOperatorChange}
          onValueChange={handleValueChange}
          onValue2Change={handleValue2Change}
          onColorChange={handleColorChange}
          onColorApplyAtChange={handleColorApplyAtChange}
          onRuleDelete={handleRuleDelete}
        />
      ))}
    </div>
  );
}
