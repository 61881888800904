import React, { useEffect, useState } from "react";

import Search from "core/components/v2/search";
import Button from "core/components/v2/button";
import FormCheckbox from "core/components/v2/form/form-checkbox";
import Tooltip from "core/components/v2/tooltip";
import { CustomizedFilterOption } from ".";
import { debounceHandler } from "core/utils";

interface FilterDropdownProps {
  handleApplyFilter: (appliedFilterValues: string[]) => void;
  filterOptions: CustomizedFilterOption[];
  onClose: () => void;
  labelText?: string;
  defaultLabelText?: string;
  onDefaultLabelClick?: () => void;
  hideSelectAll?: boolean;
  hideFooter?: boolean;
  itemOnChange?: (position: number) => void;
}

const FilterDropdown = (props: FilterDropdownProps) => {
  const {
    handleApplyFilter,
    filterOptions,
    onClose,
    labelText,
    defaultLabelText,
    onDefaultLabelClick,
    hideSelectAll,
    hideFooter,
    itemOnChange,
  } = props;
  const [sortedOptions, setSortedOptions] = useState<CustomizedFilterOption[]>(
    []
  );
  const [checkedState, setCheckedState] = useState<boolean[]>(
    filterOptions.map((option) => option.defaultSelected || false)
  );

  const [searchedValue, setSearchedValue] = useState("");

  const handleOnChange = (position: number) => {
    const updatedCheckedState = checkedState.map((item, id) =>
      id === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };

  const isSelectAllChecked = checkedState.every((isChecked) => isChecked);

  useEffect(() => {
    const sortedOptionsTemp = [...filterOptions].sort((a, b) => {
      const isCheckedA = checkedState[filterOptions.indexOf(a)] ? 1 : 0;
      const isCheckedB = checkedState[filterOptions.indexOf(b)] ? 1 : 0;

      return isCheckedB - isCheckedA;
    });

    setSortedOptions(sortedOptionsTemp);
  }, [onClose]);

  const handleSearch = debounceHandler(
    0,
    (search: string) => {
      setSearchedValue(search);
    },
    500
  );

  const disableApplyChangeBtn = () => {
    if (filterOptions.length === 0) return true;
  };

  useEffect(() => {
    setCheckedState(
      filterOptions.map((option) => option.defaultSelected || false)
    );
  }, [filterOptions]);

  return (
    <div className="filter-dropdown-container">
      <div className="filter-search-container">
        <div className="filter-header">
          <div className="row-customization">
            {labelText ?? "Customization"}
          </div>
          {filterOptions.length > 0 && (
            <div
              className="reset"
              onClick={() => {
                if (onDefaultLabelClick) {
                  onDefaultLabelClick();
                  onClose();
                }
                setCheckedState(
                  filterOptions.map((option) => option.defaultSelected || false)
                );
              }}
            >
              {defaultLabelText ?? "Default"}
            </div>
          )}
        </div>
        <Search
          onChange={(e) => handleSearch(e)}
          width={216}
          disabled={filterOptions.length === 0}
          placeholder="Search"
        />
      </div>
      <div className="options-list-container">
        {!hideSelectAll && (
          <div className="option-container">
            <FormCheckbox
              disabled={filterOptions.length === 0}
              id="SelectAll"
              label="Select All"
              checked={isSelectAllChecked}
              onChange={(checked) => {
                setCheckedState(new Array(filterOptions.length).fill(checked));
              }}
            />
          </div>
        )}
        {sortedOptions.length === 0 && (
          <div className="option-container">No results found</div>
        )}
        {sortedOptions
          .filter(
            (option) =>
              option.label.toLowerCase().includes(searchedValue.toLowerCase()) ||
              option.value.toLowerCase().includes(searchedValue.toLowerCase())
          )
          .map((option, id) => (
            <div className="option-container" key={id}>
              <FormCheckbox
                id={option.value}
                label={option.label}
                disabled={option.disabled}
                checked={checkedState[filterOptions.indexOf(option)]}
                onChange={() => {
                  handleOnChange(filterOptions.indexOf(option));
                  if (itemOnChange) {
                    itemOnChange(filterOptions.indexOf(option));
                  }
                }}
              />
            </div>
          ))}
      </div>
      {!hideFooter && (
        <div className="filter-dropdown-footer">
          <div onClick={onClose} className="cancel-btn">
            Cancel
          </div>
          <Tooltip
            content={
              checkedState.every((isChecked) => isChecked === false)
                ? "Atleast one column should be selected"
                : ""
            }
          >
            <div>
              <Button
                primary
                onClick={() => {
                  const selectedValues: string[] = [];
                  filterOptions.map((option, id) => {
                    if (checkedState[id]) {
                      selectedValues.push(option.value);
                    }
                  });
                  handleApplyFilter(selectedValues);
                  onClose();
                }}
                disabled={
                  filterOptions.length === 0 ||
                  checkedState.every((isChecked) => isChecked === false)
                }
              >
                Apply Changes
              </Button>
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default FilterDropdown;
