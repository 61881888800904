import { Tooltip as AntdTooltip } from "antd";
import React from "react";

interface TooltipProps {
  /**
   * Where you want to display the tooltip
   */
  children: string | React.ReactNode;

  /**
   * Position of tooltip display
   * @default top
   */
  position?: "top" | "right" | "left" | "bottom" | "topLeft" | "topRight";

  /**
   * What you want to show in tooltip
   */
  content: string | React.ReactNode;
}

const Tooltip = (props: TooltipProps) => {
  return (
    <AntdTooltip
      placement={props.position ? props.position : "top"}
      trigger={"hover"}
      destroyTooltipOnHide
      overlay={props.content}
      showArrow
      overlayStyle={{ maxWidth: "calc(80vw - 100px)" }}
      overlayClassName={"mw-tooltip-overlay"}
    >
      {props.children}
    </AntdTooltip>
  );
};

export default Tooltip;
