import {createReducer} from "core/utils/create_reducer";
import {TRACE_DASHBOARD_RECEIVED, WS_TRACE_DASHBOARD_RECEIVED, TRACE_LIST_RECEIVED, RECEIVED_TRACE_INFO} from "./constant";
import {combineReducers} from "redux";
import {abbreviateNumber} from "core/application/utils";


function pushMetric(sourceArray: object[], newArray: object) {
    if (sourceArray.length >= 10) sourceArray.shift();
    sourceArray.push(newArray);
    // sourceArray = sourceArray.concat(newArray);
    return sourceArray;
}

export default combineReducers({
    dashboard: createReducer({
        info: {},
        update: null,
    }, {
        [TRACE_DASHBOARD_RECEIVED]: (state, opts) => {
            if (opts.status && opts.hasOwnProperty('data') && Object.keys(opts.data).length > 0) {
                state.info = opts.data;
            }
            return {...state};
        },
        [WS_TRACE_DASHBOARD_RECEIVED]: (state, opts) => {
            if (opts.wsResponse) {
                const wsResponse = opts.wsResponse;
                const accordionsStack = state.info['accordions'];
                const overviewStack = state.info['overview'];
                const wsStack = state.info['ws_stats'];
                if (wsResponse && wsResponse.hasOwnProperty('accordions') && wsStack && wsStack.hasOwnProperty('accordions')) {
                    for (const accKey in wsResponse.accordions) {
                        const accData = wsResponse.accordions[accKey];
                        if (wsStack.accordions.hasOwnProperty(accKey)) {
                            for (const chartKey in accData) {
                                if (wsStack.accordions[accKey].hasOwnProperty(chartKey)) {
                                    if (accordionsStack[accKey]?.body?.boxes[chartKey]?.chart?.type === "pie") {
                                        wsStack.accordions[accKey][chartKey] = wsResponse.accordions[accKey][chartKey];
                                    } else if (accordionsStack[accKey]?.body?.boxes[chartKey]?.chart?.type === "line") {
                                        const linesLength = wsResponse.accordions[accKey][chartKey].length;
                                        for (const [lineIndex, _] of wsResponse.accordions[accKey][chartKey].entries()) {
                                            if (linesLength === wsStack.accordions[accKey][chartKey].length) {
                                                wsStack.accordions[accKey][chartKey][lineIndex].values = pushMetric(
                                                    wsStack.accordions[accKey][chartKey][lineIndex].values,
                                                    wsResponse.accordions[accKey][chartKey][lineIndex]
                                                );
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                if (wsResponse && wsResponse.hasOwnProperty('overview') && wsStack && wsStack.hasOwnProperty('overview')) {
                    for (const ovKey in wsResponse.overview) {
                        if (wsStack.overview.hasOwnProperty(ovKey)) {
                            if (overviewStack[ovKey].hasOwnProperty('value')) {
                                overviewStack[ovKey]['value'] = abbreviateNumber(wsResponse.overview[ovKey][0]['yAxis']);
                            }
                            const linesLength = wsResponse.overview[ovKey].length;
                            for (const [lineIndex, _] of wsResponse.overview[ovKey].entries()) {
                                if (linesLength === wsStack.overview[ovKey].length) {
                                    wsStack.overview[ovKey][lineIndex].values = pushMetric(
                                        wsStack.overview[ovKey][lineIndex].values,
                                        wsResponse.overview[ovKey][lineIndex]
                                    );
                                }
                            }
                        }
                    }
                }

                if (wsResponse && wsResponse.hasOwnProperty('clusters') && wsStack && wsStack.hasOwnProperty('clusters')) {
                    for (const cKey in wsResponse.clusters) {
                        if (wsStack.clusters.hasOwnProperty(cKey)) {
                            for (const nodeKey in wsResponse.clusters[cKey]) {
                                if (wsStack.clusters[cKey].hasOwnProperty(nodeKey)) {
                                    const allServices = wsResponse.clusters[cKey][nodeKey];
                                    const working = allServices.filter((s: any) => s.status === 'working');
                                    const stop = allServices.filter((s: any) => s.status === 'stop');
                                    const lowRunning = allServices.filter((s: any) => s.status === 'lowRunning');
                                    const remaining = allServices.filter((s: any) => s.status === 'inactive');
                                    wsStack.clusters[cKey][nodeKey] = [...stop, ...lowRunning, ...working, ...remaining];
                                }
                            }
                        }
                    }
                }

                state.info['overview'] = overviewStack;
                state.info['ws_stats'] = wsStack;
                state.update = Date.now();
            }
            return {...state};
        }
    }),

    list: createReducer([], {
        [TRACE_LIST_RECEIVED]: (state, opts) => {
            if (opts.status && Array.isArray(opts.items)) {
                return opts.items;
            }
            return {...state};
        },
      
    }),

    info: createReducer([] , {
        [RECEIVED_TRACE_INFO]: (state, opts) => {
            if (typeof opts.infoInflight != "undefined")
                state.infoInflight = opts.infoInflight;
            if (opts) {
                state.info = opts
            }

            return { ...state }
        }

    })

})