import React, { useState } from "react";
import { useContext } from "react";
import AppContext, { ContextProps } from "core/components/wrapper/context";

import StepItem from "../../components/steps/step-item";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import { getTenantUrl } from "core/application/utils";
import Button from "core/components/v2/button";
import { Link } from "react-router-dom";

interface ViewYourDataProps {
  language: string;
  stepNumber: number;
}

const ViewYourData: React.FC<ViewYourDataProps> = ({
  language,
  stepNumber,
}) => {
  const [appEnv, setAppEnv] = useState<number>(1);
  const [appBehaviour, setAppBehaviour] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];

  return (
    <>
      <StepItem title="View Your Data" stepNumber={stepNumber}>
        Once you have completed the {language} APM installation, wait 3-5
        minutes before accessing your data. Access your Trace, Log, and
        Profiling data by clicking on one of the buttons below. Proceed to
        deploy your project on Kubernetes.
        <br />
        <br />
        <div className="view-data-button-container">
          <Link key={"traces"} className="button-link" to={`#`}>
            <Button onClick={() => window.open("/apm/services", "_blank")}>
              Traces
            </Button>
          </Link>
          <Link key={"logs"} className="button-link" to={`#`}>
            <Button onClick={() => window.open("/logs", "_blank")}>Logs</Button>
          </Link>
          <Link key={"profiling"} className="button-link" to={`#`}>
            <Button onClick={() => window.open("/apm/profiling", "_blank")}>
              Profiling
            </Button>
          </Link>
        </div>
      </StepItem>
    </>
  );
};
export default ViewYourData;
