import React from 'react';
import Command from 'views/modules/installation-v2/components/code-block/command';
import MwMessage from '../../components/message'
import { Message } from "core/components/v2/enums";

const PostgresDatabaseConfigGuide: React.FC = () => {
      return (
        <div style={{ fontFamily: 'monospace', margin: '20px' }}>
          <p>First of all login to postgres shell as root user.</p>

          <p>Ensure <b>pg_stat_statements</b> is working. This view shows the statistics about the statements executed by the postgres engine.
          For this we need to modify a config file. You can find that file at: 
          <b> /etc/postgresql/14/main/postgresql.conf</b></p> 
          <p>Add/Uncomment:</p>
          <Command command={`shared_preload_libraries = 'pg_stat_statements'
    pg_stat_statements.track = all
    pg_stat_statements.max = 10000
    track_io_timing = on`} />

          <p>With root user logged in:</p>
          <Command command="ALTER SYSTEM SET shared_preload_libraries = 'pg_stat_statements';" />

          <p>Restart the server:</p>
          <Command command="sudo systemctl restart postgresql" />

          <p>To verify configuration, as a superuser, execute:</p>
          <Command command={`CREATE EXTENSION IF NOT EXISTS pg_stat_statements;
    SELECT calls, query FROM pg_stat_statements LIMIT 1;`} />

          <p>Expected output:</p>
      <Command command = {`calls | query
-------+-------
      8 | SELECT * FROM t WHERE field = ?`} />
      <MwMessage
        type={Message.Info}
        message="This result may have different data as this is info about queries that you have run."
      ></MwMessage>

          <h5>Make a least privileged user and use it to query <b>pg_stat_statements</b></h5>
          <Command command="CREATE USER lpu WITH PASSWORD 'pass';" />

          <p>Check the new user:</p>
          <Command command="\\du" />

          <p>Expected output:</p>
          <Command command={`Role name | Attributes | Member of
-----------+------------+------------
     lpu       |            | {}`}/>

          <p>Grant permissions and allow the new user to connect to the database and read stats:</p>
          <Command command={`GRANT CONNECT ON DATABASE <your db name> TO lpu;
    GRANT SELECT ON pg_stat_statements TO lpu;
    GRANT pg_read_all_stats TO lpu;`} />

          <p>Login and Query as New User</p>
          <Command command={`SELECT calls, query FROM pg_stat_statements LIMIT 1;`} />

          <p>Expected output:</p>
          <Command command={`calls | query
-------+-----------------------------------------
         3 | SELECT +
           | queryid, +
           | calls, +
           | total_exec_time +
           | FROM pg_stat_statements`}/>
          <MwMessage
        type={Message.Info}
        message="The displayed results may vary as they reflect the queries you have run"
      ></MwMessage>
        </div>
      );
    }

export default PostgresDatabaseConfigGuide;