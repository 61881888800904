/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/** @format */
/**
 * External dependencies
 */
import * as React from "react";
import classNames from "classnames";
import PopoverMenu from "../popover/menu";
import PopoverMenuItem from "../popover/menu-item";
import FormInputCheckbox from "../form/form-checkbox";
import { CloseSvg, DownArrowSvg } from "core/components/svg/icons";
import { escapeHtml } from "core/application/utils";

interface Props {
  label?: string;
  placeholder?: string;
  labelField?: any;
  valueField?: any;
  selectedField?: string;
  noDataLabel?: string;
  onChange?: (e: any) => void;
  onClick?: (e: any) => void;
  multiple?: boolean;
  disabled?: boolean;
  showOnlySelectedIcon?: boolean;
  search?: boolean;
  onSearch?: (e: any) => void;
  options: any;
  value?: any;
  addOption?: boolean;
  addOptionOnlyOnClick?: boolean;
  hideInputValidation?: boolean;
  isError?: any;
  isValid?: any;
  removeSelectedValue?: boolean;
  showFieldOnSelected?: string;
  popOverClass?: string;
  popoverMaxWidth?: string;
  checkbox?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onClose?: (e: any) => void;
  selectAllOption?: boolean;
  isPopOverDisabled?: boolean;
  onDelete?: (e: any) => void;
  onValueSelect?: (e: any) => void;
  spanClass?: string;
  selectAllLabel?: string;
}
interface States {
  filter?: string;
  isOpen?: boolean;
  hasVale?: boolean;
  positionTop?: boolean;
  hide_menu?: boolean;
  selectAll?: boolean;
}
class Dropdown extends React.Component<Props, States> {
  static defaultProps = {
    multiple: false,
    disabled: false,
    addOption: false,
    search: false,
    removeSelectedValue: false,
    valueField: "value",
    labelField: "label",
    selectedField: "selected",
    noDataLabel: "No record found",
    showFieldOnSelected: "",
  };

  hasItemObject = false;
  items = [];
  itemVal?: any = [];
  onStateChanges = false;
  toggleContainer?: any;
  refs: any;
  ref?: any;
  searchField?: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      filter: "",
      isOpen: false,
      hasVale: false,
      positionTop: false,
      selectAll: false,
    };
    this.toggleContainer = React.createRef();
    this.searchField = React.createRef();
    this.onClickOutsideHandler = this.onClickOutsideHandler.bind(this);
  }

  componentDidMount() {
    window.addEventListener("click", this.onClickOutsideHandler);
    window.addEventListener("keydown", this.onClickOutsideHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onClickOutsideHandler);
    window.removeEventListener("keydown", this.onClickOutsideHandler);
  }

  onClickHandler(e: any) {
    let on_appearance =
      this.state.hide_menu &&
      e.target &&
      e.target.classList &&
      (e.target.classList.value || "").indexOf("appearance-icon") !== -1;
    const isOpen = on_appearance ? false : !this.state.isOpen;

    if (!this.props.disabled) {
      if (isOpen) this.searchField.current.focus();

      this.setState({
        isOpen,
        hide_menu: false,
      });
      if (typeof this.props.onClick === "function") {
        this.props.onClick(e);
      }
    }
    if (
      this.searchField.current &&
      this.searchField.current.classList.contains("focus-visible")
    ) {
      this.setState({
        isOpen: true,
      });
    }
  }

  onClickOutsideHandler(event: any) {
    let dropdownPopover = document.querySelector(
      ".dropdown-popover-option-body"
    );
    if (
      dropdownPopover != null &&
      dropdownPopover != undefined &&
      (dropdownPopover.classList.contains("is-top-right") ||
        dropdownPopover.classList.contains("is-top") ||
        dropdownPopover.classList.contains("is-top-left"))
    ) {
      this.setState({ positionTop: true });
    } else {
      this.setState({ positionTop: false });
    }
    if (event.keyCode && event.keyCode === 9 && this.state.isOpen) {
      this.searchField.current.value = "";
      this.setState({ isOpen: false, filter: "" });
    }
    if (
      this.state.isOpen &&
      !this.toggleContainer.current.contains(event.target)
    ) {
      this.searchField.current.value = "";
      this.setState({ isOpen: false, filter: "" });
    }
  }

  setOptionFormat() {
    this.hasItemObject = false;
    let options = this.props.options;
    let value = [];
    this.items = [];
    this.itemVal = [];

    if (this.props.value) {
      if (this.props.value.constructor === String) value = [this.props.value];
      if (this.props.value.constructor === Array) value = this.props.value;
      if (this.props.value.constructor === Number) value = [this.props.value];
    }

    let _val = (this.searchField?.current?.value || "").trim().toString();
    let urlRegex = /(https?:\/\/[^\s]+)/g;

    if (urlRegex.test(_val) === true) {
      _val = _val.replace(/[\+\*\(\)\{\}\[\]\(\)\?]/g, "");
    } else {
      _val = _val.replace(/[\//\/+\/*\(\)\{\}\[\]\(\)\?]/g, "");
    }

    let final_options = [];
    if (options && options.length > 0) {
      for (let op of options) {
        let _obj: any = {};
        _obj.value = "";
        _obj.label = "";
        _obj.selected = false;
        _obj.disabled = false;
        _obj.filter = true;
        _obj.icon = "";
        _obj.iconType = "";
        _obj.name = "";

        if (typeof op === "string" || typeof op === "number") {
          _obj.value = op;
          _obj.label = op;
          _obj.selected = false;
        }

        if (op.constructor === Object) {
          if (
            this.props.valueField != undefined &&
            typeof op[this.props.valueField] !== "undefined"
          )
            _obj.value = (op[this.props.valueField] || "").toString();
          if (
            this.props.labelField != undefined &&
            typeof op[this.props.labelField] !== "undefined"
          )
            _obj.label = op[this.props.labelField];
          if (
            this.props.selectedField != undefined &&
            typeof op[this.props.selectedField] === "undefined"
          )
            _obj.selected = false;
          if (typeof op.icon !== "undefined") _obj.icon = op.icon;
          if (typeof op.iconType !== "undefined") _obj.iconType = op.iconType;
          if (typeof op.disabled !== "undefined") _obj.disabled = op.disabled;
          if (typeof op.name !== "undefined") _obj.name = op.name;
          this.hasItemObject = true;
        }

        if (this.props.multiple && value && value.indexOf(_obj.value) !== -1) {
          _obj.selected = true;
        } else {
          if (value && value.indexOf(_obj.value) !== -1) {
            _obj.selected = true;
            value = [_obj.value];
          }
        }

        if (typeof op === "string") {
          let _match = _obj.label.match(new RegExp(_val, "gi"));
          _match = _match !== null;
          if (!_match) _match = _obj.value.match(new RegExp(_val, "gi"));
          _match = _match !== null;
          _obj.filter = _match;
        }

        final_options.push(_obj);
      }
    }

    this.items = Object.assign(final_options);
    this.itemVal = Object.assign(value);
  }

  formSearch() {
    this.onStateChanges = true;
    let _newItem: any = [];
    let _val = (this.searchField.current.value || "").trim().toString();
    let urlRegex = /(https?:\/\/[^\s]+)/g;

    if (urlRegex.test(_val) === true) {
      _val = _val.replace(/[\+\*\(\)\{\}\[\]\(\)\?]/g, "");
    } else {
      _val = _val.replace(/[\//\/+\/*\(\)\{\}\[\]\(\)\?]/g, "");
    }

    _val = _val.replace(/kub?e?r?n?e?t?e?s?/gi, "k8s");

    for (let op of this.items) {
      (op as any).label = (op as any).label
        ? (op as any).label + ""
        : (op as any).label;
      let _match = ((op as any).label || "").match(new RegExp(_val, "gi"));
      _match = _match !== null;
      (op as any).value = (op as any).value
        ? (op as any).value + ""
        : (op as any).value;
      if (!_match) _match = (op as any).value.match(new RegExp(_val, "gi"));
      _match = _match !== null;
      (op as any).filter = _match;
      //if (_match === true) _newItem.push(op);
      _newItem.push(op);
    }

    this.props.onSearch && this.props.onSearch(_val);

    this.items = Object.assign(_newItem);
    let _state: any = { filter: _val };
    if (_val.length > 0 && !this.state.isOpen) _state.isOpen = true;
    this.setState(_state);
  }

  addTerms() {
    let hasOp = this.props.options.find(
      (r: any) => r?.label && r.label === this.state.filter
    );
    let val = (this.state.filter || "").trim();
    //val = (this.state.filter || "").toLowerCase().replace(/\s/gi,'-');
    if (typeof hasOp === "undefined" && val !== "") {
      if (this.hasItemObject) {
        this.props.options.unshift({
          [this.props.valueField]: this.state.filter,
          [this.props.labelField]: this.state.filter,
        });
      } else {
        this.props.options.unshift(val);
      }
      this.selectOption(val, true);
    }
    this.searchField.current.value = "";
    this.setState({
      filter: "",
      // isOpen:true,
    });
  }

  selectOption(
    val: any,
    forceAdd = false,
    forceRemove = false,
    type: string = "",
    index: any = -1
  ) {
    if (this.props.multiple) {
      if (this.itemVal.indexOf(val) !== -1) {
        if (!forceAdd) {
          this.itemVal.splice(this.itemVal.indexOf(val), 1);
        }
      } else {
        // this.itemVal.push(val);
        if (!forceRemove) {
          this.itemVal.push(val);
        }
      }
    } else {
      if (
        this.props.removeSelectedValue &&
        this.itemVal &&
        this.itemVal.indexOf(val) !== -1
      )
        this.itemVal = [];
      else this.itemVal = [val];
    }

    let _newItem = [];
    for (let op of this.itemVal) {
      _newItem.push(op);
    }
    this.itemVal = Object.assign(_newItem);

    //this.refs.selectorBox.focus();
    this.searchField.current.value = "";
    if (typeof this.props.onChange === "function") {
      this.props.onChange({
        value: this.props.multiple ? this.itemVal : this.itemVal[0],
        options: this.props.options,
        type,
        index,
      });
    } else console.error("Undefined onChange function");
    this.setState({
      filter: "",
      isOpen: !!this.props.multiple,
    });
    if (this.props.selectAllOption) {
      if (this.props.options.length == this.itemVal.length) {
        this.setState({
          selectAll: true,
        });
      }
      if (
        this.props.options.length > this.itemVal.length ||
        this.props.options.length == 0
      ) {
        this.setState({
          selectAll: false,
        });
      }
    }
  }

  hideMenu = (e: any) => {
    // this.toggleMenu(false);
    if (this.props.onClose && typeof this.props.onClose === "function") {
      this.props.onClose(e);
    }
  };

  showMenu = () => this.toggleMenu(true);

  toggleMenu = (isMenuVisible: boolean) => {
    if (
      this.props.addOption &&
      !this.props.addOptionOnlyOnClick &&
      (this.state.filter || "") !== ""
    ) {
      this.addTerms();
    } else if (
      this.refs &&
      typeof this.searchField.current !== "undefined" &&
      this.searchField.current.value
    ) {
      this.searchField.current.value = "";
    } else if (
      this.props.addOption &&
      this.props.addOptionOnlyOnClick &&
      this.refs &&
      typeof this.searchField.current !== "undefined" &&
      this.searchField.current.value
    ) {
      this.searchField.current.value = "";
    }
    this.setState({
      isOpen: isMenuVisible,
      filter: "",
      hide_menu: true,
    });
  };

  popoverContext = React.createRef();
  render() {
    const { className, disabled } = this.props;
    if (!this.onStateChanges) this.setOptionFormat();
    this.onStateChanges = false;
    let hasValue = this.itemVal && this.itemVal.length > 0;
    let noDataFound = () => {
      let hasFilter = this.items.findIndex((o: any) => o.filter === true);
      return hasFilter === -1;
    };

    const rejectPopOver =
      this.props.isPopOverDisabled && this.state.filter == "";

    let _width =
      this.toggleContainer && this.toggleContainer.current
        ? this.toggleContainer.current.clientWidth - 1
        : null;

    return (
      <div className={classNames("dropdown-main_wrap", className)}>
        <div
          ref={this.toggleContainer}
          className={classNames("dropdown-components", {
            "is-open": this.state.isOpen === true,
            "is-value": !(this.state.isOpen === true) && hasValue === true,
            "is-valid": this.props.isValid,
            "is-error": this.props.isError,
            "is-disabled": disabled,
          })}
        >
          <div
            className={classNames("selected-container", {
              "only-selected-icon": this.props.showOnlySelectedIcon,
            })}
            onClick={(e) => this.onClickHandler(e)}
          >
            {!this.state.selectAll &&
              !this.state.filter &&
              hasValue &&
              this.itemVal.map((val: any, key: number) => {
                let valFind: any = this.items.find(
                  (o: any) => o.value === val.toString()
                );
                let _label: any = val;
                if (typeof valFind !== "undefined") {
                  _label = valFind.label;
                  if (
                    this.props.showFieldOnSelected &&
                    valFind[this.props.showFieldOnSelected]
                  ) {
                    _label = valFind[this.props.showFieldOnSelected];
                  }
                }
                const spanClass = this.props.spanClass || "";
                return (
                  (val || val == "0") && (
                    <span
                      onClick={() => {
                        if (this.props.onValueSelect) {
                          this.props.onValueSelect(val);
                        }
                      }}
                      key={key}
                      className={classNames({
                        "single-val": !this.props.multiple,
                        [spanClass]: spanClass,
                      })}
                    >
                      {typeof valFind !== "undefined" &&
                        typeof valFind.icon !== "undefined" &&
                        valFind.icon && (
                          <>
                            {valFind.iconType === "icon" && (
                              <span className={valFind.icon} />
                            )}
                            {valFind.iconType === "path" && (
                              <span className={"icon-val"}>
                                <img
                                  alt={""}
                                  src={valFind.icon}
                                  className={"_icons"}
                                />
                                <span>
                                  {this.props.showFieldOnSelected
                                    ? valFind[this.props.showFieldOnSelected]
                                    : valFind.value}
                                </span>
                              </span>
                            )}
                          </>
                        )}
                      {!this.props.showOnlySelectedIcon && _label}{" "}
                      {this.props.multiple && (
                        <i
                          className={"delete"}
                          onClick={(e) => {
                            if (!disabled) {
                              if (this.props.onDelete) {
                                this.props.onDelete(val);
                              }
                              this.selectOption(val, false, false, "delete");
                              e.stopPropagation();
                            }
                          }}
                        >
                          <CloseSvg />{" "}
                        </i>
                      )}{" "}
                    </span>
                  )
                );
              })}

            {this.props.selectAllOption &&
              this.state.selectAll &&
              !this.state.filter &&
              hasValue && (
                <span>
                  {this.props.selectAllLabel
                    ? "All products selected"
                    : "All Hosts selected"}
                </span>
              )}

            {!disabled && (
              <input
                type={"text"}
                autoComplete="off"
                className={classNames("search__input", {
                  pos_absolute: this.state.filter === "",
                  hidden: !this.props.search,
                })}
                onChange={(e) => {
                  const sanitizedInput = escapeHtml(e.target.value);
                  e.target.value = sanitizedInput;
                  !disabled && this.formSearch();
                }}
                onKeyUp={(e: any) => {
                  if (e.keyCode === 9 && !disabled) {
                    this.onClickHandler(e);
                  }
                  if (
                    this.props.addOption &&
                    (e.target.value || "") !== "" &&
                    e.keyCode === 13
                  ) {
                    this.addTerms();
                  }
                }}
                ref={this.searchField}
              />
            )}

            {!this.props.isPopOverDisabled && (
              <i
                className={classNames(" appearance-icon", {
                  "icon-arrow-bottom": !this.state.isOpen,
                  "icon-arrow-top": this.state.isOpen,
                })}
              >
                <DownArrowSvg />
              </i>
            )}
          </div>
          <div
            className={"popover_context"}
            ref={(this as any).popoverContext}
          />
          {typeof this.props.label !== "undefined" ? (
            <label className={"select-label"}>{this.props.label}</label>
          ) : (
            <label className={"placeholder-label"}>
              {this.props.placeholder}
            </label>
          )}
          {!rejectPopOver && (
            <PopoverMenu
              positionAdjustment={
                this.state.positionTop
                  ? { left: 9, top: -30 }
                  : { left: 9, top: 10 }
              }
              isVisible={this.state.isOpen != undefined && this.state.isOpen}
              onClose={this.hideMenu}
              position={"bottom right"}
              minWidth={_width ? `${_width}px` : ``}
              maxWidth={this.props.popoverMaxWidth}
              //maxWidth={ _width ? `${_width+80}px`: `` }
              context={this.popoverContext.current}
              className={classNames(
                "dropdown-popover-option-body",
                className ? `${className}-popover` : "",
                this.props.popOverClass ? `${this.props.popOverClass}` : ""
              )}
            >
              {this.items && (
                <>
                  {this.props.addOption && (
                    <PopoverMenuItem
                      className={classNames("add-terms", {
                        hidden: this.state.filter === "",
                      })}
                      onClick={() => {
                        if (this.state.filter !== "") this.addTerms();
                      }}
                    >
                      {"Add: `" + this.state.filter}
                    </PopoverMenuItem>
                  )}

                  {!this.props.isPopOverDisabled &&
                    this.props.selectAllOption &&
                    this.props.multiple && (
                      <PopoverMenuItem
                        className={`check-popover__menu-item ${this.state.selectAll ? "is-selected" : ""}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState(
                            {
                              selectAll: !this.state.selectAll,
                            },
                            () => {
                              this.items.forEach((item: any, index: number) => {
                                if (this.state.selectAll) {
                                  this.selectOption(item.value, true);
                                } else {
                                  this.selectOption(item.value, false, true);
                                }
                              });
                            }
                          );
                        }}
                      >
                        {" "}
                        <span className={"select-icon"}></span>
                        {this.props.selectAllLabel
                          ? this.props.selectAllLabel
                          : "Select All"}
                      </PopoverMenuItem>
                    )}
                  {!this.props.isPopOverDisabled &&
                    this.items.length > 0 &&
                    this.items.map((data: any, key: number) => {
                      let icon = "";
                      if (
                        data.iconType &&
                        ["icon", "path"].indexOf(data.iconType) !== -1 &&
                        data.icon
                      )
                        icon = data.icon;

                      // if(!this.props.checkbox){
                      //     if (data.selected) icon = "icon-check-box";
                      // }
                      if (!this.props.checkbox) {
                        return (
                          data.filter && (
                            <PopoverMenuItem
                              key={key}
                              onClick={(e) => {
                                if (!data.disabled) {
                                  if (
                                    typeof this.props.onClick === "function"
                                  ) {
                                    this.props.onClick(e);
                                  }
                                  this.selectOption(
                                    data.value,
                                    false,
                                    false,
                                    "",
                                    key
                                  );
                                  e.stopPropagation();
                                }
                              }}
                              isSelected={data.selected}
                              icon={icon}
                              imgUrl={data.iconType === "path" ? icon : ""}
                              disabled={data.disabled}
                            >
                              {data.label}
                            </PopoverMenuItem>
                          )
                        );
                      } else {
                        return (
                          data.filter && (
                            <PopoverMenuItem
                              onClick={(e) => {
                                if (!data.disabled) {
                                  if (
                                    typeof this.props.onClick === "function"
                                  ) {
                                    this.props.onClick(e);
                                  }
                                  this.selectOption(data.value);
                                  e.stopPropagation();
                                }
                              }}
                              className={"check-popover__menu-item"}
                              key={key}
                              isSelected={data.selected}
                              disabled={data.disabled}
                            >
                              <span className={"select-icon"}></span>{" "}
                              {data.label}
                            </PopoverMenuItem>
                          )
                        );
                      }
                    })}

                  {!this.props.isPopOverDisabled && noDataFound() === true && (
                    <PopoverMenuItem className={"noDataLabel"}>
                      {this.props.noDataLabel}
                    </PopoverMenuItem>
                  )}
                </>
              )}
            </PopoverMenu>
          )}
        </div>

        {!this.props.hideInputValidation && (
          <div
            className={classNames("form-input-validation", {
              "is-error": this.props.isError,
            })}
            role="alert"
          >
            {this.props.isError && <span>{this.props.isError}</span>}
            {this.props.isValid && <span>{this.props.isValid}</span>}
          </div>
        )}
      </div>
    );
  }
}
export default Dropdown;
