import * as React from "react";
import moment from "moment";
import {
  LinuxIcon,
  MacIcon,
  WindowsIcon,
} from "core/components/v2/svg/os-icons";
import {
  AwsIcon,
  DockerIcon,
  KubernetesIcon,
  RedHatIcon,
} from "core/components/v2/svg/integrations-icons";
import { CloudProviderSvg, GoogleCloudIcon } from "core/components/svg/icons";

export const getDefaultDateRange = (
  defaultMinutes = 15,
  d?: any
): Array<number> => {
  const defDate: any = d ? new Date(d) : new Date();
  const defToTs = (defDate / 1000) * 1000;
  const defFromTs =
    ((new Date(
      moment(defDate).subtract(defaultMinutes, "minutes").valueOf()
    ) as any) /
      1000) *
    1000;
  return [defFromTs, defToTs];
};

export type StatusInnerType = {
  label: string;
  type: string;
};

export type StatusType =
  | "up"
  | "down"
  | "partial_up"
  | "stop"
  | "warning"
  | "suggestion"
  | "default"
  | "info";
export const StatusBadgeTheme: { [key in StatusType]: StatusInnerType } = {
  up: {
    label: "ACTIVE",
    type: "success",
  },
  partial_up: {
    label: "ACTIVE",
    type: "success",
  },
  down: {
    label: "DOWN",
    type: "error",
  },
  stop: {
    label: "STOP",
    type: "default",
  },
  default: {
    label: "DEFAULT",
    type: "default",
  },
  info: {
    label: "INFO",
    type: "info",
  },
  warning: {
    label: "WARNING",
    type: "warning",
  },
  suggestion: {
    label: "SUGGESTION",
    type: "info",
  },
};

export type AlertType = "info" | "warning" | "critical";
export const AlertBadgeTheme: { [key in AlertType | any]: StatusInnerType } = {
  info: {
    label: "INFO",
    type: "info",
  },
  warning: {
    label: "WARNING",
    type: "warning",
  },
  critical: {
    label: "CRITICAL",
    type: "error",
  },
};

export type AlertRuleType = "ok" | "alert";
export const AlertRuleBadgeTheme: { [key in AlertRuleType]: StatusInnerType } =
  {
    ok: {
      label: "OK",
      type: "success",
    },
    alert: {
      label: "ALERT",
      type: "error",
    },
  };

export type LogType = "info" | "warn" | "error" | "debug" | "unknown";
export const LogBadgeTheme: { [key in LogType]: StatusInnerType } = {
  info: {
    label: "INFO",
    type: "info",
  },
  warn: {
    label: "WARNING",
    type: "warning",
  },
  error: {
    label: "ERROR",
    type: "error",
  },
  debug: {
    label: "DEBUG",
    type: "debug",
  },
  unknown: {
    label: "UNKNOWN",
    type: "unknown",
  },
};

export type ServiceProviderSvgType =
  | "linux"
  | "windows"
  | "aws"
  | "redhat"
  | "google_cloud"
  | "docker"
  | "kubernetes"
  | "default"
  | "unknown"
  | "GNU/Linux";
export const ServiceProviderSvgIcons: {
  [key in ServiceProviderSvgType]: JSX.Element;
} = {
  linux: <LinuxIcon />,
  aws: <AwsIcon size={20} />,
  windows: <WindowsIcon />,
  redhat: <RedHatIcon />,
  google_cloud: <GoogleCloudIcon />,
  docker: <DockerIcon />,
  kubernetes: <KubernetesIcon />,
  default: <CloudProviderSvg />,
  unknown: <CloudProviderSvg />,
  "GNU/Linux": <LinuxIcon />,
};

export type OsProviderType = "linux" | "windows" | "darwin";
export const OsProviderTypeIcons: { [key in OsProviderType]: JSX.Element } = {
  linux: <LinuxIcon />,
  windows: <WindowsIcon />,
  darwin: <MacIcon />,
};

export type AlertStatusType = "Active" | "In Progress" | "Resolved" | "Ignore";
export const AlertStatusBadgeTheme: {
  [key in AlertStatusType]: StatusInnerType;
} = {
  Active: {
    label: "ACTIVE",
    type: "info",
  },
  "In Progress": {
    label: "IN PROGRESS",
    type: "warning",
  },
  Resolved: {
    label: "RESOLVED",
    type: "success",
  },
  Ignore: {
    label: "IGNORE",
    type: "default",
  },
};

export type RuleType =
  | "Info"
  | "Warning"
  | "Critical"
  | "Failed"
  | "Alert"
  | "alert"
  | "Active";
export const RuleBadgeTheme: { [key in RuleType]: StatusInnerType } = {
  Info: {
    label: "INFO",
    type: "info",
  },
  Warning: {
    label: "WARNING",
    type: "warning",
  },
  Critical: {
    label: "CRITICAL",
    type: "error",
  },
  Failed: {
    label: "FAILED",
    type: "error",
  },
  Alert: {
    label: "ALERT",
    type: "success",
  },
  alert: {
    label: "ALERT",
    type: "success",
  },
  Active: {
    label: "ACTIVE",
    type: "info",
  },
};

export type TaskType =
  | "New"
  | "Pending"
  | "Resolved"
  | "Ignore"
  | "To Do"
  | "In Progress"
  | "Done";
export const TaskTypeBadgeTheme: { [key in TaskType]: StatusInnerType } = {
  New: {
    label: "New",
    type: "new",
  },
  Pending: {
    label: "PENDING",
    type: "pending",
  },
  Resolved: {
    label: "RESOLVED",
    type: "resolved",
  },
  Ignore: {
    label: "IGNORE",
    type: "ignore",
  },
  "To Do": {
    label: "To Do",
    type: "To Do",
  },
  "In Progress": {
    label: "In Progress",
    type: "In Progress",
  },
  Done: {
    label: "Done",
    type: "Done",
  },
};

interface UserAgentInfo {
  browser: string;
  version: string;
  os: string;
}

export const extractUserAgentInfo = (userAgent: string): UserAgentInfo => {
  const browsers: any = {
    Chrome: /Chrome\/([\d.]+)/,
    Safari: /Safari\/([\d.]+)/,
    Firefox: /Firefox\/([\d.]+)/,
    Edge: /Edge\/([\d.]+)/,
    IE: /MSIE\s([\d.]+);/,
    Opera: /Opera\/([\d.]+)/,
  };

  const osRegex = /(Windows|Linux|Mac OS X|Android|iOS) ([\d._]+)/;

  let browser = "";
  let version = "";
  let os = "";

  for (const key in browsers) {
    if (browsers.hasOwnProperty(key)) {
      const match = userAgent.match(browsers[key]);
      if (match) {
        browser = key;
        version = match[1];
        break;
      }
    }
  }

  const osMatch = userAgent.match(osRegex);
  if (osMatch) {
    os = osMatch[1] + " " + osMatch[2];
  }

  return { browser, version, os };
};

export function generateHashAndMetricColor(input: string): {
  hash: string;
  color: string;
} {
  let hash = 0;

  if (input === "") return { hash: "", color: "#ccc" };

  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = Math.abs(hash);
  }

  return {
    hash: hash.toString(),
    color: hashToColorCode(hash.toString()),
  };
}

function hashToColorCode(hash: string): string {
  hash = hash.substring(0, 6);

  let r = parseInt(hash.substring(0, 2), 16);
  let g = parseInt(hash.substring(2, 4), 16);
  let b = parseInt(hash.substring(4, 6), 16);

  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  if (luminance > 200) {
    r = Math.max(0, r - 100);
    g = Math.max(0, g - 100);
    b = Math.max(0, b - 100);
  } else {
    // Otherwise, lighten it
    r = Math.max(0, Math.floor(r + 50));
    g = Math.max(0, Math.floor(g + 50));
    b = Math.max(0, Math.floor(b + 50));
  }

  const adjustedColor = ((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1);

  return "#" + adjustedColor;
}

type StatusCode = number | string;

interface ColorMapping {
  [key: number]: string;
  [key: string]: string;
}

const colorMapping: ColorMapping = {
  100: "#1e88e5", // Continue
  101: "#1e88e5", // Switching Protocols
  200: "#4caf50", // OK
  201: "#4caf50", // Created
  204: "#4caf50", // No Content
  300: "#ff9800", // Multiple Choices
  301: "#ff9800", // Moved Permanently
  304: "#ff9800", // Not Modified
  400: "#e91e63", // Bad Request
  401: "#e91e63", // Unauthorized
  403: "#e91e63", // Forbidden
  404: "#e91e63", // Not Found
  500: "#f44336", // Internal Server Error
  502: "#f44336", // Bad Gateway
  503: "#f44336", // Service Unavailable
  "0": "#1e88e5", // OK
  "1": "#e91e63", // Canceled
  "2": "#e91e63", // Unknown
  "3": "#e91e63", // Invalid Argument
  "4": "#e91e63", // Deadline Exceeded
  "5": "#e91e63", // Not Found
  "6": "#e91e63", // Already Exists
  "7": "#e91e63", // Permission Denied
  "8": "#e91e63", // Resource Exhausted
  "9": "#e91e63", // Failed Precondition
  "10": "#e91e63", // Aborted
  "11": "#e91e63", // Out of Range
  "12": "#ff9800", // Unimplemented
  "13": "#ff9800", // Internal
  "14": "#ff9800", // Unavailable
  "15": "#ff9800", // Data Loss
  "16": "#ff9800", // Unauthenticated
};

export function getStatusColor(statusCode: StatusCode): string {
  if (Object.hasOwn(colorMapping, statusCode)) {
    return colorMapping[statusCode];
  } else {
    return "#757575";
  }
}
