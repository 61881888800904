import { isProdWithDisabledUid } from "core/application/utils";
import AppContext from "core/components/wrapper/context";
import mixpanel from "mixpanel-browser";
import { useContext } from "react";

interface MixPanelEventAttributes {
  user_name?: string;
  email?: string;
  agent_type?: string;
  integration?: string;
  installed_agent_type?: string;
  previous_page?: string;
  already_installed?: boolean;
  command_type?: string;
}

const useMixPanel = () => {
  const context = useContext(AppContext);
  const sendEvent = (
    event_name: string,
    attr?: MixPanelEventAttributes,
    register_once?: boolean
  ) => {
    const isProduction = isProdWithDisabledUid();
    if (
      isProduction &&
      (context.user?.account?.is_internal == undefined ||
        (context.user?.account?.is_internal === false &&
          context.user.env === "prod"))
    )
      if (register_once && attr) {
        mixpanel.register_once(attr);
      } else {
        if (attr) {
          mixpanel.track(event_name, attr);
        } else {
          mixpanel.track(event_name);
        }
      }
  };
  return sendEvent;
};

export default useMixPanel;
