import React from "react";
import InstallationSteps from "../../../components/steps";
import Content from "./content";

const CloudflareIntegration = () => {
  return (
    <>
      <InstallationSteps>
        <Content serverless="true" />
      </InstallationSteps>
    </>
  );
};
export default CloudflareIntegration;
