import { CustomObject, ValueType } from "../../entities/extra.entities";

// santizeWhitespace removes all whitespaces from a string and replaces them with "_".
const santizeWhitespace = (str: string) => {
  return str.replace(/\s/g, "_");
};

// UIFilterKeySep is the separator used to separate the attribute, operator, and index in the UI filter
const UIFilterKeySep = "<###>";

// getUIFilterKey generates the key for the filter based on the attribute and operator, and index
export const getUIFilterKey = (
  filter: Record<string, CustomObject>,
  idx?: number
) => {
  if (Object.keys(filter).length < 1) return "";

  const attribute = Object.keys(filter)[0];
  const operator = Object.keys(filter[attribute])[0];

  const attrOperKey = `${attribute}${UIFilterKeySep}${santizeWhitespace(operator)}`;

  return idx !== undefined
    ? `${attrOperKey}${UIFilterKeySep}${idx}`
    : attrOperKey;
};

// groupFiltersByExcludingLastKey groups the filters by the attribute without the last portion of the key
// key is separated by the UIFilterKeySep
const groupFiltersByExcludingLastKey = (
  filters: Record<string, Record<string, CustomObject>>
) => {
  const groupFilters: Record<string, Record<string, CustomObject>[]> = {};
  Object.entries(filters).forEach(([groupName, groupVal]) => {
    const keyWithoutIdx = groupName
      .split(UIFilterKeySep)
      .slice(0, -1)
      .join(UIFilterKeySep);

    groupFilters[keyWithoutIdx] = [
      ...(groupFilters[keyWithoutIdx] || []),
      groupVal,
    ];
  });

  return groupFilters;
};

// applyOperatorOnGroupFilters applies the given operator on the group filters
const applyOperatorOnGroupFilters = (
  groupFilters: Record<string, Record<string, CustomObject>[]>,
  operator: string
) => {
  let newFilters: Record<string, Record<string, CustomObject>> = {};
  Object.entries(groupFilters).forEach(([groupName, groupValues]) => {
    newFilters = {
      ...newFilters,
      [groupName]: (groupValues.length > 1
        ? {
            [operator]: groupValues,
          }
        : groupValues[0]) as Record<string, CustomObject>,
    };
  });
  return newFilters;
};

// getNewFilterIdx returns the index of the new filter
export const getNewFilterIdx = (
  filters: Record<string, Record<string, CustomObject>>,
  newFilter: Record<string, CustomObject>
) => {
  const sameFilterKeys = Object.keys(filters).filter((item) =>
    item.includes(getUIFilterKey(newFilter))
  );

  if (sameFilterKeys.length < 1) return 0;
  sameFilterKeys.sort();

  const lastIdx = sameFilterKeys.at(-1)?.split(UIFilterKeySep).at(-1);
  return lastIdx && !isNaN(parseInt(lastIdx)) ? parseInt(lastIdx) + 1 : 0;
};

// convertUIFiltersToBuilderFilters is a helper function that converts the filters from the UI structure
// to the builder structure to correctly build the query and retrieve the data.
// Example of UI Filter: {"state<###>IN<###>0":{"state":{"IN":["user","system"]}},"state<###>IN<###>1":{"state":{"IN":["wait"]}},"state<###>NOT_IN<###>0":{"state":{"NOTIN":["used"]}},"state<###>NOT_IN<###>1":{"state":{"NOTIN":["idle"]}},"cpu<###>=<###>0":{"cpu":{"=":"cpu0"}},"cpu<###>=<###>1":{"cpu":{"=":"cpu1"}},"host.id<###>=<###>0":{"host.id":{"=":"linux-user"}}}
export const convertUIFiltersToBuilderFilters = (
  filters: Record<string, Record<string, CustomObject>>
) => {
  // Group the filters by the attribute and operator
  let groupFilters = groupFiltersByExcludingLastKey(filters);
  // Apply the "OR" operator on each group
  filters = applyOperatorOnGroupFilters(groupFilters, "or");

  // Group the resulant filters by the attribute
  groupFilters = groupFiltersByExcludingLastKey(filters);
  // Apply the "AND" operator on each group
  filters = applyOperatorOnGroupFilters(groupFilters, "and");

  // Apply the "AND" operator on the resulant filters
  return {
    and: Object.values(filters).map((nf) => nf),
  } as ValueType;
};

// appendUIFilter is a helper function that appends the new filter to the exist filters
const appendUIFilter = (
  existFilter: Record<string, Record<string, CustomObject>>,
  newFilter: Record<string, CustomObject>,
  idx: number
) => ({
  ...existFilter,
  [getUIFilterKey(newFilter, idx)]: newFilter,
});

// convertBuilderFiltersToUIFilters is a helper function that converts the filters from the builder structure
// to the UI structure to make them easier to use and loop through.
// Example of Builder Filter: {"and":[{"and":[{"or":[{"state":{"IN":["user","system"]}},{"state":{"IN":["wait"]}}]},{"or":[{"state":{"NOTIN":["used"]}},{"state":{"NOTIN":["idle"]}}]}]},{"or":[{"cpu":{"=":"cpu0"}},{"cpu":{"=":"cpu1"}}]},{"host.id":{"=":"linux-user"}}]}
export const convertBuilderFiltersToUIFilters = (filters: ValueType) => {
  const existFilters = filters as Record<string, unknown>;

  // NOTE: Before the support of the multiple filters on the same attribute,
  // "and" operator were never used from the UI to create the query filters.
  // Hence, it is safe to distinguish between the old and the new filter structure.

  // Parse the filters as the new filter structure
  let newFilters: Record<string, Record<string, CustomObject>> = {};

  // Backward Compatibility: If there is no "and" filter key, that it means
  // the filter structure is old where there were no support for muliple filters
  // on the same attribute key.
  if (!Object.hasOwn(existFilters, "and")) {
    Object.entries(existFilters).forEach(([attribute, attrValue]) => {
      const flt = {
        [attribute]: attrValue,
      } as Record<string, CustomObject>;
      newFilters = appendUIFilter(newFilters, flt, 0);
    });
    return newFilters;
  }

  const andFiltersDiffAttr = existFilters.and as Record<string, unknown>[];
  andFiltersDiffAttr.forEach((andFilterDiffAttr) => {
    // In the new filter strucuture, the filters on the same attribute key
    // are applied via the "and" or "or" operator. If the filter doesn't have
    //  "and" or "or" operator that means there is only one filter on the attribute key.
    if (
      !Object.hasOwn(andFilterDiffAttr, "and") &&
      !Object.hasOwn(andFilterDiffAttr, "or")
    ) {
      const flt = andFilterDiffAttr as Record<string, CustomObject>;
      newFilters = appendUIFilter(newFilters, flt, 0);
      return;
    }

    if (Object.hasOwn(andFilterDiffAttr, "or")) {
      const orFiltersSameOpr = andFilterDiffAttr.or as Record<
        string,
        CustomObject
      >[];
      orFiltersSameOpr.forEach((orFilterSameAttr, idx) => {
        const flt = orFilterSameAttr;
        newFilters = appendUIFilter(newFilters, flt, idx);
      });
      return;
    }

    const andFiltersSameAttr = andFilterDiffAttr.and as Record<
      string,
      unknown
    >[];
    andFiltersSameAttr.forEach((andFilterSameAttr) => {
      // If the same operator is applied on the same attribute key, the filter will have
      // "or" operator. If the filter doens't have "or" operator that means there is only
      // one filter with this operator on the attribute key.
      if (!Object.hasOwn(andFilterSameAttr, "or")) {
        const flt = andFilterSameAttr as Record<string, CustomObject>;
        newFilters = appendUIFilter(newFilters, flt, 0);
        return;
      }

      const orFiltersSameOpr = andFilterSameAttr.or as Record<
        string,
        CustomObject
      >[];
      orFiltersSameOpr.forEach((orFilterSameAttr, idx) => {
        const flt = orFilterSameAttr;
        newFilters = appendUIFilter(newFilters, flt, idx);
      });
    });
  });

  return newFilters;
};
