/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from "core/components/v2/button";
import LogoLoader from "core/components/v2/loader/logo-loader";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  DeleteIcon,
  PlusIcon,
} from "core/components/v2/svg/icons";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  requestAgentTokenDetail,
  requestClientTokenDelete,
  requestClientTokenList,
} from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import JavaScriptInstallation from "views/modules/installation-v2/pages/rum/javascript-installation";
import { Application, ApplicationList } from "views/modules/rum/v2";
import "./_styles.scss";
import MobileInstallation from "./mobile-installation";

export interface App {
  name: string;
  language: string;
  token: string;
  origin: string[];
  tracePropagationTargets: string[];
}

export enum Languages {
  JavaScript = "javascript",
  IOS = "ios",
  Android = "android",
  ReactNative = "reactnative",
}

const RUMInstallation = ({ language }: { language: Languages }) => {
  const dispatch = useDispatch();

  const [activeApplication, setActiveApplication] = useState<number>();
  const [newApplicationFlag, setNewApplicationFlag] = useState(false);
  const [hasManyApplications, setHasManyApplications] = useState(false);
  const [applications, setApplications] = useState<Application[]>([]);
  const [application, setApplication] = useState<App>();
  const [loader, setLoader] = useState(true);
  const [isApplicationConfigUpdated, setIsApplicationConfigUpdated] =
    useState(false);

  const getApplicationLists = () => {
    dispatch(
      requestClientTokenList((res: ApplicationList) => {
        if (res.status) {
          if (res.items && res.items.length > 0) {
            const applicationList: Application[] = [];

            for (const item of res.items) {
              const application = item.meta_data.RawMessage;
              if (application.language.toLowerCase() === language.toString()) {
                applicationList.push({
                  name: application.name,
                  language: application.language,
                  token: item.token,
                });
              }
            }
            setApplications(applicationList);
            setHasManyApplications(applicationList.length > 4);
          } else {
            setApplications([]);
          }
          setLoader(false);
        }
      })
    );
  };

  const getApplication = (index: number) => {
    if (applications.length > 0) {
      const data = { token: applications[index].token };

      dispatch(
        requestAgentTokenDetail(data, (res: any) => {
          if (res?.data?.data) {
            const token = res.data.data.token;
            const app = res.data.data.meta_data.RawMessage;
            const application: App = {
              name: app.name,
              language: app.language,
              token: token,
              origin: app.origin,
              tracePropagationTargets: app?.tracePropagationTargets ?? [],
            };
            setApplication(application);
            setActiveApplication(index);
          }
        })
      );
    }
  };

  useEffect(() => {
    getApplicationLists();
  }, [isApplicationConfigUpdated]);

  const removeApplication = (index: number) => {
    const data = { token: applications[index].token };

    dispatch(
      requestClientTokenDelete(data, (res: any) => {
        if (res?.status) {
          const updatedApplications = applications.filter(
            (application: Application) => application.token !== data.token
          );
          setApplications(updatedApplications);

          dispatch(
            showToast("success", `${res?.message}`, {
              title: "Successfully Deleted",
            })
          );
        } else {
          dispatch(
            showToast("error", `${res?.message}`, {
              title: "Not Deleted",
            })
          );
        }
      })
    );
  };

  const addNewApplication = () => {
    setNewApplicationFlag(true);
  };

  const createApplication = (language: Languages) => {
    if (language === Languages.JavaScript) {
      return <JavaScriptInstallation />;
    } else if (language === Languages.IOS) {
      return <MobileInstallation language={Languages.IOS} />;
    } else if (language === Languages.Android) {
      return <MobileInstallation language={Languages.Android} />;
    } else {
      return <MobileInstallation language={Languages.ReactNative} />;
    }
  };

  const updateApplication = (language: Languages) => {
    if (language === Languages.JavaScript) {
      return (
        <JavaScriptInstallation
          isUpdate
          application={application}
          isApplicationConfigUpdated={isApplicationConfigUpdated}
          setIsApplicationConfigUpdated={setIsApplicationConfigUpdated}
        />
      );
    } else if (language === Languages.IOS) {
      return (
        <MobileInstallation
          language={Languages.IOS}
          isUpdate
          application={application}
          isApplicationConfigUpdated={isApplicationConfigUpdated}
          setIsApplicationConfigUpdated={setIsApplicationConfigUpdated}
        />
      );
    } else if (language === Languages.Android) {
      return (
        <MobileInstallation
          language={Languages.Android}
          isUpdate
          application={application}
          isApplicationConfigUpdated={isApplicationConfigUpdated}
          setIsApplicationConfigUpdated={setIsApplicationConfigUpdated}
        />
      );
    } else {
      return (
        <MobileInstallation
          language={Languages.ReactNative}
          isUpdate
          application={application}
          isApplicationConfigUpdated={isApplicationConfigUpdated}
          setIsApplicationConfigUpdated={setIsApplicationConfigUpdated}
        />
      );
    }
  };

  return !loader ? (
    newApplicationFlag || applications.length === 0 ? (
      <div className="rum-installation">{createApplication(language)}</div>
    ) : (
      <div className="rum-installation">
        <div className={"list-applications"}>
          <span className="active-applications">ACTIVE RUM APPLICATIONS</span>
          <div
            className="rum-applications"
            style={{
              maxHeight: hasManyApplications ? "50px" : "100%",
            }}
          >
            {applications.map((application: Application, index: number) => {
              return (
                <div
                  className="rum-application"
                  style={{
                    border:
                      activeApplication === index
                        ? "1px solid var(--color-primary)"
                        : "1px solid var(--color-neutrals-200)",
                  }}
                  key={index + "_div"}
                  onClick={() => getApplication(index)}
                >
                  <div className="step-number">
                    <span>{index + 1}</span>
                  </div>
                  <span key={index + "_input"} style={{ flexGrow: 1 }}>
                    {application.name}
                  </span>
                  <Button
                    secondary
                    error
                    shouldStopPropagation
                    onClick={() => removeApplication(index)}
                    key={index + "_button"}
                  >
                    <DeleteIcon color="var(--color-error)" />
                  </Button>
                </div>
              );
            })}
          </div>
          <div>
            <Button
              secondary
              prefixicon={<PlusIcon color="var(--color-primary)" />}
              onClick={() => addNewApplication()}
            >
              Add New Application
            </Button>
          </div>
        </div>

        <div
          className="view-more"
          onClick={() => {
            setHasManyApplications(!hasManyApplications);
          }}
        >
          {hasManyApplications ? (
            <>
              <span>{`View All ${applications.length} Applications`}</span>
              <ArrowDownIcon color="var(--color-primary)" />
            </>
          ) : (
            applications.length > 4 && (
              <>
                <span>View Less</span>
                <ArrowUpIcon color="var(--color-primary)" />
              </>
            )
          )}
        </div>
        {application && (
          <div className="edit-application">Editing RUM Application</div>
        )}
        {application && updateApplication(language)}
      </div>
    )
  ) : (
    <LogoLoader size={100} />
  );
};

export default RUMInstallation;
