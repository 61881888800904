// Log monitoring
// Monitor your infrastructure and applications logs.
// $0.3
// per 1 GB / month
const calculateLogMonitoring = (gb:number) => {
    return parseFloat((gb * 0.3).toFixed(2));
}
// Infrastructure monitoring
// See inside any stack at any scale with infrastructure monitoring
// $10
// per hosts / month
const calculateInfrastructureMonitoring = (hosts:number) => {
    return parseFloat((hosts * 10).toFixed(2));
}
// APM
// APM monitors every service , every request with end to end distributed tracing.
// $20
// per hosts / month
const calculateAPM = (hosts:number) => {
    return parseFloat((hosts * 20).toFixed(2));
}
// Synthetic monitoring
// Proactively test and improve your APIs and webpages by creating synthetic tests.
// $2
// for 10K synthetic checks
const calculateSyntheticMonitoring = (checks:number) => {
    return parseFloat(((checks/10000) * 2).toFixed(2));
}

// Database monitoring
// Track query performance using database generated metrics and traces.
// $49
// per database /month
const calcluateDatabaseMonitoring = (hosts:number) => {
    return parseFloat((hosts * 49).toFixed(2));
}
// Real user monitoring
// Monitor end to end experience of web & mobile application users with session replays.
// $1
// per 1000 sessions/month
const calculateRUM = (sessions:number) => {
    return parseFloat(((sessions/1000) * 1).toFixed(2));
}
// Serverless monitoring
// Detect and resolve performance issues in your serverless applications.
// $5
// per 1M traces/month
const calculateServerlessMonitoring = (traces:number) => {
    return parseFloat(((traces/1000000) * 5).toFixed(2));
}

const calculateTotal = (gb:number, hosts:number, checks:number, sessions:number, traces:number) => {
    const logMonitoringCost = calculateLogMonitoring(gb);
    const infrastructureMonitoringCost = calculateInfrastructureMonitoring(hosts);
    const apmCost = calculateAPM(hosts);
    const syntheticMonitoringCost = calculateSyntheticMonitoring(checks);
    const databaseMonitoringCost = calcluateDatabaseMonitoring(hosts);
    const rumCost = calculateRUM(sessions);
    const serverlessMonitoringCost = calculateServerlessMonitoring(traces);
    const totalCost = logMonitoringCost + infrastructureMonitoringCost + apmCost + syntheticMonitoringCost + databaseMonitoringCost + rumCost + serverlessMonitoringCost;
    return totalCost.toFixed(2);
}

const formatPrice = (price:number) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return formatter.format(price);
}

const sum = (...args:any) => {
    return args.reduce((total:number, num:number) => total + num, 0);
}

const getLimitExceedProducts = (productLimits:{'Apm':boolean,'Infrastructure':boolean,'Logs':boolean,'Synthetics':boolean,'Rum':boolean,'Serverless Traces':boolean,'Database':boolean}) => {

    const exceedProducts = Object.entries(productLimits)
        .filter(([product, limitOver]) => limitOver)
        .map(([product]) => product);

    const numberOfExceedProducts = exceedProducts.length;

    if (numberOfExceedProducts === 1) {
        return exceedProducts[0];
    } else if (numberOfExceedProducts === 2) {
        return `${exceedProducts[0]} and ${exceedProducts[1]}`;
    } else if (numberOfExceedProducts > 2) {
        const lastProduct = exceedProducts.pop(); // Remove the last product
        return `${exceedProducts.join(', ')} and ${lastProduct}`;
    } else {
        return '';
    }
};
const prodChargebeePlan = "MW_pay-as-you-go-USD-Monthly"
const testChargebeePlan = "MW_standard-USD-Monthly"
export {
    calculateLogMonitoring,
    calculateInfrastructureMonitoring,
    calculateAPM,
    calculateSyntheticMonitoring,
    calcluateDatabaseMonitoring,
    calculateRUM,
    calculateServerlessMonitoring,
    calculateTotal,
    sum,
    getLimitExceedProducts,
    formatPrice,
    prodChargebeePlan,
    testChargebeePlan
};