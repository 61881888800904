import CreateRequest from "core/xhr";
import {
    priceReceived,
    priceListReceived,
    subscriptionForCustomerReceived,
    invoicesReceived,
    hostedPageReceived,
    customerReceived,
    itemsPricesReceived,
    usageListReceived,
    dailyUsageReceived,
    billingCountsReceived,
    monthlyUsageReceived,
} from "./actions";
import {subscriptionParams} from "views/modules/settings/billing-usages/entity";



interface requestCustomerParams{
    id?: string
}
interface updatePaymentMethodParams{
    customer_id: string,
    gateway_account_id ?: string
}
interface createUsageParams{
    subscription_id?: string,
    usage_date?:number,
    item_price_id?:string,
    quantity:string
}
interface createSubscriptionParams{
    customer_id: string,
    item_price_id:string,
    quantity?:string
}
export const requestBillingCreateHostedPage = (data: subscriptionParams[],callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/checkout/new",
            method: 'post',
            data
        }).then( res => {
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}





export const requestCustomer = (data: requestCustomerParams,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/customer/get",
            method: 'get',
            data
        }).then( res => {
            if (res.status === 200) {
                dispatch(customerReceived(res.data))
            }else{
                dispatch(customerReceived())
            }
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const requestUpdatePaymentMethod = (data: updatePaymentMethodParams,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/payment/update",
            method: 'post',
            data
        }).then( res => {
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const requestItemPrice = (data?: any,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/price/" + data.id,
            method: 'get',
            data
        }).then( res => {
            if (res.status === 200) {
                dispatch(priceReceived(res.data))
            }else{
                dispatch(priceReceived())
            }

            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const requestItemsPriceList = (data?: any,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/price/list",
            method: 'get',
            data
        }).then( res => {
            if (res.status === 200) {
                dispatch(priceListReceived(res.data))
            }else{
                dispatch(priceListReceived())
            }
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}
export const requestSubscriptionsForCustomer = (callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/subscriptions/customer",
            method: 'get',

        }).then( res => {
            if (res.status === 200) {
                dispatch(subscriptionForCustomerReceived(res.data))
            }else{
                dispatch(subscriptionForCustomerReceived())
            }
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}
export const requestInvoices = (params?: any,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/invoices" ,
            method: 'get',
            params: typeof params == "object" ? params : {}
        }).then( res => {
            if (res.status === 200) {
                dispatch(invoicesReceived(res.data))
            }else{
                dispatch(invoicesReceived())
            }
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}
export const requestHostedPage = (data?: any,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/hostedpage/" + data.id,
            method: 'get',
            data
        }).then( res => {
            if (res.status === 200) {
                dispatch(hostedPageReceived(res.data))
            }else{
                dispatch(hostedPageReceived())
            }
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const requestUsages = (data: createUsageParams,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/usage/create",
            method: 'post',
            data
        }).then( res => {
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const requestCreateSubscription = (data: createSubscriptionParams,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/subscription/create",
            method: 'post',
            data
        }).then( res => {
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const requestUpdateBillingInfo = (data: any,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/update",
            method: 'post',
            data
        }).then( res => {
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}
export const requestDownloadInvoice = (data: any,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/invoice/download",
            method: 'post',
            data
        }).then( res => {
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}
export const requestUpdateSubscription = (data: any,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/subscription/update",
            method: 'post',
            data
        }).then( res => {
            dispatch(requestSubscriptionsForCustomer())
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const requestPauseSubscription = (data: any,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/subscription/pause",
            method: 'post',
            data
        }).then( res => {
            dispatch(requestSubscriptionsForCustomer())
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}
export const requestResumeSubscription = (data: any,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/subscription/resume",
            method: 'post',
            data
        }).then( res => {
            if (res.status === 200) {
                dispatch(requestSubscriptionsForCustomer())
            }
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const requestUpdateMwBilling = (data: any,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/mw/update",
            method: 'post',
            data
        }).then( res => {
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const requestItems = (data?: any,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/items",
            method: 'get',
            data
        }).then( res => {
            if (res.status === 200) {
                dispatch(itemsPricesReceived(res.data))
            }else{
                dispatch(itemsPricesReceived())
            }
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}
export const requestUsageListByInvoice = (data?: any,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/usages/list/" + data.id +`?sts=${data.sts}&ets=${data.ets}`,
            method: 'get',
            data
        }).then( res => {
            if (res.status === 200) {
                dispatch(usageListReceived(res.data))
            }else{
                dispatch(usageListReceived())
            }

            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const requestCollectPayment = (data: any,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/billing/hosted-page/collect-now",
            method: 'post',
            data
        }).then( res => {
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}
export const requestDailyUsages = (data?: any,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/usages",
            method: 'get',
            params: data
        }).then( res => {
            if (res.status === 200) {
                dispatch(dailyUsageReceived(res.data))
            }else{
                dispatch(dailyUsageReceived())
            }
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}

export const requestBillingCounts = (data?: any,callback?: (res?: any ) => void ) => {
    const url = "/billing/counts"
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: url,
            method: 'get',
            params: data
        }).then( res => {
            if (res.status === 200) {
                dispatch(billingCountsReceived(res.data))
            } else{
                dispatch(billingCountsReceived())
            }
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}
export const requestMonthlyUsages = (data?: any,callback?: (res?: any ) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        return CreateRequest({
            url: "/usages",
            method: 'get',
            params: data
        }).then( res => {
            if (res.status === 200) {
                dispatch(monthlyUsageReceived(res.data))
            }else{
                dispatch(monthlyUsageReceived())
            }
            if (callback) {
                callback(res)
            }
        }).catch( err => {
            console.log("list: error", err)
        });
    }
}
