import { RoutesPatterns } from "core/utils/route_schema";
import * as React from "react";
import { INFRA_MODULE_SLUG } from "views/modules/infrastructure/helper";
import "./_style.breadcrumbs.scss";

const InfrastructureMainRoutes: RoutesPatterns = {
  moduleName: "infrastructure--new",
  slug: INFRA_MODULE_SLUG,
  breadcrumbs: React.lazy(
    () => import("views/modules/infrastructure/breadcrumbs/breadcrumbs.index")
  ),
  sidebar: React.lazy(
    () => import("src/views/modules/infrastructure/sidebar/sidebar-v2.index")
  ),
  items: [
    {
      pattern: ["/:resource/:tab"],
      component: React.lazy(
        () => import("views/modules/infrastructure/infra.index")
      ),
      breadcrumbTitle: "Infrastructure",
    },
  ],
};

export default InfrastructureMainRoutes;
