import * as React from "react";
import "./_style.scss";
import {RoutesPatterns} from "core/utils/route_schema";

const AppstoreRoutes: RoutesPatterns = {
    moduleName: "appstore",
    slug: "/app-store",
    breadcrumbs: React.lazy(() => import("views/modules/appstore/breadcrumbs")),
    items: [
        {
            pattern: [
                "/",
            ],
            component: React.lazy(() => import("views/modules/appstore/index")),
            breadcrumbTitle: "App-Store",
        }

    ]
}
export default AppstoreRoutes