export const PRICE_RECEIVED = 'PRICE_RECEIVED';
export const PRICE_LIST_RECEIVED = 'PRICE_LIST_RECEIVED';
export const SUBSCRIPTION_FOR_CUSTOMER_RECEIVED = 'SUBSCRIPTION_FOR_CUSTOMER_RECEIVED';
export const INVOICES_RECEIVED = 'INVOICES_RECEIVED';
export const HOSTED_PAGE_RECEIVED = 'HOSTED_PAGE_RECEIVED';
export const CUSTOMER_RECEIVED = 'CUSTOMER_RECEIVED';
export const ITEMS_PRICES_RECEIVED = 'ITEMS_PRICES_RECEIVED';
export const USAGE_LIST_RECEIVED = 'USAGE_LIST_RECEIVED';
export const MW_DAILY_USAGE_RECEIVED = 'MW_DAILY_USAGE_RECEIVED';
export const BILLING_COUNTS_RECEIVED = 'BILLING_COUNTS_RECEIVED';
export const MW_MONTHLY_USAGE_RECEIVED = 'MW_MONTHLY_USAGE_RECEIVED';
