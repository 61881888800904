import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import AppContext from "core/components/wrapper/context";
import { getCheckIsIntegratedDetails } from "store/task_management/api";
import useQuery from "core/components/query";
import Search from "core/components/search";
import {getAgentIntegrationsList} from "store/task_management/selectors";
import {getIntegrationList} from "store/alert/api";
import {getIntegrationsList} from "store/alert/selectors";
import {IntegrationListItems} from "views/modules/integrations/entity";

let filteredIntegrationItemList: any[] = IntegrationListItems

export default function Integration() {
    const query = useQuery()
    const dispatch = useDispatch()
    const hash = window?.location?.hash?.split("/")[1]
    const [appConfig, setAppConfig] = useState<any>({})

    useEffect(() => {
        _reqGetSetting()
        dispatch(getIntegrationList())
    }, [])

    const _reqGetSetting = () => {
        dispatch(getCheckIsIntegratedDetails((res?: any) => {
            setAppConfig(res?.result || {})
        }))
    }

    const [searchTerm, setSearchTerm] = useState('');
    const handleSearch = (searchTerm: string) => {
        setSearchTerm(searchTerm);
        filteredIntegrationItemList = IntegrationListItems.filter(item =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        if (!searchTerm) {
            filteredIntegrationItemList = IntegrationListItems
            return;
        }
    };

    useEffect(() => {
        return () => {
            filteredIntegrationItemList = IntegrationListItems
        }
    }, []);

    const agentIntegrationList = useSelector(getAgentIntegrationsList)
    const integrationListData = useSelector(getIntegrationsList)

    const getApps = () => {
        let allIntegrations:string[] = []
        if (Array.isArray(integrationListData)) {
            allIntegrations = integrationListData.map((item:any) => item.integration)
        }
        for (const key in agentIntegrationList) {
            if (agentIntegrationList[key] === true) {
                allIntegrations.push(key);
            }
        }
        return allIntegrations
    }

    const installedApps = (filteredIntegrationItemList.filter(item => item.category.indexOf(hash) !== -1 && getApps().includes(item.configKey)))
    const availableApps = (filteredIntegrationItemList.filter(item => item.category.indexOf(hash) !== -1 && !getApps().includes(item.configKey)))
    return (
        <div className="all-integration-screen-container">
            <div className="integration-container">
                <Search
                    onKeyDown={(a: any, b: any) => {
                    }}
                    onSearchClose={(a: any, b: any) => {
                    }}
                    value={''}
                    onSearch={handleSearch}
                    placeholder={'Search...'}/>


                {installedApps.length > 0 && <><p className={'install-available'}>Installed</p>
                <div className="integration-row installed">
                    {
                        installedApps.map((item, index) => {
                            if (item?.disabled && !query.has("enable")) {
                                return null
                            }
                            const IntComponent: any = item.component
                            return (<IntComponent
                                key={index}
                                appKey={item.key}
                                configKey={item?.configKey || undefined}
                                {...item}
                                appConfig={appConfig}
                                reqGetSetting={() => _reqGetSetting()}
                            />)
                        })
                    }
                </div></>}

                {availableApps.length > 0 && <><p className={'install-available'}>Available</p>
                <div className="integration-row">
                    {
                        availableApps.map((item, index) => {
                            if (item?.disabled && !query.has("enable")) {
                                return null
                            }
                            const IntComponent: any = item.component
                            return (<IntComponent
                                key={index}
                                appKey={item.key}
                                configKey={item?.configKey || undefined}
                                {...item}
                                appConfig={appConfig}
                                reqGetSetting={() => _reqGetSetting()}
                            />)
                        })
                    }
                </div>
                </>}
            </div>
        </div>
    )
}