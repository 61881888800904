import * as React from "react";

interface TypographyProps {
    style?: React.CSSProperties
    className?: string
    children: any
    attrs?: any
}

const getCls = (props: TypographyProps) :string => {
    return typeof props.className === "undefined" ? "" : props.className
}

export const TypographyHeadingPrimary = function (props: TypographyProps) {
    return <div className={`typography__component typography-heading__primary ${getCls(props)}`} style={props?.style} {...props.attrs}>{props.children}</div>
}

export const TypographyHeadingSecondary = function (props: TypographyProps) {
    return <div className={`typography__component typography-heading__secondary ${getCls(props)}`} style={props?.style} {...props.attrs}>{props.children}</div>
}

export const TypographyParagraphPrimary = function (props: TypographyProps) {
    return <div className={`typography__component typography-paragraph__primary ${getCls(props)}`} style={props?.style} {...props.attrs}>{props.children}</div>
}

export const TypographyParagraphPrimary800 = function (props: TypographyProps) {
    return <div className={`typography__component typography-paragraph__primary-800 ${getCls(props)}`} style={props?.style} {...props.attrs}>{props.children}</div>
}

export const TypographyParagraphSteps = function (props: TypographyProps) {
    return <div className={`typography__component typography-paragraph__steps ${getCls(props)}`} style={props?.style} {...props.attrs}>{props.children}</div>
}

export const TypographyParagraphPrimaryBold = function (props: TypographyProps) {
    return <div className={`typography-paragraph__primary-bold ${getCls(props)}`} style={props?.style} {...props.attrs}>{props.children}</div>
}