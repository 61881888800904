export interface CalculatorItems {
  title: string;
  unit: string;
  id: string;
  value: string;
  label: string;
  price: number;
}
export interface InvoiceData {
  log_size: InvoiceProductDetail;
  infra: InvoiceProductDetail;
  apm: InvoiceProductDetail;
  rum: InvoiceProductDetail;
  serverless_traces: InvoiceProductDetail;
  synthetic: InvoiceProductDetail;
  database: InvoiceProductDetail;
  timestamp: string;
  total_cost: number;
}
export interface InvoiceProductDetail {
  cost: number;
  quantity: number;
}
export const priceFormat = (price: number) => {
  return price / 100;
};
export interface billingItems {
  name?: string;
  price?: number;
  desc?: string;
  featureDesc?: string;
  defaultSelected?: boolean;
  id?: string;
  disabled?: boolean;
}
export interface billingItemsRes {
  addons: addons;
  main_plan: string;
  plan: {
    id: string;
  };
}
export interface addons {
  apm: addonDetail;
  infra: addonDetail;
  database: addonDetail;
  log_size: addonDetail;
  logs: addonDetail;
  rum: addonDetail;
  serverless_traces: addonDetail;
  synthetic: addonDetail;
}
export interface addonDetail {
  free: number;
  id: string;
  per_charge_count: number;
  price?: number;
  unit: string;
}
export interface subscriptionParams {
  item_price_id?: string;
  unit_price?: number;
  quantity?: number;
}
export interface billingCountRequest {
  sts?: string;
  ets?: number;
  filterby?: string;
  project?: string;
}
export interface billingCountRes {
  log_size_for_range?: number;
  max_apm_hosts?: number;
  max_hosts?: number;
  rum_for_range?: number;
  rum_sessions_per_month?: number;
  serverless_for_range?: number;
  synthetics_for_range?: number;
  total_apm_hosts?: number;
  total_database_hosts?: number;
  total_hosts?: number;
  total_log_size?: number;
  total_logs?: number;
  total_rum_sessions?: number;
  total_serverless_traces?: number;
  total_synthetics?: number;
}
export interface subscriptionDetail {
  customer?: CustomerDetails;
  subscription?: {
    id: string;
    subscription_items: subscriptionItem[];
    billing_period_unit: string;
    next_billing_at: number;
  };
  card?: CardDetails;
}
export interface CardDetails {
  billing_addr1: string;
  billing_addr2: string;
  billing_city: string;
  billing_country: string;
  billing_state: string;
  billing_state_code: string;
  billing_zip: string;
  card_type: string;
  customer_id: string;
  expiry_month: number;
  expiry_year: number;
  last4: number;
}
export interface CustomerDetails {
  id: string;
  email: string;
  billing_address: BillingAddressDetails;
}
export interface BillingAddressDetails {
  city: string;
  company: string;
  country: string;
  email: string;
  first_name: string;
  last_name: string;
  line1: string;
  line2: string;
  line3: string;
  state: string;
  state_code: string;
  zip: string;
}
export interface subscriptionItem {
  item_price_id: string;
  amount: number;
  unit_price: number;
}
export interface usages {
  apm: usageDetail[] | null;
  infra: usageDetail[] | null;
  database: usageDetail[] | null;
  log_size: usageDetail[] | null;
  logs: usageDetail[] | null;
  rum: usageDetail[] | null;
  serverless_traces: usageDetail[] | null;
  synthetic: usageDetail[] | null;
}
export interface usageDetail {
  timestamp: string;
  count: number;
  date_format_timestamp: string;
  daily_cost: number;
}
export const TabEnum = {
  invoices: "invoices",
  calculator: "calculator",
  plan: "plan",
};
export interface invoiceDetails {
  id: string;
  date: number;
  paid_at: number;
  total: number;
  status: InvoiceStatus;
  customer_id: string;
  generated_at: number;
  line_items: invoiceLineItems[];
  amount_paid: number;
}
export interface invoiceList {
  invoice: invoiceDetails;
}
export interface invoiceLineItems {
  entity_id: string;
  amount: number;
  quantity: number;
}
export enum InvoiceStatus {
  Paid = "paid",
  PaymentDue = "payment_due",
  NotPaid = "not_paid",
}
export enum PricingLimit {
  Infra = "2 Hosts",
  Apm = "2 Hosts ",
  Logs = "10 GB",
  Synthetics = "20K",
  Rum = "500 Sessions",
  Serverless_Traces = "1M",
  Database = "1 Host",
}
export interface HostedPageDetails {
  data: {
    data: {
      content: {
        customer: CustomerDetails;
      };
    };
  };
  status: number;
}
