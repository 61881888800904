import { useEffect, useState } from "react";

function useIsclicked() {
  const [isAltClicked, setAltCkiced] = useState(false);
  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Alt") {
        setAltCkiced(true);
      } else {
        setAltCkiced(false);
      }
    });
    document.addEventListener("keyup", (e) => {
      if (e.key === "Alt") {
        setAltCkiced(false);
      }
    });
    window.addEventListener("blur", () => setAltCkiced(false));
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      document.removeEventListener("keydown", () => {});
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      document.removeEventListener("keyup", () => {});
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.removeEventListener("blur", () => {});
      setAltCkiced(false);
    };
  }, []);

  return { isAltClicked };
}

export default useIsclicked;
