export const RECEIVED_WIDGETS_APPS = "RECEIVED_WIDGETS_APPS";
export const RECEIVED_REPORTS = "RECEIVED_REPORTS";
export const ADD_UPDATE_REPORT = "ADD_UPDATE_REPORT";
export const DELETE_REPORT = "DELETE_REPORT";
export const IMPORT_DASHBOARD = "IMPORT_DASHBOARD";
export const RECEIVED_CUSTOM_ATTRIBUTES = "RECEIVED_CUSTOM_ATTRIBUTES";
export const RECEIVED_WIDGET_REPORTS_BUILDER_LIST =
  "RECEIVED_WIDGET_REPORTS_BUILDER_LIST";
export const UPDATE_WIDGET_REPORTS_BUILDER_LIST =
  "UPDATE_WIDGET_REPORTS_BUILDER_LIST";
export const RECEIVED_BUILDER_FORMATTED_DATA =
  "RECEIVED_BUILDER_FORMATTED_DATA";
export const RESET_BUILDER_FORMATTED_DATA = "RESET_BUILDER_FORMATTED_DATA";
export const REMOVE_ITEM_FROM_DASHBOARD = "REMOVE_ITEM_FROM_DASHBOARD";
export const RECEIVED_DATABASE_CONNECTIONS = "RECEIVED_DATABASE_CONNECTIONS";
export const REQUEST_AVAILABLE_METRICS = "REQUEST_AVAILABLE_METRICS";
export const RECEIVED_AVAILABLE_METRICS = "RECEIVED_AVAILABLE_METRICS";
export const RECEIVED_AVAILABLE_METRICS_ATTRIBUTE_VALUES =
  "RECEIVED_AVAILABLE_METRICS_ATTRIBUTE_VALUES";
export const UPDATE_DATASETS_LIST = "UPDATE_DATASETS_LIST";
export const REMOVE_ITEM_FROM_REPORT = "REMOVE_ITEM_FROM_REPORT";
export const RESET_WIDGETS_REPORTS = "RESET_WIDGETS_REPORTS";
export const WS_RECEIVED_BUILDER_FORMATTED_DATA =
  "WS_RECEIVED_BUILDER_FORMATTED_DATA";
export const RECEIVED_BUILDER_DIALOG_DATA = "RECEIVED_BUILDER_DIALOG_DATA";
export const REQUEST_AWS_NAMESPACES = "REQUEST_AWS_NAMESPACES";
export const RECEIVED_AWS_NAMESPACES = "RECEIVED_AWS_NAMESPACES";
export const RESET_WIDGET = "RESET_WIDGET";
export const LAYOUTS_UPDATE = "LAYOUTS_UPDATE";
export const REFRESH_BUILDER = "REFRESH_BUILDER";
export const REQUEST_SOURCES = "REQUEST_SOURCES";
export const RECEIVED_SOURCES = "RECEIVED_SOURCES";
export const REQUEST_ALL_WIDGETS = "REQUEST_ALL_WIDGETS";
export const RECEIVED_ALL_WIDGETS = "RECEIVED_ALL_WIDGETS";
export const RESET_RESOURCE_VIEW = "RESET_RESOURCE_VIEW";
export const RECEIVED_BUILDER_COUNT_DATA = "RECEIVED_BUILDER_COUNT_DATA";

// TODO: Create enum & replace wherever used
export const TIMESERIES_WIDGET_ID = 1;
export const BAR_WIDGET_ID = 2;
export const LIST_WIDGET_ID = 5;
export const QUERY_VALUE_WIDGET_ID = 6;
export const PIE_CHART_WIDGET_ID = 7;
export const SCATTER_PLOT_WIDGET_ID = 8;
export const TREE_CHART_WIDGET_ID = 9;
export const TOP_LIST_CHART_WIDGET_ID = 9;
export const HEATMAP_CHART_WIDGET_ID = 10;
export const HEXAGON_CHART_WIDGET_ID = 11;
export const TRACE_QUERY_VALUE_WIDGET_ID = 12;

export const RECEIVED_RESOURCE_LIST = "RECEIVED_RESOURCE_LIST";
export const RECEIVED_BUILDER_METRIC_LIST = "RECEIVED_BUILDER_METRIC_LIST";
export const RECEIVED_BUILDER_METRIC_FILTER_VALUES =
  "RECEIVED_BUILDER_METRIC_FILTER_VALUES";
export const RECEIVED_BAR_FILTERS = "RECEIVED_SIDEBAR_FILTERS";
export const RECEIVED_SIDEBAR_FILTER_VALUES = "RECEIVED_SIDEBAR_FILTER_VALUES";
export const RECEIVED_SOCKET_DATA = "RECEIVED_SOCKET_DATA";
export const REMOVE_SOCKET_DATA_BY_INDEX = "REMOVE_SOCKET_DATA_BY_INDEX";
export const RECEIVED_INFRA_METRICS = "RECEIVED_INFRA_METRICS";
