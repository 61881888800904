import LogoLoader from "core/components/v2/loader/logo-loader";
import React from "react";
const BuilderLoader = () => {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        zIndex: 1,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <LogoLoader size={60} />
    </div>
  );
};
export default BuilderLoader;
