import {COUNTRIES_LIST_ITEM_REQUEST, COUNTRIES_TIMEZONE_REQUEST} from "./constant";


export const receivedCountriesList = (arg: any = {}) => {
    return {
        type: COUNTRIES_LIST_ITEM_REQUEST,
        ...arg
    }
}


export const receivedCountriesTimezone = (arg: any = {}) => {
    return {
        type: COUNTRIES_TIMEZONE_REQUEST,
        ...arg
    }
}

