import * as React from "react";
import {
    TypographyHeadingPrimary,
    TypographyParagraphPrimary800,
    TypographyParagraphSteps
} from "core/components/typography";
import {useContext, useState} from "react";
import AppContext, {ContextProps} from "core/components/wrapper/context";
import Command from "views/modules/installation/common/command";
import Note from "views/modules/installation/common/note";
import Button from "core/components/button";
import {useDispatch} from "react-redux";
import {generateAgentConfig} from "store/settings/installation/api";
import {showToast} from "store/toast-alerts/actions";
import {getTenantUrl} from "core/application/utils";
import GoToDashboard from "../common/goto-dashboard";

export default function DockerInstallation() {
    const dispatch = useDispatch();
    const context = useContext<ContextProps>(AppContext);
    const [generatingToken, setGeneratingToken] = useState<boolean>(false);
    const generateToken = () => {
        setGeneratingToken(true);
        dispatch(generateAgentConfig((success, config) => {
            setGeneratingToken(false);
            if (success) {
                context.changeContext({
                    key: "user",
                    value: {
                        ...context.user,
                        account: {
                            ...context.user?.account,
                            agent_config: config,
                        }
                    }
                })
            } else {
                dispatch(showToast("error", "Error while generating token.Please try again."))
            }
        }))
    }
    const url = getTenantUrl(context.user);
    const capturePath = `https://${url}`;

    let command = `MW_API_KEY=${context.user?.account?.agent_config?.token} ${!capturePath ? "" : `MW_TARGET=${capturePath}:443`} bash -c "$(curl -L https://install.middleware.io/scripts/docker-install.sh)"`


    if (context.user?.misc_details?.env_type != "prod") {
        command = `MW_API_URL_FOR_CONFIG_CHECK="${capturePath}" ${command}`
    }

    return context.user ? <div className={"installation-page"}>
        <div className={"page-header"}>
            <div className={"title"}>
                <TypographyHeadingPrimary>Installing MW Agent on Host via Docker</TypographyHeadingPrimary>
            </div>
            <div className={"description"}>
                <TypographyParagraphPrimary800>Run the MW Agent to collect and analyze your Docker data. Please refer to
                    the <a href="https://docs.middleware.io/agent-installation/docker/" target={"_blank"}
                           rel={"noreferrer"}> Docker Documentation </a> for more information about the installation
                    process.</TypographyParagraphPrimary800>
            </div>
        </div>
        <div className={"page-body"}>
            <div className={"step"}>
                <div className={"details"}>
                    <div className={"count"}>
                        <span>1</span>
                    </div>
                    <div className={"detail"}>
                        <TypographyParagraphSteps>Use our easy one-step install.</TypographyParagraphSteps>
                    </div>
                </div>
                {
                    context.user?.account?.agent_config?.token ?
                        <React.Fragment>
                            <Command command={command}/>
                            <Note
                                note={"Run only one MW Agent per host or cluster. Multiple agents will cause unexpected behavior."}/>
                        </React.Fragment>
                        : <div className={"notice"}><Button loading={generatingToken} disabled={generatingToken}
                                                            onClick={generateToken}>Generate Token</Button></div>
                }
            </div>

            <GoToDashboard dashboardURL="/infrastructure/container/dashboard"/>
        </div>
    </div> : <div>Loading</div>
}