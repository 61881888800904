import React from "react";
import classNames from "classnames";
interface Props {
  onChange: (value: string) => void;
  value: string;
  label?: string;
  placeholder?: string;
  id: string;
  isError?: boolean;
  disabled?: boolean;
  className?: string;
  onBlur?: (value: string) => void;
  rows?: number;
}
const FormTextArea = (props: Props) => {
  const {
    onChange,
    value,
    id,
    label,
    placeholder,
    className,
    disabled,
    isError,
    onBlur,
    rows,
  } = props;
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };
  const handleBlur = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onBlur) {
      onBlur(event.target.value);
    }
  };
  const inputWrapperClassname = classNames("mw-input", className, {
    "is-error": isError,
    "is-disabled": disabled,
    "has-value": value.length > 0,
  });
  const inputClassname = classNames("form-input-element", className, {
    "is-error": isError,
    "is-disabled": disabled,
    "has-value": value.length > 0,
  });
  return (
    <>
      <div className={inputWrapperClassname}>
        {label && <label htmlFor={id}>{label} </label>}
        <textarea
          id={id}
          onChange={handleChange}
          placeholder={placeholder}
          value={value}
          className={inputClassname}
          onBlur={handleBlur}
          rows={rows ? rows : 1}
        ></textarea>
      </div>
    </>
  );
};
export default FormTextArea;
