import {combineReducers} from "redux";
import HostListReducers from "store/infrastructure/host-list/reducers";
import TraceReducers from "store/infrastructure/trace/reducers";
import {DATE_RANGE, INFRA_REFRESH} from "store/infrastructure/constant";
import * as SubReducer from "store/infrastructure/sub.reducers";
import { createReducer } from "core/utils/create_reducer";
export type InfrastructureCoreReducer = {
    refresh: boolean;
}
const defaultValue: InfrastructureCoreReducer = {
    refresh: false,
}
export const infrastructureCore = createReducer(defaultValue, {
    [INFRA_REFRESH]: (state: InfrastructureCoreReducer) => {
        state.refresh = !state.refresh;
        return {...state}
    }
})
export default combineReducers({
    hostList: HostListReducers,
    trace: TraceReducers,
    dateRange: DATE_RANGE,
    ...SubReducer,
    infrastructureCore,
})