import { MetricTypeEnum } from "views/modules/builder/entities/builder.entities";
import { QueryParams } from "../../routes/model";

export interface GlobalSearchProps {
  caller: string;
  placeholder?: string;
  extraBtnLabel?: string;
  extraBtnPrefixIcon?: JSX.Element;
  onExtraBtnClick?: () => void;
  extraJSXElement?: React.ReactNode;
  hideGroupByLabel?: boolean;
  allowConditionalOperators?: boolean;
  includeMetricTypes?: MetricTypeEnum[];
  extraAttributes?: OptionsType[];
  customFilters?: QueryParams;
  isCustom?: boolean;
  onChange?: (e: QueryParams) => void;
  uniqueId: string;
  handleRunQuery?: (config: string) => void
  regexSearch?: string;
}
export interface GlobalSearchState {
  searchParams: GlobalSearchParams;
  searchActions: MwRouteActions;
}
export interface GlobalSearchParams {
  search?: string;
}
export interface MwRouteActions {
  handleSearch: (search: string) => void;
}

export type MetricsType = Record<
  string,
  {
    count?: string | number;
    input?: string | number;
    inputLen?: string | number;
    label?: string;
    type?: string | number;
    values?: string[];
  }
>;

export enum SelectionType {
  Attributes = "ATTRIBUTES",
  Operator = "OPERATOR",
  Value = "VALUE",
}

export interface OptionsType {
  value: string;
  label: string;
}
