import React, { useMemo } from "react";
import { Span } from "../modal/apm.modals";
import FlamegraphRow from "./components/FlamegraphRow";
import FlameGraphTicks from "./components/ticks";
import getSpanRows from "./utils";
import "./_style.scss"

type Props = {
    width: number;
    spans: Span[];
    onTraceClick: (arg0: Span) => void
};

const Flamegraph = ({ width, spans, onTraceClick }: Props) => {
    const {
        minPresentationalSpanDuration,
        minStartTimeNs,
        maxEndTimeNs,
        spanBitMap,
        spanRows,
    } = useMemo(() => {
        return getSpanRows(spans, width);
    }, [width, spans]);

    return (
        <div className="flamegraph-inner">
            <FlameGraphTicks
                numTicks={10}
                totalDuration={maxEndTimeNs - minStartTimeNs}
                parentWidth={width}
            />
            <div className="flamegraph-rows">
                {spanRows.map((row, index) => {
                    return (
                        <FlamegraphRow
                            key={index}
                            spans={row}
                            spanBitMap={spanBitMap}
                            minStartTimeNs={minStartTimeNs}
                            minPresentationalSpanDuration={minPresentationalSpanDuration}
                            maxEndTimeNs={maxEndTimeNs}
                            width={width}
                            onTraceClick={onTraceClick}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Flamegraph;
