import React from "react";
import InstallationSteps from "../../../components/steps";
import NavTab from "core/components/v2/nav-tab";
import Content from "./content";
import TabItems from "core/components/v2/nav-tab/items";

interface JavaIntegrationProps {
  serverlessSupport: boolean;
}
const JavaIntegration: React.FC<JavaIntegrationProps> = ({
  serverlessSupport,
}) => {
  return (
    <>
      {serverlessSupport && (
        <InstallationSteps>
          <NavTab>
            <TabItems title="Serverless" key="serverless">
              <Content serverless="true" />
            </TabItems>

            <TabItems title="With Host" key="host-based">
              <Content serverless="false" />
            </TabItems>
          </NavTab>
        </InstallationSteps>
      )}
      {!serverlessSupport && (
        <InstallationSteps>
          <Content serverless="false" />
        </InstallationSteps>
      )}
    </>
  );
};
export default JavaIntegration;
