import {
  CustomWidget,
  FilterDataType,
  GLOBAL_ATTRIBUTE_FILTER,
  MetricTypeEnum,
  Query,
  SELECT_DATA_BY,
  builderFiltersInstance,
  defaultQuery,
} from "views/modules/builder/entities/builder.entities";
import { LIST_WIDGET_ID } from "./constant";

// prepareReqBodyForAttributeSearch is a helper function that prepares the request body for attribute search
export const prepareReqBodyForAttributeSearch = (
  resource: string,
  attribute: string,
  attType: MetricTypeEnum,
  fromTs: number,
  toTs: number,
  search?: string
) => {
  // Prepare the query
  const qry = JSON.parse(JSON.stringify(defaultQuery)) as Query;
  // Set the source of the data
  qry.source.name = resource;
  qry.columns = [attribute];
  // Add the search filter
  if (search) {
    let searchFilter: FilterDataType = {};

    switch (attType) {
      // Use equal operator for float values
      case MetricTypeEnum.ColumnIntNumber:
      case MetricTypeEnum.ColumnFloatNumber:
      case MetricTypeEnum.MetricNameKey: {
        const equalFilter = builderFiltersInstance.Equal(attribute, search);
        if (equalFilter) {
          searchFilter = equalFilter;
        }
        break;
      }
      // Use like operator for other type of values
      default: {
        const likeFilter = builderFiltersInstance.Like(attribute, search);
        if (likeFilter) {
          searchFilter = likeFilter;
        }
        break;
      }
    }

    qry.with?.push({
      key: GLOBAL_ATTRIBUTE_FILTER,
      value: searchFilter,
      is_arg: true,
    });
  }
  // Add the list of column to be retrieved
  qry.with?.push({
    key: SELECT_DATA_BY,
    value: [attribute],
    is_arg: true,
  });

  // Prepare the request body
  const reqBody: CustomWidget = {
    builderId: 0,
    label: "Get Attribute Values",
    scopeId: 0,
    widgetAppId: LIST_WIDGET_ID,
    builderConfig: qry,
    builderViewOptions: {},
    params: [
      { key: "from_ts", value: fromTs },
      { key: "to_ts", value: toTs },
      { key: "debug", value: "true" },
    ],
  };

  return reqBody;
};
