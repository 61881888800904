import { getTenantUrl } from "core/application/utils";
import { Message } from "core/components/v2/enums";
import FormTextInput from "core/components/v2/form/form-text-input";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import Command from "../../../components/code-block/command";
import MwMessage from "../../../components/message";
import StepItem from "../../../components/steps/step-item";

import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import APMAgentConnect from "../../common/apm-agent-connect";
import ViewYourData from "../../common/view-data-buttons";

interface ContentProps {
  serverless: string;
}

const Content: React.FC<ContentProps> = ({ serverless }) => {

  const context = useContext<ContextProps>(AppContext);
  const [appEnvShared, setAppEnvShared] = useState(1);
  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;

  interface StepFormat {
    title: string;
    content: React.ReactNode;
  }

  // Define a type for the number-to-string map
  type appEnvToMwAgentServiceMap = {
    [key: number]: string;
  };

  // Example map
  const myAppEnvToMwAgentServiceMap: appEnvToMwAgentServiceMap = {
    1: 'mw-service.mw-agent-ns.svc.cluster.local',
    2: '172.17.0.1',
    3: 'localhost',
    4: 'localhost',
  };

  const [activeServiceName, setActiveServiceName] = useState("");
  const sendEvent = useMixPanel();
  const steps: StepFormat[] = [
    {
      title: "Install Python APM package",
      content: (
        <>
          Run the following command at the root of your workspace: Make sure you
          have installed the Python version with greater than v3.8.
          <br />
          <br />
          <Command
            command={`pip install middleware-apm`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Python",
                command_type: "Install Python APM package",
              });
            }}
          />
          Check the middleware-apm was installed with the following command:
          <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Python",
                command_type: "Install Python APM package",
              });
            }}
            command={`pip list`}
            className="margin-1"
          />
        </>
      ),
    },
    {
      title: "Import Middleware Tracker",
      content: (
        <>
          Import the following code at the beginning of your project:
          <br />
          <br />
          <Command
            command={`import logging
from middleware import MwTracker
tracker=MwTracker()`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Python",
                command_type: "Import Middleware Tracker",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Install Instrumentation Packages",
      content: (
        <>
          Add the following command to your Dockerfile to reduce your
          application start up time. This command installs instrumentation
          packages by crawling through your active site packages and finding any
          libraries that support auto-instrumentation.
          <br />
          <br />
          <Command
            command={`RUN middleware-bootstrap -a install`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Python",
                command_type: "Install Instrumentation Packages",
              });
            }}
          />
        </>
      ),
    },
    {
      title:
        "Create a middleware.ini file based on the features you want to observe. service_name and access_token are required for the tracker to send data to Middleware.",
      content: (
        <>
          Make sure the middleware.ini file is saved in the same folder as your
          app.
          <br />
          <br />
          <div className="apm-service-name-block">
            <div className="apm-service-name-label">
              Add this snippet in your code{" "}
            </div>
            <FormTextInput
              id="go-service-text-input"
              // label={"Service Name"}
              className="apm-service-name-input"
              placeholder={"Service Name"}
              value={activeServiceName}
              onChange={(e: any) => setActiveServiceName(e)}
            />
          </div>
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Python",
                command_type:
                  "Create a middleware.ini file based on the features you want to observe.",
              });
            }}
            command={`# ---------------------------------------------------------------------------
# This file contains settings for the Middleware Python-APM Agent.
# ---------------------------------------------------------------------------

[middleware.common]

# The name of your application as service-name, as it will appear in the UI to filter out your data.
service_name = ${activeServiceName == "" ? "{APM-SERVICE-NAME}" : activeServiceName}

# This Token binds the Python Agent's data and profiling data to your account.
access_token = ${context.user?.account?.agent_config?.token}

${(serverless != "true" ?
  `# The service name, where Middleware Agent is running, in case of K8s.
mw_agent_service = ${myAppEnvToMwAgentServiceMap[appEnvShared]}
` : '')}

# Toggle to enable/disable distributed traces for your application.
collect_traces = true

# Toggle to enable/disable the collection of metrics for your application.
collect_metrics = true

# Toggle to enable/disable the collection of logs for your application.
collect_logs = true

# Toggle to enable/disable the collection of profiling data for your application.
collect_profiling = true
            `}
            className="margin-1"
          />
        </>
      ),
    },
    {
      title: "Enable Custom Logs (Optional)",
      content: (
        <>
          Custom logs are ones that the application does not produce by default.
          They may be generated within certain methods, or managed by a central
          logging method in your application. To get started with custom logs
          add the following code:
          <br />
          <br />
          <Command
            command={`logging.info("info sample")
logging.warning("Sample Warning Log")
logging.error("Sample Error Log.", extra={'tester': 'Alex'})`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Python",
                command_type: "Enable Custom Logs (Optional)",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Record Stack-Errors (Optional)",
      content: (
        <>
          Use tracker.record_error() method to record a stack trace when an
          error occurs:
          <br />
          <br />
          <Command
            command={`try:
    not_possible = 12/0
except ZeroDivisionError as e:
    tracker.record_error(e)`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Python",
                command_type: "Record Stack-Errors (Optional)",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Start Your Project",
      content: (
        <>
          After deploying your application, run the following command to start
          your project: If middleware.ini isn't in your project's root, set
          MIDDLEWARE_CONFIG_FILE=./path/to/middleware.ini in the below run
          command.
          <br />
          <br />
          <Command
            command={
              serverless === "true"
                ? `MW_API_KEY=${context.user?.account?.agent_config?.token} MW_TARGET=${capturePath}:443 middleware-apm run python app.py`
                : `middleware-apm run python app.py`
            }
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Python",
                command_type: "Start Your Project",
              });
            }}
          />

          <MwMessage
            type={Message.Warning}
            message="If you are using the Django framework in your Python application, skip the above command and proceed to the next section."
          ></MwMessage>
        </>
      ),
    },
  ];

  let indexOffset = 0;
  if (serverless != "true") {
    indexOffset = 2;
  }

  return (
    <>
      {serverless != "true" && <APMAgentConnect agentName="Python" setAppEnvShared={setAppEnvShared} />}
      {steps.map((step, index) => (
        <StepItem stepNumber={index + indexOffset + 1} title={step.title}>
          {step.content}
        </StepItem>
      ))}
      <ViewYourData
        language="Python"
        stepNumber={steps.length + indexOffset + 1}
      />
    </>
  );
};
export default Content;
