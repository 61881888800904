import { InfoIconSvg } from "core/components/svg/icons";
import { Size } from "core/components/v2/enums";
import StatusBadge, { StatusBadgeType } from "core/components/v2/status-badge";
import { InfoIcon } from "core/components/v2/svg/icons";
import Tooltip from "core/components/v2/tooltip";
import * as React from "react";
import {
  CardType,
  CardTypeRange,
  MwCardProps,
  ValueType,
} from "views/layouts/app/components/cards/model";
import { SubInfoRange } from "views/modules/builder/entities/builder.entities";
import "./_style.scss";

const getStatusFromValueRange = (
  value: number,
  subInfoBadgeRange: SubInfoRange[]
): SubInfoRange | undefined => {
  const range = subInfoBadgeRange.find(
    (range) => value >= range.min && value <= range.max
  );
  return range ?? undefined;
};

const getCardType = (
  value: number,
  cardTypeRange: CardTypeRange[]
): CardTypeRange | undefined => {
  const range = cardTypeRange.find(
    (range) => value >= range.min && value <= range.max
  );
  return range ?? undefined;
};

const MwCard = (props: MwCardProps) => {
  const {
    title,
    info,
    hideValue = false,
    unit,
    value,
    secondaryValue,
    subInfo,
    warning,
    error,
    success,
    valueType = ValueType.String,
    prefixicon,
    placeInfoIconOnLeft,
    children,
    subInfoBadgeRange,
    cardTypeRange,
    isError = false,
    refreshData,
  } = props;
  const cardClassName = warning
    ? CardType.warning
    : error
      ? CardType.error
      : success
        ? CardType.success
        : CardType.default;

  const cardType = getCardType(Number(value), cardTypeRange || [])?.cardType;
  const newCardType = cardType || cardClassName;
  const baseBadgeType = success
    ? StatusBadgeType.Success
    : error
      ? StatusBadgeType.Error
      : warning
        ? StatusBadgeType.Warning
        : StatusBadgeType.Info;
  const subInfoBadge = getStatusFromValueRange(
    Number(value),
    subInfoBadgeRange || []
  );
  const badgeType = subInfoBadge?.badgeType || baseBadgeType;
  const newSubInfo = subInfo ? subInfo : subInfoBadge?.subInfo;

  return (
    <>
      <div className={"mw-card-outer-wrapper"}>
        <div className={`card-wrapper ${newCardType}`}>
          <div
            className={`title-area ${placeInfoIconOnLeft ? "place-info-on-left" : ""}`}
          >
            <h6>{title}</h6>
            {info && (
              <Tooltip content={info}>
                <span className={"i-tag"}>
                  <InfoIconSvg />
                </span>
              </Tooltip>
            )}
          </div>
          <div className={"content-area"}>
            {isError ? (
              <div className="error-portion">
                <InfoIcon size={32} color="var(--color-error)" />
                <div className="error-text">
                  <span>Could not load data.</span>
                </div>
                {refreshData && (
                  <div className="retry-btn" onClick={refreshData}>
                    <span>Retry</span>
                  </div>
                )}
              </div>
            ) : (
              <>
                {prefixicon}
                {!hideValue && value && (
                  <div className={"value"}>
                    {valueType === ValueType.Badge ? (
                      <StatusBadge
                        labelText={value ?? ""}
                        size={Size.Medium}
                        type={badgeType}
                      />
                    ) : (
                      `${value}${unit ? unit : ""}`
                    )}
                    {secondaryValue && (
                      <span className="secondary">{secondaryValue}</span>
                    )}
                  </div>
                )}
                {newSubInfo && (
                  <div className={"sub-info"}>
                    <StatusBadge
                      labelText={newSubInfo}
                      size={Size.Small}
                      type={badgeType}
                    />
                  </div>
                )}
                {children}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default MwCard;
