import { User } from "core/application/model";
import { getAuthStore, getRedirectURI, removeAuthStore } from "core/utils";
import CreateRequest from "core/xhr";
import { serverErrorContext, updateAuthToken } from "store/actions";
import { initInFlight, receivedInit } from "./actions";

export const initRequest = (
  callback?: (status: boolean, inflight: boolean, res?: User) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    dispatch(initInFlight(true));
    if (callback) {
      callback(false, true);
    }
    return CreateRequest({
      url: "/account/init",
      method: "get",
      data: {},
    })
      .then((res) => {
        if (res.status === 200 && res.data.redirect_url) {
          let _path = getRedirectURI();
          if (_path !== "") {
            const q: any = new URLSearchParams(window.location.search);
            if (
              q.has("redirect_uri") &&
              q.get("redirect_uri") &&
              q.get("redirect_uri") !== ""
            ) {
              _path = decodeURIComponent(q.get("redirect_uri"));
            }
          }
          window.location.href = res.data.redirect_url + _path;
        } else {
          if (res.status == 503 && getAuthStore()) {
            dispatch(serverErrorContext(true));
          } else {
            dispatch(serverErrorContext(false));

            if (!res?.data?.user_id) {
              dispatch(updateAuthToken(""));
              removeAuthStore();
            }
          }

          if (callback) {
            res.data.apiStatus = res?.status;
            callback(true, false, res.data);
          }
          dispatch(receivedInit(res.data));
        }
      })
      .catch((err) => {
        if (callback) {
          callback(false, false);
        }
        dispatch(initInFlight(false));
      });
  };
};

export const requestInitAdditional = (
  callback: (inflight: boolean, res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    callback(true);

    return CreateRequest({
      url: "/account/init-additional",
      method: "get",
      data: {},
    })
      .then((res) => {
        callback(false);
        if (res?.data?.status && res.data?.additional) {
          callback(false, res.data.additional);
          dispatch(receivedInit({ additional: res.data.additional }));
        }
      })
      .catch((err) => {
        callback(false);
      });
  };
};

export const requestAddUpdateSetting = (
  body: any,
  callback?: (res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    dispatch(initInFlight(true));

    return CreateRequest({
      url: "/account/setting",
      method: "post",
      data: body,
    })
      .then((res) => {
        dispatch(initInFlight(false));
        if (callback) {
          callback(res.data);
        }
        if (res?.data?.key) {
          dispatch(
            receivedInit({
              updateSetting: res.data,
            })
          );
        }
      })
      .catch((err) => {});
  };
};

export const requestUploadMedia = (
  file: any,
  callback: (a0: boolean, a1?: any) => void
) => {
  const formData: any = new FormData();
  formData.append("file", file, file.name);
  return CreateRequest({
    url: "/media/save",
    method: "POST",
    data: formData,
    headers: {
      "form-data": formData,
    },
    onUploadProgress: (progressEvent) => {
      const progress = Math.round(
        (100 * progressEvent.loaded) / progressEvent.total
      );
      callback(true, { progress });
    },
  })
    .then((data) => {
      callback(true, { response: data });
    })
    .catch((error: any) => {
      console.error("upload--->", error);
      callback(true, { error });
    });
};

export const requestAgentSetting = (
  data: any,
  callback: (a0: boolean, a1?: any) => void
) => {
  const { hostId, body } = data;
  body.value = window.btoa(JSON.stringify(body.config));
  if (body.config) {
    delete body.config;
  }

  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/agent/setting/${encodeURIComponent(hostId)}`,
      method: "POST",
      data: body,
    })
      .then((res) => {
        callback(true, res?.data);
      })
      .catch((error: any) => {
        callback(false, error);
      });
  };
};
export const requestGetAgentSetting = (
  hostId: string,
  callback: (a0: boolean, a1?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/agent/setting/${encodeURIComponent(hostId)}`,
      method: "GET",
    })
      .then((res) => {
        callback(true, res.data);
      })
      .catch((error: any) => {
        callback(false, error);
      });
  };
};

export const requestAgentSettingDbHosts = (
  platform: string,
  configKey: string,
  callback: (a0: boolean, a1?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/agent/setting/db/hosts/${platform}/${configKey}`,
      method: "GET",
    })
      .then((res) => {
        callback(true, res?.data);
      })
      .catch((error: any) => {
        callback(false, error);
      });
  };
};
export const requestRemoveAgentSetting = (
  platform: string,
  configKey: string,
  callback: (a0: boolean, a1?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/agent/setting/rm/${platform}/${configKey}`,
      method: "DELETE",
    })
      .then((res) => {
        callback(true, res?.data);
      })
      .catch((error: any) => {
        callback(false, error);
      });
  };
};
export const requestProjectHosts = (
  shouldGetK8sClusters: boolean,
  callback: (a0: boolean, a1?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: `/agent/hosts?shouldGetK8sClusters=${shouldGetK8sClusters}`,
      method: "GET",
    })
      .then((res) => {
        if (res.status > 200 && res.status < 300) {
          callback(true, res?.data);
        } else {
          callback(false, res?.data);
        }
      })
      .catch((error: any) => {
        callback(false, error);
      });
  };
};
