import { BarChartData, MultiBaseGroup } from "core/components/charts/common/chart-props";
import moment from "moment";
import { GLOBAL_ATTRIBUTE_FILTER, InFilterType, LikeFilterType, Query, builderFiltersInstance, defaultQuery } from "views/modules/builder/entities/builder.entities";


const SEVERITY_COLORS : any = {
    ["INFO"]: {
        color: 'var(--color-blue-500)',
    },
    ["WARN"]: {
        color: 'var(--color-yellow-500)',
    },
    ["ERROR"]: {
        color: 'var(--color-red-500)',
    },
    ["DEBUG"]: {
        color: 'var(--color-green-500)',
    },
}

export const convertToTimeline = (logTimeLine: any, filters: any = null): any => {
    const outputData: any = {
        timestamps: {
        },
        chartGroups: {
            'INFO': { 'INFO': [] },
            'WARN': { 'WARN': [] },
            'ERROR': { 'ERROR': [] },
            'DEBUG': { 'DEBUG': [] },
        }
    };

    const timeline = logTimeLine

    if (timeline && Array.isArray(timeline) && timeline.length) {


        for (const tl of timeline) {

            const { groups } = tl;

            outputData.timestamps[tl.timestamp] = true;

            if (groups && Array.isArray(groups)) {
                const f = groups[0] || [];
                const s = groups[1] || [];

                if (f && Array.isArray(f)) {
                    for (const i in f) {

                        const level = (f[i] && typeof f[i] == 'string') ? f[i].toUpperCase() : 'INFO';

                        let value = isNaN(parseInt(s[i])) ? 0 : parseInt(s[i]);
                        if (value > 3000) value %= 3000;
                        if (outputData.chartGroups[level]) {
                            outputData.chartGroups[level][level].push({
                                timestamp: tl.timestamp,
                                value: value,
                                config: {}

                            })

                        }
                    }
                }

            }


        }

    }

    const { chartGroups } = outputData;


    if (filters) {
        if (filters.Levels && Array.isArray(filters.Levels) && filters.Levels.length) {
            Object.keys(chartGroups).forEach((sev: string) => {
                if (filters.Levels.indexOf(sev) == -1) {
                    delete chartGroups[sev];
                }
            })
        }
    }

    outputData.chartGroups = { ...chartGroups }

    return outputData;
}


export const convertToTimelineGroups = (logTimeLine: any, filters: any = null): any => {
    const outputData : {[key : string] : BarChartData[]} = {
        'INFO': [],
        'WARN': [],
        'ERROR': [],
        'DEBUG': [],

    };

    const timeline = logTimeLine

    if (timeline && Array.isArray(timeline) && timeline.length) {


        for (const tl of timeline) {

            const { groups } = tl;


            if (groups && Array.isArray(groups)) {
                const f = groups[0] || [];
                const s = groups[1] || [];

                if (f && Array.isArray(f)) {
                    for (const i in f) {

                        let level: string = (f[i] && typeof f[i] == 'string') ? f[i].toUpperCase() : 'INFO';
                        if(level.toLowerCase() === "warning") {
                            level = 'WARN'
                        }
                        if(level.toLowerCase() === "error") {
                            level = 'ERROR'
                        }
                        const x = moment(new Date(tl.timestamp));
                        const value = isNaN(parseInt(s[i])) ? 0 : parseInt(s[i]);
                        if(outputData[level]) {
                            outputData[level].push({
                                xAxis: x.format('YYYY-MM-DD HH:mm:ss'),
                                yAxis: value,
                                barColor: SEVERITY_COLORS[level].color
                            })
                        }

                    }
                }

            }


        }

    }

    if (filters) {
        if (filters.Levels && Array.isArray(filters.Levels) && filters.Levels.length) {
            Object.keys(outputData).forEach((sev: string) => {
                if (filters.Levels.indexOf(sev) == -1) {
                    delete outputData[sev];
                }
            })
        }
    }

    return outputData;
}


export const formatDate = (ts: any, type: string): any => {
    const years = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]

    const date = new Date(ts);
    switch (type) {
        case 'day_format':
            return date.getDate() + " " + years[date.getMonth()] + " " + formatAMPM(date);
        // case 'time_format': return formatAMPM(date);
        default: return '';
    }
}

const formatAMPM = (date: Date): string => {
    const hours: number = date.getHours();
    let minutes: any = date.getMinutes();
    const seconds: number = date.getSeconds();
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime: string = hours + ':' + minutes + ':' + seconds;
    return strTime;
}

export const encryptAttributes = (filters: any): any => {
    const tempFilters = { ...filters } || {};
    if (tempFilters && typeof tempFilters == 'object') {
        for (const i in tempFilters) {
            if (Array.isArray(tempFilters[i])) {
                tempFilters[i] = tempFilters[i].map((item: any) => {
                    return window.btoa(item)
                })
            }

        }
    }

    return tempFilters;
}

export const transformFilterForQB = (filterObject:any) => {
    const transformedFilterObject: {
        [key: string]: string[];
    } = {};
    for (const key in filterObject) {
        if (Object.prototype.hasOwnProperty.call(filterObject, key)) {
            let newKey = key;
            switch (key) {
                case 'Levels':
                    newKey = 'severityText';
                    break;
                case 'regex_pattern':
                    newKey = 'regex';
                    break;
                case 'text':
                    newKey = 'body';
                    break;
                default:
                    break;
            }

            transformedFilterObject[newKey] = filterObject[key];
        }
    }
    return transformedFilterObject;
}

export const getFilterInstance = (filterObj:any) => {
    const userQuery: Query = JSON.parse(JSON.stringify(defaultQuery));
    userQuery.with = [];
    const transformedFilters = transformFilterForQB(filterObj);

    const likeFiltersKeys = ['body', 'regex']
    const inFilters: InFilterType[] = [];
    const likeFilters: LikeFilterType[] = [];

    for (const key in transformedFilters) {
        if (Object.prototype.hasOwnProperty.call(transformedFilters, key)) {
            const values = transformedFilters[key];
            if (likeFiltersKeys.indexOf(key) > -1) {
                const filters = builderFiltersInstance.Like('body', values[0]);
                if (filters) {
                    likeFilters.push(filters);
                }
            } else {
                const filters = builderFiltersInstance.In(key, values);
                if (filters) {
                    inFilters.push(filters);
                }
            }
        }
    }
    
    const inFiltersAnd: any = builderFiltersInstance.And(inFilters);
    const likeFiltersAnd:any = builderFiltersInstance.Or(likeFilters); 
    let filters = undefined;
        if (inFiltersAnd && likeFiltersAnd) {
            filters = builderFiltersInstance.And([inFiltersAnd, likeFiltersAnd]);
        } else if (inFiltersAnd) {
            filters = inFiltersAnd;
        } else if (likeFiltersAnd) {
            filters = likeFiltersAnd;
        }

    // const filters = builderFiltersInstance.And([inFiltersAnd, likeFiltersAnd]);
    if (filters) {
        const fValues = builderFiltersInstance.ConvertToValueType(filters);
        if (fValues) {
            userQuery.with.push({
                key: GLOBAL_ATTRIBUTE_FILTER,
                value: fValues,
                is_arg: true,
            });
        }
    }
    const result: { key: string, value: any } = {
        key: '',
        value: {}
    }
    userQuery.with.forEach(obj => {
        if (result.value[obj.key]) {
            result.value[obj.key] = {
                ...result.value[obj.key],
                ...obj.value
            }
        } else {
            result.value[obj.key] = {
                ...obj.value
            }
        }
    });
    result.key = 'ATTRIBUTE_FILTER';  
    result.value = {... result.value[GLOBAL_ATTRIBUTE_FILTER]};      
    return result.value;
}