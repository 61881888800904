import Button from "core/components/button";
import CodeViewer from "core/components/code-viewer";
import {
  AlertsIntegrationIcon,
  ContainerizedIcon,
  CopyIconNew,
  DebAndUbuntuColorSvg,
  DockerColorSvg,
  KubernetesColorSvg,
  NativelyIcon,
  WindowsSvg,
} from "core/components/svg/icons";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "store/toast-alerts/actions";
import CommonAgentInstallation from "views/modules/installation/components/apm/common-agent-installation";
import { StepsEntity, StepsEnum } from "views/modules/integrations/entity";

export default function ScalaAPM() {
  const context = useContext<ContextProps>(AppContext);
  const isHelloBarRendered = document.querySelector(".hello-bar") !== null;
  const [stateJavaLatest, setStateJavaLatest] = useState("");
  const dispatch = useDispatch();
  const [vValue, setVValue] = useState("");

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      dispatch(showToast("success", "Copied to Clipboard !"));
    });
  }

  useEffect(() => {
    fetch(
      "https://search.maven.org/solrsearch/select?q=g:%22io.github.middleware-labs%22+AND+a:%22agent-apm-java%22&core=gav&start=0&rows=1&wt=json"
    )
      .then((response) => response.json())
      .then((data) => {
        const firstDoc = data.response.docs[0];
        if (firstDoc && firstDoc.v) {
          setVValue(firstDoc.v);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const [activeServiceName, setActiveServiceName] =
    useState("{APM-SERVICE-NAME}");

  const stackError =
    "try {\n" +
    "    ...\n" +
    "} catch (Exception e) {\n" +
    "    ...\n" +
    "    Logger.recordError(e);\n" +
    "    ...\n" +
    "}";

  const ApmLogginSnap =
    "<dependency>\n" +
    "  <groupId>io.github.middleware-labs</groupId>\n" +
    "  <artifactId>agent-apm-java</artifactId>\n" +
    `  <version>${vValue}</version>\n` +
    "</dependency>\n";

  const steps: StepsEntity = {
    deployment_type: {
      title: "Choose Deployment Type",
    },
    code: {
      title: "Getting Started with Code",
    },
    custom_logs: {
      title: "Setup Custom Logs",
    },
    see_data: {
      title: "View Your Data",
    },
  };
  useEffect(() => {
    // this api for get java latest version
    fetch(
      "https://api.github.com/repos/middleware-labs/opentelemetry-java-instrumentation/releases/latest"
    )
      .then((response) => response.json())
      .then((data) => {
        const latestTag = data.tag_name;
        const tagWithoutV = latestTag.substring(1);
        setStateJavaLatest(tagWithoutV);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  const [activeStep, setActiveStep] = useState(StepsEnum.DeploymentType);
  const [activeDeployment, setActiveDeployment] = useState("kubernetes");
  const [activeAppType, setActiveAppType] = useState("containerized");
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const MwAgentServiceK8s =
    "MW_AGENT_SERVICE=mw-service.mw-agent-ns.svc.cluster.local\n";
  const MwAgentServiceLinuxDocker = "MW_AGENT_SERVICE=172.17.0.1";

  const scala =
    'lazy val root = (project in file("."))\n' +
    "  .settings(\n" +
    "    ...\n" +
    "    (run / javaOptions) ++= Seq(\n" +
    `      "-javaagent:middleware-javaagent-${stateJavaLatest}.jar",\n` +
    `      "-Dotel.service.name=${activeServiceName == "" ? "{SCALA-APP-NAME}" : activeServiceName}"\n` +
    "    )\n" +
    "    ...\n" +
    "  )";

  const scalaCmd = `MW_API_KEY=${context.user?.account?.agent_config?.token} sbt run`;

  return (
    <>
      <div
        className={
          "markdown-body installation-docs apm-installation-doc java-doc"
        }
      >
        <div className={"apm-body"}>
          <div className={"apm-steps"}>
            {steps &&
              Object.keys(steps).map((step, index) => {
                return (
                  <div
                    key={index + 1}
                    className={`step ${activeStep === step ? "selected" : ""} ${(Object.keys(steps).indexOf(activeStep) || 0) > index ? "passed" : ""}`}
                  >
                    <div className={"count"}>
                      <span>{index + 1}</span>
                    </div>
                    <div
                      className={"detail"}
                      onClick={() => setActiveStep(step)}
                    >
                      {steps[step].title}
                    </div>
                  </div>
                );
              })}
          </div>
          <div
            className={`apm-content ${!isHelloBarRendered && "hello-bar-exists"}`}
          >
            {activeStep === StepsEnum.DeploymentType && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>
                          1. Our APMs require the host agent in order to
                          operate. To install a host agent please select from
                          the below options.{" "}
                        </span>
                        <p>
                          <span>
                            (If the host already has an agent running please{" "}
                            <span onClick={() => setActiveStep("code")}>
                              {" "}
                              skip to Getting Started with Code.
                            </span>
                            )
                          </span>
                        </p>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Run only one MW Agent. Multiple agents will cause
                          unexpected behavior.
                        </span>
                        <br />
                        <span>
                          Which platform are you hosting your Scala application?
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"button-flex"}>
                          <button
                            className={
                              activeDeployment === "kubernetes" ? "active" : ""
                            }
                            onClick={() => {
                              setActiveDeployment("kubernetes");
                              setActiveAppType("containerized");
                            }}
                          >
                            <KubernetesColorSvg /> Kubernetes
                          </button>
                          <button
                            className={
                              activeDeployment === "linux" ? "active" : ""
                            }
                            onClick={() => setActiveDeployment("linux")}
                          >
                            <DebAndUbuntuColorSvg /> Linux
                          </button>
                          <button
                            className={
                              activeDeployment === "docker" ? "active" : ""
                            }
                            onClick={() => setActiveDeployment("docker")}
                          >
                            <DockerColorSvg /> Docker
                          </button>
                          <button
                            className={
                              activeDeployment === "windows" ? "active" : ""
                            }
                            onClick={() => setActiveDeployment("windows")}
                          >
                            <WindowsSvg /> Windows
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>2. Application Behaviour Will Be</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Please choose from below, as your application will run
                          accordingly.
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"button-flex"}>
                          <button
                            className={
                              activeAppType === "containerized" ? "active" : ""
                            }
                            onClick={() => setActiveAppType("containerized")}
                          >
                            <ContainerizedIcon /> Containerized
                          </button>
                          <button
                            className={`${activeAppType === "natively" ? "active" : ""} ${activeDeployment === "kubernetes" ? "disabled" : ""}`}
                            onClick={() => {
                              if (activeDeployment !== "kubernetes") {
                                setActiveAppType("natively");
                              }
                            }}
                          >
                            <NativelyIcon /> Natively
                          </button>
                        </div>
                      </div>
                    </div>
                    {activeAppType === "containerized" && (
                      <div className={"block"}>
                        <div className={"heading"}>
                          <span>3. Setup Container Variable</span>
                        </div>
                        <div className={"desc"}>
                          <span>
                            Application running in a container require an
                            additional environment variable
                          </span>
                        </div>
                        <div className={"content"}>
                          {activeDeployment === "kubernetes" && (
                            <>
                              <div className={"docs-script"}>
                                <CodeViewer
                                  showLineNumbers={false}
                                  language={"python"}
                                >
                                  {MwAgentServiceK8s}
                                </CodeViewer>
                                <span
                                  className={"doc-copy-btn"}
                                  onClick={() =>
                                    copyToClipboard(MwAgentServiceK8s)
                                  }
                                >
                                  <CopyIconNew size={8} />
                                </span>
                              </div>
                              <span className={"sentence mini"}>
                                The default namespace for running the Middleware
                                agent is{" "}
                                <strong>
                                  mw-service.mw-agent-ns.svc.cluster.local
                                </strong>
                                .
                              </span>
                              <br />
                            </>
                          )}
                          {activeDeployment !== "kubernetes" && (
                            <>
                              <div className={"docs-script"}>
                                <CodeViewer
                                  showLineNumbers={false}
                                  language={"python"}
                                >
                                  {MwAgentServiceLinuxDocker}
                                </CodeViewer>
                                <span
                                  className={"doc-copy-btn"}
                                  onClick={() =>
                                    copyToClipboard(MwAgentServiceLinuxDocker)
                                  }
                                >
                                  <CopyIconNew size={8} />
                                </span>
                              </div>
                              <span className={"sentence mini"}>
                                The default value for{" "}
                                <strong>DOCKER_BRIDGE_GATEWAY_ADDRESS</strong>{" "}
                                is typically <strong>172.17.0.1</strong>.
                              </span>
                              <br />
                              <span className={"sentence mini"}>
                                For more detailed information, refer to the
                                official Docker&nbsp;
                                <a
                                  href={
                                    "https://docs.docker.com/network/network-tutorial-standalone/"
                                  }
                                  target={"_blank"}
                                >
                                  documentation
                                </a>
                                .
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    <div className={"block"}>
                      <CommonAgentInstallation
                        activeDeployment={activeDeployment}
                        activeAppType={activeAppType}
                      />
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button className={"disabled"}>Back</Button>
                  <Button primary onClick={() => setActiveStep("code")}>
                    Next
                  </Button>
                </div>
              </>
            )}

            {activeStep === "code" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block first-step"}>
                      <div className={"heading"}>
                        <span>1. Download JAR File:</span>
                      </div>
                      <div className={"desc jar_step_1"}>
                        <span>
                          Download the most recent{" "}
                          <a
                            href={
                              "https://github.com/middleware-labs/opentelemetry-java-instrumentation/releases"
                            }
                            target={"_blank"}
                            rel={"noreferrer"}
                          >
                            Middleware Java Agent jar file
                          </a>
                          .
                        </span>
                      </div>
                      <div className={"heading"}>
                        <span>
                          2. Update your <b>build.sbt</b> file
                        </span>
                      </div>

                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer showLineNumbers={false} language={"java"}>
                            {scala}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard(scala)}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>

                      <div className={"heading"}>
                        <span>3. Run your app</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Run the following command in your terminal to start
                          your application
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer showLineNumbers={false} language={"java"}>
                            {scalaCmd}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard(scalaCmd)}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button
                    onClick={() => setActiveStep(StepsEnum.DeploymentType)}
                  >
                    Back
                  </Button>
                  <Button primary onClick={() => setActiveStep("custom_logs")}>
                    Next
                  </Button>
                </div>
              </>
            )}
            {activeStep === "custom_logs" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>1. Enable Custom Logs (Optional)</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Custom logs are ones that the application does not
                          produce by default. They may be generated within
                          certain methods, or managed by a central logging
                          method in your application.
                        </span>
                        <br />
                        <span>
                          To get started with custom logs add the following
                          code:
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer showLineNumbers={false} language={"java"}>
                            {ApmLogginSnap}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard(ApmLogginSnap)}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer showLineNumbers={false} language={"java"}>
                            {"mvn install"}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard("mvn install")}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                        <p className={"fn-size"}>Import logger package.</p>
                        <div className={"docs-script"}>
                          <CodeViewer showLineNumbers={false} language={"java"}>
                            {
                              "import io.github.middlewarelabs.agentapmjava.Logger;"
                            }
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() =>
                              copyToClipboard(
                                "import io.github.middlewarelabs.agentapmjava.Logger;"
                              )
                            }
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                        <p className={"fn-size"}>
                          Use these functions for logging with different
                          severity levels
                        </p>
                        <div className={"docs-script"}>
                          <CodeViewer showLineNumbers={false} language={"java"}>
                            {'Logger.info("info message");\n' +
                              'Logger.debug("debug message");\n' +
                              'Logger.warn("warn message");\n' +
                              'Logger.error("error message");\n'}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() =>
                              copyToClipboard(
                                'Logger.info("info message");\n' +
                                  'Logger.debug("debug message");\n' +
                                  'Logger.warn("warn message");\n' +
                                  'Logger.error("error message");\n'
                              )
                            }
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>2. Enable Stack Errors</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Use the errorRecord method to record stack traces that
                          throw an error object:
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer showLineNumbers={false} language={"java"}>
                            {stackError}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard(stackError)}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"help"}>
                    <div className={"block"}>
                      <AlertsIntegrationIcon /> Access previous APM versions{" "}
                      <a
                        href={
                          "https://central.sonatype.com/artifact/io.github.middleware-labs/agent-apm-java/versions"
                        }
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        here
                      </a>
                      .
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button onClick={() => setActiveStep("code")}>Back</Button>
                  <Button primary onClick={() => setActiveStep("see_data")}>
                    Next
                  </Button>
                </div>
              </>
            )}
            {activeStep === "see_data" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>View Your Data</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Once you have completed the Scala installation, wait
                          3-5 minutes before accessing your data.
                        </span>
                      </div>
                      <div className={"content"}>
                        <p
                          className={"paragraph"}
                          style={{ marginTop: "10px", marginBottom: "0" }}
                        >
                          Access your Trace, Log, and Profiling data by clicking
                          on one of the buttons bellow.
                        </p>
                        <p className={"paragraph"} style={{ marginTop: "0px" }}>
                          Proceed to deploy your project on{" "}
                          {capitalizeFirstLetter(activeDeployment)}.
                        </p>
                        <div className={"buttons-group"}>
                          <Button
                            primary
                            onClick={() =>
                              window.open("/apm/overview", "_blank")
                            }
                          >
                            Traces
                          </Button>
                          <Button
                            primary
                            onClick={() => window.open("/logs", "_blank")}
                          >
                            Logs
                          </Button>
                          {activeDeployment != "windows" ? (
                            <Button
                              primary
                              onClick={() =>
                                window.open(
                                  "/apm/continuous-profiling",
                                  "_blank"
                                )
                              }
                            >
                              Profiling
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button onClick={() => setActiveStep("custom_logs")}>
                    Back
                  </Button>
                  <Button className={"disabled"}>Next</Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
