import * as React from "react";
import { RoutesPatterns } from "core/utils/route_schema";

const Installationv2Routes: RoutesPatterns = {
  moduleName: "installation",
  slug: "/installation",
  breadcrumbs: React.lazy(
    () => import("views/modules/installation-v2/breadcrumbs")
  ),

  items: [
    {
      pattern: ["/:tab/:installation"],
      component: React.lazy(
        () => import("views/modules/installation-v2/index")
      ),
    },

    {
      pattern: ["/simulate-selection"],
      component: React.lazy(
        () => import("views/modules/installation/simulate-selection")
      ),
      breadcrumbTitle: "Agent",
      helloBar: React.lazy(
        () => import("views/modules/installation/hello-bar")
      ),
    },
  ],
};
export default Installationv2Routes;
