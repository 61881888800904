import React from "react";
import "./_style.scss";
import Dialog from "core/components/v2/dialog";
import GlobalDatePicker from "../v2/global-date-picker";

interface Props {
  title?: string;
  onClose: () => void;
  children: React.ReactNode;
  isOpen: boolean;
  headerElements?: React.ReactNode;
  bodyPadding?: boolean;
  dialogContentClassName?: string;
  size?:
    | "sx"
    | "sm"
    | "md"
    | "lg"
    | "default"
    | "xxs"
    | "xxxs"
    | "xl"
    | "xxl"
    | "full";
  rightAlignedHeaderElements?: React.ReactNode;
  datePicker?: boolean;
  footer?: React.ReactNode;
}
const GlobalCenterDialog = (props: Props) => {
  const {
    title,
    isOpen,
    onClose,
    size,
    dialogContentClassName,
    headerElements,
    rightAlignedHeaderElements,
    bodyPadding = true,
    children,
    datePicker,
    footer,
  } = props;
  return (
    <Dialog
      htmlOpenClassName={"mw-global-center-dialog"}
      isOpen={isOpen}
      position={"center"}
      onClose={onClose}
      size={size}
      contentClassName={dialogContentClassName}
      verticalCenter
    >
      <div className={"global-center-dialog--wrapper"}>
        <div className={"dialog-header"}>
          <div className={"left-area"}>
            <div className={"header-body"}>
              {title && <div className={"title text-ellipsis"}>{title}</div>}
              {headerElements && (
                <div className={"body-child"}>{headerElements}</div>
              )}
            </div>
          </div>
          <div className={"right-area"}>
            {datePicker && <GlobalDatePicker />}
            {rightAlignedHeaderElements}
          </div>
        </div>
        <div
          className={`dialog-body ${bodyPadding ? "padding-body" : "no-padding-body"}`}
        >
          {children}
        </div>
        {footer && <div className={"dialog-footer"}>{footer}</div>}
      </div>
    </Dialog>
  );
};
export default GlobalCenterDialog;
