import { TimeSeriesData } from "../../../../../views/modules/builder/entities/builder.entities";
import { RenderTpl } from "../../../../../views/modules/builder/entities/tpl.entities";

export interface BuilderChartProps {
  data: TimeSeriesData;
  tooltipInBody?: boolean;
}
export interface BuilderChartPoint {
  dataKey: string;
  title: string;
  config?: RenderTpl;
  color?: string;
}
export interface LegendItem {
  title: string;
  color: string;
  selected: boolean;
  alias: string;
}
export const DefaultGroupName = "default";
