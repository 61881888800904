import { getTenantUrl } from "core/application/utils";
import FormTextInput from "core/components/v2/form/form-text-input";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import Command from "../../../components/code-block/command";
import StepItem from "../../../components/steps/step-item";
import APMAgentConnect from "../../common/apm-agent-connect";
import ViewYourData from "../../common/view-data-buttons";

interface ContentProps {
  serverless: string;
}

const Content: React.FC<ContentProps> = ({ serverless }) => {
  const [appEnv, setAppEnv] = useState<number>(1);
  const [appBehaviour, setAppBehaviour] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];
  const sendEvent = useMixPanel();
  interface StepFormat {
    title: string;
    content: React.ReactNode;
  }
  const [activeServiceName, setActiveServiceName] = useState("");
  const steps: StepFormat[] = [
    {
      title: "Install Node.js APM package",
      content: (
        <>
          Run the command below in your terminal to install Middleware's Node.js
          APM package. Make sure you have installed the Node.js version
          v12.13.0, as instrumentation is a experimental feature in Node.js, and
          supported in that release.
          <br />
          <br />
          <Command
            command={`npm install @middleware.io/node-apm --save`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Node.JS",
                command_type: "Install Node.js APM package",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Add this code snippet to the top of your server.js file",
      content: (
        <>
          <div className="apm-service-name-block">
            <FormTextInput
              id="go-service-text-input"
              // label={"Service Name"}
              className="apm-service-name-input"
              placeholder={"Service Name"}
              value={activeServiceName}
              onChange={(e: any) => setActiveServiceName(e)}
            />
          </div>
          <br />
          {serverless == "true" && (
            <Command
              command={`const tracker = require('@middleware.io/node-apm'); 
tracker.track({ 
  serviceName: "${activeServiceName == "" ? "{APM-SERVICE-NAME}" : activeServiceName}", 
  accessToken: "${context.user?.account?.agent_config?.token}", 
  target: "${capturePath}",
});`}
              className="margin-1"
              onCopyClick={() => {
                sendEvent("click on copy command", {
                  agent_type: "Node.JS",
                  command_type:
                    "Add this code snippet to the top of your server.js file",
                });
              }}
            />
          )}
          {serverless != "true" && (
            <Command
              command={`const tracker = require('@middleware.io/node-apm'); 
tracker.track({ 
  serviceName: "{APM-SERVICE-NAME}", 
  accessToken: "${context.user?.account?.agent_config?.token}", 
});`}
              className="margin-1"
              onCopyClick={() => {
                sendEvent("click on copy command", {
                  agent_type: "Node.JS",
                  command_type:
                    "Add this code snippet to the top of your server.js file",
                });
              }}
            />
          )}
        </>
      ),
    },
    {
      title: "Enable Custom Logs (Optional)",
      content: (
        <>
          Custom logs are ones that the application does not produce by default.
          They may be generated within certain methods, or managed by a central
          logging method in your application. To get started with custom logs
          add the following code: <br />
          <br />
          <Command
            command={`tracker.info('Info sample');
tracker.warn('Warning sample');
tracker.debug('Debugging Sample');
tracker.error('Error Sample');`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Node.JS",
                command_type: "Enable Custom Logs (Optional)",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Enable Stack Errors (Optional)",
      content: (
        <>
          Use the errorRecord method to record stack traces when error objects
          are caught. <br />
          <br />
          <Command
            command={`app.get('/error', function (req, res) {
    try{
        throw new Error('oh error!');
    }catch (e) {
        tracker.errorRecord(e)
    }
    res.status(500).send("wrong");
});`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Node.JS",
                command_type: "Enable Stack Errors (Optional)",
              });
            }}
          />
        </>
      ),
    },
  ];

  let indexOffset = 0;
  if (serverless != "true") {
    indexOffset = 2;
  }

  return (
    <>
      {serverless != "true" && <APMAgentConnect agentName="Node.JS" />}
      {steps.map((step, index) => (
        <StepItem stepNumber={index + indexOffset + 1} title={step.title}>
          {step.content}
        </StepItem>
      ))}

      <ViewYourData
        language="Node.js"
        stepNumber={steps.length + indexOffset + 1}
      />
    </>
  );
};
export default Content;
