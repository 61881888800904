import * as React from "react";
import NavTab from "core/components/nav-tab";
import TabItems from "core/components/nav-tab/items";
import {
    InstallationDocsIcon, JavascriptV2
} from "core/components/svg/icons";
import {Link} from "react-router-dom";
import RumSetting from "views/modules/installation/components/rum/rum-setting";

export default function JavascriptRUMInstrument() {

    return <>
        <div className={'cloudflare-apm-new dialog-table-componants'}>
            <div className={' setting_side-panel'}>
                <div className={'integration-popup'}>
                    <div className={'header-section'}>
                        <div className={'d-flex'}>
                            <JavascriptV2/>
                            <div className={'header-content'}>
                                <h3>Javascript</h3>
                                <p>JavaScript RUM is for generic web application user monitoring.</p>
                            </div>
                            <button className={"docs-link"}><Link to={'https://docs.middleware.io/rum/rum-nextjs'}
                                                                  target={'_blank'}><span> <InstallationDocsIcon/>Documentation</span></Link>
                            </button>

                        </div>
                        <NavTab targetIndex={1} defaultActiveTabIndex={1}>
                            <TabItems title={'Overview'}>
                                <div className={'d-flex in-content'}>
                                    <div className={'overview-heading'}>
                                        <div className={'config-overview'}>
                                            <h4>Category</h4>
                                            <span>Real User Monitoring</span>

                                            <h4 className={'tp-mr'}>Supported Version</h4>
                                            <img alt="Javascript RUM supported"
                                                 src="https://img.shields.io/badge/cdnjs-0.0.1-blue"/>

                                        </div>
                                    </div>
                                    <div className={'overview-content'}>
                                        <p>The JavaScript Browser RUM SDK This guide walks you through setting up Real User Monitoring (RUM) with JavaScript in the browser.</p>
                                        <div className={'overview-image'}>
                                            <img src={'assets/images/common-rum.png'}
                                                 alt={'rum-overview'}/>
                                        </div>
                                    </div>
                                </div>
                            </TabItems>
                            <TabItems title={'Installation'}>
                                <div className={'panel-body'}>
                                    <RumSetting data={'javascript'}/>
                                </div>

                            </TabItems>
                        </NavTab>
                    </div>
                </div>

            </div>
        </div>
    </>
}