import EventEmitter from "core/utils/events";

interface SocketWrapper {
    retry: () => any
    connect: (url: string | undefined, callback?: (a0: any) => void) => any
    emit: (json: any) => void
    on: (...args: any[]) => void
    off: (...args: any[]) => void
    disconnect: () => any
    ws: WebSocket | undefined
}

class SocketConnector implements SocketWrapper {
    ws: WebSocket | undefined;
    private isLogout = false;
    private retryTimer: NodeJS.Timeout | undefined;
    private retryTimeout = 1000;
    private EventListener: any = {};
    private url: string | undefined;
    constructor() {
        EventEmitter.on("testWsEmit", (data: any) => {
            this.emit(data)
        })
    }

    connect(url: string | undefined, callback?: (a0?: any) => void): any {
        if (this.ws) return;
        this.url = url;
        if (!this.url) {
            console.error("Invalid WS URL");
            return
        }
        this.ws = new WebSocket(this.url);


        //Triggered when connection is open
        this.ws.onopen = () => {
            // console.log(this.url ? "Custom WS Connected!!!" : "Ws Connected!!!");
            this.retryTimeout = 1000;
            callback && callback()
            if (this.url) return;
            this.isLogout = false;
            this.retryTimer && clearTimeout(this.retryTimer);
            this.emit({ event: "ping", data: "test", test: "ab7" })
        };

        //Triggered when a message is received
        this.ws.onmessage = (evt) => {
            try {
                const data = JSON.parse(evt.data);
                //console.log("Ws-message-received--", data)
                if (this.url && callback) callback(data)
                if (data?.event && typeof this.EventListener[data.event] === "function") {
                    if(typeof data.data === "string") {
                        try {
                            data.data = JSON.parse(data.data);
                        } catch (e) {
                            console.warn("Invalid JSON received:", data.data, e)
                        }
                    }
                    this.EventListener[data.event](data)
                }
                if (data?.payload || data?.key) {
                    if (data?.key !== "ping" && typeof this.EventListener[data?.key] === "function") {
                        let json = data?.properties?.data;
                        if(typeof json === "string") {
                            try {
                                json = JSON.parse(json);
                            } catch (e) {
                                console.warn("Invalid JSON received:", json, e)
                            }
                        }
                        this.EventListener[data.key]({
                            event: data.key,
                            data: json,
                        })
                    } else if(typeof this.EventListener[data?.key] !== "function"){
                        console.warn("No event listener found for event: ", data?.key)
                    }
                    this.emit({
                        "messageId": data.messageId,
                    })
                }
            } catch (e) {
                console.warn("Invalid data received:", evt.data, e)
            }
        };
        //Triggered when connection is closed
        this.ws.onclose = () => {
            // console.log(this.url ? "Custom WS Disconnected!!!" : "Ws Disconnected!!!");
            this.ws = undefined;
            // this.EventListener = {};
            this.retry();
        };
    }

    disconnect(): void {
        if (this.ws) {
            this.isLogout = true;
            this.ws.close();
            this.ws = undefined;
        }
    }

    emit(json: any): void {
        if (this.ws && this.ws.readyState === 1) {
            try {
                if (typeof json === "object") {
                    this.ws.send(JSON.stringify(json))
                } else {
                    console.warn("Please send valid json data in socket instead of " + typeof json)
                }
            } catch (e) {
                console.warn("Please send valid json data in socket instead of " + typeof json)
            }
        }
    }

    retry(): any {
        this.retryTimer = setTimeout(() => {
            if (!this.isLogout) {
                this.retryTimeout = this.retryTimeout + 1000;
                this.connect(this.url);
            }
        }, this.retryTimeout);
    }

    off(...args: any): void {
        console.log("off-args--", args)
    }

    on(event: string, cb: (...arg: any) => void): void {
        this.EventListener[event] = cb
    }
}

export default SocketConnector
