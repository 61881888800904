import React, {useContext, useRef, useState} from "react";
import {showToast} from "store/toast-alerts/actions";
import {useDispatch} from "react-redux";
import NavTab from "core/components/nav-tab";
import TabItems from "core/components/nav-tab/items";
import TableGridview, {GridviewColumnsOptions} from "core/components/table-gridview";
import AppContext, {ContextProps} from "core/components/wrapper/context";

export default function CustomAPIs() {
    const context = useContext<ContextProps>(AppContext);

    const [codeCollaps, setcodeCollaps] = useState();
    const toggleCodeVisibility = () => {
        // @ts-ignore
        setcodeCollaps(!codeCollaps);
    };
    const dispatch = useDispatch();

    function copyToClipboard(text: string) {

        navigator.clipboard.writeText(text)
            .then(() => {
                dispatch(showToast("success", "Copied to Clipboard !"))
            });
    }

    const dataProvider = [
        {
            type: "host",
            resource: "host.id",
            datastore: 'Host Metrics',
        },
        {
            type: "k8s.node",
            resource: "k8s.node.uid",
            datastore: 'K8s Node Metrics',
        },
        {
            type: "k8s.pod",
            resource: "k8s.pod.uid",
            datastore: 'K8s POD metrics',
        },
        {
            type: "k8s.deployment",
            resource: "k8s.deployment.uid",
            datastore: 'K8s Deployment Metrics',
        },
        {
            type: "k8s.daemonset",
            resource: "k8s.daemonset.uid",
            datastore: '~',
        },
        {
            type: "k8s.replicaset",
            resource: "k8s.replicaset.uid",
            datastore: '~',
        },
        {
            type: "k8s.statefulset",
            resource: "k8s.statefulset.uid",
            datastore: '~',
        },
        {
            type: "k8s.namespace",
            resource: "k8s.namespace.uid",
            datastore: '~',
        },
        {
            type: "service",
            resource: "service.name",
            datastore: '~',
        },
        {
            type: "os",
            resource: "os.type",
            datastore: '~',
        },
    ]

    const columns: Array<GridviewColumnsOptions> = [
        {
            title: "Type",
            accessor: "type",
            value: (data: any) => {
                return data.type
            }
        },
        {
            title: "Resource Attributes Required",
            accessor: "resource-attributes",
            value: (data: any) => {
                return data.resource
            }
        },
        {
            title: "Data will be stored to this Dataset\n",
            accessor: "data-store",
            value: (data: any) => {
                return data.datastore
            }
        },
    ]

    const currentDate = new Date();
    const unixTime = Math.floor(currentDate.getTime() / 1000) + "000";
    const customAPICodeCommon = `curl -X POST "${window.location.origin}:443/v1/metrics\" \\\n` +
        "-H \"Accept: application/json\" \\\n" +
        "-H \"Content-Type: application/json\" \\\n" +
        "-d @- << EOF\n" +
        "{\n" +
        "  \"resource_metrics\": [\n" +
        "    {\n" +
        "      \"resource\": {\n" +
        "        \"attributes\": [\n" +
        "......"


    const customAPICode = `curl -X POST "${window.location.origin}:443/v1/metrics\" \\\n` +
        "-H \"Accept: application/json\" \\\n" +
        "-H \"Content-Type: application/json\" \\\n" +
        "-d @- << EOF\n" +
        "{\n" +
        "  \"resource_metrics\": [\n" +
        "    {\n" +
        "      \"resource\": {\n" +
        "        \"attributes\": [\n" +
        "          {\n" +
        "            \"key\": \"mw.account_key\",\n" +
        "            \"value\": {\n" +
        `              \"string_value\": \"${context.user?.account?.agent_config?.token}\"\n` +
        "            }\n" +
        "          },\n" +
        "          {\n" +
        "            \"key\": \"mw.resource_type\",\n" +
        "            \"value\": {\n" +
        "              \"string_value\": \"custom\"\n" +
        "            }\n" +
        "          }\n" +
        "        ]\n" +
        "      },\n" +
        "      \"scope_metrics\": [\n" +
        "        {\n" +
        "          \"metrics\": [\n" +
        "            {\n" +
        "              \"name\": \"swap-usage\",\n" +
        "              \"description\": \"SWAP usage\",\n" +
        "              \"unit\": \"Bytes\",\n" +
        "              \"gauge\": {\n" +
        "                \"data_points\": [\n" +
        "                  {\n" +
        "                    \"attributes\": [\n" +
        "                      {\n" +
        "                        \"key\": \"device\",\n" +
        "                        \"value\": {\n" +
        "                          \"string_value\": \"nvme0n1p4\"\n" +
        "                        }\n" +
        "                      }\n" +
        "                    ],\n" +
        `                    \"time_unix_nano\": ${unixTime}\n` +
        "                    \"asInt\": 4000500678\n" +
        "                  }\n" +
        "                ]\n" +
        "              }\n" +
        "            }\n" +
        "          ]\n" +
        "        }\n" +
        "      ]\n" +
        "    }\n" +
        "  ]\n" +
        "}\n" +
        "EOF\n"
    return (
        <>
            <div className={"markdown-body aws-kiness-doc"}>
                <div className={"node-tab"}>
                    <div className={"steps"}>
                        <div className={"details"}>
                            <div className={"count"}>
                                <span>1</span>
                            </div>
                            <div className={"detail"}>
                                Post your custom data
                            </div>
                        </div>
                        <p className={'fn-size'}>You can send custom metrics to Middleware Backend Using the API given
                            below.</p>
                        <p className={'notice'}>POST {window.location.origin}:443/v1/metrics</p>
                        {codeCollaps && (
                            <pre onClick={() => {
                                copyToClipboard(customAPICode)
                            }} className={'command'}>
                                <code>{customAPICode}</code>
                            </pre>
                        )}
                        {!codeCollaps && (
                            <pre onClick={() => {
                                copyToClipboard(customAPICode)
                            }} className={'command'}>
                                <code>{customAPICodeCommon}</code>
                            </pre>

                        )}
                        <div className={'collaps-expand'}>{codeCollaps ?
                            <button className={'collaps-btn'} onClick={toggleCodeVisibility}>Collapse curl
                                request</button> :
                            <button className={'expand-btn'} onClick={toggleCodeVisibility}>Expand curl
                                request</button>}
                        </div>
                    </div>
                    <NavTab defaultActiveTabIndex={0}>
                        <TabItems title={'Ingest Into Existing Resources'}>
                            <p className={'fn-size'}>If you want to add your custom data to <span>Existing Middleware Resource Types,</span> you
                                will have to add resource_attributes according to the list given below</p>
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            <p className={'fn-size'}><b>Ex.</b> If you want to add a metric for a "host" - you will need
                                to add "host.id" resource attribute in your request body. Refer the table given below
                                for the full list of supported types.</p>
                            <TableGridview
                                columns={columns}
                                dataProvider={dataProvider}
                            />
                        </TabItems>
                        <TabItems title={'Ingest Custom Data'}>
                            <p className={'fn-size'}>If you want to add data that does not fall under the existing
                                resource types, you have to pass resource_attributes as given below.</p>
                            <pre onClick={() => {
                                copyToClipboard("mw.resource_type: custom")
                            }} className={'command'}><code>mw.resource_type: custom</code></pre>
                            <p className={'fn-size'}>Data added using this resource attributes will be available
                                under <span>Custom Metrics Dataset</span></p>
                        </TabItems>
                    </NavTab>
                </div>
            </div>
        </>
    )
}