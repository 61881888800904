import { Message } from "core/components/v2/enums";
import TabSelection from "core/components/v2/tab-selection";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import InstallationCard from "../../../components/card";
import Command from "../../../components/code-block/command";
import MwMessage from "../../../components/message";

import { getTenantUrl } from "core/application/utils";
import Button from "core/components/v2/button";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import InstallationSteps from "views/modules/installation-v2/components/steps";
import StepItem from "../../../components/steps/step-item";
// import agentRelatedSteps from "../../common/apm-agent-connect";

interface DatadogKubernetesProps {
  serverless: string;
}
const DatadogKubernetes: React.FC<DatadogKubernetesProps> = ({
  serverless,
}) => {
  const [appEnv, setAppEnv] = useState<number>(1);
  const [appBehaviour, setAppBehaviour] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const capturePathWithoutHttp = url;

  const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];

  interface StepFormat {
    title: string;
    content: React.ReactNode;
  }

  const datadogCardItems = [
    {
      items: [
        {
          label: "Datadog Agent Version",
          value: "6.7.0 or above",
        },
      ],
    },
  ];
  const sendEvent = useMixPanel();
  const steps: StepFormat[] = [
    {
      title: "Prerequisites",
      content: (
        <>
          <InstallationCard items={datadogCardItems} />
          <br />
          <br />
        </>
      ),
    },
    {
      title: "Configure Agent Via",
      content: (
        <>
          Run this command at the root of your workspace: Make sure you have
          installed the Go version with greater than v1.17+.
          <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Datadog",
                command_type: "Configure Agent Via",
              });
            }}
            command={`go get github.com/middleware-labs/golang-apm`}
            className="margin-1"
          />
          <MwMessage
            type={Message.Info}
            message="The above script will register the Middleware Agent APT repository with your infrastructure and install the latest DEB package"
          ></MwMessage>
          <MwMessage
            type={Message.Warning}
            message="You can clone the sample project from the GitHub repository."
          ></MwMessage>
          Add this import statement in your application:
          <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Datadog",
                command_type: "Configure Agent Via",
              });
            }}
            command={`import (
            track "github.com/middleware-labs/golang-apm/tracker" 
           )`}
            className="margin-1"
          />
          Add this snippet in your code
          <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Datadog",
                command_type: "Configure Agent Via",
              });
            }}
            command={`go track.Track( 
            track.WithConfigTag("service", "{APM-SERVICE-NAME}"), 
            track.WithConfigTag("accessToken", "xusuusalpvush63ud7zcg8bi3mauuptds528"),
           )`}
            className="margin-1"
          />
        </>
      ),
    },
    {
      title: "Enable Custom Logs (Optional)",
      content: (
        <>
          Custom logs are ones that the application does not produce by default.
          They may be generated within certain methods, or managed by a central
          logging method in your application.To get started with custom logs add
          the following code: <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Datadog",
                command_type: "Enable Custom Logs (Optional)",
              });
            }}
            command={`"github.com/middleware-labs/golang-apm/logger"

        ....
        
        logger.Error("Error")
        logger.Info("Info")
        logger.Warn("Warn")
        `}
            className="margin-1"
          />
          Enable Stack Errors Use the errorRecord method to record stack traces
          that throw an error object: <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Datadog",
                command_type: "Enable Custom Logs (Optional)",
              });
            }}
            command={`r.GET("/books", func(c *gin.Context) {
          ctx := req.Context()
          if err := db.Ping(ctx); err != nil {
              track.RecordError(ctx, err)
              panic(err)
          }
      })`}
            className="margin-1"
          />
          Custom logs are ones that the application does not produce by default.
          They may be generated within certain methods, or managed by a central
          logging method in your application.To get started with custom logs add
          the following code: <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Datadog",
                command_type: "Enable Custom Logs (Optional)",
              });
            }}
            command={`"github.com/middleware-labs/golang-apm/logger"

        ....
        
        logger.Error("Error")
        logger.Info("Info")
        logger.Warn("Warn")
        `}
            className="margin-1"
          />
          <br />
          <br />
          Enable Stack Errors Use the errorRecord method to record stack traces
          that throw an error object: <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Datadog",
                command_type: "Enable Custom Logs (Optional)",
              });
            }}
            command={`r.GET("/books", func(c *gin.Context) {
          ctx := req.Context()
          if err := db.Ping(ctx); err != nil {
              track.RecordError(ctx, err)
              panic(err)
          }
      })`}
            className="margin-1"
          />
        </>
      ),
    },
    {
      title: "View Your Data",
      content: (
        <>
          Once you have completed the Go installation, wait 3-5 minutes before
          accessing your data. Access your Trace, Log, and Profiling data by
          clicking on one of the buttons below. Proceed to deploy your project
          on Kubernetes.
          <br />
          <br />
          <div className="view-data-button-container">
            <Link key={"traces"} className="button-link" to={`#`}>
              <Button>Traces</Button>
            </Link>
            <Link key={"logs"} className="button-link" to={`#`}>
              <Button>Logs</Button>
            </Link>
            <Link key={"profiling"} className="button-link" to={`#`}>
              <Button>Profiling</Button>
            </Link>
          </div>
        </>
      ),
    },
  ];

  let indexOffset = 0;
  if (serverless != "true") {
    indexOffset = 2;
  }

  const [tab, setTab] = useState<number>(1);

  return (
    <>
      <InstallationSteps>
        <StepItem title="Prerequisites" stepNumber={1}>
          <InstallationCard items={datadogCardItems} />
        </StepItem>
        <StepItem title="Configure Agent Via" stepNumber={2}>
          <TabSelection
            tabs={[
              {
                label: "Helm Chart",
                value: 1,
              },
              {
                label: "Kubernetes Operator",
                value: 2,
              },
            ]}
            selectedTab={1}
            onTabChange={(tabObject) => {
              setTab(tabObject.value);
            }}
          />
          <div className="tab-content-area">
            {tab == 1 && (
              <>
                If you have installed the Datadog Agent using their Helm chart,
                you must add the following section in datadog-values.yaml that
                you create during this step.
                <br />
                <br />
                <Command
                  onCopyClick={() => {
                    sendEvent("click on copy command", {
                      agent_type: "Datadog",
                      command_type: "Configure Agent Via",
                    });
                  }}
                  command={`agents:
  useConfigMap: true
  customAgentConfig:
    logs_config:
      container_collect_all: true
      use_http: true
      additional_endpoints:
        - api_key: "${context.user?.account?.agent_config?.token}"
          Host: "${capturePathWithoutHttp}"
          Port: 443
          is_reliable: true
    apm_config:
      additional_endpoints:
        "https://${capturePathWithoutHttp}":
          - ${context.user?.account?.agent_config?.token}`}
                  className="margin-1"
                />
                <MwMessage
                  type={Message.Warning}
                  message="You can remove the logs_config section if you don't want to send logs to Middleware. Similarly, you can remove the apm_config section if you don't want to send APM traces to Middleware."
                ></MwMessage>
                You can refer the full datadog-values.yaml{" "}
                <a
                  href="https://github.com/DataDog/helm-charts/blob/main/charts/datadog/values.yaml"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>{" "}
                <br />
                <br />
              </>
            )}
            {tab == 2 && (
              <>
                If you have installed the Datadog Agent using their Kubernetes
                operator, you must add the following section in
                datadog-agent.yaml that you create during this step. You must
                add this section under the “spec” section:
                <br />
                <br />
                <Command
                  onCopyClick={() => {
                    sendEvent("click on copy command", {
                      agent_type: "Datadog",
                      command_type: "Configure Agent Via",
                    });
                  }}
                  command={`spec:
  ...
  override:
    nodeAgent:
      customConfigurations:
        datadog.yaml:
          configData: |
            logs_enabled: true
            apm_config:
              enabled: true
              additional_endpoints:
                - "https://${capturePathWithoutHttp}:"
                    - "${context.user?.account?.agent_config?.token}"
            logs_config:
              use_http: true
              container_collect_all: true
              additional_endpoints:
                - api_key: "${context.user?.account?.agent_config?.token}"
                  Host: "${capturePathWithoutHttp}"
                  Port: 443
                  is_reliable: true`}
                  className="margin-1"
                />
                You can refer the full datadog-values.yaml{" "}
                <a
                  href="https://github.com/DataDog/helm-charts/blob/main/charts/datadog/values.yaml"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>{" "}
                <br />
                <br />
              </>
            )}
          </div>
        </StepItem>
      </InstallationSteps>
    </>
  );
};
export default DatadogKubernetes;
