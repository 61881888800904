import * as React from "react";
import FormInputCheckbox from "core/components/form/form-checkbox";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
    InfoIconSvg,
    LeftArrowIcon,
    MinusIcon,
    PlusIcon,
    RightArrowIcon,
    SortingDownArrow,
    SortingUpArrow
} from "../svg/icons";
import Tooltip from "core/components/tooltip";
import PaginationWithNumber from "core/components/pagination/pagination-with-number";
import NoDataSpace from "../v2/no-data";

interface Props {
    style?: React.CSSProperties
    tableStyle?: React.CSSProperties
    className?: string
    tableClassName?: string
    onScroll?: (e: any) => void
    rowOnClick?: (row: any, rowIndex: any, childRowIndex?: any) => void
    rowWrapper?: (index: number, row: any) => void
    checkboxColumn?: boolean
    serialColumn?: boolean
    toggleColumn?: boolean
    columns: Array<GridviewColumnsOptions | string>
    dataProvider: Array<any>
    noDataLabel?: any;
    hideHead?: boolean;
    headSticky?: boolean;
    rowLoader?: boolean;
    numberOfRowLoader?: number;
    pagination?: boolean;
    limit?: number;
    pageCallback?: (event: "next" | "previous", page: number) => void
    page?: number;
    trClassCallback?: (row: any, rowIndex: any) => void;
    paginationWithNumbers ?: boolean
    totalRecords ?: number
    paginationWithNumbersCallback ?: (page: number) => void
}


export interface GridviewColumnsOptions {
    title: string
    accessor?: string
    className?: string
    style?: React.CSSProperties
    valueIndex?: number
    value?: (item: any, columnIndex?: number, rowIndex?: number) => any
    onClick?: (item: any, columnIndex?: number, rowIndex?: number) => any
    sortable?: {
        type : "ASC" | "DESC" | null;
        onSort: (data: any, index?: number) => void;
    }
    titleInfo ?: string
}


var checkbox: any = {}
const TableGridview = function (props: Props) {
    const [checkboxValue, checkboxUpdate] = useState(false)
    const [ui, uiUpdate] = useState(null as any)
    const [activePage, setActivePage] = useState(1)


    useEffect(() => {
        checkbox = {}
        if(props.page != undefined){
            setActivePage(props.page)
        }

    }, [])

    const toggleChecks = (index: number) => {
        checkbox[index] = typeof checkbox[index] != "undefined" ? !checkbox[index] : true
        uiUpdate(Date.now())
        let allChecks = true
        for (const v in checkbox) {
            if (!checkbox[v]) {
                allChecks = false
                break
            }
        }
        checkboxUpdate(allChecks)
    }

    const tableAttr: any = {
        style: props.tableStyle,
        className: (props?.tableClassName ? props.tableClassName : "") + " " + (typeof props.headSticky == "undefined" || props?.headSticky ? "head__sticky" : "")
    }

    const wrapperAttr:any = {
        style: props.style,
        className: `table-gridview__components ${(props?.className ? props.className : "")}`
    }

    if (props?.onScroll) {
        wrapperAttr.onScroll = (e: any) => props?.onScroll && typeof props.onScroll === "function" && props.onScroll(e)
    }

    const _providerColsRow = (row: any, rowIndex: number) => {
        return props.columns.map((column: GridviewColumnsOptions | string, columnIndex: number) => {
            let _atr: any = {}
            let _val = <span className={"with__opacity"}>-n/a-</span>;
            const isArrayWithIndex = (Array.isArray(row) && typeof row[columnIndex] !== "undefined")
            if (typeof column == "string") {
                _val = isArrayWithIndex ? row[columnIndex] : _val
            } else {
                if (column?.value && typeof column.value === "function") {
                    _val = column.value((isArrayWithIndex ? row[columnIndex] : row), columnIndex, rowIndex)
                } else if (column?.accessor && typeof row[column.accessor] != "undefined")
                    _val = row[column.accessor]
                else if (isArrayWithIndex) {
                    _val = row[columnIndex]
                }
                if (column?.style)
                    _atr.style = column.style
                if (column?.className) {
                    _atr.className = column.className
                }
            }
            if (typeof props?.rowOnClick == "function" || typeof column !== "string" && typeof column?.onClick == "function") {
                // _atr.className += " toggle_action__td"
                _atr.onClick = () => {
                    if (typeof column !== "string" && typeof column?.onClick == "function") {
                        column.onClick && column.onClick((isArrayWithIndex ? row[columnIndex] : row), columnIndex, rowIndex)
                    } else if (typeof props?.rowOnClick == "function") {
                        props.rowOnClick(row, rowIndex)
                    }
                }
            }

            return <td key={columnIndex} {..._atr}>{_val || "-"}</td>
        })
    }

    const [dataProviderIndex, setDataProviderIndex] = useState<any>(null)
    let _toggleItems = dataProviderIndex ? dataProviderIndex : {}

    const [page, setPage] = useState<number>(1)
    let providerItems = props.dataProvider
    let previousDisabled = false
    let nextDisabled = false
    let _limit = props?.limit ? props.limit : 50
    if (props?.pagination) {
        const isCallback = (props?.pageCallback && typeof props.pageCallback === "function")
        if (!isCallback) {
            providerItems = providerItems.slice((page - 1) * _limit, page * _limit)
        }
        if (providerItems.length < _limit) {
            nextDisabled = true
        }
        if (page == 1) {
            previousDisabled = true
        }
        // @ts-ignore
        if (props?.page > 1) {
            previousDisabled = false
        }
    }

    if (props?.paginationWithNumbers) {
        const isCallback = (props?.pageCallback && typeof props.pageCallback === "function")
        if (!isCallback) {
            providerItems = providerItems.slice((page - 1) * _limit, page * _limit)
        }
    }

    let colSpan = props.columns.length
    if (props.serialColumn)
        colSpan += 1
    if (props.toggleColumn)
        colSpan += 1
    if (props.checkboxColumn)
        colSpan += 1

    return <div  className={"table-grid-view-outer-wrapper"}><div  {...wrapperAttr}>
        <table {...tableAttr}>
            {!props?.hideHead && <thead>
            <tr>
                {props?.checkboxColumn && <th>
                    <FormInputCheckbox
                        id={'checkbox_tr_th'} name={'checkbox_tr_th'}
                        className={'checkbox_tr_th'}
                        labelClassName={'grid_row_tr_th'}
                        defaultValue={checkboxValue}
                        defaultChecked={checkboxValue}
                        onChange={() => {
                            for (const v in checkbox)
                                checkbox[v] = !checkboxValue
                            checkboxUpdate(!checkboxValue)
                        }} />
                </th>}

                {props?.serialColumn && <th>#</th>}
                {props?.toggleColumn && <th style={{width: "18px"}}>&nbsp;</th>}

                {props.columns.map((data: GridviewColumnsOptions | string, index: number) => {
                    let _atr: any = {}
                    if (typeof data != "string" && data?.style){
                        _atr.style = data.style
                    }
                    return <th key={index} {..._atr}>
                        <span className={`${typeof data !== "string" && data.sortable ? "sortable" : ""}`} onClick={() => {
                          typeof data !== "string" && data?.sortable?.onSort(data, index);
                        }}>
                            {(typeof data == "string" ? data : data.title)}
                            {typeof data !== "string" && data.titleInfo && <><i className={"grid-header-info"} data-tip data-for={`tooltip_table_column_${index}`}><InfoIconSvg /></i>
                                <Tooltip className={"grid-header-info-title"} id={`tooltip_table_column_${index}`} place="top" type="dark" effect="solid">
                                                            <div>{data.titleInfo}</div>
                                                        </Tooltip></>
                            }
                            {
                                typeof data !== "string" && data.sortable &&
                                    <div className={`default-arrow ${data.sortable.type ? data.sortable.type.toLowerCase() : "default"}`}>
                                        <span className="up"><SortingUpArrow /></span>
                                        <span className="down"><SortingDownArrow /></span>
                                    </div>
                            }
                        </span>
                    </th>
                })}
            </tr>
            </thead> }

            <tbody>
            {providerItems.length < 1 && <React.Fragment>
                {props?.numberOfRowLoader && props.numberOfRowLoader > 0 ? new Array(props.numberOfRowLoader).fill(Math.random()).map((k:any, index: number) => {
                    return <tr key={index}>{new Array(props.columns.length + (props.toggleColumn ? 1 : 0)).fill(true).map((r: any, clmIndex: number) => {
                        return <td key={`${index}__${clmIndex}`}><Skeleton baseColor={"var(--color-grey-300)"} borderRadius={2} width={"100%"}/></td>
                    })}</tr>
                }) : <tr key={-1} className={"simple__row"}>
                    <td className={"no-data-label"} colSpan={colSpan}>{props.noDataLabel || "No data found during this current time period."}</td>
                </tr>}
            </React.Fragment>}


            {providerItems.length > 0 && providerItems.map((row: any, rowIndex: number) => {
                checkbox[rowIndex] = typeof checkbox[rowIndex] != "undefined" ? checkbox[rowIndex] : false
                if (checkboxValue)
                    checkbox[rowIndex] = true

                const isChildItems = row?.childRows && Array.isArray(row.childRows) && row.childRows.length > 0
                if (typeof _toggleItems[rowIndex] == "undefined") {
                    _toggleItems[rowIndex] = providerItems.length == 1;
                }

                let trClass: any = `simple__row ${props?.rowOnClick ? "clickable__row" : ""} `
                if (props?.trClassCallback) {
                    trClass += props?.trClassCallback(row, rowIndex)
                }
                if(trClass.includes("clickable__row")){
                    const ret = trClass.replace('latest__alert','');
                }

                return <React.Fragment key={rowIndex}>
                    <tr className={trClass}>
                        {props?.checkboxColumn && <td>
                            <FormInputCheckbox
                                id={'checkbox_tr_td__' + rowIndex} name={'checkbox_tr_td__' + rowIndex}
                                className={'checkbox_tr_td__' + rowIndex}
                                labelClassName={'grid_row_tr_td__' + rowIndex}
                                defaultValue={checkbox[rowIndex]}
                                defaultChecked={checkbox[rowIndex]}
                                onChange={() => toggleChecks(rowIndex)}
                            />
                        </td>}
                        {props?.serialColumn && <td>{rowIndex + 1}</td>}
                        {props?.toggleColumn && <td style={{width: "18px"}} className={`toggle_action__td`} onClick={() => {
                            _toggleItems[rowIndex] = !_toggleItems[rowIndex]
                            setDataProviderIndex({..._toggleItems})
                        }}>
                            {row.childRows.length > 0 ? <span>
                                {_toggleItems[rowIndex] ? <MinusIcon/> : <PlusIcon/>}
                            </span> : <span>&nbsp;</span>}
                        </td>}
                        {_providerColsRow(row, rowIndex)}
                    </tr>
                    {_toggleItems[rowIndex] && isChildItems && row.childRows.map((childRow: any, childRowIndex: number) => {
                        return <tr key={childRowIndex} className={"is__child__row"}>
                            <td></td>
                            {_providerColsRow(childRow, childRowIndex)}
                        </tr>
                    })}
                </React.Fragment>
            })}

            {props?.rowLoader && <tr className={"simple__row"}>
                {props.columns.map((data: any, index: number) => {
                    return <td key={index} className={"no-data__l"}><Skeleton baseColor={"var(--color-grey-300)"} borderRadius={2} width={"100%"}/></td>
                })}
            </tr>}
            </tbody>
        </table>
    </div>
        {!props?.pagination && props.paginationWithNumbers && <PaginationWithNumber totalRecord={props.totalRecords != undefined ? props.totalRecords : props.dataProvider.length} perPage={props.limit ? props.limit : 50} currentPage={props.page ? props.page : page}  onClick={(page_number:number)=>{
            props.paginationWithNumbersCallback != undefined && props.paginationWithNumbersCallback(page)
            let page_event: "next" | "previous" = "next"
            if(page_number < page){
                page_event = "previous"
            }
            setPage(page_number)
            props.pageCallback && props.pageCallback(page_event,page_number)
        }}/>}
        {props?.pagination && <div className={"gridview__pagination__wrapper"}>
            <div className={"action__group"}>
                <button
                    title={`Previous`}
                    disabled={previousDisabled}
                    className={"action__group__btn"}
                    onClick={() => {
                        let _pg = page - 1
                        if (props.pageCallback && typeof props.pageCallback === "function") {
                            props.pageCallback("previous", _pg)
                        }
                        setPage(_pg)
                    } }><LeftArrowIcon size={15}/></button>
                <button
                    title={`Next`}
                    disabled={nextDisabled}
                    className={"action__group__btn"}
                    onClick={() => {
                        let _pg = page + 1
                        if (props.pageCallback && typeof props.pageCallback === "function") {
                            props.pageCallback("next", _pg)
                        }
                        setPage(_pg)
                    }}><RightArrowIcon size={15}/></button>
            </div>
        </div>}
    </div>
}

export default TableGridview