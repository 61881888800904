import * as React from "react";
import classNames from "classnames";

interface TabItemsWrapperProps {
    children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
    borderTop?: boolean
    borderBottom?: boolean
}
const TabItemsWrapper = function (props: TabItemsWrapperProps) {
    return  <ul className={classNames("tabs-nav nav navbar-nav navbar-left", {
        "tab__border-top": props.borderTop,
        "tab__border-bottom": props.borderBottom,
    })}>{props?.children}</ul>
}
export default TabItemsWrapper