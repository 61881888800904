import React, { useContext, useState } from "react";
import { NavigateFunction } from "react-router-dom";

import { Popover, PopoverPlacement } from "core/components/v2/popover";
import { PopoverItem } from "core/components/v2/popover/item";
import {
  ArrowRightIcon,
  DarkThemeIcon,
  LightThemeIcon,
} from "core/components/v2/svg/icons";
import AppContext from "core/components/wrapper/context";
import { setThemeState } from "core/utils";
import { useDispatch, useSelector } from "react-redux";
import { getThemesMode } from "store/selectors";
import { requestSwitchProject } from "store/settings/project/api";
import { defaultInstallationPath } from "views/modules/installation-v2/entity";
import { ProjectData } from "views/modules/settings/projects/entities";
import { billingPopupKeyName } from "../utils";
import { DefaultMwAvatar } from "./_icons";

interface ProfileAvatarV2Props {
  navigate: NavigateFunction;
}

const ProfileAvatarV2 = ({ navigate }: ProfileAvatarV2Props) => {
  const [avatarPopoverVisible, setAvatarPopoverVisible] = useState(false);
  const [projectPopoverVisible, setProjectPopoverVisible] = useState(false);

  const themeMode = useSelector(getThemesMode);
  const dispatch = useDispatch();
  const context = useContext(AppContext);

  const projects = context.user?.projects;

  const redirect = (id: number) => {
    const projectId = id.toString();
    dispatch(
      requestSwitchProject({
        email: context.user?.email,
        project: projectId,
      })
    );
  };

  const projectPopoverContent =
    Array.isArray(projects) &&
    projects.map((item: ProjectData, index: number) => {
      const isSameProject = context?.user?.account?.project_id == item?.id;
      return (
        <PopoverItem
          key={index}
          disabled={isSameProject}
          className={`project-title ${isSameProject ? "item-disable" : ""}`}
          onClick={() => {
            setAvatarPopoverVisible(false);
            setProjectPopoverVisible(false);
            redirect(item.id);
          }}
        >
          {item.name}
        </PopoverItem>
      );
    });

  const popoverContent = (
    <div className="profile-avatar-popover-content">
      <div
        className="theme-switch-container"
        onMouseOver={() => setProjectPopoverVisible(false)}
      >
        <div className="theme-switch">
          <div
            className={`light-theme ${themeMode === "light" ? "active" : ""}`}
            onClick={() => {
              themeMode === "dark" && setThemeState(themeMode);
            }}
          >
            <LightThemeIcon />
            <span>Light</span>
          </div>
          <div
            className={`dark-theme ${themeMode === "dark" ? "active" : ""}`}
            onClick={() => {
              themeMode === "light" && setThemeState(themeMode);
            }}
          >
            <DarkThemeIcon />
            <span>Dark</span>
          </div>
        </div>
      </div>
      <PopoverItem
        onClick={() => {
          navigate("/settings");
          setAvatarPopoverVisible(false);
        }}
      >
        <span
          className="popover-menu-item"
          onMouseOver={() => setProjectPopoverVisible(false)}
        >
          Profile
        </span>
      </PopoverItem>
      <PopoverItem
        onClick={() => {
          navigate(defaultInstallationPath);
          setAvatarPopoverVisible(false);
        }}
      >
        <span
          className="popover-menu-item"
          onMouseOver={() => setProjectPopoverVisible(false)}
        >
          Installation
        </span>
      </PopoverItem>
      <PopoverItem
        onClick={() => {
          setProjectPopoverVisible(!projectPopoverVisible);
        }}
      >
        <Popover
          onClose={() => setProjectPopoverVisible(!projectPopoverContent)}
          rootClassName="project-popover"
          isVisible={projectPopoverVisible}
          content={projectPopoverContent}
          placement={PopoverPlacement.Right}
        >
          <div
            onMouseOver={() => setProjectPopoverVisible(true)}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>Projects</span>
            <span className={"arrow-projects"}>
              <ArrowRightIcon />
            </span>
          </div>
        </Popover>
      </PopoverItem>
      <PopoverItem
        onClick={() => {
          setAvatarPopoverVisible(false);
          if (themeMode === "dark") setThemeState(themeMode);
          context.application.logout();
          context.changeContext({
            key: "user",
            value: undefined,
          });
          localStorage.removeItem("installation_redirect");
          sessionStorage.removeItem("mw_hide_acquire_chat_support");
          sessionStorage.removeItem(billingPopupKeyName);
        }}
      >
        <span
          className="popover-menu-item"
          onMouseOver={() => setProjectPopoverVisible(false)}
        >
          Logout
        </span>
      </PopoverItem>
    </div>
  );

  return (
    <Popover
      content={popoverContent}
      rootClassName="avatar-popover"
      isVisible={avatarPopoverVisible || projectPopoverVisible}
      onClose={() => setAvatarPopoverVisible(!avatarPopoverVisible)}
      placement={PopoverPlacement.Right}
      shouldCloseOnInnerClick={false}
    >
      <div
        onClick={() => setAvatarPopoverVisible(!avatarPopoverVisible)}
        className={"profile-avatar-frame"}
      >
        <span className={"user__avatar"}>
          <DefaultMwAvatar />
        </span>
      </div>
    </Popover>
  );
};

export default ProfileAvatarV2;
