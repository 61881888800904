import { APIStatus } from "core/application/utils";
import {
  ADD_UPDATE_REPORT,
  DELETE_REPORT,
  IMPORT_DASHBOARD,
  LAYOUTS_UPDATE,
  RECEIVED_ALL_WIDGETS,
  RECEIVED_AVAILABLE_METRICS,
  RECEIVED_AVAILABLE_METRICS_ATTRIBUTE_VALUES,
  RECEIVED_AWS_NAMESPACES,
  RECEIVED_BAR_FILTERS,
  RECEIVED_BUILDER_COUNT_DATA,
  RECEIVED_BUILDER_DIALOG_DATA,
  RECEIVED_BUILDER_FORMATTED_DATA,
  RECEIVED_BUILDER_METRIC_FILTER_VALUES,
  RECEIVED_BUILDER_METRIC_LIST,
  RECEIVED_CUSTOM_ATTRIBUTES,
  RECEIVED_INFRA_METRICS,
  RECEIVED_REPORTS,
  RECEIVED_RESOURCE_LIST,
  RECEIVED_SIDEBAR_FILTER_VALUES,
  RECEIVED_SOCKET_DATA,
  RECEIVED_SOURCES,
  RECEIVED_WIDGET_REPORTS_BUILDER_LIST,
  REFRESH_BUILDER,
  REMOVE_ITEM_FROM_DASHBOARD,
  REMOVE_ITEM_FROM_REPORT,
  REMOVE_SOCKET_DATA_BY_INDEX,
  REQUEST_ALL_WIDGETS,
  REQUEST_AVAILABLE_METRICS,
  REQUEST_AWS_NAMESPACES,
  REQUEST_SOURCES,
  RESET_BUILDER_FORMATTED_DATA,
  RESET_RESOURCE_VIEW,
  RESET_WIDGET,
  RESET_WIDGETS_REPORTS,
  UPDATE_DATASETS_LIST,
  UPDATE_WIDGET_REPORTS_BUILDER_LIST,
  WS_RECEIVED_BUILDER_FORMATTED_DATA,
} from "store/widgets/constant";
import {
  AddUpdateWidgetBuilder,
  AvailableFilters,
  AvailableMetricsResponse,
  ChartType,
  DashboardSource,
  GridViewCountData,
  GridviewDataType,
  LayoutItem,
  SocketResponse,
  TimeSeriesData,
} from "views/modules/builder/entities/builder.entities";
import {
  BarType,
  DataType,
  FilterAttSearchResp,
  FiltersAPIResp,
  MetricsAPIResp,
  ResourceAPIResp,
} from "./entities";
import {
  APIAction,
  ReportListReceived,
  ResetWidget,
  UpdateDatasetsListType,
} from "./reducers";

export const reportListAction = (data: ReportListReceived) => {
  return {
    type: RECEIVED_REPORTS,
    data,
  };
};
export const resetCreateUpdateAction = () => {
  return {
    type: ADD_UPDATE_REPORT,
    data: {
      apiAction: {
        apiStatus: APIStatus.IDLE,
        errorMsg: "",
        requestErrors: [],
      },
    },
  };
};
export const resetDeleteAction = () => {
  return {
    type: DELETE_REPORT,
    data: {
      apiAction: {
        apiStatus: APIStatus.IDLE,
        errorMsg: "",
        requestErrors: [],
      },
    },
  };
};
export const dashboardImportAction = (data: APIAction) => {
  return {
    type: IMPORT_DASHBOARD,
    data,
  };
};
export const resetDashboardImportAction = () => {
  return {
    type: IMPORT_DASHBOARD,
    data: {
      apiStatus: APIStatus.IDLE,
      errorMsg: "",
      requestErrors: [],
    },
  };
};

export const receivedAttributes = ({ ...arg }) => {
  return {
    type: RECEIVED_CUSTOM_ATTRIBUTES,
    ...arg,
  };
};

export const updateReportsBuilderList = ({ ...arg }) => {
  return { type: UPDATE_WIDGET_REPORTS_BUILDER_LIST, ...arg };
};

export const receivedReportsBuilderList = ({ ...arg }) => {
  return { type: RECEIVED_WIDGET_REPORTS_BUILDER_LIST, ...arg };
};

export const receivedBuilderFormattedData = (data: AddUpdateWidgetBuilder) => {
  return { type: RECEIVED_BUILDER_FORMATTED_DATA, data };
};
export const resetBuilderFormattedData = () => {
  return { type: RESET_BUILDER_FORMATTED_DATA };
};
export const removeViewFromDashboard = (scopeId: number) => {
  return { type: REMOVE_ITEM_FROM_DASHBOARD, scopeId };
};
export const makeRequestForAvailableMetrics = () => {
  return { type: REQUEST_AVAILABLE_METRICS };
};
export const receivedAvailableMetrics = (
  response?: AvailableMetricsResponse
) => {
  return { type: RECEIVED_AVAILABLE_METRICS, response };
};

export const receivedAvailableMetricsAttributeValues = (
  resource: string,
  attribute: string,
  chartType: ChartType,
  values: GridviewDataType
) => {
  return {
    type: RECEIVED_AVAILABLE_METRICS_ATTRIBUTE_VALUES,
    resource,
    attribute,
    chartType,
    values,
  };
};

export const updateDatasetList = (data: UpdateDatasetsListType) => {
  return { type: UPDATE_DATASETS_LIST, data };
};
export const removeItemFromDashboard = (builderId: number) => {
  return { type: REMOVE_ITEM_FROM_REPORT, builderId };
};
export const resetWidget = (data: ResetWidget) => {
  return { type: RESET_WIDGET, data };
};
export const resetResourceView = (data: string) => {
  return { type: RESET_RESOURCE_VIEW, data };
};
export const resetReportBuilders = () => {
  return { type: RESET_WIDGETS_REPORTS };
};
export const receivedWsFormattedData = (data: TimeSeriesData) => {
  return { type: WS_RECEIVED_BUILDER_FORMATTED_DATA, data };
};
export const receivedBuilderCountData = (data: GridViewCountData) => {
  return { type: RECEIVED_BUILDER_COUNT_DATA, data };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
export const receivedWidgetBuilderDialog = ({ type, ...opts }: any) => ({
  type: RECEIVED_BUILDER_DIALOG_DATA,
  ...opts,
});
export const requestAwsNamspaces = () => ({
  type: REQUEST_AWS_NAMESPACES,
});
export const receivedAwsNamspaces = (namespaces: string[]) => ({
  type: RECEIVED_AWS_NAMESPACES,
  namespaces,
});
export const layoutUpdates = (layouts: LayoutItem[]) => ({
  type: LAYOUTS_UPDATE,
  layouts,
});
export const doBuilderRefresh = (name: string) => ({
  type: REFRESH_BUILDER,
  name,
});
export const requestDashboardAction = () => {
  const data: APIAction = {
    apiStatus: APIStatus.LOADING,
    errorMsg: "",
    requestErrors: [],
  };
  return {
    type: REQUEST_SOURCES,
    data,
  };
};
export const receveidDashboardSources = (data: {
  apiAction: APIAction;
  sources: DashboardSource[];
}) => {
  return {
    type: RECEIVED_SOURCES,
    data,
  };
};
export const receveidDashboardAllWidgets = (data: {
  apiAction: APIAction;
  widgets: DashboardSource[];
  reportKey: string;
}) => {
  return {
    type: RECEIVED_ALL_WIDGETS,
    data,
  };
};
export const requestAllWidgetsAction = () => {
  return {
    type: REQUEST_ALL_WIDGETS,
  };
};

export const receivedResourceList = (data: ResourceAPIResp) => ({
  type: RECEIVED_RESOURCE_LIST,
  data,
});

export const recievedBuilderMetricList = (
  dataType: DataType,
  data: MetricsAPIResp
) => {
  return {
    type: RECEIVED_BUILDER_METRIC_LIST,
    dataType,
    data,
  };
};

export const receivedBuilderMetricFilterValues = (
  data: FilterAttSearchResp
) => {
  return {
    type: RECEIVED_BUILDER_METRIC_FILTER_VALUES,
    data,
  };
};

export const receivedBarFilters = (barType: BarType, data: FiltersAPIResp) => {
  return {
    type: RECEIVED_BAR_FILTERS,
    barType,
    data,
  };
};

export const receivedInfraMetrics = (
  infraType: string,
  data: MetricsAPIResp
) => {
  return {
    type: RECEIVED_INFRA_METRICS,
    infraType,
    data,
  };
};

export const receivedSidebarFilterValues = (data: AvailableFilters) => {
  return {
    type: RECEIVED_SIDEBAR_FILTER_VALUES,
    data,
  };
};
export const SocketDataAction = (data: SocketResponse) => {
  return {
    type: RECEIVED_SOCKET_DATA,
    data,
  };
};
export const RemoveSocketDataByIndex = (index: number) => {
  return {
    type: REMOVE_SOCKET_DATA_BY_INDEX,
    index,
  };
};
