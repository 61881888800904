/** @format */
/**
 * External dependencies
 */
import * as React from 'react';
import classnames from 'classnames';

interface Props {
    className?: string
    id?: string
    label?: string
    labelClassName?: string
    labelRootClassName?: string,
    defaultChecked?: boolean,
    onChange?:(e: any) => void,
    ref?:any,
    name?:string,
    defaultValue?:any
    checked?:boolean
    disabled?:boolean
}

const FormInputCheckbox = ( { className, label, labelClassName, labelRootClassName,defaultChecked, ...otherProps }: Props ) => {
    if(label){
        return <label htmlFor={otherProps.id} className={classnames( "form__checkbox_input", labelRootClassName )}>
            <input { ...otherProps } type="checkbox" className={ classnames( className, 'form-checkbox' ) } />
            <span className={labelClassName} dangerouslySetInnerHTML={{__html: label}}/>
        </label>;
    }else{
        return <input { ...otherProps } type="checkbox" className={ classnames( className, 'form-checkbox' ) } />
    }
}

export default FormInputCheckbox;
