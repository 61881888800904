import * as React from 'react';



const Loader = React.memo(function () {
    return (
        <div className={ 'mw-loader' } >
            <div className={"loader-root"}>
                <div>
                    <div className="circular-loader"></div>
                </div>
            </div>
        </div>
    );
})

export default Loader
