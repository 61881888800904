import { APIStatus } from "core/application/utils";
import DropdownSelection from "core/components/v2/dropdown-selection";
import { debounceHandler } from "core/utils";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { recievedBuilderMetricList } from "store/widgets/actions";
import { fetchMetricList } from "store/widgets/api";
import { DataType, MetricsAPIResp } from "store/widgets/entities";
import { getBuilderMetrics } from "store/widgets/selectors";
import {
  MetricDetails,
  getColumnName,
  getMetricDetails,
  isAggregationSupported,
  isMetricAggregation,
} from "../../common/utils";
import {
  ChartType,
  DefaultNoneValue,
  KpiType,
  MetricItem,
  MetricTypeEnum,
  Query,
  SelectOneMetric,
  getWidgetType,
} from "../../entities/builder.entities";
import "./_style.scss";

export interface MetricProps {
  chartType: ChartType;
  selectedKpiType: KpiType;
  query: Query;
  onMetricUpdate: (updateQry: Query) => void;
}

const shouldAddResrcAsPrefx = (
  metricItems: MetricItem[],
  metricName: string
) => {
  let metric: MetricItem | undefined;

  if (metricItems.filter((mi) => mi.name === metricName).length > 1) {
    return true;
  } else {
    metric = metricItems.find((mi) => mi.name === metricName);
    return (
      metric?.type === MetricTypeEnum.ResourceAttributeKey ||
      metric?.type === MetricTypeEnum.MetricAttributeKey
    );
  }
};

const Metric = ({
  chartType,
  selectedKpiType,
  query,
  onMetricUpdate,
}: MetricProps) => {
  const dispatch = useDispatch();
  const builderMetrics = useSelector(getBuilderMetrics);

  const currentMetric =
    query.columns.length > 0 ? (query.columns[0] as string) : "";
  const currentMetricDetails = useMemo(
    () => getMetricDetails(currentMetric),
    [currentMetric]
  );

  const metrics = useMemo(() => {
    if (selectedKpiType === KpiType.KpiTypeNone) return [];

    return (builderMetrics.metrics?.items as MetricItem[]) || [];
  }, [chartType, selectedKpiType, builderMetrics.metrics?.items]);

  const sortedOptions = useMemo(() => {
    const options = [
      ...metrics,
      {
        label: DefaultNoneValue,
        name: DefaultNoneValue,
        type: MetricTypeEnum.MetricTypeNone,
        attributes: {},
        resource: "",
      },
    ];

    return options
      .map((mi) => {
        const addResrcAsPrefix = shouldAddResrcAsPrefx(metrics, mi.name);
        // NOTE: If the there are more than one metric with same name, add the resource as prefix to create unique label.
        // So, the label will become `{resource_name}.{metric_name}`.
        // Since, the Dropdown component only returns the string value on the option change. We need to add this information
        // in the value. That is being achieved by the pattern `resrc-prefix<###>{resource_name}<###>{label}`.
        const label = addResrcAsPrefix ? `${mi.resource}.${mi.name}` : mi.name;
        const value = addResrcAsPrefix
          ? `resrc-prefix<###>${mi.resource}<###>${label}`
          : mi.name;

        return {
          label,
          value,
        };
      })
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
  }, [metrics]);

  const [selectedMetric, setSelectedMetric] = useState<string[]>(
    currentMetric
      ? [
          shouldAddResrcAsPrefx(metrics, currentMetricDetails.metricName)
            ? `resrc-prefix<###>${query.source.name}<###>${query.source.name}.${currentMetricDetails.metricName}`
            : currentMetricDetails.metricName,
        ]
      : []
  );

  useEffect(() => {
    if (!currentMetric || currentMetric === SelectOneMetric) {
      setSelectedMetric([]);
    }
  }, [currentMetric]);

  const onUpdate = (details: MetricDetails, newResource: string) => {
    const updateMetricName = getColumnName(details);
    const updatedQry = { ...query, columns: [updateMetricName], with: [] };
    if (query.source.name !== newResource) {
      updatedQry.source.name = newResource;
    }
    onMetricUpdate(updatedQry);
  };

  const handleMetricChange = (value: string | string[]) => {
    const strVal = (value || "") as string;

    setSelectedMetric(!strVal ? [] : [strVal]);

    let resrc = "",
      metricName = strVal;
    if (strVal.startsWith("resrc-prefix<###>")) {
      const tokens = strVal.split("resrc-prefix<###>")[1].split("<###>");
      resrc = tokens[0];
      metricName = tokens[1].replace(`${resrc}.`, "");
    }

    if (!metricName || metricName === DefaultNoneValue) {
      currentMetricDetails.metricName = SelectOneMetric;
      currentMetricDetails.aggregation = "avg";
      currentMetricDetails.subAggregation = "avg";
      onUpdate(currentMetricDetails, "");
      return;
    }

    const selectedOption = metrics.find((option) => {
      return (
        option.name === metricName &&
        (resrc === "" || option.resource === resrc)
      );
    });
    if (!selectedOption) return;

    let agg = currentMetricDetails.aggregation || "avg";
    let subAggregation = currentMetricDetails.subAggregation || "avg";

    if (
      !isAggregationSupported(selectedOption.type, agg) &&
      isMetricAggregation(agg)
    ) {
      agg = "any";
      subAggregation = "any";
    }

    currentMetricDetails.metricName = selectedOption.name;
    currentMetricDetails.aggregation = agg;
    currentMetricDetails.subAggregation = subAggregation;
    onUpdate(currentMetricDetails, selectedOption.resource);
  };

  const requestMetricList = (value: string) => {
    dispatch(
      fetchMetricList(
        {
          kpiType: selectedKpiType,
          widgetType: getWidgetType(chartType),
          dataType: DataType.Metrics,
          search: value,
        },
        (resp: MetricsAPIResp) =>
          recievedBuilderMetricList(DataType.Metrics, resp)
      )
    );
  };

  return (
    <div className="dropdown-with-label-container">
      <label>Metric*</label>
      <DropdownSelection
        placeholder={SelectOneMetric}
        options={sortedOptions}
        onChange={handleMetricChange}
        selectedValues={selectedMetric}
        notFoundContent="No options found"
        extraNoteTitle={
          sortedOptions.length >= 100 ? "Search for more..." : undefined
        }
        isLoading={builderMetrics.metrics?.apiStatus === APIStatus.LOADING}
        isError={
          !currentMetricDetails.metricName ||
          currentMetricDetails.metricName === SelectOneMetric
        }
        onSearch={debounceHandler(-1, requestMetricList, 500)}
      />
    </div>
  );
};

export default Metric;
