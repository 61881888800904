import * as React from "react";
import NavTab from "core/components/nav-tab";
import TabItems from "core/components/nav-tab/items";
import {
    InstallationDocsIcon, ReactNativeV2
} from "core/components/svg/icons";
import {Link} from "react-router-dom";
import RumSetting from "views/modules/installation/components/rum/rum-setting";

export default function ReactNativeRUMInstrument() {

    return <>
        <div className={'cloudflare-apm-new dialog-table-componants'}>
            <div className={' setting_side-panel'}>
                <div className={'integration-popup rum-integration-box'}>
                    <div className={'header-section'}>
                        <div className={'d-flex'}>
                            <ReactNativeV2/>
                            <div className={'header-content'}>
                                <h3>React Native</h3>
                                <p>React Native RUM is specifically used for monitoring React Native mobile
                                    applications.</p>
                            </div>
                            <button className={"docs-link"}><Link to={'https://docs.middleware.io/rum/rum-react'}
                                                                  target={'_blank'}><span> <InstallationDocsIcon/>Documentation</span></Link>
                            </button>

                        </div>
                        <NavTab targetIndex={1} defaultActiveTabIndex={1}>
                            <TabItems title={'Overview'}>
                                <div className={'d-flex in-content'}>
                                    <div className={'overview-heading'}>
                                        <div className={'config-overview'}>
                                            <h4>Category</h4>
                                            <span>Real User Monitoring</span>

                                            <h4 className={'tp-mr'}>Supported Version</h4>
                                            <img alt="React-Native RUM Supported"
                                                 src="https://img.shields.io/github/v/release/middleware-labs/middleware-react-native?include_prereleases&style=flat"/>

                                        </div>
                                    </div>
                                    <div className={'overview-content'}>
                                        <p>The Mobile RUM SDK provides a customizable suite of tools to analyze and optimize the performance of React Native applications. Seamlessly track user sessions and auto-instrument HTTP monitoring, JS errors, navigation tracking for react-navigation, and native crash errors.</p>
                                        <div className={'overview-image'}>
                                            <img src={'assets/images/common-rum.png'}
                                                 alt={'rum-overview'}/>
                                        </div>
                                    </div>
                                </div>
                            </TabItems>
                            <TabItems title={'Installation'}>
                                <div className={'panel-body'}>
                                    <RumSetting data={'react-native'}/>
                                </div>

                            </TabItems>

                        </NavTab>
                    </div>
                </div>

            </div>
        </div>
    </>
}