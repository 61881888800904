/** @format */
/**
 * External dependencies
 */
import * as React from 'react';
import classNames from 'classnames';
import Loader from "core/components/loader";

interface Props {
	simple?: boolean,
	compact?: boolean,
	primary?: boolean,
	secondary?: boolean,
	scary?: boolean,
	success?: boolean,
	warning?: boolean,
	info?: boolean,
	loading?:boolean,
	busy?: boolean,
	type?: string,
	href?: string,
	borderless?: boolean,
	target?: string,
	rel?: string,
	shadow?:boolean
	className?:any
	children:any
	ref?:any
	title?:any
	disabled?:boolean
	onClick?: (a0?: any) => void
    action?: boolean
	flex?: boolean
    style?: React.CSSProperties
}


const Button = React.forwardRef(function ( props: Props,ref) {

	const { simple, compact, primary,secondary, scary, busy, borderless, success, warning, info, loading,shadow,action,type, flex, ...otherProps } = props;

	const className = classNames( 'button', props.className, {
		'is-warning': props.warning,
		'is-info': props.info,
		'is-success': props.success,
		'is-simple': props.simple,
		'is-compact': props.compact,
		'is-primary': props.primary,
		'is-secondary' : props.secondary,
		'is-scary': props.scary,
		'is-busy': props.busy,
		'is-loading': props.loading,
		'is-borderless': props.borderless,
		'is-shadow':props.shadow,
        'is-action': props.action,
        'is-flex': props.flex
	} );

	if ( props.href ) {
		// @ts-ignore
		const rel = props.target ? (props.rel || "").replace( /noopener|noreferrer/g, '' ) + ' noopener noreferrer' : props.rel;

		return <a { ...otherProps } rel={ rel } className={ className } />;
	}
	if ( props.loading ) {
		return <button { ...otherProps } className={ className } ><Loader/></button>
	}
	return <button { ...otherProps } className={ className }  />;
})


export default Button
