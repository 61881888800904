import * as React from "react";
import {RoutesPatterns} from "core/utils/route_schema";

const MetricsRoutes: RoutesPatterns = {
    moduleName: "metrics",
    slug: "/metrics",
    sidebar: React.lazy(() => import("views/modules/metrics/sidebar")),
    breadcrumbs: React.lazy(() => import("views/modules/metrics/breadcrumbs")),
    items: [
        {
            pattern: [
                "/",
            ],
            component: React.lazy(() => import("views/modules/metrics/index")),
            breadcrumbTitle: "AWS"
        },
    ]
}
export default MetricsRoutes