import LogoLoader from "core/components/v2/loader/logo-loader";
import NoDataSpace from "core/components/v2/no-data";
import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getReportData } from "store/widgets/selectors";
import { CustomizedFilterOption } from "views/layouts/app/components/customise-filter-button";
import { DateRange } from "views/layouts/app/routes/model";
import {
  getMetricDetails,
  getQueryValueData,
  validateVisualFormattingRules,
} from "../common/utils";
import {
  BuilderNestedProps,
  ChartDataType,
  ColorApplyAt,
  ColumnConfigDetails,
  CustomWidget,
  Pagination,
  ResourceViewOptions,
  SELECT_LIMIT,
  SortDirection,
  mapAppKey,
} from "../entities/builder.entities";
import BuilderChart from "./charts/builder-chart-v2";
import { WidgetAppType, WidgetsApp } from "./widgets.app";

export interface WidgetAppViewOptions {
  resource?: ResourceViewOptions;
  builderView?: {
    builderId: number;
    scopeId: number;
  };
}

export interface WidgetAppViewProps {
  onSortingChange?: (
    sortingColumnName: string,
    sortingType: SortDirection
  ) => void;
  refreshData: () => void;
  builderViewOptions: WidgetAppViewOptions;
  preview?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listOnClick?: (a0: any) => void;
  openInnerDialog?: boolean;
  nestedProps?: BuilderNestedProps;
  onEdit?: (() => void) | null;
  onClone?: (() => void) | null;
  onDelete?: (() => void) | null;
  /** Display title if you are using builder as resource */
  boxTitle?: string;
  subInfo?: string;
  titleChild?: React.ReactNode;
  columnsSelection?: CustomizedFilterOption[];
  onColumnsChange?: (selectedColumns: string[]) => void;
  onDefaultLabelClick?: () => void;
  onPagination?: (offset: number) => void;
  pagination?: Pagination;
  expandableTitleOptions?: {
    showExpandable?: boolean;
    titleOnClick?: (expanded: boolean) => void;
  };
  resourceView?: CustomWidget;
  onDateChange?: (range: DateRange) => void;
}
const WidgetAppView = (props: WidgetAppViewProps) => {
  const reportData = useSelector(getReportData, shallowEqual);
  const {
    onSortingChange,
    listOnClick,
    builderViewOptions,
    preview = false,
    refreshData,
    openInnerDialog,
    subInfo,
    titleChild,
    columnsSelection,
    onColumnsChange,
    onPagination,
    pagination,
    expandableTitleOptions,
    onDateChange,
  } = props;
  const { onDataReceived } = props.nestedProps || {};
  useEffect(() => {
    // if (props?.mountCallback)
    //     props.mountCallback(true, builderViewOptions?.builderView?.builderId)

    if (preview) {
      document.body.classList.add("graph-preview-mode");
    }
    return () => {
      document.body.classList.remove("graph-preview-mode");
    };
  }, []);
  const builderId = builderViewOptions?.builderView?.builderId;
  const resource = builderViewOptions?.resource;

  useEffect(() => {
    resource?.with?.push({
      key: SELECT_LIMIT,
      value: {
        n: pagination?.limit ?? 100,
        offset: 0,
      },
      is_arg: false,
    });
  }, [builderViewOptions?.resource?.with]);

  let builderItem: CustomWidget | undefined;
  if (builderId) {
    builderItem = reportData.builders.widgets[builderId];
  } else if (resource?.resourceType) {
    builderItem = reportData.builders.defaultViews[resource.name];
  }
  if (props.resourceView) {
    if (!builderItem)
      builderItem = JSON.parse(
        JSON.stringify(props.resourceView)
      ) as CustomWidget;
    // Update the widgetData by the props.resourceView?.widgetData as widgetData is now
    // retrieved from the useBuilderView and not from the redux. This is specifically for
    // the Create/Update Widget as those data are being stored in the redux.
    builderItem.widgetData = JSON.parse(
      JSON.stringify(props.resourceView.widgetData)
    ) as ChartDataType;
  }
  const widgetData = builderItem?.widgetData;
  const _App = Object.values(WidgetsApp).find(
    (app: WidgetAppType) => app.id === builderItem?.widgetAppId
  );
  useEffect(() => {
    if (!widgetData?.inflight && widgetData && onDataReceived) {
      onDataReceived(widgetData);
    }
  }, [widgetData?.inflight]);
  if (!builderItem && !preview) {
    return <LogoLoader />;
  }

  let columnConfig = props.nestedProps?.columnConfig;
  let countChartOptions = props.nestedProps?.countChartOptions ?? {};
  if (
    builderItem &&
    Array.isArray(builderItem?.builderConfig) &&
    _App
    //  &&
    // _App.key === ChartType.QueryValueChart
  ) {
    builderItem.builderConfig.forEach((config) => {
      if (config.meta_data && config.meta_data) {
        const selectedColumnConfig: ColumnConfigDetails = {
          title: config.meta_data.alias,
          tpl: config.meta_data.tpl,
          visualFormatting: config.meta_data.visualFormatting,
          bgColor: "",
          color: "",
        };
        if (config.meta_data.visualFormatting && widgetData) {
          const d = widgetData.queryCountChartData;
          const value = getQueryValueData(d);
          const validRules = validateVisualFormattingRules(
            config.meta_data.visualFormatting,
            value
          );
          validRules.forEach((validRule) => {
            if (validRule.applyAt === ColorApplyAt.Background)
              selectedColumnConfig.bgColor = validRule.color;
            else selectedColumnConfig.color = validRule.color;
          });
        }
        if (config.meta_data.comparisonRanges) {
          if (!countChartOptions) {
            countChartOptions = {};
          }
          countChartOptions.showComparison = true;
          countChartOptions.badgeType =
            config.meta_data.comparisonRanges.badgeType;
        }
        const columnName = config.columns[0] as string;
        const metricName = getMetricDetails(columnName).metricName;
        if (columnConfig && config.columns.length === 1) {
          columnConfig[columnName] = selectedColumnConfig;
          columnConfig[metricName] = selectedColumnConfig;
        } else {
          columnConfig = {
            [columnName]: selectedColumnConfig,
            [metricName]: selectedColumnConfig,
          };
        }
      }
    });
  }
  if (_App) {
    //TODO: Change appKey to ChartType
    return (
      <BuilderChart
        nestedProps={{
          ...(props?.nestedProps ?? {}),
          columnConfig,
          countChartOptions,
          chartType: builderItem?.builderMetaData?.chartType,
        }}
        onEdit={props.onEdit}
        onClone={props.onClone}
        boxTitle={props.boxTitle}
        onDelete={props.onDelete}
        appKey={mapAppKey(_App.key)}
        builderViewOptions={builderViewOptions}
        customWidget={props.resourceView}
        onSortingChange={onSortingChange}
        onPagination={onPagination}
        pagination={pagination}
        listOnClick={listOnClick}
        widgetData={widgetData}
        builderId={builderId ?? -1}
        preview={preview}
        isLoading={widgetData?.inflight}
        openInnerDialog={openInnerDialog}
        subInfo={subInfo}
        titleChild={titleChild}
        columnsSelection={columnsSelection}
        onColumnsChange={onColumnsChange}
        onDefaultLabelClick={props.onDefaultLabelClick}
        isError={builderItem?.isError}
        errorMsg={builderItem?.errorMsg}
        refreshData={refreshData}
        expandableTitleOptions={expandableTitleOptions}
        onDateChange={onDateChange}
      />
    );
  }
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <div className="no-app">
          <NoDataSpace />
        </div>
      </div>
    </main>
  );
};
export default WidgetAppView;
