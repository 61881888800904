import * as React from "react";
import { useDispatch } from "react-redux";
import { removeToast } from "store/toast-alerts/actions";
import { useEffect } from "react";
import {
  ArrowRightIcon,
  CloseIcon,
  InfoToastIcon,
  SuccessToastIcon,
  WarningToastIcon,
} from "../v2/svg/icons";
import Button from "../v2/button";

const ToastItem = function (props: any) {
  const dispatch = useDispatch();
  const toast: any = props.toast;
  const _close = () => dispatch(removeToast(props.toastIndex));

  useEffect(() => {
    if (!toast?.duration) return;
    const id = setTimeout(() => _close(), toast.duration);
    return () => clearTimeout(id);
  }, [toast.uniqueId]);

  return (
    <div className={`mw-toast-alerts top-right ${toast.toastType}`}>
      <div className="left-thick-border"></div>
      <div className="toast-container">
        {toast.toastType !== "default" && (
          <div className="mw-toast_icon">
            {toast.toastType === "success" && <SuccessToastIcon />}
            {toast.toastType === "info" && <InfoToastIcon />}
            {toast.toastType === "error" && (
              <InfoToastIcon color="var(--color-error)" />
            )}
            {toast.toastType === "warning" && <WarningToastIcon />}
          </div>
        )}
        <div className="mw-toast-content">
          <div className="mw-toast__title">{toast.title}</div>
          <div className="mw-toast__message">{toast.message}</div>
          {toast.btnText && (
            <div className="text-btn" onClick={() => toast.onBtnClick()}>
              <div>{toast.btnText}</div>
              <ArrowRightIcon color="var(--color-primary)" />
            </div>
          )}
          <div className="action-btn-container">
            {toast.onPrimaryBtnClick && (
              <Button primary onClick={() => toast?.onPrimaryBtnClick?.()}>
                {toast.primaryBtnText}
              </Button>
            )}
            {toast.onSecondaryBtnClick && (
              <Button onClick={() => toast?.onSecondaryBtnClick?.()}>
                {toast.secondaryBtnText}
              </Button>
            )}
          </div>
        </div>
        <div onClick={() => _close()} className="mw-close-icon-container">
          <CloseIcon />
        </div>
      </div>
    </div>
  );
};
export default ToastItem;
