import * as React from 'react';
import { over } from 'lodash-es';
import { isUndefined } from "lodash";

import Popover from '.';

const isInvalidTarget = (target: any) => {
	return target.tagName === 'HR';
};
interface Props {
	autoPosition ?: boolean,
	isVisible : boolean,
	onClose:(a0: any) => void,
	position ?: string,
	maxWidth ?: string,
	minWidth ?: string,
	className ?: string,
	rootClassName ?: string,
	positionAdjustment ?: {
		left: number,
		top:  number,
	},
	popoverTitle ?: string,
	customPosition ?: object,
	stopPropagation ?: boolean,
	footerButtonOnClick ?: (a0: any) => void,
	footerButtonLabel ?: any
	autoFocusList ?: boolean,
	popoverComponent? : any,
	context?: any,
	secondaryTopOffset ? : any,
	secondaryContext?: any,
	menuClass?: string,
}
class PopoverMenu extends React.Component<Props, any> {
	_previouslyFocusedElement?: any;

	static defaultProps = {
		autoPosition: true,
		position: 'top',
		popoverComponent: Popover,
		stopPropagation: false,
		autoFocusList: false,
	};

	menu: any = React.createRef();

	componentWillUnmount() {
		// Make sure we don't hold on to reference to the DOM reference
		this._previouslyFocusedElement = null;
	}

	render() {
		const {
			popoverComponent: PopoverComponent,
			autoPosition,
			className,
			context,
			customPosition,
			isVisible,
			popoverTitle,
			position,
            positionAdjustment,
            minWidth,
            maxWidth,
			rootClassName,
            footerButtonOnClick,
			footerButtonLabel,
		} = this.props;

		const __style: any = {};
		if (minWidth) __style.minWidth = minWidth;
		if (maxWidth) __style.maxWidth = maxWidth;

		return (
			<PopoverComponent
				onClose={ this._onClose }
				onShow={ this._onShow }
				autoPosition={ autoPosition }
				className={ className }
				context={ context }
				customPosition={ customPosition }
				isVisible={ isVisible }
				popoverTitle={ popoverTitle }
				position={ position }
                positionAdjustment={ positionAdjustment }
				rootClassName={ rootClassName }
                secondaryTopOffset={ this.props.secondaryTopOffset }
                secondaryContext={ this.props.secondaryContext }
			>
				<div
					ref={ this.menu }
					role="menu"
					style={__style}
					className={`popover__menu ${this.props.menuClass || ''}`}
					onKeyDown={ this._onKeyDown }
					tabIndex={-1}
				>
                    { isVisible && React.Children.map( this.props.children, this._setPropsOnChild ) }
				</div>
                {footerButtonLabel && <button className={"popover__menu-footer"} onClick={(e)=>{
                    if (typeof footerButtonOnClick === "function")
                        footerButtonOnClick(e);
                    this.props.onClose( true );
                }}>
                    {footerButtonLabel}
                </button>}
			</PopoverComponent>
		);
	}

	_setPropsOnChild = (child: any) => {
		if ( child == null ) {
			return child;
		}

		const boundOnClose = this._onClose.bind( this, child.props.action );
		let onClick = boundOnClose;

		if ( child.props.onClick ) {
			onClick = over( [ child.props.onClick, boundOnClose ] );
		}

		let { autoFocusList } = this.props;
		if(autoFocusList){
			this.focus();
		}

		return React.cloneElement( child, {
			onClick: onClick,
		} );
	};

	focus = ()=>{
		setTimeout(()=>{
			let { rootClassName, className } = this.props;
			let parentClassName = rootClassName ? rootClassName : className;
			if(!isUndefined(parentClassName)){
				parentClassName = parentClassName.toString().split(' ')[0];
				let list:any = document.querySelector(`.${parentClassName} .popover__menu-item`);
				if(list) list.focus();
			}
		}, 100);
	}

	_onShow = () => {
		const elementToFocus = this.menu.current;

		this._previouslyFocusedElement = document.activeElement;

		if ( elementToFocus ) {
			elementToFocus.focus();
		}
	};

	/*
	 * Warning:
	 *
	 * This doesn't cover crazy things like a separator at the very top or
	 * bottom.
	 */
	_getClosestSibling = ( target: any, isDownwardMotion = true ): any[] => {
		const menu = this.menu.current;

		let first = menu.firstChild,
			last = menu.lastChild;

		if ( ! isDownwardMotion ) {
			first = menu.lastChild;
			last = menu.firstChild;
		}

		if ( target === menu ) {
			return first;
		}

		const closest = target[ isDownwardMotion ? 'nextSibling' : 'previousSibling' ];

		const sibling = closest || last;

		return isInvalidTarget( sibling )
			? this._getClosestSibling( sibling, isDownwardMotion )
			: sibling;
	};

	_onKeyDown = (event: any) => {

		let handled = false;
		let elementToFocus: any;

		if ( elementToFocus ) {
			elementToFocus.focus();
		}

		if ( handled ) {
			event.preventDefault();
		}
	};

	_onClose = (action: any) => {
		if ( this._previouslyFocusedElement ) {
			this._previouslyFocusedElement.focus();
			this._previouslyFocusedElement = null;
		}

		if ( this.props.onClose && !this.props.stopPropagation) {
			this.props.onClose( action );
		}
	};
}

export default PopoverMenu;
