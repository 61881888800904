import {createReducer} from "core/utils/create_reducer";
import {MW_LIST_RECEIVED, MW_RECEIVED} from "./constant";
import {combineReducers} from "redux";

const detail = createReducer([], {
    [MW_RECEIVED]: (state, opts) => {
        if (Object.keys(opts.data).length) {
            return opts.data;
        }
        return {...state};
    }
})
const list = createReducer({}, {
    [MW_LIST_RECEIVED]: (state, opts) => {
        if (Object.keys(opts.items).length) {
            return opts.items;
        }
        return {...state};
    }
})

export default combineReducers({
    detail,
    list
})