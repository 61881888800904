import Button from "core/components/v2/button";
import {
  ConversionStatusIcon,
  ExportIcon,
  ImportIcon,
  PlusIcon,
  RocketIcon,
} from "core/components/v2/svg/icons";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { incrementDashboardViewCount } from "store/dashboard-builder/api";
import { showToast } from "store/toast-alerts/actions";
import {
  receivedReportsBuilderList,
  removeItemFromDashboard,
} from "store/widgets/actions";
import {
  deleteBuilder,
  requestAvailableMetrics,
  requestReportBuilderList,
} from "store/widgets/api";
import {
  getAvailableMetrics,
  getReportWidgetsInflightStatus,
} from "store/widgets/selectors";
import GlobalSidePanel from "views/layouts/app/components/global-side-panel";
import GlobalHeader from "views/layouts/app/components/v2/global-header";
import { BreadCrumbItem } from "views/layouts/app/components/v2/global-header/model";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import {
  BuilderProps,
  ConvertedDashboardStats,
  CustomWidget,
  DashboardCfg,
  Widget,
  defaultWidgetBuilder,
} from "../../entities/builder.entities";
import "../_stylev2.scss";
import AddWidget from "../add-widget";
import AddFromDSCard from "../add-widget/add-from-ds-card";
import CreateNewWidgetCard from "../add-widget/create-new-widget-card";
import ManageDashboard, {
  DashboardOpsType,
  DashboardType,
} from "../manage-dashboard/manage-dashboard";
import ManageWidget from "../manage-widget";
import DashboardView from "./dashboard-view";
import StatsView from "./stats-view";

const WidgetView = (props: BuilderProps) => {
  const dispatch = useDispatch();

  const routeData = useContext(MwRouteContext);

  //TODO: Remove reducer and use in state
  const areWidgetsLoading = useSelector(getReportWidgetsInflightStatus);
  const availableMetricsData = useSelector(getAvailableMetrics);

  const availlableMetrics = availableMetricsData.availableMetrics;
  const datasetInflight = availableMetricsData.inflight;
  const { builderViewOptions } = props;

  const reportId = builderViewOptions?.report?.reportId;
  const reportLabel = builderViewOptions?.report?.reportName;
  const displayScope = builderViewOptions?.displayScope;
  const stats = builderViewOptions?.report?.metadata
    ?.stats as ConvertedDashboardStats[];

  // sourceSelection is used to add widgets from the existing data source
  // or to open the new create widget dialog
  const [sourceSelection, setSourceSelection] = useState<{
    isOpen: boolean;
    showAddWidgetsFromDS: boolean;
  }>({
    isOpen: false,
    showAddWidgetsFromDS: false,
  });
  // editWidgetData is used to create/update/clone widget
  const [editWidgetData, setEditWidgetData] = useState<
    CustomWidget | undefined
  >();
  const [openImpWidgetDialog, setOpenImpWidgetDialog] = useState(false);

  const [openConversionStatsDialog, setopenConversionStatsDialog] =
    useState(false);

  // widgets contains the list of widgets of the dashboard returned by the API
  // Each widget is being stored as <widgetId, Widget> pair.
  const [widgetsMap, setWidgetsMap] = useState<Record<number, CustomWidget>>(
    {}
  );

  // widgets is the list of widgets sorted according their position in the grid.
  const widgets = useMemo(
    () =>
      Object.values(widgetsMap).sort((w1, w2) => {
        if (!w1.layout || !w2.layout) return 0;
        if (w1.layout.y === w2.layout.y && w1.layout.x === w2.layout.x) {
          return w2.builderId - w1.builderId;
        }
        return w1.layout.y === w2.layout.y
          ? w1.layout.x - w2.layout.x
          : w1.layout.y - w2.layout.y;
      }),
    [widgetsMap]
  );

  // widgetGroups is the list of groups in which widgets are placed.
  const widgetGroups = useMemo(() => {
    const groups = new Set<string>();

    widgets.forEach((widget) => {
      if (widget.builderMetaData?.group_name)
        groups.add(widget.builderMetaData.group_name);
    });

    return Array.from(groups);
  }, [widgets]);

  const getCallerMsg = (msg: string) => {
    if (reportId) {
      return `Report ${reportId} ${msg}`;
    } else if (displayScope) {
      return `Display Scope ${displayScope} ${msg}`;
    }
    return "no caller";
  };

  // requestMetrics fetches the list of all the metrics available in the user's account.
  const requestMetrics = () => {
    if (datasetInflight) {
      return;
    }
    dispatch(requestAvailableMetrics());
  };

  // onWidgetsReceived is callback function to handle the api response
  const onWidgetsReceived = (
    success: boolean,
    inflight: boolean,
    data?: Widget[]
  ) => {
    if (!success || inflight || !data || !builderViewOptions) return;

    const newWidgetsMap: Record<number, CustomWidget> = {};
    data.forEach((item: Widget) => {
      if (!item?.scope?.meta_data?.RawMessage?.layouts) {
        item.scope.meta_data.RawMessage = item.scope.meta_data.RawMessage || {};
        item.scope.meta_data.RawMessage.layouts = {
          _scope_id: item.scope.id,
          h: 4,
          resizeHandles: ["se"],
          w: 4,
          x: 0,
          y: 0,
        };
      }

      const cw: CustomWidget = {
        key: item.key,
        builderConfig: item.config.RawMessage,
        builderId: item.id,
        label: item.label,
        scopeId: item.scope.id,
        widgetAppId: item.widget_app_id,
        layout: item.scope.meta_data.RawMessage.layouts || {},
        builderViewOptions,
        builderMetaData: item.meta_data.RawMessage || {},
      };
      newWidgetsMap[item.id] = cw;
    });

    if (Object.keys(newWidgetsMap).length) {
      setWidgetsMap(newWidgetsMap);
    }
  };

  // requestWidgets fetches the list of widgets of the dashboard.
  const requestWidgets = (caller: string) => {
    const requestPayload: {
      report_id?: number;
      reportName?: string;
      display_scope?: string;
    } = {};
    if (routeData.params.debug) {
      console.log("requestWidgets called from", caller);
    }
    if (reportId) {
      requestPayload.report_id = reportId;
      requestPayload.reportName = reportLabel || "";
    } else if (displayScope) {
      requestPayload.display_scope = displayScope;
    }

    dispatch(requestReportBuilderList(requestPayload, onWidgetsReceived));
  };

  // Increment the view count of the dashboard, whenever the builder view is loaded for the first time
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) return;

    isMounted.current = true;
    if (reportId) incrementDashboardViewCount(reportId);
  }, []);

  useEffect(() => {
    // TODO: Remove this and use v2 API.
    if (!availlableMetrics?.metrics.length) {
      requestMetrics();
    }

    // Cleanup
    return () => {
      dispatch(
        receivedReportsBuilderList({
          ...{
            data: {
              items: [],
            },
            layouts: [],
          },
          inflight: true,
        })
      );
    };
  }, []);

  useEffect(() => {
    // Reset the existing data from the state
    dispatch(
      receivedReportsBuilderList({
        ...{
          data: {
            items: [],
          },
          layouts: [],
        },
        inflight: true,
      })
    );

    // Fetch the widgets based on the dashboard type
    if (reportId) {
      requestWidgets(getCallerMsg("load"));
    } else if (displayScope) {
      requestWidgets(getCallerMsg("load"));
    }
  }, [reportId, displayScope]);

  const handleDeleteWidget = useCallback(
    (builderId: number) => {
      dispatch(
        deleteBuilder(
          builderId,
          (
            success: boolean,
            payload: {
              inflight: boolean;
            }
          ) => {
            if (success) {
              if (!payload.inflight) return;

              dispatch(showToast("success", "Builder Removed Successfully"));
              dispatch(removeItemFromDashboard(builderId));

              if (Object.hasOwn(widgetsMap, builderId)) {
                delete widgetsMap[builderId];
                setWidgetsMap({
                  ...widgetsMap,
                });
              }
            } else {
              dispatch(showToast("warning", "Failed to remove builder view"));
            }
          }
        )
      );
    },
    [widgetsMap]
  );

  const openCreateNewWidgetDialog = () => {
    if (!builderViewOptions) return;

    const cw = JSON.parse(JSON.stringify(defaultWidgetBuilder)) as CustomWidget;
    cw.builderViewOptions = builderViewOptions;
    cw.label = "";
    setEditWidgetData(cw);
  };

  // exportDashboardJSON creates the JSON file which contains configuration details of all the widgets
  // and downloads into the user's system.
  const exportDashboardJSON = () => {
    const viewOptions = props.builderViewOptions;
    if (!viewOptions) return;

    const request: DashboardCfg = {
      builderViewOptions: viewOptions,
      widgets: [],
    };

    widgets.forEach((widget) => {
      if (!widget.layout) return;
      const widgetKey = (widget.key || "no-key").split("##")[0];

      request.widgets.push({
        config: Array.isArray(widget.builderConfig) ? widget.builderConfig : [],
        label: widget.label,
        layout: widget.layout,
        widgetAppId: widget.widgetAppId,
        key: widgetKey,
        builderMetaData: widget.builderMetaData,
      });
    });

    const exportJson = JSON.stringify(request);
    const file = new Blob([exportJson], { type: "application/json" });

    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download =
      builderViewOptions?.report?.reportName ||
      builderViewOptions?.displayScope ||
      "dashboard.json";
    document.body.appendChild(element);
    element.click();
  };

  const renderImportExportButton = () => {
    return (
      <div className="import-export-btn-container">
        <Button
          outlined
          onClick={() => setOpenImpWidgetDialog(true)}
          prefixicon={<ImportIcon />}
        >
          Import Data
        </Button>
        <Button
          outlined
          onClick={exportDashboardJSON}
          prefixicon={<ExportIcon />}
        >
          Export Data
        </Button>
      </div>
    );
  };

  const renderAddWidgetButton = () => {
    return (
      <Button
        secondary
        onClick={() => {
          setSourceSelection({
            isOpen: true,
            showAddWidgetsFromDS: false,
          });
        }}
        prefixicon={<PlusIcon color="var(--color-primary)" />}
      >
        Add Widget
      </Button>
    );
  };

  // renderDisplayScopeHeader renders the header specific to the Scope-Based dashboard.
  const renderDisplayScopeHeader = () => {
    return (
      <div className="builder-view-header">
        {renderAddWidgetButton()}
        {renderImportExportButton()}
      </div>
    );
  };

  // renderReportHeader renders the header specific to the Report-Based dashboard.
  const renderReportHeader = () => {
    const breadcrumbs: BreadCrumbItem[] = [];
    if (reportLabel) {
      breadcrumbs.push({
        label: "Dashboards",
        link: "/dashboards",
      });
      breadcrumbs.push({
        label: reportLabel,
      });
    }

    return (
      <GlobalHeader
        breadcrumbs={breadcrumbs}
        showRefreshBtn={false}
        extraChildren={
          <>
            {renderAddWidgetButton()}
            <Button prefixicon={<ExportIcon />} onClick={exportDashboardJSON}>
              Export Dashboard
            </Button>
            {stats && (
              <Button
                prefixicon={<ConversionStatusIcon />}
                onClick={() => setopenConversionStatsDialog(true)}
              ></Button>
            )}
          </>
        }
        dataTestIdDatePicker="header-datepicker-widget-view"
      />
    );
  };

  const renderNoWidgetView = () => (
    <div className="no-widgets-content-body">
      <RocketIcon />
      <h5>Ready to Launch?</h5>
      <span>Let&apos;s add your first widget by selecting an option</span>
      <div className="cards">
        <AddFromDSCard
          onCardClick={() =>
            setSourceSelection({
              isOpen: true,
              showAddWidgetsFromDS: true,
            })
          }
        />
        <CreateNewWidgetCard onCardClick={() => openCreateNewWidgetDialog()} />
      </div>
    </div>
  );

  return (
    <div className="builder-view-container">
      {/* Header */}
      {reportId && renderReportHeader()}
      {displayScope && renderDisplayScopeHeader()}

      {/* Dialogs */}
      {sourceSelection.isOpen && (
        <AddWidget
          dashboardId={reportId}
          displayScope={displayScope}
          showWidgetsFromDS={sourceSelection.showAddWidgetsFromDS}
          onClose={(createNewWidget?: boolean) => {
            setSourceSelection({
              isOpen: false,
              showAddWidgetsFromDS: false,
            });
            // If createNewWidget is true, then open a dialog to create a new widget
            if (createNewWidget) openCreateNewWidgetDialog();
          }}
        />
      )}

      {editWidgetData && (
        <ManageWidget
          nestedProps={props?.nestedProps}
          existWidgetData={editWidgetData}
          onWidgetUpsert={(upsertedWidget?: CustomWidget) => {
            setEditWidgetData(undefined);

            if (!upsertedWidget) return;
            setWidgetsMap({
              ...widgetsMap,
              [upsertedWidget.builderId]: upsertedWidget,
            });
          }}
        />
      )}

      {openImpWidgetDialog && (
        <GlobalSidePanel
          isOpen
          size="xxs"
          title="Import Data"
          datePicker={false}
          onClose={() => setOpenImpWidgetDialog(false)}
        >
          <ManageDashboard
            type={DashboardOpsType.Import}
            dashboardType={DashboardType.ScopeBased}
            displayScope={displayScope}
            onClose={() => {
              requestWidgets(getCallerMsg("change"));
              setOpenImpWidgetDialog(false);
            }}
          />
        </GlobalSidePanel>
      )}

      {openConversionStatsDialog && (
        <GlobalSidePanel
          isOpen
          headerElements={<ConversionStatusIcon />}
          //size="xxs"
          title={`Conversion Status : ${reportLabel}`}
          datePicker={false}
          onClose={() => setopenConversionStatsDialog(false)}
          onBackBtnClick={() => setopenConversionStatsDialog(false)}
        >
          <StatsView stats={stats} />
        </GlobalSidePanel>
      )}

      {/* Dashboard View */}
      <div
        className="builder-view-body"
        style={{ marginLeft: reportId ? "12px" : "" }}
      >
        <div className="builder-view-body-content">
          {!areWidgetsLoading && widgets.length <= 0 && renderNoWidgetView()}

          {widgets.length > 0 && (
            <DashboardView
              widgets={widgets}
              widgetGroups={widgetGroups}
              builderProps={props}
              onEditWidget={setEditWidgetData}
              onDeleteWidget={handleDeleteWidget}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WidgetView;
