import {createReducer} from "core/utils/create_reducer";
import {combineReducers} from "redux";
import {AGENT_INTEGRATION_DETAIL_RECEIVED} from "store/task_management/constant";


const list = createReducer({}, {
    [AGENT_INTEGRATION_DETAIL_RECEIVED]: (state, opts) => {
        if (Object.keys(opts.result)?.length) {
            return opts.result;
        }
        return {...state};
    }
})




export default combineReducers({
    list,

})