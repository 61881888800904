import * as React from "react";

type DescriptionProps = {
    description: string;
    classes?: string;
}

export default function Description(props: DescriptionProps) {
    return <div className={"description " + props.classes} dangerouslySetInnerHTML={{ __html: props.description }}/>
}
export const renderTab = () => {
    return `&nbsp;&nbsp;&nbsp;&nbsp`;
}