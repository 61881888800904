import Button from "core/components/button";
import Dialog from "core/components/dialog";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RumSettingDialog from "views/modules/installation/components/rum/rum-setting-dialog";

import {CopyIconNew, DeleteIcon} from "core/components/svg/icons";
import { requestClientTokenDelete, requestClientTokenList } from "store/settings/installation/api";
import { getClientTokenList } from "store/settings/installation/selectors";
import { showToast } from "store/toast-alerts/actions";
import CodeViewer from "core/components/code-viewer";

export default function RumSetting(props: any) {
    const dispatch = useDispatch()
    const language = props.data;
    const packageIconLink = props?.data?.packageIconLink;
    const [editDialog, setEditDialog] = useState(false)
    const [dltDialog, setDltDialog] = useState(false)
    const [token, setToken] = useState("")
    const [isData, setIsData] = useState(false)
    const [isKey, setIsKey] = useState("")
    const [creatApp, setCreateApp] = useState(false)

    useEffect(() => {
        dispatch(requestClientTokenList())
    }, [])
    const ApplicationList = useSelector(getClientTokenList)
    const filteredApplicationList = ApplicationList.length > 0 ? ApplicationList.filter((obj?: any) => obj?.meta_data?.RawMessage?.language === language) : [];

    const closeEditDialog = () => {
        setEditDialog(false)
        setToken("")
    }
    const closeDltDialog = () => {
        setDltDialog(false)
    }
    const deleteClientToken = (token: string) => {
        dispatch(requestClientTokenDelete({ token: token }, (res?: any) => {
            dispatch(showToast("success", "Token deleted successfully"))
            setToken("")
            setDltDialog(false)
        }))
    }

    interface TokenData {
        id: number;
        account_id: number;
        key: string;
        token: string;
        meta_data: {
            RawMessage: {
                language: string;
                name: string;
                origin: string[];
                tracePropagationTargets: string[];
            };
            Valid: boolean;
        };
        type: string;
        project_id: number;
    }

    const getCodeSnip = (item : TokenData) => {
        setIsKey(item?.meta_data?.RawMessage?.name)
        setIsData(true)
        setCreateApp(false)
    }

    const createNewApplication = () => {
        setCreateApp(true)
    }


    const jsCommand = `<script src="https://cdnjs.middleware.io/browser/libs/0.0.1/middleware-rum.min.js" type="text/javascript"></script>`;
    const jsCommand2 = `<script>
        if (window.Middleware){
            Middleware.track({
                serviceName:" ",
                projectName:" ",
                accountKey:" ",
                target:"${window.location.origin}",
                tracePropagationTargets: [[/localhost:3000/i]]
            })
        }
 </script>
`;
    const androidInstallationCommand = `implementation 'io.github.middleware-labs:android-sdk:+`
    const androidCommand =
        `import static io.middleware.android.sdk.utils.Constants.APP_VERSION;
            
import android.app.Application;

import java.time.Duration;

import io.middleware.android.sdk.Middleware;
import io.opentelemetry.api.common.Attributes;

public class MiddlewareApplication extends Application {
    private final String targetUrl = "${window.location.origin}";
    private final String rumAccessToken = " ";

    @Override
    public void onCreate() {
        super.onCreate();
    
        Middleware.builder()
            .setGlobalAttributes(Attributes.of(APP_VERSION, BuildConfig.VERSION_NAME))
            .setTarget(targetUrl)
            .setServiceName(" ")
            .setProjectName(" ")
            .setRumAccessToken(rumAccessToken)
            .setSlowRenderingDetectionPollInterval(Duration.ofMillis(1000))
            .setDeploymentEnvironment("PROD")
            .build(this);
}
    `;

    const iosInstallationCommand = `.package(url: "https://github.com/middleware-labs/middleware-ios"),`;
    const iosUseCommand = `.target(name: "YourApp", dependencies: [
    .product(name: "MiddlewareRum", package: "middleware-ios")
],`;
    const iosScript = `import MiddlewareRum

MiddlewareRumBuilder()
    .globalAttributes(["customerId" : "123456"])
    .target("${window.location.origin}")
    .serviceName(" ")
    .projectName(" ")
    .rumAccessToken(" ")
    .deploymentEnvironment("PROD")
    .build()
        `;

    const reactNativeInstallation = `# yarn
yarn add @middleware.io/middleware-react-native`;

    const reactNativeScript = `import { MiddlewareWrapper, type ReactNativeConfiguration } from '@middleware.io/middleware-react-native';
        
const MiddlewareConfig: ReactNativeConfiguration = {
    serviceName: ' ',
    projectName: ' ',
    accountKey: ' ',
    target: '${window.location.origin}',
    deploymentEnvironment: 'PROD',
    globalAttributes: {
        name: ' ',
    },
};

export default function App() { 
    return (
      <MiddlewareWrapper configuration={MiddlewareConfig}>
        // Application Components
      </MiddlewareWrapper>
    );
  }
`;


    function copyToClipboard(text: string) {

        navigator.clipboard.writeText(text)
            .then(() => {
                dispatch(showToast("success", "Copied to Clipboard !"))
            });
    }

    return (
        <>
            <div className={"markdown-body installation-docs apm-installation-doc vercel-doc installation-page rum-javascript"}>
                <div className={"page-body rum-page-body"}>
                    <div className={"list-area"}>
                        {filteredApplicationList.map((item: TokenData, index: number) => (
                            <div
                                key={index}
                                className={`d-flex ${index === 0 && !isKey ? 'active' : isKey === item?.meta_data?.RawMessage?.name ? 'active' : ''}`}
                                onClick={() => {
                                    getCodeSnip(item);
                                    // setToken(item?.token);
                                    // setEditDialog(true)
                                }}
                            >
                                <div className="app-name-area">
                                    {item?.meta_data?.RawMessage?.name}
                                    <span>{index + 1}</span>
                                </div>
                                <div className="actions-rum-token">
                                    <div className="delete-token" onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setToken(item?.token);
                                        setDltDialog(true);
                                    }}>
                                        <span><DeleteIcon/></span>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {filteredApplicationList.length > 0 ? <div className={'create-add-app'}>
                            <Button onClick={createNewApplication} primary>Create New Application</Button>
                        </div> : ''}
                    </div>

                    {creatApp && <div className={'data active'}>
                        <RumSettingDialog language={language}/>
                    </div>}

                    {!creatApp ? (filteredApplicationList.length > 0 ? filteredApplicationList.map((item: TokenData, index: any) => {
                        if (isKey === item?.meta_data?.RawMessage?.name || (index === 0 && !isKey)) {
                            return (
                                <div key={index} className={'data active'}>
                                    <RumSettingDialog
                                        language={language}
                                        token={item?.token}
                                        onDiscardClick={closeEditDialog}
                                        closeDialog={closeEditDialog}
                                    />
                                </div>
                            );
                        }
                    }) : <div className={'data active'}>
                        <RumSettingDialog language={language}/>
                    </div>) : ''}

                    <div className={'rum-no-data'}>
                    {filteredApplicationList.length <= 0 && language == 'javascript' ? <div>
                        <p>1. Add the following script tag to the head section of your index.html file.</p>
                        <div className={"installation-page rum-script"}>
                            <CodeViewer showLineNumbers={false}
                                        language={'javascript'}>{jsCommand}</CodeViewer>
                            <span className={"rum-copy-btn"}
                                  onClick={() => copyToClipboard(jsCommand)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                        </div>
                        <p>2. Place below script tag in the head section of your index.html file.</p>

                        <div className={"installation-page rum-script"}>
                            <CodeViewer showLineNumbers={false}
                                        language={'javascript'}>{jsCommand2}</CodeViewer>
                            <span className={"rum-copy-btn"}
                                  onClick={() => copyToClipboard(jsCommand2)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                        </div>
                    </div> : ''}
                    {filteredApplicationList.length <= 0 && language == 'android' ? <div>
                        <p>1. Add the following dependency in build.gradle file.</p>
                        <div className={"installation-page rum-script"}>
                            <CodeViewer showLineNumbers={false}
                                        language={'javascript'}>{androidInstallationCommand}</CodeViewer>
                            <span className={"rum-copy-btn"}
                                  onClick={() => copyToClipboard(androidInstallationCommand)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                        </div>
                        <p>2. Place below class file in your Android application. This will instantiate Middleware
                            SDK.</p>

                        <div className={"installation-page rum-script"}>
                            <CodeViewer showLineNumbers={false}
                                        language={'javascript'}>{androidCommand}</CodeViewer>
                            <span className={"rum-copy-btn"}
                                  onClick={() => copyToClipboard(androidCommand)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>

                        </div>
                    </div> : ''}
                    {filteredApplicationList.length <= 0 && language == 'ios' ? <div>
                        <p>1. Add the following dependencies section in <code>Package.swift</code> file.</p>
                        <div className={"installation-page rum-script"}>
                            <CodeViewer showLineNumbers={false}
                                        language={'javascript'}>{iosInstallationCommand}</CodeViewer>
                            <span className={"rum-copy-btn"}
                                  onClick={() => copyToClipboard(iosInstallationCommand)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                        </div>
                        <p>2. To use Middleware SDK add the following
                            in <code>targets</code> in <code>Package.swift</code> file.</p>
                        <div className={"installation-page rum-script"}>
                            <CodeViewer showLineNumbers={false}
                                        language={'javascript'}>{iosUseCommand}</CodeViewer>
                            <span className={"rum-copy-btn"}
                                  onClick={() => copyToClipboard(iosUseCommand)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>

                        </div>
                        <p>3. Add below code file in your iOS application. This will instantiate Middleware SDK.</p>
                        <div className={"installation-page rum-script"}>
                            <CodeViewer showLineNumbers={false}
                                        language={'javascript'}>{iosScript}</CodeViewer>
                            <span className={"rum-copy-btn"}
                                  onClick={() => copyToClipboard(iosScript)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>

                        </div>
                    </div> : ''}
                    {filteredApplicationList.length <= 0 && language == 'react-native' ? <div>
                        <p>1. Install the library using either npm or yarn.</p>
                        <div className={"installation-page rum-script"}>
                            <CodeViewer showLineNumbers={false}
                                        language={'javascript'}>{reactNativeInstallation}</CodeViewer>
                            <span className={"rum-copy-btn"}
                                  onClick={() => copyToClipboard(reactNativeInstallation)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                        </div>
                        <p>2. Initialize the library in your app lifecycle.</p>
                        <div className={"installation-page rum-script"}>
                            <CodeViewer showLineNumbers={false}
                                        language={'javascript'}>{reactNativeScript}</CodeViewer>
                            <span className={"rum-copy-btn"}
                                  onClick={() => copyToClipboard(reactNativeScript)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                        </div>
                    </div> : ''}
                    </div>

                </div>
            </div>

            <Dialog isOpen={dltDialog}
                    position={'center'}
                    overlayClassName={'user-delete-confirmation__dialog setting-confirmation-dialog'}
                    onClose={closeDltDialog}
            >

                <h4>Are you sure you want to delete application?</h4>
                <div className={'dialog-buttons-wrapper'}>
                    <Button simple onClick={closeDltDialog}>cancel</Button>
                    <Button scary onClick={() => {
                        deleteClientToken(token)
                    }}>Delete</Button>
                </div>
            </Dialog>
        </>
    )
}