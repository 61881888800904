import classNames from "classnames";
import Button from "core/components/v2/button";
import FormInputValidation from "core/components/v2/form/form-input-validation";
import FormTextArea from "core/components/v2/form/form-text-area";
import { Popover, PopoverPlacement } from "core/components/v2/popover";
import { PopoverItem } from "core/components/v2/popover/item";
import { LinkToDoc } from "core/components/v2/svg/icons";
import Tooltip from "core/components/v2/tooltip";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { requestCustomerSupport } from "store/api";
import { getThemesMode } from "store/selectors";
import {
  APMIcon,
  AlertsIcon,
  AppStoreIcon,
  DashboardBuilderIcon,
  HelpDocsIcon,
  HomeIcon,
  InfrastructureIcon,
  LogoSvg,
  LogsIcon,
  RumIcon,
  SettingsIcon,
  SyntheticMonitorIcon,
} from "views/layouts/app/nav/_icons";
import { NavLoader } from "views/layouts/app/nav/nav-loader";
import { defaultInstallationPath } from "views/modules/installation-v2/entity";
import useMixPanel from "../mix-panel-events/use-mix-panel";
import ProfileAvatarV2 from "./profile-avatar-v2";

const NavItems: any = [
  {
    path: "/dashboard",
    keys: ["home", "dashboard", ""],
    title: "Home",
    icon: HomeIcon,
  },
  {
    path: "/infrastructure/host/list",
    keys: ["infrastructure"],
    title: "Infrastructure",
    icon: InfrastructureIcon,
  },
  {
    path: "/apm/services",
    keys: ["apm"],
    title: "APM",
    icon: APMIcon,
  },
  {
    path: "/logs",
    keys: ["logs"],
    title: "Logs",
    icon: LogsIcon,
  },
  {
    path: "/alert/rules",
    keys: ["alert"],
    title: "Alerts",
    icon: AlertsIcon,
  },
  {
    path: "/dashboards",
    keys: ["dashboards"],
    title: "Dashboard Builder",
    icon: DashboardBuilderIcon,
  },
  {
    path: "/synthetics",
    keys: ["synthetics"],
    title: "Synthetic Monitoring",
    icon: SyntheticMonitorIcon,
  },
  // {
  //     path: "/advisor/recommendations",
  //     keys: ["advisor"],
  //     title: "Advisor",
  //     icon: AdvisorIcon,
  // },
  {
    path: "/real-user-monitoring",
    keys: ["real-user-monitoring"],
    title: "Real User Monitoring",
    icon: RumIcon,
  },
  // {
  //     path: "/metrics/aws/cloudwatch",
  //     keys: ["metrics"],
  //     title: "Metrics",
  //     icon: MetricsIcon,
  // },
];
const FooterItems: any = [
  {
    path: defaultInstallationPath,
    keys: ["app-store", "installation"],
    title: "Installation",
    icon: AppStoreIcon,
  },
  {
    path: "/settings",
    keys: ["settings"],
    title: "Settings",
    icon: SettingsIcon,
  },
];

let stopPropagation = false;
const MainNavMenu = function (props: any) {
  const dispatch = useDispatch();
  const context = useContext<ContextProps>(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useSelector(getThemesMode);
  const _path: any = (location.pathname || "").split("/")[1];
  const isDark = theme == "dark";
  const con = React.useContext(AppContext);
  const [isHelpPop, setIsHelpPop] = useState(false);
  const [isCustomerSupport, setIsCustomerSupport] = useState(false);
  const [supportContent, setSupportContent] = useState("");
  const [showError, setShowError] = useState(false);
  const [isInstalled, setIsInstalled] = useState(true);
  const [isContactSupportReqSending, setIsContactSupportReqSending] =
    useState(false);

  const divRef = useRef<HTMLDivElement | null>(null);
  const handleClickOutsideRef = useRef<(event: MouseEvent) => void>(() => {
    return;
  });

  const handleClickOutside = (event: any) => {
    if (
      divRef.current &&
      event.target instanceof Node &&
      !divRef.current.contains(event.target)
    ) {
      setIsCustomerSupport(false);
    }
    if (!divRef.current) {
      stopPropagation = false;
    }
  };

  useEffect(() => {
    handleClickOutsideRef.current = handleClickOutside;
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideRef.current);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideRef.current);
    };
  }, []);

  const togglePopover = () => {
    stopPropagation = false;
    setIsHelpPop(!isHelpPop);
  };

  const handleSubmit = () => {
    setShowError(true);
    if (supportContent.length > 5) {
      dispatch(
        requestCustomerSupport(
          {
            supportContent: supportContent,
            customerEmail: context?.user?.email || "",
          },
          (inflight, res) => {
            setIsContactSupportReqSending(inflight);
            if (res?.status === 200) {
              stopPropagation = false;
              setIsCustomerSupport(false);
              setSupportContent("");
              setIsHelpPop(false);
            }
          }
        )
      );
    }
  };

  const customerSupportPopoverContent = (
    <div ref={divRef} className={"customer-support-card"}>
      <div className="support-contact-textarea-container">
        <FormTextArea
          id="support-contact-textarea"
          placeholder={
            "Looking for answers? Write your questions here for quick assistance."
          }
          value={supportContent}
          onChange={(value) => {
            setSupportContent(value);
          }}
          rows={4}
          isError={showError && supportContent.length < 2}
        />
        {showError && supportContent.length < 2 && (
          <FormInputValidation
            isError
            text={"Support content should be atleast 2 characters long"}
          />
        )}
      </div>
      <Button
        primary
        loading={isContactSupportReqSending}
        onClick={handleSubmit}
      >
        Send
      </Button>
    </div>
  );

  const helpPopoverContent = (
    <div>
      <PopoverItem
        onClick={() => {
          window.open("https://docs.middleware.io/", "_blank");
        }}
      >
        <div
          className="popover-menu-item help-doc"
          onMouseOver={() => setIsCustomerSupport(false)}
        >
          <div>Help Documents</div>
          <LinkToDoc color="var(--color-primary)" />
        </div>
      </PopoverItem>
      <PopoverItem onClick={() => {}}>
        <Popover
          content={customerSupportPopoverContent}
          isVisible={isCustomerSupport}
          rootClassName="contact-support-popover"
          onClose={() => {
            setIsCustomerSupport(false);
          }}
          placement={PopoverPlacement.Right}
          shouldCloseOnInnerClick={false}
        >
          <div
            className="contact-support-label popover-menu-item"
            onMouseOver={() => setIsCustomerSupport(true)}
          >
            Contact Support
          </div>
        </Popover>
      </PopoverItem>
      {/* commenting for now as chat widget can be required again */}
      {/* <PopoverItem
        onClick={() => {
          const win: any = window;
          win.acquire = win.acquire || [];
          win.acquire.push(function (app: any) {
            app.applyAction("update-settings", {
              "crm.hide_widget_in_web": "no",
            });
            app.ui.status("max");
          });
          sessionStorage.setItem("mw_hide_acquire_chat_support", "no");
        }}
      >
        <span className="popover-menu-item">Chat with Support</span>
      </PopoverItem> */}
    </div>
  );

  const isDataInstalled =
    context?.user?.misc_details?.has_data ||
    context?.user?.misc_details?.skip_to_dashboard;
  // const isVercelRedirectionExist = context?.user?.misc_details?.redirect_to_vercel || false;

  useEffect(() => {
    if (
      isDataInstalled != undefined &&
      !isDataInstalled &&
      !location.pathname.includes("installation") &&
      !location.pathname.includes("profile") &&
      !location.pathname.includes("settings")
    ) {
      sendEvent("open installation page when no agent installed");
      navigate(defaultInstallationPath);
    }
  }, [location.pathname, isInstalled]);
  useEffect(() => {
    if (isDataInstalled != undefined) {
      setIsInstalled(isDataInstalled);
    }
  }, [isDataInstalled]);

  // Commenting this for short time. Will remove, once vercel's log-drain works as expected.
  /*useEffect(()=>{
        if(isVercelRedirectionExist != undefined
            && isVercelRedirectionExist){
            const urlHash = (window.location.hash).replace('#', '');
            const isInstallationPage = location.pathname.includes("installation");
            const isProfilePage = location.pathname.includes("profile");
            const isSettingsPage = location.pathname.includes("settings");

            if ((!isInstallationPage || (isInstallationPage && urlHash === ''))
                && !isProfilePage && !isSettingsPage) {
                navigate("/installation?installation_integration=vercel#instrumentation/apm")
            }
        }
    },[isVercelRedirectionExist])*/
  const sendEvent = useMixPanel();

  return (
    <React.Suspense fallback={<NavLoader />}>
      <div className={"nav-layout__context"}>
        <div className={"main-nav-menu__context"}>
          <div className={"sidebar__nav-item"}>
            <div className={"site__logo"}>
              <Link to={"/dashboard"}>
                <LogoSvg />
              </Link>
            </div>

            <ul className={"sidebar__nav-item__list-items"}>
              {NavItems.map((item: any, index: number) => {
                let isActive = item.keys.includes(_path);
                const Icon = item.icon;
                {
                  isHelpPop ? (isActive = "") : "";
                }
                return (
                  <Tooltip content={item.title} position={"right"} key={index}>
                    <li
                      key={index}
                      className={classNames(item.class ? item.class : "", {
                        active: isActive,
                        "is-disabled-menu": !isInstalled,
                      })}
                      onClick={() => {
                        if (isInstalled) {
                          sendEvent(`click - ${item.title} menu`);
                        }
                      }}
                    >
                      {isInstalled && (
                        <Link to={item.path}>
                          <Icon
                            isDark={isDark}
                            active={isActive}
                            alertcount={con?.user}
                          />
                        </Link>
                      )}
                      {!isInstalled && (
                        <Icon
                          isDark={isDark}
                          active={isActive}
                          alertcount={con?.user}
                        />
                      )}
                      {/*<Tooltip id={`nav_tip__${index}`} place="right" type="dark" effect="solid">*/}
                      {/*    <span>{item.title}</span>*/}
                      {/*</Tooltip>*/}
                    </li>
                  </Tooltip>
                );
              })}
            </ul>
          </div>
          <div className={"sidebar__nav-footer"}>
            <div>
              <Popover
                isVisible={isHelpPop}
                onClose={() => setIsHelpPop(false)}
                content={helpPopoverContent}
                rootClassName="help-popover"
                placement={PopoverPlacement.Right}
                shouldCloseOnInnerClick={false}
              >
                <span
                  className="help-menu-trigger"
                  onClick={() => togglePopover()}
                >
                  <Button
                    borderless
                    className="help-btn"
                    background={false}
                    prefixicon={<HelpDocsIcon active={isHelpPop} />}
                  />
                </span>
              </Popover>
            </div>

            {FooterItems.map((item: any, index: number) => {
              let isActive = item.keys.includes(_path);
              const Icon = item.icon;

              return (
                <Tooltip key={index} content={item.title} position={"right"}>
                  <span
                    key={index}
                    data-tip
                    data-for={`footer-nav_tip__${index}`}
                    className={item.class ? item.class : ""}
                  >
                    <Link to={item.path}>
                      {isHelpPop ? (isActive = "") : ""}
                      <Icon isDark={isDark} active={isActive} />
                    </Link>
                  </span>
                </Tooltip>
              );
            })}
            <ProfileAvatarV2 navigate={navigate} />
          </div>
        </div>
      </div>
    </React.Suspense>
  );
};

export default MainNavMenu;
