import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "rrweb-player/dist/style.css";
import { requestSessionRecordings } from "store/rum/api";
import Player from "views/modules/rum/panel/rr-player";
import "../../_style.scss";

const SessionReplay = (props: any) => {
  const dispatch = useDispatch();

  // TODO: Define type
  const [sessionRecording, setSessionRecording] = useState<any>({
    is_loading: false,
    data: [],
  });

  // TODO: Define type
  const [player, setPlayer] = useState<any>();

  useEffect(() => {
    getSessionRecordings();
  }, []);

  const getSessionRecordings = () => {
    const queryParams = {
      session_id: props.session.session_id,
      start_ts: props.session.start_date,
      end_ts: props.session.end_date,
    };

    setSessionRecording((prevState: any) => ({
      data: [],
      is_loading: true,
    }));

    dispatch(
      requestSessionRecordings(queryParams, (status, res) => {
        if (status) {
          if (res.items.length > 0) {
            // TODO: Define type
            const playerData: any = [];
            const data = res.items;

            data.filter((item: any) => {
              playerData.push({
                data: JSON.parse(item.attributes.data),
                timestamp: parseInt(item.attributes.timestamp),
                type: parseInt(item.attributes.type),
              });
            });

            setSessionRecording({
              is_loading: false,
              data: playerData,
            });
          } else {
            setSessionRecording({
              is_loading: false,
              data: [],
            });
          }
        }
      })
    );
  };

  const playerCallback = (_player: any) => {
    _player?.$set({
      width: "inherit",
    });
    setPlayer(_player);
  };

  return (
    <div className="session-panel">
      <div className={"session-tabs"}>
        <div className={"session-replay-container"}>
          <div className={"session-replay-player"}>
            <div className={"session-player"}>
              {sessionRecording.is_loading && (
                <div className={"loading"}>Loading...</div>
              )}

              {!sessionRecording.is_loading &&
                sessionRecording.data.length > 0 && (
                  <Player
                    session={props.session}
                    events={sessionRecording.data}
                    playerCallback={playerCallback}
                    timestampToJumpVideo={props.timestampToJumpVideo}
                  />
                )}

              {!sessionRecording.is_loading &&
                sessionRecording.data.length <= 0 && (
                  <div className={"no-session-recording"}>
                    {" "}
                    No Session Recoding found{" "}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionReplay;
