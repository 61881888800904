import React from "react";
import Card from "core/components/v2/card";
import "./_style.scss";
import InstallationCardRow from "./card-row";
import { InstallationCardData } from "./entity";

const InstallationCard = (props: InstallationCardData) => {
  const { title, titleInfo, items } = props;

  return (
    <div className={"installation-card-view"}>
      <Card bordered title={title} titleInfo={titleInfo}>
        {items.map((item, index) => {
          return <InstallationCardRow items={item.items} key={index} />;
        })}
      </Card>
    </div>
  );
};
export default InstallationCard;
