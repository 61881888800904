import { createReducer } from "core/utils/create_reducer";
import { combineReducers } from "redux";
import {
  REMOVE_TOAST_ALERTS,
  REQUEST_TOAST_ALERTS,
} from "store/toast-alerts/constant";

const list = createReducer([], {
  [REQUEST_TOAST_ALERTS]: (state, opts) => {
    if (opts?.toastType) {
      const _update = Object.assign([], state);
      if (_update && Array.isArray(_update)) {
        _update.push({
          uniqueId: opts?.uniqueId
            ? opts.uniqueId
            : Math.random().toString(36).substring(3),
          duration: opts?.duration > 6000 ? opts.duration : 6000,
          toastType: opts.toastType || "default",
          message: opts.message || "Empty content!",
          title: opts.title ?? "",
          onBtnClick: opts.onBtnClick,
          onPrimaryBtnClick: opts.onPrimaryBtnClick,
          onSecondaryBtnClick: opts.onSecondaryBtnClick,
          btnText: opts.btnText,
          primaryBtnText: opts.primaryBtnText,
          secondaryBtnText: opts.secondaryBtnText,
        });
        return [..._update];
      }
    }
    return [...state];
  },
  [REMOVE_TOAST_ALERTS]: (state, opts) => {
    if (opts?.toastIndex >= 0) {
      const _update = Object.assign([], state);
      if (_update && Array.isArray(_update)) {
        _update.splice(opts.toastIndex, 1);
        return [..._update];
      }
    }
    return [...state];
  },
});
export default combineReducers({
  list,
});
