import {
  AndroidV2,
  ApacheIcon,
  AppleV2,
  AWSECSNewDocsSVG,
  AwsTelemetryV2,
  CassandraIcon,
  ClickhouseIcon,
  CloudSvgV2,
  DenoSvgV2,
  DockerNewIcon,
  DotNetIconV2,
  ElasticSearchIcon,
  GoSvgV2,
  JavascriptV2,
  JavaSvgV2,
  JiraIcon,
  KafkaIconV2,
  KubernetesColorSvgV2,
  LinuxSvgV2,
  MicrosoftTeamIcon,
  MongoDBIcon,
  NextJsSvgV2,
  NodeJsSvgV2,
  OpenTelemetryV2,
  OpsgenieIconIntegration,
  OracleDBIcon,
  PagerDutyIconIntegration,
  PhpSvgV2,
  PostgreSQLIconIntegration,
  PrometheusIcon,
  PythonSvgV2,
  ReactNativeV2,
  RedisIcon,
  RedpandaIcon,
  RubyIconV2,
  ScalaSvgV2,
  SlackIconV2,
  VercelIconV2,
  WindowIconInst,
} from "core/components/svg/icons";
import React from "react";
import CloudflareAPMInstrument from "views/modules/integrations/components/dialog/apm-dialog/cloudflare-dialog";
import DenoAPMInstrument from "views/modules/integrations/components/dialog/apm-dialog/deno-dialog";
import DotNetAPMInstrument from "views/modules/integrations/components/dialog/apm-dialog/dot-net-dialog";
import GoAPMInstrument from "views/modules/integrations/components/dialog/apm-dialog/go-dialog";
import JavaAPMInstrument from "views/modules/integrations/components/dialog/apm-dialog/java-dialog";
import NextJsAPMInstrument from "views/modules/integrations/components/dialog/apm-dialog/nextjs-dialog";
import NodeJsAPMInstrument from "views/modules/integrations/components/dialog/apm-dialog/nodejs-dialog";
import PHPAPMInstrument from "views/modules/integrations/components/dialog/apm-dialog/php-dialog";
import PythonAPMInstrument from "views/modules/integrations/components/dialog/apm-dialog/python-dialog";
import RubyAPMInstrument from "views/modules/integrations/components/dialog/apm-dialog/ruby-dialog";
import ScalaAPMInstrument from "views/modules/integrations/components/dialog/apm-dialog/scala-dialog";
import VercelAPMInstrument from "views/modules/integrations/components/dialog/apm-dialog/vercel-dialog";
import OpenTelemetryLogsInstrument from "views/modules/integrations/components/dialog/data-ingestion-dialog/opentelemetry-logs-dialog";
import OpenTelemetryMetricInstrument from "views/modules/integrations/components/dialog/data-ingestion-dialog/opentelemetry-metric-dialog";
import AWSECSInfraInstrument from "views/modules/integrations/components/dialog/infra-dialog/aws-ecs-dialog";
import DockerInfraInstrument from "views/modules/integrations/components/dialog/infra-dialog/docker-dialog";
import K8sInfraInstrument from "views/modules/integrations/components/dialog/infra-dialog/k8s-dialog";
import LinuxInfraInstrument from "views/modules/integrations/components/dialog/infra-dialog/linux-integration-dialog";
import WindowInfraInstrument from "views/modules/integrations/components/dialog/infra-dialog/window-dialog";
import AndroidRUMInstrument from "views/modules/integrations/components/dialog/rum-dialog/android-rum-dialog";
import IosRUMInstrument from "views/modules/integrations/components/dialog/rum-dialog/ios-rum-dialog";
import JavascriptRUMInstrument from "views/modules/integrations/components/dialog/rum-dialog/javascript-rum-dialog";
import ReactNativeRUMInstrument from "views/modules/integrations/components/dialog/rum-dialog/react-rum-dialog";
export const StepsEnum = {
  Code: "code",
  DeploymentType: "deployment_type",
  CustomLogs: "custom_logs",
  SeeData: "see_data",
  InstalledApp: "install_app",
  ProjectList: "projects_list",
  DeployCode: "deploy_code",
};
import {
  PostgreSQLIcon,
  MySQLIcon,
  MariadbIcon,
} from "core/components/v2/svg/database-icons"

// export const StepsEnum = {
//   Code: "code",
//   InstalledApp: "install_app",
//   ProjectList: "projects_list",
// };

export type StepsEntity = {
  [key: string]: {
    title: string;
  };
};

export type IntegrationItem = {
  title?: string;
  desc?: string;
  icon: any;
  link: string;
  component: any;
  borderColor?: string;
  key: string;
  disabled?: boolean;
  name?: string;
  category?: string[];
  tagline?: string;
  subText?: string;
  docs?: string;
  supportAgent?: any;
  releaseNote?: any;
  features?: any;
  metricCollect?: any;
  overviewImage?: any;
  hostConfig?: string[];
};
const IntegrationConfigKey = {
  Jira: "Jira",
  MsTeam: "Microsoft Teams",
  Opsgenie: "Opsgenie",
  PagerDuty: "PagerDuty",
  Slack: "Slack",
  Postgres: "postgres_config",
  MongoDb: "mongodb_config",
  MySQL: "mysql_config",
  MariaDB: "mariadb_config",
  Redis: "redis_config",
  Clickhouse: "clickhouse_config",
  Cassandra: "cassandra_config",
  ElasticSearch: "elasticsearch_config",
  Prometheus: "prometheus_config",
  Redpanda: "redpanda_config",
  Kafka: "kafka_config",
  OracleDB: "oracledb_config",
  Apache: "apache_config",
  AWS: "aws_config",
};
export const InstrumentationIntegrationItems: IntegrationItem[] = [
  {
    title: "Linux",
    desc: "Linux is an open-source Unix-like operating system based on the Linux Kernel. Download the Linux Agent for DEB or RPM.",
    icon: <LinuxSvgV2 />,
    link: "/installation?installation_integration=linux#instrumentation/infrastructure",
    component: <LinuxInfraInstrument />,
    borderColor: "#B9B9B9",
    key: "linux",
    category: ["allinstrumentation", "infrastructure"],
  },
  {
    title: "Windows",
    desc: "Windows is an operating system developed by Microsoft. Download the Windows Agent to monitor your infrastructure’s performance.",
    icon: <WindowIconInst />,
    link: "/installation?installation_integration=windows#instrumentation/infrastructure",
    component: <WindowInfraInstrument />,
    borderColor: "#0178d499",
    key: "windows",
    category: ["allinstrumentation", "infrastructure"],
  },
  {
    title: "Docker",
    desc: "Docker is a platform for developing, shipping, and running applications with standardized units called containers.",
    icon: <DockerNewIcon />,
    link: "/installation?installation_integration=docker#instrumentation/infrastructure",
    component: <DockerInfraInstrument />,
    borderColor: "#2497ED",
    key: "docker",
    category: ["allinstrumentation", "infrastructure"],
  },
  {
    title: "Kubernetes",
    desc: "Kubernetes is an open-source container orchestration platform that helps you deploy, manage and scale your software.",
    icon: <KubernetesColorSvgV2 />,
    link: "/installation?installation_integration=kubernetes#instrumentation/infrastructure",
    component: <K8sInfraInstrument />,
    borderColor: "#326CE5",
    key: "kubernetes",
    category: ["allinstrumentation", "infrastructure"],
  },
  {
    title: "Amazon ECS",
    desc: "Amazon Elastic Container Service (ECS) helps you monitor your ECS containers and tasks running on EC2 instances and ECS Fargate",
    icon: <AWSECSNewDocsSVG />,
    link: "/installation?installation_integration=aws-ecs#instrumentation/infrastructure",
    component: <AWSECSInfraInstrument />,
    borderColor: "#F48120",
    key: "aws-ecs",
    category: ["allinstrumentation", "infrastructure"],
  },
  {
    title: "Cloudflare",
    desc: "Cloudflare is a security and performance optimization platform.",
    icon: <CloudSvgV2 />,
    link: "/installation?installation_integration=cloudflare#instrumentation/apm",
    component: <CloudflareAPMInstrument />,
    borderColor: "#F48120",
    key: "cloudflare",
    category: ["allinstrumentation", "apm"],
  },
  {
    title: "Deno",
    desc: "Deno is a JavaScript and TypeScript runtime for serverless applications.",
    icon: <DenoSvgV2 />,
    link: "/installation?installation_integration=deno#instrumentation/apm",
    component: <DenoAPMInstrument />,
    borderColor: "#B9B9B9",
    key: "deno",
    category: ["allinstrumentation", "apm"],
  },
  {
    title: "Go",
    desc: "Go is a statically typed, compiled high-level programming language.",
    icon: <GoSvgV2 />,
    link: "/installation?installation_integration=go#instrumentation/apm",
    component: <GoAPMInstrument />,
    borderColor: "#00ACD7",
    key: "go",
    category: ["allinstrumentation", "apm"],
  },
  {
    title: "Java",
    desc: "Java is a high-level, class-based, object-oriented programming language.",
    icon: <JavaSvgV2 />,
    link: "/installation?installation_integration=java#instrumentation/apm",
    component: <JavaAPMInstrument />,
    borderColor: "#F58219",
    key: "java",
    category: ["allinstrumentation", "apm"],
  },
  {
    title: ".NET",
    desc: ".NET is a framework developed by Microsoft that runs primarily on Microsoft Windows.",
    icon: <DotNetIconV2 />,
    link: "/installation?installation_integration=dot-net#instrumentation/apm",
    component: <DotNetAPMInstrument />,
    borderColor: "#5C2D91",
    key: "dot-net",
    category: ["allinstrumentation", "apm"],
  },
  {
    title: "Next.js",
    desc: "Next.js is an open-source React framework for building full-stack web applications.",
    icon: <NextJsSvgV2 />,
    link: "/installation?installation_integration=nextjs#instrumentation/apm",
    component: <NextJsAPMInstrument />,
    borderColor: "#B9B9B9",
    key: "nextjs",
    category: ["allinstrumentation", "apm"],
  },
  {
    title: "Node.js",
    desc: "Node.js is a cross-platform, open-source JavaScript runtime environment.",
    icon: <NodeJsSvgV2 />,
    link: "/installation?installation_integration=nodejs#instrumentation/apm",
    component: <NodeJsAPMInstrument />,
    borderColor: "#2DA44E",
    key: "nodejs",
    category: ["allinstrumentation", "apm"],
  },
  {
    title: "PHP",
    desc: "PHP is an open-source general-purpose scripting language.",
    icon: <PhpSvgV2 />,
    link: "/installation?installation_integration=php#instrumentation/apm",
    component: <PHPAPMInstrument />,
    borderColor: "#777BB3",
    key: "php",
    category: ["allinstrumentation", "apm"],
  },
  {
    title: "Python",
    desc: "Python is a high-level, general-purpose programming language.",
    icon: <PythonSvgV2 />,
    link: "/installation?installation_integration=python#instrumentation/apm",
    component: <PythonAPMInstrument />,
    borderColor: "#FFE262",
    key: "python",
    category: ["allinstrumentation", "apm"],
  },
  {
    title: "Ruby",
    desc: "Ruby is an interpreted, high-level, general-purpose programming language.",
    icon: <RubyIconV2 />,
    link: "/installation?installation_integration=ruby#instrumentation/apm",
    component: <RubyAPMInstrument />,
    borderColor: "#D11E06",
    key: "ruby",
    category: ["allinstrumentation", "apm"],
  },
  {
    title: "Vercel",
    desc: "Vercel is a cloud platform as a service company.",
    icon: <VercelIconV2 />,
    link: "/installation?installation_integration=vercel#instrumentation/apm",
    component: <VercelAPMInstrument />,
    borderColor: "#B9B9B9",
    key: "vercel",
    category: ["allinstrumentation", "apm"],
  },
  {
    title: "Scala",
    desc: "Scala is a general-purpose programming language that supports OOP and functional programming.",
    icon: <ScalaSvgV2 />,
    link: "/installation?installation_integration=scala#instrumentation/apm",
    component: <ScalaAPMInstrument />,
    borderColor: "#D11E06",
    key: "scala",
    category: ["allinstrumentation", "apm"],
  },
  {
    title: "Javascript",
    desc: "JavaScript RUM is for generic web application user monitoring.",
    icon: <JavascriptV2 />,
    link: "/installation?installation_integration=javascript#instrumentation/rum",
    component: <JavascriptRUMInstrument />,
    borderColor: "#FFE262",
    key: "javascript",
    category: ["allinstrumentation", "rum", "browser"],
  },
  {
    title: "Android",
    desc: "Android RUM is specifically used for monitoring Android mobile applications.",
    icon: <AndroidV2 />,
    link: "/installation?installation_integration=android#instrumentation/rum",
    component: <AndroidRUMInstrument />,
    borderColor: "#32DE84",
    key: "android",
    category: ["allinstrumentation", "rum", "mobile"],
  },
  {
    title: "iOS",
    desc: "iOS RUM is specifically used for monitoring iOS mobile applications.",
    icon: <AppleV2 />,
    link: "/installation?installation_integration=iOS#instrumentation/rum",
    component: <IosRUMInstrument />,
    borderColor: "#A2AAAD",
    key: "iOS",
    category: ["allinstrumentation", "rum", "mobile"],
  },
  {
    title: "React Native",
    desc: "React Native RUM is specifically used for monitoring React Native mobile applications.",
    icon: <ReactNativeV2 />,
    link: "/installation?installation_integration=react-native#instrumentation/rum",
    component: <ReactNativeRUMInstrument />,
    borderColor: "#61DBFB",
    key: "react-native",
    category: ["allinstrumentation", "rum", "mobile"],
  },
];
export const IntegrationListItems = [
  {
    name: "Jira",
    icon: <JiraIcon />,
    component: React.lazy(
      () => import("views/modules/integrations/components/jira-integration")
    ),
    category: ["allintegration", "collaboration"],
    key: "jira",
    link: "/installation?installation_integration=jira#integration/allintegration",
    configKey: IntegrationConfigKey.Jira,
    subTitle:
      "The Jira integration allows you to create and assign tickets to members within your organization. Create custom alerts and receive notifications in any project within your Jira account.",
  },
  {
    name: "Microsoft Teams",
    icon: <MicrosoftTeamIcon />,
    component: React.lazy(
      () =>
        import("views/modules/integrations/components/microsoft-integration")
    ),
    category: ["allintegration", "collaboration"],
    key: "ms-teams",
    link: "/installation?installation_integration=ms-team#integration/allintegration",
    configKey: IntegrationConfigKey.MsTeam,
    subTitle:
      "The Microsoft Teams integration allows you to directly send alerts to members of your organization. Create custom alerts and receive notifications in any channel or thread within your MS Teams account.",
  },
  {
    name: "Opsgenie",
    icon: <OpsgenieIconIntegration />,
    component: React.lazy(
      () => import("views/modules/integrations/components/opsgenie-integration")
    ),
    category: ["allintegration", "collaboration"],
    key: "opsgenie",
    link: "/installation?installation_integration=opsgenie#integration/allintegration",
    configKey: IntegrationConfigKey.Opsgenie,
    subTitle:
      "The Opsgenie Integration allows you to create and assign incidents to team members within your Opsgenie account. Track the total amount of resources consumed by your incident management tool in the Middleware platform.",
  },
  {
    name: "Pagerduty",
    icon: <PagerDutyIconIntegration />,
    component: React.lazy(
      () =>
        import("views/modules/integrations/components/pagerduty-integration")
    ),
    category: ["allintegration", "collaboration"],
    key: "pager-duty",
    link: "/installation?installation_integration=pagerduty#integration/allintegration",
    configKey: IntegrationConfigKey.PagerDuty,
    subTitle:
      "The PagerDuty Integration allows you to create and assign incidents to team members within your PagerDuty account. Track the total amount of resources consumed by your incident management tool in the Middleware platform.",
  },
  {
    name: "Slack",
    icon: <SlackIconV2 />,
    component: React.lazy(
      () => import("views/modules/integrations/components/slack-integration")
    ),
    category: ["allintegration", "collaboration"],
    key: "slack",
    link: "/installation?installation_integration=slack#integration/allintegration",
    configKey: IntegrationConfigKey.Slack,
    subTitle:
      "The Slack integration allows you to create and assign tickets to members within your organization. Create custom alerts and receive notifications in any channel within your Slack account.",
  },
  {
    name: "PostgreSQL",
    key: "postgresql",
    configKey: IntegrationConfigKey.Postgres,
    icon: <PostgreSQLIconIntegration />,
    link: "/installation?installation_integration=postgresql#integration/allintegration",
    component: React.lazy(
      () => import("views/modules/integrations/components/db.monitoring")
    ),
    category: ["databasemonitoring", "allintegration"],
    tagline:
      "PostgreSQL is an open-source object-relational database management system (ORDBMS).",
    subText: "Agent v0.0.25+",
    docs: "https://docs.middleware.io/integrations/postgresql-integration",
    categories: ["Database"],
    supportAgent: "V0.0.25+",
    releaseNote: [],
    features: [
      {
        feature: "Custom widget",
      },
      {
        feature: "Compare DB performance",
      },
      {
        feature: "Analyze query performance",
      },
      {
        feature: "Custom metrics",
      },
    ],
    metricCollect: [
      {
        metric: <span>postgresql.backends</span>,
        description: "Number of backends\n",
      },
      {
        metric: <span>postgresql.bgwriter.buffers.allocate</span>,
        description: "Number of buffers allocated.",
      },
      {
        metric: <span>postgresql.bgwriter.buffers.writes</span>,
        description: "Number of buffers written.",
      },
      {
        metric: <span>postgresql.bgwriter.checkpoint.count</span>,
        description: "Number of checkpoints performed.",
      },
      {
        metric: <span>postgresql.bgwriter.duration</span>,
        description:
          "Total time spent in checkpoint processing writing and syncing files to disk, in milliseconds",
      },
      {
        metric: <span>postgresql.bgwriter.maxwritten</span>,
        description:
          "Number of times the background writer stopped a cleaning scan because it had written too many buffers",
      },
      {
        metric: <span>postgresql.blocks_read</span>,
        description: "Number of disk blocks read",
      },
      {
        metric: <span>postgresql.commits</span>,
        description: "Number of transactions that have been committed",
      },
      {
        metric: <span>postgresql.connection.max</span>,
        description: "Configured maximum number of client connections allowed",
      },
      {
        metric: <span>postgresql.database.count</span>,
        description: "Count of databases in a cluster",
      },
      {
        metric: <span>postgresql.db_size</span>,
        description: "Database disk size",
      },
      {
        metric: <span>postgresql.index.scans</span>,
        description: "Number of index scans on a table",
      },
      {
        metric: <span>postgresql.index.size</span>,
        description: "Size of the index on disk",
      },
      {
        metric: <span>postgresql.operations</span>,
        description: "Number of db row operations",
      },
      {
        metric: <span>postgresql.rollbacks</span>,
        description: "Number of rollbacks",
      },
      {
        metric: <span>postgresql.rows</span>,
        description: "Number of rows in the database",
      },
      {
        metric: <span>postgresql.table.count</span>,
        description: "Number of user tables in a database",
      },
      {
        metric: <span>postgresql.table.size</span>,
        description: "Disk space used by a table",
      },
      {
        metric: <span>postgresql.table.vacuum.count</span>,
        description: "Number of times a table has been manually vacuumed",
      },
    ],
    overviewImage: "assets/images/postgres_overview.png",
    subTitle:
      "The PostgreSQL Integration allows users to effortlessly ingest and monitor their object-relational database management system (ORDBMS) data. Collect PostgreSQL performance and health metrics from a PostgreSQL database.",
  },
  {
    name: "MongoDB",
    key: "mongodb",
    configKey: IntegrationConfigKey.MongoDb,
    icon: <MongoDBIcon />,
    component: React.lazy(
      () => import("views/modules/integrations/components/db.monitoring")
    ),
    category: ["databasemonitoring", "allintegration"],
    tagline: "MongoDB is a cross-platform and source-available NoSQL database.",
    subText: "Agent v0.0.25+",
    docs: "https://docs.middleware.io/integrations/mongodb-integration",
    categories: ["Database", "NoSQL"],
    supportAgent: "V0.0.25+",
    releaseNote: [],
    features: [
      {
        feature: "Custom widget",
      },
      {
        feature: "Compare DB performance",
      },
      {
        feature: "Analyze query performance",
      },
      {
        feature: "Custom metrics",
      },
    ],
    metricCollect: [
      {
        metric: <span>mongodb.cache.operations</span>,
        description: "Number of cache operations\n",
      },
      {
        metric: <span>mongodb.collection.count</span>,
        description: "Number of collections\n",
      },
      {
        metric: <span>mongodb.data.size</span>,
        description:
          "Size of the collection, data compression does not affect this value\n",
      },
      {
        metric: <span>mongodb.connection.count</span>,
        description: "Number of connections\n",
      },
      {
        metric: <span>mongodb.extent.count</span>,
        description: "Number of extents\n",
      },
      {
        metric: <span>mongodb.global_lock.time</span>,
        description: "Total time the global lock has been held\n",
      },
      {
        metric: <span>mongodb.index.count</span>,
        description: "Number of indexes\n",
      },
      {
        metric: <span>mongodb.index.size</span>,
        description:
          "Total space allocated to all database indexes, including free index space\n",
      },
      {
        metric: <span>mongodb.memory.usage</span>,
        description: "Amount of memory used\n",
      },
      {
        metric: <span>mongodb.object.count</span>,
        description: "Number of objects\n",
      },
      {
        metric: <span>mongodb.operation.latency.time</span>,
        description: "Total latency of operations\n",
      },
      {
        metric: <span>mongodb.operation.count</span>,
        description: "Number of operations executed\n",
      },
      {
        metric: <span>mongodb.operation.repl.count</span>,
        description: "Number of replicated operations executed\n",
      },
      {
        metric: <span>mongodb.storage.size</span>,
        description: "Total amount of storage allocated to this collection\n",
      },
      {
        metric: <span>mongodb.database.count</span>,
        description: "Number of existing databases\n",
      },
      {
        metric: <span>mongodb.index.access.count</span>,
        description: "Number of times an index has been accessed\n",
      },
      {
        metric: <span>mongodb.document.operation.count</span>,
        description: "Number of document operations executed\n",
      },
      {
        metric: <span>mongodb.network.io.receive</span>,
        description: "Number of bytes received\n",
      },
      {
        metric: <span>mongodb.network.io.transmit</span>,
        description: "Number of bytes transmitted\n",
      },
      {
        metric: <span>mongodb.network.request.count</span>,
        description: "Number of requests received by a server\n",
      },
      {
        metric: <span>mongodb.operation.time</span>,
        description: "Total time spent performing operations\n",
      },
      {
        metric: <span>mongodb.session.count</span>,
        description: "Total number of active sessions\n",
      },
      {
        metric: <span>mongodb.cursor.count</span>,
        description: "Number of opened cursors maintained for clients\n",
      },
      {
        metric: <span>mongodb.cursor.timeout.count</span>,
        description: "Number of cursors that have timed out\n",
      },
      {
        metric: <span>mongodb.lock.acquire.count</span>,
        description:
          "Number of times a lock was acquired in the specified mode\n",
      },
      {
        metric: <span>mongodb.lock.acquire.wait_count</span>,
        description:
          "Number of times lock acquisitions encountered waits due to locks held in conflicting modes\n",
      },
      {
        metric: <span>mongodb.lock.acquire.time</span>,
        description: "Cumulative wait time for the lock acquisitions.\n",
      },
      {
        metric: <span>mongodb.lock.deadlock.count</span>,
        description:
          "Number of times lock acquisitions encountered deadlocks\n",
      },
      {
        metric: <span>mongodb.health</span>,
        description: "Health status of the server\n",
      },
      {
        metric: <span>mongodb.uptime</span>,
        description: "Amount of time the server has been running\n",
      },
    ],
    overviewImage: "assets/images/mongoDbOverview.png",
    link: "/installation?installation_integration=mongodb#integration/allintegration",
    subTitle:
      "The MongoDB Integration allows users to effortlessly ingest and monitor their NoSQL data. Collect MongoDB performance and health metrics.",
  },
  {
    name: "MySQL",
    key: "mysql",
    configKey: IntegrationConfigKey.MySQL,
    icon: <MySQLIcon />,
    component: React.lazy(
      () => import("views/modules/integrations/components/db.monitoring")
    ),
    category: ["databasemonitoring", "allintegration"],
    tagline:
      "MySQL is an open-source relational database management system (RDBMS).",
    subText: "Agent v0.0.27+",
    docs: "https://docs.middleware.io/integrations/mysql-integration",
    categories: ["Database"],
    supportAgent: "V0.0.27+",
    releaseNote: [],
    features: [
      {
        feature: "Custom widget",
      },
      {
        feature: "Compare DB performance",
      },
      {
        feature: "Analyze query performance",
      },
      {
        feature: "Custom metrics",
      },
    ],
    metricCollect: [
      {
        metric: <span>mysql.buffer_pool.pages</span>,
        description: "Number of pages in the InnoDB buffer pool\n",
      },
      {
        metric: <span>mysql.buffer_pool.data_pages</span>,
        description: "Number of data pages in the InnoDB buffer pool\n",
      },
      {
        metric: <span>mysql.buffer_pool.page_flushes</span>,
        description:
          "Number of requests to flush pages from the InnoDB buffer pool\n",
      },
      {
        metric: <span>mysql.buffer_pool.operations</span>,
        description: "Number of operations on the InnoDB buffer pool\n",
      },
      {
        metric: <span>mysql.buffer_pool.limit</span>,
        description: "Configured size of the InnoDB buffer pool\n",
      },
      {
        metric: <span>mysql.buffer_pool.usage</span>,
        description: "Number of bytes in the InnoDB buffer pool\n",
      },
      {
        metric: <span>mysql.commands</span>,
        description: "Count of commands executed, per command type\n",
      },
      {
        metric: <span>mysql.prepared_statements</span>,
        description:
          "Count of prepared statements executed, per statement type\n",
      },
      {
        metric: <span>mysql.handlers</span>,
        description: "Number of requests to MySQL handlers\n",
      },
      {
        metric: <span>mysql.double_writes</span>,
        description: "Number of writes to the InnoDB doublewrite buffer\n",
      },
      {
        metric: <span>mysql.log_operations</span>,
        description: "Number of InnoDB log operations\n",
      },
      {
        metric: <span>mysql.operations</span>,
        description: "Number of InnoDB operations\n",
      },
      {
        metric: <span>mysql.page_operations</span>,
        description: "Number of InnoDB page operations\n",
      },
      {
        metric: <span>mysql.table.io.wait.count</span>,
        description: "Total count of I/O wait events for a table\n",
      },
      {
        metric: <span>mysql.table.io.wait.time</span>,
        description: "Total time of I/O wait events for a table\n",
      },
      {
        metric: <span>mysql.index.io.wait.count</span>,
        description: "Total count of I/O wait events for an index\n",
      },
      {
        metric: <span>mysql.index.io.wait.time</span>,
        description: "Total time of I/O wait events for an index\n",
      },
      {
        metric: <span>mysql.row_locks</span>,
        description: "Number of InnoDB row blocks\n",
      },
      {
        metric: <span>mysql.row_operations</span>,
        description: "Number of InnoDB row operations\n",
      },
      {
        metric: <span>mysql.locks</span>,
        description: "Number of MySQL locks\n",
      },
      {
        metric: <span>mysql.sorts</span>,
        description: "Number of MySQL sorts\n",
      },
      {
        metric: <span>mysql.threads</span>,
        description: "State of MySQL threads\n",
      },
      {
        metric: <span>mysql.locked_connects</span>,
        description: "Number of attempts to connect to locked user accounts\n",
      },
      {
        metric: <span>mysql.opened_resources</span>,
        description: "Number of opened resources\n",
      },
      {
        metric: <span>mysql.mysqlx_connections</span>,
        description: "Number of mysqlx connections\n",
      },
      {
        metric: <span>mysql.tmp_resources</span>,
        description: "Number of created temporary resources\n",
      },
      {
        metric: <span>mysql.table.lock_wait.read.count</span>,
        description: "Count of lock wait read events\n",
      },
      {
        metric: <span>mysql.table.lock_wait.read.time</span>,
        description: "Total lock wait read event time\n",
      },
      {
        metric: <span>mysql.table.lock_wait.write.count</span>,
        description: "Count of lock wait write events\n",
      },
      {
        metric: <span>mysql.table.lock_wait.write.time</span>,
        description: "Total lock wait write event time\n",
      },
      {
        metric: <span>mysql.locked_connects</span>,
        description: "Number of attempts to connect to locked user accounts\n",
      },
      {
        metric: <span>mysql.connection.count</span>,
        description:
          "Total number of connection attempts to the MySQL server (includes successful and unsuccessful attempts)\n",
      },
      {
        metric: <span>mysql.connection.errors</span>,
        description: "Client connection process errors\n",
      },
      {
        metric: <span>mysql.mysqlx_connections</span>,
        description: "Number of mysqlx connections\n",
      },
      {
        metric: <span>mysql.joins</span>,
        description: "Number of joins that perform table scans\n",
      },
      {
        metric: <span>mysql.replica.time_behind_source</span>,
        description: "Actual replica delay, in seconds\n",
      },
      {
        metric: <span>mysql.replica.sql_delay</span>,
        description: "Configured replica delay, in seconds\n",
      },
      {
        metric: <span>mysql.statement_event.count</span>,
        description: "Count of statement events\n",
      },
      {
        metric: <span>mysql.statement_event.wait.time</span>,
        description: "Total wait time of summarized timed events\n",
      },
      {
        metric: <span>mysql.mysqlx_worker_threads</span>,
        description: "Number of worker threads available\n",
      },
      {
        metric: <span>mysql.table_open_cache</span>,
        description:
          "Total number of hits, misses, and overflows for open table cache lookups\n",
      },
      {
        metric: <span>mysql.query.client.count</span>,
        description: "Number of client statements executed by the server\n",
      },
      {
        metric: <span>mysql.query.count</span>,
        description: "Total number of statements executed by the server\n",
      },
      {
        metric: <span>mysql.query.slow.count</span>,
        description: "Number of slow queries\n",
      },
    ],
    overviewImage: "assets/images/mySqlDbOverviewV2.png",
    link: "/installation?installation_integration=mysql#integration/allintegration",
    subTitle:
      "The MySQL Integration allows users to effortlessly ingest and monitor their relational database management system (RDBMS) data. Collect MySQL performance and health metrics from a MySQL database.",
  },
  {
    name: "MariaDB",
    key: "mariadb",
    configKey: IntegrationConfigKey.MariaDB,
    icon: <MariadbIcon />,
    component: React.lazy(
      () => import("views/modules/integrations/components/db.monitoring")
    ),
    category: ["databasemonitoring", "allintegration"],
    tagline:
      "MariaDB is an open-source fork of MySQL, offering high compatibility with enhanced features and performance.",
    subText: "Agent v0.0.27+",
    docs: "https://docs.middleware.io/integrations/mysql-integration",
    categories: ["Database"],
    supportAgent: "V0.0.27+",
    releaseNote: [],
    features: [
      {
        feature: "Custom widget",
      },
      {
        feature: "Compare DB performance",
      },
      {
        feature: "Analyze query performance",
      },
      {
        feature: "Custom metrics",
      },
    ],
    metricCollect: [
      {
        metric: <span>mysql.buffer_pool.pages</span>,
        description: "Number of pages in the InnoDB buffer pool\n",
      },
      {
        metric: <span>mysql.buffer_pool.data_pages</span>,
        description: "Number of data pages in the InnoDB buffer pool\n",
      },
      {
        metric: <span>mysql.buffer_pool.page_flushes</span>,
        description:
          "Number of requests to flush pages from the InnoDB buffer pool\n",
      },
      {
        metric: <span>mysql.buffer_pool.operations</span>,
        description: "Number of operations on the InnoDB buffer pool\n",
      },
      {
        metric: <span>mysql.buffer_pool.limit</span>,
        description: "Configured size of the InnoDB buffer pool\n",
      },
      {
        metric: <span>mysql.buffer_pool.usage</span>,
        description: "Number of bytes in the InnoDB buffer pool\n",
      },
      {
        metric: <span>mysql.commands</span>,
        description: "Count of commands executed, per command type\n",
      },
      {
        metric: <span>mysql.prepared_statements</span>,
        description:
          "Count of prepared statements executed, per statement type\n",
      },
      {
        metric: <span>mysql.handlers</span>,
        description: "Number of requests to MySQL handlers\n",
      },
      {
        metric: <span>mysql.double_writes</span>,
        description: "Number of writes to the InnoDB doublewrite buffer\n",
      },
      {
        metric: <span>mysql.log_operations</span>,
        description: "Number of InnoDB log operations\n",
      },
      {
        metric: <span>mysql.operations</span>,
        description: "Number of InnoDB operations\n",
      },
      {
        metric: <span>mysql.page_operations</span>,
        description: "Number of InnoDB page operations\n",
      },
      {
        metric: <span>mysql.table.io.wait.count</span>,
        description: "Total count of I/O wait events for a table\n",
      },
      {
        metric: <span>mysql.table.io.wait.time</span>,
        description: "Total time of I/O wait events for a table\n",
      },
      {
        metric: <span>mysql.index.io.wait.count</span>,
        description: "Total count of I/O wait events for an index\n",
      },
      {
        metric: <span>mysql.index.io.wait.time</span>,
        description: "Total time of I/O wait events for an index\n",
      },
      {
        metric: <span>mysql.row_locks</span>,
        description: "Number of InnoDB row blocks\n",
      },
      {
        metric: <span>mysql.row_operations</span>,
        description: "Number of InnoDB row operations\n",
      },
      {
        metric: <span>mysql.locks</span>,
        description: "Number of MySQL locks\n",
      },
      {
        metric: <span>mysql.sorts</span>,
        description: "Number of MySQL sorts\n",
      },
      {
        metric: <span>mysql.threads</span>,
        description: "State of MySQL threads\n",
      },
      {
        metric: <span>mysql.locked_connects</span>,
        description: "Number of attempts to connect to locked user accounts\n",
      },
      {
        metric: <span>mysql.opened_resources</span>,
        description: "Number of opened resources\n",
      },
      {
        metric: <span>mysql.mysqlx_connections</span>,
        description: "Number of mysqlx connections\n",
      },
      {
        metric: <span>mysql.tmp_resources</span>,
        description: "Number of created temporary resources\n",
      },
      {
        metric: <span>mysql.table.lock_wait.read.count</span>,
        description: "Count of lock wait read events\n",
      },
      {
        metric: <span>mysql.table.lock_wait.read.time</span>,
        description: "Total lock wait read event time\n",
      },
      {
        metric: <span>mysql.table.lock_wait.write.count</span>,
        description: "Count of lock wait write events\n",
      },
      {
        metric: <span>mysql.table.lock_wait.write.time</span>,
        description: "Total lock wait write event time\n",
      },
      {
        metric: <span>mysql.locked_connects</span>,
        description: "Number of attempts to connect to locked user accounts\n",
      },
      {
        metric: <span>mysql.connection.count</span>,
        description:
          "Total number of connection attempts to the MySQL server (includes successful and unsuccessful attempts)\n",
      },
      {
        metric: <span>mysql.connection.errors</span>,
        description: "Client connection process errors\n",
      },
      {
        metric: <span>mysql.mysqlx_connections</span>,
        description: "Number of mysqlx connections\n",
      },
      {
        metric: <span>mysql.joins</span>,
        description: "Number of joins that perform table scans\n",
      },
      {
        metric: <span>mysql.replica.time_behind_source</span>,
        description: "Actual replica delay, in seconds\n",
      },
      {
        metric: <span>mysql.replica.sql_delay</span>,
        description: "Configured replica delay, in seconds\n",
      },
      {
        metric: <span>mysql.statement_event.count</span>,
        description: "Count of statement events\n",
      },
      {
        metric: <span>mysql.statement_event.wait.time</span>,
        description: "Total wait time of summarized timed events\n",
      },
      {
        metric: <span>mysql.mysqlx_worker_threads</span>,
        description: "Number of worker threads available\n",
      },
      {
        metric: <span>mysql.table_open_cache</span>,
        description:
          "Total number of hits, misses, and overflows for open table cache lookups\n",
      },
      {
        metric: <span>mysql.query.client.count</span>,
        description: "Number of client statements executed by the server\n",
      },
      {
        metric: <span>mysql.query.count</span>,
        description: "Total number of statements executed by the server\n",
      },
      {
        metric: <span>mysql.query.slow.count</span>,
        description: "Number of slow queries\n",
      },
    ],
    overviewImage: "assets/images/mySqlDbOverviewV2.png",
    link: "/installation?installation_integration=mysql#integration/allintegration",
    subTitle:
      "The MariaDB Integration allows users to effortlessly ingest and monitor their relational database management system (RDBMS) data. Collect MariaDB performance and health metrics from a MariaDB database.",
  },
  {
    name: "Redis",
    key: "redis",
    configKey: IntegrationConfigKey.Redis,
    icon: <RedisIcon />,
    component: React.lazy(
      () => import("views/modules/integrations/components/db.monitoring")
    ),
    category: ["databasemonitoring", "allintegration"],
    tagline: "Redis is an open-source in-memory data structure store.",
    subText: "Agent v1.0.0+",
    docs: "https://docs.middleware.io/integrations/redis-integration",
    categories: ["Database"],
    supportAgent: "V1.0.0+",
    releaseNote: [],
    features: [],
    metricCollect: [
      {
        metric: <span>redis.maxmemory</span>,
        description: "Configured maxmemory value\n",
      },
      {
        metric: <span>redis.role</span>,
        description: "Lists node’s role\n",
      },
      {
        metric: <span>redis.cmd.calls</span>,
        description: "Total number of calls for a command\n",
      },
      {
        metric: <span>redis.cmd.usec</span>,
        description: "Total time for all executions of this command\n",
      },
      {
        metric: <span>redis.uptime</span>,
        description: "Number of seconds since server start\n",
      },
      {
        metric: <span>redis.cpu.time</span>,
        description: "System CPU consumed by server since start\n",
      },
      {
        metric: <span>redis.clients.connected</span>,
        description:
          "Number of client connections, excludes connections from replicas\n",
      },
      {
        metric: <span>redis.clients.max_input_buffer</span>,
        description: "Largest buffer of current client connections\n",
      },
      {
        metric: <span>redis.clients.max_output_buffer</span>,
        description: "Longest output list of current client connections\n",
      },
      {
        metric: <span>redis.clients.blocked</span>,
        description: "Number of clients pending a blocking call\n",
      },
      {
        metric: <span>redis.keys.expired</span>,
        description: "Total number of key expiration events\n",
      },
      {
        metric: <span>redis.keys.evicted</span>,
        description: "Number of keys evicted due to maxmemory limit\n",
      },
      {
        metric: <span>redis.connections.received</span>,
        description: "Number of connections accepted by the server\n",
      },
      {
        metric: <span>redis.connections.rejected</span>,
        description: "Number of connections rejected due to maxclients limit\n",
      },
      {
        metric: <span>redis.memory.used</span>,
        description: "Total bytes allocated by Redis\n",
      },
      {
        metric: <span>redis.memory.peak</span>,
        description: "Historical max bytes consumed by Redis\n",
      },
      {
        metric: <span>redis.memory.rss</span>,
        description: "Bytes allocated from operating system\n",
      },
      {
        metric: <span>redis.memory.lua</span>,
        description: "Bytes used by the Lua engine\n",
      },
      {
        metric: <span>redis.memory.fragmentation_ratio</span>,
        description: "Ratio between memory.rss and memory.used\n",
      },
      {
        metric: <span>redis.rdb.changes_since_last_save</span>,
        description: "Number of changes since latest dump\n",
      },
      {
        metric: <span>redis.commands</span>,
        description: "Number of commands processed per second\n",
      },
      {
        metric: <span>redis.commands.processed</span>,
        description: "Total number of commands processed by the server\n",
      },
      {
        metric: <span>redis.net.input</span>,
        description: "Total number of bytes read from the network\n",
      },
      {
        metric: <span>redis.net.output</span>,
        description: "Total number of bytes written to the network\n",
      },
      {
        metric: <span>redis.keyspace.hits</span>,
        description: "Number of successful key lookups in main directory\n",
      },
      {
        metric: <span>redis.keyspace.misses</span>,
        description: "Number of unsuccessful key lookups in main directory\n",
      },
      {
        metric: <span>redis.latest_fork</span>,
        description: "Duration of latest fork operation in microseconds\n",
      },
      {
        metric: <span>redis.slaves.connected</span>,
        description: "Number of connected replicas\n",
      },
      {
        metric: <span>redis.replication.backlog_first_byte_offset</span>,
        description: "Master offset of the replication backlog buffer\n",
      },
      {
        metric: <span>redis.replication.offset</span>,
        description: "Current offset of the replication server\n",
      },
      {
        metric: <span>redis.db.keys</span>,
        description: "Number of keyspace keys\n",
      },
      {
        metric: <span>redis.db.expires</span>,
        description: "Number of keyspace keys with an expiration\n",
      },
      {
        metric: <span>redis.db.avg_ttl</span>,
        description: "Average keyspace keys time to live\n",
      },
    ],
    overviewImage: "assets/images/redis_overview.png",
    link: "/installation?installation_integration=redis#integration/allintegration",
    subTitle:
      "Retrieve data and metrics from a Redis instance to effectively monitor the performance of your Redis server.",
  },
  {
    name: "Clickhouse",
    key: "clickhouse",
    configKey: IntegrationConfigKey.Clickhouse,
    icon: <ClickhouseIcon />,
    component: React.lazy(
      () => import("views/modules/integrations/components/db.monitoring")
    ),
    category: ["databasemonitoring", "allintegration"],
    tagline: "ClickHouse is a column-oriented database.",
    subText: "Agent 1.3.0+",
    placeholder: "localhost:9126",
    docs: "https://docs.middleware.io/integrations/clickhouse-integration",
    categories: ["Database", "NoSQL"],
    supportAgent: "V1.3.0+",
    releaseNote: [],
    features: [
      {
        feature: "Custom widget",
      },
      {
        feature: "Compare DB performance",
      },
      {
        feature: "Analyze query performance",
      },
      {
        feature: "Custom metrics",
      },
    ],
    metricCollect: [
      {
        metric: <span>AggregatorThreads</span>,
        description: "Number of threads in the Aggregator thread pool.\n",
      },
      {
        metric: <span>AggregatorThreadsActive</span>,
        description:
          "Number of threads in the Aggregator thread pool running a task.\n",
      },
      {
        metric: <span>TablesLoaderForegroundThreads</span>,
        description:
          "Number of threads in the async loader foreground thread pool.\n",
      },
      {
        metric: <span>TablesLoaderForegroundThreadsActive</span>,
        description:
          "Number of threads in the async loader foreground thread pool running a task.\n",
      },
      {
        metric: <span>TablesLoaderBackgroundThreads</span>,
        description:
          "Number of threads in the async loader background thread pool.\n",
      },
      {
        metric: <span>TablesLoaderBackgroundThreadsActive</span>,
        description:
          "Number of threads in the async loader background thread pool running a task.\n",
      },
      {
        metric: <span>AsyncInsertCacheSize</span>,
        description: "Number of async insert hash id in cache\n",
      },
      {
        metric: <span>AsynchronousInsertThreads</span>,
        description:
          "Number of threads in the AsynchronousInsert thread pool.\n",
      },
      {
        metric: <span>AsynchronousInsertThreadsActive</span>,
        description:
          "Number of threads in the AsynchronousInsert thread pool running a task.\n",
      },
      {
        metric: <span>AsynchronousReadWait</span>,
        description: "Number of threads waiting for asynchronous read.\n",
      },
      {
        metric: <span>BackgroundBufferFlushSchedulePoolSize</span>,
        description:
          "Limit on number of tasks in BackgroundBufferFlushSchedulePool\n",
      },
      {
        metric: <span>BackgroundBufferFlushSchedulePoolTask</span>,
        description:
          "Number of active tasks in BackgroundBufferFlushSchedulePool. This pool is used for periodic Buffer flushes\n",
      },
      {
        metric: <span>BackgroundCommonPoolSize</span>,
        description:
          "Limit on number of tasks in an associated background pool\n",
      },
      {
        metric: <span>BackgroundCommonPoolTask</span>,
        description:
          "Number of active tasks in an associated background pool\n",
      },
      {
        metric: <span>BackgroundDistributedSchedulePoolSize</span>,
        description:
          "Limit on number of tasks in BackgroundDistributedSchedulePool\n",
      },
      {
        metric: <span>BackgroundDistributedSchedulePoolTask</span>,
        description:
          "Number of active tasks in BackgroundDistributedSchedulePool. This pool is used for distributed sends that is done in background.\n",
      },
      {
        metric: <span>BackgroundFetchesPoolSize</span>,
        description:
          "Limit on number of simultaneous fetches in an associated background pool\n",
      },
      {
        metric: <span>BackgroundFetchesPoolTask</span>,
        description:
          "Number of active fetches in an associated background pool\n",
      },
      {
        metric: <span>BackgroundMergesAndMutationsPoolSize</span>,
        description:
          "Limit on number of active merges and mutations in an associated background pool\n",
      },
      {
        metric: <span>BackgroundMergesAndMutationsPoolTask</span>,
        description:
          "Number of active merges and mutations in an associated background pool\n",
      },
      {
        metric: <span>BackgroundMessageBrokerSchedulePoolSize</span>,
        description:
          "Limit on number of tasks in BackgroundProcessingPool for message streaming\n",
      },
      {
        metric: <span>BackgroundMessageBrokerSchedulePoolTask</span>,
        description:
          "Number of active tasks in BackgroundProcessingPool for message streaming\n",
      },
      {
        metric: <span>BackgroundMovePoolSize</span>,
        description:
          "Limit on number of tasks in BackgroundProcessingPool for moves",
      },
      {
        metric: <span>BackgroundMovePoolTask</span>,
        description:
          "Number of active tasks in BackgroundProcessingPool for moves\n",
      },
      {
        metric: <span>BackgroundSchedulePoolSize</span>,
        description:
          "Limit on number of tasks in BackgroundSchedulePool. This pool is used for periodic ReplicatedMergeTree tasks, like cleaning old data parts, altering data parts, replica re-initialization, etc.\n",
      },
      {
        metric: <span>BackgroundSchedulePoolTask</span>,
        description:
          "Number of active tasks in BackgroundSchedulePool. This pool is used for periodic ReplicatedMergeTree tasks, like cleaning old data parts, altering data parts, replica re-initialization, etc.\n",
      },
      {
        metric: <span>BackupsIOThreads</span>,
        description: "Number of threads in the BackupsIO thread pool.\n",
      },
      {
        metric: <span>BackupsIOThreadsActive</span>,
        description:
          "Number of threads in the BackupsIO thread pool running a task.\n",
      },
      {
        metric: <span>BackupsThreads</span>,
        description: "Number of threads in the thread pool for BACKUP.\n",
      },
      {
        metric: <span>BackupsThreadsActive</span>,
        description:
          "Number of threads in thread pool for BACKUP running a task.\n",
      },
      {
        metric: <span>BrokenDistributedFilesToInsert</span>,
        description:
          "Number of files for asynchronous insertion into Distributed tables that has been marked as broken. This metric will starts from 0 on start. Number of files for every shard is summed.\n",
      },
      {
        metric: <span>CacheDetachedFileSegments</span>,
        description: "Number of existing detached cache file segments\n",
      },
      {
        metric: <span>CacheDictionaryThreads</span>,
        description: "Number of threads in the CacheDictionary thread pool.\n",
      },
      {
        metric: <span>CacheDictionaryThreadsActive</span>,
        description:
          "Number of threads in the CacheDictionary thread pool running a task.\n",
      },
      {
        metric: <span>CacheDictionaryUpdateQueueBatches</span>,
        description:
          "Number of ‘batches’ (a set of keys) in update queue in CacheDictionaries.\n",
      },
      {
        metric: <span>CacheDictionaryUpdateQueueKeys</span>,
        description:
          "Exact number of keys in update queue in CacheDictionaries.\n",
      },
      {
        metric: <span>CacheFileSegments</span>,
        description: "Number of existing cache file segments\n",
      },
      {
        metric: <span>ContextLockWait</span>,
        description:
          "Number of threads waiting for lock in Context. This is global lock.\n",
      },
      {
        metric: <span>DDLWorkerThreads</span>,
        description:
          "Number of threads in the DDLWorker thread pool for ON CLUSTER queries.\n",
      },
      {
        metric: <span>DDLWorkerThreadsActive</span>,
        description:
          "Number of threads in the DDLWORKER thread pool for ON CLUSTER queries running a task.\n",
      },
      {
        metric: <span>DatabaseCatalogThreads</span>,
        description: "Number of threads in the DatabaseCatalog thread pool.\n",
      },
      {
        metric: <span>DatabaseCatalogThreadsActive</span>,
        description:
          "Number of threads in the DatabaseCatalog thread pool running a task.\n",
      },
      {
        metric: <span>DatabaseOnDiskThreads</span>,
        description: "Number of threads in the DatabaseOnDisk thread pool.\n",
      },
      {
        metric: <span>DatabaseOnDiskThreadsActive</span>,
        description:
          "Number of threads in the DatabaseOnDisk thread pool running a task.\n",
      },
      {
        metric: <span>DelayedInserts</span>,
        description:
          "Number of INSERT queries that are throttled due to high number of active data parts for partition in a MergeTree table.\n",
      },
      {
        metric: <span>DestroyAggregatesThreads</span>,
        description:
          "Number of threads in the thread pool for destroy aggregate states.\n",
      },
      {
        metric: <span>DestroyAggregatesThreadsActive</span>,
        description:
          "Number of threads in the thread pool for destroy aggregate states running a task.\n",
      },
      {
        metric: <span>DictCacheRequests</span>,
        description:
          "Number of requests in fly to data sources of dictionaries of cache type.\n",
      },
      {
        metric: <span>DiskObjectStorageAsyncThreads</span>,
        description:
          "Number of threads in the async thread pool for DiskObjectStorage.\n",
      },
      {
        metric: <span>DiskObjectStorageAsyncThreadsActive</span>,
        description:
          "Number of threads in the async thread pool for DiskObjectStorage running a task.\n",
      },
      {
        metric: <span>DiskSpaceReservedForMerge</span>,
        description:
          "Disk space reserved for currently running background merges. It is slightly more than the total size of currently merging parts.\n",
      },
      {
        metric: <span>DistributedFilesToInsert</span>,
        description:
          "Number of pending files to process for asynchronous insertion into Distributed tables. Number of files for every shard is summed.\n",
      },
      {
        metric: <span>DistributedSend</span>,
        description:
          "Number of connections to remote servers sending data that was INSERTed into Distributed tables. Both synchronous and asynchronous mode.\n",
      },
      {
        metric: <span>EphemeralNode</span>,
        description: "Number of ephemeral nodes hold in ZooKeeper.\n",
      },
      {
        metric: <span>FilesystemCacheElements</span>,
        description: "Filesystem cache elements (file segments)\n",
      },
      {
        metric: <span>FilesystemCacheReadBuffers</span>,
        description: "Number of active cache buffers\n",
      },
      {
        metric: <span>FilesystemCacheSize</span>,
        description: "Filesystem cache size in bytes\n",
      },
      {
        metric: <span>GlobalThread</span>,
        description: "Number of threads in global thread pool.\n",
      },
      {
        metric: <span>GlobalThreadActive</span>,
        description:
          "Number of threads in global thread pool running a task.\n",
      },
      {
        metric: <span>HTTPConnection</span>,
        description: "Number of connections to HTTP server\n",
      },
      {
        metric: <span>HashedDictionaryThreads</span>,
        description: "Number of threads in the HashedDictionary thread pool.\n",
      },
      {
        metric: <span>HashedDictionaryThreadsActive</span>,
        description:
          "Number of threads in the HashedDictionary thread pool running a task.\n",
      },
      {
        metric: <span>IOPrefetchThreads</span>,
        description: "Number of threads in the IO prefertch thread pool.\n",
      },
      {
        metric: <span>IOPrefetchThreadsActive</span>,
        description:
          "Number of threads in the IO prefetch thread pool running a task.\n",
      },
      {
        metric: <span>IOThreads</span>,
        description: "Number of threads in the IO thread pool.\n",
      },
      {
        metric: <span>IOThreadsActive</span>,
        description:
          "Number of threads in the IO thread pool running a task.\n",
      },
      {
        metric: <span>IOUringInFlightEvents</span>,
        description: "Number of io_uring SQEs in flight\n",
      },
      {
        metric: <span>IOUringPendingEvents</span>,
        description: "Number of io_uring SQEs waiting to be submitted\n",
      },
      {
        metric: <span>IOWriterThreads</span>,
        description: "Number of threads in the IO writer thread pool.\n",
      },
      {
        metric: <span>IOWriterThreadsActive</span>,
        description:
          "Number of threads in the IO writer thread pool running a task.\n",
      },
      {
        metric: <span>InterserverConnection</span>,
        description:
          "Number of connections from other replicas to fetch parts\n",
      },
      {
        metric: <span>KafkaAssignedPartitions</span>,
        description:
          "Number of partitions Kafka tables currently assigned to\n",
      },
      {
        metric: <span>KafkaBackgroundReads</span>,
        description:
          "Number of background reads currently working (populating materialized views from Kafka)\n",
      },
      {
        metric: <span>KafkaConsumers</span>,
        description: "Number of active Kafka consumers\n",
      },
      {
        metric: <span>KafkaConsumersInUse</span>,
        description:
          "Number of consumers which are currently used by direct or background reads\n",
      },
      {
        metric: <span>KafkaConsumersWithAssignment</span>,
        description:
          "Number of active Kafka consumers which have some partitions assigned.\n",
      },
      {
        metric: <span>KafkaLibrdkafkaThreads</span>,
        description: "Number of active librdkafka threads\n",
      },
      {
        metric: <span>KafkaProducers</span>,
        description: "Number of active Kafka producer created\n",
      },
      {
        metric: <span>KafkaWrites</span>,
        description: "Number of currently running inserts to Kafka\n",
      },
      {
        metric: <span>KeeperAliveConnections</span>,
        description: "Number of alive connections\n",
      },
      {
        metric: <span>KeeperOutstandingRequets</span>,
        description: "Number of outstanding requests\n",
      },
      {
        metric: <span>LocalThread</span>,
        description:
          "Number of threads in local thread pools. The threads in local thread pools are taken from the global thread pool.\n",
      },
      {
        metric: <span>LocalThreadActive</span>,
        description:
          "Number of threads in local thread pools running a task.\n",
      },
      {
        metric: <span>MMappedAllocBytes</span>,
        description: "Sum bytes of mmapped allocations\n",
      },
      {
        metric: <span>MMappedAllocs</span>,
        description: "Total number of mmapped allocations\n",
      },
      {
        metric: <span>MMappedFileBytes</span>,
        description: "Sum size of mmapped file regions.\n",
      },
      {
        metric: <span>MMappedFiles</span>,
        description: "Total number of mmapped files.\n",
      },
      {
        metric: <span>MarksLoaderThreads</span>,
        description: "Number of threads in thread pool for loading marks.\n",
      },
      {
        metric: <span>MarksLoaderThreadsActive</span>,
        description:
          "Number of threads in the thread pool for loading marks running a task.\n",
      },
      {
        metric: <span>MaxDDLEntryID</span>,
        description: "Max processed DDL entry of DDLWorker.\n",
      },
      {
        metric: <span>MaxPushedDDLEntryID</span>,
        description: "Max DDL entry of DDLWorker that pushed to zookeeper.\n",
      },
      {
        metric: <span>MemoryTracking</span>,
        description:
          "Total amount of memory (bytes) allocated by the server.\n",
      },
      {
        metric: <span>Merge</span>,
        description: "Number of executing background merges\n",
      },
      {
        metric: <span>MergeTreeAllRangesAnnouncementsSent</span>,
        description:
          "The current number of announcement being sent in flight from the remote server to the initiator server about the set of data parts (for MergeTree tables). Measured on the remote server side.\n",
      },
      {
        metric: <span>MergeTreeBackgroundExecutorThreads</span>,
        description:
          "Number of threads in the MergeTreeBackgroundExecutor thread pool.\n",
      },
      {
        metric: <span>MergeTreeBackgroundExecutorThreadsActive</span>,
        description:
          "Number of threads in the MergeTreeBackgroundExecutor thread pool running a task.\n",
      },
      {
        metric: <span>MergeTreeDataSelectExecutorThreads</span>,
        description:
          "Number of threads in the MergeTreeDataSelectExecutor thread pool.\n",
      },
      {
        metric: <span>MergeTreeDataSelectExecutorThreadsActive</span>,
        description:
          "Number of threads in the MergeTreeDataSelectExecutor thread pool running a task.\n",
      },
      {
        metric: <span>MergeTreePartsCleanerThreads</span>,
        description:
          "Number of threads in the MergeTree parts cleaner thread pool.\n",
      },
      {
        metric: <span>MergeTreePartsCleanerThreadsActive</span>,
        description:
          "Number of threads in the MergeTree parts cleaner thread pool running a task.\n",
      },
      {
        metric: <span>MergeTreePartsLoaderThreads</span>,
        description:
          "Number of threads in the MergeTree parts loader thread pool.\n",
      },
      {
        metric: <span>MergeTreePartsLoaderThreadsActive</span>,
        description:
          "Number of threads in the MergeTree parts loader thread pool running a task.\n",
      },
      {
        metric: <span>MergeTreeReadTaskRequestsSent</span>,
        description:
          "The current number of callback requests in flight from the remote server back to the initiator server to choose the read task (for MergeTree tables). Measured on the remote server side.\n",
      },
      {
        metric: <span>Move</span>,
        description: "Number of currently executing moves\n",
      },
      {
        metric: <span>MySQLConnection</span>,
        description: "Number of client connections using MySQL protocol\n",
      },
      {
        metric: <span>NetworkReceive</span>,
        description:
          "Number of threads receiving data from network. Only ClickHouse-related network interaction is included, not by 3rd party libraries.\n",
      },
      {
        metric: <span>NetworkSend</span>,
        description:
          "Number of threads sending data to network. Only ClickHouse-related network interaction is included, not by 3rd party libraries.\n",
      },
      {
        metric: <span>OpenFileForRead</span>,
        description: "Number of files open for reading\n",
      },
      {
        metric: <span>OpenFileForWrite</span>,
        description: "Number of files open for writing\n",
      },
      {
        metric: <span>ParallelFormattingOutputFormatThreads</span>,
        description:
          "Number of threads in the ParallelFormattingOutputFormatThreads thread pool.\n",
      },
      {
        metric: <span>ParallelFormattingOutputFormatThreadsActive</span>,
        description:
          "Number of threads in the ParallelFormattingOutputFormatThreads thread pool running a task.\n",
      },
      {
        metric: <span>ParallelParsingInputFormatThreads</span>,
        description:
          "Number of threads in the ParallelParsingInputFormat thread pool.\n",
      },
      {
        metric: <span>ParallelParsingInputFormatThreadsActive</span>,
        description:
          "Number of threads in the ParallelParsingInputFormat thread pool running a task.\n",
      },
      {
        metric: <span>PartMutation</span>,
        description: "Number of mutations (ALTER DELETE/UPDATE)\n",
      },
      {
        metric: <span>PartsActive</span>,
        description:
          "Active data part, used by current and upcoming SELECTs.\n",
      },
      {
        metric: <span>PartsCommitted</span>,
        description: "Deprecated. See PartsActive.\n",
      },
      {
        metric: <span>PartsCompact</span>,
        description: "Compact parts.\n",
      },
      {
        metric: <span>PartsDeleteOnDestroy</span>,
        description:
          "Part was moved to another disk and should be deleted in own destructor.\n",
      },
      {
        metric: <span>PartsDeleting</span>,
        description:
          "Not active data part with identity refcounter, it is deleting right now by a cleaner.\n",
      },
      {
        metric: <span>PartsInMemory</span>,
        description: "In-memory parts.\n",
      },
      {
        metric: <span>PartsOutdated</span>,
        description:
          "Not active data part, but could be used by only current SELECTs, could be deleted after SELECTs finishes.\n",
      },
      {
        metric: <span>PartsPreActive</span>,
        description: "The part is in data_parts, but not used for SELECTs.\n",
      },
      {
        metric: <span>PartsPreCommitted</span>,
        description: "Deprecated. See PartsPreActive.\n",
      },
      {
        metric: <span>PartsTemporary</span>,
        description:
          "The part is generating now, it is not in data_parts list.\n",
      },
      {
        metric: <span>PartsWide</span>,
        description: "Wide parts.",
      },
      {
        metric: <span>PendingAsyncInsert</span>,
        description:
          "Number of asynchronous inserts that are waiting for flush.\n",
      },
      {
        metric: <span>PostgreSQLConnection</span>,
        description: "Number of client connections using PostgreSQL protocol\n",
      },
      {
        metric: <span>Query</span>,
        description: "Number of executing queries\n",
      },
      {
        metric: <span>QueryPreempted</span>,
        description:
          "Number of queries that are stopped and waiting due to ‘priority’ setting.\n",
      },
      {
        metric: <span>QueryThread</span>,
        description: "Number of query processing threads\n",
      },
      {
        metric: <span>RWLockActiveReaders</span>,
        description: "Number of threads holding read lock in a table RWLock.\n",
      },
      {
        metric: <span>RWLockActiveWriters</span>,
        description:
          "Number of threads holding write lock in a table RWLock.\n",
      },
      {
        metric: <span>RWLockWaitingReaders</span>,
        description: "Number of threads waiting for read on a table RWLock.\n",
      },
      {
        metric: <span>RWLockWaitingWriters</span>,
        description: "Number of threads waiting for write on a table RWLock.\n",
      },
      {
        metric: <span>Read</span>,
        description:
          "Number of read (read, pread, io_getevents, etc.) syscalls in fly\n",
      },
      {
        metric: <span>ReadTaskRequestsSent</span>,
        description:
          "The current number of callback requests in flight from the remote server back to the initiator server to choose the read task (for s3Cluster table function and similar). Measured on the remote server side.\n",
      },
      {
        metric: <span>ReadonlyReplica</span>,
        description:
          "Number of Replicated tables that are currently in readonly state due to re-initialization after ZooKeeper session loss or due to startup without ZooKeeper configured.\n",
      },
      {
        metric: <span>RemoteRead</span>,
        description: "Number of read with remote reader in fly\n",
      },
      {
        metric: <span>ReplicatedChecks</span>,
        description: "Number of data parts checking for consistency\n",
      },
      {
        metric: <span>ReplicatedFetch</span>,
        description: "Number of data parts being fetched from replica\n",
      },
      {
        metric: <span>ReplicatedSend</span>,
        description: "Number of data parts being sent to replicas\n",
      },
      {
        metric: <span>RestartReplicaThreads</span>,
        description: "Number of threads in the RESTART REPLICA thread pool.\n",
      },
      {
        metric: <span>RestartReplicaThreadsActive</span>,
        description:
          "Number of threads in the RESTART REPLICA thread pool running a task.\n",
      },
      {
        metric: <span>RestoreThreads</span>,
        description: "Number of threads in the thread pool for RESTORE.\n",
      },
      {
        metric: <span>RestoreThreadsActive</span>,
        description:
          "Number of threads in the thread pool for RESTORE running a task.\n",
      },
      {
        metric: <span>Revision</span>,
        description:
          "Revision of the server. It is a number incremented for every release or release candidate except patch releases.\n",
      },
      {
        metric: <span>S3Requests</span>,
        description: "S3 requests\n",
      },
      {
        metric: <span>SendExternalTables</span>,
        description:
          "Number of connections that are sending data for external tables to remote servers. External tables are used to implement GLOBAL IN and GLOBAL JOIN operators with distributed subqueries.\n",
      },
      {
        metric: <span>SendScalars</span>,
        description:
          "Number of connections that are sending data for scalars to remote servers.\n",
      },
      {
        metric: <span>StorageBufferBytes</span>,
        description: "Number of bytes in buffers of Buffer tables\n",
      },
      {
        metric: <span>StorageBufferRows</span>,
        description: "Number of rows in buffers of Buffer tables\n",
      },
      {
        metric: <span>StorageDistributedThreads</span>,
        description:
          "Number of threads in the StorageDistributed thread pool.\n",
      },
      {
        metric: <span>StorageDistributedThreadsActive</span>,
        description:
          "Number of threads in the StorageDistributed thread pool running a task.\n",
      },
      {
        metric: <span>StorageHiveThreads</span>,
        description: "Number of threads in the StorageHive thread pool.\n",
      },
      {
        metric: <span>StorageHiveThreadsActive</span>,
        description:
          "Number of threads in the StorageHive thread pool running a task.\n",
      },
      {
        metric: <span>StorageS3Threads</span>,
        description: "Number of threads in the StorageS3 thread pool.\n",
      },
      {
        metric: <span>StorageS3ThreadsActive</span>,
        description:
          "Number of threads in the StorageS3 thread pool running a task.\n",
      },
      {
        metric: <span>SystemReplicasThreads</span>,
        description: "Number of threads in the system.replicas thread pool.\n",
      },
      {
        metric: <span>SystemReplicasThreadsActive</span>,
        description:
          "Number of threads in the system.replicas thread pool running a task.\n",
      },
      {
        metric: <span>TCPConnection</span>,
        description:
          "Number of connections to TCP server (clients with native interface), also included server-server distributed query connections\n",
      },
      {
        metric: <span>TablesToDropQueueSize</span>,
        description:
          "Number of dropped tables, that are waiting for background data removal.\n",
      },
      {
        metric: <span>TemporaryFilesForAggregation</span>,
        description:
          "Number of temporary files created for external aggregation\n",
      },
      {
        metric: <span>TemporaryFilesForJoin</span>,
        description: "Number of temporary files created for JOIN\n",
      },
      {
        metric: <span>TemporaryFilesForSort</span>,
        description: "Number of temporary files created for external sorting\n",
      },
      {
        metric: <span>TemporaryFilesUnknown</span>,
        description:
          "Number of temporary files created without known purpose\n",
      },
      {
        metric: <span>ThreadPoolFSReaderThreads</span>,
        description:
          "Number of threads in the thread pool for local_filesystem_read_method=threadpool.\n",
      },
      {
        metric: <span>ThreadPoolFSReaderThreadsActive</span>,
        description:
          "Number of threads in the thread pool for local_filesystem_read_method=threadpool running a task.\n",
      },
      {
        metric: <span>ThreadPoolRemoteFSReaderThreads</span>,
        description:
          "Number of threads in the thread pool for remote_filesystem_read_method=threadpool.\n",
      },
      {
        metric: <span>ThreadPoolRemoteFSReaderThreadsActive</span>,
        description:
          "Number of threads in the thread pool for remote_filesystem_read_method=threadpool running a task.\n",
      },
      {
        metric: <span>ThreadsInOvercommitTracker</span>,
        description: "Number of waiting threads inside of OvercommitTracker\n",
      },
      {
        metric: <span>TotalTemporaryFiles</span>,
        description: "Number of temporary files created\n",
      },
      {
        metric: <span>VersionInteger</span>,
        description:
          "Version of the server in a single integer number in base-1000. For example, version 11.22.33 is translated to 11022033.",
      },
      {
        metric: <span>Write</span>,
        description:
          "Number of write (write, pwrite, io_getevents, etc.) syscalls in fly\n",
      },
      {
        metric: <span>ZooKeeperRequest</span>,
        description: "Number of requests to ZooKeeper in fly.\n",
      },
      {
        metric: <span>ZooKeeperSession</span>,
        description:
          "Number of sessions (connections) to ZooKeeper. Should be no more than one, because using more than one connection to ZooKeeper may lead to bugs due to lack of linearizability (stale reads) that ZooKeeper consistency model allows.\n",
      },
      {
        metric: <span>ZooKeeperWatch</span>,
        description: "Number of watches (event subscriptions) in ZooKeeper.\n",
      },
    ],
    overviewImage: "assets/images/clickhouse_overview.png",
    link: "/installation?installation_integration=clickhouse#integration/allintegration",
    subTitle:
      "The ClickHouse Integration allows users to effortlessly ingest and monitor their column-oriented ClickHouse data. Use our default dashboard to comprehend system-wide usage patterns and identify performance bottlenecks in your ClickHouse cluster. Understand how your high-volume SQL queries perform in real-time to maximize your infrastructure’s efficiency further.",
  },
  {
    name: "Cassandra",
    key: "cassandra",
    configKey: IntegrationConfigKey.Cassandra,
    icon: <CassandraIcon />,
    component: React.lazy(
      () => import("views/modules/integrations/components/db.monitoring")
    ),
    category: ["databasemonitoring", "allintegration"],
    tagline: "Cassandra is an open-source NoSQL database.",
    subText: "Agent 1.3.0+",
    docs: "https://docs.middleware.io/integrations/cassandra-integration",
    categories: ["Database", "NoSQL"],
    supportAgent: "V1.3.0+",
    releaseNote: [
      {
        date: "11/27/2023",
        description: "Added JMX receiver",
      },
    ],
    features: [
      {
        feature: "Custom widget",
      },
      {
        feature: "Compare DB performance",
      },
      {
        feature: "Monitor Cassandra states",
      },
      {
        feature: "Analyze query performance",
      },
      {
        feature: "Custom metrics",
      },
    ],
    metricCollect: [
      {
        metric: <span>cassandra.client.request.count</span>,
        description: "Total count of client requests",
      },
      {
        metric: <span>cassandra.client.request.error.count</span>,
        description: "Count of errors that occurred during client requests",
      },
      {
        metric: <span>cassandra.client.request.range_slice.latency.50p</span>,
        description: "50th percentile latency for range slice requests",
      },
      {
        metric: <span>cassandra.client.request.range_slice.latency.99p</span>,
        description: "99th percentile latency for range slice requests",
      },
      {
        metric: <span>cassandra.client.request.range_slice.latency.max</span>,
        description: "Maximum latency for range slice requests",
      },
      {
        metric: <span>cassandra.client.request.read.latency.50p</span>,
        description: "50th percentile latency for read requests",
      },
      {
        metric: <span>cassandra.client.request.read.latency.99p</span>,
        description: "99th percentile latency for read requests",
      },
      {
        metric: <span>cassandra.client.request.read.latency.max</span>,
        description: "Maximum latency for read requests",
      },
      {
        metric: <span>cassandra.client.request.write.latency.50p</span>,
        description: "50th percentile latency for write requests",
      },
      {
        metric: <span>cassandra.client.request.write.latency.99p</span>,
        description: "99th percentile latency for write requests",
      },
      {
        metric: <span>cassandra.client.request.write.latency.max</span>,
        description: "Maximum latency for write requests",
      },
      {
        metric: <span>cassandra.compaction.tasks.completed</span>,
        description: "Count of completed compaction tasks",
      },
      {
        metric: <span>cassandra.compaction.tasks</span>,
        description: "Current count of compaction tasks",
      },
      {
        metric: <span>cassandra.storage.load.count</span>,
        description: "Count of storage loads",
      },
      {
        metric: <span>cassandra.storage.total_hints.count</span>,
        description: "Total count of hints stored",
      },
      {
        metric: <span>cassandra.storage.total_hints.in_progress.count</span>,
        description: "Count of hints currently in progress",
      },
    ],
    overviewImage: "assets/images/cassandra_overview.png",
    link: "/installation?installation_integration=cassandra#integration/allintegration",
    subTitle:
      "The Cassandra Database Integration provides seamless access to performance metrics and enables users to effortlessly visualize crucial insights in a single dashboard. The integration works by pulling metrics from the database through the Middleware Host Agent.",
  },
  {
    name: "Elasticsearch",
    key: "elasticsearch",
    configKey: IntegrationConfigKey.ElasticSearch,
    icon: <ElasticSearchIcon />,
    component: React.lazy(
      () => import("views/modules/integrations/components/db.monitoring")
    ),
    category: ["databasemonitoring", "allintegration"],
    tagline:
      "Elasticsearch is a distributed, RESTful search and analytics engine that sits at the center of the Elastic Stack. ",
    subText: "Agent v1.1.0+",
    docs: "https://docs.middleware.io/integrations/elasticsearch-integration",
    categories: ["Database"],
    supportAgent: "V1.1.0+",
    releaseNote: [
      {
        date: "01/10/2024",
        description: "Changing how metrics are aggregated and modified",
      },
      {
        date: "11/26/2023",
        description: "Adding synthetic alert logs for failure checks",
      },
      {
        date: "11/24/2023",
        description: "Fixed metrics query issue",
      },
      {
        date: "11/20/2023",
        description: "Added default dashboard",
      },
    ],
    features: [
      {
        feature: "Custom widget",
      },
      {
        feature: "Compare DB performance",
      },
      {
        feature: "Analyze query performance",
      },
      {
        feature: "Custom metrics",
      },
    ],
    metricCollect: [
      {
        metric: <span>elasticsearch.breaker.memory.estimated</span>,
        description: "Estimated memory used for the operation\n",
      },
      {
        metric: <span>elasticsearch.breaker.memory.limit</span>,
        description: "Memory limit for circuit breaker\n",
      },
      {
        metric: <span>elasticsearch.breaker.tripped</span>,
        description:
          "Total number of times the circuit breaker has been triggered and prevented an out of memory error\n",
      },
      {
        metric: <span>elasticsearch.node.cache.memory.usage</span>,
        description: "Size in bytes of the cache on a node\n",
      },
      {
        metric: <span>elasticsearch.node.cache.evictions</span>,
        description: "Number of evictions from the cache on a node\n",
      },
      {
        metric: <span>elasticsearch.node.cache.count</span>,
        description:
          "Total count of query cache misses across all shards assigned to selected nodes\n",
      },
      {
        metric: <span>elasticsearch.node.fs.disk.available</span>,
        description:
          "Amount of disk space available to the JVM across all file stores for this node\n",
      },
      {
        metric: <span>elasticsearch.node.fs.disk.free</span>,
        description:
          "Amount of unallocated disk space across all file stores for this node\n",
      },
      {
        metric: <span>elasticsearch.node.fs.disk.total</span>,
        description:
          "Amount of disk space across all file stores for this node\n",
      },
      {
        metric: <span>elasticsearch.node.disk.io.read</span>,
        description:
          "Total number of kilobytes read across all file stores for this node\n",
      },
      {
        metric: <span>elasticsearch.node.disk.io.write</span>,
        description:
          "Total number of kilobytes written across all file stores for this node\n",
      },
      {
        metric: <span>elasticsearch.node.cluster.io</span>,
        description:
          "Number of bytes sent and received on the network for internal cluster communication\n",
      },
      {
        metric: <span>elasticsearch.node.cluster.connections</span>,
        description:
          "Number of open tcp connections for internal cluster communication\n",
      },
      {
        metric: <span>elasticsearch.node.http.connections</span>,
        description: "Number of HTTP connections to the node\n",
      },
      {
        metric: <span>elasticsearch.node.operations.completed</span>,
        description: "Number of operations completed by a node\n",
      },
      {
        metric: <span>elasticsearch.node.operations.time</span>,
        description: "Time spent on operations by a node\n",
      },
      {
        metric: <span>elasticsearch.node.shards.size</span>,
        description: "Size of shards assigned to this node\n",
      },
      {
        metric: <span>elasticsearch.node.shards.data_set.size</span>,
        description: "Total data set size of all shards assigned to the node\n",
      },
      {
        metric: <span>elasticsearch.node.shards.reserved.size</span>,
        description:
          "Prediction of how much larger the shard stores on this node will eventually grow\n",
      },
      {
        metric: <span>elasticsearch.node.translog.operations</span>,
        description: "Number of transaction log operations\n",
      },
      {
        metric: <span>elasticsearch.node.translog.size</span>,
        description: "Size of the transaction log\n",
      },
      {
        metric: <span>elasticsearch.node.translog.uncommitted.size</span>,
        description: "Size of uncommitted transaction log operations\n",
      },
      {
        metric: <span>elasticsearch.node.thread_pool.threads</span>,
        description: "Number of threads in the thread pool\n",
      },
      {
        metric: <span>elasticsearch.node.thread_pool.tasks.queued</span>,
        description: "Number of queued tasks in the thread pool\n",
      },
      {
        metric: <span>elasticsearch.node.thread_pool.tasks.finished</span>,
        description: "Number of tasks finished by the thread pool\n",
      },
      {
        metric: <span>elasticsearch.node.documents</span>,
        description: "Number of documents on the node\n",
      },
      {
        metric: <span>elasticsearch.node.open_files</span>,
        description: "Number of open file descriptors held by the node\n",
      },
      {
        metric: <span>jvm.classes.loaded</span>,
        description: "Number of loaded classes\n",
      },
      {
        metric: <span>jvm.gc.collections.count</span>,
        description: "Total number of garbage collections that have occurred\n",
      },
      {
        metric: <span>jvm.gc.collections.elapsed</span>,
        description: "Approximate accumulated collection elapsed time\n",
      },
      {
        metric: <span>jvm.memory.heap.max</span>,
        description: "Maximum amount of memory can be used for the heap\n",
      },
      {
        metric: <span>jvm.memory.heap.used</span>,
        description: "Current heap memory usage\n",
      },
      {
        metric: <span>jvm.memory.heap.committed</span>,
        description:
          "Amount of memory that is guaranteed to be available for the heap\n",
      },
      {
        metric: <span>jvm.memory.nonheap.used</span>,
        description: "Current non-heap memory usage\n",
      },
      {
        metric: <span>jvm.memory.nonheap.committed</span>,
        description:
          "Amount of memory that is guaranteed to be available for non-heap purposes\n",
      },
      {
        metric: <span>jvm.memory.pool.max</span>,
        description:
          "Maximum amount of memory can be used for the memory pool\n",
      },
      {
        metric: <span>jvm.memory.pool.used</span>,
        description: "Current memory pool memory usage\n",
      },
      {
        metric: <span>jvm.threads.count</span>,
        description: "Current number of threads\n",
      },
      {
        metric: <span>elasticsearch.cluster.pending_tasks</span>,
        description:
          "Number of cluster-level changes that have not yet been executed\n",
      },
      {
        metric: <span>elasticsearch.cluster.in_flight_fetch</span>,
        description: "Number of unfinished fetches\n",
      },
      {
        metric: <span>elasticsearch.cluster.shards</span>,
        description: "Number of shards in the cluster\n",
      },
      {
        metric: <span>elasticsearch.cluster.data_nodes</span>,
        description: "Number of data nodes in the cluster\n",
      },
      {
        metric: <span>elasticsearch.cluster.nodes</span>,
        description: "Total number of nodes in the cluster\n",
      },
      {
        metric: <span>elasticsearch.cluster.health</span>,
        description: "The health status of the cluster\n",
      },
      {
        metric: <span>elasticsearch.os.cpu.usage</span>,
        description:
          "Recent CPU usage for the whole system, or -1 if not supported\n",
      },
      {
        metric: <span>elasticsearch.os.cpu.load_avg.1m</span>,
        description:
          "One-minute load average on the system (field is not present if one-minute load average is not available)\n",
      },
      {
        metric: <span>elasticsearch.os.cpu.load_avg.5m</span>,
        description:
          "Five-minute load average on the system (field is not present if five-minute load average is not available)\n",
      },
      {
        metric: <span>elasticsearch.os.cpu.load_avg.15m</span>,
        description:
          "Fifteen-minute load average on the system (field is not present if fifteen-minute load average is not available)\n",
      },
      {
        metric: <span>elasticsearch.os.memory</span>,
        description: "Amount of physical memory\n",
      },
      {
        metric: <span>elasticsearch.memory.indexing_pressure</span>,
        description:
          "Memory consumed, in bytes, by indexing requests in the specified stage\n",
      },
      {
        metric: (
          <span>
            elasticsearch.indexing_pressure.memory.total.primary_rejections
          </span>
        ),
        description:
          "Cumulative number of indexing requests rejected in the primary stage\n",
      },
      {
        metric: (
          <span>
            elasticsearch.indexing_pressure.memory.total.replica_rejections
          </span>
        ),
        description:
          "Number of indexing requests rejected in the replica stage\n",
      },
      {
        metric: <span>elasticsearch.indexing_pressure.memory.limit</span>,
        description:
          "Configured memory limit, in bytes, for the indexing requests\n",
      },
      {
        metric: <span>elasticsearch.cluster.state_queue</span>,
        description: "Number of cluster states in queue\n",
      },
      {
        metric: <span>elasticsearch.cluster.published_states.full</span>,
        description: "Number of published cluster states\n",
      },
      {
        metric: <span>elasticsearch.cluster.published_states.differences</span>,
        description: "Number of differences between published cluster states\n",
      },
      {
        metric: <span>elasticsearch.cluster.state_update.count</span>,
        description:
          "Number of cluster state update attempts that changed the cluster state since the node started\n",
      },
      {
        metric: <span>elasticsearch.cluster.state_update.time</span>,
        description:
          "Cumulative amount of time updating the cluster state since the node started\n",
      },
      {
        metric: <span>elasticsearch.cluster.indices.cache.evictions</span>,
        description:
          "Number of evictions from the cache for indices in the cluster\n",
      },
      {
        metric: <span>elasticsearch.node.ingest.documents</span>,
        description:
          "Total documents ingested during the lifetime of this node\n",
      },
      {
        metric: <span>elasticsearch.node.ingest.documents.current</span>,
        description: "Total documents currently being ingested\n",
      },
      {
        metric: <span>elasticsearch.node.ingest.operations.failed</span>,
        description:
          "Total failed ingest operations during the lifetime of this node\n",
      },
      {
        metric: (
          <span>elasticsearch.node.pipeline.ingest.documents.preprocessed</span>
        ),
        description:
          "Number of documents preprocessed by the ingest pipeline\n",
      },
      {
        metric: (
          <span>elasticsearch.node.pipeline.ingest.operations.failed</span>
        ),
        description: "Total failed operations for the ingest pipeline\n",
      },
      {
        metric: (
          <span>elasticsearch.node.pipeline.ingest.documents.current</span>
        ),
        description: "Total documents currently being ingested by a pipeline\n",
      },
      {
        metric: <span>elasticsearch.node.script.compilations</span>,
        description: "Total inline script compilations performed by the node\n",
      },
      {
        metric: <span>elasticsearch.node.script.cache_evictions</span>,
        description: "Total times the script cache has evicted old data\n",
      },
      {
        metric: (
          <span>elasticsearch.node.script.compilation_limit_triggered</span>
        ),
        description:
          "Total times the script compilation circuit breaker has limited inline script compilations\n",
      },
      {
        metric: <span>elasticsearch.node.segments.memory</span>,
        description: "Size of memory for segment object of a node\n",
      },
      {
        metric: <span>elasticsearch.index.operations.completed</span>,
        description: "Number of operations completed for an index\n",
      },
      {
        metric: <span>elasticsearch.index.operations.time</span>,
        description: "Time spent on operations for an index\n",
      },
      {
        metric: <span>elasticsearch.index.shards.size</span>,
        description: "Size of the shards assigned to this index\n",
      },
      {
        metric: <span>elasticsearch.process.cpu.usage</span>,
        description: "CPU usage in percent\n",
      },
      {
        metric: <span>elasticsearch.node.operations.current</span>,
        description: "Number of query operations currently running\n",
      },
      {
        metric: <span>elasticsearch.index.documents</span>,
        description: "Number of documents for an index\n",
      },
      {
        metric: <span>elasticsearch.process.memory.virtual</span>,
        description:
          "Size of virtual memory that is guaranteed to be available to the running process\n",
      },
      {
        metric: <span>elasticsearch.process.cpu.time</span>,
        description:
          "CPU time used by the process on which the Java virtual machine is running\n",
      },
    ],
    overviewImage: "assets/images/elastic_overview.png",
    link: "/installation?installation_integration=elasticsearch#integration/allintegration",
    subTitle:
      "The Elasticsearch Integration allows users to track their Elasticsearch performance and health metrics from Elasticsearch clusters and nodes. Use our default dashboard to understand system-wide usage patterns and identify performance bottlenecks. Drill into all of your data within the Elastic ecosystem.",
  },
  {
    name: "Prometheus",
    key: "prometheus",
    configKey: IntegrationConfigKey.Prometheus,
    icon: <PrometheusIcon />,
    component: React.lazy(
      () => import("views/modules/integrations/components/db.monitoring")
    ),
    category: ["allintegration", "telemetry"],
    tagline:
      "Prometheus is an open-source tool used for monitoring and alerting.",
    subText: "Agent v0.0.25+",
    docs: "",
    categories: ["OpenTelemetry"],
    supportAgent: "v0.0.25+",
    metricCollect: [],
    releaseNote: [],
    features: [
      {
        feature: "Custom metrics",
      },
    ],
    link: "/installation?installation_integration=prometheus#integration/allintegration",
    subTitle:
      "The Prometheus Integration offers effortless access to performance metrics, allowing users to visualize key insights in our dashboard. Retrieve metrics from a Prometheus endpoint.",
  },
  {
    name: "Redpanda",
    key: "redpanda",
    configKey: IntegrationConfigKey.Redpanda,
    icon: <RedpandaIcon />,
    component: React.lazy(
      () => import("views/modules/integrations/components/db.monitoring")
    ),
    category: ["allintegration", "streaming"],
    tagline: "Redpanda is a Kafka-compatible data streaming platform.",
    subText: "Agent 1.3.0+",
    placeholder: "localhost:9644",
    docs: "https://docs.middleware.io/integrations/redpanda-integration",
    categories: ["Streaming"],
    supportAgent: "",
    releaseNote: [],
    features: [],
    metricCollect: [],
    overviewImage: "assets/images/redpanda_overview.png",
    link: "/installation?installation_integration=redpanda#integration/allintegration",
    subTitle:
      "The Redpanda Integration offers effortless access to performance metrics, allowing users to visualize key insights in our dashboard. Identify bottlenecks swiftly and optimize Redpanda’s performance seamlessly.",
  },
  {
    name: "Kafka",
    key: "kafka",
    configKey: IntegrationConfigKey.Kafka,
    icon: <KafkaIconV2 />,
    component: React.lazy(
      () => import("views/modules/integrations/components/db.monitoring")
    ),
    category: ["allintegration", "streaming"],
    tagline: "Collects Kafka Performance and Health Metrics",
    subText: "Agent v1.5.0+",
    docs: "https://docs.middleware.io/integrations/kafka-integration",
    categories: ["Streaming"],
    supportAgent: "v 1.5.0+",
    features: [],
    metricCollect: [],
    link: "/installation?installation_integration=kafka#integration/allintegration",
    subTitle:
      "The Kafka Integration offers real-time insights into your Kafka pipeline performance. Actively monitor your Kafka brokers, partitions, consumer groups, and JMX metrics in our default dashboard or with custom widgets.",
  },
  {
    name: "Oracle",
    key: "oracledb",
    configKey: IntegrationConfigKey.OracleDB,
    icon: <OracleDBIcon />,
    component: React.lazy(
      () => import("views/modules/integrations/components/db.monitoring")
    ),
    category: ["databasemonitoring", "allintegration"],
    categories: ["Database"],
    tagline: "Collects OracleDB performance and health metrics.",
    docs: "https://www.oracle.com/in/database",
    link: "/installation?installation_integration=oracledb#integration/allintegration",
    supportAgent: "v1.6.1+",
    features: [],
    metricCollect: [],
    subText: " ",
    subTitle:
      "OracleDB is a multi-model database management system created by Oracle Corporation.",
    overviewImage: "assets/images/oracle_integration.png",
  },
  {
    name: "Apache",
    key: "apache",
    configKey: IntegrationConfigKey.Apache,
    icon: <ApacheIcon />,
    component: React.lazy(
      () => import("views/modules/integrations/components/db.monitoring")
    ),
    category: ["allintegration", "networking"],
    categories: ["Networking"],
    tagline: "Collects Apache performance and health metrics.",
    link: "/installation?installation_integration=apache#integration/allintegration",
    supportAgent: "v1.6.1+",
    docs: "https://docs.middleware.io/integrations/apache-server-integration",
    metricCollect: [
      {
        metric: <span>apache.uptime</span>,
        description: "Amount of time server has been running in seconds",
      },
      {
        metric: <span>apache.current_connections</span>,
        description:
          "Number of active connections currently attached to the HTTP server",
      },
      {
        metric: <span>apache.workers</span>,
        description: "Number of workers currently attached to the HTTP server",
      },
      {
        metric: <span>apache.requests</span>,
        description:
          "Number of requests serviced by the HTTP server per second",
      },
      {
        metric: <span>apache.traffic</span>,
        description: "Total HTTP server traffic",
      },
      {
        metric: <span>apache.cpu.time</span>,
        description: "Number of jiffs used by a process in a given category",
      },
      {
        metric: <span>apache.cpu.load</span>,
        description: "Current load of the CPU",
      },
      {
        metric: <span>apache.load.1</span>,
        description: "Average server load within the last minute",
      },
      {
        metric: <span>apache.load.5</span>,
        description: "Average server load within the last 5 minutes",
      },
      {
        metric: <span>apache.load.15</span>,
        description: "Average server load within the last 15 minutes",
      },
      {
        metric: <span>apache.request.time</span>,
        description: "Total time spent handling requests",
      },
      {
        metric: <span>apache.scoreboard</span>,
        description: "Number of workers in each state",
      },
    ],
    overviewImage: "assets/images/apache-mw.png",
    subTitle:
      "The Apache Network Integration provides real-time visibility into your team’s server performance and statistics.",
    features: [
      {
        feature: "Custom widget",
      },
      {
        feature: "Server Metrics",
      },
      {
        feature: "Custom Metrics",
      },
    ],
    hostConfig: [
      {
        title: <span>endpoint</span>,
        description: "URL of the httpd status endpoint",
      },
      {
        title: <span>collection_interval</span>,
        description:
          "Interval that telemetry data is collected (default set to 10 sec interval)",
      },
    ],
  },
];
export const DataIngestionAPiItems: IntegrationItem[] = [
  {
    title: "OpenTelemetry Logs",
    link: "/installation?ingestion_api=logs#custom_apis/alldataingestionapi",
    icon: <OpenTelemetryV2 />,
    key: "logs",
    component: <OpenTelemetryLogsInstrument />,
    desc: "OpenTelemetry Logs allow you to send and monitor custom log data in Middleware.",
    borderColor: "#4f62ad",
    category: ["alldataingestionapi", "opentelemetry"],
  },
  {
    title: "OpenTelemetry Metrics",
    link: "/installation?ingestion_api=metrics#custom_apis/alldataingestionapi",
    icon: <OpenTelemetryV2 />,
    key: "metrics",
    component: <OpenTelemetryMetricInstrument />,
    desc: "OpenTelemetry Metrics allow you to send and monitor custom Metric data in Middleware.",
    borderColor: "#4f62ad",
    category: ["alldataingestionapi", "opentelemetry"],
  },
];
