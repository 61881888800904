import React, { memo, useCallback, useEffect, useState } from "react";
import "./_style.scss";
import {
  GlobalSearchProps,
  MetricsType,
  OptionsType,
  SelectionType,
} from "./model";

import { APIStatus } from "core/application/utils";
import Button from "core/components/v2/button";
import LogoLoader from "core/components/v2/loader/logo-loader";
import {
  CancelIcon,
  CodeIcon,
  EscapeKeyIcon,
  KeyboardArrowDownKeyIcon,
  KeyboardArrowUpKeyIcon,
  KeyboardReturnKeyIcon,
  SearchIcon,
} from "core/components/v2/svg/icons";
import Tooltip from "core/components/v2/tooltip";
import AppContext from "core/components/wrapper/context";
import { debounceHandler } from "core/utils";
import { useDispatch, useSelector } from "react-redux";
import { receivedBarFilters } from "store/widgets/actions";
import { fetchMetricList, requestBuilderData } from "store/widgets/api";
import {
  BarType,
  DataType,
  FiltersAPIResp,
  MetricsAPIResp,
} from "store/widgets/entities";
import { getBarFilters } from "store/widgets/selectors";
import {
  CustomWidget,
  FilterDataType,
  FilterOpEnum,
  GLOBAL_ATTRIBUTE_FILTER,
  GridviewData,
  Query,
  RequestParam,
  SELECT_DATA_BY,
  SqlOperatorType,
  builderFiltersInstance,
  defaultQuery,
  operatorMapping,
  sqlOperators,
  toKpiType,
} from "views/modules/builder/entities/builder.entities";
import MwRouteContext from "../../routes/MWRouteContext";
import { QueryParams } from "../../routes/model";
import useDidMountEffect from "../../routes/useDidMountEffect";
import TextToQuery from "../text-to-query";
import { Size } from "core/components/v2/enums";
import { Popover } from "core/components/v2/popover";

const filterValuesMap: Record<string, Record<string, string>> = {
  kind: {
    "0": "Unspecified",
    "1": "Internal",
    "2": "Server",
    "3": "Client",
    "4": "Producer",
    "5": "Consumer",
    key: "Span Kind",
  },
  statusCode: {
    "0": "Unset",
    "1": "Ok",
    "2": "Error",
    key: "Span Status",
  },
};

const GlobalQueryBar = (props: GlobalSearchProps) => {
  const {
    caller,
    placeholder,
    extraBtnPrefixIcon,
    extraBtnLabel,
    onExtraBtnClick,
    includeMetricTypes = [],
    // hideGroupByLabel = false,
    extraJSXElement,
    allowConditionalOperators = false,
    extraAttributes = [],
    customFilters = {},
    isCustom = false,
    onChange,
    uniqueId,
    handleRunQuery,
    regexSearch,
  } = props;
  const extraOperators: string[] = allowConditionalOperators
    ? ["<=", ">=", "<", ">"]
    : [];
  const ALLOWED_OPERATORS: string[] = [
    ...extraOperators,
    "!=",
    "NOT IN",
    "NOT LIKE",
    "=",
    "IN",
    "LIKE",
    "REGEX",
  ];

  const routeData = React.useContext(MwRouteContext);
  const context = React.useContext(AppContext);
  const dispatch = useDispatch();
  const querybarFilters = useSelector(getBarFilters(BarType.Querybar));

  // const appliedFilters = routeData.params.appliedFilters as QueryParams;
  const [appliedFilters, setAplliedFilters] = useState<QueryParams>({});
  const _filters = routeData.params.appliedFilters;

  const [focussed, setFocused] = useState<boolean>(false);

  const [width, setWidth] = useState<number>(0);

  const [options, setOptions] = useState<OptionsType[]>([]);
  const [fixedOptions, setFixedOptions] = useState<OptionsType[]>([]);

  const [currentType, setCurrentType] = useState<SelectionType>(
    SelectionType.Attributes
  );

  const [highlitedIndex, setHighlitedIndex] = useState<number>(-1);

  const [input, setInput] = useState<string>("");

  const [metricOptions, setMetricOptions] = useState<MetricsType>({});
  const [showTextToQuery, setShowTextToQuery] = useState(false);

  const [inFilter, setInFilter] = useState({});

  const [runningQuery, setRunningQuery] = useState<{
    attribute?: string;
    operator?: FilterOpEnum;
    values?: string[];
  }>({
    attribute: "",
    operator: FilterOpEnum.Equal,
    values: [],
  });

  const regexSearchOption = regexSearch || "body";
  const startDateParam = routeData.params.dateRange.fromTs;
  const endDateParam = routeData.params.dateRange.toTs;
  let is_datadog_agent = false;
  let datdog_agent_installed = false;
  if (context.user?.misc_details?.datadog_installed === true) {
    datdog_agent_installed = true;
  }
  if (
    context.user?.misc_details?.datadog_installed === true &&
    context.user?.settings?.live_infra_dashboard?.value !== "mw_agent"
  ) {
    is_datadog_agent = true;
  }
  useEffect(() => {
    window.addEventListener("click", onClickOutsideHandler);
    return () => {
      window.removeEventListener("click", onClickOutsideHandler);
    };
  }, []);

  useEffect(() => {
    if (!isCustom) {
      setAplliedFilters(_filters);
    }
  }, [JSON.stringify(_filters)]);

  useEffect(() => {
    if (isCustom) {
      setAplliedFilters(customFilters);
    }
  }, [JSON.stringify(customFilters)]);

  const fetchFilterAttributes = (search: string) => {
    dispatch(
      fetchMetricList(
        {
          kpiType: toKpiType(caller),
          widgetType: "list",
          dataType: DataType.Filters,
          resource: caller,
          filterTypes: JSON.stringify(includeMetricTypes),
          search,
        },
        (resp: MetricsAPIResp) =>
          receivedBarFilters(BarType.Querybar, resp as FiltersAPIResp)
      )
    );
  };

  useEffect(() => {
    if (!focussed || currentType !== SelectionType.Attributes) return;
    fetchFilterAttributes(getAttrNameFromSearch(input));
  }, [focussed]);

  useEffect(() => {
    if (runningQuery.attribute !== "" && runningQuery.operator && !focussed)
      setFocused(true);
  }, [runningQuery.operator]);

  // useCallback is used to use the same function reference between re-renders.
  // It will help to prevent calling the debounceHandler multiple times due to
  // new function definition creation.
  const onSearch = useCallback(
    debounceHandler(-1, fetchFilterAttributes, 500),
    [caller, JSON.stringify(includeMetricTypes)]
  );

  useDidMountEffect(() => {
    const searchAttr = getAttrNameFromSearch(input);
    if (currentType !== SelectionType.Attributes) {
      return;
    }

    onSearch(searchAttr);
  }, [input]);

  useEffect(() => {
    const logOptions: MetricsType = getLogOptions() || {};
    setMetricOptions(logOptions);

    let opts: OptionsType[] = [];

    if (currentType === SelectionType.Attributes) {
      opts = Object.keys(logOptions).map((key: string) => {
        const label = logOptions[key]?.label;
        return {
          value: key,
          label: label && label.length > 0 ? label : key,
        };
      });
      if (extraAttributes?.length) {
        opts = [...extraAttributes, ...opts];
      }
    } else if (currentType === SelectionType.Operator) {
      opts = sqlOperators
        .filter(
          (item: SqlOperatorType) => ALLOWED_OPERATORS.indexOf(item.value) > -1
        )
        .map((f: SqlOperatorType) => {
          return {
            value: f.value,
            label: f.title,
          };
        });
    } else {
      if (runningQuery?.attribute) {
        if (logOptions[runningQuery.attribute]?.values) {
          if (logOptions[runningQuery.attribute]?.values?.length) {
            const customWidget: CustomWidget[] = [];
            const _params: RequestParam[] = [
              { key: "from_ts", value: startDateParam },
              { key: "to_ts", value: endDateParam },
            ];
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const userQuery: Query = JSON.parse(JSON.stringify(defaultQuery));
            const resourceType = {
              columns: [runningQuery.attribute || ""],
              resourceType: caller,
              name: `get-filter-data`,
              widgetAppId: 5,
            };
            userQuery.columns = resourceType.columns;
            userQuery.source.name = resourceType.resourceType;
            userQuery.with = [];
            const inFilters: FilterDataType[] = [];
            const likeFilters: FilterDataType[] = [];
            const inFiltersAnd = builderFiltersInstance.And(inFilters);
            const likeFiltersAnd = builderFiltersInstance.Or(likeFilters);
            let filters = undefined;
            if (inFiltersAnd && likeFiltersAnd) {
              filters = builderFiltersInstance.And([
                inFiltersAnd,
                likeFiltersAnd,
              ]);
            } else if (inFiltersAnd) {
              filters = inFiltersAnd;
            } else if (likeFiltersAnd) {
              filters = likeFiltersAnd;
            }
            if (filters) {
              const fValues =
                builderFiltersInstance.ConvertToValueType(filters);
              if (fValues) {
                userQuery.with.push({
                  key: GLOBAL_ATTRIBUTE_FILTER,
                  value: fValues,
                  is_arg: true,
                });
              }
            }
            userQuery.with.push({
              key: SELECT_DATA_BY,
              value: resourceType.columns,
              is_arg: true,
            });
            let mw_filter = null;
            if (is_datadog_agent) {
              mw_filter = builderFiltersInstance.Equal("mw_source", "datadog");
            } else {
              if (datdog_agent_installed) {
                mw_filter = builderFiltersInstance.NotEqual(
                  "mw_source",
                  "datadog"
                );
              }
            }
            if (mw_filter) {
              userQuery.with.push({
                key: GLOBAL_ATTRIBUTE_FILTER,
                value: mw_filter,
                is_arg: true,
              });
            }
            const widget: CustomWidget = {
              label: "get-filter-values",
              builderId: 0,
              scopeId: 0,
              widgetAppId: 5,
              builderConfig: userQuery,
              builderViewOptions: {
                resource: resourceType,
              },
              params: _params,
            };
            customWidget.push(widget);
            opts =
              logOptions[runningQuery.attribute].values?.map((item: string) => {
                return {
                  value: item,
                  label: item,
                };
              }) || [];
            dispatch(
              requestBuilderData(
                customWidget,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (status: boolean, inflight: boolean, data?: any) => {
                  if (status && data && data.length > 0) {
                    let newopts: OptionsType[] = [];
                    for (let ind = 0; ind < data.length; ind++) {
                      const index = ind;
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                      const items = data[index].chart_data.data as GridviewData;
                      items.forEach((d: Record<string, any>) => {
                        const attribute = resourceType.columns[0];
                        const attributeValue =
                          typeof d[resourceType.columns[0]] === "boolean"
                            ? d[resourceType.columns[0]].toString()
                            : d[resourceType.columns[0]];
                      //TODO: Need to add relevant props instead of static array of filter keys & filterValue map
                        if (["kind", "statusCode"].includes(attribute)) {
                          newopts.push({
                            value: d[attribute].toString(),
                            label: filterValuesMap[attribute][d[attribute]],
                          });
                        } else if (attributeValue.length > 0) {
                          newopts.push({
                            value: attributeValue,
                            label: attributeValue,
                          });
                        }
                      });
                    }
                    setFixedOptions([...newopts]);
                    setOptions([...newopts]);
                  }
                }
              )
            );
          }
        }
      } else {
        opts = [];
      }
    }

    setFixedOptions([...opts]);
    setOptions([...opts]);
  }, [querybarFilters, currentType]);

  const onClickOutsideHandler = (event: MouseEvent) => {
    const elem: HTMLElement = event.target as HTMLElement;
    const containsElement =
      elem &&
      elem.classList &&
      (elem.classList?.contains("input-field") ||
        elem.classList?.contains("query-options") ||
        elem.classList?.contains("options-items") ||
        elem.classList?.contains("option") ||
        elem.classList?.contains("options-title") ||
        elem.classList?.contains("options-label"));

    if (!containsElement) {
      setFocused(false);
      setHighlitedIndex(-1);
    }
  };

  const getLogOptions = (): MetricsType => {
    const logOptions: MetricsType = {};
    if (!querybarFilters.filters.items?.length) return logOptions;

    querybarFilters.filters.items.forEach((item) => {
      if (!item.name) return;
      logOptions[item.name] = item;
    });

    return logOptions;
  };

  const handleRemoveFilters = (filterKey: string) => {
    const tempArr = { ...appliedFilters };
    delete tempArr[filterKey];
    appliedFilters[filterKey].values = [];
    if (!isCustom) {
      routeData.actions.handleApplyFilters(appliedFilters);
    }
    if (onChange) onChange(appliedFilters);
  };

  const handleFocus = (): void => {
    const allQuery: NodeListOf<HTMLElement> =
      document.querySelectorAll(".search-container");

    // setFocused(true);
    if (allQuery.length > 0) {
      const check = allQuery[allQuery.length - 1].getBoundingClientRect();
      setWidth(check.width);
    }
  };

  const setOptionValues = (text?: string) => {
    if (text) {
      const opts = fixedOptions.filter((item: OptionsType) => {
        return item.label.includes(text);
      });
      setOptions(opts);
    } else {
      setOptions(fixedOptions);
    }
  };

  const getAttrNameFromSearch = (search: string) => {
    if (currentType === SelectionType.Attributes) {
      return search;
    } else if (currentType === SelectionType.Operator) {
      const splits = search.split(`${runningQuery.attribute}`);
      if (splits.length > 1) {
        return splits[1].trim();
      }
    } else {
      const splits = search.split(
        `${runningQuery.attribute + " " + runningQuery.operator}`
      );
      if (splits.length > 1) {
        return splits[1].trim();
      }
    }

    return "";
  };

  const onPrimaryInputChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.trim() === "")
      setCurrentType(SelectionType.Attributes);

    const text: string = event.target.value;
    setInput(text);
    setTempVal(text);
    setHighlitedIndex(-1);
    setOptionValues(getAttrNameFromSearch(text));
  };

  const [tempVal, setTempVal] = useState<string>("");

  const resetOptions = () => {
    let opts: OptionsType[] = [];

    opts =
      Object.keys(metricOptions).map((key: string) => {
        return {
          value: key,
          label: key,
        };
      }) || [];

    setOptions(opts);
    setFixedOptions(opts);
  };

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === "Escape") {
      setInput("");
      setFocused(false);
      setRunningQuery({
        attribute: "",
        operator: FilterOpEnum.Equal,
        values: [],
      });
      setCurrentType(SelectionType.Attributes);
      resetOptions();
    }

    if (event.key === "ArrowUp") {
      if (highlitedIndex == 0) {
        setInput(tempVal);
      }
      if (highlitedIndex >= 0) {
        if (highlitedIndex > 0) {
          if (currentType === SelectionType.Attributes) {
            setInput(options[highlitedIndex - 1]?.value);
          } else if (currentType === SelectionType.Operator) {
            setInput(
              runningQuery.attribute + " " + options[highlitedIndex - 1].value
            );
          } else if (
            runningQuery.operator === FilterOpEnum.In ||
            runningQuery.operator === FilterOpEnum.NotIn
          ) {
            setInput(
              runningQuery.attribute +
                " " +
                runningQuery.operator +
                " " +
                [
                  ...(runningQuery.values ?? []),
                  !runningQuery.values?.includes(
                    options[highlitedIndex + 1]?.value
                  )
                    ? options[highlitedIndex + 1]?.value
                    : "",
                ].join(", ")
            );
          } else {
            setInput(
              runningQuery.attribute +
                " " +
                runningQuery.operator +
                " " +
                options[highlitedIndex - 1].value
            );
          }
        }

        setHighlitedIndex(highlitedIndex - 1);
      }
    }
    if (event.key === "ArrowDown") {
      if (highlitedIndex === -1) {
        setTempVal(input);
      }
      if (options.length - 1 > highlitedIndex) {
        if (currentType === SelectionType.Attributes) {
          setInput(options[highlitedIndex + 1].value);
        } else if (currentType === SelectionType.Operator) {
          setInput(
            runningQuery.attribute + " " + options[highlitedIndex + 1].value
          );
        } else if (
          runningQuery.operator === FilterOpEnum.In ||
          runningQuery.operator === FilterOpEnum.NotIn
        ) {
          setInput(
            runningQuery.attribute +
              " " +
              runningQuery.operator +
              " " +
              [
                ...(runningQuery.values ?? []),
                !runningQuery.values?.includes(
                  options[highlitedIndex + 1].value
                )
                  ? options[highlitedIndex + 1].value
                  : "",
              ].join(", ")
          );
        } else {
          setInput(
            runningQuery.attribute +
              " " +
              runningQuery.operator +
              " " +
              options[highlitedIndex + 1].value
          );
        }

        setHighlitedIndex(highlitedIndex + 1);
      }
    }

    if (event.key === "Enter") {
      let fltr: Record<
        string,
        {
          operator: FilterOpEnum;
          values: string[];
        }
      > = {};
      if (highlitedIndex > -1) {
        selectOptionHandler(options[highlitedIndex]?.value);
      } else {
        if (currentType === SelectionType.Attributes) {
          if (input.startsWith("!") || input.startsWith("~")) {
            const str = input.substring(1);
            fltr = {
              [regexSearchOption]: {
                operator: input.startsWith("!")
                  ? FilterOpEnum.NotLike
                  : FilterOpEnum.Regex,
                values: [str],
              },
            };
            if (!isCustom) {
              routeData.actions.handleApplyFilters(fltr);
            }
            if (onChange) onChange(fltr);
            setInput("");
            setOptionValues();
            setFocused(false);
            setCurrentType(SelectionType.Attributes);
            return;
          }
        }

        //1. Checking for splits by checking if the string contains any operator
        //2. If operator exist creating attribute , operator and value
        //3. If not applying whole string to "body" filter

        const splitObj: {
          attribute?: string;
          operator?: FilterOpEnum;
          values?: string[];
        } = {};

        let isQuery = false;

        for (const elem of ALLOWED_OPERATORS) {
          if (input.includes(elem)) {
            const splits = input.split(elem);
            if (splits && splits.length > 1) {
              isQuery = true;
              splitObj.attribute = splits[0].trim();
              splitObj.operator = operatorMapping[elem];
              if (
                operatorMapping[elem] === FilterOpEnum.In ||
                operatorMapping[elem] === FilterOpEnum.NotIn
              ) {
                splitObj.values = runningQuery.values;
                // splits[1]?.split(",")?.map((s: string) => s.trim()) || [];
              } else {
                splitObj.values = [splits[1].trim()];
              }
            }
            break;
          }
        }

        if (isQuery) {
          if (splitObj?.attribute && splitObj.values && splitObj.operator) {
            fltr = {
              [splitObj.attribute]: {
                operator: splitObj.operator,
                values: splitObj.values,
              },
            };
          }
        } else {
          fltr = {
            [regexSearchOption]: {
              operator: FilterOpEnum.ILIKE,
              values: [input],
            },
          };
        }

        if (!isCustom) {
          routeData.actions.handleApplyFilters(fltr);
        }
        if (onChange) onChange(fltr);
        setInput("");
        setOptionValues();
        setFocused(false);
        setCurrentType(SelectionType.Attributes);
      }
    }
  };

  const selectOptionHandler = (
    option: string,
    append = false,
    isMultiValue = false
  ) => {
    if (append) {
      if (currentType == SelectionType.Attributes) {
        setInput(`${option}`.trim());
      } else if (isMultiValue) {
        setInput(
          (runningQuery.values ?? []).includes(option)
            ? input
                .replace(new RegExp(`\\b${option}\\b,?\\s*`), "")
                .replace(/,\s*$/, "")
                .trim()
            : `${input}, ${option}`.trim()
        );
      } else {
        setInput(`${input} ${option}`.trim());
      }
    } else {
      setInput(input + " ");
    }

    runningQuery.operator !== FilterOpEnum.In &&
      runningQuery.operator !== FilterOpEnum.NotIn &&
      setHighlitedIndex(-1);

    switch (currentType) {
      case SelectionType.Attributes: {
        setCurrentType(SelectionType.Operator);
        setRunningQuery({
          ...runningQuery,
          attribute: option,
        });
        break;
      }

      case SelectionType.Operator: {
        setRunningQuery({
          ...runningQuery,
          operator: operatorMapping[option],
        });
        setCurrentType(SelectionType.Value);
        break;
      }

      case SelectionType.Value: {
        if (
          runningQuery.attribute &&
          runningQuery.operator &&
          [FilterOpEnum.In, FilterOpEnum.NotIn].includes(runningQuery.operator)
        ) {
          const fltr: Record<
            string,
            {
              operator: FilterOpEnum;
              values: string[];
            }
          > = {
            [runningQuery.attribute]: {
              operator: runningQuery.operator,
              values: runningQuery.values?.includes(option)
                ? runningQuery.values.filter((val) => val !== option)
                : [...(runningQuery.values ?? []), option],
            },
          };
          setInFilter(fltr);
          setRunningQuery({
            attribute: runningQuery.attribute,
            operator: runningQuery.operator,
            values: runningQuery.values?.includes(option)
              ? runningQuery.values.filter((val) => val !== option)
              : [...(runningQuery.values ?? []), option],
          });
        } else if (runningQuery.attribute && runningQuery.operator) {
          const fltr: Record<
            string,
            {
              operator: FilterOpEnum;
              values: string[];
            }
          > = {
            [runningQuery.attribute]: {
              operator: runningQuery.operator,
              values: [option],
            },
          };
          handleApplyFilter(fltr);
        }
        break;
      }

      default:
        break;
    }
  };

  const getOptionType = (): string => {
    if (currentType === SelectionType.Attributes) {
      return "Attribute";
    }

    if (currentType === SelectionType.Operator) {
      return "Operator";
    }

    return "Value";
  };

  const handleEditFilter = (filter: string) => {
    setRunningQuery({
      attribute: filter,
      operator: appliedFilters[filter].operator,
    });
    setInput(`${filter} ${appliedFilters[filter].operator}`);
    setCurrentType(SelectionType.Value);
  };

  const handleApplyFilter = (fltr: QueryParams) => {
    setCurrentType(SelectionType.Attributes);
    if (!isCustom) {
      routeData.actions.handleApplyFilters(fltr);
    }
    if (onChange) onChange(fltr);
    setInput("");
    setFocused(false);
    setRunningQuery({
      attribute: "",
      operator: FilterOpEnum.Equal,
      values: [],
    });
  };

  const handleClosePopover = () => {
    if (
      (runningQuery.operator === FilterOpEnum.In ||
        runningQuery.operator === FilterOpEnum.NotIn) &&
      (runningQuery.values ?? [])?.length > 0
    ) {
      handleApplyFilter(inFilter);
    }
  };

  const RenderPopoverContent = () => {
    return (
      <div className={"options-wrapper"} style={{ width: `${width}px` }}>
        <div className={"query-options"}>
          <div className="autocomplete-options">
            {querybarFilters.filters.apiStatus === APIStatus.LOADING && (
              <LogoLoader size={50} />
            )}
            {querybarFilters.filters.apiStatus !== APIStatus.LOADING &&
              options.length <= 0 && (
                <div className="options-items no-filters-option">
                  <span>
                    <span className="options-label">No filters available</span>
                  </span>
                </div>
              )}
            {options.map((item: OptionsType, index: number) => {
              return (
                <div
                  key={"options-item-" + item.value + "-" + index + 1}
                  className={`options-items ${index === highlitedIndex || runningQuery.values?.includes(item.value) ? "highlight" : ""}`}
                  onClick={() =>
                    selectOptionHandler(
                      item.value,
                      true,
                      (runningQuery.values ?? []).length > 0
                    )
                  }
                >
                  <span className="option">
                    <span className={"options-label"}>{item.label}</span>
                    {currentType === SelectionType.Operator && (
                      <span className={"options-title"}>
                        {`attribute ${item.value} "value"`}
                      </span>
                    )}
                  </span>
                  <span className="option-type">{getOptionType()}</span>
                </div>
              );
            })}
          </div>
        </div>

        <div className={"query-guide"}>
          <div className={"instructions"}>
            <div className="left-aligned-instructions">
              <div className="instruction-block">
                <span className="ins-title">Use arrow Keys</span>

                <KeyboardArrowUpKeyIcon />

                <KeyboardArrowDownKeyIcon />
                <span className="ins-title">to navigate & </span>

                <KeyboardReturnKeyIcon />
                <span className="ins-title">to select</span>
              </div>

              <div className="instruction-block">
                <span className={"addons"}>
                  <b> ! </b>&quot;Text&quot; <b> (exclude) </b>
                  <span>entries</span>
                </span>
              </div>

              <div className="instruction-block">
                <span className={"addons"}>
                  <b> ~ </b>&quot;Regex&quot;
                  <span className="ins-title">to body</span>
                </span>
              </div>

              <div className="instruction-block">
                <span>
                  <span>
                    Use (<b> , </b>){" "}
                  </span>
                  <span> bewteen values after </span>
                  <span className={"addons"}> IN </span>
                  <span> {` / `} </span>
                  <span className={"addons"}>NOT IN </span>
                  <span className="ins-title">
                    {" "}
                    operator for multiple values{" "}
                  </span>
                </span>
              </div>
            </div>

            <div className="right-aligned-instructions">
              <div className="instruction-block">
                <EscapeKeyIcon />

                <span className="ins-title">to Dismiss</span>
              </div>
              {(runningQuery.operator === FilterOpEnum.In ||
                runningQuery.operator === FilterOpEnum.NotIn) &&
                currentType === SelectionType.Value &&
                options.length > 0 && (
                  <div style={{ maxWidth: "fit-content", cursor: "pointer" }}>
                    <Button
                      onClick={() => handleApplyFilter(inFilter)}
                      primary
                      size={Size.Small}
                    >
                      Apply Filters
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="global-search-wrapper">
      <Popover
        isVisible={focussed}
        onClose={() => handleClosePopover()}
        content={<RenderPopoverContent />}
        shouldCloseOnInnerClick={false}
      >
        <div className="global-search">
          <div className="search-options-container">
            <div className="search-container">
              <div className="search-icon">
                <SearchIcon />
              </div>
              <input
                id={`${uniqueId}`}
                className="input-field"
                placeholder={
                  placeholder ?? "Search by name, tag, label or annotation"
                }
                onFocus={handleFocus}
                onClick={() => setFocused(true)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onPrimaryInputChangeHandler(e)
                }
                onKeyDown={handleKeyPress}
                value={input}
                key={`${uniqueId}`}
              />
            </div>
            {handleRunQuery && (
              <div
                className={`query-button ${showTextToQuery ? "active" : ""}`}
              >
                <Button
                  outlined
                  onClick={() => setShowTextToQuery((prev) => !prev)}
                  prefixicon={<CodeIcon />}
                />
              </div>
            )}
            {/* Note: This will be used once we'll implement the functionality */}
            {/* {!hideGroupByLabel && (
            <Button prefixicon={<GroupByIcon />}>
              Group by tag, label or annotation
            </Button>
          )} */}
            {extraBtnLabel && (
              <Button
                secondary
                prefixicon={extraBtnPrefixIcon}
                onClick={onExtraBtnClick}
              >
                {extraBtnLabel}
              </Button>
            )}
            {extraJSXElement}
          </div>
          {showTextToQuery && (
            <TextToQuery uniqueId={uniqueId} handleRunQuery={handleRunQuery} />
          )}
          {appliedFilters && Object.keys(appliedFilters).length > 0 && (
            <div className="applied-filters-container">
              <span className="applied-filters-label">Applied Filters:</span>
              {/* //TODO: Need to add relevant props instead of static array of filter keys & filterValue map */}
              {Object.keys(appliedFilters).map((filter) => {
                const filterValues = ["kind", "statusCode"].includes(filter)
                  ? appliedFilters[filter].values
                      .map((val) => filterValuesMap[filter][val])
                      .join(", ")
                  : appliedFilters[filter].values.join(",");
                return (
                  <div key={filter} className="filter-tag">
                    <Tooltip content={"Click to edit"}>
                      <div
                        className="filter-info"
                        onClick={() => handleEditFilter(filter)}
                      >
                        <span>
                          {["kind", "statusCode"].includes(filter)
                            ? filterValuesMap[filter].key
                            : filter}
                        </span>
                        <span>{appliedFilters[filter].operator}</span>
                        <span className="filter-value-span">
                          {filterValues}
                        </span>
                      </div>
                    </Tooltip>
                    <div
                      onClick={() => handleRemoveFilters(filter)}
                      className="icon-container"
                    >
                      <CancelIcon size={10} />
                    </div>
                  </div>
                );
              })}
              <span
                className="clear-all"
                onClick={() => {
                  if (!isCustom) {
                    routeData.actions.handleApplyFilters({});
                  }
                  if (onChange) onChange({});
                }}
              >
                Clear All
              </span>
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};
export default memo(GlobalQueryBar);
