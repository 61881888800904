import React from "react";
import classNames from "classnames";

interface Props {
  selected?: boolean;
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  prefixIcon?: React.ReactNode;
  className?: string;
}
export const PopoverItem = (props: Props) => {
  const { children, selected, prefixIcon, onClick, className, disabled } =
    props;
  const itemClassNames = classNames("popover-menu-item-wrapper", className, {
    "is-selected": selected,
    "is-disabled": disabled,
    clickable: typeof onClick === "function",
  });
  return (
    <div
      className={itemClassNames}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      {prefixIcon && <span className={"prefix-icon"}>{prefixIcon}</span>}
      <span className="item-child-elements">{children}</span>
    </div>
  );
};
