import React from "react";
import InstallationSteps from "../../../components/steps";
import NavTab from "core/components/v2/nav-tab";
import Content from "./content";
import TabItems from "core/components/v2/nav-tab/items";

interface NextJsIntegrationProps {
  serverlessSupport: boolean;
}
const NextJsIntegration: React.FC<NextJsIntegrationProps> = ({
  serverlessSupport,
}) => {
  return (
    <>
      {serverlessSupport && (
        <InstallationSteps>
          <NavTab>
            <TabItems title="Serverless" key="serverless">
              <Content serverless="true" />
            </TabItems>

            <TabItems title="With Host" key="host-based">
              <Content serverless="false" />
            </TabItems>
          </NavTab>
        </InstallationSteps>
      )}
    </>
  );
};
export default NextJsIntegration;
