import { getTenantUrl } from "core/application/utils";
import FormTextInput from "core/components/v2/form/form-text-input";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import Command from "../../../components/code-block/command";
import StepItem from "../../../components/steps/step-item";
import APMAgentConnect from "../../common/apm-agent-connect";
import ViewYourData from "../../common/view-data-buttons";

interface ContentProps {
  serverless: string;
}

const Content: React.FC<ContentProps> = ({ serverless }) => {
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];

  interface StepFormat {
    title: string;
    content: React.ReactNode;
  }
  const [activeServiceName, setActiveServiceName] = useState("");
  const sendEvent = useMixPanel();
  const steps: StepFormat[] = [
    {
      title: "Install Go APM Package",
      content: (
        <>
          Run this command at the root of your workspace: Make sure you have
          installed the Go version with greater than{" "}
          <a href="https://go.dev/doc/go1.17" target="_blank" rel="noreferrer">
            v1.17+
          </a>
          .
          <br />
          <br />
          <Command
            command={`go get github.com/middleware-labs/golang-apm`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Go",
                command_type: "Install Go APM Package",
              });
            }}
          />
          Add this import statement in your application:
          <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Go",
                command_type: "Install Go APM Package",
              });
            }}
            command={`import (
  track "github.com/middleware-labs/golang-apm/tracker" 
)`}
            className="margin-1"
          />
          <div className="apm-service-name-block">
            <div className="apm-service-name-label">
              Add this snippet in your code{" "}
            </div>
            <FormTextInput
              id="go-service-text-input"
              // label={"Service Name"}
              className="apm-service-name-input"
              placeholder={"Service Name"}
              value={activeServiceName}
              onChange={(e: any) => setActiveServiceName(e)}
            />
          </div>
          <br />
          {serverless == "true" && (
            <Command
              command={`go track.Track( 
  track.WithConfigTag("service", "${activeServiceName == "" ? "{APM-SERVICE-NAME}" : activeServiceName}"),
  track.WithConfigTag("accessToken", "${context.user?.account?.agent_config?.token}"),
  track.WithConfigTag("target", "${url}:443"),
)`}
              className="margin-1"
              onCopyClick={() => {
                sendEvent("click on copy command", {
                  agent_type: "Go",
                  command_type: "Install Go APM Package",
                });
              }}
            />
          )}
          {serverless != "true" && (
            <Command
              command={`go track.Track( 
  track.WithConfigTag("service", "${activeServiceName == "" ? "{APM-SERVICE-NAME}" : activeServiceName}"),
  track.WithConfigTag("accessToken", "${context.user?.account?.agent_config?.token}"),
)`}
              className="margin-1"
              onCopyClick={() => {
                sendEvent("click on copy command", {
                  agent_type: "Go",
                  command_type: "Install Go APM Package",
                });
              }}
            />
          )}
        </>
      ),
    },
    {
      title: "Enable Custom Logs (Optional)",
      content: (
        <>
          Custom logs are ones that the application does not produce by default.
          They may be generated within certain methods, or managed by a central
          logging method in your application.To get started with custom logs add
          the following code: <br />
          <br />
          <Command
            command={`"github.com/middleware-labs/golang-apm/logger"
....
logger.Error("Error")
logger.Info("Info")
logger.Warn("Warn")`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Go",
                command_type: "Enable Custom Logs (Optional)",
              });
            }}
          />
          Enable Stack Errors Use the errorRecord method to record stack traces
          that throw an error object: <br />
          <br />
          <Command
            command={`r.GET("/books", func(c *gin.Context) {
    ctx := req.Context()
    if err := db.Ping(ctx); err != nil {
        track.RecordError(ctx, err)
        panic(err)
    }
})`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Go",
                command_type: "Enable Custom Logs (Optional)",
              });
            }}
          />
        </>
      ),
    },
  ];

  let indexOffset = 0;
  if (serverless != "true") {
    indexOffset = 2;
  }

  return (
    <>
      {serverless != "true" && <APMAgentConnect agentName="Go" />}
      {steps.map((step, index) => (
        <StepItem stepNumber={index + indexOffset + 1} title={step.title}>
          {step.content}
        </StepItem>
      ))}
      <ViewYourData
        language="Golang"
        stepNumber={steps.length + indexOffset + 1}
      />
    </>
  );
};
export default Content;
