import {
  APPLICATION_LIST_RECEIVED,
  CORE_WEB_VITAL_GRAPH_REQUESTED,
  CORE_WEB_VITALS_REQUESTED,
  ERROR_SPAN_GRAPH_RECEIVED,
  ERROR_SPAN_LIST_RECEIVED,
  LOADING_TIME_GRAPH_REQUESTED,
  PERCENTAGE_ERROR_GRAPH_REQUESTED,
  RUM_DASHBOARD_ERROR_LIST_RECEIVED,
  RUM_DASHBOARD_ERROR_STATS_RECEIVED,
  RUM_DASHBOARD_FILTERS_RECEIVED,
  RUM_DASHBOARD_TOP_URLS_RECEIVED,
  RUM_DASHBOARD_USER_STATS_RECEIVED,
  RUM_SUB_NAV_TAB_CHANGE,
  SESSION_ERROR_GRAPH_RECEIVED,
  SESSION_ERROR_LIST_RECEIVED,
  SESSION_ERRORS_RECEIVED,
  SESSION_FILTERS_RECEIVED,
  SESSION_GRAPH_RECEIVED,
  SESSION_LIST_RECEIVED,
  SESSION_RECORDING_RECEIVED,
  SESSION_RECORDING_STATUS_RECEIVED,
  SESSION_RECORDING_TIME_RECEIVED,
  SESSION_STATUS_RECEIVED,
  SESSION_TRACES_RECEIVED,
  SESSION_USER_STATUS_RECEIVED,
  USER_SESSIONS_GRAPH_RECEIVED,
} from "store/rum/constant";

export const applicationListReceived = (arg: any = {}) => {
  return {
    type: APPLICATION_LIST_RECEIVED,
    ...arg,
  };
};

export const userSessionGraphReceived = (arg: any = {}) => {
  return {
    type: USER_SESSIONS_GRAPH_RECEIVED,
    ...arg,
  };
};

export const loadingTimeGraphReceived = (arg: any = {}) => {
  return {
    type: LOADING_TIME_GRAPH_REQUESTED,
    ...arg,
  };
};

export const percentageErrorGraphReceived = (arg: any = {}) => {
  return {
    type: PERCENTAGE_ERROR_GRAPH_REQUESTED,
    ...arg,
  };
};
export const coreWebVitalsReceived = (arg: any = {}) => {
  return {
    type: CORE_WEB_VITALS_REQUESTED,
    ...arg,
  };
};
export const coreWebVitalGraphReceived = (arg: any = {}) => {
  return {
    type: CORE_WEB_VITAL_GRAPH_REQUESTED,
    ...arg,
  };
};
export const rumDashboardFiltersReceived = (arg: any = {}) => {
  return {
    type: RUM_DASHBOARD_FILTERS_RECEIVED,
    ...arg,
  };
};
export const rumDashboardErrorListReceived = (arg: any = {}) => {
  return {
    type: RUM_DASHBOARD_ERROR_LIST_RECEIVED,
    ...arg,
  };
};
export const rumDashboardErrorStatsReceived = (arg: any = {}) => {
  return {
    type: RUM_DASHBOARD_ERROR_STATS_RECEIVED,
    ...arg,
  };
};
export const rumDashboardTopUrlsReceived = (arg: any = {}) => {
  return {
    type: RUM_DASHBOARD_TOP_URLS_RECEIVED,
    ...arg,
  };
};
export const rumDashboardUserStatsReceived = (arg: any = {}) => {
  return {
    type: RUM_DASHBOARD_USER_STATS_RECEIVED,
    ...arg,
  };
};
export const rumSessionGraphReceived = (arg: any = {}) => {
  return {
    type: SESSION_GRAPH_RECEIVED,
    ...arg,
  };
};

export const rumErrorGraphReceived = (arg: any = {}) => {
  return {
    type: SESSION_ERROR_GRAPH_RECEIVED,
    ...arg,
  };
};

export const rumErrorListReceived = (arg: any = {}) => {
  return {
    type: SESSION_ERROR_LIST_RECEIVED,
    ...arg,
  };
};

export const rumSessionListReceived = (arg: any = {}) => {
  return {
    type: SESSION_LIST_RECEIVED,
    ...arg,
  };
};

export const rumRecordingStatusReceived = (arg: any = {}) => {
  return {
    type: SESSION_RECORDING_STATUS_RECEIVED,
    ...arg,
  };
};

export const rumRecordingTimeReceived = (arg: any = {}) => {
  return {
    type: SESSION_RECORDING_TIME_RECEIVED,
    ...arg,
  };
};
export const rumUserStatusReceived = (arg: any = {}) => {
  return {
    type: SESSION_USER_STATUS_RECEIVED,
    ...arg,
  };
};

export const sessionStatusReceived = (arg: any = {}) => {
  return {
    type: SESSION_STATUS_RECEIVED,
    ...arg,
  };
};
export const rumSessionFiltersReceived = (arg: any = {}) => {
  return {
    type: SESSION_FILTERS_RECEIVED,
    ...arg,
  };
};
export const rumSessionTracesReceived = (arg: any = {}) => {
  return {
    type: SESSION_TRACES_RECEIVED,
    ...arg,
  };
};

export const rumSessionRecordingReceived = (arg: any = {}) => {
  return {
    type: SESSION_RECORDING_RECEIVED,
    ...arg,
  };
};
export const rumSessionErrorsReceived = (arg: any = {}) => {
  return {
    type: SESSION_ERRORS_RECEIVED,
    ...arg,
  };
};

export const errorSpanGraphReceived = (arg: any = {}) => {
  return {
    type: ERROR_SPAN_GRAPH_RECEIVED,
    ...arg,
  };
};

export const errorSpanListReceived = (arg: any = {}) => {
  return {
    type: ERROR_SPAN_LIST_RECEIVED,
    ...arg,
  };
};

export const setRumActiveTab = (key: string, args: number) => {
  return {
    type: RUM_SUB_NAV_TAB_CHANGE,
    args: {
      key,
      tab: args,
    },
  };
};
