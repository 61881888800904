import React, {useEffect, useState} from "react";
import Search from "core/components/search";

import InstrumentationItems from "views/modules/integrations/components/instrumentation";
import {
    InstrumentationIntegrationItems,
    IntegrationItem,
} from "views/modules/integrations/entity";

let filteredIntegrationItemList = InstrumentationIntegrationItems


export default function InstrumentationCommon() {
    const urlHash = (window.location.hash).replace('#', '');
    let hash = window?.location?.hash?.split("/")[1]
    if (urlHash.split("/").length > 2) {
        hash = urlHash.split('/')[2] || ''
    }
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearch = (searchTerm: string) => {
        setSearchTerm(searchTerm);
        const filteredinstrumentationList = InstrumentationIntegrationItems.filter(item =>
            item.title && item.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
        if (!searchTerm) {
            filteredIntegrationItemList = InstrumentationIntegrationItems;
        } else {
            filteredIntegrationItemList = filteredinstrumentationList;
        }
    };

    useEffect(() => {
        return () => {
            filteredIntegrationItemList = InstrumentationIntegrationItems;
        }
    }, []);

    const filteredItems: IntegrationItem[] = filteredIntegrationItemList.filter(item => item.category && item.category.indexOf(hash) !== -1)
    return (<>
        <div className="all-integration-screen-container">
            <div className="integration-container">
                <Search
                    onKeyDown={(a: any, b: any) => {
                    }}
                    onSearchClose={(a: any, b: any) => {
                    }}
                    value={''}
                    onSearch={handleSearch}
                    placeholder={'Search...'}/>
                <div className="integration-row">
                    {
                        <InstrumentationItems items={filteredItems}/>
                    }
                </div>
            </div>
        </div>
    </>)
}