/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import ChartsV2 from "core/components/v2/charts";
import { PointOptionsObject } from "highcharts";
import React from "react";
import { DateRange } from "views/layouts/app/routes/model";
import { generateHashAndMetricColor } from "views/modules/helper";
import {
  BuilderNestedProps,
  GridviewDataType,
} from "../../../../../views/modules/builder/entities/builder.entities";
import {
  RenderTpl,
  getValueFromConfig,
} from "../../../../../views/modules/builder/entities/tpl.entities";
import NoDataSpace from "../../no-data";
import { ChartType } from "../models";
interface BuilderTreeChart {
  treeChartDaata: GridviewDataType | undefined;
  nestedProps?: BuilderNestedProps;
  onDateChange?: (range: DateRange) => void;
}
const BuilderTreeChartV2 = (props: BuilderTreeChart) => {
  const { treeChartDaata, onDateChange } = props;
  if (!treeChartDaata) return null;
  const columnConfig = props.nestedProps?.columnConfig;
  let tpl: RenderTpl | undefined = undefined;
  const metrics = treeChartDaata.columns.filter((col) => col.isMetric);
  const groups = treeChartDaata.columns.filter((col) => !col.isMetric);
  if (!treeChartDaata.data.length || !metrics.length) {
    return <NoDataSpace />;
  }
  const generateData = () => {
    const chartData: (PointOptionsObject & {
      custom?: Record<string, string>;
    })[] = [];
    treeChartDaata.data.map((item) => {
      const groupName = groups.map((group) => item[group.accessor]).join("-");
      metrics.forEach((metric) => {
        const cConfig = columnConfig?.[metric.accessor];
        tpl = cConfig?.tpl || metric.tpl;
        let name = groupName + "-" + metric.accessor;
        if (metrics.length == 1) {
          name = groupName;
          if (cConfig?.title) {
            name = groupName + "-" + cConfig.title;
          }
        }
        if (!name) {
          name = metric.accessor;
        }
        if (/^\d+$/.test(name) && groups.length) {
          const number = parseInt(name);
          const group = groups[0];
          if (group) {
            name = getValueFromConfig(number, group.tpl);
          }
        }

        chartData.push({
          name,
          value: item[metric.accessor],
          custom: {
            [name]: getValueFromConfig(item[metric.accessor], tpl),
          },
          color: generateHashAndMetricColor(name).color,
        });
        return metric;
      });
      return item;
    });
    return {
      chartData,
    };
  };
  const { chartData } = generateData();
  return (
    <ChartsV2
      chartType={ChartType.TREEMAP}
      series={[
        {
          type: "treemap",
          data: chartData,
          layoutAlgorithm: "squarified",
          alternateStartingDirection: true,
          borderRadius: 2,
          dataLabels: {
            enabled: true,
            style: {
              color: "var(--color-white)",
              borderColor: "#fff",
              borderRadius: 6,
              textOutline: "none",
            },
            overflow: "allow",
            useHTML: true,
            formatter: function (): string {
              if (this?.point?.options?.custom && this.key) {
                const value = this?.point?.options?.custom[this.key];
                return `${this.key}<br/>${value}`;
              }
              return this.key || ``;
            },
          },
        },
      ]}
      extraChartConfig={{
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: [
          {
            enabled: false,
          },
        ],
        // borderWidth: 5
      }}
      yAxsisVisible={false}
      xAxsisVisible={false}
      yAxisConfig={tpl}
      chartHeight={props.nestedProps?.chartHeight}
      showLegend={props?.nestedProps?.showLegend}
      onDateChange={onDateChange}
    />
  );
};

export default BuilderTreeChartV2;
