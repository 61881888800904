/** @format */

/**
 * External dependencies
 */

import * as React from 'react';
import classNames from 'classnames';
import {useState, useEffect} from "react";

interface Props{
	onChange?:(a0: any) => void,
	onKeyDown?: (a0: any) => void,
	checked?: boolean,
	disabled?: boolean,
	id?: string,
	className?: string,
	wrapperClassName?: string,
	toggling?: boolean,
	'aria-label'?: string,
	children?: any,
}
var idNum = 0;
var id: string | number;
const FormToggle = React.memo(function (props: Props) {
	const [mounted, setMounted] = useState(false)
	if(!mounted){
		id = idNum++;
	}
	useEffect(() => {
		setMounted(true)
	}, [])

	const onKeyDown = (event: any) => {
	if ( props.disabled ) {
		return;
	}

	if ( (event.key === 'Enter' || event.key === ' ' ) && props.onChange != undefined) {
		event.preventDefault();
		props.onChange( ! props.checked );
	}

	props.onKeyDown != undefined &&props.onKeyDown( event );
};

	const onClick = (event: any) => {
		if ( event ) {
			event.stopPropagation && event.stopPropagation();
		}

		if ( ! props.disabled  && props.onChange != undefined ) {
			props.onChange( ! props.checked );
		}
	};

	const onLabelClick = (event: any) => {
		if ( props.disabled ) {
			return;
		}
		const nodeName = event.target.nodeName.toLowerCase();
		if ( nodeName !== 'a' && nodeName !== 'input' && nodeName !== 'select' &&  props.onChange != undefined ) {
			event.preventDefault();
			props.onChange( ! props.checked );
		}
	};

	const id__ = props.id || 'toggle-' + id;
	const wrapperClasses = classNames( 'form-toggle__wrapper', props.wrapperClassName, {
		'is-disabled': props.disabled,
	} );
	const toggleClasses = classNames( 'form-toggle', props.className, {
		'is-toggling': props.toggling,
	} );
	return (
		<span className={ wrapperClasses }>
				<input
					id={ id__ }
					className={ toggleClasses }
					type="checkbox"
					checked={ props.checked }
					readOnly={ true }
					disabled={ props.disabled }
				/>
				<label className="form-toggle__label" htmlFor={ id__}>
					<span
						className="form-toggle__switch"
						onClick={ onClick }
						onKeyDown={ onKeyDown }
						role="checkbox"
						aria-checked={ props.checked }
						aria-label={ props[ 'aria-label' ] }
						tabIndex={props.disabled ? -1 : 0 }
					/>
					{ props.children && (

						<span className="form-toggle__label-content" onClick={ onLabelClick }>
							{ props.children }
						</span>

					) }
				</label>
			</span>
	);
})
export default FormToggle
