import * as history from "history";
import Store from "store";

const historyNavigator: any = history.createBrowserHistory();

// @ts-ignore
historyNavigator.redirect = (...options) => {
    // @ts-ignore
    historyNavigator.push(...options)
    Store.dispatch({type: "UPDATE_ROUTES", options})
}

export default historyNavigator