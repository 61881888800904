import {createReducer} from "core/utils/create_reducer";
import {COUNTRIES_LIST_ITEM_REQUEST, COUNTRIES_TIMEZONE_REQUEST} from "./constant";
import {combineReducers} from "redux";

const list = createReducer([], {
    [COUNTRIES_LIST_ITEM_REQUEST]: (state, opts) => {
        if (opts) {
            return opts;
        }
        return {...state};

    }
})

const timezone = createReducer([], {
    [COUNTRIES_TIMEZONE_REQUEST]: (state, opts) => {
        if (opts) {
            return opts;
        }
        return {...state};

    }
})

export default combineReducers({
    list,
    timezone
})