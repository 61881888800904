export enum TimeRangeOption {
  OneMinute = "1m",
  FiveMinutes = "5m",
  FifteenMinutes = "15m",
  OneHour = "1h",
  ThreeHours = "3h",
  TwelveHours = "12h",
  OneDay = "1d",
  TwoDays = "2d",
  ThreeDays = "3d",
  FiveDays = "5d",
  TenDays = "10d",
  TwentyFiveDays = "25d",
  TwentyDays = "20d",
  OneWeek = "1w",
  TwoWeeks = "2w",
  OneMonth = "1M",
  Custom = "custom",
  Empty = "",
}
