import { Message } from "core/components/v2/enums";
import FormTextInput from "core/components/v2/form/form-text-input";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import Command from "../../../components/code-block/command";
import MwMessage from "../../../components/message";

import { getTenantUrl } from "core/application/utils";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import StepItem from "../../../components/steps/step-item";
import APMAgentConnect from "../../common/apm-agent-connect";
import ViewYourData from "../../common/view-data-buttons";

interface ContentProps {
  serverless: string;
}

const Content: React.FC<ContentProps> = ({ serverless }) => {
  const [appEnv, setAppEnv] = useState<number>(1);
  const [appBehaviour, setAppBehaviour] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];

  interface StepFormat {
    title: string;
    content: React.ReactNode;
  }

  const [activeServiceName, setActiveServiceName] = useState("");
  const sendEvent = useMixPanel();
  const steps: StepFormat[] = [
    {
      title: "Setup Middleware .NET project",
      content: (
        <>
          To install the Middleware Agent, your .NET version must have version
          6.0+. Make sure you have Curl, Unzip tools installed, in order to go
          forward with .NET APM. Add Following code in .csproj file.
          <br />
          <br />
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: ".NET",
                command_type: "Setup Middleware .NET project",
              });
            }}
            command={`<ItemGroup>
  <Reference Include="Middleware">
    <HintPath>path\\to\\Middleware.dll</HintPath>
  </Reference>
</ItemGroup>`}
            className="margin-1"
          />
        </>
      ),
    },
    {
      title: ".NET binary installation",
      content: (
        <>
          Download the bash script.
          <br />
          <br />
          <Command
            command={`curl -sSfL https://install.middleware.io/apm/dotnet/v1.0.0-rc.1/scripts/mw-dotnet-auto-install.sh -O`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: ".NET",
                command_type: ".NET binary installation",
              });
            }}
          />
          Install core files
          <br />
          <br />
          <Command
            command={`sh ./mw-dotnet-auto-install.sh`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: ".NET",
                command_type: ".NET binary installation",
              });
            }}
          />
          <Command
            command={`rm -rf mw-dotnet-auto-install.sh`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: ".NET",
                command_type: ".NET binary installation",
              });
            }}
          />
          Enable execution for the instrumentation script
          <br />
          <br />
          <Command
            command={`chmod +x $HOME/.mw-dotnet-auto/instrument.sh`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: ".NET",
                command_type: ".NET binary installation",
              });
            }}
          />
          <div className="apm-service-name-block">
            <div className="apm-service-name-label">
              Run your .NET application with command given below:{" "}
            </div>
            <FormTextInput
              id="dotnet-service-text-input"
              className="apm-service-name-input"
              placeholder={"Service Name"}
              value={activeServiceName}
              onChange={(e: any) => setActiveServiceName(e)}
            />
          </div>
          <br />
          <Command
            command={`MW_API_KEY=${context.user?.account?.agent_config?.token} . $HOME/.mw-dotnet-auto/instrument.sh && OTEL_SERVICE_NAME="${activeServiceName == "" ? "{APM-SERVICE-NAME}" : activeServiceName}" dotnet path/to/YourApplication.dll`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: ".NET",
                command_type: ".NET binary installation",
              });
            }}
          />
          If you are running your .NET app as a container, you can include all
          this steps in your Dockerfile as mentioned below
          <br />
          <br />
          <MwMessage
            type={Message.Warning}
            message="Make sure to modify your Dockerfile with the instructions given below, before re-deploying your app on Kubernetes"
          ></MwMessage>
          <Command
            command={`RUN apt-get update && apt-get install -y curl unzip
RUN curl -sSfL https://install.middleware.io/apm/dotnet/v1.0.0-rc.1/scripts/mw-dotnet-auto-install.sh -O 
RUN bash mw-dotnet-auto-install.sh 
RUN rm -rf mw-dotnet-auto-install.sh
RUN chmod +x $HOME/.mw-dotnet-auto/instrument.sh
...........
CMD . $HOME/.mw-dotnet-auto/instrument.sh && dotnet Mw-WebApplication.dll`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: ".NET",
                command_type: ".NET binary installation",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Enable Custom Logs (Optional)",
      content: (
        <>
          Custom logs are ones that the application does not produce by default.
          They may be generated within certain methods, or managed by a central
          logging method in your application. To get started with custom logs
          add the following code: <br />
          <br />
          <Command
            command={`using Middleware.Logger;
...
Logger.Info("This is info log");
Logger.Warning("This is info log");
Logger.Debug("This is info log");
Logger.Error(<Object of Exception>);`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: ".NET",
                command_type: "Enable Custom Logs (Optional)",
              });
            }}
          />
        </>
      ),
    },
    {
      title: "Application Logs feature Configuration.",
      content: (
        <>
          Add required package reference in YourApp.csproj file. <br />
          <br />
          <Command
            command={`<Project Sdk="Microsoft.NET.Sdk.Web">    
    ...
    <ItemGroup>
      <PackageReference Include="OpenTelemetry" Version="1.5.1" />
      <PackageReference Include="OpenTelemetry.Exporter.Console" Version="1.5.1" />
    </ItemGroup>
    ...
</Project>`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: ".NET",
                command_type: "Application Logs feature Configuration.",
              });
            }}
          />
          Add the following code to your Project.cs file to enable Application
          logs:
          <br />
          <br />
          <Command
            command={`using OpenTelemetry.Logs;
using OpenTelemetry.Resources;
...
var builder = WebApplication.CreateBuilder(args);
builder.Logging.AddOpenTelemetry(options => 
{
    options.AddConsoleExporter();
});
...

var app = builder.Build();
...
app.Run();`}
            className="margin-1"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: ".NET",
                command_type: "Application Logs feature Configuration.",
              });
            }}
          />
        </>
      ),
    },
  ];

  let indexOffset = 0;
  if (serverless != "true") {
    indexOffset = 2;
  }

  return (
    <>
      {serverless != "true" && <APMAgentConnect agentName=".NET" />}
      {steps.map((step, index) => (
        <StepItem stepNumber={index + indexOffset + 1} title={step.title}>
          {step.content}
        </StepItem>
      ))}
      <ViewYourData
        language=".NET"
        stepNumber={steps.length + indexOffset + 1}
      />
    </>
  );
};
export default Content;
