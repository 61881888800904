import React, {useContext, useState} from 'react';
import {showToast} from "store/toast-alerts/actions";
import {useDispatch} from "react-redux";
import {
    DebAndUbuntuColorSvg,
    KubernetesColorSvg,
    DockerColorSvg,
    AlertsIntegrationIcon, ContainerizedIcon, NativelyIcon, CopyIconNew
} from "core/components/svg/icons";
import AppContext, {ContextProps} from "core/components/wrapper/context";
import CodeViewer from "core/components/code-viewer";
import Button from "core/components/button";
import FormTextInput from "core/components/form/form-text-input";
import CommonAgentInstallation from "views/modules/installation/components/apm/common-agent-installation";
import {StepsEntity, StepsEnum} from "views/modules/integrations/entity";

export default function Nextjs() {

    const context = useContext<ContextProps>(AppContext);
    const isHelloBarRendered = document.querySelector('.hello-bar') !== null;

    const dispatch = useDispatch();

    function copyToClipboard(text: string) {

        navigator.clipboard.writeText(text)
            .then(() => {
                dispatch(showToast("success", "Copied to Clipboard !"))
            });
    }


    const [activeServiceName, setActiveServiceName] = useState('{APM-SERVICE-NAME}');
    const projectNameHost = "// @ts-ignore \n" +
        "import tracker from '@middleware.io/agent-apm-nextjs'; \n \n" +
        "export function register() { \n" +
        "   tracker.track({\n" +
        `        serviceName: "${activeServiceName || '{APM-SERVICE-NAME}'}", \n  ` +
        `      accessToken: "${context.user?.account?.agent_config?.token}", \n` +
        "   }); \n" +
        "}"

    const modifyNextjs = "const nextConfig = { \n" +
        "   experimental: { \n " +
        "      instrumentationHook: true \n" +
        "   } \n" +
        "} \n" +
        "module.exports = nextConfig"

    const ApmLogginSnap = " // @ts-ignore \n" +
        "import tracker from '@middleware.io/agent-apm-nextjs'; \n \n" +
        "export default async function handler(req, res) { \n \n" +
        "   tracker.info(\"Info Sample\"); \n" +
        "   tracker.warn(\"Warn Sample\", { \n" +
        "       \"tester\": \"Alex\", \n" +
        "   }); \n" +
        "   tracker.debug(\"Debug Sample\"); \n" +
        "   tracker.error(\"Error Sample\");\n \n" +
        "   // Your existing code \n " +
        "}"

    const steps: StepsEntity = {
        deployment_type: {
            title: "Choose Deployment Type",
        },
        code: {
            title: "Getting Started with Code",
        },
        custom_logs: {
            title: "Setup Custom Logs",
        },
        see_data: {
            title: "View Your Data",
        }
    }
    const [activeStep, setActiveStep] = useState(StepsEnum.DeploymentType);
    const [activeDeployment, setActiveDeployment] = useState('kubernetes');
    const [activeAppType, setActiveAppType] = useState('containerized');
    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const MwAgentServiceK8s = "MW_AGENT_SERVICE=mw-service.mw-agent-ns.svc.cluster.local"
    const MwAgentServiceLinuxDocker = "MW_AGENT_SERVICE=172.17.0.1"

    return (
        <>
            <div className={"markdown-body installation-docs apm-installation-doc next-js-doc"}>
                <div className={"apm-body"}>
                    <div className={"apm-steps"}>
                        {
                            steps && Object.keys(steps).map((step, index) => {
                                return <div key={index + 1}
                                            className={`step ${activeStep === step ? 'selected' : ''} ${(Object.keys(steps).indexOf(activeStep) || 0) > index ? 'passed' : ''}`}>
                                    <div className={"count"}>
                                        <span>{index + 1}</span>
                                    </div>
                                    <div className={"detail vercel"}>
                                        {steps[step].title}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className={`apm-content ${!isHelloBarRendered && "hello-bar-exists"}`}>
                        {activeStep === StepsEnum.DeploymentType && <>
                            <div className={"workspace"}>
                                <div className={"blocks"}>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>1. Our APMs require the host agent in order to operate. To install a host agent please select from the below options. </span>
                                            <p><span>(If the host already has an agent running please <span
                                                onClick={() => setActiveStep('code')}> skip to Getting Started with Code.</span>)</span>
                                            </p>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Which platform are you hosting your Next.js application?</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'button-flex'}>
                                                <button
                                                    className={activeDeployment === 'kubernetes' ? 'active' : ''}
                                                    onClick={() => {
                                                        setActiveDeployment('kubernetes')
                                                        setActiveAppType('containerized')
                                                    }}>
                                                    <KubernetesColorSvg/> Kubernetes
                                                </button>
                                                <button className={activeDeployment === 'linux' ? 'active' : ''}
                                                        onClick={() => setActiveDeployment('linux')}>
                                                    <DebAndUbuntuColorSvg/> Linux
                                                </button>
                                                <button className={activeDeployment === 'docker' ? 'active' : ''}
                                                        onClick={() => setActiveDeployment('docker')}>
                                                    <DockerColorSvg/> Docker
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>2. Application Behaviour Will Be</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Please choose from below, as your application will run accordingly.</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'button-flex'}>
                                                <button
                                                    className={activeAppType === 'containerized' ? 'active' : ''}
                                                    onClick={() => setActiveAppType('containerized')}>
                                                    <ContainerizedIcon/> Containerized
                                                </button>
                                                <button
                                                    className={`${activeAppType === 'natively' ? 'active' : ''} ${activeDeployment === 'kubernetes' ? 'disabled' : ''}`}
                                                    onClick={() => {
                                                        if (activeDeployment !== 'kubernetes') {
                                                            setActiveAppType('natively')
                                                        }
                                                    }}>
                                                    <NativelyIcon/> Natively
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {activeAppType === 'containerized' && <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>3. Setup Container Variable</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Application running in a container require an additional environment variable</span>
                                        </div>
                                        <div className={"content"}>
                                            {activeDeployment === 'kubernetes' && <>
                                                {/*<pre onClick={() => {*/}
                                                {/*    copyToClipboard(MwAgentServiceK8s)*/}
                                                {/*}} className={'command'}><code>{MwAgentServiceK8s}</code></pre>*/}
                                                <div className={'docs-script'}>
                                                    <CodeViewer showLineNumbers={false}
                                                                language={'javascript'}>{MwAgentServiceK8s}</CodeViewer>
                                                    <span className={"doc-copy-btn"}
                                                          onClick={() => copyToClipboard(MwAgentServiceK8s)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                                </div>
                                                <span className={"sentence mini"}>The default namespace for running the Middleware agent is <strong>mw-service.mw-agent-ns.svc.cluster.local</strong>.</span><br/>
                                            </>}
                                            {activeDeployment !== 'kubernetes' && <>
                                                <div className={'docs-script'}>
                                                    <CodeViewer showLineNumbers={false}
                                                                language={'javascript'}>{MwAgentServiceLinuxDocker}</CodeViewer>
                                                    <span className={"doc-copy-btn"}
                                                          onClick={() => copyToClipboard(MwAgentServiceLinuxDocker)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                                </div>
                                                <span
                                                    className={"sentence mini"}>The default value for <strong>DOCKER_BRIDGE_GATEWAY_ADDRESS</strong> is typically <strong>172.17.0.1</strong>.</span><br/>
                                                <span className={"sentence mini"}>For more detailed information, refer to the official Docker&nbsp;
                                                    <a href={"https://docs.docker.com/network/network-tutorial-standalone/"}
                                                       target={"_blank"}>documentation</a>.</span>
                                            </>}
                                        </div>
                                    </div>}
                                    <div className={"block"}>
                                        <CommonAgentInstallation activeDeployment={activeDeployment}
                                                                 activeAppType={activeAppType}/>
                                    </div>
                                </div>

                            </div>
                            <div className={"buttons-group"}>
                                <Button className={"disabled"}>Back</Button>
                                <Button primary onClick={() => setActiveStep('code')}>Next</Button>
                            </div>
                        </>}

                        {activeStep === 'code' && <>
                            <div className={"workspace"}>
                                <div className={"blocks"}>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>1. Install Next.js APM package</span>
                                        </div>
                                        <div className={"desc"}>
                                                <span>To install the Middleware Agent, your Next.js
                                            version must have <a
                                                        href={'https://nextjs.org/docs/pages/building-your-application/upgrading'}
                                                        rel={'noreferrer'} target={'_blank'}>13.2.0</a>.</span> <br/>
                                            <span>Run the following command in your terminal:</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'javascript'}>{"npm install @middleware.io/agent-apm-nextjs\n"}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard("npm install @middleware.io/agent-apm-nextjs\n")}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>2. Modify the next.config.js file</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Add the following code to your next.config.js file:</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'javascript'}>{modifyNextjs}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard(modifyNextjs)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>3. Create an Instrumentation file</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Create a custom instrumentation.ts file your project root directory and add the following code:</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={"inputbox-flex"} style={{marginTop: 15}}>
                                                <FormTextInput label={"Service Name"} maxLength={50}
                                                               onChange={(e: any) => setActiveServiceName(e.target.value)}/>
                                            </div>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'ruby'}>{projectNameHost}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard(projectNameHost)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"help"}>
                                    <div className={"block"}>
                                        <AlertsIntegrationIcon/> You can clone the sample project from the <a
                                        href={'https://github.com/middleware-labs/demo-apm/tree/master/nextjs/setup'}
                                        target={"_blank"}>GitHub</a> repository.
                                    </div>

                                </div>
                            </div>
                            <div className={"buttons-group"}>
                                <Button onClick={() => setActiveStep(StepsEnum.DeploymentType)}>Back</Button>
                                <Button primary onClick={() => setActiveStep('custom_logs')}>Next</Button>
                            </div>
                        </>}
                        {activeStep === 'custom_logs' && <>
                            <div className={"workspace"}>
                                <div className={"blocks"}>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>Enable Custom Logs (Optional)</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Custom logs are ones that the application does not produce by default. They may be generated within certain methods, or managed by a central logging method in your application.</span><br/>
                                            <span>To get started with custom logs add the following code:</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'ruby'}>{ApmLogginSnap}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard(ApmLogginSnap)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={"buttons-group"}>
                                <Button onClick={() => setActiveStep('code')}>Back</Button>
                                <Button primary onClick={() => setActiveStep('see_data')}>Next</Button>
                            </div>
                        </>}
                        {activeStep === 'see_data' && <>
                            <div className={"workspace"}>
                                <div className={"blocks"}>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>View Your Data</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Once you have completed the Next.js installation, wait 3-5 minutes before accessing your data.</span>
                                        </div>
                                        <div className={"content"}>
                                            <p className={"paragraph"}
                                               style={{marginTop: "10px", marginBottom: "0"}}>Access your Trace, Log,
                                                and Profiling data by clicking on one of the buttons bellow.</p>
                                            <p className={"paragraph"} style={{marginTop: "0px"}}>Proceed to deploy your
                                                project on {capitalizeFirstLetter(activeDeployment)}.</p>
                                            <div className={"buttons-group"}>
                                                <Button primary
                                                        onClick={() => window.open('/apm/overview', '_blank')}>Traces</Button>
                                                <Button primary
                                                        onClick={() => window.open('/logs', '_blank')}>Logs</Button>
                                                {activeDeployment != "windows" ? <Button primary
                                                                                         onClick={() => window.open('/apm/continuous-profiling', '_blank')}>Profiling</Button> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={"buttons-group"}>
                                <Button onClick={() => setActiveStep('custom_logs')}>Back</Button>
                                <Button className={"disabled"}>Next</Button>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </>
    )
}