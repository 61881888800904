import { APIStatus } from "core/application/utils";
import {
  AppKeyType,
  AttrValues,
  GroupByItem,
  KpiType,
  MetricItem,
  MetricTypeEnum,
  WidgetDataType,
} from "views/modules/builder/entities/builder.entities";
import { APIAction } from "./reducers";

export interface ResourceAPIResp extends APIAction {
  resources?: string[];
}

export interface MetricsAPIResp extends APIAction {
  page?: number;
  limit?: number;
  items?: MetricItem[] | GroupByItem[] | AttrValues[];
}

export const defaultMetricAPIResp: MetricsAPIResp = {
  apiStatus: APIStatus.IDLE,
};

export interface BuilderMetrics {
  metrics?: MetricsAPIResp;
  groupby?: MetricsAPIResp;
  filters?: MetricsAPIResp;
  fltAttSearchAPIResp: APIAction;
}

export interface BarFilters {
  filters: FiltersAPIResp;
}

export interface MetricsReducer {
  builder: BuilderMetrics;
  sidebar: BarFilters;
  querybar: BarFilters;
  resourceAPIResp: ResourceAPIResp;
  infra: Record<string, MetricsAPIResp>;
}

export enum DataType {
  Metrics = "metrics",
  GroupBy = "groupby",
  Filters = "filters",
}

// The enum values in the metrics list API is as per the query_runtime package
export interface MetricListQueryParams {
  kpiType: KpiType;
  widgetType: AppKeyType;
  dataType: DataType;

  page?: number;
  limit?: number;
  search?: string;

  // Optional. ex: host, container, etc.
  resource?: string;
  // Mandatory for "groupby" requests. ex: system.cpu.utilization, container.id, etc.
  metric?: string;

  // Optional: It will be used only for "metrics" data type
  mandatoryMetrics?: string;
  excludeMetrics?: string;

  // Optional: It will be used only for "filters" data type
  mandatoryFilters?: string;
  excludeFilters?: string;
  filterTypes?: string;

  // Indicates whether to return only mandatory data items like "filters" or "metrics"
  returnOnlyMandatoryData?: boolean;
}

export interface FilterAttSearchResp extends APIAction {
  attName?: string;
  attLabel?: string;
  attType?: MetricTypeEnum;
  resource?: string;
  searchResult?: string[];
}

export enum BarType {
  Sidebar = "sidebar",
  Querybar = "querybar",
}

export interface FiltersAPIResp extends MetricsAPIResp {
  items?: AttrValues[];
}
export interface APIResponse {
  status: number;
}
export interface WidgetDataResponse extends APIResponse {
  chart_data: WidgetDataType;
}
export interface WidgetMultiDataResponse extends APIResponse {
  data: WidgetDataResponse[];
}
