import CreateRequest from "core/xhr";
import { userReceived, userDetailReceived } from "./actions";

export const requestUserList = (callback?: (res?: any) => void) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/settings/user",
      method: "get",
      data: {},
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(userReceived(res.data));
        } else {
          dispatch(userReceived());
        }
        if (callback) {
          callback(res);
        }
      })
      .catch((err) => {
        console.log("list: error", err);
        if (callback) {
          callback([]);
        }
      });
  };
};

export const requestUserDetails = (
  data: any,
  callback?: (res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/settings/user/" + data.id,
      method: "get",
      data: {},
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(userDetailReceived(res.data));
        } else {
          dispatch(userDetailReceived());
        }
        if (callback) {
          callback(res);
        }
      })
      .catch((err) => {
        console.log("save: error", err);
      });
  };
};

export const requestUserMultipleSave = (
  data: any,
  callback?: (res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/settings/user-multiple",
      method: "post",
      data,
    })
      .then((res) => {
        if (callback) {
          callback(res);
        }
      })
      .catch((err) => {
        console.log("save: error", err);
      });
  };
};

export const requestUserUpdate = (
  data: any,
  callback?: (res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    return CreateRequest({
      url: "/settings/user/" + data.id,
      method: "put",
      data,
    })
      .then((res) => {
        if (callback) {
          callback(res);
        }
        if (!data?._init && res.status === 200) {
          dispatch(requestUserList());
        }
      })
      .catch((err) => {
        console.log("save: error", err);
      });
  };
};

export const requestUserDelete = (
  data: any,
  callback?: (res?: any) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    // url: '/settings/user/' + data.id,
    // method: 'delete',
    return CreateRequest({
      url: "/settings/user/" + data.id,
      method: "delete",
      data: {},
    })
      .then((res) => {
        if (callback) {
          callback(res);
        }
        if (res.status === 200) {
          dispatch(requestUserList());
        }
      })
      .catch((err) => {
        console.log("save: error", err);
      });
  };
};
