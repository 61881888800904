import { useEffect, useRef } from "react";
import { POLL_INTERVAL } from "./helper";

export interface Poller {
  start: () => void;
  stop: () => void;
}

function usePoller(
  callback: () => void,
  interval: number = POLL_INTERVAL
): Poller {
  const savedCallback = useRef<() => void>();
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const start = () => {
    if (intervalId.current === null) {
      intervalId.current = setInterval(() => {
        if (savedCallback.current) {
          savedCallback.current();
        }
      }, interval);
    }
  };

  // Function to stop polling
  const stop = () => {
    if (intervalId.current !== null) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      stop();
    };
  }, []);

  return { start, stop };
}
export default usePoller;
