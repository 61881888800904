import * as React from "react";

interface NavTabProps {
    classname?:string,
    children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
}

const NavTabWrapper = function (props: NavTabProps) {
    return  <div className={`component__tabs__wrapper ${props?.classname? props.classname: ''}`}>{props?.children}</div>
}
export default NavTabWrapper