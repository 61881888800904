import * as React from "react";
import NavTab from "core/components/nav-tab";
import TabItems from "core/components/nav-tab/items";
import {
    DebAndUbuntuColorSvg, DockerColorSvg, InstallationDocsIcon, KubernetesColorSvg,
    LinuxIcon, PhpSvgV2,
    WindowsSvg
} from "core/components/svg/icons";
import TableGridview, {GridviewColumnsOptions} from "core/components/table-gridview";
import {BkoffRightIcon, BkoffWrongIcon} from "views/layouts/app/nav/_icons";
import PhpAPM from "views/modules/installation/components/apm/php";
import {Link} from "react-router-dom";

export default function PHPAPMInstrument() {

    const dataProvider = [
        {
            agent: <KubernetesColorSvg/>,
            flags: [
                <BkoffRightIcon/>,
                <BkoffWrongIcon/>,
                <BkoffWrongIcon/>,
                <BkoffRightIcon/>,
                <BkoffWrongIcon/>
            ]
        },
        {
            agent: <DebAndUbuntuColorSvg/>,
            flags: [
                <BkoffRightIcon/>,
                <BkoffWrongIcon/>,
                <BkoffWrongIcon/>,
                <BkoffRightIcon/>,
                <BkoffWrongIcon/>
            ]
        },
        {
            agent: <DockerColorSvg/>,
            flags: [
                <BkoffRightIcon/>,
                <BkoffWrongIcon/>,
                <BkoffWrongIcon/>,
                <BkoffRightIcon/>,
                <BkoffWrongIcon/>
            ]
        },
        {
            agent: <WindowsSvg/>,
            flags: [
                <BkoffWrongIcon/>,
                <BkoffWrongIcon/>,
                <BkoffWrongIcon/>,
                <BkoffWrongIcon/>,
                <BkoffWrongIcon/>
            ]
        },
    ];
    const columns: Array<GridviewColumnsOptions> = [
        {
            title: "",
            value: (data: any) => {
                return data.agent;
            }
        },
        {
            title: "Traces",
            value: (data: any) => {
                return data.flags[0];
            }
        },
        {
            title: "Metrics",
            value: (data: any) => {
                return data.flags[1];
            }
        },
        {
            title: "App logs",
            value: (data: any) => {
                return data.flags[2];
            }
        },
        {
            title: "Custom logs",
            value: (data: any) => {
                return data.flags[3];
            }
        },
        {
            title: "Profiling",
            value: (data: any) => {
                return data.flags[4];
            }
        },
    ];

    const dataProviderProfiling = [
        {
            continue: 'Object Allocation',
            item: [
                <span>memory:alloc_objects:count::</span>,
                <BkoffWrongIcon/>,
            ]
        },
        {
            continue: 'Memory Allocation',
            item: [
                <span>memory:alloc_space:bytes::</span>,
                <BkoffWrongIcon/>,
            ]
        },
        {
            continue: 'Objects in Use',
            item: [
                <span>memory:inuse_objects:count</span>,
                <BkoffWrongIcon/>,
            ]
        },
        {
            continue: 'Memory in Use',
            item: [
                <span>memory:inuse_space:bytes</span>,
                <BkoffWrongIcon/>,
            ]
        },
        {
            continue: 'CPU Usage',
            item: [
                <span>process_cpu:cpu:nanoseconds:cpu:nanoseconds</span>,
                <BkoffWrongIcon/>,
            ]
        },
        {
            continue: 'Wall-clock time',
            item: [
                <span>wall:wall:nanoseconds:cpu:nanoseconds</span>,
                <BkoffWrongIcon/>,
            ]
        },
        {
            continue: 'Blocking Contentions',
            item: [
                <span>block:contentions:count::</span>,
                <BkoffWrongIcon/>,
            ]
        },
        {
            continue: 'Blocking Delays',
            item: [
                <span>block:delay:nanoseconds::</span>,
                <BkoffWrongIcon/>,
            ]
        },
        {
            continue: 'Bytes Allocated (TLAB)',
            item: [
                <span>memory:alloc_in_new_tlab_bytes:bytes::</span>,
                <BkoffWrongIcon/>,
            ]
        },
        {
            continue: 'Objects Allocated (TLAB)',
            item: [
                <span>memory:alloc_in_new_tlab_objects:count::</span>,
                <BkoffWrongIcon/>,
            ]
        },
    ];
    const columnsProfiling: Array<GridviewColumnsOptions> = [
        {
            title: "Profiling Category",
            value: (data: any) => {
                return data.continue;
            }
        },
        {
            title: "Profiling Query Type",
            value: (data: any) => {
                return data.item[0];
            }
        },
        {
            title: "Available",
            value: (data: any) => {
                return data.item[1];
            }
        },
    ];
    return <>
        <div className={'cloudflare-apm-new dialog-table-componants'}>
            <div className={' setting_side-panel'}>
                <div className={'integration-popup'}>
                    <div className={'header-section'}>
                        <div className={'d-flex'}>
                            <PhpSvgV2/>
                            <div className={'header-content'}>
                                <h3>PHP</h3>
                                <p>PHP is an open-source general-purpose scripting language. Monitor your PHP
                                    application running on Kubernetes, Linux, Docker, and more.</p>
                            </div>
                            <button className={"docs-link"}><Link
                                to={'https://docs.middleware.io/docs/apm-configuration/php/php-apm-setup'}
                                target={'_blank'}><span> <InstallationDocsIcon/>Documentation</span></Link></button>
                        </div>
                        <NavTab targetIndex={1} defaultActiveTabIndex={1}>
                            <TabItems title={'Overview'}>
                                <div className={'d-flex in-content'}>
                                    <div className={'overview-heading'}>
                                        <div className={'config-overview'}>
                                            <h4>Category</h4>
                                            <span>APM</span>

                                            <h4 className={'tp-mr'}>Supported Version</h4>
                                            <img
                                                src="https://img.shields.io/badge/dev--master-%23f28d1a?label=packagist"
                                                alt="packagist"/>

                                            <h4 className={'tp-mr'}>Supported OS</h4>
                                            <div className={'d-flex'}>
                                                <LinuxIcon/>
                                                <WindowsSvg/>
                                                {/*<MacIcon/>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'overview-content'}>
                                        <div className={'overview-image'}>
                                            <img src={'assets/images/apm-overview.png'}
                                                 alt={'bare-metal-overview'}/>
                                        </div>

                                        <div className={'features-dialog-instrumentation'}>
                                            <h3>Features</h3>
                                            <div className={'d-flex'}>
                                                <button className={'example-linux'}>Disk Persistence</button>
                                                <button className={'example-linux'}>Memory Usage</button>
                                                <button className={'example-linux'}>Performance Metrics</button>
                                            </div>
                                        </div>

                                        <h3>APM Support</h3>
                                        <TableGridview
                                            columns={columns}
                                            dataProvider={dataProvider}
                                        />
                                        <h3 className={'mr-top'}>Continuous Profiling </h3>
                                        <div className={'table-profilling'}>
                                            <TableGridview
                                                columns={columnsProfiling}
                                                dataProvider={dataProviderProfiling}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </TabItems>
                            <TabItems title={'Installation'}>
                                <div className={'panel-body'}>
                                    <PhpAPM/>
                                </div>

                            </TabItems>

                        </NavTab>
                    </div>
                </div>

            </div>
        </div>
    </>
}