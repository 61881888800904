import axios from 'axios';
import { getAuthStore, getBaseURL } from "../utils";



const CreateRequest = axios.create({
    timeout: 90000,
    baseURL: getBaseURL(),
})


axios.defaults.headers.common["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Methods"] = "GET,HEAD,OPTIONS,POST,PUT,DELETE";
axios.defaults.headers.common["Access-Control-Allow-Headers"] = "Origin, X-Requested-With, Content-Type, Accept, Authorization";

CreateRequest.interceptors.request.use((conf) => {
    if(!conf.headers) conf.headers = {}
    const token = getAuthStore()
    if (token)
        conf.headers.Authorization = `Bearer ${token}`

    if (conf.headers['form-data'])
        conf.headers['Content-Type'] = "multipart/form-data"

    const _date:any = new Date().toString()

    conf.headers['CLIENT_TIME_ZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone
    conf.headers['CLIENT_TIME_ZONE_OFFSET'] = _date.match(/([-\+][0-9]+)\s/)[1]
    conf.headers['MW_USER_SESSION_ID'] = (window as any).mw_user_session

    return conf
}, (error: any) => {
    console.log('request-error: ',error);
    Promise.reject(error).then(null)
})

CreateRequest.interceptors.response.use( (response: any) => {
    return response
}, (error: any) => {
    if (!error.response) {
        return Promise.reject('Network Error')
    } else if(error.response?.status==401 && !error.response.request.responseURL.includes("init") && !error.response.request.responseURL.includes("init-additional")){
        // ==============================================================
        // TODO: Login page should not call the init, init-additional API
        // window.location.href = "https://"+window.location.host+"/auth/login"
        // window.location.reload()
        return Promise.reject('Auth Error')
    } else {
        return error.response
    }

});


export default CreateRequest