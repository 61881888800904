import { DatePicker, TimePicker } from "antd";
import { TimeRangeOption } from "core/components/datepicker/model";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { DateRange } from "views/layouts/app/routes/model";
import {
  getMinutesDifference,
  getRoundedInterval,
} from "views/modules/apm/utils";
import { PresetOptions } from ".";

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const { RangePicker: DateRangePicker } = DatePicker;
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const { RangePicker: TimeRangePicker } = TimePicker;

const getInterval = (fromTs: number, toTs: number) =>
  getRoundedInterval(parseInt(getMinutesDifference(fromTs, toTs)));

interface DateTimeSelectionProps {
  onChange: (range: DateRange) => void;
  defaultValue?: DateRange;
  presetOptions: PresetOptions[];
  disablePastDates: boolean;
  disableFutureDates: boolean;
  maxDaysSelection: number;
}

const cellRender = (current: Dayjs) => {
  return (
    <div className="ant-picker-cell-inner custom-inner-div">
      {current.date()}
    </div>
  );
};

const DateTimeSelection = (props: DateTimeSelectionProps) => {
  const {
    onChange,
    defaultValue,
    presetOptions,
    disablePastDates,
    disableFutureDates,
    maxDaysSelection,
  } = props;

  // default date & time range
  const defaultDateRange = defaultValue
    ? defaultValue.range === "2w"
      ? [dayjs().subtract(2, "week"), dayjs()]
      : [
          dayjs.unix(defaultValue.fromTs / 1000),
          dayjs.unix(defaultValue.toTs / 1000),
        ]
    : [dayjs(), dayjs()];
  const defaultTimeRange = defaultValue
    ? [
        dayjs.unix(defaultValue.fromTs / 1000),
        dayjs.unix(defaultValue.toTs / 1000),
      ]
    : [dayjs("00:00", "HH:mm"), dayjs("23:59 PM", "HH:mm")];

  const [selectedOption, setSelectedOption] = useState(
    defaultValue?.range ?? TimeRangeOption.Custom
  );
  const [selectedDateRange, setSelectedDateRange] = useState(defaultDateRange);
  const [selectedTimeRange, setSelectedTimeRange] = useState(defaultTimeRange);

  useEffect(() => {
    setSelectedDateRange(defaultDateRange);
    setSelectedTimeRange(defaultTimeRange);
    setSelectedOption(defaultValue?.range ?? TimeRangeOption.Custom);
  }, [defaultValue]);

  const handleOptionChange = (option: {
    label: string;
    value?: Dayjs[];
    timeRangeOption: TimeRangeOption;
  }) => {
    setSelectedOption(option.timeRangeOption);
    if (option.value) {
      setSelectedDateRange(option.value);
      setSelectedTimeRange(option.value);
      const fromTs = option.value[0].unix() * 1000;
      const toTs = option.value[1].unix() * 1000;
      const interval = getInterval(fromTs, toTs);
      const range = option.timeRangeOption;
      onChange({
        fromTs,
        toTs,
        interval,
        range,
      });
    }
  };

  const handleDateRangeChange = (dateRange: Dayjs[]) => {
    setSelectedOption(TimeRangeOption.Custom);
    setSelectedDateRange(dateRange);
    const fromTs =
      dayjs(
        `${dateRange[0].format("YYYY-MM-DD")} ${selectedTimeRange[0].startOf("day").format("HH:mm")}`
      ).unix() * 1000;
    const toTs =
      dayjs(
        `${dateRange[1].format("YYYY-MM-DD")} ${selectedTimeRange[1].endOf("day").format("HH:mm")}`
      ).unix() * 1000;
    const interval = getInterval(fromTs, toTs);
    const range = TimeRangeOption.Custom;
    onChange({
      fromTs,
      toTs,
      interval,
      range,
    });
  };

  const handleTimeRangeChange = (timeRange: Dayjs[]) => {
    setSelectedOption(TimeRangeOption.Custom);
    setSelectedTimeRange(timeRange);
    const fromTs =
      dayjs(
        `${selectedDateRange[0].format("YYYY-MM-DD")} ${timeRange[0].format("HH:mm")}`
      ).unix() * 1000;
    const toTs =
      dayjs(
        `${selectedDateRange[1].format("YYYY-MM-DD")} ${timeRange[1].format("HH:mm")}`
      ).unix() * 1000;
    const interval = getInterval(fromTs, toTs);
    const range = TimeRangeOption.Custom;
    onChange({
      fromTs,
      toTs,
      interval,
      range,
    });
  };

  const disabledDate = (current: Dayjs, { from }: { from: Dayjs }) => {
    if (from && maxDaysSelection > 0) {
      if (disableFutureDates) {
        return (
          Math.abs(current.diff(from, "days")) >= maxDaysSelection ||
          current > dayjs().endOf("day")
        );
      } else if (disablePastDates) {
        return (
          Math.abs(current.diff(from, "days")) >= maxDaysSelection ||
          current < dayjs().startOf("day")
        );
      } else {
        return Math.abs(current.diff(from, "days")) >= maxDaysSelection;
      }
    }
    return disableFutureDates
      ? current > dayjs().endOf("day")
      : disablePastDates
        ? current < dayjs().startOf("day")
        : false;
  };

  useEffect(() => {
    setSelectedTimeRange(
      defaultValue
        ? [
            dayjs.unix(defaultValue.fromTs / 1000),
            dayjs.unix(defaultValue.toTs / 1000),
          ]
        : [dayjs("00:00", "HH:mm"), dayjs("23:59 PM", "HH:mm")]
    );
  }, [defaultValue]);

  return (
    <div className="select-date-dropdown">
      <div className="date-picker-container">
        <DateRangePicker
          open
          autoFocus={false}
          value={selectedDateRange}
          defaultValue={selectedDateRange}
          getPopupContainer={(trigger: HTMLElement) => trigger}
          onChange={handleDateRangeChange}
          cellRender={cellRender}
          allowClear={false}
          placement={"bottomRight"}
          suffixIcon={null}
          popupClassName="dateRangePicker"
          disabledDate={disabledDate}
          renderExtraFooter={() => (
            <TimeRangePicker
              use12Hours
              value={selectedTimeRange}
              allowClear={false}
              suffixIcon={null}
              format="hh:mm A"
              defaultValue={selectedTimeRange}
              onChange={handleTimeRangeChange}
            />
          )}
        />
      </div>
      <div className="date-time-option-list">
        {presetOptions.map((option, index) => (
          <div
            key={index}
            className={`list-item ${option.timeRangeOption === selectedOption ? "selected-option" : ""}`}
            onClick={() => handleOptionChange(option)}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DateTimeSelection;
