import * as React from "react";

type NoteProps = {
    note: string;
    classes?: string;
}

export default function Note(props: NoteProps) {
    return <div className={"notice " + props.classes} dangerouslySetInnerHTML={{ __html: "<b>Note: </b>" + props.note }}/>
}
export const renderTab = () => {
    return `&nbsp;&nbsp;&nbsp;&nbsp`;
}