import ChartsV2 from "core/components/v2/charts";
import { /* Point, */ SeriesScatterOptions } from "highcharts";
import React from "react";
import { DateRange } from "views/layouts/app/routes/model";
import { colorSchemas } from "../../../../../views/modules/builder/common/utils";
import {
  BuilderNestedProps,
  GridviewDataType,
} from "../../../../../views/modules/builder/entities/builder.entities";
import {
  RenderTpl,
  getValueFromConfig,
} from "../../../../../views/modules/builder/entities/tpl.entities";
import NoDataSpace from "../../no-data";
// import { additionalDataAsColumns } from "./AditionalTooltip";
interface BuilderScatterPlotProps {
  scatterPlotDaata: GridviewDataType | undefined;
  nestedProps?: BuilderNestedProps;
  onDateChange?: (range: DateRange) => void;
}
// max 2 metircs are allowed
// first will be x axis and second will be y axis
const BuilderScatterPlotV2 = (props: BuilderScatterPlotProps) => {
  const { scatterPlotDaata, onDateChange } = props;
  if (!scatterPlotDaata) return null;
  const metrics = scatterPlotDaata.columns.filter((col) => col.isMetric);
  const groups = scatterPlotDaata.columns.filter((col) => !col.isMetric);
  if (!scatterPlotDaata.data.length || !metrics.length) {
    return <NoDataSpace />;
  }
  const generateData = (): {
    chartData: SeriesScatterOptions[];
    xAxisConfig: RenderTpl;
    yAxisConfig: RenderTpl;
  } => {
    const chartSeries: SeriesScatterOptions[] = [];
    let xAxisConfig!: RenderTpl;
    let yAxisConfig!: RenderTpl;
    let xAxisKey: string, yAxisKey: string;
    // const xAxisKey = metrics?.[0]?.accessor;
    // const xAxisConfig = metrics?.[0]?.tpl;
    // const yAxisKey = metrics?.[1]?.accessor;
    // const yAxisConfig = metrics?.[1]?.tpl;
    metrics.map((metricColumn, mIndex) => {
      const cConfig = props.nestedProps?.columnConfig?.[metricColumn.accessor];
      if (mIndex == 0) {
        xAxisKey = metricColumn.accessor;
        xAxisConfig = cConfig?.tpl || metricColumn.tpl;
      } else {
        yAxisKey = metricColumn.accessor;
        yAxisConfig = cConfig?.tpl || metricColumn.tpl;
      }

      scatterPlotDaata.data.map((item, index) => {
        const groupName = groups
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          .map((group) => (item[group.accessor] ? item[group.accessor] : ""))
          .join("-");
        let name = groupName + "-" + metricColumn.accessor;
        if (metrics.length == 1) {
          name = groupName;
          if (cConfig?.title) {
            name = groupName + "-" + cConfig.title;
          }
        }
        if (!name) {
          name = metricColumn.accessor;
        }
        if (/^\d+$/.test(name) && groups.length) {
          const number = parseInt(name);
          const group = groups[0];
          if (group) {
            name = getValueFromConfig(number, group.tpl);
          }
        }
        const obj: SeriesScatterOptions = {
          type: "scatter",
          name: name,
          id: name,
          marker: {
            symbol: "circle",
            radius: 5,
          },
          data: [],
          keys: ["x", "y", "color", "additional"],
        };
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const xAxisValue = item[xAxisKey];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const yAxisValue = item[yAxisKey];
        const found = chartSeries.findIndex((it) => it.id === obj.id);
        if (found > -1 && chartSeries[found]?.data) {
          chartSeries[found]?.data?.push([
            xAxisValue,
            yAxisValue,
            colorSchemas(index),
            {
              [xAxisKey]: getValueFromConfig(Number(xAxisValue), xAxisConfig),
              [yAxisKey]: getValueFromConfig(Number(yAxisValue), yAxisConfig),
            },
          ]);
        } else {
          obj.data?.push([
            xAxisValue,
            yAxisValue,
            colorSchemas(index),
            {
              [xAxisKey]: getValueFromConfig(Number(xAxisValue), xAxisConfig),
              [yAxisKey]: getValueFromConfig(Number(yAxisValue), yAxisConfig),
            },
          ]);
          chartSeries.push(obj);
        }
        return item;
      });
    });

    return {
      chartData: chartSeries,
      xAxisConfig: xAxisConfig,
      yAxisConfig: yAxisConfig,
    };
  };
  const chartData = generateData();
  return (
    <ChartsV2
      chartType={"scatter"}
      series={chartData.chartData}
      extraChartConfig={{
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: [
          {
            enabled: true,
          },
        ],
        // borderWidth: 5
      }}
      yAxsisTitle={props?.nestedProps?.yAxisTitle}
      extraConfig={{
        colorAxis: {
          visible: false,
        },
        legend: {
          enabled: false,
          align: "center",
        },
      }}
      showLegend={props?.nestedProps?.showLegend}
      chartHeight={props.nestedProps?.chartHeight}
      yAxisConfig={chartData.yAxisConfig}
      xAxisFormatter={function (ctx) {
        return getValueFromConfig(Number(ctx.value), chartData.xAxisConfig);
      }}
      tooltipOptions={{
        stickOnContact: true,
        positioner: function (labelWidth, labelHeight, point) {
          return {
            x: point.plotX + 30,
            y: point.plotY + 30,
          };
        },
      }}
      onDateChange={onDateChange}
    />
  );
};

export default BuilderScatterPlotV2;
