import React from 'react';
import chromeIcon from "super-tiny-icons/images/svg/chrome.svg";
import firefoxIcon from "super-tiny-icons/images/svg/firefox.svg";
import edgeIcon from "super-tiny-icons/images/svg/edge.svg";
import operaIcon from "super-tiny-icons/images/svg/opera.svg";
import safariIcon from "super-tiny-icons/images/svg/safari.svg";
import braveIcon from "super-tiny-icons/images/svg/brave.svg";
import chromiumIcon from "super-tiny-icons/images/svg/chromium.svg";
import samsungIcon from "super-tiny-icons/images/svg/samsung_internet.svg";
import linuxIcon from "super-tiny-icons/images/svg/linux.svg";
import androidIcon from "super-tiny-icons/images/svg/android.svg";
import ubuntuIcon from "super-tiny-icons/images/svg/ubuntu.svg";
import windowsIcon from "super-tiny-icons/images/svg/windows.svg";
import macosIcon from "super-tiny-icons/images/svg/macos.svg";

const iconMap: any = {
    "Chrome": chromeIcon,
    "Firefox": firefoxIcon,
    "Edge": edgeIcon,
    "Opera": operaIcon,
    "Safari": safariIcon,
    "Brave": braveIcon,
    "Chromium": chromiumIcon,
    "Linux": linuxIcon,
    "Android": androidIcon,
    "Ubuntu": ubuntuIcon,
    "Windows": windowsIcon,
    "Mac OS X": macosIcon,
    "Mac OS": macosIcon,
};

const defaultIcon = samsungIcon;

const RenderIcon = (browserName: any) => {
    const icon = iconMap[browserName.browserName] || defaultIcon;
    return <img src={icon} width="16px" alt={browserName.browserName + " icon"} />;
};

export default RenderIcon;
