import React from "react";
import Command from "views/modules/installation-v2/components/code-block/command";
import { Languages } from "..";
import { ApplicationDetails } from "../mobile-installation";
import { getSteps } from "../mobile-installation-steps";

const MobileInstallationPage = ({
  language,
  applicationDetails,
}: {
  language: Languages;
  applicationDetails: ApplicationDetails;
}) => {
  const steps = getSteps(language, applicationDetails);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      {steps.map((step: { heading: string; info: string }) => {
        return (
          <>
            <span style={{ lineHeight: "20px" }}>{step.heading}</span>
            <div>
              <Command command={step.info} className="code-block" />
            </div>
          </>
        );
      })}
    </div>
  );
};

export default MobileInstallationPage;
