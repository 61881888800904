import { HexagonsGrid } from "core/components/charts/hexagon/HexagonsGrid";
import React from "react";
import { ResponsiveContainer } from "recharts";
import {
  BuilderNestedProps,
  GridviewDataType,
} from "../../../../../views/modules/builder/entities/builder.entities";
import {
  RenderTpl,
  StatusConfig,
  TPL_TYPE_STATUS,
  getStatusColorFromConfig,
  getValueFromConfig,
} from "../../../../../views/modules/builder/entities/tpl.entities";
import { WidgetAppViewOptions } from "views/modules/builder/core/widget-app-view";
import Dialog from "core/components/dialog";
import LogsIntegration from "views/modules/logs/logs-components/logs.corelation";
import useQuery from "core/components/query";
import NoDataSpace from "../../no-data";
interface BuilderHexagonChart {
  hexagonData: GridviewDataType | undefined;
  nestedProps?: BuilderNestedProps;
  builderViewOptions?: WidgetAppViewOptions;
}
export interface GroupMetric {
  name: string;
  value: number | string;
  tpl: RenderTpl;
}
export interface Group {
  color?: string;
  name: string;
  metrics: GroupMetric[];
  isClickable?: boolean;
}
export const getTitleName = (item: string) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const _item: unknown[] = JSON.parse(item);
    if (Array.isArray(_item)) {
      return _item.join("-");
    }
    return item;
  } catch (e) {
    return item;
  }
};
const BuilderHexagonV2 = (props: BuilderHexagonChart) => {
  const { hexagonData, nestedProps } = props;
  const columnConfig = props?.nestedProps?.columnConfig;
  const splitBy = nestedProps?.hexagonProps?.splitBy;
  const query = useQuery();
  const fromTs = query.get("from_ts") ?? new Date().valueOf() + "";
  const toTs = query.get("to_ts") ?? new Date().valueOf() + "";
  const [panelDetails, setPanelDetails] = React.useState<
    Record<string, string>
  >({});
  if (!hexagonData) return null;
  const metrics = hexagonData.columns.filter((col) => col.isMetric);
  const groups = hexagonData.columns.filter((col) => !col.isMetric);
  if (!hexagonData.data.length) {
    return (
     <NoDataSpace/>
    );
  }

  const getGrids = () => {
    if (!splitBy) return [];
    const grids: string[] = [];
    hexagonData.data.forEach((item: Record<string, string>) => {
      if (item[splitBy] && !grids.includes(item[splitBy])) {
        grids.push(item[splitBy]);
      }
    });
    return grids;
  };
  const gridStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    minWidth: "150px",
  };
  const gridWrapperStyle: React.CSSProperties = {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  };
  const renderGrids = () => {
    const grids = getGrids();
    return (
      <div style={gridWrapperStyle} className="grid-wrapper">
        {grids.map((grid) => {
          const gridData = generateGridData(grid);
          return (
            <div key={grid} style={gridStyle}>
              <b>
                {grid} {`(${gridData.length})`}
              </b>
              {renderGrid(gridData)}
            </div>
          );
        })}
      </div>
    );
  };
  const generateGridData = (grid: string) => {
    const dataToRender: Group[] = [];
    hexagonData.data.forEach((item: Record<string, string>) => {
      if (splitBy && grid && item[splitBy] !== grid) return;
      const groupName = groups.map((group) => item[group.accessor]).join("-");
      const metricsToAdd: GroupMetric[] = [];
      let isClickable = false;
      metrics.forEach((metric) => {
        const metricValue = item[metric.accessor];
        const metricConfig = metric.tpl;
        const metricObj: GroupMetric = {
          name: metric.accessor,
          value: metricValue,
          tpl: metricConfig,
        };
        if (metric.tpl.type === TPL_TYPE_STATUS) {
          isClickable = true;
        }
        metricsToAdd.push(metricObj);
      });
      const groupObj: Group = {
        name: groupName,
        metrics: metricsToAdd,
        isClickable,
        color: item.color,
      };
      dataToRender.push(groupObj);
    });
    return dataToRender;
  };
  const renderGrid = (dataToRender: Group[]) => {
    const _size = 35;
    const _margin = 1;
    if (!dataToRender.length)
      return (
        <NoDataSpace/>
      );
    return (
      <div className={"builder-hexagon_chart_wrapper"}>
        <HexagonsGrid
          items={dataToRender}
          size={_size}
          margin={_margin}
          onClick={(_, data: Group) => {
            if (!data.isClickable) return;
            const details: Record<string, string> = {};
            groups.map((group) => {
              details[group.accessor] = data.name;
            });
            setPanelDetails(details);
          }}
          showLegend={props?.nestedProps?.showLegend}
          getBgColor={(_: unknown, data: Group) => {
            if (!data) return "var(--color-blue-500)";
            if (!data.metrics) return "var(--color-blue-500)";
            if (data.color) return data.color;
            const status = data.metrics.find(
              (metric) => metric.tpl.type === TPL_TYPE_STATUS
            );
            if (status) {
              const statusConfig = status.tpl.config as StatusConfig;
              return getStatusColorFromConfig(status.value + "", statusConfig)
                .color;
            }
            return "var(--color-blue-500)";
          }}
          /**
           * Disabled due to need to change type in charts hexagon
           */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          tooltipTemplate={(_i: unknown, selectedItem: any) => {
            if (!selectedItem) return;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            const metrics: GroupMetric[] = selectedItem.metrics;

            return (
              <div className={"builder-hexagon-chart-tooltip"}>
                {metrics
                  .filter((f) => f.name !== "resource_status")
                  .map((metric, index) => {
                    const config = columnConfig?.[metric.name];
                    const value =
                      typeof metric.value === "number"
                        ? getValueFromConfig(
                            metric.value,
                            config?.tpl ?? metric.tpl
                          )
                        : metric.value;
                    let _title = metric.name;
                    if (config?.title) {
                      _title = config?.title || metric.name;
                    }
                    return (
                      <div className="highcharts_item_row" key={index}>
                        <span className="highcharts_item_row_colored_icon">
                          <span className={"highcharts_item_row_text"}>
                            {_title}
                          </span>
                        </span>
                        <b>{value}</b>
                      </div>
                      // <div className={"info"} >
                      //   <p className={"title"}>{_title}</p>
                      //   <p className={"value"}>{value}</p>
                      // </div>
                    );
                  })}
              </div>
            );
          }}
          {...(props?.nestedProps?.hexagonProps
            ? props?.nestedProps?.hexagonProps
            : {})}
        />
      </div>
    );
  };
  const renderHexagonChart = () => {
    const gridData = generateGridData("");
    return gridData.length ? (
      renderGrid(gridData)
    ) : (
      <NoDataSpace/>
    );
  };

  return (
    <div
      className={"base_chart__wrapper"}
      style={{ height: "100%", width: "100%" }}
    >
      <ResponsiveContainer width="98%">
        <React.Fragment>
          {splitBy ? renderGrids() : renderHexagonChart()}
          <Dialog
            isOpen={Object.keys(panelDetails).length > 0}
            onClose={() => {
              setPanelDetails({});
            }}
            position="right"
          >
            <LogsIntegration
              attr={Object.keys(panelDetails)[0]}
              value={Object.values(panelDetails)[0]}
              themeMode={"light"}
              sts={parseInt(fromTs)}
              ets={parseInt(toTs)}
            />
          </Dialog>
        </React.Fragment>
      </ResponsiveContainer>
    </div>
  );
};
export default BuilderHexagonV2;
