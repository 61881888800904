import {createSelector} from "reselect";
import {get} from "lodash-es";
interface TaskManagementList {
    cassandra_config: boolean
    clickhouse_config: boolean
    elasticsearch_config: boolean
    kafka_config: boolean
    mongodb_config: boolean
    mysql_config: boolean
    oracledb_config: boolean
    apache_config: boolean
    postgres_config: boolean
    prometheus_config: boolean
    redis_config: boolean
    redpanda_config: boolean
    aws_config: boolean
}
export const getAgentIntegrationsList = createSelector((state: TaskManagementList) => get(state, ['task_management', 'list'], {}), data => data);


