/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { converMicroSecondsToSeconds } from "core/application/utils";
import React, { useEffect, useRef, useState } from "react";
import rrwebPlayer from "rrweb-player";
import { PlayerData, SessionPanelData } from "../types";

const jumpToSpecificTimeline = ({
  player,
  currentTimeStamp,
  endTimestamp,
}: {
  player: any;
  currentTimeStamp: number;
  endTimestamp: number;
}) => {
  const time = currentTimeStamp - endTimestamp;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  player?.goto(converMicroSecondsToSeconds(time), false);
};
const Player = ({
  height,
  session,
  events,
  playerCallback,
  timestampToJumpVideo,
}: {
  height?: number;
  session: SessionPanelData | undefined;
  events: PlayerData[];
  playerCallback: (player: any) => void;
  timestampToJumpVideo: number;
}) => {
  const [replayer, setReplayer] = useState<any>();
  const wrapper = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (events.length > 1 && wrapper.current) {
      const player = new rrwebPlayer({
        target: wrapper.current,
        props: {
          autoPlay: false,
          events,
          UNSAFE_replayCanvas: false,
          mouseTail: false,
          inactiveColor: "var(--color-red-200)",
          height: height ?? 400,
          tags: {
            click: "var(--color-blue)",
            error: "var(--color-red)",
            rage_click: "var(--color-primary)",
            dead_click: "var(--color-error)",
          },
        },
      });
      timestampToJumpVideo &&
        jumpToSpecificTimeline({
          player: player,
          currentTimeStamp: timestampToJumpVideo,
          // This is v1 file. Changed to just passed the build.
          endTimestamp: session?.maxTimestamp ?? 0,
        });
      setReplayer(player);
      playerCallback(player);
    }
  }, [events]);

  useEffect(() => {
    function handleResize() {
      if (replayer && typeof replayer?.triggerResize === "function") {
        replayer.triggerResize();
      }
    }

    const resizeObserver = new ResizeObserver(handleResize);
    if (wrapper.current) {
      resizeObserver.observe(wrapper.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [replayer]);

  return <div ref={wrapper} />;
};

export default Player;
