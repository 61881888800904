import CreateRequest from "core/xhr";
import {
    applicationListReceived,
    coreWebVitalGraphReceived,
    coreWebVitalsReceived, errorSpanGraphReceived, errorSpanListReceived,
    loadingTimeGraphReceived,
    percentageErrorGraphReceived,
    rumDashboardErrorListReceived,
    rumDashboardErrorStatsReceived,
    rumDashboardFiltersReceived,
    rumDashboardTopUrlsReceived,
    rumDashboardUserStatsReceived,
    rumErrorGraphReceived,
    rumErrorListReceived, rumRecordingStatusReceived, rumRecordingTimeReceived,
    rumSessionErrorsReceived,
    rumSessionFiltersReceived,
    rumSessionGraphReceived,
    rumSessionListReceived,
    rumSessionRecordingReceived, rumUserStatusReceived,
    sessionStatusReceived,
    userSessionGraphReceived,
} from "./actions";
import { receivedRumSession } from "store/apm/actions";
import { SourceMapRequest, stackTraceData } from "views/modules/rum/v2/stackTrace/stackTrace";

export const requestApplicationList = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(applicationListReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/application-list",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(applicationListReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(applicationListReceived({ infoInflight: false }))
            console.log("Application list: error", err)
        });
    }
}

export const requestUserSessionGraph = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(userSessionGraphReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/application-user-sessions",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(userSessionGraphReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(userSessionGraphReceived({ infoInflight: false }))
            console.log("User session graph: error", err)
        });
    }
}

export const requestLoadingTimeGraph = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(loadingTimeGraphReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/application-loading-time",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(loadingTimeGraphReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(loadingTimeGraphReceived({ infoInflight: false }))
            console.log("User session graph: error", err)
        });
    }
}

export const requestPercentageErrorGraph = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(percentageErrorGraphReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/application-view-error",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(percentageErrorGraphReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(percentageErrorGraphReceived({ infoInflight: false }))
            console.log("Error percentage graph: error", err)
        });
    }
}

export const requestCoreWebVitals = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(coreWebVitalsReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/dashboard/core-web-vitals",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(coreWebVitalsReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(coreWebVitalsReceived({ infoInflight: false }))
            console.log("core web vitals: error", err)
        });
    }
}
export const requestCoreWebVitalGraph = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(coreWebVitalGraphReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/dashboard/core-web-vitals/graph",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(coreWebVitalGraphReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(coreWebVitalGraphReceived({ infoInflight: false }))
            console.log("core web vital graph: error", err)
        });
    }
}
export const requestRumDashboardFilters = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(rumDashboardFiltersReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/dashboard/filters",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(rumDashboardFiltersReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(rumDashboardFiltersReceived({ infoInflight: false }))
            console.log("filters: error", err)
        });
    }
}

export const requestRumDashboardErrorList = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(rumDashboardErrorListReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/dashboard/error-list",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(rumDashboardErrorListReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(rumDashboardErrorListReceived({ infoInflight: false }))
            console.log("error list: error", err)
        });
    }
}
export const requestRumDashboardErrorStats = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(rumDashboardErrorStatsReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/dashboard/error-stats",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(rumDashboardErrorStatsReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(rumDashboardErrorStatsReceived({ infoInflight: false }))
            console.log("error stats: error", err)
        });
    }
}

export const requestRumDashboardTopUrls = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(rumDashboardTopUrlsReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/dashboard/visited-urls",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(rumDashboardTopUrlsReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(rumDashboardTopUrlsReceived({ infoInflight: false }))
            console.log("error list: error", err)
        });
    }
}
export const requestRumDashboardUserStats = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(rumDashboardUserStatsReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/dashboard/user-stats",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(rumDashboardUserStatsReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(rumDashboardUserStatsReceived({ infoInflight: false }))
            console.log("error stats: error", err)
        });
    }
}

export const requestRumSessionGraph = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(rumSessionGraphReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/session/graph",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(rumSessionGraphReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(rumSessionGraphReceived({ infoInflight: false }))
            console.log("session-graph: error", err)
        });
    }
}

export const requestRumSessionList = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(rumSessionListReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/session/list",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(rumSessionListReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(rumSessionListReceived({ infoInflight: false }))
            console.log("session-list: error", err)
        });
    }
}

export const requestRumRecordingStatus = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(rumRecordingStatusReceived({ infoInflight: true }))

        return CreateRequest({
            url: `rum/session/recording-status?${params}`,
            method: 'get',
        }).then( res => {
            dispatch(rumRecordingStatusReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(rumRecordingStatusReceived({ infoInflight: false }))
            console.log("session-list: error", err)
        });
    }
}
export const requestUserStatus = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(rumUserStatusReceived({ infoInflight: true }))

        return CreateRequest({
            url: `rum/session/user-status?${params}`,
            method: 'get',
        }).then( res => {
            dispatch(rumUserStatusReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(rumUserStatusReceived({ infoInflight: false }))
            console.log("session-list: error", err)
        });
    }
}

export const requestRecordingTime = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(rumRecordingTimeReceived({ infoInflight: true }))

        return CreateRequest({
            url: `rum/session/recording-time?${params}`,
            method: 'get',
        }).then( res => {
            dispatch(rumRecordingTimeReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(rumRecordingTimeReceived({ infoInflight: false }))
            console.log("session-list: error", err)
        });
    }
}

export const requestSessionStatus = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(sessionStatusReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/session/status",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(sessionStatusReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(sessionStatusReceived({ infoInflight: false }))
            console.log("session-status: error", err)
        });
    }
}
export const requestSessionFilters = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(rumSessionFiltersReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/session/filters",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(rumSessionFiltersReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(rumSessionFiltersReceived({ infoInflight: false }))
            console.log("session-list: error", err)
        });
    }
}
export const requestSessionTraces = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(rumSessionFiltersReceived({ infoInflight: true }))

        return CreateRequest({
            url: `rum/session/traces/${params.session_id}`,
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(rumSessionFiltersReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(rumSessionFiltersReceived({ infoInflight: false }))
            console.log("session-list: error", err)
        });
    }
}

export const requestRumSession = (data: any, callback?: (status:boolean, res?: any) => void ) => {
    return (dispatch: (a: any) => void) => {
        dispatch(receivedRumSession({infoInflight: true}))

        return CreateRequest({
            url: `/rum/session/${data.session_id}`,
            method: 'get',
            params: {
                start_ts: data.start_date,
                end_ts: data.end_date,
            }
        }).then( res => {
            dispatch(receivedRumSession(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(receivedRumSession({infoInflight: false}))
            console.log("trace:info: error", err)
        });
    }
}

export const requestSessionRecordings = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(rumSessionRecordingReceived({ infoInflight: true }))

        return CreateRequest({
            url: `rum/session/recordings/${params.session_id}`,
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(rumSessionRecordingReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(rumSessionRecordingReceived({ infoInflight: false }))
            console.log("session-recording: error", err)
        });
    }
}

export const requestSourcemaps = (data?: SourceMapRequest ,callback?: (status?:boolean, data?: stackTraceData[]) => void ) => {
    return () => {
        callback && callback(true)
        return CreateRequest({
            url: "rum/sourcemap",
            method: 'POST',
            data
        }).then( res => {
            if (callback) { 
                callback(true, res.data.data)
            }
        }).catch( err => {
            console.log("Failed to get sourcemap: error", err)
            if (callback) {
                callback(false, [])
            }
        });
    }
}
export const requestSessionErrors = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(rumSessionErrorsReceived({ infoInflight: true }))

        return CreateRequest({
            url: `rum/session/errors/${params.session_id}`,
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(rumSessionErrorsReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(rumSessionErrorsReceived({ infoInflight: false }))
            console.log("session-recording: error", err)
        });
    }
}
export const requestRumErrorGraph = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(rumErrorGraphReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/error/graph",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(rumErrorGraphReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(rumErrorGraphReceived({ infoInflight: false }))
            console.log("rum-err-graph: error", err)
        });
    }
}
export const requestRumErrorList = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(rumErrorListReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/error/list",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(rumErrorListReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(rumErrorListReceived({ infoInflight: false }))
            console.log("rum-err-graph: error", err)
        });
    }
}

export const requestErrorSpanGraph = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(errorSpanGraphReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/error/span/graph",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(errorSpanGraphReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(errorSpanGraphReceived({ infoInflight: false }))
            console.log("rum-err-graph: error", err)
        });
    }
}

export const requestErrorSpanList = (params?: any ,callback?: (status?:boolean, res?: any) => void ) => {
    return (dispatch: (arg0: any) => void) => {
        dispatch(errorSpanListReceived({ infoInflight: true }))

        return CreateRequest({
            url: "rum/error/span/list",
            method: 'get',
            params: {...params}
        }).then( res => {
            dispatch(errorSpanListReceived(res.data))
            if (callback) {
                callback(true, res.data)
            }
        }).catch( err => {
            dispatch(errorSpanListReceived({ infoInflight: false }))
            console.log("rum-err-graph: error", err)
        });
    }
}