import {CopyIcon, CopyIconNew} from "core/components/svg/icons";
import * as React from "react";
import { useDispatch } from "react-redux";
import {showToast} from "store/toast-alerts/actions";
// import { useDispatch } from "react-redux";

type CommandProps = {
    command: string;
    classes?: string;
}



export default function Command(props: CommandProps) {
  const dispatch = useDispatch();
  function copyToClipboard(text: string) {
  
    navigator.clipboard.writeText(text)
      .then(() => {
        dispatch(showToast("success", "Copied to Clipboard !"))
      });
  }
  return <div className={"command " + props.classes}>
    {props.command}
    <span onClick={() => {copyToClipboard(props.command)}} style={{float:"right"}}>
      <CopyIconNew size={8} />
    </span>
  </div>
}
export const renderTab = () => {
    return `&nbsp;&nbsp;&nbsp;&nbsp`;
}

