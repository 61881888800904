import { createSelector } from "reselect";
import {
  DashboardListResp,
  DashboardResp,
  WidgetDataSourcesResp,
} from "./entities";
import { get } from "lodash";
import { APIAction } from "store/widgets/reducers";

export const getDashboardListResponse = createSelector(
  (state: unknown) =>
    get(
      state,
      ["dashboardBuilder", "dashboardListResp"],
      {}
    ) as DashboardListResp,
  (dr: DashboardListResp) => dr
);

export const getDashboardResponse = createSelector(
  (state: unknown) =>
    get(state, ["dashboardBuilder", "dashboardResp"], {}) as DashboardResp,
  (dr: DashboardResp) => dr
);

export const getWidgetDataSourcesResponse = createSelector(
  (state: unknown) =>
    get(
      state,
      ["dashboardBuilder", "widgetDataSourcesResp"],
      {}
    ) as WidgetDataSourcesResp,
  (dr: WidgetDataSourcesResp) => dr
);

export const getDataSourceWidgetsResponse = createSelector(
  (state: unknown) =>
    get(state, ["dashboardBuilder", "dataSourceWidgetsResp"], {}) as APIAction,
  (dr: APIAction) => dr
);
