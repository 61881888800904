import * as React from "react";
import { getToastAlerts } from "store/toast-alerts/selectors";
import { useSelector } from "react-redux";
import ToastItem from "core/components/toast-alerts/toast.item";

const ToastAlerts = function (props: any) {
  const alerts = useSelector(getToastAlerts);
  return (
    <React.Fragment>
      {Array.isArray(alerts) && alerts.length > 0 && (
        <div className={`mw-toast-alerts__wrapper`}>
          {alerts.map((toast: any, key: number) => (
            <ToastItem key={key} toastIndex={key} toast={toast} />
          ))}
        </div>
      )}
    </React.Fragment>
  );
};
export default ToastAlerts;
