import * as React from "react";
import { PropsWithChildren, useState, useEffect } from "react";
import Card from "core/components/card";
import { TypographyHeadingPrimary } from "core/components/typography";
import StatusBadge from "core/components/status-badge";
import TabItems from "core/components/nav-tab/items";
import NavTab from "core/components/nav-tab";
import { useDispatch } from "react-redux";
import ReactJson from 'react-json-view'
import Button from "core/components/button";
import Skeleton from 'react-loading-skeleton'
import SyntaxHighlighter from "react-syntax-highlighter";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";
import TableGridview, { GridviewColumnsOptions } from "core/components/table-gridview";
import { LogBadgeTheme, LogType } from "views/modules/helper";
import ActionMenu from "core/components/action-menu";
import PopoverMenuItem from "core/components/popover/menu-item";
import { setFiltersForLogs } from "store/logs/actions";
import Dialog from "core/components/dialog";
import TraceSideInfoPanel from "views/modules/apm/panel/span-panel/trace_side_panel";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import { ResourceKeyEnum } from "views/modules/builder/common/utils";
import InfraMetrics from "views/modules/infrastructure/dialog/infra.metrics";
import { AddUpdateWidgetBuilder, CustomWidget, FilterOpEnum, GLOBAL_ATTRIBUTE_FILTER, Query, builderFiltersInstance, builderLimitInstance, builderSortingInstance, defaultQuery } from "views/modules/builder/entities/builder.entities";
import { requestBuilderFormattedData } from "store/widgets/api";
import BuilderView from "views/modules/builder/views";
import { granularityInterval } from "core/utils/chart_helper";
import AppContext from "core/components/wrapper/context";



const dummyLoaders: any[] = []
const listLoaders: any[] = []

const LABELS = ["container_name", "pod_name", "projectName", "serviceName", "fluent.tag", "log.file.name", "operator_type", "name", "namespace"];

const LogInfoPanel = React.memo(function (props?: { id: number | string, themeMode: string, navigate?: any, ets?: any, applyFilters?: any, isLogScreen?: boolean, log?: any, logData?: any, hidePart?: string }) {



    const dispatch = useDispatch()
    const [jsonBody, setJsonBody] = useState({} as any)
    const [isLoading, setIsLoading] = useState(true)
    const [logs, setLogs] = useState([] as any[])
    const [id, setId] = useState(props?.id)
    const [listLoading, setListLoading] = useState(true)
    const [identity, setIdentity] = useState('')
    const [resource, setResource] = useState<any>({});
    const [infoPanel, setInfoPanel] = useState<any>([]);
    const [processId, setProcessId] = useState<any>(null);
    const [containerName, setContainerName] = useState<any>(null);
    const [traceWindow, setTraceWindow] = useState(false)

    const routeData: any = React.useContext(MwRouteContext);
    const startDateParam: number = routeData.params.dateRange.fromTs;
    const endDateParam: number = routeData.params.dateRange.toTs;
    const appContext: any = React.useContext(AppContext);
    const getAppliedFilters = () => {
        const logFiltersData:{[key:string]:string[]} = {};
        for (const key in routeData.params.appliedFilters) {
            if (Object.prototype.hasOwnProperty.call(routeData.params.appliedFilters, key)) {
                const element = routeData.params.appliedFilters[key];
                if (!logFiltersData[key]) {
                    logFiltersData[key] = element.values
                } else {
                    logFiltersData[key] = [...logFiltersData[key], ...element.values]
                }
            }
        }
        return logFiltersData;
    }
    const logFilters = getAppliedFilters();

    const [trace, setTrace] = useState({
        trace_id: '',
        span_id: '',
        start_date: startDateParam,
        end_date: endDateParam,
        service_name: ''
    })
    console.log();
    
    const mappings: any = {
        'pod_name': 'Kubernetes Pod',
        'container_name': 'Container',
        'host.name': 'Host',
        'project.name': 'APM Project',
        'os.type': 'Operating System'
    }


    const columns: Array<GridviewColumnsOptions> = [

        {
            title: "MESSAGE",
            accessor: 'body',
            value: (data: any) => {

                if (listLoading) {
                    return <Skeleton height={60} width={1120} count={1} inline wrapper={InlineWrapperWithMargin} />
                }

                return <SyntaxHighlighter language={"nginx"} wrapLines={true} wrapLongLines={true} style={github}>
                    {data.body}
                </SyntaxHighlighter>
            }
        },
        {
            title: "SEVERITY",
            accessor: 'severity',
            value: (data: any) => {
                if (listLoading) {
                    return <Skeleton height={25} width={30} count={1} inline wrapper={InlineWrapperWithMargin} />
                }
                const _type: LogType = data.severity ? data.severity.toLowerCase() : 'unknown';
                const _label = LogBadgeTheme[_type]?.label || 'INFO';
                const _theme = LogBadgeTheme[_type]?.type || 'info';
                return <StatusBadge
                    labelText={_label}
                    simple={props?.themeMode === "light"}
                    info={(_theme === "info")}
                    primary={(_theme === "primary")}
                    warning={(_theme === "warning")}
                    error={(_theme === "error")}
                    success={(_theme === "success")}
                />;
            }
        },

        {
            title: "ACTION",
            accessor: '',
            value: (data: any) => {
                if (listLoading) {
                    return <Skeleton height={40} width={20} count={1} inline wrapper={InlineWrapperWithMargin} />
                }
                return <ActionMenu position="bottom right">
                    <PopoverMenuItem onClick={() => {
                        let q = `?`
                        if (props?.isLogScreen) {
                            q += `id=${data.id}`
                        } else {
                            q += `lid=${data.id}`
                        }

                        props?.navigate(q)
                        setListLoading(true)
                        requestData(data.id)
                        const opts: any = {
                            key: identity,
                            value: currentInfo.attributes[identity] || '',
                            sts: data?.timestamp - 3600000 || new Date().getTime() - 3600000,
                            ets: data?.timestamp || new Date().getTime()
                        }
                        requestTlLogsTQB(opts.sts, opts.ets, opts.key, opts.value);

                    }} >Log Info</PopoverMenuItem>
                    {props?.isLogScreen && <PopoverMenuItem onClick={() => {
                        applyFilters(data.attributes || {}, data.severity)
                    }} >Show in context</PopoverMenuItem>}
                </ActionMenu>
            }
        }
    ]

    const applyFilters = (attributes: any, severity: string) => {
        const obj: any = {}
        if (attributes && typeof attributes == 'object') {
            for (const i in attributes) {
                obj[i] = [attributes[i]]
            }
            obj['severityText'] = [severity]

        }
        const newAppliedFilters = { ...logFilters, ...obj }
        const filters: { [key: string]: { operator: FilterOpEnum, values: string[] } } = {};
        for (const key in newAppliedFilters) {
            if (Object.prototype.hasOwnProperty.call(newAppliedFilters, key)) {
                const element = newAppliedFilters[key];
                filters[key] = {
                    operator: FilterOpEnum.In,
                    values: element
                }
            }
        }
        props?.applyFilters()
        routeData.actions.handleApplyFilters(filters)
    }
    const [currentInfo, setCurrentInfo] = useState({
        account: "",
        account_id: 0,
        count: 0,
        date_time: 0,
        description: "",
        id: 0,
        label: "",
        severity: "",
        tags: {} as any,
        timeline: [],
        triggered: "",
        attributes: {} as any,
        identity: '',
        code: {
            line_from: 0,
            issue_char_at: 0,
            issue_line_at: 0,
            file: "",
            language: "",
            snippet: ""
        }
    })
    const [details, setDetails] = useState({
        id: 0,
        count: 0,
        body: "",
        label: {},
        service_name: "",
        severity: "",
        time: "",
        more_details: {},
        message: '',
        account_id: 0
    } as any)

    const [isFocussed, setIsFocussed] = useState(false)

    //TODO: Param, Rishab : Check this and verify the requirement
    // if (!props?.id) {return <div>Info type is not available.</div>}
    
    useEffect(() => {

        //TODO: Rishab, verify this, as I found this is no longer required
        // dummyLoaders = []
        // for (let i = 0; i < 8; i++) {
        //     dummyLoaders.push(i + 1)
        // }

        // listLoaders = []
        // for (let i = 0; i <= 10; i++) {
        //     listLoaders.push({
        //         body: i + 1,
        //         severity: "INFO"
        //     })
        // }
        
        // if (props && Object.keys(props.logData).length > 0) {
        //     console.log("Non empty log data", props.logData);
        // } else {
        //     console.log("Empty log data");
        //     if (props?.id) {
        //         requestData(props?.id)
        //     }
        // }

        // if (props.logData) {
        //     console.log('props.logData', props.logData);
        //     console.log("inside this");
            
            
        //     const { logData } = props;

        //     const tags = logData.attributes || {}

        //     for (const val of LABELS) {
        //         if (logData && logData.attributes && logData.attributes[val]) {
        //             setIdentity(val)
        //             break;
        //         }
        //     }

        //     let allAttributes: any = {

        //     };
        //     if (logData && logData.attributes) {
        //         allAttributes = { ...logData.attributes }
        //     }
        //     allAttributes.resourceAttributes = {}

        //     if (logData && logData.resource_attributes) {
        //         allAttributes.resourceAttributes = { ...logData.resource_attributes }
        //     }



        //     const fm: any = formatDescription(logData.label) || {}
        //     const jBody = fm.jsonBody || {}
        //     const { plainText, jsonBody } = fm;

        //     const detailsObj: any = {
        //         id: logData.id,
        //         count: logData.count || 1,
        //         body: logData.body,
        //         label: allAttributes,
        //         service_name: logData.label == '' ? "-NA-" : logData.label,
        //         severity: logData.severity,
        //         time: logData.date_time ? (new Date(logData.date_time)).toString() : '',
        //         more_details: jsonBody || {},
        //         message: plainText,
        //         account_id: appContext.user.account_id,
        //         identity
        //     }
        //     setJsonBody(jBody)
        //     setDetails(detailsObj)
        // }
     
        requestData(props?.id || props?.logData?.id)
    }, [])

    const requestLogInfoTQB = (id: number) => {
        const userQuery: Query = JSON.parse(JSON.stringify(defaultQuery));
        const resource = {
            columns: [
                "bodyFingerprint",
                "timestampMs",
                "severityText",
                "serviceName",
                "logAttributes",
                "count",
                "body",
                "resource_attributes",
                "spanId",
                "traceID",
                "total_logs"
            ],
            resourceType: "log",
            name: `log-info-service-${id}`,
            widgetAppId: 5,
        }

        userQuery.columns = resource.columns;
        userQuery.source.name = resource.resourceType;
        let ets = props?.ets
        if (!ets) ets = new Date().getTime()
        const fromTs = startDateParam;
        const toTs = endDateParam;

        const _params = [
            { key: "from_ts", value: fromTs },
            { key: "to_ts", value: toTs }
        ]
        userQuery.with = [];

        const filterById = builderFiltersInstance.Equal("bodyFingerprint", id.toString());
        if (filterById) {
            const fValues = builderFiltersInstance.ConvertToValueType(filterById);
            if (fValues) {
                userQuery.with.push({
                    key: GLOBAL_ATTRIBUTE_FILTER,
                    value: fValues,
                    is_arg: true,
                });
            }
        }

        const widgetRequest: CustomWidget = {
            label: "Logs Info Service",
            builderId: 0,
            scopeId: 0,
            widgetAppId: 5,
            builderConfig: userQuery,
            builderViewOptions: {
                resource,
            },
            params: _params,
        }
        const data: AddUpdateWidgetBuilder = {
            action: "add",
            body: widgetRequest,
            inflight: true,
        }

        dispatch(requestBuilderFormattedData(data, (status?: boolean, res?: any) => {

            if (res && res?.chart_data) {
                const logData = res.chart_data.data[0];
                
                logData.severity = logData.severityText
                delete logData.severityText
                logData.label = logData.serviceName
                delete logData.serviceName
                logData.description = logData.body
                delete logData.body
                logData.date_time = logData.timestampMs
                delete logData.timestampMs
                logData.id = logData.bodyFingerprint
                delete logData.bodyFingerprint
                logData.attributes = logData.logAttributes
                delete logData.logAttributes
                logData.span_id = logData.spanId
                delete logData.spanId
                logData.trace_id = logData.traceID
                delete logData.traceID
                logData.timeline = []
                logData.tags = ["azure", "docker", "host", "kubernetes", "proc", "postgresql", "redis", "socket", "logs", "system", "env.prod", "live", "shoplist", "information", "cpu", "delta", "cache", "docker", "host", "kubernetes", "proc", "postgresql", "redis", "socket", "logs", "system", "env.prod", "live", "information", "cpu", "delta", "cache"]
                logData.triggered = ''
                logData.account = "Account001"
                logData.account_id = appContext.user.account_id
                logData.count = logData.total_logs
                delete logData.total_logs
                const fm: any = formatDescription(logData.description) || {}
                const jBody = fm.jsonBody || {}
                setJsonBody(jBody)
                const { plainText } = fm;
                logData.message = plainText == '' ? logData.description : plainText
                logData.identity = identity

                if (status && logData && logData.id) {

                    if (logData.attributes) {
                        for (const val of LABELS) {
                            if (logData.attributes[val]) {
                                setIdentity(val)
                                break;
                            }
                        }
                    }

                    const { trace_id, span_id } = logData;

                    if (trace_id && typeof trace_id == 'string' && span_id && typeof span_id == 'string') {
                        if (trace_id.trim() != '' && span_id.trim() != '') {
                            setTrace({
                                ...trace,
                                trace_id,
                                span_id,
                                service_name: logData.label == '' ? "-NA-" : logData.label
                            })
                        }
                    }

                    const obj = { ...logData.attributes, ...logData.resource_attributes }

                    let allAttributes: any = {}

                    if (logData && logData.attributes) {
                        allAttributes = { ...logData.attributes }
                    }
                    allAttributes.resourceAttributes = {}

                    if (logData && logData.resource_attributes) {
                        allAttributes.resourceAttributes = { ...logData.resource_attributes }
                    }
                    const keys = ['host.name', 'os.type', 'pod_name', 'container_name', 'project.name']
                    const panel: any = [];

                    Object.keys(obj).forEach((key: string) => {
                        if (keys.indexOf(key) > -1) {
                            panel.push({
                                key,
                                value: obj[key]
                            })
                        }
                    })
    
                    setInfoPanel(panel);

                    extractPidFromLog(logData.description)
                    setResource(logData['resource_attributes'] || {})
                    if (logData['logAttributes'] && logData['logAttributes']['container_name']) {
                        setContainerName(logData['logAttributes']['container_name'])
                    }
                    setCurrentInfo(logData)
                    setId(id)
                    const fm: any = formatDescription(logData.description) || {}
                    const jBody = fm.jsonBody || {}
                    setJsonBody(jBody)
                    const { plainText, jsonBody } = fm;

                    const detailsObj: any = {
                        id: logData.id,
                        count: logData.count,
                        body: logData.description,
                        label: allAttributes,
                        service_name: logData.label == '' ? "-NA-" : logData.label,
                        severity: logData.severity,
                        time: logData.date_time ? (new Date(logData.date_time)).toString() : '',
                        more_details: jsonBody || {},
                        message: plainText == '' ? logData.description : plainText,
                        account_id: logData.account_id,
                        identity
                    }

                    if(props && props.logData && props.logData.date_time) {
                        detailsObj.time = (new Date(props.logData.date_time)).toString()
                    }

                    setDetails(detailsObj)
                    setIsLoading(false)
                }
            }
        }))

    }

    const requestData = (id: number | string) => {
        if (typeof id == 'string') id = parseInt(id as string)
        
        requestLogInfoTQB(id)
    }

    const formatDate = (ts: any, type: string): string => {
        const format: any = {
            years: {
                "0": "Jan",
                "1": "Feb",
                "2": "March",
                "3": "April",
                "4": "May",
                "5": "June",
                "6": "July",
                "7": "Aug",
                "8": "Sept",
                "9": "Oct",
                "10": "Nov",
                "11": "Dec"
            },
            weeks: {
                "0": "Mon",
                "1": "Tue",
                "2": "Wed",
                "3": "Thurs",
                "4": "Fri",
                "5": "Sat",
                "6": "Sun"
            }
        }
        const date = new Date(ts);
        switch (type) {
            case 'day_format':
                return (format["weeks"][date.getDay()] + " ," + date.getDate() + " " + format["years"][date.getMonth()] + " " + date.getFullYear());
            case 'time_format': return formatAMPM(date);

            default: return '';

        }

    }

    const formatAMPM = (date: Date): string => {
        let hours: number = date.getHours();
        let minutes: any = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        const strTime: string = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    const resetView = () => {
        setIsFocussed(false)
    }

    const formatDescription = (body: string) => {
        if (!body || typeof body != 'string') {
            return {
                plainText: '',
                jsonBody: null
            }
        }
        const firstIndex = body.indexOf('{');
        const lastIndex = body.lastIndexOf('}');
        if (firstIndex < 0 || lastIndex < 0) return { plainText: body, jsonBody: null }

        const jsonString = body.slice(firstIndex, lastIndex + 1);
        const plainText = body.slice(0, firstIndex)
        let jsonFormat = {}
        try {
            jsonFormat = JSON.parse(jsonString)
        } catch (error) {
            return { plainText: body, jsonBody: null }
        }

        return {
            plainText,
            jsonBody: jsonFormat
        }
    }

    const InlineWrapperWithMargin = ({ children }: PropsWithChildren<unknown>) => {
        return <span style={{ marginRight: '1.5rem' }}>{children}</span>
    }

    const covertTimePassed = (time: any): string => {
        const secondsPassed = ((new Date()).getTime() - time) / 1000
        if (secondsPassed >= 86400) {
            return `${Math.floor(secondsPassed / 86400)} Days and ${Math.floor(secondsPassed % 86400 / 3600)} Hours`
        } else if (secondsPassed >= 3600) {
            return `${Math.floor(secondsPassed / 3600)} Hours and ${Math.floor(secondsPassed % 3600 / 60)} Minutes`
        } else if (secondsPassed >= 60) {
            return `${Math.floor(secondsPassed / 60)} Minutes and ${Math.floor(secondsPassed % 60)} Seconds`
        }

        return `${secondsPassed} Seconds`
    }

    const requestTlLogsTQB = (sts: number, ets: number, key: string, value: string) => {
        const userQuery: Query = JSON.parse(JSON.stringify(defaultQuery));
        const resource = {
            columns: [
                "bodyFingerprint",
                "timestampMs",
                "severityText",
                "serviceName",
                "body",
                "logAttributes",
                "resource_attributes",
                "is_apm",
                "is_infra",
            ],
            resourceType: "log",
            name: `details-log-service`,
            widgetAppId: 5,
        }

        userQuery.columns = resource.columns;
        userQuery.source.name = resource.resourceType;
        const fromTs = sts;
        const toTs = ets;
        const _params = [
            { key: "from_ts", value: fromTs },
            { key: "to_ts", value: toTs }
        ]
        userQuery.with = [];

        //TODO: build == "combine" condition and check with other filters
        // for (const key in logFilters) {
        //     if (Object.prototype.hasOwnProperty.call(logFilters, key)) {
        //         const values = logFilters[key];
        //         const filters = builderFiltersInstance.In(key, values);
        //         if (filters) {
        //             const fValues = builderFiltersInstance.ConvertToValueType(filters);
        //             if (fValues) {
        //                 userQuery.with.push({
        //                     key: GLOBAL_ATTRIBUTE_FILTER,
        //                     value: fValues,
        //                     is_arg: true,
        //                 });
        //             }
        //         }
        //     }
        // }

        const identityFilter = builderFiltersInstance.Equal(key, value);
        if (identityFilter) {
            const fValues = builderFiltersInstance.ConvertToValueType(identityFilter);
            if (fValues) {
                userQuery.with.push({
                    key: GLOBAL_ATTRIBUTE_FILTER,
                    value: fValues,
                    is_arg: true,
                });
            }
        }

        const sorting = builderSortingInstance.Desc("timestampMs");
        if (sorting) {
            userQuery.with.push(builderSortingInstance.getValues([sorting]));
        }

        const offset = 0;
        const limit = 100;
        const Limit = builderLimitInstance.Limit(offset, limit);
        if (Limit) {
            userQuery.with.push(builderLimitInstance.getValues(Limit));
        }

        const widgetRequest: CustomWidget = {
            label: "GetTimeLineLogs Service",
            builderId: 0,
            scopeId: 0,
            widgetAppId: 5,
            builderConfig: userQuery,
            builderViewOptions: {
                resource,
            },
            params: _params,
        }
        const data: AddUpdateWidgetBuilder = {
            action: "add",
            body: widgetRequest,
            inflight: true,
        }

        dispatch(requestBuilderFormattedData(data, (status?: boolean, res?: any) => {

            if (res && res?.chart_data) {

                const data = res.chart_data.data;
                for (let i = 0; i < data.length; i++) {
                    data[i].severity = data[i].severityText
                    delete data[i].severityText
                    data[i].timestamp = data[i].timestampMs
                    delete data[i].timestampMs
                    data[i].id = data[i].bodyFingerprint
                    delete data[i].bodyFingerprint
                    data[i].log_attributes = data[i].logAttributes
                    delete data[i].logAttributes
                    data[i].service = data[i].serviceName
                    delete data[i].serviceName
                }
                const result = {
                    list: data,
                    message: '',
                    status: true
                }
                if (status) {
                    if (result && result.list) {
                        setLogs(result.list)
                        setListLoading(false)
                    }
                }
            } else {
                return
            }
        }))
    }


    const extractPidFromLog = (log: string): any => {
        const keyValuePairs: any = {};

        // Regular expression pattern to match key-value pairs
        const keyValuePattern = /(\b[a-zA-Z0-9._/]+\b)=([^ :=]+)/g;

        // Extract key-value pairs from the log
        const matches: any = log.matchAll(keyValuePattern);
        for (const match of matches) {
            const key = match[1];
            const value = match[2];
            keyValuePairs[key] = value;
        }

        if (keyValuePairs['pid']) {
            setProcessId(keyValuePairs['pid']);
        }
    }

    const logAttributesPanel = () => {
        return <div className={"log-info__tab_content"}>
            <div className={"box_layouts__group"}>
                {infoPanel.map((item: any, index: number) => {
                    const style: any = {}
                    if ((item.key == 'pod_name' || item.key == 'host.name') && props?.navigate) {
                        style['cursor'] = 'pointer'
                    }
                    return (
                        <div className={"box_layout"} key={index} style={style} onClick={() => {
                            let ets = props?.ets
                            if (!ets) ets = new Date().getTime()

                            if (item.key == 'pod_name' && props?.navigate) {
                                routeData.actions.handleApplyFilters({
                                    "k8s.pod.name": [item.value],
                                })

                                props?.navigate(`/infrastructure/k8s.pod/list`)
                            }

                            if (item.key == 'host.name' && props?.navigate) {
                                routeData.actions.handleApplyFilters({
                                    "host.name": [item.value],
                                })

                                props.navigate(`/infrastructure/host/list`)
                            }
                        }}>
                            <TypographyHeadingPrimary>{mappings[item.key]}</TypographyHeadingPrimary>
                            <h1 className={"max_size log_sm_size"}>{item.value}</h1>
                        </div>
                    )
                })}
            </div>
        </div>
    }


    const hostFilter = builderFiltersInstance.Equal('host.id', resource['host.id']);
    const processFilter = builderFiltersInstance.Equal('process.pid', processId);
    const containerFilter = builderFiltersInstance.Equal('container_name', containerName);

    const appendTimes = (date_time:string | number) => {
        return formatDate(new Date(currentInfo.date_time).getTime(), 'day_format') + ', ' + formatDate(new Date(currentInfo.date_time).getTime(), 'time_format')
    }


    return <React.Suspense fallback={"dialog.loading..."}>
        <div className={"dialog-info-panel log-panel"}>
            {(isLoading && !props?.logData) ? <Skeleton height={30} width={"100%"} count={1} inline wrapper={InlineWrapperWithMargin} />
                :
                <Card className={"main-header"} bordered={false} borderRadius={false} borderBottom flexBox style={{ padding: 16 }}>
                    <TypographyHeadingPrimary style={{
                        marginRight: 8,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "45%"
                    }}>
                        {details.body}

                    </TypographyHeadingPrimary>
                    <StatusBadge
                        key={"badge-status"}
                        labelText={(details.severity || '').toUpperCase()}
                        warning={details.severity === 'WARN'}
                        error={details.severity === 'ERROR'}
                        success={details.severity === 'SUCCESS'}
                        info={details.severity === 'INFO'}
                        simple={props?.themeMode === "light"}
                    />
                    <StatusBadge key={"badge-count"} labelText={'Count: ' + currentInfo.count} default />
                    <StatusBadge key={"badge-triggered"} labelText={appendTimes(props?.logData?.date_time ? props.logData.date_time : currentInfo.date_time  )} default />
                    <StatusBadge key={"badge-started"} labelText={'Last Seen: ' + covertTimePassed(new Date(currentInfo.date_time).getTime())} default />
                </Card>
            }
            <div className={"detail"}>
                <Card bordered={false} borderRadius={false} noPadding style={{ width: '100%' }}>
                    {(isLoading && !props?.logData) ? <Skeleton height={30} width={900} count={1} inline wrapper={InlineWrapperWithMargin} /> :

                        <Card style={{ border: '0px solid #ccc' }}>{logAttributesPanel()}</Card>

                    }


                    {/* {(trace.trace_id != '' && trace.span_id != '') && <Card bordered={false} borderRadius={false} flexBox style={{ padding: '6px', marginLeft: '10px', marginBottom: '10px' }}>
                        <span className={'trace_span'} onClick={() => setTraceWindow(true)}>See Related Trace</span>
                    </Card>} */}


                    {props?.isLogScreen && <Card style={{ border: '0px solid #ccc', position: 'absolute', top: '0', right: '50px' }}>
                        <Button compact primary onClick={() => {
                            applyFilters(currentInfo.attributes, currentInfo.severity)
                        }}>Show in context</Button>
                    </Card>}
                    <Card bordered={false} borderRadius={false} flexBox noPadding>
                        {isFocussed && <Button secondary onClick={() => resetView()}>Reset View</Button>}
                    </Card>


                    <Card className={"tab-info"} bordered={false} borderRadius={false} noPadding borderRight={false} flexBox>
                        <NavTab classname={"tab-nav"} defaultActiveTabIndex={0} borderTop={false}>
                            <TabItems title="Log Properties">
                                <Card className={"app-only"} bordered={false} borderRadius={false} borderRight={false} flexBox>

                                    <div className={"header"}>
                                        
                                        <SyntaxHighlighter language={"nginx"} wrapLines={true} wrapLongLines={true} style={github}>
                                            {details.body}
                                        </SyntaxHighlighter>
                                        <div className={"more"}>
                                            {covertTimePassed(currentInfo.date_time)}
                                        </div>

                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className={'data-head-first'}>Field</th>
                                                    <th className={'data-head-second'}>Value</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {(isLoading && !props?.logData) ? dummyLoaders.map((k, index) => {
                                                    return (<tr key={`format-load-${index + 1}`}>
                                                        <td>
                                                            <Skeleton height={30} width={"100%"} count={1} inline wrapper={InlineWrapperWithMargin} />
                                                        </td>

                                                        <td>
                                                            <Skeleton height={30} width={"100%"} count={1} inline wrapper={InlineWrapperWithMargin} />
                                                        </td>

                                                    </tr>
                                                    )
                                                }) :

                                                    Object.keys(details).map((k, index) => {
                                                        return (<tr key={`format-load-${index + 1}`}>
                                                            <td>
                                                                <span>{k}</span>
                                                            </td>
                                                            {['label', 'more_details'].indexOf(k) < 0 ? <td>
                                                                <span className={'table_value_font'}>{details[k]}</span>
                                                            </td> :
                                                                <td>
                                                                    <ReactJson
                                                                        src={details[k]}
                                                                        // src={{log.file.name}}
                                                                        name={null}
                                                                        displayDataTypes={false}
                                                                        iconStyle={"square"}
                                                                        theme={props?.themeMode === "light" ? "rjv-default" : "colors"}
                                                                        enableClipboard={false} />
                                                                </td>
                                                            }
                                                        </tr>
                                                        )
                                                    })

                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                </Card>
                            </TabItems>

                            <TabItems title="Raw"  >
                                <Card className={"raw"} bordered={false} borderRadius={false} borderRight={false} flexBox>
                                    <div style={{ paddingLeft: 16 }}>
                                        {/* <TypographyParagraphPrimary800>Pending..</TypographyParagraphPrimary800> */}
                                        <ReactJson src={details}
                                            name={null}
                                            displayDataTypes={false}
                                            iconStyle={"square"}
                                            theme={props?.themeMode === "light" ? "rjv-default" : "colors"}
                                            enableClipboard={true} />
                                    </div>
                                </Card>
                            </TabItems>


                            {props?.hidePart != 'metrics' && <TabItems title="Metrics" className={"log__metrics"}>
                                <NavTab classname={"tab-nav"} defaultActiveTabIndex={0} borderTop={false}>
                                    <TabItems title="Host"  >
                                        <InfraMetrics resourceType={ResourceKeyEnum.HOST} filters={hostFilter ? [hostFilter] : []} />
                                    </TabItems>
                                    <TabItems title="Process"  >
                                        <InfraMetrics resourceType={ResourceKeyEnum.PROCESS} filters={processFilter ? [processFilter] : []} />
                                    </TabItems>
                                    <TabItems title="Container">
                                        <InfraMetrics resourceType={ResourceKeyEnum.CONTAINER} filters={containerFilter ? [containerFilter] : []} />
                                    </TabItems>
                                </NavTab>

                            </TabItems>}

                            <TabItems title="Source Logs" onTabChange={(e) => {
                                const opts: any = {
                                    key: identity,
                                    value: currentInfo.attributes[identity] || '',
                                    sts: currentInfo?.date_time - 24 * 3600000 || (new Date()).getTime() - 24 * 3600000,
                                    ets: currentInfo?.date_time || (new Date()).getTime()
                                }
                                opts.sts = opts.ets - 3600000;
                                requestTlLogsTQB(opts.sts, opts.ets, opts.key, opts.value);
                            }}>
                                <Card className={"raw"} bordered={false} borderRadius={false} borderRight={false} flexBox>
                                    <Card className={"bar-informs"} bordered={false} borderRadius={false}  >
                                            <div style={{ height: '130px', width: '100%' }}>
                                                <BuilderView
                                                    filters={[]}
                                                    builderViewOptions={{
                                                        displayScope: "",
                                                        resource: {
                                                            columns: ["any(total_logs)"],
                                                            resourceType: "log",
                                                            name: `source-logs-timeline-chart-service`,
                                                            widgetAppId: 1,
                                                            granularity: {
                                                                value: granularityInterval(
                                                                    routeData.params.dateRange.fromTs || 0,
                                                                    routeData.params.dateRange.toTs || 0
                                                                ),
                                                            },
                                                        },
                                                    }}
                                                    dateRange={{
                                                        fromTs: routeData.params.dateRange.fromTs || 0,
                                                        toTs: routeData.params.dateRange.toTs || 0,
                                                    }}
                                                    nestedProps={{
                                                        hideFilterAction: true,
                                                        allowAllLegend: true,
                                                        columnConfig: {
                                                            ["any(total_logs)"]: {
                                                                title: "Info",
                                                                color: "var(--color-blue-500)",
                                                            },
                                                        },
                                                        stackId: "1",
                                                    }}
                                                />
                                            </div>
                                    </Card>

                                    <Card className={'related-logs'}>
                                        <div>
                                            <TableGridview
                                                columns={columns}
                                                dataProvider={listLoading ? listLoaders : logs}
                                            />
                                        </div>
                                    </Card>
                                </Card>
                            </TabItems>

                            <TabItems title="Traces">

                                {trace.trace_id === "" || trace.span_id === "" ? (
                                    <p style={{ textAlign: 'center', fontSize: '14px' }}>No Data Found</p>
                                ) : (
                                    <TraceSideInfoPanel trace={trace} themeMode={props?.themeMode || ''} />
                                )}

                            </TabItems>

                        </NavTab>
                    </Card>
                </Card>
            </div>
        </div>


        <Dialog
            isOpen={traceWindow}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            position={"right"}
            onClose={() => {
                setTraceWindow(false)
            }} >
            <TraceSideInfoPanel trace={trace} themeMode={props?.themeMode || ''} />
        </Dialog>
    </React.Suspense>
})
LogInfoPanel.displayName = "LogInfoPanel"
export default LogInfoPanel
