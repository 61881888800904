import classNames from "classnames";
import { CopyIcon } from "core/components/v2/svg/icons";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getThemesMode } from "store/selectors";
import { showToast } from "store/toast-alerts/actions";
import "./_styles.scss";

interface CommandProps {
  command: string;
  className?: string;
  onCopyClick?: () => void;
}

export default function Command(props: CommandProps) {
  const { onCopyClick } = props;
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemesMode);
  function copyToClipboard(text: string) {
    void navigator.clipboard.writeText(text).then(() => {
      dispatch(showToast("success", "Copied to Clipboard !"));
      if (onCopyClick) onCopyClick();
    });
  }

  const commandclassName = classNames("command", props.className, themeMode);
  return (
    <div className="mw-command">
      <div className={commandclassName}>
        <span className="command-text">{props.command}</span>
        <span
          onClick={() => {
            copyToClipboard(props.command);
          }}
          className="command-copy"
        >
          <CopyIcon size={8} color="var(--color-white)" />
        </span>
      </div>
    </div>
  );
}
