import * as React from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";

import NotFound from "views/placeholder/NotFound";
import {useNavigate} from "react-router";
import InitLoader from "views/placeholder/init-loader";
import {RoutesPatternsProps} from "core/utils/route_schema";


const BackoffRoutesHelper = function (props: RoutesPatternsProps) {
    const {routes} = props;
    const navigate = useNavigate()


    return <React.Suspense fallback={<InitLoader/>}>
        <Routes>
            {routes.map( module => {
                return module.items.map(v => {
                    return v.pattern.map((path, key) => {
                        const ElementComponent = v.component
                        const url = (v.slug ? v.slug : module.slug) + path
                        return <Route key={key} path={url} element={<React.Suspense fallback={
                            <InitLoader/>
                        }>
                            <ElementComponent path={url} navigate={navigate}/>
                        </React.Suspense>}/>
                    })
                })
            })}
            <Route path={"/404"} element={<NotFound/>}/>
            <Route path="*" element={<Navigate to="/404"/>}/>
        </Routes>

    </React.Suspense>
}


export default BackoffRoutesHelper