import React, { ChangeEvent } from "react";

import classNames from "classnames";

interface FormToggleProps {
  id: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  showSecondaryToggleOffState?: boolean;
}

const FormToggle = (props: FormToggleProps) => {
  const {
    id,
    checked,
    onChange,
    disabled,
    label,
    className,
    children,
    showSecondaryToggleOffState,
  } = props;
  const toggleClassname = classNames("form-toggle", className);
  const switchClassname = classNames("toggle-switch", className, {
    "is-checked": checked,
    "is-disabled": disabled,
    "is-show-secondary-toggle-offState": showSecondaryToggleOffState,
  });

  return (
    <span className="form-toggle-wrapper">
      <input
        type="checkbox"
        checked={checked}
        id={id}
        onChange={onChange}
        className={toggleClassname}
        aria-label={label}
        disabled={disabled}
        aria-checked={checked}
      />
      <label className={switchClassname} htmlFor={id}></label>
      {children && <span className="toggle-children">{children}</span>}
    </span>
  );
};

export default FormToggle;
