import { getCookie } from "core/application/utils";
import Store from "store";
import { updateThemeMode } from "store/actions";
import { updateThemeModeBkoff } from "store/backoff/actions";
import historyNavigator from "./history";

// import base64 from 'base-64';
// import utf8 from 'utf8';

export const USER_AUTH_TOKEN = "__bifrost__anonymous__user";
export const SITE_THEME_MODE = "__site__theme__mode";
export const USER_COOKIE_NAME = "__mw___user";
export const MW_BACKOFF_COOKIE_NAME = "__mw___boss";

export const getBaseURL = (): string => {
  return "https://" + window.location.host + "/api/v1";
};

export const getAuthStore = () => {
  const token = getCookie(USER_COOKIE_NAME);
  return token ? token : null;
};

// export const setAuthStore = (token: string) => {
//     localStorage.setItem(USER_AUTH_TOKEN, token)
//     return getAuthStore()
// }

export const removeAuthStore = () => {
  // localStorage.removeItem(USER_AUTH_TOKEN)
  historyNavigator.redirect("/");
};

export const setThemeState = (mode: string) => {
  // mode = "light";
  // We will enable this once we proper implements dark theme
  mode = mode === "light" ? "dark" : "light";
  localStorage.setItem(SITE_THEME_MODE, mode);
  Store.dispatch(updateThemeMode(mode));
};
export const setThemeStateBkoff = (mode: string) => {
  // We will enable this once we proper implements dark theme
  mode = mode === "light" ? "dark" : "light";
  localStorage.setItem(SITE_THEME_MODE, mode);
  Store.dispatch(updateThemeModeBkoff(mode));
};

export const getThemeState = () => {
  // We will enable this once we proper implements dark theme
  const theme = localStorage.getItem(SITE_THEME_MODE);
  if (theme) return theme;
  if (window.matchMedia("(prefers-color-scheme: dark)").matches) return "dark";
  // return "light";
};
export const getThemeStateBkoff = () => {
  // We will enable this once we proper implements dark theme
  const theme = localStorage.getItem(SITE_THEME_MODE);
  if (theme) return theme;
  if (window.matchMedia("(prefers-color-scheme: dark)").matches) return "dark";
  return "light";
};
export const isTabFocused = () => {
  return document.visibilityState === "visible";
};
/*
export const isStringProperEncoded = (str: string) => {
    try {
        const resp = utf8.decode(base64.decode(str));
        return true;
    } catch {
        return false;
    }
}

export const encodeUnicode = (str: string) => {
    try {
        return base64.encode(utf8.encode(str));
    } catch {
        return '';
    }
}

export const decodeUnicode = (str: string) => {
    try {
        return utf8.decode(base64.decode(str));
    } catch {
        return '';
    }
}*/
export const hasPermission = (permission: string) => {
  const account = Store.getState().account;
  const userPermission = account?.init?.user?.scopes?.RawMessage;
  const team = account?.init?.user?.team;
  let child: any;
  const result: any = [];
  let key: any;

  if (userPermission != undefined && Object.keys(userPermission).length > 0) {
    for (child of Object.values(userPermission)) {
      result.push(...child);
    }
  }
  if (team != undefined && team.length > 0) {
    for (child of team) {
      const teamPermission = child.Role.Scopes.RawMessage;
      for (key of Object.values(teamPermission)) {
        result.push(...key);
      }
    }
  }

  if (userPermission != undefined && team != undefined) {
    const permissions = result.filter(
      (item: string, index: number) => result.indexOf(item) === index
    );
    const havePermission =
      account.init.user?.role_name?.length < 1 ||
      permissions.includes(permission);
    return havePermission;
  } else {
    return true;
  }
};

export const getRedirectURI = (): string => {
  let _path = "";
  if (window.location.pathname) _path += window.location.pathname;
  if (window.location.hash) _path += window.location.hash;
  if (window.location.search) _path += window.location.search;

  if (_path.indexOf("&register=new") > -1) {
    _path = _path.replace("&register=new", "");
    triggerRegisterEvent();
  }

  return _path;
};

export const triggerRegisterEvent = (_cb?: (a0: boolean) => void) => {
  try {
    const _W = window as any;
    if (typeof _W.gtag !== "undefined") {
      _W.gtag("event", "signup_complete");
      _cb && _cb(true);
    } else {
      _cb && _cb(false);
    }
  } catch (e) {}
};

export const debounceHandler = (timeoutId: any, func: any, delay: number) => {
  return (...args: any) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

export const getOperatingSystem = (
  window: Window & typeof globalThis
): "Not known" | "Windows OS" | "MacOS" | "UNIX OS" | "Linux OS" => {
  let operatingSystem:
    | "Not known"
    | "Windows OS"
    | "MacOS"
    | "UNIX OS"
    | "Linux OS" = "Not known";
  if (window.navigator.appVersion.indexOf("Win") !== -1) {
    operatingSystem = "Windows OS";
  }
  if (window.navigator.appVersion.indexOf("Mac") !== -1) {
    operatingSystem = "MacOS";
  }
  if (window.navigator.appVersion.indexOf("X11") !== -1) {
    operatingSystem = "UNIX OS";
  }
  if (window.navigator.appVersion.indexOf("Linux") !== -1) {
    operatingSystem = "Linux OS";
  }
  return operatingSystem;
};
