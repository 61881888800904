import {
    AGENT_INTEGRATION_DETAIL_RECEIVED,
    TASKS_RECEIVED
} from './constant'

interface AgentIntegrationDetails {
    result: {
        cassandra_config: boolean;
        clickhouse_config: boolean;
        elasticsearch_config: boolean;
        kafka_config: boolean;
        mongodb_config: boolean;
        mysql_config: boolean;
        oracledb_config: boolean;
        apache_config: boolean;
        postgres_config: boolean;
        prometheus_config: boolean;
        redis_config: boolean;
        redpanda_config: boolean;
        aws_config: boolean;
    };
    status: boolean;
}


export const tasksReceived = (arg: any = {}) => {
    return {
        type: TASKS_RECEIVED,
        ...arg
    }
}
export const agentIntegrationDetailsReceived = (arg?: Partial<AgentIntegrationDetails>) => {
    return {
        type: AGENT_INTEGRATION_DETAIL_RECEIVED,
        ...arg
    }
}