import * as React from "react";
import NavTab from "core/components/nav-tab";
import TabItems from "core/components/nav-tab/items";
import {
    InstallationDocsIcon,
    LinuxIcon,
    WindowIconInst,
    WindowsSvg
} from "core/components/svg/icons";
import WindowsInstallation from "views/modules/installation/components/windows-installation";
import {Link} from "react-router-dom";
import TableGridview, {GridviewColumnsOptions} from "core/components/table-gridview";
import Tooltip from "core/components/tooltip";
import {useRef} from "react";

export default function WindowInfraInstrument() {
    const tooltipParentRef = useRef<HTMLDivElement>(null)

    const dataProviderProfiling = [
        {
            continue: 'container',
            item: [
                <span>container.cpu.usage.kernelmode</span>,
                'CPU Usage Kernelmode\n',
                'Time spent by tasks of the cgroup in kernel mode (Linux). Time spent by all container processes in kernel mode (Windows).\n'
            ]
        },
        {
            continue: 'container',
            item: [
                <span>container.cpu.usage.usermode</span>,
                'CPU Usage Usermode\n',
                'Time spent by tasks of the cgroup in user mode (Linux). Time spent by all container processes in user mode (Windows).\n'
            ]
        },
        {
            continue: 'host',
            item: [
                <span>system.disk.io_time</span>,
                'Disk IO Time\n',
                'Time disk spent activated. On Windows, this is calculated as the inverse of disk idle time.\n'
            ]
        },
        {
            continue: 'host',
            item: [
                <span>system.paging.usage</span>,
                'Paging Usage\n',
                'Swap (unix) or pagefile (windows) usage.\n'
            ]
        },
        {
            continue: 'process',
            item: [
                <span>process.command</span>,
                '-',
                'The command used to launch the process (i.e. the command name). On Linux based systems, can be set to the zeroth string in proc/[pid]/cmdline. On Windows, can be set to the first parameter extracted from GetCommandLineW.\n'
            ]
        },
        {
            continue: 'process',
            item: [
                <span>process.command_line</span>,
                'Command Line\n',
                'The full command used to launch the process as a single string representing the full command. On Windows, can be set to the result of GetCommandLineW. Do not set this if you have to assemble it just for monitoring; use process.command_args instead.\n'
            ]
        },
        {
            continue: 'process',
            item: [
                <span>process.executable.name</span>,
                'Name',
                'The name of the process executable. On Linux based systems, can be set to the Name in proc/[pid]/status. On Windows, can be set to the base name of GetProcessImageFileNameW.\n'
            ]
        },
        {
            continue: 'process',
            item: [
                <span>process.executable.path</span>,
                'Path',
                'The full path to the process executable. On Linux based systems, can be set to the target of proc/[pid]/exe. On Windows, can be set to the result of GetProcessImageFileNameW.\n'
            ]
        }
    ];
    const columnsProfiling: Array<GridviewColumnsOptions> = [
        {
            title: "Resource",
            value: (data: any) => {
                return data.continue;
            }
        },
        {
            title: "Key",
            value: (data: any) => {
                return data.item[0];
            }
        },
        {
            title: "Label",
            value: (data: any) => {
                return data.item[1];
            }
        },
        {
            title: "Description",
            value: (data: any,columnIndex ?:number, rowIndex ?: number) => {

                const parentWidth = tooltipParentRef.current ? tooltipParentRef.current.clientWidth : 500

                const approxLetterWid = 9
                const charactersPerLine = Math.floor(parentWidth / approxLetterWid);
                const truncatedValue = data.item[2]?.length > charactersPerLine ? data.item[2]?.slice(0, charactersPerLine) + "..." : data.item[2];

                return <div ref={tooltipParentRef}>
                    <span data-tip data-for={"tooltip" + rowIndex}>{truncatedValue}</span>
                    {data.item[2]?.length > charactersPerLine && <Tooltip id={"tooltip" + rowIndex} place="top" type="dark" effect="solid">
                        <span>{data.item[2]}</span>
                    </Tooltip>}
                </div>
            }
        },
    ];
    return <>
        <div className={'jira-integration-new dialog-table-componants'}>
            <div className={' setting_side-panel'}>
                <div className={'integration-popup'}>
                    <div className={'header-section'}>
                        <div className={'d-flex'}>
                            <WindowIconInst/>
                            <div className={'header-content'}>
                                <h3>Windows</h3>
                                <p>Windows is an operating system developed by Microsoft. Download the Windows Agent to
                                    monitor your infrastructure’s performance.</p>
                            </div>
                            <button className={"docs-link"}><Link
                                to={'https://docs.middleware.io/docs/agent-installation/windows'}
                                target={'_blank'}><span> <InstallationDocsIcon/>Documentation</span></Link></button>
                        </div>
                        <NavTab targetIndex={1} defaultActiveTabIndex={1}>
                            <TabItems title={'Overview'}>
                                <div className={'d-flex in-content'}>
                                    <div className={'overview-heading'}>
                                        <div className={'config-overview'}>
                                            <h4>Category</h4>
                                            <span>OS</span><br/>
                                            <span>Bare-metal</span><br/>
                                            <span>Infrastructure</span><br/>

                                            <h4 className={'tp-mr'}>Supported OS</h4>
                                            <div className={'d-flex'}>
                                                <LinuxIcon/>
                                                <WindowsSvg/>
                                                {/*<MacIcon/>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'overview-content'}>
                                        <p>The Windows Infra Agent can be installed with an end-to-end install
                                            wizard.</p>
                                        <div className={'overview-image'}>
                                            <img src={'assets/images/bare-metal-overview.png'}
                                                 alt={'bare-metal-overview'}/>
                                        </div>
                                        <div className={'features-dialog-instrumentation'}>
                                            <h3>Features</h3>
                                            <div className={'d-flex'}>
                                                <button className={'example-linux'}>Disk Persistence</button>
                                                <button className={'example-linux'}>Memory Usage</button>
                                                <button className={'example-linux'}>Performance Metrics</button>
                                            </div>
                                        </div>
                                        <div className={'overview-table'}>
                                            <h3 className={'mr-top'}>Metrics Collected</h3>
                                            <div className={'table-metric-collect'}>
                                            <TableGridview
                                                columns={columnsProfiling}
                                                dataProvider={dataProviderProfiling}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabItems>
                            <TabItems title={'Installation'}>
                                <div className={'panel-body'}>
                                    <WindowsInstallation/>
                                </div>

                            </TabItems>

                        </NavTab>
                    </div>
                </div>

            </div>
        </div>
    </>
}