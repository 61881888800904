import Card from "core/components/v2/card";
import NoDataSpace from "core/components/v2/no-data";
import React, { useRef } from "react";
import { MwCardProps } from "views/layouts/app/components/cards/model";
import {
  BuilderNestedProps,
  ChartDataType,
  SubInfoRange,
} from "views/modules/builder/entities/builder.entities";
import { getValueFromConfig } from "views/modules/builder/entities/tpl.entities";
import { InfoPlacement } from "../../card/entity";
import { StatusBadgeType } from "../../status-badge";
import { DirectionUpIcon } from "../../svg/interaction-icons";
interface CountChartProps {
  widgetData: ChartDataType | undefined;
  nestedProps?: BuilderNestedProps;
  boxTitle?: string;
  subInfo?: string;
  isLoading?: boolean;
  cardActions?: React.ReactNode;
}
const GetStatusFromValueRange = (
  value: number,
  subInfoBadgeRange: SubInfoRange[]
): SubInfoRange | undefined => {
  const range = subInfoBadgeRange.find(
    (range) => value >= range.min && value <= range.max
  );
  return range ?? undefined;
};
const CountChart = (props: CountChartProps) => {
  const { widgetData, nestedProps, boxTitle, subInfo, isLoading, cardActions } =
    props;
  const { countChartOptions } = nestedProps ?? {};
  const queryCountRef = useRef<HTMLDivElement>(null);
  const columnConfig = nestedProps?.columnConfig;
  try {
    const queryCountChartData = widgetData?.queryCountChartData;
    return queryCountChartData && Array.isArray(queryCountChartData) ? (
      <div
        className="query-count"
        ref={queryCountRef}
        style={{ height: "100%" }}
      >
        {queryCountChartData.map((item, key) => {
          const name = item.name;
          const config = columnConfig?.[name];
          const fontColor = config?.color;
          const bgColor = config?.bgColor;
          const tpl = config?.tpl ?? item.config;
          const value = getValueFromConfig(item.value, tpl);
          const queryCardProps: MwCardProps = {
            subInfo: subInfo ?? "",
            default: true,
          };
          if (!countChartOptions?.hideValue) {
            queryCardProps.value = value;
          }
          let badgeType = countChartOptions?.badgeType ?? StatusBadgeType.Info;
          if (!isNaN(parseInt(value)) && countChartOptions?.subInfoBadgeRange) {
            const range = GetStatusFromValueRange(
              parseFloat(value),
              countChartOptions?.subInfoBadgeRange
            );
            if (range) {
              badgeType = range.badgeType;
              queryCardProps.subInfo = range.subInfo;
            }
          }
          if (badgeType === StatusBadgeType.Error) {
            queryCardProps.error = true;
          } else if (badgeType === StatusBadgeType.Warning) {
            queryCardProps.warning = true;
          } else if (badgeType === StatusBadgeType.Success) {
            queryCardProps.success = true;
          }
          if (nestedProps?.countChartOptions?.showComparison) {
            const totalValue = item.value;
            const comparisonValue = item.comparisonData;
            const percentage = comparisonValue
              ? Math.round(
                  ((totalValue - comparisonValue) / comparisonValue) * 100
                )
              : 0;
            const svgColor =
              badgeType === StatusBadgeType.Error
                ? "var(--color-error)"
                : "var(--color-success)";
            queryCardProps.subInfo = (
              <React.Fragment>
                {percentage + " %" ?? ""}
                <DirectionUpIcon color={svgColor} />
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={key}>
              <Card
                bordered
                title={boxTitle ? boxTitle : ""}
                titleInfo={props.nestedProps?.titleInfo}
                className="full-height-card main-header "
                queryCardProps={queryCardProps}
                infoPlacement={InfoPlacement.End}
                cardActions={cardActions}
                displayActionsOnHoverOnly={true}
                fontColor={fontColor}
                bgColor={bgColor}
              />
            </React.Fragment>
          );
        })}
      </div>
    ) : (
      <div className={"widget-no-data-label"}>
        <Card
          bordered
          title={boxTitle ? boxTitle : ""}
          titleInfo={props.nestedProps?.titleInfo}
          className="full-height-card main-header "
          infoPlacement={InfoPlacement.End}
          cardActions={cardActions}
          displayActionsOnHoverOnly={true}
        >
          <div
            style={{
              display: "flex",
              flex: "1",
              height: "100%",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {!isLoading ? (
              <NoDataSpace
                iconSize={32}
                displayInline
                // exmpySpaceText={"Oops! No data available"}
              />
            ) : (
              <div
                style={{
                  height: "30px",
                }}
              />
            )}
          </div>
        </Card>
      </div>
    );
  } catch (e) {
    console.warn(e);
    return <div>Something went wrong...</div>;
  }
};
export default CountChart;
