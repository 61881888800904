export const RECEIVED_BACKOFF_AUTH_TOKEN = 'RECEIVED_BACKOFF_AUTH_TOKEN';
export const RECEIVED_BACKOFF_USER = 'RECEIVED_BACKOFF_USER';
export const REQUEST_BACKOFF_INFLIGHT = 'REQUEST_BACKOFF_INFLIGHT';
export const RECEIVED_BACKOFF_AGENTS = 'RECEIVED_BACKOFF_AGENTS';
export const RECEIVED_BACKOFF_LOGS = 'RECEIVED_BACKOFF_LOGS';
export const RECEIVED_BACKOFF_USAGES = 'RECEIVED_BACKOFF_USAGES';
export const RECEIVED_BACKOFF_AGENTS_BINDING = 'RECEIVED_BACKOFF_AGENTS_BINDING';
export const RECEIVED_BACKOFF_LOGIN_DETAILS = 'RECEIVED_BACKOFF_LOGIN_DETAILS';
export const RECEIVED_BACKOFF_EMAIL_DETAILS = 'RECEIVED_BACKOFF_EMAIL_DETAILS';
export const RECEIVED_BACKOFF_CONTACT_DETAILS = 'RECEIVED_BACKOFF_CONTACT_DETAILS';
export const RECEIVED_BACKOFF_SENDER = 'RECEIVED_BACKOFF_SENDER';
export const RECEIVED_BACKOFF_EMAIL_CAMPAIGN = 'RECEIVED_BACKOFF_EMAIL_CAMPAIGN';
export const RECEIVED_BACKOFF_EDIT_EMAIL_CAMPAIGN = 'RECEIVED_BACKOFF_EDIT_EMAIL_CAMPAIGN';
export const RECEIVED_BACKOFF_DELETE_EMAIL_CAMPAIGN = 'RECEIVED_BACKOFF_DELETE_EMAIL_CAMPAIGN';
export const RECEIVED_BACKOFF_DELETE_ACCOUNT = 'RECEIVED_BACKOFF_DELETE_ACCOUNT';
export const RECEIVED_BACKOFF_UPDATE_ACCOUNT = 'RECEIVED_BACKOFF_UPDATE_ACCOUNT';
export const RECEIVED_BACKOFF_UPDATE_TRIALDAY = 'RECEIVED_BACKOFF_UPDATE_TRIALDAY';
export const RECEIVED_BACKOFF_VERCEL_DATA = 'RECEIVED_BACKOFF_VERCEL_DATA';
export const THEME_MODE = "THEME_MODE"
export const RECEIVED_INTERNAL_ACCOUNT_FLAG = "RECEIVED_INTERNAL_ACCOUNT_FLAG"

