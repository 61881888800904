import { getTenantUrl } from "core/application/utils";
import { Message } from "core/components/v2/enums";
import TabSelection from "core/components/v2/tab-selection";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import { InstallationCardRowItems } from "views/modules/installation-v2/components/card/entity";
import InstallationCard from "../../components/card";
import Command from "../../components/code-block/command";
import MwMessage from "../../components/message";
import InstallationSteps from "../../components/steps";
import StepItem from "../../components/steps/step-item";
import LinuxInstallationSnippet from "../common/linux-snippet";
interface LinuxIntegrationProps {
  packageType: string;
  os: string;
}

const LinuxIntegration: React.FC<LinuxIntegrationProps> = ({
  packageType,
  os,
}) => {
  const [tab, setTab] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];

  const osCardItemsMap: Record<string, InstallationCardRowItems[]> = {
    linux: [
      {
        items: [
          {
            label: "Linux Based OS Version",
            value: "Running on LTS",
          },
        ],
      },
    ],
    debian: [
      {
        items: [
          {
            label: "OS Version",
            value: "Running on LTS",
          },
        ],
      },
    ],
    ubuntu: [
      {
        items: [
          {
            label: "OS Version",
            value: "v18.04 or above",
          },
        ],
      },
    ],
    "red-hat": [
      {
        items: [
          {
            label: "OS Version",
            value: "Running on LTS",
          },
        ],
      },
    ],
    "cent-os": [
      {
        items: [
          {
            label: "OS Version",
            value: "Running on LTS",
          },
        ],
      },
    ],
    "alma-linux": [
      {
        items: [
          {
            label: "OS Version",
            value: "Running on LTS",
          },
        ],
      },
    ],
    "rocky-linux": [
      {
        items: [
          {
            label: "OS Version",
            value: "Running on LTS",
          },
        ],
      },
    ],
    suse: [
      {
        items: [
          {
            label: "OS Version",
            value: "Running on LTS",
          },
        ],
      },
    ],
    "oracle-linux": [
      {
        items: [
          {
            label: "OS Version",
            value: "Running on LTS",
          },
        ],
      },
    ],

    // Add more OS cases as needed
  };

  // Get the card items based on the OS
  const cardItemsToShow = os ? osCardItemsMap[os] : cardItems;

  const oldStatusCheckCommand = `sudo systemctl status mwservice`;
  const newStatusCheckCommand = `sudo systemctl status mw-agent`;
  const sendEvent = useMixPanel();
  return (
    <>
      <InstallationSteps>
        <StepItem stepNumber={1} title={"Prerequisites"}>
          <InstallationCard items={cardItemsToShow} />
        </StepItem>

        <StepItem stepNumber={2} title={"Install Middleware Host Agent"}>
          <LinuxInstallationSnippet packageType={packageType} os="unknown" />
        </StepItem>
        <StepItem stepNumber={3} title={"Check Status:  Middleware Host Agent"}>
          <TabSelection
            tabs={[
              {
                label: "v1.6.4 & Higher",
                value: 1,
              },
              {
                label: "Upto v1.6.3",
                value: 2,
              },
            ]}
            selectedTab={1}
            onTabChange={(tabObject) => {
              setStatusTab(tabObject.value);
            }}
          />
          <div className="tab-content-area">
            {statusTab === 1 && (
              <>
                <Command
                  onCopyClick={() => {
                    sendEvent("click on copy command", {
                      agent_type: "Linux",
                      command_type: "Check Status:  Middleware Host Agent",
                    });
                  }}
                  command={newStatusCheckCommand}
                  className="margin-0"
                />
              </>
            )}
            {statusTab === 2 && (
              <>
                <Command
                  onCopyClick={() => {
                    sendEvent("click on copy command", {
                      agent_type: "Linux",
                      command_type: "Check Status:  Middleware Host Agent",
                    });
                  }}
                  command={oldStatusCheckCommand}
                  className="margin-0"
                />
              </>
            )}
          </div>
          <MwMessage
            type={Message.Info}
            message="Get the current version of Middleware Host Agent using the following command: `mw-agent version`"
          ></MwMessage>
        </StepItem>
      </InstallationSteps>
    </>
  );
};
export default LinuxIntegration;
