export const SET_ALERT_FILTER_BAR = "SET_ALERT_FILTER_BAR";
export const SEARCH_ALERT_FILTER_BAR = "SEARCH_ALERT_FILTER_BAR";
export const ALERTS_RECEIVED = "ALERTS_RECEIVED";
export const ALERTS_STATES_RECEIVED = "ALERTS_STATES_RECEIVED";
export const ALERT_DETAIL_RECEIVED = "ALERT_DETAIL_RECEIVED";
export const WS_ALERTS_RECEIVED = "WS_ALERTS_RECEIVED";
export const WS_ALERT_DETAIL_RECEIVED = "WS_ALERT_DETAIL_RECEIVED";

export const RULES_RECEIVED = "RULES_RECEIVED";
export const DATE_RANGE_CHART = "DATE_RANGE_CHART";
export const TAGS_RECEIVED = "TAGS_RECEIVED";
export const EMAIL_RECEIVED = "EMAIL_RECEIVED";
export const ALERT_COUNT_SIDEBAR = "ALERT_COUNT_SIDEBAR";
export const HOST_RECEIVED = "HOST_RECEIVED";
export const SLACK_CHANNEL_RECEIVED = "SLACK_CHANNEL_RECEIVED";
export const MS_TEAMS_CHANNEL_RECEIVED = "MS_TEAMS_CHANNEL_RECEIVED";
export const APM_PROJECCT_NAME_RECEIVED = "APM_PROJECCT_NAME_RECEIVED";
export const APM_SERVICE_NAME_RECEIVED = "APM_SERVICE_NAME_RECEIVED";
export const APM_ROUTE_LIST_RECEIVED = "APM_ROUTE_LIST_RECEIVED";
export const K8S_METRICS_LIST_RECEIVED = "K8S_METRICS_LIST_RECEIVED";
export const K8_NODE_LIST_RECEIVED = "K8_NODE_LIST_RECEIVED";
export const K8_NAMESPACE_LIST_RECEIVED = "K8_NAMESPACE_LIST_RECEIVED";
export const K8_POD_LIST_RECEIVED = "K8_POD_LIST_RECEIVED";
export const GET_ALERT_FILTER_DATA = "GET_ALERT_FILTER_DATA";
export const GET_LOGS_ATTRIBUTE_DATA = "GET_LOGS_ATTRIBUTE_DATA";
export const GET_LOGS_TYPE_DATA = "GET_LOGS_TYPE_DATA";
export const SET_FILTER_FOR_ALERTS = "SET_FILTER_FOR_ALERTS";
export const REMOVE_FILTER_FOR_ALERTS = "REMOVE_FILTER_FOR_ALERTS";
export const ALERT_FILTERS = "ALERT_FILTERS";
export const RECEIVED_RULES_LIST = "RECEIVED_RULES_LIST";
export const TRIGGERED_ALERTS_RECEIVED = "TRIGGERED_ALERTS_RECEIVED";
export const TRIGGERED_INNER_ALERTS_RECEIVED =
  "TRIGGERED_INNER_ALERTS_RECEIVED";
export const RULE_DETAILS_RECEIVED = "RULE_DETAILS_RECEIVED";
export const RESET_RULE_DETAILS = "RESET_RULE_DETAILS";
export const FILTER_SECTIONS_RECEIVED = "FILTER_SECTIONS_RECEIVED";
export const ALERT_STATES_RECEIVED = "ALERT_STATES_RECEIVED";
export const INTEGRATIONS_LIST_RECEIVED = "INTEGRATIONS_LIST_RECEIVED";
export const CLEAR_TRIGGERED_ALERTS = "CLEAR_TRIGGERED_ALERTS";

export const FILTER_BAR = {
  status: {
    label: "Status",
    checkboxes: [
      "cert-manager",
      "default",
      "dynatrace",
      "elastic-system",
      "ngress-ngnix",
      "ingress-controller-test",
      "buoyant-cloud",
    ],
    selected: [],
  },
  muted: {
    label: "Muted",
    checkboxes: ["option 1", "option 2"],
    selected: [],
  },
  priority: {
    label: "Priority",
    checkboxes: ["option 1", "option 2"],
    selected: [],
  },
  type: {
    label: "Type",
    checkboxes: ["option 1", "option 2"],
    selected: [],
  },
  event_migration: {
    label: "StatEvent Migration",
    checkboxes: ["option 1", "option 2"],
    selected: [],
  },
  creator: {
    label: "Creator",
    checkboxes: ["option 1", "option 2"],
    selected: [],
  },
  handle: {
    label: "Handle",
    checkboxes: ["option 1", "option 2"],
    selected: [],
  },
  scope: {
    label: "Scope",
    checkboxes: ["option 1", "option 2"],
    selected: [],
  },
  metric_check: {
    label: "Metric/Check",
    checkboxes: ["option 1", "option 2"],
    selected: [],
  },
  notification: {
    label: "Notification",
    checkboxes: ["option 1", "option 2"],
    selected: [],
  },
} as any;
