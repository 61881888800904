import React from "react";
import CustomizedFilterButton, {
  CustomizedFilterOption,
} from "views/layouts/app/components/customise-filter-button";
import { Type } from "../enums";
export interface StatsData {
  total: number;
  filtered: number;
  label: string;
  totalLabel: string;
  filteredNumberType?: Type.Warning | Type.Error;
}
interface Props {
  children: React.ReactNode;
  titleChild?: React.ReactNode;
  columnsSelection?: CustomizedFilterOption[];
  onColumnsChange?: (selectedColumns: string[]) => void;
  statsData?: StatsData;
  actionElements?: React.ReactNode;
  onDefaultLabelClick?: () => void;
}
const TableOuterWrapper = (props: Props) => {
  const {
    titleChild,
    columnsSelection,
    onColumnsChange,
    children,
    statsData,
    actionElements,
    onDefaultLabelClick,
  } = props;
  const { total, filtered, label, totalLabel, filteredNumberType } =
    statsData ?? {};
  return (
    <>
      <div className={"mw-table-outer-wrapper"}>
        {(titleChild ?? statsData ?? columnsSelection) && (
          <div className={"upper-table-area"}>
            <div>
              {titleChild ? titleChild : ""}
              {statsData ? (
                <p>
                  <span
                    className={
                      filteredNumberType === Type.Error
                        ? "bg-red"
                        : filteredNumberType === Type.Warning
                          ? "bg-yellow"
                          : "bg-green"
                    }
                  >
                    {filtered}
                  </span>{" "}
                  {label}
                  <span className={"bg-grey"}>{total}</span> {totalLabel}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className={"filter-area"}>
              {columnsSelection && onColumnsChange && (
                <div>
                  <CustomizedFilterButton
                    filterOptions={columnsSelection}
                    handleApplyFilter={(filters) => onColumnsChange(filters)}
                    onDefaultLabelClick={onDefaultLabelClick}
                  />
                </div>
              )}
              {actionElements}
            </div>
          </div>
        )}
        {children}
      </div>
    </>
  );
};
export default TableOuterWrapper;
