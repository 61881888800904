import React, { useEffect, useState } from "react";
import { Popover as AntdPopover } from "antd";
import { triggerEnum } from "core/components/v2/popover/entity";
import classNames from "classnames";

export enum PopoverPlacement {
  TopLeft = "topLeft",
  Top = "top",
  TopRight = "topRight",
  Right = "right",
  RightTop = "rightTop",
  RightBottom = "RightBottom",
  LeftTop = "leftTop",
  Left = "left",
  LeftBottom = "leftBottom",
  BottomRight = "bottomRight",
  Bottom = "bottom",
  BottomLeft = "bottomLeft",
}

interface Props {
  children: React.ReactNode;
  content: React.ReactNode;
  triggerEvent?: triggerEnum;
  isVisible: boolean;
  onClose: (isVisible: boolean) => void;
  shouldCloseOnInnerClick?: boolean;
  placement?: PopoverPlacement;
  rootClassName?: string;
}

export const Popover = (props: Props) => {
  const {
    content,
    children,
    triggerEvent,
    rootClassName,
    isVisible,
    onClose,
    shouldCloseOnInnerClick = true,
    placement,
  } = props;

  const [open, setOpen] = useState(isVisible);

  const handleOpenChange = (newOpen: boolean) => {
    if (isVisible) {
      setOpen(newOpen);
      if (!newOpen) {
        onClose(newOpen);
      }
    }
  };

  useEffect(() => {
    setOpen(isVisible);
  }, [isVisible]);

  return (
    <div className={"mw-popover"}>
      <AntdPopover
        rootClassName={rootClassName}
        content={
          <div
            className={"mw-popover-inner-content"}
            onClick={() => {
              if (shouldCloseOnInnerClick) {
                setOpen(false);
                onClose(false);
              }
            }}
          >
            {content}
          </div>
        }
        trigger={triggerEvent ? triggerEvent : triggerEnum.click}
        open={open}
        onOpenChange={handleOpenChange}
        placement={placement ?? PopoverPlacement.BottomLeft}
        arrow={false}
        destroyTooltipOnHide
      >
        {children}
      </AntdPopover>
    </div>
  );
};
