import { Dayjs } from "dayjs";
import React from "react";

import { TimeRangeOption } from "core/components/datepicker/model";
import DateTimeSelection from "core/components/v2/date-picker/DateTimeSelection";
import { Popover, PopoverPlacement } from "core/components/v2/popover";
import { DateRange } from "views/layouts/app/routes/model";
import Button from "../button";
import { ArrowDownIcon, ClockIcon } from "../svg/icons";
import { defaultPresetOptions } from "./presetOptions";

export interface PresetOptions {
  timeRangeOption: TimeRangeOption;
  label: string;
  value?: Dayjs[];
}

interface DatePickerProps {
  isDatePickerVisible: boolean;
  handleDatePickerVisibility: (value: boolean) => void;
  onChange: (range: DateRange) => void;
  defaultValue?: DateRange;
  presetOptions?: PresetOptions[];
  datePickerBtnText: string;
  disablePastDates?: boolean;
  disableFutureDates?: boolean;
  maxDaysSelection?: number;
  dataTestId?: string;
}

const DatePicker = (props: DatePickerProps) => {
  const {
    onChange,
    defaultValue,
    presetOptions,
    datePickerBtnText,
    isDatePickerVisible,
    handleDatePickerVisibility,
    disablePastDates = false,
    disableFutureDates = false,
    maxDaysSelection = 0,
    dataTestId,
  } = props;

  return (
    <Popover
      content={
        <DateTimeSelection
          presetOptions={presetOptions ?? defaultPresetOptions}
          defaultValue={defaultValue}
          onChange={onChange}
          disablePastDates={disablePastDates}
          disableFutureDates={disableFutureDates}
          maxDaysSelection={maxDaysSelection}
        />
      }
      placement={PopoverPlacement.BottomRight}
      isVisible={isDatePickerVisible}
      onClose={() => {
        handleDatePickerVisibility(false);
      }}
      shouldCloseOnInnerClick={false}
    >
      <div onClick={() => handleDatePickerVisibility(!isDatePickerVisible)}>
        <Button
          prefixicon={<ClockIcon size={16} />}
          suffixicon={<ArrowDownIcon />}
          dataTestId={dataTestId}
        >
          {datePickerBtnText}
        </Button>
      </div>
    </Popover>
  );
};

export default DatePicker;
