import React, { ReactNode } from "react";
import { Upload } from "antd";
import { UploadFile, UploadProps } from "antd/es/upload";
import { UploadIcon } from "../svg/interaction-icons";
import Button from "../button";
import {
  DefaultFileIcon,
  DeleteIcon,
  JsonFileIcon,
  XlsFileIcon,
} from "../svg/icons";
import LogoLoader from "../loader/logo-loader";

interface FileUploadProps {
  name: string;
  multiple?: boolean;
  onFileUpload: (file: UploadFile[]) => void;
  onRemove?: (file: UploadFile) => void;
  onReUpload?: (file: UploadFile) => void;
  fileList?: UploadFile[];
  uploading?: boolean;
  hideSize?: boolean;
}

const { Dragger } = Upload;

const FileUpload = (props: FileUploadProps) => {
  const {
    name,
    multiple = false,
    onFileUpload,
    onRemove,
    onReUpload,
    fileList,
    uploading,
    hideSize = false,
  } = props;

  // Todo: Need to update this mapping once we have possible file types & icons
  const getFileIcon = (fileType: string) => {
    if (fileType === "application/xls") {
      return <XlsFileIcon />;
    } else if (fileType === "application/json") {
      return <JsonFileIcon />;
    } else {
      return <DefaultFileIcon />;
    }
  };

  const renderFile = (fileList: UploadFile[]) =>
    fileList.map((file) => (
      <div key={file.uid} className="file-items-container">
        <div className="file-items">
          <div className="file-details-container">
            <div className="file-icon-container">
              {getFileIcon(file.type ?? "")}
            </div>
            <div className="file-details">
              <div className="file-name">{file.name}</div>
              {hideSize ? null : (
                <div className="file-size">{file.size ?? 0 / 1000}KB</div>
              )}
            </div>
          </div>
          <div className="btn-container">
            {onReUpload && (
              <Button secondary onClick={() => onReUpload?.(file)}>
                Reupload
              </Button>
            )}
            <Button secondary error onClick={() => onRemove?.(file)}>
              <DeleteIcon color="var(--color-error)" />
            </Button>
          </div>
        </div>
        {/* Todo: need to replace this once we get solution for xhr request */}
        {/* {file.status === "uploading" && (
          <Progress
            percent={Math.round(file.percent ?? 0)}
            className="upload-progress"
            strokeColor="var(--color-primary)"
            showInfo={false}
          />
        )} */}
      </div>
    ));

  const uploadProps: UploadProps = {
    name: name,
    multiple: multiple,
    onChange: (info) => onFileUpload(info.fileList),
    fileList: fileList,
  };

  return (
    <>
      {fileList?.length === 0 && (
        <Dragger {...uploadProps}>
          {
            <div className="ant-dragger-content">
              <UploadIcon />
              <div className="ant-upload-text">
                Drag & Drop files here or{" "}
                <a className="choose-file">Choose Files</a>
              </div>
            </div>
          }
        </Dragger>
      )}
      {fileList && fileList.length > 0 && (
        <div className="file-list-container">
          {fileList && fileList.length > 0 && (
            <div className={`file-list ${uploading ? "uploading" : ""}`}>
              {renderFile(fileList)}
            </div>
          )}
          {uploading && (
            <div className="logo-loader-container">
              <LogoLoader size={50} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FileUpload;
