import AppContext from "core/components/wrapper/context";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Sandbox from "views/layouts/app/breadcrumbs/sandbox";

import { BillingStatus } from "core/application/model";
import Button from "core/components/v2/button";
import Dialog from "core/components/v2/dialog";
import { InfoToastIcon } from "core/components/v2/svg/icons";
import { BillingLimitIcon } from "core/components/v2/svg/interaction-icons";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { requestBillingCreateHostedPage } from "store/settings/billing/api";
import {
  BillingPopupEnum,
  billingPopupKeyName,
  setBillingPopupInSession,
} from "views/layouts/app/utils";
import ChargebeePopup from "views/modules/settings-v2/billing/chargebee-popup";
import { PricingLimit } from "views/modules/settings-v2/billing/entity";
import {
  prodChargebeePlan,
  testChargebeePlan,
} from "views/modules/settings/billing-usages/utils";

const MainBreadcrumbs = function (props: any) {
  const context = React.useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [billingPopup, setBillingPopup] = useState(false);
  const [openChargebeePopup, setOpenChargebeePopup] = useState<boolean>(false);
  const [chargebeePopupUrl, setChargebeePopupUrl] = useState<string>("");

  const sub_products = context.user?.billing?.subscribed_products?.RawMessage;
  const syntheticLimitOver = !sub_products?.synthetics;
  const serverlessTracesLimitOver = !sub_products?.serverless_traces;
  const rumLimitOver = !sub_products?.rum;
  const infraLimitOver = !sub_products?.infra;
  const apmLimitOver = !sub_products?.apm;
  const logsLimitOver = !sub_products?.logs;
  const databaseLimitOver = !sub_products?.database;
  const billingStatusPending =
    context.user?.billing?.status == BillingStatus.pending;
  const billingStatusActive =
    context.user?.billing?.status == BillingStatus.active;
  const billingStatusInTrial =
    context.user?.billing?.status == BillingStatus.in_trial;
  const stopDataIngestion =
    context.user?.billing?.meta_data?.RawMessage?.stop_data_ingestion;

  const productLimitExceed =
    (billingStatusPending || billingStatusInTrial) &&
    !stopDataIngestion &&
    (infraLimitOver ||
      apmLimitOver ||
      logsLimitOver ||
      syntheticLimitOver ||
      rumLimitOver ||
      databaseLimitOver ||
      serverlessTracesLimitOver);

  useEffect(() => {
    const getBillingPopupFromSession: string | null =
      sessionStorage.getItem(billingPopupKeyName);
    if (
      getBillingPopupFromSession != BillingPopupEnum.closed &&
      location.pathname != "/settings/billing" &&
      productLimitExceed
    ) {
      setBillingPopup(true);
    }
    if (
      location.pathname == "/settings/billing" ||
      billingStatusActive ||
      location.pathname == "/settings/billing/usages"
    ) {
      setBillingPopup(false);
      setBillingPopupInSession(BillingPopupEnum.closed);
    }
  }, [context?.user?.billing?.status, location.pathname]);

  const getProductLimits = [
    {
      title: "Infrastructure",
      value: infraLimitOver,
      limit: PricingLimit.Infra,
    },
    {
      title: "Apm",
      value: apmLimitOver,
      limit: PricingLimit.Apm,
    },
    {
      title: "Logs",
      value: logsLimitOver,
      limit: PricingLimit.Logs,
    },
    {
      title: "Synthetics",
      value: syntheticLimitOver,
      limit: PricingLimit.Synthetics,
    },
    {
      title: "Rum",
      value: rumLimitOver,
      limit: PricingLimit.Rum,
    },
    {
      title: "Serverless Traces",
      value: serverlessTracesLimitOver,
      limit: PricingLimit.Serverless_Traces,
    },
    {
      title: "Database",
      value: databaseLimitOver,
      limit: PricingLimit.Database,
    },
  ];

  const confirm = () => {
    const subscribedProducts: any = [
      {
        item_price_id:
          context?.user?.env == "prod" ? prodChargebeePlan : testChargebeePlan,
      },
    ];
    dispatch(
      requestBillingCreateHostedPage(subscribedProducts, (res: any) => {
        if (res?.status === 200) {
          setChargebeePopupUrl(res?.data?.data?.url);
          setOpenChargebeePopup(true);
          setBillingPopup(false);
          setBillingPopupInSession(BillingPopupEnum.closed);
        }
      })
    );
  };

  return (
    <React.Fragment>
      {!billingPopup && productLimitExceed && (
        <div className={"hello-bar billing error"}>
          <i>
            <InfoToastIcon color="var(--color-error)" />
          </i>
          Data collection is paused until you add{" "}
          <span onClick={confirm}>billing details.</span>
        </div>
      )}
      {(context.user?.billing?.meta_data?.RawMessage?.due_payment === true ||
        context.user?.billing?.meta_data?.RawMessage?.not_paid) && (
        <div className={"hello-bar billing error"}>
          Your payment method has been declined. Please{" "}
          <Link to={"/settings/billing"}>update the billing details</Link> to
          unlock your account.
        </div>
      )}

      <MainBreadcrumbsContextWrapperV2
        className={props.module ? "main_breadcrumbs__" + props.module : ""}
      >
        {props.children ? (
          props.children
        ) : (
          <BreadcrumbsWrapper>
            <BreadcrumbsListItem>Default</BreadcrumbsListItem>
            <BreadcrumbsListItem classname={"active"}>List</BreadcrumbsListItem>
          </BreadcrumbsWrapper>
        )}
        <Sandbox />
      </MainBreadcrumbsContextWrapperV2>

      <Dialog
        isOpen={billingPopup}
        position={"center"}
        overlayClassName={"free-trial-over-__dialog common-confirmation-dialog"}
        onClose={() => {
          setBillingPopup(false);
          setBillingPopupInSession(BillingPopupEnum.closed);
        }}
      >
        <div className={"inner-content"}>
          <div className="d-flex">
            <div className="left-block">
              <div className="graphic-block">
                <BillingLimitIcon />
              </div>
              <div className="description-block">
                <p>You have run out!</p>
                <p>
                  Embrace Your <span>Pro User</span> Potential &<br />
                  <span>Pay as You Go</span>
                </p>
              </div>
            </div>
            <div className="right-block">
              <div className="error-block">
                <div className="title-area">
                  <h6>Usage surpassed free tier</h6>
                  <div className="sub-title">Max Limit Reached</div>
                </div>
                <div className="products-block">
                  {getProductLimits.map((item, index) => {
                    if (item.value === true) {
                      return (
                        <div className="d-flex" key={index}>
                          <div className="product">{item.title}</div>
                          <div className="value">{item.limit}</div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="gradient-block">
            <div className="d-flex">
              <div className="details">
                <div className="d-flex">
                  <div className="image-block"></div>
                  <div className="heading-block">
                    <div className="small-heading">Unlock Your Account</div>
                    <div className="main-heading">
                      Upgrade Now by Adding Details
                    </div>
                  </div>
                </div>
              </div>
              <div className="actions">
                <Button primary onClick={confirm}>
                  Add Card Details
                </Button>
                <a href={"mailto:onboarding@middleware.io"}>
                  <Button>Contact Us</Button>
                </a>
              </div>
            </div>
          </div>
          <div className="info-note">
            <InfoToastIcon />
            If you're having any issues or would like a few more days to explore
            reach out to
            <a href={"mailto:onboarding@middleware.io"}>
              {" "}
              onboarding@middleware.io
            </a>
          </div>
        </div>
      </Dialog>
      <ChargebeePopup
        url={chargebeePopupUrl}
        openChargebeePopup={openChargebeePopup}
        onClose={() => {
          setChargebeePopupUrl("");
          setOpenChargebeePopup(false);
        }}
      />
    </React.Fragment>
  );
};

export default MainBreadcrumbs;

export const MainBreadcrumbsContextWrapper = (props: any) => {
  return (
    <div
      className={`main-breadcrumbs__context ${props.className} ${props?.flexBox ? "is__flex_box" : ""}`}
    >
      {props.children}
    </div>
  );
};
export const MainBreadcrumbsContextWrapperV2 = (props: any) => {
  return (
    <div
      className={`main-breadcrumbs__context main-breadcrumbs__contextV2 ${props.className} ${props?.flexBox ? "is__flex_box" : ""}`}
    >
      {props.children}
    </div>
  );
};
export const BreadcrumbsWrapper = React.memo(function (props: any) {
  const navigate = useNavigate();
  function onBackBtnClick() {
    navigate("/synthetics/status-page/");
  }

  return (
    <ul className={`breadcrumbs__list-items ${props.className || ""} `}>
      {props.children}
    </ul>
  );
});

interface BreadcrumbsListItemProps {
  classname?: string | "";
  children: any;
  style?: React.CSSProperties;
  backButton?: boolean;
}

export const BreadcrumbsListItem = React.memo(function (
  props: BreadcrumbsListItemProps
) {
  return (
    <li
      className={`breadcrumbs__list-item ${props.classname || ""}`}
      style={props?.style ?? {}}
    >
      {props.children}
    </li>
  );
});
