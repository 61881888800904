import { initMixPanel, isProdWithDisabledUid } from "core/application/utils";
import * as React from "react";
import InitLoader from "views/placeholder/init-loader";
import AuthRoutes from "./routes";
import AuthRoutesHelper from "./routes-helper";

class AuthLayouts extends React.Component<any, any> {
  private readonly headerRef: React.RefObject<any>;
  private readonly contextRef: React.RefObject<any>;
  private readonly footerRef: React.RefObject<any>;
  private resize: any;
  constructor(props: any) {
    super(props);

    this.headerRef = React.createRef();
    this.contextRef = React.createRef();
    this.footerRef = React.createRef();

    this.screenResize = this.screenResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.screenResize);

    setTimeout(() => this.screenResize(), 400);
    const isProduction = isProdWithDisabledUid();

    initMixPanel(
      isProduction ? "prod" : "",
      "",
      {
        name: "",
        email: "",
        plan: "",
      },
      false
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.screenResize);
  }

  screenResize() {
    if (this.resize) clearTimeout(this.resize);
    this.resize = setTimeout(() => {
      if (
        this.headerRef.current &&
        this.contextRef.current &&
        this.footerRef.current
      ) {
        const h =
          this.headerRef.current.clientHeight +
          this.footerRef.current.clientHeight;

        this.contextRef.current.style.minHeight = `calc(100vh - ${h}px)`;
      }
    }, 10);
  }

  render() {
    const routes = [AuthRoutes];

    return (
      <React.Suspense fallback={<InitLoader />}>
        <div className={"auth__context__wrapper"}>
          <div ref={this.contextRef} className={"auth__layouts__context"}>
            <AuthRoutesHelper routes={routes} />
          </div>
        </div>
      </React.Suspense>
    );
  }
}
export default AuthLayouts;
