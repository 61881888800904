import {createReducer} from "core/utils/create_reducer";
import {INIT_IN_FLIGHT, RECEIVED_INIT} from "./constant";
import {combineReducers} from "redux";

const initUser = createReducer({
    inFlight: false,
    user: null,
    org: null,
    permissions: {},
    settings: {},
}, {
    [INIT_IN_FLIGHT]: (state, opts) => {
        if (typeof opts === "object") {
            state.inFlight = ( typeof opts.flag === "undefined" ? false : opts.flag )
        }
        return {...state}
    },
    [RECEIVED_INIT]: (state, opts) => {
        state.inFlight = false
        if (typeof opts === "object") {
            let {permissions, settings, type, updateSetting, attributes, updateAttributes, additional, ...usr} = opts

            if (usr && usr?.user_id)
                state.user = usr

            if (permissions)
                state.permissions = permissions

            if (settings)
                state.settings = settings

            if (attributes)
                state.attributes = attributes

            if (updateAttributes?.attributes)
                state.attributes = updateAttributes.attributes

            if (updateSetting && updateSetting?.key) {
                state.settings[updateSetting.key] = updateSetting
            }

            if (additional) {
                if (state.user?.account) {
                    if (additional?.is_agent_installed)
                        state.user.account.is_agent_installed = additional.is_agent_installed;
                    if (additional?.host_count)
                        state.user.account.host_count = additional.host_count;
                    if (additional?.apm_host_count)
                        state.user.account.apm_host_count = additional.apm_host_count;
                }
                if (additional?.misc_details)
                    state.user.misc_details = additional.misc_details;
            }
        }
        return {...state}
    }
})

export default combineReducers({
    init: initUser
})