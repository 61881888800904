import { getTenantUrl } from "core/application/utils";
import { Message } from "core/components/v2/enums";
import TabSelection from "core/components/v2/tab-selection";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { generateAgentConfig } from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import useMixPanel from "views/layouts/app/mix-panel-events/use-mix-panel";
import { InstallationCardRowItems } from "views/modules/installation-v2/components/card/entity";
import Command from "../../components/code-block/command";
import MwMessage from "../../components/message";
interface LinuxIntegrationProps {
  packageType: string;
  os: string;
}

const LinuxInstallationSnippet: React.FC<LinuxIntegrationProps> = ({
  packageType,
  os,
}) => {
  const [tab, setTab] = useState<number>(1);
  const [statusTab, setStatusTab] = useState<number>(1);
  const dispatch = useDispatch();

  const context = useContext<ContextProps>(AppContext);
  const [generatingToken, setGeneratingToken] = useState<boolean>(false);
  const generateToken = () => {
    setGeneratingToken(true);
    dispatch(
      generateAgentConfig((success, config) => {
        setGeneratingToken(false);
        if (success) {
          context.changeContext({
            key: "user",
            value: {
              ...context.user,
              account: {
                ...context.user?.account,
                agent_config: config,
              },
            },
          });
        } else {
          dispatch(
            showToast("error", "Error while generating token.Please try again.")
          );
        }
      })
    );
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;
  const cardItems = [
    {
      items: [
        {
          label: "Linux OS",
          value: "v18.04 or above",
        },
      ],
    },
  ];

  const osCardItemsMap: Record<string, InstallationCardRowItems[]> = {
    linux: [
      {
        items: [
          {
            label: "Linux Based OS Version",
            value: "Running on LTS",
          },
        ],
      },
    ],
    debian: [
      {
        items: [
          {
            label: "OS Version",
            value: "Running on LTS",
          },
        ],
      },
    ],
    ubuntu: [
      {
        items: [
          {
            label: "OS Version",
            value: "v18.04 or above",
          },
        ],
      },
    ],
    "red-hat": [
      {
        items: [
          {
            label: "OS Version",
            value: "Running on LTS",
          },
        ],
      },
    ],
    "cent-os": [
      {
        items: [
          {
            label: "OS Version",
            value: "Running on LTS",
          },
        ],
      },
    ],
    "alma-linux": [
      {
        items: [
          {
            label: "OS Version",
            value: "Running on LTS",
          },
        ],
      },
    ],
    "rocky-linux": [
      {
        items: [
          {
            label: "OS Version",
            value: "Running on LTS",
          },
        ],
      },
    ],
    suse: [
      {
        items: [
          {
            label: "OS Version",
            value: "Running on LTS",
          },
        ],
      },
    ],
    "oracle-linux": [
      {
        items: [
          {
            label: "OS Version",
            value: "Running on LTS",
          },
        ],
      },
    ],

    // Add more OS cases as needed
  };

  // Get the card items based on the OS
  const cardItemsToShow = os ? osCardItemsMap[os] : cardItems;

  let debCommand = `MW_API_KEY=${context.user?.account?.agent_config?.token} MW_TARGET=${capturePath}:443 bash -c "$(curl -L https://install.middleware.io/scripts/deb-install.sh)"`;
  let rpmCpmmand = `MW_API_KEY=${context.user?.account?.agent_config?.token} MW_TARGET=${capturePath}:443 bash -c "$(curl -L https://install.middleware.io/scripts/rpm-install.sh)"`;

  if (context.user?.misc_details?.env_type != "prod") {
    debCommand = `MW_API_URL_FOR_CONFIG_CHECK="${capturePath}" ${debCommand}`;
    rpmCpmmand = `MW_API_URL_FOR_CONFIG_CHECK="${capturePath}" ${rpmCpmmand}`;
  }
  const sendEvent = useMixPanel();
  return (
    <>
      {packageType === "deb" && (
        <>
          <Command
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Linux",
                command_type: "Install Middleware Host Agent",
              });
            }}
            command={debCommand}
            className="margin-0"
          />
          <MwMessage
            type={Message.Info}
            message="The above script will register the Middleware Agent APT repository with your infrastructure and install the latest DEB package"
          ></MwMessage>
        </>
      )}

      {packageType === "rpm" && (
        <>
          <Command
            command={rpmCpmmand}
            className="margin-0"
            onCopyClick={() => {
              sendEvent("click on copy command", {
                agent_type: "Linux",
                command_type: "Install Middleware Host Agent",
              });
            }}
          />
          <MwMessage
            type={Message.Info}
            message="The above script will install the latest RPM package of the Middleware Host Agent."
          ></MwMessage>
        </>
      )}

      {packageType === "unknown" && (
        <>
          <TabSelection
            tabs={[
              {
                label: "DEB",
                value: 1,
              },
              {
                label: "RPM",
                value: 2,
              },
            ]}
            selectedTab={1}
            onTabChange={(tabObject) => {
              setTab(tabObject.value);
            }}
          />
          <div className="tab-content-area">
            {tab === 1 && (
              <>
                <Command
                  command={debCommand}
                  className="margin-0"
                  onCopyClick={() => {
                    sendEvent("click on copy command", {
                      agent_type: "Linux",
                      command_type: "Install Middleware Host Agent",
                    });
                  }}
                />
                <MwMessage
                  type={Message.Info}
                  message="The above script will register the Middleware Agent APT repository with your infrastructure and install the latest DEB package"
                ></MwMessage>
              </>
            )}
            {tab === 2 && (
              <>
                <Command
                  command={rpmCpmmand}
                  className="margin-0"
                  onCopyClick={() => {
                    sendEvent("click on copy command", {
                      agent_type: "Linux",
                      command_type: "Install Middleware Host Agent",
                    });
                  }}
                />
                <MwMessage
                  type={Message.Info}
                  message="This script will install latest RPM package of Middleware Host Agent"
                ></MwMessage>
              </>
            )}
          </div>
        </>
      )}

      <MwMessage
        type={Message.Warning}
        message="Run only one MW Agent per host. Multiple agents will cause unexpected behavior."
      ></MwMessage>
    </>
  );
};
export default LinuxInstallationSnippet;
