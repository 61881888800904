import { abbreviateNumber } from "core/application/utils";

export type DateRangeType =
  | "15m"
  | "30m"
  | "45m"
  | "1h"
  | "2h"
  | "4h"
  | "12h"
  | "1d"
  | "2d"
  | "1w"
  | "10d"
  | "1mo";
const DateRangeInMinutes: { [key in DateRangeType]: number } = {
  "15m": 15,
  "30m": 30,
  "45m": 45,
  "1h": 60,
  "2h": 120,
  "4h": 240,
  "12h": 720,
  "1d": 1440,
  "2d": 2880,
  "1w": 10080,
  "10d": 14400,
  "1mo": 43200,
};

function UnitMaps(val: string): string {
  switch (val) {
    case "k8s.node.cpu.utilization":
      return "percentage";
    case "k8s.node.memory.usage":
    case "k8s.node.network.io":
      return "bytes";
    default:
      return "number";
  }
}

function IntervalInSeconds(val: DateRangeType): number {
  switch (val) {
    case "15m":
    case "30m":
    case "45m":
    case "1h":
      return 15;
    case "4h":
      return 60;
    case "12h":
      return 60 * 2;
    case "1d":
      return 60 * 5;
    case "2d":
      return 60 * 10;
    case "10d":
      return 60 * 60;
    case "1mo":
      return 60 * 60 * 24;
    default:
      return 15;
  }
}

function formatYAxisTick(tickValue: any): any {
  if (tickValue === 0) {
    return tickValue;
  } else {
    return abbreviateNumber(tickValue);
  }
}

function convertMilliseconds(milliseconds: any): string {
  const seconds = parseFloat(milliseconds) / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;

  if (milliseconds < 1000) {
    return parseFloat(milliseconds).toFixed(2) + "ms";
  } else if (seconds < 60) {
    return seconds.toFixed(2) + "s";
  } else if (minutes < 60) {
    return minutes.toFixed(2) + "min";
  } else if (hours < 24) {
    return hours.toFixed(2) + "hr";
  } else {
    return days.toFixed(2) + "days";
  }
}

function formatYAxisTicker(tickValue: any, resource: any): any {
  let unit = "number";
  if (
    resource != undefined &&
    resource.columns != undefined &&
    resource.columns.length > 0
  ) {
    unit = UnitMaps(resource.columns[0]);
  }
  switch (unit) {
    case "percentage":
      return tickValue + "%";
    case "bytes":
      return formatBytes(tickValue);
    default:
      return formatYAxisTick(tickValue);
  }
}

function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + "" + sizes[i];
}

const granularityInterval = (tsStart: number, tsEnd: number) => {
  const interval = Math.round((tsEnd - tsStart) / 1000);
  let _val = 60 * 60 * 24;
  if (interval <= 15 * 60) {
    _val = 1;
  } else if (interval <= 45 * 60) {
    _val = 15;
  } else if (interval <= 60 * 60) {
    _val = 60;
  } else if (interval <= 60 * 60 * 24) {
    _val = 60 * 5;
  } else if (interval <= 60 * 60 * 24 * 7) {
    _val = 60 * 60;
  }
  return _val;
};

export {
  DateRangeInMinutes,
  IntervalInSeconds, convertMilliseconds, formatYAxisTick,
  formatYAxisTicker,
  granularityInterval
};

