import React, { useState } from "react";
import "./_style.scss";
import Button from "core/components/v2/button";
import FormInputValidation from "core/components/form/form-input-validation";
import { DashboardCfg } from "../../entities/builder.entities";
import { useDispatch } from "react-redux";
import { showToast } from "store/toast-alerts/actions";
import {
  executeDashboardOperation,
  importDashboard,
} from "store/dashboard-builder/api";
import FormTextInput from "core/components/v2/form/form-text-input";
import FormTextArea from "core/components/v2/form/form-text-area";
import { APIStatus, capitalizeFirstLetter } from "core/application/utils";
import { useNavigate } from "react-router-dom";
import {
  DashboardOperationResp,
  Dashboard,
  ImportDashboardResp,
  DashboardOperationType,
} from "store/dashboard-builder/entities";
import FileUpload from "core/components/v2/file-upload";
import { UploadFile } from "antd/es/upload";
import { generateDashboardKey } from "../../common/utils";
import FormInputCheckbox from "core/components/form/form-checkbox";
import MwMessage from "views/modules/installation-v2/components/message";
import { Message } from "core/components/v2/enums";

// There are two types of dashboards: scope-based and report-based
export enum DashboardType {
  // scope-based dashboards are displayed in the infrastructure module tab.
  ScopeBased = "scope-based",
  // report-based dashboards are displayed in the dashboard builder module.
  ReportBased = "report-based",
}

export enum DashboardOpsType {
  Create = "Create",
  Import = "Import",
  Edit = "Edit",
  Clone = "Clone",
  Delete = "Delete",
}

export interface ManageDashboardProps {
  type: DashboardOpsType;
  dashboardType: DashboardType;
  // displayScope represents the scope of the dashboard for which the dashboard is being imported.
  displayScope?: string;
  onClose: (refreshDashboardList?: boolean) => void;
  // dashboard is passed only when editing or cloning a dashboard
  dashboard?: Dashboard;
}

interface FormData {
  label: string;
  key: string; // Will be used only for create request
  description: string;
  file?: UploadFile;
  submitted: boolean;
  isDatadogDashboard: boolean;
}

export default function ManageDashboard(props: ManageDashboardProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<FormData>({
    label: props.dashboard?.label || "",
    key: props.dashboard?.key || "",
    description: props.dashboard?.description || "",
    submitted: false,
    isDatadogDashboard: false,
  });

  // handleImportDashbaord validates the form input along with the file and submits to the backend
  const handleImportDashbaord = (dashboardSource?: string) => {
    if (
      !formData.file ||
      formData.file.type !== "application/json" ||
      !formData.file.originFileObj ||
      (props.dashboardType === DashboardType.ReportBased && !formData.label)
    ) {
      return;
    }

    formData.file.originFileObj
      ?.text()
      .then((rawFileData) => {
        let dashboardConfig;
        if (dashboardSource === "datadog") {
          // Validation is being performed at Converter Service API
          dashboardConfig = JSON.parse(rawFileData) as Record<string, unknown>;
          // Overriding Title
          if (dashboardConfig?.title) {
            dashboardConfig.title = formData.label;
          }
        } else {
          dashboardConfig = JSON.parse(rawFileData) as DashboardCfg;

          //Reset Stats Options if exists (Use case suppose datadog converted dashboard is downloaded then imported again than need to clear stats)
          if (dashboardConfig?.builderViewOptions?.report?.metadata?.stats) {
            delete dashboardConfig.builderViewOptions.report.metadata.stats;
          }

          // Validate file based on the type of dashboard
          switch (props.dashboardType) {
            case DashboardType.ReportBased: {
              dashboardConfig.builderViewOptions.report = {
                ...(dashboardConfig.builderViewOptions.report ?? {}),
                reportName: formData.label,
                reportId: 0,
              };
              // Reset displayScope field to avoid any unwanted issues
              dashboardConfig.builderViewOptions.displayScope = undefined;
              break;
            }
            case DashboardType.ScopeBased: {
              if (!props.displayScope) {
                dispatch(showToast("error", "Display scope is not provided"));
                return;
              }
              // Set the displayScope provided by the caller via props.
              dashboardConfig.builderViewOptions.displayScope =
                props.displayScope;
              // Reset report field to avoid any unwanted issues
              dashboardConfig.builderViewOptions.report = undefined;
              break;
            }
          }
        }

        // Create a File Blob from the modified file JSON data
        const fileBlob = new Blob([JSON.stringify(dashboardConfig)], {
          type: "application/json",
        });

        importDashboard(
          fileBlob,
          // Handle the import dashboard API response
          (resp: ImportDashboardResp) => {
            switch (resp.apiStatus) {
              case APIStatus.SUCCESS: {
                let message = "New widgets imported successfully.";
                if (props.dashboardType === DashboardType.ReportBased) {
                  message = `New dashboard named ${resp.importedDashboard?.label} imported successfully.`;
                }

                dispatch(
                  showToast("success", message, {
                    title: "Successful Import",
                  })
                );

                props.onClose();
                if (props.dashboardType === DashboardType.ReportBased)
                  navigate(`/dashboards/${resp.importedDashboard?.key}`);
                break;
              }
              case APIStatus.ERROR: {
                dispatch(
                  showToast(
                    "error",
                    capitalizeFirstLetter(
                      resp.errorMsg || "Something went wrong!"
                    ),
                    {
                      title: "Unsuccessful Import",
                    }
                  )
                );
                break;
              }
            }
          },
          dashboardSource
        );
      })
      .catch((err) => {
        // MTODO: Handle file read error.
        console.error(err);
      });
  };

  // handleDashboardOperation validates the form input and submits to the backend
  const handleDashboardOperation = (operationType: DashboardOperationType) => {
    if (!formData.label || !formData.key) {
      return;
    }

    executeDashboardOperation(
      operationType,
      {
        label: formData.label,
        key: formData.key,
        description: formData.description,
        visibility: "public",
        id: props.dashboard?.id,
      },
      // Handle the dashboard operation API response
      (resp: DashboardOperationResp) => {
        switch (resp.apiStatus) {
          case APIStatus.SUCCESS: {
            dispatch(
              showToast(
                "success",
                `Dashboard named ${formData.label} ${operationType.toLowerCase()}${operationType.toLowerCase().endsWith("e") ? "d" : "ed"} successfully.`,
                {
                  title: `Successful ${operationType}`,
                }
              )
            );

            props.onClose(operationType === DashboardOperationType.Delete);

            if (operationType !== DashboardOperationType.Delete)
              navigate(`/dashboards/${resp.dashboard?.key}`);
            break;
          }
          case APIStatus.ERROR: {
            dispatch(
              showToast(
                "error",
                capitalizeFirstLetter(resp.errorMsg || "Something went wrong!"),
                {
                  title: `Unsuccessful ${operationType}`,
                }
              )
            );
            break;
          }
        }
      }
    );
  };

  const handleFormSubmit = () => {
    setFormData({ ...formData, submitted: true });

    switch (props.type) {
      case DashboardOpsType.Create: {
        handleDashboardOperation(DashboardOperationType.Create);
        break;
      }

      case DashboardOpsType.Import: {
        if (formData.isDatadogDashboard) {
          handleImportDashbaord("datadog");
        } else {
          handleImportDashbaord();
        }
        break;
      }

      case DashboardOpsType.Edit: {
        handleDashboardOperation(DashboardOperationType.Update);
        break;
      }

      case DashboardOpsType.Clone: {
        handleDashboardOperation(DashboardOperationType.Clone);
        break;
      }

      case DashboardOpsType.Delete: {
        handleDashboardOperation(DashboardOperationType.Delete);
        break;
      }
    }
  };

  return (
    <div className="manage-dashboard-content">
      {props.type !== DashboardOpsType.Delete &&
        props.dashboardType === DashboardType.ReportBased && (
          <div className="form-input-group">
            <span className="form-input-label">Name of Dashboard</span>
            <FormTextInput
              id="dashboard-name"
              value={formData.label}
              isError={formData.submitted && !formData.label}
              placeholder="Let's give your dashboard a name"
              onChange={(val) => {
                setFormData({
                  ...formData,
                  label: val,
                  key: generateDashboardKey(val),
                  submitted: false,
                });
              }}
            />
            {formData.submitted && !formData.label && (
              <FormInputValidation isError={true} text={"Name is required"} />
            )}
          </div>
        )}

      {[
        DashboardOpsType.Create,
        DashboardOpsType.Edit,
        DashboardOpsType.Clone,
      ].includes(props.type) && (
        <div className="form-input-group">
          <span className="form-input-label">Description</span>
          <FormTextArea
            id="dashboard-description"
            placeholder="Add some description regarding the dashboard"
            rows={8}
            value={formData.description}
            onChange={(val) => setFormData({ ...formData, description: val })}
          />
        </div>
      )}

      {[DashboardOpsType.Import].includes(props.type) && (
        <div className="form-input-group">
          <FormInputCheckbox
            className="checkbox"
            id="is-datadog-dashboard"
            label="Importing Dashboard from Datadog"
            labelClassName="is_datadog_form-text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({
                ...formData,
                isDatadogDashboard: e.target.checked,
              });
            }}
          />
          {formData?.isDatadogDashboard && (
            <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
              <MwMessage
                type={Message.Info}
                message="Converts DataDog Dashboard JSON to Middleware format. Currently supports metrics from DataDog agent only. Some features may be limited as we continue to improve the service"
              ></MwMessage>
            </div>
          )}
        </div>
      )}

      {props.type === DashboardOpsType.Import && (
        <div className="form-input-group">
          <div style={{ height: formData.file ? "auto" : "35vh" }}>
            <FileUpload
              name="dashboard-file"
              onFileUpload={(files: UploadFile[]) => {
                setFormData({
                  ...formData,
                  file: files[0],
                  submitted: false,
                });
              }}
              onRemove={() => {
                delete formData.file;
                setFormData({ ...formData, submitted: false });
              }}
              fileList={formData.file ? [formData.file] : []}
            />
          </div>

          {formData.submitted && !formData.file && (
            <FormInputValidation isError={true} text={"File is required"} />
          )}

          {formData.submitted &&
            formData.file &&
            formData.file.type !== "application/json" && (
              <FormInputValidation
                isError={true}
                text={"File type must be JSON"}
              />
            )}
        </div>
      )}

      {props.type === DashboardOpsType.Delete && (
        <div className="form-input-group">
          <span>
            Are you sure you want to delete <b>{props.dashboard?.label}</b>?
          </span>
        </div>
      )}

      <div className="form-button-group">
        <div className="form-button">
          <Button
            primary
            onClick={handleFormSubmit}
            disabled={
              (props.type !== DashboardOpsType.Delete &&
                props.dashboardType === DashboardType.ReportBased &&
                !formData.label) ||
              (props.type === DashboardOpsType.Import && !formData.file)
            }
          >
            {props.type}
            {props.type !== DashboardOpsType.Delete && " Dashboard"}
          </Button>
        </div>

        {props.type === DashboardOpsType.Delete && (
          <div className="form-button">
            <Button onClick={props.onClose}>Cancel</Button>
          </div>
        )}
      </div>
    </div>
  );
}
