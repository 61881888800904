import * as React from "react";
interface Props {
  stepNumber: number;
  title: string;
  description?: string | React.ReactNode;
  children?: React.ReactNode;
}
const StepItem = (props: Props) => {
    const {stepNumber, title, description, children} = props;
    return (
        <div className="step-card">
            <div className={`step-card-title-container${description ? " with-description" : ""}`}>
                <div className="step-number">
                    <span>{stepNumber}</span>
                </div>
                <div className="step-title">{title}</div>
            </div>
            { description && <div className={`step-description ${children ? " with-children" : ""}`}>{description}</div> }
            { children && <div className="step-body">{children}</div> }
</div>
)
    ;
};
export default StepItem;
