import React, {useContext, useState} from "react";
import {showToast} from "store/toast-alerts/actions";
import {useDispatch} from "react-redux";
import {
    CopyIconNew,
} from "core/components/svg/icons";
import AppContext, {ContextProps} from "core/components/wrapper/context";
import CodeViewer from "core/components/code-viewer";
import Button from "core/components/button";
import {getTenantUrl} from "core/application/utils";
import FormTextInput from "core/components/form/form-text-input";
import {StepsEntity, StepsEnum} from "views/modules/integrations/entity";


export default function DenoAPM() {
    const context = useContext<ContextProps>(AppContext);
    const dispatch = useDispatch();

    const url = getTenantUrl(context.user);
    const capturePath = `https://${url}`;

    function copyToClipboard(text: string) {

        navigator.clipboard.writeText(text)
            .then(() => {
                dispatch(showToast("success", "Copied to Clipboard !"))
            });
    }

    const steps: StepsEntity = {
        code: {
            title: "Getting Started with Code",
        },
        custom_logs: {
            title: "Setup Custom Logs",
        },
        see_data: {
            title: "View Your Data",
        }
    }

    const [activeStep, setActiveStep] = useState(StepsEnum.Code);
    const [activeDeployment, setActiveDeployment] = useState('kubernetes');
    const [activeServiceName, setActiveServiceName] = useState('{APM-SERVICE-NAME}');

    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }


    const dependencyDeno = `import { serve } from "https://deno.land/std@0.120.0/http/server.ts";\nimport { track, httpTracer, info, warn, error, debug } from "https://deno.land/x/middlewareio@v1.0.7/mod.ts";`

    const config = 'track({\n' +
        `   serviceName: '${activeServiceName || '{APM-SERVICE-NAME}'}',\n` +
        `   target: \'${capturePath}:443\',\n` +
        `   accessToken: \'${context.user?.account?.agent_config?.token}\',\n` +
        '})'

    const requestHandle = 'function handler(_req: Request): Response {\n' +
        '   const data = {\n' +
        '       message: `Hello world!`,\n' +
        '   };\n' +
        '   return new Response(JSON.stringify(data), { headers: { \'Content-Type\': \'application/json\' } });\n' +
        '}'

    const server = 'await serve(httpTracer(handler));'

    const customLogs = 'info("info");\n' +
        'warn("warn");\n' +
        'error("error");\n' +
        'debug("debug");';

    return (
        <>
            <div className={"markdown-body installation-docs apm-installation-doc deno-doc"}>
                <div className={"apm-body"}>
                    <div className={"apm-steps"}>
                        {
                            steps && Object.keys(steps).map((step, index) => {
                                return <div key={index + 1}
                                            className={`step ${activeStep === step ? 'selected' : ''} ${(Object.keys(steps).indexOf(activeStep) || 0) > index ? 'passed' : ''}`}>
                                    <div className={"count"}>
                                        <span>{index + 1}</span>
                                    </div>
                                    <div className={"detail"} onClick={() => setActiveStep(step)}>
                                        {steps[step].title}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className={`apm-content`}>
                        {activeStep === StepsEnum.Code && <>
                            <div className={"workspace"}>
                                <div className={"blocks full-width-block"}>
                                    <div className={"block first-step"}>
                                        <div className={"heading"}>
                                            <span>1. Import dependencies</span>
                                        </div>
                                        <div className={'desc jar_step_1'}>
                                            <span>Import necessary packages into your Deno project from the Deno standard library and middlewareio library:</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'java'}>{dependencyDeno}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard(dependencyDeno)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>


                                        <div className={"heading"}>
                                            <span>2. Configure Tracing</span>
                                        </div>
                                        <div className={'desc'}>
                                            <span>Trace network requests with the following `track()` function:</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={"inputbox-flex"} style={{marginTop: 15}}>
                                                <FormTextInput label={"Service Name"} placeholder={"Service Name"}
                                                               maxLength={50}
                                                               onChange={(e: any) => setActiveServiceName(e.target.value)}/>
                                            </div>

                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'java'}>{config}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard(config)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>

                                        <div className={"heading"}>
                                            <span>3. Define Request Handler</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Define how your server responds to incoming HTTP requests with the following function:</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'java'}>{requestHandle}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard(requestHandle)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>

                                        <div className={"heading"}>
                                            <span>4. Start Your Server</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Start an HTTP server with the `serve` function:</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'java'}>{server}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard(server)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div className={"buttons-group"}>
                                <Button className={"disabled"}>Back</Button>
                                <Button primary onClick={() => setActiveStep('custom_logs')}>Next</Button>
                            </div>
                        </>}
                        {activeStep === 'custom_logs' && <>
                            <div className={"workspace"}>
                                <div className={"blocks full-width-block"}>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>1. Enable Custom Logs (Optional)</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Custom logs are ones that the application does not produce by default. They may be generated within certain methods, or managed by a central logging method in your application.</span><br/>
                                            <span>To get started with custom logs add the following code:</span>
                                        </div>
                                        <div className={"content"}>
                                            <div className={'docs-script'}>
                                                <CodeViewer showLineNumbers={false}
                                                            language={'java'}>{customLogs}</CodeViewer>
                                                <span className={"doc-copy-btn"}
                                                      onClick={() => copyToClipboard(customLogs)}>
                                                        <CopyIconNew size={8}/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={"buttons-group"}>
                                <Button onClick={() => setActiveStep(StepsEnum.Code)}>Back</Button>
                                <Button primary onClick={() => setActiveStep('see_data')}>Next</Button>
                            </div>
                        </>}
                        {activeStep === 'see_data' && <>
                            <div className={"workspace"}>
                                <div className={"blocks full-width-block"}>
                                    <div className={"block"}>
                                        <div className={"heading"}>
                                            <span>View Your Data</span>
                                        </div>
                                        <div className={"desc"}>
                                            <span>Once you have completed the Deno installation, wait 3-5 minutes before accessing your data.</span>
                                        </div>
                                        <div className={"content"}>
                                            <p className={"paragraph"}
                                               style={{marginTop: "10px", marginBottom: "0"}}>Access your Trace, Log,
                                                and Profiling data by clicking on one of the buttons bellow.</p>
                                            <p className={"paragraph"} style={{marginTop: "0px"}}>Proceed to deploy your
                                                project on {capitalizeFirstLetter(activeDeployment)}.</p>
                                            <div className={"buttons-group"}>
                                                <Button primary
                                                        onClick={() => window.open('/apm/overview', '_blank')}>Traces</Button>
                                                <Button primary
                                                        onClick={() => window.open('/logs', '_blank')}>Logs</Button>
                                                {activeDeployment != "windows" ? <Button primary
                                                                                         onClick={() => window.open('/apm/continuous-profiling', '_blank')}>Profiling</Button> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={"buttons-group"}>
                                <Button onClick={() => setActiveStep('custom_logs')}>Back</Button>
                                <Button className={"disabled"}>Next</Button>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </>
    )
}