import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "store/toast-alerts/actions";
import {
  AlertsIntegrationIcon,
  ContainerizedIcon,
  CopyIconNew,
  DebAndUbuntuColorSvg,
  DockerColorSvg,
  KubernetesColorSvg,
  NativelyIcon,
} from "core/components/svg/icons";
import AppContext, { ContextProps } from "core/components/wrapper/context";
import CodeViewer from "core/components/code-viewer";
import Button from "core/components/button";
import CommonAgentInstallation from "views/modules/installation/components/apm/common-agent-installation";
import FormTextInput from "core/components/form/form-text-input";
import GoToDashboard from "../../common/goto-dashboard";
import { getTenantUrl } from "core/application/utils";
import { StepsEntity, StepsEnum } from "views/modules/integrations/entity";

interface Serverless {
  serverless: boolean;
}

export default function GoLangAPM(props: Serverless) {
  const context = useContext<ContextProps>(AppContext);
  const isHelloBarRendered = document.querySelector(".hello-bar") !== null;

  const dispatch = useDispatch();

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      dispatch(showToast("success", "Copied to Clipboard !"));
    });
  }

  const [activeServiceName, setActiveServiceName] =
    useState("{APM-SERVICE-NAME}");

  const stackError =
    'r.GET("/books", func(c *gin.Context) {\n' +
    "    ctx := req.Context()\n" +
    "    if err := db.Ping(ctx); err != nil {\n" +
    "        track.RecordError(ctx, err)\n" +
    "        panic(err)\n" +
    "    }\n" +
    "})";

  const ApmLogginSnap =
    `"github.com/middleware-labs/golang-apm/logger\"\n` +
    "\n" +
    "....\n" +
    "\n" +
    'logger.Error("Error")\n' +
    'logger.Info("Info")\n' +
    'logger.Warn("Warn")\n';

  const steps: StepsEntity = {
    deployment_type: {
      title: "Choose Deployment Type",
    },
    code: {
      title: "Getting Started with Code",
    },
    custom_logs: {
      title: "Setup Custom Logs",
    },
    see_data: {
      title: "View Your Data",
    },
  };
  const serverless: StepsEntity = {
    code: {
      title: "Getting Started with Code",
    },
    custom_logs: {
      title: "Setup Custom Logs",
    },
    see_data: {
      title: "View Your Data",
    },
  };

  const url = getTenantUrl(context.user);
  const capturePath = `https://${url}`;

  const [activeStep, setActiveStep] = useState(StepsEnum.DeploymentType);
  const [activeDeployment, setActiveDeployment] = useState("kubernetes");
  const [activeAppType, setActiveAppType] = useState("containerized");
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // if(props.serverless) {
  useEffect(() => {
    setActiveStep("code");
  }, [props.serverless]);
  // }

  const urlTest = new URL(capturePath);
  const hostname = urlTest.hostname;

  const MwAgentServiceK8s =
    "MW_AGENT_SERVICE=mw-service.mw-agent-ns.svc.cluster.local";
  const MwAgentServiceLinuxDocker = "MW_AGENT_SERVICE=172.17.0.1";
  const golangTrack =
    "import (\n " +
    `track "github.com/middleware-labs/golang-apm/tracker" \n` +
    ")";
  const golangTrace =
    "go track.Track( \n " +
    `track.WithConfigTag("service", "${activeServiceName == "" ? "{APM-SERVICE-NAME}" : activeServiceName}"), \n` +
    ` track.WithConfigTag("accessToken", "${context.user?.account?.agent_config?.token}"),\n` +
    `)`;

  const golangTraceServerLess =
    "go track.Track( \n " +
    `track.WithConfigTag("service", "${activeServiceName == "" ? "{APM-SERVICE-NAME}" : activeServiceName}"), \n` +
    ` track.WithConfigTag("accessToken", "${context.user?.account?.agent_config?.token}"),\n` +
    ` ${props.serverless && 'track.WithConfigTag("target",' + ' "' + hostname + ":443"}"), \n ` +
    `)`;
  return (
    <>
      <div
        className={
          "markdown-body installation-docs apm-installation-doc golang-doc"
        }
      >
        <div className={"apm-body"}>
          <div className={"apm-steps"}>
            {props.serverless
              ? serverless &&
                Object.keys(serverless).map((step, index) => {
                  return (
                    <div
                      key={index + 1}
                      className={`step ${activeStep === step ? "selected" : ""} ${(Object.keys(serverless).indexOf(activeStep) || 0) > index ? "passed" : ""}`}
                    >
                      <div className={"count"}>
                        <span>{index + 1}</span>
                      </div>
                      <div
                        className={"detail"}
                        onClick={() => setActiveStep(step)}
                      >
                        {serverless[step].title}
                      </div>
                    </div>
                  );
                })
              : steps &&
                Object.keys(steps).map((step, index) => {
                  return (
                    <div
                      key={index + 1}
                      className={`step ${activeStep === step ? "selected" : ""} ${(Object.keys(steps).indexOf(activeStep) || 0) > index ? "passed" : ""}`}
                    >
                      <div className={"count"}>
                        <span>{index + 1}</span>
                      </div>
                      <div
                        className={"detail"}
                        onClick={() => setActiveStep(step)}
                      >
                        {steps[step].title}
                      </div>
                    </div>
                  );
                })}
          </div>
          <div
            className={`apm-content ${!isHelloBarRendered && "hello-bar-exists"}`}
          >
            {!props.serverless && activeStep === StepsEnum.DeploymentType && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>
                          1. Our APMs require the host agent in order to
                          operate. To install a host agent please select from
                          the below options.{" "}
                        </span>
                        <p>
                          <span>
                            (If the host already has an agent running please{" "}
                            <span onClick={() => setActiveStep("code")}>
                              {" "}
                              skip to Getting Started with Code.
                            </span>
                            )
                          </span>
                        </p>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Run only one MW Agent. Multiple agents will cause
                          unexpected behavior.
                        </span>
                        <br />
                        <span>
                          Which platform are you hosting your Go application?
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"button-flex"}>
                          <button
                            className={
                              activeDeployment === "kubernetes" ? "active" : ""
                            }
                            onClick={() => {
                              setActiveDeployment("kubernetes");
                              setActiveAppType("containerized");
                            }}
                          >
                            <KubernetesColorSvg /> Kubernetes
                          </button>
                          <button
                            className={
                              activeDeployment === "linux" ? "active" : ""
                            }
                            onClick={() => setActiveDeployment("linux")}
                          >
                            <DebAndUbuntuColorSvg /> Linux
                          </button>
                          <button
                            className={
                              activeDeployment === "docker" ? "active" : ""
                            }
                            onClick={() => setActiveDeployment("docker")}
                          >
                            <DockerColorSvg /> Docker
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>2. Application Behaviour Will Be</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Please choose from below, as your application will run
                          accordingly.
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"button-flex"}>
                          <button
                            className={
                              activeAppType === "containerized" ? "active" : ""
                            }
                            onClick={() => setActiveAppType("containerized")}
                          >
                            <ContainerizedIcon /> Containerized
                          </button>
                          <button
                            className={`${activeAppType === "natively" ? "active" : ""} ${activeDeployment === "kubernetes" ? "disabled" : ""}`}
                            onClick={() => {
                              if (activeDeployment !== "kubernetes") {
                                setActiveAppType("natively");
                              }
                            }}
                          >
                            <NativelyIcon /> Natively
                          </button>
                        </div>
                      </div>
                    </div>
                    {activeAppType === "containerized" && (
                      <div className={"block"}>
                        <div className={"heading"}>
                          <span>3. Setup Container Variable</span>
                        </div>
                        <div className={"desc"}>
                          <span>
                            Application running in a container require an
                            additional environment variable
                          </span>
                        </div>
                        <div className={"content"}>
                          {activeDeployment === "kubernetes" && (
                            <>
                              <div className={"docs-script"}>
                                <CodeViewer
                                  showLineNumbers={false}
                                  language={"javascript"}
                                >
                                  {MwAgentServiceK8s}
                                </CodeViewer>
                                <span
                                  className={"doc-copy-btn"}
                                  onClick={() =>
                                    copyToClipboard(MwAgentServiceK8s)
                                  }
                                >
                                  <CopyIconNew size={8} />
                                </span>
                              </div>
                              <span className={"sentence mini"}>
                                The default namespace for running the Middleware
                                agent is{" "}
                                <strong>
                                  mw-service.mw-agent-ns.svc.cluster.local
                                </strong>
                                .
                              </span>
                              <br />
                            </>
                          )}
                          {activeDeployment !== "kubernetes" && (
                            <>
                              <div className={"docs-script"}>
                                <CodeViewer
                                  showLineNumbers={false}
                                  language={"javascript"}
                                >
                                  {MwAgentServiceLinuxDocker}
                                </CodeViewer>
                                <span
                                  className={"doc-copy-btn"}
                                  onClick={() =>
                                    copyToClipboard(MwAgentServiceLinuxDocker)
                                  }
                                >
                                  <CopyIconNew size={8} />
                                </span>
                              </div>
                              <span className={"sentence mini"}>
                                The default value for{" "}
                                <strong>DOCKER_BRIDGE_GATEWAY_ADDRESS</strong>{" "}
                                is typically <strong>172.17.0.1</strong>.
                              </span>
                              <br />
                              <span className={"sentence mini"}>
                                For more detailed information, refer to the
                                official Docker&nbsp;
                                <a
                                  href={
                                    "https://docs.docker.com/network/network-tutorial-standalone/"
                                  }
                                  target={"_blank"}
                                >
                                  documentation
                                </a>
                                .
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    <div className={"block"}>
                      <CommonAgentInstallation
                        activeDeployment={activeDeployment}
                        activeAppType={activeAppType}
                      />
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button className={"disabled"}>Back</Button>
                  <Button primary onClick={() => setActiveStep("code")}>
                    Next
                  </Button>
                </div>
              </>
            )}

            {activeStep === "code" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>1. Install Go APM package</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Run this command at the root of your workspace:
                        </span>{" "}
                        <br />
                        <span>
                          Make sure you have installed the Go version with
                          greater than{" "}
                          <a
                            href={"https://go.dev/doc/go1.17"}
                            target={"_blank"}
                            rel={"noreferrer"}
                          >
                            v1.17+
                          </a>
                          .
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer
                            showLineNumbers={false}
                            language={"javascript"}
                          >
                            {"go get github.com/middleware-labs/golang-apm"}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() =>
                              copyToClipboard(
                                "go get github.com/middleware-labs/golang-apm"
                              )
                            }
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>2. Import Tracker</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Add this import statement in your application:
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer
                            showLineNumbers={false}
                            language={"javascript"}
                          >
                            {golangTrack}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard(golangTrack)}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>3. Add this snippet in your code</span>
                      </div>
                      <div className={"content"}>
                        <div
                          className={"inputbox-flex"}
                          style={{ marginTop: 15 }}
                        >
                          <FormTextInput
                            label={"Service Name"}
                            placeholder={"Service Name"}
                            maxLength={50}
                            onChange={(e: any) =>
                              setActiveServiceName(e.target.value)
                            }
                          />
                        </div>
                        <div className={"docs-script"}>
                          <CodeViewer
                            showLineNumbers={false}
                            language={"javascript"}
                          >
                            {props.serverless
                              ? golangTraceServerLess
                              : golangTrace}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() =>
                              copyToClipboard(
                                props.serverless
                                  ? golangTraceServerLess
                                  : golangTrace
                              )
                            }
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"help"}>
                    <div className={"block"}>
                      <AlertsIntegrationIcon /> You can clone the sample project
                      from the{" "}
                      <a
                        href={
                          "https://github.com/middleware-labs/demo-apm/tree/master/golang/setup"
                        }
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        GitHub
                      </a>{" "}
                      repository.
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  {props.serverless ? (
                    <Button className={"disabled"}>Back</Button>
                  ) : (
                    <Button
                      onClick={() => setActiveStep(StepsEnum.DeploymentType)}
                    >
                      Back
                    </Button>
                  )}
                  <Button primary onClick={() => setActiveStep("custom_logs")}>
                    Next
                  </Button>
                </div>
              </>
            )}
            {activeStep === "custom_logs" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>1. Enable Custom Logs (Optional)</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Custom logs are ones that the application does not
                          produce by default. They may be generated within
                          certain methods, or managed by a central logging
                          method in your application.
                        </span>
                        <br />
                        <span>
                          To get started with custom logs add the following
                          code:
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer
                            showLineNumbers={false}
                            language={"javascript"}
                          >
                            {ApmLogginSnap}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard(ApmLogginSnap)}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>2. Enable Stack Errors</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Use the errorRecord method to record stack traces that
                          throw an error object:
                        </span>
                      </div>
                      <div className={"content"}>
                        <div className={"docs-script"}>
                          <CodeViewer
                            showLineNumbers={false}
                            language={"javascript"}
                          >
                            {stackError}
                          </CodeViewer>
                          <span
                            className={"doc-copy-btn"}
                            onClick={() => copyToClipboard(stackError)}
                          >
                            <CopyIconNew size={8} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button onClick={() => setActiveStep("code")}>Back</Button>
                  <Button primary onClick={() => setActiveStep("see_data")}>
                    Next
                  </Button>
                </div>
              </>
            )}
            {activeStep === "see_data" && (
              <>
                <div className={"workspace"}>
                  <div className={"blocks"}>
                    <div className={"block"}>
                      <div className={"heading"}>
                        <span>View Your Data</span>
                      </div>
                      <div className={"desc"}>
                        <span>
                          Once you have completed the Go installation, wait 3-5
                          minutes before accessing your data.
                        </span>
                      </div>
                      <div className={"content"}>
                        <p
                          className={"paragraph"}
                          style={{ marginTop: "10px", marginBottom: "0" }}
                        >
                          Access your Trace, Log, and Profiling data by clicking
                          on one of the buttons bellow.
                        </p>
                        <p className={"paragraph"} style={{ marginTop: "0px" }}>
                          Proceed to deploy your project on{" "}
                          {capitalizeFirstLetter(activeDeployment)}.
                        </p>
                        <div className={"buttons-group"}>
                          <Button
                            primary
                            onClick={() =>
                              window.open("/apm/overview", "_blank")
                            }
                          >
                            Traces
                          </Button>
                          <Button
                            primary
                            onClick={() => window.open("/logs", "_blank")}
                          >
                            Logs
                          </Button>
                          {activeDeployment != "windows" ? (
                            <Button
                              primary
                              onClick={() =>
                                window.open(
                                  "/apm/continuous-profiling",
                                  "_blank"
                                )
                              }
                            >
                              Profiling
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"buttons-group"}>
                  <Button onClick={() => setActiveStep("custom_logs")}>
                    Back
                  </Button>
                  <GoToDashboard
                    dashboardURL={`/dashboards/go_metrics_${context?.user?.account?.project_id}`}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
