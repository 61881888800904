import * as React from 'react';
import classNames from "classnames";
import { CloseSvg, SearchSvg } from "core/components/svg/icons";

import { debounce, noop, uniqueId } from 'lodash-es';

interface Props {
	autoFocus?: boolean,
	describedBy?: any,
	onClick?: (a0?: any, a1?: any) => void,
	disabled?: boolean,
	maxLength?: number,
	minLength?: number,
	onSearchClick?: (a0?: any, a1?: any) => void,
	hideOpenIcon?: boolean,
	onClickSearch?: (a0?: any, a1?: any) => void,
	onKeyDown?: (a0?: any, a1?: any) => void,
	onKeyUp?: (a0?: any, a1?: any) => void,
	additionalClasses?: string,
	searching?: any,
	initialValue?: any,
	isOpen?: boolean,
	placeholder?: string,
	inputLabel?: string,
	pinned?: boolean,
	dir?: any,
	children?: any,
	onSearchClose?: (a0?: any, a1?: any) => void,
	onBlur?: (a0?: any, a1?: any) => void,
	disableAutocorrect?: any,
	hideClose?: boolean,
	onSearch?: (a0?: any, a1?: any) => void,
	delaySearch?: boolean,
	delayTimeout?: number,
	onSearchChange?: (a0?: any, a1?: any) => void,
	value?: string
	clear?: (a0?: any, a1?: any) => void,
	onSearchOpen?: (a0?: any, a1?: any) => void,
	disabledSearchIcon?: boolean
}

const SEARCH_DEBOUNCE_MS = 300;

class Search extends React.Component<Props, any>{


	static defaultProps = {
		pinned: false,
		delaySearch: false,
		delayTimeout: SEARCH_DEBOUNCE_MS,
		autoFocus: false,
		disabled: false,
		describedBy: null,
		onSearchChange: noop,
		onSearchOpen: noop,
		onSearchClose: noop,
		onKeyDown: noop,
		onClick: noop,
		overlayStyling: undefined,
		disableAutocorrect: false,
		searching: false,
		isOpen: false,
		dir: undefined,
		fitsContainer: false,
		hideClose: false,
		compact: false,
		hideOpenIcon: false,
		hideClearIcon: false,
		filterButton: false
	};
	openIcon: any;
	instanceId: string;
	searchInput?: any;
	overlay?: any
	onSearch?: any;
	constructor(props: Props) {
		super(props);

		this.instanceId = uniqueId();

		this.state = {
			keyword: props.initialValue || '',
			isOpen: !!props.isOpen,
			hasFocus: props.autoFocus,
		};

	}

	setOpenIconRef = (openIcon: any) => (this.openIcon = openIcon);

	setSearchInputRef = (input: any) => (this.searchInput = input);


	componentWillReceiveProps(nextProps: Props) {
		if (this.props.onSearch != undefined &&
			(nextProps.onSearch !== this.props.onSearch ||
				nextProps.delaySearch !== this.props.delaySearch)
		) {
			this.onSearch = this.props.delaySearch
				? debounce(this.props.onSearch, this.props.delayTimeout)
				: this.props.onSearch;
		}

		if (nextProps.isOpen) {
			this.setState({ isOpen: nextProps.isOpen });
		}

		if (
			this.props.value !== nextProps.value &&
			(nextProps.value || nextProps.value === '') &&
			nextProps.value !== this.state.keyword
		) {
			this.setState({ keyword: nextProps.value });
		}
	}

	componentDidUpdate(prevProps: Props, prevState: any, nextProps: Props) {
		this.scrollOverlay();

		if (
			(this.state.isOpen && !prevState.isOpen) ||
			(this.props.autoFocus && !prevProps.autoFocus)
		) {
			this.focus();
		}

		if (this.state.keyword === prevState.keyword) {
			return;
		}

		if (this.onSearch) {
			this.onSearch(this.state.keyword);
		}

		this.props.onSearchChange != undefined && this.props.onSearchChange(this.state.keyword);
	}

	componentDidMount() {
		this.onSearch = this.props.onSearch != undefined && this.props.delaySearch
			? debounce(this.props.onSearch, this.props.delayTimeout)
			: this.props.onSearch;
		// this.setState({
		// 	keyword:this.props.value
		// })
	}

	scrollOverlay = () => {
		this.overlay &&
			window.requestAnimationFrame(() => {
				if (this.overlay && this.searchInput) {
					this.overlay.scrollLeft = this.getScrollLeft(this.searchInput);
				}
			});
	};


	getScrollLeft = (inputElement: any) => {
		if (!inputElement.createTextRange) {
			return inputElement.scrollLeft;
		}

		const range = inputElement.createTextRange();
		const inputStyle = window.getComputedStyle(inputElement, undefined);
		const paddingLeft = parseFloat(inputStyle.paddingLeft);
		const rangeRect = range.getBoundingClientRect();
		const scrollLeft =
			inputElement.getBoundingClientRect().left +
			inputElement.clientLeft +
			paddingLeft -
			rangeRect.left;
		return scrollLeft;
	};

	focus = () => {
		setTimeout(() => this.searchInput && this.searchInput.focus(), 0);
	};

	blur = () => this.searchInput.blur();

	clear = () => {
		this.setState({ keyword: '' });
		if (this.props.clear) {
			this.props.clear();
		}
	};

	onBlur = (event: any) => {
		if (this.props.onBlur) {
			this.props.onBlur(event);
		}

		this.setState({ hasFocus: false });
	};

	onChange = (event: any) => {
		this.setState({
			keyword: event.target.value,
		});
	};

	openSearch = (event: any) => {
		event.preventDefault();
		this.setState({
			keyword: '',
			isOpen: true,
		});

	};

	closeSearch = (event: any) => {
		event.preventDefault();

		if (this.props.disabled) {
			return;
		}

		this.setState({
			keyword: '',
			isOpen: this.props.isOpen || false,
		});

		this.searchInput.value = '';
		this.searchInput.blur();

		if (this.props.pinned) {
			this.openIcon.focus();
		}

		this.props.onSearchClose != undefined && this.props.onSearchClose(event);

	};

	keyUp = (event: any) => {
		this.props.onKeyUp && this.props.onKeyUp(event);
		if (!this.props.pinned) {
			return;
		}

		if (event.key === 'Escape') {
			this.closeSearch(event);
		}
		this.scrollOverlay();
	};

	keyDown = (event: any) => {
		this.scrollOverlay();
		if (event.key === 'Escape' && event.target.value === '') {
			this.closeSearch(event);
		}
		if (event.key === 'Enter') {
			this.props.onKeyDown && this.props.onKeyDown(event);
		}
	};


	onFocus = () => {
		if (!this.searchInput) {
			return;
		}

		const setValue = this.searchInput.value;
		if (setValue) {
			// Firefox needs clear or won't move cursor to end
			this.searchInput.value = '';
			this.searchInput.value = setValue;
		}

		this.setState({ hasFocus: true });
		this.props.onSearchOpen && this.props.onSearchOpen();
	};
	closeButton() {
		if (!this.props.hideClose && (this.state.keyword || this.state.isOpen)) {
			return (
				<div
					role="button"
					className="search__icon-navigation"
					onClick={this.closeSearch}
					tabIndex={0}
					aria-controls={'search-component-' + this.instanceId}
					aria-label={'Close Search'}
				>

					<CloseSvg />
				</div>
			);
		}

		return null;
	}
	render() {
		const searchValue = this.state.keyword;
		const placeholder = this.props.placeholder || 'Search...';
		const inputLabel = this.props.inputLabel;
		const enableOpenIcon = this.props.pinned && !this.state.isOpen;
		const isOpenUnpinnedOrQueried =
			this.state.isOpen || !this.props.pinned || this.props.initialValue;

		const autocorrect = this.props.disableAutocorrect && {
			autoComplete: 'off',
			autoCorrect: 'off',
			spellCheck: 'false',
		};

		const searchClass = classNames(this.props.additionalClasses, this.props.dir, {
			'is-open': isOpenUnpinnedOrQueried,
			'is-searching': this.props.searching,
			'has-focus': this.state.hasFocus,
			'has-open-icon': !this.props.hideOpenIcon,
			search: true,
		});

		const fadeDivClass = classNames('search__input-fade', this.props.dir);
		const inputClass = classNames('search__input', this.props.dir);

		return (
			<div dir={this.props.dir || null} className={searchClass} role="search">
				{!this.props.disabledSearchIcon && <div
					role="button"
					className="search__icon-navigation search-btn"
					ref={this.setOpenIconRef}
					onClick={this.props.onSearchClick ? this.props.onSearchClick : focus}
					tabIndex={enableOpenIcon ? 0 : undefined}
					aria-controls={'search-component-' + this.instanceId}
					aria-label={'Open Search'}
				>
					{!this.props.hideOpenIcon && <span className="icon-search search__open-icon" onClick={() => {
						if (this.props.onClickSearch && this.props.onKeyDown != undefined) {
							this.props.onKeyDown(null, searchValue)
						}
					}} ><SearchSvg /> </span>}
				</div>}
				<div className={fadeDivClass}>
					<input
						type="search"
						id={'search-component-' + this.instanceId}
						autoFocus={this.props.autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
						aria-describedby={this.props.describedBy}
						aria-label={inputLabel ? inputLabel : 'Search'}
						aria-hidden={!isOpenUnpinnedOrQueried}
						className={inputClass}
						placeholder={placeholder}
						role="searchbox"
						value={searchValue}
						ref={this.setSearchInputRef}
						onChange={this.onChange}
						onKeyUp={this.keyUp}
						onKeyDown={this.keyDown}
						onMouseUp={this.props.onClick}
						onFocus={this.onFocus}
						onBlur={this.onBlur}
						disabled={this.props.disabled}
						autoCapitalize="none"
						dir={this.props.dir}
						maxLength={this.props.maxLength}
						minLength={this.props.minLength}
						{...autocorrect}
					/>

				</div>
				{this.closeButton()}


			</div>
		);
	}




}

export default Search;
